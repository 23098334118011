/**
 * Métodos del, get, post, put para llamadas a la API
 * @get     en este método primero consulta con @getFromLocalStorage si es necesario hacer la petición http sino se obtiene del LocalStorage;
 *          si hay un error en la petición http se busca el objeto en el LocalStorage sino lo encuentra devuelve un objeto vacío; si la
 *          petición se realiza correctamente actualiza el valor individual y en el array del objeto en el LocalStorage y devuelve el objeto
 *          obtenido del servidor.
 * @getAll  en este método primero consulta con @getFromLocalStorage si es necesario hacer la petición http sino se obtiene del LocalStorage;
 *          si hay un error en la petición http se busca el objeto array en el LocalStorage sino lo encuentra devuelve un array vacío; si la
 *          petición se realiza correctamente actualiza array en el LocalStorage y devuelve el array obtenido del servidor
 * @update  en este método después de realizar la petición http si falla devuelve el objeto que se iba a actualizar; si la petición se
 *          realiza correctamente actualiza el valor individual y en el array del objeto en el LocalStorage y devuelve el objeto obtenido del
 *          servidor.
 * @create  en este método después de realizar la petición http si falla devuelve el objeto que se iba a crear; si la petición se realiza 
 *          correctamente actualiza el valor individual y en el array del objeto en el LocalStorage y devuelve el objeto obtenido del
 *          servidor.
 * @delete  en este método después de realizar la petición http si falla devuelve el objeto que se iba a eliminar; si la petición se realiza 
 *          correctamente elimina el valor individual y en el objeto en el arrya del LocalStorage y devuelve un objeto vacio (Default)
 */

import { del, get, post, put } from "../../api/api";
import { getFromLocalStorage, updateLocalStorageExpiry } from "../../api/helpers";
import { removeArrayItemStorageData } from "../../api/storage";
import { DEFAULT_STATE } from "../../enums/default_state";
import { getEmptyStatusDireccion, setDireccion, IDireccion, getSerializableDireccion } from "../../interfaces/personas";
import { IServerDate } from "../../interfaces/time";
import { getStorageDireccionArrayItem, setStorageDireccion, setStorageDireccionArrayItem, getStorageDireccionArray, setStorageDireccionArray, removeStorageDireccion } from "../../localStorage/personas";

export const getDireccion = async (serverDate: IServerDate, pk : number, idCliente : number) => {
  const temp = getEmptyStatusDireccion();
  const doHttp = await getFromLocalStorage(serverDate, temp.expiry);
  temp.expiry = doHttp.storage;
  if (!doHttp.result) {
    console.log(`(localStorage) Direccion con pk: ${pk}, obtenido con éxito`);
    temp.estado = DEFAULT_STATE.success;
    temp.mensaje = `Direccion obtenida con éxito`;
    temp.data = await getStorageDireccionArrayItem(pk);
    return temp;
  }
  const result = await get(`personas/direccion/${pk}/${idCliente}`);
  const data = result.data;
  console.log(`Direccion data: `, data);
  if (!data.id || data.detail) {
    console.log(`Error al obtener Direccion con pk: ${pk}, ${data.detail}`);
    temp.estado = DEFAULT_STATE.error;
    temp.mensaje =  `Error al obtener la direccion: ${data.detail ?? "Hubo un problema al conectarse con el servidor"}`;
    temp.data = await getStorageDireccionArrayItem(pk);
    return temp;
  }
  console.log(`Direccion con pk: ${pk}, obtenido con éxito`);
  temp.estado = DEFAULT_STATE.success;
  temp.mensaje = `Direccion obtenida con éxito`;
  temp.data = await setDireccion(data, serverDate);
  await setStorageDireccion(temp.data);
  temp.data_array = await setStorageDireccionArrayItem(pk, temp.data);
  temp.expiry = await updateLocalStorageExpiry(serverDate, temp.expiry);
  return temp;
}

export const getAllDireccion = async (serverDate: IServerDate, idCliente : number) => {
  const temp = getEmptyStatusDireccion();
  const doHttp = await getFromLocalStorage(serverDate, temp.expiry_array);
  temp.expiry_array = doHttp.storage;
  if (!doHttp.result) {
    console.log(`(localStorage) Todos las Direcciones obtenidas con éxito`);
    temp.estado = DEFAULT_STATE.success;
    temp.mensaje = `Todos las direcciones obtenidas con éxito`;
    temp.data_array = await getStorageDireccionArray();
  }
  const result = await get(`personas/direccion/${idCliente}`);
  const data = result.data;
  if (data.detail) {
    console.log(`Error al obtener la lista de Direccion`, data);
    temp.estado = DEFAULT_STATE.error;
    temp.mensaje = `Error al obtener la lista de direcciones: ${data.detail ?? "Hubo un problema al conectarse con el servidor"}`;
    temp.data_array = await getStorageDireccionArray();
    return temp;
  }
  console.log(`Todos las Direcciones obtenidos con éxito`);
  temp.estado = DEFAULT_STATE.success;
  temp.mensaje = `Todos los direcciones obtenidas con éxito`;
  temp.data_array = await Promise.all(data.map(async (e : any) => await setDireccion(e, serverDate)));
  await setStorageDireccionArray(temp.data_array);
  temp.expiry_array = await updateLocalStorageExpiry(serverDate, temp.expiry_array);
  return temp;
}

export const updateDireccion = async (serverDate: IServerDate, pk : number, idCliente : number, object : IDireccion) => {
  const temp = getEmptyStatusDireccion();
  const result = await put(`personas/direccion/${pk}/${idCliente}`, getSerializableDireccion(object));
  const data = result.data;
  if (!data.id || data.detail) {
    console.log(`Error al actualizar Direccion con pk: ${pk}, ${data.detail}`);
    temp.estado = DEFAULT_STATE.error;
    temp.mensaje = `Error al actualizar la direccion: ${data.detail ?? "Hubo un problema al conectarse con el servidor"}`;
    temp.data = await setDireccion(object, serverDate);
    return temp;
  }
  console.log(`Direccion con pk: ${pk}, actualizado con éxito`);
  temp.estado = DEFAULT_STATE.success;
  temp.mensaje = `Direccion actualizada con éxito`;
  temp.data = await setDireccion(data, serverDate);
  await setStorageDireccion(temp.data);
  temp.data_array = await setStorageDireccionArrayItem(temp.data.id, temp.data);
  return temp;
}

export const createDireccion = async (serverDate: IServerDate, idCliente : number, object : IDireccion) => {
  const temp = getEmptyStatusDireccion();
  const result = await post(`personas/direccion/${idCliente}`, getSerializableDireccion(object));
  const data = result.data;
  if (!data.id || data.detail) {
    console.log(`Error al crear Direccion con los datos ${object}, ${data.detail}`);
    temp.estado = DEFAULT_STATE.error;
    temp.mensaje = `Error al crear la direccion: ${data.detail ?? "Hubo un problema al conectarse con el servidor"}`;
    temp.data = await setDireccion(object, serverDate);
    return temp;
  }
  console.log(`Direccion creada con éxito`);
  temp.estado = DEFAULT_STATE.success;
  temp.mensaje = `Direccion creada con éxito`;
  temp.data = await setDireccion(data, serverDate);
  await setStorageDireccion(temp.data);
  temp.data_array = await setStorageDireccionArrayItem(temp.data.id, temp.data);
  return temp;
}

export const deleteDireccion = async (pk : number, idCliente : number) => {
  const temp = getEmptyStatusDireccion();
  const result = await del(`personas/direccion/${pk}/${idCliente}`);
  const data = result.data;
  if (data.detail) {
    console.log(`Error al eliminar Direccion con pk: ${pk}, ${data.detail}`);
    temp.estado = DEFAULT_STATE.error;
    temp.mensaje = `Error al eliminar la direccion: ${data.detail}`;
    temp.data = await getStorageDireccionArrayItem(pk);
    temp.data_array = await getStorageDireccionArray();
    return temp;
  }
  console.log(`Direccion eliminada con éxito`);
  temp.estado = DEFAULT_STATE.success;
  temp.mensaje = `Direccion eliminada con éxito`;
  await removeStorageDireccion();
  temp.data_array = await removeArrayItemStorageData("IDireccion", pk, []);
  return temp;
}
