/**
 * Métodos para el manejo de IonicStorage 
 * @setStorage              guarda un unico objeto al storage
 * @getStorage              obtiene el objeto guardado con @setStorage
 * @setStorageArray         guarda el array de objetos en el storage
 * @getStorageArray         obtiene el array de objetos guardado con @setStorageArray
 * @removeStorageArray      elimina el array de objetos guardado con @setStorageArray
 * @setStorageItemArray     agrega un elemento al array de objeto guardado con @setStorageArray
 * @getStorageItemArray     obtiene un elemento del array guardado con @setStorageArray buscandolo por su id 
 * @removeStorageItemArray  elimina un elemento del array guardado con @setStorageArray buscandolo por su id 
 */

import { setStorageData, getStorageData, removeStorageData, setArrayItemStorageData, getArrayItemStorageData, removeArrayItemStorageData } from "../../api/storage";
import { IAntiguedadEmpleado, getEmptyAntiguedadEmpleado } from "../../interfaces/personas";

export const setStorageAntiguedadEmpleado = async (IAntiguedadEmpleado : IAntiguedadEmpleado) => await setStorageData('IAntiguedadEmpleado', IAntiguedadEmpleado);
export const getStorageAntiguedadEmpleado = async () : Promise<IAntiguedadEmpleado> => await getStorageData('IAntiguedadEmpleado', getEmptyAntiguedadEmpleado());
export const removeStorageAntiguedadEmpleado = async () => await removeStorageData('IAntiguedadEmpleado');
export const setStorageAntiguedadEmpleadoArray = async (IAntiguedadEmpleado : IAntiguedadEmpleado[]) => await setStorageData('IAntiguedadEmpleadoArray', IAntiguedadEmpleado);
export const getStorageAntiguedadEmpleadoArray = async () : Promise<IAntiguedadEmpleado[]> => await getStorageData('IAntiguedadEmpleadoArray', [] as IAntiguedadEmpleado[]);
export const removeStorageAntiguedadEmpleadoArray = async () => await removeStorageData('IAntiguedadEmpleadoArray');
export const setStorageAntiguedadEmpleadoArrayItem = async (id: number, IAntiguedadEmpleado : IAntiguedadEmpleado) : Promise<IAntiguedadEmpleado[]> => await setArrayItemStorageData('IAntiguedadEmpleadoArray', id, IAntiguedadEmpleado, [] as IAntiguedadEmpleado[]);
export const getStorageAntiguedadEmpleadoArrayItem = async (id: number) : Promise<IAntiguedadEmpleado> => await getArrayItemStorageData('IAntiguedadEmpleadoArray', id, getEmptyAntiguedadEmpleado);
export const removeStorageAntiguedadEmpleadoArrayItem = async (id: number) : Promise<IAntiguedadEmpleado[]> => await removeArrayItemStorageData('IAntiguedadEmpleadoArray', id, [] as IAntiguedadEmpleado[]);
