/**
 * Métodos para el manejo de IonicStorage 
 * @setStorage              guarda un unico objeto al storage
 * @getStorage              obtiene el objeto guardado con @setStorage
 * @setStorageArray         guarda el array de objetos en el storage
 * @getStorageArray         obtiene el array de objetos guardado con @setStorageArray
 * @removeStorageArray      elimina el array de objetos guardado con @setStorageArray
 * @setStorageItemArray     agrega un elemento al array de objeto guardado con @setStorageArray
 * @getStorageItemArray     obtiene un elemento del array guardado con @setStorageArray buscandolo por su id 
 * @removeStorageItemArray  elimina un elemento del array guardado con @setStorageArray buscandolo por su id 
 */

import { setStorageData, getStorageData, removeStorageData, setArrayItemStorageData, getArrayItemStorageData, removeArrayItemStorageData } from "../../api/storage";
import { IMvMotivoNotificacion, getEmptyMvMotivoNotificacion } from "../../interfaces/nomina";

export const setStorageMvMotivoNotificacion = async (IMvMotivoNotificacion : IMvMotivoNotificacion) => await setStorageData('IMvMotivoNotificacion', IMvMotivoNotificacion);
export const getStorageMvMotivoNotificacion = async () : Promise<IMvMotivoNotificacion> => await getStorageData('IMvMotivoNotificacion', getEmptyMvMotivoNotificacion());
export const removeStorageMvMotivoNotificacion = async () => await removeStorageData('IMvMotivoNotificacion');
export const setStorageMvMotivoNotificacionArray = async (IMvMotivoNotificacion : IMvMotivoNotificacion[]) => await setStorageData('IMvMotivoNotificacionArray', IMvMotivoNotificacion);
export const getStorageMvMotivoNotificacionArray = async () : Promise<IMvMotivoNotificacion[]> => await getStorageData('IMvMotivoNotificacionArray', [] as IMvMotivoNotificacion[]);
export const removeStorageMvMotivoNotificacionArray = async () => await removeStorageData('IMvMotivoNotificacionArray');
export const setStorageMvMotivoNotificacionArrayItem = async (id: number, IMvMotivoNotificacion : IMvMotivoNotificacion) : Promise<IMvMotivoNotificacion[]> => await setArrayItemStorageData('IMvMotivoNotificacionArray', id, IMvMotivoNotificacion, [] as IMvMotivoNotificacion[]);
export const getStorageMvMotivoNotificacionArrayItem = async (id: number) : Promise<IMvMotivoNotificacion> => await getArrayItemStorageData('IMvMotivoNotificacionArray', id, getEmptyMvMotivoNotificacion);
export const removeStorageMvMotivoNotificacionArrayItem = async (id: number) : Promise<IMvMotivoNotificacion[]> => await removeArrayItemStorageData('IMvMotivoNotificacionArray', id, [] as IMvMotivoNotificacion[]);
