/**
 * Métodos AsyncThunk para ser usados en los reducers y
 * los resultados a las llamadas http puedan ser usados para
 * actualizar el state en redux
 */

import { createAsyncThunk } from "@reduxjs/toolkit";
import { DEFAULT_STATE } from "../../enums/default_state";
import { getModificacionSuscripcionQentry, getAllModificacionSuscripcionQentry, updateModificacionSuscripcionQentry, createModificacionSuscripcionQentry, deleteModificacionSuscripcionQentry } from "../../http/personas";
import { getEmptyStatusModificacionSuscripcionQentry, IModificacionSuscripcionQentry } from "../../interfaces/personas";
import { IServerDate } from "../../interfaces/time";

export const getModificacionSuscripcionQentryAsyncThunk = createAsyncThunk(
  'personas/getModificacionSuscripcionQentryAsyncThunk',
  async (query : {serverDate : IServerDate, id: number}) => await getModificacionSuscripcionQentry(query.serverDate, query.id)
    .then(async (result) => result)
    .catch(async (error) => getEmptyStatusModificacionSuscripcionQentry(DEFAULT_STATE.error))  
);

export const getAllModificacionSuscripcionQentryAsyncThunk = createAsyncThunk(
  'personas/getAllModificacionSuscripcionQentryAsyncThunk',
  async (queryAll : {serverDate : IServerDate}) => await getAllModificacionSuscripcionQentry(queryAll.serverDate)
    .then(async (result) => result)
    .catch(async (error) => getEmptyStatusModificacionSuscripcionQentry(DEFAULT_STATE.error))  
);

export const updateModificacionSuscripcionQentryAsyncThunk = createAsyncThunk(
  'personas/updateModificacionSuscripcionQentryAsyncThunk',
  async (update: {serverDate : IServerDate, id: number, ModificacionSuscripcionQentry: IModificacionSuscripcionQentry}) => await updateModificacionSuscripcionQentry(update.serverDate, update.id, update.ModificacionSuscripcionQentry)
    .then(async (result) => result)
    .catch(async (error) => getEmptyStatusModificacionSuscripcionQentry(DEFAULT_STATE.error))  
);

export const createModificacionSuscripcionQentryAsyncThunk = createAsyncThunk(
  'personas/createModificacionSuscripcionQentryAsyncThunk',
  async (create : {serverDate : IServerDate, ModificacionSuscripcionQentry: IModificacionSuscripcionQentry}) => await createModificacionSuscripcionQentry(create.serverDate, create.ModificacionSuscripcionQentry)
    .then(async (result) => result)
    .catch(async (error) => getEmptyStatusModificacionSuscripcionQentry(DEFAULT_STATE.error))  
);

export const deleteModificacionSuscripcionQentryAsyncThunk = createAsyncThunk(
  'personas/deleteModificacionSuscripcionQentryAsyncThunk',
  async (del : {id : number}) => await deleteModificacionSuscripcionQentry(del.id)
    .then(async (result) => result)
    .catch(async (error) => getEmptyStatusModificacionSuscripcionQentry())  
);
