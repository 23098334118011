/**
 * Métodos del, get, post, put para llamadas a la API
 * @get     en este método primero consulta con @getFromLocalStorage si es necesario hacer la petición http sino se obtiene del LocalStorage;
 *          si hay un error en la petición http se busca el objeto en el LocalStorage sino lo encuentra devuelve un objeto vacío; si la
 *          petición se realiza correctamente actualiza el valor individual y en el array del objeto en el LocalStorage y devuelve el objeto
 *          obtenido del servidor.
 * @getAll  en este método primero consulta con @getFromLocalStorage si es necesario hacer la petición http sino se obtiene del LocalStorage;
 *          si hay un error en la petición http se busca el objeto array en el LocalStorage sino lo encuentra devuelve un array vacío; si la
 *          petición se realiza correctamente actualiza array en el LocalStorage y devuelve el array obtenido del servidor
 * @update  en este método después de realizar la petición http si falla devuelve el objeto que se iba a actualizar; si la petición se
 *          realiza correctamente actualiza el valor individual y en el array del objeto en el LocalStorage y devuelve el objeto obtenido del
 *          servidor.
 * @create  en este método después de realizar la petición http si falla devuelve el objeto que se iba a crear; si la petición se realiza
 *          correctamente actualiza el valor individual y en el array del objeto en el LocalStorage y devuelve el objeto obtenido del
 *          servidor.
 * @delete  en este método después de realizar la petición http si falla devuelve el objeto que se iba a eliminar; si la petición se realiza
 *          correctamente elimina el valor individual y en el objeto en el arrya del LocalStorage y devuelve un objeto vacio (Default)
 */

import { del, get, post, put } from "../../api/api";
import {
  getFromLocalStorage,
  updateLocalStorageExpiry,
} from "../../api/helpers";
import { removeArrayItemStorageData } from "../../api/storage";
import { DEFAULT_STATE } from "../../enums/default_state";
import {
  getEmptyStatusCanalVenta,
  setCanalVenta,
  ICanalVenta,
  getSerializableCanalVenta,
} from "../../interfaces/personas";
import { IServerDate } from "../../interfaces/time";
import {
  getStorageCanalVentaArrayItem,
  setStorageCanalVenta,
  setStorageCanalVentaArrayItem,
  getStorageCanalVentaArray,
  setStorageCanalVentaArray,
  removeStorageCanalVenta,
} from "../../localStorage/personas";

export const getCanalVenta = async (serverDate: IServerDate, pk: number) => {
  const temp = getEmptyStatusCanalVenta();
  const doHttp = await getFromLocalStorage(serverDate, temp.expiry);
  temp.expiry = doHttp.storage;
  if (!doHttp.result) {
    console.log(`(localStorage) CanalVenta con pk: ${pk}, obtenido con éxito`);
    temp.estado = DEFAULT_STATE.success;
    temp.mensaje = `CanalVenta obtenido con éxito`;
    temp.data = await getStorageCanalVentaArrayItem(pk);
    return temp;
  }
  const result = await get(`personas/canal-venta/${pk}`);
  const data = result.data;
  console.log(`CanalVenta data: `, data);
  if (!data.id || data.detail) {
    console.log(`Error al obtener CanalVenta con pk: ${pk}, ${data.detail}`);
    temp.estado = DEFAULT_STATE.error;
    temp.mensaje = `Error al obtener el canal venta: ${
      data.detail ?? "Hubo un problema al conectarse con el servidor"
    }`;
    temp.data = await getStorageCanalVentaArrayItem(pk);
    return temp;
  }
  console.log(`CanalVenta con pk: ${pk}, obtenido con éxito`);
  temp.estado = DEFAULT_STATE.success;
  temp.mensaje = `CanalVenta obtenido con éxito`;
  temp.data = await setCanalVenta(data, serverDate);
  await setStorageCanalVenta(temp.data);
  temp.data_array = await setStorageCanalVentaArrayItem(pk, temp.data);
  temp.expiry = await updateLocalStorageExpiry(serverDate, temp.expiry);
  return temp;
};

export const getAllCanalVenta = async (serverDate: IServerDate) => {
  const temp = getEmptyStatusCanalVenta();
  const doHttp = await getFromLocalStorage(serverDate, temp.expiry_array);
  temp.expiry_array = doHttp.storage;
  if (!doHttp.result) {
    console.log(`(localStorage) Todos los CanalVentas obtenidos con éxito`);
    temp.estado = DEFAULT_STATE.success;
    temp.mensaje = `Todos los canal ventas obtenidos con éxito`;
    temp.data_array = await getStorageCanalVentaArray();
    return temp;
  }
  const result = await get(`personas/canal-venta`);
  const data = result.data;
  if (data.detail) {
    console.log(`Error al obtener la lista de CanalVenta, ${data.detail}`);
    temp.estado = DEFAULT_STATE.error;
    temp.mensaje = `Error al obtener la lista de canal ventas: ${
      data.detail ?? "Hubo un problema al conectarse con el servidor"
    }`;
    temp.data_array = await getStorageCanalVentaArray();
    return temp;
  }
  console.log(`Todos los CanalVentaes obtenidos con éxito`);
  temp.estado = DEFAULT_STATE.success;
  temp.mensaje = `Todos los canal ventas obtenidos con éxito`;
  temp.data_array = await Promise.all(
    data.map(async (e: any) => await setCanalVenta(e, serverDate))
  );
  await setStorageCanalVentaArray(temp.data_array);
  temp.expiry_array = await updateLocalStorageExpiry(
    serverDate,
    temp.expiry_array
  );
  return temp;
};

export const updateCanalVenta = async (
  serverDate: IServerDate,
  pk: number,
  object: ICanalVenta
) => {
  const temp = getEmptyStatusCanalVenta();
  const result = await put(
    `personas/canal-venta/${pk}`,
    getSerializableCanalVenta(object)
  );
  const data = result.data;
  if (!data.id || data.detail) {
    console.log(`Error al actualizar CanalVenta con pk: ${pk}, ${data.detail}`);
    temp.estado = DEFAULT_STATE.error;
    temp.mensaje = `Error al actualizar el canal venta: ${
      data.detail ?? "Hubo un problema al conectarse con el servidor"
    }`;
    temp.data = await setCanalVenta(object, serverDate);
    return temp;
  }
  console.log(`CanalVenta con pk: ${pk}, actualizado con éxito`);
  temp.estado = DEFAULT_STATE.success;
  temp.mensaje = `CanalVenta actualizado con éxito`;
  temp.data = await setCanalVenta(data, serverDate);
  await setStorageCanalVenta(temp.data);
  temp.data_array = await setStorageCanalVentaArrayItem(
    temp.data.id,
    temp.data
  );
  return temp;
};

export const createCanalVenta = async (
  serverDate: IServerDate,
  object: ICanalVenta
) => {
  const temp = getEmptyStatusCanalVenta();
  const result = await post(
    `personas/canal-venta`,
    getSerializableCanalVenta(object)
  );
  const data = result.data;
  if (!data.id || data.detail) {
    console.log(
      `Error al crear CanalVenta con los datos ${object}, ${data.detail}`
    );
    temp.estado = DEFAULT_STATE.error;
    temp.mensaje = `Error al crear el canal venta: ${
      data.detail ?? "Hubo un problema al conectarse con el servidor"
    }`;
    temp.data = await setCanalVenta(object, serverDate);
    return temp;
  }
  console.log(`CanalVenta creado con éxito`);
  temp.estado = DEFAULT_STATE.success;
  temp.mensaje = `CanalVenta creado con éxito`;
  temp.data = await setCanalVenta(data, serverDate);
  await setStorageCanalVenta(temp.data);
  temp.data_array = await setStorageCanalVentaArrayItem(
    temp.data.id,
    temp.data
  );
  return temp;
};

export const deleteCanalVenta = async (pk: number) => {
  const temp = getEmptyStatusCanalVenta();
  const result = await del(`personas/canal-venta/${pk}`);
  const data = result.data;
  if (data.detail) {
    console.log(`Error al eliminar CanalVenta con pk: ${pk}, ${data.detail}`);
    temp.estado = DEFAULT_STATE.error;
    temp.mensaje = `Error al eliminar el canal venta: ${data.detail}`;
    temp.data = await getStorageCanalVentaArrayItem(pk);
    temp.data_array = await getStorageCanalVentaArray();
    return temp;
  }
  console.log(`CanalVenta eliminado con éxito`);
  temp.estado = DEFAULT_STATE.success;
  temp.mensaje = `CanalVenta eliminado con éxito`;
  await removeStorageCanalVenta();
  temp.data_array = await removeArrayItemStorageData("ICanalVenta", pk, []);
  return temp;
};
