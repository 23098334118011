import { getArrayItemStorageData, getStorageData, removeArrayItemStorageData, removeStorageData, setArrayItemStorageData, setStorageData } from "../../api/storage";
import { getEmptyClienteQentry, IClienteQentry } from "../../interfaces/personas";

export const setStorageClienteQentry = async (iClienteQentry : IClienteQentry) => await setStorageData('IClienteQentry', iClienteQentry);
export const getStorageClienteQentry = async () : Promise<IClienteQentry> => await getStorageData('IClienteQentry', getEmptyClienteQentry());
export const removeStorageClienteQentry = async () => await removeStorageData('IClienteQentry');
export const setStorageClienteQentryArray = async (IClienteQentry : IClienteQentry[]) => await setStorageData('IClienteQentryArray', IClienteQentry);
export const getStorageClienteQentryArray = async () : Promise<IClienteQentry[]> => await getStorageData('IClienteQentryArray', [] as IClienteQentry[]);
export const removeStorageClienteQentryArray = async () => await removeStorageData('IClienteQentryArray');
export const setStorageClienteQentryArrayItem = async (id: number, IClienteQentry : IClienteQentry) : Promise<IClienteQentry[]> => await setArrayItemStorageData('IClienteQentryArray', id, IClienteQentry, [] as IClienteQentry[]);
export const getStorageClienteQentryArrayItem = async (id: number) : Promise<IClienteQentry> => await getArrayItemStorageData('IClienteQentryArray', id, getEmptyClienteQentry);
export const removeStorageClienteQentryArrayItem = async (id: number) : Promise<IClienteQentry[]> => await removeArrayItemStorageData('IClienteQentryArray', id, [] as IClienteQentry[]);
