/**
 * Métodos para el manejo de IonicStorage 
 * @setStorage              guarda un unico objeto al storage
 * @getStorage              obtiene el objeto guardado con @setStorage
 * @setStorageArray         guarda el array de objetos en el storage
 * @getStorageArray         obtiene el array de objetos guardado con @setStorageArray
 * @removeStorageArray      elimina el array de objetos guardado con @setStorageArray
 * @setStorageItemArray     agrega un elemento al array de objeto guardado con @setStorageArray
 * @getStorageItemArray     obtiene un elemento del array guardado con @setStorageArray buscandolo por su id 
 * @removeStorageItemArray  elimina un elemento del array guardado con @setStorageArray buscandolo por su id 
 */

import { setStorageData, getStorageData, removeStorageData, setArrayItemStorageData, getArrayItemStorageData, removeArrayItemStorageData } from "../../api/storage";
import { IMvEstadoCivil, getEmptyMvEstadoCivil } from "../../interfaces/personas";

export const setStorageMvEstadoCivil = async (IMvEstadoCivil : IMvEstadoCivil) => await setStorageData('IMvEstadoCivil', IMvEstadoCivil);
export const getStorageMvEstadoCivil = async () : Promise<IMvEstadoCivil> => await getStorageData('IMvEstadoCivil', getEmptyMvEstadoCivil());
export const removeStorageMvEstadoCivil = async () => await removeStorageData('IMvEstadoCivil');
export const setStorageMvEstadoCivilArray = async (IMvEstadoCivil : IMvEstadoCivil[]) => await setStorageData('IMvEstadoCivilArray', IMvEstadoCivil);
export const getStorageMvEstadoCivilArray = async () : Promise<IMvEstadoCivil[]> => await getStorageData('IMvEstadoCivilArray', [] as IMvEstadoCivil[]);
export const removeStorageMvEstadoCivilArray = async () => await removeStorageData('IMvEstadoCivilArray');
export const setStorageMvEstadoCivilArrayItem = async (id: number, IMvEstadoCivil : IMvEstadoCivil) : Promise<IMvEstadoCivil[]> => await setArrayItemStorageData('IMvEstadoCivilArray', id, IMvEstadoCivil, [] as IMvEstadoCivil[]);
export const getStorageMvEstadoCivilArrayItem = async (id: number) : Promise<IMvEstadoCivil> => await getArrayItemStorageData('IMvEstadoCivilArray', id, getEmptyMvEstadoCivil);
export const removeStorageMvEstadoCivilArrayItem = async (id: number) : Promise<IMvEstadoCivil[]> => await removeArrayItemStorageData('IMvEstadoCivilArray', id, [] as IMvEstadoCivil[]);
