/**
 * Reducers creados para obtener asíncronamente 
 * los datos de los modelos y dependiendo del resultado
 * de la peticón actualice el state en redux o mande un mensaje de error
 */

import { PayloadAction } from "@reduxjs/toolkit";
import { getUserAsyncThunk, getAllUserAsyncThunk, updateUserAsyncThunk, createUserAsyncThunk, deleteUserAsyncThunk } from "../../asyncThunk/personas";
import { DEFAULT_STATE } from "../../enums/default_state";
import { IStatusUser } from "../../interfaces/personas";
import { personasInitialState } from "../../slices/personasSlice";

// Reducers: User
export const createUserReducers = () => {
  const get = () => {
    var { pending, fulfilled, rejected } = getUserAsyncThunk;
    return {
      [pending.type]: (state : typeof personasInitialState) => {
        state.personasState.user.estado = DEFAULT_STATE.loading;
      },
      [fulfilled.type]: (state : typeof personasInitialState, action : PayloadAction<IStatusUser>) => {
        const result = action.payload;
        result.expiry_array = state.personasState.user.expiry_array;
        if (result.estado === DEFAULT_STATE.error) result.data_array = state.personasState.user.data_array;
        state.personasState.user = result;
      },
      [rejected.type]: (state : typeof personasInitialState, action : PayloadAction<IStatusUser>) => {
        state.personasState.user = {
          ...state.personasState.user,
          estado: action.payload.estado,
          mensaje: "Ocurrió un problema al realizar la solicitud",
        };
      },
    };
  };
  
  const getAll = () => {
    var { pending, fulfilled, rejected } = getAllUserAsyncThunk;
    return {
      [pending.type]: (state : typeof personasInitialState) => {
        state.personasState.user.estado = DEFAULT_STATE.loading;
      },
      [fulfilled.type]: (state : typeof personasInitialState, action : PayloadAction<IStatusUser>) => {
        const result = action.payload;
        result.data = state.personasState.user.data;
        result.expiry = state.personasState.user.expiry;
        if (result.estado === DEFAULT_STATE.error) result.data_array = state.personasState.user.data_array;
        state.personasState.user = result;
      },
      [rejected.type]: (state : typeof personasInitialState, action : PayloadAction<IStatusUser>) => {
        state.personasState.user = {
          ...state.personasState.user,
          estado: action.payload.estado,
          mensaje: "Ocurrió un problema al realizar la solicitud",
        };
      },
    };
  };
  
  const update = () => {
    var { pending, fulfilled, rejected } = updateUserAsyncThunk;
    return {
      [pending.type]: (state : typeof personasInitialState) => {
        state.personasState.user.estado = DEFAULT_STATE.loading;
      },
      [fulfilled.type]: (state : typeof personasInitialState, action : PayloadAction<IStatusUser>) => {
        const result: IStatusUser = action.payload;
        result.expiry = state.personasState.user.expiry;
        result.expiry_array = state.personasState.user.expiry_array;
        if (result.estado === DEFAULT_STATE.error) result.data_array = state.personasState.user.data_array;
        state.personasState.user = result;
      },
      [rejected.type]: (state : typeof personasInitialState, action : PayloadAction<IStatusUser>) => {
        state.personasState.user = {
          ...state.personasState.user,
          estado: action.payload.estado,
          mensaje: "Ocurrió un problema al realizar la solicitud",
        };
      },
    };
  };

  const create = () => {
    var { pending, fulfilled, rejected } = createUserAsyncThunk;
    return {
      [pending.type]: (state : typeof personasInitialState) => {
        state.personasState.user.estado = DEFAULT_STATE.loading;
      },
      [fulfilled.type]: (state : typeof personasInitialState, action : PayloadAction<IStatusUser>) => {
        const result = action.payload;
        result.expiry = state.personasState.user.expiry;
        result.expiry_array = state.personasState.user.expiry_array;
        if (result.estado === DEFAULT_STATE.error) result.data_array = state.personasState.user.data_array;
        state.personasState.user = result;
      },
      [rejected.type]: (state : typeof personasInitialState, action : PayloadAction<IStatusUser>) => {
        state.personasState.user = {
          ...state.personasState.user,
          estado: action.payload.estado,
          mensaje: "Ocurrió un problema al realizar la solicitud",
        };
      },
    };
  };
  
  const del = () => {
    var { pending, fulfilled, rejected } = deleteUserAsyncThunk;
    return {
      [pending.type]: (state : typeof personasInitialState) => {
        state.personasState.user.estado = DEFAULT_STATE.loading;
      },
      [fulfilled.type]: (state : typeof personasInitialState, action : PayloadAction<IStatusUser>) => {
        const result: IStatusUser = action.payload;
        result.expiry = state.personasState.user.expiry;
        result.expiry_array = state.personasState.user.expiry_array;
        if (result.estado === DEFAULT_STATE.error) result.data_array = state.personasState.user.data_array;
        state.personasState.user = result;
      },
      [rejected.type]: (state : typeof personasInitialState, action : PayloadAction<IStatusUser>) => {
        state.personasState.user = {
          ...state.personasState.user,
          estado: action.payload.estado,
          mensaje: "Ocurrió un problema al realizar la solicitud",
        };
      },
    };
  };

  return {
    ...get(),
    ...getAll(),
    ...update(),
    ...create(),
    ...del(),
  };
};
