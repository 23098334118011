/**
 * Metódos de ayuda para el formatio de datos y fechas [Moment Timezone] "https://momentjs.com/timezone/"
 * @function isNotEmpty verifa que el varios recibido no esté vacío
 * @function formatDate devuelve la fecha en formato "YYYY-MM-DD"
 * @function formatTime devuelve la fecha en formato "HH:mm:ss"
 * @function addDaysToDate devuelve una fecha con N días agregados
 * @function getFormattedDaysAdded devuelve la fuecha retornada en @function addDaysToDate en formato "YYYY-MM-DD"
 * @function getDefaultTimeZone obtiene la zona horaria del servidor
 * @function getFormattedDateString con una fecha con zona horaria la convierte a la zona horaria que desee y la devuelkve en formato "YYYY-MM-DD"
 * @function getTimeString con una hora con zona horaria la convierte en otra zona horaria y la devuelve en formato "HH:mm:ss"
 * @function getDjangoTimeString convierte la hora en un formato requerido para la actualización de @IHorario e @ITurno
 * @function getDateString devuelve la checa en el formato "YYYY-MM-DD HH:mm:ss Z" para su visualización en el historial de asistencias y rondines
 * @function getOnlyDateString devuelve la fecha en formato "HH:mm:ss" algo similar a @function formatDate pero con manejo de zona horaria
 * @function getReportsDateString devuelve la fecha en formato "YYYY-MM-DD 00:00:00" para las peticiones de los reportes
 * @function getDate devuelve on objeto tipo date de ka fecha actual o con una zona horaria diferente
 * @function getFromLocalStorage verifica que sea el momento de realizar la petición dependiendo del tiempo de expiración de los datos
 * @function updateLocalStorageExpiry actualiza la fecha de la ultima vez que se realizó de la petición
 * @function sleep hace esperar un tiempo en milisegundos
 */

import moment from "moment-timezone";
import {
  getStorageLocalStorageExpiry,
  setStorageLocalStorageExpiry,
} from "../localStorage/time/LocalStorageExpiry";
import { ILocalStorageExpiry, IServerDate } from "../interfaces/time";
import { IEmpleado } from "../interfaces/personas";

export const SERVER_TIME_ZONE = process.env.REACT_APP_SERVER_TIME_ZONE;

export const isNotEmpty = (value: any) => {
  const type = Object.prototype.toString.call(value);
  return (
    value !== null &&
    value !== undefined &&
    (type === "[object Object]"
      ? Object.keys(value).length > 0
      : type === "[object Number]" ||
        type === "[object Boolean]" ||
        value.length > 0)
  );
};

export const formatDate = (value: string | Date) => {
  const date = new Date(value);
  const year = date.getFullYear().toString();
  let month = (date.getMonth() + 1).toString();
  let day = date.getDate().toString();

  if (parseInt(month) < 10) month = `0${month}`;
  if (parseInt(day) < 10) day = `0${day}`;

  return `${year}-${month}-${day}`;
};

export const formatTime = (value: string | Date) => {
  const date = new Date(value);
  let hours = date.getHours().toString();
  let minutes = date.getMinutes().toString();
  let seconds = date.getSeconds().toString();

  if (parseInt(hours) < 10) hours = `0${hours}`;
  if (parseInt(minutes) < 10) minutes = `0${minutes}`;
  if (parseInt(seconds) < 10) seconds = `0${seconds}`;

  return `${hours}:${minutes}:${seconds}`;
};

export const addDaysToDate = (value: Date, daysToAdd: number) => {
  const change = new Date(value);
  change.setDate(change.getDate() + daysToAdd);
  return change;
};
export const getFormattedDaysAdded = (
  startDate: string,
  zonaHoraria: string,
  daysToAdd: number
) =>
  formatDate(
    addDaysToDate(moment(startDate).tz(zonaHoraria).toDate(), daysToAdd)
  );

export const getDefaultTimeZone = () =>
  SERVER_TIME_ZONE ?? Intl.DateTimeFormat().resolvedOptions().timeZone;

export const getFormattedDateString = (zonaHoraria?: string, date?: string) =>
  formatDate(
    moment(date)
      .tz(zonaHoraria ? zonaHoraria : getDefaultTimeZone())
      .toDate()
  );

export const getTimeString = (zonaHoraria: string, time: string) => {
  const t = time.includes("T") ? time.split("T")[1] : time;
  return moment(t, "HH:mm:ss")
    .tz(zonaHoraria ? zonaHoraria : getDefaultTimeZone())
    .format("HH:mm:ss");
};

export const getDjangoTimeString = (time: string, zonaHoraria?: string) =>
  moment(time, "HH:mm:ss")
    .tz(zonaHoraria ? zonaHoraria : getDefaultTimeZone())
    .format("HH:mm:ss.000000");

export const getDateString = (zonaHoraria?: string, date?: string) =>
  moment(date)
    .tz(zonaHoraria ? zonaHoraria : getDefaultTimeZone())
    .format("YYYY-MM-DD HH:mm:ss Z");

export const getOnlyDateString = (date?: string) =>
  moment(date).format("YYYY-MM-DD");

export const getReportsDateString = (date?: string) =>
  moment(date).format("YYYY-MM-DD 00:00:00");

export const getDate = (zonaHoraria?: string) =>
  moment()
    .tz(zonaHoraria ? zonaHoraria : getDefaultTimeZone())
    .toDate();

export const getFromLocalStorage = async (
  serverDate: IServerDate,
  expiry: ILocalStorageExpiry
) => {
  const storage = await getStorageLocalStorageExpiry(
    expiry.interface_name,
    expiry.update_in_days,
    serverDate
  );
  storage.update_in_days = expiry.update_in_days;
  if (!storage.last_update) {
    return { result: true, storage };
  }

  return {
    result:
      storage.today <
      getFormattedDaysAdded(
        storage.last_update,
        serverDate.zona_horaria,
        storage.update_in_days
      ),
    storage,
  };
};

export const updateLocalStorageExpiry = async (
  serverDate: IServerDate,
  expiry: ILocalStorageExpiry
) => {
  if (!expiry.last_update) {
    expiry.last_update = getFormattedDaysAdded(
      expiry.today,
      serverDate.zona_horaria,
      0
    );
    await setStorageLocalStorageExpiry(expiry.interface_name, expiry);
    return expiry;
  }
  expiry.last_update = getFormattedDaysAdded(
    expiry.last_update,
    serverDate.zona_horaria,
    expiry.update_in_days
  );
  await setStorageLocalStorageExpiry(expiry.interface_name, expiry);
  return expiry;
};

export function sleep(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export const getFullName = (e: IEmpleado) => {
  return `${e.datos_personales.primer_nombre} ${
    e.datos_personales.segundo_nombre
      ? e.datos_personales.segundo_nombre + " "
      : " "
  }${e.datos_personales.apellido_paterno} ${
    e.datos_personales.apellido_materno
  }`;
};

export const toDegreesMinutesAndSeconds = (coordinate: any) => {
  var absolute = Math.abs(coordinate);
  var degrees = Math.floor(absolute);
  var minutesNotTruncated = (absolute - degrees) * 60;
  var minutes = Math.floor(minutesNotTruncated);
  var seconds = Math.floor((minutesNotTruncated - minutes) * 60);

  return degrees + "°" + minutes + "'" + seconds + '"';
};

export const convertDMS = (lat: any, lng: any) => {
  var latitude = toDegreesMinutesAndSeconds(lat);
  var latitudeCardinal = lat >= 0 ? "N" : "S";

  var longitude = toDegreesMinutesAndSeconds(lng);
  var longitudeCardinal = lng >= 0 ? "E" : "W";

  return (
    latitude +
    " " +
    latitudeCardinal +
    "+" +
    longitude +
    " " +
    longitudeCardinal
  );
};

export const convertDMSForSearch = (lat: any, lng: any) => {
  var latitude = toDegreesMinutesAndSeconds(lat);
  var latitudeCardinal = lat >= 0 ? "N" : "S";

  var longitude = toDegreesMinutesAndSeconds(lng);
  var longitudeCardinal = lng >= 0 ? "E" : "W";

  return (
    latitude +
    " " +
    latitudeCardinal +
    " " +
    longitude +
    " " +
    longitudeCardinal
  );
};
