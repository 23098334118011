/**
 * Métodos AsyncThunk para ser usados en los reducers y
 * los resultados a las llamadas http puedan ser usados para
 * actualizar el state en redux
 */

import { createAsyncThunk } from "@reduxjs/toolkit";
import { DEFAULT_STATE } from "../../enums/default_state";
import { getPuesto, getAllPuesto, updatePuesto, createPuesto, deletePuesto } from "../../http/nomina";
import { getEmptyStatusPuesto, IPuesto } from "../../interfaces/nomina";
import { IServerDate } from "../../interfaces/time";

export const getPuestoAsyncThunk = createAsyncThunk(
  'nomina/getPuestoAsyncThunk',
  async (query : {serverDate : IServerDate, id: number, idCliente : number}) => await getPuesto(query.serverDate, query.id, query.idCliente)
    .then(async (result) => result)
    .catch(async (error) => getEmptyStatusPuesto(DEFAULT_STATE.error))  
);

export const getAllPuestoAsyncThunk = createAsyncThunk(
  'nomina/getAllPuestoAsyncThunk',
  async (queryAll : {serverDate : IServerDate, idCliente : number}) => await getAllPuesto(queryAll.serverDate, queryAll.idCliente)
    .then(async (result) => result)
    .catch(async (error) => getEmptyStatusPuesto(DEFAULT_STATE.error))  
);

export const updatePuestoAsyncThunk = createAsyncThunk(
  'nomina/updatePuestoAsyncThunk',
  async (update: {serverDate : IServerDate, id: number, idCliente : number, object: IPuesto}) => await updatePuesto(update.serverDate, update.id, update.idCliente, update.object)
    .then(async (result) => result)
    .catch(async (error) => getEmptyStatusPuesto(DEFAULT_STATE.error))  
);

export const createPuestoAsyncThunk = createAsyncThunk(
  'nomina/createPuestoAsyncThunk',
  async (create : {serverDate : IServerDate, idCliente : number, object: IPuesto}) => await createPuesto(create.serverDate, create.idCliente, create.object)
    .then(async (result) => result)
    .catch(async (error) => getEmptyStatusPuesto(DEFAULT_STATE.error))  
);

export const deletePuestoAsyncThunk = createAsyncThunk(
  'nomina/deletePuestoAsyncThunk',
  async (del : {id : number, idCliente : number}) => await deletePuesto(del.id, del.idCliente)
    .then(async (result) => result)
    .catch(async (error) => getEmptyStatusPuesto())  
);
