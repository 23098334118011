/** 
 * Componente que se encarga del elemento MvEstatusSolicitudCambioTelefonoAutorizado
 * @IMvEstatusSolicitudCambioTelefonoAutorizado es la interfaz que contiene los parámetos el elemento MvEstatusSolicitudCambioTelefonoAutorizado
 * @getEmptyMvEstatusSolicitudCambioTelefonoAutorizado  devuelve un objeto con los parámetros por defecto; @param {id}, opcional cuando no encuentra el objeto en el LocalSotrage
 * @IStatusMvEstatusSolicitudCambioTelefonoAutorizado es la interfaz para los estatus a llamadas a la API; @param {status}, opcional cuando queremos otro estado de inizialización de tipo [DEFAULT_STATE]
 * @getEmptyStatusMvEstatusSolicitudCambioTelefonoAutorizado devuelve un objeto con los parámetros por defecto,
 * @setMvEstatusSolicitudCambioTelefonoAutorizado devuelve un objeto de tipo [IMvEstatusSolicitudCambioTelefonoAutorizado] recibiendo @param {object} y @param {serverDate}, para verificar que los datos estén en el formato correcto y ajustarlos a la zona horaria actual
 * @getSerializableMvEstatusSolicitudCambioTelefonoAutorizado recibe un @param {IMvEstatusSolicitudCambioTelefonoAutorizado}, para devolver un objeto serizalizable para las peticiones de la API
 * */

import { getDateString, isNotEmpty, SERVER_TIME_ZONE } from "../../api/helpers";
import { DEFAULT_STATE } from "../../enums/default_state";
import { getStoragePaisArrayItem } from "../../localStorage/personas";
import { ILocalStorageExpiry, getEmptyLocalStorageExpiry, IServerDate, convertDateToZonaHoraria } from "../time";
import { IPais, getEmptyPais, setPais } from "./IPais";

export interface IMvEstatusSolicitudCambioTelefonoAutorizado {
  id : number,
  dato : string,
  fecha_elaboracion : string, // Fecha
  estatus_sistema : boolean,
  ultima_actualizacion : string, // Fecha
  pais : IPais,
}

export const getEmptyMvEstatusSolicitudCambioTelefonoAutorizado = (id? : number) => {
  const temp : IMvEstatusSolicitudCambioTelefonoAutorizado = 
  {
    id : id ? id : 0,
    dato : "",
    fecha_elaboracion : "",
    estatus_sistema : false,
    ultima_actualizacion : "",
    pais : getEmptyPais(),
  };
  return temp;
}

export interface IStatusMvEstatusSolicitudCambioTelefonoAutorizado {
  estado : DEFAULT_STATE,
  mensaje : string,
  data : IMvEstatusSolicitudCambioTelefonoAutorizado,
  data_array: IMvEstatusSolicitudCambioTelefonoAutorizado[],
  expiry : ILocalStorageExpiry,
  expiry_array : ILocalStorageExpiry,
}

export const getEmptyStatusMvEstatusSolicitudCambioTelefonoAutorizado = (status: DEFAULT_STATE = DEFAULT_STATE.loading) => {
  const temp : IStatusMvEstatusSolicitudCambioTelefonoAutorizado = 
  {
    estado : status,
    mensaje : "",
    data : getEmptyMvEstatusSolicitudCambioTelefonoAutorizado(),
    data_array : [],
    expiry : getEmptyLocalStorageExpiry("IMvEstatusSolicitudCambioTelefonoAutorizado", 0),
    expiry_array : getEmptyLocalStorageExpiry("IMvEstatusSolicitudCambioTelefonoAutorizadoArray", 0),
  };
  return temp;
}

export const setMvEstatusSolicitudCambioTelefonoAutorizado = async (
  object : any,
  serverDate : IServerDate,
) => {
  const temp = getEmptyMvEstatusSolicitudCambioTelefonoAutorizado();
  if (isNotEmpty(object.id)) temp.id = object.id;
  if (isNotEmpty(object.dato)) temp.dato = object.dato;
  if (isNotEmpty(object.fecha_elaboracion)) temp.fecha_elaboracion = convertDateToZonaHoraria(serverDate, object.fecha_elaboracion);
  if (isNotEmpty(object.estatus_sistema)) temp.estatus_sistema = object.estatus_sistema;
  if (isNotEmpty(object.ultima_actualizacion)) temp.ultima_actualizacion = convertDateToZonaHoraria(serverDate, object.ultima_actualizacion);
  if (isNotEmpty(object.pais)) temp.pais = (typeof object.pais === 'number') ? await getStoragePaisArrayItem(object.pais) : await setPais(object.pais, serverDate);
  return temp;
}

export const getSerializableMvEstatusSolicitudCambioTelefonoAutorizado = (IMvEstatusSolicitudCambioTelefonoAutorizado : IMvEstatusSolicitudCambioTelefonoAutorizado) => {
  return {
    id : IMvEstatusSolicitudCambioTelefonoAutorizado.id,
    dato : IMvEstatusSolicitudCambioTelefonoAutorizado.dato,
    fecha_elaboracion : IMvEstatusSolicitudCambioTelefonoAutorizado.fecha_elaboracion,
    estatus_sistema : IMvEstatusSolicitudCambioTelefonoAutorizado.estatus_sistema,
    ultima_actualizacion : getDateString(SERVER_TIME_ZONE),
    pais : IMvEstatusSolicitudCambioTelefonoAutorizado.pais.id > 0 ? IMvEstatusSolicitudCambioTelefonoAutorizado.pais.id : null,
  };
}
