/** 
 * Componente que se encarga del elemento MvMotivoPagoSuscripcion
 * @IMvMotivoPagoSuscripcion es la interfaz que contiene los parámetos el elemento MvMotivoPagoSuscripcion
 * @getEmptyMvMotivoPagoSuscripcion  devuelve un objeto con los parámetros por defecto; @param {id}, opcional cuando no encuentra el objeto en el LocalSotrage
 * @IStatusMvMotivoPagoSuscripcion es la interfaz para los estatus a llamadas a la API; @param {status}, opcional cuando queremos otro estado de inizialización de tipo [DEFAULT_STATE]
 * @getEmptyStatusMvMotivoPagoSuscripcion devuelve un objeto con los parámetros por defecto,
 * @setMvMotivoPagoSuscripcion devuelve un objeto de tipo [IMvMotivoPagoSuscripcion] recibiendo @param {object} y @param {serverDate}, para verificar que los datos estén en el formato correcto y ajustarlos a la zona horaria actual
 * @getSerializableMvMotivoPagoSuscripcion recibe un @param {IMvMotivoPagoSuscripcion}, para devolver un objeto serizalizable para las peticiones de la API
 * */

import { getDateString, isNotEmpty, SERVER_TIME_ZONE } from "../../api/helpers";
import { DEFAULT_STATE } from "../../enums/default_state";
import { convertDateToZonaHoraria, IServerDate } from "../time";
import { getEmptyLocalStorageExpiry, ILocalStorageExpiry } from "../time/ILocalStorageExpiry";

export interface IMvMotivoPagoSuscripcion {
  id : number,
  dato : string,
  fecha_elaboracion : string, // Fecha
  estatus_sistema : boolean,
  ultima_actualizacion : string, // Fecha
}

export const getEmptyMvMotivoPagoSuscripcion = (id? : number) => {
  const temp : IMvMotivoPagoSuscripcion = 
  {
    id : id ? id : 0,
    dato : "",
    fecha_elaboracion : "",
    estatus_sistema : false,
    ultima_actualizacion : "",
  };
  return temp;
}

export interface IStatusMvMotivoPagoSuscripcion {
  estado : DEFAULT_STATE,
  mensaje : string,
  data : IMvMotivoPagoSuscripcion,
  data_array: IMvMotivoPagoSuscripcion[],
  expiry : ILocalStorageExpiry,
  expiry_array : ILocalStorageExpiry,
}

export const getEmptyStatusMvMotivoPagoSuscripcion = (status: DEFAULT_STATE = DEFAULT_STATE.loading) => {
  const temp : IStatusMvMotivoPagoSuscripcion = 
  {
    estado : status,
    mensaje : "",
    data : getEmptyMvMotivoPagoSuscripcion(),
    data_array : [],
    expiry : getEmptyLocalStorageExpiry("IMvMotivoPagoSuscripcion", 0),
    expiry_array : getEmptyLocalStorageExpiry("IMvMotivoPagoSuscripcionArray", 0),
  };
  return temp;
}

export const setMvMotivoPagoSuscripcion = async (
  object : any,
  serverDate : IServerDate,
) => {
  const temp = getEmptyMvMotivoPagoSuscripcion();
  if (isNotEmpty(object.id)) temp.id = object.id;
  if (isNotEmpty(object.dato)) temp.dato = object.dato;
  if (isNotEmpty(object.fecha_elaboracion)) temp.fecha_elaboracion = convertDateToZonaHoraria(serverDate, object.fecha_elaboracion);
  if (isNotEmpty(object.estatus_sistema)) temp.estatus_sistema = object.estatus_sistema;
  if (isNotEmpty(object.ultima_actualizacion)) temp.ultima_actualizacion = convertDateToZonaHoraria(serverDate, object.ultima_actualizacion);
  return temp;
}

export const getSerializableMvMotivoPagoSuscripcion = (IMvMotivoPagoSuscripcion : IMvMotivoPagoSuscripcion) => {
  return {
    id : IMvMotivoPagoSuscripcion.id,
    dato : IMvMotivoPagoSuscripcion.dato,
    fecha_elaboracion : IMvMotivoPagoSuscripcion.fecha_elaboracion,
    estatus_sistema : IMvMotivoPagoSuscripcion.estatus_sistema,
    ultima_actualizacion : getDateString(SERVER_TIME_ZONE),
  };
}
