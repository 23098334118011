/**
 * Métodos del, get, post, put para llamadas a la API
 * @get     en este método primero consulta con @getFromLocalStorage si es necesario hacer la petición http sino se obtiene del LocalStorage;
 *          si hay un error en la petición http se busca el objeto en el LocalStorage sino lo encuentra devuelve un objeto vacío; si la
 *          petición se realiza correctamente actualiza el valor individual y en el array del objeto en el LocalStorage y devuelve el objeto
 *          obtenido del servidor.
 * @getAll  en este método primero consulta con @getFromLocalStorage si es necesario hacer la petición http sino se obtiene del LocalStorage;
 *          si hay un error en la petición http se busca el objeto array en el LocalStorage sino lo encuentra devuelve un array vacío; si la
 *          petición se realiza correctamente actualiza array en el LocalStorage y devuelve el array obtenido del servidor
 * @update  en este método después de realizar la petición http si falla devuelve el objeto que se iba a actualizar; si la petición se
 *          realiza correctamente actualiza el valor individual y en el array del objeto en el LocalStorage y devuelve el objeto obtenido del
 *          servidor.
 * @create  en este método después de realizar la petición http si falla devuelve el objeto que se iba a crear; si la petición se realiza
 *          correctamente actualiza el valor individual y en el array del objeto en el LocalStorage y devuelve el objeto obtenido del
 *          servidor.
 * @delete  en este método después de realizar la petición http si falla devuelve el objeto que se iba a eliminar; si la petición se realiza
 *          correctamente elimina el valor individual y en el objeto en el arrya del LocalStorage y devuelve un objeto vacio (Default)
 */

import { del, get, post, put } from "../../api/api";
import {
  getFromLocalStorage,
  updateLocalStorageExpiry,
} from "../../api/helpers";
import { removeArrayItemStorageData } from "../../api/storage";
import { DEFAULT_STATE } from "../../enums/default_state";
import {
  getEmptyStatusEmpleado,
  setEmpleado,
  IEmpleado,
  getSerializableEmpleado,
} from "../../interfaces/personas";
import { IServerDate } from "../../interfaces/time";
import {
  getStorageEmpleadoArrayItem,
  setStorageEmpleado,
  setStorageEmpleadoArrayItem,
  getStorageEmpleadoArray,
  setStorageEmpleadoArray,
  removeStorageEmpleado,
} from "../../localStorage/personas";

export const getEmpleado = async (
  serverDate: IServerDate,
  pk: number,
  idCliente: number
) => {
  const temp = getEmptyStatusEmpleado();
  const doHttp = await getFromLocalStorage(serverDate, temp.expiry);
  temp.expiry = doHttp.storage;
  if (!doHttp.result) {
    console.log(`(localStorage) Empleado con pk: ${pk}, obtenido con éxito`);
    temp.estado = DEFAULT_STATE.success;
    temp.mensaje = `Empleado obtenida con éxito`;
    temp.data = await getStorageEmpleadoArrayItem(pk);
    return temp;
  }
  const result = await get(`personas/empleado/${pk}/${idCliente}`);
  const data = result.data;
  console.log(`Empleado data: `, data);
  if (!data.id || data.detail) {
    console.log(`Error al obtener Empleado con pk: ${pk}, ${data.detail}`);
    temp.estado = DEFAULT_STATE.error;
    temp.mensaje = `Error al obtener la empleado: ${
      data.detail ?? "Hubo un problema al conectarse con el servidor"
    }`;
    temp.data = await getStorageEmpleadoArrayItem(pk);
    return temp;
  }
  console.log(`Empleado con pk: ${pk}, obtenido con éxito`);
  temp.estado = DEFAULT_STATE.success;
  temp.mensaje = `Empleado obtenida con éxito`;
  temp.data = await setEmpleado(data, serverDate);
  await setStorageEmpleado(temp.data);
  temp.data_array = await setStorageEmpleadoArrayItem(pk, temp.data);
  temp.expiry = await updateLocalStorageExpiry(serverDate, temp.expiry);
  return temp;
};

export const getAllEmpleado = async (
  serverDate: IServerDate,
  idCliente: number
) => {
  const temp = getEmptyStatusEmpleado();
  const doHttp = await getFromLocalStorage(serverDate, temp.expiry_array);
  temp.expiry_array = doHttp.storage;
  if (!doHttp.result) {
    console.log(`(localStorage) Todos las Empleados obtenidas con éxito`);
    temp.estado = DEFAULT_STATE.success;
    temp.mensaje = `Todos las empleados obtenidas con éxito`;
    temp.data_array = await getStorageEmpleadoArray();
  }
  const result = await get(`personas/empleado/${idCliente}`);
  const data = result.data;
  if (data.detail) {
    console.log(`Error al obtener la lista de Empleado`, data);
    temp.estado = DEFAULT_STATE.error;
    temp.mensaje = `Error al obtener la lista de empleados: ${
      data.detail ?? "Hubo un problema al conectarse con el servidor"
    }`;
    temp.data_array = await getStorageEmpleadoArray();
    return temp;
  }
  console.log(`Todos las Empleados obtenidos con éxito`);
  temp.estado = DEFAULT_STATE.success;
  temp.mensaje = `Todos las empleados obtenidas con éxito`;
  temp.data_array = await Promise.all(
    data.map(async (e: any) => await setEmpleado(e, serverDate))
  );
  await setStorageEmpleadoArray(temp.data_array);
  temp.expiry_array = await updateLocalStorageExpiry(
    serverDate,
    temp.expiry_array
  );
  return temp;
};

export const getEmpleadosACargo = async (
  serverDate: IServerDate,
  idCliente: number,
  employeesArray: number[]
) => {
  const temp = getEmptyStatusEmpleado();
  const doHttp = await getFromLocalStorage(serverDate, temp.expiry_array);
  temp.expiry_array = doHttp.storage;
  if (!doHttp.result) {
    console.log(`(localStorage) Todos las Empleados obtenidas con éxito`);
    temp.estado = DEFAULT_STATE.success;
    temp.mensaje = `Todos las empleados obtenidas con éxito`;
    temp.data_array = await getStorageEmpleadoArray();
  }
  const employees = [];
  for (const employeeId of employeesArray) {
    const result = await getEmpleado(serverDate, employeeId, idCliente);
    if (result.estado === DEFAULT_STATE.success) {
      employees.push(result.data);
    }
  }
  // const result = await get(`personas/empleado/${idCliente}`);
  // const data = result.data;
  if (employees.length === 0) {
    console.log(`Error al obtener la lista de Empleado`);
    temp.estado = DEFAULT_STATE.error;
    temp.mensaje =
      "Error al obtener la lista de empleados a cargo: Hubo un problema al conectarse con el servidor";
    temp.data_array = await getStorageEmpleadoArray();
    return temp;
  }
  console.log(`Todos las Empleados obtenidos con éxito`);
  temp.estado = DEFAULT_STATE.success;
  temp.mensaje = `Todos las empleados obtenidas con éxito`;
  temp.data_array = employees;
  await setStorageEmpleadoArray(temp.data_array);
  temp.expiry_array = await updateLocalStorageExpiry(
    serverDate,
    temp.expiry_array
  );
  return temp;
};

export const updateEmpleado = async (
  serverDate: IServerDate,
  pk: number,
  idCliente: number,
  object: IEmpleado
) => {
  const temp = getEmptyStatusEmpleado();
  const result = await put(
    `personas/empleado/${pk}/${idCliente}`,
    getSerializableEmpleado(object)
  );
  const data = result.data;
  if (!data.id || data.detail) {
    console.log(`Error al actualizar Empleado con pk: ${pk}, ${data.detail}`);
    temp.estado = DEFAULT_STATE.error;
    temp.mensaje = `Error al actualizar la empleado: ${
      data.detail ?? "Hubo un problema al conectarse con el servidor"
    }`;
    temp.data = await setEmpleado(object, serverDate);
    return temp;
  }
  console.log(`Empleado con pk: ${pk}, actualizado con éxito`);
  temp.estado = DEFAULT_STATE.success;
  temp.mensaje = `Empleado actualizada con éxito`;
  temp.data = await setEmpleado(data, serverDate);
  await setStorageEmpleado(temp.data);
  temp.data_array = await setStorageEmpleadoArrayItem(temp.data.id, temp.data);
  return temp;
};

export const createEmpleado = async (
  serverDate: IServerDate,
  idCliente: number,
  object: IEmpleado
) => {
  const temp = getEmptyStatusEmpleado();
  const result = await post(
    `personas/empleado/${idCliente}`,
    getSerializableEmpleado(object)
  );
  const data = result.data;
  if (!data.id || data.detail) {
    console.log(
      `Error al crear Empleado con los datos ${object}, ${data.detail}`
    );
    temp.estado = DEFAULT_STATE.error;
    temp.mensaje = `Error al crear la empleado: ${
      data.detail ?? "Hubo un problema al conectarse con el servidor"
    }`;
    temp.data = await setEmpleado(object, serverDate);
    return temp;
  }
  console.log(`Empleado creada con éxito`);
  temp.estado = DEFAULT_STATE.success;
  temp.mensaje = `Empleado creada con éxito`;
  temp.data = await setEmpleado(data, serverDate);
  await setStorageEmpleado(temp.data);
  temp.data_array = await setStorageEmpleadoArrayItem(temp.data.id, temp.data);
  return temp;
};

export const deleteEmpleado = async (pk: number, idCliente: number) => {
  const temp = getEmptyStatusEmpleado();
  const result = await del(`personas/empleado/${pk}/${idCliente}`);
  const data = result.data;
  if (data.detail) {
    console.log(`Error al eliminar Empleado con pk: ${pk}, ${data.detail}`);
    temp.estado = DEFAULT_STATE.error;
    temp.mensaje = `Error al eliminar la empleado: ${data.detail}`;
    temp.data = await getStorageEmpleadoArrayItem(pk);
    temp.data_array = await getStorageEmpleadoArray();
    return temp;
  }
  console.log(`Empleado eliminada con éxito`);
  temp.estado = DEFAULT_STATE.success;
  temp.mensaje = `Empleado eliminada con éxito`;
  await removeStorageEmpleado();
  temp.data_array = await removeArrayItemStorageData("IEmpleado", pk, []);
  return temp;
};
