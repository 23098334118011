/** 
 * Componente que se encarga del elemento InformacionContrato
 * @IInformacionContrato es la interfaz que contiene los parámetos el elemento InformacionContrato
 * @getEmptyInformacionContrato  devuelve un objeto con los parámetros por defecto; @param {id}, opcional cuando no encuentra el objeto en el LocalSotrage
 * @IStatusInformacionContrato es la interfaz para los estatus a llamadas a la API; @param {status}, opcional cuando queremos otro estado de inizialización de tipo [DEFAULT_STATE]
 * @getEmptyStatusInformacionContrato devuelve un objeto con los parámetros por defecto,
 * @setInformacionContrato devuelve un objeto de tipo [IInformacionContrato] recibiendo @param {object} y @param {serverDate}, para verificar que los datos estén en el formato correcto y ajustarlos a la zona horaria actual
 * @getSerializableInformacionContrato recibe un @param {IInformacionContrato}, para devolver un objeto serizalizable para las peticiones de la API
 * */

import { getDateString, isNotEmpty, SERVER_TIME_ZONE } from "../../api/helpers";
import { DEFAULT_STATE } from "../../enums/default_state";
import { getStorageDocumentoArrayItem, getStorageClienteQentryArrayItem } from "../../localStorage/personas";
import { convertDateToZonaHoraria, ILocalStorageExpiry, getEmptyLocalStorageExpiry, IServerDate } from "../time";
import { getEmptyClienteQentry, IClienteQentry, setClienteQentry } from "./IClienteQentry";
import { IDocumento, getEmptyDocumento, setDocumento } from "./IDocumento";

export interface IInformacionContrato {
  id : number,
  tipo_nomina : number, // nomina.MvTipoNomina
  motivo_pago_nomina : number, // nomina.MvMotivoPagoNomina
  tipo_regimen : number, // nomina.MvTipoRegimen
  forma_de_pago_nomina : number, // nomina.MvFormaPagoNomina
  frecuencia_pago : number, // nomina.MvFrecuenciaPagoNomina
  tipo_contrato : number, // nomina.MvTipoContrato
  puesto : number, // nomina.Puesto
  centro_de_costos : number, // nomina.CentroDeCostos
  salario_base_cotizacion : number, // Decimal
  salario_diario_integrado : number, // Decimal
  sueldo_diario : number, // Decimal
  tipos_percepciones_asignados : number[], // nomina.MvTipoPercepcion
  tipos_deducciones_asignados : number[], // nomina.MvTipoDeduccion
  documento_contrato : IDocumento,
  fecha_elaboracion : string, // Fecha
  estatus_sistema : boolean,
  ultima_actualizacion : string, // Fecha
  cliente_qentry : IClienteQentry,
}

export const getEmptyInformacionContrato = (id? : number) => {
  const temp : IInformacionContrato = 
  {
    id : id ? id : 0,
    tipo_nomina : 0,
    motivo_pago_nomina : 0,
    tipo_regimen : 0,
    forma_de_pago_nomina : 0,
    frecuencia_pago : 0,
    tipo_contrato : 0,
    puesto : 0,
    centro_de_costos : 0,
    salario_base_cotizacion : 0,
    salario_diario_integrado : 0,
    sueldo_diario : 0,
    tipos_percepciones_asignados : [],
    tipos_deducciones_asignados : [],
    documento_contrato : getEmptyDocumento(),
    fecha_elaboracion : "",
    estatus_sistema : false,
    ultima_actualizacion : "",
    cliente_qentry : getEmptyClienteQentry(),
  };
  return temp;
}

export interface IStatusInformacionContrato {
  estado : DEFAULT_STATE,
  mensaje : string,
  data : IInformacionContrato,
  data_array: IInformacionContrato[],
  expiry : ILocalStorageExpiry,
  expiry_array : ILocalStorageExpiry,
}

export const getEmptyStatusInformacionContrato = (status: DEFAULT_STATE = DEFAULT_STATE.loading) => {
  const temp : IStatusInformacionContrato = 
  {
    estado : status,
    mensaje : "",
    data : getEmptyInformacionContrato(),
    data_array : [],
    expiry : getEmptyLocalStorageExpiry("IInformacionContrato", 0),
    expiry_array : getEmptyLocalStorageExpiry("IInformacionContratoArray", 0),
  };
  return temp;
}

export const setInformacionContrato = async (
  object : any,
  serverDate : IServerDate,
) => {
  const temp = getEmptyInformacionContrato();
  if (isNotEmpty(object.id)) temp.id = object.id;
  if (isNotEmpty(object.tipo_nomina)) temp.tipo_nomina = object.tipo_nomina;
  if (isNotEmpty(object.motivo_pago_nomina)) temp.motivo_pago_nomina = object.motivo_pago_nomina;
  if (isNotEmpty(object.tipo_regimen)) temp.tipo_regimen = object.tipo_regimen;
  if (isNotEmpty(object.forma_de_pago_nomina)) temp.forma_de_pago_nomina = object.forma_de_pago_nomina;
  if (isNotEmpty(object.frecuencia_pago)) temp.frecuencia_pago = object.frecuencia_pago;
  if (isNotEmpty(object.tipo_contrato)) temp.tipo_contrato = object.tipo_contrato;
  if (isNotEmpty(object.puesto)) temp.puesto = object.puesto;
  if (isNotEmpty(object.centro_de_costos)) temp.centro_de_costos = object.centro_de_costos;
  if (isNotEmpty(object.salario_base_cotizacion)) temp.salario_base_cotizacion = Number(object.salario_base_cotizacion);
  if (isNotEmpty(object.salario_diario_integrado)) temp.salario_diario_integrado = Number(object.salario_diario_integrado);
  if (isNotEmpty(object.sueldo_diario)) temp.sueldo_diario = Number(object.sueldo_diario);
  if (isNotEmpty(object.tipos_percepciones_asignados)) temp.tipos_percepciones_asignados = object.tipos_percepciones_asignados;
  if (isNotEmpty(object.tipos_deducciones_asignados)) temp.tipos_deducciones_asignados = object.tipos_deducciones_asignados;
  if (isNotEmpty(object.documento_contrato)) temp.documento_contrato = (typeof object.documento_contrato === 'number') ? await getStorageDocumentoArrayItem(object.documento_contrato) : await setDocumento(object.documento_contrato, serverDate);
  if (isNotEmpty(object.fecha_elaboracion)) temp.fecha_elaboracion = convertDateToZonaHoraria(serverDate, object.fecha_elaboracion);
  if (isNotEmpty(object.estatus_sistema)) temp.estatus_sistema = object.estatus_sistema;
  if (isNotEmpty(object.ultima_actualizacion)) temp.ultima_actualizacion = convertDateToZonaHoraria(serverDate, object.ultima_actualizacion);
  if (isNotEmpty(object.cliente_qentry)) temp.cliente_qentry = (typeof object.cliente_qentry === 'number') ? await getStorageClienteQentryArrayItem(object.cliente_qentry) : await setClienteQentry(object.cliente_qentry, serverDate);
  return temp;
}

export const getSerializableInformacionContrato = (IInformacionContrato : IInformacionContrato) => {
  return {
    id : IInformacionContrato.id,
    tipo_nomina : IInformacionContrato.tipo_nomina,
    motivo_pago_nomina : IInformacionContrato.motivo_pago_nomina,
    tipo_regimen : IInformacionContrato.tipo_regimen,
    forma_de_pago_nomina : IInformacionContrato.forma_de_pago_nomina,
    frecuencia_pago : IInformacionContrato.frecuencia_pago,
    tipo_contrato : IInformacionContrato.tipo_contrato,
    puesto : IInformacionContrato.puesto,
    centro_de_costos : IInformacionContrato.centro_de_costos,
    salario_base_cotizacion : IInformacionContrato.salario_base_cotizacion.toFixed(2),
    salario_diario_integrado : IInformacionContrato.salario_diario_integrado.toFixed(2),
    sueldo_diario : IInformacionContrato.sueldo_diario.toFixed(2),
    tipos_percepciones_asignados : IInformacionContrato.tipos_percepciones_asignados,
    tipos_deducciones_asignados : IInformacionContrato.tipos_deducciones_asignados,
    documento_contrato : IInformacionContrato.documento_contrato.id,
    fecha_elaboracion : IInformacionContrato.fecha_elaboracion,
    estatus_sistema : IInformacionContrato.estatus_sistema,
    ultima_actualizacion : getDateString(SERVER_TIME_ZONE),
    cliente_qentry : IInformacionContrato.cliente_qentry.id
  };
}
