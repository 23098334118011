/**
 * Métodos AsyncThunk para ser usados en los reducers y
 * los resultados a las llamadas http puedan ser usados para
 * actualizar el state en redux
 */

import { createAsyncThunk } from "@reduxjs/toolkit";
import { DEFAULT_STATE } from "../../enums/default_state";
import { getMvTipoCanalVenta, getAllMvTipoCanalVenta, updateMvTipoCanalVenta, createMvTipoCanalVenta, deleteMvTipoCanalVenta } from "../../http/personas";
import { getEmptyStatusMvTipoCanalVenta, IMvTipoCanalVenta } from "../../interfaces/personas";
import { IServerDate } from "../../interfaces/time";

export const getMvTipoCanalVentaAsyncThunk = createAsyncThunk(
  'personas/getMvTipoCanalVentaAsyncThunk',
  async (query : {serverDate : IServerDate, id: number}) => await getMvTipoCanalVenta(query.serverDate, query.id)
    .then(async (result) => result)
    .catch(async (error) => getEmptyStatusMvTipoCanalVenta(DEFAULT_STATE.error))  
);

export const getAllMvTipoCanalVentaAsyncThunk = createAsyncThunk(
  'personas/getAllMvTipoCanalVentaAsyncThunk',
  async (queryAll : {serverDate : IServerDate}) => await getAllMvTipoCanalVenta(queryAll.serverDate)
    .then(async (result) => result)
    .catch(async (error) => getEmptyStatusMvTipoCanalVenta(DEFAULT_STATE.error))  
);

export const updateMvTipoCanalVentaAsyncThunk = createAsyncThunk(
  'personas/updateMvTipoCanalVentaAsyncThunk',
  async (update: {serverDate : IServerDate, id: number, MvTipoCanalVenta: IMvTipoCanalVenta}) => await updateMvTipoCanalVenta(update.serverDate, update.id, update.MvTipoCanalVenta)
    .then(async (result) => result)
    .catch(async (error) => getEmptyStatusMvTipoCanalVenta(DEFAULT_STATE.error))  
);

export const createMvTipoCanalVentaAsyncThunk = createAsyncThunk(
  'personas/createMvTipoCanalVentaAsyncThunk',
  async (create : {serverDate : IServerDate, MvTipoCanalVenta: IMvTipoCanalVenta}) => await createMvTipoCanalVenta(create.serverDate, create.MvTipoCanalVenta)
    .then(async (result) => result)
    .catch(async (error) => getEmptyStatusMvTipoCanalVenta(DEFAULT_STATE.error))  
);

export const deleteMvTipoCanalVentaAsyncThunk = createAsyncThunk(
  'personas/deleteMvTipoCanalVentaAsyncThunk',
  async (del : {id : number}) => await deleteMvTipoCanalVenta(del.id)
    .then(async (result) => result)
    .catch(async (error) => getEmptyStatusMvTipoCanalVenta())  
);
