/** 
 * Componente que se encarga del elemento HorarioExtra
 * @IHorarioExtra es la interfaz que contiene los parámetos el elemento HorarioExtra
 * @getEmptyHorarioExtra  devuelve un objeto con los parámetros por defecto; @param {id}, opcional cuando no encuentra el objeto en el LocalSotrage
 * @IStatusHorarioExtra es la interfaz para los estatus a llamadas a la API; @param {status}, opcional cuando queremos otro estado de inizialización de tipo [DEFAULT_STATE]
 * @getEmptyStatusHorarioExtra devuelve un objeto con los parámetros por defecto,
 * @setHorarioExtra devuelve un objeto de tipo [IHorarioExtra] recibiendo @param {object} y @param {serverDate}, para verificar que los datos estén en el formato correcto y ajustarlos a la zona horaria actual
 * @getSerializableHorarioExtra recibe un @param {IHorarioExtra}, para devolver un objeto serizalizable para las peticiones de la API
 * */

import { getDateString, isNotEmpty, SERVER_TIME_ZONE } from "../../api/helpers";
import { DEFAULT_STATE } from "../../enums/default_state";
import { getStorageAsistenciaArrayItem, getStorageHorarioArrayItem, getStorageMvTipoHorasExtraArrayItem } from "../../localStorage/nomina";
import { getStorageEmpleadoArrayItem, getStorageCentroDeTrabajoArrayItem, getStorageGeolocalizacionArrayItem, getStorageUserArrayItem, getStorageClienteQentryArrayItem } from "../../localStorage/personas";
import { getEmptyCentroDeTrabajo, getEmptyClienteQentry, getEmptyEmpleado, getEmptyGeolocalizacion, getEmptyUser, ICentroDeTrabajo, IClienteQentry, IEmpleado, IGeolocalizacion, IUser, setCentroDeTrabajo, setClienteQentry, setEmpleado, setGeolocalizacion, setUser } from "../personas";
import { ILocalStorageExpiry, getEmptyLocalStorageExpiry, IServerDate, convertDateToZonaHoraria } from "../time";
import { getEmptyAsistencia, IAsistencia, setAsistencia } from "./Asistencia";
import { getEmptyHorario, IHorario, setHorario } from "./Horario";
import { getEmptyMvTipoHorasExtra, IMvTipoHorasExtra, setMvTipoHorasExtra } from "./MvTipoHorasExtra";

export interface IHorasExtra {
  id : number,
  empleado : IEmpleado,
  asistencia : IAsistencia,
  horario : IHorario,
  tipo_horas_extra : IMvTipoHorasExtra,
  horas_extra_aprobadas : number, // Decimal
  centro_de_trabajo : ICentroDeTrabajo,
  geolocalizacion_empleado : IGeolocalizacion,
  usuario_que_aprueba : IUser,
  fecha_elaboracion : string, // Fecha
  estatus_sistema : boolean,
  ultima_actualizacion : string, // Fecha
  cliente_qentry : IClienteQentry,
}

export const getEmptyHorasExtra = (id? : number) => {
  const temp : IHorasExtra = 
  {
    id : id ? id : 0,
    empleado : getEmptyEmpleado(),
    asistencia : getEmptyAsistencia(),
    horario : getEmptyHorario(),
    tipo_horas_extra : getEmptyMvTipoHorasExtra(),
    horas_extra_aprobadas : 0, // Decimal
    centro_de_trabajo : getEmptyCentroDeTrabajo(),
    geolocalizacion_empleado : getEmptyGeolocalizacion(),
    usuario_que_aprueba : getEmptyUser(),
    fecha_elaboracion : "",
    estatus_sistema : false,
    ultima_actualizacion : "",
    cliente_qentry : getEmptyClienteQentry(),
  };
  return temp;
}

export interface IStatusHorasExtra {
  estado : DEFAULT_STATE,
  mensaje : string,
  data : IHorasExtra,
  data_array: IHorasExtra[],
  expiry : ILocalStorageExpiry,
  expiry_array : ILocalStorageExpiry,
}

export const getEmptyStatusHorasExtra = (status: DEFAULT_STATE = DEFAULT_STATE.loading) => {
  const temp : IStatusHorasExtra = 
  {
    estado : status,
    mensaje : "",
    data : getEmptyHorasExtra(),
    data_array : [],
    expiry : getEmptyLocalStorageExpiry("IHorasExtra", 0),
    expiry_array : getEmptyLocalStorageExpiry("IHorasExtraArray", 0),
  };
  return temp;
}

export const setHorasExtra = async (
  object : any,
  serverDate : IServerDate,
) => {
  const temp = getEmptyHorasExtra();
  if (isNotEmpty(object.id)) temp.id = object.id;
  if (isNotEmpty(object.empleado)) temp.empleado = (typeof object.empleado === 'number') ? await getStorageEmpleadoArrayItem(object.empleado) : await setEmpleado(object.empleado, serverDate);
  if (isNotEmpty(object.asistencia)) temp.asistencia = (typeof object.asistencia === 'number') ? await getStorageAsistenciaArrayItem(object.asistencia) : await setAsistencia(object.asistencia, serverDate);
  if (isNotEmpty(object.horario)) temp.horario = (typeof object.horario === 'number') ? await getStorageHorarioArrayItem(object.horario) : await setHorario(object.horario, serverDate);
  if (isNotEmpty(object.tipo_horas_extra)) temp.tipo_horas_extra = (typeof object.tipo_horas_extra === 'number') ? await getStorageMvTipoHorasExtraArrayItem(object.tipo_horas_extra) : await setMvTipoHorasExtra(object.tipo_horas_extra, serverDate);
  if (isNotEmpty(object.horas_extra_aprobadas)) temp.horas_extra_aprobadas = Number(object.horas_extra_aprobadas);
  if (isNotEmpty(object.centro_de_trabajo)) temp.centro_de_trabajo = (typeof object.centro_de_trabajo === 'number') ? await getStorageCentroDeTrabajoArrayItem(object.centro_de_trabajo) : await setCentroDeTrabajo(object.centro_de_trabajo, serverDate);
  if (isNotEmpty(object.geolocalizacion_empleado)) temp.geolocalizacion_empleado = (typeof object.geolocalizacion_empleado === 'number') ? await getStorageGeolocalizacionArrayItem(object.geolocalizacion_empleado) : await setGeolocalizacion(object.geolocalizacion_empleado, serverDate);
  if (isNotEmpty(object.usuario_que_aprueba)) temp.usuario_que_aprueba = (typeof object.usuario_que_aprueba === 'number') ? await getStorageUserArrayItem(object.usuario_que_aprueba) : await setUser(object.usuario_que_aprueba, serverDate);
  if (isNotEmpty(object.fecha_elaboracion)) temp.fecha_elaboracion = convertDateToZonaHoraria(serverDate, object.fecha_elaboracion);
  if (isNotEmpty(object.estatus_sistema)) temp.estatus_sistema = object.estatus_sistema;
  if (isNotEmpty(object.ultima_actualizacion)) temp.ultima_actualizacion = convertDateToZonaHoraria(serverDate, object.ultima_actualizacion);
  if (isNotEmpty(object.cliente_qentry)) temp.cliente_qentry = (typeof object.cliente_qentry === 'number') ? await getStorageClienteQentryArrayItem(object.cliente_qentry) : await setClienteQentry(object.cliente_qentry, serverDate);
  return temp;
}

export const getSerializableHorasExtra = (IHorasExtra : IHorasExtra ) => {
  return {
    id : IHorasExtra.id,
    empleado : IHorasExtra.empleado.id,
    asistencia : IHorasExtra.asistencia.id,
    horario : IHorasExtra.horario.id,
    tipo_horas_extra : IHorasExtra.tipo_horas_extra.id,
    horas_extra_aprobadas : IHorasExtra.horas_extra_aprobadas.toFixed(2), // Decimal
    centro_de_trabajo : IHorasExtra.centro_de_trabajo.id,
    geolocalizacion_empleado : IHorasExtra.geolocalizacion_empleado.id,
    usuario_que_aprueba : IHorasExtra.usuario_que_aprueba.id,
    fecha_elaboracion : IHorasExtra.fecha_elaboracion,
    estatus_sistema : IHorasExtra.estatus_sistema,
    ultima_actualizacion : getDateString(SERVER_TIME_ZONE),
    cliente_qentry : IHorasExtra.cliente_qentry.id,
  };
}
