/**
 * Métodos para el manejo de IonicStorage 
 * @setStorage              guarda un unico objeto al storage
 * @getStorage              obtiene el objeto guardado con @setStorage
 * @setStorageArray         guarda el array de objetos en el storage
 * @getStorageArray         obtiene el array de objetos guardado con @setStorageArray
 * @removeStorageArray      elimina el array de objetos guardado con @setStorageArray
 * @setStorageItemArray     agrega un elemento al array de objeto guardado con @setStorageArray
 * @getStorageItemArray     obtiene un elemento del array guardado con @setStorageArray buscandolo por su id 
 * @removeStorageItemArray  elimina un elemento del array guardado con @setStorageArray buscandolo por su id 
 */

import { setStorageData, getStorageData, removeStorageData, setArrayItemStorageData, getArrayItemStorageData, removeArrayItemStorageData } from "../../api/storage";
import { IModificadorNomina, getEmptyModificadorNomina } from "../../interfaces/personas";

export const setStorageModificadorNomina = async (IModificadorNomina : IModificadorNomina) => await setStorageData('IModificadorNomina', IModificadorNomina);
export const getStorageModificadorNomina = async () : Promise<IModificadorNomina> => await getStorageData('IModificadorNomina', getEmptyModificadorNomina());
export const removeStorageModificadorNomina = async () => await removeStorageData('IModificadorNomina');
export const setStorageModificadorNominaArray = async (IModificadorNomina : IModificadorNomina[]) => await setStorageData('IModificadorNominaArray', IModificadorNomina);
export const getStorageModificadorNominaArray = async () : Promise<IModificadorNomina[]> => await getStorageData('IModificadorNominaArray', [] as IModificadorNomina[]);
export const removeStorageModificadorNominaArray = async () => await removeStorageData('IModificadorNominaArray');
export const setStorageModificadorNominaArrayItem = async (id: number, IModificadorNomina : IModificadorNomina) : Promise<IModificadorNomina[]> => await setArrayItemStorageData('IModificadorNominaArray', id, IModificadorNomina, [] as IModificadorNomina[]);
export const getStorageModificadorNominaArrayItem = async (id: number) : Promise<IModificadorNomina> => await getArrayItemStorageData('IModificadorNominaArray', id, getEmptyModificadorNomina);
export const removeStorageModificadorNominaArrayItem = async (id: number) : Promise<IModificadorNomina[]> => await removeArrayItemStorageData('IModificadorNominaArray', id, [] as IModificadorNomina[]);
