/**
 * Métodos para el manejo de IonicStorage 
 * @setStorage              guarda un unico objeto al storage
 * @getStorage              obtiene el objeto guardado con @setStorage
 * @setStorageArray         guarda el array de objetos en el storage
 * @getStorageArray         obtiene el array de objetos guardado con @setStorageArray
 * @removeStorageArray      elimina el array de objetos guardado con @setStorageArray
 * @setStorageItemArray     agrega un elemento al array de objeto guardado con @setStorageArray
 * @getStorageItemArray     obtiene un elemento del array guardado con @setStorageArray buscandolo por su id 
 * @removeStorageItemArray  elimina un elemento del array guardado con @setStorageArray buscandolo por su id 
 */

import { setStorageData, getStorageData, removeStorageData, setArrayItemStorageData, getArrayItemStorageData, removeArrayItemStorageData } from "../../api/storage";
import { IMvTipoJornada, getEmptyMvTipoJornada } from "../../interfaces/nomina";

export const setStorageMvTipoJornada = async (IMvTipoJornada : IMvTipoJornada) => await setStorageData('IMvTipoJornada', IMvTipoJornada);
export const getStorageMvTipoJornada = async () : Promise<IMvTipoJornada> => await getStorageData('IMvTipoJornada', getEmptyMvTipoJornada());
export const removeStorageMvTipoJornada = async () => await removeStorageData('IMvTipoJornada');
export const setStorageMvTipoJornadaArray = async (IMvTipoJornada : IMvTipoJornada[]) => await setStorageData('IMvTipoJornadaArray', IMvTipoJornada);
export const getStorageMvTipoJornadaArray = async () : Promise<IMvTipoJornada[]> => await getStorageData('IMvTipoJornadaArray', [] as IMvTipoJornada[]);
export const removeStorageMvTipoJornadaArray = async () => await removeStorageData('IMvTipoJornadaArray');
export const setStorageMvTipoJornadaArrayItem = async (id: number, IMvTipoJornada : IMvTipoJornada) : Promise<IMvTipoJornada[]> => await setArrayItemStorageData('IMvTipoJornadaArray', id, IMvTipoJornada, [] as IMvTipoJornada[]);
export const getStorageMvTipoJornadaArrayItem = async (id: number) : Promise<IMvTipoJornada> => await getArrayItemStorageData('IMvTipoJornadaArray', id, getEmptyMvTipoJornada);
export const removeStorageMvTipoJornadaArrayItem = async (id: number) : Promise<IMvTipoJornada[]> => await removeArrayItemStorageData('IMvTipoJornadaArray', id, [] as IMvTipoJornada[]);
