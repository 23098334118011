/**
 * Métodos AsyncThunk para ser usados en los reducers y
 * los resultados a las llamadas http puedan ser usados para
 * actualizar el state en redux
 */

import { createAsyncThunk } from "@reduxjs/toolkit";
import { DEFAULT_STATE } from "../../enums/default_state";
import { getImagen, getAllImagen, updateImagen, createImagen, deleteImagen } from "../../http/personas";
import { getEmptyStatusImagen, IImagen } from "../../interfaces/personas";
import { IServerDate } from "../../interfaces/time";

export const getImagenAsyncThunk = createAsyncThunk(
  'personas/getImagenAsyncThunk',
  async (query : {serverDate : IServerDate, id: number, idCliente : number}) => await getImagen(query.serverDate, query.id, query.idCliente)
    .then(async (result) => result)
    .catch(async (error) => getEmptyStatusImagen(DEFAULT_STATE.error))  
);

export const getAllImagenAsyncThunk = createAsyncThunk(
  'personas/getAllImagenAsyncThunk',
  async (queryAll : {serverDate : IServerDate, idCliente : number}) => await getAllImagen(queryAll.serverDate, queryAll.idCliente)
    .then(async (result) => result)
    .catch(async (error) => getEmptyStatusImagen(DEFAULT_STATE.error))  
);

export const updateImagenAsyncThunk = createAsyncThunk(
  'personas/updateImagenAsyncThunk',
  async (update: {serverDate : IServerDate, id: number, idCliente : number, object: IImagen}) => await updateImagen(update.serverDate, update.id, update.idCliente, update.object)
    .then(async (result) => result)
    .catch(async (error) => getEmptyStatusImagen(DEFAULT_STATE.error))  
);

export const createImagenAsyncThunk = createAsyncThunk(
  'personas/createImagenAsyncThunk',
  async (create : {serverDate : IServerDate, idCliente : number, object: IImagen}) => await createImagen(create.serverDate, create.idCliente, create.object)
    .then(async (result) => result)
    .catch(async (error) => getEmptyStatusImagen(DEFAULT_STATE.error))  
);

export const deleteImagenAsyncThunk = createAsyncThunk(
  'personas/deleteImagenAsyncThunk',
  async (del : {id : number, idCliente : number}) => await deleteImagen(del.id, del.idCliente)
    .then(async (result) => result)
    .catch(async (error) => getEmptyStatusImagen())  
);
