import { getArrayItemStorageData, getStorageData, removeArrayItemStorageData, removeStorageData, setArrayItemStorageData, setStorageData } from "../../api/storage";
import { getEmptyPais, IPais } from "../../interfaces/personas";

export const setStoragePais = async (IPais : IPais) => await setStorageData('IPais', IPais);
export const getStoragePais = async () : Promise<IPais> => await getStorageData('IPais', getEmptyPais());
export const removeStoragePais = async () => await removeStorageData('IPais');
export const setStoragePaisArray = async (IPais : IPais[]) => await setStorageData('IPaisArray', IPais);
export const getStoragePaisArray = async () : Promise<IPais[]> => await getStorageData('IPaisArray', [] as IPais[]);
export const removeStoragePaisArray = async () => await removeStorageData('IPaisArray');
export const setStoragePaisArrayItem = async (id: number, IPais : IPais) : Promise<IPais[]> => await setArrayItemStorageData('IPaisArray', id, IPais, [] as IPais[]);
export const getStoragePaisArrayItem = async (id: number) : Promise<IPais> => await getArrayItemStorageData('IPaisArray', id, getEmptyPais);
export const removeStoragePaisArrayItem = async (id: number) : Promise<IPais[]> => await removeArrayItemStorageData('IPaisArray', id, [] as IPais[]);
