import { createAntiguedadEmpleadoReducers } from "./AntiguedadEmpleado";
import { createCanalVentaReducers } from "./CanalVenta";
import { createCentroDeTrabajoReducers } from "./CentroDeTrabajo";
import { createClienteQentryReducers } from "./ClienteQentry";
import { createCodigoPostalReducers } from "./CodigoPostal";
import { createCodigoQrReducers } from "./CodigoQr";
import { createDatosFiscalesReducers } from "./DatosFiscales";
import { createDatosPersonalesReducers } from "./DatosPersonales";
import { createDireccionReducers } from "./Direccion";
import { createDocumentoReducers } from "./Documento";
import { createEmpleadoReducers } from "./Empleado";
import { createEmpresaReducers } from "./Empresa";
import { createEstadoReducers } from "./Estado";
import { createGeolocalizacionReducers } from "./Geolocalizacion";
import { createHelpersReducers } from "./helpers";
import { createImagenReducers } from "./Imagen";
import { createInformacionBancariaReducers } from "./InformacionBancaria";
import { createInformacionContratoReducers } from "./InformacionContrato";
import { createInformacionSuscripcionReducers } from "./InformacionSuscripcion";
import { createModificacionSuscripcionQentryReducers } from "./ModificacionSuscripcionQentry";
import { createModificadorNominaReducers } from "./ModificadorNomina";
import { createMvAntiguedadReducers } from "./MvAntiguedad";
import { createMvBancoReducers } from "./MvBanco";
import { createMvEstadoCivilReducers } from "./MvEstadoCivil";
import { createMvEstatusSolicitudCambioTelefonoAutorizadoReducers } from "./MvEstatusSolicitudCambioTelefonoAutorizado";
import { createMvFormaDePagoSuscripcionReducers } from "./MvFormaDePagoSuscripcion";
import { createMvGeneroReducers } from "./MvGenero";
import { createMvImpuestosReducers } from "./MvImpuestos";
import { createMvMotivoPagoSuscripcionReducers } from "./MvMotivoPagoSuscripcion";
import { createMvTipoCanalVentaReducers } from "./MvTipoCanalVenta";
import { createMvTipoDocumentoReducers } from "./MvTipoDocumento";
import { createMvTipoEmpleadoReducers } from "./MvTIpoEmpleado";
import { createMvTipoServicioQentryReducers } from "./MvTipoServicioQentry";
import { createMvTipoSuscripcionReducers } from "./MvTipoSuscripcion";
import { createMvTipoUsuarioReducers } from "./MvTipoUsuario";
import { createMvUsoFacturaReducers } from "./MvUsoFactura";
import { createPagoSuscripcionReducers } from "./PagoSuscripcion";
import { createPaisReducers } from "./Pais";
import { createServicioQentryReducers } from "./ServicioQentry";
import { createSolicitudCambioTelefonoAutorizadoReducers } from "./SolicitudCambioTelefonoAutorizado";
import { createSuscripcionQentryReducers } from "./SuscripcionQentry";
import { createTipoDocumentosPorPuestoReducers } from "./TipoDocumentosPorPuesto";
import { createUserReducers } from "./User";

export const personasReducers = {
  ...createAntiguedadEmpleadoReducers(),
  ...createCanalVentaReducers(),
  ...createCentroDeTrabajoReducers(),
  ...createClienteQentryReducers(),
  ...createCodigoPostalReducers(),
  ...createCodigoQrReducers(),
  ...createDatosFiscalesReducers(),
  ...createDatosPersonalesReducers(),
  ...createDireccionReducers(),
  ...createDocumentoReducers(),
  ...createEmpleadoReducers(),
  ...createEmpresaReducers(),
  ...createEstadoReducers(),
  ...createGeolocalizacionReducers(),
  ...createHelpersReducers(),
  ...createImagenReducers(),
  ...createInformacionBancariaReducers(),
  ...createInformacionContratoReducers(),
  ...createInformacionSuscripcionReducers(),
  ...createModificacionSuscripcionQentryReducers(),
  ...createModificadorNominaReducers(),
  ...createMvAntiguedadReducers(),
  ...createMvBancoReducers(),
  ...createMvEstadoCivilReducers(),
  ...createMvEstatusSolicitudCambioTelefonoAutorizadoReducers(),
  ...createMvFormaDePagoSuscripcionReducers(),
  ...createMvGeneroReducers(),
  ...createMvImpuestosReducers(),
  ...createMvMotivoPagoSuscripcionReducers(),
  ...createMvTipoCanalVentaReducers(),
  ...createMvTipoDocumentoReducers(),
  ...createMvTipoEmpleadoReducers(),
  ...createMvTipoServicioQentryReducers(),
  ...createMvTipoSuscripcionReducers(),
  ...createMvTipoUsuarioReducers(),
  ...createMvUsoFacturaReducers(),
  ...createPagoSuscripcionReducers(),
  ...createPaisReducers(),
  ...createServicioQentryReducers(),
  ...createSolicitudCambioTelefonoAutorizadoReducers(),
  ...createSuscripcionQentryReducers(),
  ...createTipoDocumentosPorPuestoReducers(),
  ...createUserReducers(),
}