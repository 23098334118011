import { getArrayItemStorageData, getStorageData, removeArrayItemStorageData, removeStorageData, setArrayItemStorageData, setStorageData } from "../../api/storage";
import { getEmptyDireccion, IDireccion } from "../../interfaces/personas";

export const setStorageDireccion = async (IDireccion : IDireccion) => await setStorageData('IDireccion', IDireccion);
export const getStorageDireccion = async () : Promise<IDireccion> => await getStorageData('IDireccion', getEmptyDireccion());
export const removeStorageDireccion = async () => await removeStorageData('IDireccion');
export const setStorageDireccionArray = async (IDireccion : IDireccion[]) => await setStorageData('IDireccionArray', IDireccion);
export const getStorageDireccionArray = async () : Promise<IDireccion[]> => await getStorageData('IDireccionArray', [] as IDireccion[]);
export const removeStorageDireccionArray = async () => await removeStorageData('IDireccionArray');
export const setStorageDireccionArrayItem = async (id: number, IDireccion : IDireccion) : Promise<IDireccion[]> => await setArrayItemStorageData('IDireccionArray', id, IDireccion, [] as IDireccion[]);
export const getStorageDireccionArrayItem = async (id: number) : Promise<IDireccion> => await getArrayItemStorageData('IDireccionArray', id, getEmptyDireccion);
export const removeStorageDireccionArrayItem = async (id: number) : Promise<IDireccion[]> => await removeArrayItemStorageData('IDireccionArray', id, [] as IDireccion[]);
