/**
 * Métodos del, get, post, put para llamadas a la API
 * @get     en este método primero consulta con @getFromLocalStorage si es necesario hacer la petición http sino se obtiene del LocalStorage;
 *          si hay un error en la petición http se busca el objeto en el LocalStorage sino lo encuentra devuelve un objeto vacío; si la
 *          petición se realiza correctamente actualiza el valor individual y en el array del objeto en el LocalStorage y devuelve el objeto
 *          obtenido del servidor.
 * @getAll  en este método primero consulta con @getFromLocalStorage si es necesario hacer la petición http sino se obtiene del LocalStorage;
 *          si hay un error en la petición http se busca el objeto array en el LocalStorage sino lo encuentra devuelve un array vacío; si la
 *          petición se realiza correctamente actualiza array en el LocalStorage y devuelve el array obtenido del servidor
 * @update  en este método después de realizar la petición http si falla devuelve el objeto que se iba a actualizar; si la petición se
 *          realiza correctamente actualiza el valor individual y en el array del objeto en el LocalStorage y devuelve el objeto obtenido del
 *          servidor.
 * @create  en este método después de realizar la petición http si falla devuelve el objeto que se iba a crear; si la petición se realiza 
 *          correctamente actualiza el valor individual y en el array del objeto en el LocalStorage y devuelve el objeto obtenido del
 *          servidor.
 * @delete  en este método después de realizar la petición http si falla devuelve el objeto que se iba a eliminar; si la petición se realiza 
 *          correctamente elimina el valor individual y en el objeto en el arrya del LocalStorage y devuelve un objeto vacio (Default)
 */

import { del, get, post, put } from "../../api/api";
import { getFromLocalStorage, updateLocalStorageExpiry } from "../../api/helpers";
import { removeArrayItemStorageData } from "../../api/storage";
import { DEFAULT_STATE } from "../../enums/default_state";
import { getEmptyStatusDatosPersonales, setDatosPersonales, IDatosPersonales, getSerializableDatosPersonales } from "../../interfaces/personas";
import { IServerDate } from "../../interfaces/time";
import { getStorageDatosPersonalesArrayItem, setStorageDatosPersonales, setStorageDatosPersonalesArrayItem, getStorageDatosPersonalesArray, setStorageDatosPersonalesArray, removeStorageDatosPersonales } from "../../localStorage/personas";

export const getDatosPersonales = async (serverDate: IServerDate, pk : number, idCliente : number) => {
  const temp = getEmptyStatusDatosPersonales();
  const doHttp = await getFromLocalStorage(serverDate, temp.expiry);
  temp.expiry = doHttp.storage;
  if (!doHttp.result) {
    console.log(`(localStorage) DatosPersonales con pk: ${pk}, obtenido con éxito`);
    temp.estado = DEFAULT_STATE.success;
    temp.mensaje = `DatosPersonales obtenida con éxito`;
    temp.data = await getStorageDatosPersonalesArrayItem(pk);
    return temp;
  }
  const result = await get(`personas/datos-personales/${pk}/${idCliente}`);
  const data = result.data;
  if (!data.id || data.detail) {
    console.log(`Error al obtener DatosPersonales con pk: ${pk}, ${data.detail}`);
    temp.estado = DEFAULT_STATE.error;
    temp.mensaje =  `Error al obtener la datos personales: ${data.detail ?? "Hubo un problema al conectarse con el servidor"}`;
    temp.data = await getStorageDatosPersonalesArrayItem(pk);
    return temp;
  }
  console.log(`DatosPersonales con pk: ${pk}, obtenido con éxito`);
  temp.estado = DEFAULT_STATE.success;
  temp.mensaje = `DatosPersonales obtenida con éxito`;
  temp.data = await setDatosPersonales(data, serverDate);
  await setStorageDatosPersonales(temp.data);
  temp.data_array = await setStorageDatosPersonalesArrayItem(pk, temp.data);
  temp.expiry = await updateLocalStorageExpiry(serverDate, temp.expiry);
  return temp;
}

export const getAllDatosPersonales = async (serverDate: IServerDate, idCliente : number) => {
  const temp = getEmptyStatusDatosPersonales();
  const doHttp = await getFromLocalStorage(serverDate, temp.expiry_array);
  temp.expiry_array = doHttp.storage;
  if (!doHttp.result) {
    console.log(`(localStorage) Todos las DatosPersonaless obtenidas con éxito`);
    temp.estado = DEFAULT_STATE.success;
    temp.mensaje = `Todos las datos personales obtenidas con éxito`;
    temp.data_array = await getStorageDatosPersonalesArray();
  }
  const result = await get(`personas/datos-personales/${idCliente}`);
  const data = result.data;
  if (data.detail) {
    console.log(`Error al obtener la lista de DatosPersonales`, data);
    temp.estado = DEFAULT_STATE.error;
    temp.mensaje = `Error al obtener la lista de datos personales: ${data.detail ?? "Hubo un problema al conectarse con el servidor"}`;
    temp.data_array = await getStorageDatosPersonalesArray();
    return temp;
  }
  console.log(`Todos las DatosPersonaless obtenidos con éxito`);
  temp.estado = DEFAULT_STATE.success;
  temp.mensaje = `Todos las datos personales obtenidas con éxito`;
  temp.data_array = await Promise.all(data.map(async (e : any) => await setDatosPersonales(e, serverDate)));
  await setStorageDatosPersonalesArray(temp.data_array);
  temp.expiry_array = await updateLocalStorageExpiry(serverDate, temp.expiry_array);
  return temp;
}

export const updateDatosPersonales = async (serverDate: IServerDate, pk : number, idCliente : number, object : IDatosPersonales) => {
  const temp = getEmptyStatusDatosPersonales();
  const result = await put(`personas/datos-personales/${pk}/${idCliente}`, getSerializableDatosPersonales(object));
  const data = result.data;
  if (!data.id || data.detail) {
    console.log(`Error al actualizar DatosPersonales con pk: ${pk}, ${data.detail}`);
    temp.estado = DEFAULT_STATE.error;
    temp.mensaje = `Error al actualizar la datos personales: ${data.detail ?? "Hubo un problema al conectarse con el servidor"}`;
    temp.data = await setDatosPersonales(object, serverDate);
    return temp;
  }
  console.log(`DatosPersonales con pk: ${pk}, actualizado con éxito`);
  temp.estado = DEFAULT_STATE.success;
  temp.mensaje = `DatosPersonales actualizada con éxito`;
  temp.data = await setDatosPersonales(data, serverDate);
  await setStorageDatosPersonales(temp.data);
  temp.data_array = await setStorageDatosPersonalesArrayItem(temp.data.id, temp.data);
  return temp;
}

export const createDatosPersonales = async (serverDate: IServerDate, idCliente : number, object : IDatosPersonales) => {
  const temp = getEmptyStatusDatosPersonales();
  const result = await post(`personas/datos-personales/${idCliente}`, getSerializableDatosPersonales(object));
  const data = result.data;
  if (!data.id || data.detail) {
    console.log(`Error al crear DatosPersonales con los datos ${object}, ${data.detail}`);
    temp.estado = DEFAULT_STATE.error;
    temp.mensaje = `Error al crear la datos personales: ${data.detail ?? "Hubo un problema al conectarse con el servidor"}`;
    temp.data = await setDatosPersonales(object, serverDate);
    return temp;
  }
  console.log(`DatosPersonales creada con éxito`);
  temp.estado = DEFAULT_STATE.success;
  temp.mensaje = `DatosPersonales creada con éxito`;
  temp.data = await setDatosPersonales(data, serverDate);
  await setStorageDatosPersonales(temp.data);
  temp.data_array = await setStorageDatosPersonalesArrayItem(temp.data.id, temp.data);
  return temp;
}

export const deleteDatosPersonales = async (pk : number, idCliente : number) => {
  const temp = getEmptyStatusDatosPersonales();
  const result = await del(`personas/datos-personales/${pk}/${idCliente}`);
  const data = result.data;
  if (data.detail) {
    console.log(`Error al eliminar DatosPersonales con pk: ${pk}, ${data.detail}`);
    temp.estado = DEFAULT_STATE.error;
    temp.mensaje = `Error al eliminar la datos personales: ${data.detail}`;
    temp.data = await getStorageDatosPersonalesArrayItem(pk);
    temp.data_array = await getStorageDatosPersonalesArray();
    return temp;
  }
  console.log(`DatosPersonales eliminada con éxito`);
  temp.estado = DEFAULT_STATE.success;
  temp.mensaje = `DatosPersonales eliminada con éxito`;
  await removeStorageDatosPersonales();
  temp.data_array = await removeArrayItemStorageData("IDatosPersonales", pk, []);
  return temp;
}
