// eslint-disable-next-line
import { Drivers, Storage } from '@ionic/storage';
import { isNotEmpty } from './helpers';

let storage : Storage;

export const createStore = async (name = "QEntry_Admin") => {
  if (storage) return;
  storage = new Storage({      
      name,
      driverOrder: [Drivers.IndexedDB, Drivers.LocalStorage]
  });
  await storage.create();
}

export const setStorageData = async (key : string, data : any) => await storage.set(key, data)/*.then(() => console.log(`(saved) ${key}: `, data)).catch((err) => console.log(`(error) ${key}: `, err))*/;

export const getStorageData = async (key : string, emptyObject : any) => await storage.get(key) ?? emptyObject;

export const removeStorageData = async (key : string) => await storage.remove(key);

export const setArrayItemStorageData = async (key : string, id : number, newValue : any, emptyArray : any[]) => {
  let array = await storage.get(key);
  if (isNotEmpty(array)) {
    const index = array.findIndex((e : any)  => e.id === id);
    if (index > -1)  array[index] = newValue;
    else array.push(newValue);
  } else {
    array = [];
    array.push(newValue);
  }
  array.sort((a : any, b: any) => a.id - b.id);
  await setStorageData(key, array);
  return array;
};

export const getArrayItemStorageData = async (key : string, id : number, emptyObject : Function) => {
  const array = await storage.get(key);
  if (!isNotEmpty(array)) return emptyObject(id);
  const index = array.findIndex((e : any)  => e.id === id);
  if (index === -1) return emptyObject(id);
  return array[index];
};

export const removeArrayItemStorageData = async (key : string, id : number, emptyArray : any[]) => {
  const _key = `${key}Array`
  let array = await storage.get(_key);
  if (!isNotEmpty(array)) return emptyArray;
  const index = array.findIndex((e : any)  => e.id === id);
  if (index > -1)  array.splice(index, 1);
  await setStorageData(_key, array);
  return array;
}

export const clearAllData = async () => await storage.clear();

export const getAllKeys = async () => await storage.keys();

export const getStorageLength = async () => await storage.length();
