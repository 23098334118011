/**
 * Es el componente utilizado en la página Reportes para la visualización y descarga de reportes
 * @function getFile obtiene el archivo del reporte a descargar con la url del documento
 * @function download realiza el proceso de descarga del reporte seleccionado
 */

import React, { useRef, useState } from "react";
import {
  IonGrid,
  IonRow,
  IonCol,
  IonDatetime,
  IonLabel,
  IonButton,
  IonItem,
  IonContent,
  useIonToast,
  IonText,
  IonInput,
  IonPopover,
  IonSpinner,
  IonButtons,
} from "@ionic/react";
import "./Reports.css";

import { documentsSharp } from "ionicons/icons";

import Card from "../components/Card";
import Selector from "../components/Selector";
import { getOnlyDateString, getReportsDateString } from "../redux/api/helpers";
import useRedux, { useAppSelector } from "../hooks/useRedux";
import { getGlobalMemoizedEmpresaAsSelectList } from "../redux/slices/personasSlice";
import {
  getMemoizedReportsActiveCentroDetrabajoAsSelectList,
  getMemoizedReportsActiveEmpleadoAsSelectList,
  getMemoizedReportsReportTypeAsSelectList,
  setReportsSelectedCentroDeTrabajo,
  setReportsSelectedEmpleado,
  setReportsSelectedEmpresa,
  setReportsSelectedReport,
} from "../redux/slices/pagesSlice/reportsSlice";
import { post } from "../redux/api/api";
import { IDocumento, setDocumento } from "../redux/interfaces/personas";
import axios from "axios";

var FileDownload = require("js-file-download");

const Reports: React.FC = () => {
  const [{ serverDate }] = useRedux();

  const empresaList = useAppSelector(getGlobalMemoizedEmpresaAsSelectList);
  const selectedEmpresa = useAppSelector(
    (state) => state.reports.selectedEmpresa
  );

  const reportsList = useAppSelector(getMemoizedReportsReportTypeAsSelectList);
  const selectedReport = useAppSelector(
    (state) => state.reports.selectedReport
  );

  const empleadoList = useAppSelector(
    getMemoizedReportsActiveEmpleadoAsSelectList
  );
  const selectedEmpleado = useAppSelector(
    (state) => state.reports.selectedEmpleado
  );

  const centroDeTrabajoList = useAppSelector(
    getMemoizedReportsActiveCentroDetrabajoAsSelectList
  );
  const selectedCentroDeTrabajo = useAppSelector(
    (state) => state.reports.selectedCentroDeTrabajo
  );

  let [selectedIniDate, setSelectedIniDate] = useState<string>(
    getOnlyDateString()
  );
  let [selectedFinDate, setSelectedFinDate] = useState<string>(
    getOnlyDateString()
  );

  const datetimeIni = useRef<null | HTMLIonDatetimeElement>(null);
  const datetimeFin = useRef<null | HTMLIonDatetimeElement>(null);

  const popoverIni = useRef<null | HTMLIonPopoverElement>(null);
  const popoverFin = useRef<null | HTMLIonPopoverElement>(null);

  const confirm = (ini: boolean) => {
    if (ini) {
      datetimeIni.current?.confirm();
      popoverIni.current?.dismiss();
      return;
    }

    datetimeFin.current?.confirm();
    popoverFin.current?.dismiss();
  };

  let [downloading, isDownloading] = useState<boolean>(false);

  let [presentToast, dismissToast] = useIonToast();

  const getFile = async (data: IDocumento) => {
    axios
      .get(data.documento, {
        responseType: "blob",
      })
      .then((res) => {
        FileDownload(res.data, `${data.nombre_documento}.xlsx`);
        isDownloading(false);
      })
      .catch((err) => {
        isDownloading(false);
        presentToast({
          message: "Ocurrió un problema al descargar el reporte",
          color: "danger",
          duration: 1500,
        });
      });
  };

  const download = async () => {
    switch (selectedReport.id) {
      case "1": {
        if (!selectedEmpleado.id) {
          presentToast({
            message: "Por favor seleccione un empleado",
            color: "warning",
            duration: 1500,
          });
          return;
        }
        isDownloading(true);
        const result = await post(`reportes/historial-empleado-fecha`, {
          id_empleado: Number(selectedEmpleado.id),
          fecha: getReportsDateString(selectedIniDate),
        });
        if (!result.data.id) {
          isDownloading(false);
          presentToast({
            message: "Ocurrió un problema al descargar el reporte",
            color: "danger",
            duration: 1500,
          });
          return;
        }
        const data = await setDocumento(result.data, serverDate.data);
        getFile(data);
        break;
      }
      case "2": {
        if (!selectedEmpresa.id) {
          presentToast({
            message: "Por favor seleccione una empresa",
            color: "warning",
            duration: 1500,
          });
          return;
        }
        isDownloading(true);
        const result = await post(
          `reportes/historial-empleados-empresa-fecha`,
          {
            id_empresa: Number(selectedEmpresa.id),
            fecha: getReportsDateString(selectedIniDate),
          }
        );
        if (!result.data.id) {
          isDownloading(false);
          presentToast({
            message: "Ocurrió un problema al descargar el reporte",
            color: "danger",
            duration: 1500,
          });
          return;
        }
        const data = await setDocumento(result.data, serverDate.data);
        getFile(data);
        break;
      }
      case "3": {
        if (!selectedEmpresa.id) {
          presentToast({
            message: "Por favor seleccione una empresa",
            color: "warning",
            duration: 1500,
          });
          return;
        }
        isDownloading(true);
        const result = await post(`reportes/empleados-empresa`, {
          id_empresa: Number(selectedEmpresa.id),
        });
        if (!result.data.id) {
          isDownloading(false);
          presentToast({
            message: "Ocurrió un problema al descargar el reporte",
            color: "danger",
            duration: 1500,
          });
          return;
        }
        const data = await setDocumento(result.data, serverDate.data);
        getFile(data);
        break;
      }
      case "4": {
        if (!selectedCentroDeTrabajo.id) {
          presentToast({
            message: "Por favor seleccione un centro de trabajo",
            color: "warning",
            duration: 1500,
          });
          return;
        }
        isDownloading(true);
        const result = await post(`reportes/empleados-centro-trabajo`, {
          id_centro: Number(selectedCentroDeTrabajo.id),
        });
        if (!result.data.id) {
          isDownloading(false);
          presentToast({
            message: "Ocurrió un problema al descargar el reporte",
            color: "danger",
            duration: 1500,
          });
          return;
        }
        const data = await setDocumento(result.data, serverDate.data);
        getFile(data);
        break;
      }
      case "5": {
        if (!selectedCentroDeTrabajo.id) {
          presentToast({
            message: "Por favor seleccione un centro de trabajo",
            color: "warning",
            duration: 1500,
          });
          return;
        }
        isDownloading(true);
        const result = await post(`reportes/empleados-laborando-fecha`, {
          id_centro: Number(selectedCentroDeTrabajo.id),
          fecha: getReportsDateString(selectedIniDate),
        });
        if (!result.data.id) {
          isDownloading(false);
          presentToast({
            message: "Ocurrió un problema al descargar el reporte",
            color: "danger",
            duration: 1500,
          });
          return;
        }
        const data = await setDocumento(result.data, serverDate.data);
        getFile(data);
        break;
      }
      case "6": {
        if (!selectedEmpleado.id) {
          presentToast({
            message: "Por favor seleccione un empleado",
            color: "warning",
            duration: 1500,
          });
          return;
        }
        isDownloading(true);
        const result = await post(`reportes/incidencias-empleado-fechas`, {
          id_empleado: Number(selectedEmpleado.id),
          fecha_inicio: getReportsDateString(selectedIniDate),
          fecha_fin: getReportsDateString(selectedFinDate),
        });
        if (!result.data.id) {
          isDownloading(false);
          presentToast({
            message: "Ocurrió un problema al descargar el reporte",
            color: "danger",
            duration: 1500,
          });
          return;
        }
        const data = await setDocumento(result.data, serverDate.data);
        getFile(data);
        break;
      }
      case "7": {
        if (!selectedEmpleado.id) {
          presentToast({
            message: "Por favor seleccione un empleado",
            color: "warning",
            duration: 1500,
          });
          return;
        }
        isDownloading(true);
        const result = await post(
          `reportes/resumen-incidencias-empleado-fechas`,
          {
            id_empleado: Number(selectedEmpleado.id),
            fecha_inicio: getReportsDateString(selectedIniDate),
            fecha_fin: getReportsDateString(selectedFinDate),
          }
        );
        if (!result.data.id) {
          isDownloading(false);
          presentToast({
            message: "Ocurrió un problema al descargar el reporte",
            color: "danger",
            duration: 1500,
          });
          return;
        }
        const data = await setDocumento(result.data, serverDate.data);
        getFile(data);
        break;
      }
      case "8": {
        if (!selectedCentroDeTrabajo.id) {
          presentToast({
            message: "Por favor seleccione un centro de trabajo",
            color: "warning",
            duration: 1500,
          });
          return;
        }
        isDownloading(true);
        const result = await post(
          `reportes/resumen-incidencias-empleados-centro-trabajo-fechas`,
          {
            id_centro: Number(selectedCentroDeTrabajo.id),
            fecha_inicio: getReportsDateString(selectedIniDate),
            fecha_fin: getReportsDateString(selectedFinDate),
          }
        );
        if (!result.data.id) {
          isDownloading(false);
          presentToast({
            message: "Ocurrió un problema al descargar el reporte",
            color: "danger",
            duration: 1500,
          });
          return;
        }
        const data = await setDocumento(result.data, serverDate.data);
        getFile(data);
        break;
      }
      case "9": {
        if (!selectedEmpresa.id) {
          presentToast({
            message: "Por favor seleccione una empresa",
            color: "warning",
            duration: 1500,
          });
          return;
        }
        isDownloading(true);
        const result = await post(
          `reportes/resumen-incidencias-empleados-empresa-fechas`,
          {
            id_empresa: Number(selectedEmpresa.id),
            fecha_inicio: getReportsDateString(selectedIniDate),
            fecha_fin: getReportsDateString(selectedFinDate),
          }
        );
        if (!result.data.id) {
          isDownloading(false);
          presentToast({
            message: "Ocurrió un problema al descargar el reporte",
            color: "danger",
            duration: 1500,
          });
          return;
        }
        const data = await setDocumento(result.data, serverDate.data);
        getFile(data);
        break;
      }
      case "10": {
        if (!selectedCentroDeTrabajo.id) {
          presentToast({
            message: "Por favor seleccione un centro de trabajo",
            color: "warning",
            duration: 1500,
          });
          return;
        }
        isDownloading(true);
        const result = await post(`reportes/incidencias-centro-fechas`, {
          id_centro: Number(selectedCentroDeTrabajo.id),
          fecha_inicio: getReportsDateString(selectedIniDate),
          fecha_fin: getReportsDateString(selectedFinDate),
        });
        if (!result.data.id) {
          isDownloading(false);
          presentToast({
            message: "Ocurrió un problema al descargar el reporte",
            color: "danger",
            duration: 1500,
          });
          return;
        }
        const data = await setDocumento(result.data, serverDate.data);
        getFile(data);
        break;
      }
      case "11": {
        if (!selectedEmpleado.id) {
          presentToast({
            message: "Por favor seleccione un empleado",
            color: "warning",
            duration: 1500,
          });
          return;
        }
        isDownloading(true);
        const result = await post(`reportes/horario-empleado`, {
          id_empleado: Number(selectedEmpleado.id),
        });
        if (!result.data.id) {
          isDownloading(false);
          presentToast({
            message: "Ocurrió un problema al descargar el reporte",
            color: "danger",
            duration: 1500,
          });
          return;
        }
        const data = await setDocumento(result.data, serverDate.data);
        getFile(data);
        break;
      }
      case "12": {
        if (!selectedEmpresa.id) {
          presentToast({
            message: "Por favor seleccione una empresa",
            color: "warning",
            duration: 1500,
          });
          return;
        }
        isDownloading(true);
        const result = await post(`reportes/usuarios-activos-empresa`, {
          id_empresa: Number(selectedEmpresa.id),
        });
        if (!result.data.id) {
          isDownloading(false);
          presentToast({
            message: "Ocurrió un problema al descargar el reporte",
            color: "danger",
            duration: 1500,
          });
          return;
        }
        const data = await setDocumento(result.data, serverDate.data);
        getFile(data);
        break;
      }
    }
  };

  return (
    <IonContent class="inner-content">
      <IonGrid>
        <IonRow>
          <IonCol>
            <Card
              icon={documentsSharp}
              title={
                <IonLabel>
                  Descargar Reporte <IonText color="danger">*</IonText>
                </IonLabel>
              }
              subtitle={
                <Selector
                  value={selectedEmpresa}
                  label="Cuentas/Regiones"
                  list={empresaList}
                  changeState={setReportsSelectedEmpresa}
                />
              }
              //content="prueba"
              content={
                <IonGrid>
                  <IonRow>
                    <IonCol size="12">
                      <Selector
                        value={selectedReport}
                        label="Reportes"
                        list={reportsList}
                        changeState={setReportsSelectedReport}
                      />
                    </IonCol>
                    {Number(selectedReport.id) === 1 ||
                    Number(selectedReport.id) === 6 ||
                    Number(selectedReport.id) === 7 ||
                    Number(selectedReport.id) === 11 ? (
                      <IonCol size="12">
                        <Selector
                          value={selectedEmpleado}
                          label="Empleado"
                          list={empleadoList}
                          changeState={setReportsSelectedEmpleado}
                        />
                      </IonCol>
                    ) : (
                      <></>
                    )}
                    {Number(selectedReport.id) === 4 ||
                    Number(selectedReport.id) === 5 ||
                    Number(selectedReport.id) === 8 ||
                    Number(selectedReport.id) === 10 ? (
                      <IonCol size="12">
                        <Selector
                          value={selectedCentroDeTrabajo}
                          label="Centro de trabajo"
                          list={centroDeTrabajoList}
                          changeState={setReportsSelectedCentroDeTrabajo}
                        />
                      </IonCol>
                    ) : (
                      <></>
                    )}
                    {Number(selectedReport.id) < 11 &&
                    Number(selectedReport.id) !== 3 &&
                    Number(selectedReport.id) !== 4 ? (
                      <IonCol size="12" sizeMd="6" sizeLg="6">
                        <IonItem className="modal-input">
                          <IonLabel position="stacked">
                            Fecha de inicial
                          </IonLabel>
                          <IonInput
                            id="report-ini-date"
                            type="text"
                            color="secondary"
                            value={selectedIniDate}
                            className="custom-picker"
                          />

                          <IonPopover
                            ref={popoverIni}
                            className="custom-picker"
                            id="report-ini-picker"
                            animated={false}
                            backdropDismiss={true}
                            trigger="report-ini-date"
                            triggerAction="click"
                            show-backdrop="true"
                            class="date-picker"
                          >
                            <IonDatetime
                              ref={datetimeIni}
                              className="custom-picker"
                              id="report-ini-datetime"
                              color="primary"
                              presentation="date"
                              placeholder="Seleccionar fecha"
                              min="2017-01-01"
                              value={selectedIniDate}
                              onIonChange={async (e) => {
                                setSelectedIniDate(
                                  getOnlyDateString(e.detail.value as string)
                                );
                              }}
                            >
                              <IonButtons slot="buttons">
                                <IonButton
                                  color="primary"
                                  onClick={(e) => confirm(true)}
                                >
                                  Confirmar
                                </IonButton>
                              </IonButtons>
                            </IonDatetime>
                          </IonPopover>
                        </IonItem>
                      </IonCol>
                    ) : (
                      <></>
                    )}
                    {Number(selectedReport.id) >= 6 &&
                    Number(selectedReport.id) < 11 ? (
                      <IonCol size="12" sizeMd="6" sizeLg="6">
                        <IonItem className="modal-input">
                          <IonLabel position="stacked">Fecha de final</IonLabel>
                          <IonInput
                            id="report-fin-date"
                            type="text"
                            color="secondary"
                            value={selectedFinDate}
                            className="custom-picker"
                          />

                          <IonPopover
                            ref={popoverFin}
                            className="custom-picker"
                            id="report-fin-picker"
                            animated={false}
                            backdropDismiss={true}
                            trigger="report-fin-date"
                            triggerAction="click"
                            show-backdrop="true"
                            class="date-picker"
                          >
                            <IonDatetime
                              ref={datetimeFin}
                              className="custom-picker"
                              id="report-fin-datetime"
                              color="primary"
                              presentation="date"
                              placeholder="Seleccionar fecha"
                              min="2017-01-01"
                              value={selectedFinDate}
                              onIonChange={async (e) => {
                                setSelectedFinDate(
                                  getOnlyDateString(e.detail.value as string)
                                );
                              }}
                            >
                              <IonButtons slot="buttons">
                                <IonButton
                                  color="primary"
                                  onClick={(e) => confirm(false)}
                                >
                                  Confirmar
                                </IonButton>
                              </IonButtons>
                            </IonDatetime>
                          </IonPopover>
                        </IonItem>
                      </IonCol>
                    ) : (
                      <></>
                    )}
                  </IonRow>
                  <IonRow>
                    <IonCol size="12">
                      <IonButton
                        disabled={downloading}
                        onClick={() => download()}
                        expand="block"
                      >
                        {downloading ? (
                          <IonSpinner
                            className="loading-center-horizontal"
                            name="circles"
                          />
                        ) : (
                          <IonLabel>Descargar</IonLabel>
                        )}
                      </IonButton>
                    </IonCol>
                  </IonRow>
                  <IonRow class="no-padding">
                    <IonCol size="12">
                      <IonLabel>
                        <IonText color="danger">*</IonText> Las horas estan en
                        zona horaria CDMX (GMT-5)
                      </IonLabel>
                    </IonCol>
                  </IonRow>
                </IonGrid>
              }
            />
          </IonCol>
        </IonRow>
      </IonGrid>
    </IonContent>
  );
};

export default Reports;
