/**
 * Reducers creados para obtener asíncronamente 
 * los datos de los modelos y dependiendo del resultado
 * de la peticón actualice el state en redux o mande un mensaje de error
 */

import { PayloadAction } from "@reduxjs/toolkit";
import { getPuestoAsyncThunk, getAllPuestoAsyncThunk, updatePuestoAsyncThunk, createPuestoAsyncThunk, deletePuestoAsyncThunk } from "../../asyncThunk/nomina";
import { DEFAULT_STATE } from "../../enums/default_state";
import { IStatusPuesto } from "../../interfaces/nomina";
import { nominaInitialState } from "../../slices/nominaSlice";

// Reducers: Puesto
export const createPuestoReducers = () => {
  const get = () => {
    var { pending, fulfilled, rejected } = getPuestoAsyncThunk;
    return {
      [pending.type]: (state : typeof nominaInitialState) => {
        state.nominaState.puesto.estado = DEFAULT_STATE.loading;
      },
      [fulfilled.type]: (state : typeof nominaInitialState, action : PayloadAction<IStatusPuesto>) => {
        const result = action.payload;
        result.expiry_array = state.nominaState.puesto.expiry_array;
        if (result.estado === DEFAULT_STATE.error) result.data_array = state.nominaState.puesto.data_array;
        state.nominaState.puesto = result;
      },
      [rejected.type]: (state : typeof nominaInitialState, action : PayloadAction<IStatusPuesto>) => {
        state.nominaState.puesto = {
          ...state.nominaState.puesto,
          estado: action.payload.estado,
          mensaje: "Ocurrió un problema al realizar la solicitud",
        };
      },
    };
  };
  
  const getAll = () => {
    var { pending, fulfilled, rejected } = getAllPuestoAsyncThunk;
    return {
      [pending.type]: (state : typeof nominaInitialState) => {
        state.nominaState.puesto.estado = DEFAULT_STATE.loading;
      },
      [fulfilled.type]: (state : typeof nominaInitialState, action : PayloadAction<IStatusPuesto>) => {
        const result = action.payload;
        result.data = state.nominaState.puesto.data;
        result.expiry = state.nominaState.puesto.expiry;
        if (result.estado === DEFAULT_STATE.error) result.data_array = state.nominaState.puesto.data_array;
        state.nominaState.puesto = result;
      },
      [rejected.type]: (state : typeof nominaInitialState, action : PayloadAction<IStatusPuesto>) => {
        state.nominaState.puesto = {
          ...state.nominaState.puesto,
          estado: action.payload.estado,
          mensaje: "Ocurrió un problema al realizar la solicitud",
        };
      },
    };
  };
  
  const update = () => {
    var { pending, fulfilled, rejected } = updatePuestoAsyncThunk;
    return {
      [pending.type]: (state : typeof nominaInitialState) => {
        state.nominaState.puesto.estado = DEFAULT_STATE.loading;
      },
      [fulfilled.type]: (state : typeof nominaInitialState, action : PayloadAction<IStatusPuesto>) => {
        const result: IStatusPuesto = action.payload;
        result.expiry = state.nominaState.puesto.expiry;
        result.expiry_array = state.nominaState.puesto.expiry_array;
        if (result.estado === DEFAULT_STATE.error) result.data_array = state.nominaState.puesto.data_array;
        state.nominaState.puesto = result;
      },
      [rejected.type]: (state : typeof nominaInitialState, action : PayloadAction<IStatusPuesto>) => {
        state.nominaState.puesto = {
          ...state.nominaState.puesto,
          estado: action.payload.estado,
          mensaje: "Ocurrió un problema al realizar la solicitud",
        };
      },
    };
  };

  const create = () => {
    var { pending, fulfilled, rejected } = createPuestoAsyncThunk;
    return {
      [pending.type]: (state : typeof nominaInitialState) => {
        state.nominaState.puesto.estado = DEFAULT_STATE.loading;
      },
      [fulfilled.type]: (state : typeof nominaInitialState, action : PayloadAction<IStatusPuesto>) => {
        const result = action.payload;
        result.expiry = state.nominaState.puesto.expiry;
        result.expiry_array = state.nominaState.puesto.expiry_array;
        if (result.estado === DEFAULT_STATE.error) result.data_array = state.nominaState.puesto.data_array;
        state.nominaState.puesto = result;
      },
      [rejected.type]: (state : typeof nominaInitialState, action : PayloadAction<IStatusPuesto>) => {
        state.nominaState.puesto = {
          ...state.nominaState.puesto,
          estado: action.payload.estado,
          mensaje: "Ocurrió un problema al realizar la solicitud",
        };
      },
    };
  };
  
  const del = () => {
    var { pending, fulfilled, rejected } = deletePuestoAsyncThunk;
    return {
      [pending.type]: (state : typeof nominaInitialState) => {
        state.nominaState.puesto.estado = DEFAULT_STATE.loading;
      },
      [fulfilled.type]: (state : typeof nominaInitialState, action : PayloadAction<IStatusPuesto>) => {
        const result: IStatusPuesto = action.payload;
        result.expiry = state.nominaState.puesto.expiry;
        result.expiry_array = state.nominaState.puesto.expiry_array;
        if (result.estado === DEFAULT_STATE.error) result.data_array = state.nominaState.puesto.data_array;
        state.nominaState.puesto = result;
      },
      [rejected.type]: (state : typeof nominaInitialState, action : PayloadAction<IStatusPuesto>) => {
        state.nominaState.puesto = {
          ...state.nominaState.puesto,
          estado: action.payload.estado,
          mensaje: "Ocurrió un problema al realizar la solicitud",
        };
      },
    };
  };

  return {
    ...get(),
    ...getAll(),
    ...update(),
    ...create(),
    ...del(),
  };
};
