/**
 * Métodos del, get, post, put para llamadas a la API
 * @get     en este método primero consulta con @getFromLocalStorage si es necesario hacer la petición http sino se obtiene del LocalStorage;
 *          si hay un error en la petición http se busca el objeto en el LocalStorage sino lo encuentra devuelve un objeto vacío; si la
 *          petición se realiza correctamente actualiza el valor individual y en el array del objeto en el LocalStorage y devuelve el objeto
 *          obtenido del servidor.
 * @getAll  en este método primero consulta con @getFromLocalStorage si es necesario hacer la petición http sino se obtiene del LocalStorage;
 *          si hay un error en la petición http se busca el objeto array en el LocalStorage sino lo encuentra devuelve un array vacío; si la
 *          petición se realiza correctamente actualiza array en el LocalStorage y devuelve el array obtenido del servidor
 * @update  en este método después de realizar la petición http si falla devuelve el objeto que se iba a actualizar; si la petición se
 *          realiza correctamente actualiza el valor individual y en el array del objeto en el LocalStorage y devuelve el objeto obtenido del
 *          servidor.
 * @create  en este método después de realizar la petición http si falla devuelve el objeto que se iba a crear; si la petición se realiza
 *          correctamente actualiza el valor individual y en el array del objeto en el LocalStorage y devuelve el objeto obtenido del
 *          servidor.
 * @delete  en este método después de realizar la petición http si falla devuelve el objeto que se iba a eliminar; si la petición se realiza
 *          correctamente elimina el valor individual y en el objeto en el arrya del LocalStorage y devuelve un objeto vacio (Default)
 */

import { del, get, post, put } from "../../api/api";
import {
  getFromLocalStorage,
  updateLocalStorageExpiry,
} from "../../api/helpers";
import { removeArrayItemStorageData } from "../../api/storage";
import { DEFAULT_STATE } from "../../enums/default_state";
import {
  getEmptyStatusCambioEstatusAsistencia,
  setCambioEstatusAsistencia,
  ICambioEstatusAsistencia,
  getSerializableCambioEstatusAsistencia,
} from "../../interfaces/nomina";
import { IServerDate } from "../../interfaces/time";
import {
  getStorageCambioEstatusAsistenciaArrayItem,
  setStorageCambioEstatusAsistencia,
  setStorageCambioEstatusAsistenciaArrayItem,
  getStorageCambioEstatusAsistenciaArray,
  setStorageCambioEstatusAsistenciaArray,
  removeStorageCambioEstatusAsistencia,
} from "../../localStorage/nomina";

export const getCambioEstatusAsistencia = async (
  serverDate: IServerDate,
  pk: number,
  idCliente: number
) => {
  const temp = getEmptyStatusCambioEstatusAsistencia();
  const doHttp = await getFromLocalStorage(serverDate, temp.expiry);
  temp.expiry = doHttp.storage;
  if (!doHttp.result) {
    console.log(
      `(localStorage) CambioEstatusAsistencia con pk: ${pk}, obtenido con éxito`
    );
    temp.estado = DEFAULT_STATE.success;
    temp.mensaje = `CambioEstatusAsistencia obtenida con éxito`;
    temp.data = await getStorageCambioEstatusAsistenciaArrayItem(pk);
    return temp;
  }
  const result = await get(
    `nomina/cambio-estatus-asistencia/${pk}/${idCliente}`
  );
  const data = result.data;
  console.log(`CambioEstatusAsistencia data: `, data);
  if (!data.id || data.detail) {
    console.log(
      `Error al obtener CambioEstatusAsistencia con pk: ${pk}, ${data.detail}`
    );
    temp.estado = DEFAULT_STATE.error;
    temp.mensaje = `Error al obtener la datos personales: ${
      data.detail ?? "Hubo un problema al conectarse con el servidor"
    }`;
    temp.data = await getStorageCambioEstatusAsistenciaArrayItem(pk);
    return temp;
  }
  console.log(`CambioEstatusAsistencia con pk: ${pk}, obtenido con éxito`);
  temp.estado = DEFAULT_STATE.success;
  temp.mensaje = `CambioEstatusAsistencia obtenida con éxito`;
  temp.data = await setCambioEstatusAsistencia(data, serverDate);
  await setStorageCambioEstatusAsistencia(temp.data);
  temp.data_array = await setStorageCambioEstatusAsistenciaArrayItem(
    pk,
    temp.data
  );
  temp.expiry = await updateLocalStorageExpiry(serverDate, temp.expiry);
  return temp;
};

export const getAllCambioEstatusAsistencia = async (
  serverDate: IServerDate,
  idCliente: number
) => {
  const temp = getEmptyStatusCambioEstatusAsistencia();
  const doHttp = await getFromLocalStorage(serverDate, temp.expiry_array);
  temp.expiry_array = doHttp.storage;
  if (!doHttp.result) {
    console.log(
      `(localStorage) Todos las CambioEstatusAsistencias obtenidas con éxito`
    );
    temp.estado = DEFAULT_STATE.success;
    temp.mensaje = `Todos las CambioEstatusAsistencias obtenidas con éxito`;
    temp.data_array = await getStorageCambioEstatusAsistenciaArray();
    // return temp;
  }
  const result = await get(`nomina/cambio-estatus-asistencia/${idCliente}`);
  const data = result.data;
  if (data.detail) {
    console.log(`Error al obtener la lista de CambioEstatusAsistencia`, data);
    temp.estado = DEFAULT_STATE.error;
    temp.mensaje = `Error al obtener la lista de CambioEstatusAsistencias: ${
      data.detail ?? "Hubo un problema al conectarse con el servidor"
    }`;
    temp.data_array = await getStorageCambioEstatusAsistenciaArray();
    return temp;
  }
  console.log(`Todos las CambioEstatusAsistencias obtenidos con éxito`);
  temp.estado = DEFAULT_STATE.success;
  temp.mensaje = `Todos las CambioEstatusAsistencias obtenidas con éxito`;
  temp.data_array = await Promise.all(
    data.map(async (e: any) => await setCambioEstatusAsistencia(e, serverDate))
  );
  await setStorageCambioEstatusAsistenciaArray(temp.data_array);
  temp.expiry_array = await updateLocalStorageExpiry(
    serverDate,
    temp.expiry_array
  );
  return temp;
};

export const updateCambioEstatusAsistencia = async (
  serverDate: IServerDate,
  pk: number,
  idCliente: number,
  object: ICambioEstatusAsistencia
) => {
  const temp = getEmptyStatusCambioEstatusAsistencia();
  const result = await put(
    `nomina/cambio-estatus-asistencia/${pk}/${idCliente}`,
    getSerializableCambioEstatusAsistencia(object)
  );
  const data = result.data;
  if (!data.id || data.detail) {
    console.log(
      `Error al actualizar CambioEstatusAsistencia con pk: ${pk}, ${data.detail}`
    );
    temp.estado = DEFAULT_STATE.error;
    temp.mensaje = `Error al actualizar la datos personales: ${
      data.detail ?? "Hubo un problema al conectarse con el servidor"
    }`;
    temp.data = await setCambioEstatusAsistencia(object, serverDate);
    return temp;
  }
  console.log(`CambioEstatusAsistencia con pk: ${pk}, actualizado con éxito`);
  temp.estado = DEFAULT_STATE.success;
  temp.mensaje = `CambioEstatusAsistencia actualizada con éxito`;
  temp.data = await setCambioEstatusAsistencia(data, serverDate);
  await setStorageCambioEstatusAsistencia(temp.data);
  temp.data_array = await setStorageCambioEstatusAsistenciaArrayItem(
    temp.data.id,
    temp.data
  );
  return temp;
};

export const createCambioEstatusAsistencia = async (
  serverDate: IServerDate,
  idCliente: number,
  object: ICambioEstatusAsistencia
) => {
  const temp = getEmptyStatusCambioEstatusAsistencia();
  const result = await post(
    `nomina/cambio-estatus-asistencia/${idCliente}`,
    getSerializableCambioEstatusAsistencia(object)
  );
  const data = result.data;
  if (!data.id || data.detail) {
    console.log(
      `Error al crear CambioEstatusAsistencia con los datos ${object}, ${data.detail}`
    );
    temp.estado = DEFAULT_STATE.error;
    temp.mensaje = `Error al crear la datos personales: ${
      data.detail ?? "Hubo un problema al conectarse con el servidor"
    }`;
    temp.data = await setCambioEstatusAsistencia(object, serverDate);
    return temp;
  }
  console.log(`CambioEstatusAsistencia creada con éxito`);
  temp.estado = DEFAULT_STATE.success;
  temp.mensaje = `CambioEstatusAsistencia creada con éxito`;
  temp.data = await setCambioEstatusAsistencia(data, serverDate);
  await setStorageCambioEstatusAsistencia(temp.data);
  temp.data_array = await setStorageCambioEstatusAsistenciaArrayItem(
    temp.data.id,
    temp.data
  );
  return temp;
};

export const deleteCambioEstatusAsistencia = async (
  pk: number,
  idCliente: number
) => {
  const temp = getEmptyStatusCambioEstatusAsistencia();
  const result = await del(
    `nomina/cambio-estatus-asistencia/${pk}/${idCliente}`
  );
  const data = result.data;
  if (data.detail) {
    console.log(
      `Error al eliminar CambioEstatusAsistencia con pk: ${pk}, ${data.detail}`
    );
    temp.estado = DEFAULT_STATE.error;
    temp.mensaje = `Error al eliminar la datos personales: ${data.detail}`;
    temp.data = await getStorageCambioEstatusAsistenciaArrayItem(pk);
    temp.data_array = await getStorageCambioEstatusAsistenciaArray();
    return temp;
  }
  console.log(`CambioEstatusAsistencia eliminada con éxito`);
  temp.estado = DEFAULT_STATE.success;
  temp.mensaje = `CambioEstatusAsistencia eliminada con éxito`;
  await removeStorageCambioEstatusAsistencia();
  temp.data_array = await removeArrayItemStorageData(
    "ICambioEstatusAsistencia",
    pk,
    []
  );
  return temp;
};
