/**
 * Métodos del, get, post, put para llamadas a la API
 * @get     en este método primero consulta con @getFromLocalStorage si es necesario hacer la petición http sino se obtiene del LocalStorage;
 *          si hay un error en la petición http se busca el objeto en el LocalStorage sino lo encuentra devuelve un objeto vacío; si la
 *          petición se realiza correctamente actualiza el valor individual y en el array del objeto en el LocalStorage y devuelve el objeto
 *          obtenido del servidor.
 * @getAll  en este método primero consulta con @getFromLocalStorage si es necesario hacer la petición http sino se obtiene del LocalStorage;
 *          si hay un error en la petición http se busca el objeto array en el LocalStorage sino lo encuentra devuelve un array vacío; si la
 *          petición se realiza correctamente actualiza array en el LocalStorage y devuelve el array obtenido del servidor
 * @update  en este método después de realizar la petición http si falla devuelve el objeto que se iba a actualizar; si la petición se
 *          realiza correctamente actualiza el valor individual y en el array del objeto en el LocalStorage y devuelve el objeto obtenido del
 *          servidor.
 * @create  en este método después de realizar la petición http si falla devuelve el objeto que se iba a crear; si la petición se realiza 
 *          correctamente actualiza el valor individual y en el array del objeto en el LocalStorage y devuelve el objeto obtenido del
 *          servidor.
 * @delete  en este método después de realizar la petición http si falla devuelve el objeto que se iba a eliminar; si la petición se realiza 
 *          correctamente elimina el valor individual y en el objeto en el arrya del LocalStorage y devuelve un objeto vacio (Default)
 */

import { del, get, post, put } from "../../api/api";
import { getFromLocalStorage, updateLocalStorageExpiry } from "../../api/helpers";
import { removeArrayItemStorageData } from "../../api/storage";
import { DEFAULT_STATE } from "../../enums/default_state";
import { getEmptyStatusEstado, setEstado, IEstado, getSerializableEstado } from "../../interfaces/personas";
import { IServerDate } from "../../interfaces/time";
import { getStorageEstadoArrayItem, setStorageEstado, setStorageEstadoArrayItem, getStorageEstadoArray, setStorageEstadoArray, removeStorageEstado } from "../../localStorage/personas";

export const getEstado = async (serverDate: IServerDate, pk : number) => {
  const temp = getEmptyStatusEstado();
  const doHttp = await getFromLocalStorage(serverDate, temp.expiry);
  temp.expiry = doHttp.storage;
  if (!doHttp.result) {
    console.log(`(localStorage) Estado con pk: ${pk}, obtenido con éxito`);
    temp.estado = DEFAULT_STATE.success;
    temp.mensaje = `Estado obtenido con éxito`;
    temp.data = await getStorageEstadoArrayItem(pk);
    return temp;
  }
  const result = await get(`personas/estado/${pk}`);
  const data = result.data;
  console.log(`Estado data: `, data);
  if (!data.id || data.detail) {
    console.log(`Error al obtener Estado con pk: ${pk}, ${data.detail}`);
    temp.estado = DEFAULT_STATE.error;
    temp.mensaje =  `Error al obtener el estado: ${data.detail ?? "Hubo un problema al conectarse con el servidor"}`;
    temp.data = await getStorageEstadoArrayItem(pk);
    return temp;
  }
  console.log(`Estado con pk: ${pk}, obtenido con éxito`);
  temp.estado = DEFAULT_STATE.success;
  temp.mensaje = `Estado obtenido con éxito`;
  temp.data = await setEstado(data, serverDate);
  await setStorageEstado(temp.data);
  temp.data_array = await setStorageEstadoArrayItem(pk, temp.data);
  temp.expiry = await updateLocalStorageExpiry(serverDate, temp.expiry);
  return temp;
}

export const getAllEstado = async (serverDate: IServerDate) => {
  const temp = getEmptyStatusEstado();
  const doHttp = await getFromLocalStorage(serverDate, temp.expiry_array);
  temp.expiry_array = doHttp.storage;
  if (!doHttp.result) {
    console.log(`(localStorage) Todos los Estados obtenidos con éxito`);
    temp.estado = DEFAULT_STATE.success;
    temp.mensaje = `Todos los estados obtenidos con éxito`;
    temp.data_array = await getStorageEstadoArray();
  }
  const result = await get(`personas/estado`);
  const data = result.data;
  if (data.detail) {
    console.log(`Error al obtener la lista de Estado, ${data.detail}`);
    temp.estado = DEFAULT_STATE.error;
    temp.mensaje = `Error al obtener la lista de estados: ${data.detail ?? "Hubo un problema al conectarse con el servidor"}`;
    temp.data_array = await getStorageEstadoArray();
    return temp;
  }
  console.log(`Todos los Estadoes obtenidos con éxito`);
  temp.estado = DEFAULT_STATE.success;
  temp.mensaje = `Todos los estados obtenidos con éxito`;
  temp.data_array = await Promise.all(data.map(async (e : any) => await setEstado(e, serverDate)));
  await setStorageEstadoArray(temp.data_array);
  temp.expiry_array = await updateLocalStorageExpiry(serverDate, temp.expiry_array);
  return temp;
}

export const updateEstado = async (serverDate: IServerDate, pk : number, object : IEstado) => {
  const temp = getEmptyStatusEstado();
  const result = await put(`personas/estado/${pk}`, getSerializableEstado(object));
  const data = result.data;
  if (!data.id || data.detail) {
    console.log(`Error al actualizar Estado con pk: ${pk}, ${data.detail}`);
    temp.estado = DEFAULT_STATE.error;
    temp.mensaje = `Error al actualizar el estado: ${data.detail ?? "Hubo un problema al conectarse con el servidor"}`;
    temp.data = await setEstado(object, serverDate);
    return temp;
  }
  console.log(`Estado con pk: ${pk}, actualizado con éxito`);
  temp.estado = DEFAULT_STATE.success;
  temp.mensaje = `Estado actualizado con éxito`;
  temp.data = await setEstado(data, serverDate);
  await setStorageEstado(temp.data);
  temp.data_array = await setStorageEstadoArrayItem(temp.data.id, temp.data);
  return temp;
}

export const createEstado = async (serverDate: IServerDate, object : IEstado) => {
  const temp = getEmptyStatusEstado();
  const result = await post(`personas/estado`, getSerializableEstado(object));
  const data = result.data;
  if (!data.id || data.detail) {
    console.log(`Error al crear Estado con los datos ${object}, ${data.detail}`);
    temp.estado = DEFAULT_STATE.error;
    temp.mensaje = `Error al crear el estado: ${data.detail ?? "Hubo un problema al conectarse con el servidor"}`;
    temp.data = await setEstado(object, serverDate);
    return temp;
  }
  console.log(`Estado creado con éxito`);
  temp.estado = DEFAULT_STATE.success;
  temp.mensaje = `Estado creado con éxito`;
  temp.data = await setEstado(data, serverDate);
  await setStorageEstado(temp.data);
  temp.data_array = await setStorageEstadoArrayItem(temp.data.id, temp.data);
  return temp;
}

export const deleteEstado = async (pk : number) => {
  const temp = getEmptyStatusEstado();
  const result = await del(`personas/estado/${pk}`);
  const data = result.data;
  if (data.detail) {
    console.log(`Error al eliminar Estado con pk: ${pk}, ${data.detail}`);
    temp.estado = DEFAULT_STATE.error;
    temp.mensaje = `Error al eliminar al estado: ${data.detail}`;
    temp.data = await getStorageEstadoArrayItem(pk);
    temp.data_array = await getStorageEstadoArray();
    return temp;
  }
  console.log(`Estado eliminado con éxito`);
  temp.estado = DEFAULT_STATE.success;
  temp.mensaje = `Estado eliminado con éxito`;
  await removeStorageEstado();
  temp.data_array = await removeArrayItemStorageData("IEstado", pk, []);
  return temp;
}