/**
 * Reducers creados para obtener asíncronamente 
 * los datos de los modelos y dependiendo del resultado
 * de la peticón actualice el state en redux o mande un mensaje de error
 */

import { PayloadAction } from "@reduxjs/toolkit";
import { cancelarRondinAsyncThunk, finalizarRondinAsyncThunk, getAllClienteQentryRondinAsyncThunk, iniciarRondinAsyncThunk, nodoIntermedioAsyncThunk, rondinesCanceladosAsyncThunk, rondinesEmpleadoFechaAsyncThunk } from "../../asyncThunk/rondines";
import { DEFAULT_STATE } from "../../enums/default_state";
import { IStatusCancelarRondin, IStatusCheckRondin, IStatusHistorialRondinesCancelados, IStatusMovimientoRondin, IStatusRondin, IStatusRondinesEmpleadoFecha } from "../../interfaces/rondines";
import { rondinesInitialState } from "../../slices/rondinesSlice";

// Reducers: helpers
export const createHelpersReducers = () => {
  
  const getAllClienteQentryRondin = () => {
    var { pending, fulfilled, rejected } = getAllClienteQentryRondinAsyncThunk;
    return {
      [pending.type]: (state : typeof rondinesInitialState) => {
        state.rondinesState.rondin.estado = DEFAULT_STATE.loading;
      },
      [fulfilled.type]: (state : typeof rondinesInitialState, action : PayloadAction<IStatusRondin>) => {
        const result = action.payload;
        result.data = state.rondinesState.rondin.data;
        result.expiry = state.rondinesState.rondin.expiry;
        if (result.estado === DEFAULT_STATE.error) result.data_array = state.rondinesState.rondin.data_array;
        state.rondinesState.rondin = result;
      },
      [rejected.type]: (state : typeof rondinesInitialState, action : PayloadAction<IStatusRondin>) => {
        state.rondinesState.rondin = {
          ...state.rondinesState.rondin,
          estado: action.payload.estado,
          mensaje: "Ocurrió un problema al realizar la solicitud",
        };
      },
    };
  };
  
  const iniciarRondin = () => {
    var { pending, fulfilled, rejected } = iniciarRondinAsyncThunk;
    return {
      [pending.type]: (state : typeof rondinesInitialState) => {
        state.rondinesState.checkRondin.estado = DEFAULT_STATE.loading;
      },
      [fulfilled.type]: (state : typeof rondinesInitialState, action : PayloadAction<IStatusMovimientoRondin>) => {
        const result = action.payload;
        state.rondinesState.checkRondin.data = result.data;
      },
      [rejected.type]: (state : typeof rondinesInitialState, action : PayloadAction<IStatusMovimientoRondin>) => {
        state.rondinesState.checkRondin = {
          ...state.rondinesState.checkRondin,
          estado: action.payload.estado,
          mensaje: "Ocurrió un problema al realizar la solicitud",
        };
      },
    };
  };
  
  const nodoIntermedio = () => {
    var { pending, fulfilled, rejected } = nodoIntermedioAsyncThunk;
    return {
      [pending.type]: (state : typeof rondinesInitialState) => {
        state.rondinesState.checkRondin.estado = DEFAULT_STATE.loading;
      },
      [fulfilled.type]: (state : typeof rondinesInitialState, action : PayloadAction<IStatusMovimientoRondin>) => {
        const result = action.payload;
        state.rondinesState.checkRondin.data = result.data;
      },
      [rejected.type]: (state : typeof rondinesInitialState, action : PayloadAction<IStatusMovimientoRondin>) => {
        state.rondinesState.checkRondin = {
          ...state.rondinesState.checkRondin,
          estado: action.payload.estado,
          mensaje: "Ocurrió un problema al realizar la solicitud",
        };
      },
    };
  };
  
  const finalizarRondin = () => {
    var { pending, fulfilled, rejected } = finalizarRondinAsyncThunk;
    return {
      [pending.type]: (state : typeof rondinesInitialState) => {
        state.rondinesState.checkRondin.estado = DEFAULT_STATE.loading;
      },
      [fulfilled.type]: (state : typeof rondinesInitialState, action : PayloadAction<IStatusMovimientoRondin>) => {
        const result = action.payload;
        state.rondinesState.checkRondin.data = result.data;
      },
      [rejected.type]: (state : typeof rondinesInitialState, action : PayloadAction<IStatusMovimientoRondin>) => {
        state.rondinesState.checkRondin = {
          ...state.rondinesState.checkRondin,
          estado: action.payload.estado,
          mensaje: "Ocurrió un problema al realizar la solicitud",
        };
      },
    };
  };
  
  const cancelarRondin = () => {
    var { pending, fulfilled, rejected } = cancelarRondinAsyncThunk;
    return {
      [pending.type]: (state : typeof rondinesInitialState) => {
        state.rondinesState.checkRondin.estado = DEFAULT_STATE.loading;
      },
      [fulfilled.type]: (state : typeof rondinesInitialState, action : PayloadAction<IStatusCancelarRondin>) => {
        const result = action.payload;
        state.rondinesState.rondinCancelado.data = result.data;
      },
      [rejected.type]: (state : typeof rondinesInitialState, action : PayloadAction<IStatusCancelarRondin>) => {
        state.rondinesState.checkRondin = {
          ...state.rondinesState.checkRondin,
          estado: action.payload.estado,
          mensaje: "Ocurrió un problema al realizar la solicitud",
        };
      },
    };
  };
  
  const rondinesEmpleadoFecha = () => {
    var { pending, fulfilled, rejected } = rondinesEmpleadoFechaAsyncThunk;
    return {
      [pending.type]: (state : typeof rondinesInitialState) => {
        state.rondinesState.checkRondin.estado = DEFAULT_STATE.loading;
      },
      [fulfilled.type]: (state : typeof rondinesInitialState, action : PayloadAction<IStatusRondinesEmpleadoFecha>) => {
        const result = action.payload;
        state.rondinesState.checkRondin.data_array = result.data;
      },
      [rejected.type]: (state : typeof rondinesInitialState, action : PayloadAction<IStatusRondinesEmpleadoFecha>) => {
        state.rondinesState.checkRondin = {
          ...state.rondinesState.checkRondin,
          estado: action.payload.estado,
          mensaje: "Ocurrió un problema al realizar la solicitud",
        };
      },
    };
  };
  
  const rondinesCancelados = () => {
    var { pending, fulfilled, rejected } = rondinesCanceladosAsyncThunk;
    return {
      [pending.type]: (state : typeof rondinesInitialState) => {
        state.rondinesState.checkRondin.estado = DEFAULT_STATE.loading;
      },
      [fulfilled.type]: (state : typeof rondinesInitialState, action : PayloadAction<IStatusHistorialRondinesCancelados>) => {
        const result = action.payload;
        state.rondinesState.rondinCancelado.data_array = result.data;
      },
      [rejected.type]: (state : typeof rondinesInitialState, action : PayloadAction<IStatusHistorialRondinesCancelados>) => {
        state.rondinesState.checkRondin = {
          ...state.rondinesState.checkRondin,
          estado: action.payload.estado,
          mensaje: "Ocurrió un problema al realizar la solicitud",
        };
      },
    };
  };

  return {
    ...getAllClienteQentryRondin(),
  };
};
