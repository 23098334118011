/**
 * Métodos AsyncThunk para ser usados en los reducers y
 * los resultados a las llamadas http puedan ser usados para
 * actualizar el state en redux
 */

import { createAsyncThunk } from "@reduxjs/toolkit";
import { DEFAULT_STATE } from "../../enums/default_state";
import { getCodigoQr, getAllCodigoQr, updateCodigoQr, createCodigoQr, deleteCodigoQr } from "../../http/personas";
import { getEmptyStatusCodigoQr, ICodigoQr } from "../../interfaces/personas";
import { IServerDate } from "../../interfaces/time";

export const getCodigoQrAsyncThunk = createAsyncThunk(
  'personas/getCodigoQrAsyncThunk',
  async (query : {serverDate : IServerDate, id: number, idCliente : number}) => await getCodigoQr(query.serverDate, query.id, query.idCliente)
    .then(async (result) => result)
    .catch(async (error) => getEmptyStatusCodigoQr(DEFAULT_STATE.error))  
);

export const getAllCodigoQrAsyncThunk = createAsyncThunk(
  'personas/getAllCodigoQrAsyncThunk',
  async (queryAll : {serverDate : IServerDate, idCliente : number}) => await getAllCodigoQr(queryAll.serverDate, queryAll.idCliente)
    .then(async (result) => result)
    .catch(async (error) => getEmptyStatusCodigoQr(DEFAULT_STATE.error))  
);

export const updateCodigoQrAsyncThunk = createAsyncThunk(
  'personas/updateCodigoQrAsyncThunk',
  async (update: {serverDate : IServerDate, id: number, idCliente : number, object: ICodigoQr}) => await updateCodigoQr(update.serverDate, update.id, update.idCliente, update.object)
    .then(async (result) => result)
    .catch(async (error) => getEmptyStatusCodigoQr(DEFAULT_STATE.error))  
);

export const createCodigoQrAsyncThunk = createAsyncThunk(
  'personas/createCodigoQrAsyncThunk',
  async (create : {serverDate : IServerDate, idCliente : number, object: ICodigoQr}) => await createCodigoQr(create.serverDate, create.idCliente, create.object)
    .then(async (result) => result)
    .catch(async (error) => getEmptyStatusCodigoQr(DEFAULT_STATE.error))  
);

export const deleteCodigoQrAsyncThunk = createAsyncThunk(
  'personas/deleteCodigoQrAsyncThunk',
  async (del : {id : number, idCliente : number}) => await deleteCodigoQr(del.id, del.idCliente)
    .then(async (result) => result)
    .catch(async (error) => getEmptyStatusCodigoQr())  
);