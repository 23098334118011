/**
 * Métodos AsyncThunk para ser usados en los reducers y
 * los resultados a las llamadas http puedan ser usados para
 * actualizar el state en redux
 */

import { createAsyncThunk } from "@reduxjs/toolkit";
import { DEFAULT_STATE } from "../../enums/default_state";
import { getSuscripcionQentry, getAllSuscripcionQentry, updateSuscripcionQentry, createSuscripcionQentry, deleteSuscripcionQentry } from "../../http/personas";
import { getEmptyStatusSuscripcionQentry, ISuscripcionQentry } from "../../interfaces/personas";
import { IServerDate } from "../../interfaces/time";

export const getSuscripcionQentryAsyncThunk = createAsyncThunk(
  'personas/getSuscripcionQentryAsyncThunk',
  async (query : {serverDate : IServerDate, id: number, idCliente : number}) => await getSuscripcionQentry(query.serverDate, query.id, query.idCliente)
    .then(async (result) => result)
    .catch(async (error) => getEmptyStatusSuscripcionQentry(DEFAULT_STATE.error))  
);

export const getAllSuscripcionQentryAsyncThunk = createAsyncThunk(
  'personas/getAllSuscripcionQentryAsyncThunk',
  async (queryAll : {serverDate : IServerDate, idCliente : number}) => await getAllSuscripcionQentry(queryAll.serverDate, queryAll.idCliente)
    .then(async (result) => result)
    .catch(async (error) => getEmptyStatusSuscripcionQentry(DEFAULT_STATE.error))  
);

export const updateSuscripcionQentryAsyncThunk = createAsyncThunk(
  'personas/updateSuscripcionQentryAsyncThunk',
  async (update: {serverDate : IServerDate, id: number, idCliente : number, object: ISuscripcionQentry}) => await updateSuscripcionQentry(update.serverDate, update.id, update.idCliente, update.object)
    .then(async (result) => result)
    .catch(async (error) => getEmptyStatusSuscripcionQentry(DEFAULT_STATE.error))  
);

export const createSuscripcionQentryAsyncThunk = createAsyncThunk(
  'personas/createSuscripcionQentryAsyncThunk',
  async (create : {serverDate : IServerDate, idCliente : number, object: ISuscripcionQentry}) => await createSuscripcionQentry(create.serverDate, create.idCliente, create.object)
    .then(async (result) => result)
    .catch(async (error) => getEmptyStatusSuscripcionQentry(DEFAULT_STATE.error))  
);

export const deleteSuscripcionQentryAsyncThunk = createAsyncThunk(
  'personas/deleteSuscripcionQentryAsyncThunk',
  async (del : {id : number, idCliente : number}) => await deleteSuscripcionQentry(del.id, del.idCliente)
    .then(async (result) => result)
    .catch(async (error) => getEmptyStatusSuscripcionQentry())  
);
