/** 
 * Componente que se encarga del elemento SuscripcionQentry
 * @ISuscripcionQentry es la interfaz que contiene los parámetos el elemento SuscripcionQentry
 * @getEmptySuscripcionQentry  devuelve un objeto con los parámetros por defecto; @param {id}, opcional cuando no encuentra el objeto en el LocalSotrage
 * @IStatusSuscripcionQentry es la interfaz para los estatus a llamadas a la API; @param {status}, opcional cuando queremos otro estado de inizialización de tipo [DEFAULT_STATE]
 * @getEmptyStatusSuscripcionQentry devuelve un objeto con los parámetros por defecto,
 * @setSuscripcionQentry devuelve un objeto de tipo [ISuscripcionQentry] recibiendo @param {object} y @param {serverDate}, para verificar que los datos estén en el formato correcto y ajustarlos a la zona horaria actual
 * @getSerializableSuscripcionQentry recibe un @param {ISuscripcionQentry}, para devolver un objeto serizalizable para las peticiones de la API
 * */

import { getDateString, isNotEmpty, SERVER_TIME_ZONE } from "../../api/helpers";
import { DEFAULT_STATE } from "../../enums/default_state";
import { getStorageClienteQentryArrayItem, getStorageInformacionSuscripcionArrayItem, getStorageUserArrayItem } from "../../localStorage/personas";
import { convertDateToZonaHoraria, ILocalStorageExpiry, getEmptyLocalStorageExpiry, IServerDate } from "../time";
import { getEmptyClienteQentry, IClienteQentry, setClienteQentry } from "./IClienteQentry";
import { IInformacionSuscripcion, getEmptyInformacionSuscripcion, setInformacionSuscripcion } from "./IInformacionSuscripcion";
import { IUser, getEmptyUser, setUser } from "./IUser";

export interface ISuscripcionQentry {
  id : number,
  cliente_qentry : IClienteQentry,
  id_suscripcion_stripe : string,
  id_plan_stripe : string,
  nombre_suscripcion : string,
  informacion_suscripcion : IInformacionSuscripcion,
  vigente : boolean,
  autor : IUser,
  fecha_elaboracion : string, // Fecha
  estatus_sistema : boolean,
  ultima_actualizacion : string, // Fecha
}

export const getEmptySuscripcionQentry = (id? : number) => {
  const temp : ISuscripcionQentry = 
  {
    id : id ? id : 0,
    cliente_qentry : getEmptyClienteQentry(),
    id_suscripcion_stripe : "",
    id_plan_stripe : "",
    nombre_suscripcion : "",
    informacion_suscripcion : getEmptyInformacionSuscripcion(),
    vigente : false,
    autor : getEmptyUser(),
    fecha_elaboracion : "",
    estatus_sistema : false,
    ultima_actualizacion :  "",
  };
  return temp;
}

export interface IStatusSuscripcionQentry {
  estado : DEFAULT_STATE,
  mensaje : string,
  data : ISuscripcionQentry,
  data_array: ISuscripcionQentry[],
  expiry : ILocalStorageExpiry,
  expiry_array : ILocalStorageExpiry,
}

export const getEmptyStatusSuscripcionQentry = (status: DEFAULT_STATE = DEFAULT_STATE.loading) => {
  const temp : IStatusSuscripcionQentry = 
  {
    estado : status,
    mensaje : "",
    data : getEmptySuscripcionQentry(),
    data_array : [],
    expiry : getEmptyLocalStorageExpiry("ISuscripcionQentry", 0),
    expiry_array : getEmptyLocalStorageExpiry("ISuscripcionQentryArray", 0),
  };
  return temp;
}

export const setSuscripcionQentry = async (
  object : any,
  serverDate : IServerDate,
) => {
  const temp = getEmptySuscripcionQentry();
  if (isNotEmpty(object.id)) temp.id = object.id;
  if (isNotEmpty(object.cliente_qentry)) temp.cliente_qentry = (typeof object.cliente_qentry === 'number') ? await getStorageClienteQentryArrayItem(object.cliente_qentry) : await setClienteQentry(object.cliente_qentry, serverDate);
  if (isNotEmpty(object.id_suscripcion_stripe)) temp.id_suscripcion_stripe = object.id_suscripcion_stripe;
  if (isNotEmpty(object.id_plan_stripe)) temp.id_plan_stripe = object.id_plan_stripe;
  if (isNotEmpty(object.nombre_suscripcion)) temp.nombre_suscripcion = object.nombre_suscripcion;
  if (isNotEmpty(object.informacion_suscripcion)) temp.informacion_suscripcion = (typeof object.informacion_suscripcion === 'number') ? await getStorageInformacionSuscripcionArrayItem(object.informacion_suscripcion) : await setInformacionSuscripcion(object.informacion_suscripcion, serverDate);
  if (isNotEmpty(object.vigente)) temp.vigente = object.vigente;
  if (isNotEmpty(object.autor)) temp.autor = (typeof object.autor === 'number') ? await getStorageUserArrayItem(object.autor) : await setUser(object.autor, serverDate);
  if (isNotEmpty(object.fecha_elaboracion)) temp.fecha_elaboracion = convertDateToZonaHoraria(serverDate, object.fecha_elaboracion);
  if (isNotEmpty(object.estatus_sistema)) temp.estatus_sistema = object.estatus_sistema;
  if (isNotEmpty(object.ultima_actualizacion)) temp.ultima_actualizacion = convertDateToZonaHoraria(serverDate, object.ultima_actualizacion);
  return temp;
}

export const getSerializableSuscripcionQentry = (ISuscripcionQentry : ISuscripcionQentry) => {
  return {
    id : ISuscripcionQentry.id,
    cliente_qentry : ISuscripcionQentry.cliente_qentry.id,
    id_suscripcion_stripe : ISuscripcionQentry.id_suscripcion_stripe,
    id_plan_stripe : ISuscripcionQentry.id_plan_stripe,
    nombre_suscripcion : ISuscripcionQentry.nombre_suscripcion,
    informacion_suscripcion : ISuscripcionQentry.informacion_suscripcion.id > 0 ? ISuscripcionQentry.informacion_suscripcion.id : null,
    vigente : ISuscripcionQentry.vigente,
    autor : ISuscripcionQentry.autor.id,
    fecha_elaboracion : ISuscripcionQentry.fecha_elaboracion,
    estatus_sistema : ISuscripcionQentry.estatus_sistema,
    ultima_actualizacion : getDateString(SERVER_TIME_ZONE),
  };
}
