import { createCheckEnRutaReducers } from "../rondines/CheckEnRuta";
import { createAsistenciaReducers } from "./Asistencia";
import { createCambioEstatusAsistenciaReducers } from "./CambioEstatusAsistencia";
import { createCambioEstatusIncapacidadReducers } from "./CambioEstatusIncapacidad";
import { createCambioEstatusVacacionesReducers } from "./CambioEstatusVacaciones";
import { createHorarioReducers } from "./Horario";
import { createHorasExtraReducers } from "./HorasExtra";
import { createIncapacidadReducers } from "./Incapacidad";
import { createIncidenciaReducers } from "./Incidencia";
import { createMvEstatusAsistenciaReducers } from "./MvEstatusAsistencia";
import { createMvEstatusIncapacidadesReducers } from "./MvEstatusIncapacidades";
import { createMvEstatusVacacionesReducers } from "./MvEstatusVacaciones";
import { createMvMotivoIncapacidadReducers } from "./MvMotivoIncapacidad";
import { createMvMotivoNotificacionReducers } from "./MvMotivoNotificacion";
import { createMvMotivoVacacionesReducers } from "./MvMotivoVacaciones";
import { createMvRiesgoPuestoReducers } from "./MvRiesgoPuesto";
import { createMvTipoAsistenciaReducers } from "./MvTipoAsistencia";
import { createMvTipoContratoReducers } from "./MvTipoContrato";
import { createMvTipoHorasExtraReducers } from "./MvTipoHorasExtra";
import { createMvTipoJornadaReducers } from "./MvTipoJornada";
import { createMvTipoMovimientoAsistenciaReducers } from "./MvTipoMovimientoAsistencia";
import { createMvTipoNotificacionReducers } from "./MvTipoNotificacion";
import { createNotificacionesReducers } from "./Notificaciones";
import { createPuestoReducers } from "./Puesto";
import { createTabuladorHorasExtraReducers } from "./TabuladorHorasExtra";
import { createTurnoReducers } from "./Turno";
import { createVacacionesReducers } from "./Vacaciones";

export const nominaReducers = {
  ...createAsistenciaReducers(),
  ...createCambioEstatusAsistenciaReducers(),
  ...createCambioEstatusIncapacidadReducers(),
  ...createCambioEstatusVacacionesReducers(),
  ...createHorarioReducers(),
  ...createHorasExtraReducers(),
  ...createIncapacidadReducers(),
  ...createIncidenciaReducers(),
  ...createMvEstatusAsistenciaReducers(),
  ...createMvEstatusIncapacidadesReducers(),
  ...createMvEstatusVacacionesReducers(),
  ...createMvMotivoIncapacidadReducers(),
  ...createMvMotivoNotificacionReducers(),
  ...createMvMotivoVacacionesReducers(),
  ...createMvRiesgoPuestoReducers(),
  ...createMvTipoAsistenciaReducers(),
  ...createMvTipoContratoReducers(),
  ...createMvTipoHorasExtraReducers(),
  ...createMvTipoJornadaReducers(),
  ...createMvTipoMovimientoAsistenciaReducers(),
  ...createMvTipoNotificacionReducers(),
  ...createNotificacionesReducers(),
  ...createPuestoReducers(),
  ...createTabuladorHorasExtraReducers(),
  ...createTurnoReducers(),
  ...createVacacionesReducers(),
}