/**
 * Es el componente utilizado mientras la app esta cargado (al hacer iniciar/cerrar sesión o al abrir la aplicación)
 */

import { IonContent, IonImg, IonGrid, IonCol, IonRow, IonPage} from '@ionic/react';
import './Loading.css';
import './Login.css';

const Loading: React.FC = () => {

  return (
    <IonPage>
      <IonContent fullscreen className="secondary-bg">
        <IonGrid  class="grid-height no-padding  ion-justify-content-center ion-align-items-center">
          <IonRow class="grid-height ion-justify-content-center ion-align-self-center ion-align-items-center">
            <IonCol class="ion-justify-content-center ion-align-self-center ion-text-center">
              <IonImg
                className="loading-css"
                src="../assets/img/anima.gif" />

            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default Loading;
