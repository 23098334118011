/**
 * Reducers creados para obtener asíncronamente 
 * los datos de los modelos y dependiendo del resultado
 * de la peticón actualice el state en redux o mande un mensaje de error
 */

import { PayloadAction } from "@reduxjs/toolkit";
import { getCentroDeTrabajoAsyncThunk, getAllCentroDeTrabajoAsyncThunk, updateCentroDeTrabajoAsyncThunk, createCentroDeTrabajoAsyncThunk, deleteCentroDeTrabajoAsyncThunk } from "../../asyncThunk/personas";
import { DEFAULT_STATE } from "../../enums/default_state";
import { IStatusCentroDeTrabajo } from "../../interfaces/personas";
import { personasInitialState } from "../../slices/personasSlice";

// Reducers: CentroDeTrabajo
export const createCentroDeTrabajoReducers = () => {
  const get = () => {
    var { pending, fulfilled, rejected } = getCentroDeTrabajoAsyncThunk;
    return {
      [pending.type]: (state : typeof personasInitialState) => {
        state.personasState.centroDeTrabajo.estado = DEFAULT_STATE.loading;
      },
      [fulfilled.type]: (state : typeof personasInitialState, action : PayloadAction<IStatusCentroDeTrabajo>) => {
        const result = action.payload;
        result.expiry_array = state.personasState.centroDeTrabajo.expiry_array;
        if (result.estado === DEFAULT_STATE.error) result.data_array = state.personasState.centroDeTrabajo.data_array;
        state.personasState.centroDeTrabajo = result;
      },
      [rejected.type]: (state : typeof personasInitialState, action : PayloadAction<IStatusCentroDeTrabajo>) => {
        state.personasState.centroDeTrabajo = {
          ...state.personasState.centroDeTrabajo,
          estado: action.payload.estado,
          mensaje: "Ocurrió un problema al realizar la solicitud",
        };
      },
    };
  };
  
  const getAll = () => {
    var { pending, fulfilled, rejected } = getAllCentroDeTrabajoAsyncThunk;
    return {
      [pending.type]: (state : typeof personasInitialState) => {
        state.personasState.centroDeTrabajo.estado = DEFAULT_STATE.loading;
      },
      [fulfilled.type]: (state : typeof personasInitialState, action : PayloadAction<IStatusCentroDeTrabajo>) => {
        const result = action.payload;
        result.data = state.personasState.centroDeTrabajo.data;
        result.expiry = state.personasState.centroDeTrabajo.expiry;
        if (result.estado === DEFAULT_STATE.error) result.data_array = state.personasState.centroDeTrabajo.data_array;
        state.personasState.centroDeTrabajo = result;
      },
      [rejected.type]: (state : typeof personasInitialState, action : PayloadAction<IStatusCentroDeTrabajo>) => {
        state.personasState.centroDeTrabajo = {
          ...state.personasState.centroDeTrabajo,
          estado: action.payload.estado,
          mensaje: "Ocurrió un problema al realizar la solicitud",
        };
      },
    };
  };
  
  const update = () => {
    var { pending, fulfilled, rejected } = updateCentroDeTrabajoAsyncThunk;
    return {
      [pending.type]: (state : typeof personasInitialState) => {
        state.personasState.centroDeTrabajo.estado = DEFAULT_STATE.loading;
      },
      [fulfilled.type]: (state : typeof personasInitialState, action : PayloadAction<IStatusCentroDeTrabajo>) => {
        const result: IStatusCentroDeTrabajo = action.payload;
        result.expiry = state.personasState.centroDeTrabajo.expiry;
        result.expiry_array = state.personasState.centroDeTrabajo.expiry_array;
        if (result.estado === DEFAULT_STATE.error) result.data_array = state.personasState.centroDeTrabajo.data_array;
        state.personasState.centroDeTrabajo = result;
      },
      [rejected.type]: (state : typeof personasInitialState, action : PayloadAction<IStatusCentroDeTrabajo>) => {
        state.personasState.centroDeTrabajo = {
          ...state.personasState.centroDeTrabajo,
          estado: action.payload.estado,
          mensaje: "Ocurrió un problema al realizar la solicitud",
        };
      },
    };
  };

  const create = () => {
    var { pending, fulfilled, rejected } = createCentroDeTrabajoAsyncThunk;
    return {
      [pending.type]: (state : typeof personasInitialState) => {
        state.personasState.centroDeTrabajo.estado = DEFAULT_STATE.loading;
      },
      [fulfilled.type]: (state : typeof personasInitialState, action : PayloadAction<IStatusCentroDeTrabajo>) => {
        const result = action.payload;
        result.expiry = state.personasState.centroDeTrabajo.expiry;
        result.expiry_array = state.personasState.centroDeTrabajo.expiry_array;
        if (result.estado === DEFAULT_STATE.error) result.data_array = state.personasState.centroDeTrabajo.data_array;
        state.personasState.centroDeTrabajo = result;
      },
      [rejected.type]: (state : typeof personasInitialState, action : PayloadAction<IStatusCentroDeTrabajo>) => {
        state.personasState.centroDeTrabajo = {
          ...state.personasState.centroDeTrabajo,
          estado: action.payload.estado,
          mensaje: "Ocurrió un problema al realizar la solicitud",
        };
      },
    };
  };
  
  const del = () => {
    var { pending, fulfilled, rejected } = deleteCentroDeTrabajoAsyncThunk;
    return {
      [pending.type]: (state : typeof personasInitialState) => {
        state.personasState.centroDeTrabajo.estado = DEFAULT_STATE.loading;
      },
      [fulfilled.type]: (state : typeof personasInitialState, action : PayloadAction<IStatusCentroDeTrabajo>) => {
        const result: IStatusCentroDeTrabajo = action.payload;
        result.expiry = state.personasState.centroDeTrabajo.expiry;
        result.expiry_array = state.personasState.centroDeTrabajo.expiry_array;
        if (result.estado === DEFAULT_STATE.error) result.data_array = state.personasState.centroDeTrabajo.data_array;
        state.personasState.centroDeTrabajo = result;
      },
      [rejected.type]: (state : typeof personasInitialState, action : PayloadAction<IStatusCentroDeTrabajo>) => {
        state.personasState.centroDeTrabajo = {
          ...state.personasState.centroDeTrabajo,
          estado: action.payload.estado,
          mensaje: "Ocurrió un problema al realizar la solicitud",
        };
      },
    };
  };

  return {
    ...get(),
    ...getAll(),
    ...update(),
    ...create(),
    ...del(),
  };
};
