import { getArrayItemStorageData, getStorageData, removeArrayItemStorageData, removeStorageData, setArrayItemStorageData, setStorageData } from "../../api/storage";
import { getEmptyEmpleado, IEmpleado } from "../../interfaces/personas";

export const setStorageEmpleado = async (IEmpleado : IEmpleado) => await setStorageData('IEmpleado', IEmpleado);
export const getStorageEmpleado = async () : Promise<IEmpleado> => await getStorageData('IEmpleado', getEmptyEmpleado());
export const removeStorageEmpleado = async () => await removeStorageData('IEmpleado');
export const setStorageEmpleadoArray = async (IEmpleado : IEmpleado[]) => await setStorageData('IEmpleadoArray', IEmpleado);
export const getStorageEmpleadoArray = async () : Promise<IEmpleado[]> => await getStorageData('IEmpleadoArray', [] as IEmpleado[]);
export const removeStorageEmpleadoArray = async () => await removeStorageData('IEmpleadoArray');
export const setStorageEmpleadoArrayItem = async (id: number, IEmpleado : IEmpleado) : Promise<IEmpleado[]> => await setArrayItemStorageData('IEmpleadoArray', id, IEmpleado, [] as IEmpleado[]);
export const getStorageEmpleadoArrayItem = async (id: number) : Promise<IEmpleado> => await getArrayItemStorageData('IEmpleadoArray', id, getEmptyEmpleado);
export const removeStorageEmpleadoArrayItem = async (id: number) : Promise<IEmpleado[]> => await removeArrayItemStorageData('IEmpleadoArray', id, [] as IEmpleado[]);
