/**
 * Métodos para el manejo de IonicStorage
 * @setStorage              guarda un unico objeto al storage
 * @getStorage              obtiene el objeto guardado con @setStorage
 * @setStorageArray         guarda el array de objetos en el storage
 * @getStorageArray         obtiene el array de objetos guardado con @setStorageArray
 * @removeStorageArray      elimina el array de objetos guardado con @setStorageArray
 * @setStorageItemArray     agrega un elemento al array de objeto guardado con @setStorageArray
 * @getStorageItemArray     obtiene un elemento del array guardado con @setStorageArray buscandolo por su id
 * @removeStorageItemArray  elimina un elemento del array guardado con @setStorageArray buscandolo por su id
 */

import {
  setStorageData,
  getStorageData,
  removeStorageData,
  setArrayItemStorageData,
  getArrayItemStorageData,
  removeArrayItemStorageData,
} from "../../api/storage";
import {
  ISolicitudCambioTelefonoAutorizado,
  getEmptySolicitudCambioTelefonoAutorizado,
} from "../../interfaces/personas";
import { IUpdateTelefonoAutorizado } from "../../interfaces/personas/ISolicitudCambioTelefonoAutorizado";

export const setStorageSolicitudCambioTelefonoAutorizado = async (
  ISolicitudCambioTelefonoAutorizado: ISolicitudCambioTelefonoAutorizado
) =>
  await setStorageData(
    "ISolicitudCambioTelefonoAutorizado",
    ISolicitudCambioTelefonoAutorizado
  );

export const setStorageUpdateTelefonoAutorizado = async (
  IUpdateTelefonoAutorizado: IUpdateTelefonoAutorizado
) =>
  await setStorageData("IUpdateTelefonoAutorizado", IUpdateTelefonoAutorizado);

export const getStorageSolicitudCambioTelefonoAutorizado =
  async (): Promise<ISolicitudCambioTelefonoAutorizado> =>
    await getStorageData(
      "ISolicitudCambioTelefonoAutorizado",
      getEmptySolicitudCambioTelefonoAutorizado()
    );
export const removeStorageSolicitudCambioTelefonoAutorizado = async () =>
  await removeStorageData("ISolicitudCambioTelefonoAutorizado");
export const setStorageSolicitudCambioTelefonoAutorizadoArray = async (
  ISolicitudCambioTelefonoAutorizado: ISolicitudCambioTelefonoAutorizado[]
) =>
  await setStorageData(
    "ISolicitudCambioTelefonoAutorizadoArray",
    ISolicitudCambioTelefonoAutorizado
  );
export const getStorageSolicitudCambioTelefonoAutorizadoArray =
  async (): Promise<ISolicitudCambioTelefonoAutorizado[]> =>
    await getStorageData(
      "ISolicitudCambioTelefonoAutorizadoArray",
      [] as ISolicitudCambioTelefonoAutorizado[]
    );
export const removeStorageSolicitudCambioTelefonoAutorizadoArray = async () =>
  await removeStorageData("ISolicitudCambioTelefonoAutorizadoArray");

export const setStorageSolicitudCambioTelefonoAutorizadoArrayItem = async (
  id: number,
  ISolicitudCambioTelefonoAutorizado: ISolicitudCambioTelefonoAutorizado
): Promise<ISolicitudCambioTelefonoAutorizado[]> =>
  await setArrayItemStorageData(
    "ISolicitudCambioTelefonoAutorizadoArray",
    id,
    ISolicitudCambioTelefonoAutorizado,
    [] as ISolicitudCambioTelefonoAutorizado[]
  );

export const setStorageUpdateTelefonoAutorizadoArrayItem = async (
  id: number,
  IUpdateTelefonoAutorizado: IUpdateTelefonoAutorizado
): Promise<IUpdateTelefonoAutorizado[]> =>
  await setArrayItemStorageData(
    "IUpdateTelefonoAutorizadoArray",
    id,
    IUpdateTelefonoAutorizado,
    [] as IUpdateTelefonoAutorizado[]
  );

export const getStorageSolicitudCambioTelefonoAutorizadoArrayItem = async (
  id: number
): Promise<ISolicitudCambioTelefonoAutorizado> =>
  await getArrayItemStorageData(
    "ISolicitudCambioTelefonoAutorizadoArray",
    id,
    getEmptySolicitudCambioTelefonoAutorizado
  );
export const removeStorageSolicitudCambioTelefonoAutorizadoArrayItem = async (
  id: number
): Promise<ISolicitudCambioTelefonoAutorizado[]> =>
  await removeArrayItemStorageData(
    "ISolicitudCambioTelefonoAutorizadoArray",
    id,
    [] as ISolicitudCambioTelefonoAutorizado[]
  );
