/**
 * Métodos AsyncThunk para ser usados en los reducers y
 * los resultados a las llamadas http puedan ser usados para
 * actualizar el state en redux
 */

import { createAsyncThunk } from "@reduxjs/toolkit";
import { DEFAULT_STATE } from "../../enums/default_state";
import {
  getEmpleado,
  getAllEmpleado,
  getEmpleadosACargo,
  updateEmpleado,
  createEmpleado,
  deleteEmpleado,
} from "../../http/personas";
import { getEmptyStatusEmpleado, IEmpleado } from "../../interfaces/personas";
import { IServerDate } from "../../interfaces/time";

export const getEmpleadoAsyncThunk = createAsyncThunk(
  "personas/getEmpleadoAsyncThunk",
  async (query: { serverDate: IServerDate; id: number; idCliente: number }) =>
    await getEmpleado(query.serverDate, query.id, query.idCliente)
      .then(async (result) => result)
      .catch(async (error) => getEmptyStatusEmpleado(DEFAULT_STATE.error))
);

export const getAllEmpleadoAsyncThunk = createAsyncThunk(
  "personas/getAllEmpleadoAsyncThunk",
  async (queryAll: { serverDate: IServerDate; idCliente: number }) =>
    await getAllEmpleado(queryAll.serverDate, queryAll.idCliente)
      .then(async (result) => result)
      .catch(async (error) => getEmptyStatusEmpleado(DEFAULT_STATE.error))
);

export const getEmpleadosACargoAsyncThunk = createAsyncThunk(
  "personas/getEmpleadosACargoAsyncThunk",
  async (queryAll: {
    serverDate: IServerDate;
    idCliente: number;
    employeesArray: number[];
  }) =>
    await getEmpleadosACargo(
      queryAll.serverDate,
      queryAll.idCliente,
      queryAll.employeesArray
    )
      .then(async (result) => result)
      .catch(async (error) => getEmptyStatusEmpleado(DEFAULT_STATE.error))
);

export const updateEmpleadoAsyncThunk = createAsyncThunk(
  "personas/updateEmpleadoAsyncThunk",
  async (update: {
    serverDate: IServerDate;
    id: number;
    idCliente: number;
    object: IEmpleado;
  }) =>
    await updateEmpleado(
      update.serverDate,
      update.id,
      update.idCliente,
      update.object
    )
      .then(async (result) => result)
      .catch(async (error) => getEmptyStatusEmpleado(DEFAULT_STATE.error))
);

export const createEmpleadoAsyncThunk = createAsyncThunk(
  "personas/createEmpleadoAsyncThunk",
  async (create: {
    serverDate: IServerDate;
    idCliente: number;
    object: IEmpleado;
  }) =>
    await createEmpleado(create.serverDate, create.idCliente, create.object)
      .then(async (result) => result)
      .catch(async (error) => getEmptyStatusEmpleado(DEFAULT_STATE.error))
);

export const deleteEmpleadoAsyncThunk = createAsyncThunk(
  "personas/deleteEmpleadoAsyncThunk",
  async (del: { id: number; idCliente: number }) =>
    await deleteEmpleado(del.id, del.idCliente)
      .then(async (result) => result)
      .catch(async (error) => getEmptyStatusEmpleado())
);
