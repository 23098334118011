/**
 * Métodos del, get, post, put para llamadas a la API
 * @get     en este método primero consulta con @getFromLocalStorage si es necesario hacer la petición http sino se obtiene del LocalStorage;
 *          si hay un error en la petición http se busca el objeto en el LocalStorage sino lo encuentra devuelve un objeto vacío; si la
 *          petición se realiza correctamente actualiza el valor individual y en el array del objeto en el LocalStorage y devuelve el objeto
 *          obtenido del servidor.
 * @getAll  en este método primero consulta con @getFromLocalStorage si es necesario hacer la petición http sino se obtiene del LocalStorage;
 *          si hay un error en la petición http se busca el objeto array en el LocalStorage sino lo encuentra devuelve un array vacío; si la
 *          petición se realiza correctamente actualiza array en el LocalStorage y devuelve el array obtenido del servidor
 * @update  en este método después de realizar la petición http si falla devuelve el objeto que se iba a actualizar; si la petición se
 *          realiza correctamente actualiza el valor individual y en el array del objeto en el LocalStorage y devuelve el objeto obtenido del
 *          servidor.
 * @create  en este método después de realizar la petición http si falla devuelve el objeto que se iba a crear; si la petición se realiza 
 *          correctamente actualiza el valor individual y en el array del objeto en el LocalStorage y devuelve el objeto obtenido del
 *          servidor.
 * @delete  en este método después de realizar la petición http si falla devuelve el objeto que se iba a eliminar; si la petición se realiza 
 *          correctamente elimina el valor individual y en el objeto en el arrya del LocalStorage y devuelve un objeto vacio (Default)
 */

import { del, get, post, put } from "../../api/api";
import { getFromLocalStorage, updateLocalStorageExpiry } from "../../api/helpers";
import { removeArrayItemStorageData } from "../../api/storage";
import { DEFAULT_STATE } from "../../enums/default_state";
import { getEmptyStatusCodigoQr, setCodigoQr, ICodigoQr, getSerializableCodigoQr } from "../../interfaces/personas";
import { IServerDate } from "../../interfaces/time";
import { getStorageCodigoQrArrayItem, setStorageCodigoQr, setStorageCodigoQrArrayItem, getStorageCodigoQrArray, setStorageCodigoQrArray, removeStorageCodigoQr } from "../../localStorage/personas";

export const getCodigoQr = async (serverDate: IServerDate, pk : number, idCliente : number) => {
  const temp = getEmptyStatusCodigoQr();
  const doHttp = await getFromLocalStorage(serverDate, temp.expiry);
  temp.expiry = doHttp.storage;
  if (!doHttp.result) {
    console.log(`(localStorage) Código Qr con pk: ${pk}, obtenido con éxito`);
    temp.estado = DEFAULT_STATE.success;
    temp.mensaje = `Código Qr obtenida con éxito`;
    temp.data = await getStorageCodigoQrArrayItem(pk);
    return temp;
  }
  const result = await get(`personas/codigo-qr/${pk}/${idCliente}`);
  const data = result.data;
  console.log(`Código Qr data: `, data);
  if (!data.id || data.detail) {
    console.log(`Error al obtener Código Qr con pk: ${pk}, ${data.detail}`);
    temp.estado = DEFAULT_STATE.error;
    temp.mensaje =  `Error al obtener el código qr: ${data.detail ?? "Hubo un problema al conectarse con el servidor"}`;
    temp.data = await getStorageCodigoQrArrayItem(pk);
    return temp;
  }
  console.log(`Código Qr con pk: ${pk}, obtenido con éxito`);
  temp.estado = DEFAULT_STATE.success;
  temp.mensaje = `Código Qr obtenida con éxito`;
  temp.data = await setCodigoQr(data, serverDate);
  await setStorageCodigoQr(temp.data);
  temp.data_array = await setStorageCodigoQrArrayItem(pk, temp.data);
  temp.expiry = await updateLocalStorageExpiry(serverDate, temp.expiry);
  return temp;
}

export const getAllCodigoQr = async (serverDate: IServerDate, idCliente : number) => {
  const temp = getEmptyStatusCodigoQr();
  const doHttp = await getFromLocalStorage(serverDate, temp.expiry_array);
  temp.expiry_array = doHttp.storage;
  if (!doHttp.result) {
    console.log(`(localStorage) Todos los CódigosQr obtenidas con éxito`);
    temp.estado = DEFAULT_STATE.success;
    temp.mensaje = `Todos los códigos qr obtenidas con éxito`;
    temp.data_array = await getStorageCodigoQrArray();
  }
  const result = await get(`personas/codigo-qr/${idCliente}`);
  const data = result.data;
  if (data.detail) {
    console.log(`Error al obtener la lista de Código Qr`, data);
    temp.estado = DEFAULT_STATE.error;
    temp.mensaje = `Error al obtener la lista de códigos qr: ${data.detail ?? "Hubo un problema al conectarse con el servidor"}`;
    temp.data_array = await getStorageCodigoQrArray();
    return temp;
  }
  console.log(`Todos los CódigosQr obtenidos con éxito`);
  temp.estado = DEFAULT_STATE.success;
  temp.mensaje = `Todos los códigos qr obtenidas con éxito`;
  temp.data_array = await Promise.all(data.map(async (e : any) => await setCodigoQr(e, serverDate)));
  await setStorageCodigoQrArray(temp.data_array);
  temp.expiry_array = await updateLocalStorageExpiry(serverDate, temp.expiry_array);
  return temp;
}

export const updateCodigoQr = async (serverDate: IServerDate, pk : number, idCliente : number, object : ICodigoQr) => {
  const temp = getEmptyStatusCodigoQr();
  const result = await put(`personas/codigo-qr/${pk}/${idCliente}`, getSerializableCodigoQr(object));
  const data = result.data;
  if (!data.id || data.detail) {
    console.log(`Error al actualizar Código Qr con pk: ${pk}, ${data.detail}`);
    temp.estado = DEFAULT_STATE.error;
    temp.mensaje = `Error al actualizar el código qr: ${data.detail ?? "Hubo un problema al conectarse con el servidor"}`;
    temp.data = await setCodigoQr(object, serverDate);
    return temp;
  }
  console.log(`Código Qr con pk: ${pk}, actualizado con éxito`);
  temp.estado = DEFAULT_STATE.success;
  temp.mensaje = `Código Qr actualizada con éxito`;
  temp.data = await setCodigoQr(data, serverDate);
  await setStorageCodigoQr(temp.data);
  temp.data_array = await setStorageCodigoQrArrayItem(temp.data.id, temp.data);
  return temp;
}

export const createCodigoQr = async (serverDate: IServerDate, idCliente : number, object : ICodigoQr) => {
  const temp = getEmptyStatusCodigoQr();
  const result = await post(`personas/codigo-qr/${idCliente}`, getSerializableCodigoQr(object));
  const data = result.data;
  if (!data.id || data.detail) {
    console.log(`Error al crear Código Qr con los datos ${object}, ${data.detail}`);
    temp.estado = DEFAULT_STATE.error;
    temp.mensaje = `Error al crear el código qr: ${data.detail ?? "Hubo un problema al conectarse con el servidor"}`;
    temp.data = await setCodigoQr(object, serverDate);
    return temp;
  }
  console.log(`Código Qr creada con éxito`);
  temp.estado = DEFAULT_STATE.success;
  temp.mensaje = `Código Qr creada con éxito`;
  temp.data = await setCodigoQr(data, serverDate);
  await setStorageCodigoQr(temp.data);
  temp.data_array = await setStorageCodigoQrArrayItem(temp.data.id, temp.data);
  return temp;
}

export const deleteCodigoQr = async (pk : number, idCliente : number) => {
  const temp = getEmptyStatusCodigoQr();
  const result = await del(`personas/codigo-qr/${pk}/${idCliente}`);
  const data = result.data;
  if (data.detail) {
    console.log(`Error al eliminar Código Qr con pk: ${pk}, ${data.detail}`);
    temp.estado = DEFAULT_STATE.error;
    temp.mensaje = `Error al eliminar el código qr: ${data.detail}`;
    temp.data = await getStorageCodigoQrArrayItem(pk);
    temp.data_array = await getStorageCodigoQrArray();
    return temp;
  }
  console.log(`Código Qr eliminada con éxito`);
  temp.estado = DEFAULT_STATE.success;
  temp.mensaje = `Código Qr eliminada con éxito`;
  await removeStorageCodigoQr();
  temp.data_array = await removeArrayItemStorageData("ICodigoQr", pk, []);
  return temp;
}
