import { getArrayItemStorageData, getStorageData, removeArrayItemStorageData, removeStorageData, setArrayItemStorageData, setStorageData } from "../../api/storage";
import { getEmptyMvUsoFactura, IMvUsoFactura } from "../../interfaces/personas";

export const setStorageMvUsoFactura = async (IMvUsoFactura : IMvUsoFactura) => await setStorageData('IMvUsoFactura', IMvUsoFactura);
export const getStorageMvUsoFactura = async () : Promise<IMvUsoFactura> => await getStorageData('IMvUsoFactura', getEmptyMvUsoFactura());
export const removeStorageMvUsoFactura = async () => await removeStorageData('IMvUsoFactura');
export const setStorageMvUsoFacturaArray = async (IMvUsoFactura : IMvUsoFactura[]) => await setStorageData('IMvUsoFacturaArray', IMvUsoFactura);
export const getStorageMvUsoFacturaArray = async () : Promise<IMvUsoFactura[]> => await getStorageData('IMvUsoFacturaArray', [] as IMvUsoFactura[]);
export const removeStorageMvUsoFacturaArray = async () => await removeStorageData('IMvUsoFacturaArray');
export const setStorageMvUsoFacturaArrayItem = async (id: number, IMvUsoFactura : IMvUsoFactura) : Promise<IMvUsoFactura[]> => await setArrayItemStorageData('IMvUsoFacturaArray', id, IMvUsoFactura, [] as IMvUsoFactura[]);
export const getStorageMvUsoFacturaArrayItem = async (id: number) : Promise<IMvUsoFactura> => await getArrayItemStorageData('IMvUsoFacturaArray', id, getEmptyMvUsoFactura);
export const removeStorageMvUsoFacturaArrayItem = async (id: number) : Promise<IMvUsoFactura[]> => await removeArrayItemStorageData('IMvUsoFacturaArray', id, [] as IMvUsoFactura[]);
