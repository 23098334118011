/**
 * Es el componente utilizado en la página Aplicaciones del usuario
 * @interface App
 *   @param {string} link package name de la app para usarlo en un link de playstore
 *   @param {string} title nombre de la applicación
 */

import React from "react";
import {
  IonGrid,
  IonRow,
  IonCol,
  IonLabel,
  IonContent,
} from '@ionic/react';


import Card from "../components/Card"

interface App {
  link: string;
  title: string;
}

const appsData: App[] = [
  {
    title: 'Control de asistencia',
    link: 'com.elemento.controldeasitencia',
  },
];

const MoreApps: React.FC = () => {  
  return (
    <IonContent class="inner-content">
    <IonGrid>
      <IonRow>
        {
          appsData.map((app, key) => {
            return (
              <IonCol key={key} size="12" sizeMd="6" sizeLg="6">
                <Card
                  img="../assets/img/logosingle.png"
                  title={
                    <IonRow>
                      <IonCol className="ion-text-center">
                        <IonLabel >
                          <h1>{app.title}</h1>
                          <h3>Controla el acceso de tu personal a través de nuestra aplicación, consulta la información desde cualquier lugar ajeno a tu área de trabajo, desde tu oficina o dispositivo móvil.</h3>
                          <br></br>
                          <h2><a href={"https://play.google.com/store/apps/details?id=" + app.link}>Más información</a></h2>
                        </IonLabel>
                      </IonCol>
                    </IonRow>
                  }
                />
              </IonCol>
            )
          })
        }
      </IonRow>        
    </IonGrid>
    </IonContent>
  );
};
    
export default MoreApps;