/**
 * Reducers creados para obtener asíncronamente 
 * los datos de los modelos y dependiendo del resultado
 * de la peticón actualice el state en redux o mande un mensaje de error
 */

import { PayloadAction } from "@reduxjs/toolkit";
import { getDatosPersonalesAsyncThunk, getAllDatosPersonalesAsyncThunk, updateDatosPersonalesAsyncThunk, createDatosPersonalesAsyncThunk, deleteDatosPersonalesAsyncThunk } from "../../asyncThunk/personas";
import { DEFAULT_STATE } from "../../enums/default_state";
import { IStatusDatosPersonales } from "../../interfaces/personas";
import { personasInitialState } from "../../slices/personasSlice";

// Reducers: DatosPersonales
export const createDatosPersonalesReducers = () => {
  const get = () => {
    var { pending, fulfilled, rejected } = getDatosPersonalesAsyncThunk;
    return {
      [pending.type]: (state : typeof personasInitialState) => {
        state.personasState.datosPersonales.estado = DEFAULT_STATE.loading;
      },
      [fulfilled.type]: (state : typeof personasInitialState, action : PayloadAction<IStatusDatosPersonales>) => {
        const result = action.payload;
        result.expiry_array = state.personasState.datosPersonales.expiry_array;
        if (result.estado === DEFAULT_STATE.error) result.data_array = state.personasState.datosPersonales.data_array;
        state.personasState.datosPersonales = result;
      },
      [rejected.type]: (state : typeof personasInitialState, action : PayloadAction<IStatusDatosPersonales>) => {
        state.personasState.datosPersonales = {
          ...state.personasState.datosPersonales,
          estado: action.payload.estado,
          mensaje: "Ocurrió un problema al realizar la solicitud",
        };
      },
    };
  };
  
  const getAll = () => {
    var { pending, fulfilled, rejected } = getAllDatosPersonalesAsyncThunk;
    return {
      [pending.type]: (state : typeof personasInitialState) => {
        state.personasState.datosPersonales.estado = DEFAULT_STATE.loading;
      },
      [fulfilled.type]: (state : typeof personasInitialState, action : PayloadAction<IStatusDatosPersonales>) => {
        const result = action.payload;
        result.data = state.personasState.datosPersonales.data;
        result.expiry = state.personasState.datosPersonales.expiry;
        if (result.estado === DEFAULT_STATE.error) result.data_array = state.personasState.datosPersonales.data_array;
        state.personasState.datosPersonales = result;
      },
      [rejected.type]: (state : typeof personasInitialState, action : PayloadAction<IStatusDatosPersonales>) => {
        state.personasState.datosPersonales = {
          ...state.personasState.datosPersonales,
          estado: action.payload.estado,
          mensaje: "Ocurrió un problema al realizar la solicitud",
        };
      },
    };
  };
  
  const update = () => {
    var { pending, fulfilled, rejected } = updateDatosPersonalesAsyncThunk;
    return {
      [pending.type]: (state : typeof personasInitialState) => {
        state.personasState.datosPersonales.estado = DEFAULT_STATE.loading;
      },
      [fulfilled.type]: (state : typeof personasInitialState, action : PayloadAction<IStatusDatosPersonales>) => {
        const result: IStatusDatosPersonales = action.payload;
        result.expiry = state.personasState.datosPersonales.expiry;
        result.expiry_array = state.personasState.datosPersonales.expiry_array;
        if (result.estado === DEFAULT_STATE.error) result.data_array = state.personasState.datosPersonales.data_array;
        state.personasState.datosPersonales = result;
      },
      [rejected.type]: (state : typeof personasInitialState, action : PayloadAction<IStatusDatosPersonales>) => {
        state.personasState.datosPersonales = {
          ...state.personasState.datosPersonales,
          estado: action.payload.estado,
          mensaje: "Ocurrió un problema al realizar la solicitud",
        };
      },
    };
  };

  const create = () => {
    var { pending, fulfilled, rejected } = createDatosPersonalesAsyncThunk;
    return {
      [pending.type]: (state : typeof personasInitialState) => {
        state.personasState.datosPersonales.estado = DEFAULT_STATE.loading;
      },
      [fulfilled.type]: (state : typeof personasInitialState, action : PayloadAction<IStatusDatosPersonales>) => {
        const result = action.payload;
        result.expiry = state.personasState.datosPersonales.expiry;
        result.expiry_array = state.personasState.datosPersonales.expiry_array;
        if (result.estado === DEFAULT_STATE.error) result.data_array = state.personasState.datosPersonales.data_array;
        state.personasState.datosPersonales = result;
      },
      [rejected.type]: (state : typeof personasInitialState, action : PayloadAction<IStatusDatosPersonales>) => {
        state.personasState.datosPersonales = {
          ...state.personasState.datosPersonales,
          estado: action.payload.estado,
          mensaje: "Ocurrió un problema al realizar la solicitud",
        };
      },
    };
  };
  
  const del = () => {
    var { pending, fulfilled, rejected } = deleteDatosPersonalesAsyncThunk;
    return {
      [pending.type]: (state : typeof personasInitialState) => {
        state.personasState.datosPersonales.estado = DEFAULT_STATE.loading;
      },
      [fulfilled.type]: (state : typeof personasInitialState, action : PayloadAction<IStatusDatosPersonales>) => {
        const result: IStatusDatosPersonales = action.payload;
        result.expiry = state.personasState.datosPersonales.expiry;
        result.expiry_array = state.personasState.datosPersonales.expiry_array;
        if (result.estado === DEFAULT_STATE.error) result.data_array = state.personasState.datosPersonales.data_array;
        state.personasState.datosPersonales = result;
      },
      [rejected.type]: (state : typeof personasInitialState, action : PayloadAction<IStatusDatosPersonales>) => {
        state.personasState.datosPersonales = {
          ...state.personasState.datosPersonales,
          estado: action.payload.estado,
          mensaje: "Ocurrió un problema al realizar la solicitud",
        };
      },
    };
  };

  return {
    ...get(),
    ...getAll(),
    ...update(),
    ...create(),
    ...del(),
  };
};
