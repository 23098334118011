/**
 * Métodos AsyncThunk para ser usados en los reducers y
 * los resultados a las llamadas http puedan ser usados para
 * actualizar el state en redux
 */

import { createAsyncThunk } from "@reduxjs/toolkit";
import { DEFAULT_STATE } from "../../enums/default_state";
import { getInformacionBancaria, getAllInformacionBancaria, updateInformacionBancaria, createInformacionBancaria, deleteInformacionBancaria } from "../../http/personas";
import { getEmptyStatusInformacionBancaria, IInformacionBancaria } from "../../interfaces/personas";
import { IServerDate } from "../../interfaces/time";

export const getInformacionBancariaAsyncThunk = createAsyncThunk(
  'personas/getInformacionBancariaAsyncThunk',
  async (query : {serverDate : IServerDate, id: number, idCliente : number}) => await getInformacionBancaria(query.serverDate, query.id, query.idCliente)
    .then(async (result) => result)
    .catch(async (error) => getEmptyStatusInformacionBancaria(DEFAULT_STATE.error))  
);

export const getAllInformacionBancariaAsyncThunk = createAsyncThunk(
  'personas/getAllInformacionBancariaAsyncThunk',
  async (queryAll : {serverDate : IServerDate, idCliente : number}) => await getAllInformacionBancaria(queryAll.serverDate, queryAll.idCliente)
    .then(async (result) => result)
    .catch(async (error) => getEmptyStatusInformacionBancaria(DEFAULT_STATE.error))  
);

export const updateInformacionBancariaAsyncThunk = createAsyncThunk(
  'personas/updateInformacionBancariaAsyncThunk',
  async (update: {serverDate : IServerDate, id: number, idCliente : number, object: IInformacionBancaria}) => await updateInformacionBancaria(update.serverDate, update.id, update.idCliente, update.object)
    .then(async (result) => result)
    .catch(async (error) => getEmptyStatusInformacionBancaria(DEFAULT_STATE.error))  
);

export const createInformacionBancariaAsyncThunk = createAsyncThunk(
  'personas/createInformacionBancariaAsyncThunk',
  async (create : {serverDate : IServerDate, idCliente : number, object: IInformacionBancaria}) => await createInformacionBancaria(create.serverDate, create.idCliente, create.object)
    .then(async (result) => result)
    .catch(async (error) => getEmptyStatusInformacionBancaria(DEFAULT_STATE.error))  
);

export const deleteInformacionBancariaAsyncThunk = createAsyncThunk(
  'personas/deleteInformacionBancariaAsyncThunk',
  async (del : {id : number, idCliente : number}) => await deleteInformacionBancaria(del.id, del.idCliente)
    .then(async (result) => result)
    .catch(async (error) => getEmptyStatusInformacionBancaria())  
);
