import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { List, setListItem } from "../../interfaces/helpers";
import { ISolicitudCambioTelefonoAutorizado } from "../../interfaces/personas";
import { RootState } from "../../store";
import { getNombreEmpleado } from "../personasSlice";

export interface IDeviceChangeRequests {
  selectedEmpresa: List;
  selectedEmpleado: List;
  selectedMvEstatusCambioDispositivo: List;
}

export const deviceChangeRequestInitialState: IDeviceChangeRequests = {
  selectedEmpresa: { id: "", title: "Todas las empresas" },
  selectedEmpleado: { id: "", title: "Seleccione un empleado" },
  selectedMvEstatusCambioDispositivo: { id: "", title: "Todos los estatus" },
};

export const deviceChangeRequestSlice = createSlice({
  name: "deviceChangeRequest",
  initialState: deviceChangeRequestInitialState,
  reducers: {
    setDeviceChangeRequestSelectedEmpresa: (
      state,
      action: PayloadAction<List>
    ) => {
      state.selectedEmpresa = action.payload;
    },
    setDeviceChangeRequestSelectedEmpleado: (
      state,
      action: PayloadAction<List>
    ) => {
      state.selectedEmpleado = action.payload;
    },
    setDeviceChangeRequestSelectedMvEstatusSolicitudCambioTelefono: (
      state,
      action: PayloadAction<List>
    ) => {
      state.selectedMvEstatusCambioDispositivo = action.payload;
    },
  },
});

export const {
  setDeviceChangeRequestSelectedEmpleado,
  setDeviceChangeRequestSelectedEmpresa,
  setDeviceChangeRequestSelectedMvEstatusSolicitudCambioTelefono,
} = deviceChangeRequestSlice.actions;

export default deviceChangeRequestSlice.reducer;

export const getMemoizedDeviceChangeRequestActiveEmpleado = createSelector(
  (state: RootState) => state.deviceChangeRequest.selectedEmpresa,
  (state: RootState) => state.personas.personasState.empleado.data_array,
  (selectedEmpresa, empleados) => {
    if (selectedEmpresa.id === "") return empleados.filter((e) => e.activo);
    const empresa = Number(selectedEmpresa.id);
    return empleados.filter((e) => e.empresa.id === empresa && e.activo);
  }
);

export const getMemoizedDeviceChangeRequestActiveEmpleadosAsSelectList =
  createSelector(getMemoizedDeviceChangeRequestActiveEmpleado, (empleados) => {
    return empleados.map((e) =>
      setListItem({
        id: e.id.toString(),
        title: getNombreEmpleado(e),
      })
    );
  });

export const getMemoizedDeviceChangeRequestByEmpresa = createSelector(
  getMemoizedDeviceChangeRequestActiveEmpleado,
  (state: RootState) =>
    state.personas.personasState.solicitudCambioTelefonoAutorizado.data_array,
  (state: RootState) =>
    state.deviceChangeRequest.selectedMvEstatusCambioDispositivo,
  (
    empleados,
    solicitudesDeCambioDeDispositivo,
    selectedMvEstatusCambioDispositivo
  ) => {
    if (selectedMvEstatusCambioDispositivo.id === "")
      return solicitudesDeCambioDeDispositivo.filter(
        (solicitud: ISolicitudCambioTelefonoAutorizado) =>
          empleados.some((empleado) => empleado.id === solicitud.empleado.id)
      );
    return solicitudesDeCambioDeDispositivo.filter(
      (solicitud: ISolicitudCambioTelefonoAutorizado) =>
        empleados.some((empleado) => empleado.id === solicitud.empleado.id) &&
        solicitud.estatus.id.toString() ===
          selectedMvEstatusCambioDispositivo.id
    );
  }
);
