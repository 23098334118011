import { getArrayItemStorageData, getStorageData, removeArrayItemStorageData, removeStorageData, setArrayItemStorageData, setStorageData } from "../../api/storage";
import { getEmptyUser, IUser } from "../../interfaces/personas";

export const setStorageUser = async (IUser : IUser) => await setStorageData('IUser', IUser);
export const getStorageUser = async () : Promise<IUser> => await getStorageData('IUser', getEmptyUser());
export const removeStorageUser = async () => await removeStorageData('IUser');
export const setStorageUserArray = async (IUser : IUser[]) => await setStorageData('IUserArray', IUser);
export const getStorageUserArray = async () : Promise<IUser[]>  => await getStorageData('IUserArray', [] as IUser[]);
export const removeStorageUserArray = async () => await removeStorageData('IUserArray');
export const setStorageUserArrayItem = async (id: number, IUser : IUser) : Promise<IUser[]> => await setArrayItemStorageData('IUserArray', id, IUser, [] as IUser[]);
export const getStorageUserArrayItem = async (id: number) : Promise<IUser> => await getArrayItemStorageData('IUserArray', id, getEmptyUser);
export const removeStorageUserArrayItem = async (id: number) : Promise<IUser[]> => await removeArrayItemStorageData('IUserArray', id, [] as IUser[]);
