/**
 * Métodos AsyncThunk para ser usados en los reducers y
 * los resultados a las llamadas http puedan ser usados para
 * actualizar el state en redux
 */

import { createAsyncThunk } from "@reduxjs/toolkit";
import { DEFAULT_STATE } from "../../enums/default_state";
import { getMvMotivoCancelacion, getAllMvMotivoCancelacion, updateMvMotivoCancelacion, createMvMotivoCancelacion, deleteMvMotivoCancelacion } from "../../http/rondines";
import { getEmptyStatusMvMotivoCancelacion, IMvMotivoCancelacion } from "../../interfaces/rondines";
import { IServerDate } from "../../interfaces/time";

export const getMvMotivoCancelacionAsyncThunk = createAsyncThunk(
  'rondines/getMvMotivoCancelacionAsyncThunk',
  async (query : {serverDate : IServerDate, id: number}) => await getMvMotivoCancelacion(query.serverDate, query.id)
    .then(async (result) => result)
    .catch(async (error) => getEmptyStatusMvMotivoCancelacion(DEFAULT_STATE.error))  
);

export const getAllMvMotivoCancelacionAsyncThunk = createAsyncThunk(
  'rondines/getAllMvMotivoCancelacionAsyncThunk',
  async (queryAll : {serverDate : IServerDate, idCliente : number}) => await getAllMvMotivoCancelacion(queryAll.serverDate, queryAll.idCliente)
    .then(async (result) => result)
    .catch(async (error) => getEmptyStatusMvMotivoCancelacion(DEFAULT_STATE.error))  
);

export const updateMvMotivoCancelacionAsyncThunk = createAsyncThunk(
  'rondines/updateMvMotivoCancelacionAsyncThunk',
  async (update: {serverDate : IServerDate, id: number, MvMotivoCancelacion: IMvMotivoCancelacion}) => await updateMvMotivoCancelacion(update.serverDate, update.id, update.MvMotivoCancelacion)
    .then(async (result) => result)
    .catch(async (error) => getEmptyStatusMvMotivoCancelacion(DEFAULT_STATE.error))  
);

export const createMvMotivoCancelacionAsyncThunk = createAsyncThunk(
  'rondines/createMvMotivoCancelacionAsyncThunk',
  async (create : {serverDate : IServerDate, idCliente : number, MvMotivoCancelacion: IMvMotivoCancelacion}) => await createMvMotivoCancelacion(create.serverDate, create.idCliente, create.MvMotivoCancelacion)
    .then(async (result) => result)
    .catch(async (error) => getEmptyStatusMvMotivoCancelacion(DEFAULT_STATE.error))  
);

export const deleteMvMotivoCancelacionAsyncThunk = createAsyncThunk(
  'rondines/deleteMvMotivoCancelacionAsyncThunk',
  async (del : {id : number}) => await deleteMvMotivoCancelacion(del.id)
    .then(async (result) => result)
    .catch(async (error) => getEmptyStatusMvMotivoCancelacion())  
);
