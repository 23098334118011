import { createSlice, createSelector, PayloadAction } from "@reduxjs/toolkit";
import { getEmptyStatusCheckEnRuta, getEmptyStatusCheckpointRondin, getEmptyStatusCheckRondin, getEmptyStatusMvMotivoCancelacion, getEmptyStatusRondin, getEmptyStatusRondinCancelado, getEmptyStatusRondinCompletado } from "../interfaces/rondines";
import { rondinesReducers } from "../reducers/rondines";

export const rondinesInitialState = {
  rondinesState: {
    checkEnRuta: getEmptyStatusCheckEnRuta(),
    checkpointRondin: getEmptyStatusCheckpointRondin(),
    checkRondin: getEmptyStatusCheckRondin(),
    mvMotivoCancelacion: getEmptyStatusMvMotivoCancelacion(),
    rondin: getEmptyStatusRondin(),
    rondinCancelado: getEmptyStatusRondinCancelado(),
    rondinCompletado: getEmptyStatusRondinCompletado(),
  },
}

export const rondinesSlice = createSlice({
  name: 'rondines',
  initialState: rondinesInitialState,
  reducers: {
  },
  extraReducers: rondinesReducers,
});


export default rondinesSlice.reducer;