/**
 * Métodos para el manejo de IonicStorage 
 * @setStorage              guarda un unico objeto al storage
 * @getStorage              obtiene el objeto guardado con @setStorage
 * @setStorageArray         guarda el array de objetos en el storage
 * @getStorageArray         obtiene el array de objetos guardado con @setStorageArray
 * @removeStorageArray      elimina el array de objetos guardado con @setStorageArray
 * @setStorageItemArray     agrega un elemento al array de objeto guardado con @setStorageArray
 * @getStorageItemArray     obtiene un elemento del array guardado con @setStorageArray buscandolo por su id 
 * @removeStorageItemArray  elimina un elemento del array guardado con @setStorageArray buscandolo por su id 
 */

import { setStorageData, getStorageData, removeStorageData, setArrayItemStorageData, getArrayItemStorageData, removeArrayItemStorageData } from "../../api/storage";
import { IMvTipoContrato, getEmptyMvTipoContrato } from "../../interfaces/nomina";

export const setStorageMvTipoContrato = async (IMvTipoContrato : IMvTipoContrato) => await setStorageData('IMvTipoContrato', IMvTipoContrato);
export const getStorageMvTipoContrato = async () : Promise<IMvTipoContrato> => await getStorageData('IMvTipoContrato', getEmptyMvTipoContrato());
export const removeStorageMvTipoContrato = async () => await removeStorageData('IMvTipoContrato');
export const setStorageMvTipoContratoArray = async (IMvTipoContrato : IMvTipoContrato[]) => await setStorageData('IMvTipoContratoArray', IMvTipoContrato);
export const getStorageMvTipoContratoArray = async () : Promise<IMvTipoContrato[]> => await getStorageData('IMvTipoContratoArray', [] as IMvTipoContrato[]);
export const removeStorageMvTipoContratoArray = async () => await removeStorageData('IMvTipoContratoArray');
export const setStorageMvTipoContratoArrayItem = async (id: number, IMvTipoContrato : IMvTipoContrato) : Promise<IMvTipoContrato[]> => await setArrayItemStorageData('IMvTipoContratoArray', id, IMvTipoContrato, [] as IMvTipoContrato[]);
export const getStorageMvTipoContratoArrayItem = async (id: number) : Promise<IMvTipoContrato> => await getArrayItemStorageData('IMvTipoContratoArray', id, getEmptyMvTipoContrato);
export const removeStorageMvTipoContratoArrayItem = async (id: number) : Promise<IMvTipoContrato[]> => await removeArrayItemStorageData('IMvTipoContratoArray', id, [] as IMvTipoContrato[]);
