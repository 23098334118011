import {
  IonCol,
  IonContent,
  IonGrid,
  IonItem,
  IonLabel,
  IonSpinner,
} from "@ionic/react";
import React from "react";
import useRedux from "../hooks/useRedux";
import GoogleMapsV3 from "../components/GoogleMapsV3";
import CompanyForm from "../components/CompanyForm";
import { DEFAULT_STATE } from "../redux/enums/default_state";
const Company: React.FC = () => {
  const [{ empresa }] = useRedux();

  return (
    <IonContent class="inner-content">
      <IonGrid>
        <IonCol
          className="no-padding"
          id="dashboard-map"
          style={{ height: "0", width: "0", display: "none" }}
        >
          <GoogleMapsV3
            id="dashboard-map"
            content={undefined}
            markers={[]}
            listData={[]}
            hasSearchBox={false}
            defaultWorkCenterSelector={false}
          />
        </IonCol>
      </IonGrid>
      {empresa.estado === DEFAULT_STATE.loading ? (
        <IonGrid>
          <IonCol>
            <IonItem lines="none">
              <IonLabel>Cargando lista de empresas activas.</IonLabel>
              <IonSpinner
                className="loading-center-horizontal"
                name="circles"
                color="medium"
                slot="start"
              />
            </IonItem>
          </IonCol>
        </IonGrid>
      ) : (
        <CompanyForm />
      )}
    </IonContent>
  );
};
export default Company;
