/**
 * Reducers creados para obtener asíncronamente 
 * los datos de los modelos y dependiendo del resultado
 * de la peticón actualice el state en redux o mande un mensaje de error
 */

import { PayloadAction } from "@reduxjs/toolkit";
import { getCambioEstatusIncapacidadAsyncThunk, getAllCambioEstatusIncapacidadAsyncThunk, updateCambioEstatusIncapacidadAsyncThunk, createCambioEstatusIncapacidadAsyncThunk, deleteCambioEstatusIncapacidadAsyncThunk } from "../../asyncThunk/nomina";
import { DEFAULT_STATE } from "../../enums/default_state";
import { IStatusCambioEstatusIncapacidad } from "../../interfaces/nomina";
import { nominaInitialState } from "../../slices/nominaSlice";

// Reducers: CambioEstatusIncapacidad
export const createCambioEstatusIncapacidadReducers = () => {
  const get = () => {
    var { pending, fulfilled, rejected } = getCambioEstatusIncapacidadAsyncThunk;
    return {
      [pending.type]: (state : typeof nominaInitialState) => {
        state.nominaState.cambioEstatusIncapacidad.estado = DEFAULT_STATE.loading;
      },
      [fulfilled.type]: (state : typeof nominaInitialState, action : PayloadAction<IStatusCambioEstatusIncapacidad>) => {
        const result = action.payload;
        result.expiry_array = state.nominaState.cambioEstatusIncapacidad.expiry_array;
        if (result.estado === DEFAULT_STATE.error) result.data_array = state.nominaState.cambioEstatusIncapacidad.data_array;
        state.nominaState.cambioEstatusIncapacidad = result;
      },
      [rejected.type]: (state : typeof nominaInitialState, action : PayloadAction<IStatusCambioEstatusIncapacidad>) => {
        state.nominaState.cambioEstatusIncapacidad = {
          ...state.nominaState.cambioEstatusIncapacidad,
          estado: action.payload.estado,
          mensaje: "Ocurrió un problema al realizar la solicitud",
        };
      },
    };
  };
  
  const getAll = () => {
    var { pending, fulfilled, rejected } = getAllCambioEstatusIncapacidadAsyncThunk;
    return {
      [pending.type]: (state : typeof nominaInitialState) => {
        state.nominaState.cambioEstatusIncapacidad.estado = DEFAULT_STATE.loading;
      },
      [fulfilled.type]: (state : typeof nominaInitialState, action : PayloadAction<IStatusCambioEstatusIncapacidad>) => {
        const result = action.payload;
        result.data = state.nominaState.cambioEstatusIncapacidad.data;
        result.expiry = state.nominaState.cambioEstatusIncapacidad.expiry;
        if (result.estado === DEFAULT_STATE.error) result.data_array = state.nominaState.cambioEstatusIncapacidad.data_array;
        state.nominaState.cambioEstatusIncapacidad = result;
      },
      [rejected.type]: (state : typeof nominaInitialState, action : PayloadAction<IStatusCambioEstatusIncapacidad>) => {
        state.nominaState.cambioEstatusIncapacidad = {
          ...state.nominaState.cambioEstatusIncapacidad,
          estado: action.payload.estado,
          mensaje: "Ocurrió un problema al realizar la solicitud",
        };
      },
    };
  };
  
  const update = () => {
    var { pending, fulfilled, rejected } = updateCambioEstatusIncapacidadAsyncThunk;
    return {
      [pending.type]: (state : typeof nominaInitialState) => {
        state.nominaState.cambioEstatusIncapacidad.estado = DEFAULT_STATE.loading;
      },
      [fulfilled.type]: (state : typeof nominaInitialState, action : PayloadAction<IStatusCambioEstatusIncapacidad>) => {
        const result: IStatusCambioEstatusIncapacidad = action.payload;
        result.expiry = state.nominaState.cambioEstatusIncapacidad.expiry;
        result.expiry_array = state.nominaState.cambioEstatusIncapacidad.expiry_array;
        if (result.estado === DEFAULT_STATE.error) result.data_array = state.nominaState.cambioEstatusIncapacidad.data_array;
        state.nominaState.cambioEstatusIncapacidad = result;
      },
      [rejected.type]: (state : typeof nominaInitialState, action : PayloadAction<IStatusCambioEstatusIncapacidad>) => {
        state.nominaState.cambioEstatusIncapacidad = {
          ...state.nominaState.cambioEstatusIncapacidad,
          estado: action.payload.estado,
          mensaje: "Ocurrió un problema al realizar la solicitud",
        };
      },
    };
  };

  const create = () => {
    var { pending, fulfilled, rejected } = createCambioEstatusIncapacidadAsyncThunk;
    return {
      [pending.type]: (state : typeof nominaInitialState) => {
        state.nominaState.cambioEstatusIncapacidad.estado = DEFAULT_STATE.loading;
      },
      [fulfilled.type]: (state : typeof nominaInitialState, action : PayloadAction<IStatusCambioEstatusIncapacidad>) => {
        const result = action.payload;
        result.expiry = state.nominaState.cambioEstatusIncapacidad.expiry;
        result.expiry_array = state.nominaState.cambioEstatusIncapacidad.expiry_array;
        if (result.estado === DEFAULT_STATE.error) result.data_array = state.nominaState.cambioEstatusIncapacidad.data_array;
        state.nominaState.cambioEstatusIncapacidad = result;
      },
      [rejected.type]: (state : typeof nominaInitialState, action : PayloadAction<IStatusCambioEstatusIncapacidad>) => {
        state.nominaState.cambioEstatusIncapacidad = {
          ...state.nominaState.cambioEstatusIncapacidad,
          estado: action.payload.estado,
          mensaje: "Ocurrió un problema al realizar la solicitud",
        };
      },
    };
  };
  
  const del = () => {
    var { pending, fulfilled, rejected } = deleteCambioEstatusIncapacidadAsyncThunk;
    return {
      [pending.type]: (state : typeof nominaInitialState) => {
        state.nominaState.cambioEstatusIncapacidad.estado = DEFAULT_STATE.loading;
      },
      [fulfilled.type]: (state : typeof nominaInitialState, action : PayloadAction<IStatusCambioEstatusIncapacidad>) => {
        const result: IStatusCambioEstatusIncapacidad = action.payload;
        result.expiry = state.nominaState.cambioEstatusIncapacidad.expiry;
        result.expiry_array = state.nominaState.cambioEstatusIncapacidad.expiry_array;
        if (result.estado === DEFAULT_STATE.error) result.data_array = state.nominaState.cambioEstatusIncapacidad.data_array;
        state.nominaState.cambioEstatusIncapacidad = result;
      },
      [rejected.type]: (state : typeof nominaInitialState, action : PayloadAction<IStatusCambioEstatusIncapacidad>) => {
        state.nominaState.cambioEstatusIncapacidad = {
          ...state.nominaState.cambioEstatusIncapacidad,
          estado: action.payload.estado,
          mensaje: "Ocurrió un problema al realizar la solicitud",
        };
      },
    };
  };

  return {
    ...get(),
    ...getAll(),
    ...update(),
    ...create(),
    ...del(),
  };
};
