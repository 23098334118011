/**
 * Es un modal para la visualización de las imágenes en un tamaño más grande actulmente usado en pages/Record.tsx para la
 * visualización de las entradas registradas por otros trabajadores
 * @param {any} data es el objeto de la imágen
 * @param {Function} onDismiss es el método que se realiza al cerrar el diálogo
 */

import { IonPage, IonHeader, IonToolbar, IonButtons, IonButton, IonTitle, IonContent, IonGrid, IonRow, IonCol } from "@ionic/react";

const ImageModal: React.FC<{data: any, onDismiss: () => void}> = ({onDismiss, data}) => {

  return(
    <IonPage>
      <IonHeader>
        <IonToolbar color="secondary" className="toolBarStart">
          <IonButtons slot="start">
            <IonButton onClick={() => onDismiss()}>Regresar</IonButton>
          </IonButtons>
          <IonTitle  className="center">Imagen</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent className="ion-padding">
        <IonGrid>
          <IonRow> 
            <IonCol size="12">
              {data}
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  )
};

export default ImageModal;
