/**
 * Métodos del, get, post, put para llamadas a la API
 * @get     en este método primero consulta con @getFromLocalStorage si es necesario hacer la petición http sino se obtiene del LocalStorage;
 *          si hay un error en la petición http se busca el objeto en el LocalStorage sino lo encuentra devuelve un objeto vacío; si la
 *          petición se realiza correctamente actualiza el valor individual y en el array del objeto en el LocalStorage y devuelve el objeto
 *          obtenido del servidor.
 * @getAll  en este método primero consulta con @getFromLocalStorage si es necesario hacer la petición http sino se obtiene del LocalStorage;
 *          si hay un error en la petición http se busca el objeto array en el LocalStorage sino lo encuentra devuelve un array vacío; si la
 *          petición se realiza correctamente actualiza array en el LocalStorage y devuelve el array obtenido del servidor
 * @update  en este método después de realizar la petición http si falla devuelve el objeto que se iba a actualizar; si la petición se
 *          realiza correctamente actualiza el valor individual y en el array del objeto en el LocalStorage y devuelve el objeto obtenido del
 *          servidor.
 * @create  en este método después de realizar la petición http si falla devuelve el objeto que se iba a crear; si la petición se realiza
 *          correctamente actualiza el valor individual y en el array del objeto en el LocalStorage y devuelve el objeto obtenido del
 *          servidor.
 * @delete  en este método después de realizar la petición http si falla devuelve el objeto que se iba a eliminar; si la petición se realiza
 *          correctamente elimina el valor individual y en el objeto en el arrya del LocalStorage y devuelve un objeto vacio (Default)
 */

import { del, get, post, put } from "../../api/api";
import {
  getFromLocalStorage,
  updateLocalStorageExpiry,
} from "../../api/helpers";
import { removeArrayItemStorageData } from "../../api/storage";
import { DEFAULT_STATE } from "../../enums/default_state";
import {
  getEmptyStatusAntiguedadEmpleado,
  setAntiguedadEmpleado,
  IAntiguedadEmpleado,
  getSerializableAntiguedadEmpleado,
} from "../../interfaces/personas";
import { IServerDate } from "../../interfaces/time";
import {
  getStorageAntiguedadEmpleadoArrayItem,
  setStorageAntiguedadEmpleado,
  setStorageAntiguedadEmpleadoArrayItem,
  getStorageAntiguedadEmpleadoArray,
  setStorageAntiguedadEmpleadoArray,
  removeStorageAntiguedadEmpleado,
} from "../../localStorage/personas";

export const getAntiguedadEmpleado = async (
  serverDate: IServerDate,
  pk: number,
  idCliente: number
) => {
  const temp = getEmptyStatusAntiguedadEmpleado();
  const doHttp = await getFromLocalStorage(serverDate, temp.expiry);
  temp.expiry = doHttp.storage;
  if (!doHttp.result) {
    console.log(
      `(localStorage) AntiguedadEmpleado con pk: ${pk}, obtenido con éxito`
    );
    temp.estado = DEFAULT_STATE.success;
    temp.mensaje = `AntiguedadEmpleado obtenida con éxito`;
    temp.data = await getStorageAntiguedadEmpleadoArrayItem(pk);
    return temp;
  }
  const result = await get(`personas/antiguedad-empleado/${pk}/${idCliente}`);
  const data = result.data;
  console.log(`AntiguedadEmpleado data: `, data);
  if (!data.id || data.detail) {
    console.log(
      `Error al obtener AntiguedadEmpleado con pk: ${pk}, ${data.detail}`
    );
    temp.estado = DEFAULT_STATE.error;
    temp.mensaje = `Error al obtener la antiguedad empleado: ${
      data.detail ?? "Hubo un problema al conectarse con el servidor"
    }`;
    temp.data = await getStorageAntiguedadEmpleadoArrayItem(pk);
    return temp;
  }
  console.log(`AntiguedadEmpleado con pk: ${pk}, obtenido con éxito`);
  temp.estado = DEFAULT_STATE.success;
  temp.mensaje = `AntiguedadEmpleado obtenida con éxito`;
  temp.data = await setAntiguedadEmpleado(data, serverDate);
  await setStorageAntiguedadEmpleado(temp.data);
  temp.data_array = await setStorageAntiguedadEmpleadoArrayItem(pk, temp.data);
  temp.expiry = await updateLocalStorageExpiry(serverDate, temp.expiry);
  return temp;
};

export const getAllAntiguedadEmpleado = async (
  serverDate: IServerDate,
  idCliente: number
) => {
  const temp = getEmptyStatusAntiguedadEmpleado();
  const doHttp = await getFromLocalStorage(serverDate, temp.expiry_array);
  temp.expiry_array = doHttp.storage;
  if (!doHttp.result) {
    console.log(
      `(localStorage) Todos las AntiguedadEmpleados obtenidas con éxito`
    );
    temp.estado = DEFAULT_STATE.success;
    temp.mensaje = `Todos las antiguedad empleados obtenidas con éxito`;
    temp.data_array = await getStorageAntiguedadEmpleadoArray();
    return temp;
  }
  const result = await get(`personas/antiguedad-empleado/${idCliente}`);
  const data = result.data;
  if (data.detail) {
    console.log(`Error al obtener la lista de AntiguedadEmpleado`, data);
    temp.estado = DEFAULT_STATE.error;
    temp.mensaje = `Error al obtener la lista de antiguedad empleados: ${
      data.detail ?? "Hubo un problema al conectarse con el servidor"
    }`;
    temp.data_array = await getStorageAntiguedadEmpleadoArray();
    return temp;
  }
  console.log(`Todos las AntiguedadEmpleados obtenidos con éxito`);
  temp.estado = DEFAULT_STATE.success;
  temp.mensaje = `Todos las antiguedad empleados obtenidas con éxito`;
  temp.data_array = await Promise.all(
    data.map(async (e: any) => await setAntiguedadEmpleado(e, serverDate))
  );
  await setStorageAntiguedadEmpleadoArray(temp.data_array);
  temp.expiry_array = await updateLocalStorageExpiry(
    serverDate,
    temp.expiry_array
  );
  return temp;
};

export const updateAntiguedadEmpleado = async (
  serverDate: IServerDate,
  pk: number,
  idCliente: number,
  object: IAntiguedadEmpleado
) => {
  const temp = getEmptyStatusAntiguedadEmpleado();
  const result = await put(
    `personas/antiguedad-empleado/${pk}/${idCliente}`,
    getSerializableAntiguedadEmpleado(object)
  );
  const data = result.data;
  if (!data.id || data.detail) {
    console.log(
      `Error al actualizar AntiguedadEmpleado con pk: ${pk}, ${data.detail}`
    );
    temp.estado = DEFAULT_STATE.error;
    temp.mensaje = `Error al actualizar la antiguedad empleado: ${
      data.detail ?? "Hubo un problema al conectarse con el servidor"
    }`;
    temp.data = await setAntiguedadEmpleado(object, serverDate);
    return temp;
  }
  console.log(`AntiguedadEmpleado con pk: ${pk}, actualizado con éxito`);
  temp.estado = DEFAULT_STATE.success;
  temp.mensaje = `AntiguedadEmpleado actualizada con éxito`;
  temp.data = await setAntiguedadEmpleado(data, serverDate);
  await setStorageAntiguedadEmpleado(temp.data);
  temp.data_array = await setStorageAntiguedadEmpleadoArrayItem(
    temp.data.id,
    temp.data
  );
  return temp;
};

export const createAntiguedadEmpleado = async (
  serverDate: IServerDate,
  idCliente: number,
  object: IAntiguedadEmpleado
) => {
  const temp = getEmptyStatusAntiguedadEmpleado();
  const result = await post(
    `personas/antiguedad-empleado/${idCliente}`,
    getSerializableAntiguedadEmpleado(object)
  );
  const data = result.data;
  if (!data.id || data.detail) {
    console.log(
      `Error al crear AntiguedadEmpleado con los datos ${object}, ${data.detail}`
    );
    temp.estado = DEFAULT_STATE.error;
    temp.mensaje = `Error al crear la antiguedad empleado: ${
      data.detail ?? "Hubo un problema al conectarse con el servidor"
    }`;
    temp.data = await setAntiguedadEmpleado(object, serverDate);
    return temp;
  }
  console.log(`AntiguedadEmpleado creada con éxito`);
  temp.estado = DEFAULT_STATE.success;
  temp.mensaje = `AntiguedadEmpleado creada con éxito`;
  temp.data = await setAntiguedadEmpleado(data, serverDate);
  await setStorageAntiguedadEmpleado(temp.data);
  temp.data_array = await setStorageAntiguedadEmpleadoArrayItem(
    temp.data.id,
    temp.data
  );
  return temp;
};

export const deleteAntiguedadEmpleado = async (
  pk: number,
  idCliente: number
) => {
  const temp = getEmptyStatusAntiguedadEmpleado();
  const result = await del(`personas/antiguedad-empleado/${pk}/${idCliente}`);
  const data = result.data;
  if (data.detail) {
    console.log(
      `Error al eliminar AntiguedadEmpleado con pk: ${pk}, ${data.detail}`
    );
    temp.estado = DEFAULT_STATE.error;
    temp.mensaje = `Error al eliminar la antiguedad empleado: ${data.detail}`;
    temp.data = await getStorageAntiguedadEmpleadoArrayItem(pk);
    temp.data_array = await getStorageAntiguedadEmpleadoArray();
    return temp;
  }
  console.log(`AntiguedadEmpleado eliminada con éxito`);
  temp.estado = DEFAULT_STATE.success;
  temp.mensaje = `AntiguedadEmpleado eliminada con éxito`;
  await removeStorageAntiguedadEmpleado();
  temp.data_array = await removeArrayItemStorageData(
    "IAntiguedadEmpleado",
    pk,
    []
  );
  return temp;
};
