/**
 * Métodos AsyncThunk para ser usados en los reducers y
 * los resultados a las llamadas http puedan ser usados para
 * actualizar el state en redux
 */

import { createAsyncThunk } from "@reduxjs/toolkit";
import { DEFAULT_STATE } from "../../enums/default_state";
import { getHorasExtra, getAllHorasExtra, updateHorasExtra, createHorasExtra, deleteHorasExtra } from "../../http/nomina";
import { getEmptyStatusHorasExtra, IHorasExtra } from "../../interfaces/nomina";
import { IServerDate } from "../../interfaces/time";

export const getHorasExtraAsyncThunk = createAsyncThunk(
  'nomina/getHorasExtraAsyncThunk',
  async (query : {serverDate : IServerDate, id: number, idCliente : number}) => await getHorasExtra(query.serverDate, query.id, query.idCliente)
    .then(async (result) => result)
    .catch(async (error) => getEmptyStatusHorasExtra(DEFAULT_STATE.error))  
);

export const getAllHorasExtraAsyncThunk = createAsyncThunk(
  'nomina/getAllHorasExtraAsyncThunk',
  async (queryAll : {serverDate : IServerDate, idCliente : number}) => await getAllHorasExtra(queryAll.serverDate, queryAll.idCliente)
    .then(async (result) => result)
    .catch(async (error) => getEmptyStatusHorasExtra(DEFAULT_STATE.error))  
);

export const updateHorasExtraAsyncThunk = createAsyncThunk(
  'nomina/updateHorasExtraAsyncThunk',
  async (update: {serverDate : IServerDate, id: number, idCliente : number, object: IHorasExtra}) => await updateHorasExtra(update.serverDate, update.id, update.idCliente, update.object)
    .then(async (result) => result)
    .catch(async (error) => getEmptyStatusHorasExtra(DEFAULT_STATE.error))  
);

export const createHorasExtraAsyncThunk = createAsyncThunk(
  'nomina/createHorasExtraAsyncThunk',
  async (create : {serverDate : IServerDate, idCliente : number, object: IHorasExtra}) => await createHorasExtra(create.serverDate, create.idCliente, create.object)
    .then(async (result) => result)
    .catch(async (error) => getEmptyStatusHorasExtra(DEFAULT_STATE.error))  
);

export const deleteHorasExtraAsyncThunk = createAsyncThunk(
  'nomina/deleteHorasExtraAsyncThunk',
  async (del : {id : number, idCliente : number}) => await deleteHorasExtra(del.id, del.idCliente)
    .then(async (result) => result)
    .catch(async (error) => getEmptyStatusHorasExtra())  
);
