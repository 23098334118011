/**
 * Métodos AsyncThunk para ser usados en los reducers y
 * los resultados a las llamadas http puedan ser usados para
 * actualizar el state en redux
 */

import { createAsyncThunk } from "@reduxjs/toolkit";
import { DEFAULT_STATE } from "../../enums/default_state";
import { getServicioQentry, getAllServicioQentry, updateServicioQentry, createServicioQentry, deleteServicioQentry } from "../../http/personas";
import { getEmptyStatusServicioQentry, IServicioQentry } from "../../interfaces/personas";
import { IServerDate } from "../../interfaces/time";

export const getServicioQentryAsyncThunk = createAsyncThunk(
  'personas/getServicioQentryAsyncThunk',
  async (query : {serverDate : IServerDate, id: number, idCliente : number}) => await getServicioQentry(query.serverDate, query.id, query.idCliente)
    .then(async (result) => result)
    .catch(async (error) => getEmptyStatusServicioQentry(DEFAULT_STATE.error))  
);

export const getAllServicioQentryAsyncThunk = createAsyncThunk(
  'personas/getAllServicioQentryAsyncThunk',
  async (queryAll : {serverDate : IServerDate, idCliente : number}) => await getAllServicioQentry(queryAll.serverDate, queryAll.idCliente)
    .then(async (result) => result)
    .catch(async (error) => getEmptyStatusServicioQentry(DEFAULT_STATE.error))  
);

export const updateServicioQentryAsyncThunk = createAsyncThunk(
  'personas/updateServicioQentryAsyncThunk',
  async (update: {serverDate : IServerDate, id: number, idCliente : number, object: IServicioQentry}) => await updateServicioQentry(update.serverDate, update.id, update.idCliente, update.object)
    .then(async (result) => result)
    .catch(async (error) => getEmptyStatusServicioQentry(DEFAULT_STATE.error))  
);

export const createServicioQentryAsyncThunk = createAsyncThunk(
  'personas/createServicioQentryAsyncThunk',
  async (create : {serverDate : IServerDate, idCliente : number, object: IServicioQentry}) => await createServicioQentry(create.serverDate, create.idCliente, create.object)
    .then(async (result) => result)
    .catch(async (error) => getEmptyStatusServicioQentry(DEFAULT_STATE.error))  
);

export const deleteServicioQentryAsyncThunk = createAsyncThunk(
  'personas/deleteServicioQentryAsyncThunk',
  async (del : {id : number, idCliente : number}) => await deleteServicioQentry(del.id, del.idCliente)
    .then(async (result) => result)
    .catch(async (error) => getEmptyStatusServicioQentry())  
);
