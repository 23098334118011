/**
 * Métodos para el manejo de IonicStorage 
 * @setStorage              guarda un unico objeto al storage
 * @getStorage              obtiene el objeto guardado con @setStorage
 * @setStorageArray         guarda el array de objetos en el storage
 * @getStorageArray         obtiene el array de objetos guardado con @setStorageArray
 * @removeStorageArray      elimina el array de objetos guardado con @setStorageArray
 * @setStorageItemArray     agrega un elemento al array de objeto guardado con @setStorageArray
 * @getStorageItemArray     obtiene un elemento del array guardado con @setStorageArray buscandolo por su id 
 * @removeStorageItemArray  elimina un elemento del array guardado con @setStorageArray buscandolo por su id 
 */

import { setStorageData, getStorageData, removeStorageData, setArrayItemStorageData, getArrayItemStorageData, removeArrayItemStorageData } from "../../api/storage";
import { IMvMotivoCancelacion, getEmptyMvMotivoCancelacion } from "../../interfaces/rondines";

export const setStorageMvMotivoCancelacion = async (IMvMotivoCancelacion : IMvMotivoCancelacion) => await setStorageData('IMvMotivoCancelacion', IMvMotivoCancelacion);
export const getStorageMvMotivoCancelacion = async () : Promise<IMvMotivoCancelacion> => await getStorageData('IMvMotivoCancelacion', getEmptyMvMotivoCancelacion());
export const removeStorageMvMotivoCancelacion = async () => await removeStorageData('IMvMotivoCancelacion');
export const setStorageMvMotivoCancelacionArray = async (IMvMotivoCancelacion : IMvMotivoCancelacion[]) => await setStorageData('IMvMotivoCancelacionArray', IMvMotivoCancelacion);
export const getStorageMvMotivoCancelacionArray = async () : Promise<IMvMotivoCancelacion[]> => await getStorageData('IMvMotivoCancelacionArray', [] as IMvMotivoCancelacion[]);
export const removeStorageMvMotivoCancelacionArray = async () => await removeStorageData('IMvMotivoCancelacionArray');
export const setStorageMvMotivoCancelacionArrayItem = async (id: number, IMvMotivoCancelacion : IMvMotivoCancelacion) : Promise<IMvMotivoCancelacion[]> => await setArrayItemStorageData('IMvMotivoCancelacionArray', id, IMvMotivoCancelacion, [] as IMvMotivoCancelacion[]);
export const getStorageMvMotivoCancelacionArrayItem = async (id: number) : Promise<IMvMotivoCancelacion> => await getArrayItemStorageData('IMvMotivoCancelacionArray', id, getEmptyMvMotivoCancelacion);
export const removeStorageMvMotivoCancelacionArrayItem = async (id: number) : Promise<IMvMotivoCancelacion[]> => await removeArrayItemStorageData('IMvMotivoCancelacionArray', id, [] as IMvMotivoCancelacion[]);
