import { getArrayItemStorageData, getStorageData, removeArrayItemStorageData, removeStorageData, setArrayItemStorageData, setStorageData } from "../../api/storage";
import { getEmptyGeolocalizacion, IGeolocalizacion } from "../../interfaces/personas";

export const setStorageGeolocalizacion = async (IGeolocalizacion : IGeolocalizacion) => await setStorageData('IGeolocalizacion', IGeolocalizacion);
export const getStorageGeolocalizacion = async () : Promise<IGeolocalizacion> => await getStorageData('IGeolocalizacion', getEmptyGeolocalizacion());
export const removeStorageGeolocalizacion = async () => await removeStorageData('IGeolocalizacion');
export const setStorageGeolocalizacionArray = async (IGeolocalizacion : IGeolocalizacion[]) => await setStorageData('IGeolocalizacionArray', IGeolocalizacion);
export const getStorageGeolocalizacionArray = async () : Promise<IGeolocalizacion[]> => await getStorageData('IGeolocalizacionArray', [] as IGeolocalizacion[]);
export const removeStorageGeolocalizacionArray = async () => await removeStorageData('IGeolocalizacionArray');
export const setStorageGeolocalizacionArrayItem = async (id: number, IGeolocalizacion : IGeolocalizacion) : Promise<IGeolocalizacion[]> => await setArrayItemStorageData('IGeolocalizacionArray', id, IGeolocalizacion, [] as IGeolocalizacion[]);
export const getStorageGeolocalizacionArrayItem = async (id: number) : Promise<IGeolocalizacion> => await getArrayItemStorageData('IGeolocalizacionArray', id, getEmptyGeolocalizacion);
export const removeStorageGeolocalizacionArrayItem = async (id: number) : Promise<IGeolocalizacion[]> => await removeArrayItemStorageData('IGeolocalizacionArray', id, [] as IGeolocalizacion[]);
