export interface List {
  id: string;
  title: string;
}

export const setListItem = ({id, title}: List) => {
  const temp : List = {
    id : id,
    title : title,
  }
  return temp;
}