/**
 * Métodos AsyncThunk para ser usados en los reducers y
 * los resultados a las llamadas http puedan ser usados para
 * actualizar el state en redux
 */

import { createAsyncThunk } from "@reduxjs/toolkit";
import { DEFAULT_STATE } from "../../enums/default_state";
import { getMvMotivoIncapacidad, getAllMvMotivoIncapacidad, updateMvMotivoIncapacidad, createMvMotivoIncapacidad, deleteMvMotivoIncapacidad } from "../../http/nomina";
import { getEmptyStatusMvMotivoIncapacidad, IMvMotivoIncapacidad } from "../../interfaces/nomina";
import { IServerDate } from "../../interfaces/time";

export const getMvMotivoIncapacidadAsyncThunk = createAsyncThunk(
  'nomina/getMvMotivoIncapacidadAsyncThunk',
  async (query : {serverDate : IServerDate, id: number}) => await getMvMotivoIncapacidad(query.serverDate, query.id)
    .then(async (result) => result)
    .catch(async (error) => getEmptyStatusMvMotivoIncapacidad(DEFAULT_STATE.error))  
);

export const getAllMvMotivoIncapacidadAsyncThunk = createAsyncThunk(
  'nomina/getAllMvMotivoIncapacidadAsyncThunk',
  async (queryAll : {serverDate : IServerDate}) => await getAllMvMotivoIncapacidad(queryAll.serverDate)
    .then(async (result) => result)
    .catch(async (error) => getEmptyStatusMvMotivoIncapacidad(DEFAULT_STATE.error))  
);

export const updateMvMotivoIncapacidadAsyncThunk = createAsyncThunk(
  'nomina/updateMvMotivoIncapacidadAsyncThunk',
  async (update: {serverDate : IServerDate, id: number, MvMotivoIncapacidad: IMvMotivoIncapacidad}) => await updateMvMotivoIncapacidad(update.serverDate, update.id, update.MvMotivoIncapacidad)
    .then(async (result) => result)
    .catch(async (error) => getEmptyStatusMvMotivoIncapacidad(DEFAULT_STATE.error))  
);

export const createMvMotivoIncapacidadAsyncThunk = createAsyncThunk(
  'nomina/createMvMotivoIncapacidadAsyncThunk',
  async (create : {serverDate : IServerDate, MvMotivoIncapacidad: IMvMotivoIncapacidad}) => await createMvMotivoIncapacidad(create.serverDate, create.MvMotivoIncapacidad)
    .then(async (result) => result)
    .catch(async (error) => getEmptyStatusMvMotivoIncapacidad(DEFAULT_STATE.error))  
);

export const deleteMvMotivoIncapacidadAsyncThunk = createAsyncThunk(
  'nomina/deleteMvMotivoIncapacidadAsyncThunk',
  async (del : {id : number}) => await deleteMvMotivoIncapacidad(del.id)
    .then(async (result) => result)
    .catch(async (error) => getEmptyStatusMvMotivoIncapacidad())  
);
