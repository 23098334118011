/** 
 * Componente que se encarga del elemento MvTipoSuscripcion
 * @IMvTipoSuscripcion es la interfaz que contiene los parámetos el elemento MvTipoSuscripcion
 * @getEmptyMvTipoSuscripcion  devuelve un objeto con los parámetros por defecto; @param {id}, opcional cuando no encuentra el objeto en el LocalSotrage
 * @IStatusMvTipoSuscripcion es la interfaz para los estatus a llamadas a la API; @param {status}, opcional cuando queremos otro estado de inizialización de tipo [DEFAULT_STATE]
 * @getEmptyStatusMvTipoSuscripcion devuelve un objeto con los parámetros por defecto,
 * @setMvTipoSuscripcion devuelve un objeto de tipo [IMvTipoSuscripcion] recibiendo @param {object} y @param {serverDate}, para verificar que los datos estén en el formato correcto y ajustarlos a la zona horaria actual
 * @getSerializableMvTipoSuscripcion recibe un @param {IMvTipoSuscripcion}, para devolver un objeto serizalizable para las peticiones de la API
 * */

import { getDateString, isNotEmpty, SERVER_TIME_ZONE } from "../../api/helpers";
import { DEFAULT_STATE } from "../../enums/default_state";
import { getStorageMvImpuestosArrayItem, getStoragePaisArrayItem } from "../../localStorage/personas";
import { convertDateToZonaHoraria, ILocalStorageExpiry, getEmptyLocalStorageExpiry, IServerDate } from "../time";
import { IMvImpuestos, setMvImpuestos } from "./IMvImpuestos";
import { IPais, getEmptyPais, setPais } from "./IPais";

export interface IMvTipoSuscripcion {
  id : number,
  dato : string,
  costo : number, // Decimal
  impuestos : IMvImpuestos[],
  fecha_elaboracion : string, // Fecha
  estatus_sistema : boolean,
  ultima_actualizacion : string, // Fecha
  pais : IPais,
}

export const getEmptyMvTipoSuscripcion = (id? : number) => {
  const temp : IMvTipoSuscripcion = 
  {
    id : id ? id : 0,
    dato : "",
    costo : 0,
    impuestos : [],
    fecha_elaboracion : "",
    estatus_sistema : false,
    ultima_actualizacion : "",
    pais : getEmptyPais(),
  };
  return temp;
}

export interface IStatusMvTipoSuscripcion {
  estado : DEFAULT_STATE,
  mensaje : string,
  data : IMvTipoSuscripcion,
  data_array: IMvTipoSuscripcion[],
  expiry : ILocalStorageExpiry,
  expiry_array : ILocalStorageExpiry,
}

export const getEmptyStatusMvTipoSuscripcion = (status: DEFAULT_STATE = DEFAULT_STATE.loading) => {
  const temp : IStatusMvTipoSuscripcion = 
  {
    estado : status,
    mensaje : "",
    data : getEmptyMvTipoSuscripcion(),
    data_array : [],
    expiry : getEmptyLocalStorageExpiry("IMvTipoSuscripcion", 0),
    expiry_array : getEmptyLocalStorageExpiry("IMvTipoSuscripcionArray", 0),
  };
  return temp;
}

export const setMvTipoSuscripcion = async (
  object : any,
  serverDate : IServerDate,
) => {
  const temp = getEmptyMvTipoSuscripcion();
  if (isNotEmpty(object.id)) temp.id = object.id;
  if (isNotEmpty(object.dato)) temp.dato = object.dato;
  if (isNotEmpty(object.costo)) temp.costo = Number(object.costo);
  if (isNotEmpty(object.impuestos)) temp.impuestos = (typeof object.impuestos[0] === 'number') ? await Promise.all(object.impuestos.map(async (e: number) => await getStorageMvImpuestosArrayItem(e))) : await Promise.all(object.impuestos.map(async (e : any) => await setMvImpuestos(e, serverDate)));
  if (isNotEmpty(object.fecha_elaboracion)) temp.fecha_elaboracion = convertDateToZonaHoraria(serverDate, object.fecha_elaboracion);
  if (isNotEmpty(object.estatus_sistema)) temp.estatus_sistema = object.estatus_sistema;
  if (isNotEmpty(object.ultima_actualizacion)) temp.ultima_actualizacion = convertDateToZonaHoraria(serverDate, object.ultima_actualizacion);
  if (isNotEmpty(object.pais)) temp.pais = (typeof object.pais === 'number') ? await getStoragePaisArrayItem(object.pais) : await setPais(object.pais, serverDate);
  return temp;
}

export const getSerializableMvTipoSuscripcion = (IMvTipoSuscripcion : IMvTipoSuscripcion) => {
  return {
    id : IMvTipoSuscripcion.id,
    dato : IMvTipoSuscripcion.dato,
    costo : IMvTipoSuscripcion.costo.toFixed(2),
    impuestos : IMvTipoSuscripcion.impuestos.map((e) => e.id),
    fecha_elaboracion : IMvTipoSuscripcion.fecha_elaboracion,
    estatus_sistema : IMvTipoSuscripcion.estatus_sistema,
    ultima_actualizacion : getDateString(SERVER_TIME_ZONE),
    pais : IMvTipoSuscripcion.pais.id > 0 ? IMvTipoSuscripcion.pais.id : null,
  };
}
