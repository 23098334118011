/**
 * Reducers creados para obtener asíncronamente 
 * los datos de los modelos y dependiendo del resultado
 * de la peticón actualice el state en redux o mande un mensaje de error
 */

import { PayloadAction } from "@reduxjs/toolkit";
import { getSuscripcionQentryAsyncThunk, getAllSuscripcionQentryAsyncThunk, updateSuscripcionQentryAsyncThunk, createSuscripcionQentryAsyncThunk, deleteSuscripcionQentryAsyncThunk } from "../../asyncThunk/personas";
import { DEFAULT_STATE } from "../../enums/default_state";
import { IStatusSuscripcionQentry } from "../../interfaces/personas";
import { personasInitialState } from "../../slices/personasSlice";

// Reducers: SuscripcionQentry
export const createSuscripcionQentryReducers = () => {
  const get = () => {
    var { pending, fulfilled, rejected } = getSuscripcionQentryAsyncThunk;
    return {
      [pending.type]: (state : typeof personasInitialState) => {
        state.personasState.suscripcionQentry.estado = DEFAULT_STATE.loading;
      },
      [fulfilled.type]: (state : typeof personasInitialState, action : PayloadAction<IStatusSuscripcionQentry>) => {
        const result = action.payload;
        result.expiry_array = state.personasState.suscripcionQentry.expiry_array;
        if (result.estado === DEFAULT_STATE.error) result.data_array = state.personasState.suscripcionQentry.data_array;
        state.personasState.suscripcionQentry = result;
      },
      [rejected.type]: (state : typeof personasInitialState, action : PayloadAction<IStatusSuscripcionQentry>) => {
        state.personasState.suscripcionQentry = {
          ...state.personasState.suscripcionQentry,
          estado: action.payload.estado,
          mensaje: "Ocurrió un problema al realizar la solicitud",
        };
      },
    };
  };
  
  const getAll = () => {
    var { pending, fulfilled, rejected } = getAllSuscripcionQentryAsyncThunk;
    return {
      [pending.type]: (state : typeof personasInitialState) => {
        state.personasState.suscripcionQentry.estado = DEFAULT_STATE.loading;
      },
      [fulfilled.type]: (state : typeof personasInitialState, action : PayloadAction<IStatusSuscripcionQentry>) => {
        const result = action.payload;
        result.data = state.personasState.suscripcionQentry.data;
        result.expiry = state.personasState.suscripcionQentry.expiry;
        if (result.estado === DEFAULT_STATE.error) result.data_array = state.personasState.suscripcionQentry.data_array;
        state.personasState.suscripcionQentry = result;
      },
      [rejected.type]: (state : typeof personasInitialState, action : PayloadAction<IStatusSuscripcionQentry>) => {
        state.personasState.suscripcionQentry = {
          ...state.personasState.suscripcionQentry,
          estado: action.payload.estado,
          mensaje: "Ocurrió un problema al realizar la solicitud",
        };
      },
    };
  };
  
  const update = () => {
    var { pending, fulfilled, rejected } = updateSuscripcionQentryAsyncThunk;
    return {
      [pending.type]: (state : typeof personasInitialState) => {
        state.personasState.suscripcionQentry.estado = DEFAULT_STATE.loading;
      },
      [fulfilled.type]: (state : typeof personasInitialState, action : PayloadAction<IStatusSuscripcionQentry>) => {
        const result: IStatusSuscripcionQentry = action.payload;
        result.expiry = state.personasState.suscripcionQentry.expiry;
        result.expiry_array = state.personasState.suscripcionQentry.expiry_array;
        if (result.estado === DEFAULT_STATE.error) result.data_array = state.personasState.suscripcionQentry.data_array;
        state.personasState.suscripcionQentry = result;
      },
      [rejected.type]: (state : typeof personasInitialState, action : PayloadAction<IStatusSuscripcionQentry>) => {
        state.personasState.suscripcionQentry = {
          ...state.personasState.suscripcionQentry,
          estado: action.payload.estado,
          mensaje: "Ocurrió un problema al realizar la solicitud",
        };
      },
    };
  };

  const create = () => {
    var { pending, fulfilled, rejected } = createSuscripcionQentryAsyncThunk;
    return {
      [pending.type]: (state : typeof personasInitialState) => {
        state.personasState.suscripcionQentry.estado = DEFAULT_STATE.loading;
      },
      [fulfilled.type]: (state : typeof personasInitialState, action : PayloadAction<IStatusSuscripcionQentry>) => {
        const result = action.payload;
        result.expiry = state.personasState.suscripcionQentry.expiry;
        result.expiry_array = state.personasState.suscripcionQentry.expiry_array;
        if (result.estado === DEFAULT_STATE.error) result.data_array = state.personasState.suscripcionQentry.data_array;
        state.personasState.suscripcionQentry = result;
      },
      [rejected.type]: (state : typeof personasInitialState, action : PayloadAction<IStatusSuscripcionQentry>) => {
        state.personasState.suscripcionQentry = {
          ...state.personasState.suscripcionQentry,
          estado: action.payload.estado,
          mensaje: "Ocurrió un problema al realizar la solicitud",
        };
      },
    };
  };
  
  const del = () => {
    var { pending, fulfilled, rejected } = deleteSuscripcionQentryAsyncThunk;
    return {
      [pending.type]: (state : typeof personasInitialState) => {
        state.personasState.suscripcionQentry.estado = DEFAULT_STATE.loading;
      },
      [fulfilled.type]: (state : typeof personasInitialState, action : PayloadAction<IStatusSuscripcionQentry>) => {
        const result: IStatusSuscripcionQentry = action.payload;
        result.expiry = state.personasState.suscripcionQentry.expiry;
        result.expiry_array = state.personasState.suscripcionQentry.expiry_array;
        if (result.estado === DEFAULT_STATE.error) result.data_array = state.personasState.suscripcionQentry.data_array;
        state.personasState.suscripcionQentry = result;
      },
      [rejected.type]: (state : typeof personasInitialState, action : PayloadAction<IStatusSuscripcionQentry>) => {
        state.personasState.suscripcionQentry = {
          ...state.personasState.suscripcionQentry,
          estado: action.payload.estado,
          mensaje: "Ocurrió un problema al realizar la solicitud",
        };
      },
    };
  };

  return {
    ...get(),
    ...getAll(),
    ...update(),
    ...create(),
    ...del(),
  };
};
