/**
 * Métodos AsyncThunk para ser usados en los reducers y
 * los resultados a las llamadas http puedan ser usados para
 * actualizar el state en redux
 */

import { createAsyncThunk } from "@reduxjs/toolkit";
import { DEFAULT_STATE } from "../../enums/default_state";
import { getMvTipoEmpleado, getAllMvTipoEmpleado, updateMvTipoEmpleado, createMvTipoEmpleado, deleteMvTipoEmpleado } from "../../http/personas";
import { getEmptyStatusMvTipoEmpleado, IMvTipoEmpleado } from "../../interfaces/personas";
import { IServerDate } from "../../interfaces/time";

export const getMvTipoEmpleadoAsyncThunk = createAsyncThunk(
  'personas/getMvTipoEmpleadoAsyncThunk',
  async (query : {serverDate : IServerDate, id: number}) => await getMvTipoEmpleado(query.serverDate, query.id)
    .then(async (result) => result)
    .catch(async (error) => getEmptyStatusMvTipoEmpleado(DEFAULT_STATE.error))  
);

export const getAllMvTipoEmpleadoAsyncThunk = createAsyncThunk(
  'personas/getAllMvTipoEmpleadoAsyncThunk',
  async (queryAll : {serverDate : IServerDate}) => await getAllMvTipoEmpleado(queryAll.serverDate)
    .then(async (result) => result)
    .catch(async (error) => getEmptyStatusMvTipoEmpleado(DEFAULT_STATE.error))  
);

export const updateMvTipoEmpleadoAsyncThunk = createAsyncThunk(
  'personas/updateMvTipoEmpleadoAsyncThunk',
  async (update: {serverDate : IServerDate, id: number, MvTipoEmpleado: IMvTipoEmpleado}) => await updateMvTipoEmpleado(update.serverDate, update.id, update.MvTipoEmpleado)
    .then(async (result) => result)
    .catch(async (error) => getEmptyStatusMvTipoEmpleado(DEFAULT_STATE.error))  
);

export const createMvTipoEmpleadoAsyncThunk = createAsyncThunk(
  'personas/createMvTipoEmpleadoAsyncThunk',
  async (create : {serverDate : IServerDate, MvTipoEmpleado: IMvTipoEmpleado}) => await createMvTipoEmpleado(create.serverDate, create.MvTipoEmpleado)
    .then(async (result) => result)
    .catch(async (error) => getEmptyStatusMvTipoEmpleado(DEFAULT_STATE.error))  
);

export const deleteMvTipoEmpleadoAsyncThunk = createAsyncThunk(
  'personas/deleteMvTipoEmpleadoAsyncThunk',
  async (del : {id : number}) => await deleteMvTipoEmpleado(del.id)
    .then(async (result) => result)
    .catch(async (error) => getEmptyStatusMvTipoEmpleado())  
);

