/**
 * Es el componente de la página Incapacidades para crear y visualizar solicitudes de incapacidades de los trabajadores
 * @function uploadIncapacidades se encarge de crear la solicitud de incapacidad del trabajador seleccionado
 */

import React, { useState, useEffect } from "react";
import {
  IonGrid,
  IonRow,
  IonCol,
  IonDatetime,
  IonLabel,
  IonButton,
  IonItem,
  IonTextarea,
  IonContent,
  useIonToast,
  IonList,
  IonSpinner,
  IonPopover,
  IonInput,
} from "@ionic/react";

import { bandage } from "ionicons/icons";

import Card from "../components/Card";
import Selector from "../components/Selector";

import useRedux, { useAppSelector } from "../hooks/useRedux";
import { IIncapacidad } from "../redux/interfaces/nomina";
import {
  getMemoizedDisabilitiesActiveEmpleadoAsSelectList,
  getMemoizedDisabilitiesByEmpresa,
  setDisabilitiesSelectedEmpleado,
  setDisabilitiesSelectedEmpresa,
  setDisabilitiesSelectedMvEstatusIncapacidades,
} from "../redux/slices/pagesSlice/disabilitiesSlice";
import {
  getGlobalMemoizedEmpresaAsSelectList,
  getNombreEmpleado,
} from "../redux/slices/personasSlice";
import { getGlobalMemoizedMvEstatusIncapacidadesAsSelectList } from "../redux/slices/nominaSlice";
import { getOnlyDateString } from "../redux/api/helpers";
import { DEFAULT_STATE } from "../redux/enums/default_state";

const Disabilities: React.FC = () => {
  const [{ empleado, incapacidad, createIncapacidades }] = useRedux();

  const empresaList = useAppSelector(getGlobalMemoizedEmpresaAsSelectList);
  const selectedEmpresa = useAppSelector(
    (state) => state.disabilities.selectedEmpresa
  );

  const empleadoList = useAppSelector(
    getMemoizedDisabilitiesActiveEmpleadoAsSelectList
  );
  const selectedEmpleado = useAppSelector(
    (state) => state.disabilities.selectedEmpleado
  );

  const disabilitiesList = useAppSelector(getMemoizedDisabilitiesByEmpresa);

  const mvEstatusIncapacidadesList = useAppSelector(
    getGlobalMemoizedMvEstatusIncapacidadesAsSelectList
  );
  const selectedMvEstatusIncapacidades = useAppSelector(
    (state) => state.disabilities.selectedMvEstatusIncapacidades
  );

  const [present, dismiss] = useIonToast();
  let [description, setDescription] = useState<string>("");
  let [selectedIniDate, setSelectedIniDate] = useState<string>(
    getOnlyDateString()
  );
  let [selectedFinDate, setSelectedFinDate] = useState<string>(
    getOnlyDateString()
  );

  const uploadIncapacidades = async () => {
    if (!selectedEmpleado.id) {
      present({
        message: "Por favor seleccione un empleado",
        color: "warning",
        duration: 1500,
      });
      return;
    }
    if (!description || !description.trim()) {
      present({
        message: "Por favor escriba la razón de la incapacidad",
        color: "warning",
        duration: 1500,
      });
      return;
    }
    const temp = empleado.data_array.find(
      (e) => e.id.toString() === selectedEmpleado.id
    );

    if (!temp) {
      present({
        message: "Hubo un problema al obtener al trabajador",
        color: "warning",
        duration: 1500,
      });
      return;
    }

    createIncapacidades(temp, description, selectedIniDate, selectedFinDate);
  };

  return (
    <IonContent class="inner-content">
      <IonGrid>
        <IonRow>
          <IonCol size="12">
            <Card
              icon={bandage}
              title={<IonLabel>Subir incapacidad</IonLabel>}
              subtitle={
                <Selector
                  value={selectedEmpresa}
                  label="Cuentas/Regiones"
                  list={empresaList}
                  changeState={setDisabilitiesSelectedEmpresa}
                />
              }
              //content="prueba"
              content={
                <IonGrid>
                  <IonRow>
                    <IonCol size="12">
                      <Selector
                        value={selectedEmpleado}
                        label="Empleado"
                        list={empleadoList}
                        changeState={setDisabilitiesSelectedEmpleado}
                      />
                    </IonCol>
                    <IonCol size="12" sizeMd="6" sizeLg="6">
                      <IonItem className="modal-input">
                        <IonLabel position="stacked">Fecha de inicial</IonLabel>
                        <IonInput
                          id="vacation-ini-date"
                          type="text"
                          color="secondary"
                          value={selectedIniDate}
                          className="custom-picker"
                        />

                        <IonPopover
                          className="custom-picker"
                          id="vacation-ini-picker"
                          animated={false}
                          backdropDismiss={true}
                          trigger="vacation-ini-date"
                          triggerAction="click"
                          show-backdrop="true"
                          class="date-picker"
                        >
                          <IonDatetime
                            className="custom-picker"
                            id="vacation-ini-datetime"
                            color="primary"
                            presentation="date"
                            placeholder="Seleccionar fecha"
                            min="2017-01-01"
                            value={selectedIniDate}
                            onIonChange={async (e) => {
                              setSelectedIniDate(
                                getOnlyDateString(e.detail.value as string)
                              );
                            }}
                          ></IonDatetime>
                        </IonPopover>
                      </IonItem>
                    </IonCol>
                    <IonCol size="12" sizeMd="6" sizeLg="6">
                      <IonItem className="modal-input">
                        <IonLabel position="stacked">Fecha de final</IonLabel>
                        <IonInput
                          id="incapacidades-fin-date"
                          type="text"
                          color="secondary"
                          value={selectedFinDate}
                          className="custom-picker"
                        />

                        <IonPopover
                          className="custom-picker"
                          id="incapacidades-fin-picker"
                          animated={false}
                          backdropDismiss={true}
                          trigger="incapacidades-fin-date"
                          triggerAction="click"
                          show-backdrop="true"
                          class="date-picker"
                        >
                          <IonDatetime
                            className="custom-picker"
                            id="incapacidades-fin-datetime"
                            color="primary"
                            presentation="date"
                            placeholder="Seleccionar fecha"
                            min="2017-01-01"
                            value={selectedFinDate}
                            onIonChange={async (e) => {
                              setSelectedFinDate(
                                getOnlyDateString(e.detail.value as string)
                              );
                            }}
                          ></IonDatetime>
                        </IonPopover>
                      </IonItem>
                    </IonCol>
                  </IonRow>
                  <IonRow>
                    <IonCol>
                      <IonItem>
                        <IonLabel position="floating">
                          Describa la razón de la incapacidad
                        </IonLabel>
                        <IonTextarea
                          autoGrow={true}
                          value={description}
                          onIonChange={(e) => setDescription(e.detail.value!)}
                        ></IonTextarea>
                      </IonItem>
                    </IonCol>
                  </IonRow>
                  <IonRow>
                    <IonCol>
                      <IonButton
                        disabled={incapacidad.estado === DEFAULT_STATE.loading}
                        onClick={() => uploadIncapacidades()}
                        expand="block"
                      >
                        {incapacidad.estado === DEFAULT_STATE.loading ? (
                          <IonSpinner
                            className="loading-center-horizontal"
                            name="circles"
                          />
                        ) : (
                          <IonLabel>Subir</IonLabel>
                        )}
                      </IonButton>
                    </IonCol>
                  </IonRow>
                </IonGrid>
              }
            />
          </IonCol>
          <IonCol size="12">
            <Card
              icon={bandage}
              title={<IonLabel>Lista de incapacidad</IonLabel>}
              content={
                <IonGrid>
                  <IonRow>
                    <IonCol size="12">
                      <IonItem color="secondary" lines="full">
                        <IonLabel>
                          {/* <h3>Registro de entradas y salidas</h3> */}
                          <div>
                            <Selector
                              classStyle="secondary"
                              color="secondary"
                              value={selectedMvEstatusIncapacidades}
                              label="Estatus"
                              list={mvEstatusIncapacidadesList}
                              changeState={
                                setDisabilitiesSelectedMvEstatusIncapacidades
                              }
                            />
                          </div>
                        </IonLabel>
                      </IonItem>
                      <IonList className="selection-area">
                        {disabilitiesList.map((e) => {
                          return (
                            <IonItem key={`${e.id}-${e.fecha_elaboracion}`}>
                              <IonLabel>
                                <h3>
                                  <b>Empleado</b>:{" "}
                                  {getNombreEmpleado(e.empleado)}
                                </h3>
                                <h3>
                                  <b>Descripción</b>:{" "}
                                  {e.motivo_incapacidad.dato}
                                </h3>
                                <h3>
                                  <b>Inicio</b>: {e.fecha_inicio_incapacidad}
                                </h3>
                                <h3>
                                  <b>Fin</b>: {e.fecha_fin_incapacidad}
                                </h3>
                                <h3>
                                  <b>Fecha elaboración</b>:{" "}
                                  {e.fecha_elaboracion}
                                </h3>
                                <h3>
                                  <b>Estado</b>: {e.estatus_incapacidad.dato}
                                </h3>
                                {e.comentarios_aprobador ? (
                                  <h3>
                                    <b>Comentarios</b>:{" "}
                                    {e.comentarios_aprobador}
                                  </h3>
                                ) : (
                                  <></>
                                )}
                              </IonLabel>
                            </IonItem>
                          );
                        })}
                        {incapacidad.estado === DEFAULT_STATE.loading ? (
                          <IonSpinner
                            className="loading-center-horizontal"
                            name="circles"
                            color="medium"
                          />
                        ) : disabilitiesList.length === 0 ? (
                          <IonItem lines="full">
                            <IonLabel>No hay datos</IonLabel>
                          </IonItem>
                        ) : (
                          <></>
                        )}
                      </IonList>
                    </IonCol>
                  </IonRow>
                </IonGrid>
              }
            />
          </IonCol>
        </IonRow>
      </IonGrid>
    </IonContent>
  );
};

export default Disabilities;
