/** 
 * Componente que se encarga del elemento CodigoQr
 * @ICodigoQr es la interfaz que contiene los parámetos el elemento CodigoQr
 * @getEmptyCodigoQr  devuelve un objeto con los parámetros por defecto; @param {id}, opcional cuando no encuentra el objeto en el LocalSotrage
 * @IStatusCodigoQr es la interfaz para los estatus a llamadas a la API; @param {status}, opcional cuando queremos otro estado de inizialización de tipo [DEFAULT_STATE]
 * @getEmptyStatusCodigoQr devuelve un objeto con los parámetros por defecto,
 * @setCodigoQr devuelve un objeto de tipo [ICodigoQr] recibiendo @param {object} y @param {serverDate}, para verificar que los datos estén en el formato correcto y ajustarlos a la zona horaria actual
 * @getSerializableCodigoQr recibe un @param {ICodigoQr}, para devolver un objeto serizalizable para las peticiones de la API
 * */

import { getDateString, isNotEmpty, SERVER_TIME_ZONE } from "../../api/helpers";
import { DEFAULT_STATE } from "../../enums/default_state";
import { getStorageGeolocalizacionArrayItem, getStorageCentroDeTrabajoArrayItem, getStorageUserArrayItem, getStorageClienteQentryArrayItem } from "../../localStorage/personas";
import { convertDateToZonaHoraria, ILocalStorageExpiry, getEmptyLocalStorageExpiry, IServerDate } from "../time";
import { ICentroDeTrabajo, getEmptyCentroDeTrabajo, setCentroDeTrabajo } from "./ICentroDeTrabajo";
import { getEmptyClienteQentry, IClienteQentry, setClienteQentry } from "./IClienteQentry";
import { IGeolocalizacion, getEmptyGeolocalizacion, setGeolocalizacion } from "./IGeolocalizacion";
import { IUser, getEmptyUser, setUser } from "./IUser";

export interface ICodigoQr {
  id : number,
  nombre_codigo : string,
  clave_codigo : string,
  imagen_codigo : string | null,  // Imagen
  geolocalizacion : IGeolocalizacion,
  centro_de_trabajo : ICentroDeTrabajo,
  autor : IUser,
  fecha_elaboracion : string, // Fecha
  estatus_sistema : boolean,
  ultima_actualizacion : string, // Fecha
  cliente_qentry : IClienteQentry,
}

export const getEmptyCodigoQr = (id? : number) => {
  const temp : ICodigoQr = 
  {
    id : id ? id : 0,
    nombre_codigo : "",
    clave_codigo : "",
    imagen_codigo : "",
    geolocalizacion : getEmptyGeolocalizacion(),
    centro_de_trabajo : getEmptyCentroDeTrabajo(),
    autor : getEmptyUser(),
    fecha_elaboracion : "",
    estatus_sistema : false,
    ultima_actualizacion : "",
    cliente_qentry : getEmptyClienteQentry(),
  };
  return temp;
}

export interface IStatusCodigoQr {
  estado : DEFAULT_STATE,
  mensaje : string,
  data : ICodigoQr,
  data_array: ICodigoQr[],
  expiry : ILocalStorageExpiry,
  expiry_array : ILocalStorageExpiry,
}

export const getEmptyStatusCodigoQr = (status: DEFAULT_STATE = DEFAULT_STATE.loading) => {
  const temp : IStatusCodigoQr = 
  {
    estado : status,
    mensaje : "",
    data : getEmptyCodigoQr(),
    data_array : [],
    expiry : getEmptyLocalStorageExpiry("ICodigoQr", 0),
    expiry_array : getEmptyLocalStorageExpiry("ICodigoQrArray", 0),
  };
  return temp;
}

export const setCodigoQr = async (
  object : any,
  serverDate : IServerDate,
) => {
  const temp = getEmptyCodigoQr();
  if (isNotEmpty(object.id)) temp.id = object.id;
  if (isNotEmpty(object.nombre_codigo)) temp.nombre_codigo = object.nombre_codigo;
  if (isNotEmpty(object.clave_codigo)) temp.clave_codigo = object.clave_codigo;
  if (isNotEmpty(object.imagen_codigo)) temp.imagen_codigo = object.imagen_codigo;
  if (isNotEmpty(object.geolocalizacion)) temp.geolocalizacion = (typeof object.geolocalizacion === 'number') ? await getStorageGeolocalizacionArrayItem(object.geolocalizacion) : await setGeolocalizacion(object.geolocalizacion, serverDate);
  if (isNotEmpty(object.centro_de_trabajo)) temp.centro_de_trabajo = (typeof object.centro_de_trabajo === 'number') ? await getStorageCentroDeTrabajoArrayItem(object.centro_de_trabajo) : await setCentroDeTrabajo(object.centro_de_trabajo, serverDate);
  if (isNotEmpty(object.autor)) temp.autor = (typeof object.autor === 'number') ? await getStorageUserArrayItem(object.autor) : await setUser(object.autor, serverDate);
  if (isNotEmpty(object.fecha_elaboracion)) temp.fecha_elaboracion = convertDateToZonaHoraria(serverDate, object.fecha_elaboracion);
  if (isNotEmpty(object.estatus_sistema)) temp.estatus_sistema = object.estatus_sistema;
  if (isNotEmpty(object.ultima_actualizacion)) temp.ultima_actualizacion = convertDateToZonaHoraria(serverDate, object.ultima_actualizacion);
  if (isNotEmpty(object.cliente_qentry)) temp.cliente_qentry = (typeof object.cliente_qentry === 'number') ? await getStorageClienteQentryArrayItem(object.cliente_qentry) : await setClienteQentry(object.cliente_qentry, serverDate);
  return temp;
}

export const getSerializableCodigoQr = (ICodigoQr : ICodigoQr) => {
  return {
    id : ICodigoQr.id,
    nombre_codigo : ICodigoQr.nombre_codigo,
    clave_codigo : ICodigoQr.clave_codigo,
    imagen_codigo : ICodigoQr.imagen_codigo,
    geolocalizacion : ICodigoQr.geolocalizacion.id,
    centro_de_trabajo : ICodigoQr.centro_de_trabajo.id,
    autor : ICodigoQr.autor.id,
    fecha_elaboracion : ICodigoQr.fecha_elaboracion,
    estatus_sistema : ICodigoQr.estatus_sistema,
    ultima_actualizacion : getDateString(SERVER_TIME_ZONE),
    cliente_qentry : ICodigoQr.cliente_qentry.id,
  };
}
