/**
 * Métodos AsyncThunk para ser usados en los reducers y
 * los resultados a las llamadas http puedan ser usados para
 * actualizar el state en redux
 */

import { createAsyncThunk } from "@reduxjs/toolkit";
import { DEFAULT_STATE } from "../../enums/default_state";
import { getRondinCompletado, getAllRondinCompletado, updateRondinCompletado, createRondinCompletado, deleteRondinCompletado } from "../../http/rondines";
import { getEmptyStatusRondinCompletado, IRondinCompletado } from "../../interfaces/rondines";
import { IServerDate } from "../../interfaces/time";

export const getRondinCompletadoAsyncThunk = createAsyncThunk(
  'rondines/getRondinCompletadoAsyncThunk',
  async (query : {serverDate : IServerDate, id: number}) => await getRondinCompletado(query.serverDate, query.id)
    .then(async (result) => result)
    .catch(async (error) => getEmptyStatusRondinCompletado(DEFAULT_STATE.error))  
);

export const getAllRondinCompletadoAsyncThunk = createAsyncThunk(
  'rondines/getAllRondinCompletadoAsyncThunk',
  async (queryAll : {serverDate : IServerDate, idCliente : number}) => await getAllRondinCompletado(queryAll.serverDate, queryAll.idCliente)
    .then(async (result) => result)
    .catch(async (error) => getEmptyStatusRondinCompletado(DEFAULT_STATE.error))  
);

export const updateRondinCompletadoAsyncThunk = createAsyncThunk(
  'rondines/updateRondinCompletadoAsyncThunk',
  async (update: {serverDate : IServerDate, id: number, RondinCompletado: IRondinCompletado}) => await updateRondinCompletado(update.serverDate, update.id, update.RondinCompletado)
    .then(async (result) => result)
    .catch(async (error) => getEmptyStatusRondinCompletado(DEFAULT_STATE.error))  
);

export const createRondinCompletadoAsyncThunk = createAsyncThunk(
  'rondines/createRondinCompletadoAsyncThunk',
  async (create : {serverDate : IServerDate, idCliente : number, RondinCompletado: IRondinCompletado}) => await createRondinCompletado(create.serverDate, create.idCliente, create.RondinCompletado)
    .then(async (result) => result)
    .catch(async (error) => getEmptyStatusRondinCompletado(DEFAULT_STATE.error))  
);

export const deleteRondinCompletadoAsyncThunk = createAsyncThunk(
  'rondines/deleteRondinCompletadoAsyncThunk',
  async (del : {id : number}) => await deleteRondinCompletado(del.id)
    .then(async (result) => result)
    .catch(async (error) => getEmptyStatusRondinCompletado())  
);
