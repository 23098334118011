/** 
 * Componente que se encarga del elemento Asistencia
 * @IAsistencia es la interfaz que contiene los parámetos el elemento Asistencia
 * @getEmptyAsistencia  devuelve un objeto con los parámetros por defecto; @param {id}, opcional cuando no encuentra el objeto en el LocalSotrage
 * @IStatusAsistencia es la interfaz para los estatus a llamadas a la API; @param {status}, opcional cuando queremos otro estado de inizialización de tipo [DEFAULT_STATE]
 * @getEmptyStatusAsistencia devuelve un objeto con los parámetros por defecto,
 * @setAsistencia devuelve un objeto de tipo [IAsistencia] recibiendo @param {object} y @param {serverDate}, para verificar que los datos estén en el formato correcto y ajustarlos a la zona horaria actual
 * @getSerializableAsistencia recibe un @param {IAsistencia}, para devolver un objeto serizalizable para las peticiones de la API
 * */

import { getDateString, isNotEmpty, SERVER_TIME_ZONE } from "../../api/helpers";
import { DEFAULT_STATE } from "../../enums/default_state";
import { getStorageHorarioArrayItem, getStorageMvTipoAsistenciaArrayItem, getStorageMvTipoMovimientoAsistenciaArrayItem, getStorageMvEstatusAsistenciaArrayItem, getStorageMvTipoJornadaArrayItem } from "../../localStorage/nomina";
import { getStorageEmpleadoArrayItem, getStorageCentroDeTrabajoArrayItem, getStorageCodigoQrArrayItem, getStorageGeolocalizacionArrayItem, getStorageImagenArrayItem, getStorageUserArrayItem, getStorageClienteQentryArrayItem } from "../../localStorage/personas";
import { getEmptyCentroDeTrabajo, getEmptyClienteQentry, getEmptyCodigoQr, getEmptyEmpleado, getEmptyGeolocalizacion, getEmptyImagen, getEmptyUser, ICentroDeTrabajo, IClienteQentry, ICodigoQr, IEmpleado, IGeolocalizacion, IImagen, IUser, setCentroDeTrabajo, setClienteQentry, setCodigoQr, setEmpleado, setGeolocalizacion, setImagen, setUser } from "../personas";
import { ILocalStorageExpiry, getEmptyLocalStorageExpiry, IServerDate, convertDateToZonaHoraria } from "../time";
import { getEmptyHorario, IHorario, setHorario } from "./Horario";
import { getEmptyMvEstatusAsistencia, IMvEstatusAsistencia, setMvEstatusAsistencia } from "./MvEstatusAsistencia";
import { getEmptyMvTipoAsistencia, IMvTipoAsistencia, setMvTipoAsistencia } from "./MvTipoAsistencia";
import { getEmptyMvTipoJornada, IMvTipoJornada, setMvTipoJornada } from "./MvTipoJornada";
import { getEmptyMvTipoMovimientoAsistencia, IMvTipoMovimientoAsistencia, setMvTipoMovimientoAsistencia } from "./MvTipoMovimientoAsistencia";

export interface IAsistencia {
  id : number,
  empleado : IEmpleado,
  horario : IHorario,
  tipo_asistencia : IMvTipoAsistencia,
  tipo_movimiento_asistencia : IMvTipoMovimientoAsistencia,
  estatus_asistencia : IMvEstatusAsistencia,
  centro_de_trabajo : ICentroDeTrabajo,
  qr_utilizado : ICodigoQr,
  geolocalizacion_empleado : IGeolocalizacion,
  momento_registro_asistencia : string, // Fecha
  minutos_diferencia_hora_entrada : number, // Decimal
  distancia_con_centro_de_trabajo : number, // Decimal
  tipo_jornada : IMvTipoJornada,
  asistencia_puntual : boolean,
  asistencia_por_tercero : boolean,
  evidencia_asistencia_por_tercero : IImagen,
  horas_extra : boolean,
  autor : IUser,
  fecha_elaboracion : string, // Fecha
  estatus_sistema : boolean,
  ultima_actualizacion : string, // Fecha
  cliente_qentry : IClienteQentry,
}

export const getEmptyAsistencia = (id? : number) => {
  const temp : IAsistencia = 
  {
    id : id ? id : 0,
    empleado : getEmptyEmpleado(),
    horario : getEmptyHorario(),
    tipo_asistencia : getEmptyMvTipoAsistencia(),
    tipo_movimiento_asistencia : getEmptyMvTipoMovimientoAsistencia(),
    estatus_asistencia : getEmptyMvEstatusAsistencia(),
    centro_de_trabajo : getEmptyCentroDeTrabajo(),
    qr_utilizado : getEmptyCodigoQr(),
    geolocalizacion_empleado : getEmptyGeolocalizacion(),
    momento_registro_asistencia : "", // Fecha
    minutos_diferencia_hora_entrada : 0, // Decimal
    distancia_con_centro_de_trabajo : 0, // Decimal
    tipo_jornada : getEmptyMvTipoJornada(),
    asistencia_puntual : false,
    asistencia_por_tercero : false,
    evidencia_asistencia_por_tercero : getEmptyImagen(),
    horas_extra : false,
    autor : getEmptyUser(),
    fecha_elaboracion : "",
    estatus_sistema : false,
    ultima_actualizacion : "",
    cliente_qentry : getEmptyClienteQentry(),
  };
  return temp;
}

export interface IStatusAsistencia {
  estado : DEFAULT_STATE,
  mensaje : string,
  data : IAsistencia,
  data_array: IAsistencia[],
  expiry : ILocalStorageExpiry,
  expiry_array : ILocalStorageExpiry,
}

export const getEmptyStatusAsistencia = (status: DEFAULT_STATE = DEFAULT_STATE.loading) => {
  const temp : IStatusAsistencia = 
  {
    estado : status,
    mensaje : "",
    data : getEmptyAsistencia(),
    data_array : [],
    expiry : getEmptyLocalStorageExpiry("IAsistencia", 0),
    expiry_array : getEmptyLocalStorageExpiry("IAsistenciaArray", 0),
  };
  return temp;
}

export const setAsistencia = async (
  object : any,
  serverDate : IServerDate,
) => {
  const temp = getEmptyAsistencia();
  if (isNotEmpty(object.id)) temp.id = object.id;
  if (isNotEmpty(object.empleado)) temp.empleado = (typeof object.empleado === 'number') ? await getStorageEmpleadoArrayItem(object.empleado) : await setEmpleado(object.empleado, serverDate);
  if (isNotEmpty(object.horario)) temp.horario = (typeof object.horario === 'number') ? await getStorageHorarioArrayItem(object.horario) : await setHorario(object.horario, serverDate);
  if (isNotEmpty(object.tipo_asistencia)) temp.tipo_asistencia = (typeof object.tipo_asistencia === 'number') ? await getStorageMvTipoAsistenciaArrayItem(object.tipo_asistencia) : await setMvTipoAsistencia(object.tipo_asistencia, serverDate);
  if (isNotEmpty(object.tipo_movimiento_asistencia)) temp.tipo_movimiento_asistencia = (typeof object.tipo_movimiento_asistencia === 'number') ? await getStorageMvTipoMovimientoAsistenciaArrayItem(object.tipo_movimiento_asistencia) : await setMvTipoMovimientoAsistencia(object.tipo_movimiento_asistencia, serverDate);
  if (isNotEmpty(object.estatus_asistencia)) temp.estatus_asistencia = (typeof object.estatus_asistencia === 'number') ? await getStorageMvEstatusAsistenciaArrayItem(object.estatus_asistencia) : await setMvEstatusAsistencia(object.estatus_asistencia, serverDate);
  if (isNotEmpty(object.centro_de_trabajo)) temp.centro_de_trabajo = (typeof object.centro_de_trabajo === 'number') ? await getStorageCentroDeTrabajoArrayItem(object.centro_de_trabajo) : await setCentroDeTrabajo(object.centro_de_trabajo, serverDate);
  if (isNotEmpty(object.qr_utilizado)) temp.qr_utilizado = (typeof object.qr_utilizado === 'number') ? await getStorageCodigoQrArrayItem(object.qr_utilizado) : await setCodigoQr(object.qr_utilizado, serverDate);
  if (isNotEmpty(object.geolocalizacion_empleado)) temp.geolocalizacion_empleado = (typeof object.geolocalizacion_empleado === 'number') ? await getStorageGeolocalizacionArrayItem(object.geolocalizacion_empleado) : await setGeolocalizacion(object.geolocalizacion_empleado, serverDate);
  if (isNotEmpty(object.momento_registro_asistencia)) temp.momento_registro_asistencia = object.momento_registro_asistencia;
  if (isNotEmpty(object.minutos_diferencia_hora_entrada)) temp.minutos_diferencia_hora_entrada = Number(object.minutos_diferencia_hora_entrada);
  if (isNotEmpty(object.distancia_con_centro_de_trabajo)) temp.distancia_con_centro_de_trabajo = Number(object.distancia_con_centro_de_trabajo);
  if (isNotEmpty(object.tipo_jornada)) temp.tipo_jornada = (typeof object.tipo_jornada === 'number') ? await getStorageMvTipoJornadaArrayItem(object.tipo_jornada) : await setMvTipoJornada(object.tipo_jornada, serverDate);
  if (isNotEmpty(object.asistencia_puntual)) temp.asistencia_puntual = object.asistencia_puntual;
  if (isNotEmpty(object.asistencia_por_tercero)) temp.asistencia_por_tercero = object.asistencia_por_tercero;
  if (isNotEmpty(object.evidencia_asistencia_por_tercero)) temp.evidencia_asistencia_por_tercero = (typeof object.evidencia_asistencia_por_tercero === 'number') ? await getStorageImagenArrayItem(object.evidencia_asistencia_por_tercero) : await setImagen(object.evidencia_asistencia_por_tercero, serverDate);
  if (isNotEmpty(object.horas_extra)) temp.horas_extra = object.horas_extra;
  if (isNotEmpty(object.autor)) temp.autor = (typeof object.autor === 'number') ? await getStorageUserArrayItem(object.autor) : await setUser(object.autor, serverDate);
  if (isNotEmpty(object.fecha_elaboracion)) temp.fecha_elaboracion = convertDateToZonaHoraria(serverDate, object.fecha_elaboracion);
  if (isNotEmpty(object.estatus_sistema)) temp.estatus_sistema = object.estatus_sistema;
  if (isNotEmpty(object.ultima_actualizacion)) temp.ultima_actualizacion = convertDateToZonaHoraria(serverDate, object.ultima_actualizacion);
  if (isNotEmpty(object.cliente_qentry)) temp.cliente_qentry = (typeof object.cliente_qentry === 'number') ? await getStorageClienteQentryArrayItem(object.cliente_qentry) : await setClienteQentry(object.cliente_qentry, serverDate);
  return temp;
}

export const getSerializableAsistencia = (IAsistencia : IAsistencia ) => {
  return {
    id : IAsistencia.id,
    empleado : IAsistencia.empleado.id,
    horario : IAsistencia.horario.id,
    tipo_asistencia : IAsistencia.tipo_asistencia.id,
    tipo_movimiento_asistencia : IAsistencia.tipo_movimiento_asistencia.id,
    estatus_asistencia : IAsistencia.estatus_asistencia.id,
    centro_de_trabajo : IAsistencia.centro_de_trabajo.id,
    qr_utilizado : IAsistencia.qr_utilizado.id,
    geolocalizacion_empleado : IAsistencia.geolocalizacion_empleado.id,
    momento_registro_asistencia : IAsistencia.momento_registro_asistencia, // Fecha
    minutos_diferencia_hora_entrada : IAsistencia.minutos_diferencia_hora_entrada.toFixed(2), // Decimal
    distancia_con_centro_de_trabajo : IAsistencia.distancia_con_centro_de_trabajo.toFixed(2), // Decimal
    tipo_jornada : IAsistencia.tipo_jornada.id !== 0 ? IAsistencia.tipo_jornada.id : null ,
    asistencia_puntual : IAsistencia.asistencia_puntual,
    asistencia_por_tercero : IAsistencia.asistencia_por_tercero,
    evidencia_asistencia_por_tercero : IAsistencia.evidencia_asistencia_por_tercero.id !== 0 ? IAsistencia.evidencia_asistencia_por_tercero.id : null,
    horas_extra : IAsistencia.horas_extra,
    autor : IAsistencia.autor.id,
    fecha_elaboracion : IAsistencia.fecha_elaboracion,
    estatus_sistema : IAsistencia.estatus_sistema,
    ultima_actualizacion : getDateString(SERVER_TIME_ZONE),
    cliente_qentry : IAsistencia.cliente_qentry.id,
  };
}
