/**
 * Métodos para el manejo de IonicStorage 
 * @setStorage              guarda un unico objeto al storage
 * @getStorage              obtiene el objeto guardado con @setStorage
 * @setStorageArray         guarda el array de objetos en el storage
 * @getStorageArray         obtiene el array de objetos guardado con @setStorageArray
 * @removeStorageArray      elimina el array de objetos guardado con @setStorageArray
 * @setStorageItemArray     agrega un elemento al array de objeto guardado con @setStorageArray
 * @getStorageItemArray     obtiene un elemento del array guardado con @setStorageArray buscandolo por su id 
 * @removeStorageItemArray  elimina un elemento del array guardado con @setStorageArray buscandolo por su id 
 */

import { setStorageData, getStorageData, removeStorageData, setArrayItemStorageData, getArrayItemStorageData, removeArrayItemStorageData } from "../../api/storage";
import { IRondinCancelado, getEmptyRondinCancelado } from "../../interfaces/rondines";

export const setStorageRondinCancelado = async (IRondinCancelado : IRondinCancelado) => await setStorageData('IRondinCancelado', IRondinCancelado);
export const getStorageRondinCancelado = async () : Promise<IRondinCancelado> => await getStorageData('IRondinCancelado', getEmptyRondinCancelado());
export const removeStorageRondinCancelado = async () => await removeStorageData('IRondinCancelado');
export const setStorageRondinCanceladoArray = async (IRondinCancelado : IRondinCancelado[]) => await setStorageData('IRondinCanceladoArray', IRondinCancelado);
export const getStorageRondinCanceladoArray = async () : Promise<IRondinCancelado[]> => await getStorageData('IRondinCanceladoArray', [] as IRondinCancelado[]);
export const removeStorageRondinCanceladoArray = async () => await removeStorageData('IRondinCanceladoArray');
export const setStorageRondinCanceladoArrayItem = async (id: number, IRondinCancelado : IRondinCancelado) : Promise<IRondinCancelado[]> => await setArrayItemStorageData('IRondinCanceladoArray', id, IRondinCancelado, [] as IRondinCancelado[]);
export const getStorageRondinCanceladoArrayItem = async (id: number) : Promise<IRondinCancelado> => await getArrayItemStorageData('IRondinCanceladoArray', id, getEmptyRondinCancelado);
export const removeStorageRondinCanceladoArrayItem = async (id: number) : Promise<IRondinCancelado[]> => await removeArrayItemStorageData('IRondinCanceladoArray', id, [] as IRondinCancelado[]);
