/** 
 * Componente que se encarga del elemento InformacionBancaria
 * @IInformacionBancaria es la interfaz que contiene los parámetos el elemento InformacionBancaria
 * @getEmptyInformacionBancaria  devuelve un objeto con los parámetros por defecto; @param {id}, opcional cuando no encuentra el objeto en el LocalSotrage
 * @IStatusInformacionBancaria es la interfaz para los estatus a llamadas a la API; @param {status}, opcional cuando queremos otro estado de inizialización de tipo [DEFAULT_STATE]
 * @getEmptyStatusInformacionBancaria devuelve un objeto con los parámetros por defecto,
 * @setInformacionBancaria devuelve un objeto de tipo [IInformacionBancaria] recibiendo @param {object} y @param {serverDate}, para verificar que los datos estén en el formato correcto y ajustarlos a la zona horaria actual
 * @getSerializableInformacionBancaria recibe un @param {IInformacionBancaria}, para devolver un objeto serizalizable para las peticiones de la API
 * */

import { getDateString, isNotEmpty, SERVER_TIME_ZONE } from "../../api/helpers";
import { DEFAULT_STATE } from "../../enums/default_state";
import { getStorageClienteQentryArrayItem, getStorageMvBancoArrayItem, getStorageUserArrayItem } from "../../localStorage/personas";
import { convertDateToZonaHoraria, ILocalStorageExpiry, getEmptyLocalStorageExpiry, IServerDate } from "../time";
import { getEmptyClienteQentry, IClienteQentry, setClienteQentry } from "./IClienteQentry";
import { IMvBanco, getEmptyMvBanco, setMvBanco } from "./IMvBanco";
import { IUser, getEmptyUser, setUser } from "./IUser";

export interface IInformacionBancaria {
  id : number,
  clabe : string,
  numero_cuenta : string,
  sucursal : string,
  banco : IMvBanco,
  autor : IUser,
  fecha_elaboracion : string, // Fecha
  estatus_sistema : boolean,
  ultima_actualizacion : string, // Fecha
  cliente_qentry : IClienteQentry,
}

export const getEmptyInformacionBancaria = (id? : number) => {
  const temp : IInformacionBancaria = 
  {
    id : id ? id : 0,
    clabe : "",
    numero_cuenta : "",
    sucursal : "",
    banco : getEmptyMvBanco(),
    autor : getEmptyUser(),
    fecha_elaboracion : "",
    estatus_sistema : false,
    ultima_actualizacion :  "",
    cliente_qentry : getEmptyClienteQentry(),
  };
  return temp;
}

export interface IStatusInformacionBancaria {
  estado : DEFAULT_STATE,
  mensaje : string,
  data : IInformacionBancaria,
  data_array: IInformacionBancaria[],
  expiry : ILocalStorageExpiry,
  expiry_array : ILocalStorageExpiry,
}

export const getEmptyStatusInformacionBancaria = (status: DEFAULT_STATE = DEFAULT_STATE.loading) => {
  const temp : IStatusInformacionBancaria = 
  {
    estado : status,
    mensaje : "",
    data : getEmptyInformacionBancaria(),
    data_array : [],
    expiry : getEmptyLocalStorageExpiry("IInformacionBancaria", 0),
    expiry_array : getEmptyLocalStorageExpiry("IInformacionBancariaArray", 0),
  };
  return temp;
}

export const setInformacionBancaria = async (
  object : any,
  serverDate : IServerDate,
) => {
  const temp = getEmptyInformacionBancaria();
  if (isNotEmpty(object.id)) temp.id = object.id;
  if (isNotEmpty(object.clabe)) temp.clabe = object.clabe;
  if (isNotEmpty(object.numero_cuenta)) temp.numero_cuenta = object.numero_cuenta;
  if (isNotEmpty(object.sucursal)) temp.sucursal = object.sucursal;
  if (isNotEmpty(object.banco)) temp.banco = (typeof object.banco === 'number') ? await getStorageMvBancoArrayItem(object.banco) : await setMvBanco(object.banco, serverDate);
  if (isNotEmpty(object.autor)) temp.autor = (typeof object.autor === 'number') ? await getStorageUserArrayItem(object.autor) : await setUser(object.autor, serverDate);
  if (isNotEmpty(object.fecha_elaboracion)) temp.fecha_elaboracion = convertDateToZonaHoraria(serverDate, object.fecha_elaboracion);
  if (isNotEmpty(object.estatus_sistema)) temp.estatus_sistema = object.estatus_sistema;
  if (isNotEmpty(object.ultima_actualizacion)) temp.ultima_actualizacion = convertDateToZonaHoraria(serverDate, object.ultima_actualizacion);
  if (isNotEmpty(object.cliente_qentry)) temp.cliente_qentry = (typeof object.cliente_qentry === 'number') ? await getStorageClienteQentryArrayItem(object.cliente_qentry) : await setClienteQentry(object.cliente_qentry, serverDate);
  return temp;
}

export const getSerializableInformacionBancaria = (IInformacionBancaria : IInformacionBancaria) => {
  return {
    id : IInformacionBancaria.id,
    clabe : IInformacionBancaria.clabe,
    numero_cuenta : IInformacionBancaria.numero_cuenta,
    sucursal : IInformacionBancaria.sucursal,
    banco : IInformacionBancaria.banco.id,
    autor : IInformacionBancaria.autor.id,
    fecha_elaboracion : IInformacionBancaria.fecha_elaboracion ? IInformacionBancaria.fecha_elaboracion : getDateString(SERVER_TIME_ZONE),
    estatus_sistema : IInformacionBancaria.estatus_sistema,
    ultima_actualizacion : getDateString(SERVER_TIME_ZONE),
    cliente_qentry : IInformacionBancaria.cliente_qentry.id,
  };
}
