/**
 * Métodos AsyncThunk para ser usados en los reducers y
 * los resultados a las llamadas http puedan ser usados para
 * actualizar el state en redux
 */

import { createAsyncThunk } from "@reduxjs/toolkit";
import { DEFAULT_STATE } from "../../enums/default_state";
import { getModificadorNomina, getAllModificadorNomina, updateModificadorNomina, createModificadorNomina, deleteModificadorNomina } from "../../http/personas";
import { getEmptyStatusModificadorNomina, IModificadorNomina } from "../../interfaces/personas";
import { IServerDate } from "../../interfaces/time";

export const getModificadorNominaAsyncThunk = createAsyncThunk(
  'personas/getModificadorNominaAsyncThunk',
  async (query : {serverDate : IServerDate, id: number, idCliente : number}) => await getModificadorNomina(query.serverDate, query.id, query.idCliente)
    .then(async (result) => result)
    .catch(async (error) => getEmptyStatusModificadorNomina(DEFAULT_STATE.error))  
);

export const getAllModificadorNominaAsyncThunk = createAsyncThunk(
  'personas/getAllModificadorNominaAsyncThunk',
  async (queryAll : {serverDate : IServerDate, idCliente : number}) => await getAllModificadorNomina(queryAll.serverDate, queryAll.idCliente)
    .then(async (result) => result)
    .catch(async (error) => getEmptyStatusModificadorNomina(DEFAULT_STATE.error))  
);

export const updateModificadorNominaAsyncThunk = createAsyncThunk(
  'personas/updateModificadorNominaAsyncThunk',
  async (update: {serverDate : IServerDate, id: number, idCliente : number, object: IModificadorNomina}) => await updateModificadorNomina(update.serverDate, update.id, update.idCliente, update.object)
    .then(async (result) => result)
    .catch(async (error) => getEmptyStatusModificadorNomina(DEFAULT_STATE.error))  
);

export const createModificadorNominaAsyncThunk = createAsyncThunk(
  'personas/createModificadorNominaAsyncThunk',
  async (create : {serverDate : IServerDate, idCliente : number, object: IModificadorNomina}) => await createModificadorNomina(create.serverDate, create.idCliente, create.object)
    .then(async (result) => result)
    .catch(async (error) => getEmptyStatusModificadorNomina(DEFAULT_STATE.error))  
);

export const deleteModificadorNominaAsyncThunk = createAsyncThunk(
  'personas/deleteModificadorNominaAsyncThunk',
  async (del : {id : number, idCliente : number}) => await deleteModificadorNomina(del.id, del.idCliente)
    .then(async (result) => result)
    .catch(async (error) => getEmptyStatusModificadorNomina())  
);
