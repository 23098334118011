/**
 * Métodos para el manejo de IonicStorage 
 * @setStorage              guarda un unico objeto al storage
 * @getStorage              obtiene el objeto guardado con @setStorage
 * @setStorageArray         guarda el array de objetos en el storage
 * @getStorageArray         obtiene el array de objetos guardado con @setStorageArray
 * @removeStorageArray      elimina el array de objetos guardado con @setStorageArray
 * @setStorageItemArray     agrega un elemento al array de objeto guardado con @setStorageArray
 * @getStorageItemArray     obtiene un elemento del array guardado con @setStorageArray buscandolo por su id 
 * @removeStorageItemArray  elimina un elemento del array guardado con @setStorageArray buscandolo por su id 
 */

import { setStorageData, getStorageData, removeStorageData, setArrayItemStorageData, getArrayItemStorageData, removeArrayItemStorageData } from "../../api/storage";
import { ITurno, getEmptyTurno } from "../../interfaces/nomina";

export const setStorageTurno = async (ITurno : ITurno) => await setStorageData('ITurno', ITurno);
export const getStorageTurno = async () : Promise<ITurno> => await getStorageData('ITurno', getEmptyTurno());
export const removeStorageTurno = async () => await removeStorageData('ITurno');
export const setStorageTurnoArray = async (ITurno : ITurno[]) => await setStorageData('ITurnoArray', ITurno);
export const getStorageTurnoArray = async () : Promise<ITurno[]> => await getStorageData('ITurnoArray', [] as ITurno[]);
export const removeStorageTurnoArray = async () => await removeStorageData('ITurnoArray');
export const setStorageTurnoArrayItem = async (id: number, ITurno : ITurno) : Promise<ITurno[]> => await setArrayItemStorageData('ITurnoArray', id, ITurno, [] as ITurno[]);
export const getStorageTurnoArrayItem = async (id: number) : Promise<ITurno> => await getArrayItemStorageData('ITurnoArray', id, getEmptyTurno);
export const removeStorageTurnoArrayItem = async (id: number) : Promise<ITurno[]> => await removeArrayItemStorageData('ITurnoArray', id, [] as ITurno[]);
