/**
 * Es el componente encargado de unir el @Menu / @IonHeader / @IonFab  y los componentes de las páginas en una sola vista
 * @function logout se encarga de cerrar la sesión del usuario
 */

import React, { useState, useEffect } from "react";

import {
  IonButtons,
  IonButton,
  IonHeader,
  IonIcon,
  IonLoading,
  IonMenuButton,
  IonPage,
  IonTitle,
  IonToolbar,
  IonRouterOutlet,
  IonFabButton,
  IonFab,
  IonFabList,
  useIonAlert,
} from "@ionic/react";

import {
  refresh,
  gridOutline,
  gridSharp,
  notificationsOutline,
  notificationsSharp,
  personOutline,
  personSharp,
  settings,
  sunny,
  help,
  logOutOutline,
  moon,
} from "ionicons/icons";

import { useParams } from "react-router";
import { Route } from "react-router-dom";

import Dashboard from "../pages/Dashboard";
import TeamMembers from "../pages/TeamMembers";
import WorkCenter from "../pages/WorkCenters";
import Reports from "../pages/Reports";
import Record from "../pages/Record";
import Requests from "../pages/Requests";
import Disabilities from "../pages/Disabilities";
import Vacations from "../pages/Vacations";
import DeviceChangeRequest from "../pages/DeviceChangeRequests";
import ScheduleChange from "../pages/ScheduleChange";
import Company from "../pages/Company";
import Profile from "../pages/Profile";
import MoreApps from "../pages/MoreApps";
import Faq from "../pages/Faq";
import "./View.css";
import Patrols from "../pages/Patrols";
import useRedux, { useAppDispatch, useAppSelector } from "../hooks/useRedux";
import { getClienteQentryAsyncThunk } from "../redux/asyncThunk/personas/ClienteQEntry";
import {
  getAllCentroDeTrabajoAsyncThunk,
  getAllCodigoQrAsyncThunk,
  getAllDatosFiscalesAsyncThunk,
  getAllDatosPersonalesAsyncThunk,
  getAllDireccionAsyncThunk,
  getAllDocumentoAsyncThunk,
  getAllEmpleadoAsyncThunk,
  getAllEmpresaAsyncThunk,
  getAllEstadoAsyncThunk,
  getAllGeolocalizacionAsyncThunk,
  getAllImagenAsyncThunk,
  getAllInformacionBancariaAsyncThunk,
  getAllInformacionContratoAsyncThunk,
  getAllMvBancoAsyncThunk,
  getAllMvEstadoCivilAsyncThunk,
  getAllMvGeneroAsyncThunk,
  getAllMvTipoDocumentoAsyncThunk,
  getAllMvTipoEmpleadoAsyncThunk,
  getAllMvTipoUsuarioAsyncThunk,
  getAllMvUsoFacturaAsyncThunk,
  getAllPaisAsyncThunk,
  getAllUserAsyncThunk,
} from "../redux/asyncThunk/personas";
import { signOut } from "../redux/asyncThunk/auth";
import {
  getThemeAsyncThunk,
  setThemeAsyncThunk,
} from "../redux/slices/themeSlice";

const View: React.FC = () => {
  const [presentAlert, dissmissAlert] = useIonAlert();
  const [
    {
      auth,
      theme,
      empresa,
      clienteQentry,
      serverDate,
      fetchRondines,
      fetchHorarios,
      fetchAsistencia,
      fetchVacations,
      fetchIncapacidades,
      fetchSolicitudesCambioDispositivo,
    },
  ] = useRedux();
  const dispatch = useAppDispatch();
  const { name } = useParams<{ name: string }>();

  useEffect(() => {
    const dark = document.body.classList.contains("dark");
    if (dark !== !theme.isLigthTheme) document.body.classList.toggle("dark");
  }, [theme.isLigthTheme]);

  useEffect(() => {
    dispatch(getThemeAsyncThunk());
    const load = async () => {
      dispatch(getAllMvTipoUsuarioAsyncThunk({ serverDate: serverDate.data }));
      dispatch(getAllUserAsyncThunk({ serverDate: serverDate.data }));
      dispatch(getAllPaisAsyncThunk({ serverDate: serverDate.data }));
      dispatch(getAllMvUsoFacturaAsyncThunk({ serverDate: serverDate.data }));
      dispatch(getAllDatosFiscalesAsyncThunk({ serverDate: serverDate.data }));
      await dispatch(
        getClienteQentryAsyncThunk({
          serverDate: serverDate.data,
          id: auth.empleado.cliente_qentry.id,
        })
      );
    };
    load();
  }, []);

  useEffect(() => {
    const loadEmpresa = async () => {
      //Data for Empresa
      dispatch(getAllEstadoAsyncThunk({ serverDate: serverDate.data }));
      await dispatch(
        getAllGeolocalizacionAsyncThunk({
          serverDate: serverDate.data,
          idCliente: clienteQentry.data.id,
        })
      );
      await dispatch(
        getAllDireccionAsyncThunk({
          serverDate: serverDate.data,
          idCliente: clienteQentry.data.id,
        })
      );
      await dispatch(
        getAllEmpresaAsyncThunk({
          serverDate: serverDate.data,
          idCliente: clienteQentry.data.id,
        })
      );
    };
    if (clienteQentry.data.id > 0) loadEmpresa();
  }, [clienteQentry.data]);

  useEffect(() => {
    const loadEmpleados = async () => {
      //Data for Empleado
      await dispatch(
        getAllDatosFiscalesAsyncThunk({ serverDate: serverDate.data })
      );
      dispatch(getAllMvGeneroAsyncThunk({ serverDate: serverDate.data }));
      dispatch(getAllMvEstadoCivilAsyncThunk({ serverDate: serverDate.data }));
      dispatch(
        getAllDatosPersonalesAsyncThunk({
          serverDate: serverDate.data,
          idCliente: clienteQentry.data.id,
        })
      );
      dispatch(getAllMvBancoAsyncThunk({ serverDate: serverDate.data }));
      dispatch(
        getAllInformacionBancariaAsyncThunk({
          serverDate: serverDate.data,
          idCliente: clienteQentry.data.id,
        })
      );
      dispatch(
        getAllMvTipoDocumentoAsyncThunk({ serverDate: serverDate.data })
      );
      dispatch(
        getAllDocumentoAsyncThunk({
          serverDate: serverDate.data,
          idCliente: clienteQentry.data.id,
        })
      );
      dispatch(
        getAllInformacionContratoAsyncThunk({
          serverDate: serverDate.data,
          idCliente: clienteQentry.data.id,
        })
      );
      await dispatch(
        getAllCentroDeTrabajoAsyncThunk({
          serverDate: serverDate.data,
          idCliente: clienteQentry.data.id,
        })
      );
      await dispatch(
        getAllCodigoQrAsyncThunk({
          serverDate: serverDate.data,
          idCliente: clienteQentry.data.id,
        })
      );
      dispatch(getAllMvTipoEmpleadoAsyncThunk({ serverDate: serverDate.data }));
      dispatch(
        getAllImagenAsyncThunk({
          serverDate: serverDate.data,
          idCliente: clienteQentry.data.id,
        })
      );
      await dispatch(
        getAllEmpleadoAsyncThunk({
          serverDate: serverDate.data,
          idCliente: clienteQentry.data.id,
        })
      );
      fetchRondines();
      fetchHorarios();
      fetchAsistencia();
      fetchVacations();
      fetchIncapacidades();
      fetchSolicitudesCambioDispositivo();
    };
    if (empresa.data_array.length > 0) loadEmpleados();
  }, [empresa.data_array]);

  const logout = () => {
    presentAlert({
      header: `¿Está seguro de que quiere cerrar su sesión?`,
      buttons: [
        {
          text: "Aceptar",
          handler() {
            dispatch(signOut(auth));
          },
        },
        {
          text: "Cancelar",
          role: "cancel",
        },
      ],
    });
  };

  return (
    <IonPage>
      <IonHeader class="ion-no-border">
        <IonToolbar className="toolBarStart">
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle>{name.replaceAll("-", " ")}</IonTitle>
          <IonButtons slot="end">
            <IonButton routerLink="/page/Resumen" class="hidden-sm-down">
              <IonIcon color="primary" ios={gridOutline} md={gridSharp} />
            </IonButton>
            <IonButton routerLink="/page/Solicitudes">
              <IonIcon
                color="primary"
                ios={notificationsOutline}
                md={notificationsSharp}
              />
            </IonButton>
            <IonButton routerLink="/page/Perfil" routerDirection="none">
              <IonIcon color="primary" ios={personOutline} md={personSharp} />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonFab vertical="bottom" horizontal="end" slot="fixed">
        <IonFabButton>
          <IonIcon color="secondary" icon={settings} />
        </IonFabButton>
        <IonFabList side="top">
          <IonFabButton
            color={theme.isLigthTheme ? "light" : "dark"}
            onClick={() =>
              dispatch(
                setThemeAsyncThunk({
                  ...theme,
                  isLigthTheme: !theme.isLigthTheme,
                })
              )
            }
          >
            <IonIcon icon={theme.isLigthTheme ? moon : sunny} />
          </IonFabButton>
          <IonFabButton
            color={theme.isLigthTheme ? "light" : "dark"}
            routerLink="Faq"
          >
            <IonIcon icon={help} />
          </IonFabButton>
          <IonFabButton
            color={theme.isLigthTheme ? "light" : "dark"}
            onClick={() => logout()}
          >
            <IonIcon icon={logOutOutline} />
          </IonFabButton>
        </IonFabList>
      </IonFab>
      <IonRouterOutlet>
        <Route path="/page/Resumen" exact={true} component={Dashboard} />
        <Route
          path="/page/Usuarios-colaboradores"
          exact={true}
          component={TeamMembers}
        />
        <Route
          path="/page/Cambios-de-horarios"
          exact={true}
          component={ScheduleChange}
        />
        <Route
          path="/page/Centros-de-trabajo"
          exact={true}
          component={WorkCenter}
        />
        <Route path="/page/Rondines" exact={true} component={Patrols} />
        <Route path="/page/Reportes" exact={true} component={Reports} />
        <Route path="/page/Historial" exact={true} component={Record} />
        <Route path="/page/Solicitudes" exact={true} component={Requests} />
        <Route
          path="/page/Incapacidades"
          exact={true}
          component={Disabilities}
        />
        <Route path="/page/Vacaciones" exact={true} component={Vacations} />
        <Route
          path="/page/Solicitudes-cambio-dispositivo"
          exact={true}
          component={DeviceChangeRequest}
        />
        <Route path="/page/Empresas" exact={true} component={Company} />
        <Route path="/page/Perfil" exact={true} component={Profile} />
        <Route
          path="/page/Aplicaciones-del-usuario"
          exact={true}
          component={MoreApps}
        />
        <Route path="/page/Faq" exact={true} component={Faq} />
      </IonRouterOutlet>
    </IonPage>
  );
};

export default View;
