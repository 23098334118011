import { createAsyncThunk } from "@reduxjs/toolkit";
import { login, signOut as firebaseSignOut, User } from "../../../firebase/config";
import { clearAllData } from "../../api/storage";
import { clearIAuthStorage, getDefaultIAuth, getErrorIAuth, getIfExistIAuth, getResultIAuth, IAuth, IAuthException, ILogin } from "../../interfaces/auth";
import { IServerDate } from "../../interfaces/time";

export const getIAuthFromStorage = createAsyncThunk(
  'auth/getIAuthFromStorage',
  async () => await getIfExistIAuth()
    .then((data: any) => {
      console.log('data: ', data);
      return data;
    })
    .catch((error: any) => {
      console.log('error: ', error);
      return getDefaultIAuth();
    })
);

export const detectActiveSession = createAsyncThunk(
  'auth/detectActiveSession',
  async (user: {data: User | null, serverDate: IServerDate}) => await getResultIAuth((user.data ? {email: user.data.email, uid: user.data!.uid, displayName: user.data!.displayName} : null), user.serverDate)
    .catch(async () => await getErrorIAuth({ code: "", message: "Ocurrió un problema al obtener los datos del usuario", name: "", stack: ""}))
  
);

export const loginByEmail = createAsyncThunk(
  'auth/loginByEmail',
  async (user: {credentials: ILogin, serverDate: IServerDate}) => await login(user.credentials.email, user.credentials.password, user.serverDate)
    .then(async (userCredential) => await getResultIAuth({email: userCredential.credentials.user.email, uid: userCredential.credentials.user.uid, displayName: userCredential.credentials.user.displayName}, userCredential.serverDate))
    .catch(async (error) => await getErrorIAuth({ code: error.code, message: error.message, name: error.name, stack: error.stack}))
  
);

export const signOut = createAsyncThunk(
  'auth/signOut',
  async (IAuth: IAuth) => await firebaseSignOut()
    .then(async () => {
      await clearIAuthStorage();
      await clearAllData();
      return getDefaultIAuth();
    })
    .catch(async (error: IAuthException) => {
      console.log('signOutError: ', error);
      return await getErrorIAuth(error, IAuth.user, IAuth)
    })
);
