/** 
 * Componente que se encarga del elemento CheckEnRuta
 * @ICheckEnRuta es la interfaz que contiene los parámetos el elemento CheckEnRuta
 * @getEmptyCheckEnRuta  devuelve un objeto con los parámetros por defecto; @param {id}, opcional cuando no encuentra el objeto en el LocalSotrage
 * @IStatusCheckEnRuta es la interfaz para los estatus a llamadas a la API; @param {status}, opcional cuando queremos otro estado de inizialización de tipo [DEFAULT_STATE]
 * @getEmptyStatusCheckEnRuta devuelve un objeto con los parámetros por defecto,
 * @setCheckEnRuta devuelve un objeto de tipo [ICheckEnRuta] recibiendo @param {object} y @param {serverDate}, para verificar que los datos estén en el formato correcto y ajustarlos a la zona horaria actual
 * @getSerializableCheckEnRuta recibe un @param {ICheckEnRuta}, para devolver un objeto serizalizable para las peticiones de la API
 * */

import { isNotEmpty, getDateString, SERVER_TIME_ZONE } from "../../api/helpers";
import { DEFAULT_STATE } from "../../enums/default_state";
import { getStorageClienteQentryArrayItem, getStorageEmpleadoArrayItem, getStorageEmpresaArrayItem, getStorageGeolocalizacionArrayItem } from "../../localStorage/personas";
import { getEmptyClienteQentry, getEmptyEmpleado, getEmptyEmpresa, getEmptyGeolocalizacion, IClienteQentry, IEmpleado, IEmpresa, IGeolocalizacion, setClienteQentry, setEmpleado, setEmpresa, setGeolocalizacion } from "../personas";
import { ILocalStorageExpiry, getEmptyLocalStorageExpiry, IServerDate, convertDateToZonaHoraria } from "../time";

export interface ICheckEnRuta {
  id : number,
  empleado : IEmpleado,
  geolocalizacion : IGeolocalizacion,
  cliente_qentry : IClienteQentry,
  empresa : IEmpresa,
  fecha_elaboracion : string, // Fecha
  estatus_sistema : boolean,
  ultima_actualizacion : string, // Fecha
}

export const getEmptyCheckEnRuta = (id? : number) => {
  const temp : ICheckEnRuta = 
  {
    id : id ? id : 0,
    empleado : getEmptyEmpleado(),
    geolocalizacion : getEmptyGeolocalizacion(),
    empresa : getEmptyEmpresa(),
    cliente_qentry : getEmptyClienteQentry(),
    fecha_elaboracion : "",
    estatus_sistema : false,
    ultima_actualizacion : "",
  };
  return temp;
}

export interface IStatusCheckEnRuta {
  estado : DEFAULT_STATE,
  mensaje : string,
  data : ICheckEnRuta,
  data_array: ICheckEnRuta[],
  expiry : ILocalStorageExpiry,
  expiry_array : ILocalStorageExpiry,
}

export const getEmptyStatusCheckEnRuta = (status: DEFAULT_STATE = DEFAULT_STATE.loading) => {
  const temp : IStatusCheckEnRuta = 
  {
    estado : status,
    mensaje : "",
    data : getEmptyCheckEnRuta(),
    data_array : [],
    expiry : getEmptyLocalStorageExpiry("ICheckEnRuta", 0),
    expiry_array : getEmptyLocalStorageExpiry("ICheckEnRutaArray", 0),
  };
  return temp;
}

export const setCheckEnRuta = async (
  object : any,
  serverDate : IServerDate,
) => {
  const temp = getEmptyCheckEnRuta();
  if (isNotEmpty(object.id)) temp.id = object.id;
  if (isNotEmpty(object.empleado)) temp.empleado = (typeof object.empleado === 'number') ? await getStorageEmpleadoArrayItem(object.empleado) : await setEmpleado(object.empleado, serverDate);
  if (isNotEmpty(object.geolocalizacion)) temp.geolocalizacion = (typeof object.geolocalizacion === 'number') ? await getStorageGeolocalizacionArrayItem(object.geolocalizacion) : await setGeolocalizacion(object.geolocalizacion, serverDate);
  if (isNotEmpty(object.cliente_qentry)) temp.cliente_qentry = (typeof object.cliente_qentry === 'number') ? await getStorageClienteQentryArrayItem(object.cliente_qentry) : await setClienteQentry(object.cliente_qentry, serverDate);
  if (isNotEmpty(object.empresa)) temp.empresa = (typeof object.empresa === 'number') ? await getStorageEmpresaArrayItem(object.empresa) : await setEmpresa(object.empresa, serverDate);
  if (isNotEmpty(object.fecha_elaboracion)) temp.fecha_elaboracion = convertDateToZonaHoraria(serverDate, object.fecha_elaboracion);
  if (isNotEmpty(object.estatus_sistema)) temp.estatus_sistema = object.estatus_sistema;
  if (isNotEmpty(object.ultima_actualizacion)) temp.ultima_actualizacion = convertDateToZonaHoraria(serverDate, object.ultima_actualizacion);
  return temp;
}

export const getSerializableCheckEnRuta = (ICheckEnRuta : ICheckEnRuta ) => {
  return {
    id : ICheckEnRuta.id,
    empleado : ICheckEnRuta.empleado.id,
    geolocalizacion : ICheckEnRuta.geolocalizacion.id,
    empresa : ICheckEnRuta.empresa.id,
    cliente_qentry : ICheckEnRuta.cliente_qentry.id,
    fecha_elaboracion : ICheckEnRuta.fecha_elaboracion,
    estatus_sistema : ICheckEnRuta.estatus_sistema,
    ultima_actualizacion : getDateString(SERVER_TIME_ZONE),
  };
}
