/**
 * Métodos para el manejo de IonicStorage 
 * @setStorage              guarda un unico objeto al storage
 * @getStorage              obtiene el objeto guardado con @setStorage
 * @setStorageArray         guarda el array de objetos en el storage
 * @getStorageArray         obtiene el array de objetos guardado con @setStorageArray
 * @removeStorageArray      elimina el array de objetos guardado con @setStorageArray
 * @setStorageItemArray     agrega un elemento al array de objeto guardado con @setStorageArray
 * @getStorageItemArray     obtiene un elemento del array guardado con @setStorageArray buscandolo por su id 
 * @removeStorageItemArray  elimina un elemento del array guardado con @setStorageArray buscandolo por su id 
 */

import { setStorageData, getStorageData, removeStorageData, setArrayItemStorageData, getArrayItemStorageData, removeArrayItemStorageData } from "../../api/storage";
import { IInformacionSuscripcion, getEmptyInformacionSuscripcion } from "../../interfaces/personas";

export const setStorageInformacionSuscripcion = async (IInformacionSuscripcion : IInformacionSuscripcion) => await setStorageData('IInformacionSuscripcion', IInformacionSuscripcion);
export const getStorageInformacionSuscripcion = async () : Promise<IInformacionSuscripcion> => await getStorageData('IInformacionSuscripcion', getEmptyInformacionSuscripcion());
export const removeStorageInformacionSuscripcion = async () => await removeStorageData('IInformacionSuscripcion');
export const setStorageInformacionSuscripcionArray = async (IInformacionSuscripcion : IInformacionSuscripcion[]) => await setStorageData('IInformacionSuscripcionArray', IInformacionSuscripcion);
export const getStorageInformacionSuscripcionArray = async () : Promise<IInformacionSuscripcion[]> => await getStorageData('IInformacionSuscripcionArray', [] as IInformacionSuscripcion[]);
export const removeStorageInformacionSuscripcionArray = async () => await removeStorageData('IInformacionSuscripcionArray');
export const setStorageInformacionSuscripcionArrayItem = async (id: number, IInformacionSuscripcion : IInformacionSuscripcion) : Promise<IInformacionSuscripcion[]> => await setArrayItemStorageData('IInformacionSuscripcionArray', id, IInformacionSuscripcion, [] as IInformacionSuscripcion[]);
export const getStorageInformacionSuscripcionArrayItem = async (id: number) : Promise<IInformacionSuscripcion> => await getArrayItemStorageData('IInformacionSuscripcionArray', id, getEmptyInformacionSuscripcion);
export const removeStorageInformacionSuscripcionArrayItem = async (id: number) : Promise<IInformacionSuscripcion[]> => await removeArrayItemStorageData('IInformacionSuscripcionArray', id, [] as IInformacionSuscripcion[]);
