/**
 * Métodos para el manejo de IonicStorage 
 * @setStorage              guarda un unico objeto al storage
 * @getStorage              obtiene el objeto guardado con @setStorage
 * @setStorageArray         guarda el array de objetos en el storage
 * @getStorageArray         obtiene el array de objetos guardado con @setStorageArray
 * @removeStorageArray      elimina el array de objetos guardado con @setStorageArray
 * @setStorageItemArray     agrega un elemento al array de objeto guardado con @setStorageArray
 * @getStorageItemArray     obtiene un elemento del array guardado con @setStorageArray buscandolo por su id 
 * @removeStorageItemArray  elimina un elemento del array guardado con @setStorageArray buscandolo por su id 
 */

import { setStorageData, getStorageData, removeStorageData, setArrayItemStorageData, getArrayItemStorageData, removeArrayItemStorageData } from "../../api/storage";
import { IMvMotivoVacaciones, getEmptyMvMotivoVacaciones } from "../../interfaces/nomina";

export const setStorageMvMotivoVacaciones = async (IMvMotivoVacaciones : IMvMotivoVacaciones) => await setStorageData('IMvMotivoVacaciones', IMvMotivoVacaciones);
export const getStorageMvMotivoVacaciones = async () : Promise<IMvMotivoVacaciones> => await getStorageData('IMvMotivoVacaciones', getEmptyMvMotivoVacaciones());
export const removeStorageMvMotivoVacaciones = async () => await removeStorageData('IMvMotivoVacaciones');
export const setStorageMvMotivoVacacionesArray = async (IMvMotivoVacaciones : IMvMotivoVacaciones[]) => await setStorageData('IMvMotivoVacacionesArray', IMvMotivoVacaciones);
export const getStorageMvMotivoVacacionesArray = async () : Promise<IMvMotivoVacaciones[]> => await getStorageData('IMvMotivoVacacionesArray', [] as IMvMotivoVacaciones[]);
export const removeStorageMvMotivoVacacionesArray = async () => await removeStorageData('IMvMotivoVacacionesArray');
export const setStorageMvMotivoVacacionesArrayItem = async (id: number, IMvMotivoVacaciones : IMvMotivoVacaciones) : Promise<IMvMotivoVacaciones[]> => await setArrayItemStorageData('IMvMotivoVacacionesArray', id, IMvMotivoVacaciones, [] as IMvMotivoVacaciones[]);
export const getStorageMvMotivoVacacionesArrayItem = async (id: number) : Promise<IMvMotivoVacaciones> => await getArrayItemStorageData('IMvMotivoVacacionesArray', id, getEmptyMvMotivoVacaciones);
export const removeStorageMvMotivoVacacionesArrayItem = async (id: number) : Promise<IMvMotivoVacaciones[]> => await removeArrayItemStorageData('IMvMotivoVacacionesArray', id, [] as IMvMotivoVacaciones[]);
