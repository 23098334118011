/**
 * Métodos del, get, post, put para llamadas a la API
 * @get     en este método primero consulta con @getFromLocalStorage si es necesario hacer la petición http sino se obtiene del LocalStorage;
 *          si hay un error en la petición http se busca el objeto en el LocalStorage sino lo encuentra devuelve un objeto vacío; si la
 *          petición se realiza correctamente actualiza el valor individual y en el array del objeto en el LocalStorage y devuelve el objeto
 *          obtenido del servidor.
 * @getAll  en este método primero consulta con @getFromLocalStorage si es necesario hacer la petición http sino se obtiene del LocalStorage;
 *          si hay un error en la petición http se busca el objeto array en el LocalStorage sino lo encuentra devuelve un array vacío; si la
 *          petición se realiza correctamente actualiza array en el LocalStorage y devuelve el array obtenido del servidor
 * @update  en este método después de realizar la petición http si falla devuelve el objeto que se iba a actualizar; si la petición se
 *          realiza correctamente actualiza el valor individual y en el array del objeto en el LocalStorage y devuelve el objeto obtenido del
 *          servidor.
 * @create  en este método después de realizar la petición http si falla devuelve el objeto que se iba a crear; si la petición se realiza
 *          correctamente actualiza el valor individual y en el array del objeto en el LocalStorage y devuelve el objeto obtenido del
 *          servidor.
 * @delete  en este método después de realizar la petición http si falla devuelve el objeto que se iba a eliminar; si la petición se realiza
 *          correctamente elimina el valor individual y en el objeto en el arrya del LocalStorage y devuelve un objeto vacio (Default)
 */

import { del, get, post, put } from "../../api/api";
import {
  getFromLocalStorage,
  updateLocalStorageExpiry,
} from "../../api/helpers";
import { removeArrayItemStorageData } from "../../api/storage";
import { DEFAULT_STATE } from "../../enums/default_state";
import {
  getEmptyStatusMvMotivoVacaciones,
  setMvMotivoVacaciones,
  IMvMotivoVacaciones,
  getSerializableMvMotivoVacaciones,
} from "../../interfaces/nomina";
import { IServerDate } from "../../interfaces/time";
import {
  getStorageMvMotivoVacacionesArrayItem,
  setStorageMvMotivoVacaciones,
  setStorageMvMotivoVacacionesArrayItem,
  getStorageMvMotivoVacacionesArray,
  setStorageMvMotivoVacacionesArray,
  removeStorageMvMotivoVacaciones,
} from "../../localStorage/nomina";

export const getMvMotivoVacaciones = async (
  serverDate: IServerDate,
  pk: number
) => {
  const temp = getEmptyStatusMvMotivoVacaciones();
  const doHttp = await getFromLocalStorage(serverDate, temp.expiry);
  temp.expiry = doHttp.storage;
  if (!doHttp.result) {
    console.log(
      `(localStorage) MvMotivoVacaciones con pk: ${pk}, obtenido con éxito`
    );
    temp.estado = DEFAULT_STATE.success;
    temp.mensaje = `MvMotivoVacaciones obtenido con éxito`;
    temp.data = await getStorageMvMotivoVacacionesArrayItem(pk);
    return temp;
  }
  const result = await get(`nomina/motivo-vacaciones/${pk}`);
  const data = result.data;
  console.log(`MvMotivoVacaciones data: `, data);
  if (!data.id || data.detail) {
    console.log(
      `Error al obtener MvMotivoVacaciones con pk: ${pk}, ${data.detail}`
    );
    temp.estado = DEFAULT_STATE.error;
    temp.mensaje = `Error al obtener el MvMotivoVacaciones: ${
      data.detail ?? "Hubo un problema al conectarse con el servidor"
    }`;
    temp.data = await getStorageMvMotivoVacacionesArrayItem(pk);
    return temp;
  }
  console.log(`MvMotivoVacaciones con pk: ${pk}, obtenido con éxito`);
  temp.estado = DEFAULT_STATE.success;
  temp.mensaje = `MvMotivoVacaciones obtenido con éxito`;
  temp.data = await setMvMotivoVacaciones(data, serverDate);
  await setStorageMvMotivoVacaciones(temp.data);
  temp.data_array = await setStorageMvMotivoVacacionesArrayItem(pk, temp.data);
  temp.expiry = await updateLocalStorageExpiry(serverDate, temp.expiry);
  return temp;
};

export const getAllMvMotivoVacaciones = async (serverDate: IServerDate) => {
  const temp = getEmptyStatusMvMotivoVacaciones();
  const doHttp = await getFromLocalStorage(serverDate, temp.expiry_array);
  temp.expiry_array = doHttp.storage;
  if (!doHttp.result) {
    console.log(
      `(localStorage) Todos los MvMotivoVacacioness obtenidos con éxito`
    );
    temp.estado = DEFAULT_STATE.success;
    temp.mensaje = `Todos los MvMotivoVacaciones obtenidos con éxito`;
    temp.data_array = await getStorageMvMotivoVacacionesArray();
    return temp;
  }
  const result = await get(`nomina/motivo-vacaciones`);
  const data = result.data;
  if (data.detail) {
    console.log(
      `Error al obtener la lista de MvMotivoVacaciones, ${data.detail}`
    );
    temp.estado = DEFAULT_STATE.error;
    temp.mensaje = `Error al obtener la lista de MvMotivoVacaciones: ${
      data.detail ?? "Hubo un problema al conectarse con el servidor"
    }`;
    temp.data_array = await getStorageMvMotivoVacacionesArray();
    return temp;
  }
  console.log(`Todos los MvMotivoVacaciones obtenidos con éxito`, data);
  temp.estado = DEFAULT_STATE.success;
  temp.mensaje = `Todos los MvMotivoVacaciones obtenidos con éxito`;
  temp.data_array = await Promise.all(
    data.map(async (e: any) => await setMvMotivoVacaciones(e, serverDate))
  );
  await setStorageMvMotivoVacacionesArray(temp.data_array);
  temp.expiry_array = await updateLocalStorageExpiry(
    serverDate,
    temp.expiry_array
  );
  return temp;
};

export const updateMvMotivoVacaciones = async (
  serverDate: IServerDate,
  pk: number,
  object: IMvMotivoVacaciones
) => {
  const temp = getEmptyStatusMvMotivoVacaciones();
  const result = await put(
    `nomina/motivo-vacaciones/${pk}`,
    getSerializableMvMotivoVacaciones(object)
  );
  const data = result.data;
  if (!data.id || data.detail) {
    console.log(
      `Error al actualizar MvMotivoVacaciones con pk: ${pk}, ${data.detail}`
    );
    temp.estado = DEFAULT_STATE.error;
    temp.mensaje = `Error al actualizar el MvMotivoVacaciones: ${
      data.detail ?? "Hubo un problema al conectarse con el servidor"
    }`;
    temp.data = await setMvMotivoVacaciones(object, serverDate);
    return temp;
  }
  console.log(`MvMotivoVacaciones con pk: ${pk}, actualizado con éxito`);
  temp.estado = DEFAULT_STATE.success;
  temp.mensaje = `MvMotivoVacaciones actualizado con éxito`;
  temp.data = await setMvMotivoVacaciones(data, serverDate);
  await setStorageMvMotivoVacaciones(temp.data);
  temp.data_array = await setStorageMvMotivoVacacionesArrayItem(
    temp.data.id,
    temp.data
  );
  return temp;
};

export const createMvMotivoVacaciones = async (
  serverDate: IServerDate,
  object: IMvMotivoVacaciones
) => {
  const temp = getEmptyStatusMvMotivoVacaciones();
  const result = await post(
    `nomina/motivo-vacaciones`,
    getSerializableMvMotivoVacaciones(object)
  );
  const data = result.data;
  if (!data.id || data.detail) {
    console.log(
      `Error al crear MvMotivoVacaciones con los datos ${object}, ${data.detail}`
    );
    temp.estado = DEFAULT_STATE.error;
    temp.mensaje = `Error al crear el MvMotivoVacaciones: ${
      data.detail ?? "Hubo un problema al conectarse con el servidor"
    }`;
    temp.data = await setMvMotivoVacaciones(object, serverDate);
    return temp;
  }
  console.log(`MvMotivoVacaciones creado con éxito`);
  temp.estado = DEFAULT_STATE.success;
  temp.mensaje = `MvMotivoVacaciones creado con éxito`;
  temp.data = await setMvMotivoVacaciones(data, serverDate);
  await setStorageMvMotivoVacaciones(temp.data);
  temp.data_array = await setStorageMvMotivoVacacionesArrayItem(
    temp.data.id,
    temp.data
  );
  return temp;
};

export const deleteMvMotivoVacaciones = async (pk: number) => {
  const temp = getEmptyStatusMvMotivoVacaciones();
  const result = await del(`nomina/motivo-vacaciones/${pk}`);
  const data = result.data;
  if (data.detail) {
    console.log(
      `Error al eliminar MvMotivoVacaciones con pk: ${pk}, ${data.detail}`
    );
    temp.estado = DEFAULT_STATE.error;
    temp.mensaje = `Error al eliminar el datos fiscales: ${data.detail}`;
    temp.data = await getStorageMvMotivoVacacionesArrayItem(pk);
    temp.data_array = await getStorageMvMotivoVacacionesArray();
    return temp;
  }
  console.log(`MvMotivoVacaciones eliminado con éxito`);
  temp.estado = DEFAULT_STATE.success;
  temp.mensaje = `MvMotivoVacaciones eliminado con éxito`;
  await removeStorageMvMotivoVacaciones();
  temp.data_array = await removeArrayItemStorageData(
    "IMvMotivoVacaciones",
    pk,
    []
  );
  return temp;
};
