/**
 * Métodos para el manejo de IonicStorage 
 * @setStorage              guarda un unico objeto al storage
 * @getStorage              obtiene el objeto guardado con @setStorage
 * @setStorageArray         guarda el array de objetos en el storage
 * @getStorageArray         obtiene el array de objetos guardado con @setStorageArray
 * @removeStorageArray      elimina el array de objetos guardado con @setStorageArray
 * @setStorageItemArray     agrega un elemento al array de objeto guardado con @setStorageArray
 * @getStorageItemArray     obtiene un elemento del array guardado con @setStorageArray buscandolo por su id 
 * @removeStorageItemArray  elimina un elemento del array guardado con @setStorageArray buscandolo por su id 
 */

import { setStorageData, getStorageData, removeStorageData, setArrayItemStorageData, getArrayItemStorageData, removeArrayItemStorageData } from "../../api/storage";
import { IMvTipoMovimientoAsistencia, getEmptyMvTipoMovimientoAsistencia } from "../../interfaces/nomina";

export const setStorageMvTipoMovimientoAsistencia = async (IMvTipoMovimientoAsistencia : IMvTipoMovimientoAsistencia) => await setStorageData('IMvTipoMovimientoAsistencia', IMvTipoMovimientoAsistencia);
export const getStorageMvTipoMovimientoAsistencia = async () : Promise<IMvTipoMovimientoAsistencia> => await getStorageData('IMvTipoMovimientoAsistencia', getEmptyMvTipoMovimientoAsistencia());
export const removeStorageMvTipoMovimientoAsistencia = async () => await removeStorageData('IMvTipoMovimientoAsistencia');
export const setStorageMvTipoMovimientoAsistenciaArray = async (IMvTipoMovimientoAsistencia : IMvTipoMovimientoAsistencia[]) => await setStorageData('IMvTipoMovimientoAsistenciaArray', IMvTipoMovimientoAsistencia);
export const getStorageMvTipoMovimientoAsistenciaArray = async () : Promise<IMvTipoMovimientoAsistencia[]> => await getStorageData('IMvTipoMovimientoAsistenciaArray', [] as IMvTipoMovimientoAsistencia[]);
export const removeStorageMvTipoMovimientoAsistenciaArray = async () => await removeStorageData('IMvTipoMovimientoAsistenciaArray');
export const setStorageMvTipoMovimientoAsistenciaArrayItem = async (id: number, IMvTipoMovimientoAsistencia : IMvTipoMovimientoAsistencia) : Promise<IMvTipoMovimientoAsistencia[]> => await setArrayItemStorageData('IMvTipoMovimientoAsistenciaArray', id, IMvTipoMovimientoAsistencia, [] as IMvTipoMovimientoAsistencia[]);
export const getStorageMvTipoMovimientoAsistenciaArrayItem = async (id: number) : Promise<IMvTipoMovimientoAsistencia> => await getArrayItemStorageData('IMvTipoMovimientoAsistenciaArray', id, getEmptyMvTipoMovimientoAsistencia);
export const removeStorageMvTipoMovimientoAsistenciaArrayItem = async (id: number) : Promise<IMvTipoMovimientoAsistencia[]> => await removeArrayItemStorageData('IMvTipoMovimientoAsistenciaArray', id, [] as IMvTipoMovimientoAsistencia[]);
