/**
 * Métodos AsyncThunk para ser usados en los reducers y
 * los resultados a las llamadas http puedan ser usados para
 * actualizar el state en redux
 */

import { createAsyncThunk } from "@reduxjs/toolkit";
import { DEFAULT_STATE } from "../../enums/default_state";
import { getMvImpuestos, getAllMvImpuestos, updateMvImpuestos, createMvImpuestos, deleteMvImpuestos } from "../../http/personas";
import { getEmptyStatusMvImpuestos, IMvImpuestos } from "../../interfaces/personas";
import { IServerDate } from "../../interfaces/time";

export const getMvImpuestosAsyncThunk = createAsyncThunk(
  'personas/getMvImpuestosAsyncThunk',
  async (query : {serverDate : IServerDate, id: number}) => await getMvImpuestos(query.serverDate, query.id)
    .then(async (result) => result)
    .catch(async (error) => getEmptyStatusMvImpuestos(DEFAULT_STATE.error))  
);

export const getAllMvImpuestosAsyncThunk = createAsyncThunk(
  'personas/getAllMvImpuestosAsyncThunk',
  async (queryAll : {serverDate : IServerDate}) => await getAllMvImpuestos(queryAll.serverDate)
    .then(async (result) => result)
    .catch(async (error) => getEmptyStatusMvImpuestos(DEFAULT_STATE.error))  
);

export const updateMvImpuestosAsyncThunk = createAsyncThunk(
  'personas/updateMvImpuestosAsyncThunk',
  async (update: {serverDate : IServerDate, id: number, MvImpuestos: IMvImpuestos}) => await updateMvImpuestos(update.serverDate, update.id, update.MvImpuestos)
    .then(async (result) => result)
    .catch(async (error) => getEmptyStatusMvImpuestos(DEFAULT_STATE.error))  
);

export const createMvImpuestosAsyncThunk = createAsyncThunk(
  'personas/createMvImpuestosAsyncThunk',
  async (create : {serverDate : IServerDate, MvImpuestos: IMvImpuestos}) => await createMvImpuestos(create.serverDate, create.MvImpuestos)
    .then(async (result) => result)
    .catch(async (error) => getEmptyStatusMvImpuestos(DEFAULT_STATE.error))  
);

export const deleteMvImpuestosAsyncThunk = createAsyncThunk(
  'personas/deleteMvImpuestosAsyncThunk',
  async (del : {id : number}) => await deleteMvImpuestos(del.id)
    .then(async (result) => result)
    .catch(async (error) => getEmptyStatusMvImpuestos())  
);
