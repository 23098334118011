/**
 * Métodos AsyncThunk para ser usados en los reducers y
 * los resultados a las llamadas http puedan ser usados para
 * actualizar el state en redux
 */

import { createAsyncThunk } from "@reduxjs/toolkit";
import { DEFAULT_STATE } from "../../enums/default_state";
import { getMvTipoServicioQentry, getAllMvTipoServicioQentry, updateMvTipoServicioQentry, createMvTipoServicioQentry, deleteMvTipoServicioQentry } from "../../http/personas";
import { getEmptyStatusMvTipoServicioQentry, IMvTipoServicioQentry } from "../../interfaces/personas";
import { IServerDate } from "../../interfaces/time";

export const getMvTipoServicioQentryAsyncThunk = createAsyncThunk(
  'personas/getMvTipoServicioQentryAsyncThunk',
  async (query : {serverDate : IServerDate, id: number}) => await getMvTipoServicioQentry(query.serverDate, query.id)
    .then(async (result) => result)
    .catch(async (error) => getEmptyStatusMvTipoServicioQentry(DEFAULT_STATE.error))  
);

export const getAllMvTipoServicioQentryAsyncThunk = createAsyncThunk(
  'personas/getAllMvTipoServicioQentryAsyncThunk',
  async (queryAll : {serverDate : IServerDate}) => await getAllMvTipoServicioQentry(queryAll.serverDate)
    .then(async (result) => result)
    .catch(async (error) => getEmptyStatusMvTipoServicioQentry(DEFAULT_STATE.error))  
);

export const updateMvTipoServicioQentryAsyncThunk = createAsyncThunk(
  'personas/updateMvTipoServicioQentryAsyncThunk',
  async (update: {serverDate : IServerDate, id: number, MvTipoServicioQentry: IMvTipoServicioQentry}) => await updateMvTipoServicioQentry(update.serverDate, update.id, update.MvTipoServicioQentry)
    .then(async (result) => result)
    .catch(async (error) => getEmptyStatusMvTipoServicioQentry(DEFAULT_STATE.error))  
);

export const createMvTipoServicioQentryAsyncThunk = createAsyncThunk(
  'personas/createMvTipoServicioQentryAsyncThunk',
  async (create : {serverDate : IServerDate, MvTipoServicioQentry: IMvTipoServicioQentry}) => await createMvTipoServicioQentry(create.serverDate, create.MvTipoServicioQentry)
    .then(async (result) => result)
    .catch(async (error) => getEmptyStatusMvTipoServicioQentry(DEFAULT_STATE.error))  
);

export const deleteMvTipoServicioQentryAsyncThunk = createAsyncThunk(
  'personas/deleteMvTipoServicioQentryAsyncThunk',
  async (del : {id : number}) => await deleteMvTipoServicioQentry(del.id)
    .then(async (result) => result)
    .catch(async (error) => getEmptyStatusMvTipoServicioQentry())  
);
