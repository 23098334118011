/**
 * Métodos para el manejo de IonicStorage 
 * @setStorage              guarda un unico objeto al storage
 * @getStorage              obtiene el objeto guardado con @setStorage
 * @setStorageArray         guarda el array de objetos en el storage
 * @getStorageArray         obtiene el array de objetos guardado con @setStorageArray
 * @removeStorageArray      elimina el array de objetos guardado con @setStorageArray
 * @setStorageItemArray     agrega un elemento al array de objeto guardado con @setStorageArray
 * @getStorageItemArray     obtiene un elemento del array guardado con @setStorageArray buscandolo por su id 
 * @removeStorageItemArray  elimina un elemento del array guardado con @setStorageArray buscandolo por su id 
 */

import { setStorageData, getStorageData, removeStorageData, setArrayItemStorageData, getArrayItemStorageData, removeArrayItemStorageData } from "../../api/storage";
import { IMvGenero, getEmptyMvGenero } from "../../interfaces/personas";

export const setStorageMvGenero = async (IMvGenero : IMvGenero) => await setStorageData('IMvGenero', IMvGenero);
export const getStorageMvGenero = async () : Promise<IMvGenero> => await getStorageData('IMvGenero', getEmptyMvGenero());
export const removeStorageMvGenero = async () => await removeStorageData('IMvGenero');
export const setStorageMvGeneroArray = async (IMvGenero : IMvGenero[]) => await setStorageData('IMvGeneroArray', IMvGenero);
export const getStorageMvGeneroArray = async () : Promise<IMvGenero[]> => await getStorageData('IMvGeneroArray', [] as IMvGenero[]);
export const removeStorageMvGeneroArray = async () => await removeStorageData('IMvGeneroArray');
export const setStorageMvGeneroArrayItem = async (id: number, IMvGenero : IMvGenero) : Promise<IMvGenero[]> => await setArrayItemStorageData('IMvGeneroArray', id, IMvGenero, [] as IMvGenero[]);
export const getStorageMvGeneroArrayItem = async (id: number) : Promise<IMvGenero> => await getArrayItemStorageData('IMvGeneroArray', id, getEmptyMvGenero);
export const removeStorageMvGeneroArrayItem = async (id: number) : Promise<IMvGenero[]> => await removeArrayItemStorageData('IMvGeneroArray', id, [] as IMvGenero[]);
