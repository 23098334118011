/**
 * Métodos AsyncThunk para ser usados en los reducers y
 * los resultados a las llamadas http puedan ser usados para
 * actualizar el state en redux
 */

import { createAsyncThunk } from "@reduxjs/toolkit";
import { DEFAULT_STATE } from "../../enums/default_state";
import { getMvEstadoCivil, getAllMvEstadoCivil, updateMvEstadoCivil, createMvEstadoCivil, deleteMvEstadoCivil } from "../../http/personas";
import { getEmptyStatusMvEstadoCivil, IMvEstadoCivil } from "../../interfaces/personas";
import { IServerDate } from "../../interfaces/time";

export const getMvEstadoCivilAsyncThunk = createAsyncThunk(
  'personas/getMvEstadoCivilAsyncThunk',
  async (query : {serverDate : IServerDate, id: number}) => await getMvEstadoCivil(query.serverDate, query.id)
    .then(async (result) => result)
    .catch(async (error) => getEmptyStatusMvEstadoCivil(DEFAULT_STATE.error))  
);

export const getAllMvEstadoCivilAsyncThunk = createAsyncThunk(
  'personas/getAllMvEstadoCivilAsyncThunk',
  async (queryAll : {serverDate : IServerDate}) => await getAllMvEstadoCivil(queryAll.serverDate)
    .then(async (result) => result)
    .catch(async (error) => getEmptyStatusMvEstadoCivil(DEFAULT_STATE.error))  
);

export const updateMvEstadoCivilAsyncThunk = createAsyncThunk(
  'personas/updateMvEstadoCivilAsyncThunk',
  async (update: {serverDate : IServerDate, id: number, MvEstadoCivil: IMvEstadoCivil}) => await updateMvEstadoCivil(update.serverDate, update.id, update.MvEstadoCivil)
    .then(async (result) => result)
    .catch(async (error) => getEmptyStatusMvEstadoCivil(DEFAULT_STATE.error))  
);

export const createMvEstadoCivilAsyncThunk = createAsyncThunk(
  'personas/createMvEstadoCivilAsyncThunk',
  async (create : {serverDate : IServerDate, MvEstadoCivil: IMvEstadoCivil}) => await createMvEstadoCivil(create.serverDate, create.MvEstadoCivil)
    .then(async (result) => result)
    .catch(async (error) => getEmptyStatusMvEstadoCivil(DEFAULT_STATE.error))  
);

export const deleteMvEstadoCivilAsyncThunk = createAsyncThunk(
  'personas/deleteMvEstadoCivilAsyncThunk',
  async (del : {id : number}) => await deleteMvEstadoCivil(del.id)
    .then(async (result) => result)
    .catch(async (error) => getEmptyStatusMvEstadoCivil())  
);

