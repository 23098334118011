/**
 * Métodos AsyncThunk para ser usados en los reducers y
 * los resultados a las llamadas http puedan ser usados para
 * actualizar el state en redux
 */

import { createAsyncThunk } from "@reduxjs/toolkit";
import { DEFAULT_STATE } from "../../enums/default_state";
import { getCambioEstatusVacaciones, getAllCambioEstatusVacaciones, updateCambioEstatusVacaciones, createCambioEstatusVacaciones, deleteCambioEstatusVacaciones } from "../../http/nomina";
import { getEmptyStatusCambioEstatusVacaciones, ICambioEstatusVacaciones } from "../../interfaces/nomina";
import { IServerDate } from "../../interfaces/time";

export const getCambioEstatusVacacionesAsyncThunk = createAsyncThunk(
  'nomina/getCambioEstatusVacacionesAsyncThunk',
  async (query : {serverDate : IServerDate, id: number, idCliente : number}) => await getCambioEstatusVacaciones(query.serverDate, query.id, query.idCliente)
    .then(async (result) => result)
    .catch(async (error) => getEmptyStatusCambioEstatusVacaciones(DEFAULT_STATE.error))  
);

export const getAllCambioEstatusVacacionesAsyncThunk = createAsyncThunk(
  'nomina/getAllCambioEstatusVacacionesAsyncThunk',
  async (queryAll : {serverDate : IServerDate, idCliente : number}) => await getAllCambioEstatusVacaciones(queryAll.serverDate, queryAll.idCliente)
    .then(async (result) => result)
    .catch(async (error) => getEmptyStatusCambioEstatusVacaciones(DEFAULT_STATE.error))  
);

export const updateCambioEstatusVacacionesAsyncThunk = createAsyncThunk(
  'nomina/updateCambioEstatusVacacionesAsyncThunk',
  async (update: {serverDate : IServerDate, id: number, idCliente : number, object: ICambioEstatusVacaciones}) => await updateCambioEstatusVacaciones(update.serverDate, update.id, update.idCliente, update.object)
    .then(async (result) => result)
    .catch(async (error) => getEmptyStatusCambioEstatusVacaciones(DEFAULT_STATE.error))  
);

export const createCambioEstatusVacacionesAsyncThunk = createAsyncThunk(
  'nomina/createCambioEstatusVacacionesAsyncThunk',
  async (create : {serverDate : IServerDate, idCliente : number, object: ICambioEstatusVacaciones}) => await createCambioEstatusVacaciones(create.serverDate, create.idCliente, create.object)
    .then(async (result) => result)
    .catch(async (error) => getEmptyStatusCambioEstatusVacaciones(DEFAULT_STATE.error))  
);

export const deleteCambioEstatusVacacionesAsyncThunk = createAsyncThunk(
  'nomina/deleteCambioEstatusVacacionesAsyncThunk',
  async (del : {id : number, idCliente : number}) => await deleteCambioEstatusVacaciones(del.id, del.idCliente)
    .then(async (result) => result)
    .catch(async (error) => getEmptyStatusCambioEstatusVacaciones())  
);
