/** 
 * Componente que se encarga del elemento MvImpuestos
 * @IMvImpuestos es la interfaz que contiene los parámetos el elemento MvImpuestos
 * @getEmptyMvImpuestos  devuelve un objeto con los parámetros por defecto; @param {id}, opcional cuando no encuentra el objeto en el LocalSotrage
 * @IStatusMvImpuestos es la interfaz para los estatus a llamadas a la API; @param {status}, opcional cuando queremos otro estado de inizialización de tipo [DEFAULT_STATE]
 * @getEmptyStatusMvImpuestos devuelve un objeto con los parámetros por defecto,
 * @setMvImpuestos devuelve un objeto de tipo [IMvImpuestos] recibiendo @param {object} y @param {serverDate}, para verificar que los datos estén en el formato correcto y ajustarlos a la zona horaria actual
 * @getSerializableMvImpuestos recibe un @param {IMvImpuestos}, para devolver un objeto serizalizable para las peticiones de la API
 * */

import { getDateString, isNotEmpty, SERVER_TIME_ZONE } from "../../api/helpers";
import { DEFAULT_STATE } from "../../enums/default_state";
import { getStoragePaisArrayItem } from "../../localStorage/personas";
import { convertDateToZonaHoraria, ILocalStorageExpiry, getEmptyLocalStorageExpiry, IServerDate } from "../time";
import { IPais, getEmptyPais, setPais } from "./IPais";

export interface IMvImpuestos {
  id : number,
  dato : string,
  porcentaje_impuesto : number, // Decimal
  fecha_elaboracion : string, // Fecha
  estatus_sistema : boolean,
  ultima_actualizacion : string, // Fecha
  pais : IPais,
}

export const getEmptyMvImpuestos = (id? : number) => {
  const temp : IMvImpuestos = 
  {
    id : id ? id : 0,
    dato : "",
    porcentaje_impuesto : 0,
    fecha_elaboracion : "",
    estatus_sistema : false,
    ultima_actualizacion : "",
    pais : getEmptyPais(),
  };
  return temp;
}

export interface IStatusMvImpuestos {
  estado : DEFAULT_STATE,
  mensaje : string,
  data : IMvImpuestos,
  data_array: IMvImpuestos[],
  expiry : ILocalStorageExpiry,
  expiry_array : ILocalStorageExpiry,
}

export const getEmptyStatusMvImpuestos = (status: DEFAULT_STATE = DEFAULT_STATE.loading) => {
  const temp : IStatusMvImpuestos = 
  {
    estado : status,
    mensaje : "",
    data : getEmptyMvImpuestos(),
    data_array : [],
    expiry : getEmptyLocalStorageExpiry("IMvImpuestos", 0),
    expiry_array : getEmptyLocalStorageExpiry("IMvImpuestosArray", 0),
  };
  return temp;
}

export const setMvImpuestos = async (
  object : any,
  serverDate : IServerDate,
) => {
  const temp = getEmptyMvImpuestos();
  if (isNotEmpty(object.id)) temp.id = object.id;
  if (isNotEmpty(object.dato)) temp.dato = object.dato;
  if (isNotEmpty(object.porcentaje_impuesto)) temp.porcentaje_impuesto = Number(object.porcentaje_impuesto);
  if (isNotEmpty(object.fecha_elaboracion)) temp.fecha_elaboracion = convertDateToZonaHoraria(serverDate, object.fecha_elaboracion);
  if (isNotEmpty(object.estatus_sistema)) temp.estatus_sistema = object.estatus_sistema;
  if (isNotEmpty(object.ultima_actualizacion)) temp.ultima_actualizacion = convertDateToZonaHoraria(serverDate, object.ultima_actualizacion);
  if (isNotEmpty(object.pais)) temp.pais = (typeof object.pais === 'number') ? await getStoragePaisArrayItem(object.pais) : await setPais(object.pais, serverDate);
  return temp;
}

export const getSerializableMvImpuestos = (IMvImpuestos : IMvImpuestos) => {
  return {
    id : IMvImpuestos.id,
    dato : IMvImpuestos.dato,
    porcentaje_impuesto : IMvImpuestos.porcentaje_impuesto.toFixed(2),
    fecha_elaboracion : IMvImpuestos.fecha_elaboracion,
    estatus_sistema : IMvImpuestos.estatus_sistema,
    ultima_actualizacion : getDateString(SERVER_TIME_ZONE),
    pais : IMvImpuestos.pais.id > 0 ? IMvImpuestos.pais.id : null,
  };
}
