/**
 * Métodos AsyncThunk para ser usados en los reducers y
 * los resultados a las llamadas http puedan ser usados para
 * actualizar el state en redux
 */

import { createAsyncThunk } from "@reduxjs/toolkit";
import { DEFAULT_STATE } from "../../enums/default_state";
import { getPais, getAllPais, updatePais, createPais, deletePais } from "../../http/personas";
import { getEmptyStatusPais, IPais } from "../../interfaces/personas";
import { IServerDate } from "../../interfaces/time";

export const getPaisAsyncThunk = createAsyncThunk(
  'personas/getPaisAsyncThunk',
  async (query : {serverDate : IServerDate, id: number}) => await getPais(query.serverDate, query.id)
    .then(async (result) => result)
    .catch(async (error) => getEmptyStatusPais(DEFAULT_STATE.error))  
);

export const getAllPaisAsyncThunk = createAsyncThunk(
  'personas/getAllPaisAsyncThunk',
  async (queryAll : {serverDate : IServerDate}) => await getAllPais(queryAll.serverDate)
    .then(async (result) => result)
    .catch(async (error) => getEmptyStatusPais(DEFAULT_STATE.error))  
);

export const updatePaisAsyncThunk = createAsyncThunk(
  'personas/updatePaisAsyncThunk',
  async (update: {serverDate : IServerDate, id: number, Pais: IPais}) => await updatePais(update.serverDate, update.id, update.Pais)
    .then(async (result) => result)
    .catch(async (error) => getEmptyStatusPais(DEFAULT_STATE.error))  
);

export const createPaisAsyncThunk = createAsyncThunk(
  'personas/createPaisAsyncThunk',
  async (create : {serverDate : IServerDate, Pais: IPais}) => await createPais(create.serverDate, create.Pais)
    .then(async (result) => result)
    .catch(async (error) => getEmptyStatusPais(DEFAULT_STATE.error))  
);

export const deletePaisAsyncThunk = createAsyncThunk(
  'personas/deletePaisAsyncThunk',
  async (del : {id : number}) => await deletePais(del.id)
    .then(async (result) => result)
    .catch(async (error) => getEmptyStatusPais())  
);

