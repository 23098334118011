/**
 * Métodos para el manejo de IonicStorage 
 * @setStorage              guarda un unico objeto al storage
 * @getStorage              obtiene el objeto guardado con @setStorage
 * @setStorageArray         guarda el array de objetos en el storage
 * @getStorageArray         obtiene el array de objetos guardado con @setStorageArray
 * @removeStorageArray      elimina el array de objetos guardado con @setStorageArray
 * @setStorageItemArray     agrega un elemento al array de objeto guardado con @setStorageArray
 * @getStorageItemArray     obtiene un elemento del array guardado con @setStorageArray buscandolo por su id 
 * @removeStorageItemArray  elimina un elemento del array guardado con @setStorageArray buscandolo por su id 
 */

import { setStorageData, getStorageData, removeStorageData, setArrayItemStorageData, getArrayItemStorageData, removeArrayItemStorageData } from "../../api/storage";
import { IHorario, getEmptyHorario } from "../../interfaces/nomina";

export const setStorageHorario = async (IHorario : IHorario) => await setStorageData('IHorario', IHorario);
export const getStorageHorario = async () : Promise<IHorario> => await getStorageData('IHorario', getEmptyHorario());
export const removeStorageHorario = async () => await removeStorageData('IHorario');
export const setStorageHorarioArray = async (IHorario : IHorario[]) => await setStorageData('IHorarioArray', IHorario);
export const getStorageHorarioArray = async () : Promise<IHorario[]> => await getStorageData('IHorarioArray', [] as IHorario[]);
export const removeStorageHorarioArray = async () => await removeStorageData('IHorarioArray');
export const setStorageHorarioArrayItem = async (id: number, IHorario : IHorario) : Promise<IHorario[]> => await setArrayItemStorageData('IHorarioArray', id, IHorario, [] as IHorario[]);
export const getStorageHorarioArrayItem = async (id: number) : Promise<IHorario> => await getArrayItemStorageData('IHorarioArray', id, getEmptyHorario);
export const removeStorageHorarioArrayItem = async (id: number) : Promise<IHorario[]> => await removeArrayItemStorageData('IHorarioArray', id, [] as IHorario[]);
