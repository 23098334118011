/**
 * Métodos para el manejo de IonicStorage 
 * @setStorage              guarda un unico objeto al storage
 * @getStorage              obtiene el objeto guardado con @setStorage
 * @setStorageArray         guarda el array de objetos en el storage
 * @getStorageArray         obtiene el array de objetos guardado con @setStorageArray
 * @removeStorageArray      elimina el array de objetos guardado con @setStorageArray
 * @setStorageItemArray     agrega un elemento al array de objeto guardado con @setStorageArray
 * @getStorageItemArray     obtiene un elemento del array guardado con @setStorageArray buscandolo por su id 
 * @removeStorageItemArray  elimina un elemento del array guardado con @setStorageArray buscandolo por su id 
 */

import { setStorageData, getStorageData, removeStorageData, setArrayItemStorageData, getArrayItemStorageData, removeArrayItemStorageData } from "../../api/storage";
import { IMvTipoSuscripcion, getEmptyMvTipoSuscripcion } from "../../interfaces/personas";

export const setStorageMvTipoSuscripcion = async (IMvTipoSuscripcion : IMvTipoSuscripcion) => await setStorageData('IMvTipoSuscripcion', IMvTipoSuscripcion);
export const getStorageMvTipoSuscripcion = async () : Promise<IMvTipoSuscripcion> => await getStorageData('IMvTipoSuscripcion', getEmptyMvTipoSuscripcion());
export const removeStorageMvTipoSuscripcion = async () => await removeStorageData('IMvTipoSuscripcion');
export const setStorageMvTipoSuscripcionArray = async (IMvTipoSuscripcion : IMvTipoSuscripcion[]) => await setStorageData('IMvTipoSuscripcionArray', IMvTipoSuscripcion);
export const getStorageMvTipoSuscripcionArray = async () : Promise<IMvTipoSuscripcion[]> => await getStorageData('IMvTipoSuscripcionArray', [] as IMvTipoSuscripcion[]);
export const removeStorageMvTipoSuscripcionArray = async () => await removeStorageData('IMvTipoSuscripcionArray');
export const setStorageMvTipoSuscripcionArrayItem = async (id: number, IMvTipoSuscripcion : IMvTipoSuscripcion) : Promise<IMvTipoSuscripcion[]> => await setArrayItemStorageData('IMvTipoSuscripcionArray', id, IMvTipoSuscripcion, [] as IMvTipoSuscripcion[]);
export const getStorageMvTipoSuscripcionArrayItem = async (id: number) : Promise<IMvTipoSuscripcion> => await getArrayItemStorageData('IMvTipoSuscripcionArray', id, getEmptyMvTipoSuscripcion);
export const removeStorageMvTipoSuscripcionArrayItem = async (id: number) : Promise<IMvTipoSuscripcion[]> => await removeArrayItemStorageData('IMvTipoSuscripcionArray', id, [] as IMvTipoSuscripcion[]);
