/**
 * Métodos AsyncThunk para ser usados en los reducers y
 * los resultados a las llamadas http puedan ser usados para
 * actualizar el state en redux
 */

import { createAsyncThunk } from "@reduxjs/toolkit";
import { DEFAULT_STATE } from "../../enums/default_state";
import {
  getSolicitudCambioTelefonoAutorizado,
  getAllSolicitudCambioTelefonoAutorizado,
  updateSolicitudCambioTelefonoAutorizado,
  createSolicitudCambioTelefonoAutorizado,
  deleteSolicitudCambioTelefonoAutorizado,
} from "../../http/personas";
import { updateTelefonoAutorizado } from "../../http/personas/SolicitudCambioTelefonoAutorizado";
import {
  getEmptyStatusSolicitudCambioTelefonoAutorizado,
  ISolicitudCambioTelefonoAutorizado,
} from "../../interfaces/personas";
import { getEmptyStatusUpdateTelefonoAutorizado } from "../../interfaces/personas/ISolicitudCambioTelefonoAutorizado";
import { IServerDate } from "../../interfaces/time";

export const getSolicitudCambioTelefonoAutorizadoAsyncThunk = createAsyncThunk(
  "personas/getSolicitudCambioTelefonoAutorizadoAsyncThunk",
  async (query: { serverDate: IServerDate; id: number; idCliente: number }) =>
    await getSolicitudCambioTelefonoAutorizado(
      query.serverDate,
      query.id,
      query.idCliente
    )
      .then(async (result) => result)
      .catch(async (error) =>
        getEmptyStatusSolicitudCambioTelefonoAutorizado(DEFAULT_STATE.error)
      )
);

export const getAllSolicitudCambioTelefonoAutorizadoAsyncThunk =
  createAsyncThunk(
    "personas/getAllSolicitudCambioTelefonoAutorizadoAsyncThunk",
    async (queryAll: { serverDate: IServerDate; idCliente: number }) =>
      await getAllSolicitudCambioTelefonoAutorizado(
        queryAll.serverDate,
        queryAll.idCliente
      )
        .then(async (result) => result)
        .catch(async (error) =>
          getEmptyStatusSolicitudCambioTelefonoAutorizado(DEFAULT_STATE.error)
        )
  );

export const updateSolicitudCambioTelefonoAutorizadoAsyncThunk =
  createAsyncThunk(
    "personas/updateSolicitudCambioTelefonoAutorizadoAsyncThunk",
    async (update: {
      serverDate: IServerDate;
      id: number;
      idCliente: number;
      object: ISolicitudCambioTelefonoAutorizado;
    }) =>
      await updateSolicitudCambioTelefonoAutorizado(
        update.serverDate,
        update.id,
        update.idCliente,
        update.object
      )
        .then(async (result) => result)
        .catch(async (error) =>
          getEmptyStatusSolicitudCambioTelefonoAutorizado(DEFAULT_STATE.error)
        )
  );

export const updateTelefonoAutorizadoAsyncThunk = createAsyncThunk(
  "personas/updateTelefonoAutorizadoAsyncThunk",
  async (update: { idEmpleado: number; idTelefono: string }) =>
    await updateTelefonoAutorizado(update.idEmpleado, update.idTelefono)
      .then(async (result) => result)
      .catch(async (error) =>
        getEmptyStatusUpdateTelefonoAutorizado(DEFAULT_STATE.error)
      )
);

export const createSolicitudCambioTelefonoAutorizadoAsyncThunk =
  createAsyncThunk(
    "personas/createSolicitudCambioTelefonoAutorizadoAsyncThunk",
    async (create: {
      serverDate: IServerDate;
      idCliente: number;
      object: ISolicitudCambioTelefonoAutorizado;
    }) =>
      await createSolicitudCambioTelefonoAutorizado(
        create.serverDate,
        create.idCliente,
        create.object
      )
        .then(async (result) => result)
        .catch(async (error) =>
          getEmptyStatusSolicitudCambioTelefonoAutorizado(DEFAULT_STATE.error)
        )
  );

export const deleteSolicitudCambioTelefonoAutorizadoAsyncThunk =
  createAsyncThunk(
    "personas/deleteSolicitudCambioTelefonoAutorizadoAsyncThunk",
    async (del: { id: number; idCliente: number }) =>
      await deleteSolicitudCambioTelefonoAutorizado(del.id, del.idCliente)
        .then(async (result) => result)
        .catch(async (error) =>
          getEmptyStatusSolicitudCambioTelefonoAutorizado()
        )
  );
