import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { List, setListItem } from "../../interfaces/helpers";
import { RootState } from "../../store";
import { getNombreEmpleado } from "../personasSlice";

export interface IVacations {
  selectedEmpresa: List;
  selectedEmpleado: List;
  selectedMvEstatusVacaciones: List;
}

export const vacationsInitialState: IVacations = {
  selectedEmpresa: { id: "", title: "Todas las empresas" },
  selectedEmpleado: { id: "", title: "Seleccione un empleado" },
  selectedMvEstatusVacaciones: { id: "", title: "Todos los estatus" },
};

export const vacationsSlice = createSlice({
  name: "vacations",
  initialState: vacationsInitialState,
  reducers: {
    setVacationsSelectedEmpresa: (state, action: PayloadAction<List>) => {
      state.selectedEmpresa = action.payload;
    },
    setVacationsSelectedEmpleado: (state, action: PayloadAction<List>) => {
      state.selectedEmpleado = action.payload;
    },
    setVacationsSelectedMvEstatusVacaciones: (
      state,
      action: PayloadAction<List>
    ) => {
      state.selectedMvEstatusVacaciones = action.payload;
    },
  },
});

export const {
  setVacationsSelectedEmpresa,
  setVacationsSelectedEmpleado,
  setVacationsSelectedMvEstatusVacaciones,
  // Modal
} = vacationsSlice.actions;

export default vacationsSlice.reducer;

export const getMemoizedVacationsActiveEmpleado = createSelector(
  (state: RootState) => state.vacations.selectedEmpresa,
  (state: RootState) => state.personas.personasState.empleado.data_array,
  (selectedEmpresa, empleados) => {
    if (selectedEmpresa.id === "") return empleados.filter((e) => e.activo);
    const empresa = Number(selectedEmpresa.id);
    return empleados.filter((e) => e.empresa.id === empresa && e.activo);
  }
);

export const getMemoizedVacationsActiveEmpleadoAsSelectList = createSelector(
  getMemoizedVacationsActiveEmpleado,
  (empleados) => {
    return empleados.map((e) =>
      setListItem({ id: e.id.toString(), title: getNombreEmpleado(e) })
    );
  }
);

export const getMemoizedVacationsByEmpresa = createSelector(
  getMemoizedVacationsActiveEmpleado,
  (state: RootState) => state.nomina.nominaState.vacaciones.data_array,
  (state: RootState) => state.vacations.selectedMvEstatusVacaciones,
  (empleados, vacaciones, selectedMvEstatusVacaciones) => {
    if (selectedMvEstatusVacaciones.id === "")
      return vacaciones.filter((e) =>
        empleados.some((v) => v.id === e.empleado.id)
      );
    return vacaciones.filter(
      (e) =>
        empleados.some((v) => v.id === e.empleado.id) &&
        e.estatus_vacaciones.id.toString() === selectedMvEstatusVacaciones.id
    );
  }
);
