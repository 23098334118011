/**
 * Métodos para el manejo de IonicStorage 
 * @setStorage              guarda un unico objeto al storage
 * @getStorage              obtiene el objeto guardado con @setStorage
 * @setStorageArray         guarda el array de objetos en el storage
 * @getStorageArray         obtiene el array de objetos guardado con @setStorageArray
 * @removeStorageArray      elimina el array de objetos guardado con @setStorageArray
 * @setStorageItemArray     agrega un elemento al array de objeto guardado con @setStorageArray
 * @getStorageItemArray     obtiene un elemento del array guardado con @setStorageArray buscandolo por su id 
 * @removeStorageItemArray  elimina un elemento del array guardado con @setStorageArray buscandolo por su id 
 */

import { setStorageData, getStorageData, removeStorageData, setArrayItemStorageData, getArrayItemStorageData, removeArrayItemStorageData } from "../../api/storage";
import { IMvAntiguedad, getEmptyMvAntiguedad } from "../../interfaces/personas";

export const setStorageMvAntiguedad = async (IMvAntiguedad : IMvAntiguedad) => await setStorageData('IMvAntiguedad', IMvAntiguedad);
export const getStorageMvAntiguedad = async () : Promise<IMvAntiguedad> => await getStorageData('IMvAntiguedad', getEmptyMvAntiguedad());
export const removeStorageMvAntiguedad = async () => await removeStorageData('IMvAntiguedad');
export const setStorageMvAntiguedadArray = async (IMvAntiguedad : IMvAntiguedad[]) => await setStorageData('IMvAntiguedadArray', IMvAntiguedad);
export const getStorageMvAntiguedadArray = async () : Promise<IMvAntiguedad[]> => await getStorageData('IMvAntiguedadArray', [] as IMvAntiguedad[]);
export const removeStorageMvAntiguedadArray = async () => await removeStorageData('IMvAntiguedadArray');
export const setStorageMvAntiguedadArrayItem = async (id: number, IMvAntiguedad : IMvAntiguedad) : Promise<IMvAntiguedad[]> => await setArrayItemStorageData('IMvAntiguedadArray', id, IMvAntiguedad, [] as IMvAntiguedad[]);
export const getStorageMvAntiguedadArrayItem = async (id: number) : Promise<IMvAntiguedad> => await getArrayItemStorageData('IMvAntiguedadArray', id, getEmptyMvAntiguedad);
export const removeStorageMvAntiguedadArrayItem = async (id: number) : Promise<IMvAntiguedad[]> => await removeArrayItemStorageData('IMvAntiguedadArray', id, [] as IMvAntiguedad[]);
