/**
 * Métodos del, get, post, put para llamadas a la API
 * @get     en este método primero consulta con @getFromLocalStorage si es necesario hacer la petición http sino se obtiene del LocalStorage;
 *          si hay un error en la petición http se busca el objeto en el LocalStorage sino lo encuentra devuelve un objeto vacío; si la
 *          petición se realiza correctamente actualiza el valor individual y en el array del objeto en el LocalStorage y devuelve el objeto
 *          obtenido del servidor.
 * @getAll  en este método primero consulta con @getFromLocalStorage si es necesario hacer la petición http sino se obtiene del LocalStorage;
 *          si hay un error en la petición http se busca el objeto array en el LocalStorage sino lo encuentra devuelve un array vacío; si la
 *          petición se realiza correctamente actualiza array en el LocalStorage y devuelve el array obtenido del servidor
 * @update  en este método después de realizar la petición http si falla devuelve el objeto que se iba a actualizar; si la petición se
 *          realiza correctamente actualiza el valor individual y en el array del objeto en el LocalStorage y devuelve el objeto obtenido del
 *          servidor.
 * @create  en este método después de realizar la petición http si falla devuelve el objeto que se iba a crear; si la petición se realiza
 *          correctamente actualiza el valor individual y en el array del objeto en el LocalStorage y devuelve el objeto obtenido del
 *          servidor.
 * @delete  en este método después de realizar la petición http si falla devuelve el objeto que se iba a eliminar; si la petición se realiza
 *          correctamente elimina el valor individual y en el objeto en el arrya del LocalStorage y devuelve un objeto vacio (Default)
 */

import { del, get, post, put } from "../../api/api";
import {
  getFromLocalStorage,
  updateLocalStorageExpiry,
} from "../../api/helpers";
import { removeArrayItemStorageData } from "../../api/storage";
import { DEFAULT_STATE } from "../../enums/default_state";
import {
  getEmptyStatusHorasExtra,
  setHorasExtra,
  IHorasExtra,
  getSerializableHorasExtra,
} from "../../interfaces/nomina";
import { IServerDate } from "../../interfaces/time";
import {
  getStorageHorasExtraArrayItem,
  setStorageHorasExtra,
  setStorageHorasExtraArrayItem,
  getStorageHorasExtraArray,
  setStorageHorasExtraArray,
  removeStorageHorasExtra,
} from "../../localStorage/nomina";

export const getHorasExtra = async (
  serverDate: IServerDate,
  pk: number,
  idCliente: number
) => {
  const temp = getEmptyStatusHorasExtra();
  const doHttp = await getFromLocalStorage(serverDate, temp.expiry);
  temp.expiry = doHttp.storage;
  if (!doHttp.result) {
    console.log(`(localStorage) HorasExtra con pk: ${pk}, obtenido con éxito`);
    temp.estado = DEFAULT_STATE.success;
    temp.mensaje = `HorasExtra obtenida con éxito`;
    temp.data = await getStorageHorasExtraArrayItem(pk);
    return temp;
  }
  const result = await get(`nomina/horas-extra/${pk}/${idCliente}`);
  const data = result.data;
  console.log(`HorasExtra data: `, data);
  if (!data.id || data.detail) {
    console.log(`Error al obtener HorasExtra con pk: ${pk}, ${data.detail}`);
    temp.estado = DEFAULT_STATE.error;
    temp.mensaje = `Error al obtener la datos personales: ${
      data.detail ?? "Hubo un problema al conectarse con el servidor"
    }`;
    temp.data = await getStorageHorasExtraArrayItem(pk);
    return temp;
  }
  console.log(`HorasExtra con pk: ${pk}, obtenido con éxito`);
  temp.estado = DEFAULT_STATE.success;
  temp.mensaje = `HorasExtra obtenida con éxito`;
  temp.data = await setHorasExtra(data, serverDate);
  await setStorageHorasExtra(temp.data);
  temp.data_array = await setStorageHorasExtraArrayItem(pk, temp.data);
  temp.expiry = await updateLocalStorageExpiry(serverDate, temp.expiry);
  return temp;
};

export const getAllHorasExtra = async (
  serverDate: IServerDate,
  idCliente: number
) => {
  const temp = getEmptyStatusHorasExtra();
  const doHttp = await getFromLocalStorage(serverDate, temp.expiry_array);
  temp.expiry_array = doHttp.storage;
  if (!doHttp.result) {
    console.log(`(localStorage) Todos las HorasExtras obtenidas con éxito`);
    temp.estado = DEFAULT_STATE.success;
    temp.mensaje = `Todos las HorasExtras obtenidas con éxito`;
    temp.data_array = await getStorageHorasExtraArray();
    // return temp;
  }
  const result = await get(`nomina/horas-extra/${idCliente}`);
  const data = result.data;
  if (data.detail) {
    console.log(`Error al obtener la lista de HorasExtra`, data);
    temp.estado = DEFAULT_STATE.error;
    temp.mensaje = `Error al obtener la lista de HorasExtras: ${
      data.detail ?? "Hubo un problema al conectarse con el servidor"
    }`;
    temp.data_array = await getStorageHorasExtraArray();
    return temp;
  }
  console.log(`Todos las HorasExtras obtenidos con éxito`);
  temp.estado = DEFAULT_STATE.success;
  temp.mensaje = `Todos las HorasExtras obtenidas con éxito`;
  temp.data_array = await Promise.all(
    data.map(async (e: any) => await setHorasExtra(e, serverDate))
  );
  await setStorageHorasExtraArray(temp.data_array);
  temp.expiry_array = await updateLocalStorageExpiry(
    serverDate,
    temp.expiry_array
  );
  return temp;
};

export const updateHorasExtra = async (
  serverDate: IServerDate,
  pk: number,
  idCliente: number,
  object: IHorasExtra
) => {
  const temp = getEmptyStatusHorasExtra();
  const result = await put(
    `nomina/horas-extra/${pk}/${idCliente}`,
    getSerializableHorasExtra(object)
  );
  const data = result.data;
  if (!data.id || data.detail) {
    console.log(`Error al actualizar HorasExtra con pk: ${pk}, ${data.detail}`);
    temp.estado = DEFAULT_STATE.error;
    temp.mensaje = `Error al actualizar la datos personales: ${
      data.detail ?? "Hubo un problema al conectarse con el servidor"
    }`;
    temp.data = await setHorasExtra(object, serverDate);
    return temp;
  }
  console.log(`HorasExtra con pk: ${pk}, actualizado con éxito`);
  temp.estado = DEFAULT_STATE.success;
  temp.mensaje = `HorasExtra actualizada con éxito`;
  temp.data = await setHorasExtra(data, serverDate);
  await setStorageHorasExtra(temp.data);
  temp.data_array = await setStorageHorasExtraArrayItem(
    temp.data.id,
    temp.data
  );
  return temp;
};

export const createHorasExtra = async (
  serverDate: IServerDate,
  idCliente: number,
  object: IHorasExtra
) => {
  const temp = getEmptyStatusHorasExtra();
  const result = await post(
    `nomina/horas-extra/${idCliente}`,
    getSerializableHorasExtra(object)
  );
  const data = result.data;
  if (!data.id || data.detail) {
    console.log(
      `Error al crear HorasExtra con los datos ${object}, ${data.detail}`
    );
    temp.estado = DEFAULT_STATE.error;
    temp.mensaje = `Error al crear la datos personales: ${
      data.detail ?? "Hubo un problema al conectarse con el servidor"
    }`;
    temp.data = await setHorasExtra(object, serverDate);
    return temp;
  }
  console.log(`HorasExtra creada con éxito`);
  temp.estado = DEFAULT_STATE.success;
  temp.mensaje = `HorasExtra creada con éxito`;
  temp.data = await setHorasExtra(data, serverDate);
  await setStorageHorasExtra(temp.data);
  temp.data_array = await setStorageHorasExtraArrayItem(
    temp.data.id,
    temp.data
  );
  return temp;
};

export const deleteHorasExtra = async (pk: number, idCliente: number) => {
  const temp = getEmptyStatusHorasExtra();
  const result = await del(`nomina/horas-extra/${pk}/${idCliente}`);
  const data = result.data;
  if (data.detail) {
    console.log(`Error al eliminar HorasExtra con pk: ${pk}, ${data.detail}`);
    temp.estado = DEFAULT_STATE.error;
    temp.mensaje = `Error al eliminar la datos personales: ${data.detail}`;
    temp.data = await getStorageHorasExtraArrayItem(pk);
    temp.data_array = await getStorageHorasExtraArray();
    return temp;
  }
  console.log(`HorasExtra eliminada con éxito`);
  temp.estado = DEFAULT_STATE.success;
  temp.mensaje = `HorasExtra eliminada con éxito`;
  await removeStorageHorasExtra();
  temp.data_array = await removeArrayItemStorageData("IHorasExtra", pk, []);
  return temp;
};
