/**
 * Métodos AsyncThunk para ser usados en los reducers y
 * los resultados a las llamadas http puedan ser usados para
 * actualizar el state en redux
 */

import { createAsyncThunk } from "@reduxjs/toolkit";
import { DEFAULT_STATE } from "../../enums/default_state";
import { getEstado, getAllEstado, updateEstado, createEstado, deleteEstado } from "../../http/personas";
import { getEmptyStatusEstado, IEstado } from "../../interfaces/personas";
import { IServerDate } from "../../interfaces/time";

export const getEstadoAsyncThunk = createAsyncThunk(
  'personas/getEstadoAsyncThunk',
  async (query : {serverDate : IServerDate, id: number}) => await getEstado(query.serverDate, query.id)
    .then(async (result) => result)
    .catch(async (error) => getEmptyStatusEstado(DEFAULT_STATE.error))  
);

export const getAllEstadoAsyncThunk = createAsyncThunk(
  'personas/getAllEstadoAsyncThunk',
  async (queryAll : {serverDate : IServerDate}) => await getAllEstado(queryAll.serverDate)
    .then(async (result) => result)
    .catch(async (error) => getEmptyStatusEstado(DEFAULT_STATE.error))  
);

export const updateEstadoAsyncThunk = createAsyncThunk(
  'personas/updateEstadoAsyncThunk',
  async (update: {serverDate : IServerDate, id: number, Estado: IEstado}) => await updateEstado(update.serverDate, update.id, update.Estado)
    .then(async (result) => result)
    .catch(async (error) => getEmptyStatusEstado(DEFAULT_STATE.error))  
);

export const createEstadoAsyncThunk = createAsyncThunk(
  'personas/createEstadoAsyncThunk',
  async (create : {serverDate : IServerDate, Estado: IEstado}) => await createEstado(create.serverDate, create.Estado)
    .then(async (result) => result)
    .catch(async (error) => getEmptyStatusEstado(DEFAULT_STATE.error))  
);

export const deleteEstadoAsyncThunk = createAsyncThunk(
  'personas/deleteEstadoAsyncThunk',
  async (del : {id : number}) => await deleteEstado(del.id)
    .then(async (result) => result)
    .catch(async (error) => getEmptyStatusEstado())  
);
