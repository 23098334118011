import { getArrayItemStorageData, getStorageData, removeArrayItemStorageData, removeStorageData, setArrayItemStorageData, setStorageData } from "../../api/storage";
import { getEmptyCodigoPostal, ICodigoPostal } from "../../interfaces/personas";

export const setStorageCodigoPostal = async (ICodigoPostal : ICodigoPostal) => await setStorageData('ICodigoPostal', ICodigoPostal);
export const getStorageCodigoPostal = async () : Promise<ICodigoPostal> => await getStorageData('ICodigoPostal', getEmptyCodigoPostal());
export const removeStorageCodigoPostal = async () => await removeStorageData('ICodigoPostal');
export const setStorageCodigoPostalArray = async (ICodigoPostal : ICodigoPostal[]) => await setStorageData('ICodigoPostalArray', ICodigoPostal);
export const getStorageCodigoPostalArray = async () : Promise<ICodigoPostal[]> => await getStorageData('ICodigoPostalArray', [] as ICodigoPostal[]);
export const removeStorageCodigoPostalArray = async () => await removeStorageData('ICodigoPostalArray');
export const setStorageCodigoPostalArrayItem = async (id: number, ICodigoPostal : ICodigoPostal) : Promise<ICodigoPostal[]> => await setArrayItemStorageData('ICodigoPostalArray', id, ICodigoPostal, [] as ICodigoPostal[]);
export const getStorageCodigoPostalArrayItem = async (id: number) : Promise<ICodigoPostal> => await getArrayItemStorageData('ICodigoPostalArray', id, getEmptyCodigoPostal);
export const removeStorageCodigoPostalArrayItem = async (id: number) : Promise<ICodigoPostal[]> => await removeArrayItemStorageData('ICodigoPostalArray', id, [] as ICodigoPostal[]);
