/**
 * Métodos del, get, post, put para llamadas a la API
 * @get     en este método primero consulta con @getFromLocalStorage si es necesario hacer la petición http sino se obtiene del LocalStorage;
 *          si hay un error en la petición http se busca el objeto en el LocalStorage sino lo encuentra devuelve un objeto vacío; si la
 *          petición se realiza correctamente actualiza el valor individual y en el array del objeto en el LocalStorage y devuelve el objeto
 *          obtenido del servidor.
 * @getAll  en este método primero consulta con @getFromLocalStorage si es necesario hacer la petición http sino se obtiene del LocalStorage;
 *          si hay un error en la petición http se busca el objeto array en el LocalStorage sino lo encuentra devuelve un array vacío; si la
 *          petición se realiza correctamente actualiza array en el LocalStorage y devuelve el array obtenido del servidor
 * @update  en este método después de realizar la petición http si falla devuelve el objeto que se iba a actualizar; si la petición se
 *          realiza correctamente actualiza el valor individual y en el array del objeto en el LocalStorage y devuelve el objeto obtenido del
 *          servidor.
 * @create  en este método después de realizar la petición http si falla devuelve el objeto que se iba a crear; si la petición se realiza
 *          correctamente actualiza el valor individual y en el array del objeto en el LocalStorage y devuelve el objeto obtenido del
 *          servidor.
 * @delete  en este método después de realizar la petición http si falla devuelve el objeto que se iba a eliminar; si la petición se realiza
 *          correctamente elimina el valor individual y en el objeto en el arrya del LocalStorage y devuelve un objeto vacio (Default)
 */

import { del, get, post, put } from "../../api/api";
import {
  getFromLocalStorage,
  updateLocalStorageExpiry,
} from "../../api/helpers";
import { removeArrayItemStorageData } from "../../api/storage";
import { DEFAULT_STATE } from "../../enums/default_state";
import {
  getEmptyStatusAsistencia,
  setAsistencia,
  IAsistencia,
  getSerializableAsistencia,
} from "../../interfaces/nomina";
import { IServerDate } from "../../interfaces/time";
import {
  getStorageAsistenciaArrayItem,
  setStorageAsistencia,
  setStorageAsistenciaArrayItem,
  getStorageAsistenciaArray,
  setStorageAsistenciaArray,
  removeStorageAsistencia,
} from "../../localStorage/nomina";

export const getAsistencia = async (
  serverDate: IServerDate,
  pk: number,
  idCliente: number
) => {
  const temp = getEmptyStatusAsistencia();
  const doHttp = await getFromLocalStorage(serverDate, temp.expiry);
  temp.expiry = doHttp.storage;
  if (!doHttp.result) {
    console.log(`(localStorage) Asistencia con pk: ${pk}, obtenido con éxito`);
    temp.estado = DEFAULT_STATE.success;
    temp.mensaje = `Asistencia obtenida con éxito`;
    temp.data = await getStorageAsistenciaArrayItem(pk);
    return temp;
  }
  const result = await get(`nomina/asistencia-lista/${pk}/${idCliente}`);
  const data = result.data;
  console.log(`Asistencia data: `, data);
  if (!data.id || data.detail) {
    console.log(`Error al obtener Asistencia con pk: ${pk}, ${data.detail}`);
    temp.estado = DEFAULT_STATE.error;
    temp.mensaje = `Error al obtener la datos personales: ${
      data.detail ?? "Hubo un problema al conectarse con el servidor"
    }`;
    temp.data = await getStorageAsistenciaArrayItem(pk);
    return temp;
  }
  console.log(`Asistencia con pk: ${pk}, obtenido con éxito`);
  temp.estado = DEFAULT_STATE.success;
  temp.mensaje = `Asistencia obtenida con éxito`;
  temp.data = await setAsistencia(data, serverDate);
  await setStorageAsistencia(temp.data);
  temp.data_array = await setStorageAsistenciaArrayItem(pk, temp.data);
  temp.expiry = await updateLocalStorageExpiry(serverDate, temp.expiry);
  return temp;
};

export const getAllAsistencia = async (
  serverDate: IServerDate,
  idCliente: number
) => {
  const temp = getEmptyStatusAsistencia();
  const doHttp = await getFromLocalStorage(serverDate, temp.expiry_array);
  temp.expiry_array = doHttp.storage;
  if (!doHttp.result) {
    console.log(`(localStorage) Todos las Asistencias obtenidas con éxito`);
    temp.estado = DEFAULT_STATE.success;
    temp.mensaje = `Todos las Asistencias obtenidas con éxito`;
    temp.data_array = await getStorageAsistenciaArray();
    // return temp;
  }
  const result = await get(`nomina/asistencia-lista/${idCliente}`);
  const data = result.data;
  if (data.detail) {
    console.log(`Error al obtener la lista de Asistencia`, data);
    temp.estado = DEFAULT_STATE.error;
    temp.mensaje = `Error al obtener la lista de Asistencias: ${
      data.detail ?? "Hubo un problema al conectarse con el servidor"
    }`;
    temp.data_array = await getStorageAsistenciaArray();
    return temp;
  }
  console.log(`Todos las Asistencias obtenidos con éxito`);
  temp.estado = DEFAULT_STATE.success;
  temp.mensaje = `Todos las Asistencias obtenidas con éxito`;
  temp.data_array = await Promise.all(
    data.map(async (e: any) => await setAsistencia(e, serverDate))
  );
  await setStorageAsistenciaArray(temp.data_array);
  temp.expiry_array = await updateLocalStorageExpiry(
    serverDate,
    temp.expiry_array
  );
  return temp;
};

export const updateAsistencia = async (
  serverDate: IServerDate,
  pk: number,
  idCliente: number,
  object: IAsistencia
) => {
  const temp = getEmptyStatusAsistencia();
  const result = await put(
    `nomina/asistencia-lista/${pk}/${idCliente}`,
    getSerializableAsistencia(object)
  );
  const data = result.data;
  if (!data.id || data.detail) {
    console.log(`Error al actualizar Asistencia con pk: ${pk}, ${data.detail}`);
    temp.estado = DEFAULT_STATE.error;
    temp.mensaje = `Error al actualizar la datos personales: ${
      data.detail ?? "Hubo un problema al conectarse con el servidor"
    }`;
    temp.data = await setAsistencia(object, serverDate);
    return temp;
  }
  console.log(`Asistencia con pk: ${pk}, actualizado con éxito`);
  temp.estado = DEFAULT_STATE.success;
  temp.mensaje = `Asistencia actualizada con éxito`;
  temp.data = await setAsistencia(data, serverDate);
  await setStorageAsistencia(temp.data);
  temp.data_array = await setStorageAsistenciaArrayItem(
    temp.data.id,
    temp.data
  );
  return temp;
};

export const createAsistencia = async (
  serverDate: IServerDate,
  idCliente: number,
  object: IAsistencia
) => {
  const temp = getEmptyStatusAsistencia();
  const result = await post(
    `nomina/asistencia-lista/${idCliente}`,
    getSerializableAsistencia(object)
  );
  const data = result.data;
  if (!data.id || data.detail) {
    console.log(
      `Error al crear Asistencia con los datos ${object}, ${data.detail}`
    );
    temp.estado = DEFAULT_STATE.error;
    temp.mensaje = `Error al crear la datos personales: ${
      data.detail ?? "Hubo un problema al conectarse con el servidor"
    }`;
    temp.data = await setAsistencia(object, serverDate);
    return temp;
  }
  console.log(`Asistencia creada con éxito`);
  temp.estado = DEFAULT_STATE.success;
  temp.mensaje = `Asistencia creada con éxito`;
  temp.data = await setAsistencia(data, serverDate);
  await setStorageAsistencia(temp.data);
  temp.data_array = await setStorageAsistenciaArrayItem(
    temp.data.id,
    temp.data
  );
  return temp;
};

export const deleteAsistencia = async (pk: number, idCliente: number) => {
  const temp = getEmptyStatusAsistencia();
  const result = await del(`nomina/asistencia-lista/${pk}/${idCliente}`);
  const data = result.data;
  if (data.detail) {
    console.log(`Error al eliminar Asistencia con pk: ${pk}, ${data.detail}`);
    temp.estado = DEFAULT_STATE.error;
    temp.mensaje = `Error al eliminar la datos personales: ${data.detail}`;
    temp.data = await getStorageAsistenciaArrayItem(pk);
    temp.data_array = await getStorageAsistenciaArray();
    return temp;
  }
  console.log(`Asistencia eliminada con éxito`);
  temp.estado = DEFAULT_STATE.success;
  temp.mensaje = `Asistencia eliminada con éxito`;
  await removeStorageAsistencia();
  temp.data_array = await removeArrayItemStorageData("IAsistencia", pk, []);
  return temp;
};
