import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { List, MakerData, setListItem } from "../../interfaces/helpers";
import { getEmptyCentroDeTrabajo, getEmptyCodigoQr, ICentroDeTrabajo, ICodigoQr } from "../../interfaces/personas";
import { RootState } from "../../store";
import { setMakerDataItem } from "../personasSlice";

export interface IGeolocalizacionData  {  
  longitud : string, // Not Null
  latitud : string, // Not Null
  descripcion_geolocalizacion : string,
}

export interface IDireccionData {
  numero_contacto : string, // Not Null
  id_cp: number, // Not Null
  calle : string, // Not Null
  numero_exterior : string, // Not Null
  numero_interior : string,
  colonia : string, // Not Null
  ciudad : string, // Not Null
  delegacion_municipio : string,
  estado : string,
  id_estado: number,
  pais : string,
  id_pais : number,
}
export interface ICodigoQrData {
  nombre_codigo: string, // Not Null
  clave_codigo: string, // Not Null
  url_imagen_codigo: string, // Not Null
}


export interface ICreateCentroDeTrabajoData extends IGeolocalizacionData, IDireccionData, ICodigoQrData {
  id_cliente: number, // Not Null
  id_empresa: number, // Not Null
  id_autor: number, // Not Null
  id_centro: number,
  nombre_centro: string; // Not Null
  distancia_maxima_permitida: number; // Not Null
  numero_empleados_asignados: number; // Not Null
  centro_trabajo_predeterminado: boolean; // Not Null
  blob: Blob;
}

export interface ICreateCentroDeTrabajo {
  codigos_qr: ICreateCentroDeTrabajoData[],
};

export interface IWorkCenters {
  selectedEmpresa: List;
  makerList: MakerData[];
  centroDeTrabajoList: List[];
  activeTable: {
    loading: boolean;
    remove: boolean;
    searchText: string;
    page: number;
    maxPerPage: number;
    selectedCentrosDeTrabajo: List[];
    selectAll: boolean;
    isIndeterminate: boolean;
  },
  inactiveTable: {
    loading: boolean;
    activate: boolean;
    remove: boolean;
    searchText: string;
    page: number;
    maxPerPage: number;
    selectedCentrosDeTrabajo: List[];
    selectAll: boolean;
    isIndeterminate: boolean;
  },
  centroDeTrabajoCreate: ICreateCentroDeTrabajoData | null,
  centroDeTrabajoModal: ICodigoQr,
  claveCodigoModal: string,
  placeModal: {lat: number, lng: number}[],
}

export const workCentersInitialState : IWorkCenters = {
  selectedEmpresa: {id: "", title: "Todas las empresas"},
  makerList: [],
  centroDeTrabajoList: [],
  activeTable: {
    loading: false,
    remove: false,
    searchText: "",
    page: 1,
    maxPerPage: 10,
    selectedCentrosDeTrabajo: [],
    selectAll: false,
    isIndeterminate: false,
  },
  inactiveTable: {
    loading: false,
    activate: false,
    remove: false,
    searchText: "",
    page: 1,
    maxPerPage: 10,
    selectedCentrosDeTrabajo: [],
    selectAll: false,
    isIndeterminate: false,
  },
  centroDeTrabajoCreate: null,
  centroDeTrabajoModal: getEmptyCodigoQr(),
  claveCodigoModal: "",
  placeModal: [],
}

export const workCentersSlice = createSlice({
  name: 'workCenters',
  initialState: workCentersInitialState,
  reducers: {
    setWorkCentersSelectedEmpresa: (state, action: PayloadAction<List>) => {
      state.selectedEmpresa = action.payload;
    },
    // CentroDeTrabajos Modal
    setWorkCentersCentroDeTrabajoModal: (state, action: PayloadAction<ICodigoQr>) => {
      if (state.centroDeTrabajoModal.id !== action.payload.id) state.centroDeTrabajoModal = action.payload;
    },
    setWorkCentersClaveCodigoModal: (state, action: PayloadAction<string>) => {
      state.claveCodigoModal = action.payload;
    },
    setWorkCentersPlaceModal: (state, action: PayloadAction<{lat: number, lng: number}[]>) => {
      state.placeModal = action.payload;
    },

    // Active CentroDeTrabajos Table
    setWorkCentersActiveLoading: (state, action: PayloadAction<boolean>) => {
      if ( action.payload !== state.activeTable.loading) state.activeTable.loading = action.payload;
    },
    setWorkCentersActiveSelectAllStatus: (state, action: PayloadAction<boolean>) => {
      state.activeTable.selectAll = action.payload;
    },
    setWorkCentersActiveRemoveStatus: (state, action: PayloadAction<boolean>) => {
      state.activeTable.remove = action.payload;
      state.activeTable.selectedCentrosDeTrabajo = [];
    },
    addWorkCentersActiveSelectedCentroDeTrabajo: (state, action: PayloadAction<{item: List, activeSearchResult: ICentroDeTrabajo[]}>) => {
      if (state.activeTable.selectedCentrosDeTrabajo.length < 1) state.activeTable.selectedCentrosDeTrabajo.push(action.payload.item);
      else {
        const index = state.activeTable.selectedCentrosDeTrabajo.findIndex((e) => e.id === action.payload.item.id);
        if (index === -1) state.activeTable.selectedCentrosDeTrabajo.push(action.payload.item);
      }
      const hasAll = action.payload.activeSearchResult.every((e) => state.activeTable.selectedCentrosDeTrabajo.findIndex((v) => v.id === e.id.toString()) > -1);
      if (state.activeTable.selectedCentrosDeTrabajo.length > 0 && !hasAll) state.activeTable.isIndeterminate = true;
      else if(hasAll){
        state.activeTable.isIndeterminate = false;
        state.activeTable.selectAll = true;
      }
    },
    removeWorkCentersActiveSelectedCentroDeTrabajo: (state, action: PayloadAction<List>) => {
      if (state.activeTable.selectedCentrosDeTrabajo.length < 1) return;
      const index = state.activeTable.selectedCentrosDeTrabajo.findIndex((e) => e.id === action.payload.id);
      if (index !== -1) state.activeTable.selectedCentrosDeTrabajo.splice(index, 1);      
      if (state.activeTable.selectedCentrosDeTrabajo.length > 0) state.activeTable.isIndeterminate = true;
      else {
        state.activeTable.isIndeterminate = false;
        state.activeTable.selectAll = false;
      }
    },
    addAllWorkCentersActiveSelectedCentroDeTrabajo: (state, action: PayloadAction<{item: List[], activeSearchResult: ICentroDeTrabajo[], searchValue: string}>) => {
      const hasAll = action.payload.activeSearchResult.every((e) => state.activeTable.selectedCentrosDeTrabajo.findIndex((v) => v.id === e.id.toString()) > -1);
      state.activeTable.selectAll = hasAll;
      if (hasAll) {
        state.activeTable.isIndeterminate = false;
      }
      else if(state.activeTable.selectedCentrosDeTrabajo.length > 0) {
        state.activeTable.isIndeterminate = true;
      } else {
        state.activeTable.isIndeterminate = false;
      }
      if (action.payload.searchValue !== state.activeTable.searchText) return;
      if (action.payload.item.length === 0) return;
      if (state.activeTable.selectedCentrosDeTrabajo.length < 1) {
        state.activeTable.selectedCentrosDeTrabajo = action.payload.item;
      } else {
        action.payload.item.forEach((e) => {
          const index = state.activeTable.selectedCentrosDeTrabajo.findIndex((v) => v.id === e.id);
          if (index === -1) state.activeTable.selectedCentrosDeTrabajo.push(e);
        });
      }
    },
    removeAllWorkCentersActiveSelectedCentroDeTrabajo: (state) => {
      state.activeTable.selectAll = false;
      state.activeTable.isIndeterminate = false;
      state.activeTable.selectedCentrosDeTrabajo = [];
    },
    setWorkCentersActiveSearchValue: (state, action: PayloadAction<string>) => {
      state.activeTable.searchText = action.payload;
    },
    resetWorkCentersActivePage: (state) => {
      if (!state.activeTable.loading) state.activeTable.loading = true;
      state.activeTable.page = 1;
    },
    activeWorkCentersNextPage: (state, action: PayloadAction<number>) => {      
      if (state.activeTable.page < action.payload) state.activeTable.page++;
    },
    activeWorkCentersPreviousPage: (state) => {
      if (state.activeTable.page > 1) state.activeTable.page--;
    },
    // Inactive CentroDeTrabajos Table
    setWorkCentersInactiveLoading: (state, action: PayloadAction<boolean>) => {
      if ( action.payload !== state.inactiveTable.loading) state.inactiveTable.loading = action.payload;
    },
    setWorkCentersInactiveSelectAllStatus: (state, action: PayloadAction<boolean>) => {
      state.inactiveTable.selectAll = action.payload;
    },
    setWorkCentersInactiveActivateStatus: (state, action: PayloadAction<boolean>) => {
      state.inactiveTable.activate = action.payload;
      state.inactiveTable.remove = false;
      state.inactiveTable.selectedCentrosDeTrabajo = [];
    },
    setWorkCentersInactiveRemoveStatus: (state, action: PayloadAction<boolean>) => {
      state.inactiveTable.activate = false;
      state.inactiveTable.remove = action.payload;
      state.inactiveTable.selectedCentrosDeTrabajo = [];
    },
    addWorkCentersInactiveSelectedCentroDeTrabajo: (state, action: PayloadAction<{item: List, inactiveSearchResult: ICentroDeTrabajo[]}>) => {
      if (state.inactiveTable.selectedCentrosDeTrabajo.length < 1) state.inactiveTable.selectedCentrosDeTrabajo.push(action.payload.item);
      else {
        const index = state.inactiveTable.selectedCentrosDeTrabajo.findIndex((e) => e.id === action.payload.item.id);
        if (index === -1) state.inactiveTable.selectedCentrosDeTrabajo.push(action.payload.item);
      }
      const hasAll = action.payload.inactiveSearchResult.every((e) => state.inactiveTable.selectedCentrosDeTrabajo.findIndex((v) => v.id === e.id.toString()) > -1);
      if (state.inactiveTable.selectedCentrosDeTrabajo.length > 0 && !hasAll) state.inactiveTable.isIndeterminate = true;
      else if(hasAll){
        state.inactiveTable.isIndeterminate = false;
        state.inactiveTable.selectAll = true;
      }
    },
    removeWorkCentersInactiveSelectedCentroDeTrabajo: (state, action: PayloadAction<List>) => {
      if (state.inactiveTable.selectedCentrosDeTrabajo.length < 1) return;
      const index = state.inactiveTable.selectedCentrosDeTrabajo.findIndex((e) => e.id === action.payload.id);
      if (index !== -1) state.inactiveTable.selectedCentrosDeTrabajo.splice(index, 1);      
      if (state.inactiveTable.selectedCentrosDeTrabajo.length > 0) state.inactiveTable.isIndeterminate = true;
      else {
        state.inactiveTable.isIndeterminate = false;
        state.inactiveTable.selectAll = false;
      }
    },
    addAllWorkCentersInactiveSelectedCentroDeTrabajo: (state, action: PayloadAction<{item: List[], inactiveSearchResult: ICentroDeTrabajo[], searchValue: string}>) => {
      const hasAll = action.payload.inactiveSearchResult.every((e) => state.inactiveTable.selectedCentrosDeTrabajo.findIndex((v) => v.id === e.id.toString()) > -1);
      state.inactiveTable.selectAll = hasAll;
      if (hasAll) {
        state.inactiveTable.isIndeterminate = false;
      }
      else if(state.inactiveTable.selectedCentrosDeTrabajo.length > 0) {
        state.inactiveTable.isIndeterminate = true;
      } else {
        state.inactiveTable.isIndeterminate = false;
      }
      if (action.payload.searchValue !== state.inactiveTable.searchText) return;
      if (action.payload.item.length === 0) return;
      if (state.inactiveTable.selectedCentrosDeTrabajo.length < 1) {
        state.inactiveTable.selectedCentrosDeTrabajo = action.payload.item;
      } else {
        action.payload.item.forEach((e) => {
          const index = state.inactiveTable.selectedCentrosDeTrabajo.findIndex((v) => v.id === e.id);
          if (index === -1) state.inactiveTable.selectedCentrosDeTrabajo.push(e);
        });
      }
    },
    removeAllWorkCentersInactiveSelectedCentroDeTrabajo: (state) => {
      state.inactiveTable.selectAll = false;
      state.inactiveTable.isIndeterminate = false;
      state.inactiveTable.selectedCentrosDeTrabajo = [];
    },
    setWorkCentersInactiveSearchValue: (state, action: PayloadAction<string>) => {
      state.inactiveTable.searchText = action.payload;
    },
    resetWorkCentersInactivePage: (state) => {
      if (!state.inactiveTable.loading) state.inactiveTable.loading = true;
      state.inactiveTable.page = 1;
    },
    inactiveWorkCentersNextPage: (state, action: PayloadAction<number>) => {      
      if (state.inactiveTable.page < action.payload) state.inactiveTable.page++;
    },
    inactiveWorkCentersPreviousPage: (state) => {
      if (state.inactiveTable.page > 1) state.inactiveTable.page--;
    },


  }
});

export const {
  setWorkCentersSelectedEmpresa,
  // Modal
  setWorkCentersCentroDeTrabajoModal,
  setWorkCentersClaveCodigoModal,
  setWorkCentersPlaceModal,
  // Active CentroDeTrabajos Table
  setWorkCentersActiveLoading,
  setWorkCentersActiveSelectAllStatus,
  setWorkCentersActiveRemoveStatus,
  addWorkCentersActiveSelectedCentroDeTrabajo,
  removeWorkCentersActiveSelectedCentroDeTrabajo,
  addAllWorkCentersActiveSelectedCentroDeTrabajo,
  removeAllWorkCentersActiveSelectedCentroDeTrabajo,
  setWorkCentersActiveSearchValue,
  resetWorkCentersActivePage,
  activeWorkCentersNextPage,
  activeWorkCentersPreviousPage,
   // Inactive CentroDeTrabajos Table
  setWorkCentersInactiveLoading,
  setWorkCentersInactiveActivateStatus,
  setWorkCentersInactiveSelectAllStatus,
  setWorkCentersInactiveRemoveStatus,
  addWorkCentersInactiveSelectedCentroDeTrabajo,
  removeWorkCentersInactiveSelectedCentroDeTrabajo,
  addAllWorkCentersInactiveSelectedCentroDeTrabajo,
  removeAllWorkCentersInactiveSelectedCentroDeTrabajo,
  setWorkCentersInactiveSearchValue,
  resetWorkCentersInactivePage,
  inactiveWorkCentersNextPage,
  inactiveWorkCentersPreviousPage
} = workCentersSlice.actions;

export default workCentersSlice.reducer;


export const getMemoizedWorkCentersActiveCentroDeTrabajo = createSelector (
  (state : RootState) => state.workCenters.selectedEmpresa,
  (state : RootState) => state.personas.personasState.centroDeTrabajo.data_array,
  (selectedEmpresa, qrs) => {
    if (selectedEmpresa.id === "") return qrs.filter((e) => e.activo);
    const empresa = Number(selectedEmpresa.id);
    return qrs.filter((e) => e.empresa.id === empresa && e.activo);
  }
);

export const getMemoizedWorkCentersActiveCentroDeTrabajoAsList = createSelector (
  getMemoizedWorkCentersActiveCentroDeTrabajo,
  (items) => {
    const temp = [...items];
    temp.sort((a, b) => {
      return (a.id > b.id) ? 1 : ((a.id < b.id) ? -1 : 0);
    });
    return temp.map((e) => setListItem({id: e.id.toString(), title: e.nombre_centro}));
  }
);

export const getMemoizeWorkCentersCodigoQrMarkerList = createSelector(
  getMemoizedWorkCentersActiveCentroDeTrabajo,
  (state : RootState) => state.personas.personasState.codigoQr.data_array,
  (items, codigosQr) => {
    const qrs = codigosQr.filter((e) => items.some((v) => v.id === e.centro_de_trabajo.id));
    return qrs.map((e) => setMakerDataItem(e));
  }
);


export const getMemoizedWorkCentersActiveSearch = createSelector(
  getMemoizedWorkCentersActiveCentroDeTrabajo,
  (state: RootState) => state.workCenters.activeTable.searchText,
  (empleados, searchText) => {
    const search = searchText.toLocaleLowerCase().trim();
    if (!search) return empleados;
    return empleados.filter((e) => {
      if (!search) return true;
      return `${e.nombre_centro}`.toLocaleLowerCase().includes(search);
    })
  }
);

export const getMemoizedWorkCentersActiveTotalPages = createSelector(
  getMemoizedWorkCentersActiveSearch,
  (state: RootState) => state.workCenters.activeTable.maxPerPage,
  (empleados, maxPerPage) => {
    let value = Math.trunc(empleados.length / maxPerPage);
    if(empleados.length % maxPerPage > 0 ) value++;
    return value;
  }
);

export const getMemoizedWorkCentersActiveCentroDeTrabajoLength = createSelector(
  getMemoizedWorkCentersActiveSearch,
  (empleados) => empleados.length
);

export const getMemoizedWorkCentersActivePageMessage = createSelector(
  getMemoizedWorkCentersActiveSearch,
  (state: RootState) => state.workCenters.activeTable.page,
  (state: RootState) => state.workCenters.activeTable.maxPerPage,
  (empleados, page, maxPerPage) => {
    const firstItem = (page - 1) * maxPerPage;
    const lastItem = firstItem + maxPerPage - 1;
    const length = empleados.length;
    return `${(firstItem + 1)} - ${((length < lastItem) ? length : (lastItem + 1))} de ${length}`;
  }
);

////////////////////////////////////////////////////////////////////////////////////////////////



export const getMemoizedWorkCentersInactiveCentroDeTrabajo = createSelector (
  (state : RootState) => state.workCenters.selectedEmpresa,
  (state : RootState) => state.personas.personasState.centroDeTrabajo.data_array,
  (selectedEmpresa, qrs) => {
    if (selectedEmpresa.id === "") return qrs.filter((e) => !e.activo);
    const empresa = Number(selectedEmpresa.id);
    return qrs.filter((e) => e.empresa.id === empresa && !e.activo);
  }
);

export const getMemoizedWorkCentersInactiveSearch = createSelector(
  getMemoizedWorkCentersInactiveCentroDeTrabajo,
  (state: RootState) => state.workCenters.inactiveTable.searchText,
  (empleados, searchText) => {
    const search = searchText.toLocaleLowerCase().trim();
    if (!search) return empleados;
    return empleados.filter((e) => {
      if (!search) return true;
      return `${e.nombre_centro}`.toLocaleLowerCase().includes(search);
    })
  }
);

export const getMemoizedWorkCentersInactiveTotalPages = createSelector(
  getMemoizedWorkCentersInactiveSearch,
  (state: RootState) => state.workCenters.inactiveTable.maxPerPage,
  (empleados, maxPerPage) => {
    let value = Math.trunc(empleados.length / maxPerPage);
    if(empleados.length % maxPerPage > 0 ) value++;
    return value;
  }
);

export const getMemoizedWorkCentersInactiveCentroDeTrabajoLength = createSelector(
  getMemoizedWorkCentersInactiveSearch,
  (empleados) => empleados.length
);

export const getMemoizedWorkCentersInactivePageMessage = createSelector(
  getMemoizedWorkCentersInactiveSearch,
  (state: RootState) => state.workCenters.inactiveTable.page,
  (state: RootState) => state.workCenters.inactiveTable.maxPerPage,
  (empleados, page, maxPerPage) => {
    const firstItem = (page - 1) * maxPerPage;
    const lastItem = firstItem + maxPerPage - 1;
    const length = empleados.length;
    return `${(firstItem + 1)} - ${((length < lastItem) ? length : (lastItem + 1))} de ${length}`;
  }
);



export const getMemoizedWorkCentersSelectedPais = createSelector(
  (state : RootState) => state.personas.personasState.pais.data_array,
  (state : RootState) => state.workCenters.centroDeTrabajoModal.centro_de_trabajo.direccion.pais,
  (paisList, pais) => {
    if (pais.id === 0 && paisList[0]) return setListItem({id: paisList[0].id.toString(), title: paisList[0].nombre});
    return setListItem({id: pais.id.toString(), title: pais.nombre});
  }
);

export const getMemoizedWorkCentersSelectedEstado = createSelector(
  (state : RootState) => state.personas.personasState.estado.data_array,
  (state : RootState) => state.workCenters.centroDeTrabajoModal.centro_de_trabajo.direccion.estado,
  getMemoizedWorkCentersSelectedPais,
  (estadoList, estado, pais) => {
    const tempList = estadoList.filter((e) => e.pais.id.toString() === pais.id);
    if (tempList.find((e) => e.id === estado.id)) return setListItem({id: estado.id.toString(), title: estado.nombre});
    return setListItem({id: tempList[0].id.toString(), title: tempList[0].nombre});
  }
);
