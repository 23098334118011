/**
 * Métodos para el manejo de IonicStorage 
 * @setStorage              guarda un unico objeto al storage
 * @getStorage              obtiene el objeto guardado con @setStorage
 * @setStorageArray         guarda el array de objetos en el storage
 * @getStorageArray         obtiene el array de objetos guardado con @setStorageArray
 * @removeStorageArray      elimina el array de objetos guardado con @setStorageArray
 * @setStorageItemArray     agrega un elemento al array de objeto guardado con @setStorageArray
 * @getStorageItemArray     obtiene un elemento del array guardado con @setStorageArray buscandolo por su id 
 * @removeStorageItemArray  elimina un elemento del array guardado con @setStorageArray buscandolo por su id 
 */

import { setStorageData, getStorageData, removeStorageData, setArrayItemStorageData, getArrayItemStorageData, removeArrayItemStorageData } from "../../api/storage";
import { ICheckRondin, getEmptyCheckRondin } from "../../interfaces/rondines";

export const setStorageCheckRondin = async (ICheckRondin : ICheckRondin) => await setStorageData('ICheckRondin', ICheckRondin);
export const getStorageCheckRondin = async () : Promise<ICheckRondin> => await getStorageData('ICheckRondin', getEmptyCheckRondin());
export const removeStorageCheckRondin = async () => await removeStorageData('ICheckRondin');
export const setStorageCheckRondinArray = async (ICheckRondin : ICheckRondin[]) => await setStorageData('ICheckRondinArray', ICheckRondin);
export const getStorageCheckRondinArray = async () : Promise<ICheckRondin[]> => await getStorageData('ICheckRondinArray', [] as ICheckRondin[]);
export const removeStorageCheckRondinArray = async () => await removeStorageData('ICheckRondinArray');
export const setStorageCheckRondinArrayItem = async (id: number, ICheckRondin : ICheckRondin) : Promise<ICheckRondin[]> => await setArrayItemStorageData('ICheckRondinArray', id, ICheckRondin, [] as ICheckRondin[]);
export const getStorageCheckRondinArrayItem = async (id: number) : Promise<ICheckRondin> => await getArrayItemStorageData('ICheckRondinArray', id, getEmptyCheckRondin);
export const removeStorageCheckRondinArrayItem = async (id: number) : Promise<ICheckRondin[]> => await removeArrayItemStorageData('ICheckRondinArray', id, [] as ICheckRondin[]);
