/**
 * Métodos para el manejo de IonicStorage 
 * @setStorage              guarda un unico objeto al storage
 * @getStorage              obtiene el objeto guardado con @setStorage
 * @setStorageArray         guarda el array de objetos en el storage
 * @getStorageArray         obtiene el array de objetos guardado con @setStorageArray
 * @removeStorageArray      elimina el array de objetos guardado con @setStorageArray
 * @setStorageItemArray     agrega un elemento al array de objeto guardado con @setStorageArray
 * @getStorageItemArray     obtiene un elemento del array guardado con @setStorageArray buscandolo por su id 
 * @removeStorageItemArray  elimina un elemento del array guardado con @setStorageArray buscandolo por su id 
 */

import { setStorageData, getStorageData, removeStorageData, setArrayItemStorageData, getArrayItemStorageData, removeArrayItemStorageData } from "../../api/storage";
import { IMvImpuestos, getEmptyMvImpuestos } from "../../interfaces/personas";

export const setStorageMvImpuestos = async (IMvImpuestos : IMvImpuestos) => await setStorageData('IMvImpuestos', IMvImpuestos);
export const getStorageMvImpuestos = async () : Promise<IMvImpuestos> => await getStorageData('IMvImpuestos', getEmptyMvImpuestos());
export const removeStorageMvImpuestos = async () => await removeStorageData('IMvImpuestos');
export const setStorageMvImpuestosArray = async (IMvImpuestos : IMvImpuestos[]) => await setStorageData('IMvImpuestosArray', IMvImpuestos);
export const getStorageMvImpuestosArray = async () : Promise<IMvImpuestos[]> => await getStorageData('IMvImpuestosArray', [] as IMvImpuestos[]);
export const removeStorageMvImpuestosArray = async () => await removeStorageData('IMvImpuestosArray');
export const setStorageMvImpuestosArrayItem = async (id: number, IMvImpuestos : IMvImpuestos) : Promise<IMvImpuestos[]> => await setArrayItemStorageData('IMvImpuestosArray', id, IMvImpuestos, [] as IMvImpuestos[]);
export const getStorageMvImpuestosArrayItem = async (id: number) : Promise<IMvImpuestos> => await getArrayItemStorageData('IMvImpuestosArray', id, getEmptyMvImpuestos);
export const removeStorageMvImpuestosArrayItem = async (id: number) : Promise<IMvImpuestos[]> => await removeArrayItemStorageData('IMvImpuestosArray', id, [] as IMvImpuestos[]);
