/**
 * Métodos para el manejo de IonicStorage 
 * @setStorage              guarda un unico objeto al storage
 * @getStorage              obtiene el objeto guardado con @setStorage
 * @setStorageArray         guarda el array de objetos en el storage
 * @getStorageArray         obtiene el array de objetos guardado con @setStorageArray
 * @removeStorageArray      elimina el array de objetos guardado con @setStorageArray
 * @setStorageItemArray     agrega un elemento al array de objeto guardado con @setStorageArray
 * @getStorageItemArray     obtiene un elemento del array guardado con @setStorageArray buscandolo por su id 
 * @removeStorageItemArray  elimina un elemento del array guardado con @setStorageArray buscandolo por su id 
 */

import { setStorageData, getStorageData, removeStorageData, setArrayItemStorageData, getArrayItemStorageData, removeArrayItemStorageData } from "../../api/storage";
import { IPuesto, getEmptyPuesto } from "../../interfaces/nomina";

export const setStoragePuesto = async (IPuesto : IPuesto) => await setStorageData('IPuesto', IPuesto);
export const getStoragePuesto = async () : Promise<IPuesto> => await getStorageData('IPuesto', getEmptyPuesto());
export const removeStoragePuesto = async () => await removeStorageData('IPuesto');
export const setStoragePuestoArray = async (IPuesto : IPuesto[]) => await setStorageData('IPuestoArray', IPuesto);
export const getStoragePuestoArray = async () : Promise<IPuesto[]> => await getStorageData('IPuestoArray', [] as IPuesto[]);
export const removeStoragePuestoArray = async () => await removeStorageData('IPuestoArray');
export const setStoragePuestoArrayItem = async (id: number, IPuesto : IPuesto) : Promise<IPuesto[]> => await setArrayItemStorageData('IPuestoArray', id, IPuesto, [] as IPuesto[]);
export const getStoragePuestoArrayItem = async (id: number) : Promise<IPuesto> => await getArrayItemStorageData('IPuestoArray', id, getEmptyPuesto);
export const removeStoragePuestoArrayItem = async (id: number) : Promise<IPuesto[]> => await removeArrayItemStorageData('IPuestoArray', id, [] as IPuesto[]);
