import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { List, setListItem } from "../../interfaces/helpers";
import { RootState } from "../../store";
import { getNombreEmpleado } from "../personasSlice";

export interface IReportType extends List {
  headers: string[];
  data: string[][];
}

export interface IReports {
  selectedEmpresa: List;
  selectedCentroDeTrabajo: List;
  selectedEmpleado: List;
  selectedReport: List;
  tipoDeReportes: IReportType[];
}

export const reportsInitialState: IReports = {
  selectedEmpresa: { id: "", title: "Todas las empresas" },
  selectedCentroDeTrabajo: { id: "", title: "" },
  selectedEmpleado: { id: "", title: "" },
  selectedReport: { id: "1", title: "Reporte Historial Empleado-Fecha" },
  tipoDeReportes: [
    {
      // Empleado
      id: "1",
      title: "Historial Empleado-Fecha",
      headers: [
        "Nombre",
        "CURP",
        "NSS",
        "Empresa",
        "Turno",
        "Centro de Trabajo",
        "Tipo Movimiento",
        "Momento Registro",
        "Distancia Centro",
      ],
      data: [],
    },
    {
      id: "2",
      title: "Historial Empleados-Empresa-Fecha",
      headers: [
        "Nombre",
        "CURP",
        "NSS",
        "Empresa",
        "Turno",
        "Centro de Trabajo",
        "Tipo Movimiento",
        "Momento Registro",
        "Distancia Centro",
      ],
      data: [],
    },
    {
      id: "3",
      title: "Empleados-Empresa",
      headers: [
        "Nombre",
        "Email",
        "CURP",
        "Celular",
        "Fecha Contratación",
        "NSS",
        "Empresa",
      ],
      data: [],
    },
    {
      // Centro
      id: "4",
      title: "Empleados-Centro de trabajo",
      headers: [
        "Nombre",
        "Email",
        "CURP",
        "Celular",
        "Fecha Contratación",
        "NSS",
        "Empresa",
      ],
      data: [],
    },
    {
      // Centro
      id: "5",
      title: "Empleados laborando",
      headers: [
        "Nombre",
        "CURP",
        "NSS",
        "Empresa",
        "Centro de Trabajo",
        "Momento de Registro",
        "Minutos Diferencia Registro",
        "Distancia Centro De Trabajo",
        "Horas Extra",
        "Asistencia Puntual",
      ],
      data: [],
    },
    {
      // Empleado
      id: "6",
      title: "Incidencias Empleado-Fecha",
      headers: [],
      data: [],
    },
    {
      // Empleado
      id: "7",
      title: "Resumen Incidencias Empleado-Fecha",
      headers: [
        "Nombre",
        "CURP",
        "NSS",
        "Empresa",
        "Turno",
        "Fecha Inicio Periodo",
        "Fecha Fin Periodo",
        "Total Horas Laboradas",
        "Total Horas Extra",
        "Total Horas Ordinarias",
        "Total Faltas",
        "Total Retardos",
        "Total días Vacaciones",
        "Total Dias Incapacidad",
        "Total Días Laborados",
        "Total Días Descanso",
      ],
      data: [],
    },
    {
      // Centro
      id: "8",
      title: "Resumen Incidencias Empleado-Centro de trabajo-Fecha",
      headers: [
        "Nombre",
        "CURP",
        "NSS",
        "Empresa",
        "Turno",
        "Fecha Inicio Periodo",
        "Fecha Fin Periodo",
        "Total Horas Laboradas",
        "Total Horas Extra",
        "Total Horas Ordinarias",
        "Total Faltas",
        "Total Retardos",
        "Total días Vacaciones",
        "Total Dias Incapacidad",
        "Total Días Laborados",
        "Total Días Descanso",
      ],
      data: [],
    },
    {
      id: "9",
      title: "Resumen Incidencias Empleado-Empresa-Fecha",
      headers: [
        "Nombre",
        "CURP",
        "NSS",
        "Empresa",
        "Turno",
        "Fecha Inicio Periodo",
        "Fecha Fin Periodo",
        "Total Horas Laboradas",
        "Total Horas Extra",
        "Total Horas Ordinarias",
        "Total Faltas",
        "Total Retardos",
        "Total días Vacaciones",
        "Total Dias Incapacidad",
        "Total Días Laborados",
        "Total Días Descanso",
      ],
      data: [],
    },
    {
      // Centro
      id: "10",
      title: "Resumen Incidencias Centro de trabajo-Fecha",
      headers: [],
      data: [],
    },
    {
      // Empleado
      id: "11",
      title: "Horario-Empleado",
      headers: [
        "Nombre Completo",
        "CURP",
        "NSS",
        "Empresa",
        "Turno",
        "Lunes",
        "Martes",
        "Miércoles",
        "Jueves",
        "Viernes",
        "Sabado",
        "Domingo",
        "Tolerancia Entrada",
        "Tolerancia Salida",
        "Dias Descanso",
      ],
      data: [],
    },
    {
      id: "12",
      title: "Trabajadores Activos - Empresa",
      headers: [
        "Nombre",
        "Email",
        "CURP",
        "Celular",
        "Fecha Contratación",
        "NSS",
        "Empresa",
        "Número Incidencias en 30 días",
      ],
      data: [],
    },
  ],
};

export const reportsSlice = createSlice({
  name: "reports",
  initialState: reportsInitialState,
  reducers: {
    setReportsSelectedEmpresa: (state, action: PayloadAction<List>) => {
      state.selectedEmpresa = action.payload;
    },
    setReportsSelectedCentroDeTrabajo: (state, action: PayloadAction<List>) => {
      state.selectedCentroDeTrabajo = action.payload;
    },
    setReportsSelectedEmpleado: (state, action: PayloadAction<List>) => {
      state.selectedEmpleado = action.payload;
    },
    setReportsSelectedReport: (state, action: PayloadAction<List>) => {
      state.selectedReport = action.payload;
    },
  },
});

export const {
  setReportsSelectedEmpresa,
  setReportsSelectedCentroDeTrabajo,
  setReportsSelectedEmpleado,
  setReportsSelectedReport,
} = reportsSlice.actions;

export default reportsSlice.reducer;

export const getMemoizedReportsActiveEmpleado = createSelector(
  (state: RootState) => state.reports.selectedEmpresa,
  (state: RootState) => state.personas.personasState.empleado.data_array,
  (selectedEmpresa, empleados) => {
    if (selectedEmpresa.id === "") return empleados.filter((e) => e.activo);
    const empresa = Number(selectedEmpresa.id);
    return empleados.filter((e) => e.empresa.id === empresa && e.activo);
  }
);

export const getMemoizedReportsActiveCentroDetrabajo = createSelector(
  (state: RootState) => state.reports.selectedEmpresa,
  (state: RootState) => state.personas.personasState.centroDeTrabajo.data_array,
  (selectedEmpresa, centroDeTrabajo) => {
    if (selectedEmpresa.id === "")
      return centroDeTrabajo.filter((e) => e.activo);
    const empresa = Number(selectedEmpresa.id);
    return centroDeTrabajo.filter((e) => e.empresa.id === empresa && e.activo);
  }
);

export const getMemoizedReportsActiveEmpleadoAsSelectList = createSelector(
  getMemoizedReportsActiveEmpleado,
  (empleados) => {
    return empleados.map((e) =>
      setListItem({
        id: e.id.toString(),
        title: getNombreEmpleado(e),
      })
    );
  }
);

export const getMemoizedReportsActiveCentroDetrabajoAsSelectList =
  createSelector(getMemoizedReportsActiveCentroDetrabajo, (centroDeTrabajo) => {
    return centroDeTrabajo.map((e) =>
      setListItem({ id: e.id.toString(), title: e.nombre_centro })
    );
  });

export const getMemoizedReportsReportTypeAsSelectList = createSelector(
  (state: RootState) => state.reports.tipoDeReportes,
  (tipoDeReportes) => {
    return tipoDeReportes.map((e) =>
      setListItem({ id: e.id.toString(), title: e.title })
    );
  }
);
