import { getArrayItemStorageData, getStorageData, removeArrayItemStorageData, removeStorageData, setArrayItemStorageData, setStorageData } from "../../api/storage";
import { getEmptyMvTipoUsuario, IMvTipoUsuario } from "../../interfaces/personas";

export const setStorageMvTipoUsuario = async (IMvTipoUsuario : IMvTipoUsuario) => await setStorageData('IMvTipoUsuario', IMvTipoUsuario);
export const getStorageMvTipoUsuario = async () => await getStorageData('IMvTipoUsuario', getEmptyMvTipoUsuario());
export const removeStorageMvTipoUsuario = async () => await removeStorageData('IMvTipoUsuario');
export const setStorageMvTipoUsuarioArray = async (IMvTipoUsuario : IMvTipoUsuario[]) => await setStorageData('IMvTipoUsuarioArray', IMvTipoUsuario);
export const getStorageMvTipoUsuarioArray = async () : Promise<IMvTipoUsuario[]> => await getStorageData('IMvTipoUsuarioArray', [] as IMvTipoUsuario[]);
export const removeStorageMvTipoUsuarioArray = async () => await removeStorageData('IMvTipoUsuarioArray');
export const setStorageMvTipoUsuarioArrayItem = async (id: number, IMvTipoUsuario : IMvTipoUsuario) : Promise<IMvTipoUsuario[]> => await setArrayItemStorageData('IMvTipoUsuarioArray', id, IMvTipoUsuario, [] as IMvTipoUsuario[]);
export const getStorageMvTipoUsuarioArrayItem = async (id: number) : Promise<IMvTipoUsuario> => await getArrayItemStorageData('IMvTipoUsuarioArray', id, getEmptyMvTipoUsuario);
export const removeStorageMvTipoUsuarioArrayItem = async (id: number) => await removeArrayItemStorageData('IMvTipoUsuarioArray', id, [] as IMvTipoUsuario[]);
