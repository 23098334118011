/**
 * Metódos para las llamadas a la api de q3
 * @function get realiza las peticiones get
 * @function post realiza las peticiones post
 * @function put realiza las peticiones put
 * @function del realiza las peticiones del
 * @function postFile realiza las peticiones que necesiten subir un archivo
 */

import { getJWT } from '../../firebase/config';
import API from './config';

export async function get(url : string, data?: any) {
  const token = await getJWT();
  let headers = {
    'Authorization': token,
    'Content-Type' : 'application/json'
  };
  return await API.get(url, {headers: headers});//ready
}

export async function post(url : string, data? : any) {
  const token = await getJWT();
  return await API.post(url, data, {headers: {'Authorization': token}});//ready
}

export async function put(url : string, data : any) {
  const token = await getJWT();
  return await API.put(url, data, {headers: {'Authorization': token}});//ready
}

export async function del(url : string) {
  const token = await getJWT();
  return await API.delete(url, {headers: {'Authorization': token}});//ready
}

export async function postFile(url : string, _data : any, fileName: string) {
  const token = await getJWT();
  const data = new FormData();
  data.append('Archivo', _data, fileName);
  return await API.post(url, data, {headers: {'Authorization': token, 
  'Content-Type' : 'multipart/form-data'}});//ready
}