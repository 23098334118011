/**
 * Es el componente utilizado en la página Faq para que describe las dudas más comúnes en el uso de la aplicación
 */

import { IonContent, IonItemDivider, IonLabel } from "@ionic/react";
import { help } from "ionicons/icons";
import React from "react";
import Card from "../components/Card";
import './Faq.css';

const Faq: React.FC = () =>{
    return (
        <IonContent class="inner-content">
            <Card
                title={<IonLabel>Preguntas frecuentes (FAQ)</IonLabel>}
                icon={help}
                subtitle={
                    <IonLabel>Consulte sus dudas o amplíe la información sobre el servicio</IonLabel>

                }
                content={
                    <div className="divFAQ">
                        <IonItemDivider className="dividerQuestion">
                            <IonLabel>
                                ¿Qué es Qentry?
                            </IonLabel>
                        </IonItemDivider>
                        <p className="textAnswer">
                            Qentry es una solución para todo aquel que quiera asistencia desde la comodidad de su teléfono móvil, haciendo un proceso eficiente y rápido.
                        </p>
                        <IonItemDivider className="dividerQuestion">
                            <IonLabel>
                                ¿Qué problemas solucionamos?
                            </IonLabel>
                        </IonItemDivider>
                        <p className="textAnswer">
                            <ul>
                                <li>Damos visibilidad en tiempo real de la asistencia de todos los colaboradores de la empresa.</li>
                                <li>Simplificamos el proceso al dar de alta un usuario.</li>
                                <li>Marca tu asistencia desde donde estés.</li>
                            </ul>
                        </p>
                        <IonItemDivider className="dividerQuestion">
                            <IonLabel>
                                ¿Cómo funciona el Qentry?
                            </IonLabel>
                        </IonItemDivider>
                        <p className="textAnswer">
                            Qentry te permite administrar de forma correcta, fácil y rápida la asistencia de usuarios de una área en específico, automatizando el pase de asistencia y concentrándote en la innovación
                        </p>
                        <IonItemDivider className="dividerQuestion">
                            <IonLabel>
                                ¿Cómo empezar a usar Qentry?
                            </IonLabel>
                        </IonItemDivider>
                        <p className="textAnswer">
                            Para empezar a aprovechar todas las posibilidades de Qentry debes de agregar a tus usuarios para posteriormente cada usuario pueda registrar sus asistencias desde su centro de trabajo establecido, ten en cuenta que el administrador será el único que podrá hacer el registro de usuarios, la cual te notificara con tus accesos para usar Qentry
                        </p>
                        <IonItemDivider className="dividerQuestion">
                            <IonLabel>
                                ¿Qué son los centros de trabajo?
                            </IonLabel>
                        </IonItemDivider>
                        <p className="textAnswer">
                            Un centro de trabajo es una ubicación dentro de la cual, los usuarios colaboradores de Qentry podrán registrar su asistencia, tenido en cuenta que la aplicación toma la ubicación en tiempo real de los usuarios que quieran registra su asistencia, si se realiza un registro de asistencia a más de 5 metros del centro de trabajo, eso no podrá ser registrado.
                        </p>
                        <IonItemDivider className="dividerQuestion">
                            <IonLabel>
                                ¿Qué reportes genera Qentry?
                            </IonLabel>
                        </IonItemDivider>
                        <div className="textAnswer">
                            Qentry te permite generar diferentes reportes dependiendo tus necesidades y haciendo que el proceso  de administración sea más fácil, ofreciéndote estos reportes:
                            <ul>
                                <li>Reportes de entradas y salidas, desde la fecha establecida.</li>
                                <li>Reporte del usuario, permite ver los datos del usuario elegido</li>
                                <li>Reporte de horarios esperados por el usuario, sé mostrar si el usuario cumplió sus horarios de forma efectiva establecidos </li>
                                <li>Reporte banco de horas por fecha, Permite consultar las horas trabajadas por alguna fecha determinada.</li>
                                <li>-Reporte banco de horas por usuario, permite consultar las horas trabajadas por cada usuario.</li>
                                <li>-Análisis de movimiento por empleado por hora, consulta todos los movimientos realizados por el empleado seleccionado</li>
                                <li>-Reporte acumulado de horas por semana y empleado, Muestra las horas por fechas establecidas o por usuario seleccionado</li>
                                <li>-Reportes de entradas y salidas, desde la fecha establecida.</li>
                            </ul>
                        </div>
                        <IonItemDivider className="dividerQuestion">
                            <IonLabel>
                                ¿Obtener el historial de registros en Qentry?
                            </IonLabel>
                        </IonItemDivider>
                        <p className="textAnswer">
                            Para visualizar el historial de estradas y salidas de todos los centros de trabajo simplemente deber de ir a la opción "Historial/ Bitácoras" dentro del menú, donde se puede seleccionar, ver las entradas o salidas de una determinada fecha
                        </p>
                        <IonItemDivider className="dividerQuestion">
                            <IonLabel>
                                ¿Solicitudes de nuevos dispositivos en Qentry?
                            </IonLabel>
                        </IonItemDivider>
                        <p className="textAnswer">
                            Qentry maneja las sesiones mediante el celular, así que para poder usar un nuevo dispositivo previamente se debe aceptar la solicitud del dispositivo, esta función es requerida cuando algún trabajador hace algun cambio de dispositivo
                        </p>
                        <IonItemDivider className="dividerQuestion">
                            <IonLabel>
                                ¿Como registrar una incapacidad?
                            </IonLabel>
                        </IonItemDivider>
                        <p color="light" className="textAnswer">
                            Para registrar una nueva incapacidad deber ir a "incapacidades", posteriormente  buscar y seleccionar al usuario para registrar su incapacidad, pudiendo seleccionar el inicio y fin de la incapacidad, también podrás describir la incidencia.
                        </p>
                        <IonItemDivider className="dividerQuestion">
                            <IonLabel>
                                ¿Como registrar vacaciones?
                            </IonLabel>
                        </IonItemDivider>
                        <p className="textAnswer">
                            Cuando se requiera registrar vacaciones, deberá ir a "Vacaciones" dentro del menú, donde se podrá seleccionar al usuario, al cual se le asignaran vaciones y posteriormente seleccionar las fechas en las que estará ausente.
                        </p>
                        <IonItemDivider className="dividerQuestion">
                            <IonLabel>
                                ¿Como cambiar los horarios en Qentry?
                            </IonLabel>
                        </IonItemDivider>
                        <p className="textAnswer">
                            Para realizar algún cambio de horario o establecer un nuevo horario, debemos ir a la opción dentro del menú "Cambios de horarios", Qentry te permite hacer el cambio de horario a múltiples usuarios, de forma fácil, buscando y agregando usuarios a la selección, además de que podrás seleccionar un rango horas para asignarlos a toda semana y a todos los usuarios seleccionados.
                        </p>
                    </div>
                }>
            </Card>
        </IonContent>
    )

}


export default Faq;