/**
 * Métodos AsyncThunk para ser usados en los reducers y
 * los resultados a las llamadas http puedan ser usados para
 * actualizar el state en redux
 */

import { createAsyncThunk } from "@reduxjs/toolkit";
import { DEFAULT_STATE } from "../../enums/default_state";
import { getInformacionContrato, getAllInformacionContrato, updateInformacionContrato, createInformacionContrato, deleteInformacionContrato } from "../../http/personas";
import { getEmptyStatusInformacionContrato, IInformacionContrato } from "../../interfaces/personas";
import { IServerDate } from "../../interfaces/time";

export const getInformacionContratoAsyncThunk = createAsyncThunk(
  'personas/getInformacionContratoAsyncThunk',
  async (query : {serverDate : IServerDate, id: number, idCliente : number}) => await getInformacionContrato(query.serverDate, query.id, query.idCliente)
    .then(async (result) => result)
    .catch(async (error) => getEmptyStatusInformacionContrato(DEFAULT_STATE.error))  
);

export const getAllInformacionContratoAsyncThunk = createAsyncThunk(
  'personas/getAllInformacionContratoAsyncThunk',
  async (queryAll : {serverDate : IServerDate, idCliente : number}) => await getAllInformacionContrato(queryAll.serverDate, queryAll.idCliente)
    .then(async (result) => result)
    .catch(async (error) => getEmptyStatusInformacionContrato(DEFAULT_STATE.error))  
);

export const updateInformacionContratoAsyncThunk = createAsyncThunk(
  'personas/updateInformacionContratoAsyncThunk',
  async (update: {serverDate : IServerDate, id: number, idCliente : number, object: IInformacionContrato}) => await updateInformacionContrato(update.serverDate, update.id, update.idCliente, update.object)
    .then(async (result) => result)
    .catch(async (error) => getEmptyStatusInformacionContrato(DEFAULT_STATE.error))  
);

export const createInformacionContratoAsyncThunk = createAsyncThunk(
  'personas/createInformacionContratoAsyncThunk',
  async (create : {serverDate : IServerDate, idCliente : number, object: IInformacionContrato}) => await createInformacionContrato(create.serverDate, create.idCliente, create.object)
    .then(async (result) => result)
    .catch(async (error) => getEmptyStatusInformacionContrato(DEFAULT_STATE.error))  
);

export const deleteInformacionContratoAsyncThunk = createAsyncThunk(
  'personas/deleteInformacionContratoAsyncThunk',
  async (del : {id : number, idCliente : number}) => await deleteInformacionContrato(del.id, del.idCliente)
    .then(async (result) => result)
    .catch(async (error) => getEmptyStatusInformacionContrato())  
);
