/**
 * Métodos del, get, post, put para llamadas a la API
 * @get     en este método primero consulta con @getFromLocalStorage si es necesario hacer la petición http sino se obtiene del LocalStorage;
 *          si hay un error en la petición http se busca el objeto en el LocalStorage sino lo encuentra devuelve un objeto vacío; si la
 *          petición se realiza correctamente actualiza el valor individual y en el array del objeto en el LocalStorage y devuelve el objeto
 *          obtenido del servidor.
 * @getAll  en este método primero consulta con @getFromLocalStorage si es necesario hacer la petición http sino se obtiene del LocalStorage;
 *          si hay un error en la petición http se busca el objeto array en el LocalStorage sino lo encuentra devuelve un array vacío; si la
 *          petición se realiza correctamente actualiza array en el LocalStorage y devuelve el array obtenido del servidor
 * @update  en este método después de realizar la petición http si falla devuelve el objeto que se iba a actualizar; si la petición se
 *          realiza correctamente actualiza el valor individual y en el array del objeto en el LocalStorage y devuelve el objeto obtenido del
 *          servidor.
 * @create  en este método después de realizar la petición http si falla devuelve el objeto que se iba a crear; si la petición se realiza
 *          correctamente actualiza el valor individual y en el array del objeto en el LocalStorage y devuelve el objeto obtenido del
 *          servidor.
 * @delete  en este método después de realizar la petición http si falla devuelve el objeto que se iba a eliminar; si la petición se realiza
 *          correctamente elimina el valor individual y en el objeto en el arrya del LocalStorage y devuelve un objeto vacio (Default)
 */

import { del, get, post, put } from "../../api/api";
import {
  getFromLocalStorage,
  updateLocalStorageExpiry,
} from "../../api/helpers";
import { removeArrayItemStorageData } from "../../api/storage";
import { DEFAULT_STATE } from "../../enums/default_state";
import {
  getEmptyStatusCambioEstatusIncapacidad,
  setCambioEstatusIncapacidad,
  ICambioEstatusIncapacidad,
  getSerializableCambioEstatusIncapacidad,
} from "../../interfaces/nomina";
import { IServerDate } from "../../interfaces/time";
import {
  getStorageCambioEstatusIncapacidadArrayItem,
  setStorageCambioEstatusIncapacidad,
  setStorageCambioEstatusIncapacidadArrayItem,
  getStorageCambioEstatusIncapacidadArray,
  setStorageCambioEstatusIncapacidadArray,
  removeStorageCambioEstatusIncapacidad,
} from "../../localStorage/nomina";

export const getCambioEstatusIncapacidad = async (
  serverDate: IServerDate,
  pk: number,
  idCliente: number
) => {
  const temp = getEmptyStatusCambioEstatusIncapacidad();
  const doHttp = await getFromLocalStorage(serverDate, temp.expiry);
  temp.expiry = doHttp.storage;
  if (!doHttp.result) {
    console.log(
      `(localStorage) CambioEstatusIncapacidad con pk: ${pk}, obtenido con éxito`
    );
    temp.estado = DEFAULT_STATE.success;
    temp.mensaje = `CambioEstatusIncapacidad obtenida con éxito`;
    temp.data = await getStorageCambioEstatusIncapacidadArrayItem(pk);
    return temp;
  }
  const result = await get(
    `nomina/cambio-estatus-incapacidad/${pk}/${idCliente}`
  );
  const data = result.data;
  console.log(`CambioEstatusIncapacidad data: `, data);
  if (!data.id || data.detail) {
    console.log(
      `Error al obtener CambioEstatusIncapacidad con pk: ${pk}, ${data.detail}`
    );
    temp.estado = DEFAULT_STATE.error;
    temp.mensaje = `Error al obtener la datos personales: ${
      data.detail ?? "Hubo un problema al conectarse con el servidor"
    }`;
    temp.data = await getStorageCambioEstatusIncapacidadArrayItem(pk);
    return temp;
  }
  console.log(`CambioEstatusIncapacidad con pk: ${pk}, obtenido con éxito`);
  temp.estado = DEFAULT_STATE.success;
  temp.mensaje = `CambioEstatusIncapacidad obtenida con éxito`;
  temp.data = await setCambioEstatusIncapacidad(data, serverDate);
  await setStorageCambioEstatusIncapacidad(temp.data);
  temp.data_array = await setStorageCambioEstatusIncapacidadArrayItem(
    pk,
    temp.data
  );
  temp.expiry = await updateLocalStorageExpiry(serverDate, temp.expiry);
  return temp;
};

export const getAllCambioEstatusIncapacidad = async (
  serverDate: IServerDate,
  idCliente: number
) => {
  const temp = getEmptyStatusCambioEstatusIncapacidad();
  const doHttp = await getFromLocalStorage(serverDate, temp.expiry_array);
  temp.expiry_array = doHttp.storage;
  if (!doHttp.result) {
    console.log(
      `(localStorage) Todos las CambioEstatusIncapacidad obtenidas con éxito`
    );
    temp.estado = DEFAULT_STATE.success;
    temp.mensaje = `Todos las CambioEstatusIncapacidad obtenidas con éxito`;
    temp.data_array = await getStorageCambioEstatusIncapacidadArray();
    // return temp;
  }
  const result = await get(`nomina/cambio-estatus-incapacidad/${idCliente}`);
  const data = result.data;
  if (data.detail) {
    console.log(`Error al obtener la lista de CambioEstatusIncapacidad`, data);
    temp.estado = DEFAULT_STATE.error;
    temp.mensaje = `Error al obtener la lista de CambioEstatusIncapacidad: ${
      data.detail ?? "Hubo un problema al conectarse con el servidor"
    }`;
    temp.data_array = await getStorageCambioEstatusIncapacidadArray();
    return temp;
  }
  console.log(`Todos las CambioEstatusIncapacidads obtenidos con éxito`);
  temp.estado = DEFAULT_STATE.success;
  temp.mensaje = `Todos las CambioEstatusIncapacidad obtenidas con éxito`;
  temp.data_array = await Promise.all(
    data.map(async (e: any) => await setCambioEstatusIncapacidad(e, serverDate))
  );
  await setStorageCambioEstatusIncapacidadArray(temp.data_array);
  temp.expiry_array = await updateLocalStorageExpiry(
    serverDate,
    temp.expiry_array
  );
  return temp;
};

export const updateCambioEstatusIncapacidad = async (
  serverDate: IServerDate,
  pk: number,
  idCliente: number,
  object: ICambioEstatusIncapacidad
) => {
  const temp = getEmptyStatusCambioEstatusIncapacidad();
  const result = await put(
    `nomina/cambio-estatus-incapacidad/${pk}/${idCliente}`,
    getSerializableCambioEstatusIncapacidad(object)
  );
  const data = result.data;
  if (!data.id || data.detail) {
    console.log(
      `Error al actualizar CambioEstatusIncapacidad con pk: ${pk}, ${data.detail}`
    );
    temp.estado = DEFAULT_STATE.error;
    temp.mensaje = `Error al actualizar la datos personales: ${
      data.detail ?? "Hubo un problema al conectarse con el servidor"
    }`;
    temp.data = await setCambioEstatusIncapacidad(object, serverDate);
    return temp;
  }
  console.log(`CambioEstatusIncapacidad con pk: ${pk}, actualizado con éxito`);
  temp.estado = DEFAULT_STATE.success;
  temp.mensaje = `CambioEstatusIncapacidad actualizada con éxito`;
  temp.data = await setCambioEstatusIncapacidad(data, serverDate);
  await setStorageCambioEstatusIncapacidad(temp.data);
  temp.data_array = await setStorageCambioEstatusIncapacidadArrayItem(
    temp.data.id,
    temp.data
  );
  return temp;
};

export const createCambioEstatusIncapacidad = async (
  serverDate: IServerDate,
  idCliente: number,
  object: ICambioEstatusIncapacidad
) => {
  const temp = getEmptyStatusCambioEstatusIncapacidad();
  const result = await post(
    `nomina/cambio-estatus-incapacidad/${idCliente}`,
    getSerializableCambioEstatusIncapacidad(object)
  );
  const data = result.data;
  if (!data.id || data.detail) {
    console.log(
      `Error al crear CambioEstatusIncapacidad con los datos ${object}, ${data.detail}`
    );
    temp.estado = DEFAULT_STATE.error;
    temp.mensaje = `Error al crear la datos personales: ${
      data.detail ?? "Hubo un problema al conectarse con el servidor"
    }`;
    temp.data = await setCambioEstatusIncapacidad(object, serverDate);
    return temp;
  }
  console.log(`CambioEstatusIncapacidad creada con éxito`);
  temp.estado = DEFAULT_STATE.success;
  temp.mensaje = `CambioEstatusIncapacidad creada con éxito`;
  temp.data = await setCambioEstatusIncapacidad(data, serverDate);
  await setStorageCambioEstatusIncapacidad(temp.data);
  temp.data_array = await setStorageCambioEstatusIncapacidadArrayItem(
    temp.data.id,
    temp.data
  );
  return temp;
};

export const deleteCambioEstatusIncapacidad = async (
  pk: number,
  idCliente: number
) => {
  const temp = getEmptyStatusCambioEstatusIncapacidad();
  const result = await del(
    `nomina/cambio-estatus-incapacidad/${pk}/${idCliente}`
  );
  const data = result.data;
  if (data.detail) {
    console.log(
      `Error al eliminar CambioEstatusIncapacidad con pk: ${pk}, ${data.detail}`
    );
    temp.estado = DEFAULT_STATE.error;
    temp.mensaje = `Error al eliminar la datos personales: ${data.detail}`;
    temp.data = await getStorageCambioEstatusIncapacidadArrayItem(pk);
    temp.data_array = await getStorageCambioEstatusIncapacidadArray();
    return temp;
  }
  console.log(`CambioEstatusIncapacidad eliminada con éxito`);
  temp.estado = DEFAULT_STATE.success;
  temp.mensaje = `CambioEstatusIncapacidad eliminada con éxito`;
  await removeStorageCambioEstatusIncapacidad();
  temp.data_array = await removeArrayItemStorageData(
    "ICambioEstatusIncapacidad",
    pk,
    []
  );
  return temp;
};
