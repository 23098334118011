/**
 * Métodos del, get, post, put para llamadas a la API
 * @get     en este método primero consulta con @getFromLocalStorage si es necesario hacer la petición http sino se obtiene del LocalStorage;
 *          si hay un error en la petición http se busca el objeto en el LocalStorage sino lo encuentra devuelve un objeto vacío; si la
 *          petición se realiza correctamente actualiza el valor individual y en el array del objeto en el LocalStorage y devuelve el objeto
 *          obtenido del servidor.
 * @getAll  en este método primero consulta con @getFromLocalStorage si es necesario hacer la petición http sino se obtiene del LocalStorage;
 *          si hay un error en la petición http se busca el objeto array en el LocalStorage sino lo encuentra devuelve un array vacío; si la
 *          petición se realiza correctamente actualiza array en el LocalStorage y devuelve el array obtenido del servidor
 * @update  en este método después de realizar la petición http si falla devuelve el objeto que se iba a actualizar; si la petición se
 *          realiza correctamente actualiza el valor individual y en el array del objeto en el LocalStorage y devuelve el objeto obtenido del
 *          servidor.
 * @create  en este método después de realizar la petición http si falla devuelve el objeto que se iba a crear; si la petición se realiza
 *          correctamente actualiza el valor individual y en el array del objeto en el LocalStorage y devuelve el objeto obtenido del
 *          servidor.
 * @delete  en este método después de realizar la petición http si falla devuelve el objeto que se iba a eliminar; si la petición se realiza
 *          correctamente elimina el valor individual y en el objeto en el arrya del LocalStorage y devuelve un objeto vacio (Default)
 */

import { del, get, post, put } from "../../api/api";
import {
  getFromLocalStorage,
  updateLocalStorageExpiry,
} from "../../api/helpers";
import { removeArrayItemStorageData } from "../../api/storage";
import { DEFAULT_STATE } from "../../enums/default_state";
import {
  getEmptyStatusMvTipoNotificacion,
  setMvTipoNotificacion,
  IMvTipoNotificacion,
  getSerializableMvTipoNotificacion,
} from "../../interfaces/nomina";
import { IServerDate } from "../../interfaces/time";
import {
  getStorageMvTipoNotificacionArrayItem,
  setStorageMvTipoNotificacion,
  setStorageMvTipoNotificacionArrayItem,
  getStorageMvTipoNotificacionArray,
  setStorageMvTipoNotificacionArray,
  removeStorageMvTipoNotificacion,
} from "../../localStorage/nomina";

export const getMvTipoNotificacion = async (
  serverDate: IServerDate,
  pk: number
) => {
  const temp = getEmptyStatusMvTipoNotificacion();
  const doHttp = await getFromLocalStorage(serverDate, temp.expiry);
  temp.expiry = doHttp.storage;
  if (!doHttp.result) {
    console.log(
      `(localStorage) MvTipoNotificacion con pk: ${pk}, obtenido con éxito`
    );
    temp.estado = DEFAULT_STATE.success;
    temp.mensaje = `MvTipoNotificacion obtenido con éxito`;
    temp.data = await getStorageMvTipoNotificacionArrayItem(pk);
    return temp;
  }
  const result = await get(`nomina/tipo-notificacion/${pk}`);
  const data = result.data;
  console.log(`MvTipoNotificacion data: `, data);
  if (!data.id || data.detail) {
    console.log(
      `Error al obtener MvTipoNotificacion con pk: ${pk}, ${data.detail}`
    );
    temp.estado = DEFAULT_STATE.error;
    temp.mensaje = `Error al obtener el MvTipoNotificacion: ${
      data.detail ?? "Hubo un problema al conectarse con el servidor"
    }`;
    temp.data = await getStorageMvTipoNotificacionArrayItem(pk);
    return temp;
  }
  console.log(`MvTipoNotificacion con pk: ${pk}, obtenido con éxito`);
  temp.estado = DEFAULT_STATE.success;
  temp.mensaje = `MvTipoNotificacion obtenido con éxito`;
  temp.data = await setMvTipoNotificacion(data, serverDate);
  await setStorageMvTipoNotificacion(temp.data);
  temp.data_array = await setStorageMvTipoNotificacionArrayItem(pk, temp.data);
  temp.expiry = await updateLocalStorageExpiry(serverDate, temp.expiry);
  return temp;
};

export const getAllMvTipoNotificacion = async (serverDate: IServerDate) => {
  const temp = getEmptyStatusMvTipoNotificacion();
  const doHttp = await getFromLocalStorage(serverDate, temp.expiry_array);
  temp.expiry_array = doHttp.storage;
  if (!doHttp.result) {
    console.log(
      `(localStorage) Todos los MvTipoNotificacions obtenidos con éxito`
    );
    temp.estado = DEFAULT_STATE.success;
    temp.mensaje = `Todos los MvTipoNotificacion obtenidos con éxito`;
    temp.data_array = await getStorageMvTipoNotificacionArray();
    return temp;
  }
  const result = await get(`nomina/tipo-notificacion`);
  const data = result.data;
  if (data.detail) {
    console.log(
      `Error al obtener la lista de MvTipoNotificacion, ${data.detail}`
    );
    temp.estado = DEFAULT_STATE.error;
    temp.mensaje = `Error al obtener la lista de MvTipoNotificacion: ${
      data.detail ?? "Hubo un problema al conectarse con el servidor"
    }`;
    temp.data_array = await getStorageMvTipoNotificacionArray();
    return temp;
  }
  console.log(`Todos los MvTipoNotificacion obtenidos con éxito`, data);
  temp.estado = DEFAULT_STATE.success;
  temp.mensaje = `Todos los MvTipoNotificacion obtenidos con éxito`;
  temp.data_array = await Promise.all(
    data.map(async (e: any) => await setMvTipoNotificacion(e, serverDate))
  );
  await setStorageMvTipoNotificacionArray(temp.data_array);
  temp.expiry_array = await updateLocalStorageExpiry(
    serverDate,
    temp.expiry_array
  );
  return temp;
};

export const updateMvTipoNotificacion = async (
  serverDate: IServerDate,
  pk: number,
  object: IMvTipoNotificacion
) => {
  const temp = getEmptyStatusMvTipoNotificacion();
  const result = await put(
    `nomina/tipo-notificacion/${pk}`,
    getSerializableMvTipoNotificacion(object)
  );
  const data = result.data;
  if (!data.id || data.detail) {
    console.log(
      `Error al actualizar MvTipoNotificacion con pk: ${pk}, ${data.detail}`
    );
    temp.estado = DEFAULT_STATE.error;
    temp.mensaje = `Error al actualizar el MvTipoNotificacion: ${
      data.detail ?? "Hubo un problema al conectarse con el servidor"
    }`;
    temp.data = await setMvTipoNotificacion(object, serverDate);
    return temp;
  }
  console.log(`MvTipoNotificacion con pk: ${pk}, actualizado con éxito`);
  temp.estado = DEFAULT_STATE.success;
  temp.mensaje = `MvTipoNotificacion actualizado con éxito`;
  temp.data = await setMvTipoNotificacion(data, serverDate);
  await setStorageMvTipoNotificacion(temp.data);
  temp.data_array = await setStorageMvTipoNotificacionArrayItem(
    temp.data.id,
    temp.data
  );
  return temp;
};

export const createMvTipoNotificacion = async (
  serverDate: IServerDate,
  object: IMvTipoNotificacion
) => {
  const temp = getEmptyStatusMvTipoNotificacion();
  const result = await post(
    `nomina/tipo-notificacion`,
    getSerializableMvTipoNotificacion(object)
  );
  const data = result.data;
  if (!data.id || data.detail) {
    console.log(
      `Error al crear MvTipoNotificacion con los datos ${object}, ${data.detail}`
    );
    temp.estado = DEFAULT_STATE.error;
    temp.mensaje = `Error al crear el MvTipoNotificacion: ${
      data.detail ?? "Hubo un problema al conectarse con el servidor"
    }`;
    temp.data = await setMvTipoNotificacion(object, serverDate);
    return temp;
  }
  console.log(`MvTipoNotificacion creado con éxito`);
  temp.estado = DEFAULT_STATE.success;
  temp.mensaje = `MvTipoNotificacion creado con éxito`;
  temp.data = await setMvTipoNotificacion(data, serverDate);
  await setStorageMvTipoNotificacion(temp.data);
  temp.data_array = await setStorageMvTipoNotificacionArrayItem(
    temp.data.id,
    temp.data
  );
  return temp;
};

export const deleteMvTipoNotificacion = async (pk: number) => {
  const temp = getEmptyStatusMvTipoNotificacion();
  const result = await del(`nomina/tipo-notificacion/${pk}`);
  const data = result.data;
  if (data.detail) {
    console.log(
      `Error al eliminar MvTipoNotificacion con pk: ${pk}, ${data.detail}`
    );
    temp.estado = DEFAULT_STATE.error;
    temp.mensaje = `Error al eliminar el datos fiscales: ${data.detail}`;
    temp.data = await getStorageMvTipoNotificacionArrayItem(pk);
    temp.data_array = await getStorageMvTipoNotificacionArray();
    return temp;
  }
  console.log(`MvTipoNotificacion eliminado con éxito`);
  temp.estado = DEFAULT_STATE.success;
  temp.mensaje = `MvTipoNotificacion eliminado con éxito`;
  await removeStorageMvTipoNotificacion();
  temp.data_array = await removeArrayItemStorageData(
    "IMvTipoNotificacion",
    pk,
    []
  );
  return temp;
};
