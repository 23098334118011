/** 
 * Componente que se encarga del elemento Incapacidad
 * @IIncapacidad es la interfaz que contiene los parámetos el elemento Incapacidad
 * @getEmptyIncapacidad  devuelve un objeto con los parámetros por defecto; @param {id}, opcional cuando no encuentra el objeto en el LocalSotrage
 * @IStatusIncapacidad es la interfaz para los estatus a llamadas a la API; @param {status}, opcional cuando queremos otro estado de inizialización de tipo [DEFAULT_STATE]
 * @getEmptyStatusIncapacidad devuelve un objeto con los parámetros por defecto,
 * @setIncapacidad devuelve un objeto de tipo [IIncapacidad] recibiendo @param {object} y @param {serverDate}, para verificar que los datos estén en el formato correcto y ajustarlos a la zona horaria actual
 * @getSerializableIncapacidad recibe un @param {IIncapacidad}, para devolver un objeto serizalizable para las peticiones de la API
 * */

import { getDateString, isNotEmpty, SERVER_TIME_ZONE } from "../../api/helpers";
import { DEFAULT_STATE } from "../../enums/default_state";
import { getStorageMvMotivoIncapacidadArrayItem, getStorageMvEstatusIncapacidadesArrayItem } from "../../localStorage/nomina";
import { getStorageEmpleadoArrayItem, getStorageDocumentoArrayItem, getStorageUserArrayItem, getStorageClienteQentryArrayItem } from "../../localStorage/personas";
import { getEmptyClienteQentry, getEmptyDocumento, getEmptyEmpleado, getEmptyUser, IClienteQentry, IDocumento, IEmpleado, IUser, setClienteQentry, setDocumento, setEmpleado, setUser } from "../personas";
import { ILocalStorageExpiry, getEmptyLocalStorageExpiry, IServerDate, convertDateToZonaHoraria } from "../time";
import { getEmptyMvEstatusIncapacidades, IMvEstatusIncapacidades, setMvEstatusIncapacidades } from "./MvEstatusIncapacidades";
import { getEmptyMvMotivoIncapacidad, IMvMotivoIncapacidad, setMvMotivoIncapacidad } from "./MvMotivoIncapacidad";

export interface IIncapacidad {
  id : number,
  empleado : IEmpleado,
  motivo_incapacidad : IMvMotivoIncapacidad,
  estatus_incapacidad : IMvEstatusIncapacidades,
  fecha_inicio_incapacidad : string, // Fecha
  fecha_fin_incapacidad : string, // Fecha
  dias_a_tomar : number,
  monto_a_pagar : number, // Decimal
  comprobante : IDocumento,
  comentarios_aprobador : string,
  autor : IUser,
  fecha_elaboracion : string, // Fecha
  estatus_sistema : boolean,
  ultima_actualizacion : string, // Fecha
  cliente_qentry : IClienteQentry,
}

export const getEmptyIncapacidad = (id? : number) => {
  const temp : IIncapacidad = 
  {
    id : id ? id : 0,
    empleado : getEmptyEmpleado(),
    motivo_incapacidad : getEmptyMvMotivoIncapacidad(),
    estatus_incapacidad : getEmptyMvEstatusIncapacidades(),
    fecha_inicio_incapacidad : "", // Fecha
    fecha_fin_incapacidad : "", // Fecha
    dias_a_tomar : 0,
    monto_a_pagar : 0, // Decimal
    comprobante : getEmptyDocumento(),
    comentarios_aprobador : "",
    autor : getEmptyUser(),
    fecha_elaboracion : "",
    estatus_sistema : false,
    ultima_actualizacion : "",
    cliente_qentry : getEmptyClienteQentry(),
  };
  return temp;
}

export interface IStatusIncapacidad {
  estado : DEFAULT_STATE,
  mensaje : string,
  data : IIncapacidad,
  data_array: IIncapacidad[],
  expiry : ILocalStorageExpiry,
  expiry_array : ILocalStorageExpiry,
}

export const getEmptyStatusIncapacidad = (status: DEFAULT_STATE = DEFAULT_STATE.loading) => {
  const temp : IStatusIncapacidad = 
  {
    estado : status,
    mensaje : "",
    data : getEmptyIncapacidad(),
    data_array : [],
    expiry : getEmptyLocalStorageExpiry("IIncapacidad", 0),
    expiry_array : getEmptyLocalStorageExpiry("IIncapacidadArray", 0),
  };
  return temp;
}

export const setIncapacidad = async (
  object : any,
  serverDate : IServerDate,
) => {
  const temp = getEmptyIncapacidad();
  if (isNotEmpty(object.id)) temp.id = object.id;
  if (isNotEmpty(object.empleado)) temp.empleado = (typeof object.empleado === 'number') ? await getStorageEmpleadoArrayItem(object.empleado) : await setEmpleado(object.empleado, serverDate);
  if (isNotEmpty(object.motivo_incapacidad)) temp.motivo_incapacidad = (typeof object.motivo_incapacidad === 'number') ? await getStorageMvMotivoIncapacidadArrayItem(object.motivo_incapacidad) : await setMvMotivoIncapacidad(object.motivo_incapacidad, serverDate);
  if (isNotEmpty(object.estatus_incapacidad)) temp.estatus_incapacidad = (typeof object.estatus_incapacidad === 'number') ? await getStorageMvEstatusIncapacidadesArrayItem(object.estatus_incapacidad) : await setMvEstatusIncapacidades(object.estatus_incapacidad, serverDate);
  if (isNotEmpty(object.fecha_inicio_incapacidad)) temp.fecha_inicio_incapacidad = convertDateToZonaHoraria(serverDate, object.fecha_inicio_incapacidad);
  if (isNotEmpty(object.fecha_fin_incapacidad)) temp.fecha_fin_incapacidad = convertDateToZonaHoraria(serverDate, object.fecha_fin_incapacidad);
  if (isNotEmpty(object.dias_a_tomar)) temp.dias_a_tomar = object.dias_a_tomar;
  if (isNotEmpty(object.monto_a_pagar)) temp.monto_a_pagar = Number(object.monto_a_pagar);
  if (isNotEmpty(object.comprobante)) temp.comprobante = (typeof object.comprobante === 'number') ? await getStorageDocumentoArrayItem(object.comprobante) : await setDocumento(object.comprobante, serverDate);
  if (isNotEmpty(object.comentarios_aprobador)) temp.comentarios_aprobador = object.comentarios_aprobador;
  if (isNotEmpty(object.autor)) temp.autor = (typeof object.autor === 'number') ? await getStorageUserArrayItem(object.autor) : await setUser(object.autor, serverDate);
  if (isNotEmpty(object.fecha_elaboracion)) temp.fecha_elaboracion = convertDateToZonaHoraria(serverDate, object.fecha_elaboracion);
  if (isNotEmpty(object.estatus_sistema)) temp.estatus_sistema = object.estatus_sistema;
  if (isNotEmpty(object.ultima_actualizacion)) temp.ultima_actualizacion = convertDateToZonaHoraria(serverDate, object.ultima_actualizacion);
  if (isNotEmpty(object.cliente_qentry)) temp.cliente_qentry = (typeof object.cliente_qentry === 'number') ? await getStorageClienteQentryArrayItem(object.cliente_qentry) : await setClienteQentry(object.cliente_qentry, serverDate);
  return temp;
}

export const getSerializableIncapacidad = (IIncapacidad : IIncapacidad ) => {
  return {
    id : IIncapacidad.id,
    empleado : IIncapacidad.empleado.id,
    motivo_incapacidad : IIncapacidad.motivo_incapacidad.id,
    estatus_incapacidad : IIncapacidad.estatus_incapacidad.id,
    fecha_inicio_incapacidad : getDateString(SERVER_TIME_ZONE, IIncapacidad.fecha_inicio_incapacidad), // Fecha
    fecha_fin_incapacidad : getDateString(SERVER_TIME_ZONE, IIncapacidad.fecha_fin_incapacidad), // Fecha
    dias_a_tomar : IIncapacidad.dias_a_tomar,
    monto_a_pagar : IIncapacidad.monto_a_pagar.toFixed(2), // Decimal
    comprobante : IIncapacidad.comprobante.id,
    comentarios_aprobador : IIncapacidad.comentarios_aprobador,
    autor : IIncapacidad.autor.id,
    fecha_elaboracion : IIncapacidad.fecha_elaboracion,
    estatus_sistema : IIncapacidad.estatus_sistema,
    ultima_actualizacion : getDateString(SERVER_TIME_ZONE),
    cliente_qentry : IIncapacidad.cliente_qentry.id,
  };
}
