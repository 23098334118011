/** 
 * Componente que se encarga del elemento MvEstadoCivil
 * @IMvEstadoCivil es la interfaz que contiene los parámetos el elemento MvEstadoCivil
 * @getEmptyMvEstadoCivil  devuelve un objeto con los parámetros por defecto; @param {id}, opcional cuando no encuentra el objeto en el LocalSotrage
 * @IStatusMvEstadoCivil es la interfaz para los estatus a llamadas a la API; @param {status}, opcional cuando queremos otro estado de inizialización de tipo [DEFAULT_STATE]
 * @getEmptyStatusMvEstadoCivil devuelve un objeto con los parámetros por defecto,
 * @setMvEstadoCivil devuelve un objeto de tipo [IMvEstadoCivil] recibiendo @param {object} y @param {serverDate}, para verificar que los datos estén en el formato correcto y ajustarlos a la zona horaria actual
 * @getSerializableMvEstadoCivil recibe un @param {IMvEstadoCivil}, para devolver un objeto serizalizable para las peticiones de la API
 * */

import { getDateString, isNotEmpty, SERVER_TIME_ZONE } from "../../api/helpers";
import { DEFAULT_STATE } from "../../enums/default_state";
import { getStorageUserArrayItem } from "../../localStorage/personas";
import { convertDateToZonaHoraria, ILocalStorageExpiry, getEmptyLocalStorageExpiry, IServerDate } from "../time";
import { IUser, getEmptyUser, setUser } from "./IUser";

export interface IMvEstadoCivil {
  id : number,
  dato : string,
  autor : IUser,
  fecha_elaboracion : string, // Fecha
  estatus_sistema : boolean,
  ultima_actualizacion : string, // Fecha
}

export const getEmptyMvEstadoCivil = (id? : number) => {
  const temp : IMvEstadoCivil = 
  {
    id : id ? id : 0,
    dato : "",
    autor : getEmptyUser(),
    fecha_elaboracion : "",
    estatus_sistema : false,
    ultima_actualizacion :  "",
  };
  return temp;
}

export interface IStatusMvEstadoCivil {
  estado : DEFAULT_STATE,
  mensaje : string,
  data : IMvEstadoCivil,
  data_array: IMvEstadoCivil[],
  expiry : ILocalStorageExpiry,
  expiry_array : ILocalStorageExpiry,
}

export const getEmptyStatusMvEstadoCivil = (status: DEFAULT_STATE = DEFAULT_STATE.loading) => {
  const temp : IStatusMvEstadoCivil = 
  {
    estado : status,
    mensaje : "",
    data : getEmptyMvEstadoCivil(),
    data_array : [],
    expiry : getEmptyLocalStorageExpiry("IMvEstadoCivil", 0),
    expiry_array : getEmptyLocalStorageExpiry("IMvEstadoCivilArray", 0),
  };
  return temp;
}

export const setMvEstadoCivil = async (
  object : any,
  serverDate : IServerDate,
) => {
  const temp = getEmptyMvEstadoCivil();
  if (isNotEmpty(object.id)) temp.id = object.id;
  if (isNotEmpty(object.dato)) temp.dato = object.dato;
  if (isNotEmpty(object.autor)) temp.autor = (typeof object.autor === 'number') ? await getStorageUserArrayItem(object.autor) : await setUser(object.autor, serverDate);
  if (isNotEmpty(object.fecha_elaboracion)) temp.fecha_elaboracion = convertDateToZonaHoraria(serverDate, object.fecha_elaboracion);
  if (isNotEmpty(object.estatus_sistema)) temp.estatus_sistema = object.estatus_sistema;
  if (isNotEmpty(object.ultima_actualizacion)) temp.ultima_actualizacion = convertDateToZonaHoraria(serverDate, object.ultima_actualizacion);
  return temp;
}

export const getSerializableMvEstadoCivil = (IMvEstadoCivil : IMvEstadoCivil) => {
  return {
    id : IMvEstadoCivil.id,
    dato : IMvEstadoCivil.dato,
    autor : IMvEstadoCivil.autor.id,
    fecha_elaboracion : IMvEstadoCivil.fecha_elaboracion,
    estatus_sistema : IMvEstadoCivil.estatus_sistema,
    ultima_actualizacion : getDateString(SERVER_TIME_ZONE),
  };
}
