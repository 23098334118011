import { getArrayItemStorageData, getStorageData, removeArrayItemStorageData, removeStorageData, setArrayItemStorageData, setStorageData } from "../../api/storage";
import { getEmptyDatosFiscales, IDatosFiscales } from "../../interfaces/personas";

export const setStorageDatosFiscales = async (IDatosFiscales : IDatosFiscales) => await setStorageData('IDatosFiscales', IDatosFiscales);
export const getStorageDatosFiscales = async () : Promise<IDatosFiscales> => await getStorageData('IDatosFiscales', getEmptyDatosFiscales());
export const removeStorageDatosFiscales = async () => await removeStorageData('IDatosFiscales');
export const setStorageDatosFiscalesArray = async (IDatosFiscales : IDatosFiscales[]) => await setStorageData('IDatosFiscalesArray', IDatosFiscales);
export const getStorageDatosFiscalesArray = async () : Promise<IDatosFiscales[]> => await getStorageData('IDatosFiscalesArray', [] as IDatosFiscales[]);
export const removeStorageDatosFiscalesArray = async () => await removeStorageData('IDatosFiscalesArray');
export const setStorageDatosFiscalesArrayItem = async (id: number, IDatosFiscales : IDatosFiscales) : Promise<IDatosFiscales[]> => await setArrayItemStorageData('IDatosFiscalesArray', id, IDatosFiscales, [] as IDatosFiscales[]);
export const getStorageDatosFiscalesArrayItem = async (id: number) : Promise<IDatosFiscales> => await getArrayItemStorageData('IDatosFiscalesArray', id, getEmptyDatosFiscales);
export const removeStorageDatosFiscalesArrayItem = async (id: number) : Promise<IDatosFiscales[]> => await removeArrayItemStorageData('IDatosFiscalesArray', id, [] as IDatosFiscales[]);
