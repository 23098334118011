/** 
 * Componente que se encarga del elemento MvGenero
 * @IMvGenero es la interfaz que contiene los parámetos el elemento MvGenero
 * @getEmptyMvGenero  devuelve un objeto con los parámetros por defecto; @param {id}, opcional cuando no encuentra el objeto en el LocalSotrage
 * @IStatusMvGenero es la interfaz para los estatus a llamadas a la API; @param {status}, opcional cuando queremos otro estado de inizialización de tipo [DEFAULT_STATE]
 * @getEmptyStatusMvGenero devuelve un objeto con los parámetros por defecto,
 * @setMvGenero devuelve un objeto de tipo [IMvGenero] recibiendo @param {object} y @param {serverDate}, para verificar que los datos estén en el formato correcto y ajustarlos a la zona horaria actual
 * @getSerializableMvGenero recibe un @param {IMvGenero}, para devolver un objeto serizalizable para las peticiones de la API
 * */

import { getDateString, isNotEmpty, SERVER_TIME_ZONE } from "../../api/helpers";
import { DEFAULT_STATE } from "../../enums/default_state";
import { getStorageUserArrayItem } from "../../localStorage/personas";
import { convertDateToZonaHoraria, ILocalStorageExpiry, getEmptyLocalStorageExpiry, IServerDate } from "../time";
import { IUser, getEmptyUser, setUser } from "./IUser";

export interface IMvGenero {
  id : number,
  dato : string,
  autor : IUser,
  fecha_elaboracion : string, // Fecha
  estatus_sistema : boolean,
  ultima_actualizacion : string, // Fecha
}

export const getEmptyMvGenero = (id? : number) => {
  const temp : IMvGenero = 
  {
    id : id ? id : 0,
    dato : "",
    autor : getEmptyUser(),
    fecha_elaboracion : "",
    estatus_sistema : false,
    ultima_actualizacion :  "",
  };
  return temp;
}

export interface IStatusMvGenero {
  estado : DEFAULT_STATE,
  mensaje : string,
  data : IMvGenero,
  data_array: IMvGenero[],
  expiry : ILocalStorageExpiry,
  expiry_array : ILocalStorageExpiry,
}

export const getEmptyStatusMvGenero = (status: DEFAULT_STATE = DEFAULT_STATE.loading) => {
  const temp : IStatusMvGenero = 
  {
    estado : status,
    mensaje : "",
    data : getEmptyMvGenero(),
    data_array : [],
    expiry : getEmptyLocalStorageExpiry("IMvGenero", 0),
    expiry_array : getEmptyLocalStorageExpiry("IMvGeneroArray", 0),
  };
  return temp;
}

export const setMvGenero = async (
  object : any,
  serverDate : IServerDate,
) => {
  const temp = getEmptyMvGenero();
  if (isNotEmpty(object.id)) temp.id = object.id;
  if (isNotEmpty(object.dato)) temp.dato = object.dato;
  if (isNotEmpty(object.autor)) temp.autor = (typeof object.autor === 'number') ? await getStorageUserArrayItem(object.autor) : await setUser(object.autor, serverDate);
  if (isNotEmpty(object.fecha_elaboracion)) temp.fecha_elaboracion = convertDateToZonaHoraria(serverDate, object.fecha_elaboracion);
  if (isNotEmpty(object.estatus_sistema)) temp.estatus_sistema = object.estatus_sistema;
  if (isNotEmpty(object.ultima_actualizacion)) temp.ultima_actualizacion = convertDateToZonaHoraria(serverDate, object.ultima_actualizacion);
  return temp;
}

export const getSerializableMvGenero = (IMvGenero : IMvGenero) => {
  return {
    id : IMvGenero.id,
    dato : IMvGenero.dato,
    autor : IMvGenero.autor.id,
    fecha_elaboracion : IMvGenero.fecha_elaboracion,
    estatus_sistema : IMvGenero.estatus_sistema,
    ultima_actualizacion : getDateString(SERVER_TIME_ZONE),
  };
}
