/**
 * Reducers creados para obtener asíncronamente 
 * los datos de los modelos y dependiendo del resultado
 * de la peticón actualice el state en redux o mande un mensaje de error
 */

import { PayloadAction } from "@reduxjs/toolkit";
import { getMvEstatusSolicitudCambioTelefonoAutorizadoAsyncThunk, getAllMvEstatusSolicitudCambioTelefonoAutorizadoAsyncThunk, updateMvEstatusSolicitudCambioTelefonoAutorizadoAsyncThunk, createMvEstatusSolicitudCambioTelefonoAutorizadoAsyncThunk, deleteMvEstatusSolicitudCambioTelefonoAutorizadoAsyncThunk } from "../../asyncThunk/personas";
import { DEFAULT_STATE } from "../../enums/default_state";
import { IStatusMvEstatusSolicitudCambioTelefonoAutorizado } from "../../interfaces/personas";
import { personasInitialState } from "../../slices/personasSlice";

// Reducers: MvEstatusSolicitudCambioTelefonoAutorizado
export const createMvEstatusSolicitudCambioTelefonoAutorizadoReducers = () => {
  const get = () => {
    var { pending, fulfilled, rejected } = getMvEstatusSolicitudCambioTelefonoAutorizadoAsyncThunk;
    return {
      [pending.type]: (state : typeof personasInitialState) => {
        state.personasState.mvEstatusSolicitudCambioTelefonoAutorizado.estado = DEFAULT_STATE.loading;
      },
      [fulfilled.type]: (state : typeof personasInitialState, action : PayloadAction<IStatusMvEstatusSolicitudCambioTelefonoAutorizado>) => {
        const result = action.payload;
        result.expiry_array = state.personasState.mvEstatusSolicitudCambioTelefonoAutorizado.expiry_array;
        if (result.estado === DEFAULT_STATE.error) result.data_array = state.personasState.mvEstatusSolicitudCambioTelefonoAutorizado.data_array;
        state.personasState.mvEstatusSolicitudCambioTelefonoAutorizado = result;
      },
      [rejected.type]: (state : typeof personasInitialState, action : PayloadAction<IStatusMvEstatusSolicitudCambioTelefonoAutorizado>) => {
        state.personasState.mvEstatusSolicitudCambioTelefonoAutorizado = {
          ...state.personasState.mvEstatusSolicitudCambioTelefonoAutorizado,
          estado: action.payload.estado,
          mensaje: "Ocurrió un problema al realizar la solicitud",
        };
      },
    };
  };
  
  const getAll = () => {
    var { pending, fulfilled, rejected } = getAllMvEstatusSolicitudCambioTelefonoAutorizadoAsyncThunk;
    return {
      [pending.type]: (state : typeof personasInitialState) => {
        state.personasState.mvEstatusSolicitudCambioTelefonoAutorizado.estado = DEFAULT_STATE.loading;
      },
      [fulfilled.type]: (state : typeof personasInitialState, action : PayloadAction<IStatusMvEstatusSolicitudCambioTelefonoAutorizado>) => {
        const result = action.payload;
        result.data = state.personasState.mvEstatusSolicitudCambioTelefonoAutorizado.data;
        result.expiry = state.personasState.mvEstatusSolicitudCambioTelefonoAutorizado.expiry;
        if (result.estado === DEFAULT_STATE.error) result.data_array = state.personasState.mvEstatusSolicitudCambioTelefonoAutorizado.data_array;
        state.personasState.mvEstatusSolicitudCambioTelefonoAutorizado = result;
      },
      [rejected.type]: (state : typeof personasInitialState, action : PayloadAction<IStatusMvEstatusSolicitudCambioTelefonoAutorizado>) => {
        state.personasState.mvEstatusSolicitudCambioTelefonoAutorizado = {
          ...state.personasState.mvEstatusSolicitudCambioTelefonoAutorizado,
          estado: action.payload.estado,
          mensaje: "Ocurrió un problema al realizar la solicitud",
        };
      },
    };
  };
  
  const update = () => {
    var { pending, fulfilled, rejected } = updateMvEstatusSolicitudCambioTelefonoAutorizadoAsyncThunk;
    return {
      [pending.type]: (state : typeof personasInitialState) => {
        state.personasState.mvEstatusSolicitudCambioTelefonoAutorizado.estado = DEFAULT_STATE.loading;
      },
      [fulfilled.type]: (state : typeof personasInitialState, action : PayloadAction<IStatusMvEstatusSolicitudCambioTelefonoAutorizado>) => {
        const result: IStatusMvEstatusSolicitudCambioTelefonoAutorizado = action.payload;
        result.expiry = state.personasState.mvEstatusSolicitudCambioTelefonoAutorizado.expiry;
        result.expiry_array = state.personasState.mvEstatusSolicitudCambioTelefonoAutorizado.expiry_array;
        if (result.estado === DEFAULT_STATE.error) result.data_array = state.personasState.mvEstatusSolicitudCambioTelefonoAutorizado.data_array;
        state.personasState.mvEstatusSolicitudCambioTelefonoAutorizado = result;
      },
      [rejected.type]: (state : typeof personasInitialState, action : PayloadAction<IStatusMvEstatusSolicitudCambioTelefonoAutorizado>) => {
        state.personasState.mvEstatusSolicitudCambioTelefonoAutorizado = {
          ...state.personasState.mvEstatusSolicitudCambioTelefonoAutorizado,
          estado: action.payload.estado,
          mensaje: "Ocurrió un problema al realizar la solicitud",
        };
      },
    };
  };

  const create = () => {
    var { pending, fulfilled, rejected } = createMvEstatusSolicitudCambioTelefonoAutorizadoAsyncThunk;
    return {
      [pending.type]: (state : typeof personasInitialState) => {
        state.personasState.mvEstatusSolicitudCambioTelefonoAutorizado.estado = DEFAULT_STATE.loading;
      },
      [fulfilled.type]: (state : typeof personasInitialState, action : PayloadAction<IStatusMvEstatusSolicitudCambioTelefonoAutorizado>) => {
        const result = action.payload;
        result.expiry = state.personasState.mvEstatusSolicitudCambioTelefonoAutorizado.expiry;
        result.expiry_array = state.personasState.mvEstatusSolicitudCambioTelefonoAutorizado.expiry_array;
        if (result.estado === DEFAULT_STATE.error) result.data_array = state.personasState.mvEstatusSolicitudCambioTelefonoAutorizado.data_array;
        state.personasState.mvEstatusSolicitudCambioTelefonoAutorizado = result;
      },
      [rejected.type]: (state : typeof personasInitialState, action : PayloadAction<IStatusMvEstatusSolicitudCambioTelefonoAutorizado>) => {
        state.personasState.mvEstatusSolicitudCambioTelefonoAutorizado = {
          ...state.personasState.mvEstatusSolicitudCambioTelefonoAutorizado,
          estado: action.payload.estado,
          mensaje: "Ocurrió un problema al realizar la solicitud",
        };
      },
    };
  };
  
  const del = () => {
    var { pending, fulfilled, rejected } = deleteMvEstatusSolicitudCambioTelefonoAutorizadoAsyncThunk;
    return {
      [pending.type]: (state : typeof personasInitialState) => {
        state.personasState.mvEstatusSolicitudCambioTelefonoAutorizado.estado = DEFAULT_STATE.loading;
      },
      [fulfilled.type]: (state : typeof personasInitialState, action : PayloadAction<IStatusMvEstatusSolicitudCambioTelefonoAutorizado>) => {
        const result: IStatusMvEstatusSolicitudCambioTelefonoAutorizado = action.payload;
        result.expiry = state.personasState.mvEstatusSolicitudCambioTelefonoAutorizado.expiry;
        result.expiry_array = state.personasState.mvEstatusSolicitudCambioTelefonoAutorizado.expiry_array;
        if (result.estado === DEFAULT_STATE.error) result.data_array = state.personasState.mvEstatusSolicitudCambioTelefonoAutorizado.data_array;
        state.personasState.mvEstatusSolicitudCambioTelefonoAutorizado = result;
      },
      [rejected.type]: (state : typeof personasInitialState, action : PayloadAction<IStatusMvEstatusSolicitudCambioTelefonoAutorizado>) => {
        state.personasState.mvEstatusSolicitudCambioTelefonoAutorizado = {
          ...state.personasState.mvEstatusSolicitudCambioTelefonoAutorizado,
          estado: action.payload.estado,
          mensaje: "Ocurrió un problema al realizar la solicitud",
        };
      },
    };
  };

  return {
    ...get(),
    ...getAll(),
    ...update(),
    ...create(),
    ...del(),
  };
};
