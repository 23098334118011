/**
 * Métodos del, get, post, put para llamadas a la API
 * @get     en este método primero consulta con @getFromLocalStorage si es necesario hacer la petición http sino se obtiene del LocalStorage;
 *          si hay un error en la petición http se busca el objeto en el LocalStorage sino lo encuentra devuelve un objeto vacío; si la
 *          petición se realiza correctamente actualiza el valor individual y en el array del objeto en el LocalStorage y devuelve el objeto
 *          obtenido del servidor.
 * @getAll  en este método primero consulta con @getFromLocalStorage si es necesario hacer la petición http sino se obtiene del LocalStorage;
 *          si hay un error en la petición http se busca el objeto array en el LocalStorage sino lo encuentra devuelve un array vacío; si la
 *          petición se realiza correctamente actualiza array en el LocalStorage y devuelve el array obtenido del servidor
 * @update  en este método después de realizar la petición http si falla devuelve el objeto que se iba a actualizar; si la petición se
 *          realiza correctamente actualiza el valor individual y en el array del objeto en el LocalStorage y devuelve el objeto obtenido del
 *          servidor.
 * @create  en este método después de realizar la petición http si falla devuelve el objeto que se iba a crear; si la petición se realiza
 *          correctamente actualiza el valor individual y en el array del objeto en el LocalStorage y devuelve el objeto obtenido del
 *          servidor.
 * @delete  en este método después de realizar la petición http si falla devuelve el objeto que se iba a eliminar; si la petición se realiza
 *          correctamente elimina el valor individual y en el objeto en el arrya del LocalStorage y devuelve un objeto vacio (Default)
 */

import { del, get, post, put } from "../../api/api";
import {
  getFromLocalStorage,
  updateLocalStorageExpiry,
} from "../../api/helpers";
import { removeArrayItemStorageData } from "../../api/storage";
import { DEFAULT_STATE } from "../../enums/default_state";
import {
  getEmptyStatusMvTipoEmpleado,
  setMvTipoEmpleado,
  IMvTipoEmpleado,
  getSerializableMvTipoEmpleado,
} from "../../interfaces/personas";
import { IServerDate } from "../../interfaces/time";
import {
  getStorageMvTipoEmpleadoArrayItem,
  setStorageMvTipoEmpleado,
  setStorageMvTipoEmpleadoArrayItem,
  getStorageMvTipoEmpleadoArray,
  setStorageMvTipoEmpleadoArray,
  removeStorageMvTipoEmpleado,
} from "../../localStorage/personas";

export const getMvTipoEmpleado = async (
  serverDate: IServerDate,
  pk: number
) => {
  const temp = getEmptyStatusMvTipoEmpleado();
  const doHttp = await getFromLocalStorage(serverDate, temp.expiry);
  temp.expiry = doHttp.storage;
  if (!doHttp.result) {
    console.log(
      `(localStorage) MvTipoEmpleado con pk: ${pk}, obtenido con éxito`
    );
    temp.estado = DEFAULT_STATE.success;
    temp.mensaje = `MvTipoEmpleado obtenido con éxito`;
    temp.data = await getStorageMvTipoEmpleadoArrayItem(pk);
    return temp;
  }
  const result = await get(`personas/tipo-empleado/${pk}`);
  const data = result.data;
  console.log(`MvTipoEmpleado data: `, data);
  if (!data.id || data.detail) {
    console.log(
      `Error al obtener MvTipoEmpleado con pk: ${pk}, ${data.detail}`
    );
    temp.estado = DEFAULT_STATE.error;
    temp.mensaje = `Error al obtener el tipo de empleado: ${
      data.detail ?? "Hubo un problema al conectarse con el servidor"
    }`;
    temp.data = await getStorageMvTipoEmpleadoArrayItem(pk);
    return temp;
  }
  console.log(`MvTipoEmpleado con pk: ${pk}, obtenido con éxito`);
  temp.estado = DEFAULT_STATE.success;
  temp.mensaje = `MvTipoEmpleado obtenido con éxito`;
  temp.data = await setMvTipoEmpleado(data, serverDate);
  await setStorageMvTipoEmpleado(temp.data);
  temp.data_array = await setStorageMvTipoEmpleadoArrayItem(pk, temp.data);
  temp.expiry = await updateLocalStorageExpiry(serverDate, temp.expiry);
  return temp;
};

export const getAllMvTipoEmpleado = async (serverDate: IServerDate) => {
  const temp = getEmptyStatusMvTipoEmpleado();
  const doHttp = await getFromLocalStorage(serverDate, temp.expiry_array);
  temp.expiry_array = doHttp.storage;
  if (!doHttp.result) {
    console.log(`(localStorage) Todos los MvTipoEmpleados obtenidos con éxito`);
    temp.estado = DEFAULT_STATE.success;
    temp.mensaje = `Todos los tipo de empleados obtenidos con éxito`;
    temp.data_array = await getStorageMvTipoEmpleadoArray();
    return temp;
  }
  const result = await get(`personas/tipo-empleado`);
  const data = result.data;
  if (data.detail) {
    console.log(`Error al obtener la lista de MvTipoEmpleado, ${data.detail}`);
    temp.estado = DEFAULT_STATE.error;
    temp.mensaje = `Error al obtener la lista de tipo de empleados: ${
      data.detail ?? "Hubo un problema al conectarse con el servidor"
    }`;
    temp.data_array = await getStorageMvTipoEmpleadoArray();
    return temp;
  }
  console.log(`Todos los MvTipoEmpleadoes obtenidos con éxito`);
  temp.estado = DEFAULT_STATE.success;
  temp.mensaje = `Todos los tipo de empleados obtenidos con éxito`;
  temp.data_array = await Promise.all(
    data.map(async (e: any) => await setMvTipoEmpleado(e, serverDate))
  );
  await setStorageMvTipoEmpleadoArray(temp.data_array);
  temp.expiry_array = await updateLocalStorageExpiry(
    serverDate,
    temp.expiry_array
  );
  return temp;
};

export const updateMvTipoEmpleado = async (
  serverDate: IServerDate,
  pk: number,
  object: IMvTipoEmpleado
) => {
  const temp = getEmptyStatusMvTipoEmpleado();
  const result = await put(
    `personas/tipo-empleado/${pk}`,
    getSerializableMvTipoEmpleado(object)
  );
  const data = result.data;
  if (!data.id || data.detail) {
    console.log(
      `Error al actualizar MvTipoEmpleado con pk: ${pk}, ${data.detail}`
    );
    temp.estado = DEFAULT_STATE.error;
    temp.mensaje = `Error al actualizar el tipo de empleado: ${
      data.detail ?? "Hubo un problema al conectarse con el servidor"
    }`;
    temp.data = await setMvTipoEmpleado(object, serverDate);
    return temp;
  }
  console.log(`MvTipoEmpleado con pk: ${pk}, actualizado con éxito`);
  temp.estado = DEFAULT_STATE.success;
  temp.mensaje = `MvTipoEmpleado actualizado con éxito`;
  temp.data = await setMvTipoEmpleado(data, serverDate);
  await setStorageMvTipoEmpleado(temp.data);
  temp.data_array = await setStorageMvTipoEmpleadoArrayItem(
    temp.data.id,
    temp.data
  );
  return temp;
};

export const createMvTipoEmpleado = async (
  serverDate: IServerDate,
  object: IMvTipoEmpleado
) => {
  const temp = getEmptyStatusMvTipoEmpleado();
  const result = await post(
    `personas/tipo-empleado`,
    getSerializableMvTipoEmpleado(object)
  );
  const data = result.data;
  if (!data.id || data.detail) {
    console.log(
      `Error al crear MvTipoEmpleado con los datos ${object}, ${data.detail}`
    );
    temp.estado = DEFAULT_STATE.error;
    temp.mensaje = `Error al crear el tipo de empleado: ${
      data.detail ?? "Hubo un problema al conectarse con el servidor"
    }`;
    temp.data = await setMvTipoEmpleado(object, serverDate);
    return temp;
  }
  console.log(`MvTipoEmpleado creado con éxito`);
  temp.estado = DEFAULT_STATE.success;
  temp.mensaje = `MvTipoEmpleado creado con éxito`;
  temp.data = await setMvTipoEmpleado(data, serverDate);
  await setStorageMvTipoEmpleado(temp.data);
  temp.data_array = await setStorageMvTipoEmpleadoArrayItem(
    temp.data.id,
    temp.data
  );
  return temp;
};

export const deleteMvTipoEmpleado = async (pk: number) => {
  const temp = getEmptyStatusMvTipoEmpleado();
  const result = await del(`personas/tipo-empleado/${pk}`);
  const data = result.data;
  if (data.detail) {
    console.log(
      `Error al eliminar MvTipoEmpleado con pk: ${pk}, ${data.detail}`
    );
    temp.estado = DEFAULT_STATE.error;
    temp.mensaje = `Error al eliminar el tipo de empleado: ${data.detail}`;
    temp.data = await getStorageMvTipoEmpleadoArrayItem(pk);
    temp.data_array = await getStorageMvTipoEmpleadoArray();
    return temp;
  }
  console.log(`MvTipoEmpleado eliminado con éxito`);
  temp.estado = DEFAULT_STATE.success;
  temp.mensaje = `MvTipoEmpleado eliminado con éxito`;
  await removeStorageMvTipoEmpleado();
  temp.data_array = await removeArrayItemStorageData("IMvTipoEmpleado", pk, []);
  return temp;
};
