/**
 * Métodos AsyncThunk para ser usados en los reducers y
 * los resultados a las llamadas http puedan ser usados para
 * actualizar el state en redux
 */

import { createAsyncThunk } from "@reduxjs/toolkit";
import { DEFAULT_STATE } from "../../enums/default_state";
import { getMvBanco, getAllMvBanco, updateMvBanco, createMvBanco, deleteMvBanco } from "../../http/personas";
import { getEmptyStatusMvBanco, IMvBanco } from "../../interfaces/personas";
import { IServerDate } from "../../interfaces/time";

export const getMvBancoAsyncThunk = createAsyncThunk(
  'personas/getMvBancoAsyncThunk',
  async (query : {serverDate : IServerDate, id: number}) => await getMvBanco(query.serverDate, query.id)
    .then(async (result) => result)
    .catch(async (error) => getEmptyStatusMvBanco(DEFAULT_STATE.error))  
);

export const getAllMvBancoAsyncThunk = createAsyncThunk(
  'personas/getAllMvBancoAsyncThunk',
  async (queryAll : {serverDate : IServerDate}) => await getAllMvBanco(queryAll.serverDate)
    .then(async (result) => result)
    .catch(async (error) => getEmptyStatusMvBanco(DEFAULT_STATE.error))  
);

export const updateMvBancoAsyncThunk = createAsyncThunk(
  'personas/updateMvBancoAsyncThunk',
  async (update: {serverDate : IServerDate, id: number, MvBanco: IMvBanco}) => await updateMvBanco(update.serverDate, update.id, update.MvBanco)
    .then(async (result) => result)
    .catch(async (error) => getEmptyStatusMvBanco(DEFAULT_STATE.error))  
);

export const createMvBancoAsyncThunk = createAsyncThunk(
  'personas/createMvBancoAsyncThunk',
  async (create : {serverDate : IServerDate, MvBanco: IMvBanco}) => await createMvBanco(create.serverDate, create.MvBanco)
    .then(async (result) => result)
    .catch(async (error) => getEmptyStatusMvBanco(DEFAULT_STATE.error))  
);

export const deleteMvBancoAsyncThunk = createAsyncThunk(
  'personas/deleteMvBancoAsyncThunk',
  async (del : {id : number}) => await deleteMvBanco(del.id)
    .then(async (result) => result)
    .catch(async (error) => getEmptyStatusMvBanco())  
);

