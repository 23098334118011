import { getArrayItemStorageData, getStorageData, removeArrayItemStorageData, removeStorageData, setArrayItemStorageData, setStorageData } from "../../api/storage";
import { getEmptyDatosPersonales, IDatosPersonales } from "../../interfaces/personas";

export const setStorageDatosPersonales = async (IDatosPersonales : IDatosPersonales) => await setStorageData('IDatosPersonales', IDatosPersonales);
export const getStorageDatosPersonales = async () : Promise<IDatosPersonales> => await getStorageData('IDatosPersonales', getEmptyDatosPersonales());
export const removeStorageDatosPersonales = async () => await removeStorageData('IDatosPersonales');
export const setStorageDatosPersonalesArray = async (IDatosPersonales : IDatosPersonales[]) => await setStorageData('IDatosPersonalesArray', IDatosPersonales);
export const getStorageDatosPersonalesArray = async () : Promise<IDatosPersonales[]> => await getStorageData('IDatosPersonalesArray', [] as IDatosPersonales[]);
export const removeStorageDatosPersonalesArray = async () => await removeStorageData('IDatosPersonalesArray');
export const setStorageDatosPersonalesArrayItem = async (id: number, IDatosPersonales : IDatosPersonales) : Promise<IDatosPersonales[]> => await setArrayItemStorageData('IDatosPersonalesArray', id, IDatosPersonales, [] as IDatosPersonales[]);
export const getStorageDatosPersonalesArrayItem = async (id: number) : Promise<IDatosPersonales> => await getArrayItemStorageData('IDatosPersonalesArray', id, getEmptyDatosPersonales);
export const removeStorageDatosPersonalesArrayItem = async (id: number) : Promise<IDatosPersonales[]> => await removeArrayItemStorageData('IDatosPersonalesArray', id, [] as IDatosPersonales[]);
