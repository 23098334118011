/**
 * Métodos AsyncThunk para ser usados en los reducers y
 * los resultados a las llamadas http puedan ser usados para
 * actualizar el state en redux
 */

import { createAsyncThunk } from "@reduxjs/toolkit";
import { DEFAULT_STATE } from "../../enums/default_state";
import { getClienteQentry, getAllClienteQentry, updateClienteQentry, createClienteQentry, deleteClienteQentry } from "../../http/personas";
import { getEmptyStatusClienteQentry, IClienteQentry } from "../../interfaces/personas";
import { IServerDate } from "../../interfaces/time";

export const getClienteQentryAsyncThunk = createAsyncThunk(
  'personas/getClienteQentryAsyncThunk',
  async (query : {serverDate : IServerDate, id: number}) => await getClienteQentry(query.serverDate, query.id)
    .then(async (result) => result)
    .catch(async (error) => getEmptyStatusClienteQentry(DEFAULT_STATE.error))  
);

export const getAllClienteQentryAsyncThunk = createAsyncThunk(
  'personas/getAllClienteQentryAsyncThunk',
  async (queryAll : {serverDate : IServerDate}) => await getAllClienteQentry(queryAll.serverDate)
    .then(async (result) => result)
    .catch(async (error) => getEmptyStatusClienteQentry(DEFAULT_STATE.error))  
);

export const updateClienteQentryAsyncThunk = createAsyncThunk(
  'personas/updateClienteQentryAsyncThunk',
  async (update: {serverDate : IServerDate, id: number, clienteQentry: IClienteQentry}) => await updateClienteQentry(update.serverDate, update.id, update.clienteQentry)
    .then(async (result) => result)
    .catch(async (error) => getEmptyStatusClienteQentry(DEFAULT_STATE.error))  
);

export const createClienteQentryAsyncThunk = createAsyncThunk(
  'personas/createClienteQentryAsyncThunk',
  async (create : {serverDate : IServerDate, clienteQentry: IClienteQentry}) => await createClienteQentry(create.serverDate, create.clienteQentry)
    .then(async (result) => result)
    .catch(async (error) => getEmptyStatusClienteQentry(DEFAULT_STATE.error))  
);

export const deleteClienteQentryAsyncThunk = createAsyncThunk(
  'personas/deleteClienteQentryAsyncThunk',
  async (del : {id : number}) => await deleteClienteQentry(del.id)
    .then(async (result) => result)
    .catch(async (error) => getEmptyStatusClienteQentry())  
);
