/** 
 * Componente que se encarga del elemento CambioEstatusIncapacidad
 * @ICambioEstatusIncapacidad es la interfaz que contiene los parámetos el elemento CambioEstatusIncapacidad
 * @getEmptyCambioEstatusIncapacidad  devuelve un objeto con los parámetros por defecto; @param {id}, opcional cuando no encuentra el objeto en el LocalSotrage
 * @IStatusCambioEstatusIncapacidad es la interfaz para los estatus a llamadas a la API; @param {status}, opcional cuando queremos otro estado de inizialización de tipo [DEFAULT_STATE]
 * @getEmptyStatusCambioEstatusIncapacidad devuelve un objeto con los parámetros por defecto,
 * @setCambioEstatusIncapacidad devuelve un objeto de tipo [ICambioEstatusIncapacidad] recibiendo @param {object} y @param {serverDate}, para verificar que los datos estén en el formato correcto y ajustarlos a la zona horaria actual
 * @getSerializableCambioEstatusIncapacidad recibe un @param {ICambioEstatusIncapacidad}, para devolver un objeto serizalizable para las peticiones de la API
 * */

import { getDateString, isNotEmpty, SERVER_TIME_ZONE } from "../../api/helpers";
import { DEFAULT_STATE } from "../../enums/default_state";
import { getStorageIncapacidadArrayItem, getStorageMvEstatusAsistenciaArrayItem } from "../../localStorage/nomina";
import { getStorageUserArrayItem, getStorageClienteQentryArrayItem } from "../../localStorage/personas";
import { IUser, IClienteQentry, getEmptyUser, getEmptyClienteQentry, setUser, setClienteQentry } from "../personas";
import { ILocalStorageExpiry, getEmptyLocalStorageExpiry, IServerDate, convertDateToZonaHoraria } from "../time";
import { getEmptyIncapacidad, IIncapacidad, setIncapacidad } from "./Incapacidad";
import { getEmptyMvEstatusAsistencia, IMvEstatusAsistencia, setMvEstatusAsistencia } from "./MvEstatusAsistencia";

export interface ICambioEstatusIncapacidad {
  id : number,
  incapacidad : IIncapacidad,
  nuevo_estatus : IMvEstatusAsistencia,
  autor : IUser,
  fecha_elaboracion : string, // Fecha
  estatus_sistema : boolean,
  ultima_actualizacion : string, // Fecha
  cliente_qentry : IClienteQentry,
}

export const getEmptyCambioEstatusIncapacidad = (id? : number) => {
  const temp : ICambioEstatusIncapacidad = 
  {
    id : id ? id : 0,
    incapacidad : getEmptyIncapacidad(),
    nuevo_estatus : getEmptyMvEstatusAsistencia(),
    autor : getEmptyUser(),
    fecha_elaboracion : "",
    estatus_sistema : false,
    ultima_actualizacion : "",
    cliente_qentry : getEmptyClienteQentry(),
  };
  return temp;
}

export interface IStatusCambioEstatusIncapacidad {
  estado : DEFAULT_STATE,
  mensaje : string,
  data : ICambioEstatusIncapacidad,
  data_array: ICambioEstatusIncapacidad[],
  expiry : ILocalStorageExpiry,
  expiry_array : ILocalStorageExpiry,
}

export const getEmptyStatusCambioEstatusIncapacidad = (status: DEFAULT_STATE = DEFAULT_STATE.loading) => {
  const temp : IStatusCambioEstatusIncapacidad = 
  {
    estado : status,
    mensaje : "",
    data : getEmptyCambioEstatusIncapacidad(),
    data_array : [],
    expiry : getEmptyLocalStorageExpiry("ICambioEstatusIncapacidad", 0),
    expiry_array : getEmptyLocalStorageExpiry("ICambioEstatusIncapacidadArray", 0),
  };
  return temp;
}

export const setCambioEstatusIncapacidad = async (
  object : any,
  serverDate : IServerDate,
) => {
  const temp = getEmptyCambioEstatusIncapacidad();
  if (isNotEmpty(object.id)) temp.id = object.id;
  if (isNotEmpty(object.incapacidad)) temp.incapacidad = (typeof object.incapacidad === 'number') ? await getStorageIncapacidadArrayItem(object.incapacidad) : await setIncapacidad(object.incapacidad, serverDate);
  if (isNotEmpty(object.nuevo_estatus)) temp.nuevo_estatus = (typeof object.nuevo_estatus === 'number') ? await getStorageMvEstatusAsistenciaArrayItem(object.nuevo_estatus) : await setMvEstatusAsistencia(object.nuevo_estatus, serverDate);
  if (isNotEmpty(object.autor)) temp.autor = (typeof object.autor === 'number') ? await getStorageUserArrayItem(object.autor) : await setUser(object.autor, serverDate);
  if (isNotEmpty(object.fecha_elaboracion)) temp.fecha_elaboracion = convertDateToZonaHoraria(serverDate, object.fecha_elaboracion);
  if (isNotEmpty(object.estatus_sistema)) temp.estatus_sistema = object.estatus_sistema;
  if (isNotEmpty(object.ultima_actualizacion)) temp.ultima_actualizacion = convertDateToZonaHoraria(serverDate, object.ultima_actualizacion);
  if (isNotEmpty(object.cliente_qentry)) temp.cliente_qentry = (typeof object.cliente_qentry === 'number') ? await getStorageClienteQentryArrayItem(object.cliente_qentry) : await setClienteQentry(object.cliente_qentry, serverDate);
  return temp;
}

export const getSerializableCambioEstatusIncapacidad = (ICambioEstatusIncapacidad : ICambioEstatusIncapacidad ) => {
  return {
    id : ICambioEstatusIncapacidad.id,
    incapacidad : ICambioEstatusIncapacidad.incapacidad.id,
    nuevo_estatus : ICambioEstatusIncapacidad.nuevo_estatus.id,
    autor : ICambioEstatusIncapacidad.autor.id,
    fecha_elaboracion : ICambioEstatusIncapacidad.fecha_elaboracion,
    estatus_sistema : ICambioEstatusIncapacidad.estatus_sistema,
    ultima_actualizacion : getDateString(SERVER_TIME_ZONE),
    cliente_qentry : ICambioEstatusIncapacidad.cliente_qentry.id,
  };
}
