import { isNotEmpty } from "../../api/helpers";
import { getStorageData, setStorageData } from "../../api/storage";

export interface ITheme {
  isLigthTheme: boolean;
}

export const getDefaultITheme = () => {
  const temp: ITheme = 
  {
    isLigthTheme: true,
  };
  return temp;
};

export const clearIThemeStorage = async () => {
  await setStorageITheme(getDefaultITheme())
}

export const setStorageITheme = async (ITheme: ITheme) => await setStorageData('ITheme', ITheme);

export const getStorageITheme  = async () : Promise<ITheme> => await getStorageData('ITheme', getDefaultITheme());

export const getIfExistITheme = async () => { 
  console.log('getIfExistITheme: '); 
  const storageITheme = await getStorageITheme();
  console.log('storageITheme: ', storageITheme);
  if (isNotEmpty(storageITheme)) return storageITheme;
  return getDefaultITheme();
};