/** 
 * Componente que se encarga del elemento RondinCancelado
 * @IRondinCancelado es la interfaz que contiene los parámetos el elemento RondinCancelado
 * @getEmptyRondinCancelado  devuelve un objeto con los parámetros por defecto; @param {id}, opcional cuando no encuentra el objeto en el LocalSotrage
 * @IStatusRondinCancelado es la interfaz para los estatus a llamadas a la API; @param {status}, opcional cuando queremos otro estado de inizialización de tipo [DEFAULT_STATE]
 * @getEmptyStatusRondinCancelado devuelve un objeto con los parámetros por defecto,
 * @setRondinCancelado devuelve un objeto de tipo [IRondinCancelado] recibiendo @param {object} y @param {serverDate}, para verificar que los datos estén en el formato correcto y ajustarlos a la zona horaria actual
 * @getSerializableRondinCancelado recibe un @param {IRondinCancelado}, para devolver un objeto serizalizable para las peticiones de la API
 * */

import { IRondin, getEmptyRondin, setRondin } from ".";
import { isNotEmpty, getDateString, SERVER_TIME_ZONE } from "../../api/helpers";
import { DEFAULT_STATE } from "../../enums/default_state";
import { getStorageClienteQentryArrayItem, getStorageEmpleadoArrayItem, getStorageEmpresaArrayItem } from "../../localStorage/personas";
import { getStorageMvMotivoCancelacionArrayItem, getStorageRondinArrayItem } from "../../localStorage/rondines";
import { IEmpleado, getEmptyEmpleado, setEmpleado, getEmptyClienteQentry, getEmptyEmpresa, IClienteQentry, IEmpresa, setClienteQentry, setEmpresa } from "../personas";
import { ILocalStorageExpiry, getEmptyLocalStorageExpiry, IServerDate, convertDateToZonaHoraria } from "../time";
import { IMvMotivoCancelacion, getEmptyMvMotivoCancelacion, setMvMotivoCancelacion } from "./MvMotivoCancelacion";

export interface IRondinCancelado {
  id : number,
  empleado : IEmpleado,
  rondin : IRondin,
  motivo_cancelacion : IMvMotivoCancelacion,
  comentarios_cancelacion : string,
  identificador_rondin : string,
  cliente_qentry : IClienteQentry,
  empresa : IEmpresa,
  fecha_elaboracion : string, // Fecha
  estatus_sistema : boolean,
  ultima_actualizacion : string, // Fecha
}

export const getEmptyRondinCancelado = (id? : number) => {
  const temp : IRondinCancelado = 
  {
    id : id ? id : 0,
    empleado : getEmptyEmpleado(),
    rondin : getEmptyRondin(),
    motivo_cancelacion : getEmptyMvMotivoCancelacion(),
    comentarios_cancelacion : "",
    identificador_rondin : "",
    empresa : getEmptyEmpresa(),
    cliente_qentry : getEmptyClienteQentry(),
    fecha_elaboracion : "",
    estatus_sistema : false,
    ultima_actualizacion : "",
  };
  return temp;
}

export interface IStatusRondinCancelado {
  estado : DEFAULT_STATE,
  mensaje : string,
  data : IRondinCancelado,
  data_array: IRondinCancelado[],
  expiry : ILocalStorageExpiry,
  expiry_array : ILocalStorageExpiry,
}

export const getEmptyStatusRondinCancelado = (status: DEFAULT_STATE = DEFAULT_STATE.loading) => {
  const temp : IStatusRondinCancelado = 
  {
    estado : status,
    mensaje : "",
    data : getEmptyRondinCancelado(),
    data_array : [],
    expiry : getEmptyLocalStorageExpiry("IRondinCancelado", 0),
    expiry_array : getEmptyLocalStorageExpiry("IRondinCanceladoArray", 0),
  };
  return temp;
}

export const setRondinCancelado = async (
  object : any,
  serverDate : IServerDate,
) => {
  const temp = getEmptyRondinCancelado();
  if (isNotEmpty(object.id)) temp.id = object.id;
  if (isNotEmpty(object.empleado)) temp.empleado = (typeof object.empleado === 'number') ? await getStorageEmpleadoArrayItem(object.empleado) : await setEmpleado(object.empleado, serverDate);
  if (isNotEmpty(object.rondin)) temp.rondin = (typeof object.rondin === 'number') ? await getStorageRondinArrayItem(object.rondin) : await setRondin(object.rondin, serverDate);
  if (isNotEmpty(object.motivo_cancelacion)) temp.motivo_cancelacion = (typeof object.motivo_cancelacion === 'number') ? await getStorageMvMotivoCancelacionArrayItem(object.motivo_cancelacion) : await setMvMotivoCancelacion(object.motivo_cancelacion, serverDate);
  if (isNotEmpty(object.comentarios_cancelacion)) temp.comentarios_cancelacion = object.comentarios_cancelacion;
  if (isNotEmpty(object.identificador_rondin)) temp.identificador_rondin = object.identificador_rondin;
  if (isNotEmpty(object.cliente_qentry)) temp.cliente_qentry = (typeof object.cliente_qentry === 'number') ? await getStorageClienteQentryArrayItem(object.cliente_qentry) : await setClienteQentry(object.cliente_qentry, serverDate);
  if (isNotEmpty(object.empresa)) temp.empresa = (typeof object.empresa === 'number') ? await getStorageEmpresaArrayItem(object.empresa) : await setEmpresa(object.empresa, serverDate);
  if (isNotEmpty(object.fecha_elaboracion)) temp.fecha_elaboracion = convertDateToZonaHoraria(serverDate, object.fecha_elaboracion);
  if (isNotEmpty(object.estatus_sistema)) temp.estatus_sistema = object.estatus_sistema;
  if (isNotEmpty(object.ultima_actualizacion)) temp.ultima_actualizacion = convertDateToZonaHoraria(serverDate, object.ultima_actualizacion);
  return temp;
}

export const getSerializableRondinCancelado = (IRondinCancelado : IRondinCancelado ) => {
  return {
    id : IRondinCancelado.id,
    empleado : IRondinCancelado.empleado.id,
    rondin : IRondinCancelado.rondin.id,
    motivo_cancelacion : IRondinCancelado.motivo_cancelacion.id,
    comentarios_cancelacion : IRondinCancelado.comentarios_cancelacion,
    identificador_rondin : IRondinCancelado.identificador_rondin,
    empresa : IRondinCancelado.empresa.id,
    cliente_qentry : IRondinCancelado.cliente_qentry.id,
    fecha_elaboracion : IRondinCancelado.fecha_elaboracion,
    estatus_sistema : IRondinCancelado.estatus_sistema,
    ultima_actualizacion : getDateString(SERVER_TIME_ZONE),
  };
}