/**
 * Es el componente de la página Vacaciones para crear y visualizar solicitudes de vacaciones de los trabajadores
 * @function uploadDeviceChangeRequest se encarge de crear la solicitud de vacaciones del trabajador seleccionado
 */

import React, { useState } from "react";
import {
  IonGrid,
  IonRow,
  IonCol,
  IonDatetime,
  IonLabel,
  IonButton,
  IonItem,
  IonTextarea,
  IonContent,
  useIonToast,
  IonList,
  IonPopover,
  IonInput,
  IonSpinner,
} from "@ionic/react";

import { phonePortraitSharp } from "ionicons/icons";

import Card from "../components/Card";
import Selector from "../components/Selector";

import useRedux, { useAppSelector } from "../hooks/useRedux";
import {
  getGlobalMemoizedEmpresaAsSelectList,
  getNombreEmpleado,
} from "../redux/slices/personasSlice";
// import {
//   getMemoizedVacationsActiveEmpleadoAsSelectList,
//   getMemoizedVacationsByEmpresa,
//   setVacationsSelectedEmpleado,
//   setVacationsSelectedEmpresa,
//   setVacationsSelectedMvEstatusVacaciones,
// } from "../redux/slices/pagesSlice/vacationsSlice";
import {
  getMemoizedDeviceChangeRequestActiveEmpleado,
  getMemoizedDeviceChangeRequestActiveEmpleadosAsSelectList,
  getMemoizedDeviceChangeRequestByEmpresa,
  setDeviceChangeRequestSelectedMvEstatusSolicitudCambioTelefono,
  setDeviceChangeRequestSelectedEmpleado,
  setDeviceChangeRequestSelectedEmpresa,
} from "../redux/slices/pagesSlice/deviceChangeRequestsSlice";
import { getOnlyDateString } from "../redux/api/helpers";
import { getGlobalMemoizedMvEstatusSolicitudCambioDispositivoAsSelectList } from "../redux/slices/personasSlice";
import { DEFAULT_STATE } from "../redux/enums/default_state";
import { useSelector } from "react-redux";
import { getEmptyUser, IUser } from "../redux/interfaces/personas";
import { List } from "../redux/interfaces/helpers";

const DeviceChangeRequest: React.FC = () => {
  // const [{ empleado, vacaciones, createVacations }] = useRedux();
  const [
    {
      empleado,
      solicitudCambioTelefonoAutorizado,
      createDeviceChangeRequest,
      updateDeviceChangeRequest,
    },
  ] = useRedux();

  const empresaList = useAppSelector(getGlobalMemoizedEmpresaAsSelectList);
  const selectedEmpresa = useAppSelector(
    (state) => state.vacations.selectedEmpresa
  );

  const empleadoList = useAppSelector(
    getMemoizedDeviceChangeRequestActiveEmpleadosAsSelectList
  );
  const selectedEmpleado = useAppSelector(
    (state: { deviceChangeRequest: { selectedEmpleado: List } }) =>
      state.deviceChangeRequest.selectedEmpleado
  );

  // const deviceChangeRequestList = useAppSelector(getMemoizedVacationsByEmpresa);
  const deviceChangeRequestList = useAppSelector(
    getMemoizedDeviceChangeRequestByEmpresa
  );

  const requestList = "";

  // const mvEstatusVacacionesList = useAppSelector(
  //   getGlobalMemoizedMvEstatusVacacionesAsSelectList
  // );
  // const selectedMvEstatusVacaciones = useAppSelector(
  //   (state) => state.vacations.selectedMvEstatusVacaciones
  // );

  const mvEstatusSolicitudCambioDispositivoList = useAppSelector(
    getGlobalMemoizedMvEstatusSolicitudCambioDispositivoAsSelectList
  );

  const selectedMvEstatusCambioDispositivo = useAppSelector(
    (state) => state.deviceChangeRequest.selectedMvEstatusCambioDispositivo
  );

  const [present, dismiss] = useIonToast();
  let [id_telefono_autorizado_anterior, setIdTelefonoAutorizadoAnterior] =
    useState<string>("");
  let [id_telefono_autorizado_nuevo, setIdTelefonoAutorizadoNuevo] =
    useState<string>("");
  let [usuario_aprobador, setUsuarioAprobador] = useState<IUser>({
    ...getEmptyUser(),
  });
  let [description, setDescription] = useState<string>("");
  let [selectedIniDate, setSelectedIniDate] = useState<string>(
    getOnlyDateString()
  );
  let [selectedFinDate, setSelectedFinDate] = useState<string>(
    getOnlyDateString()
  );

  const uploadDeviceChangeRequest = async () => {
    if (!selectedEmpleado.id) {
      present({
        message: "Por favor seleccione un empleado",
        color: "warning",
        duration: 1500,
      });
      return;
    }
    if (
      !id_telefono_autorizado_anterior ||
      !id_telefono_autorizado_anterior.trim()
    ) {
      present({
        message: "Por favor, escriba el id del dispositivo anterior",
        color: "warning",
        duration: 1500,
      });
      return;
    }
    if (!id_telefono_autorizado_nuevo || !id_telefono_autorizado_nuevo.trim()) {
      present({
        message: "Por favor, escriba el id del dispositivo nuevo",
        color: "warning",
        duration: 1500,
      });
      return;
    }
    // if (!description || !description.trim()) {
    //   present({
    //     message: "Por favor escriba la razón de las vacaciones",
    //     color: "warning",
    //     duration: 1500,
    //   });
    //   return;
    // }
    const temp = empleado.data_array.find(
      (e) => e.id.toString() === selectedEmpleado.id
    );

    if (!temp) {
      present({
        message: "Hubo un problema al obtener al trabajador",
        color: "warning",
        duration: 1500,
      });
      return;
    }
    createDeviceChangeRequest(
      temp,
      id_telefono_autorizado_anterior,
      id_telefono_autorizado_nuevo,
      usuario_aprobador
    );
    // createVacations(temp, description, selectedIniDate, selectedFinDate);
  };

  return (
    <IonContent class="inner-content">
      <IonGrid>
        <IonRow>
          <IonCol size="12">
            <Card
              icon={phonePortraitSharp}
              title={
                <IonLabel>Subir solicitud de cambio de dispositivo</IonLabel>
              }
              subtitle={
                <Selector
                  value={selectedEmpresa}
                  label="Cuentas/Regiones"
                  list={empresaList}
                  changeState={setDeviceChangeRequestSelectedEmpresa}
                />
              }
              //content="prueba"
              content={
                <IonGrid>
                  <IonRow>
                    <IonCol size="12">
                      <Selector
                        value={selectedEmpleado}
                        label="Empleado"
                        list={empleadoList}
                        changeState={setDeviceChangeRequestSelectedEmpleado}
                      />
                    </IonCol>
                    <IonCol size="12" sizeMd="6" sizeLg="6">
                      <IonItem>
                        <IonLabel position="stacked">
                          Id dispositivo anterior
                        </IonLabel>
                        <IonInput
                          id="prev-device-id"
                          type="text"
                          color="secondary"
                          value={id_telefono_autorizado_anterior}
                          onIonChange={(e) =>
                            setIdTelefonoAutorizadoAnterior(e.detail.value!)
                          }
                        />
                      </IonItem>
                    </IonCol>
                    <IonCol size="12" sizeMd="6" sizeLg="6">
                      <IonItem>
                        <IonLabel position="stacked">
                          Id dispositivo nuevo
                        </IonLabel>
                        <IonInput
                          id="new-device-id"
                          type="text"
                          color="secondary"
                          value={id_telefono_autorizado_nuevo}
                          onIonChange={(e) =>
                            setIdTelefonoAutorizadoNuevo(e.detail.value!)
                          }
                        />
                      </IonItem>
                    </IonCol>
                  </IonRow>
                  <IonRow>
                    <IonCol>
                      <IonButton
                        disabled={
                          solicitudCambioTelefonoAutorizado.estado ===
                          DEFAULT_STATE.loading
                        }
                        onClick={() => uploadDeviceChangeRequest()}
                        expand="block"
                      >
                        {solicitudCambioTelefonoAutorizado.estado ===
                        DEFAULT_STATE.loading ? (
                          <IonSpinner
                            className="loading-center-horizontal"
                            name="circles"
                          />
                        ) : (
                          <IonLabel>Subir</IonLabel>
                        )}
                      </IonButton>
                    </IonCol>
                  </IonRow>
                </IonGrid>
              }
            />
          </IonCol>
          <IonCol size="12">
            <Card
              icon={phonePortraitSharp}
              title={
                <IonLabel>
                  Lista de solicitudes de cambio de dispositivo
                </IonLabel>
              }
              content={
                <IonGrid>
                  <IonRow>
                    <IonCol size="12">
                      <IonItem color="secondary" lines="full">
                        <IonLabel>
                          {/* <h3>Registro de entradas y salidas</h3> */}
                          <div>
                            <Selector
                              classStyle="secondary"
                              color="secondary"
                              value={selectedMvEstatusCambioDispositivo}
                              label="Estatus"
                              list={mvEstatusSolicitudCambioDispositivoList}
                              changeState={
                                setDeviceChangeRequestSelectedMvEstatusSolicitudCambioTelefono
                              }
                            />
                          </div>
                        </IonLabel>
                      </IonItem>
                      <IonList className="selection-area">
                        {deviceChangeRequestList.map((e) => {
                          return (
                            <IonItem key={`${e.id}-${e.fecha_elaboracion}`}>
                              <IonLabel>
                                <h3>
                                  <b>Empleado</b>:{" "}
                                  {getNombreEmpleado(e.empleado)}
                                </h3>
                                <h3>
                                  <b>Id dispositivo anterior</b>:{" "}
                                  {e.id_telefono_autorizado_anterior}
                                </h3>
                                <h3>
                                  <b>Id dispositivo nuevo</b>:{" "}
                                  {e.id_telefono_autorizado_nuevo}
                                </h3>
                                <h3>
                                  <b>Usuario aprobador</b>:{" "}
                                  {e.usuario_aprobador.username}
                                </h3>
                                <h3>
                                  <b>Fecha elaboración</b>:{" "}
                                  {e.fecha_elaboracion}
                                </h3>
                                <h3>
                                  <b>Estatus</b>: {e.estatus.dato}
                                </h3>
                              </IonLabel>
                            </IonItem>
                          );
                        })}
                        {solicitudCambioTelefonoAutorizado.estado ===
                        DEFAULT_STATE.loading ? (
                          <IonSpinner
                            className="loading-center-horizontal"
                            name="circles"
                            color="medium"
                          />
                        ) : deviceChangeRequestList.length === 0 ? (
                          <IonItem lines="full">
                            <IonLabel>No hay datos</IonLabel>
                          </IonItem>
                        ) : (
                          <></>
                        )}
                      </IonList>
                    </IonCol>
                  </IonRow>
                </IonGrid>
              }
            />
          </IonCol>
        </IonRow>
      </IonGrid>
    </IonContent>
  );
};

export default DeviceChangeRequest;
