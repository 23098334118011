/**
 * Métodos AsyncThunk para ser usados en los reducers y
 * los resultados a las llamadas http puedan ser usados para
 * actualizar el state en redux
 */

import { createAsyncThunk } from "@reduxjs/toolkit";
import { DEFAULT_STATE } from "../../enums/default_state";
import { getDocumento, getAllDocumento, updateDocumento, createDocumento, deleteDocumento } from "../../http/personas";
import { getEmptyStatusDocumento, IDocumento } from "../../interfaces/personas";
import { IServerDate } from "../../interfaces/time";

export const getDocumentoAsyncThunk = createAsyncThunk(
  'personas/getDocumentoAsyncThunk',
  async (query : {serverDate : IServerDate, id: number, idCliente : number}) => await getDocumento(query.serverDate, query.id, query.idCliente)
    .then(async (result) => result)
    .catch(async (error) => getEmptyStatusDocumento(DEFAULT_STATE.error))  
);

export const getAllDocumentoAsyncThunk = createAsyncThunk(
  'personas/getAllDocumentoAsyncThunk',
  async (queryAll : {serverDate : IServerDate, idCliente : number}) => await getAllDocumento(queryAll.serverDate, queryAll.idCliente)
    .then(async (result) => result)
    .catch(async (error) => getEmptyStatusDocumento(DEFAULT_STATE.error))  
);

export const updateDocumentoAsyncThunk = createAsyncThunk(
  'personas/updateDocumentoAsyncThunk',
  async (update: {serverDate : IServerDate, id: number, idCliente : number, object: IDocumento}) => await updateDocumento(update.serverDate, update.id, update.idCliente, update.object)
    .then(async (result) => result)
    .catch(async (error) => getEmptyStatusDocumento(DEFAULT_STATE.error))  
);

export const createDocumentoAsyncThunk = createAsyncThunk(
  'personas/createDocumentoAsyncThunk',
  async (create : {serverDate : IServerDate, idCliente : number, object: IDocumento}) => await createDocumento(create.serverDate, create.idCliente, create.object)
    .then(async (result) => result)
    .catch(async (error) => getEmptyStatusDocumento(DEFAULT_STATE.error))  
);

export const deleteDocumentoAsyncThunk = createAsyncThunk(
  'personas/deleteDocumentoAsyncThunk',
  async (del : {id : number, idCliente : number}) => await deleteDocumento(del.id, del.idCliente)
    .then(async (result) => result)
    .catch(async (error) => getEmptyStatusDocumento())  
);
