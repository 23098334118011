import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { List, setListItem } from "../../interfaces/helpers";
import { RootState } from "../../store";
import { getNombreEmpleado, setMakerDataItem } from "../personasSlice";

export interface IDashboard {
  selectedEmpresa: List;
  empleadosList: List[];
  empleadoStatus: List[];
}

export const dashboardInitialState: IDashboard = {
  selectedEmpresa: { id: "", title: "Todas las empresas" },
  empleadosList: [],
  empleadoStatus: [
    {
      id: "1",
      title: "Laborando",
    },
    {
      id: "2",
      title: "Con retardo",
    },
    {
      id: "3",
      title: "En tiempo extra",
    },
    {
      id: "4",
      title: "Ausente",
    },
    {
      id: "5",
      title: "Esperados",
    },
  ],
};

export const dashboardSlice = createSlice({
  name: "Dashboard",
  initialState: dashboardInitialState,
  reducers: {
    setDashboardSelectedEmpresa: (state, action: PayloadAction<List>) => {
      state.selectedEmpresa = action.payload;
    },
  },
});

export const {
  setDashboardSelectedEmpresa,
  // Modal
} = dashboardSlice.actions;

export default dashboardSlice.reducer;

export const getMemoizedDashboardActiveEmpleado = createSelector(
  (state: RootState) => state.dashboard.selectedEmpresa,
  (state: RootState) => state.personas.personasState.empleado.data_array,
  (selectedEmpresa, empleados) => {
    if (selectedEmpresa.id === "") return empleados.filter((e) => e.activo);
    const empresa = Number(selectedEmpresa.id);
    return empleados.filter((e) => e.empresa.id === empresa && e.activo);
  }
);

export const getMemoizedDashboardActiveEmpleadoAsSelectList = createSelector(
  getMemoizedDashboardActiveEmpleado,
  (empleados) => {
    return empleados.map((e) =>
      setListItem({
        id: e.id.toString(),
        title: getNombreEmpleado(e),
      })
    );
  }
);

export const getMemoizedDashboardActiveCentroDeTrabajo = createSelector(
  (state: RootState) => state.dashboard.selectedEmpresa,
  (state: RootState) => state.personas.personasState.centroDeTrabajo.data_array,
  (selectedEmpresa, qrs) => {
    if (selectedEmpresa.id === "") return qrs.filter((e) => e.activo);
    const empresa = Number(selectedEmpresa.id);
    return qrs.filter((e) => e.empresa.id === empresa && e.activo);
  }
);

export const getMemoizedDashboardActiveCentroDeTrabajoAsList = createSelector(
  getMemoizedDashboardActiveCentroDeTrabajo,
  (items) => {
    const temp = [...items];
    temp.sort((a, b) => {
      return a.id > b.id ? 1 : a.id < b.id ? -1 : 0;
    });
    return temp.map((e) =>
      setListItem({ id: e.id.toString(), title: e.nombre_centro })
    );
  }
);

export const getMemoizeDashboardCodigoQrMarkerList = createSelector(
  getMemoizedDashboardActiveCentroDeTrabajo,
  (state: RootState) => state.personas.personasState.codigoQr.data_array,
  (items, codigosQr) => {
    const qrs = codigosQr.filter((e) =>
      items.some((v) => v.id === e.centro_de_trabajo.id)
    );
    return qrs.map((e) => setMakerDataItem(e));
  }
);
