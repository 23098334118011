/** 
 * Componente que se encarga del elemento ModificacionSuscripcionQentry
 * @IModificacionSuscripcionQentry es la interfaz que contiene los parámetos el elemento ModificacionSuscripcionQentry
 * @getEmptyModificacionSuscripcionQentry  devuelve un objeto con los parámetros por defecto; @param {id}, opcional cuando no encuentra el objeto en el LocalSotrage
 * @IStatusModificacionSuscripcionQentry es la interfaz para los estatus a llamadas a la API; @param {status}, opcional cuando queremos otro estado de inizialización de tipo [DEFAULT_STATE]
 * @getEmptyStatusModificacionSuscripcionQentry devuelve un objeto con los parámetros por defecto,
 * @setModificacionSuscripcionQentry devuelve un objeto de tipo [IModificacionSuscripcionQentry] recibiendo @param {object} y @param {serverDate}, para verificar que los datos estén en el formato correcto y ajustarlos a la zona horaria actual
 * @getSerializableModificacionSuscripcionQentry recibe un @param {IModificacionSuscripcionQentry}, para devolver un objeto serizalizable para las peticiones de la API
 * */

import { getDateString, isNotEmpty, SERVER_TIME_ZONE } from "../../api/helpers";
import { DEFAULT_STATE } from "../../enums/default_state";
import { getStorageClienteQentryArrayItem, getStorageSuscripcionQentryArrayItem, getStorageUserArrayItem } from "../../localStorage/personas";
import { convertDateToZonaHoraria, ILocalStorageExpiry, getEmptyLocalStorageExpiry, IServerDate } from "../time";
import { IClienteQentry, getEmptyClienteQentry, setClienteQentry } from "./IClienteQentry";
import { ISuscripcionQentry, getEmptySuscripcionQentry, setSuscripcionQentry } from "./ISuscripcionQentry";
import { IUser, getEmptyUser, setUser } from "./IUser";

export interface IModificacionSuscripcionQentry {
  id : number,
  cliente_qentry : IClienteQentry,
  suscripcion : ISuscripcionQentry,
  id_suscripcion_stripe : string,
  id_plan_stripe_anterior : string,
  id_plan_stripe_nuevo : string,
  vigente : boolean,
  autor : IUser,
  fecha_elaboracion : string, // Fecha
  estatus_sistema : boolean,
  ultima_actualizacion : string, // Fecha
}

export const getEmptyModificacionSuscripcionQentry = (id? : number) => {
  const temp : IModificacionSuscripcionQentry = 
  {
    id : id ? id : 0,
    cliente_qentry : getEmptyClienteQentry(),
    suscripcion : getEmptySuscripcionQentry(),
    id_suscripcion_stripe : "",
    id_plan_stripe_anterior : "",
    id_plan_stripe_nuevo : "",
    vigente : false,
    autor : getEmptyUser(),
    fecha_elaboracion : "",
    estatus_sistema : false,
    ultima_actualizacion :  "",
  };
  return temp;
}

export interface IStatusModificacionSuscripcionQentry {
  estado : DEFAULT_STATE,
  mensaje : string,
  data : IModificacionSuscripcionQentry,
  data_array: IModificacionSuscripcionQentry[],
  expiry : ILocalStorageExpiry,
  expiry_array : ILocalStorageExpiry,
}

export const getEmptyStatusModificacionSuscripcionQentry = (status: DEFAULT_STATE = DEFAULT_STATE.loading) => {
  const temp : IStatusModificacionSuscripcionQentry = 
  {
    estado : status,
    mensaje : "",
    data : getEmptyModificacionSuscripcionQentry(),
    data_array : [],
    expiry : getEmptyLocalStorageExpiry("IModificacionSuscripcionQentry", 0),
    expiry_array : getEmptyLocalStorageExpiry("IModificacionSuscripcionQentryArray", 0),
  };
  return temp;
}

export const setModificacionSuscripcionQentry = async (
  object : any,
  serverDate : IServerDate,
) => {
  const temp = getEmptyModificacionSuscripcionQentry();
  if (isNotEmpty(object.id)) temp.id = object.id;
  if (isNotEmpty(object.cliente_qentry)) temp.cliente_qentry = (typeof object.cliente_qentry === 'number') ? await getStorageClienteQentryArrayItem(object.cliente_qentry) : await setClienteQentry(object.cliente_qentry, serverDate);
  if (isNotEmpty(object.suscripcion)) temp.suscripcion = (typeof object.suscripcion === 'number') ? await getStorageSuscripcionQentryArrayItem(object.suscripcion) : await setSuscripcionQentry(object.suscripcion, serverDate);
  if (isNotEmpty(object.id_suscripcion_stripe)) temp.id_suscripcion_stripe = object.id_suscripcion_stripe;
  if (isNotEmpty(object.id_plan_stripe_anterior)) temp.id_plan_stripe_anterior = object.id_plan_stripe_anterior;
  if (isNotEmpty(object.id_plan_stripe_nuevo)) temp.id_plan_stripe_nuevo = object.id_plan_stripe_nuevo;
  if (isNotEmpty(object.vigente)) temp.vigente = object.vigente;
  if (isNotEmpty(object.autor)) temp.autor = (typeof object.autor === 'number') ? await getStorageUserArrayItem(object.autor) : await setUser(object.autor, serverDate);
  if (isNotEmpty(object.fecha_elaboracion)) temp.fecha_elaboracion = convertDateToZonaHoraria(serverDate, object.fecha_elaboracion);
  if (isNotEmpty(object.estatus_sistema)) temp.estatus_sistema = object.estatus_sistema;
  if (isNotEmpty(object.ultima_actualizacion)) temp.ultima_actualizacion = convertDateToZonaHoraria(serverDate, object.ultima_actualizacion);
  return temp;
}

export const getSerializableModificacionSuscripcionQentry = (IModificacionSuscripcionQentry : IModificacionSuscripcionQentry) => {
  return {
    id : IModificacionSuscripcionQentry.id,
    cliente_qentry : IModificacionSuscripcionQentry.cliente_qentry.id,
    suscripcion : IModificacionSuscripcionQentry.suscripcion.id,
    id_suscripcion_stripe : IModificacionSuscripcionQentry.id_suscripcion_stripe,
    id_plan_stripe_anterior : IModificacionSuscripcionQentry.id_plan_stripe_anterior,
    id_plan_stripe_nuevo : IModificacionSuscripcionQentry.id_plan_stripe_nuevo,
    vigente : IModificacionSuscripcionQentry.vigente,
    autor : IModificacionSuscripcionQentry.autor.id,
    fecha_elaboracion : IModificacionSuscripcionQentry.fecha_elaboracion,
    estatus_sistema : IModificacionSuscripcionQentry.estatus_sistema,
    ultima_actualizacion : getDateString(SERVER_TIME_ZONE),
  };
}
