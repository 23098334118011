/**
 * Es el obejto Maker que se visualiza en el mapa
 * @interface MarkerProps
 *   @param {boolean} isSelected: se utiliza para definir si está seleccionado el Marker.
 *   @param {any} content: es el contenido del InfoWindow del Marker.
 *   @param {Function} onDrag: es el método a realizar cuando se arrastra el Marker
 * @function onClose es el método a realizar cuando se cierra el InfoWindow del Marker
 */

import { useState, useEffect } from "react";

interface MarkerProps extends google.maps.MarkerOptions {
  isSelected?: boolean,
  content: any,
  onDrag?: (e: google.maps.MapMouseEvent) => void,
}

const Marker: React.FC<MarkerProps> = ({
  isSelected,
  content,
  onDrag,
  ...options
}) => {
  const [marker, setMarker] = useState<google.maps.Marker>();
  const [isInfoWindowOpen, setInfoWindowOpen] = useState<boolean>(false);

  const onClose = () => {
    // avoid directly mutating state
    console.log("onClose");
    setInfoWindowOpen(false);
  };

  useEffect(() => {
    if (!marker) {
      setMarker(new google.maps.Marker());
    }

    // remove marker from map on unmount
    return () => {
      if (marker) {
        marker.setMap(null);
      }
    };
  }, [marker]);

  useEffect(() => {
    if (marker) {
      marker.setOptions(options);
    }
  }, [marker, options]);

  useEffect(() => {
    if (marker) {
      ["dragend"].forEach((eventName) =>
        google.maps.event.clearListeners(marker, eventName)
      );
  
      if (onDrag) {
        google.maps.event.addListener(marker, "dragend", onDrag);
      }
    }
  }, [marker, onDrag]);
  
  return (
    <>
    </>
  );
};

export default Marker;