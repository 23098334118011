import {
  IonButton,
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonCol,
  IonContent,
  IonGrid,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonRow,
  IonSpinner,
  IonText,
  useIonActionSheet,
  useIonAlert,
  useIonToast,
} from "@ionic/react";
import React, { useEffect, useState } from "react";
import Card from "../components/Card";
import { businessOutline, pencil } from "ionicons/icons";
import {
  ICodigoPostal,
  IEmpresa,
  IEstado,
  IGeolocalizacion,
  IPais,
  IUser,
  getEmptyCodigoPostal,
  getEmptyDatosFiscales,
  getEmptyDireccion,
  getEmptyEmpresa,
  getEmptyGeolocalizacion,
  getEmptyUser,
} from "../redux/interfaces/personas";
import useRedux, { useAppSelector } from "../hooks/useRedux";
import {
  getDireccion,
  getGlobalMemoizedEstadoAsSelectList,
  getGlobalMemoizedMvUsoFacturaAsSelectList,
  getGlobalMemoizedPaisAsSelectList,
} from "../redux/slices/personasSlice";
import { List } from "../redux/interfaces/helpers";
import { searchCodigoPostal } from "../redux/http/personas";
import Selector from "../components/Selector";
import { Status, Wrapper } from "@googlemaps/react-wrapper";
import Map from "../components/Map";
import Marker from "../components/Maker";
import { sleep } from "../redux/api/helpers";
import { StatusIcon } from "../components/GoogleMapsV3";
import GoogleMapsV3 from "../components/GoogleMapsV3";
import { DEFAULT_STATE } from "../redux/enums/default_state";
const CompanyForm: React.FC = () => {
  const [present] = useIonToast();
  const [presentAlert] = useIonAlert();
  const [presentCodigoPostalList] = useIonActionSheet();
  const [presentToast] = useIonToast();

  const [
    {
      auth,
      clienteQentry,
      direccion,
      estado,
      empresa,
      mvUsoFactura,
      location: empleadoLocation,
      pais,
      createEmpresa,
      updateEmpresa,
    },
  ] = useRedux();

  const estadoList = useAppSelector(getGlobalMemoizedEstadoAsSelectList);
  const paisList = useAppSelector(getGlobalMemoizedPaisAsSelectList);
  const mvUsoFacturaList = useAppSelector(
    getGlobalMemoizedMvUsoFacturaAsSelectList
  );

  const [autor, setAutor] = useState<IUser | null>(null);

  const [editing, setEditing] = useState<boolean>(false);

  const [companies, setCompanies] = useState<IEmpresa[]>([]);

  const [nombre_empresa, setNombreEmpresa] = useState<string>("");

  const [hiddenCodigoPostalId, setHiddenCodigoPostalId] = useState<number>(0);
  const [codigoPostal, setCodigoPostal] = useState<string>("");
  const [calle, setCalle] = useState<string>("");
  const [numeroExterior, setNumeroExterior] = useState<string>("");
  const [numeroInterior, setNumeroInterior] = useState<string>("");
  const [colonia, setColonia] = useState<string>("");
  const [ciudad, setCiudad] = useState<string>("");
  const [delegacionMunicipio, setDelegacionMunicipio] = useState<string>("");
  const [estadoEmpresa, setEstadoEmpresa] = useState<List>({
    id: "0",
    title: "",
  });
  const [paisEmpresa, setPaisEmpresa] = useState<List>({ id: "0", title: "" });
  const [selectedGeolocation, setSelectedGeolocation] =
    useState<IGeolocalizacion>(getEmptyGeolocalizacion());
  const [selectedCompany, setSelectedCompany] = useState<IEmpresa>(
    getEmptyEmpresa()
  );

  const [taxId, setTaxId] = useState<string>("");
  const [nombreORazonSocial, setNombreORazonSocial] = useState<string>("");
  const [usoFactura, setUsoFactura] = useState<List>({ id: "0", title: "" });

  // Mapa
  const [searchText, setSearchText] = useState(""); // initial zoom
  const [zoom, setZoom] = useState(10); // initial zoom
  const [center, setCenter] = useState<google.maps.LatLngLiteral>({
    lat: empleadoLocation.latitud,
    lng: empleadoLocation.longitud,
  });
  const [currentLocation, setCurrentLocation] = useState<google.maps.LatLng>(
    new google.maps.LatLng({
      lat: empleadoLocation.latitud,
      lng: empleadoLocation.longitud,
    })
  );

  const [places, setPlaces] = useState<google.maps.LatLng[]>([]);
  const [findPlace, setFindPlace] = useState<google.maps.Geocoder>();
  const [mapLoading, setMapLoading] = useState(true);

  useEffect(() => {
    setAutor(auth.empleado.usuario);
  }, [auth.empleado.usuario]);

  useEffect(() => {
    const filteredCompanies = empresa.data_array.filter(
      (company) => company.cliente_qentry.id === clienteQentry.data.id
    );
    setCompanies(filteredCompanies);
  }, [clienteQentry.data.id, empresa.data_array]);

  const cleanCompany = () => {
    setEditing(false);

    setNombreEmpresa("");

    setCalle("");
    setNumeroExterior("");
    setNumeroInterior("");
    setColonia("");
    setCiudad("");
    setDelegacionMunicipio("");
    setEstadoEmpresa({ id: "0", title: "" });
    setPaisEmpresa({ id: "0", title: "" });
    setCodigoPostal("");

    setNombreORazonSocial("");
    setTaxId("");
    setUsoFactura({ id: "0", title: "" });

    setPlaces([]);
    setCurrentLocation(
      new google.maps.LatLng({
        lat: empleadoLocation.latitud,
        lng: empleadoLocation.longitud,
      })
    );
    setCenter({
      lat: empleadoLocation.latitud,
      lng: empleadoLocation.longitud,
    });
  };

  /** Métodos para mapa */
  const render = (status: Status) => {
    if (status === Status.SUCCESS) setMapLoading(false);
  };

  const removeAccents = (str: string) => {
    return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
  };

  const getCpIfExist = async (codigoPostal: string, colonia: string) => {
    console.log("Dentro de getCpIfExist");

    console.log("Codigo a buscar", codigoPostal, "Colonia a buscar", colonia);
    const listaCP = await searchCodigoPostal(codigoPostal);
    console.log("lista de codigos encontrados", listaCP);
    return listaCP.length === 0
      ? undefined
      : listaCP.length === 1
      ? listaCP[0]
      : listaCP.find((itemCp) =>
          removeAccents(itemCp.nombre_asentamiento).includes(colonia)
        );
  };

  const searchCP = async (autosearch = true) => {
    //setValidCP(false);
    if (!codigoPostal.length) {
      return presentAlert({
        header: "Aviso",
        message: "Código postal vacío",
        buttons: ["OK"],
      });
    }
    const listaCP = await searchCodigoPostal(codigoPostal);
    if (listaCP.length === 0) {
      return presentAlert({
        header: "Aviso",
        message: "No se encontró el código postal",
        buttons: ["OK"],
      });
    }
    return presentCodigoPostalList({
      buttons: [
        ...listaCP.map((cpItem) => ({
          text: `${cpItem.codigo_postal}, ${cpItem.nombre_asentamiento}`,
          handler: () => {
            const estadoValue = estado.data_array.find(
              (e) => e.nombre === cpItem.nombre_entidad
            )!;
            const paisValue = pais.data_array.find(
              (e) => e.nombre.includes("México") || e.nombre.includes("Mexico")
            )!;

            setHiddenCodigoPostalId(cpItem.id);
            setColonia(cpItem.nombre_asentamiento);
            setCiudad(cpItem.nombre_ciudad);
            setDelegacionMunicipio(cpItem.nombre_municipio);
            if (estadoValue) {
              setEstadoEmpresa({
                id: estadoValue.id.toString(),
                title: estadoValue.nombre,
              });
            }
            if (paisValue) {
              setPaisEmpresa({
                id: paisValue.id.toString(),
                title: paisValue.nombre,
              });
            }
            if (autosearch)
              setSearchText(
                `${codigoPostal} ${estadoValue.nombre} ${paisValue.nombre}`
              );
            // setValidCP(true);
          },
        })),
        { text: "Cancelar", role: "cancel" },
      ],
      header: "Seleccionar código postal",
    });
  };

  const addressComponent = (
    geocoderResult: google.maps.GeocoderResult,
    componentType: string
  ) => {
    if (
      !geocoderResult.address_components ||
      geocoderResult.address_components.length === 0
    )
      return "";
    const res = geocoderResult.address_components.find((e) =>
      e.types.includes(componentType)
    );
    if (!res) return "";
    return res.long_name;
  };

  const onClick = (e: google.maps.MapMouseEvent) => {
    // avoid directly mutating state
    console.log("dentro onClick e", e);
    setPlaces([e.latLng!]);
    setCurrentLocation(e.latLng!);
    setCenter({ lat: e.latLng!?.lat(), lng: e.latLng!?.lng() });
    setZoom(20);
    fillAddressFromCurrentPosition(e.latLng!);
  };

  const fillAddressFromCurrentPosition = (position: google.maps.LatLng) => {
    const newGeocoder = new google!.maps!.Geocoder();
    console.log(
      "newGeocoder dentro de fillAddressFromCurrentPosition",
      newGeocoder
    );

    // onPlaceAdded([value]);
    //if (findPlace === undefined) setFindPlace(newGeocoder);
    console.log("Despues de findPlace fillAddressFromCurrentPosition");
    sleep(200).then(() => {
      console.log("Dentro de sleep fillAddressFromCurrentPosition", findPlace);

      newGeocoder
        ? newGeocoder.geocode({ location: position }, async (result) => {
            console.log(
              "Dentro de geocode de fillAddressFromCurrentPosition",
              result
            );

            if (!result) {
              presentToast({
                message:
                  "No se pudo obtener la dirección del punto marcado. Por favor agréguelo manualmente",
                color: "warning",
                duration: 2500,
              });
              return;
            }
            newGeocoder.geocode(
              { placeId: result[0].place_id, componentRestrictions: null },
              async (finalResult) => {
                console.log(
                  "finalResult fillAddressFromCurrentPosition",
                  finalResult
                );

                if (!finalResult) {
                  presentToast({
                    message:
                      "No pudo obtener la dirección a traves del id del punto marcado, por favor agréguelo manualmente",
                    color: "warning",
                    duration: 2500,
                  });
                  return;
                }
                console.log({ finalResult });
                const numExteriorAddress = addressComponent(
                  finalResult[0],
                  "street_number"
                );
                const calleAddress = addressComponent(finalResult[0], "route");
                const coloniaAddress = addressComponent(
                  finalResult[0],
                  "sublocality_level_1"
                );
                const ciudadAddress = addressComponent(
                  finalResult[0],
                  "locality"
                );
                const entidadAddress = addressComponent(
                  finalResult[0],
                  "administrative_area_level_1"
                );
                const paisAddress = addressComponent(finalResult[0], "country");
                const cpAddress = addressComponent(
                  finalResult[0],
                  "postal_code"
                );

                if (!cpAddress) {
                  // setValidCP(false);
                  presentToast({
                    message:
                      "La ubicación marcada no tiene un código postal, por favor agréguelo manualmente",
                    color: "warning",
                    duration: 2500,
                  });
                  return;
                }

                const estadoValue = estado.data_array.find((e) =>
                  e.nombre.includes(entidadAddress)
                )!;
                const paisValue = pais.data_array.find(
                  (e) =>
                    e.nombre.includes("México") || e.nombre.includes("Mexico")
                )!;

                if (estadoValue) {
                  setEstadoEmpresa({
                    id: estadoValue.id.toString(),
                    title: estadoValue.nombre,
                  });
                }
                if (paisValue) {
                  setPaisEmpresa({
                    id: paisValue.id.toString(),
                    title: paisValue.nombre,
                  });
                }

                setNumeroExterior(numExteriorAddress);
                setCalle(calleAddress);
                setColonia(coloniaAddress);
                setCiudad(ciudadAddress);
                setCodigoPostal(cpAddress);

                setSearchText(
                  `${cpAddress} ${estadoValue ? estadoValue.nombre : ""} ${
                    paisValue ? paisValue.nombre : ""
                  }`
                );

                console.log(
                  "searchText useEffect fillAddressFromCurrentPosition",
                  searchText
                );

                const findedCP = await getCpIfExist(
                  cpAddress,
                  ciudadAddress !== "" ? coloniaAddress : ciudadAddress
                );
                console.log("Codigo encontrado", findedCP);

                if (findedCP) {
                  setHiddenCodigoPostalId(findedCP.id);
                  setDelegacionMunicipio(findedCP.nombre_municipio);
                  setColonia(findedCP.nombre_asentamiento);
                  setCiudad(findedCP.nombre_ciudad);
                }
                // setValidCP(true);
              }
            );
          })
        : console.log("noooooo newGeocoder");
    });
    console.log("Despues de sleep");
  };

  const onPlaceAdded = (e: google.maps.LatLng[]) => {
    console.log("Dentro de onPlaceAdded", e);

    setPlaces([...e]);
    setCurrentLocation(e[0]);
    if (!findPlace) setFindPlace(new google.maps.Geocoder());
    sleep(200).then(() => {
      findPlace?.geocode({ location: e[0] }, (result) => {
        console.log("geocode result onPlaceAdded", result);

        if (!result) {
          presentToast({
            message:
              "No se pudo obtener la dirección del punto marcado. Por favor agréguelo manualmente",
            color: "warning",
            duration: 2500,
          });
          return;
        }
        findPlace.geocode(
          { placeId: result[0].place_id, componentRestrictions: null },
          (finalResult) => {
            if (!finalResult) {
              presentToast({
                message:
                  "No pudo obtener la dirección a través del id del punto marcado, por favor agréguelo manualmente",
                color: "warning",
                duration: 2500,
              });
              return;
            }
            const cpAddress = addressComponent(finalResult[0], "postal_code");

            if (!cpAddress) {
              presentToast({
                message:
                  "La ubicación marcada no tiene un código postal, por favor agréguelo manualmente",
                color: "warning",
                duration: 2500,
              });
              return;
            }
            setCodigoPostal(cpAddress);
            searchCP(false);
          }
        );
      });
    });
    // setValidCP(true);
  };

  const onPlacesDrag = (e: google.maps.MapMouseEvent) => {
    setPlaces([e.latLng!]);
    setCurrentLocation(e.latLng!);
    sleep(200).then(() => {
      findPlace?.geocode({ location: e.latLng }, (result) => {
        if (!result) {
          presentToast({
            message:
              "La ubicación marcada no tiene un código postal, por favor agréguelo manualmente",
            color: "warning",
            duration: 2500,
          });
          return;
        }
        findPlace.geocode(
          { placeId: result[0].place_id, componentRestrictions: null },
          (finalResult) => {
            if (!finalResult) {
              presentToast({
                message:
                  "La ubicación marcada no tiene un código postal, por favor agréguelo manualmente",
                color: "warning",
                duration: 2500,
              });
              return;
            }
            const cp = addressComponent(finalResult[0], "postal_code");
            if (!cp) {
              presentToast({
                message:
                  "La ubicación marcada no tiene un código postal, por favor agréguelo manualmente",
                color: "warning",
                duration: 2500,
              });
              return;
            }
            setCodigoPostal(cp);
            searchCP(false);
          }
        );
      });
    });
    // setValidCP(true);
  };

  const onIdle = (m: google.maps.Map) => {
    if (m.getZoom()) setZoom(m.getZoom()!);
    if (m.getCenter()) setCenter(m.getCenter()!.toJSON());
  };

  /** Metodos CRUD empresa */
  const createCompany = async () => {
    try {
      const tempDireccion = getEmptyDireccion();
      const tempDatosFiscales = getEmptyDatosFiscales();
      const tempEmpresa = getEmptyEmpresa();

      tempDireccion.calle = calle;
      tempDireccion.numero_exterior = numeroExterior;
      tempDireccion.numero_interior = numeroInterior;
      tempDireccion.colonia = colonia;
      tempDireccion.ciudad = ciudad;
      tempDireccion.delegacion_municipio = delegacionMunicipio;
      const cp = await getCpIfExist(codigoPostal, colonia);
      tempDireccion.codigo_postal = cp ? cp : getEmptyCodigoPostal();
      tempDireccion.estado = estado.data_array.find(
        (e) => e.id.toString() === estadoEmpresa.id
      )!;
      tempDireccion.pais = pais.data_array.find(
        (e) => e.id.toString() === paisEmpresa.id
      )!;
      tempDireccion.estatus_sistema = true;
      tempDireccion.autor = autor!;
      tempDireccion.cliente_qentry = clienteQentry.data;

      tempDireccion.geolocalizacion.descripcion = getDireccion(tempDireccion);
      tempDireccion.geolocalizacion.latitud = places.length
        ? String(places[0].lat())
        : "";
      tempDireccion.geolocalizacion.longitud = places.length
        ? String(places[0].lng())
        : "";
      tempDireccion.geolocalizacion.autor = autor!;
      tempDireccion.geolocalizacion.cliente_qentry = clienteQentry.data;
      tempDireccion.geolocalizacion.estatus_sistema = true;

      tempDatosFiscales.nombre_o_razon_social = nombreORazonSocial;
      tempDatosFiscales.tax_id = taxId;
      tempDatosFiscales.uso_factura = mvUsoFactura.data_array.find(
        (e) => e.id.toString() === usoFactura.id
      )!;
      tempDatosFiscales.rfc_default = true;
      tempDatosFiscales.estatus_sistema = true;
      tempDatosFiscales.autor = autor!;

      tempEmpresa.nombre_empresa = nombre_empresa;
      tempEmpresa.direccion = tempDireccion;
      tempEmpresa.datos_fiscales = tempDatosFiscales;
      tempEmpresa.cliente_qentry = clienteQentry.data;
      tempEmpresa.estatus_sistema = true;
      tempEmpresa.autor = autor!;
      createEmpresa(tempDireccion, tempDatosFiscales, tempEmpresa);
      cleanCompany();
    } catch (error) {
      present({
        message: "Hubo un problema al intentar crear la empresa",
        color: "danger",
        duration: 1500,
      });
    }
  };

  const editCompany = (item: IEmpresa) => {
    setEditing(true);
    const { direccion, datos_fiscales } = item;

    setSelectedCompany({ ...item });
    setNombreEmpresa(item.nombre_empresa);

    setCalle(direccion.calle);
    setNumeroExterior(direccion.numero_exterior);
    setNumeroInterior(direccion.numero_interior);
    setColonia(direccion.colonia);
    setCiudad(direccion.ciudad);
    setDelegacionMunicipio(direccion.delegacion_municipio);
    setCodigoPostal(direccion.codigo_postal.codigo_postal);
    setHiddenCodigoPostalId(direccion.codigo_postal.id);
    setEstadoEmpresa(
      estadoList.find((el) => el.id === direccion.estado.id.toString())!
    );
    setPaisEmpresa(
      paisList.find((pl) => pl.id === direccion.pais.id.toString())!
    );
    try {
      const location = {
        lat: Number(direccion.geolocalizacion.latitud),
        lng: Number(direccion.geolocalizacion.longitud),
      };
      const value = new google!.maps!.LatLng(location);
      setPlaces([value]);
      setCurrentLocation(value);
      setCenter(location);
    } catch (error) {
      console.log("Error al intentar establecer la latitud y la longitud");
    }

    setNombreORazonSocial(datos_fiscales.nombre_o_razon_social);
    setTaxId(datos_fiscales.tax_id);
    setUsoFactura(
      mvUsoFacturaList.find(
        (ufl) => ufl.id === datos_fiscales.uso_factura.id.toString()
      )!
    );
  };

  const updateCompany = async () => {
    try {
      const tempDireccion = getEmptyDireccion();
      const tempDatosFiscales = getEmptyDatosFiscales();
      const tempEmpresa = getEmptyEmpresa();

      tempDireccion.id = selectedCompany.direccion.id;
      tempDireccion.calle = calle;
      tempDireccion.numero_exterior = numeroExterior;
      tempDireccion.numero_interior = numeroInterior;
      tempDireccion.colonia = colonia;
      tempDireccion.ciudad = ciudad;
      tempDireccion.delegacion_municipio = delegacionMunicipio;
      tempDireccion.estado = estado.data_array.find(
        (e) => e.id.toString() === estadoEmpresa.id
      )!;
      tempDireccion.pais = pais.data_array.find(
        (e) => e.id.toString() === paisEmpresa.id
      )!;
      tempDireccion.estatus_sistema = true;
      tempDireccion.autor = autor!;
      tempDireccion.cliente_qentry = clienteQentry.data;
      const cp = await getCpIfExist(codigoPostal, colonia);
      tempDireccion.codigo_postal = cp ? cp : getEmptyCodigoPostal();

      tempDireccion.geolocalizacion.id =
        selectedCompany.direccion.geolocalizacion.id;
      tempDireccion.geolocalizacion.descripcion = getDireccion(tempDireccion);
      tempDireccion.geolocalizacion.latitud = places.length
        ? String(places[0].lat())
        : "";
      tempDireccion.geolocalizacion.longitud = places.length
        ? String(places[0].lng())
        : "";
      tempDireccion.geolocalizacion.autor = autor!;
      tempDireccion.geolocalizacion.cliente_qentry = clienteQentry.data;
      tempDireccion.geolocalizacion.estatus_sistema = true;

      tempDatosFiscales.id = selectedCompany.datos_fiscales.id;
      tempDatosFiscales.nombre_o_razon_social = nombreORazonSocial;
      tempDatosFiscales.tax_id = taxId;
      tempDatosFiscales.uso_factura = mvUsoFactura.data_array.find(
        (e) => e.id.toString() === usoFactura.id
      )!;
      tempDatosFiscales.rfc_default = true;
      tempDatosFiscales.estatus_sistema = true;
      tempDatosFiscales.autor = autor!;

      tempEmpresa.id = selectedCompany.id;
      tempEmpresa.nombre_empresa = nombre_empresa;
      tempEmpresa.direccion = tempDireccion;
      tempEmpresa.datos_fiscales = tempDatosFiscales;
      tempEmpresa.cliente_qentry = clienteQentry.data;
      tempEmpresa.estatus_sistema = true;
      tempEmpresa.autor = autor!;

      updateEmpresa(tempDireccion, tempDatosFiscales, tempEmpresa);
      setEditing(false);
      cleanCompany();
    } catch (error) {
      console.log("Error en updateCompany", error);

      present({
        message: "Hubo un problema al intentar actualizar la empresa",
        color: "danger",
        duration: 1500,
      });
    }
  };
  return (
    <IonGrid>
      <IonCol size="12">
        <Card
          icon={businessOutline}
          title={<IonLabel>Empresas</IonLabel>}
          content={
            <IonGrid className="no-padding">
              <IonRow className="no-padding">
                <IonCol size="12">
                  <IonItem>
                    <IonLabel position="stacked">Nombre de la empresa</IonLabel>
                    <IonInput
                      id="company-id"
                      type="text"
                      color="secondary"
                      value={nombre_empresa}
                      onIonChange={(data) =>
                        setNombreEmpresa(data.detail.value!)
                      }
                    />
                  </IonItem>
                </IonCol>
                <IonCol size="12">
                  <IonItem>
                    <IonLabel position="stacked">
                      Nombre o razón social
                    </IonLabel>
                    <IonInput
                      id="company-id"
                      type="text"
                      color="secondary"
                      value={nombreORazonSocial}
                      onIonChange={(data) =>
                        setNombreORazonSocial(data.detail.value!)
                      }
                    />
                  </IonItem>
                </IonCol>
                <IonCol size="6">
                  <IonItem>
                    <IonLabel position="stacked">
                      RFC<IonText color="danger">*</IonText>
                    </IonLabel>
                    <IonInput
                      id="company-id"
                      type="text"
                      color="secondary"
                      value={taxId}
                      onIonChange={(data) => setTaxId(data.detail.value!)}
                    />
                  </IonItem>
                </IonCol>
                <IonCol size="6">
                  <Selector
                    value={usoFactura}
                    label="Uso factura"
                    list={mvUsoFacturaList}
                    change={setUsoFactura}
                  />
                </IonCol>
                <IonCol size="12">
                  <IonGrid className="no-padding">
                    <IonRow className="no-padding">
                      <IonCol size="6">
                        <Wrapper
                          apiKey={process.env.REACT_APP_GOOGLE_MAPS_KEY!}
                          libraries={["places"]}
                          callback={render}
                        >
                          {mapLoading ? (
                            "Cargando mapa..."
                          ) : (
                            <Map
                              center={center}
                              onClick={onClick}
                              onIdle={onIdle}
                              onPlaceAdded={onPlaceAdded}
                              zoom={zoom}
                              hasSearchBox={false}
                              searchText={searchText}
                              style={{ flexGrow: "1", height: "100%" }}
                            >
                              <Marker
                                key="direccion-empresa"
                                icon={StatusIcon[3]}
                                position={currentLocation}
                                content={"Dirección"}
                                clickable={true}
                                draggable={true}
                                onDrag={onPlacesDrag}
                              />
                            </Map>
                          )}
                        </Wrapper>
                      </IonCol>
                      <IonCol size="6">
                        <IonGrid>
                          <IonRow>
                            <IonCol
                              sizeXl="6"
                              sizeLg="6"
                              sizeMd="6"
                              sizeSm="6"
                              sizeXs="12"
                              size="12"
                            >
                              <IonItem>
                                <IonLabel position="stacked">
                                  Calle<IonText color="danger">*</IonText>
                                </IonLabel>
                                <IonInput
                                  clearInput={true}
                                  type="text"
                                  color="secondary"
                                  value={calle}
                                  onIonChange={(data) =>
                                    setCalle(data.detail.value!)
                                  }
                                />
                              </IonItem>
                            </IonCol>
                            <IonCol
                              sizeXl="6"
                              sizeLg="6"
                              sizeMd="6"
                              sizeSm="6"
                              sizeXs="12"
                              size="12"
                            >
                              <IonItem>
                                <IonLabel position="stacked">
                                  Número exterior
                                  <IonText color="danger">*</IonText>
                                </IonLabel>
                                <IonInput
                                  clearInput={true}
                                  type="text"
                                  color="secondary"
                                  value={numeroExterior}
                                  onIonChange={(data) =>
                                    setNumeroExterior(data.detail.value!)
                                  }
                                />
                              </IonItem>
                            </IonCol>
                            <IonCol
                              sizeXl="6"
                              sizeLg="6"
                              sizeMd="6"
                              sizeSm="6"
                              sizeXs="12"
                              size="12"
                            >
                              <IonItem>
                                <IonLabel position="stacked">
                                  Número interior
                                </IonLabel>
                                <IonInput
                                  clearInput={true}
                                  type="text"
                                  color="secondary"
                                  value={numeroInterior}
                                  onIonChange={(data) =>
                                    setNumeroInterior(data.detail.value!)
                                  }
                                />
                              </IonItem>
                            </IonCol>
                            <IonCol
                              sizeXl="6"
                              sizeLg="6"
                              sizeMd="6"
                              sizeSm="6"
                              sizeXs="12"
                              size="12"
                            >
                              <IonItem>
                                <IonLabel position="stacked">
                                  Colonia<IonText color="danger">*</IonText>
                                </IonLabel>
                                <IonInput
                                  clearInput={true}
                                  type="text"
                                  color="secondary"
                                  value={colonia}
                                  onIonChange={(data) =>
                                    setColonia(data.detail.value!)
                                  }
                                />
                              </IonItem>
                            </IonCol>
                            <IonCol
                              sizeXl="6"
                              sizeLg="6"
                              sizeMd="6"
                              sizeSm="6"
                              sizeXs="12"
                              size="12"
                            >
                              <IonItem>
                                <IonLabel position="stacked">
                                  Ciudad<IonText color="danger">*</IonText>
                                </IonLabel>
                                <IonInput
                                  clearInput={true}
                                  type="text"
                                  color="secondary"
                                  value={ciudad}
                                  onIonChange={(data) =>
                                    setCiudad(data.detail.value!)
                                  }
                                />
                              </IonItem>
                            </IonCol>
                            <IonCol
                              sizeXl="6"
                              sizeLg="6"
                              sizeMd="6"
                              sizeSm="6"
                              sizeXs="12"
                              size="12"
                            >
                              <IonItem>
                                <IonLabel position="stacked">
                                  Delegación/Municipio
                                </IonLabel>
                                <IonInput
                                  clearInput={true}
                                  type="text"
                                  color="secondary"
                                  value={delegacionMunicipio}
                                  onIonChange={(data) =>
                                    setDelegacionMunicipio(data.detail.value!)
                                  }
                                />
                              </IonItem>
                            </IonCol>
                            <IonCol
                              sizeXl="6"
                              sizeLg="6"
                              sizeMd="6"
                              sizeSm="6"
                              sizeXs="12"
                              size="12"
                            >
                              <Selector
                                value={estadoEmpresa}
                                label="Estado"
                                list={estadoList}
                                change={setEstadoEmpresa}
                              />
                            </IonCol>
                            <IonCol
                              sizeXl="6"
                              sizeLg="6"
                              sizeMd="6"
                              sizeSm="6"
                              sizeXs="12"
                              size="12"
                            >
                              <Selector
                                value={paisEmpresa}
                                label="País"
                                list={paisList}
                                change={setPaisEmpresa}
                              />
                            </IonCol>
                            <IonCol
                              sizeXl="6"
                              sizeLg="6"
                              sizeMd="6"
                              sizeSm="6"
                              sizeXs="12"
                              size="12"
                            >
                              <IonItem>
                                <IonLabel position="stacked">
                                  Código postal
                                </IonLabel>
                                <IonInput
                                  clearInput={true}
                                  type="text"
                                  color="secondary"
                                  value={codigoPostal}
                                  onIonChange={(data) =>
                                    setCodigoPostal(data.detail.value!)
                                  }
                                />
                              </IonItem>
                            </IonCol>
                          </IonRow>
                        </IonGrid>
                      </IonCol>
                    </IonRow>
                  </IonGrid>
                </IonCol>
                <IonCol size="12">
                  <IonButton
                    expand="block"
                    color="primary"
                    onClick={() =>
                      editing ? updateCompany() : createCompany()
                    }
                  >
                    {editing ? "Actualizar" : "Subir"}
                  </IonButton>
                </IonCol>
                {editing ? (
                  <IonCol size="12">
                    <IonButton
                      expand="block"
                      color="warning"
                      onClick={() => cleanCompany()}
                    >
                      Cancelar
                    </IonButton>
                  </IonCol>
                ) : (
                  <></>
                )}
              </IonRow>
            </IonGrid>
          }
        />
      </IonCol>
      <IonCol size="12">
        <Card
          icon={businessOutline}
          title={<IonLabel>Lista de Empresas</IonLabel>}
          content={
            <IonGrid className="no-padding">
              <IonRow className="no-padding">
                <IonCol size="12">
                  <IonItem color="secondary" lines="full">
                    <IonLabel>
                      <h3>Lista de empresas activas</h3>
                    </IonLabel>
                  </IonItem>
                  {empresa.estado === DEFAULT_STATE.loading &&
                  direccion.estado === DEFAULT_STATE.loading ? (
                    <IonItem lines="none">
                      <IonLabel>Cargando lista de empresas activas.</IonLabel>
                      <IonSpinner
                        className="loading-center-horizontal"
                        name="circles"
                        color="medium"
                        slot="start"
                      />
                    </IonItem>
                  ) : (
                    <IonList className="selection-area">
                      {companies.map((company) => {
                        return (
                          <IonItem key={`company-${company.id}`} lines="full">
                            <IonLabel>
                              <h3>
                                <b>Empresa</b>: {company.nombre_empresa}
                              </h3>
                              <h3>
                                <b>Dirección</b>:{" "}
                                {getDireccion(company.direccion)}
                              </h3>
                              <h3>
                                <b>RFC</b>:{" "}
                                {company.datos_fiscales &&
                                company.datos_fiscales.id
                                  ? company.datos_fiscales.tax_id
                                  : ""}
                              </h3>
                            </IonLabel>
                            <IonButton
                              fill="clear"
                              slot="end"
                              onClick={() => editCompany(company)}
                            >
                              <IonIcon
                                slot="icon-only"
                                icon={pencil}
                                color="primary"
                              />
                            </IonButton>
                          </IonItem>
                        );
                      })}
                    </IonList>
                  )}
                </IonCol>
              </IonRow>
            </IonGrid>
          }
        />
      </IonCol>
    </IonGrid>
  );
};
export default CompanyForm;
