/**
 * Métodos AsyncThunk para ser usados en los reducers y
 * los resultados a las llamadas http puedan ser usados para
 * actualizar el state en redux
 */

import { createAsyncThunk } from "@reduxjs/toolkit";
import { DEFAULT_STATE } from "../../enums/default_state";
import { getTurno, getAllTurno, updateTurno, createTurno, deleteTurno } from "../../http/nomina";
import { getEmptyStatusTurno, ITurno } from "../../interfaces/nomina";
import { IServerDate } from "../../interfaces/time";

export const getTurnoAsyncThunk = createAsyncThunk(
  'nomina/getTurnoAsyncThunk',
  async (query : {serverDate : IServerDate, id: number, idCliente : number}) => await getTurno(query.serverDate, query.id, query.idCliente)
    .then(async (result) => result)
    .catch(async (error) => getEmptyStatusTurno(DEFAULT_STATE.error))  
);

export const getAllTurnoAsyncThunk = createAsyncThunk(
  'nomina/getAllTurnoAsyncThunk',
  async (queryAll : {serverDate : IServerDate, idCliente : number}) => await getAllTurno(queryAll.serverDate, queryAll.idCliente)
    .then(async (result) => result)
    .catch(async (error) => getEmptyStatusTurno(DEFAULT_STATE.error))  
);

export const updateTurnoAsyncThunk = createAsyncThunk(
  'nomina/updateTurnoAsyncThunk',
  async (update: {serverDate : IServerDate, id: number, idCliente : number, object: ITurno}) => await updateTurno(update.serverDate, update.id, update.idCliente, update.object)
    .then(async (result) => result)
    .catch(async (error) => getEmptyStatusTurno(DEFAULT_STATE.error))  
);

export const createTurnoAsyncThunk = createAsyncThunk(
  'nomina/createTurnoAsyncThunk',
  async (create : {serverDate : IServerDate, idCliente : number, object: ITurno}) => await createTurno(create.serverDate, create.idCliente, create.object)
    .then(async (result) => result)
    .catch(async (error) => getEmptyStatusTurno(DEFAULT_STATE.error))  
);

export const deleteTurnoAsyncThunk = createAsyncThunk(
  'nomina/deleteTurnoAsyncThunk',
  async (del : {id : number, idCliente : number}) => await deleteTurno(del.id, del.idCliente)
    .then(async (result) => result)
    .catch(async (error) => getEmptyStatusTurno())  
);
