/**
 * Es el componente utilizado en la página Rondines para la creación, edición y eliminación de estos.
 * @function doReorder reordena los elementos en el array @selectedWorkCenters cuando se hace un reodenamiento de los items en @IonReorderGroup
 * @function scroll cuando se selecciona a editar un rondin hace el scroll para llevarte al componente de edición
 * @function setToEdit activa el modo editar del rondin seleccionado
 * @function cancelEdit cancela la edición del rondin seleccionado
 * @function uploadRondin crea o actualiza el rondin
 * @TableActions
 *   @function backPage regresa a la página anterior mientras esta no sea la primera página
 *   @function forwardPage procede a la página siguiente mientras no pase del número de páginas
 */

import React, { useState, useEffect } from "react";
import {
  IonGrid,
  IonRow,
  IonCol,
  IonLabel,
  IonButton,
  IonInput,
  IonIcon,
  IonItem,
  IonContent,
  useIonToast,
  IonList,
  IonListHeader,
  IonButtons,
  IonReorderGroup,
  IonReorder,
  IonSpinner,
  IonSearchbar,
  IonAccordionGroup,
  IonAccordion,
  IonPopover,
  IonDatetime,
  IonChip,
} from "@ionic/react";
import {
  businessOutline,
  pencilOutline,
  walkOutline,
  chevronBackSharp,
  chevronForwardSharp,
  calendar,
} from "ionicons/icons";
import Card from "../components/Card";
import Selector from "../components/Selector";
import { ItemReorderEventDetail } from "@ionic/core";
import useRedux, { useAppDispatch, useAppSelector } from "../hooks/useRedux";
import { DEFAULT_STATE } from "../redux/enums/default_state";
import {
  getEmptyCheckpointRondin,
  getEmptyRondin,
  ICheckpointRondin,
  IRondin,
  IRondinCancelado,
  IRondinCompletado,
  setRondin,
} from "../redux/interfaces/rondines";
import {
  getGlobalMemoizedEmpresaAsSelectList,
  getNombreEmpleado,
} from "../redux/slices/personasSlice";
import {
  getMemoizedPatrolsActiveCentroDetrabajoAsSelectList,
  getMemoizedPatrolsActiveEmpleadoAsSelectList,
  getMemoizedPatrolsRondinesList,
  getMemoizedPatrolsSelectedEmpleados,
  getMemoizedPatrolsSelectedWorkCenters,
  setPatrolsRondinModal,
  setPatrolsSelectedEmpresa,
} from "../redux/slices/pagesSlice/patrolsSlice";
import { List, setListItem } from "../redux/interfaces/helpers";
import "./Patrols.css";
import { getOnlyDateString } from "../redux/api/helpers";

const Patrols: React.FC = () => {
  const dispatch = useAppDispatch();
  const [
    {
      serverDate,
      clienteQentry,
      empresa,
      empleado,
      codigoQr,
      rondin,
      rondinCancelado,
      rondinCompletado,
      checkRondin,
      addOrEditRondin,
      deleteRondin,
    },
  ] = useRedux();

  const empresaList = useAppSelector(getGlobalMemoizedEmpresaAsSelectList);
  const empleadoList = useAppSelector(
    getMemoizedPatrolsActiveEmpleadoAsSelectList
  );
  const centroDeTrabajoList = useAppSelector(
    getMemoizedPatrolsActiveCentroDetrabajoAsSelectList
  );
  const selectedEmpresa = useAppSelector(
    (state) => state.patrols.selectedEmpresa
  );
  const selectedCentroDeTrabajo = useAppSelector(
    getMemoizedPatrolsSelectedWorkCenters
  );
  const selectedEmpleados = useAppSelector(getMemoizedPatrolsSelectedEmpleados);
  const rondinModal = useAppSelector((state) => state.patrols.rondinModal);
  const rondinList = useAppSelector(getMemoizedPatrolsRondinesList);

  const [presentToast, dismissToast] = useIonToast();
  let [editing, setEditing] = useState<boolean>(false);

  let [patrolName, setPatrolName] = useState<string>("");
  let [maxDistance, setMaxDistance] = useState<string>("");
  let [selectedWorkCenters, setSelectedWorkCenters] = useState<List[]>(
    selectedCentroDeTrabajo
  );
  let [selectedWorkers, setSelectedWorkers] =
    useState<List[]>(selectedEmpleados);

  const [selectedDate, setSelectedDate] = useState<string>(getOnlyDateString());
  const [selectedEstatusRondin, setSelectedEstatusRondin] = useState<List>({
    id: "",
    title: "Todos los estatus",
  });
  const estatusRondinList = [
    { id: "", title: "Todos los estatus" },
    { id: "1", title: "Completados" },
    { id: "2", title: "Cancelados" },
    { id: "3", title: "En proceso" },
  ];

  const [patrolsCompleted, setPatrolsCompleted] = useState<IRondinCompletado[]>(
    []
  );
  const [patrolsCanceled, setPatrolsCanceled] = useState<IRondinCancelado[]>(
    []
  );
  const [rondinesByDate, setRondinesByDate] = useState<any[]>([]);

  const doReorder = (event: CustomEvent<ItemReorderEventDetail>) => {
    const copyOfSelectedWorCenters = JSON.parse(
      JSON.stringify(selectedWorkCenters)
    );
    let draggedItem = copyOfSelectedWorCenters.splice(event.detail.from, 1)[0];
    copyOfSelectedWorCenters.splice(event.detail.to, 0, draggedItem);
    // console.log("copyOfSelectedWorCenters", copyOfSelectedWorCenters);
    setSelectedWorkCenters([]);
    setSelectedWorkCenters([...copyOfSelectedWorCenters]);
    event.detail.complete();
  };

  const scroll = (toBottom: boolean) => {
    const content = document.getElementById(
      "rondines-content"
    ) as HTMLIonContentElement;
    if (!content) {
      return;
    }
    if (toBottom) content.scrollToBottom(500);
    else content.scrollToTop(500);
  };

  const setToEdit = (rondin: IRondin) => {
    setPatrolName("");
    setMaxDistance("");
    dispatch(setPatrolsRondinModal(rondin));
    setEditing(true);
    scroll(true);
  };
  const cancelEdit = () => {
    dispatch(setPatrolsRondinModal(getEmptyRondin()));
    dispatch(
      setPatrolsSelectedEmpresa({ id: "", title: "Todas las empresas" })
    );
    setPatrolName("");
    setMaxDistance("");
    setSelectedWorkCenters([]);
    setSelectedWorkers([]);
    setEditing(false);
    scroll(false);
  };

  // Table Actions
  let [searchText, setSearchText] = useState<string>("");
  let [pageMessage, setPageMessage] = useState<string>("0-0 de 1");
  let [deletion, setDeletion] = useState<boolean>(false);
  let [page, setPage] = useState(1);
  let [totalPages, setTotalPages] = useState(1);
  let [items, setItems] = useState<IRondin[]>(rondinList);
  let [iniItemList, setIniItemList] = useState(1);
  let [finItemList, setFinItemList] = useState(10);

  const backPage = () => {
    if (page > 1) {
      setPage(page - 1);
    }
  };

  const forwardPage = () => {
    if (page < totalPages) {
      setPage(page + 1);
    }
  };

  // End Table Actions

  const uploadRondin = async () => {
    const tempRondin = { ...(await setRondin(rondinModal, serverDate.data)) };
    tempRondin.cliente_qentry = clienteQentry.data;
    if (tempRondin.empresa.id === 0)
      tempRondin.empresa = empresa.data_array.find(
        (e) => e.id.toString() === selectedEmpresa.id
      )!;

    if (tempRondin.id === 0 && (!patrolName || !patrolName.trim())) {
      presentToast({
        message: "Por favor ingrese el nombre del rondín.",
        color: "danger",
        duration: 2500,
      });
      return;
    }

    if (tempRondin.id === 0 && (!maxDistance || !maxDistance.trim())) {
      presentToast({
        message: "Por favor ingrese una distancia máxima para checar.",
        color: "danger",
        duration: 2500,
      });
      return;
    }

    if (tempRondin.id === 0 && selectedWorkCenters.length < 2) {
      presentToast({
        message: "Por favor seleccione más de un centro de trabajo",
        color: "danger",
        duration: 2500,
      });
      return;
    }

    if (tempRondin.id === 0 && !selectedWorkers.length) {
      presentToast({
        message: "Por favor seleccione, al menos, un trabajador",
        color: "danger",
        duration: 2500,
      });
      return;
    }

    tempRondin.nombre_rondin = patrolName;
    tempRondin.distancia_maxima_permitida = maxDistance;
    tempRondin.empleados = empleado.data_array.filter((e) =>
      selectedWorkers.some((v) => v.id === e.id.toString())
    );
    if (tempRondin.id === 0) {
      tempRondin.checkpoints = [];
      tempRondin.estatus_sistema = true;

      selectedWorkCenters.forEach((workCenter, i) => {
        console.log("workCenter: ", workCenter);
        const item = codigoQr.data_array.find(
          (e) => e.centro_de_trabajo.id.toString() === workCenter.id
        );
        console.log("item: ", item);
        if (item) {
          const checkpointRondinTemp = getEmptyCheckpointRondin();
          checkpointRondinTemp.id = 0;
          checkpointRondinTemp.nombre_checkpoint = item.nombre_codigo;
          checkpointRondinTemp.geolocalizacion = item.geolocalizacion;
          checkpointRondinTemp.estatus_sistema = true;
          checkpointRondinTemp.codigo_qr = codigoQr.data_array.find(
            (e) => e.centro_de_trabajo.id.toString() === workCenter.id
          )!;
          checkpointRondinTemp.nodo_inicial = i === 0;
          checkpointRondinTemp.nodo_final =
            i === selectedWorkCenters.length - 1;
          checkpointRondinTemp.empresa = tempRondin.empresa;
          checkpointRondinTemp.cliente_qentry = tempRondin.cliente_qentry;
          tempRondin.checkpoints.push(checkpointRondinTemp);
        }
      });
    }

    console.log("tempRondin: ", tempRondin);
    addOrEditRondin(tempRondin);
    setPatrolName("");
    setMaxDistance("");
    setSelectedWorkers([]);
    setSelectedWorkCenters([]);
  };

  useEffect(() => {
    if (rondinModal.id !== 0) {
      const empresaSeleccionada = empresaList.find(
        (eL: List) => eL.id === rondinModal.empresa.id.toString()
      );

      if (empresaSeleccionada) {
        dispatch(setPatrolsSelectedEmpresa(empresaSeleccionada));
      }

      setSelectedWorkCenters(
        rondinModal.checkpoints.map((e) => {
          return setListItem({
            id: e.codigo_qr.centro_de_trabajo.id.toString(),
            title: e.codigo_qr.centro_de_trabajo.nombre_centro,
          });
        })
      );
      setSelectedWorkers(
        rondinModal.empleados.map((e) => {
          return setListItem({
            id: e.id.toString(),
            title: getNombreEmpleado(e),
          });
        })
      );
      setPatrolName(rondinModal.nombre_rondin);
      setMaxDistance(rondinModal.distancia_maxima_permitida);
    }
  }, [rondinModal]);

  useEffect(() => {
    console.log("searchText: ", searchText);
    // console.log('arg2', searchText.trim());
    // console.log('arg3', searchText.length > 0);
    if (searchText && searchText.trim() && searchText.length > 0) {
      setPage(1);
      const searchResult: IRondin[] = [];
      Object.values(rondinList).forEach((item) => {
        if (item.nombre_rondin.includes(searchText.toLowerCase())) {
          searchResult.push(item);
        }
      });
      setItems(searchResult);
      setTotalPages(Math.ceil(searchResult.length / 10));
    } else {
      setItems(rondinList);
      setTotalPages(Math.ceil(rondinList.length / 10));
    }
  }, [searchText, rondinList]);

  useEffect(() => {
    if (items) {
      // console.log('items2', items);
      const iniItemList = (page - 1) * 10;
      const finItemList = page * 10;
      setPageMessage(
        (1 + iniItemList).toString() +
          "-" +
          (items.length < 10
            ? items.length.toString()
            : finItemList > items.length
            ? items.length.toString()
            : finItemList.toString()) +
          " de " +
          items.length.toString()
      );
      setIniItemList(iniItemList);
      setFinItemList(finItemList);
    }
  }, [page, items]);

  useEffect(() => {
    let filteredCompletados = rondinCompletado.data_array.filter(
      (rCom) => getOnlyDateString(rCom.fecha_elaboracion) === selectedDate
    );
    let filteredCancelados = rondinCancelado.data_array.filter(
      (rCan) => getOnlyDateString(rCan.fecha_elaboracion) === selectedDate
    );
    let filteredEnProceso = checkRondin.data_array.filter(
      (check) =>
        getOnlyDateString(check.fecha_elaboracion) === selectedDate &&
        !filteredCompletados.some(
          (fCom) => fCom.identificador_rondin === check.identificador_rondin
        ) &&
        !filteredCancelados.some(
          (fCan) => fCan.identificador_rondin === check.identificador_rondin
        )
    );
    if (selectedEmpresa.id !== "") {
      filteredCompletados = filteredCompletados.filter(
        (fc) => selectedEmpresa.id === fc.empleado.empresa.id.toString()
      );
      filteredCancelados = filteredCancelados.filter(
        (fc) => selectedEmpresa.id === fc.empleado.empresa.id.toString()
      );
      filteredEnProceso = filteredEnProceso.filter(
        (fep) => selectedEmpresa.id === fep.empleado.empresa.id.toString()
      );
    }
    if (selectedEstatusRondin.id === "") {
      setRondinesByDate([
        ...filteredCompletados,
        ...filteredCancelados,
        ...filteredEnProceso,
      ]);
    } else if (selectedEstatusRondin.id === "1") {
      setRondinesByDate(filteredCompletados);
    } else if (selectedEstatusRondin.id === "2") {
      setRondinesByDate(filteredCancelados);
    } else if (selectedEstatusRondin.id === "3") {
      setRondinesByDate(filteredEnProceso);
    }
  }, [
    checkRondin.data_array,
    rondinCancelado.data_array,
    rondinCompletado.data_array,
    selectedDate,
    selectedEmpresa.id,
    selectedEstatusRondin.id,
  ]);

  return (
    <IonContent scrollEvents={true} id="rondines-content" class="inner-content">
      <IonGrid>
        <IonRow>
          <IonCol size="12">
            <Card
              icon={walkOutline}
              title={<IonLabel>Lista de rondines creados</IonLabel>}
              subtitle={
                empresa.estado !== DEFAULT_STATE.loading ? (
                  <Selector
                    value={selectedEmpresa}
                    label="Cuentas/Regiones"
                    list={empresaList}
                    changeState={setPatrolsSelectedEmpresa}
                  />
                ) : (
                  <IonItem lines="none">
                    <IonSpinner slot="start" name="bubbles" color="medium" />
                    <IonLabel>Cargando...</IonLabel>
                  </IonItem>
                )
              }
              //content="prueba"
              content={
                <IonGrid>
                  <IonRow>
                    <IonCol size="12">
                      <IonSearchbar
                        color="light"
                        value={searchText}
                        onIonChange={(e) => setSearchText(e.detail.value!)}
                        placeholder="Buscar"
                        className="search-bar-padding"
                      />
                      <IonList lines="full">
                        <IonListHeader color="secondary">
                          Rondines
                        </IonListHeader>

                        {rondin.estado === DEFAULT_STATE.loading ? (
                          <IonItem lines="none">
                            <IonLabel>Cargando...</IonLabel>
                            <IonSpinner
                              className="loading-center-horizontal"
                              name="dots"
                              color="medium"
                            />
                          </IonItem>
                        ) : items.length > 0 ? (
                          items.map((workCenter, id) => {
                            return id >= iniItemList && id < finItemList ? (
                              <IonItem
                                key={`${id}-${workCenter.id}-${workCenter.nombre_rondin}`}
                              >
                                <IonIcon
                                  slot="start"
                                  icon={walkOutline}
                                  color="medium"
                                />
                                <IonLabel>{workCenter.nombre_rondin}</IonLabel>
                                <IonButtons slot="end">
                                  <IonButton
                                    onClick={() => setToEdit(workCenter)}
                                  >
                                    <IonIcon
                                      slot="icon-only"
                                      icon={pencilOutline}
                                      color="medium"
                                    />
                                  </IonButton>
                                </IonButtons>
                              </IonItem>
                            ) : (
                              <div key={`${id}-${workCenter.nombre_rondin}`} />
                            );
                          })
                        ) : (
                          <IonItem>
                            <IonLabel>No hay datos</IonLabel>
                          </IonItem>
                        )}
                        <IonItem lines="full">
                          <IonLabel slot="end" className="ion-text-right">
                            {pageMessage}
                          </IonLabel>
                          <IonButtons slot="end">
                            <IonButton
                              disabled={page === 1}
                              onClick={() => backPage()}
                            >
                              <IonIcon color="medium" icon={chevronBackSharp} />
                            </IonButton>
                            <IonButton
                              disabled={page === totalPages}
                              onClick={() => forwardPage()}
                            >
                              <IonIcon
                                color="medium"
                                icon={chevronForwardSharp}
                              />
                            </IonButton>
                          </IonButtons>
                        </IonItem>
                      </IonList>
                    </IonCol>
                  </IonRow>
                </IonGrid>
              }
            />
          </IonCol>
          <IonCol size="12">
            <Card
              icon={walkOutline}
              title={<IonLabel>Crear o editar rondines</IonLabel>}
              //content="prueba"
              content={
                <IonGrid>
                  <IonRow>
                    <IonCol size="12">
                      <Selector
                        value={selectedWorkCenters}
                        label="Centros de trabajo"
                        multiple={true}
                        list={centroDeTrabajoList}
                        change={setSelectedWorkCenters}
                        disabled={rondinModal.id !== 0}
                      />
                    </IonCol>
                    {selectedWorkCenters && selectedWorkCenters.length > 0 ? (
                      <>
                        {selectedEmpresa.id === "" ? (
                          <IonCol size="12">
                            <IonItem color={"danger"} lines="none">
                              Por favor seleccione una empresa
                            </IonItem>
                          </IonCol>
                        ) : (
                          <></>
                        )}
                        <IonCol size="12">
                          <IonButton
                            expand="block"
                            disabled={
                              selectedEmpresa.id === "" && rondinModal.id !== 0
                            }
                            onClick={async () => await uploadRondin()}
                          >
                            {editing ? "Actualizar" : "Subir rondín"}
                          </IonButton>
                        </IonCol>
                        {editing ? (
                          <IonCol>
                            <IonButton
                              color="danger"
                              expand="block"
                              onClick={() =>
                                presentToast({
                                  cssClass: "my-css",
                                  color: "warning",
                                  header: "Solicitud",
                                  message:
                                    "¿Está seguro de eliminar a este rondín?",
                                  buttons: [
                                    {
                                      text: "Confirmar",
                                      handler: () => {
                                        deleteRondin(rondinModal);
                                        cancelEdit();
                                        setPage(1);
                                        dismissToast();
                                      },
                                    },
                                    {
                                      text: "Cancelar",
                                      handler: () => dismissToast(),
                                    },
                                  ],
                                })
                              }
                            >
                              Eliminar
                            </IonButton>
                          </IonCol>
                        ) : (
                          <></>
                        )}
                        {editing ? (
                          <IonCol size="12">
                            <IonButton
                              color="warning"
                              expand="block"
                              onClick={() => cancelEdit()}
                            >
                              Cancelar
                            </IonButton>
                          </IonCol>
                        ) : (
                          <></>
                        )}
                        <IonCol size="12">
                          <IonItem>
                            <IonIcon
                              slot="start"
                              icon={walkOutline}
                              class="icon-input-margin"
                              color="medium"
                            />
                            <IonLabel position="floating">
                              Nombre del rondín
                            </IonLabel>
                            <IonInput
                              value={patrolName}
                              onIonChange={(e) =>
                                setPatrolName(e.detail.value!)
                              }
                            />
                          </IonItem>
                        </IonCol>
                        <IonCol size="12">
                          <IonItem>
                            <IonIcon
                              slot="start"
                              icon={walkOutline}
                              class="icon-input-margin"
                              color="medium"
                            />
                            <IonLabel position="floating">
                              Distancia máxima de checado (m)
                            </IonLabel>
                            <IonInput
                              value={maxDistance}
                              type="number"
                              min="0"
                              onIonChange={(e) =>
                                setMaxDistance(
                                  Math.abs(Number(e.detail.value!)).toString()
                                )
                              }
                            />
                          </IonItem>
                        </IonCol>
                        <IonCol size="12">
                          <Selector
                            value={selectedWorkers}
                            label="Empleados asignados"
                            multiple={true}
                            list={empleadoList}
                            change={setSelectedWorkers}
                          />
                        </IonCol>
                        <IonCol size="12">
                          <IonList>
                            <IonListHeader color="secondary">
                              Centros de trabajo del rondín{" "}
                              {`${!editing ? "(reordenables)" : ""}`}
                            </IonListHeader>
                            <IonReorderGroup
                              disabled={editing}
                              onIonItemReorder={doReorder}
                            >
                              {selectedWorkCenters &&
                                selectedWorkCenters.map((workCenter, i) => {
                                  return (
                                    <IonItem
                                      key={`${i}-${workCenter.id}-${workCenter.title}`}
                                    >
                                      <IonIcon
                                        slot="start"
                                        icon={businessOutline}
                                        color="medium"
                                      />
                                      <IonLabel>{workCenter.title}</IonLabel>
                                      <IonReorder slot="end" />
                                    </IonItem>
                                  );
                                })}
                            </IonReorderGroup>
                          </IonList>
                        </IonCol>
                      </>
                    ) : (
                      <></>
                    )}
                  </IonRow>
                </IonGrid>
              }
            />
          </IonCol>
          <IonCol size="12">
            <Card
              icon={walkOutline}
              title={<IonLabel>Bitácora de rondines</IonLabel>}
              subtitle={
                empresa.estado !== DEFAULT_STATE.loading ? (
                  <>
                    <Selector
                      value={selectedEmpresa}
                      label="Cuentas/Regiones"
                      list={empresaList}
                      changeState={setPatrolsSelectedEmpresa}
                    />
                    <IonItem className="modal-input">
                      <IonLabel position="floating" className="modal-label">
                        Fecha
                      </IonLabel>
                      <IonIcon
                        slot="start"
                        icon={calendar}
                        class="icon-input-margin"
                      />
                      <IonInput
                        id="patrol-date"
                        type="text"
                        clearInput={true}
                        color="secondary"
                        value={selectedDate}
                        className="custom-picker"
                      />
                      <IonPopover
                        className="custom-picker"
                        id="patrol-date-picker"
                        animated={true}
                        backdropDismiss={true}
                        trigger="patrol-date"
                        triggerAction="click"
                        show-showBackdrop="true"
                        class="date-picker"
                      >
                        <IonDatetime
                          className="custom-picker"
                          id="patrol-date-datetime"
                          color="primary"
                          presentation="date"
                          placeholder="Seleccionar fecha"
                          min="2017-01-01"
                          value={selectedDate}
                          onIonChange={async (e) => {
                            setSelectedDate(
                              getOnlyDateString(e.detail.value as string)
                            );
                          }}
                        ></IonDatetime>
                      </IonPopover>
                    </IonItem>
                  </>
                ) : (
                  <IonItem lines="none">
                    <IonSpinner slot="start" name="bubbles" color="medium" />
                    <IonLabel>Cargando...</IonLabel>
                  </IonItem>
                )
              }
              content={
                <IonGrid className="no-padding">
                  <IonRow>
                    <IonCol size="12">
                      <IonItem color="secondary" lines="full">
                        <IonLabel>
                          <h3>Lista de rondines</h3>
                          <div>
                            <Selector
                              classStyle="secondary"
                              color="secondary"
                              value={selectedEstatusRondin}
                              label="Estatus de rondín"
                              list={estatusRondinList}
                              change={setSelectedEstatusRondin}
                            />
                          </div>
                        </IonLabel>
                      </IonItem>
                      <IonList className="selection-area">
                        {rondinCancelado.estado === DEFAULT_STATE.loading &&
                        rondinCompletado.estado === DEFAULT_STATE.loading ? (
                          <IonSpinner
                            className="loading-center-horizontal"
                            name="circles"
                            color="medium"
                          />
                        ) : rondinesByDate.length === 0 ? (
                          <IonItem lines="full">
                            <IonLabel>No hay datos</IonLabel>
                          </IonItem>
                        ) : (
                          rondinesByDate.map((rondinByDate) => {
                            return (
                              <IonItem
                                key={`${rondinByDate.id}-${rondinByDate.fecha_elaboracion}`}
                                lines="full"
                              >
                                <IonLabel>
                                  <h2>
                                    <b>Rondín</b>:{" "}
                                    {rondinByDate.rondin.nombre_rondin}
                                  </h2>
                                  {rondinByDate.checkpoint &&
                                  rondinByDate.geolocalizacion ? (
                                    <h3>
                                      <b>Último punto checado</b>:{" "}
                                      <IonChip
                                        color="secondary"
                                        disabled={true}
                                      >
                                        {
                                          rondinByDate.checkpoint
                                            .nombre_checkpoint
                                        }
                                      </IonChip>
                                    </h3>
                                  ) : (
                                    <></>
                                  )}

                                  <h3>
                                    <b>Checkpoints</b>:{" "}
                                    {rondinByDate.rondin.checkpoints.map(
                                      (
                                        cPoint: ICheckpointRondin,
                                        i: number
                                      ) => (
                                        <IonChip
                                          key={`${cPoint.id}-${i}`}
                                          color="secondary"
                                          disabled={true}
                                        >
                                          {cPoint.nombre_checkpoint}
                                        </IonChip>
                                      )
                                    )}
                                  </h3>
                                  <h3>
                                    <b>Identificador</b>:{" "}
                                    {rondinByDate.identificador_rondin}
                                  </h3>

                                  <h3>
                                    <b>Empleado</b>:{" "}
                                    {getNombreEmpleado(rondinByDate.empleado)}
                                  </h3>
                                  {rondinByDate.motivo_cancelacion ? (
                                    <h3>
                                      <b>Motivo de cancelación</b>:{" "}
                                      {rondinByDate.motivo_cancelacion.dato}
                                    </h3>
                                  ) : (
                                    <></>
                                  )}
                                  {rondinByDate.comentarios_cancelacion ? (
                                    <h3>
                                      <b>Comentarios de cancelación</b>:{" "}
                                      {rondinByDate.comentarios_cancelacion}
                                    </h3>
                                  ) : (
                                    <></>
                                  )}
                                  <h3>
                                    <b>Fecha</b>:{" "}
                                    {rondinByDate.fecha_elaboracion}
                                  </h3>
                                  <h3>
                                    <b>Estatus</b>:{" "}
                                    {rondinByDate.motivo_cancelacion
                                      ? "Cancelado"
                                      : rondinByDate.checkpoint
                                      ? "En proceso"
                                      : "Completado"}
                                  </h3>
                                </IonLabel>
                              </IonItem>
                            );
                          })
                        )}
                      </IonList>
                    </IonCol>
                  </IonRow>
                </IonGrid>
              }
            />
          </IonCol>
        </IonRow>
      </IonGrid>
    </IonContent>
  );
};

export default Patrols;
