/** 
 * Componente que se encarga del elemento MvTipoMovimientoAsistencia
 * @IMvTipoMovimientoAsistencia es la interfaz que contiene los parámetos el elemento MvTipoMovimientoAsistencia
 * @getEmptyMvTipoMovimientoAsistencia  devuelve un objeto con los parámetros por defecto; @param {id}, opcional cuando no encuentra el objeto en el LocalSotrage
 * @IStatusMvTipoMovimientoAsistencia es la interfaz para los estatus a llamadas a la API; @param {status}, opcional cuando queremos otro estado de inizialización de tipo [DEFAULT_STATE]
 * @getEmptyStatusMvTipoMovimientoAsistencia devuelve un objeto con los parámetros por defecto,
 * @setMvTipoMovimientoAsistencia devuelve un objeto de tipo [IMvTipoMovimientoAsistencia] recibiendo @param {object} y @param {serverDate}, para verificar que los datos estén en el formato correcto y ajustarlos a la zona horaria actual
 * @getSerializableMvTipoMovimientoAsistencia recibe un @param {IMvTipoMovimientoAsistencia}, para devolver un objeto serizalizable para las peticiones de la API
 * */

import { getDateString, isNotEmpty, SERVER_TIME_ZONE } from "../../api/helpers";
import { DEFAULT_STATE } from "../../enums/default_state";
import { ILocalStorageExpiry, getEmptyLocalStorageExpiry, IServerDate, convertDateToZonaHoraria } from "../time";

export interface IMvTipoMovimientoAsistencia {
  id : number,
  dato : string,
  fecha_elaboracion : string, // Fecha
  estatus_sistema : boolean,
  ultima_actualizacion : string, // Fecha
}

export const getEmptyMvTipoMovimientoAsistencia = (id? : number) => {
  const temp : IMvTipoMovimientoAsistencia = 
  {
    id : id ? id : 0,
    dato : "",
    fecha_elaboracion : "",
    estatus_sistema : false,
    ultima_actualizacion : "",
  };
  return temp;
}

export interface IStatusMvTipoMovimientoAsistencia {
  estado : DEFAULT_STATE,
  mensaje : string,
  data : IMvTipoMovimientoAsistencia,
  data_array: IMvTipoMovimientoAsistencia[],
  expiry : ILocalStorageExpiry,
  expiry_array : ILocalStorageExpiry,
}

export const getEmptyStatusMvTipoMovimientoAsistencia = (status: DEFAULT_STATE = DEFAULT_STATE.loading) => {
  const temp : IStatusMvTipoMovimientoAsistencia = 
  {
    estado : status,
    mensaje : "",
    data : getEmptyMvTipoMovimientoAsistencia(),
    data_array : [],
    expiry : getEmptyLocalStorageExpiry("IMvTipoMovimientoAsistencia", 0),
    expiry_array : getEmptyLocalStorageExpiry("IMvTipoMovimientoAsistenciaArray", 0),
  };
  return temp;
}

export const setMvTipoMovimientoAsistencia = async (
  object : any,
  serverDate : IServerDate,
) => {
  const temp = getEmptyMvTipoMovimientoAsistencia();
  if (isNotEmpty(object.id)) temp.id = object.id;
  if (isNotEmpty(object.dato)) temp.dato = object.dato;
  if (isNotEmpty(object.fecha_elaboracion)) temp.fecha_elaboracion = convertDateToZonaHoraria(serverDate, object.fecha_elaboracion);
  if (isNotEmpty(object.estatus_sistema)) temp.estatus_sistema = object.estatus_sistema;
  if (isNotEmpty(object.ultima_actualizacion)) temp.ultima_actualizacion = convertDateToZonaHoraria(serverDate, object.ultima_actualizacion);
  return temp;
}

export const getSerializableMvTipoMovimientoAsistencia = (IMvTipoMovimientoAsistencia : IMvTipoMovimientoAsistencia ) => {
  return {
    id : IMvTipoMovimientoAsistencia.id,
    dato : IMvTipoMovimientoAsistencia.dato,
    fecha_elaboracion : IMvTipoMovimientoAsistencia.fecha_elaboracion,
    estatus_sistema : IMvTipoMovimientoAsistencia.estatus_sistema,
    ultima_actualizacion : getDateString(SERVER_TIME_ZONE),
  };
}
