/**
 * Métodos del, get, post, put para llamadas a la API
 * @get     en este método primero consulta con @getFromLocalStorage si es necesario hacer la petición http sino se obtiene del LocalStorage;
 *          si hay un error en la petición http se busca el objeto en el LocalStorage sino lo encuentra devuelve un objeto vacío; si la
 *          petición se realiza correctamente actualiza el valor individual y en el array del objeto en el LocalStorage y devuelve el objeto
 *          obtenido del servidor.
 * @getAll  en este método primero consulta con @getFromLocalStorage si es necesario hacer la petición http sino se obtiene del LocalStorage;
 *          si hay un error en la petición http se busca el objeto array en el LocalStorage sino lo encuentra devuelve un array vacío; si la
 *          petición se realiza correctamente actualiza array en el LocalStorage y devuelve el array obtenido del servidor
 * @update  en este método después de realizar la petición http si falla devuelve el objeto que se iba a actualizar; si la petición se
 *          realiza correctamente actualiza el valor individual y en el array del objeto en el LocalStorage y devuelve el objeto obtenido del
 *          servidor.
 * @create  en este método después de realizar la petición http si falla devuelve el objeto que se iba a crear; si la petición se realiza
 *          correctamente actualiza el valor individual y en el array del objeto en el LocalStorage y devuelve el objeto obtenido del
 *          servidor.
 * @delete  en este método después de realizar la petición http si falla devuelve el objeto que se iba a eliminar; si la petición se realiza
 *          correctamente elimina el valor individual y en el objeto en el arrya del LocalStorage y devuelve un objeto vacio (Default)
 */

import { del, get, post, put } from "../../api/api";
import {
  getFromLocalStorage,
  updateLocalStorageExpiry,
} from "../../api/helpers";
import { removeArrayItemStorageData } from "../../api/storage";
import { DEFAULT_STATE } from "../../enums/default_state";
import {
  getEmptyStatusMvEstadoCivil,
  setMvEstadoCivil,
  IMvEstadoCivil,
  getSerializableMvEstadoCivil,
} from "../../interfaces/personas";
import { IServerDate } from "../../interfaces/time";
import {
  getStorageMvEstadoCivilArrayItem,
  setStorageMvEstadoCivil,
  setStorageMvEstadoCivilArrayItem,
  getStorageMvEstadoCivilArray,
  setStorageMvEstadoCivilArray,
  removeStorageMvEstadoCivil,
} from "../../localStorage/personas";

export const getMvEstadoCivil = async (serverDate: IServerDate, pk: number) => {
  const temp = getEmptyStatusMvEstadoCivil();
  const doHttp = await getFromLocalStorage(serverDate, temp.expiry);
  temp.expiry = doHttp.storage;
  if (!doHttp.result) {
    console.log(
      `(localStorage) MvEstadoCivil con pk: ${pk}, obtenido con éxito`
    );
    temp.estado = DEFAULT_STATE.success;
    temp.mensaje = `MvEstadoCivil obtenido con éxito`;
    temp.data = await getStorageMvEstadoCivilArrayItem(pk);
    return temp;
  }
  const result = await get(`personas/estado-civil/${pk}`);
  const data = result.data;
  console.log(`MvEstadoCivil data: `, data);
  if (!data.id || data.detail) {
    console.log(`Error al obtener MvEstadoCivil con pk: ${pk}, ${data.detail}`);
    temp.estado = DEFAULT_STATE.error;
    temp.mensaje = `Error al obtener el estado civil: ${
      data.detail ?? "Hubo un problema al conectarse con el servidor"
    }`;
    temp.data = await getStorageMvEstadoCivilArrayItem(pk);
    return temp;
  }
  console.log(`MvEstadoCivil con pk: ${pk}, obtenido con éxito`);
  temp.estado = DEFAULT_STATE.success;
  temp.mensaje = `MvEstadoCivil obtenido con éxito`;
  temp.data = await setMvEstadoCivil(data, serverDate);
  await setStorageMvEstadoCivil(temp.data);
  temp.data_array = await setStorageMvEstadoCivilArrayItem(pk, temp.data);
  temp.expiry = await updateLocalStorageExpiry(serverDate, temp.expiry);
  return temp;
};

export const getAllMvEstadoCivil = async (serverDate: IServerDate) => {
  const temp = getEmptyStatusMvEstadoCivil();
  const doHttp = await getFromLocalStorage(serverDate, temp.expiry_array);
  temp.expiry_array = doHttp.storage;
  if (!doHttp.result) {
    console.log(`(localStorage) Todos los MvEstadoCivil obtenidos con éxito`);
    temp.estado = DEFAULT_STATE.success;
    temp.mensaje = `Todos los estado civils obtenido con éxito`;
    temp.data_array = await getStorageMvEstadoCivilArray();
    return temp;
  }
  const result = await get(`personas/estado-civil`);
  const data = result.data;
  if (data.detail) {
    console.log(`Error al obtener la lista de MvEstadoCivil, ${data.detail}`);
    temp.estado = DEFAULT_STATE.error;
    temp.mensaje = `Error al obtener la lista de estado civil: ${
      data.detail ?? "Hubo un problema al conectarse con el servidor"
    }`;
    temp.data_array = await getStorageMvEstadoCivilArray();
    return temp;
  }
  console.log(`Todos los MvEstadoCiviles obtenidos con éxito`);
  temp.estado = DEFAULT_STATE.success;
  temp.mensaje = `Todos los estado civils obtenidos con éxito`;
  temp.data_array = await Promise.all(
    data.map(async (e: any) => await setMvEstadoCivil(e, serverDate))
  );
  await setStorageMvEstadoCivilArray(temp.data_array);
  temp.expiry_array = await updateLocalStorageExpiry(
    serverDate,
    temp.expiry_array
  );
  return temp;
};

export const updateMvEstadoCivil = async (
  serverDate: IServerDate,
  pk: number,
  object: IMvEstadoCivil
) => {
  const temp = getEmptyStatusMvEstadoCivil();
  const result = await put(
    `personas/estado-civil/${pk}`,
    getSerializableMvEstadoCivil(object)
  );
  const data = result.data;
  if (!data.id || data.detail) {
    console.log(
      `Error al actualizar MvEstadoCivil con pk: ${pk}, ${data.detail}`
    );
    temp.estado = DEFAULT_STATE.error;
    temp.mensaje = `Error al actualizar el estado civil: ${
      data.detail ?? "Hubo un problema al conectarse con el servidor"
    }`;
    temp.data = await setMvEstadoCivil(object, serverDate);
    return temp;
  }
  console.log(`MvEstadoCivil con pk: ${pk}, actualizado con éxito`);
  temp.estado = DEFAULT_STATE.success;
  temp.mensaje = `MvEstadoCivil actualizado con éxito`;
  temp.data = await setMvEstadoCivil(data, serverDate);
  await setStorageMvEstadoCivil(temp.data);
  temp.data_array = await setStorageMvEstadoCivilArrayItem(
    temp.data.id,
    temp.data
  );
  return temp;
};

export const createMvEstadoCivil = async (
  serverDate: IServerDate,
  object: IMvEstadoCivil
) => {
  const temp = getEmptyStatusMvEstadoCivil();
  const result = await post(
    `personas/estado-civil`,
    getSerializableMvEstadoCivil(object)
  );
  const data = result.data;
  if (!data.id || data.detail) {
    console.log(
      `Error al crear MvEstadoCivil con los datos ${object}, ${data.detail}`
    );
    temp.estado = DEFAULT_STATE.error;
    temp.mensaje = `Error al crear el estado civil: ${
      data.detail ?? "Hubo un problema al conectarse con el servidor"
    }`;
    temp.data = await setMvEstadoCivil(object, serverDate);
    return temp;
  }
  console.log(`MvEstadoCivil creado con éxito`);
  temp.estado = DEFAULT_STATE.success;
  temp.mensaje = `MvEstadoCivil creado con éxito`;
  temp.data = await setMvEstadoCivil(data, serverDate);
  await setStorageMvEstadoCivil(temp.data);
  temp.data_array = await setStorageMvEstadoCivilArrayItem(
    temp.data.id,
    temp.data
  );
  return temp;
};

export const deleteMvEstadoCivil = async (pk: number) => {
  const temp = getEmptyStatusMvEstadoCivil();
  const result = await del(`personas/estado-civil/${pk}`);
  const data = result.data;
  if (data.detail) {
    console.log(
      `Error al eliminar MvEstadoCivil con pk: ${pk}, ${data.detail}`
    );
    temp.estado = DEFAULT_STATE.error;
    temp.mensaje = `Error al eliminar el estado civil: ${data.detail}`;
    temp.data = await getStorageMvEstadoCivilArrayItem(pk);
    temp.data_array = await getStorageMvEstadoCivilArray();
    return temp;
  }
  console.log(`MvEstadoCivil eliminado con éxito`);
  temp.estado = DEFAULT_STATE.success;
  temp.mensaje = `MvEstadoCivil eliminado con éxito`;
  await removeStorageMvEstadoCivil();
  temp.data_array = await removeArrayItemStorageData("IMvEstadoCivil", pk, []);
  return temp;
};
