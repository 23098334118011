/**
 * Métodos AsyncThunk para ser usados en los reducers y
 * los resultados a las llamadas http puedan ser usados para
 * actualizar el state en redux
 */

import { createAsyncThunk } from "@reduxjs/toolkit";
import { DEFAULT_STATE } from "../../enums/default_state";
import { getPagoSuscripcion, getAllPagoSuscripcion, updatePagoSuscripcion, createPagoSuscripcion, deletePagoSuscripcion } from "../../http/personas";
import { getEmptyStatusPagoSuscripcion, IPagoSuscripcion } from "../../interfaces/personas";
import { IServerDate } from "../../interfaces/time";

export const getPagoSuscripcionAsyncThunk = createAsyncThunk(
  'personas/getPagoSuscripcionAsyncThunk',
  async (query : {serverDate : IServerDate, id: number}) => await getPagoSuscripcion(query.serverDate, query.id)
    .then(async (result) => result)
    .catch(async (error) => getEmptyStatusPagoSuscripcion(DEFAULT_STATE.error))  
);

export const getAllPagoSuscripcionAsyncThunk = createAsyncThunk(
  'personas/getAllPagoSuscripcionAsyncThunk',
  async (queryAll : {serverDate : IServerDate}) => await getAllPagoSuscripcion(queryAll.serverDate)
    .then(async (result) => result)
    .catch(async (error) => getEmptyStatusPagoSuscripcion(DEFAULT_STATE.error))  
);

export const updatePagoSuscripcionAsyncThunk = createAsyncThunk(
  'personas/updatePagoSuscripcionAsyncThunk',
  async (update: {serverDate : IServerDate, id: number, PagoSuscripcion: IPagoSuscripcion}) => await updatePagoSuscripcion(update.serverDate, update.id, update.PagoSuscripcion)
    .then(async (result) => result)
    .catch(async (error) => getEmptyStatusPagoSuscripcion(DEFAULT_STATE.error))  
);

export const createPagoSuscripcionAsyncThunk = createAsyncThunk(
  'personas/createPagoSuscripcionAsyncThunk',
  async (create : {serverDate : IServerDate, PagoSuscripcion: IPagoSuscripcion}) => await createPagoSuscripcion(create.serverDate, create.PagoSuscripcion)
    .then(async (result) => result)
    .catch(async (error) => getEmptyStatusPagoSuscripcion(DEFAULT_STATE.error))  
);

export const deletePagoSuscripcionAsyncThunk = createAsyncThunk(
  'personas/deletePagoSuscripcionAsyncThunk',
  async (del : {id : number}) => await deletePagoSuscripcion(del.id)
    .then(async (result) => result)
    .catch(async (error) => getEmptyStatusPagoSuscripcion())  
);
