/**
 * Métodos del, get, post, put para llamadas a la API
 * @get     en este método primero consulta con @getFromLocalStorage si es necesario hacer la petición http sino se obtiene del LocalStorage;
 *          si hay un error en la petición http se busca el objeto en el LocalStorage sino lo encuentra devuelve un objeto vacío; si la
 *          petición se realiza correctamente actualiza el valor individual y en el array del objeto en el LocalStorage y devuelve el objeto
 *          obtenido del servidor.
 * @getAll  en este método primero consulta con @getFromLocalStorage si es necesario hacer la petición http sino se obtiene del LocalStorage;
 *          si hay un error en la petición http se busca el objeto array en el LocalStorage sino lo encuentra devuelve un array vacío; si la
 *          petición se realiza correctamente actualiza array en el LocalStorage y devuelve el array obtenido del servidor
 * @update  en este método después de realizar la petición http si falla devuelve el objeto que se iba a actualizar; si la petición se
 *          realiza correctamente actualiza el valor individual y en el array del objeto en el LocalStorage y devuelve el objeto obtenido del
 *          servidor.
 * @create  en este método después de realizar la petición http si falla devuelve el objeto que se iba a crear; si la petición se realiza
 *          correctamente actualiza el valor individual y en el array del objeto en el LocalStorage y devuelve el objeto obtenido del
 *          servidor.
 * @delete  en este método después de realizar la petición http si falla devuelve el objeto que se iba a eliminar; si la petición se realiza
 *          correctamente elimina el valor individual y en el objeto en el arrya del LocalStorage y devuelve un objeto vacio (Default)
 */

import { del, get, post, put } from "../../api/api";
import {
  getFromLocalStorage,
  updateLocalStorageExpiry,
} from "../../api/helpers";
import { removeArrayItemStorageData } from "../../api/storage";
import { DEFAULT_STATE } from "../../enums/default_state";
import {
  getEmptyStatusHorario,
  setHorario,
  IHorario,
  getSerializableHorario,
} from "../../interfaces/nomina";
import { IServerDate } from "../../interfaces/time";
import {
  getStorageHorarioArrayItem,
  setStorageHorario,
  setStorageHorarioArrayItem,
  getStorageHorarioArray,
  setStorageHorarioArray,
  removeStorageHorario,
} from "../../localStorage/nomina";

export const getHorario = async (
  serverDate: IServerDate,
  pk: number,
  idCliente: number
) => {
  const temp = getEmptyStatusHorario();
  const doHttp = await getFromLocalStorage(serverDate, temp.expiry);
  temp.expiry = doHttp.storage;
  if (!doHttp.result) {
    console.log(`(localStorage) Horario con pk: ${pk}, obtenido con éxito`);
    temp.estado = DEFAULT_STATE.success;
    temp.mensaje = `Horario obtenida con éxito`;
    temp.data = await getStorageHorarioArrayItem(pk);
    return temp;
  }
  const result = await get(`nomina/horario/${pk}/${idCliente}`);
  const data = result.data;
  console.log(`Horario data: `, data);
  if (!data.id || data.detail) {
    console.log(`Error al obtener Horario con pk: ${pk}, ${data.detail}`);
    temp.estado = DEFAULT_STATE.error;
    temp.mensaje = `Error al obtener la datos personales: ${
      data.detail ?? "Hubo un problema al conectarse con el servidor"
    }`;
    temp.data = await getStorageHorarioArrayItem(pk);
    return temp;
  }
  console.log(`Horario con pk: ${pk}, obtenido con éxito`);
  temp.estado = DEFAULT_STATE.success;
  temp.mensaje = `Horario obtenida con éxito`;
  temp.data = await setHorario(data, serverDate);
  await setStorageHorario(temp.data);
  temp.data_array = await setStorageHorarioArrayItem(pk, temp.data);
  temp.expiry = await updateLocalStorageExpiry(serverDate, temp.expiry);
  return temp;
};

export const getAllHorario = async (
  serverDate: IServerDate,
  idCliente: number
) => {
  const temp = getEmptyStatusHorario();
  const doHttp = await getFromLocalStorage(serverDate, temp.expiry_array);
  temp.expiry_array = doHttp.storage;
  if (!doHttp.result) {
    console.log(`(localStorage) Todos las Horarios obtenidas con éxito`);
    temp.estado = DEFAULT_STATE.success;
    temp.mensaje = `Todos las Horarios obtenidas con éxito`;
    temp.data_array = await getStorageHorarioArray();
    // return temp;
  }
  const result = await get(`nomina/horario/${idCliente}`);
  const data = result.data;
  if (data.detail) {
    console.log(`Error al obtener la lista de Horario`, data);
    temp.estado = DEFAULT_STATE.error;
    temp.mensaje = `Error al obtener la lista de Horarios: ${
      data.detail ?? "Hubo un problema al conectarse con el servidor"
    }`;
    temp.data_array = await getStorageHorarioArray();
    return temp;
  }
  console.log(`Todos las Horarios obtenidos con éxito`);
  temp.estado = DEFAULT_STATE.success;
  temp.mensaje = `Todos las Horarios obtenidas con éxito`;
  temp.data_array = await Promise.all(
    data.map(async (e: any) => await setHorario(e, serverDate))
  );
  await setStorageHorarioArray(temp.data_array);
  temp.expiry_array = await updateLocalStorageExpiry(
    serverDate,
    temp.expiry_array
  );
  return temp;
};

export const updateHorario = async (
  serverDate: IServerDate,
  pk: number,
  idCliente: number,
  object: IHorario
) => {
  const temp = getEmptyStatusHorario();
  const result = await put(
    `nomina/horario/${pk}/${idCliente}`,
    getSerializableHorario(object)
  );
  const data = result.data;
  if (!data.id || data.detail) {
    console.log(`Error al actualizar Horario con pk: ${pk}, ${data.detail}`);
    temp.estado = DEFAULT_STATE.error;
    temp.mensaje = `Error al actualizar la datos personales: ${
      data.detail ?? "Hubo un problema al conectarse con el servidor"
    }`;
    temp.data = await setHorario(object, serverDate);
    return temp;
  }
  console.log(`Horario con pk: ${pk}, actualizado con éxito`);
  temp.estado = DEFAULT_STATE.success;
  temp.mensaje = `Horario actualizada con éxito`;
  temp.data = await setHorario(data, serverDate);
  await setStorageHorario(temp.data);
  temp.data_array = await setStorageHorarioArrayItem(temp.data.id, temp.data);
  return temp;
};

export const createHorario = async (
  serverDate: IServerDate,
  idCliente: number,
  object: IHorario
) => {
  const temp = getEmptyStatusHorario();
  const result = await post(
    `nomina/horario/${idCliente}`,
    getSerializableHorario(object)
  );
  const data = result.data;
  if (!data.id || data.detail) {
    console.log(
      `Error al crear Horario con los datos ${object}, ${data.detail}`
    );
    temp.estado = DEFAULT_STATE.error;
    temp.mensaje = `Error al crear la datos personales: ${
      data.detail ?? "Hubo un problema al conectarse con el servidor"
    }`;
    temp.data = await setHorario(object, serverDate);
    return temp;
  }
  console.log(`Horario creada con éxito`);
  temp.estado = DEFAULT_STATE.success;
  temp.mensaje = `Horario creada con éxito`;
  temp.data = await setHorario(data, serverDate);
  await setStorageHorario(temp.data);
  temp.data_array = await setStorageHorarioArrayItem(temp.data.id, temp.data);
  return temp;
};

export const deleteHorario = async (pk: number, idCliente: number) => {
  const temp = getEmptyStatusHorario();
  const result = await del(`nomina/horario/${pk}/${idCliente}`);
  const data = result.data;
  if (data.detail) {
    console.log(`Error al eliminar Horario con pk: ${pk}, ${data.detail}`);
    temp.estado = DEFAULT_STATE.error;
    temp.mensaje = `Error al eliminar la datos personales: ${data.detail}`;
    temp.data = await getStorageHorarioArrayItem(pk);
    temp.data_array = await getStorageHorarioArray();
    return temp;
  }
  console.log(`Horario eliminada con éxito`);
  temp.estado = DEFAULT_STATE.success;
  temp.mensaje = `Horario eliminada con éxito`;
  await removeStorageHorario();
  temp.data_array = await removeArrayItemStorageData("IHorario", pk, []);
  return temp;
};
