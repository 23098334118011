/**
 * Métodos del, get, post, put para llamadas a la API
 * @get     en este método primero consulta con @getFromLocalStorage si es necesario hacer la petición http sino se obtiene del LocalStorage;
 *          si hay un error en la petición http se busca el objeto en el LocalStorage sino lo encuentra devuelve un objeto vacío; si la
 *          petición se realiza correctamente actualiza el valor individual y en el array del objeto en el LocalStorage y devuelve el objeto
 *          obtenido del servidor.
 * @getAll  en este método primero consulta con @getFromLocalStorage si es necesario hacer la petición http sino se obtiene del LocalStorage;
 *          si hay un error en la petición http se busca el objeto array en el LocalStorage sino lo encuentra devuelve un array vacío; si la
 *          petición se realiza correctamente actualiza array en el LocalStorage y devuelve el array obtenido del servidor
 * @update  en este método después de realizar la petición http si falla devuelve el objeto que se iba a actualizar; si la petición se
 *          realiza correctamente actualiza el valor individual y en el array del objeto en el LocalStorage y devuelve el objeto obtenido del
 *          servidor.
 * @create  en este método después de realizar la petición http si falla devuelve el objeto que se iba a crear; si la petición se realiza
 *          correctamente actualiza el valor individual y en el array del objeto en el LocalStorage y devuelve el objeto obtenido del
 *          servidor.
 * @delete  en este método después de realizar la petición http si falla devuelve el objeto que se iba a eliminar; si la petición se realiza
 *          correctamente elimina el valor individual y en el objeto en el arrya del LocalStorage y devuelve un objeto vacio (Default)
 */

import { del, get, post, put } from "../../api/api";
import {
  getFromLocalStorage,
  updateLocalStorageExpiry,
} from "../../api/helpers";
import { removeArrayItemStorageData } from "../../api/storage";
import { DEFAULT_STATE } from "../../enums/default_state";
import {
  getEmptyStatusMvTipoMovimientoAsistencia,
  setMvTipoMovimientoAsistencia,
  IMvTipoMovimientoAsistencia,
  getSerializableMvTipoMovimientoAsistencia,
} from "../../interfaces/nomina";
import { IServerDate } from "../../interfaces/time";
import {
  getStorageMvTipoMovimientoAsistenciaArrayItem,
  setStorageMvTipoMovimientoAsistencia,
  setStorageMvTipoMovimientoAsistenciaArrayItem,
  getStorageMvTipoMovimientoAsistenciaArray,
  setStorageMvTipoMovimientoAsistenciaArray,
  removeStorageMvTipoMovimientoAsistencia,
} from "../../localStorage/nomina";

export const getMvTipoMovimientoAsistencia = async (
  serverDate: IServerDate,
  pk: number
) => {
  const temp = getEmptyStatusMvTipoMovimientoAsistencia();
  const doHttp = await getFromLocalStorage(serverDate, temp.expiry);
  temp.expiry = doHttp.storage;
  if (!doHttp.result) {
    console.log(
      `(localStorage) MvTipoMovimientoAsistencia con pk: ${pk}, obtenido con éxito`
    );
    temp.estado = DEFAULT_STATE.success;
    temp.mensaje = `MvTipoMovimientoAsistencia obtenido con éxito`;
    temp.data = await getStorageMvTipoMovimientoAsistenciaArrayItem(pk);
    return temp;
  }
  const result = await get(`nomina/tipo-movimiento-asistencia/${pk}`);
  const data = result.data;
  console.log(`MvTipoMovimientoAsistencia data: `, data);
  if (!data.id || data.detail) {
    console.log(
      `Error al obtener MvTipoMovimientoAsistencia con pk: ${pk}, ${data.detail}`
    );
    temp.estado = DEFAULT_STATE.error;
    temp.mensaje = `Error al obtener el MvTipoMovimientoAsistencia: ${
      data.detail ?? "Hubo un problema al conectarse con el servidor"
    }`;
    temp.data = await getStorageMvTipoMovimientoAsistenciaArrayItem(pk);
    return temp;
  }
  console.log(`MvTipoMovimientoAsistencia con pk: ${pk}, obtenido con éxito`);
  temp.estado = DEFAULT_STATE.success;
  temp.mensaje = `MvTipoMovimientoAsistencia obtenido con éxito`;
  temp.data = await setMvTipoMovimientoAsistencia(data, serverDate);
  await setStorageMvTipoMovimientoAsistencia(temp.data);
  temp.data_array = await setStorageMvTipoMovimientoAsistenciaArrayItem(
    pk,
    temp.data
  );
  temp.expiry = await updateLocalStorageExpiry(serverDate, temp.expiry);
  return temp;
};

export const getAllMvTipoMovimientoAsistencia = async (
  serverDate: IServerDate
) => {
  const temp = getEmptyStatusMvTipoMovimientoAsistencia();
  const doHttp = await getFromLocalStorage(serverDate, temp.expiry_array);
  temp.expiry_array = doHttp.storage;
  if (!doHttp.result) {
    console.log(
      `(localStorage) Todos los MvTipoMovimientoAsistencias obtenidos con éxito`
    );
    temp.estado = DEFAULT_STATE.success;
    temp.mensaje = `Todos los MvTipoMovimientoAsistencia obtenidos con éxito`;
    temp.data_array = await getStorageMvTipoMovimientoAsistenciaArray();
    return temp;
  }
  const result = await get(`nomina/tipo-movimiento-asistencia`);
  const data = result.data;
  if (data.detail) {
    console.log(
      `Error al obtener la lista de MvTipoMovimientoAsistencia, ${data.detail}`
    );
    temp.estado = DEFAULT_STATE.error;
    temp.mensaje = `Error al obtener la lista de MvTipoMovimientoAsistencia: ${
      data.detail ?? "Hubo un problema al conectarse con el servidor"
    }`;
    temp.data_array = await getStorageMvTipoMovimientoAsistenciaArray();
    return temp;
  }
  console.log(`Todos los MvTipoMovimientoAsistencia obtenidos con éxito`, data);
  temp.estado = DEFAULT_STATE.success;
  temp.mensaje = `Todos los MvTipoMovimientoAsistencia obtenidos con éxito`;
  temp.data_array = await Promise.all(
    data.map(
      async (e: any) => await setMvTipoMovimientoAsistencia(e, serverDate)
    )
  );
  await setStorageMvTipoMovimientoAsistenciaArray(temp.data_array);
  temp.expiry_array = await updateLocalStorageExpiry(
    serverDate,
    temp.expiry_array
  );
  return temp;
};

export const updateMvTipoMovimientoAsistencia = async (
  serverDate: IServerDate,
  pk: number,
  object: IMvTipoMovimientoAsistencia
) => {
  const temp = getEmptyStatusMvTipoMovimientoAsistencia();
  const result = await put(
    `nomina/tipo-movimiento-asistencia/${pk}`,
    getSerializableMvTipoMovimientoAsistencia(object)
  );
  const data = result.data;
  if (!data.id || data.detail) {
    console.log(
      `Error al actualizar MvTipoMovimientoAsistencia con pk: ${pk}, ${data.detail}`
    );
    temp.estado = DEFAULT_STATE.error;
    temp.mensaje = `Error al actualizar el MvTipoMovimientoAsistencia: ${
      data.detail ?? "Hubo un problema al conectarse con el servidor"
    }`;
    temp.data = await setMvTipoMovimientoAsistencia(object, serverDate);
    return temp;
  }
  console.log(
    `MvTipoMovimientoAsistencia con pk: ${pk}, actualizado con éxito`
  );
  temp.estado = DEFAULT_STATE.success;
  temp.mensaje = `MvTipoMovimientoAsistencia actualizado con éxito`;
  temp.data = await setMvTipoMovimientoAsistencia(data, serverDate);
  await setStorageMvTipoMovimientoAsistencia(temp.data);
  temp.data_array = await setStorageMvTipoMovimientoAsistenciaArrayItem(
    temp.data.id,
    temp.data
  );
  return temp;
};

export const createMvTipoMovimientoAsistencia = async (
  serverDate: IServerDate,
  object: IMvTipoMovimientoAsistencia
) => {
  const temp = getEmptyStatusMvTipoMovimientoAsistencia();
  const result = await post(
    `nomina/tipo-movimiento-asistencia`,
    getSerializableMvTipoMovimientoAsistencia(object)
  );
  const data = result.data;
  if (!data.id || data.detail) {
    console.log(
      `Error al crear MvTipoMovimientoAsistencia con los datos ${object}, ${data.detail}`
    );
    temp.estado = DEFAULT_STATE.error;
    temp.mensaje = `Error al crear el MvTipoMovimientoAsistencia: ${
      data.detail ?? "Hubo un problema al conectarse con el servidor"
    }`;
    temp.data = await setMvTipoMovimientoAsistencia(object, serverDate);
    return temp;
  }
  console.log(`MvTipoMovimientoAsistencia creado con éxito`);
  temp.estado = DEFAULT_STATE.success;
  temp.mensaje = `MvTipoMovimientoAsistencia creado con éxito`;
  temp.data = await setMvTipoMovimientoAsistencia(data, serverDate);
  await setStorageMvTipoMovimientoAsistencia(temp.data);
  temp.data_array = await setStorageMvTipoMovimientoAsistenciaArrayItem(
    temp.data.id,
    temp.data
  );
  return temp;
};

export const deleteMvTipoMovimientoAsistencia = async (pk: number) => {
  const temp = getEmptyStatusMvTipoMovimientoAsistencia();
  const result = await del(`nomina/tipo-movimiento-asistencia/${pk}`);
  const data = result.data;
  if (data.detail) {
    console.log(
      `Error al eliminar MvTipoMovimientoAsistencia con pk: ${pk}, ${data.detail}`
    );
    temp.estado = DEFAULT_STATE.error;
    temp.mensaje = `Error al eliminar el datos fiscales: ${data.detail}`;
    temp.data = await getStorageMvTipoMovimientoAsistenciaArrayItem(pk);
    temp.data_array = await getStorageMvTipoMovimientoAsistenciaArray();
    return temp;
  }
  console.log(`MvTipoMovimientoAsistencia eliminado con éxito`);
  temp.estado = DEFAULT_STATE.success;
  temp.mensaje = `MvTipoMovimientoAsistencia eliminado con éxito`;
  await removeStorageMvTipoMovimientoAsistencia();
  temp.data_array = await removeArrayItemStorageData(
    "IMvTipoMovimientoAsistencia",
    pk,
    []
  );
  return temp;
};
