/**
 * Métodos AsyncThunk para ser usados en los reducers y
 * los resultados a las llamadas http puedan ser usados para
 * actualizar el state en redux
 */

import { createAsyncThunk } from "@reduxjs/toolkit";
import { DEFAULT_STATE } from "../../enums/default_state";
import { getAntiguedadEmpleado, getAllAntiguedadEmpleado, updateAntiguedadEmpleado, createAntiguedadEmpleado, deleteAntiguedadEmpleado } from "../../http/personas";
import { getEmptyStatusAntiguedadEmpleado, IAntiguedadEmpleado } from "../../interfaces/personas";
import { IServerDate } from "../../interfaces/time";

export const getAntiguedadEmpleadoAsyncThunk = createAsyncThunk(
  'personas/getAntiguedadEmpleadoAsyncThunk',
  async (query : {serverDate : IServerDate, id: number, idCliente : number}) => await getAntiguedadEmpleado(query.serverDate, query.id, query.idCliente)
    .then(async (result) => result)
    .catch(async (error) => getEmptyStatusAntiguedadEmpleado(DEFAULT_STATE.error))  
);

export const getAllAntiguedadEmpleadoAsyncThunk = createAsyncThunk(
  'personas/getAllAntiguedadEmpleadoAsyncThunk',
  async (queryAll : {serverDate : IServerDate, idCliente : number}) => await getAllAntiguedadEmpleado(queryAll.serverDate, queryAll.idCliente)
    .then(async (result) => result)
    .catch(async (error) => getEmptyStatusAntiguedadEmpleado(DEFAULT_STATE.error))  
);

export const updateAntiguedadEmpleadoAsyncThunk = createAsyncThunk(
  'personas/updateAntiguedadEmpleadoAsyncThunk',
  async (update: {serverDate : IServerDate, id: number, idCliente : number, object: IAntiguedadEmpleado}) => await updateAntiguedadEmpleado(update.serverDate, update.id, update.idCliente, update.object)
    .then(async (result) => result)
    .catch(async (error) => getEmptyStatusAntiguedadEmpleado(DEFAULT_STATE.error))  
);

export const createAntiguedadEmpleadoAsyncThunk = createAsyncThunk(
  'personas/createAntiguedadEmpleadoAsyncThunk',
  async (create : {serverDate : IServerDate, idCliente : number, object: IAntiguedadEmpleado}) => await createAntiguedadEmpleado(create.serverDate, create.idCliente, create.object)
    .then(async (result) => result)
    .catch(async (error) => getEmptyStatusAntiguedadEmpleado(DEFAULT_STATE.error))  
);

export const deleteAntiguedadEmpleadoAsyncThunk = createAsyncThunk(
  'personas/deleteAntiguedadEmpleadoAsyncThunk',
  async (del : {id : number, idCliente : number}) => await deleteAntiguedadEmpleado(del.id, del.idCliente)
    .then(async (result) => result)
    .catch(async (error) => getEmptyStatusAntiguedadEmpleado())  
);
