/** 
 * Componente que se encarga del elemento Vacaciones
 * @IVacaciones es la interfaz que contiene los parámetos el elemento Vacaciones
 * @getEmptyVacaciones  devuelve un objeto con los parámetros por defecto; @param {id}, opcional cuando no encuentra el objeto en el LocalSotrage
 * @IStatusVacaciones es la interfaz para los estatus a llamadas a la API; @param {status}, opcional cuando queremos otro estado de inizialización de tipo [DEFAULT_STATE]
 * @getEmptyStatusVacaciones devuelve un objeto con los parámetros por defecto,
 * @setVacaciones devuelve un objeto de tipo [IVacaciones] recibiendo @param {object} y @param {serverDate}, para verificar que los datos estén en el formato correcto y ajustarlos a la zona horaria actual
 * @getSerializableVacaciones recibe un @param {IVacaciones}, para devolver un objeto serizalizable para las peticiones de la API
 * */

import { getDateString, isNotEmpty, SERVER_TIME_ZONE } from "../../api/helpers";
import { DEFAULT_STATE } from "../../enums/default_state";
import { getStorageMvMotivoVacacionesArrayItem, getStorageMvEstatusVacacionesArrayItem } from "../../localStorage/nomina";
import { getStorageEmpleadoArrayItem, getStorageDocumentoArrayItem, getStorageUserArrayItem, getStorageClienteQentryArrayItem } from "../../localStorage/personas";
import { getEmptyClienteQentry, getEmptyDocumento, getEmptyEmpleado, getEmptyUser, IClienteQentry, IDocumento, IEmpleado, IUser, setClienteQentry, setDocumento, setEmpleado, setUser } from "../personas";
import { ILocalStorageExpiry, getEmptyLocalStorageExpiry, IServerDate, convertDateToZonaHoraria } from "../time";
import { getEmptyMvEstatusVacaciones, IMvEstatusVacaciones, setMvEstatusVacaciones } from "./MvEstatusVacaciones";
import { getEmptyMvMotivoVacaciones, IMvMotivoVacaciones, setMvMotivoVacaciones } from "./MvMotivoVacaciones";

export interface IVacaciones {
  id : number,
  empleado : IEmpleado,
  motivo_vacaciones : IMvMotivoVacaciones,
  estatus_vacaciones : IMvEstatusVacaciones,
  fecha_inicio_vacaciones : string, // Fecha
  fecha_fin_vacaciones : string, // Fecha
  dias_a_tomar : number,
  dias_disponibles : number,
  dias_restantes : number,
  comentarios_aprobador : string,
  autor : IUser,
  fecha_elaboracion : string, // Fecha
  estatus_sistema : boolean,
  ultima_actualizacion : string, // Fecha
  cliente_qentry : IClienteQentry,
}

export const getEmptyVacaciones = (id? : number) => {
  const temp : IVacaciones = 
  {
    id : id ? id : 0,
    empleado : getEmptyEmpleado(),
    motivo_vacaciones : getEmptyMvMotivoVacaciones(),
    estatus_vacaciones : getEmptyMvEstatusVacaciones(),
    fecha_inicio_vacaciones : "", // Fecha
    fecha_fin_vacaciones : "", // Fecha
    dias_a_tomar : 0,
    dias_disponibles : 0,
    dias_restantes : 0,
    comentarios_aprobador : "",
    autor : getEmptyUser(),
    fecha_elaboracion : "",
    estatus_sistema : false,
    ultima_actualizacion : "",
    cliente_qentry : getEmptyClienteQentry(),
  };
  return temp;
}

export interface IStatusVacaciones {
  estado : DEFAULT_STATE,
  mensaje : string,
  data : IVacaciones,
  data_array: IVacaciones[],
  expiry : ILocalStorageExpiry,
  expiry_array : ILocalStorageExpiry,
}

export const getEmptyStatusVacaciones = (status: DEFAULT_STATE = DEFAULT_STATE.loading) => {
  const temp : IStatusVacaciones = 
  {
    estado : status,
    mensaje : "",
    data : getEmptyVacaciones(),
    data_array : [],
    expiry : getEmptyLocalStorageExpiry("IVacaciones", 0),
    expiry_array : getEmptyLocalStorageExpiry("IVacacionesArray", 0),
  };
  return temp;
}

export const setVacaciones = async (
  object : any,
  serverDate : IServerDate,
) => {
  const temp = getEmptyVacaciones();
  if (isNotEmpty(object.id)) temp.id = object.id;
  if (isNotEmpty(object.empleado)) temp.empleado = (typeof object.empleado === 'number') ? await getStorageEmpleadoArrayItem(object.empleado) : await setEmpleado(object.empleado, serverDate);
  if (isNotEmpty(object.motivo_vacaciones)) temp.motivo_vacaciones = (typeof object.motivo_vacaciones === 'number') ? await getStorageMvMotivoVacacionesArrayItem(object.motivo_vacaciones) : await setMvMotivoVacaciones(object.motivo_vacaciones, serverDate);
  if (isNotEmpty(object.estatus_vacaciones)) temp.estatus_vacaciones = (typeof object.estatus_vacaciones === 'number') ? await getStorageMvEstatusVacacionesArrayItem(object.estatus_vacaciones) : await setMvEstatusVacaciones(object.estatus_vacaciones, serverDate);
  if (isNotEmpty(object.fecha_inicio_vacaciones)) temp.fecha_inicio_vacaciones = convertDateToZonaHoraria(serverDate, object.fecha_inicio_vacaciones);
  if (isNotEmpty(object.fecha_fin_vacaciones)) temp.fecha_fin_vacaciones = convertDateToZonaHoraria(serverDate, object.fecha_fin_vacaciones);
  if (isNotEmpty(object.dias_a_tomar)) temp.dias_a_tomar = object.dias_a_tomar;
  if (isNotEmpty(object.dias_disponibles)) temp.dias_disponibles = object.dias_disponibles;
  if (isNotEmpty(object.dias_restantes)) temp.dias_restantes = object.dias_restantes;
  if (isNotEmpty(object.comentarios_aprobador)) temp.comentarios_aprobador = object.comentarios_aprobador;
  if (isNotEmpty(object.autor)) temp.autor = (typeof object.autor === 'number') ? await getStorageUserArrayItem(object.autor) : await setUser(object.autor, serverDate);
  if (isNotEmpty(object.fecha_elaboracion)) temp.fecha_elaboracion = convertDateToZonaHoraria(serverDate, object.fecha_elaboracion);
  if (isNotEmpty(object.estatus_sistema)) temp.estatus_sistema = object.estatus_sistema;
  if (isNotEmpty(object.ultima_actualizacion)) temp.ultima_actualizacion = convertDateToZonaHoraria(serverDate, object.ultima_actualizacion);
  if (isNotEmpty(object.cliente_qentry)) temp.cliente_qentry = (typeof object.cliente_qentry === 'number') ? await getStorageClienteQentryArrayItem(object.cliente_qentry) : await setClienteQentry(object.cliente_qentry, serverDate);
  return temp;
}

export const getSerializableVacaciones = (IVacaciones : IVacaciones ) => {
  return {
    id : IVacaciones.id,
    empleado : IVacaciones.empleado.id,
    motivo_vacaciones : IVacaciones.motivo_vacaciones.id,
    estatus_vacaciones : IVacaciones.estatus_vacaciones.id,
    fecha_inicio_vacaciones : getDateString(SERVER_TIME_ZONE, IVacaciones.fecha_inicio_vacaciones), // Fecha
    fecha_fin_vacaciones : getDateString(SERVER_TIME_ZONE, IVacaciones.fecha_fin_vacaciones), // Fecha
    dias_a_tomar : IVacaciones.dias_a_tomar,
    dias_disponibles : IVacaciones.dias_disponibles,
    dias_restantes : IVacaciones.dias_restantes,
    comentarios_aprobador : IVacaciones.comentarios_aprobador,
    autor : IVacaciones.autor.id,
    fecha_elaboracion : IVacaciones.fecha_elaboracion,
    estatus_sistema : IVacaciones.estatus_sistema,
    ultima_actualizacion : getDateString(SERVER_TIME_ZONE),
    cliente_qentry : IVacaciones.cliente_qentry.id,
  };
}
