/**
 * Métodos del, get, post, put para llamadas a la API
 * @get     en este método primero consulta con @getFromLocalStorage si es necesario hacer la petición http sino se obtiene del LocalStorage;
 *          si hay un error en la petición http se busca el objeto en el LocalStorage sino lo encuentra devuelve un objeto vacío; si la
 *          petición se realiza correctamente actualiza el valor individual y en el array del objeto en el LocalStorage y devuelve el objeto
 *          obtenido del servidor.
 * @getAll  en este método primero consulta con @getFromLocalStorage si es necesario hacer la petición http sino se obtiene del LocalStorage;
 *          si hay un error en la petición http se busca el objeto array en el LocalStorage sino lo encuentra devuelve un array vacío; si la
 *          petición se realiza correctamente actualiza array en el LocalStorage y devuelve el array obtenido del servidor
 * @update  en este método después de realizar la petición http si falla devuelve el objeto que se iba a actualizar; si la petición se
 *          realiza correctamente actualiza el valor individual y en el array del objeto en el LocalStorage y devuelve el objeto obtenido del
 *          servidor.
 * @create  en este método después de realizar la petición http si falla devuelve el objeto que se iba a crear; si la petición se realiza
 *          correctamente actualiza el valor individual y en el array del objeto en el LocalStorage y devuelve el objeto obtenido del
 *          servidor.
 * @delete  en este método después de realizar la petición http si falla devuelve el objeto que se iba a eliminar; si la petición se realiza
 *          correctamente elimina el valor individual y en el objeto en el arrya del LocalStorage y devuelve un objeto vacio (Default)
 */

import { del, get, post, put } from "../../api/api";
import {
  getFromLocalStorage,
  updateLocalStorageExpiry,
} from "../../api/helpers";
import { removeArrayItemStorageData } from "../../api/storage";
import { DEFAULT_STATE } from "../../enums/default_state";
import {
  getEmptyStatusMvMotivoIncapacidad,
  setMvMotivoIncapacidad,
  IMvMotivoIncapacidad,
  getSerializableMvMotivoIncapacidad,
} from "../../interfaces/nomina";
import { IServerDate } from "../../interfaces/time";
import {
  getStorageMvMotivoIncapacidadArrayItem,
  setStorageMvMotivoIncapacidad,
  setStorageMvMotivoIncapacidadArrayItem,
  getStorageMvMotivoIncapacidadArray,
  setStorageMvMotivoIncapacidadArray,
  removeStorageMvMotivoIncapacidad,
} from "../../localStorage/nomina";

export const getMvMotivoIncapacidad = async (
  serverDate: IServerDate,
  pk: number
) => {
  const temp = getEmptyStatusMvMotivoIncapacidad();
  const doHttp = await getFromLocalStorage(serverDate, temp.expiry);
  temp.expiry = doHttp.storage;
  if (!doHttp.result) {
    console.log(
      `(localStorage) MvMotivoIncapacidad con pk: ${pk}, obtenido con éxito`
    );
    temp.estado = DEFAULT_STATE.success;
    temp.mensaje = `MvMotivoIncapacidad obtenido con éxito`;
    temp.data = await getStorageMvMotivoIncapacidadArrayItem(pk);
    return temp;
  }
  const result = await get(`nomina/motivo-incapacidades/${pk}`);
  const data = result.data;
  console.log(`MvMotivoIncapacidad data: `, data);
  if (!data.id || data.detail) {
    console.log(
      `Error al obtener MvMotivoIncapacidad con pk: ${pk}, ${data.detail}`
    );
    temp.estado = DEFAULT_STATE.error;
    temp.mensaje = `Error al obtener el MvMotivoIncapacidad: ${
      data.detail ?? "Hubo un problema al conectarse con el servidor"
    }`;
    temp.data = await getStorageMvMotivoIncapacidadArrayItem(pk);
    return temp;
  }
  console.log(`MvMotivoIncapacidad con pk: ${pk}, obtenido con éxito`);
  temp.estado = DEFAULT_STATE.success;
  temp.mensaje = `MvMotivoIncapacidad obtenido con éxito`;
  temp.data = await setMvMotivoIncapacidad(data, serverDate);
  await setStorageMvMotivoIncapacidad(temp.data);
  temp.data_array = await setStorageMvMotivoIncapacidadArrayItem(pk, temp.data);
  temp.expiry = await updateLocalStorageExpiry(serverDate, temp.expiry);
  return temp;
};

export const getAllMvMotivoIncapacidad = async (serverDate: IServerDate) => {
  const temp = getEmptyStatusMvMotivoIncapacidad();
  const doHttp = await getFromLocalStorage(serverDate, temp.expiry_array);
  temp.expiry_array = doHttp.storage;
  if (!doHttp.result) {
    console.log(
      `(localStorage) Todos los MvMotivoIncapacidads obtenidos con éxito`
    );
    temp.estado = DEFAULT_STATE.success;
    temp.mensaje = `Todos los MvMotivoIncapacidad obtenidos con éxito`;
    temp.data_array = await getStorageMvMotivoIncapacidadArray();
    return temp;
  }
  const result = await get(`nomina/motivo-incapacidades`);
  const data = result.data;
  if (data.detail) {
    console.log(
      `Error al obtener la lista de MvMotivoIncapacidad, ${data.detail}`
    );
    temp.estado = DEFAULT_STATE.error;
    temp.mensaje = `Error al obtener la lista de MvMotivoIncapacidad: ${
      data.detail ?? "Hubo un problema al conectarse con el servidor"
    }`;
    temp.data_array = await getStorageMvMotivoIncapacidadArray();
    return temp;
  }
  console.log(`Todos los MvMotivoIncapacidad obtenidos con éxito`, data);
  temp.estado = DEFAULT_STATE.success;
  temp.mensaje = `Todos los MvMotivoIncapacidad obtenidos con éxito`;
  temp.data_array = await Promise.all(
    data.map(async (e: any) => await setMvMotivoIncapacidad(e, serverDate))
  );
  await setStorageMvMotivoIncapacidadArray(temp.data_array);
  temp.expiry_array = await updateLocalStorageExpiry(
    serverDate,
    temp.expiry_array
  );
  return temp;
};

export const updateMvMotivoIncapacidad = async (
  serverDate: IServerDate,
  pk: number,
  object: IMvMotivoIncapacidad
) => {
  const temp = getEmptyStatusMvMotivoIncapacidad();
  const result = await put(
    `nomina/motivo-incapacidades/${pk}`,
    getSerializableMvMotivoIncapacidad(object)
  );
  const data = result.data;
  if (!data.id || data.detail) {
    console.log(
      `Error al actualizar MvMotivoIncapacidad con pk: ${pk}, ${data.detail}`
    );
    temp.estado = DEFAULT_STATE.error;
    temp.mensaje = `Error al actualizar el MvMotivoIncapacidad: ${
      data.detail ?? "Hubo un problema al conectarse con el servidor"
    }`;
    temp.data = await setMvMotivoIncapacidad(object, serverDate);
    return temp;
  }
  console.log(`MvMotivoIncapacidad con pk: ${pk}, actualizado con éxito`);
  temp.estado = DEFAULT_STATE.success;
  temp.mensaje = `MvMotivoIncapacidad actualizado con éxito`;
  temp.data = await setMvMotivoIncapacidad(data, serverDate);
  await setStorageMvMotivoIncapacidad(temp.data);
  temp.data_array = await setStorageMvMotivoIncapacidadArrayItem(
    temp.data.id,
    temp.data
  );
  return temp;
};

export const createMvMotivoIncapacidad = async (
  serverDate: IServerDate,
  object: IMvMotivoIncapacidad
) => {
  const temp = getEmptyStatusMvMotivoIncapacidad();
  const result = await post(
    `nomina/motivo-incapacidades`,
    getSerializableMvMotivoIncapacidad(object)
  );
  const data = result.data;
  if (!data.id || data.detail) {
    console.log(
      `Error al crear MvMotivoIncapacidad con los datos ${object}, ${data.detail}`
    );
    temp.estado = DEFAULT_STATE.error;
    temp.mensaje = `Error al crear el MvMotivoIncapacidad: ${
      data.detail ?? "Hubo un problema al conectarse con el servidor"
    }`;
    temp.data = await setMvMotivoIncapacidad(object, serverDate);
    return temp;
  }
  console.log(`MvMotivoIncapacidad creado con éxito`);
  temp.estado = DEFAULT_STATE.success;
  temp.mensaje = `MvMotivoIncapacidad creado con éxito`;
  temp.data = await setMvMotivoIncapacidad(data, serverDate);
  await setStorageMvMotivoIncapacidad(temp.data);
  temp.data_array = await setStorageMvMotivoIncapacidadArrayItem(
    temp.data.id,
    temp.data
  );
  return temp;
};

export const deleteMvMotivoIncapacidad = async (pk: number) => {
  const temp = getEmptyStatusMvMotivoIncapacidad();
  const result = await del(`nomina/motivo-incapacidades/${pk}`);
  const data = result.data;
  if (data.detail) {
    console.log(
      `Error al eliminar MvMotivoIncapacidad con pk: ${pk}, ${data.detail}`
    );
    temp.estado = DEFAULT_STATE.error;
    temp.mensaje = `Error al eliminar el datos fiscales: ${data.detail}`;
    temp.data = await getStorageMvMotivoIncapacidadArrayItem(pk);
    temp.data_array = await getStorageMvMotivoIncapacidadArray();
    return temp;
  }
  console.log(`MvMotivoIncapacidad eliminado con éxito`);
  temp.estado = DEFAULT_STATE.success;
  temp.mensaje = `MvMotivoIncapacidad eliminado con éxito`;
  await removeStorageMvMotivoIncapacidad();
  temp.data_array = await removeArrayItemStorageData(
    "IMvMotivoIncapacidad",
    pk,
    []
  );
  return temp;
};
