/** 
 * Componente que se encarga del elemento MvMotivoCancelacion
 * @IMvMotivoCancelacion es la interfaz que contiene los parámetos el elemento MvMotivoCancelacion
 * @getEmptyMvMotivoCancelacion  devuelve un objeto con los parámetros por defecto; @param {id}, opcional cuando no encuentra el objeto en el LocalSotrage
 * @IStatusMvMotivoCancelacion es la interfaz para los estatus a llamadas a la API; @param {status}, opcional cuando queremos otro estado de inizialización de tipo [DEFAULT_STATE]
 * @getEmptyStatusMvMotivoCancelacion devuelve un objeto con los parámetros por defecto,
 * @setMvMotivoCancelacion devuelve un objeto de tipo [IMvMotivoCancelacion] recibiendo @param {object} y @param {serverDate}, para verificar que los datos estén en el formato correcto y ajustarlos a la zona horaria actual
 * @getSerializableMvMotivoCancelacion recibe un @param {IMvMotivoCancelacion}, para devolver un objeto serizalizable para las peticiones de la API
 * */

import { getDateString, isNotEmpty, SERVER_TIME_ZONE } from "../../api/helpers";
import { DEFAULT_STATE } from "../../enums/default_state";
import { getStorageClienteQentryArrayItem, getStorageEmpresaArrayItem } from "../../localStorage/personas";
import { IClienteQentry, IEmpresa, getEmptyEmpresa, getEmptyClienteQentry, setClienteQentry, setEmpresa } from "../personas";
import { ILocalStorageExpiry, getEmptyLocalStorageExpiry, IServerDate, convertDateToZonaHoraria } from "../time";

export interface IMvMotivoCancelacion {
  id : number,
  dato : string,
  cliente_qentry : IClienteQentry,
  empresa : IEmpresa,
  fecha_elaboracion : string, // Fecha
  estatus_sistema : boolean,
  ultima_actualizacion : string, // Fecha
}

export const getEmptyMvMotivoCancelacion = (id? : number) => {
  const temp : IMvMotivoCancelacion = 
  {
    id : id ? id : 0,
    dato : "",
    empresa : getEmptyEmpresa(),
    cliente_qentry : getEmptyClienteQentry(),
    fecha_elaboracion : "",
    estatus_sistema : false,
    ultima_actualizacion : "",
  };
  return temp;
}

export interface IStatusMvMotivoCancelacion {
  estado : DEFAULT_STATE,
  mensaje : string,
  data : IMvMotivoCancelacion,
  data_array: IMvMotivoCancelacion[],
  expiry : ILocalStorageExpiry,
  expiry_array : ILocalStorageExpiry,
}

export const getEmptyStatusMvMotivoCancelacion = (status: DEFAULT_STATE = DEFAULT_STATE.loading) => {
  const temp : IStatusMvMotivoCancelacion = 
  {
    estado : status,
    mensaje : "",
    data : getEmptyMvMotivoCancelacion(),
    data_array : [],
    expiry : getEmptyLocalStorageExpiry("IMvMotivoCancelacion", 0),
    expiry_array : getEmptyLocalStorageExpiry("IMvMotivoCancelacionArray", 0),
  };
  return temp;
}

export const setMvMotivoCancelacion = async (
  object : any,
  serverDate : IServerDate,
) => {
  const temp = getEmptyMvMotivoCancelacion();
  if (isNotEmpty(object.id)) temp.id = object.id;
  if (isNotEmpty(object.dato)) temp.dato = object.dato;
  if (isNotEmpty(object.cliente_qentry)) temp.cliente_qentry = (typeof object.cliente_qentry === 'number') ? await getStorageClienteQentryArrayItem(object.cliente_qentry) : await setClienteQentry(object.cliente_qentry, serverDate);
  if (isNotEmpty(object.empresa)) temp.empresa = (typeof object.empresa === 'number') ? await getStorageEmpresaArrayItem(object.empresa) : await setEmpresa(object.empresa, serverDate);
  if (isNotEmpty(object.fecha_elaboracion)) temp.fecha_elaboracion = convertDateToZonaHoraria(serverDate, object.fecha_elaboracion);
  if (isNotEmpty(object.estatus_sistema)) temp.estatus_sistema = object.estatus_sistema;
  if (isNotEmpty(object.ultima_actualizacion)) temp.ultima_actualizacion = convertDateToZonaHoraria(serverDate, object.ultima_actualizacion);
  return temp;
}

export const getSerializableMvMotivoCancelacion = (IMvMotivoCancelacion : IMvMotivoCancelacion ) => {
  return {
    id : IMvMotivoCancelacion.id,
    dato : IMvMotivoCancelacion.dato,
    empresa : IMvMotivoCancelacion.empresa.id,
    cliente_qentry : IMvMotivoCancelacion.cliente_qentry.id,
    fecha_elaboracion : IMvMotivoCancelacion.fecha_elaboracion,
    estatus_sistema : IMvMotivoCancelacion.estatus_sistema,
    ultima_actualizacion : getDateString(SERVER_TIME_ZONE),
  };
}
