/**
 * Métodos para el manejo de IonicStorage 
 * @setStorage              guarda un unico objeto al storage
 * @getStorage              obtiene el objeto guardado con @setStorage
 * @setStorageArray         guarda el array de objetos en el storage
 * @getStorageArray         obtiene el array de objetos guardado con @setStorageArray
 * @removeStorageArray      elimina el array de objetos guardado con @setStorageArray
 * @setStorageItemArray     agrega un elemento al array de objeto guardado con @setStorageArray
 * @getStorageItemArray     obtiene un elemento del array guardado con @setStorageArray buscandolo por su id 
 * @removeStorageItemArray  elimina un elemento del array guardado con @setStorageArray buscandolo por su id 
 */

import { setStorageData, getStorageData, removeStorageData, setArrayItemStorageData, getArrayItemStorageData, removeArrayItemStorageData } from "../../api/storage";
import { IRondin, getEmptyRondin } from "../../interfaces/rondines";

export const setStorageRondin = async (IRondin : IRondin) => await setStorageData('IRondin', IRondin);
export const getStorageRondin = async () : Promise<IRondin> => await getStorageData('IRondin', getEmptyRondin());
export const removeStorageRondin = async () => await removeStorageData('IRondin');
export const setStorageRondinArray = async (IRondin : IRondin[]) => await setStorageData('IRondinArray', IRondin);
export const getStorageRondinArray = async () : Promise<IRondin[]> => await getStorageData('IRondinArray', [] as IRondin[]);
export const removeStorageRondinArray = async () => await removeStorageData('IRondinArray');
export const setStorageRondinArrayItem = async (id: number, IRondin : IRondin) : Promise<IRondin[]> => await setArrayItemStorageData('IRondinArray', id, IRondin, [] as IRondin[]);
export const getStorageRondinArrayItem = async (id: number) : Promise<IRondin> => await getArrayItemStorageData('IRondinArray', id, getEmptyRondin);
export const removeStorageRondinArrayItem = async (id: number) : Promise<IRondin[]> => await removeArrayItemStorageData('IRondinArray', id, [] as IRondin[]);
