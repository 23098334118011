/**
 * Es el componente de la página Vacaciones para crear y visualizar solicitudes de vacaciones de los trabajadores
 * @function uploadVacations se encarge de crear la solicitud de vacaciones del trabajador seleccionado
 */

import React, { useState, useEffect } from "react";
import {
  IonGrid,
  IonRow,
  IonCol,
  IonDatetime,
  IonLabel,
  IonButton,
  IonItem,
  IonTextarea,
  IonContent,
  useIonToast,
  IonList,
  IonPopover,
  IonInput,
  IonSpinner,
} from "@ionic/react";

import { airplaneSharp } from "ionicons/icons";

import Card from "../components/Card";
import Selector from "../components/Selector";

import useRedux, { useAppSelector } from "../hooks/useRedux";
import {
  getGlobalMemoizedEmpresaAsSelectList,
  getNombreEmpleado,
} from "../redux/slices/personasSlice";
import {
  getMemoizedVacationsActiveEmpleadoAsSelectList,
  getMemoizedVacationsByEmpresa,
  setVacationsSelectedEmpleado,
  setVacationsSelectedEmpresa,
  setVacationsSelectedMvEstatusVacaciones,
} from "../redux/slices/pagesSlice/vacationsSlice";
import { getOnlyDateString } from "../redux/api/helpers";
import { getGlobalMemoizedMvEstatusVacacionesAsSelectList } from "../redux/slices/nominaSlice";
import { IVacaciones } from "../redux/interfaces/nomina";
import { DEFAULT_STATE } from "../redux/enums/default_state";

const Vacations: React.FC = () => {
  const [{ empleado, vacaciones, createVacations }] = useRedux();

  const empresaList = useAppSelector(getGlobalMemoizedEmpresaAsSelectList);
  const selectedEmpresa = useAppSelector(
    (state) => state.vacations.selectedEmpresa
  );

  const empleadoList = useAppSelector(
    getMemoizedVacationsActiveEmpleadoAsSelectList
  );
  const selectedEmpleado = useAppSelector(
    (state) => state.vacations.selectedEmpleado
  );

  const vacationsList = useAppSelector(getMemoizedVacationsByEmpresa);

  const mvEstatusVacacionesList = useAppSelector(
    getGlobalMemoizedMvEstatusVacacionesAsSelectList
  );
  const selectedMvEstatusVacaciones = useAppSelector(
    (state) => state.vacations.selectedMvEstatusVacaciones
  );

  const [present, dismiss] = useIonToast();
  let [description, setDescription] = useState<string>("");
  let [selectedIniDate, setSelectedIniDate] = useState<string>(
    getOnlyDateString()
  );
  let [selectedFinDate, setSelectedFinDate] = useState<string>(
    getOnlyDateString()
  );

  const uploadVacations = async () => {
    if (!selectedEmpleado.id) {
      present({
        message: "Por favor seleccione un empleado",
        color: "warning",
        duration: 1500,
      });
      return;
    }
    if (!description || !description.trim()) {
      present({
        message: "Por favor escriba la razón de las vacaciones",
        color: "warning",
        duration: 1500,
      });
      return;
    }
    const temp = empleado.data_array.find(
      (e) => e.id.toString() === selectedEmpleado.id
    );

    if (!temp) {
      present({
        message: "Hubo un problema al obtener al trabajador",
        color: "warning",
        duration: 1500,
      });
      return;
    }

    createVacations(temp, description, selectedIniDate, selectedFinDate);
  };

  return (
    <IonContent class="inner-content">
      <IonGrid>
        <IonRow>
          <IonCol size="12">
            <Card
              icon={airplaneSharp}
              title={<IonLabel>Subir vacaciones</IonLabel>}
              subtitle={
                <Selector
                  value={selectedEmpresa}
                  label="Cuentas/Regiones"
                  list={empresaList}
                  changeState={setVacationsSelectedEmpresa}
                />
              }
              //content="prueba"
              content={
                <IonGrid>
                  <IonRow>
                    <IonCol size="12">
                      <Selector
                        value={selectedEmpleado}
                        label="Empleado"
                        list={empleadoList}
                        changeState={setVacationsSelectedEmpleado}
                      />
                    </IonCol>
                    <IonCol size="12" sizeMd="6" sizeLg="6">
                      <IonItem className="modal-input">
                        <IonLabel position="stacked">Fecha de inicial</IonLabel>
                        <IonInput
                          id="vacation-ini-date"
                          type="text"
                          color="secondary"
                          value={selectedIniDate}
                          className="custom-picker"
                        />

                        <IonPopover
                          className="custom-picker"
                          id="vacation-ini-picker"
                          animated={false}
                          backdropDismiss={true}
                          trigger="vacation-ini-date"
                          triggerAction="click"
                          show-backdrop="true"
                          class="date-picker"
                        >
                          <IonDatetime
                            className="custom-picker"
                            id="vacation-ini-datetime"
                            color="primary"
                            presentation="date"
                            placeholder="Seleccionar fecha"
                            min="2017-01-01"
                            value={selectedIniDate}
                            onIonChange={async (e) => {
                              setSelectedIniDate(
                                getOnlyDateString(e.detail.value as string)
                              );
                            }}
                          ></IonDatetime>
                        </IonPopover>
                      </IonItem>
                    </IonCol>
                    <IonCol size="12" sizeMd="6" sizeLg="6">
                      <IonItem className="modal-input">
                        <IonLabel position="stacked">Fecha de final</IonLabel>
                        <IonInput
                          id="vacation-fin-date"
                          type="text"
                          color="secondary"
                          value={selectedFinDate}
                          className="custom-picker"
                        />

                        <IonPopover
                          className="custom-picker"
                          id="vacation-fin-picker"
                          animated={false}
                          backdropDismiss={true}
                          trigger="vacation-fin-date"
                          triggerAction="click"
                          show-backdrop="true"
                          class="date-picker"
                        >
                          <IonDatetime
                            className="custom-picker"
                            id="vacation-fin-datetime"
                            color="primary"
                            presentation="date"
                            placeholder="Seleccionar fecha"
                            min="2017-01-01"
                            value={selectedFinDate}
                            onIonChange={async (e) => {
                              setSelectedFinDate(
                                getOnlyDateString(e.detail.value as string)
                              );
                            }}
                          ></IonDatetime>
                        </IonPopover>
                      </IonItem>
                    </IonCol>
                  </IonRow>
                  <IonRow>
                    <IonCol>
                      <IonItem>
                        <IonLabel position="floating">
                          Describa la razón de las vacaciones
                        </IonLabel>
                        <IonTextarea
                          autoGrow={true}
                          value={description}
                          onIonChange={(e) => setDescription(e.detail.value!)}
                        ></IonTextarea>
                      </IonItem>
                    </IonCol>
                  </IonRow>
                  <IonRow>
                    <IonCol>
                      <IonButton
                        disabled={vacaciones.estado === DEFAULT_STATE.loading}
                        onClick={() => uploadVacations()}
                        expand="block"
                      >
                        {vacaciones.estado === DEFAULT_STATE.loading ? (
                          <IonSpinner
                            className="loading-center-horizontal"
                            name="circles"
                          />
                        ) : (
                          <IonLabel>Subir</IonLabel>
                        )}
                      </IonButton>
                    </IonCol>
                  </IonRow>
                </IonGrid>
              }
            />
          </IonCol>
          <IonCol size="12">
            <Card
              icon={airplaneSharp}
              title={<IonLabel>Lista de vacaciones</IonLabel>}
              content={
                <IonGrid>
                  <IonRow>
                    <IonCol size="12">
                      <IonItem color="secondary" lines="full">
                        <IonLabel>
                          {/* <h3>Registro de entradas y salidas</h3> */}
                          <div>
                            <Selector
                              classStyle="secondary"
                              color="secondary"
                              value={selectedMvEstatusVacaciones}
                              label="Estatus"
                              list={mvEstatusVacacionesList}
                              changeState={
                                setVacationsSelectedMvEstatusVacaciones
                              }
                            />
                          </div>
                        </IonLabel>
                      </IonItem>
                      <IonList className="selection-area">
                        {vacationsList.map((e) => {
                          return (
                            <IonItem key={`${e.id}-${e.fecha_elaboracion}`}>
                              <IonLabel>
                                <h3>
                                  <b>Empleado</b>:{" "}
                                  {getNombreEmpleado(e.empleado)}
                                </h3>
                                <h3>
                                  <b>Descripción</b>: {e.motivo_vacaciones.dato}
                                </h3>
                                <h3>
                                  <b>Inicio</b>: {e.fecha_inicio_vacaciones}
                                </h3>
                                <h3>
                                  <b>Fin</b>: {e.fecha_fin_vacaciones}
                                </h3>
                                <h3>
                                  <b>Fecha elaboración</b>:{" "}
                                  {e.fecha_elaboracion}
                                </h3>
                                <h3>
                                  <b>Estado</b>: {e.estatus_vacaciones.dato}
                                </h3>
                                {e.comentarios_aprobador ? (
                                  <h3>
                                    <b>Comentarios</b>:{" "}
                                    {e.comentarios_aprobador}
                                  </h3>
                                ) : (
                                  <></>
                                )}
                              </IonLabel>
                            </IonItem>
                          );
                        })}
                        {vacaciones.estado === DEFAULT_STATE.loading ? (
                          <IonSpinner
                            className="loading-center-horizontal"
                            name="circles"
                            color="medium"
                          />
                        ) : vacationsList.length === 0 ? (
                          <IonItem lines="full">
                            <IonLabel>No hay datos</IonLabel>
                          </IonItem>
                        ) : (
                          <></>
                        )}
                      </IonList>
                    </IonCol>
                  </IonRow>
                </IonGrid>
              }
            />
          </IonCol>
        </IonRow>
      </IonGrid>
    </IonContent>
  );
};

export default Vacations;
