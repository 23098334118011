/**
 * Métodos del, get, post, put para llamadas a la API
 * @get     en este método primero consulta con @getFromLocalStorage si es necesario hacer la petición http sino se obtiene del LocalStorage;
 *          si hay un error en la petición http se busca el objeto en el LocalStorage sino lo encuentra devuelve un objeto vacío; si la
 *          petición se realiza correctamente actualiza el valor individual y en el array del objeto en el LocalStorage y devuelve el objeto
 *          obtenido del servidor.
 * @getAll  en este método primero consulta con @getFromLocalStorage si es necesario hacer la petición http sino se obtiene del LocalStorage;
 *          si hay un error en la petición http se busca el objeto array en el LocalStorage sino lo encuentra devuelve un array vacío; si la
 *          petición se realiza correctamente actualiza array en el LocalStorage y devuelve el array obtenido del servidor
 * @update  en este método después de realizar la petición http si falla devuelve el objeto que se iba a actualizar; si la petición se
 *          realiza correctamente actualiza el valor individual y en el array del objeto en el LocalStorage y devuelve el objeto obtenido del
 *          servidor.
 * @create  en este método después de realizar la petición http si falla devuelve el objeto que se iba a crear; si la petición se realiza
 *          correctamente actualiza el valor individual y en el array del objeto en el LocalStorage y devuelve el objeto obtenido del
 *          servidor.
 * @delete  en este método después de realizar la petición http si falla devuelve el objeto que se iba a eliminar; si la petición se realiza
 *          correctamente elimina el valor individual y en el objeto en el arrya del LocalStorage y devuelve un objeto vacio (Default)
 */

import { del, get, post, put } from "../../api/api";
import {
  getFromLocalStorage,
  updateLocalStorageExpiry,
} from "../../api/helpers";
import { removeArrayItemStorageData } from "../../api/storage";
import { DEFAULT_STATE } from "../../enums/default_state";
import {
  getEmptyStatusSolicitudCambioTelefonoAutorizado,
  setSolicitudCambioTelefonoAutorizado,
  ISolicitudCambioTelefonoAutorizado,
  getSerializableSolicitudCambioTelefonoAutorizado,
} from "../../interfaces/personas";
import {
  getEmptyStatusUpdateTelefonoAutorizado,
  setUpdateTelefonoAutorizado,
} from "../../interfaces/personas/ISolicitudCambioTelefonoAutorizado";
import { IServerDate } from "../../interfaces/time";
import {
  getStorageSolicitudCambioTelefonoAutorizadoArrayItem,
  setStorageSolicitudCambioTelefonoAutorizado,
  setStorageSolicitudCambioTelefonoAutorizadoArrayItem,
  getStorageSolicitudCambioTelefonoAutorizadoArray,
  setStorageSolicitudCambioTelefonoAutorizadoArray,
  removeStorageSolicitudCambioTelefonoAutorizado,
} from "../../localStorage/personas";
import {
  setStorageUpdateTelefonoAutorizado,
  setStorageUpdateTelefonoAutorizadoArrayItem,
} from "../../localStorage/personas/SolicitudCambioTelefonoAutorizado";

export const getSolicitudCambioTelefonoAutorizado = async (
  serverDate: IServerDate,
  pk: number,
  idCliente: number
) => {
  const temp = getEmptyStatusSolicitudCambioTelefonoAutorizado();
  const doHttp = await getFromLocalStorage(serverDate, temp.expiry);
  temp.expiry = doHttp.storage;
  if (!doHttp.result) {
    console.log(
      `(localStorage) SolicitudCambioTelefonoAutorizado con pk: ${pk}, obtenido con éxito`
    );
    temp.estado = DEFAULT_STATE.success;
    temp.mensaje = `SolicitudCambioTelefonoAutorizado obtenida con éxito`;
    temp.data = await getStorageSolicitudCambioTelefonoAutorizadoArrayItem(pk);
    return temp;
  }
  const result = await get(
    `personas/solicitud-cambio-telefono-autorizado/${pk}/${idCliente}`
  );
  const data = result.data;
  console.log(`SolicitudCambioTelefonoAutorizado data: `, data);
  if (!data.id || data.detail) {
    console.log(
      `Error al obtener SolicitudCambioTelefonoAutorizado con pk: ${pk}, ${data.detail}`
    );
    temp.estado = DEFAULT_STATE.error;
    temp.mensaje = `Error al obtener la SolicitudCambioTelefonoAutorizado: ${
      data.detail ?? "Hubo un problema al conectarse con el servidor"
    }`;
    temp.data = await getStorageSolicitudCambioTelefonoAutorizadoArrayItem(pk);
    return temp;
  }
  console.log(
    `SolicitudCambioTelefonoAutorizado con pk: ${pk}, obtenido con éxito`
  );
  temp.estado = DEFAULT_STATE.success;
  temp.mensaje = `SolicitudCambioTelefonoAutorizado obtenida con éxito`;
  temp.data = await setSolicitudCambioTelefonoAutorizado(data, serverDate);
  await setStorageSolicitudCambioTelefonoAutorizado(temp.data);
  temp.data_array = await setStorageSolicitudCambioTelefonoAutorizadoArrayItem(
    pk,
    temp.data
  );
  temp.expiry = await updateLocalStorageExpiry(serverDate, temp.expiry);
  return temp;
};

export const getAllSolicitudCambioTelefonoAutorizado = async (
  serverDate: IServerDate,
  idCliente: number
) => {
  const temp = getEmptyStatusSolicitudCambioTelefonoAutorizado();
  const doHttp = await getFromLocalStorage(serverDate, temp.expiry_array);
  temp.expiry_array = doHttp.storage;
  if (!doHttp.result) {
    console.log(
      `(localStorage) Todos las SolicitudCambioTelefonoAutorizado obtenidas con éxito`
    );
    temp.estado = DEFAULT_STATE.success;
    temp.mensaje = `Todos las SolicitudCambioTelefonoAutorizado obtenidas con éxito`;
    temp.data_array = await getStorageSolicitudCambioTelefonoAutorizadoArray();
  }
  const result = await get(
    `personas/solicitud-cambio-telefono-autorizado/${idCliente}`
  );
  const data = result.data;
  if (data.detail) {
    console.log(
      `Error al obtener la lista de SolicitudCambioTelefonoAutorizado`,
      data
    );
    temp.estado = DEFAULT_STATE.error;
    temp.mensaje = `Error al obtener la lista de SolicitudCambioTelefonoAutorizado: ${
      data.detail ?? "Hubo un problema al conectarse con el servidor"
    }`;
    temp.data_array = await getStorageSolicitudCambioTelefonoAutorizadoArray();
    return temp;
  }
  console.log(
    `Todos las SolicitudCambioTelefonoAutorizados obtenidos con éxito`
  );
  temp.estado = DEFAULT_STATE.success;
  temp.mensaje = `Todos las SolicitudCambioTelefonoAutorizado obtenidas con éxito`;
  temp.data_array = await Promise.all(
    data.map(
      async (e: any) =>
        await setSolicitudCambioTelefonoAutorizado(e, serverDate)
    )
  );
  await setStorageSolicitudCambioTelefonoAutorizadoArray(
    temp.data_array as ISolicitudCambioTelefonoAutorizado[]
  );
  temp.expiry_array = await updateLocalStorageExpiry(
    serverDate,
    temp.expiry_array
  );
  return temp;
};

export const updateSolicitudCambioTelefonoAutorizado = async (
  serverDate: IServerDate,
  pk: number,
  idCliente: number,
  object: ISolicitudCambioTelefonoAutorizado
) => {
  const temp = getEmptyStatusSolicitudCambioTelefonoAutorizado();
  const result = await put(
    `personas/solicitud-cambio-telefono-autorizado/${pk}/${idCliente}`,
    getSerializableSolicitudCambioTelefonoAutorizado(object)
  );
  const data = result.data;
  if (!data.id || data.detail) {
    console.log(
      `Error al actualizar SolicitudCambioTelefonoAutorizado con pk: ${pk}, ${data.detail}`
    );
    temp.estado = DEFAULT_STATE.error;
    temp.mensaje = `Error al actualizar la SolicitudCambioTelefonoAutorizado: ${
      data.detail ?? "Hubo un problema al conectarse con el servidor"
    }`;
    temp.data = await setSolicitudCambioTelefonoAutorizado(object, serverDate);
    return temp;
  }
  console.log(
    `SolicitudCambioTelefonoAutorizado con pk: ${pk}, actualizado con éxito`
  );
  temp.estado = DEFAULT_STATE.success;
  temp.mensaje = `SolicitudCambioTelefonoAutorizado actualizada con éxito`;
  temp.data = await setSolicitudCambioTelefonoAutorizado(data, serverDate);
  await setStorageSolicitudCambioTelefonoAutorizado(temp.data);
  temp.data_array = await setStorageSolicitudCambioTelefonoAutorizadoArrayItem(
    temp.data.id,
    temp.data
  );
  return temp;
};

export const updateTelefonoAutorizado = async (
  idEmpleado: number,
  idTelefono: string
) => {
  const temp = getEmptyStatusUpdateTelefonoAutorizado();
  const result = await post(
    "personas/helpers/actualizar-id-telefono-empleado",
    {
      id_empleado: idEmpleado,
      id_telefono: idTelefono,
    }
  );
  const data = result.data;
  if (!data.id_telefono_autorizado || data.detail) {
    console.log(
      `Error al realizar UpdateTelefonoAutorizado del empleado ${idEmpleado} - Telefono id ${idTelefono}`
    );
    temp.estado = DEFAULT_STATE.error;
    temp.mensaje = `Error al realizar UpdateTelefonoAutorizado: ${
      data.detail ?? "Hubo un problema al conectarse con el servidor"
    }`;
    temp.data = await setUpdateTelefonoAutorizado({
      id_empleado: idEmpleado,
      id_telefono: idTelefono,
    });
    return temp;
  }
  console.log(
    `UpdateTelefonoAutorizado del empleado: ${idEmpleado}, actualizado con éxito`
  );
  temp.estado = DEFAULT_STATE.success;
  temp.mensaje = `UpdateTelefonoAutorizado actualizada con éxito`;
  temp.data = await setUpdateTelefonoAutorizado({
    id_empleado: idEmpleado,
    id_telefono: idTelefono,
  });
  await setStorageUpdateTelefonoAutorizado(temp.data);
  temp.data_array = await setStorageUpdateTelefonoAutorizadoArrayItem(
    0,
    temp.data
  );
  return temp;
};

export const createSolicitudCambioTelefonoAutorizado = async (
  serverDate: IServerDate,
  idCliente: number,
  object: ISolicitudCambioTelefonoAutorizado
) => {
  const temp = getEmptyStatusSolicitudCambioTelefonoAutorizado();
  const result = await post(
    `personas/solicitud-cambio-telefono-autorizado/${idCliente}`,
    getSerializableSolicitudCambioTelefonoAutorizado(object)
  );
  const data = result.data;
  if (!data.id || data.detail) {
    console.log(
      `Error al crear SolicitudCambioTelefonoAutorizado con los datos ${object}, ${data.detail}`
    );
    temp.estado = DEFAULT_STATE.error;
    temp.mensaje = `Error al crear la SolicitudCambioTelefonoAutorizado: ${
      data.detail ?? "Hubo un problema al conectarse con el servidor"
    }`;
    temp.data = await setSolicitudCambioTelefonoAutorizado(object, serverDate);
    return temp;
  }
  console.log(`SolicitudCambioTelefonoAutorizado creada con éxito`);
  temp.estado = DEFAULT_STATE.success;
  temp.mensaje = `SolicitudCambioTelefonoAutorizado creada con éxito`;
  temp.data = await setSolicitudCambioTelefonoAutorizado(data, serverDate);
  await setStorageSolicitudCambioTelefonoAutorizado(temp.data);
  temp.data_array = await setStorageSolicitudCambioTelefonoAutorizadoArrayItem(
    temp.data.id,
    temp.data
  );
  return temp;
};

export const deleteSolicitudCambioTelefonoAutorizado = async (
  pk: number,
  idCliente: number
) => {
  const temp = getEmptyStatusSolicitudCambioTelefonoAutorizado();
  const result = await del(
    `personas/solicitud-cambio-telefono-autorizado/${pk}/${idCliente}`
  );
  const data = result.data;
  if (data.detail) {
    console.log(
      `Error al eliminar SolicitudCambioTelefonoAutorizado con pk: ${pk}, ${data.detail}`
    );
    temp.estado = DEFAULT_STATE.error;
    temp.mensaje = `Error al eliminar la SolicitudCambioTelefonoAutorizado: ${data.detail}`;
    temp.data = await getStorageSolicitudCambioTelefonoAutorizadoArrayItem(pk);
    temp.data_array = await getStorageSolicitudCambioTelefonoAutorizadoArray();
    return temp;
  }
  console.log(`SolicitudCambioTelefonoAutorizado eliminada con éxito`);
  temp.estado = DEFAULT_STATE.success;
  temp.mensaje = `SolicitudCambioTelefonoAutorizado eliminada con éxito`;
  await removeStorageSolicitudCambioTelefonoAutorizado();
  temp.data_array = await removeArrayItemStorageData(
    "ISolicitudCambioTelefonoAutorizado",
    pk,
    []
  );
  return temp;
};
