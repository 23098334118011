/** 
 * Componente que se encarga del elemento Pais
 * @IPais es la interfaz que contiene los parámetos el elemento Pais
 * @getEmptyPais  devuelve un objeto con los parámetros por defecto; @param {id}, opcional cuando no encuentra el objeto en el LocalSotrage
 * @IStatusPais es la interfaz para los estatus a llamadas a la API; @param {status}, opcional cuando queremos otro estado de inizialización de tipo [DEFAULT_STATE]
 * @getEmptyStatusPais devuelve un objeto con los parámetros por defecto,
 * @setPais devuelve un objeto de tipo [IPais] recibiendo @param {object} y @param {serverDate}, para verificar que los datos estén en el formato correcto y ajustarlos a la zona horaria actual
 * @getSerializablePais recibe un @param {IPais}, para devolver un objeto serizalizable para las peticiones de la API
 * */

import { getDateString, isNotEmpty, SERVER_TIME_ZONE } from "../../api/helpers";
import { DEFAULT_STATE } from "../../enums/default_state";
import { getStorageUserArrayItem } from "../../localStorage/personas/User";
import { convertDateToZonaHoraria, IServerDate } from "../time";
import { getEmptyLocalStorageExpiry, ILocalStorageExpiry } from "../time/ILocalStorageExpiry";
import { IUser, getEmptyUser, setUser } from "./IUser";

export interface IPais {
  id : number,
  nombre : string,
  codigo_iso : string,
  autor : IUser,
  fecha_elaboracion : string, // Fecha
  estatus_sistema : boolean,
  ultima_actualizacion : string, // Fecha
}

export const getEmptyPais = (id? : number) => {
  const temp : IPais = 
  {
    id : id ? id : 0,
    nombre : "",
    codigo_iso : "",
    autor : getEmptyUser(),
    fecha_elaboracion : "",
    estatus_sistema : false,
    ultima_actualizacion :  "",
  };
  return temp;
}

export interface IStatusPais {
  estado : DEFAULT_STATE,
  mensaje : string,
  data : IPais,
  data_array: IPais[],
  expiry : ILocalStorageExpiry,
  expiry_array : ILocalStorageExpiry,
}

export const getEmptyStatusPais = (status: DEFAULT_STATE = DEFAULT_STATE.loading) => {
  const temp : IStatusPais = 
  {
    estado : status,
    mensaje : "",
    data : getEmptyPais(),
    data_array : [],
    expiry : getEmptyLocalStorageExpiry("IPais", 0),
    expiry_array : getEmptyLocalStorageExpiry("IPaisArray", 0),
  };
  return temp;
}

export const setPais = async (
  object : any,
  serverDate : IServerDate,
) => {
  const temp = getEmptyPais();
  if (isNotEmpty(object.id)) temp.id = object.id;
  if (isNotEmpty(object.nombre)) temp.nombre = object.nombre;
  if (isNotEmpty(object.codigo_iso)) temp.codigo_iso = object.codigo_iso;
  if (isNotEmpty(object.autor)) temp.autor = (typeof object.autor === 'number') ? await getStorageUserArrayItem(object.autor) : await setUser(object.autor, serverDate);
  if (isNotEmpty(object.fecha_elaboracion)) temp.fecha_elaboracion = convertDateToZonaHoraria(serverDate, object.fecha_elaboracion);
  if (isNotEmpty(object.estatus_sistema)) temp.estatus_sistema = object.estatus_sistema;
  if (isNotEmpty(object.ultima_actualizacion)) temp.ultima_actualizacion = convertDateToZonaHoraria(serverDate, object.ultima_actualizacion);
  return temp;
}

export const getSerializablePais = (IPais : IPais) => {
  return {
    id : IPais.id,
    nombre : IPais.nombre,
    codigo_iso : IPais.codigo_iso,
    autor : IPais.autor.id,
    fecha_elaboracion : IPais.fecha_elaboracion,
    estatus_sistema : IPais.estatus_sistema,
    ultima_actualizacion : getDateString(SERVER_TIME_ZONE),
  };
}
