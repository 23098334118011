/**
 * Métodos del, get, post, put para llamadas a la API
 * @get     en este método primero consulta con @getFromLocalStorage si es necesario hacer la petición http sino se obtiene del LocalStorage;
 *          si hay un error en la petición http se busca el objeto en el LocalStorage sino lo encuentra devuelve un objeto vacío; si la
 *          petición se realiza correctamente actualiza el valor individual y en el array del objeto en el LocalStorage y devuelve el objeto
 *          obtenido del servidor.
 * @getAll  en este método primero consulta con @getFromLocalStorage si es necesario hacer la petición http sino se obtiene del LocalStorage;
 *          si hay un error en la petición http se busca el objeto array en el LocalStorage sino lo encuentra devuelve un array vacío; si la
 *          petición se realiza correctamente actualiza array en el LocalStorage y devuelve el array obtenido del servidor
 * @update  en este método después de realizar la petición http si falla devuelve el objeto que se iba a actualizar; si la petición se
 *          realiza correctamente actualiza el valor individual y en el array del objeto en el LocalStorage y devuelve el objeto obtenido del
 *          servidor.
 * @create  en este método después de realizar la petición http si falla devuelve el objeto que se iba a crear; si la petición se realiza 
 *          correctamente actualiza el valor individual y en el array del objeto en el LocalStorage y devuelve el objeto obtenido del
 *          servidor.
 * @delete  en este método después de realizar la petición http si falla devuelve el objeto que se iba a eliminar; si la petición se realiza 
 *          correctamente elimina el valor individual y en el objeto en el arrya del LocalStorage y devuelve un objeto vacio (Default)
 */

import { del, get, post, put } from "../../api/api";
import { getFromLocalStorage, updateLocalStorageExpiry } from "../../api/helpers";
import { removeArrayItemStorageData } from "../../api/storage";
import { DEFAULT_STATE } from "../../enums/default_state";
import { getEmptyStatusInformacionSuscripcion, setInformacionSuscripcion, IInformacionSuscripcion, getSerializableInformacionSuscripcion } from "../../interfaces/personas";
import { IServerDate } from "../../interfaces/time";
import { getStorageInformacionSuscripcionArrayItem, setStorageInformacionSuscripcion, setStorageInformacionSuscripcionArrayItem, getStorageInformacionSuscripcionArray, setStorageInformacionSuscripcionArray, removeStorageInformacionSuscripcion } from "../../localStorage/personas";

export const getInformacionSuscripcion = async (serverDate: IServerDate, pk : number, idCliente : number) => {
  const temp = getEmptyStatusInformacionSuscripcion();
  const doHttp = await getFromLocalStorage(serverDate, temp.expiry);
  temp.expiry = doHttp.storage;
  if (!doHttp.result) {
    console.log(`(localStorage) InformacionSuscripcion con pk: ${pk}, obtenido con éxito`);
    temp.estado = DEFAULT_STATE.success;
    temp.mensaje = `InformacionSuscripcion obtenida con éxito`;
    temp.data = await getStorageInformacionSuscripcionArrayItem(pk);
    return temp;
  }
  const result = await get(`personas/informacion-suscripcion/${pk}/${idCliente}`);
  const data = result.data;
  console.log(`InformacionSuscripcion data: `, data);
  if (!data.id || data.detail) {
    console.log(`Error al obtener InformacionSuscripcion con pk: ${pk}, ${data.detail}`);
    temp.estado = DEFAULT_STATE.error;
    temp.mensaje =  `Error al obtener la informacion de suscripcion: ${data.detail ?? "Hubo un problema al conectarse con el servidor"}`;
    temp.data = await getStorageInformacionSuscripcionArrayItem(pk);
    return temp;
  }
  console.log(`InformacionSuscripcion con pk: ${pk}, obtenido con éxito`);
  temp.estado = DEFAULT_STATE.success;
  temp.mensaje = `InformacionSuscripcion obtenida con éxito`;
  temp.data = await setInformacionSuscripcion(data, serverDate);
  await setStorageInformacionSuscripcion(temp.data);
  temp.data_array = await setStorageInformacionSuscripcionArrayItem(pk, temp.data);
  temp.expiry = await updateLocalStorageExpiry(serverDate, temp.expiry);
  return temp;
}

export const getAllInformacionSuscripcion = async (serverDate: IServerDate, idCliente : number) => {
  const temp = getEmptyStatusInformacionSuscripcion();
  const doHttp = await getFromLocalStorage(serverDate, temp.expiry_array);
  temp.expiry_array = doHttp.storage;
  if (!doHttp.result) {
    console.log(`(localStorage) Todos las InformacionSuscripciones obtenidas con éxito`);
    temp.estado = DEFAULT_STATE.success;
    temp.mensaje = `Todos las informacion de suscripcions obtenidas con éxito`;
    temp.data_array = await getStorageInformacionSuscripcionArray();
  }
  const result = await get(`personas/informacion-suscripcion/${idCliente}`);
  const data = result.data;
  if (data.detail) {
    console.log(`Error al obtener la lista de InformacionSuscripcion`, data);
    temp.estado = DEFAULT_STATE.error;
    temp.mensaje = `Error al obtener la lista de informacion de suscripciones: ${data.detail ?? "Hubo un problema al conectarse con el servidor"}`;
    temp.data_array = await getStorageInformacionSuscripcionArray();
    return temp;
  }
  console.log(`Todos las InformacionSuscripcions obtenidos con éxito`);
  temp.estado = DEFAULT_STATE.success;
  temp.mensaje = `Todos las informacion de suscripcions obtenidas con éxito`;
  temp.data_array = await Promise.all(data.map(async (e : any) => await setInformacionSuscripcion(e, serverDate)));
  await setStorageInformacionSuscripcionArray(temp.data_array);
  temp.expiry_array = await updateLocalStorageExpiry(serverDate, temp.expiry_array);
  return temp;
}

export const updateInformacionSuscripcion = async (serverDate: IServerDate, pk : number, idCliente : number, object : IInformacionSuscripcion) => {
  const temp = getEmptyStatusInformacionSuscripcion();
  const result = await put(`personas/informacion-suscripcion/${pk}/${idCliente}`, getSerializableInformacionSuscripcion(object));
  const data = result.data;
  if (!data.id || data.detail) {
    console.log(`Error al actualizar InformacionSuscripcion con pk: ${pk}, ${data.detail}`);
    temp.estado = DEFAULT_STATE.error;
    temp.mensaje = `Error al actualizar la informacion de suscripcion: ${data.detail ?? "Hubo un problema al conectarse con el servidor"}`;
    temp.data = await setInformacionSuscripcion(object, serverDate);
    return temp;
  }
  console.log(`InformacionSuscripcion con pk: ${pk}, actualizado con éxito`);
  temp.estado = DEFAULT_STATE.success;
  temp.mensaje = `InformacionSuscripcion actualizada con éxito`;
  temp.data = await setInformacionSuscripcion(data, serverDate);
  await setStorageInformacionSuscripcion(temp.data);
  temp.data_array = await setStorageInformacionSuscripcionArrayItem(temp.data.id, temp.data);
  return temp;
}

export const createInformacionSuscripcion = async (serverDate: IServerDate, idCliente : number, object : IInformacionSuscripcion) => {
  const temp = getEmptyStatusInformacionSuscripcion();
  const result = await post(`personas/informacion-suscripcion/${idCliente}`, getSerializableInformacionSuscripcion(object));
  const data = result.data;
  if (!data.id || data.detail) {
    console.log(`Error al crear InformacionSuscripcion con los datos ${object}, ${data.detail}`);
    temp.estado = DEFAULT_STATE.error;
    temp.mensaje = `Error al crear la informacion de suscripcion: ${data.detail ?? "Hubo un problema al conectarse con el servidor"}`;
    temp.data = await setInformacionSuscripcion(object, serverDate);
    return temp;
  }
  console.log(`InformacionSuscripcion creada con éxito`);
  temp.estado = DEFAULT_STATE.success;
  temp.mensaje = `InformacionSuscripcion creada con éxito`;
  temp.data = await setInformacionSuscripcion(data, serverDate);
  await setStorageInformacionSuscripcion(temp.data);
  temp.data_array = await setStorageInformacionSuscripcionArrayItem(temp.data.id, temp.data);
  return temp;
}

export const deleteInformacionSuscripcion = async (pk : number, idCliente : number) => {
  const temp = getEmptyStatusInformacionSuscripcion();
  const result = await del(`personas/informacion-suscripcion/${pk}/${idCliente}`);
  const data = result.data;
  if (data.detail) {
    console.log(`Error al eliminar InformacionSuscripcion con pk: ${pk}, ${data.detail}`);
    temp.estado = DEFAULT_STATE.error;
    temp.mensaje = `Error al eliminar la informacion de suscripcion: ${data.detail}`;
    temp.data = await getStorageInformacionSuscripcionArrayItem(pk);
    temp.data_array = await getStorageInformacionSuscripcionArray();
    return temp;
  }
  console.log(`InformacionSuscripcion eliminada con éxito`);
  temp.estado = DEFAULT_STATE.success;
  temp.mensaje = `InformacionSuscripcion eliminada con éxito`;
  await removeStorageInformacionSuscripcion();
  temp.data_array = await removeArrayItemStorageData("IInformacionSuscripcion", pk, []);
  return temp;
}