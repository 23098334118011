/**
 * Métodos AsyncThunk para ser usados en los reducers y
 * los resultados a las llamadas http puedan ser usados para
 * actualizar el state en redux
 */

import { createAsyncThunk } from "@reduxjs/toolkit";
import { DEFAULT_STATE } from "../../enums/default_state";
import { getIncidencia, getAllIncidencia, updateIncidencia, createIncidencia, deleteIncidencia } from "../../http/nomina";
import { getEmptyStatusIncidencia, IIncidencia } from "../../interfaces/nomina";
import { IServerDate } from "../../interfaces/time";

export const getIncidenciaAsyncThunk = createAsyncThunk(
  'nomina/getIncidenciaAsyncThunk',
  async (query : {serverDate : IServerDate, id: number, idCliente : number}) => await getIncidencia(query.serverDate, query.id, query.idCliente)
    .then(async (result) => result)
    .catch(async (error) => getEmptyStatusIncidencia(DEFAULT_STATE.error))  
);

export const getAllIncidenciaAsyncThunk = createAsyncThunk(
  'nomina/getAllIncidenciaAsyncThunk',
  async (queryAll : {serverDate : IServerDate, idCliente : number}) => await getAllIncidencia(queryAll.serverDate, queryAll.idCliente)
    .then(async (result) => result)
    .catch(async (error) => getEmptyStatusIncidencia(DEFAULT_STATE.error))  
);

export const updateIncidenciaAsyncThunk = createAsyncThunk(
  'nomina/updateIncidenciaAsyncThunk',
  async (update: {serverDate : IServerDate, id: number, idCliente : number, object: IIncidencia}) => await updateIncidencia(update.serverDate, update.id, update.idCliente, update.object)
    .then(async (result) => result)
    .catch(async (error) => getEmptyStatusIncidencia(DEFAULT_STATE.error))  
);

export const createIncidenciaAsyncThunk = createAsyncThunk(
  'nomina/createIncidenciaAsyncThunk',
  async (create : {serverDate : IServerDate, idCliente : number, object: IIncidencia}) => await createIncidencia(create.serverDate, create.idCliente, create.object)
    .then(async (result) => result)
    .catch(async (error) => getEmptyStatusIncidencia(DEFAULT_STATE.error))  
);

export const deleteIncidenciaAsyncThunk = createAsyncThunk(
  'nomina/deleteIncidenciaAsyncThunk',
  async (del : {id : number, idCliente : number}) => await deleteIncidencia(del.id, del.idCliente)
    .then(async (result) => result)
    .catch(async (error) => getEmptyStatusIncidencia())  
);
