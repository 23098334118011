/**
 * Hooks usados para trabajar con los plugins de Capacitor (para uso nativo)
 * @function getCurrentPosition obtiene la ubicación actual usando '@capacitor/geolocation'
 * @function guessLocation obtiene la ubicación actual usando '@capacitor-community/background-geolocation'
 * @function geolocationFallback obtiene la ubicación actual cuando estámos en la web app
 */

 import { Capacitor, registerPlugin } from "@capacitor/core";
 import { BackgroundGeolocationPlugin } from "@capacitor-community/background-geolocation";
 import { Geolocation } from '@capacitor/geolocation';
 import { useIonToast } from "@ionic/react";
 import { useAppDispatch } from "../hooks/useRedux";
 import { setLocation } from "../redux/slices/timeSlice";
 
 
 export const DEFAULT_COORDS = {
   latitud: Number(process.env.REACT_APP_DEFAULT_COORDS_LAT!),
   longitud: Number(process.env.REACT_APP_DEFAULT_COORDS_LNG!),
 } 
 const BackgroundGeolocation = registerPlugin<BackgroundGeolocationPlugin>("BackgroundGeolocation");
 
 function CapacitorPluginsData() : [
   {
     getCurrentPosition:  () => Promise<void>,
     guessLocation: (callback?: any) => Promise<void>,
   },
 ] {
   const dispatch = useAppDispatch();
   const [presentToast, dismissToast] = useIonToast();
   const options = {
     enableHighAccuracy: true,
     timeout: 5000,
     maximumAge: 0
   };
 
   const getCurrentPosition = async () => {
     if (!Capacitor.isNativePlatform()) {
       geolocationFallback();
       return;
     }
     const checkedPerms  = await Geolocation.checkPermissions();
     console.log('Geolocation.checkPermissions()', checkedPerms );
     const perms = await Geolocation.requestPermissions();
     console.log('Geolocation.requestPermissions()', perms);
     const position = await Geolocation.getCurrentPosition({
       enableHighAccuracy: true,
       maximumAge: 0,
       timeout: 5000,
     });
     dispatch(setLocation({latitud: position.coords.latitude, longitud: position.coords.longitude}));
   };
 
   const guessLocation = async () => {
     if (!Capacitor.isNativePlatform()) {
       geolocationFallback();
       return;
     }
     await BackgroundGeolocation.addWatcher(
         {
   
           // Whether permissions should be requested from the user automatically,
           // if they are not already granted. Defaults to "true".
           requestPermissions: true,
   
           // If "true", stale locations may be delivered while the device
           // obtains a GPS fix. You are responsible for checking the "time"
           // property. If "false", locations are guaranteed to be up to date.
           // Defaults to "false".
           stale: true,
         
         },
         (location) => {
           
           if (!location) {
             return
           }
           if (location.simulated) {
             presentToast({
               message: "Se necesita de su ubicación real.",
               color: "danger",
               duration: 2000,
             });
             return
           }
           dispatch(setLocation({latitud: location.latitude, longitud: location.longitude}));
         }
     )
   }
 
   const geolocationFallback = () => {
     function success(pos: GeolocationPosition) {
       dispatch(setLocation({latitud: pos.coords.latitude, longitud: pos.coords.longitude}));
     }
     
     function error(err: GeolocationPositionError) {
       console.warn(`ERROR(${err.code}): ${err.message}`);
     }
     if (!navigator.geolocation) return;
     navigator.geolocation.getCurrentPosition(success, error, options);
   }
 
   return [
     {
       getCurrentPosition,
       guessLocation,
     }
   ]
 }
 
 export default CapacitorPluginsData;