/**
 * Métodos AsyncThunk para ser usados en los reducers y
 * los resultados a las llamadas http puedan ser usados para
 * actualizar el state en redux
 */

import { createAsyncThunk } from "@reduxjs/toolkit";
import { DEFAULT_STATE } from "../../enums/default_state";
import {
  getDireccion,
  getAllDireccion,
  updateDireccion,
  createDireccion,
  deleteDireccion,
} from "../../http/personas";
import { getEmptyStatusDireccion, IDireccion } from "../../interfaces/personas";
import { IServerDate } from "../../interfaces/time";

export const getDireccionAsyncThunk = createAsyncThunk(
  "personas/getDireccionAsyncThunk",
  async (query: { serverDate: IServerDate; id: number; idCliente: number }) =>
    await getDireccion(query.serverDate, query.id, query.idCliente)
      .then(async (result) => result)
      .catch(async (error) => getEmptyStatusDireccion(DEFAULT_STATE.error))
);

export const getAllDireccionAsyncThunk = createAsyncThunk(
  "personas/getAllDireccionAsyncThunk",
  async (queryAll: { serverDate: IServerDate; idCliente: number }) =>
    await getAllDireccion(queryAll.serverDate, queryAll.idCliente)
      .then(async (result) => result)
      .catch(async (error) => getEmptyStatusDireccion(DEFAULT_STATE.error))
);

export const updateDireccionAsyncThunk = createAsyncThunk(
  "personas/updateDireccionAsyncThunk",
  async (update: {
    serverDate: IServerDate;
    id: number;
    idCliente: number;
    object: IDireccion;
  }) =>
    await updateDireccion(
      update.serverDate,
      update.id,
      update.idCliente,
      update.object
    )
      .then(async (result) => result)
      .catch(async (error) => getEmptyStatusDireccion(DEFAULT_STATE.error))
);

export const createDireccionAsyncThunk = createAsyncThunk(
  "personas/createDireccionAsyncThunk",
  async (create: {
    serverDate: IServerDate;
    idCliente: number;
    object: IDireccion;
  }) =>
    await createDireccion(create.serverDate, create.idCliente, create.object)
      .then(async (result) => result)
      .catch(async (error) => getEmptyStatusDireccion(DEFAULT_STATE.error))
);

export const deleteDireccionAsyncThunk = createAsyncThunk(
  "personas/deleteDireccionAsyncThunk",
  async (del: { id: number; idCliente: number }) =>
    await deleteDireccion(del.id, del.idCliente)
      .then(async (result) => result)
      .catch(async (error) => getEmptyStatusDireccion())
);
