import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { List, setListItem } from "../../interfaces/helpers";
import { RootState } from "../../store";
import { getNombreEmpleado } from "../personasSlice";

export interface IDisabilities {
  selectedEmpresa: List;
  selectedEmpleado: List;
  selectedMvEstatusIncapacidades: List;
}

export const disabilitiesInitialState: IDisabilities = {
  selectedEmpresa: { id: "", title: "Todas las empresas" },
  selectedEmpleado: { id: "", title: "Seleccione un empleado" },
  selectedMvEstatusIncapacidades: { id: "", title: "Todos los estatus" },
};

export const disabilitiesSlice = createSlice({
  name: "disabilities",
  initialState: disabilitiesInitialState,
  reducers: {
    setDisabilitiesSelectedEmpresa: (state, action: PayloadAction<List>) => {
      state.selectedEmpresa = action.payload;
    },
    setDisabilitiesSelectedEmpleado: (state, action: PayloadAction<List>) => {
      state.selectedEmpleado = action.payload;
    },
    setDisabilitiesSelectedMvEstatusIncapacidades: (
      state,
      action: PayloadAction<List>
    ) => {
      state.selectedMvEstatusIncapacidades = action.payload;
    },
  },
});

export const {
  setDisabilitiesSelectedEmpresa,
  setDisabilitiesSelectedEmpleado,
  setDisabilitiesSelectedMvEstatusIncapacidades,
  // Modal
} = disabilitiesSlice.actions;

export default disabilitiesSlice.reducer;

export const getMemoizedDisabilitiesActiveEmpleado = createSelector(
  (state: RootState) => state.disabilities.selectedEmpresa,
  (state: RootState) => state.personas.personasState.empleado.data_array,
  (selectedEmpresa, empleados) => {
    if (selectedEmpresa.id === "") return empleados.filter((e) => e.activo);
    const empresa = Number(selectedEmpresa.id);
    return empleados.filter((e) => e.empresa.id === empresa && e.activo);
  }
);

export const getMemoizedDisabilitiesActiveEmpleadoAsSelectList = createSelector(
  getMemoizedDisabilitiesActiveEmpleado,
  (empleados) => {
    return empleados.map((e) =>
      setListItem({
        id: e.id.toString(),
        title: getNombreEmpleado(e),
      })
    );
  }
);

export const getMemoizedDisabilitiesByEmpresa = createSelector(
  getMemoizedDisabilitiesActiveEmpleado,
  (state: RootState) => state.nomina.nominaState.incapacidad.data_array,
  (state: RootState) => state.disabilities.selectedMvEstatusIncapacidades,
  (empleados, disabilities, selectedMvEstatusIncapacidades) => {
    if (selectedMvEstatusIncapacidades.id === "")
      return disabilities.filter((e) =>
        empleados.some((v) => v.id === e.empleado.id)
      );
    return disabilities.filter(
      (e) =>
        empleados.some((v) => v.id === e.empleado.id) &&
        e.estatus_incapacidad.id.toString() ===
          selectedMvEstatusIncapacidades.id
    );
  }
);
