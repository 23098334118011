/**
 * Métodos del, get, post, put para llamadas a la API
 * @get     en este método primero consulta con @getFromLocalStorage si es necesario hacer la petición http sino se obtiene del LocalStorage;
 *          si hay un error en la petición http se busca el objeto en el LocalStorage sino lo encuentra devuelve un objeto vacío; si la
 *          petición se realiza correctamente actualiza el valor individual y en el array del objeto en el LocalStorage y devuelve el objeto
 *          obtenido del servidor.
 * @getAll  en este método primero consulta con @getFromLocalStorage si es necesario hacer la petición http sino se obtiene del LocalStorage;
 *          si hay un error en la petición http se busca el objeto array en el LocalStorage sino lo encuentra devuelve un array vacío; si la
 *          petición se realiza correctamente actualiza array en el LocalStorage y devuelve el array obtenido del servidor
 * @update  en este método después de realizar la petición http si falla devuelve el objeto que se iba a actualizar; si la petición se
 *          realiza correctamente actualiza el valor individual y en el array del objeto en el LocalStorage y devuelve el objeto obtenido del
 *          servidor.
 * @create  en este método después de realizar la petición http si falla devuelve el objeto que se iba a crear; si la petición se realiza
 *          correctamente actualiza el valor individual y en el array del objeto en el LocalStorage y devuelve el objeto obtenido del
 *          servidor.
 * @delete  en este método después de realizar la petición http si falla devuelve el objeto que se iba a eliminar; si la petición se realiza
 *          correctamente elimina el valor individual y en el objeto en el arrya del LocalStorage y devuelve un objeto vacio (Default)
 */

import { del, get, post, put } from "../../api/api";
import {
  getFromLocalStorage,
  updateLocalStorageExpiry,
} from "../../api/helpers";
import { removeArrayItemStorageData } from "../../api/storage";
import { DEFAULT_STATE } from "../../enums/default_state";
import {
  getEmptyStatusMvAntiguedad,
  setMvAntiguedad,
  IMvAntiguedad,
  getSerializableMvAntiguedad,
} from "../../interfaces/personas";
import { IServerDate } from "../../interfaces/time";
import {
  getStorageMvAntiguedadArrayItem,
  setStorageMvAntiguedad,
  setStorageMvAntiguedadArrayItem,
  getStorageMvAntiguedadArray,
  setStorageMvAntiguedadArray,
  removeStorageMvAntiguedad,
} from "../../localStorage/personas";

export const getMvAntiguedad = async (serverDate: IServerDate, pk: number) => {
  const temp = getEmptyStatusMvAntiguedad();
  const doHttp = await getFromLocalStorage(serverDate, temp.expiry);
  temp.expiry = doHttp.storage;
  if (!doHttp.result) {
    console.log(
      `(localStorage) MvAntiguedad con pk: ${pk}, obtenido con éxito`
    );
    temp.estado = DEFAULT_STATE.success;
    temp.mensaje = `MvAntiguedad obtenido con éxito`;
    temp.data = await getStorageMvAntiguedadArrayItem(pk);
    return temp;
  }
  const result = await get(`personas/antiguedad/${pk}`);
  const data = result.data;
  console.log(`MvAntiguedad data: `, data);
  if (!data.id || data.detail) {
    console.log(`Error al obtener MvAntiguedad con pk: ${pk}, ${data.detail}`);
    temp.estado = DEFAULT_STATE.error;
    temp.mensaje = `Error al obtener el antiguedad: ${
      data.detail ?? "Hubo un problema al conectarse con el servidor"
    }`;
    temp.data = await getStorageMvAntiguedadArrayItem(pk);
    return temp;
  }
  console.log(`MvAntiguedad con pk: ${pk}, obtenido con éxito`);
  temp.estado = DEFAULT_STATE.success;
  temp.mensaje = `MvAntiguedad obtenido con éxito`;
  temp.data = await setMvAntiguedad(data, serverDate);
  await setStorageMvAntiguedad(temp.data);
  temp.data_array = await setStorageMvAntiguedadArrayItem(pk, temp.data);
  temp.expiry = await updateLocalStorageExpiry(serverDate, temp.expiry);
  return temp;
};

export const getAllMvAntiguedad = async (serverDate: IServerDate) => {
  const temp = getEmptyStatusMvAntiguedad();
  const doHttp = await getFromLocalStorage(serverDate, temp.expiry_array);
  temp.expiry_array = doHttp.storage;
  if (!doHttp.result) {
    console.log(`(localStorage) Todos los MvAntiguedads obtenidos con éxito`);
    temp.estado = DEFAULT_STATE.success;
    temp.mensaje = `Todos los antiguedads obtenidos con éxito`;
    temp.data_array = await getStorageMvAntiguedadArray();
    return temp;
  }
  const result = await get(`personas/antiguedad`);
  const data = result.data;
  if (data.detail) {
    console.log(`Error al obtener la lista de MvAntiguedad, ${data.detail}`);
    temp.estado = DEFAULT_STATE.error;
    temp.mensaje = `Error al obtener la lista de antiguedads: ${
      data.detail ?? "Hubo un problema al conectarse con el servidor"
    }`;
    temp.data_array = await getStorageMvAntiguedadArray();
    return temp;
  }
  console.log(`Todos los MvAntiguedades obtenidos con éxito`);
  temp.estado = DEFAULT_STATE.success;
  temp.mensaje = `Todos los antiguedads obtenidos con éxito`;
  temp.data_array = await Promise.all(
    data.map(async (e: any) => await setMvAntiguedad(e, serverDate))
  );
  await setStorageMvAntiguedadArray(temp.data_array);
  temp.expiry_array = await updateLocalStorageExpiry(
    serverDate,
    temp.expiry_array
  );
  return temp;
};

export const updateMvAntiguedad = async (
  serverDate: IServerDate,
  pk: number,
  object: IMvAntiguedad
) => {
  const temp = getEmptyStatusMvAntiguedad();
  const result = await put(
    `personas/antiguedad/${pk}`,
    getSerializableMvAntiguedad(object)
  );
  const data = result.data;
  if (!data.id || data.detail) {
    console.log(
      `Error al actualizar MvAntiguedad con pk: ${pk}, ${data.detail}`
    );
    temp.estado = DEFAULT_STATE.error;
    temp.mensaje = `Error al actualizar el antiguedad: ${
      data.detail ?? "Hubo un problema al conectarse con el servidor"
    }`;
    temp.data = await setMvAntiguedad(object, serverDate);
    return temp;
  }
  console.log(`MvAntiguedad con pk: ${pk}, actualizado con éxito`);
  temp.estado = DEFAULT_STATE.success;
  temp.mensaje = `MvAntiguedad actualizado con éxito`;
  temp.data = await setMvAntiguedad(data, serverDate);
  await setStorageMvAntiguedad(temp.data);
  temp.data_array = await setStorageMvAntiguedadArrayItem(
    temp.data.id,
    temp.data
  );
  return temp;
};

export const createMvAntiguedad = async (
  serverDate: IServerDate,
  object: IMvAntiguedad
) => {
  const temp = getEmptyStatusMvAntiguedad();
  const result = await post(
    `personas/antiguedad`,
    getSerializableMvAntiguedad(object)
  );
  const data = result.data;
  if (!data.id || data.detail) {
    console.log(
      `Error al crear MvAntiguedad con los datos ${object}, ${data.detail}`
    );
    temp.estado = DEFAULT_STATE.error;
    temp.mensaje = `Error al crear el antiguedad: ${
      data.detail ?? "Hubo un problema al conectarse con el servidor"
    }`;
    temp.data = await setMvAntiguedad(object, serverDate);
    return temp;
  }
  console.log(`MvAntiguedad creado con éxito`);
  temp.estado = DEFAULT_STATE.success;
  temp.mensaje = `MvAntiguedad creado con éxito`;
  temp.data = await setMvAntiguedad(data, serverDate);
  await setStorageMvAntiguedad(temp.data);
  temp.data_array = await setStorageMvAntiguedadArrayItem(
    temp.data.id,
    temp.data
  );
  return temp;
};

export const deleteMvAntiguedad = async (pk: number) => {
  const temp = getEmptyStatusMvAntiguedad();
  const result = await del(`personas/antiguedad/${pk}`);
  const data = result.data;
  if (data.detail) {
    console.log(`Error al eliminar MvAntiguedad con pk: ${pk}, ${data.detail}`);
    temp.estado = DEFAULT_STATE.error;
    temp.mensaje = `Error al eliminar el antiguedad: ${data.detail}`;
    temp.data = await getStorageMvAntiguedadArrayItem(pk);
    temp.data_array = await getStorageMvAntiguedadArray();
    return temp;
  }
  console.log(`MvAntiguedad eliminado con éxito`);
  temp.estado = DEFAULT_STATE.success;
  temp.mensaje = `MvAntiguedad eliminado con éxito`;
  await removeStorageMvAntiguedad();
  temp.data_array = await removeArrayItemStorageData("IMvAntiguedad", pk, []);
  return temp;
};
