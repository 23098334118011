/**
 * Es el componente utilizado para crear un @IonThumbnail usando la url de la imagen para descargarlas en segundo plano,
 * mientras la imagen se descarga muestra un @IonSpinner.
 * @interface ThumbnailProps
 *   @param {string} src: la url de la imagen
 */

import { IonSpinner, IonThumbnail, useIonModal } from "@ionic/react";
import { useMemo, useState } from "react";
import ImageModal from "./ImageModal";

interface ThumbnailProps {
  src: string;
}

const Thumbnail: React.FC<ThumbnailProps> = ({ src }) => {
  const [loading, setLoading] = useState(true);

  const data = useMemo(() => {
    return (
      <img 
        src={src}
        onLoad={() => setLoading(false)} />
    )
  }, [src]);
  
  const [presentImageModal, dismissImageoModal] = useIonModal(ImageModal, {
    data,
    onDismiss: () => {
      dismissImageoModal();
    }}
  );


  return (
    <>
      <div style={{display: loading && src ? "block" : "none"}}>
        <IonSpinner />
      </div>
      <IonThumbnail style={{display: loading ? "none" : "block"}} onClick={() => presentImageModal()}>
        {data}
      </IonThumbnail>
    </>
  );
};

export default Thumbnail;