/**
 * Métodos para el manejo de IonicStorage 
 * @setStorage              guarda un unico objeto al storage
 * @getStorage              obtiene el objeto guardado con @setStorage
 * @setStorageArray         guarda el array de objetos en el storage
 * @getStorageArray         obtiene el array de objetos guardado con @setStorageArray
 * @removeStorageArray      elimina el array de objetos guardado con @setStorageArray
 * @setStorageItemArray     agrega un elemento al array de objeto guardado con @setStorageArray
 * @getStorageItemArray     obtiene un elemento del array guardado con @setStorageArray buscandolo por su id 
 * @removeStorageItemArray  elimina un elemento del array guardado con @setStorageArray buscandolo por su id 
 */

import { setStorageData, getStorageData, removeStorageData, setArrayItemStorageData, getArrayItemStorageData, removeArrayItemStorageData } from "../../api/storage";
import { IAsistencia, getEmptyAsistencia } from "../../interfaces/nomina";

export const setStorageAsistencia = async (IAsistencia : IAsistencia) => await setStorageData('IAsistencia', IAsistencia);
export const getStorageAsistencia = async () : Promise<IAsistencia> => await getStorageData('IAsistencia', getEmptyAsistencia());
export const removeStorageAsistencia = async () => await removeStorageData('IAsistencia');
export const setStorageAsistenciaArray = async (IAsistencia : IAsistencia[]) => await setStorageData('IAsistenciaArray', IAsistencia);
export const getStorageAsistenciaArray = async () : Promise<IAsistencia[]> => await getStorageData('IAsistenciaArray', [] as IAsistencia[]);
export const removeStorageAsistenciaArray = async () => await removeStorageData('IAsistenciaArray');
export const setStorageAsistenciaArrayItem = async (id: number, IAsistencia : IAsistencia) : Promise<IAsistencia[]> => await setArrayItemStorageData('IAsistenciaArray', id, IAsistencia, [] as IAsistencia[]);
export const getStorageAsistenciaArrayItem = async (id: number) : Promise<IAsistencia> => await getArrayItemStorageData('IAsistenciaArray', id, getEmptyAsistencia);
export const removeStorageAsistenciaArrayItem = async (id: number) : Promise<IAsistencia[]> => await removeArrayItemStorageData('IAsistenciaArray', id, [] as IAsistencia[]);
