/**
 * Se encarga inicialiar Firebase y sus métodos correspondientes
 * @function login inicia la sesión del usuario
 * @function signOut cierra la sesión del usuario
 * @function getJWT obtiene el token JWT del usuario activo
 */

import { initializeApp } from "firebase/app";
import {
  getAuth, signOut as firebaseSignOut,
  signInWithEmailAndPassword,
} from "firebase/auth";
import { IServerDate } from "../redux/interfaces/time";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_PROJECT_ID,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
};

initializeApp(firebaseConfig);

const auth = getAuth();

export const login = async (email: string, password: string, serverDate: IServerDate) => {
 const credentials = await signInWithEmailAndPassword(auth, email, password);
 return {
  credentials,
  serverDate,
 }
};

export const signOut = async () => await firebaseSignOut(auth);

export const getJWT = async () => {
  let token = "JWT";
  const result = await auth.currentUser!.getIdToken(true);
  return `${token} ${result}`;
};

export { auth };

export type { Auth, ApplicationVerifier, ConfirmationResult, User } from "firebase/auth";
export {
  RecaptchaVerifier,
  signInWithEmailAndPassword,
  onAuthStateChanged,
  PhoneAuthProvider,
  updatePhoneNumber,
  sendPasswordResetEmail,
  signInWithPhoneNumber
} from "firebase/auth";

export { ref, uploadBytes, getDownloadURL, uploadBytesResumable, uploadString } from "firebase/storage";
export type { UploadTask, TaskEvent, FullMetadata } from "firebase/storage";

export {
  child,
  onValue
} from 'firebase/database'; 

