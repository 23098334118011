/** 
 * Componente que se encarga del elemento Notificaciones
 * @INotificaciones es la interfaz que contiene los parámetos el elemento Notificaciones
 * @getEmptyNotificaciones  devuelve un objeto con los parámetros por defecto; @param {id}, opcional cuando no encuentra el objeto en el LocalSotrage
 * @IStatusNotificaciones es la interfaz para los estatus a llamadas a la API; @param {status}, opcional cuando queremos otro estado de inizialización de tipo [DEFAULT_STATE]
 * @getEmptyStatusNotificaciones devuelve un objeto con los parámetros por defecto,
 * @setNotificaciones devuelve un objeto de tipo [INotificaciones] recibiendo @param {object} y @param {serverDate}, para verificar que los datos estén en el formato correcto y ajustarlos a la zona horaria actual
 * @getSerializableNotificaciones recibe un @param {INotificaciones}, para devolver un objeto serizalizable para las peticiones de la API
 * */

import { getDateString, isNotEmpty, SERVER_TIME_ZONE } from "../../api/helpers";
import { DEFAULT_STATE } from "../../enums/default_state";
import { getStorageMvTipoNotificacionArrayItem, getStorageMvMotivoNotificacionArrayItem } from "../../localStorage/nomina";
import { getStorageEmpleadoArrayItem, getStorageUserArrayItem } from "../../localStorage/personas";
import { getEmptyClienteQentry, getEmptyEmpleado, getEmptyUser, IClienteQentry, IEmpleado, IUser, setEmpleado, setUser } from "../personas";
import { ILocalStorageExpiry, getEmptyLocalStorageExpiry, IServerDate, convertDateToZonaHoraria } from "../time";
import { getEmptyMvMotivoNotificacion, IMvMotivoNotificacion, setMvMotivoNotificacion } from "./MvMotivoNotificacion";
import { getEmptyMvTipoNotificacion, IMvTipoNotificacion, setMvTipoNotificacion } from "./MvTipoNotificacion";

export interface INotificaciones {
  id : number,
  destinatario : IEmpleado,
  mensaje : string,
  tipo_notificacion : IMvTipoNotificacion,
  motivo_notificacion : IMvMotivoNotificacion,
  autor : IUser,
  fecha_elaboracion : string, // Fecha
  estatus_sistema : boolean,
  ultima_actualizacion : string, // Fecha
  cliente_qentry : IClienteQentry,
}

export const getEmptyNotificaciones = (id? : number) => {
  const temp : INotificaciones = 
  {
    id : id ? id : 0,
    destinatario : getEmptyEmpleado(),
    mensaje : "",
    tipo_notificacion : getEmptyMvTipoNotificacion(),
    motivo_notificacion : getEmptyMvMotivoNotificacion(),
    autor : getEmptyUser(),
    fecha_elaboracion : "",
    estatus_sistema : false,
    ultima_actualizacion : "",
    cliente_qentry : getEmptyClienteQentry(),
  };
  return temp;
}

export interface IStatusNotificaciones {
  estado : DEFAULT_STATE,
  mensaje : string,
  data : INotificaciones,
  data_array: INotificaciones[],
  expiry : ILocalStorageExpiry,
  expiry_array : ILocalStorageExpiry,
}

export const getEmptyStatusNotificaciones = (status: DEFAULT_STATE = DEFAULT_STATE.loading) => {
  const temp : IStatusNotificaciones = 
  {
    estado : status,
    mensaje : "",
    data : getEmptyNotificaciones(),
    data_array : [],
    expiry : getEmptyLocalStorageExpiry("INotificaciones", 0),
    expiry_array : getEmptyLocalStorageExpiry("INotificacionesArray", 0),
  };
  return temp;
}

export const setNotificaciones = async (
  object : any,
  serverDate : IServerDate,
) => {
  const temp = getEmptyNotificaciones();
  if (isNotEmpty(object.id)) temp.id = object.id;
  if (isNotEmpty(object.destinatario)) temp.destinatario = (typeof object.destinatario === 'number') ? await getStorageEmpleadoArrayItem(object.destinatario) : await setEmpleado(object.destinatario, serverDate);
  if (isNotEmpty(object.mensaje)) temp.mensaje = object.mensaje;
  if (isNotEmpty(object.tipo_notificacion)) temp.tipo_notificacion = (typeof object.tipo_notificacion === 'number') ? await getStorageMvTipoNotificacionArrayItem(object.tipo_notificacion) : await setMvTipoNotificacion(object.tipo_notificacion, serverDate);
  if (isNotEmpty(object.motivo_notificacion)) temp.motivo_notificacion = (typeof object.motivo_notificacion === 'number') ? await getStorageMvMotivoNotificacionArrayItem(object.motivo_notificacion) : await setMvMotivoNotificacion(object.motivo_notificacion, serverDate);
  if (isNotEmpty(object.autor)) temp.autor = (typeof object.autor === 'number') ? await getStorageUserArrayItem(object.autor) : await setUser(object.autor, serverDate);
  if (isNotEmpty(object.fecha_elaboracion)) temp.fecha_elaboracion = convertDateToZonaHoraria(serverDate, object.fecha_elaboracion);
  if (isNotEmpty(object.estatus_sistema)) temp.estatus_sistema = object.estatus_sistema;
  if (isNotEmpty(object.ultima_actualizacion)) temp.ultima_actualizacion = convertDateToZonaHoraria(serverDate, object.ultima_actualizacion);
  return temp;
}

export const getSerializableNotificaciones = (INotificaciones : INotificaciones ) => {
  return {
    id : INotificaciones.id,
    destinatario : INotificaciones.destinatario.id,
    mensaje : INotificaciones.mensaje,
    tipo_notificacion : INotificaciones.tipo_notificacion.id,
    motivo_notificacion : INotificaciones.motivo_notificacion.id,
    autor : INotificaciones.autor.id,
    fecha_elaboracion : INotificaciones.fecha_elaboracion,
    estatus_sistema : INotificaciones.estatus_sistema,
    ultima_actualizacion : getDateString(SERVER_TIME_ZONE),
    cliente_qentry : INotificaciones.cliente_qentry.id,
  };
}
