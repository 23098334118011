/**
 * Métodos para el manejo de IonicStorage 
 * @setStorage              guarda un unico objeto al storage
 * @getStorage              obtiene el objeto guardado con @setStorage
 * @setStorageArray         guarda el array de objetos en el storage
 * @getStorageArray         obtiene el array de objetos guardado con @setStorageArray
 * @removeStorageArray      elimina el array de objetos guardado con @setStorageArray
 * @setStorageItemArray     agrega un elemento al array de objeto guardado con @setStorageArray
 * @getStorageItemArray     obtiene un elemento del array guardado con @setStorageArray buscandolo por su id 
 * @removeStorageItemArray  elimina un elemento del array guardado con @setStorageArray buscandolo por su id 
 */

import { setStorageData, getStorageData, removeStorageData, setArrayItemStorageData, getArrayItemStorageData, removeArrayItemStorageData } from "../../api/storage";
import { ICanalVenta, getEmptyCanalVenta } from "../../interfaces/personas";

export const setStorageCanalVenta = async (ICanalVenta : ICanalVenta) => await setStorageData('ICanalVenta', ICanalVenta);
export const getStorageCanalVenta = async () : Promise<ICanalVenta> => await getStorageData('ICanalVenta', getEmptyCanalVenta());
export const removeStorageCanalVenta = async () => await removeStorageData('ICanalVenta');
export const setStorageCanalVentaArray = async (ICanalVenta : ICanalVenta[]) => await setStorageData('ICanalVentaArray', ICanalVenta);
export const getStorageCanalVentaArray = async () : Promise<ICanalVenta[]> => await getStorageData('ICanalVentaArray', [] as ICanalVenta[]);
export const removeStorageCanalVentaArray = async () => await removeStorageData('ICanalVentaArray');
export const setStorageCanalVentaArrayItem = async (id: number, ICanalVenta : ICanalVenta) : Promise<ICanalVenta[]> => await setArrayItemStorageData('ICanalVentaArray', id, ICanalVenta, [] as ICanalVenta[]);
export const getStorageCanalVentaArrayItem = async (id: number) : Promise<ICanalVenta> => await getArrayItemStorageData('ICanalVentaArray', id, getEmptyCanalVenta);
export const removeStorageCanalVentaArrayItem = async (id: number) : Promise<ICanalVenta[]> => await removeArrayItemStorageData('ICanalVentaArray', id, [] as ICanalVenta[]);
