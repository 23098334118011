/**
 * Métodos para el manejo de IonicStorage 
 * @setStorage              guarda un unico objeto al storage
 * @getStorage              obtiene el objeto guardado con @setStorage
 * @setStorageArray         guarda el array de objetos en el storage
 * @getStorageArray         obtiene el array de objetos guardado con @setStorageArray
 * @removeStorageArray      elimina el array de objetos guardado con @setStorageArray
 * @setStorageItemArray     agrega un elemento al array de objeto guardado con @setStorageArray
 * @getStorageItemArray     obtiene un elemento del array guardado con @setStorageArray buscandolo por su id 
 * @removeStorageItemArray  elimina un elemento del array guardado con @setStorageArray buscandolo por su id 
 */

import { setStorageData, getStorageData, removeStorageData, setArrayItemStorageData, getArrayItemStorageData, removeArrayItemStorageData } from "../../api/storage";
import { IMvEstatusAsistencia, getEmptyMvEstatusAsistencia } from "../../interfaces/nomina";

export const setStorageMvEstatusAsistencia = async (IMvEstatusAsistencia : IMvEstatusAsistencia) => await setStorageData('IMvEstatusAsistencia', IMvEstatusAsistencia);
export const getStorageMvEstatusAsistencia = async () : Promise<IMvEstatusAsistencia> => await getStorageData('IMvEstatusAsistencia', getEmptyMvEstatusAsistencia());
export const removeStorageMvEstatusAsistencia = async () => await removeStorageData('IMvEstatusAsistencia');
export const setStorageMvEstatusAsistenciaArray = async (IMvEstatusAsistencia : IMvEstatusAsistencia[]) => await setStorageData('IMvEstatusAsistenciaArray', IMvEstatusAsistencia);
export const getStorageMvEstatusAsistenciaArray = async () : Promise<IMvEstatusAsistencia[]> => await getStorageData('IMvEstatusAsistenciaArray', [] as IMvEstatusAsistencia[]);
export const removeStorageMvEstatusAsistenciaArray = async () => await removeStorageData('IMvEstatusAsistenciaArray');
export const setStorageMvEstatusAsistenciaArrayItem = async (id: number, IMvEstatusAsistencia : IMvEstatusAsistencia) : Promise<IMvEstatusAsistencia[]> => await setArrayItemStorageData('IMvEstatusAsistenciaArray', id, IMvEstatusAsistencia, [] as IMvEstatusAsistencia[]);
export const getStorageMvEstatusAsistenciaArrayItem = async (id: number) : Promise<IMvEstatusAsistencia> => await getArrayItemStorageData('IMvEstatusAsistenciaArray', id, getEmptyMvEstatusAsistencia);
export const removeStorageMvEstatusAsistenciaArrayItem = async (id: number) : Promise<IMvEstatusAsistencia[]> => await removeArrayItemStorageData('IMvEstatusAsistenciaArray', id, [] as IMvEstatusAsistencia[]);
