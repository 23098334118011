/**
 * Métodos para el manejo de IonicStorage 
 * @setStorage              guarda un unico objeto al storage
 * @getStorage              obtiene el objeto guardado con @setStorage
 * @setStorageArray         guarda el array de objetos en el storage
 * @getStorageArray         obtiene el array de objetos guardado con @setStorageArray
 * @removeStorageArray      elimina el array de objetos guardado con @setStorageArray
 * @setStorageItemArray     agrega un elemento al array de objeto guardado con @setStorageArray
 * @getStorageItemArray     obtiene un elemento del array guardado con @setStorageArray buscandolo por su id 
 * @removeStorageItemArray  elimina un elemento del array guardado con @setStorageArray buscandolo por su id 
 */

import { setStorageData, getStorageData, removeStorageData, setArrayItemStorageData, getArrayItemStorageData, removeArrayItemStorageData } from "../../api/storage";
import { IIncidencia, getEmptyIncidencia } from "../../interfaces/nomina";

export const setStorageIncidencia = async (IIncidencia : IIncidencia) => await setStorageData('IIncidencia', IIncidencia);
export const getStorageIncidencia = async () : Promise<IIncidencia> => await getStorageData('IIncidencia', getEmptyIncidencia());
export const removeStorageIncidencia = async () => await removeStorageData('IIncidencia');
export const setStorageIncidenciaArray = async (IIncidencia : IIncidencia[]) => await setStorageData('IIncidenciaArray', IIncidencia);
export const getStorageIncidenciaArray = async () : Promise<IIncidencia[]> => await getStorageData('IIncidenciaArray', [] as IIncidencia[]);
export const removeStorageIncidenciaArray = async () => await removeStorageData('IIncidenciaArray');
export const setStorageIncidenciaArrayItem = async (id: number, IIncidencia : IIncidencia) : Promise<IIncidencia[]> => await setArrayItemStorageData('IIncidenciaArray', id, IIncidencia, [] as IIncidencia[]);
export const getStorageIncidenciaArrayItem = async (id: number) : Promise<IIncidencia> => await getArrayItemStorageData('IIncidenciaArray', id, getEmptyIncidencia);
export const removeStorageIncidenciaArrayItem = async (id: number) : Promise<IIncidencia[]> => await removeArrayItemStorageData('IIncidenciaArray', id, [] as IIncidencia[]);
