/**
 * Métodos para el manejo de IonicStorage 
 * @setStorage              guarda un unico objeto al storage
 * @getStorage              obtiene el objeto guardado con @setStorage
 * @setStorageArray         guarda el array de objetos en el storage
 * @getStorageArray         obtiene el array de objetos guardado con @setStorageArray
 * @removeStorageArray      elimina el array de objetos guardado con @setStorageArray
 * @setStorageItemArray     agrega un elemento al array de objeto guardado con @setStorageArray
 * @getStorageItemArray     obtiene un elemento del array guardado con @setStorageArray buscandolo por su id 
 * @removeStorageItemArray  elimina un elemento del array guardado con @setStorageArray buscandolo por su id 
 */

import { setStorageData, getStorageData, removeStorageData, setArrayItemStorageData, getArrayItemStorageData, removeArrayItemStorageData } from "../../api/storage";
import { IMvTipoServicioQentry, getEmptyMvTipoServicioQentry } from "../../interfaces/personas";

export const setStorageMvTipoServicioQentry = async (IMvTipoServicioQentry : IMvTipoServicioQentry) => await setStorageData('IMvTipoServicioQentry', IMvTipoServicioQentry);
export const getStorageMvTipoServicioQentry = async () : Promise<IMvTipoServicioQentry> => await getStorageData('IMvTipoServicioQentry', getEmptyMvTipoServicioQentry());
export const removeStorageMvTipoServicioQentry = async () => await removeStorageData('IMvTipoServicioQentry');
export const setStorageMvTipoServicioQentryArray = async (IMvTipoServicioQentry : IMvTipoServicioQentry[]) => await setStorageData('IMvTipoServicioQentryArray', IMvTipoServicioQentry);
export const getStorageMvTipoServicioQentryArray = async () : Promise<IMvTipoServicioQentry[]> => await getStorageData('IMvTipoServicioQentryArray', [] as IMvTipoServicioQentry[]);
export const removeStorageMvTipoServicioQentryArray = async () => await removeStorageData('IMvTipoServicioQentryArray');
export const setStorageMvTipoServicioQentryArrayItem = async (id: number, IMvTipoServicioQentry : IMvTipoServicioQentry) : Promise<IMvTipoServicioQentry[]> => await setArrayItemStorageData('IMvTipoServicioQentryArray', id, IMvTipoServicioQentry, [] as IMvTipoServicioQentry[]);
export const getStorageMvTipoServicioQentryArrayItem = async (id: number) : Promise<IMvTipoServicioQentry> => await getArrayItemStorageData('IMvTipoServicioQentryArray', id, getEmptyMvTipoServicioQentry);
export const removeStorageMvTipoServicioQentryArrayItem = async (id: number) : Promise<IMvTipoServicioQentry[]> => await removeArrayItemStorageData('IMvTipoServicioQentryArray', id, [] as IMvTipoServicioQentry[]);
