/**
 * Métodos para el manejo de IonicStorage 
 * @setStorage              guarda un unico objeto al storage
 * @getStorage              obtiene el objeto guardado con @setStorage
 * @setStorageArray         guarda el array de objetos en el storage
 * @getStorageArray         obtiene el array de objetos guardado con @setStorageArray
 * @removeStorageArray      elimina el array de objetos guardado con @setStorageArray
 * @setStorageItemArray     agrega un elemento al array de objeto guardado con @setStorageArray
 * @getStorageItemArray     obtiene un elemento del array guardado con @setStorageArray buscandolo por su id 
 * @removeStorageItemArray  elimina un elemento del array guardado con @setStorageArray buscandolo por su id 
 */

import { setStorageData, getStorageData, removeStorageData, setArrayItemStorageData, getArrayItemStorageData, removeArrayItemStorageData } from "../../api/storage";
import { IHorasExtra, getEmptyHorasExtra } from "../../interfaces/nomina";

export const setStorageHorasExtra = async (IHorasExtra : IHorasExtra) => await setStorageData('IHorasExtra', IHorasExtra);
export const getStorageHorasExtra = async () : Promise<IHorasExtra> => await getStorageData('IHorasExtra', getEmptyHorasExtra());
export const removeStorageHorasExtra = async () => await removeStorageData('IHorasExtra');
export const setStorageHorasExtraArray = async (IHorasExtra : IHorasExtra[]) => await setStorageData('IHorasExtraArray', IHorasExtra);
export const getStorageHorasExtraArray = async () : Promise<IHorasExtra[]> => await getStorageData('IHorasExtraArray', [] as IHorasExtra[]);
export const removeStorageHorasExtraArray = async () => await removeStorageData('IHorasExtraArray');
export const setStorageHorasExtraArrayItem = async (id: number, IHorasExtra : IHorasExtra) : Promise<IHorasExtra[]> => await setArrayItemStorageData('IHorasExtraArray', id, IHorasExtra, [] as IHorasExtra[]);
export const getStorageHorasExtraArrayItem = async (id: number) : Promise<IHorasExtra> => await getArrayItemStorageData('IHorasExtraArray', id, getEmptyHorasExtra);
export const removeStorageHorasExtraArrayItem = async (id: number) : Promise<IHorasExtra[]> => await removeArrayItemStorageData('IHorasExtraArray', id, [] as IHorasExtra[]);
