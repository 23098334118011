/** 
 * Componente que se encarga del elemento AntiguedadEmpleado
 * @IAntiguedadEmpleado es la interfaz que contiene los parámetos el elemento AntiguedadEmpleado
 * @getEmptyAntiguedadEmpleado  devuelve un objeto con los parámetros por defecto; @param {id}, opcional cuando no encuentra el objeto en el LocalSotrage
 * @IStatusAntiguedadEmpleado es la interfaz para los estatus a llamadas a la API; @param {status}, opcional cuando queremos otro estado de inizialización de tipo [DEFAULT_STATE]
 * @getEmptyStatusAntiguedadEmpleado devuelve un objeto con los parámetros por defecto,
 * @setAntiguedadEmpleado devuelve un objeto de tipo [IAntiguedadEmpleado] recibiendo @param {object} y @param {serverDate}, para verificar que los datos estén en el formato correcto y ajustarlos a la zona horaria actual
 * @getSerializableAntiguedadEmpleado recibe un @param {IAntiguedadEmpleado}, para devolver un objeto serizalizable para las peticiones de la API
 * */

import { getDateString, isNotEmpty, SERVER_TIME_ZONE } from "../../api/helpers";
import { DEFAULT_STATE } from "../../enums/default_state";
import { getStorageEmpleadoArrayItem, getStorageMvAntiguedadArrayItem, getStorageUserArrayItem, getStorageClienteQentryArrayItem } from "../../localStorage/personas";
import { convertDateToZonaHoraria, ILocalStorageExpiry, getEmptyLocalStorageExpiry, IServerDate } from "../time";
import { getEmptyClienteQentry, IClienteQentry, setClienteQentry } from "./IClienteQentry";
import { IEmpleado, getEmptyEmpleado, setEmpleado } from "./IEmpleado";
import { IMvAntiguedad, getEmptyMvAntiguedad, setMvAntiguedad } from "./IMvAntiguedad";
import { IUser, getEmptyUser, setUser } from "./IUser";

export interface IAntiguedadEmpleado {
  id : number,
  empleado : IEmpleado,
  rango_antiguedad : IMvAntiguedad,
  fecha_contratacion : string, // Fecha
  dias_laborados : number,
  dias_vacaciones_disponibles : number,
  autor : IUser,
  fecha_elaboracion : string, // Fecha
  estatus_sistema : boolean,
  ultima_actualizacion : string, // Fecha
  cliente_qentry : IClienteQentry,
}

export const getEmptyAntiguedadEmpleado = (id? : number) => {
  const temp : IAntiguedadEmpleado = 
  {
    id : id ? id : 0,
    empleado : getEmptyEmpleado(),
    rango_antiguedad : getEmptyMvAntiguedad(),
    fecha_contratacion : "",
    dias_laborados : 0,
    dias_vacaciones_disponibles : 0,
    autor : getEmptyUser(),
    fecha_elaboracion : "",
    estatus_sistema : false,
    ultima_actualizacion :  "",
    cliente_qentry : getEmptyClienteQentry(),
  };
  return temp;
}

export interface IStatusAntiguedadEmpleado {
  estado : DEFAULT_STATE,
  mensaje : string,
  data : IAntiguedadEmpleado,
  data_array: IAntiguedadEmpleado[],
  expiry : ILocalStorageExpiry,
  expiry_array : ILocalStorageExpiry,
}

export const getEmptyStatusAntiguedadEmpleado = (status: DEFAULT_STATE = DEFAULT_STATE.loading) => {
  const temp : IStatusAntiguedadEmpleado = 
  {
    estado : status,
    mensaje : "",
    data : getEmptyAntiguedadEmpleado(),
    data_array : [],
    expiry : getEmptyLocalStorageExpiry("IAntiguedadEmpleado", 0),
    expiry_array : getEmptyLocalStorageExpiry("IAntiguedadEmpleadoArray", 0),
  };
  return temp;
}

export const setAntiguedadEmpleado = async (
  object : any,
  serverDate : IServerDate,
) => {
  const temp = getEmptyAntiguedadEmpleado();
  if (isNotEmpty(object.id)) temp.id = object.id;
  if (isNotEmpty(object.empleado)) temp.empleado = (typeof object.empleado === 'number') ? await getStorageEmpleadoArrayItem(object.empleado) : await setEmpleado(object.empleado, serverDate);
  if (isNotEmpty(object.rango_antiguedad)) temp.rango_antiguedad = (typeof object.rango_antiguedad === 'number') ? await getStorageMvAntiguedadArrayItem(object.rango_antiguedad) : await setMvAntiguedad(object.rango_antiguedad, serverDate);
  if (isNotEmpty(object.fecha_contratacion)) temp.fecha_contratacion = convertDateToZonaHoraria(serverDate, object.fecha_contratacion);
  if (isNotEmpty(object.dias_laborados)) temp.dias_laborados = object.dias_laborados;
  if (isNotEmpty(object.dias_vacaciones_disponibles)) temp.dias_vacaciones_disponibles = object.dias_vacaciones_disponibles;
  if (isNotEmpty(object.autor)) temp.autor = (typeof object.autor === 'number') ? await getStorageUserArrayItem(object.autor) : await setUser(object.autor, serverDate);
  if (isNotEmpty(object.fecha_elaboracion)) temp.fecha_elaboracion = convertDateToZonaHoraria(serverDate, object.fecha_elaboracion);
  if (isNotEmpty(object.estatus_sistema)) temp.estatus_sistema = object.estatus_sistema;
  if (isNotEmpty(object.ultima_actualizacion)) temp.ultima_actualizacion = convertDateToZonaHoraria(serverDate, object.ultima_actualizacion);
  if (isNotEmpty(object.cliente_qentry)) temp.cliente_qentry = (typeof object.cliente_qentry === 'number') ? await getStorageClienteQentryArrayItem(object.cliente_qentry) : await setClienteQentry(object.cliente_qentry, serverDate);
  return temp;
}

export const getSerializableAntiguedadEmpleado = (IAntiguedadEmpleado : IAntiguedadEmpleado) => {
  return {
    id : IAntiguedadEmpleado.id,
    empleado : IAntiguedadEmpleado.empleado.id,
    rango_antiguedad : IAntiguedadEmpleado.rango_antiguedad.id,
    fecha_contratacion :  getDateString(SERVER_TIME_ZONE, IAntiguedadEmpleado.fecha_contratacion),
    dias_laborados : IAntiguedadEmpleado.dias_laborados,
    dias_vacaciones_disponibles : IAntiguedadEmpleado.dias_vacaciones_disponibles,
    autor : IAntiguedadEmpleado.autor.id,
    fecha_elaboracion : IAntiguedadEmpleado.fecha_elaboracion,
    estatus_sistema : IAntiguedadEmpleado.estatus_sistema,
    ultima_actualizacion : getDateString(SERVER_TIME_ZONE),
    cliente_qentry : IAntiguedadEmpleado.cliente_qentry.id,
  };
}
