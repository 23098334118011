import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { List, setListItem } from "../../interfaces/helpers";
import { RootState } from "../../store";
import { getNombreEmpleado } from "../personasSlice";

export interface IRecord {
  selectedEmpresa: List;
  selectedMvTipoAsistencia: List;
  selectedMvTipoMovimientoAsistencia: List;
  selectedStatusRondin: List;
  statusRondin: List[];
}

export const recordInitialState: IRecord = {
  selectedEmpresa: { id: "", title: "Todas las empresas" },
  selectedMvTipoAsistencia: { id: "", title: "Todos los registros" },
  selectedMvTipoMovimientoAsistencia: {
    id: "",
    title: "Todos los movimientos",
  },
  selectedStatusRondin: { id: "", title: "" },
  statusRondin: [
    {
      id: "1",
      title: "Completados",
    },
    {
      id: "2",
      title: "Cancelados",
    },
    {
      id: "3",
      title: "Activos",
    },
  ],
};

export const recordSlice = createSlice({
  name: "record",
  initialState: recordInitialState,
  reducers: {
    setRecordSelectedEmpresa: (state, action: PayloadAction<List>) => {
      state.selectedEmpresa = action.payload;
    },
    setRecordSelectedMvTipoAsistencia: (state, action: PayloadAction<List>) => {
      state.selectedMvTipoAsistencia = action.payload;
    },
    setRecordSelectedMvTipoMovimientoAsistencia: (
      state,
      action: PayloadAction<List>
    ) => {
      state.selectedMvTipoMovimientoAsistencia = action.payload;
    },
  },
});

export const {
  setRecordSelectedEmpresa,
  setRecordSelectedMvTipoAsistencia,
  setRecordSelectedMvTipoMovimientoAsistencia,
  // Modal
} = recordSlice.actions;

export default recordSlice.reducer;

export const getMemoizedRecordActiveEmpleado = createSelector(
  (state: RootState) => state.record.selectedEmpresa,
  (state: RootState) => state.personas.personasState.empleado.data_array,
  (selectedEmpresa, empleados) => {
    if (selectedEmpresa.id === "") return empleados.filter((e) => e.activo);
    const empresa = Number(selectedEmpresa.id);
    return empleados.filter((e) => e.empresa.id === empresa && e.activo);
  }
);

export const getMemoizedRecordActiveCentroDetrabajo = createSelector(
  (state: RootState) => state.record.selectedEmpresa,
  (state: RootState) => state.personas.personasState.centroDeTrabajo.data_array,
  (selectedEmpresa, centroDeTrabajo) => {
    if (selectedEmpresa.id === "")
      return centroDeTrabajo.filter((e) => e.activo);
    const empresa = Number(selectedEmpresa.id);
    return centroDeTrabajo.filter((e) => e.empresa.id === empresa && e.activo);
  }
);

export const getMemoizedRecordActiveEmpleadoAsSelectList = createSelector(
  getMemoizedRecordActiveEmpleado,
  (empleados) => {
    return empleados.map((e) =>
      setListItem({ id: e.id.toString(), title: getNombreEmpleado(e) })
    );
  }
);

export const getMemoizedRecordActiveCentroDetrabajoAsSelectList =
  createSelector(getMemoizedRecordActiveCentroDetrabajo, (centroDeTrabajo) => {
    return centroDeTrabajo.map((e) =>
      setListItem({ id: e.id.toString(), title: e.nombre_centro })
    );
  });
