/**
 * Reducers creados para obtener asíncronamente 
 * los datos de los modelos y dependiendo del resultado
 * de la peticón actualice el state en redux o mande un mensaje de error
 */

import { PayloadAction } from "@reduxjs/toolkit";
import { getImagenAsyncThunk, getAllImagenAsyncThunk, updateImagenAsyncThunk, createImagenAsyncThunk, deleteImagenAsyncThunk } from "../../asyncThunk/personas";
import { DEFAULT_STATE } from "../../enums/default_state";
import { IStatusImagen } from "../../interfaces/personas";
import { personasInitialState } from "../../slices/personasSlice";

// Reducers: Imagen
export const createImagenReducers = () => {
  const get = () => {
    var { pending, fulfilled, rejected } = getImagenAsyncThunk;
    return {
      [pending.type]: (state : typeof personasInitialState) => {
        state.personasState.imagen.estado = DEFAULT_STATE.loading;
      },
      [fulfilled.type]: (state : typeof personasInitialState, action : PayloadAction<IStatusImagen>) => {
        const result = action.payload;
        result.expiry_array = state.personasState.imagen.expiry_array;
        if (result.estado === DEFAULT_STATE.error) result.data_array = state.personasState.imagen.data_array;
        state.personasState.imagen = result;
      },
      [rejected.type]: (state : typeof personasInitialState, action : PayloadAction<IStatusImagen>) => {
        state.personasState.imagen = {
          ...state.personasState.imagen,
          estado: action.payload.estado,
          mensaje: "Ocurrió un problema al realizar la solicitud",
        };
      },
    };
  };
  
  const getAll = () => {
    var { pending, fulfilled, rejected } = getAllImagenAsyncThunk;
    return {
      [pending.type]: (state : typeof personasInitialState) => {
        state.personasState.imagen.estado = DEFAULT_STATE.loading;
      },
      [fulfilled.type]: (state : typeof personasInitialState, action : PayloadAction<IStatusImagen>) => {
        const result = action.payload;
        result.data = state.personasState.imagen.data;
        result.expiry = state.personasState.imagen.expiry;
        if (result.estado === DEFAULT_STATE.error) result.data_array = state.personasState.imagen.data_array;
        state.personasState.imagen = result;
      },
      [rejected.type]: (state : typeof personasInitialState, action : PayloadAction<IStatusImagen>) => {
        state.personasState.imagen = {
          ...state.personasState.imagen,
          estado: action.payload.estado,
          mensaje: "Ocurrió un problema al realizar la solicitud",
        };
      },
    };
  };
  
  const update = () => {
    var { pending, fulfilled, rejected } = updateImagenAsyncThunk;
    return {
      [pending.type]: (state : typeof personasInitialState) => {
        state.personasState.imagen.estado = DEFAULT_STATE.loading;
      },
      [fulfilled.type]: (state : typeof personasInitialState, action : PayloadAction<IStatusImagen>) => {
        const result: IStatusImagen = action.payload;
        result.expiry = state.personasState.imagen.expiry;
        result.expiry_array = state.personasState.imagen.expiry_array;
        if (result.estado === DEFAULT_STATE.error) result.data_array = state.personasState.imagen.data_array;
        state.personasState.imagen = result;
      },
      [rejected.type]: (state : typeof personasInitialState, action : PayloadAction<IStatusImagen>) => {
        state.personasState.imagen = {
          ...state.personasState.imagen,
          estado: action.payload.estado,
          mensaje: "Ocurrió un problema al realizar la solicitud",
        };
      },
    };
  };

  const create = () => {
    var { pending, fulfilled, rejected } = createImagenAsyncThunk;
    return {
      [pending.type]: (state : typeof personasInitialState) => {
        state.personasState.imagen.estado = DEFAULT_STATE.loading;
      },
      [fulfilled.type]: (state : typeof personasInitialState, action : PayloadAction<IStatusImagen>) => {
        const result = action.payload;
        result.expiry = state.personasState.imagen.expiry;
        result.expiry_array = state.personasState.imagen.expiry_array;
        if (result.estado === DEFAULT_STATE.error) result.data_array = state.personasState.imagen.data_array;
        state.personasState.imagen = result;
      },
      [rejected.type]: (state : typeof personasInitialState, action : PayloadAction<IStatusImagen>) => {
        state.personasState.imagen = {
          ...state.personasState.imagen,
          estado: action.payload.estado,
          mensaje: "Ocurrió un problema al realizar la solicitud",
        };
      },
    };
  };
  
  const del = () => {
    var { pending, fulfilled, rejected } = deleteImagenAsyncThunk;
    return {
      [pending.type]: (state : typeof personasInitialState) => {
        state.personasState.imagen.estado = DEFAULT_STATE.loading;
      },
      [fulfilled.type]: (state : typeof personasInitialState, action : PayloadAction<IStatusImagen>) => {
        const result: IStatusImagen = action.payload;
        result.expiry = state.personasState.imagen.expiry;
        result.expiry_array = state.personasState.imagen.expiry_array;
        if (result.estado === DEFAULT_STATE.error) result.data_array = state.personasState.imagen.data_array;
        state.personasState.imagen = result;
      },
      [rejected.type]: (state : typeof personasInitialState, action : PayloadAction<IStatusImagen>) => {
        state.personasState.imagen = {
          ...state.personasState.imagen,
          estado: action.payload.estado,
          mensaje: "Ocurrió un problema al realizar la solicitud",
        };
      },
    };
  };

  return {
    ...get(),
    ...getAll(),
    ...update(),
    ...create(),
    ...del(),
  };
};
