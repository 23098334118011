/** 
 * Componente que se encarga del elemento MvAntiguedad
 * @IMvAntiguedad es la interfaz que contiene los parámetos el elemento MvAntiguedad
 * @getEmptyMvAntiguedad  devuelve un objeto con los parámetros por defecto; @param {id}, opcional cuando no encuentra el objeto en el LocalSotrage
 * @IStatusMvAntiguedad es la interfaz para los estatus a llamadas a la API; @param {status}, opcional cuando queremos otro estado de inizialización de tipo [DEFAULT_STATE]
 * @getEmptyStatusMvAntiguedad devuelve un objeto con los parámetros por defecto,
 * @setMvAntiguedad devuelve un objeto de tipo [IMvAntiguedad] recibiendo @param {object} y @param {serverDate}, para verificar que los datos estén en el formato correcto y ajustarlos a la zona horaria actual
 * @getSerializableMvAntiguedad recibe un @param {IMvAntiguedad}, para devolver un objeto serizalizable para las peticiones de la API
 * */

import { getDateString, isNotEmpty, SERVER_TIME_ZONE } from "../../api/helpers";
import { DEFAULT_STATE } from "../../enums/default_state";
import { ILocalStorageExpiry, getEmptyLocalStorageExpiry, IServerDate, convertDateToZonaHoraria } from "../time";

export interface IMvAntiguedad {
  id : number,
  dato : string,
  fecha_elaboracion : string, // Fecha
  estatus_sistema : boolean,
  ultima_actualizacion : string, // Fecha
}

export const getEmptyMvAntiguedad = (id? : number) => {
  const temp : IMvAntiguedad = 
  {
    id : id ? id : 0,
    dato : "",
    fecha_elaboracion : "",
    estatus_sistema : false,
    ultima_actualizacion : "",
  };
  return temp;
}

export interface IStatusMvAntiguedad {
  estado : DEFAULT_STATE,
  mensaje : string,
  data : IMvAntiguedad,
  data_array: IMvAntiguedad[],
  expiry : ILocalStorageExpiry,
  expiry_array : ILocalStorageExpiry,
}

export const getEmptyStatusMvAntiguedad = (status: DEFAULT_STATE = DEFAULT_STATE.loading) => {
  const temp : IStatusMvAntiguedad = 
  {
    estado : status,
    mensaje : "",
    data : getEmptyMvAntiguedad(),
    data_array : [],
    expiry : getEmptyLocalStorageExpiry("IMvAntiguedad", 0),
    expiry_array : getEmptyLocalStorageExpiry("IMvAntiguedadArray", 0),
  };
  return temp;
}

export const setMvAntiguedad = async (
  object : any,
  serverDate : IServerDate,
) => {
  const temp = getEmptyMvAntiguedad();
  if (isNotEmpty(object.id)) temp.id = object.id;
  if (isNotEmpty(object.dato)) temp.dato = object.dato;
  if (isNotEmpty(object.fecha_elaboracion)) temp.fecha_elaboracion = convertDateToZonaHoraria(serverDate, object.fecha_elaboracion);
  if (isNotEmpty(object.estatus_sistema)) temp.estatus_sistema = object.estatus_sistema;
  if (isNotEmpty(object.ultima_actualizacion)) temp.ultima_actualizacion = convertDateToZonaHoraria(serverDate, object.ultima_actualizacion);
  return temp;
}

export const getSerializableMvAntiguedad = (IMvAntiguedad : IMvAntiguedad) => {
  return {
    id : IMvAntiguedad.id,
    dato : IMvAntiguedad.dato,
    fecha_elaboracion : IMvAntiguedad.fecha_elaboracion,
    estatus_sistema : IMvAntiguedad.estatus_sistema,
    ultima_actualizacion : getDateString(SERVER_TIME_ZONE),
  };
}
