/**
 * Reducers creados para obtener asíncronamente 
 * los datos de los modelos y dependiendo del resultado
 * de la peticón actualice el state en redux o mande un mensaje de error
 */

import { PayloadAction } from "@reduxjs/toolkit";
import { getMvEstatusIncapacidadesAsyncThunk, getAllMvEstatusIncapacidadesAsyncThunk, updateMvEstatusIncapacidadesAsyncThunk, createMvEstatusIncapacidadesAsyncThunk, deleteMvEstatusIncapacidadesAsyncThunk } from "../../asyncThunk/nomina";
import { DEFAULT_STATE } from "../../enums/default_state";
import { IStatusMvEstatusIncapacidades } from "../../interfaces/nomina";
import { nominaInitialState } from "../../slices/nominaSlice";

// Reducers: MvEstatusIncapacidades
export const createMvEstatusIncapacidadesReducers = () => {
  const get = () => {
    var { pending, fulfilled, rejected } = getMvEstatusIncapacidadesAsyncThunk;
    return {
      [pending.type]: (state : typeof nominaInitialState) => {
        state.nominaState.mvEstatusIncapacidades.estado = DEFAULT_STATE.loading;
      },
      [fulfilled.type]: (state : typeof nominaInitialState, action : PayloadAction<IStatusMvEstatusIncapacidades>) => {
        const result = action.payload;
        result.expiry_array = state.nominaState.mvEstatusIncapacidades.expiry_array;
        if (result.estado === DEFAULT_STATE.error) result.data_array = state.nominaState.mvEstatusIncapacidades.data_array;
        state.nominaState.mvEstatusIncapacidades = result;
      },
      [rejected.type]: (state : typeof nominaInitialState, action : PayloadAction<IStatusMvEstatusIncapacidades>) => {
        state.nominaState.mvEstatusIncapacidades = {
          ...state.nominaState.mvEstatusIncapacidades,
          estado: action.payload.estado,
          mensaje: "Ocurrió un problema al realizar la solicitud",
        };
      },
    };
  };
  
  const getAll = () => {
    var { pending, fulfilled, rejected } = getAllMvEstatusIncapacidadesAsyncThunk;
    return {
      [pending.type]: (state : typeof nominaInitialState) => {
        state.nominaState.mvEstatusIncapacidades.estado = DEFAULT_STATE.loading;
      },
      [fulfilled.type]: (state : typeof nominaInitialState, action : PayloadAction<IStatusMvEstatusIncapacidades>) => {
        const result = action.payload;
        result.data = state.nominaState.mvEstatusIncapacidades.data;
        result.expiry = state.nominaState.mvEstatusIncapacidades.expiry;
        if (result.estado === DEFAULT_STATE.error) result.data_array = state.nominaState.mvEstatusIncapacidades.data_array;
        state.nominaState.mvEstatusIncapacidades = result;
      },
      [rejected.type]: (state : typeof nominaInitialState, action : PayloadAction<IStatusMvEstatusIncapacidades>) => {
        state.nominaState.mvEstatusIncapacidades = {
          ...state.nominaState.mvEstatusIncapacidades,
          estado: action.payload.estado,
          mensaje: "Ocurrió un problema al realizar la solicitud",
        };
      },
    };
  };
  
  const update = () => {
    var { pending, fulfilled, rejected } = updateMvEstatusIncapacidadesAsyncThunk;
    return {
      [pending.type]: (state : typeof nominaInitialState) => {
        state.nominaState.mvEstatusIncapacidades.estado = DEFAULT_STATE.loading;
      },
      [fulfilled.type]: (state : typeof nominaInitialState, action : PayloadAction<IStatusMvEstatusIncapacidades>) => {
        const result: IStatusMvEstatusIncapacidades = action.payload;
        result.expiry = state.nominaState.mvEstatusIncapacidades.expiry;
        result.expiry_array = state.nominaState.mvEstatusIncapacidades.expiry_array;
        if (result.estado === DEFAULT_STATE.error) result.data_array = state.nominaState.mvEstatusIncapacidades.data_array;
        state.nominaState.mvEstatusIncapacidades = result;
      },
      [rejected.type]: (state : typeof nominaInitialState, action : PayloadAction<IStatusMvEstatusIncapacidades>) => {
        state.nominaState.mvEstatusIncapacidades = {
          ...state.nominaState.mvEstatusIncapacidades,
          estado: action.payload.estado,
          mensaje: "Ocurrió un problema al realizar la solicitud",
        };
      },
    };
  };

  const create = () => {
    var { pending, fulfilled, rejected } = createMvEstatusIncapacidadesAsyncThunk;
    return {
      [pending.type]: (state : typeof nominaInitialState) => {
        state.nominaState.mvEstatusIncapacidades.estado = DEFAULT_STATE.loading;
      },
      [fulfilled.type]: (state : typeof nominaInitialState, action : PayloadAction<IStatusMvEstatusIncapacidades>) => {
        const result = action.payload;
        result.expiry = state.nominaState.mvEstatusIncapacidades.expiry;
        result.expiry_array = state.nominaState.mvEstatusIncapacidades.expiry_array;
        if (result.estado === DEFAULT_STATE.error) result.data_array = state.nominaState.mvEstatusIncapacidades.data_array;
        state.nominaState.mvEstatusIncapacidades = result;
      },
      [rejected.type]: (state : typeof nominaInitialState, action : PayloadAction<IStatusMvEstatusIncapacidades>) => {
        state.nominaState.mvEstatusIncapacidades = {
          ...state.nominaState.mvEstatusIncapacidades,
          estado: action.payload.estado,
          mensaje: "Ocurrió un problema al realizar la solicitud",
        };
      },
    };
  };
  
  const del = () => {
    var { pending, fulfilled, rejected } = deleteMvEstatusIncapacidadesAsyncThunk;
    return {
      [pending.type]: (state : typeof nominaInitialState) => {
        state.nominaState.mvEstatusIncapacidades.estado = DEFAULT_STATE.loading;
      },
      [fulfilled.type]: (state : typeof nominaInitialState, action : PayloadAction<IStatusMvEstatusIncapacidades>) => {
        const result: IStatusMvEstatusIncapacidades = action.payload;
        result.expiry = state.nominaState.mvEstatusIncapacidades.expiry;
        result.expiry_array = state.nominaState.mvEstatusIncapacidades.expiry_array;
        if (result.estado === DEFAULT_STATE.error) result.data_array = state.nominaState.mvEstatusIncapacidades.data_array;
        state.nominaState.mvEstatusIncapacidades = result;
      },
      [rejected.type]: (state : typeof nominaInitialState, action : PayloadAction<IStatusMvEstatusIncapacidades>) => {
        state.nominaState.mvEstatusIncapacidades = {
          ...state.nominaState.mvEstatusIncapacidades,
          estado: action.payload.estado,
          mensaje: "Ocurrió un problema al realizar la solicitud",
        };
      },
    };
  };

  return {
    ...get(),
    ...getAll(),
    ...update(),
    ...create(),
    ...del(),
  };
};
