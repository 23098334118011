/**
 * Métodos AsyncThunk para ser usados en los reducers y
 * los resultados a las llamadas http puedan ser usados para
 * actualizar el state en redux
 */

import { createAsyncThunk } from "@reduxjs/toolkit";
import { DEFAULT_STATE } from "../../enums/default_state";
import { getEmpresa, getAllEmpresa, updateEmpresa, createEmpresa, deleteEmpresa } from "../../http/personas";
import { getEmptyStatusEmpresa, IEmpresa } from "../../interfaces/personas";
import { IServerDate } from "../../interfaces/time";

export const getEmpresaAsyncThunk = createAsyncThunk(
  'personas/getEmpresaAsyncThunk',
  async (query : {serverDate : IServerDate, id: number, idCliente : number}) => await getEmpresa(query.serverDate, query.id, query.idCliente)
    .then(async (result) => result)
    .catch(async (error) => getEmptyStatusEmpresa(DEFAULT_STATE.error))  
);

export const getAllEmpresaAsyncThunk = createAsyncThunk(
  'personas/getAllEmpresaAsyncThunk',
  async (queryAll : {serverDate : IServerDate, idCliente : number}) => await getAllEmpresa(queryAll.serverDate, queryAll.idCliente)
    .then(async (result) => result)
    .catch(async (error) => getEmptyStatusEmpresa(DEFAULT_STATE.error))  
);

export const updateEmpresaAsyncThunk = createAsyncThunk(
  'personas/updateEmpresaAsyncThunk',
  async (update: {serverDate : IServerDate, id: number, idCliente : number, object: IEmpresa}) => await updateEmpresa(update.serverDate, update.id, update.idCliente, update.object)
    .then(async (result) => result)
    .catch(async (error) => getEmptyStatusEmpresa(DEFAULT_STATE.error))  
);

export const createEmpresaAsyncThunk = createAsyncThunk(
  'personas/createEmpresaAsyncThunk',
  async (create : {serverDate : IServerDate, idCliente : number, object: IEmpresa}) => await createEmpresa(create.serverDate, create.idCliente, create.object)
    .then(async (result) => result)
    .catch(async (error) => getEmptyStatusEmpresa(DEFAULT_STATE.error))  
);

export const deleteEmpresaAsyncThunk = createAsyncThunk(
  'personas/deleteEmpresaAsyncThunk',
  async (del : {id : number, idCliente : number}) => await deleteEmpresa(del.id, del.idCliente)
    .then(async (result) => result)
    .catch(async (error) => getEmptyStatusEmpresa())  
);
