/**
 * Reducers creados para obtener asíncronamente 
 * los datos de los modelos y dependiendo del resultado
 * de la peticón actualice el state en redux o mande un mensaje de error
 */

import { PayloadAction } from "@reduxjs/toolkit";
import { getMvTipoSuscripcionAsyncThunk, getAllMvTipoSuscripcionAsyncThunk, updateMvTipoSuscripcionAsyncThunk, createMvTipoSuscripcionAsyncThunk, deleteMvTipoSuscripcionAsyncThunk } from "../../asyncThunk/personas";
import { DEFAULT_STATE } from "../../enums/default_state";
import { IStatusMvTipoSuscripcion } from "../../interfaces/personas";
import { personasInitialState } from "../../slices/personasSlice";

// Reducers: MvTipoSuscripcion
export const createMvTipoSuscripcionReducers = () => {
  const get = () => {
    var { pending, fulfilled, rejected } = getMvTipoSuscripcionAsyncThunk;
    return {
      [pending.type]: (state : typeof personasInitialState) => {
        state.personasState.mvTipoSuscripcion.estado = DEFAULT_STATE.loading;
      },
      [fulfilled.type]: (state : typeof personasInitialState, action : PayloadAction<IStatusMvTipoSuscripcion>) => {
        const result = action.payload;
        result.expiry_array = state.personasState.mvTipoSuscripcion.expiry_array;
        if (result.estado === DEFAULT_STATE.error) result.data_array = state.personasState.mvTipoSuscripcion.data_array;
        state.personasState.mvTipoSuscripcion = result;
      },
      [rejected.type]: (state : typeof personasInitialState, action : PayloadAction<IStatusMvTipoSuscripcion>) => {
        state.personasState.mvTipoSuscripcion = {
          ...state.personasState.mvTipoSuscripcion,
          estado: action.payload.estado,
          mensaje: "Ocurrió un problema al realizar la solicitud",
        };
      },
    };
  };
  
  const getAll = () => {
    var { pending, fulfilled, rejected } = getAllMvTipoSuscripcionAsyncThunk;
    return {
      [pending.type]: (state : typeof personasInitialState) => {
        state.personasState.mvTipoSuscripcion.estado = DEFAULT_STATE.loading;
      },
      [fulfilled.type]: (state : typeof personasInitialState, action : PayloadAction<IStatusMvTipoSuscripcion>) => {
        const result = action.payload;
        result.data = state.personasState.mvTipoSuscripcion.data;
        result.expiry = state.personasState.mvTipoSuscripcion.expiry;
        if (result.estado === DEFAULT_STATE.error) result.data_array = state.personasState.mvTipoSuscripcion.data_array;
        state.personasState.mvTipoSuscripcion = result;
      },
      [rejected.type]: (state : typeof personasInitialState, action : PayloadAction<IStatusMvTipoSuscripcion>) => {
        state.personasState.mvTipoSuscripcion = {
          ...state.personasState.mvTipoSuscripcion,
          estado: action.payload.estado,
          mensaje: "Ocurrió un problema al realizar la solicitud",
        };
      },
    };
  };
  
  const update = () => {
    var { pending, fulfilled, rejected } = updateMvTipoSuscripcionAsyncThunk;
    return {
      [pending.type]: (state : typeof personasInitialState) => {
        state.personasState.mvTipoSuscripcion.estado = DEFAULT_STATE.loading;
      },
      [fulfilled.type]: (state : typeof personasInitialState, action : PayloadAction<IStatusMvTipoSuscripcion>) => {
        const result: IStatusMvTipoSuscripcion = action.payload;
        result.expiry = state.personasState.mvTipoSuscripcion.expiry;
        result.expiry_array = state.personasState.mvTipoSuscripcion.expiry_array;
        if (result.estado === DEFAULT_STATE.error) result.data_array = state.personasState.mvTipoSuscripcion.data_array;
        state.personasState.mvTipoSuscripcion = result;
      },
      [rejected.type]: (state : typeof personasInitialState, action : PayloadAction<IStatusMvTipoSuscripcion>) => {
        state.personasState.mvTipoSuscripcion = {
          ...state.personasState.mvTipoSuscripcion,
          estado: action.payload.estado,
          mensaje: "Ocurrió un problema al realizar la solicitud",
        };
      },
    };
  };

  const create = () => {
    var { pending, fulfilled, rejected } = createMvTipoSuscripcionAsyncThunk;
    return {
      [pending.type]: (state : typeof personasInitialState) => {
        state.personasState.mvTipoSuscripcion.estado = DEFAULT_STATE.loading;
      },
      [fulfilled.type]: (state : typeof personasInitialState, action : PayloadAction<IStatusMvTipoSuscripcion>) => {
        const result = action.payload;
        result.expiry = state.personasState.mvTipoSuscripcion.expiry;
        result.expiry_array = state.personasState.mvTipoSuscripcion.expiry_array;
        if (result.estado === DEFAULT_STATE.error) result.data_array = state.personasState.mvTipoSuscripcion.data_array;
        state.personasState.mvTipoSuscripcion = result;
      },
      [rejected.type]: (state : typeof personasInitialState, action : PayloadAction<IStatusMvTipoSuscripcion>) => {
        state.personasState.mvTipoSuscripcion = {
          ...state.personasState.mvTipoSuscripcion,
          estado: action.payload.estado,
          mensaje: "Ocurrió un problema al realizar la solicitud",
        };
      },
    };
  };
  
  const del = () => {
    var { pending, fulfilled, rejected } = deleteMvTipoSuscripcionAsyncThunk;
    return {
      [pending.type]: (state : typeof personasInitialState) => {
        state.personasState.mvTipoSuscripcion.estado = DEFAULT_STATE.loading;
      },
      [fulfilled.type]: (state : typeof personasInitialState, action : PayloadAction<IStatusMvTipoSuscripcion>) => {
        const result: IStatusMvTipoSuscripcion = action.payload;
        result.expiry = state.personasState.mvTipoSuscripcion.expiry;
        result.expiry_array = state.personasState.mvTipoSuscripcion.expiry_array;
        if (result.estado === DEFAULT_STATE.error) result.data_array = state.personasState.mvTipoSuscripcion.data_array;
        state.personasState.mvTipoSuscripcion = result;
      },
      [rejected.type]: (state : typeof personasInitialState, action : PayloadAction<IStatusMvTipoSuscripcion>) => {
        state.personasState.mvTipoSuscripcion = {
          ...state.personasState.mvTipoSuscripcion,
          estado: action.payload.estado,
          mensaje: "Ocurrió un problema al realizar la solicitud",
        };
      },
    };
  };

  return {
    ...get(),
    ...getAll(),
    ...update(),
    ...create(),
    ...del(),
  };
};
