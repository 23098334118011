import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { List, setListItem } from "../../interfaces/helpers";
import { getEmptyRondin, IRondin } from "../../interfaces/rondines";
import { RootState } from "../../store";
import { getNombreEmpleado } from "../personasSlice";

export interface IPatrols {
  selectedEmpresa: List;
  empleadosList: List[];
  centroDeTrabajoList: List[];
  rondinModal: IRondin;
}

export const patrolsInitialState: IPatrols = {
  selectedEmpresa: { id: "", title: "Todas las empresas" },
  empleadosList: [],
  centroDeTrabajoList: [],
  rondinModal: getEmptyRondin(),
};

export const patrolsSlice = createSlice({
  name: "patrols",
  initialState: patrolsInitialState,
  reducers: {
    setPatrolsSelectedEmpresa: (state, action: PayloadAction<List>) => {
      //state.rondinModal = getEmptyRondin();
      console.log("Dentro de setPatrolsSelectedEmpresa", action.payload);

      state.selectedEmpresa = action.payload;
    },
    setPatrolsRondinModal: (state, action: PayloadAction<IRondin>) => {
      state.rondinModal = action.payload;
    },
  },
});

export const {
  setPatrolsSelectedEmpresa,
  // Modal
  setPatrolsRondinModal,
} = patrolsSlice.actions;

export default patrolsSlice.reducer;

export const getMemoizedPatrolsActiveEmpleado = createSelector(
  (state: RootState) => state.patrols.selectedEmpresa,
  (state: RootState) => state.personas.personasState.empleado.data_array,
  (selectedEmpresa, empleados) => {
    if (selectedEmpresa.id === "") return empleados.filter((e) => e.activo);
    const empresa = Number(selectedEmpresa.id);
    return empleados.filter((e) => e.empresa.id === empresa && e.activo);
  }
);

export const getMemoizedPatrolsActiveCentroDetrabajo = createSelector(
  (state: RootState) => state.patrols.selectedEmpresa,
  (state: RootState) => state.personas.personasState.centroDeTrabajo.data_array,
  (selectedEmpresa, centroDeTrabajo) => {
    if (selectedEmpresa.id === "")
      return centroDeTrabajo.filter((e) => e.activo);
    const empresa = Number(selectedEmpresa.id);
    return centroDeTrabajo.filter((e) => e.empresa.id === empresa && e.activo);
  }
);

export const getMemoizedPatrolsActiveEmpleadoAsSelectList = createSelector(
  getMemoizedPatrolsActiveEmpleado,
  (empleados) => {
    return empleados.map((e) =>
      setListItem({
        id: e.id.toString(),
        title: getNombreEmpleado(e),
      })
    );
  }
);

export const getMemoizedPatrolsActiveCentroDetrabajoAsSelectList =
  createSelector(getMemoizedPatrolsActiveCentroDetrabajo, (centroDeTrabajo) => {
    return centroDeTrabajo.map((e) =>
      setListItem({ id: e.id.toString(), title: e.nombre_centro })
    );
  });

export const getMemoizedPatrolsSelectedWorkCenters = createSelector(
  (state: RootState) => state.patrols.rondinModal,
  (rondin) => {
    return rondin.checkpoints.map((e) =>
      setListItem({
        id: e.codigo_qr.centro_de_trabajo.id.toString(),
        title: e.codigo_qr.centro_de_trabajo.nombre_centro,
      })
    );
  }
);

export const getMemoizedPatrolsSelectedEmpleados = createSelector(
  (state: RootState) => state.patrols.rondinModal,
  (rondin) => {
    return rondin.empleados.map((e) =>
      setListItem({
        id: e.id.toString(),
        title: getNombreEmpleado(e),
      })
    );
  }
);

export const getMemoizedPatrolsRondinesList = createSelector(
  (state: RootState) => state.patrols.selectedEmpresa,
  (state: RootState) => state.rondines.rondinesState.rondin.data_array,
  (selectedEmpresa, rondin) => {
    if (selectedEmpresa.id === "") return rondin;
    return rondin.filter((e) => e.empresa.id.toString() === selectedEmpresa.id);
  }
);
