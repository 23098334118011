import { IonApp, IonRouterOutlet, IonSplitPane, IonLoading, useIonToast, IonText, setupIonicReact } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import { Redirect, Route } from 'react-router-dom';
import Menu from './components/Menu';
import View from './components/View';
import Login from './pages/Login';
import LoginPhone from './pages/LoginPhone';
import Loading from './pages/Loading';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';


import { useEffect } from 'react';
import useRedux, { useAppDispatch } from './hooks/useRedux';
import { createStore } from './redux/api/storage';
import { getServerDateAsyncThunk } from './redux/asyncThunk/time/ServerDate';

import {
  Chart as ChartJS,
  RadialLinearScale,
  ArcElement,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { AuthState } from './redux/interfaces/auth';
import useCapacitor from './hooks/useCapacitor';
import { auth as firebaseAuth} from "./firebase/config";
import { detectActiveSession } from './redux/asyncThunk/auth';
import { sleep } from './redux/api/helpers';

setupIonicReact({
  animated: true,
});

ChartJS.register(
  RadialLinearScale,
  ArcElement,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const App: React.FC = () => {
  const dispatch = useAppDispatch();  
  const [{auth, serverDate, location}] = useRedux();
  const [{guessLocation}] = useCapacitor();

  useEffect(() => {
    const load = async () => {
      await createStore(); // Ionic Store      
      await guessLocation();
    };
    load();
  }, [serverDate]);

  useEffect(() => {
    const load = async () => {
      await sleep(1000);
      console.log({location})
      await dispatch(getServerDateAsyncThunk({latitud: location.latitud, longitud: location.longitud}));
    };
    load();
  }, []);

  useEffect(() => {
    firebaseAuth.onAuthStateChanged(async (firebaseUser) => {
      await dispatch(detectActiveSession({data: firebaseUser, serverDate: serverDate.data}))
    });
  }, []);

  return (
    <IonApp>
      {
        auth.isLoggedIn === AuthState.offline ?
          <IonReactRouter>
            <Route path="/Login" exact={true} component={Login} />
            <Route path="/Login/:accessKey" exact={true} component={Login} />
            <Route path="/LoginPhone" exact={true} component={LoginPhone} />

            <Route path="/" exact={true}>
              <Redirect to="/Login" />
            </Route>

            <Route path="/page/:name" exact={true}>
              <Redirect to="/Login" />
            </Route>
          </IonReactRouter>
        :
        auth.isLoggedIn === AuthState.loading ?
          <IonReactRouter>
            <Route component={Loading} />
          </IonReactRouter>
        :
          <IonReactRouter>
            <IonSplitPane contentId="main">
              <Menu />
              <IonRouterOutlet id="main">
                <Route path="/" exact={true}>
                  <Redirect to="/page/Resumen" />
                </Route>
                <Route path="/Login" exact={true}>
                  <Redirect to="/page/Resumen" />
                </Route>
                <Route path="/Login/:accessKey" exact={true}>
                  <Redirect to="/page/Resumen" />
                </Route>
                <Route path="/LoginPhone" exact={true}>
                  <Redirect to="/page/Resumen" />
                </Route>
                <Route path="/Plans" exact={true}>
                  <Redirect to="/page/Resumen" />
                </Route>
                <Route path="/page/:name" exact={true}>
                  <View />
                </Route>
              </IonRouterOutlet>
            </IonSplitPane>
          </IonReactRouter>
      }
    </IonApp>
  );
};

export default App;
