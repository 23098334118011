/**
 * Métodos AsyncThunk para ser usados en los reducers y
 * los resultados a las llamadas http puedan ser usados para
 * actualizar el state en redux
 */

import { createAsyncThunk } from "@reduxjs/toolkit";
import { DEFAULT_STATE } from "../../enums/default_state";
import {
  getMvEstatusSolicitudCambioTelefonoAutorizado,
  getAllMvEstatusSolicitudCambioTelefonoAutorizado,
  updateMvEstatusSolicitudCambioTelefonoAutorizado,
  createMvEstatusSolicitudCambioTelefonoAutorizado,
  deleteMvEstatusSolicitudCambioTelefonoAutorizado,
} from "../../http/personas";
import {
  getEmptyStatusMvEstatusSolicitudCambioTelefonoAutorizado,
  IMvEstatusSolicitudCambioTelefonoAutorizado,
} from "../../interfaces/personas";
import { IServerDate } from "../../interfaces/time";

export const getMvEstatusSolicitudCambioTelefonoAutorizadoAsyncThunk =
  createAsyncThunk(
    "personas/getMvEstatusSolicitudCambioTelefonoAutorizadoAsyncThunk",
    async (query: { serverDate: IServerDate; id: number }) =>
      await getMvEstatusSolicitudCambioTelefonoAutorizado(
        query.serverDate,
        query.id
      )
        .then(async (result) => result)
        .catch(async (error) =>
          getEmptyStatusMvEstatusSolicitudCambioTelefonoAutorizado(
            DEFAULT_STATE.error
          )
        )
  );

export const getAllMvEstatusSolicitudCambioTelefonoAutorizadoAsyncThunk =
  createAsyncThunk(
    "personas/getAllMvEstatusSolicitudCambioTelefonoAutorizadoAsyncThunk",
    async (queryAll: { serverDate: IServerDate }) =>
      await getAllMvEstatusSolicitudCambioTelefonoAutorizado(
        queryAll.serverDate
      )
        .then(async (result) => result)
        .catch(async (error) =>
          getEmptyStatusMvEstatusSolicitudCambioTelefonoAutorizado(
            DEFAULT_STATE.error
          )
        )
  );

export const updateMvEstatusSolicitudCambioTelefonoAutorizadoAsyncThunk =
  createAsyncThunk(
    "personas/updateMvEstatusSolicitudCambioTelefonoAutorizadoAsyncThunk",
    async (update: {
      serverDate: IServerDate;
      id: number;
      MvEstatusSolicitudCambioTelefonoAutorizado: IMvEstatusSolicitudCambioTelefonoAutorizado;
    }) =>
      await updateMvEstatusSolicitudCambioTelefonoAutorizado(
        update.serverDate,
        update.id,
        update.MvEstatusSolicitudCambioTelefonoAutorizado
      )
        .then(async (result) => result)
        .catch(async (error) =>
          getEmptyStatusMvEstatusSolicitudCambioTelefonoAutorizado(
            DEFAULT_STATE.error
          )
        )
  );

export const createMvEstatusSolicitudCambioTelefonoAutorizadoAsyncThunk =
  createAsyncThunk(
    "personas/createMvEstatusSolicitudCambioTelefonoAutorizadoAsyncThunk",
    async (create: {
      serverDate: IServerDate;
      MvEstatusSolicitudCambioTelefonoAutorizado: IMvEstatusSolicitudCambioTelefonoAutorizado;
    }) =>
      await createMvEstatusSolicitudCambioTelefonoAutorizado(
        create.serverDate,
        create.MvEstatusSolicitudCambioTelefonoAutorizado
      )
        .then(async (result) => result)
        .catch(async (error) =>
          getEmptyStatusMvEstatusSolicitudCambioTelefonoAutorizado(
            DEFAULT_STATE.error
          )
        )
  );

export const deleteMvEstatusSolicitudCambioTelefonoAutorizadoAsyncThunk =
  createAsyncThunk(
    "personas/deleteMvEstatusSolicitudCambioTelefonoAutorizadoAsyncThunk",
    async (del: { id: number }) =>
      await deleteMvEstatusSolicitudCambioTelefonoAutorizado(del.id)
        .then(async (result) => result)
        .catch(async (error) =>
          getEmptyStatusMvEstatusSolicitudCambioTelefonoAutorizado()
        )
  );
