/**
 * Métodos AsyncThunk para ser usados en los reducers y
 * los resultados a las llamadas http puedan ser usados para
 * actualizar el state en redux
 */

import { createAsyncThunk } from "@reduxjs/toolkit";
import { DEFAULT_STATE } from "../../enums/default_state";
import { getMvEstatusAsistencia, getAllMvEstatusAsistencia, updateMvEstatusAsistencia, createMvEstatusAsistencia, deleteMvEstatusAsistencia } from "../../http/nomina";
import { getEmptyStatusMvEstatusAsistencia, IMvEstatusAsistencia } from "../../interfaces/nomina";
import { IServerDate } from "../../interfaces/time";

export const getMvEstatusAsistenciaAsyncThunk = createAsyncThunk(
  'nomina/getMvEstatusAsistenciaAsyncThunk',
  async (query : {serverDate : IServerDate, id: number}) => await getMvEstatusAsistencia(query.serverDate, query.id)
    .then(async (result) => result)
    .catch(async (error) => getEmptyStatusMvEstatusAsistencia(DEFAULT_STATE.error))  
);

export const getAllMvEstatusAsistenciaAsyncThunk = createAsyncThunk(
  'nomina/getAllMvEstatusAsistenciaAsyncThunk',
  async (queryAll : {serverDate : IServerDate}) => await getAllMvEstatusAsistencia(queryAll.serverDate)
    .then(async (result) => result)
    .catch(async (error) => getEmptyStatusMvEstatusAsistencia(DEFAULT_STATE.error))  
);

export const updateMvEstatusAsistenciaAsyncThunk = createAsyncThunk(
  'nomina/updateMvEstatusAsistenciaAsyncThunk',
  async (update: {serverDate : IServerDate, id: number, MvEstatusAsistencia: IMvEstatusAsistencia}) => await updateMvEstatusAsistencia(update.serverDate, update.id, update.MvEstatusAsistencia)
    .then(async (result) => result)
    .catch(async (error) => getEmptyStatusMvEstatusAsistencia(DEFAULT_STATE.error))  
);

export const createMvEstatusAsistenciaAsyncThunk = createAsyncThunk(
  'nomina/createMvEstatusAsistenciaAsyncThunk',
  async (create : {serverDate : IServerDate, MvEstatusAsistencia: IMvEstatusAsistencia}) => await createMvEstatusAsistencia(create.serverDate, create.MvEstatusAsistencia)
    .then(async (result) => result)
    .catch(async (error) => getEmptyStatusMvEstatusAsistencia(DEFAULT_STATE.error))  
);

export const deleteMvEstatusAsistenciaAsyncThunk = createAsyncThunk(
  'nomina/deleteMvEstatusAsistenciaAsyncThunk',
  async (del : {id : number}) => await deleteMvEstatusAsistencia(del.id)
    .then(async (result) => result)
    .catch(async (error) => getEmptyStatusMvEstatusAsistencia())  
);
