/** 
 * Componente que se encarga del elemento CambioEstatusVacaciones
 * @ICambioEstatusVacaciones es la interfaz que contiene los parámetos el elemento CambioEstatusVacaciones
 * @getEmptyCambioEstatusVacaciones  devuelve un objeto con los parámetros por defecto; @param {id}, opcional cuando no encuentra el objeto en el LocalSotrage
 * @IStatusCambioEstatusVacaciones es la interfaz para los estatus a llamadas a la API; @param {status}, opcional cuando queremos otro estado de inizialización de tipo [DEFAULT_STATE]
 * @getEmptyStatusCambioEstatusVacaciones devuelve un objeto con los parámetros por defecto,
 * @setCambioEstatusVacaciones devuelve un objeto de tipo [ICambioEstatusVacaciones] recibiendo @param {object} y @param {serverDate}, para verificar que los datos estén en el formato correcto y ajustarlos a la zona horaria actual
 * @getSerializableCambioEstatusVacaciones recibe un @param {ICambioEstatusVacaciones}, para devolver un objeto serizalizable para las peticiones de la API
 * */

import { isNotEmpty, getDateString, SERVER_TIME_ZONE } from "../../api/helpers";
import { DEFAULT_STATE } from "../../enums/default_state";
import { getStorageMvEstatusAsistenciaArrayItem, getStorageVacacionesArrayItem } from "../../localStorage/nomina";
import { getStorageClienteQentryArrayItem, getStorageUserArrayItem } from "../../localStorage/personas";
import { IUser, getEmptyUser, setUser, IClienteQentry, getEmptyClienteQentry, setClienteQentry } from "../personas";
import { ILocalStorageExpiry, getEmptyLocalStorageExpiry, IServerDate, convertDateToZonaHoraria } from "../time";
import { IMvEstatusAsistencia, getEmptyMvEstatusAsistencia, setMvEstatusAsistencia } from "./MvEstatusAsistencia";
import { getEmptyVacaciones, IVacaciones, setVacaciones } from "./Vacaciones";

export interface ICambioEstatusVacaciones {
  id : number,
  vacaciones : IVacaciones,
  nuevo_estatus : IMvEstatusAsistencia,
  autor : IUser,
  fecha_elaboracion : string, // Fecha
  estatus_sistema : boolean,
  ultima_actualizacion : string, // Fecha
  cliente_qentry : IClienteQentry,
}

export const getEmptyCambioEstatusVacaciones = (id? : number) => {
  const temp : ICambioEstatusVacaciones = 
  {
    id : id ? id : 0,
    vacaciones : getEmptyVacaciones(),
    nuevo_estatus : getEmptyMvEstatusAsistencia(),
    autor : getEmptyUser(),
    fecha_elaboracion : "",
    estatus_sistema : false,
    ultima_actualizacion : "",
    cliente_qentry : getEmptyClienteQentry(),
  };
  return temp;
}

export interface IStatusCambioEstatusVacaciones {
  estado : DEFAULT_STATE,
  mensaje : string,
  data : ICambioEstatusVacaciones,
  data_array: ICambioEstatusVacaciones[],
  expiry : ILocalStorageExpiry,
  expiry_array : ILocalStorageExpiry,
}

export const getEmptyStatusCambioEstatusVacaciones = (status: DEFAULT_STATE = DEFAULT_STATE.loading) => {
  const temp : IStatusCambioEstatusVacaciones = 
  {
    estado : status,
    mensaje : "",
    data : getEmptyCambioEstatusVacaciones(),
    data_array : [],
    expiry : getEmptyLocalStorageExpiry("ICambioEstatusVacaciones", 0),
    expiry_array : getEmptyLocalStorageExpiry("ICambioEstatusVacacionesArray", 0),
  };
  return temp;
}

export const setCambioEstatusVacaciones = async (
  object : any,
  serverDate : IServerDate,
) => {
  const temp = getEmptyCambioEstatusVacaciones();
  if (isNotEmpty(object.id)) temp.id = object.id;
  if (isNotEmpty(object.vacaciones)) temp.vacaciones = (typeof object.vacaciones === 'number') ? await getStorageVacacionesArrayItem(object.vacaciones) : await setVacaciones(object.vacaciones, serverDate);
  if (isNotEmpty(object.nuevo_estatus)) temp.nuevo_estatus = (typeof object.nuevo_estatus === 'number') ? await getStorageMvEstatusAsistenciaArrayItem(object.nuevo_estatus) : await setMvEstatusAsistencia(object.nuevo_estatus, serverDate);
  if (isNotEmpty(object.autor)) temp.autor = (typeof object.autor === 'number') ? await getStorageUserArrayItem(object.autor) : await setUser(object.autor, serverDate);
  if (isNotEmpty(object.fecha_elaboracion)) temp.fecha_elaboracion = convertDateToZonaHoraria(serverDate, object.fecha_elaboracion);
  if (isNotEmpty(object.estatus_sistema)) temp.estatus_sistema = object.estatus_sistema;
  if (isNotEmpty(object.ultima_actualizacion)) temp.ultima_actualizacion = convertDateToZonaHoraria(serverDate, object.ultima_actualizacion);
  if (isNotEmpty(object.cliente_qentry)) temp.cliente_qentry = (typeof object.cliente_qentry === 'number') ? await getStorageClienteQentryArrayItem(object.cliente_qentry) : await setClienteQentry(object.cliente_qentry, serverDate);
  return temp;
}

export const getSerializableCambioEstatusVacaciones = (ICambioEstatusVacaciones : ICambioEstatusVacaciones ) => {
  return {
    id : ICambioEstatusVacaciones.id,
    vacaciones : ICambioEstatusVacaciones.vacaciones.id,
    nuevo_estatus : ICambioEstatusVacaciones.nuevo_estatus.id,
    autor : ICambioEstatusVacaciones.autor.id,
    fecha_elaboracion : ICambioEstatusVacaciones.fecha_elaboracion,
    estatus_sistema : ICambioEstatusVacaciones.estatus_sistema,
    ultima_actualizacion : getDateString(SERVER_TIME_ZONE),
    cliente_qentry : ICambioEstatusVacaciones.cliente_qentry.id,
  };
}
