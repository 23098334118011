/** 
 * Componente que se encarga del elemento CanalVenta
 * @ICanalVenta es la interfaz que contiene los parámetos el elemento CanalVenta
 * @getEmptyCanalVenta  devuelve un objeto con los parámetros por defecto; @param {id}, opcional cuando no encuentra el objeto en el LocalSotrage
 * @IStatusCanalVenta es la interfaz para los estatus a llamadas a la API; @param {status}, opcional cuando queremos otro estado de inizialización de tipo [DEFAULT_STATE]
 * @getEmptyStatusCanalVenta devuelve un objeto con los parámetros por defecto,
 * @setCanalVenta devuelve un objeto de tipo [ICanalVenta] recibiendo @param {object} y @param {serverDate}, para verificar que los datos estén en el formato correcto y ajustarlos a la zona horaria actual
 * @getSerializableCanalVenta recibe un @param {ICanalVenta}, para devolver un objeto serizalizable para las peticiones de la API
 * */

import { getDateString, isNotEmpty, SERVER_TIME_ZONE } from "../../api/helpers";
import { DEFAULT_STATE } from "../../enums/default_state";
import { getStorageMvTipoCanalVentaArrayItem, getStorageUserArrayItem, getStoragePaisArrayItem } from "../../localStorage/personas";
import { convertDateToZonaHoraria, ILocalStorageExpiry, getEmptyLocalStorageExpiry, IServerDate } from "../time";
import { IMvTipoCanalVenta, getEmptyMvTipoCanalVenta, setMvTipoCanalVenta } from "./IMvTipoCanalVenta";
import { IPais, getEmptyPais, setPais } from "./IPais";
import { IUser, getEmptyUser, setUser } from "./IUser";

export interface ICanalVenta {
  id : number,
  nombre_canal : string,
  tipo_canal : IMvTipoCanalVenta,
  autor : IUser,
  fecha_elaboracion : string, // Fecha
  estatus_sistema : boolean,
  ultima_actualizacion : string, // Fecha
  pais : IPais,
}

export const getEmptyCanalVenta = (id? : number) => {
  const temp : ICanalVenta = 
  {
    id : id ? id : 0,
    nombre_canal : "",
    tipo_canal : getEmptyMvTipoCanalVenta(),
    autor : getEmptyUser(),
    fecha_elaboracion : "",
    estatus_sistema : false,
    ultima_actualizacion : "",
    pais : getEmptyPais(),
  };
  return temp;
}

export interface IStatusCanalVenta {
  estado : DEFAULT_STATE,
  mensaje : string,
  data : ICanalVenta,
  data_array: ICanalVenta[],
  expiry : ILocalStorageExpiry,
  expiry_array : ILocalStorageExpiry,
}

export const getEmptyStatusCanalVenta = (status: DEFAULT_STATE = DEFAULT_STATE.loading) => {
  const temp : IStatusCanalVenta = 
  {
    estado : status,
    mensaje : "",
    data : getEmptyCanalVenta(),
    data_array : [],
    expiry : getEmptyLocalStorageExpiry("ICanalVenta", 0),
    expiry_array : getEmptyLocalStorageExpiry("ICanalVentaArray", 0),
  };
  return temp;
}

export const setCanalVenta = async (
  object : any,
  serverDate : IServerDate,
) => {
  const temp = getEmptyCanalVenta();
  if (isNotEmpty(object.id)) temp.id = object.id;
  if (isNotEmpty(object.nombre_canal)) temp.nombre_canal = object.nombre_canal;
  if (isNotEmpty(object.tipo_canal)) temp.tipo_canal = (typeof object.tipo_canal === 'number') ? await getStorageMvTipoCanalVentaArrayItem(object.tipo_canal) : await setMvTipoCanalVenta(object.tipo_canal, serverDate);
  if (isNotEmpty(object.autor)) temp.autor = (typeof object.autor === 'number') ? await getStorageUserArrayItem(object.autor) : await setUser(object.autor, serverDate);
  if (isNotEmpty(object.fecha_elaboracion)) temp.fecha_elaboracion = convertDateToZonaHoraria(serverDate, object.fecha_elaboracion);
  if (isNotEmpty(object.estatus_sistema)) temp.estatus_sistema = object.estatus_sistema;
  if (isNotEmpty(object.ultima_actualizacion)) temp.ultima_actualizacion = convertDateToZonaHoraria(serverDate, object.ultima_actualizacion);
  if (isNotEmpty(object.pais)) temp.pais = (typeof object.pais === 'number') ? await getStoragePaisArrayItem(object.pais) : await setPais(object.pais, serverDate);
  return temp;
}

export const getSerializableCanalVenta = (ICanalVenta : ICanalVenta) => {
  return {
    id : ICanalVenta.id,
    nombre_canal : ICanalVenta.nombre_canal,
    tipo_canal : ICanalVenta.tipo_canal.id,
    autor : ICanalVenta.autor.id,
    fecha_elaboracion : ICanalVenta.fecha_elaboracion,
    estatus_sistema : ICanalVenta.estatus_sistema,
    ultima_actualizacion : getDateString(SERVER_TIME_ZONE),
    pais : ICanalVenta.pais.id > 0 ? ICanalVenta.pais.id : null,
  };
}
