/**
 * Métodos AsyncThunk para ser usados en los reducers y
 * los resultados a las llamadas http puedan ser usados para
 * actualizar el state en redux
 */

import { createAsyncThunk } from "@reduxjs/toolkit";
import { DEFAULT_STATE } from "../../enums/default_state";
import { getMvTipoSuscripcion, getAllMvTipoSuscripcion, updateMvTipoSuscripcion, createMvTipoSuscripcion, deleteMvTipoSuscripcion } from "../../http/personas";
import { getEmptyStatusMvTipoSuscripcion, IMvTipoSuscripcion } from "../../interfaces/personas";
import { IServerDate } from "../../interfaces/time";

export const getMvTipoSuscripcionAsyncThunk = createAsyncThunk(
  'personas/getMvTipoSuscripcionAsyncThunk',
  async (query : {serverDate : IServerDate, id: number}) => await getMvTipoSuscripcion(query.serverDate, query.id)
    .then(async (result) => result)
    .catch(async (error) => getEmptyStatusMvTipoSuscripcion(DEFAULT_STATE.error))  
);

export const getAllMvTipoSuscripcionAsyncThunk = createAsyncThunk(
  'personas/getAllMvTipoSuscripcionAsyncThunk',
  async (queryAll : {serverDate : IServerDate}) => await getAllMvTipoSuscripcion(queryAll.serverDate)
    .then(async (result) => result)
    .catch(async (error) => getEmptyStatusMvTipoSuscripcion(DEFAULT_STATE.error))  
);

export const updateMvTipoSuscripcionAsyncThunk = createAsyncThunk(
  'personas/updateMvTipoSuscripcionAsyncThunk',
  async (update: {serverDate : IServerDate, id: number, MvTipoSuscripcion: IMvTipoSuscripcion}) => await updateMvTipoSuscripcion(update.serverDate, update.id, update.MvTipoSuscripcion)
    .then(async (result) => result)
    .catch(async (error) => getEmptyStatusMvTipoSuscripcion(DEFAULT_STATE.error))  
);

export const createMvTipoSuscripcionAsyncThunk = createAsyncThunk(
  'personas/createMvTipoSuscripcionAsyncThunk',
  async (create : {serverDate : IServerDate, MvTipoSuscripcion: IMvTipoSuscripcion}) => await createMvTipoSuscripcion(create.serverDate, create.MvTipoSuscripcion)
    .then(async (result) => result)
    .catch(async (error) => getEmptyStatusMvTipoSuscripcion(DEFAULT_STATE.error))  
);

export const deleteMvTipoSuscripcionAsyncThunk = createAsyncThunk(
  'personas/deleteMvTipoSuscripcionAsyncThunk',
  async (del : {id : number}) => await deleteMvTipoSuscripcion(del.id)
    .then(async (result) => result)
    .catch(async (error) => getEmptyStatusMvTipoSuscripcion())  
);
