/**
 * Métodos AsyncThunk para ser usados en los reducers y
 * los resultados a las llamadas http puedan ser usados para
 * actualizar el state en redux
 */

import { createAsyncThunk } from "@reduxjs/toolkit";
import { DEFAULT_STATE } from "../../enums/default_state";
import { getAllClienteQentryRondin, iniciarRondin, nodoIntermedio, finalizarRondin, cancelarRondin, rondinesEmpleadoFecha, rondinesCancelados, rondinesCompletados, rondinesCanceladosEmpleado, ultimoCheck } from "../../http/rondines";
import { getEmptyStatusRondin, IMovimientoRondin, getEmptyStatusMovimientoRondin, ICancelarRondin, getEmptyStatusCancelarRondin, IRondinesEmpleadoFecha, getEmptyStatusRondinesEmpleadoFecha, IHistorialRondinesCancelados, getEmptyStatusHistorialRondinesCancelados, IHistorialRondinesCompletados, getEmptyStatusHistorialRondinesCompletados, IHistorialRondinesCanceladosEmpleado, getEmptyStatusHistorialRondinesCanceladosEmpleado, IUltimoCheck, getEmptyStatusUltimoCheck } from "../../interfaces/rondines";
import { IServerDate } from "../../interfaces/time";

export const getAllClienteQentryRondinAsyncThunk = createAsyncThunk(
  'rondines/getAllClienteQentryRondinAsyncThunk',
  async (getAll : {serverDate : IServerDate, idCliente: number}) => await getAllClienteQentryRondin(getAll.serverDate, getAll.idCliente)
    .then(async (result) => result)
    .catch(async (error) => getEmptyStatusRondin(DEFAULT_STATE.error))  
);


export const iniciarRondinAsyncThunk = createAsyncThunk(
  'rondines/iniciarRondinAsyncThunk',
  async (update : {object : IMovimientoRondin}) => await iniciarRondin(update.object)
    .then(async (result) => result)
    .catch(async (error) => getEmptyStatusMovimientoRondin(DEFAULT_STATE.error))  
);


export const nodoIntermedioAsyncThunk = createAsyncThunk(
  'rondines/nodoIntermedioAsyncThunk',
  async (update : {object : IMovimientoRondin}) => await nodoIntermedio(update.object)
    .then(async (result) => result)
    .catch(async (error) => getEmptyStatusMovimientoRondin(DEFAULT_STATE.error))  
);


export const finalizarRondinAsyncThunk = createAsyncThunk(
  'rondines/finalizarRondinAsyncThunk',
  async (update : {object : IMovimientoRondin}) => await finalizarRondin(update.object)
    .then(async (result) => result)
    .catch(async (error) => getEmptyStatusMovimientoRondin(DEFAULT_STATE.error))  
);


export const cancelarRondinAsyncThunk = createAsyncThunk(
  'rondines/cancelarRondinAsyncThunk',
  async (update : {object : ICancelarRondin}) => await cancelarRondin(update.object)
    .then(async (result) => result)
    .catch(async (error) => getEmptyStatusCancelarRondin(DEFAULT_STATE.error))  
);


export const rondinesEmpleadoFechaAsyncThunk = createAsyncThunk(
  'rondines/rondinesEmpleadoFechaAsyncThunk',
  async (get : {object : IRondinesEmpleadoFecha}) => await rondinesEmpleadoFecha(get.object)
    .then(async (result) => result)
    .catch(async (error) => getEmptyStatusRondinesEmpleadoFecha(DEFAULT_STATE.error))  
);


export const rondinesCanceladosAsyncThunk = createAsyncThunk(
  'rondines/rondinesCanceladosAsyncThunk',
  async (get : {object : IHistorialRondinesCancelados}) => await rondinesCancelados(get.object)
    .then(async (result) => result)
    .catch(async (error) => getEmptyStatusHistorialRondinesCancelados(DEFAULT_STATE.error))  
);


export const rondinesCompletadosAsyncThunk = createAsyncThunk(
  'rondines/rondinesCompletadosAsyncThunk',
  async (get : {object : IHistorialRondinesCompletados}) => await rondinesCompletados(get.object)
    .then(async (result) => result)
    .catch(async (error) => getEmptyStatusHistorialRondinesCompletados(DEFAULT_STATE.error))  
);


export const rondinesCanceladosEmpleadoAsyncThunk = createAsyncThunk(
  'rondines/rondinesCanceladosEmpleadoAsyncThunk',
  async (get : {object : IHistorialRondinesCanceladosEmpleado}) => await rondinesCanceladosEmpleado(get.object)
    .then(async (result) => result)
    .catch(async (error) => getEmptyStatusHistorialRondinesCanceladosEmpleado(DEFAULT_STATE.error))  
);


export const ultimoCheckAsyncThunk = createAsyncThunk(
  'rondines/ultimoCheckAsyncThunk',
  async (get : {object : IUltimoCheck}) => await ultimoCheck(get.object)
    .then(async (result) => result)
    .catch(async (error) => getEmptyStatusUltimoCheck(DEFAULT_STATE.error))  
);
