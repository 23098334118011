/**
 * Es el compoente encargado de crear @IonSelect dentro de un @IonItem para selección individual o multiple con valores de tipo @interface List
 * @interface SelectorProps
 *   @param {boolean} multiple: define si la selección es multiple o no
 *   @param {string} label: es el valor del label del placeholder del select
 *   @param {any} value: es el valor de estado y visualización del @IonSelect
 *   @param {List[]} list: es la lista de opciones del select
 *   @param {boolean} multiple: define si la selección es multiple o no
 *   @param {ActionCreatorWithPayload<List, any>} changeState: es usado cuando el selector usa los estados de Redux para modificar el/los elemento(s) seleccionado(s)
 *   @param {any} change: es usado cuando el selector usa los estados de React para modificar el/los elemento(s) seleccionado(s)
 *   @param {boolean} disabled: se usa para desabilitar la selección
 *   @param {string} classStyle: se usa para agregar estilos al selector en el @IonItem
 *   @param {boolean} clearLine: se usa para eliminar la linea que te genera el objeto @IonItem automáticamente
 *   @param {boolean} required: agrega un asterícos despues en el label para describirle al usuario que es un campo requerido
 *   @param {string} icon: agrega un icono al selector
 *   @param {string} color: cambia el color del estilo del selector ( @primary / @secondary / @medium / @light / @dark )
 */

import React from 'react';
import { IonIcon, IonItem, IonLabel, IonSelect, IonSelectOption, IonText } from '@ionic/react';  
import { useAppDispatch } from '../hooks/useRedux';
import { ActionCreatorWithPayload } from '@reduxjs/toolkit';
import { List } from '../redux/interfaces/helpers';  
import './Selector.css';

interface SelectorProps {
  multiple?: boolean;
  label?: string;
  value?: any;
  list: List[];
  changeState?: ActionCreatorWithPayload<List, any>;
  change?: any;
  disabled?: boolean;
  classStyle?: string;
  clearLine?: boolean;
  required?: boolean;
  icon?: string;
  color?: string;
}

const Selector: React.FC<SelectorProps> = ({ clearLine, multiple, label, value, list, changeState, change, disabled, classStyle, required, icon, color}) => {
  const dispatch = useAppDispatch(); 

  const compareWith = (o1: List, o2: List) => {
    return o1 && o2 ? o1.id === o2.id : o1 === o2;
  };  

  return (
    <IonItem
      color={color}
      lines={(clearLine) ? "none" : undefined}
      className={classStyle}>
      <IonLabel position="floating" class="select-label">{label} { required ? <IonText color="danger">*</IonText> : <></>}</IonLabel>
      {
        (icon) ? 
          <IonIcon slot="start" icon={icon} class="icon-input-margin"/>
          :
          <></>
      }
      {
        (!multiple) ? 
          <IonSelect
            color={color}
            value={value}
            interface={"action-sheet"}
            compareWith={compareWith}
            disabled={disabled}
            okText="Okay"
            cancelText="Cancelar"
            onIonChange={e => {
              // console.log("(3) Select(Run): ", changeState && (e.detail.value.id !== undefined && value.id !== e.detail.value.id));
              
              if (e.detail.value && change) change(e.detail.value as List);

              if (changeState && 
                (
                  e.detail.value &&
                  e.detail.value.id !== undefined &&
                  value.id !== e.detail.value.id)
              ) dispatch(changeState(e.detail.value as List));
            }}
          >
            {list.map(item => (
              <IonSelectOption key={item.id} value={item}>
                {item.title}
              </IonSelectOption>
            ))}
          </IonSelect>
          :
          <IonSelect
            color={color}
            value={value}
            multiple={true}
            compareWith={compareWith}
            disabled={disabled}
            okText="Okay"
            cancelText="Cancelar"
            onIonChange={e => {
               console.log("(1) Select(e.detail.value): ", e.detail.value as List[]);
              if (change) {
                change(e.detail.value as List[]);
              }
              
              if (changeState) dispatch(changeState(e.detail.value));
            }}
          >
            {list.map(item => (
              <IonSelectOption key={item.id} value={item}>
                {item.title}
              </IonSelectOption>
            ))}
          </IonSelect>
      }
    </IonItem>
  );
};

export default Selector;
