/**
 * Es el componente utilizado en la página Login
 * @function getLanguage obtiene el idioma del dispositivo
 * @function handleChangeCorreo cambia el valor de state del @email para recuperación de contraseña
 * @function handleSave envía el correo de recuperación de contraseña
 */

import { useParams } from 'react-router';
import React, {  useEffect, useState } from 'react';
import {
  IonButton,
  IonCol,
  IonContent,
  IonGrid,
  IonIcon,
  IonImg,
  IonInput,
  IonItem,
  IonLabel,
  IonPage,
  IonRow,
  useIonToast,
  useIonModal,
} from '@ionic/react';

import { loginByEmail } from '../redux/asyncThunk/auth';

import {
  mailSharp,
  keySharp,
} from 'ionicons/icons';

import PasswordModal from "../components/PasswordModal"
import {
  sendRecoveryEmail
} from "../storage/UserApi"


import useRedux, { useAppDispatch } from '../hooks/useRedux';

import {
  LoginMesages,
} from "../string/Messages";
import { Device } from '@capacitor/device';
import { validate } from 'email-validator';

const Login: React.FC = () => {
  const dispatch = useAppDispatch();
  const [{serverDate, auth}] = useRedux()
  const { accessKey } = useParams<{ accessKey: string; }>();

  const [present, dismiss] = useIonToast();
  let [lang, setLang] = useState<string>("es");
  const [logInEmail, setLogInEmail] = useState("");
  const [email, setEmailPassRecover] = useState<string>("");
  const [logInPassword, setLogInPassword] = useState("");

  const getLanguage = async () => {
    const info = await Device.getLanguageCode();
    const lang = info.value.split("-")[0];
    setLang(lang);
    return info;
  };

  useEffect(() => {
    getLanguage();
    if (auth.error) {
      present({
        message: auth.error.message,
        color: "danger",
        duration: 2500,
      });
    }
  }, []);

  function handleChangeCorreo(data: string) {
    setEmailPassRecover(data);;
  }
  function handleSave() {
    const currentEmail = validate(email)
    if (currentEmail){
      if (email) {
        sendRecoveryEmail(email)
          .then((res) => {
            present({
              message: 'El correo se envio con exito',
              color: "success",
              duration: 2500,
            });
            console.log('res', res)
            dismissModal();
          })
          .catch((err) => {
            present({
              message: 'El correo no pudo ser enviado',
              color: "danger",
              duration: 2500,
            });
            console.log('err', err);
            dismissModal();
          });
      }
    }
    else{
      present({
        message: 'El correo no es valido, intente nuevamente',
        color: "danger",
        duration: 2500,
      });
    }
    
  }

  const [presentModal, dismissModal] = useIonModal(PasswordModal, {
    email,
    onChangeCorreo: handleChangeCorreo,
    onSave: handleSave,
  });


  return (
    <IonPage >
      <IonContent>
        <IonGrid className='full-screen no-padding'>
          <IonRow className='full-screen'>
            <IonCol size="12" sizeMd='12' sizeLg='12' className='form'>
              <IonRow className="ion-justify-content-center">
                <IonCol size="12">
                  <IonImg src="../assets/img/nuevoLogoQentry.png" class="img-form" />
                </IonCol>
              </IonRow>
              <IonRow className="ion-justify-content-center">
                <IonCol size="10" >
                  <IonItem class="secondary-bg" lines="none">
                    <IonLabel color="medium">
                      <IonIcon icon={mailSharp}  color="medium"/>
                    </IonLabel>
                    <IonInput
                      class="input-secondary-bg"
                      required
                      autofocus={true}
                      autocorrect="on"
                      type="email"
                      placeholder={LoginMesages['EmailInput'][lang]}
                      value={logInEmail}
                      onIonChange={(event) => setLogInEmail((event.detail.value) ? event.detail.value : "")}
                    />
                  </IonItem>
                </IonCol>
              </IonRow>
              <IonRow className="ion-justify-content-center">
                <IonCol size="10" >
                  <IonItem class="secondary-bg" color="" lines="none">
                    <IonLabel color="medium">
                      <IonIcon icon={keySharp}  color="medium"/>
                    </IonLabel>
                    <IonInput
                      class="input-secondary-bg"
                      required
                      type="password"
                      placeholder={LoginMesages['PasswordInput'][lang]}
                      value={logInPassword}
                      onIonChange={(event) =>
                        setLogInPassword((event.detail.value) ? event.detail.value : "")
                      }
                    />
                  </IonItem>
                </IonCol>
              </IonRow>
              <IonRow className="ion-justify-content-center ion-text-center">
                <IonCol size="8">
                  <IonButton type="submit" expand="block" class="round-25" onClick={() => dispatch(loginByEmail({credentials: {email: logInEmail, password: logInPassword}, serverDate: serverDate.data}))
                    }>
                    {LoginMesages['LoginButton'][lang]}
                  </IonButton>
                </IonCol>
                <IonCol size="8">
                  <IonButton class="round-25"  expand="block" routerLink='/LoginPhone'>
                    {LoginMesages['ToPhoneLogin'][lang]}
                  </IonButton>
                </IonCol>
              </IonRow>

              <IonRow className="ion-justify-content-center ion-text-center">
                <IonCol size="10">
                  <IonLabel  color="medium">
                    {LoginMesages['PasswordChangeMessage'][lang]} &nbsp;
                  </IonLabel>
                  <IonLabel color="primary" onClick={() => presentModal({
                    cssClass: 'qr-modal',
                  })}>
                    {LoginMesages['PasswordChangeButton'][lang]}
                  </IonLabel>
                </IonCol>
              </IonRow>
              <IonLabel className='message'>
                Recuerda que un administrador creará tu cuenta y te llegarán los accesos a tu correo
              </IonLabel>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default Login;