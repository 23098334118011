/** 
 * Componente que se encarga del elemento MvRiesgoPuesto
 * @IMvRiesgoPuesto es la interfaz que contiene los parámetos el elemento MvRiesgoPuesto
 * @getEmptyMvRiesgoPuesto  devuelve un objeto con los parámetros por defecto; @param {id}, opcional cuando no encuentra el objeto en el LocalSotrage
 * @IStatusMvRiesgoPuesto es la interfaz para los estatus a llamadas a la API; @param {status}, opcional cuando queremos otro estado de inizialización de tipo [DEFAULT_STATE]
 * @getEmptyStatusMvRiesgoPuesto devuelve un objeto con los parámetros por defecto,
 * @setMvRiesgoPuesto devuelve un objeto de tipo [IMvRiesgoPuesto] recibiendo @param {object} y @param {serverDate}, para verificar que los datos estén en el formato correcto y ajustarlos a la zona horaria actual
 * @getSerializableMvRiesgoPuesto recibe un @param {IMvRiesgoPuesto}, para devolver un objeto serizalizable para las peticiones de la API
 * */

import { getDateString, isNotEmpty, SERVER_TIME_ZONE } from "../../api/helpers";
import { DEFAULT_STATE } from "../../enums/default_state";
import { getStoragePaisArrayItem } from "../../localStorage/personas";
import { getEmptyPais, IPais, setPais } from "../personas";
import { ILocalStorageExpiry, getEmptyLocalStorageExpiry, IServerDate, convertDateToZonaHoraria } from "../time";

export interface IMvRiesgoPuesto {
  id : number,
  dato : string,
  valor : string,
  fecha_elaboracion : string, // Fecha
  estatus_sistema : boolean,
  ultima_actualizacion : string, // Fecha
  pais : IPais,
}

export const getEmptyMvRiesgoPuesto = (id? : number) => {
  const temp : IMvRiesgoPuesto = 
  {
    id : id ? id : 0,
    dato : "",
    valor : "",
    fecha_elaboracion : "",
    estatus_sistema : false,
    ultima_actualizacion : "",
    pais : getEmptyPais(),
  };
  return temp;
}

export interface IStatusMvRiesgoPuesto {
  estado : DEFAULT_STATE,
  mensaje : string,
  data : IMvRiesgoPuesto,
  data_array: IMvRiesgoPuesto[],
  expiry : ILocalStorageExpiry,
  expiry_array : ILocalStorageExpiry,
}

export const getEmptyStatusMvRiesgoPuesto = (status: DEFAULT_STATE = DEFAULT_STATE.loading) => {
  const temp : IStatusMvRiesgoPuesto = 
  {
    estado : status,
    mensaje : "",
    data : getEmptyMvRiesgoPuesto(),
    data_array : [],
    expiry : getEmptyLocalStorageExpiry("IMvRiesgoPuesto", 0),
    expiry_array : getEmptyLocalStorageExpiry("IMvRiesgoPuestoArray", 0),
  };
  return temp;
}

export const setMvRiesgoPuesto = async (
  object : any,
  serverDate : IServerDate,
) => {
  const temp = getEmptyMvRiesgoPuesto();
  if (isNotEmpty(object.id)) temp.id = object.id;
  if (isNotEmpty(object.dato)) temp.dato = object.dato;
  if (isNotEmpty(object.valor)) temp.valor = object.valor;
  if (isNotEmpty(object.fecha_elaboracion)) temp.fecha_elaboracion = convertDateToZonaHoraria(serverDate, object.fecha_elaboracion);
  if (isNotEmpty(object.estatus_sistema)) temp.estatus_sistema = object.estatus_sistema;
  if (isNotEmpty(object.ultima_actualizacion)) temp.ultima_actualizacion = convertDateToZonaHoraria(serverDate, object.ultima_actualizacion);
  if (isNotEmpty(object.pais)) temp.pais = (typeof object.pais === 'number') ? await getStoragePaisArrayItem(object.pais) : await setPais(object.pais, serverDate);
  return temp;
}

export const getSerializableMvRiesgoPuesto = (IMvRiesgoPuesto : IMvRiesgoPuesto ) => {
  return {
    id : IMvRiesgoPuesto.id,
    dato : IMvRiesgoPuesto.dato,
    valor : IMvRiesgoPuesto.valor,
    fecha_elaboracion : IMvRiesgoPuesto.fecha_elaboracion,
    estatus_sistema : IMvRiesgoPuesto.estatus_sistema,
    ultima_actualizacion : getDateString(SERVER_TIME_ZONE),
    pais : IMvRiesgoPuesto.pais.id,
  };
}
