/**
 * Métodos del, get, post, put para llamadas a la API
 * @get     en este método primero consulta con @getFromLocalStorage si es necesario hacer la petición http sino se obtiene del LocalStorage;
 *          si hay un error en la petición http se busca el objeto en el LocalStorage sino lo encuentra devuelve un objeto vacío; si la
 *          petición se realiza correctamente actualiza el valor individual y en el array del objeto en el LocalStorage y devuelve el objeto
 *          obtenido del servidor.
 * @getAll  en este método primero consulta con @getFromLocalStorage si es necesario hacer la petición http sino se obtiene del LocalStorage;
 *          si hay un error en la petición http se busca el objeto array en el LocalStorage sino lo encuentra devuelve un array vacío; si la
 *          petición se realiza correctamente actualiza array en el LocalStorage y devuelve el array obtenido del servidor
 * @update  en este método después de realizar la petición http si falla devuelve el objeto que se iba a actualizar; si la petición se
 *          realiza correctamente actualiza el valor individual y en el array del objeto en el LocalStorage y devuelve el objeto obtenido del
 *          servidor.
 * @create  en este método después de realizar la petición http si falla devuelve el objeto que se iba a crear; si la petición se realiza
 *          correctamente actualiza el valor individual y en el array del objeto en el LocalStorage y devuelve el objeto obtenido del
 *          servidor.
 * @delete  en este método después de realizar la petición http si falla devuelve el objeto que se iba a eliminar; si la petición se realiza
 *          correctamente elimina el valor individual y en el objeto en el arrya del LocalStorage y devuelve un objeto vacio (Default)
 */

import { del, get, post, put } from "../../api/api";
import {
  getFromLocalStorage,
  updateLocalStorageExpiry,
} from "../../api/helpers";
import { removeArrayItemStorageData } from "../../api/storage";
import { DEFAULT_STATE } from "../../enums/default_state";
import {
  getEmptyStatusMvEstatusSolicitudCambioTelefonoAutorizado,
  setMvEstatusSolicitudCambioTelefonoAutorizado,
  IMvEstatusSolicitudCambioTelefonoAutorizado,
  getSerializableMvEstatusSolicitudCambioTelefonoAutorizado,
} from "../../interfaces/personas";
import { IServerDate } from "../../interfaces/time";
import {
  getStorageMvEstatusSolicitudCambioTelefonoAutorizadoArrayItem,
  setStorageMvEstatusSolicitudCambioTelefonoAutorizado,
  setStorageMvEstatusSolicitudCambioTelefonoAutorizadoArrayItem,
  getStorageMvEstatusSolicitudCambioTelefonoAutorizadoArray,
  setStorageMvEstatusSolicitudCambioTelefonoAutorizadoArray,
  removeStorageMvEstatusSolicitudCambioTelefonoAutorizado,
} from "../../localStorage/personas";

export const getMvEstatusSolicitudCambioTelefonoAutorizado = async (
  serverDate: IServerDate,
  pk: number
) => {
  const temp = getEmptyStatusMvEstatusSolicitudCambioTelefonoAutorizado();
  const doHttp = await getFromLocalStorage(serverDate, temp.expiry);
  temp.expiry = doHttp.storage;
  if (!doHttp.result) {
    console.log(
      `(localStorage) MvEstatusSolicitudCambioTelefonoAutorizado con pk: ${pk}, obtenido con éxito`
    );
    temp.estado = DEFAULT_STATE.success;
    temp.mensaje = `MvEstatusSolicitudCambioTelefonoAutorizado obtenido con éxito`;
    temp.data =
      await getStorageMvEstatusSolicitudCambioTelefonoAutorizadoArrayItem(pk);
    return temp;
  }
  const result = await get(
    `personas/estatus-solicitud-cambio-telefono-autorizado/${pk}`
  );
  const data = result.data;
  console.log(`MvEstatusSolicitudCambioTelefonoAutorizado data: `, data);
  if (!data.id || data.detail) {
    console.log(
      `Error al obtener MvEstatusSolicitudCambioTelefonoAutorizado con pk: ${pk}, ${data.detail}`
    );
    temp.estado = DEFAULT_STATE.error;
    temp.mensaje = `Error al obtener el MvEstatusSolicitudCambioTelefonoAutorizado: ${
      data.detail ?? "Hubo un problema al conectarse con el servidor"
    }`;
    temp.data =
      await getStorageMvEstatusSolicitudCambioTelefonoAutorizadoArrayItem(pk);
    return temp;
  }
  console.log(
    `MvEstatusSolicitudCambioTelefonoAutorizado con pk: ${pk}, obtenido con éxito`
  );
  temp.estado = DEFAULT_STATE.success;
  temp.mensaje = `MvEstatusSolicitudCambioTelefonoAutorizado obtenido con éxito`;
  temp.data = await setMvEstatusSolicitudCambioTelefonoAutorizado(
    data,
    serverDate
  );
  await setStorageMvEstatusSolicitudCambioTelefonoAutorizado(temp.data);
  temp.data_array =
    await setStorageMvEstatusSolicitudCambioTelefonoAutorizadoArrayItem(
      pk,
      temp.data
    );
  temp.expiry = await updateLocalStorageExpiry(serverDate, temp.expiry);
  return temp;
};

export const getAllMvEstatusSolicitudCambioTelefonoAutorizado = async (
  serverDate: IServerDate
) => {
  const temp = getEmptyStatusMvEstatusSolicitudCambioTelefonoAutorizado();
  const doHttp = await getFromLocalStorage(serverDate, temp.expiry_array);
  temp.expiry_array = doHttp.storage;
  if (!doHttp.result) {
    console.log(
      `(localStorage) Todos los MvEstatusSolicitudCambioTelefonoAutorizado obtenidos con éxito`
    );
    temp.estado = DEFAULT_STATE.success;
    temp.mensaje = `Todos los MvEstatusSolicitudCambioTelefonoAutorizado obtenidos con éxito`;
    temp.data_array =
      await getStorageMvEstatusSolicitudCambioTelefonoAutorizadoArray();
    return temp;
  }
  const result = await get(
    `personas/estatus-solicitud-cambio-telefono-autorizado`
  );
  const data = result.data;
  if (data.detail) {
    console.log(
      `Error al obtener la lista de MvEstatusSolicitudCambioTelefonoAutorizado, ${data.detail}`
    );
    temp.estado = DEFAULT_STATE.error;
    temp.mensaje = `Error al obtener la lista de MvEstatusSolicitudCambioTelefonoAutorizado: ${
      data.detail ?? "Hubo un problema al conectarse con el servidor"
    }`;
    temp.data_array =
      await getStorageMvEstatusSolicitudCambioTelefonoAutorizadoArray();
    return temp;
  }
  console.log(
    `Todos los MvEstatusSolicitudCambioTelefonoAutorizado obtenidos con éxito`
  );
  temp.estado = DEFAULT_STATE.success;
  temp.mensaje = `Todos MvEstatusSolicitudCambioTelefonoAutorizado obtenidos con éxito`;
  temp.data_array = await Promise.all(
    data.map(
      async (e: any) =>
        await setMvEstatusSolicitudCambioTelefonoAutorizado(e, serverDate)
    )
  );
  await setStorageMvEstatusSolicitudCambioTelefonoAutorizadoArray(
    temp.data_array
  );
  temp.expiry_array = await updateLocalStorageExpiry(
    serverDate,
    temp.expiry_array
  );
  return temp;
};

export const updateMvEstatusSolicitudCambioTelefonoAutorizado = async (
  serverDate: IServerDate,
  pk: number,
  object: IMvEstatusSolicitudCambioTelefonoAutorizado
) => {
  const temp = getEmptyStatusMvEstatusSolicitudCambioTelefonoAutorizado();
  const result = await put(
    `personas/estatus-solicitud-cambio-telefono-autorizado/${pk}`,
    getSerializableMvEstatusSolicitudCambioTelefonoAutorizado(object)
  );
  const data = result.data;
  if (!data.id || data.detail) {
    console.log(
      `Error al actualizar MvEstatusSolicitudCambioTelefonoAutorizado con pk: ${pk}, ${data.detail}`
    );
    temp.estado = DEFAULT_STATE.error;
    temp.mensaje = `Error al actualizar el MvEstatusSolicitudCambioTelefonoAutorizado: ${
      data.detail ?? "Hubo un problema al conectarse con el servidor"
    }`;
    temp.data = await setMvEstatusSolicitudCambioTelefonoAutorizado(
      object,
      serverDate
    );
    return temp;
  }
  console.log(
    `MvEstatusSolicitudCambioTelefonoAutorizado con pk: ${pk}, actualizado con éxito`
  );
  temp.estado = DEFAULT_STATE.success;
  temp.mensaje = `MvEstatusSolicitudCambioTelefonoAutorizado actualizado con éxito`;
  temp.data = await setMvEstatusSolicitudCambioTelefonoAutorizado(
    data,
    serverDate
  );
  await setStorageMvEstatusSolicitudCambioTelefonoAutorizado(temp.data);
  temp.data_array =
    await setStorageMvEstatusSolicitudCambioTelefonoAutorizadoArrayItem(
      temp.data.id,
      temp.data
    );
  return temp;
};

export const createMvEstatusSolicitudCambioTelefonoAutorizado = async (
  serverDate: IServerDate,
  object: IMvEstatusSolicitudCambioTelefonoAutorizado
) => {
  const temp = getEmptyStatusMvEstatusSolicitudCambioTelefonoAutorizado();
  const result = await post(
    `personas/estatus-solicitud-cambio-telefono-autorizado`,
    getSerializableMvEstatusSolicitudCambioTelefonoAutorizado(object)
  );
  const data = result.data;
  if (!data.id || data.detail) {
    console.log(
      `Error al crear MvEstatusSolicitudCambioTelefonoAutorizado con los datos ${object}, ${data.detail}`
    );
    temp.estado = DEFAULT_STATE.error;
    temp.mensaje = `Error al crear el MvEstatusSolicitudCambioTelefonoAutorizado: ${
      data.detail ?? "Hubo un problema al conectarse con el servidor"
    }`;
    temp.data = await setMvEstatusSolicitudCambioTelefonoAutorizado(
      object,
      serverDate
    );
    return temp;
  }
  console.log(`MvEstatusSolicitudCambioTelefonoAutorizado creado con éxito`);
  temp.estado = DEFAULT_STATE.success;
  temp.mensaje = `MvEstatusSolicitudCambioTelefonoAutorizado creado con éxito`;
  temp.data = await setMvEstatusSolicitudCambioTelefonoAutorizado(
    data,
    serverDate
  );
  await setStorageMvEstatusSolicitudCambioTelefonoAutorizado(temp.data);
  temp.data_array =
    await setStorageMvEstatusSolicitudCambioTelefonoAutorizadoArrayItem(
      temp.data.id,
      temp.data
    );
  return temp;
};

export const deleteMvEstatusSolicitudCambioTelefonoAutorizado = async (
  pk: number
) => {
  const temp = getEmptyStatusMvEstatusSolicitudCambioTelefonoAutorizado();
  const result = await del(
    `personas/estatus-solicitud-cambio-telefono-autorizado/${pk}`
  );
  const data = result.data;
  if (data.detail) {
    console.log(
      `Error al eliminar MvEstatusSolicitudCambioTelefonoAutorizado con pk: ${pk}, ${data.detail}`
    );
    temp.estado = DEFAULT_STATE.error;
    temp.mensaje = `Error al eliminar el datos fiscales: ${data.detail}`;
    temp.data =
      await getStorageMvEstatusSolicitudCambioTelefonoAutorizadoArrayItem(pk);
    temp.data_array =
      await getStorageMvEstatusSolicitudCambioTelefonoAutorizadoArray();
    return temp;
  }
  console.log(`MvEstatusSolicitudCambioTelefonoAutorizado eliminado con éxito`);
  temp.estado = DEFAULT_STATE.success;
  temp.mensaje = `MvEstatusSolicitudCambioTelefonoAutorizado eliminado con éxito`;
  await removeStorageMvEstatusSolicitudCambioTelefonoAutorizado();
  temp.data_array = await removeArrayItemStorageData(
    "IMvEstatusSolicitudCambioTelefonoAutorizado",
    pk,
    []
  );
  return temp;
};
