/**
 * Métodos AsyncThunk para ser usados en los reducers y
 * los resultados a las llamadas http puedan ser usados para
 * actualizar el state en redux
 */

import { createAsyncThunk } from "@reduxjs/toolkit";
import { DEFAULT_STATE } from "../../enums/default_state";
import { getMvEstatusVacaciones, getAllMvEstatusVacaciones, updateMvEstatusVacaciones, createMvEstatusVacaciones, deleteMvEstatusVacaciones } from "../../http/nomina";
import { getEmptyStatusMvEstatusVacaciones, IMvEstatusVacaciones } from "../../interfaces/nomina";
import { IServerDate } from "../../interfaces/time";

export const getMvEstatusVacacionesAsyncThunk = createAsyncThunk(
  'nomina/getMvEstatusVacacionesAsyncThunk',
  async (query : {serverDate : IServerDate, id: number}) => await getMvEstatusVacaciones(query.serverDate, query.id)
    .then(async (result) => result)
    .catch(async (error) => getEmptyStatusMvEstatusVacaciones(DEFAULT_STATE.error))  
);

export const getAllMvEstatusVacacionesAsyncThunk = createAsyncThunk(
  'nomina/getAllMvEstatusVacacionesAsyncThunk',
  async (queryAll : {serverDate : IServerDate}) => await getAllMvEstatusVacaciones(queryAll.serverDate)
    .then(async (result) => result)
    .catch(async (error) => getEmptyStatusMvEstatusVacaciones(DEFAULT_STATE.error))  
);

export const updateMvEstatusVacacionesAsyncThunk = createAsyncThunk(
  'nomina/updateMvEstatusVacacionesAsyncThunk',
  async (update: {serverDate : IServerDate, id: number, MvEstatusVacaciones: IMvEstatusVacaciones}) => await updateMvEstatusVacaciones(update.serverDate, update.id, update.MvEstatusVacaciones)
    .then(async (result) => result)
    .catch(async (error) => getEmptyStatusMvEstatusVacaciones(DEFAULT_STATE.error))  
);

export const createMvEstatusVacacionesAsyncThunk = createAsyncThunk(
  'nomina/createMvEstatusVacacionesAsyncThunk',
  async (create : {serverDate : IServerDate, MvEstatusVacaciones: IMvEstatusVacaciones}) => await createMvEstatusVacaciones(create.serverDate, create.MvEstatusVacaciones)
    .then(async (result) => result)
    .catch(async (error) => getEmptyStatusMvEstatusVacaciones(DEFAULT_STATE.error))  
);

export const deleteMvEstatusVacacionesAsyncThunk = createAsyncThunk(
  'nomina/deleteMvEstatusVacacionesAsyncThunk',
  async (del : {id : number}) => await deleteMvEstatusVacaciones(del.id)
    .then(async (result) => result)
    .catch(async (error) => getEmptyStatusMvEstatusVacaciones())  
);
