/**
 * Reducers creados para obtener asíncronamente 
 * los datos de los modelos y dependiendo del resultado
 * de la peticón actualice el state en redux o mande un mensaje de error
 */

import { PayloadAction } from "@reduxjs/toolkit";
import { getTabuladorHorasExtraAsyncThunk, getAllTabuladorHorasExtraAsyncThunk, updateTabuladorHorasExtraAsyncThunk, createTabuladorHorasExtraAsyncThunk, deleteTabuladorHorasExtraAsyncThunk } from "../../asyncThunk/nomina";
import { DEFAULT_STATE } from "../../enums/default_state";
import { IStatusTabuladorHorasExtra } from "../../interfaces/nomina";
import { nominaInitialState } from "../../slices/nominaSlice";

// Reducers: TabuladorHorasExtra
export const createTabuladorHorasExtraReducers = () => {
  const get = () => {
    var { pending, fulfilled, rejected } = getTabuladorHorasExtraAsyncThunk;
    return {
      [pending.type]: (state : typeof nominaInitialState) => {
        state.nominaState.tabuladorHorasExtra.estado = DEFAULT_STATE.loading;
      },
      [fulfilled.type]: (state : typeof nominaInitialState, action : PayloadAction<IStatusTabuladorHorasExtra>) => {
        const result = action.payload;
        result.expiry_array = state.nominaState.tabuladorHorasExtra.expiry_array;
        if (result.estado === DEFAULT_STATE.error) result.data_array = state.nominaState.tabuladorHorasExtra.data_array;
        state.nominaState.tabuladorHorasExtra = result;
      },
      [rejected.type]: (state : typeof nominaInitialState, action : PayloadAction<IStatusTabuladorHorasExtra>) => {
        state.nominaState.tabuladorHorasExtra = {
          ...state.nominaState.tabuladorHorasExtra,
          estado: action.payload.estado,
          mensaje: "Ocurrió un problema al realizar la solicitud",
        };
      },
    };
  };
  
  const getAll = () => {
    var { pending, fulfilled, rejected } = getAllTabuladorHorasExtraAsyncThunk;
    return {
      [pending.type]: (state : typeof nominaInitialState) => {
        state.nominaState.tabuladorHorasExtra.estado = DEFAULT_STATE.loading;
      },
      [fulfilled.type]: (state : typeof nominaInitialState, action : PayloadAction<IStatusTabuladorHorasExtra>) => {
        const result = action.payload;
        result.data = state.nominaState.tabuladorHorasExtra.data;
        result.expiry = state.nominaState.tabuladorHorasExtra.expiry;
        if (result.estado === DEFAULT_STATE.error) result.data_array = state.nominaState.tabuladorHorasExtra.data_array;
        state.nominaState.tabuladorHorasExtra = result;
      },
      [rejected.type]: (state : typeof nominaInitialState, action : PayloadAction<IStatusTabuladorHorasExtra>) => {
        state.nominaState.tabuladorHorasExtra = {
          ...state.nominaState.tabuladorHorasExtra,
          estado: action.payload.estado,
          mensaje: "Ocurrió un problema al realizar la solicitud",
        };
      },
    };
  };
  
  const update = () => {
    var { pending, fulfilled, rejected } = updateTabuladorHorasExtraAsyncThunk;
    return {
      [pending.type]: (state : typeof nominaInitialState) => {
        state.nominaState.tabuladorHorasExtra.estado = DEFAULT_STATE.loading;
      },
      [fulfilled.type]: (state : typeof nominaInitialState, action : PayloadAction<IStatusTabuladorHorasExtra>) => {
        const result: IStatusTabuladorHorasExtra = action.payload;
        result.expiry = state.nominaState.tabuladorHorasExtra.expiry;
        result.expiry_array = state.nominaState.tabuladorHorasExtra.expiry_array;
        if (result.estado === DEFAULT_STATE.error) result.data_array = state.nominaState.tabuladorHorasExtra.data_array;
        state.nominaState.tabuladorHorasExtra = result;
      },
      [rejected.type]: (state : typeof nominaInitialState, action : PayloadAction<IStatusTabuladorHorasExtra>) => {
        state.nominaState.tabuladorHorasExtra = {
          ...state.nominaState.tabuladorHorasExtra,
          estado: action.payload.estado,
          mensaje: "Ocurrió un problema al realizar la solicitud",
        };
      },
    };
  };

  const create = () => {
    var { pending, fulfilled, rejected } = createTabuladorHorasExtraAsyncThunk;
    return {
      [pending.type]: (state : typeof nominaInitialState) => {
        state.nominaState.tabuladorHorasExtra.estado = DEFAULT_STATE.loading;
      },
      [fulfilled.type]: (state : typeof nominaInitialState, action : PayloadAction<IStatusTabuladorHorasExtra>) => {
        const result = action.payload;
        result.expiry = state.nominaState.tabuladorHorasExtra.expiry;
        result.expiry_array = state.nominaState.tabuladorHorasExtra.expiry_array;
        if (result.estado === DEFAULT_STATE.error) result.data_array = state.nominaState.tabuladorHorasExtra.data_array;
        state.nominaState.tabuladorHorasExtra = result;
      },
      [rejected.type]: (state : typeof nominaInitialState, action : PayloadAction<IStatusTabuladorHorasExtra>) => {
        state.nominaState.tabuladorHorasExtra = {
          ...state.nominaState.tabuladorHorasExtra,
          estado: action.payload.estado,
          mensaje: "Ocurrió un problema al realizar la solicitud",
        };
      },
    };
  };
  
  const del = () => {
    var { pending, fulfilled, rejected } = deleteTabuladorHorasExtraAsyncThunk;
    return {
      [pending.type]: (state : typeof nominaInitialState) => {
        state.nominaState.tabuladorHorasExtra.estado = DEFAULT_STATE.loading;
      },
      [fulfilled.type]: (state : typeof nominaInitialState, action : PayloadAction<IStatusTabuladorHorasExtra>) => {
        const result: IStatusTabuladorHorasExtra = action.payload;
        result.expiry = state.nominaState.tabuladorHorasExtra.expiry;
        result.expiry_array = state.nominaState.tabuladorHorasExtra.expiry_array;
        if (result.estado === DEFAULT_STATE.error) result.data_array = state.nominaState.tabuladorHorasExtra.data_array;
        state.nominaState.tabuladorHorasExtra = result;
      },
      [rejected.type]: (state : typeof nominaInitialState, action : PayloadAction<IStatusTabuladorHorasExtra>) => {
        state.nominaState.tabuladorHorasExtra = {
          ...state.nominaState.tabuladorHorasExtra,
          estado: action.payload.estado,
          mensaje: "Ocurrió un problema al realizar la solicitud",
        };
      },
    };
  };

  return {
    ...get(),
    ...getAll(),
    ...update(),
    ...create(),
    ...del(),
  };
};
