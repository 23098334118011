/**
 * Métodos para el manejo de IonicStorage 
 * @setStorage              guarda un unico objeto al storage
 * @getStorage              obtiene el objeto guardado con @setStorage
 * @setStorageArray         guarda el array de objetos en el storage
 * @getStorageArray         obtiene el array de objetos guardado con @setStorageArray
 * @removeStorageArray      elimina el array de objetos guardado con @setStorageArray
 * @setStorageItemArray     agrega un elemento al array de objeto guardado con @setStorageArray
 * @getStorageItemArray     obtiene un elemento del array guardado con @setStorageArray buscandolo por su id 
 * @removeStorageItemArray  elimina un elemento del array guardado con @setStorageArray buscandolo por su id 
 */

import { setStorageData, getStorageData, removeStorageData, setArrayItemStorageData, getArrayItemStorageData, removeArrayItemStorageData } from "../../api/storage";
import { IImagen, getEmptyImagen } from "../../interfaces/personas";

export const setStorageImagen = async (IImagen : IImagen) => await setStorageData('IImagen', IImagen);
export const getStorageImagen = async () : Promise<IImagen> => await getStorageData('IImagen', getEmptyImagen());
export const removeStorageImagen = async () => await removeStorageData('IImagen');
export const setStorageImagenArray = async (IImagen : IImagen[]) => await setStorageData('IImagenArray', IImagen);
export const getStorageImagenArray = async () : Promise<IImagen[]> => await getStorageData('IImagenArray', [] as IImagen[]);
export const removeStorageImagenArray = async () => await removeStorageData('IImagenArray');
export const setStorageImagenArrayItem = async (id: number, IImagen : IImagen) : Promise<IImagen[]> => await setArrayItemStorageData('IImagenArray', id, IImagen, [] as IImagen[]);
export const getStorageImagenArrayItem = async (id: number) : Promise<IImagen> => await getArrayItemStorageData('IImagenArray', id, getEmptyImagen);
export const removeStorageImagenArrayItem = async (id: number) : Promise<IImagen[]> => await removeArrayItemStorageData('IImagenArray', id, [] as IImagen[]);
