/** 
 * Componente que se encarga del elemento Puesto
 * @IPuesto es la interfaz que contiene los parámetos el elemento Puesto
 * @getEmptyPuesto  devuelve un objeto con los parámetros por defecto; @param {id}, opcional cuando no encuentra el objeto en el LocalSotrage
 * @IStatusPuesto es la interfaz para los estatus a llamadas a la API; @param {status}, opcional cuando queremos otro estado de inizialización de tipo [DEFAULT_STATE]
 * @getEmptyStatusPuesto devuelve un objeto con los parámetros por defecto,
 * @setPuesto devuelve un objeto de tipo [IPuesto] recibiendo @param {object} y @param {serverDate}, para verificar que los datos estén en el formato correcto y ajustarlos a la zona horaria actual
 * @getSerializablePuesto recibe un @param {IPuesto}, para devolver un objeto serizalizable para las peticiones de la API
 * */

import { getDateString, isNotEmpty, SERVER_TIME_ZONE } from "../../api/helpers";
import { DEFAULT_STATE } from "../../enums/default_state";
import { getStorageMvRiesgoPuestoArrayItem } from "../../localStorage/nomina";
import { getStorageUserArrayItem, getStorageClienteQentryArrayItem, getStorageEmpresaArrayItem } from "../../localStorage/personas";
import { getEmptyClienteQentry, getEmptyEmpresa, getEmptyUser, IClienteQentry, IEmpresa, IUser, setClienteQentry, setEmpresa, setUser } from "../personas";
import { ILocalStorageExpiry, getEmptyLocalStorageExpiry, IServerDate, convertDateToZonaHoraria } from "../time";
import { getEmptyMvRiesgoPuesto, IMvRiesgoPuesto, setMvRiesgoPuesto } from "./MvRiesgoPuesto";

export interface IPuesto {
  id : number,
  nombre_puesto : string,
  empresa : IEmpresa,
  descripcion_puesto : string,
  riesgo_puesto : IMvRiesgoPuesto,
  autor : IUser,
  fecha_elaboracion : string, // Fecha
  estatus_sistema : boolean,
  ultima_actualizacion : string, // Fecha
  cliente_qentry : IClienteQentry,
}

export const getEmptyPuesto = (id? : number) => {
  const temp : IPuesto = 
  {
    id : id ? id : 0,
    nombre_puesto : "",
    empresa : getEmptyEmpresa(),
    descripcion_puesto : "",
    riesgo_puesto : getEmptyMvRiesgoPuesto(),
    autor : getEmptyUser(),
    fecha_elaboracion : "",
    estatus_sistema : false,
    ultima_actualizacion : "",
    cliente_qentry : getEmptyClienteQentry(),
  };
  return temp;
}

export interface IStatusPuesto {
  estado : DEFAULT_STATE,
  mensaje : string,
  data : IPuesto,
  data_array: IPuesto[],
  expiry : ILocalStorageExpiry,
  expiry_array : ILocalStorageExpiry,
}

export const getEmptyStatusPuesto = (status: DEFAULT_STATE = DEFAULT_STATE.loading) => {
  const temp : IStatusPuesto = 
  {
    estado : status,
    mensaje : "",
    data : getEmptyPuesto(),
    data_array : [],
    expiry : getEmptyLocalStorageExpiry("IPuesto", 0),
    expiry_array : getEmptyLocalStorageExpiry("IPuestoArray", 0),
  };
  return temp;
}

export const setPuesto = async (
  object : any,
  serverDate : IServerDate,
) => {
  const temp = getEmptyPuesto();
  if (isNotEmpty(object.id)) temp.id = object.id;
  if (isNotEmpty(object.nombre_puesto)) temp.nombre_puesto = object.nombre_puesto;
  if (isNotEmpty(object.empresa)) temp.empresa = (typeof object.empresa === 'number') ? await getStorageEmpresaArrayItem(object.empresa) : await setEmpresa(object.empresa, serverDate);
  if (isNotEmpty(object.descripcion_puesto)) temp.descripcion_puesto = object.descripcion_puesto;
  if (isNotEmpty(object.riesgo_puesto)) temp.riesgo_puesto = (typeof object.riesgo_puesto === 'number') ? await getStorageMvRiesgoPuestoArrayItem(object.riesgo_puesto) : await setMvRiesgoPuesto(object.riesgo_puesto, serverDate);
  if (isNotEmpty(object.autor)) temp.autor = (typeof object.autor === 'number') ? await getStorageUserArrayItem(object.autor) : await setUser(object.autor, serverDate);
  if (isNotEmpty(object.fecha_elaboracion)) temp.fecha_elaboracion = convertDateToZonaHoraria(serverDate, object.fecha_elaboracion);
  if (isNotEmpty(object.estatus_sistema)) temp.estatus_sistema = object.estatus_sistema;
  if (isNotEmpty(object.ultima_actualizacion)) temp.ultima_actualizacion = convertDateToZonaHoraria(serverDate, object.ultima_actualizacion);
  if (isNotEmpty(object.cliente_qentry)) temp.cliente_qentry = (typeof object.cliente_qentry === 'number') ? await getStorageClienteQentryArrayItem(object.cliente_qentry) : await setClienteQentry(object.cliente_qentry, serverDate);
  return temp;
}

export const getSerializablePuesto = (IPuesto : IPuesto ) => {
  return {
    id : IPuesto.id,
    nombre_puesto : IPuesto.nombre_puesto,
    empresa : IPuesto.empresa.id !== 0 ? IPuesto.empresa.id : null,
    descripcion_puesto : IPuesto.descripcion_puesto,
    riesgo_puesto : IPuesto.riesgo_puesto.id !== 0 ? IPuesto.riesgo_puesto.id : null,
    autor : IPuesto.autor.id,
    fecha_elaboracion : IPuesto.fecha_elaboracion,
    estatus_sistema : IPuesto.estatus_sistema,
    ultima_actualizacion : getDateString(SERVER_TIME_ZONE),
    cliente_qentry : IPuesto.cliente_qentry.id,
  };
}
