/**
 * Modal usados para la creación, edicion de los centros de trabajo/codigos qr
 * @EditCentroDeTrabajoModal
 *   @function disableAceptar verifica que los campos estén el formato correcto para su actualización
 *   @function onValidate habilita o desabilita el componente error en los @component {IonItem} dependiendo del valor de su input
 *   @function searchCP busca el CP escrito en @createCentroDeTrabajoCodigoPostal y devuele los resultados como un alert para seleccionar uno.
 *   @function inititalCP obtiene el @param {IStatusCodigoPostal} listaCP para guardar el dato en @createCentroDeTrabajoCodigoPostal y su id en @hiddenCodigoPostalId
 *   @function validate evía una cloud a @qentry3ValidatePhoneNumber para verificar el que formato sea el correcto
 *   @function updateCentroDeTrabajo acomoda los datos escritos en un objeto @ICodigoQr para la creación del centro de trabajo
 * @CreateCentroDeTrabajoModal
 *   @function disableAceptar verifica que los campos estén el formato correcto para su actualización
 *   @function onValidate habilita o desabilita el componente error en los @component {IonItem} dependiendo del valor de su input
 *   @function searchCP busca el CP escrito en @createCentroDeTrabajoCodigoPostal y devuele los resultados como un alert para seleccionar uno.
 *   @function validate evía una cloud a @qentry3ValidatePhoneNumber para verificar el que formato sea el correcto
 */

import { Status, Wrapper } from "@googlemaps/react-wrapper";
import {
  IonPage,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonButton,
  IonTitle,
  IonContent,
  IonItem,
  IonLabel,
  IonGrid,
  IonRow,
  IonCol,
  IonText,
  IonInput,
  useIonActionSheet,
  useIonAlert,
  IonIcon,
  IonSpinner,
  ModalOptions,
  IonModal,
  IonNote,
  useIonToast,
} from "@ionic/react";
import { business, documentText, flag, search } from "ionicons/icons";
import { useState, useRef, useEffect } from "react";
import { QRCode } from "react-qrcode-logo";
import useRedux, { useAppDispatch, useAppSelector } from "../hooks/useRedux";
import { getCodigoPostal, searchCodigoPostal } from "../redux/http/personas";
import { setListItem } from "../redux/interfaces/helpers";
import {
  ICodigoPostal,
  IStatusCodigoPostal,
  getEmptyCodigoPostal,
  setCodigoQr,
} from "../redux/interfaces/personas";
import {
  getMemoizedWorkCentersSelectedEstado,
  getMemoizedWorkCentersSelectedPais,
  ICreateCentroDeTrabajoData,
  setWorkCentersClaveCodigoModal,
} from "../redux/slices/pagesSlice/workCentersSlice";
import {
  getGlobalMemoizedEstadoAsSelectList,
  getGlobalMemoizedPaisAsSelectList,
} from "../redux/slices/personasSlice";
import { qentry3ValidatePhoneNumber } from "../storage/UserApi";
import Selector from "./Selector";
import Map from "./Map";
import Marker from "./Maker";
import { StatusIcon } from "./GoogleMapsV3";
import { sleep } from "../redux/api/helpers";

const EditCentroDeTrabajoModal: React.FC<{
  onDismiss: (data?: any, role?: string | undefined) => void;
}> = ({ onDismiss }) => {
  const dispatch = useAppDispatch();
  const [{ serverDate, estado, pais, editCentrosDeTrabajo }] = useRedux();
  const [presentToast, dismissToast] = useIonToast();
  const centroDeTrabajoModal = useAppSelector(
    (state) => state.workCenters.centroDeTrabajoModal
  );
  const claveCodigo = useAppSelector(
    (state) => state.workCenters.claveCodigoModal
  );
  const place = useAppSelector((state) => state.workCenters.placeModal);
  // Mapa
  const [searchText, setSearchText] = useState(""); // initial zoom
  const [zoom, setZoom] = useState(10); // initial zoom
  const [center, setCenter] = useState<google.maps.LatLngLiteral>({
    lat: 19.42847,
    lng: -99.12766,
  });
  const [places, setPlaces] = useState<google.maps.LatLng[]>([]);
  const [findPlace, setFindPlace] = useState<google.maps.Geocoder>(
    new google.maps.Geocoder()
  );
  const [mapLoading, setMapLoading] = useState(true);

  const render = (status: Status) => {
    if (status !== Status.LOADING) setMapLoading(false);
  };

  const postalCode = (place: google.maps.GeocoderResult) => {
    if (!place.address_components || place.address_components.length === 0)
      return "";
    const res = place.address_components.find((e) =>
      e.types.includes("postal_code")
    );
    if (!res) return "";
    return res.long_name;
  };

  const onClick = (e: google.maps.MapMouseEvent) => {
    // avoid directly mutating state
    setPlaces([e.latLng!]);
  };

  const onPlaceAdded = (e: google.maps.LatLng[]) => {
    setPlaces([...e]);
    findPlace.geocode({ location: e[0] }, (result) => {
      if (!result) {
        presentToast({
          message:
            "La ubicación marcada no tiene un código postal, por favor agréguelo manualmente",
          color: "warning",
          duration: 2500,
        });
        return;
      }
      findPlace.geocode(
        { placeId: result[0].place_id, componentRestrictions: null },
        (finalResult) => {
          if (!finalResult) {
            presentToast({
              message:
                "La ubicación marcada no tiene un código postal, por favor agréguelo manualmente",
              color: "warning",
              duration: 2500,
            });
            return;
          }
          const cp = postalCode(finalResult[0]);
          if (!cp) {
            presentToast({
              message:
                "La ubicación marcada no tiene un código postal, por favor agréguelo manualmente",
              color: "warning",
              duration: 2500,
            });
            return;
          }
          setEditCentroDeTrabajoCodigoPostal(cp);
          searchCP(false);
        }
      );
    });
  };

  const onPlacesDrag = (e: google.maps.MapMouseEvent) => {
    setPlaces([e.latLng!]);
    findPlace.geocode({ location: e.latLng }, (result) => {
      if (!result) {
        presentToast({
          message:
            "La ubicación marcada no tiene un código postal, por favor agréguelo manualmente",
          color: "warning",
          duration: 2500,
        });
        return;
      }
      findPlace.geocode(
        { placeId: result[0].place_id, componentRestrictions: null },
        (finalResult) => {
          if (!finalResult) {
            presentToast({
              message:
                "La ubicación marcada no tiene un código postal, por favor agréguelo manualmente",
              color: "warning",
              duration: 2500,
            });
            return;
          }
          console.log({ finalResult });
          const cp = postalCode(finalResult[0]);
          if (!cp) {
            presentToast({
              message:
                "La ubicación marcada no tiene un código postal, por favor agréguelo manualmente",
              color: "warning",
              duration: 2500,
            });
            return;
          }
          setEditCentroDeTrabajoCodigoPostal(cp);
          searchCP(false);
        }
      );
    });
  };

  const onIdle = (m: google.maps.Map) => {
    if (m.getZoom()) setZoom(m.getZoom()!);
    if (m.getCenter()) setCenter(m.getCenter()!.toJSON());
  };

  const selectedEmpresa = setListItem({
    id: centroDeTrabajoModal.centro_de_trabajo.empresa.id.toString(),
    title: centroDeTrabajoModal.centro_de_trabajo.empresa.nombre_empresa,
  });

  const defaultErrorMessages = [
    "Por favor un ingrese un nombre del centro de trabajo.",
    "Por favor un ingrese un numero válido.", // Distancia máxima
    "Por favor un ingrese un numero de contacto.",
    "Por favor un ingrese un código postal válido.",
    "Por favor un ingrese una calle.",
    "Por favor un ingrese un numero exterior.",
    "Por favor un ingrese una colonia.",
    "Por favor un ingrese una ciudad.",
  ];

  const [showErrorMessage, setShowErrorMessage] = useState<
    { estatus: boolean; msg: string }[]
  >(defaultErrorMessages.map((v) => ({ estatus: false, msg: v })));
  const [dataHasErrors, setDataHasErrors] = useState<boolean>(true);
  const [validCP, setValidCP] = useState<boolean>(true);
  const [loadingValidate, setLoadingValidate] = useState<boolean>(false);
  const [hiddenCodigoPostalId, setHiddenCodigoPostalId] = useState<number>(1);

  const estadoList = useAppSelector(getGlobalMemoizedEstadoAsSelectList);
  const estadoModalConfiguracionEstado = useAppSelector(
    getMemoizedWorkCentersSelectedEstado
  );
  const paisList = useAppSelector(getGlobalMemoizedPaisAsSelectList);
  const paisModalConfiguracionPais = useAppSelector(
    getMemoizedWorkCentersSelectedPais
  );

  const [editCentroDeTrabajoNombreCentro, setEditCentroDeTrabajoNombreCentro] =
    useState(centroDeTrabajoModal.centro_de_trabajo.nombre_centro);
  const [
    editCentroDeTrabajoDistanciaMaximaPermitida,
    setEditCentroDeTrabajoDistanciaMaximaPermitida,
  ] = useState(
    centroDeTrabajoModal.centro_de_trabajo.distancia_maxima_permitida.toString()
  );
  const [
    editCentroDeTrabajoNumeroDeContacto,
    setEditCentroDeTrabajoNumeroDeContacto,
  ] = useState(
    centroDeTrabajoModal.centro_de_trabajo.direccion.numero_contacto
  );
  const [editCentroDeTrabajoCodigoPostal, setEditCentroDeTrabajoCodigoPostal] =
    useState(
      centroDeTrabajoModal.centro_de_trabajo.direccion.codigo_postal
        .codigo_postal
    );
  const [editCentroDeTrabajoCalle, setEditCentroDeTrabajoNombreCalle] =
    useState(centroDeTrabajoModal.centro_de_trabajo.direccion.calle);
  const [
    editCentroDeTrabajoNumeroExterior,
    setEditCentroDeTrabajoNumeroExterior,
  ] = useState(
    centroDeTrabajoModal.centro_de_trabajo.direccion.numero_exterior
  );
  const [
    editCentroDeTrabajoNumeroInterior,
    setEditCentroDeTrabajoNumeroInterior,
  ] = useState(
    centroDeTrabajoModal.centro_de_trabajo.direccion.numero_interior
  );
  const [editCentroDeTrabajoColonia, setEditCentroDeTrabajoNombreColonia] =
    useState(centroDeTrabajoModal.centro_de_trabajo.direccion.colonia);
  const [editCentroDeTrabajoCiudad, setEditCentroDeTrabajoCiudad] = useState(
    centroDeTrabajoModal.centro_de_trabajo.direccion.ciudad
  );
  const [
    editCentroDeTrabajoDelegacionMunicipio,
    setEditCentroDeTrabajoDelegacionMunicipio,
  ] = useState(
    centroDeTrabajoModal.centro_de_trabajo.direccion.delegacion_municipio
  );
  const [editCentroDeTrabajoEstado, setEditCentroDeTrabajoEstado] = useState(
    estadoModalConfiguracionEstado
  );
  const [editCentroDeTrabajoPais, setEditCentroDeTrabajoPais] = useState(
    paisModalConfiguracionPais
  );

  const itemNombreCentro = useRef<HTMLIonItemElement>(null);
  const itemDistanciaMaximaPermitida = useRef<HTMLIonItemElement>(null);
  const itemNumeroDeContacto = useRef<HTMLIonItemElement>(null);
  const itemCodigoPostal = useRef<HTMLIonItemElement>(null);
  const itemCalle = useRef<HTMLIonItemElement>(null);
  const itemNumeroExterior = useRef<HTMLIonItemElement>(null);
  const itemColonia = useRef<HTMLIonItemElement>(null);
  const itemCiudad = useRef<HTMLIonItemElement>(null);

  const qrParent = useRef<HTMLIonColElement>(null);
  const [originalImage, setOriginalImage] = useState<HTMLCanvasElement>();

  const [qrCodeSize, setQrCodeSize] = useState<number>(260);

  const disableAceptar = () => {
    const notValid = showErrorMessage.some((v) => v.estatus);
    if (notValid) return true;
    const nombreCentroValid =
      editCentroDeTrabajoNombreCentro && editCentroDeTrabajoNombreCentro.trim();
    if (!nombreCentroValid) return true;
    const distanciaMaximaPermitidaValid =
      editCentroDeTrabajoDistanciaMaximaPermitida &&
      editCentroDeTrabajoDistanciaMaximaPermitida.trim();
    if (!distanciaMaximaPermitidaValid) return true;
    const numeroDeContactoValid =
      editCentroDeTrabajoNumeroDeContacto &&
      editCentroDeTrabajoNumeroDeContacto.trim();
    if (!numeroDeContactoValid) return true;
    const codigoPostalValid =
      editCentroDeTrabajoCodigoPostal && editCentroDeTrabajoCodigoPostal.trim();
    if (!codigoPostalValid) return true;
    const calleValid =
      editCentroDeTrabajoCalle && editCentroDeTrabajoCalle.trim();
    if (!calleValid) return true;
    const numeroExteriorValid =
      editCentroDeTrabajoNumeroExterior &&
      editCentroDeTrabajoNumeroExterior.trim();
    if (!numeroExteriorValid) return true;
    const coloniaValid =
      editCentroDeTrabajoColonia && editCentroDeTrabajoColonia.trim();
    if (!coloniaValid) return true;
    const ciudadValid =
      editCentroDeTrabajoCiudad && editCentroDeTrabajoCiudad.trim();
    if (!ciudadValid) return true;
    if (dataHasErrors || selectedEmpresa.id === "" || loadingValidate)
      return true;
    return false;
  };

  const onValidate = (id: number, value: any) => {
    if (!itemNombreCentro.current) return;
    if (!itemDistanciaMaximaPermitida.current) return;
    if (!itemNumeroDeContacto.current) return;
    if (!itemCodigoPostal.current) return;
    if (!itemCalle.current) return;
    if (!itemNumeroExterior.current) return;
    if (!itemColonia.current) return;
    if (!itemCiudad.current) return;

    switch (id) {
      case 0:
        const testItemNombreCentro = !/.+/.test(value.trim());
        if (testItemNombreCentro)
          itemNombreCentro.current.classList.add("error");
        else itemNombreCentro.current.classList.remove("error");
        setShowErrorMessage(
          showErrorMessage.map((e, i) => {
            if (i !== id) return e;
            return { estatus: testItemNombreCentro, msg: e.msg };
          })
        );
        break;
      case 1:
        const testItemDistanciaMaximaPermitida = !/[0-9]+/.test(value.trim());
        if (testItemDistanciaMaximaPermitida)
          itemDistanciaMaximaPermitida.current.classList.add("error");
        else itemDistanciaMaximaPermitida.current.classList.remove("error");
        setShowErrorMessage(
          showErrorMessage.map((e, i) => {
            if (i !== id) return e;
            return { estatus: testItemDistanciaMaximaPermitida, msg: e.msg };
          })
        );
        break;
      case 2:
        const testItemNumeroDeContacto =
          !/^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/.test(value.trim());
        if (testItemNumeroDeContacto)
          itemNumeroDeContacto.current.classList.add("error");
        else itemNumeroDeContacto.current.classList.remove("error");
        setShowErrorMessage(
          showErrorMessage.map((e, i) => {
            if (i !== id) return e;
            return { estatus: testItemNumeroDeContacto, msg: e.msg };
          })
        );
        break;
      case 3:
        setValidCP(false);
        const testItemCodigoPostal = !/[0-9]+/.test(value.trim());
        if (testItemCodigoPostal)
          itemCodigoPostal.current.classList.add("error");
        else itemCodigoPostal.current.classList.remove("error");
        setShowErrorMessage(
          showErrorMessage.map((e, i) => {
            if (i !== id) return e;
            return { estatus: testItemCodigoPostal, msg: e.msg };
          })
        );
        break;
      case 4:
        const testItemCalle = !/.+/.test(value.trim());
        if (testItemCalle) itemCalle.current.classList.add("error");
        else itemCalle.current.classList.remove("error");
        setShowErrorMessage(
          showErrorMessage.map((e, i) => {
            if (i !== id) return e;
            return { estatus: testItemCalle, msg: e.msg };
          })
        );
        break;
      case 5:
        const testItemNumeroExterior = !/.+/.test(value.trim());
        if (testItemNumeroExterior)
          itemNumeroExterior.current.classList.add("error");
        else itemNumeroExterior.current.classList.remove("error");
        setShowErrorMessage(
          showErrorMessage.map((e, i) => {
            if (i !== id) return e;
            return { estatus: testItemNumeroExterior, msg: e.msg };
          })
        );
        break;
      case 6:
        const testItemColonia = !/.+/.test(value.trim());
        if (testItemColonia) itemColonia.current.classList.add("error");
        else itemColonia.current.classList.remove("error");
        setShowErrorMessage(
          showErrorMessage.map((e, i) => {
            if (i !== id) return e;
            return { estatus: testItemColonia, msg: e.msg };
          })
        );
        break;
      case 7:
        const testItemCiudad = !/.+/.test(value.trim());
        if (testItemCiudad) itemCiudad.current.classList.add("error");
        else itemCiudad.current.classList.remove("error");
        setShowErrorMessage(
          showErrorMessage.map((e, i) => {
            if (i !== id) return e;
            return { estatus: testItemCiudad, msg: e.msg };
          })
        );
        break;
    }
  };

  const searchCP = async (autosearch = true) => {
    setValidCP(false);
    const listaCP = await searchCodigoPostal(editCentroDeTrabajoCodigoPostal);
    if (listaCP.length === 0) {
      return presentAlert({
        header: "Aviso",
        message: "No se encontró el código postal",
        buttons: ["OK"],
      });
    }
    return presentCodigoPostalList({
      buttons: [
        ...listaCP.map((v) => ({
          text: `${v.codigo_postal}, ${v.nombre_asentamiento}`,
          handler: () => {
            const estadoValue = estado.data_array.find(
              (e) => e.nombre === v.nombre_entidad
            )!;
            const paisValue = pais.data_array.find(
              (e) => e.nombre === "México"
            )!;

            setHiddenCodigoPostalId(v.id);
            setEditCentroDeTrabajoNombreColonia(v.nombre_asentamiento);
            setEditCentroDeTrabajoCiudad(v.nombre_ciudad);
            setEditCentroDeTrabajoDelegacionMunicipio(v.nombre_municipio);
            setEditCentroDeTrabajoEstado(
              setListItem({
                id: estadoValue.id.toString(),
                title: estadoValue.nombre,
              })
            );
            setEditCentroDeTrabajoPais(
              setListItem({
                id: paisValue.id.toString(),
                title: paisValue.nombre,
              })
            );
            if (autosearch) {
              setSearchText(
                `${editCentroDeTrabajoCodigoPostal} ${estadoValue.nombre} ${paisValue.nombre}`
              );
            }
            setValidCP(true);
          },
        })),
        { text: "Cancelar", role: "cancel" },
      ],
      header: "Seleccionar código postal",
    });
  };

  const inititalCP = async () => {
    setValidCP(false);
    const listaCP = await getCodigoPostal(
      serverDate.data,
      centroDeTrabajoModal.centro_de_trabajo.direccion.codigo_postal.id
    );
    if (listaCP.data.id !== 0) {
      setHiddenCodigoPostalId(listaCP.data.id);
      setValidCP(true);
      setEditCentroDeTrabajoCodigoPostal(listaCP.data.codigo_postal);
      setPlaces([
        new google.maps.LatLng(Number(place[0].lat), Number(place[0].lng)),
      ]);
      setCenter(place[0]);
      setZoom(20);
    }
  };

  const validate = async () => {
    setLoadingValidate(true);
    setDataHasErrors(false);

    const res = await qentry3ValidatePhoneNumber(
      editCentroDeTrabajoNumeroDeContacto
    );

    setLoadingValidate(false);
    if (res.data.hasError) {
      const temp = res.data.result;
      setDataHasErrors(res.data.hasError);
      setShowErrorMessage(
        showErrorMessage.map((e, i) => {
          if (i !== 2) return e;
          const index = temp.findIndex((v: any) => v.id === i);
          switch (i) {
            case 2:
              if (index !== -1)
                itemNumeroDeContacto.current?.classList.add("error");
              else itemNumeroDeContacto.current?.classList.remove("error");
              break;
          }
          if (index === -1) return e;
          return { estatus: true, msg: temp[0].msg };
        })
      );
    }
  };

  const [presentCodigoPostalList, dismissCodigoPostalList] =
    useIonActionSheet();
  const [presentAlert] = useIonAlert();

  useEffect(() => {
    inititalCP();
  }, []);

  useEffect(() => {
    dispatch(setWorkCentersClaveCodigoModal(centroDeTrabajoModal.clave_codigo));
  }, []);

  useEffect(() => {
    if (!qrParent.current) return;
    const canvas = document.getElementById(
      "react-qrcode-logo"
    ) as HTMLCanvasElement;
    if (canvas) {
      let context = canvas.getContext("2d");
      if (originalImage) {
        context!.fillStyle = "#ffffff";
        context!.fillRect(0, qrCodeSize + 30, canvas.width, 40);
      } else {
        setOriginalImage(canvas);
      }
      context!.lineWidth = 1;
      context!.fillStyle = "#cdde00";
      context!.font = "bolder 18px sans-serif";
      context!.textAlign = "center";
      context!.fillText(editCentroDeTrabajoNombreCentro, 150, qrCodeSize + 55);
    }
  }, [editCentroDeTrabajoNombreCentro, qrParent.current]);

  const updateCentroDeTrabajo = async () => {
    console.log("beforeCanvas");
    const canvas = document.getElementById(
      "react-qrcode-logo"
    ) as HTMLCanvasElement;

    console.log("canvas", canvas);
    const temp = {
      ...(await setCodigoQr(centroDeTrabajoModal, serverDate.data)),
    };
    temp.nombre_codigo = editCentroDeTrabajoNombreCentro;
    temp.centro_de_trabajo.nombre_centro = editCentroDeTrabajoNombreCentro;
    temp.centro_de_trabajo.distancia_maxima_permitida = Number(
      editCentroDeTrabajoDistanciaMaximaPermitida
    );
    temp.centro_de_trabajo.direccion.numero_contacto =
      editCentroDeTrabajoNumeroDeContacto;
    temp.centro_de_trabajo.direccion.codigo_postal.id = hiddenCodigoPostalId;
    temp.centro_de_trabajo.direccion.calle = editCentroDeTrabajoCalle;
    temp.centro_de_trabajo.direccion.numero_exterior =
      editCentroDeTrabajoNumeroExterior;
    temp.centro_de_trabajo.direccion.numero_interior =
      editCentroDeTrabajoNumeroInterior;
    temp.centro_de_trabajo.direccion.colonia = editCentroDeTrabajoColonia;
    temp.centro_de_trabajo.direccion.ciudad = editCentroDeTrabajoCiudad;
    temp.centro_de_trabajo.direccion.delegacion_municipio =
      editCentroDeTrabajoDelegacionMunicipio;
    temp.centro_de_trabajo.direccion.estado = estado.data_array.find(
      (e) => e.id.toString() === editCentroDeTrabajoEstado.id
    )!;
    temp.centro_de_trabajo.direccion.pais = pais.data_array.find(
      (e) => e.id.toString() === editCentroDeTrabajoPais.id
    )!;
    temp.centro_de_trabajo.direccion.geolocalizacion.latitud = places[0]
      .lat()
      .toString();
    temp.centro_de_trabajo.direccion.geolocalizacion.longitud = places[0]
      .lng()
      .toString();

    temp.geolocalizacion.latitud = places[0].lat().toString();
    temp.geolocalizacion.longitud = places[0].lng().toString();

    canvas.toBlob((blob) => {
      editCentrosDeTrabajo(temp, blob!);
    });
    onDismiss();
  };
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar color="secondary" className="toolBarStart">
          <IonButtons slot="start">
            <IonButton onClick={() => onDismiss()}>Cancelar</IonButton>
          </IonButtons>
          <IonTitle className="toolBarStart center">Centro de trabajo</IonTitle>
          <IonButtons slot="end">
            <IonButton
              strong={true}
              disabled={disableAceptar()}
              onClick={() => updateCentroDeTrabajo()}
            >
              Aceptar
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent className="ion-padding">
        {selectedEmpresa.id === "" ? (
          <IonItem lines="none" color="danger">
            <IonLabel>
              <h2>
                <b>Por favor seleccione una empresa</b>
              </h2>
            </IonLabel>
          </IonItem>
        ) : (
          <></>
        )}
        <IonItem lines="none" className="modal-input">
          <IonLabel>
            <h2>
              <b>Datos del centro de trabajo </b>
            </h2>
          </IonLabel>
        </IonItem>
        <IonGrid>
          <IonRow class="no-padding">
            <IonCol class="no-padding" id="qr-code-parent" ref={qrParent}>
              <QRCode
                value={claveCodigo}
                logoImage={"../assets/img/logosingle.png"}
                eyeRadius={5}
                ecLevel={"H"}
                qrStyle={"dots"}
                size={qrCodeSize}
                quietZone={30}
                logoWidth={qrCodeSize * 0.5}
                logoHeight={qrCodeSize * 0.5}
              />
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol size="12">
              <IonItem className="modal-input" ref={itemNombreCentro}>
                <IonIcon
                  slot="start"
                  icon={business}
                  class="icon-input-margin"
                />
                <IonLabel position="floating" className="modal-label">
                  Nombre del centro de trabajo
                  <IonText color="danger">*</IonText>
                </IonLabel>
                <IonInput
                  clearInput={true}
                  disabled={selectedEmpresa.id === ""}
                  inputmode="text"
                  type="text"
                  color="secondary"
                  value={editCentroDeTrabajoNombreCentro}
                  onIonChange={(e) => {
                    setEditCentroDeTrabajoNombreCentro(e.detail.value!);
                    onValidate(0, e.detail.value!);
                  }}
                />
              </IonItem>
              {showErrorMessage[0].estatus ? (
                <IonText class="input-error-message">
                  {showErrorMessage[0].msg}
                </IonText>
              ) : (
                <></>
              )}
            </IonCol>
            <IonCol size="12">
              <IonItem
                className="modal-input"
                ref={itemDistanciaMaximaPermitida}
              >
                <IonIcon
                  slot="start"
                  icon={business}
                  class="icon-input-margin"
                />
                <IonLabel position="floating" className="modal-label">
                  Distancia máxima para el registro (m)
                  <IonText color="danger">*</IonText>
                </IonLabel>
                <IonInput
                  clearInput={true}
                  disabled={selectedEmpresa.id === ""}
                  min={0}
                  inputmode="numeric"
                  type="number"
                  color="secondary"
                  value={editCentroDeTrabajoDistanciaMaximaPermitida}
                  onIonChange={(e) => {
                    setEditCentroDeTrabajoDistanciaMaximaPermitida(
                      e.detail.value!
                    );
                    onValidate(1, e.detail.value!);
                  }}
                />
              </IonItem>
              {showErrorMessage[1].estatus ? (
                <IonText class="input-error-message">
                  {showErrorMessage[1].msg}
                </IonText>
              ) : (
                <></>
              )}
            </IonCol>
            <IonCol size="12">
              <IonItem className="modal-input" ref={itemNumeroDeContacto}>
                <IonIcon
                  slot="start"
                  icon={business}
                  class="icon-input-margin"
                />
                <IonLabel position="floating" className="modal-label">
                  Número de contacto (celular)
                  <IonText color="danger">*</IonText>
                </IonLabel>
                <IonInput
                  clearInput={true}
                  disabled={selectedEmpresa.id === ""}
                  inputmode="tel"
                  type="tel"
                  color="secondary"
                  placeholder="+522215778772"
                  value={editCentroDeTrabajoNumeroDeContacto}
                  onIonChange={(e) => {
                    setEditCentroDeTrabajoNumeroDeContacto(e.detail.value!);
                    onValidate(2, e.detail.value!);
                  }}
                />
              </IonItem>
              {showErrorMessage[2].estatus ? (
                <IonText class="input-error-message">
                  {showErrorMessage[2].msg}
                </IonText>
              ) : (
                <></>
              )}
            </IonCol>
            <IonCol size="9">
              <IonItem className="modal-input" ref={itemCodigoPostal}>
                <IonIcon
                  slot="start"
                  icon={business}
                  class="icon-input-margin"
                />
                <IonLabel position="floating" className="modal-label">
                  Código Postal<IonText color="danger">*</IonText>
                </IonLabel>
                <IonInput
                  clearInput={true}
                  disabled={selectedEmpresa.id === ""}
                  inputmode="text"
                  type="text"
                  color="secondary"
                  value={editCentroDeTrabajoCodigoPostal}
                  onIonChange={(e) => {
                    setEditCentroDeTrabajoCodigoPostal(e.detail.value!);
                    setValidCP(false);
                    onValidate(3, e.detail.value!);
                  }}
                />
              </IonItem>
              {showErrorMessage[3].estatus ? (
                <IonText class="input-error-message">
                  {showErrorMessage[3].msg}
                </IonText>
              ) : (
                <></>
              )}
            </IonCol>
            <IonCol size="3">
              <IonButton expand="block" size="large" onClick={() => searchCP()}>
                <IonIcon slot="icon-only" icon={search} />
              </IonButton>
            </IonCol>
            <IonCol size="12">
              <div
                style={{
                  display: "flex",
                  height: "300px",
                  flexDirection: "column",
                }}
              >
                <Wrapper
                  apiKey={process.env.REACT_APP_GOOGLE_MAPS_KEY!}
                  libraries={["places"]}
                  callback={render}
                >
                  <Map
                    center={center}
                    onClick={onClick}
                    onIdle={onIdle}
                    onPlaceAdded={onPlaceAdded}
                    zoom={zoom}
                    hasSearchBox={false}
                    searchText={searchText}
                    style={{ flexGrow: "1", height: "100%" }}
                  >
                    {places.map((place, i) => (
                      <Marker
                        key={`nueva-dirección-${i}`}
                        icon={StatusIcon[3]}
                        position={place}
                        content={"Dirección"}
                        clickable={true}
                        draggable={true}
                        onDrag={onPlacesDrag}
                      />
                    ))}
                  </Map>
                </Wrapper>
              </div>
              <IonNote>
                <IonText color="danger">**</IonText>Si la ubicación no se
                detecta automáticamente al buscar el código postal por favor
                seleccione un punto en el mapa.
              </IonNote>
            </IonCol>
            <IonCol size="12" sizeMd="6" sizeLg="6" sizeXl="6">
              <IonItem className="modal-input" ref={itemCalle}>
                <IonIcon
                  slot="start"
                  icon={business}
                  class="icon-input-margin"
                />
                <IonLabel position="floating" className="modal-label">
                  Calle<IonText color="danger">*</IonText>
                </IonLabel>
                <IonInput
                  clearInput={true}
                  disabled={selectedEmpresa.id === "" || !validCP}
                  inputmode="text"
                  type="text"
                  color="secondary"
                  value={editCentroDeTrabajoCalle}
                  onIonChange={(e) => {
                    setEditCentroDeTrabajoNombreCalle(e.detail.value!);
                    onValidate(4, e.detail.value!);
                  }}
                />
              </IonItem>
              {showErrorMessage[4].estatus ? (
                <IonText class="input-error-message">
                  {showErrorMessage[4].msg}
                </IonText>
              ) : (
                <></>
              )}
            </IonCol>
            <IonCol size="12" sizeMd="6" sizeLg="6" sizeXl="6">
              <IonItem className="modal-input" ref={itemNumeroExterior}>
                <IonIcon
                  slot="start"
                  icon={business}
                  class="icon-input-margin"
                />
                <IonLabel position="floating" className="modal-label">
                  Número exterior<IonText color="danger">*</IonText>
                </IonLabel>
                <IonInput
                  clearInput={true}
                  disabled={selectedEmpresa.id === "" || !validCP}
                  inputmode="text"
                  type="text"
                  color="secondary"
                  value={editCentroDeTrabajoNumeroExterior}
                  onIonChange={(e) => {
                    setEditCentroDeTrabajoNumeroExterior(e.detail.value!);
                    onValidate(5, e.detail.value!);
                  }}
                />
              </IonItem>
              {showErrorMessage[5].estatus ? (
                <IonText class="input-error-message">
                  {showErrorMessage[5].msg}
                </IonText>
              ) : (
                <></>
              )}
            </IonCol>
            <IonCol size="12" sizeMd="6" sizeLg="6" sizeXl="6">
              <IonItem className="modal-input">
                <IonIcon
                  slot="start"
                  icon={business}
                  class="icon-input-margin"
                />
                <IonLabel position="floating" className="modal-label">
                  Número interior
                </IonLabel>
                <IonInput
                  clearInput={true}
                  disabled={selectedEmpresa.id === "" || !validCP}
                  inputmode="text"
                  type="text"
                  color="secondary"
                  value={editCentroDeTrabajoNumeroInterior}
                  onIonChange={(e) => {
                    setEditCentroDeTrabajoNumeroInterior(e.detail.value!);
                  }}
                />
              </IonItem>
            </IonCol>
            <IonCol size="12" sizeMd="6" sizeLg="6" sizeXl="6">
              <IonItem className="modal-input" ref={itemColonia}>
                <IonIcon
                  slot="start"
                  icon={business}
                  class="icon-input-margin"
                />
                <IonLabel position="floating" className="modal-label">
                  Colonia<IonText color="danger">*</IonText>
                </IonLabel>
                <IonInput
                  clearInput={true}
                  disabled={selectedEmpresa.id === "" || !validCP}
                  inputmode="text"
                  type="text"
                  color="secondary"
                  value={editCentroDeTrabajoColonia}
                  onIonChange={(e) => {
                    setEditCentroDeTrabajoNombreColonia(e.detail.value!);
                    onValidate(6, e.detail.value!);
                  }}
                />
              </IonItem>
              {showErrorMessage[6].estatus ? (
                <IonText class="input-error-message">
                  {showErrorMessage[6].msg}
                </IonText>
              ) : (
                <></>
              )}
            </IonCol>
            <IonCol size="12" sizeMd="6" sizeLg="6" sizeXl="6">
              <IonItem className="modal-input" ref={itemCiudad}>
                <IonIcon
                  slot="start"
                  icon={business}
                  class="icon-input-margin"
                />
                <IonLabel position="floating" className="modal-label">
                  Ciudad<IonText color="danger">*</IonText>
                </IonLabel>
                <IonInput
                  clearInput={true}
                  disabled={selectedEmpresa.id === "" || !validCP}
                  inputmode="text"
                  type="text"
                  color="secondary"
                  value={editCentroDeTrabajoCiudad}
                  onIonChange={(e) => {
                    setEditCentroDeTrabajoCiudad(e.detail.value!);
                    onValidate(7, e.detail.value!);
                  }}
                />
              </IonItem>
              {showErrorMessage[7].estatus ? (
                <IonText class="input-error-message">
                  {showErrorMessage[7].msg}
                </IonText>
              ) : (
                <></>
              )}
            </IonCol>
            <IonCol size="12" sizeMd="6" sizeLg="6" sizeXl="6">
              <IonItem className="modal-input">
                <IonIcon
                  slot="start"
                  icon={business}
                  class="icon-input-margin"
                />
                <IonLabel position="floating" className="modal-label">
                  Delegación/Municipio
                </IonLabel>
                <IonInput
                  clearInput={true}
                  disabled={selectedEmpresa.id === "" || !validCP}
                  inputmode="text"
                  type="text"
                  color="secondary"
                  value={editCentroDeTrabajoDelegacionMunicipio}
                  onIonChange={(e) => {
                    setEditCentroDeTrabajoDelegacionMunicipio(e.detail.value!);
                  }}
                />
              </IonItem>
            </IonCol>
            <IonCol
              sizeXl="6"
              sizeLg="6"
              sizeMd="6"
              sizeSm="6"
              sizeXs="12"
              size="12"
            >
              <Selector
                classStyle="modal-input"
                value={editCentroDeTrabajoEstado}
                label="Estado"
                list={estadoList}
                change={setEditCentroDeTrabajoEstado}
                icon={documentText}
                disabled={selectedEmpresa.id === "" || !validCP}
              />
            </IonCol>
            <IonCol
              sizeXl="6"
              sizeLg="6"
              sizeMd="6"
              sizeSm="6"
              sizeXs="12"
              size="12"
            >
              <Selector
                classStyle="modal-input"
                value={editCentroDeTrabajoPais}
                label="País"
                list={paisList}
                change={setEditCentroDeTrabajoPais}
                icon={flag}
                disabled={selectedEmpresa.id === "" || !validCP}
              />
            </IonCol>
          </IonRow>
        </IonGrid>
        {
          <IonButton
            expand="block"
            disabled={selectedEmpresa.id === "" || loadingValidate}
            onClick={async () => await validate()}
          >
            {loadingValidate ? (
              <IonSpinner
                className="loading-center-horizontal"
                name="circles"
                color="medium"
                slot="start"
              />
            ) : (
              <IonLabel>Validar datos</IonLabel>
            )}
          </IonButton>
        }
        <IonItem lines="none" className="modal-input">
          <IonLabel>
            <IonText color="danger">*</IonText> Campos requeridos
          </IonLabel>
        </IonItem>
      </IonContent>
    </IonPage>
  );
};

const CreateCentroDeTrabajoModal: React.FC<{
  onDismiss: (data?: any, role?: string | undefined) => void;
  data: ICreateCentroDeTrabajoData;
  autofocus: number;
  accept?: (data: ICreateCentroDeTrabajoData) => void;
}> = ({ onDismiss, data, autofocus, accept }) => {
  const dispatch = useAppDispatch();
  const [presentToast, dismissToast] = useIonToast();
  const [{ auth, clienteQentry, estado, pais, user }] = useRedux();
  const claveCodigo = useAppSelector(
    (state) => state.workCenters.claveCodigoModal
  );
  const place = useAppSelector((state) => state.workCenters.placeModal);
  // Mapa
  const [searchText, setSearchText] = useState(""); // initial zoom
  const [zoom, setZoom] = useState(10); // initial zoom
  const [center, setCenter] = useState<google.maps.LatLngLiteral>({
    lat: 19.42847,
    lng: -99.12766,
  });
  const [places, setPlaces] = useState<google.maps.LatLng[]>([]);
  const [findPlace, setFindPlace] = useState<google.maps.Geocoder>(
    new google.maps.Geocoder()
  );
  const [mapLoading, setMapLoading] = useState(true);

  const render = (status: Status) => {
    if (status !== Status.LOADING) setMapLoading(false);
  };

  const addressComponent = (
    geocoderResult: google.maps.GeocoderResult,
    componentType: string
  ) => {
    if (
      !geocoderResult.address_components ||
      geocoderResult.address_components.length === 0
    )
      return "";
    const res = geocoderResult.address_components.find((e) =>
      e.types.includes(componentType)
    );
    if (!res) return "";
    return res.long_name;
  };
  const postalCode = (place: google.maps.GeocoderResult) => {
    if (!place.address_components || place.address_components.length === 0)
      return "";
    const res = place.address_components.find((e) =>
      e.types.includes("postal_code")
    );
    if (!res) return "";
    return res.long_name;
  };

  const onClick = (e: google.maps.MapMouseEvent) => {
    // avoid directly mutating state
    setPlaces([e.latLng!]);
  };

  const onPlaceAdded = (e: google.maps.LatLng[]) => {
    setPlaces([...e]);
    findPlace.geocode({ location: e[0] }, (result) => {
      if (!result) {
        presentToast({
          message:
            "La ubicación marcada no tiene un código postal, por favor agréguelo manualmente",
          color: "warning",
          duration: 2500,
        });
        return;
      }
      findPlace.geocode(
        { placeId: result[0].place_id, componentRestrictions: null },
        (finalResult) => {
          if (!finalResult) {
            presentToast({
              message:
                "La ubicación marcada no tiene un código postal, por favor agréguelo manualmente",
              color: "warning",
              duration: 2500,
            });
            return;
          }
          const cp = postalCode(finalResult[0]);
          if (!cp) {
            presentToast({
              message:
                "La ubicación marcada no tiene un código postal, por favor agréguelo manualmente",
              color: "warning",
              duration: 2500,
            });
            return;
          }
          setCreateCentroDeTrabajoCodigoPostal(cp);
          searchCP(false, cp);
        }
      );
    });
  };

  const onPlacesDrag = (e: google.maps.MapMouseEvent) => {
    setPlaces([e.latLng!]);
    findPlace.geocode({ location: e.latLng }, (result) => {
      if (!result) {
        presentToast({
          message:
            "La ubicación marcada no tiene un código postal, por favor agreguelo manualmente",
          color: "warning",
          duration: 2500,
        });
        return;
      }
      findPlace.geocode(
        { placeId: result[0].place_id, componentRestrictions: null },
        (finalResult) => {
          if (!finalResult) {
            presentToast({
              message:
                "La ubicación marcada no tiene un código postal, por favor agréguelo manualmente",
              color: "warning",
              duration: 2500,
            });
            return;
          }
          const cp = postalCode(finalResult[0]);
          if (!cp) {
            presentToast({
              message:
                "La ubicación marcada no tiene un código postal, por favor agréguelo manualmente",
              color: "warning",
              duration: 2500,
            });
            return;
          }
          setCreateCentroDeTrabajoCodigoPostal(cp);
          searchCP(false, cp);
        }
      );
    });
  };

  const onIdle = (m: google.maps.Map) => {
    if (m.getZoom()) setZoom(m.getZoom()!);
    if (m.getCenter()) setCenter(m.getCenter()!.toJSON());
  };

  const selectedEmpresa = useAppSelector(
    (state) => state.workCenters.selectedEmpresa
  );

  const defaultErrorMessages = [
    "Por favor un ingrese un nombre del centro de trabajo.",
    "Por favor un ingrese un numero válido.", // Distancia máxima
    "Por favor un ingrese un numero de contacto.",
    "Por favor un ingrese un código postal válido.",
    "Por favor un ingrese una calle.",
    "Por favor un ingrese un numero exterior.",
    "Por favor un ingrese una colonia.",
    "Por favor un ingrese una ciudad.",
  ];

  const [showErrorMessage, setShowErrorMessage] = useState<
    { estatus: boolean; msg: string }[]
  >(defaultErrorMessages.map((v) => ({ estatus: false, msg: v })));
  const [dataHasErrors, setDataHasErrors] = useState<boolean>(true);
  const [validCP, setValidCP] = useState<boolean>(false);
  const [loadingValidate, setLoadingValidate] = useState<boolean>(false);
  const [hiddenCodigoPostalId, setHiddenCodigoPostalId] = useState<number>(1);

  const estadoList = useAppSelector(getGlobalMemoizedEstadoAsSelectList);
  const estadoModalConfiguracionEstado = useAppSelector(
    getMemoizedWorkCentersSelectedEstado
  );
  const paisList = useAppSelector(getGlobalMemoizedPaisAsSelectList);
  const paisModalConfiguracionPais = useAppSelector(
    getMemoizedWorkCentersSelectedPais
  );

  const [
    createCentroDeTrabajoNombreCentro,
    setCreateCentroDeTrabajoNombreCentro,
  ] = useState("");
  const [
    createCentroDeTrabajoDistanciaMaximaPermitida,
    setCreateCentroDeTrabajoDistanciaMaximaPermitida,
  ] = useState("");
  const [
    createCentroDeTrabajoNumeroDeContacto,
    setCreateCentroDeTrabajoNumeroDeContacto,
  ] = useState("+52");
  const [
    createCentroDeTrabajoCodigoPostal,
    setCreateCentroDeTrabajoCodigoPostal,
  ] = useState("");
  const [createCentroDeTrabajoCalle, setCreateCentroDeTrabajoNombreCalle] =
    useState("");
  const [
    createCentroDeTrabajoNumeroExterior,
    setCreateCentroDeTrabajoNumeroExterior,
  ] = useState("");
  const [
    createCentroDeTrabajoNumeroInterior,
    setCreateCentroDeTrabajoNumeroInterior,
  ] = useState("");
  const [createCentroDeTrabajoColonia, setCreateCentroDeTrabajoNombreColonia] =
    useState("");
  const [createCentroDeTrabajoCiudad, setCreateCentroDeTrabajoCiudad] =
    useState("");
  const [
    createCentroDeTrabajoDelegacionMunicipio,
    setCreateCentroDeTrabajoDelegacionMunicipio,
  ] = useState("");
  const [createCentroDeTrabajoEstado, setCreateCentroDeTrabajoEstado] =
    useState(estadoModalConfiguracionEstado);
  const [createCentroDeTrabajoPais, setCreateCentroDeTrabajoPais] = useState(
    paisModalConfiguracionPais
  );

  const itemNombreCentro = useRef<HTMLIonItemElement>(null);
  const itemDistanciaMaximaPermitida = useRef<HTMLIonItemElement>(null);
  const itemNumeroDeContacto = useRef<HTMLIonItemElement>(null);
  const itemCodigoPostal = useRef<HTMLIonItemElement>(null);
  const itemCalle = useRef<HTMLIonItemElement>(null);
  const itemNumeroExterior = useRef<HTMLIonItemElement>(null);
  const itemColonia = useRef<HTMLIonItemElement>(null);
  const itemCiudad = useRef<HTMLIonItemElement>(null);

  const qrParent = useRef<HTMLIonColElement>(null);
  const [originalImage, setOriginalImage] = useState<HTMLCanvasElement>();
  let [canvasBlob, setCanvasBlob] = useState<Blob>();

  const [qrCodeSize, setQrCodeSize] = useState<number>(260);

  const disableAceptar = () => {
    const notValid = showErrorMessage.some((v) => v.estatus);
    if (notValid) return true;
    const nombreCentroValid =
      createCentroDeTrabajoNombreCentro &&
      createCentroDeTrabajoNombreCentro.trim();
    if (!nombreCentroValid) return true;
    const distanciaMaximaPermitidaValid =
      createCentroDeTrabajoDistanciaMaximaPermitida &&
      createCentroDeTrabajoDistanciaMaximaPermitida.trim();
    if (!distanciaMaximaPermitidaValid) return true;
    const numeroDeContactoValid =
      createCentroDeTrabajoNumeroDeContacto &&
      createCentroDeTrabajoNumeroDeContacto.trim();
    if (!numeroDeContactoValid) return true;
    const codigoPostalValid =
      createCentroDeTrabajoCodigoPostal &&
      createCentroDeTrabajoCodigoPostal.trim();
    if (!codigoPostalValid) return true;
    const calleValid =
      createCentroDeTrabajoCalle && createCentroDeTrabajoCalle.trim();
    if (!calleValid) return true;
    const numeroExteriorValid =
      createCentroDeTrabajoNumeroExterior &&
      createCentroDeTrabajoNumeroExterior.trim();
    if (!numeroExteriorValid) return true;
    const coloniaValid =
      createCentroDeTrabajoColonia && createCentroDeTrabajoColonia.trim();
    if (!coloniaValid) return true;
    const ciudadValid =
      createCentroDeTrabajoCiudad && createCentroDeTrabajoCiudad.trim();
    if (!ciudadValid) return true;
    if (dataHasErrors || selectedEmpresa.id === "" || loadingValidate)
      return true;
    return false;
  };

  const onValidate = (id: number, value: any) => {
    if (!itemNombreCentro.current) return;
    if (!itemDistanciaMaximaPermitida.current) return;
    if (!itemNumeroDeContacto.current) return;
    if (!itemCodigoPostal.current) return;
    if (!itemCalle.current) return;
    if (!itemNumeroExterior.current) return;
    if (!itemColonia.current) return;
    if (!itemCiudad.current) return;

    switch (id) {
      case 0:
        const testItemNombreCentro = !/.+/.test(value.trim());
        if (testItemNombreCentro)
          itemNombreCentro.current.classList.add("error");
        else itemNombreCentro.current.classList.remove("error");
        setShowErrorMessage(
          showErrorMessage.map((e, i) => {
            if (i !== id) return e;
            return { estatus: testItemNombreCentro, msg: e.msg };
          })
        );
        break;
      case 1:
        const testItemDistanciaMaximaPermitida = !/[0-9]+/.test(value.trim());
        if (testItemDistanciaMaximaPermitida)
          itemDistanciaMaximaPermitida.current.classList.add("error");
        else itemDistanciaMaximaPermitida.current.classList.remove("error");
        setShowErrorMessage(
          showErrorMessage.map((e, i) => {
            if (i !== id) return e;
            return { estatus: testItemDistanciaMaximaPermitida, msg: e.msg };
          })
        );
        break;
      case 2:
        const testItemNumeroDeContacto =
          !/^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/.test(value.trim());
        if (testItemNumeroDeContacto)
          itemNumeroDeContacto.current.classList.add("error");
        else itemNumeroDeContacto.current.classList.remove("error");
        setShowErrorMessage(
          showErrorMessage.map((e, i) => {
            if (i !== id) return e;
            return { estatus: testItemNumeroDeContacto, msg: e.msg };
          })
        );
        break;
      case 3:
        setValidCP(false);
        const testItemCodigoPostal = !/[0-9]+/.test(value.trim());
        if (testItemCodigoPostal)
          itemCodigoPostal.current.classList.add("error");
        else itemCodigoPostal.current.classList.remove("error");
        setShowErrorMessage(
          showErrorMessage.map((e, i) => {
            if (i !== id) return e;
            return { estatus: testItemCodigoPostal, msg: e.msg };
          })
        );
        break;
      case 4:
        const testItemCalle = !/.+/.test(value.trim());
        if (testItemCalle) itemCalle.current.classList.add("error");
        else itemCalle.current.classList.remove("error");
        setShowErrorMessage(
          showErrorMessage.map((e, i) => {
            if (i !== id) return e;
            return { estatus: testItemCalle, msg: e.msg };
          })
        );
        break;
      case 5:
        const testItemNumeroExterior = !/.+/.test(value.trim());
        if (testItemNumeroExterior)
          itemNumeroExterior.current.classList.add("error");
        else itemNumeroExterior.current.classList.remove("error");
        setShowErrorMessage(
          showErrorMessage.map((e, i) => {
            if (i !== id) return e;
            return { estatus: testItemNumeroExterior, msg: e.msg };
          })
        );
        break;
      case 6:
        const testItemColonia = !/.+/.test(value.trim());
        if (testItemColonia) itemColonia.current.classList.add("error");
        else itemColonia.current.classList.remove("error");
        setShowErrorMessage(
          showErrorMessage.map((e, i) => {
            if (i !== id) return e;
            return { estatus: testItemColonia, msg: e.msg };
          })
        );
        break;
      case 7:
        const testItemCiudad = !/.+/.test(value.trim());
        if (testItemCiudad) itemCiudad.current.classList.add("error");
        else itemCiudad.current.classList.remove("error");
        setShowErrorMessage(
          showErrorMessage.map((e, i) => {
            if (i !== id) return e;
            return { estatus: testItemCiudad, msg: e.msg };
          })
        );
        break;
    }
  };

  const removeAccents = (str: string) => {
    return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
  };

  const getCpIfExist = async (codigoPostal: string, colonia: string) => {
    console.log("Dentro de getCpIfExist");

    console.log("Codigo a buscar", codigoPostal, "Colonia a buscar", colonia);
    const listaCP = await searchCodigoPostal(codigoPostal);
    console.log("lista de codigos encontrados", listaCP);

    // if (listaCP.length === 0) {
    //   return presentAlert({
    //     header: "Aviso",
    //     message: "No se encontró el código postal",
    //     buttons: ["OK"],
    //   });
    // }
    return listaCP.length === 0
      ? undefined
      : listaCP.length === 1
      ? listaCP[0]
      : listaCP.find((itemCp) =>
          removeAccents(itemCp.nombre_asentamiento).includes(colonia)
        );
  };

  const searchCP = async (autosearch = true, searchCodigo = "") => {
    setValidCP(false);
    const listaCP = await searchCodigoPostal(
      !autosearch ? searchCodigo : createCentroDeTrabajoCodigoPostal
    );
    console.log({ createCentroDeTrabajoCodigoPostal, searchCodigo, listaCP });
    if (listaCP.length === 0) {
      return presentAlert({
        header: "Aviso",
        message: "No se encontró el código postal",
        buttons: ["OK"],
      });
    }
    return presentCodigoPostalList({
      buttons: [
        ...listaCP.map((cpItem) => ({
          text: `${cpItem.codigo_postal}, ${cpItem.nombre_asentamiento}`,
          handler: () => {
            const estadoValue = estado.data_array.find(
              (e) => e.nombre === cpItem.nombre_entidad
            )!;
            const paisValue = pais.data_array.find(
              (e) => e.nombre.includes("México") || e.nombre.includes("Mexico")
            )!;

            setHiddenCodigoPostalId(cpItem.id);
            setCreateCentroDeTrabajoNombreColonia(cpItem.nombre_asentamiento);
            setCreateCentroDeTrabajoCiudad(cpItem.nombre_ciudad);
            setCreateCentroDeTrabajoDelegacionMunicipio(
              cpItem.nombre_municipio
            );
            setCreateCentroDeTrabajoEstado(
              setListItem({
                id: estadoValue.id.toString(),
                title: estadoValue.nombre,
              })
            );
            setCreateCentroDeTrabajoPais(
              setListItem({
                id: paisValue.id.toString(),
                title: paisValue.nombre,
              })
            );
            if (autosearch)
              setSearchText(
                `${createCentroDeTrabajoCodigoPostal} ${estadoValue.nombre} ${paisValue.nombre}`
              );
            setValidCP(true);
          },
        })),
        { text: "Cancelar", role: "cancel" },
      ],
      header: "Seleccionar código postal",
    });
  };

  const validate = async () => {
    setLoadingValidate(true);
    setDataHasErrors(false);

    const res = await qentry3ValidatePhoneNumber(
      createCentroDeTrabajoNumeroDeContacto
    );

    setLoadingValidate(false);
    if (res.data.hasError) {
      const temp = res.data.result;
      setDataHasErrors(res.data.hasError);
      setShowErrorMessage(
        showErrorMessage.map((e, i) => {
          if (i !== 2) return e;
          const index = temp.findIndex((v: any) => v.id === i);
          switch (i) {
            case 2:
              if (index !== -1)
                itemNumeroDeContacto.current?.classList.add("error");
              else itemNumeroDeContacto.current?.classList.remove("error");
              break;
          }
          if (index === -1) return e;
          return { estatus: true, msg: temp[0].msg };
        })
      );
    }
  };

  const [presentCodigoPostalList, dismissCodigoPostalList] =
    useIonActionSheet();
  const [presentAlert] = useIonAlert();

  useEffect(() => {
    dispatch(
      setWorkCentersClaveCodigoModal(
        [...Array(3)].map(() => Math.random().toString(36).slice(2)).join("")
      )
    );
    const location = { lat: place[0].lat, lng: place[0].lng };
    const value = new google!.maps!.LatLng(location);
    setPlaces([value]);
    setCenter(location);
    setZoom(20);
    sleep(200).then(() => {
      findPlace.geocode({ location: place[0] }, (result) => {
        if (!result) {
          presentToast({
            message:
              "No se pudo obtener la dirección del punto marcado. Por favor agréguelo manualmente",
            color: "warning",
            duration: 2500,
          });
          return;
        }
        findPlace.geocode(
          { placeId: result[0].place_id, componentRestrictions: null },
          async (finalResult) => {
            if (!finalResult) {
              presentToast({
                message:
                  "No pudo obtener la dirección a traves del id del punto marcado, por favor agréguelo manualmente",
                color: "warning",
                duration: 2500,
              });
              return;
            }
            console.log({ finalResult });
            const numExteriorAddress = addressComponent(
              finalResult[0],
              "street_number"
            );
            const calleAddress = addressComponent(finalResult[0], "route");
            const coloniaAddress = addressComponent(
              finalResult[0],
              "sublocality_level_1"
            );
            const ciudadAddress = addressComponent(finalResult[0], "locality");
            const entidadAddress = addressComponent(
              finalResult[0],
              "administrative_area_level_1"
            );
            const paisAddress = addressComponent(finalResult[0], "country");
            const cpAddress = addressComponent(finalResult[0], "postal_code");

            if (!cpAddress) {
              setValidCP(false);
              presentToast({
                message:
                  "La ubicación marcada no tiene un código postal, por favor agréguelo manualmente",
                color: "warning",
                duration: 2500,
              });
              return;
            }

            const estadoValue = estado.data_array.find((e) =>
              e.nombre.includes(entidadAddress)
            )!;
            const paisValue = pais.data_array.find(
              (e) => e.nombre.includes("México") || e.nombre.includes("Mexico")
            )!;

            setCreateCentroDeTrabajoEstado(
              setListItem({
                id: estadoValue.id.toString(),
                title: estadoValue.nombre,
              })
            );
            setCreateCentroDeTrabajoPais(
              setListItem({
                id: paisValue.id.toString(),
                title: paisValue.nombre,
              })
            );

            setCreateCentroDeTrabajoNumeroExterior(numExteriorAddress);
            setCreateCentroDeTrabajoNombreCalle(calleAddress);
            setCreateCentroDeTrabajoNombreColonia(coloniaAddress);
            setCreateCentroDeTrabajoCiudad(ciudadAddress);
            setCreateCentroDeTrabajoCodigoPostal(cpAddress);

            const findedCP = await getCpIfExist(
              cpAddress,
              coloniaAddress === "" ? coloniaAddress : ciudadAddress
            );
            console.log("Codigo encontrado", findedCP);

            if (findedCP) {
              setHiddenCodigoPostalId(findedCP.id);
              setCreateCentroDeTrabajoDelegacionMunicipio(
                findedCP.nombre_municipio
              );
              setCreateCentroDeTrabajoNombreColonia(
                findedCP.nombre_asentamiento
              );
              setCreateCentroDeTrabajoCiudad(findedCP.nombre_ciudad);
            }

            setValidCP(true);

            // searchCP(false, cpAddress);
          }
        );
      });
    });
  }, []);

  useEffect(() => {
    if (!qrParent.current) return;
    const canvas = document.getElementById(
      "react-qrcode-logo"
    ) as HTMLCanvasElement;
    if (canvas) {
      let context = canvas.getContext("2d");
      if (originalImage) {
        context!.fillStyle = "#ffffff";
        context!.fillRect(0, qrCodeSize + 30, canvas.width, 40);
      } else {
        setOriginalImage(canvas);
      }
      context!.lineWidth = 1;
      context!.fillStyle = "#cdde00";
      context!.font = "bolder 18px sans-serif";
      context!.textAlign = "center";
      context!.fillText(
        createCentroDeTrabajoNombreCentro,
        150,
        qrCodeSize + 55
      );
    }
  }, [createCentroDeTrabajoNombreCentro, qrParent.current]);

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar color="secondary" className="toolBarStart">
          <IonButtons slot="start">
            <IonButton onClick={() => onDismiss()}>Cancelar</IonButton>
          </IonButtons>
          <IonTitle className="toolBarStart center">Centro de trabajo</IonTitle>
          <IonButtons slot="end">
            <IonButton
              strong={true}
              disabled={disableAceptar()}
              onClick={async () => {
                if (accept) {
                  console.log("beforeCanvas");
                  const canvas = document.getElementById(
                    "react-qrcode-logo"
                  ) as HTMLCanvasElement;

                  const idAutor = auth.empleado.usuario.id;
                  console.log("canvas", canvas);
                  canvas.toBlob((blob) => {
                    if (blob) canvasBlob = blob;
                    console.log("(0) canvasBlob", canvasBlob);
                    accept({
                      // ICreateCentroDeTrabajoData
                      id_cliente: clienteQentry.data.id,
                      id_empresa: Number(selectedEmpresa.id),
                      id_autor: user.data_array.find((e) => e.id === idAutor)!
                        .id,
                      id_centro: 0,
                      nombre_centro: createCentroDeTrabajoNombreCentro,
                      distancia_maxima_permitida: Number(
                        createCentroDeTrabajoDistanciaMaximaPermitida
                      ),
                      numero_empleados_asignados: 0,
                      centro_trabajo_predeterminado: false,
                      // IGeolocalizacionData
                      longitud: place[0].lng.toString(),
                      latitud: place[0].lat.toString(),
                      descripcion_geolocalizacion:
                        createCentroDeTrabajoNombreCentro,
                      nombre_codigo: createCentroDeTrabajoNombreCentro,
                      // ICodigoQrData
                      clave_codigo: claveCodigo,
                      url_imagen_codigo: "",
                      // IDireccionData
                      numero_contacto: createCentroDeTrabajoNumeroDeContacto, // Not Null
                      id_cp: hiddenCodigoPostalId, // Not Null
                      calle: createCentroDeTrabajoCalle, // Not Null
                      numero_exterior: createCentroDeTrabajoNumeroExterior, // Not Null
                      numero_interior: createCentroDeTrabajoNumeroInterior,
                      colonia: createCentroDeTrabajoColonia, // Not Null
                      ciudad: createCentroDeTrabajoCiudad, // Not Null
                      delegacion_municipio:
                        createCentroDeTrabajoDelegacionMunicipio,
                      estado: createCentroDeTrabajoEstado.title,
                      id_estado: Number(createCentroDeTrabajoEstado.id),
                      pais: createCentroDeTrabajoPais.title,
                      id_pais: Number(createCentroDeTrabajoPais.id),
                      blob: canvasBlob!,
                    });
                  });
                  console.log("(1) canvasBlob", canvasBlob);
                }
                onDismiss();
              }}
            >
              Aceptar
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent className="ion-padding">
        {selectedEmpresa.id === "" ? (
          <IonItem lines="none" color="danger">
            <IonLabel>
              <h2>
                <b>Por favor seleccione una empresa</b>
              </h2>
            </IonLabel>
          </IonItem>
        ) : (
          <></>
        )}
        <IonItem lines="none" className="modal-input">
          <IonLabel>
            <h2>
              <b>Datos del centro de trabajo </b>
            </h2>
          </IonLabel>
        </IonItem>
        <IonGrid>
          <IonRow class="no-padding">
            <IonCol class="no-padding" id="qr-code-parent" ref={qrParent}>
              <QRCode
                value={claveCodigo}
                logoImage={"../assets/img/logosingle.png"}
                eyeRadius={5}
                ecLevel={"H"}
                qrStyle={"dots"}
                size={qrCodeSize}
                quietZone={30}
                logoWidth={qrCodeSize * 0.5}
                logoHeight={qrCodeSize * 0.5}
              />
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol size="12">
              <IonItem className="modal-input" ref={itemNombreCentro}>
                <IonIcon
                  slot="start"
                  icon={business}
                  class="icon-input-margin"
                />
                <IonLabel position="floating" className="modal-label">
                  Nombre del centro de trabajo
                  <IonText color="danger">*</IonText>
                </IonLabel>
                <IonInput
                  clearInput={true}
                  disabled={selectedEmpresa.id === ""}
                  inputmode="text"
                  type="text"
                  color="secondary"
                  value={createCentroDeTrabajoNombreCentro}
                  onIonChange={(e) => {
                    setCreateCentroDeTrabajoNombreCentro(e.detail.value!);
                    onValidate(0, e.detail.value!);
                  }}
                />
              </IonItem>
              {showErrorMessage[0].estatus ? (
                <IonText class="input-error-message">
                  {showErrorMessage[0].msg}
                </IonText>
              ) : (
                <></>
              )}
            </IonCol>
            <IonCol size="12">
              <IonItem
                className="modal-input"
                ref={itemDistanciaMaximaPermitida}
              >
                <IonIcon
                  slot="start"
                  icon={business}
                  class="icon-input-margin"
                />
                <IonLabel position="floating" className="modal-label">
                  Distancia máxima para el registro (m)
                  <IonText color="danger">*</IonText>
                </IonLabel>
                <IonInput
                  clearInput={true}
                  disabled={selectedEmpresa.id === ""}
                  min={0}
                  inputmode="numeric"
                  type="number"
                  color="secondary"
                  value={createCentroDeTrabajoDistanciaMaximaPermitida}
                  onIonChange={(e) => {
                    setCreateCentroDeTrabajoDistanciaMaximaPermitida(
                      e.detail.value!
                    );
                    onValidate(1, e.detail.value!);
                  }}
                />
              </IonItem>
              {showErrorMessage[1].estatus ? (
                <IonText class="input-error-message">
                  {showErrorMessage[1].msg}
                </IonText>
              ) : (
                <></>
              )}
            </IonCol>
            <IonCol size="12">
              <IonItem className="modal-input" ref={itemNumeroDeContacto}>
                <IonIcon
                  slot="start"
                  icon={business}
                  class="icon-input-margin"
                />
                <IonLabel position="floating" className="modal-label">
                  Número de contacto (celular)
                  <IonText color="danger">*</IonText>
                </IonLabel>
                <IonInput
                  clearInput={true}
                  disabled={selectedEmpresa.id === ""}
                  inputmode="tel"
                  type="tel"
                  color="secondary"
                  placeholder="+522215778772"
                  value={createCentroDeTrabajoNumeroDeContacto}
                  onIonChange={(e) => {
                    setCreateCentroDeTrabajoNumeroDeContacto(e.detail.value!);
                    onValidate(2, e.detail.value!);
                  }}
                />
              </IonItem>
              {showErrorMessage[2].estatus ? (
                <IonText class="input-error-message">
                  {showErrorMessage[2].msg}
                </IonText>
              ) : (
                <></>
              )}
            </IonCol>
            <IonCol size="9">
              <IonItem className="modal-input" ref={itemCodigoPostal}>
                <IonIcon
                  slot="start"
                  icon={business}
                  class="icon-input-margin"
                />
                <IonLabel position="floating" className="modal-label">
                  Código Postal<IonText color="danger">*</IonText>
                </IonLabel>
                <IonInput
                  clearInput={true}
                  disabled={selectedEmpresa.id === ""}
                  inputmode="text"
                  type="text"
                  color="secondary"
                  value={createCentroDeTrabajoCodigoPostal}
                  onIonChange={(e) => {
                    setCreateCentroDeTrabajoCodigoPostal(e.detail.value!);
                    setValidCP(false);
                    onValidate(3, e.detail.value!);
                  }}
                />
              </IonItem>
              {showErrorMessage[3].estatus ? (
                <IonText class="input-error-message">
                  {showErrorMessage[3].msg}
                </IonText>
              ) : (
                <></>
              )}
            </IonCol>
            <IonCol size="3">
              <IonButton expand="block" size="large" onClick={() => searchCP()}>
                <IonIcon slot="icon-only" icon={search} />
              </IonButton>
            </IonCol>
            <IonCol size="12">
              <div
                style={{
                  display: "flex",
                  height: "300px",
                  flexDirection: "column",
                }}
              >
                <Wrapper
                  apiKey={process.env.REACT_APP_GOOGLE_MAPS_KEY!}
                  libraries={["places"]}
                  callback={render}
                >
                  <Map
                    center={center}
                    onClick={onClick}
                    onIdle={onIdle}
                    onPlaceAdded={onPlaceAdded}
                    zoom={zoom}
                    hasSearchBox={false}
                    searchText={searchText}
                    style={{ flexGrow: "1", height: "100%" }}
                  >
                    {places.map((place, i) => (
                      <Marker
                        key={`nueva-dirección-${i}`}
                        icon={StatusIcon[3]}
                        position={place}
                        content={"Dirección"}
                        clickable={true}
                        draggable={true}
                        onDrag={onPlacesDrag}
                      />
                    ))}
                  </Map>
                </Wrapper>
              </div>
              <IonNote>
                <IonText color="danger">**</IonText>Si la ubicación no se
                detecta automáticamente al buscar el código postal por favor
                seleccione un punto en el mapa.
              </IonNote>
            </IonCol>
            <IonCol size="12" sizeMd="6" sizeLg="6" sizeXl="6">
              <IonItem className="modal-input" ref={itemCalle}>
                <IonIcon
                  slot="start"
                  icon={business}
                  class="icon-input-margin"
                />
                <IonLabel position="floating" className="modal-label">
                  Calle<IonText color="danger">*</IonText>
                </IonLabel>
                <IonInput
                  clearInput={true}
                  disabled={selectedEmpresa.id === "" || !validCP}
                  inputmode="text"
                  type="text"
                  color="secondary"
                  value={createCentroDeTrabajoCalle}
                  onIonChange={(e) => {
                    setCreateCentroDeTrabajoNombreCalle(e.detail.value!);
                    onValidate(4, e.detail.value!);
                  }}
                />
              </IonItem>
              {showErrorMessage[4].estatus ? (
                <IonText class="input-error-message">
                  {showErrorMessage[4].msg}
                </IonText>
              ) : (
                <></>
              )}
            </IonCol>
            <IonCol size="12" sizeMd="6" sizeLg="6" sizeXl="6">
              <IonItem className="modal-input" ref={itemNumeroExterior}>
                <IonIcon
                  slot="start"
                  icon={business}
                  class="icon-input-margin"
                />
                <IonLabel position="floating" className="modal-label">
                  Número exterior<IonText color="danger">*</IonText>
                </IonLabel>
                <IonInput
                  clearInput={true}
                  disabled={selectedEmpresa.id === "" || !validCP}
                  inputmode="text"
                  type="text"
                  color="secondary"
                  value={createCentroDeTrabajoNumeroExterior}
                  onIonChange={(e) => {
                    setCreateCentroDeTrabajoNumeroExterior(e.detail.value!);
                    onValidate(5, e.detail.value!);
                  }}
                />
              </IonItem>
              {showErrorMessage[5].estatus ? (
                <IonText class="input-error-message">
                  {showErrorMessage[5].msg}
                </IonText>
              ) : (
                <></>
              )}
            </IonCol>
            <IonCol size="12" sizeMd="6" sizeLg="6" sizeXl="6">
              <IonItem className="modal-input">
                <IonIcon
                  slot="start"
                  icon={business}
                  class="icon-input-margin"
                />
                <IonLabel position="floating" className="modal-label">
                  Número interior
                </IonLabel>
                <IonInput
                  clearInput={true}
                  disabled={selectedEmpresa.id === "" || !validCP}
                  inputmode="text"
                  type="text"
                  color="secondary"
                  value={createCentroDeTrabajoNumeroInterior}
                  onIonChange={(e) => {
                    setCreateCentroDeTrabajoNumeroInterior(e.detail.value!);
                  }}
                />
              </IonItem>
            </IonCol>
            <IonCol size="12" sizeMd="6" sizeLg="6" sizeXl="6">
              <IonItem className="modal-input" ref={itemColonia}>
                <IonIcon
                  slot="start"
                  icon={business}
                  class="icon-input-margin"
                />
                <IonLabel position="floating" className="modal-label">
                  Colonia<IonText color="danger">*</IonText>
                </IonLabel>
                <IonInput
                  clearInput={true}
                  disabled={selectedEmpresa.id === "" || !validCP}
                  inputmode="text"
                  type="text"
                  color="secondary"
                  value={createCentroDeTrabajoColonia}
                  onIonChange={(e) => {
                    setCreateCentroDeTrabajoNombreColonia(e.detail.value!);
                    onValidate(6, e.detail.value!);
                  }}
                />
              </IonItem>
              {showErrorMessage[6].estatus ? (
                <IonText class="input-error-message">
                  {showErrorMessage[6].msg}
                </IonText>
              ) : (
                <></>
              )}
            </IonCol>
            <IonCol size="12" sizeMd="6" sizeLg="6" sizeXl="6">
              <IonItem className="modal-input" ref={itemCiudad}>
                <IonIcon
                  slot="start"
                  icon={business}
                  class="icon-input-margin"
                />
                <IonLabel position="floating" className="modal-label">
                  Ciudad
                </IonLabel>
                <IonInput
                  clearInput={true}
                  disabled={selectedEmpresa.id === "" || !validCP}
                  inputmode="text"
                  type="text"
                  color="secondary"
                  value={createCentroDeTrabajoCiudad}
                  onIonChange={(e) => {
                    setCreateCentroDeTrabajoCiudad(e.detail.value!);
                  }}
                />
              </IonItem>
              {showErrorMessage[7].estatus ? (
                <IonText class="input-error-message">
                  {showErrorMessage[7].msg}
                </IonText>
              ) : (
                <></>
              )}
            </IonCol>
            <IonCol size="12" sizeMd="6" sizeLg="6" sizeXl="6">
              <IonItem className="modal-input">
                <IonIcon
                  slot="start"
                  icon={business}
                  class="icon-input-margin"
                />
                <IonLabel position="floating" className="modal-label">
                  Delegación/Municipio
                </IonLabel>
                <IonInput
                  clearInput={true}
                  disabled={selectedEmpresa.id === "" || !validCP}
                  inputmode="text"
                  type="text"
                  color="secondary"
                  value={createCentroDeTrabajoDelegacionMunicipio}
                  onIonChange={(e) => {
                    setCreateCentroDeTrabajoDelegacionMunicipio(
                      e.detail.value!
                    );
                  }}
                />
              </IonItem>
            </IonCol>
            <IonCol
              sizeXl="6"
              sizeLg="6"
              sizeMd="6"
              sizeSm="6"
              sizeXs="12"
              size="12"
            >
              <Selector
                classStyle="modal-input"
                value={createCentroDeTrabajoEstado}
                label="Estado"
                list={estadoList}
                change={setCreateCentroDeTrabajoEstado}
                icon={documentText}
                disabled={selectedEmpresa.id === "" || !validCP}
              />
            </IonCol>
            <IonCol
              sizeXl="6"
              sizeLg="6"
              sizeMd="6"
              sizeSm="6"
              sizeXs="12"
              size="12"
            >
              <Selector
                classStyle="modal-input"
                value={createCentroDeTrabajoPais}
                label="País"
                list={paisList}
                change={setCreateCentroDeTrabajoPais}
                icon={flag}
                disabled={selectedEmpresa.id === "" || !validCP}
              />
            </IonCol>
          </IonRow>
        </IonGrid>
        {!data ? (
          <IonButton
            expand="block"
            disabled={selectedEmpresa.id === "" || loadingValidate}
            onClick={async () => await validate()}
          >
            {loadingValidate ? (
              <IonSpinner
                className="loading-center-horizontal"
                name="circles"
                color="medium"
                slot="start"
              />
            ) : (
              <IonLabel>Validar datos</IonLabel>
            )}
          </IonButton>
        ) : (
          <></>
        )}
        <IonItem lines="none" className="modal-input">
          <IonLabel>
            <IonText color="danger">*</IonText> Campos requeridos
          </IonLabel>
        </IonItem>
      </IonContent>
    </IonPage>
  );
};

export { EditCentroDeTrabajoModal, CreateCentroDeTrabajoModal };
