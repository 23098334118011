/**
 * Reducers creados para obtener asíncronamente 
 * los datos de los modelos y dependiendo del resultado
 * de la peticón actualice el state en redux o mande un mensaje de error
 */

import { PayloadAction } from "@reduxjs/toolkit";
import { getMvMotivoNotificacionAsyncThunk, getAllMvMotivoNotificacionAsyncThunk, updateMvMotivoNotificacionAsyncThunk, createMvMotivoNotificacionAsyncThunk, deleteMvMotivoNotificacionAsyncThunk } from "../../asyncThunk/nomina";
import { DEFAULT_STATE } from "../../enums/default_state";
import { IStatusMvMotivoNotificacion } from "../../interfaces/nomina";
import { nominaInitialState } from "../../slices/nominaSlice";

// Reducers: MvMotivoNotificacion
export const createMvMotivoNotificacionReducers = () => {
  const get = () => {
    var { pending, fulfilled, rejected } = getMvMotivoNotificacionAsyncThunk;
    return {
      [pending.type]: (state : typeof nominaInitialState) => {
        state.nominaState.mvMotivoNotificacion.estado = DEFAULT_STATE.loading;
      },
      [fulfilled.type]: (state : typeof nominaInitialState, action : PayloadAction<IStatusMvMotivoNotificacion>) => {
        const result = action.payload;
        result.expiry_array = state.nominaState.mvMotivoNotificacion.expiry_array;
        if (result.estado === DEFAULT_STATE.error) result.data_array = state.nominaState.mvMotivoNotificacion.data_array;
        state.nominaState.mvMotivoNotificacion = result;
      },
      [rejected.type]: (state : typeof nominaInitialState, action : PayloadAction<IStatusMvMotivoNotificacion>) => {
        state.nominaState.mvMotivoNotificacion = {
          ...state.nominaState.mvMotivoNotificacion,
          estado: action.payload.estado,
          mensaje: "Ocurrió un problema al realizar la solicitud",
        };
      },
    };
  };
  
  const getAll = () => {
    var { pending, fulfilled, rejected } = getAllMvMotivoNotificacionAsyncThunk;
    return {
      [pending.type]: (state : typeof nominaInitialState) => {
        state.nominaState.mvMotivoNotificacion.estado = DEFAULT_STATE.loading;
      },
      [fulfilled.type]: (state : typeof nominaInitialState, action : PayloadAction<IStatusMvMotivoNotificacion>) => {
        const result = action.payload;
        result.data = state.nominaState.mvMotivoNotificacion.data;
        result.expiry = state.nominaState.mvMotivoNotificacion.expiry;
        if (result.estado === DEFAULT_STATE.error) result.data_array = state.nominaState.mvMotivoNotificacion.data_array;
        state.nominaState.mvMotivoNotificacion = result;
      },
      [rejected.type]: (state : typeof nominaInitialState, action : PayloadAction<IStatusMvMotivoNotificacion>) => {
        state.nominaState.mvMotivoNotificacion = {
          ...state.nominaState.mvMotivoNotificacion,
          estado: action.payload.estado,
          mensaje: "Ocurrió un problema al realizar la solicitud",
        };
      },
    };
  };
  
  const update = () => {
    var { pending, fulfilled, rejected } = updateMvMotivoNotificacionAsyncThunk;
    return {
      [pending.type]: (state : typeof nominaInitialState) => {
        state.nominaState.mvMotivoNotificacion.estado = DEFAULT_STATE.loading;
      },
      [fulfilled.type]: (state : typeof nominaInitialState, action : PayloadAction<IStatusMvMotivoNotificacion>) => {
        const result: IStatusMvMotivoNotificacion = action.payload;
        result.expiry = state.nominaState.mvMotivoNotificacion.expiry;
        result.expiry_array = state.nominaState.mvMotivoNotificacion.expiry_array;
        if (result.estado === DEFAULT_STATE.error) result.data_array = state.nominaState.mvMotivoNotificacion.data_array;
        state.nominaState.mvMotivoNotificacion = result;
      },
      [rejected.type]: (state : typeof nominaInitialState, action : PayloadAction<IStatusMvMotivoNotificacion>) => {
        state.nominaState.mvMotivoNotificacion = {
          ...state.nominaState.mvMotivoNotificacion,
          estado: action.payload.estado,
          mensaje: "Ocurrió un problema al realizar la solicitud",
        };
      },
    };
  };

  const create = () => {
    var { pending, fulfilled, rejected } = createMvMotivoNotificacionAsyncThunk;
    return {
      [pending.type]: (state : typeof nominaInitialState) => {
        state.nominaState.mvMotivoNotificacion.estado = DEFAULT_STATE.loading;
      },
      [fulfilled.type]: (state : typeof nominaInitialState, action : PayloadAction<IStatusMvMotivoNotificacion>) => {
        const result = action.payload;
        result.expiry = state.nominaState.mvMotivoNotificacion.expiry;
        result.expiry_array = state.nominaState.mvMotivoNotificacion.expiry_array;
        if (result.estado === DEFAULT_STATE.error) result.data_array = state.nominaState.mvMotivoNotificacion.data_array;
        state.nominaState.mvMotivoNotificacion = result;
      },
      [rejected.type]: (state : typeof nominaInitialState, action : PayloadAction<IStatusMvMotivoNotificacion>) => {
        state.nominaState.mvMotivoNotificacion = {
          ...state.nominaState.mvMotivoNotificacion,
          estado: action.payload.estado,
          mensaje: "Ocurrió un problema al realizar la solicitud",
        };
      },
    };
  };
  
  const del = () => {
    var { pending, fulfilled, rejected } = deleteMvMotivoNotificacionAsyncThunk;
    return {
      [pending.type]: (state : typeof nominaInitialState) => {
        state.nominaState.mvMotivoNotificacion.estado = DEFAULT_STATE.loading;
      },
      [fulfilled.type]: (state : typeof nominaInitialState, action : PayloadAction<IStatusMvMotivoNotificacion>) => {
        const result: IStatusMvMotivoNotificacion = action.payload;
        result.expiry = state.nominaState.mvMotivoNotificacion.expiry;
        result.expiry_array = state.nominaState.mvMotivoNotificacion.expiry_array;
        if (result.estado === DEFAULT_STATE.error) result.data_array = state.nominaState.mvMotivoNotificacion.data_array;
        state.nominaState.mvMotivoNotificacion = result;
      },
      [rejected.type]: (state : typeof nominaInitialState, action : PayloadAction<IStatusMvMotivoNotificacion>) => {
        state.nominaState.mvMotivoNotificacion = {
          ...state.nominaState.mvMotivoNotificacion,
          estado: action.payload.estado,
          mensaje: "Ocurrió un problema al realizar la solicitud",
        };
      },
    };
  };

  return {
    ...get(),
    ...getAll(),
    ...update(),
    ...create(),
    ...del(),
  };
};
