/**
 * Es el componente utilizado en la página Solicitudes para la visualización, aprobación de rechazo de solicitudes
 * @function updateNotificacionVacaciones actualiza el estado de una solicitud de vacaciones
 * @function updateNotificacionIncapacidades actualiza el estado de una solicitud de incapacidad
 */

import React, { useState, useEffect } from "react";
import {
  IonGrid,
  IonRow,
  IonCol,
  IonLabel,
  IonContent,
  useIonToast,
  IonText,
  IonList,
  IonItem,
  IonSpinner,
  useIonAlert,
  IonButtons,
  IonButton,
  IonIcon,
} from "@ionic/react";

import {
  checkmark,
  close,
  documentsSharp,
  phonePortraitSharp,
} from "ionicons/icons";

import Card from "../components/Card";
import Selector from "../components/Selector";
import useRedux, { useAppSelector } from "../hooks/useRedux";
import {
  getGlobalMemoizedEmpresaAsSelectList,
  getNombreEmpleado,
} from "../redux/slices/personasSlice";
import {
  getMemoizedRequestsDisabilitiesByEmpresa,
  getMemoizedRequestsVacationsByEmpresa,
  getMemoizedRequestsDeviceChangeByEmpresa,
  setRequestsSelectedEmpresa,
} from "../redux/slices/pagesSlice/requestsSlice";
import { IIncapacidad, IVacaciones } from "../redux/interfaces/nomina";
import { ISolicitudCambioTelefonoAutorizado } from "../redux/interfaces/personas";
import { DEFAULT_STATE } from "../redux/enums/default_state";

const Record: React.FC = () => {
  const [
    {
      vacaciones,
      incapacidad,
      solicitudCambioTelefonoAutorizado,
      updateVacations,
      updateIncapacidades,
      updateDeviceChangeRequest,
    },
  ] = useRedux();
  const [present, dismiss] = useIonToast();
  const [presentAlert, dismissAlert] = useIonAlert();

  const empresaList = useAppSelector(getGlobalMemoizedEmpresaAsSelectList);
  const selectedEmpresa = useAppSelector(
    (state) => state.reports.selectedEmpresa
  );

  const disabilitiesList = useAppSelector(
    getMemoizedRequestsDisabilitiesByEmpresa
  );
  const vacationsList = useAppSelector(getMemoizedRequestsVacationsByEmpresa);
  const deviceChangeRequestList = useAppSelector(
    getMemoizedRequestsDeviceChangeByEmpresa
  );

  const updateNotificacionVacaciones = async (
    vacations: IVacaciones,
    accept: boolean
  ) => {
    presentAlert({
      header: `¿Está seguro de ${
        accept ? "aprobar" : "denegar"
      } esta solicitud?`,
      buttons: [
        {
          text: "Aceptar",
          handler(alertData) {
            console.log("alertData", alertData.comentarios);
            if (!alertData.comentarios || !alertData.comentarios.trim()) {
              present({
                message: "Por favor deje un comentario",
                color: "warning",
                duration: 1500,
              });
              return;
            }
            updateVacations(vacations, alertData.comentarios, accept);
            dismissAlert();
          },
        },
        {
          text: "Cancelar",
          role: "cancel",
        },
      ],
      inputs: [
        {
          name: "comentarios",
          type: "textarea",
          placeholder: "Comentarios",
        },
      ],
    });
  };

  const updateNotificacionIncapacidades = async (
    incapacidad: IIncapacidad,
    accept: boolean
  ) => {
    presentAlert({
      header: `¿Está seguro de ${
        accept ? "aprobar" : "denegar"
      } esta solicitud?`,
      buttons: [
        {
          text: "Aceptar",
          handler(alertData) {
            if (!alertData.comentarios || !alertData.comentarios.trim()) {
              present({
                message: "Por favor deje un comentario",
                color: "warning",
                duration: 1500,
              });
              return;
            }
            updateIncapacidades(incapacidad, alertData.comentarios, accept);
            dismissAlert();
          },
        },
        {
          text: "Cancelar",
          role: "cancel",
        },
      ],
      inputs: [
        {
          name: "comentarios",
          type: "textarea",
          placeholder: "Comentarios",
        },
      ],
    });
  };
  const updateNotificacionSolicitudCambioDispositivo = async (
    solicitudCambioTelefonoAutorizado: ISolicitudCambioTelefonoAutorizado,
    accept: boolean
  ) => {
    presentAlert({
      header: `¿Está seguro de ${
        accept ? "aprobar" : "denegar"
      } esta solicitud?`,
      buttons: [
        {
          text: "Aceptar",
          handler(alertData) {
            // if (!alertData.comentarios || !alertData.comentarios.trim()) {
            //   present({
            //     message: "Por favor deje un comentario",
            //     color: "warning",
            //     duration: 1500,
            //   });
            //   return;
            // }
            // updateIncapacidades(incapacidad, alertData.comentarios, accept);
            updateDeviceChangeRequest(
              solicitudCambioTelefonoAutorizado,
              accept
            );
            dismissAlert();
          },
        },
        {
          text: "Cancelar",
          role: "cancel",
        },
      ],
      // inputs: [
      //   {
      //     name: "comentarios",
      //     type: "textarea",
      //     placeholder: "Comentarios",
      //   },
      // ],
    });
  };

  return (
    <IonContent class="inner-content">
      <IonGrid>
        <Card
          icon={documentsSharp}
          title={
            <IonLabel>
              Solicitudes <IonText color="danger">*</IonText>
            </IonLabel>
          }
          subtitle={
            <Selector
              value={selectedEmpresa}
              label="Cuentas/Regiones"
              list={empresaList}
              changeState={setRequestsSelectedEmpresa}
            />
          }
          content={
            <IonGrid class="no-padding">
              <IonRow class="no-padding">
                <IonCol size="12">
                  <IonItem color="secondary" lines="full">
                    <IonLabel>
                      <h3>Solicitud de vacaciones</h3>
                    </IonLabel>
                  </IonItem>
                  <IonList className="selection-area">
                    {vacationsList.map((e) => {
                      return (
                        <IonItem key={`${e.id}-${e.fecha_elaboracion}`}>
                          <IonLabel>
                            <h3>
                              <b>Empleado</b>: {getNombreEmpleado(e.empleado)}
                            </h3>
                            <h3>
                              <b>Descripción</b>: {e.motivo_vacaciones.dato}
                            </h3>
                            <h3>
                              <b>Inicio</b>: {e.fecha_inicio_vacaciones}
                            </h3>
                            <h3>
                              <b>Fin</b>: {e.fecha_fin_vacaciones}
                            </h3>
                            <h3>
                              <b>Fecha elaboración</b>: {e.fecha_elaboracion}
                            </h3>
                            <h3>
                              <b>Estado</b>: {e.estatus_vacaciones.dato}
                            </h3>
                            {e.comentarios_aprobador ? (
                              <h3>
                                <b>Comentarios</b>: {e.comentarios_aprobador}
                              </h3>
                            ) : (
                              <></>
                            )}
                          </IonLabel>
                          <IonButtons slot="end">
                            <IonButton
                              onClick={() =>
                                updateNotificacionVacaciones(e, false)
                              }
                            >
                              <IonIcon
                                color="danger"
                                slot="icon-only"
                                icon={close}
                              />
                            </IonButton>
                            <IonButton
                              onClick={() =>
                                updateNotificacionVacaciones(e, true)
                              }
                            >
                              <IonIcon
                                color="success"
                                slot="icon-only"
                                icon={checkmark}
                              />
                            </IonButton>
                          </IonButtons>
                        </IonItem>
                      );
                    })}
                    {vacaciones.estado === DEFAULT_STATE.loading &&
                    vacationsList.length < 1 ? (
                      <IonSpinner
                        className="loading-center-horizontal"
                        name="circles"
                        color="medium"
                      />
                    ) : vacationsList.length === 0 ? (
                      <IonItem lines="full">
                        <IonLabel>No hay datos</IonLabel>
                      </IonItem>
                    ) : (
                      <></>
                    )}
                  </IonList>
                </IonCol>
                <IonCol size="12">
                  <IonItem color="secondary" lines="full">
                    <IonLabel>
                      <h3>Solicitud de incapacidades</h3>
                    </IonLabel>
                  </IonItem>
                  <IonList className="selection-area">
                    {disabilitiesList.map((e) => {
                      return (
                        <IonItem key={`${e.id}-${e.fecha_elaboracion}`}>
                          <IonLabel>
                            <h3>
                              <b>Empleado</b>: {getNombreEmpleado(e.empleado)}
                            </h3>
                            <h3>
                              <b>Descripción</b>: {e.motivo_incapacidad.dato}
                            </h3>
                            <h3>
                              <b>Inicio</b>: {e.fecha_inicio_incapacidad}
                            </h3>
                            <h3>
                              <b>Fin</b>: {e.fecha_fin_incapacidad}
                            </h3>
                            <h3>
                              <b>Fecha elaboración</b>: {e.fecha_elaboracion}
                            </h3>
                            <h3>
                              <b>Estado</b>: {e.estatus_incapacidad.dato}
                            </h3>
                            {e.comentarios_aprobador ? (
                              <h3>
                                <b>Comentarios</b>: {e.comentarios_aprobador}
                              </h3>
                            ) : (
                              <></>
                            )}
                          </IonLabel>
                          <IonButtons slot="end">
                            <IonButton
                              onClick={() =>
                                updateNotificacionIncapacidades(e, false)
                              }
                            >
                              <IonIcon
                                color="danger"
                                slot="icon-only"
                                icon={close}
                              />
                            </IonButton>
                            <IonButton
                              onClick={() =>
                                updateNotificacionIncapacidades(e, true)
                              }
                            >
                              <IonIcon
                                color="success"
                                slot="icon-only"
                                icon={checkmark}
                              />
                            </IonButton>
                          </IonButtons>
                        </IonItem>
                      );
                    })}
                    {incapacidad.estado === DEFAULT_STATE.loading &&
                    disabilitiesList.length < 1 ? (
                      <IonSpinner
                        className="loading-center-horizontal"
                        name="circles"
                        color="medium"
                      />
                    ) : disabilitiesList.length === 0 ? (
                      <IonItem lines="full">
                        <IonLabel>No hay datos</IonLabel>
                      </IonItem>
                    ) : (
                      <></>
                    )}
                  </IonList>
                </IonCol>
                <IonCol size="12">
                  <IonItem color="secondary" lines="full">
                    <IonLabel>
                      <h3>Solicitud de cambio de dispositivo</h3>
                    </IonLabel>
                  </IonItem>
                  <IonList className="selection-area">
                    {deviceChangeRequestList.map(
                      (e: ISolicitudCambioTelefonoAutorizado) => {
                        return (
                          <IonItem key={`${e.id}-${e.fecha_elaboracion}`}>
                            <IonLabel>
                              <h3>
                                <b>Empleado</b>: {getNombreEmpleado(e.empleado)}
                              </h3>
                              <h3>
                                <b>Id dispositivo anterior</b>:{" "}
                                {e.id_telefono_autorizado_anterior}
                              </h3>
                              <h3>
                                <b>Id dispositivo nuevo</b>:{" "}
                                {e.id_telefono_autorizado_nuevo}
                              </h3>
                              <h3>
                                <b>Fecha elaboración</b>: {e.fecha_elaboracion}
                              </h3>
                              <h3>
                                <b>Estatus</b>: {e.estatus.dato}
                              </h3>
                            </IonLabel>
                            <IonButtons slot="end">
                              <IonButton
                                onClick={() =>
                                  updateNotificacionSolicitudCambioDispositivo(
                                    e,
                                    false
                                  )
                                }
                              >
                                <IonIcon
                                  color="danger"
                                  slot="icon-only"
                                  icon={close}
                                />
                              </IonButton>
                              <IonButton
                                onClick={() =>
                                  updateNotificacionSolicitudCambioDispositivo(
                                    e,
                                    true
                                  )
                                }
                              >
                                <IonIcon
                                  color="success"
                                  slot="icon-only"
                                  icon={checkmark}
                                />
                              </IonButton>
                            </IonButtons>
                          </IonItem>
                        );
                      }
                    )}
                    {solicitudCambioTelefonoAutorizado.estado ===
                      DEFAULT_STATE.loading &&
                    deviceChangeRequestList.length < 1 ? (
                      <IonSpinner
                        className="loading-center-horizontal"
                        name="circles"
                        color="medium"
                      />
                    ) : deviceChangeRequestList.length === 0 ? (
                      <IonItem lines="full">
                        <IonLabel>No hay datos</IonLabel>
                      </IonItem>
                    ) : (
                      <></>
                    )}
                  </IonList>
                </IonCol>
              </IonRow>
            </IonGrid>
          }
        ></Card>
      </IonGrid>
    </IonContent>
  );
};

export default Record;
