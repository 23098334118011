/**
 * Es el modal para la recuperación de la contraseña cuando no se ha iniciado sesión.
 * @interface PasswordModal
 *   @param {string} email el correo del usuario para evíar el correo de recuperación de contraseña
 *   @param {Function} onDismiss método que se realiza al cerrar el modal
 *   @param {Function} onChangeCorreo método que actualiza el valor del state del correo
 *   @param {Function} onSave método para enviar el correo de recuperación de contraseña
 */

import React from 'react';
import {  IonButton, IonItem, IonInput, IonLabel, IonGrid, IonRow, IonCol, IonIcon, IonToggle, IonContent } from '@ionic/react';

import {
  closeCircleSharp,
  checkmarkCircle
} from 'ionicons/icons';
import "./PasswordModal.css"  

const PasswordModal: React.FC<{
  email: string;
  onDismiss: () => void;
  onChangeCorreo: (data: string) => void;
  onSave(): () => void;
}> = ({
  email, 
  onDismiss,
  onChangeCorreo,
  onSave
 }) => (
  <IonContent>
    <IonGrid className="no-padding-modal">
      <IonRow className="ion-justify-content-center ion-text-center ion-align-self-center no-padding-modal">
        <IonCol className="no-padding-modal">
          <IonItem color="primary">
            <IonLabel>
              Escribe el correo:
            </IonLabel>
          </IonItem>
        </IonCol>
        <IonCol size="12" className="no-padding-modal">
          <IonItem>
            <IonLabel position="floating">Correo</IonLabel>
            <IonInput type="email" value={email} placeholder="Correo" onIonChange={e => onChangeCorreo(e.detail.value!)}></IonInput>
          </IonItem>
        </IonCol>
        <IonCol size="6">
          <IonButton className='button' expand="block" color="tertiary" onClick={() => onSave()}>
            <IonIcon slot="icon-only" icon={checkmarkCircle} />Enviar
          </IonButton>
        </IonCol>
        <IonCol size="6">
          <IonButton className='button' expand="block"  color="danger" onClick={() => onDismiss()}>
            <IonIcon slot="icon-only" icon={closeCircleSharp} />Cancelar
          </IonButton>
        </IonCol>
      </IonRow>
    </IonGrid>
  </IonContent>
);

export default PasswordModal;

