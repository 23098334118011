/**
 * Métodos para el manejo de IonicStorage 
 * @setStorage              guarda un unico objeto al storage
 * @getStorage              obtiene el objeto guardado con @setStorage
 * @setStorageArray         guarda el array de objetos en el storage
 * @getStorageArray         obtiene el array de objetos guardado con @setStorageArray
 * @removeStorageArray      elimina el array de objetos guardado con @setStorageArray
 * @setStorageItemArray     agrega un elemento al array de objeto guardado con @setStorageArray
 * @getStorageItemArray     obtiene un elemento del array guardado con @setStorageArray buscandolo por su id 
 * @removeStorageItemArray  elimina un elemento del array guardado con @setStorageArray buscandolo por su id 
 */

import { setStorageData, getStorageData, removeStorageData, setArrayItemStorageData, getArrayItemStorageData, removeArrayItemStorageData } from "../../api/storage";
import { IMvTipoNotificacion, getEmptyMvTipoNotificacion } from "../../interfaces/nomina";

export const setStorageMvTipoNotificacion = async (IMvTipoNotificacion : IMvTipoNotificacion) => await setStorageData('IMvTipoNotificacion', IMvTipoNotificacion);
export const getStorageMvTipoNotificacion = async () : Promise<IMvTipoNotificacion> => await getStorageData('IMvTipoNotificacion', getEmptyMvTipoNotificacion());
export const removeStorageMvTipoNotificacion = async () => await removeStorageData('IMvTipoNotificacion');
export const setStorageMvTipoNotificacionArray = async (IMvTipoNotificacion : IMvTipoNotificacion[]) => await setStorageData('IMvTipoNotificacionArray', IMvTipoNotificacion);
export const getStorageMvTipoNotificacionArray = async () : Promise<IMvTipoNotificacion[]> => await getStorageData('IMvTipoNotificacionArray', [] as IMvTipoNotificacion[]);
export const removeStorageMvTipoNotificacionArray = async () => await removeStorageData('IMvTipoNotificacionArray');
export const setStorageMvTipoNotificacionArrayItem = async (id: number, IMvTipoNotificacion : IMvTipoNotificacion) : Promise<IMvTipoNotificacion[]> => await setArrayItemStorageData('IMvTipoNotificacionArray', id, IMvTipoNotificacion, [] as IMvTipoNotificacion[]);
export const getStorageMvTipoNotificacionArrayItem = async (id: number) : Promise<IMvTipoNotificacion> => await getArrayItemStorageData('IMvTipoNotificacionArray', id, getEmptyMvTipoNotificacion);
export const removeStorageMvTipoNotificacionArrayItem = async (id: number) : Promise<IMvTipoNotificacion[]> => await removeArrayItemStorageData('IMvTipoNotificacionArray', id, [] as IMvTipoNotificacion[]);
