/**
 * Métodos del, get, post, put para llamadas a la API
 * @get     en este método primero consulta con @getFromLocalStorage si es necesario hacer la petición http sino se obtiene del LocalStorage;
 *          si hay un error en la petición http se busca el objeto en el LocalStorage sino lo encuentra devuelve un objeto vacío; si la
 *          petición se realiza correctamente actualiza el valor individual y en el array del objeto en el LocalStorage y devuelve el objeto
 *          obtenido del servidor.
 * @getAll  en este método primero consulta con @getFromLocalStorage si es necesario hacer la petición http sino se obtiene del LocalStorage;
 *          si hay un error en la petición http se busca el objeto array en el LocalStorage sino lo encuentra devuelve un array vacío; si la
 *          petición se realiza correctamente actualiza array en el LocalStorage y devuelve el array obtenido del servidor
 * @update  en este método después de realizar la petición http si falla devuelve el objeto que se iba a actualizar; si la petición se
 *          realiza correctamente actualiza el valor individual y en el array del objeto en el LocalStorage y devuelve el objeto obtenido del
 *          servidor.
 * @create  en este método después de realizar la petición http si falla devuelve el objeto que se iba a crear; si la petición se realiza
 *          correctamente actualiza el valor individual y en el array del objeto en el LocalStorage y devuelve el objeto obtenido del
 *          servidor.
 * @delete  en este método después de realizar la petición http si falla devuelve el objeto que se iba a eliminar; si la petición se realiza
 *          correctamente elimina el valor individual y en el objeto en el arrya del LocalStorage y devuelve un objeto vacio (Default)
 */

import { del, get, post, put } from "../../api/api";
import {
  getFromLocalStorage,
  updateLocalStorageExpiry,
} from "../../api/helpers";
import { removeArrayItemStorageData } from "../../api/storage";
import { DEFAULT_STATE } from "../../enums/default_state";
import {
  getEmptyStatusMvTipoDocumento,
  setMvTipoDocumento,
  IMvTipoDocumento,
  getSerializableMvTipoDocumento,
} from "../../interfaces/personas";
import { IServerDate } from "../../interfaces/time";
import {
  getStorageMvTipoDocumentoArrayItem,
  setStorageMvTipoDocumento,
  setStorageMvTipoDocumentoArrayItem,
  getStorageMvTipoDocumentoArray,
  setStorageMvTipoDocumentoArray,
  removeStorageMvTipoDocumento,
} from "../../localStorage/personas";

export const getMvTipoDocumento = async (
  serverDate: IServerDate,
  pk: number
) => {
  const temp = getEmptyStatusMvTipoDocumento();
  const doHttp = await getFromLocalStorage(serverDate, temp.expiry);
  temp.expiry = doHttp.storage;
  if (!doHttp.result) {
    console.log(
      `(localStorage) MvTipoDocumento con pk: ${pk}, obtenido con éxito`
    );
    temp.estado = DEFAULT_STATE.success;
    temp.mensaje = `MvTipoDocumento obtenido con éxito`;
    temp.data = await getStorageMvTipoDocumentoArrayItem(pk);
    return temp;
  }
  const result = await get(`personas/tipo-documento/${pk}`);
  const data = result.data;
  console.log(`MvTipoDocumento data: `, data);
  if (!data.id || data.detail) {
    console.log(
      `Error al obtener MvTipoDocumento con pk: ${pk}, ${data.detail}`
    );
    temp.estado = DEFAULT_STATE.error;
    temp.mensaje = `Error al obtener el tipo de documento: ${
      data.detail ?? "Hubo un problema al conectarse con el servidor"
    }`;
    temp.data = await getStorageMvTipoDocumentoArrayItem(pk);
    return temp;
  }
  console.log(`MvTipoDocumento con pk: ${pk}, obtenido con éxito`);
  temp.estado = DEFAULT_STATE.success;
  temp.mensaje = `MvTipoDocumento obtenido con éxito`;
  temp.data = await setMvTipoDocumento(data, serverDate);
  await setStorageMvTipoDocumento(temp.data);
  temp.data_array = await setStorageMvTipoDocumentoArrayItem(pk, temp.data);
  temp.expiry = await updateLocalStorageExpiry(serverDate, temp.expiry);
  return temp;
};

export const getAllMvTipoDocumento = async (serverDate: IServerDate) => {
  const temp = getEmptyStatusMvTipoDocumento();
  const doHttp = await getFromLocalStorage(serverDate, temp.expiry_array);
  temp.expiry_array = doHttp.storage;
  if (!doHttp.result) {
    console.log(
      `(localStorage) Todos los MvTipoDocumentos obtenidos con éxito`
    );
    temp.estado = DEFAULT_STATE.success;
    temp.mensaje = `Todos los tipo de documentos obtenidos con éxito`;
    temp.data_array = await getStorageMvTipoDocumentoArray();
    return temp;
  }
  const result = await get(`personas/tipo-documento`);
  const data = result.data;
  if (data.detail) {
    console.log(`Error al obtener la lista de MvTipoDocumento, ${data.detail}`);
    temp.estado = DEFAULT_STATE.error;
    temp.mensaje = `Error al obtener la lista de tipo de documentos: ${
      data.detail ?? "Hubo un problema al conectarse con el servidor"
    }`;
    temp.data_array = await getStorageMvTipoDocumentoArray();
    return temp;
  }
  console.log(`Todos los MvTipoDocumentoes obtenidos con éxito`);
  temp.estado = DEFAULT_STATE.success;
  temp.mensaje = `Todos los tipo de documentos obtenidos con éxito`;
  temp.data_array = await Promise.all(
    data.map(async (e: any) => await setMvTipoDocumento(e, serverDate))
  );
  await setStorageMvTipoDocumentoArray(temp.data_array);
  temp.expiry_array = await updateLocalStorageExpiry(
    serverDate,
    temp.expiry_array
  );
  return temp;
};

export const updateMvTipoDocumento = async (
  serverDate: IServerDate,
  pk: number,
  object: IMvTipoDocumento
) => {
  const temp = getEmptyStatusMvTipoDocumento();
  const result = await put(
    `personas/tipo-documento/${pk}`,
    getSerializableMvTipoDocumento(object)
  );
  const data = result.data;
  if (!data.id || data.detail) {
    console.log(
      `Error al actualizar MvTipoDocumento con pk: ${pk}, ${data.detail}`
    );
    temp.estado = DEFAULT_STATE.error;
    temp.mensaje = `Error al actualizar el tipo de documento: ${
      data.detail ?? "Hubo un problema al conectarse con el servidor"
    }`;
    temp.data = await setMvTipoDocumento(object, serverDate);
    return temp;
  }
  console.log(`MvTipoDocumento con pk: ${pk}, actualizado con éxito`);
  temp.estado = DEFAULT_STATE.success;
  temp.mensaje = `MvTipoDocumento actualizado con éxito`;
  temp.data = await setMvTipoDocumento(data, serverDate);
  await setStorageMvTipoDocumento(temp.data);
  temp.data_array = await setStorageMvTipoDocumentoArrayItem(
    temp.data.id,
    temp.data
  );
  return temp;
};

export const createMvTipoDocumento = async (
  serverDate: IServerDate,
  object: IMvTipoDocumento
) => {
  const temp = getEmptyStatusMvTipoDocumento();
  const result = await post(
    `personas/tipo-documento`,
    getSerializableMvTipoDocumento(object)
  );
  const data = result.data;
  if (!data.id || data.detail) {
    console.log(
      `Error al crear MvTipoDocumento con los datos ${object}, ${data.detail}`
    );
    temp.estado = DEFAULT_STATE.error;
    temp.mensaje = `Error al crear el tipo de documento: ${
      data.detail ?? "Hubo un problema al conectarse con el servidor"
    }`;
    temp.data = await setMvTipoDocumento(object, serverDate);
    return temp;
  }
  console.log(`MvTipoDocumento creado con éxito`);
  temp.estado = DEFAULT_STATE.success;
  temp.mensaje = `MvTipoDocumento creado con éxito`;
  temp.data = await setMvTipoDocumento(data, serverDate);
  await setStorageMvTipoDocumento(temp.data);
  temp.data_array = await setStorageMvTipoDocumentoArrayItem(
    temp.data.id,
    temp.data
  );
  return temp;
};

export const deleteMvTipoDocumento = async (pk: number) => {
  const temp = getEmptyStatusMvTipoDocumento();
  const result = await del(`personas/tipo-documento/${pk}`);
  const data = result.data;
  if (data.detail) {
    console.log(
      `Error al eliminar MvTipoDocumento con pk: ${pk}, ${data.detail}`
    );
    temp.estado = DEFAULT_STATE.error;
    temp.mensaje = `Error al eliminar el tipo de documento: ${data.detail}`;
    temp.data = await getStorageMvTipoDocumentoArrayItem(pk);
    temp.data_array = await getStorageMvTipoDocumentoArray();
    return temp;
  }
  console.log(`MvTipoDocumento eliminado con éxito`);
  temp.estado = DEFAULT_STATE.success;
  temp.mensaje = `MvTipoDocumento eliminado con éxito`;
  await removeStorageMvTipoDocumento();
  temp.data_array = await removeArrayItemStorageData(
    "IMvTipoDocumento",
    pk,
    []
  );
  return temp;
};
