/**
 * Métodos para el manejo de IonicStorage 
 * @setStorage              guarda un unico objeto al storage
 * @getStorage              obtiene el objeto guardado con @setStorage
 * @setStorageArray         guarda el array de objetos en el storage
 * @getStorageArray         obtiene el array de objetos guardado con @setStorageArray
 * @removeStorageArray      elimina el array de objetos guardado con @setStorageArray
 * @setStorageItemArray     agrega un elemento al array de objeto guardado con @setStorageArray
 * @getStorageItemArray     obtiene un elemento del array guardado con @setStorageArray buscandolo por su id 
 * @removeStorageItemArray  elimina un elemento del array guardado con @setStorageArray buscandolo por su id 
 */

import { setStorageData, getStorageData, removeStorageData, setArrayItemStorageData, getArrayItemStorageData, removeArrayItemStorageData } from "../../api/storage";
import { IEstado, getEmptyEstado } from "../../interfaces/personas";

export const setStorageEstado = async (IEstado : IEstado) => await setStorageData('IEstado', IEstado);
export const getStorageEstado = async () : Promise<IEstado> => await getStorageData('IEstado', getEmptyEstado());
export const removeStorageEstado = async () => await removeStorageData('IEstado');
export const setStorageEstadoArray = async (IEstado : IEstado[]) => await setStorageData('IEstadoArray', IEstado);
export const getStorageEstadoArray = async () : Promise<IEstado[]> => await getStorageData('IEstadoArray', [] as IEstado[]);
export const removeStorageEstadoArray = async () => await removeStorageData('IEstadoArray');
export const setStorageEstadoArrayItem = async (id: number, IEstado : IEstado) : Promise<IEstado[]> => await setArrayItemStorageData('IEstadoArray', id, IEstado, [] as IEstado[]);
export const getStorageEstadoArrayItem = async (id: number) : Promise<IEstado> => await getArrayItemStorageData('IEstadoArray', id, getEmptyEstado);
export const removeStorageEstadoArrayItem = async (id: number) : Promise<IEstado[]> => await removeArrayItemStorageData('IEstadoArray', id, [] as IEstado[]);
