/**
 * Métodos AsyncThunk para ser usados en los reducers y
 * los resultados a las llamadas http puedan ser usados para
 * actualizar el state en redux
 */

import { createAsyncThunk } from "@reduxjs/toolkit";
import { DEFAULT_STATE } from "../../enums/default_state";
import { getMvTipoHorasExtra, getAllMvTipoHorasExtra, updateMvTipoHorasExtra, createMvTipoHorasExtra, deleteMvTipoHorasExtra } from "../../http/nomina";
import { getEmptyStatusMvTipoHorasExtra, IMvTipoHorasExtra } from "../../interfaces/nomina";
import { IServerDate } from "../../interfaces/time";

export const getMvTipoHorasExtraAsyncThunk = createAsyncThunk(
  'nomina/getMvTipoHorasExtraAsyncThunk',
  async (query : {serverDate : IServerDate, id: number}) => await getMvTipoHorasExtra(query.serverDate, query.id)
    .then(async (result) => result)
    .catch(async (error) => getEmptyStatusMvTipoHorasExtra(DEFAULT_STATE.error))  
);

export const getAllMvTipoHorasExtraAsyncThunk = createAsyncThunk(
  'nomina/getAllMvTipoHorasExtraAsyncThunk',
  async (queryAll : {serverDate : IServerDate}) => await getAllMvTipoHorasExtra(queryAll.serverDate)
    .then(async (result) => result)
    .catch(async (error) => getEmptyStatusMvTipoHorasExtra(DEFAULT_STATE.error))  
);

export const updateMvTipoHorasExtraAsyncThunk = createAsyncThunk(
  'nomina/updateMvTipoHorasExtraAsyncThunk',
  async (update: {serverDate : IServerDate, id: number, MvTipoHorasExtra: IMvTipoHorasExtra}) => await updateMvTipoHorasExtra(update.serverDate, update.id, update.MvTipoHorasExtra)
    .then(async (result) => result)
    .catch(async (error) => getEmptyStatusMvTipoHorasExtra(DEFAULT_STATE.error))  
);

export const createMvTipoHorasExtraAsyncThunk = createAsyncThunk(
  'nomina/createMvTipoHorasExtraAsyncThunk',
  async (create : {serverDate : IServerDate, MvTipoHorasExtra: IMvTipoHorasExtra}) => await createMvTipoHorasExtra(create.serverDate, create.MvTipoHorasExtra)
    .then(async (result) => result)
    .catch(async (error) => getEmptyStatusMvTipoHorasExtra(DEFAULT_STATE.error))  
);

export const deleteMvTipoHorasExtraAsyncThunk = createAsyncThunk(
  'nomina/deleteMvTipoHorasExtraAsyncThunk',
  async (del : {id : number}) => await deleteMvTipoHorasExtra(del.id)
    .then(async (result) => result)
    .catch(async (error) => getEmptyStatusMvTipoHorasExtra())  
);
