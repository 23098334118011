/** 
 * Componente que se encarga del elemento DatosPersonales
 * @IDatosPersonales es la interfaz que contiene los parámetos el elemento DatosPersonales
 * @getEmptyDatosPersonales  devuelve un objeto con los parámetros por defecto; @param {id}, opcional cuando no encuentra el objeto en el LocalSotrage
 * @IStatusDatosPersonales es la interfaz para los estatus a llamadas a la API; @param {status}, opcional cuando queremos otro estado de inizialización de tipo [DEFAULT_STATE]
 * @getEmptyStatusDatosPersonales devuelve un objeto con los parámetros por defecto,
 * @setDatosPersonales devuelve un objeto de tipo [IDatosPersonales] recibiendo @param {object} y @param {serverDate}, para verificar que los datos estén en el formato correcto y ajustarlos a la zona horaria actual
 * @getSerializableDatosPersonales recibe un @param {IDatosPersonales}, para devolver un objeto serizalizable para las peticiones de la API
 * */

import { getDateString, getFormattedDateString, isNotEmpty, SERVER_TIME_ZONE } from "../../api/helpers";
import { DEFAULT_STATE } from "../../enums/default_state";
import { getStorageDireccionArrayItem, getStorageMvGeneroArrayItem, getStorageMvEstadoCivilArrayItem, getStorageUserArrayItem, getStorageClienteQentryArrayItem } from "../../localStorage/personas";
import { convertDateToZonaHoraria, ILocalStorageExpiry, getEmptyLocalStorageExpiry, IServerDate } from "../time";
import { getEmptyClienteQentry, IClienteQentry, setClienteQentry } from "./IClienteQentry";
import { IDireccion, getEmptyDireccion, setDireccion } from "./IDireccion";
import { IMvEstadoCivil, getEmptyMvEstadoCivil, setMvEstadoCivil } from "./IMvEstadoCivil";
import { IMvGenero, getEmptyMvGenero, setMvGenero } from "./IMvGenero";
import { IUser, getEmptyUser, setUser } from "./IUser";

export interface IDatosPersonales {
  id : number,
  primer_nombre : string,
  segundo_nombre : string,
  apellido_paterno : string,
  apellido_materno : string,
  fecha_nacimiento : string, //Fecha (Sin horas)
  edad : number,
  numero_identidad_CURP : string,
  email : string,
  telefono_casa : string,
  telefono_movil : string,
  direccion : IDireccion,
  genero : IMvGenero,
  estado_civil : IMvEstadoCivil,
  autor : IUser,
  fecha_elaboracion : string, //Fecha
  estatus_sistema : boolean,
  ultima_actualizacion : string, //Fecha
  cliente_qentry : IClienteQentry,
}

export const getEmptyDatosPersonales = (id? : number) => {
  const temp : IDatosPersonales = 
  {
    id : id ? id : 0,
    primer_nombre : "",
    segundo_nombre : "",
    apellido_paterno : "",
    apellido_materno : "",
    fecha_nacimiento : "",
    edad : 0,
    numero_identidad_CURP : "",
    email : "",
    telefono_casa : "",
    telefono_movil : "",
    direccion : getEmptyDireccion(),
    genero : getEmptyMvGenero(),
    estado_civil : getEmptyMvEstadoCivil(),
    autor : getEmptyUser(),
    fecha_elaboracion : "",
    estatus_sistema : false,
    ultima_actualizacion : "",
    cliente_qentry : getEmptyClienteQentry(),
  };
  return temp;
}

export interface IStatusDatosPersonales {
  estado : DEFAULT_STATE,
  mensaje : string,
  data : IDatosPersonales,
  data_array: IDatosPersonales[],
  expiry : ILocalStorageExpiry,
  expiry_array : ILocalStorageExpiry,
}

export const getEmptyStatusDatosPersonales = (status: DEFAULT_STATE = DEFAULT_STATE.loading) => {
  const temp : IStatusDatosPersonales = 
  {
    estado : status,
    mensaje : "",
    data : getEmptyDatosPersonales(),
    data_array : [],
    expiry : getEmptyLocalStorageExpiry("IDatosPersonales", 0),
    expiry_array : getEmptyLocalStorageExpiry("IDatosPersonalesArray", 0),
  };
  return temp;
}

export const setDatosPersonales = async (
  object : any,
  serverDate : IServerDate,
) => {
  const temp = getEmptyDatosPersonales();
  if (isNotEmpty(object.id)) temp.id = object.id;
  if (isNotEmpty(object.primer_nombre)) temp.primer_nombre = object.primer_nombre;
  if (isNotEmpty(object.segundo_nombre)) temp.segundo_nombre = object.segundo_nombre;
  if (isNotEmpty(object.apellido_paterno)) temp.apellido_paterno = object.apellido_paterno;
  if (isNotEmpty(object.apellido_materno)) temp.apellido_materno = object.apellido_materno;
  if (isNotEmpty(object.fecha_nacimiento)) temp.fecha_nacimiento = object.fecha_nacimiento;
  if (isNotEmpty(object.edad)) temp.edad = object.edad;
  if (isNotEmpty(object.numero_identidad_CURP)) temp.numero_identidad_CURP = object.numero_identidad_CURP;
  if (isNotEmpty(object.email)) temp.email = object.email;
  if (isNotEmpty(object.telefono_casa)) temp.telefono_casa = object.telefono_casa;
  if (isNotEmpty(object.telefono_movil)) temp.telefono_movil = object.telefono_movil;
  if (isNotEmpty(object.direccion)) temp.direccion = (typeof object.direccion === 'number') ? await getStorageDireccionArrayItem(object.direccion) : await setDireccion(object.direccion, serverDate);
  if (isNotEmpty(object.genero)) temp.genero = (typeof object.genero === 'number') ? await getStorageMvGeneroArrayItem(object.genero) : await setMvGenero(object.genero, serverDate);
  if (isNotEmpty(object.estado_civil)) temp.estado_civil = (typeof object.estado_civil === 'number') ? await getStorageMvEstadoCivilArrayItem(object.estado_civil) : await setMvEstadoCivil(object.estado_civil, serverDate);
  if (isNotEmpty(object.autor)) temp.autor = (typeof object.autor === 'number') ? await getStorageUserArrayItem(object.autor) : await setUser(object.autor, serverDate);
  if (isNotEmpty(object.fecha_elaboracion)) temp.fecha_elaboracion = convertDateToZonaHoraria(serverDate, object.fecha_elaboracion);
  if (isNotEmpty(object.estatus_sistema)) temp.estatus_sistema = object.estatus_sistema;
  if (isNotEmpty(object.ultima_actualizacion)) temp.ultima_actualizacion = convertDateToZonaHoraria(serverDate, object.ultima_actualizacion);
  if (isNotEmpty(object.cliente_qentry)) temp.cliente_qentry = (typeof object.cliente_qentry === 'number') ? await getStorageClienteQentryArrayItem(object.cliente_qentry) : await setClienteQentry(object.cliente_qentry, serverDate);
  return temp;

}

export const getSerializableDatosPersonales = (IDatosPersonales : IDatosPersonales) => {
  return {
    id : IDatosPersonales.id,
    primer_nombre : IDatosPersonales.primer_nombre,
    segundo_nombre : IDatosPersonales.segundo_nombre,
    apellido_paterno : IDatosPersonales.apellido_paterno,
    apellido_materno : IDatosPersonales.apellido_materno,
    fecha_nacimiento : IDatosPersonales.fecha_nacimiento ? getFormattedDateString(SERVER_TIME_ZONE, IDatosPersonales.fecha_nacimiento) : null,
    edad : IDatosPersonales.edad,
    numero_identidad_CURP : IDatosPersonales.numero_identidad_CURP,
    email : IDatosPersonales.email,
    telefono_casa : IDatosPersonales.telefono_casa,
    telefono_movil : IDatosPersonales.telefono_movil,
    direccion : IDatosPersonales.direccion.id > 0 ? IDatosPersonales.direccion.id : null,
    genero : IDatosPersonales.genero.id > 0 ? IDatosPersonales.genero.id : null,
    estado_civil : IDatosPersonales.estado_civil.id > 0 ? IDatosPersonales.estado_civil.id : null,
    autor : IDatosPersonales.autor.id,
    fecha_elaboracion : IDatosPersonales.fecha_elaboracion,
    estatus_sistema : IDatosPersonales.estatus_sistema,
    ultima_actualizacion : getDateString(SERVER_TIME_ZONE),
    cliente_qentry : IDatosPersonales.cliente_qentry.id,
  };
}
