/**
 * Métodos AsyncThunk para ser usados en los reducers y
 * los resultados a las llamadas http puedan ser usados para
 * actualizar el state en redux
 */

import { createAsyncThunk } from "@reduxjs/toolkit";
import { DEFAULT_STATE } from "../../enums/default_state";
import { getCodigoPostal, getAllCodigoPostal, updateCodigoPostal, createCodigoPostal, deleteCodigoPostal } from "../../http/personas";
import { getEmptyStatusCodigoPostal, ICodigoPostal } from "../../interfaces/personas";
import { IServerDate } from "../../interfaces/time";

export const getCodigoPostalAsyncThunk = createAsyncThunk(
  'personas/getCodigoPostalAsyncThunk',
  async (query : {serverDate : IServerDate, id: number}) => await getCodigoPostal(query.serverDate, query.id)
    .then(async (result) => result)
    .catch(async (error) => getEmptyStatusCodigoPostal(DEFAULT_STATE.error))  
);

export const getAllCodigoPostalAsyncThunk = createAsyncThunk(
  'personas/getAllCodigoPostalAsyncThunk',
  async (queryAll : {serverDate : IServerDate}) => await getAllCodigoPostal(queryAll.serverDate)
    .then(async (result) => result)
    .catch(async (error) => getEmptyStatusCodigoPostal(DEFAULT_STATE.error))  
);

export const updateCodigoPostalAsyncThunk = createAsyncThunk(
  'personas/updateCodigoPostalAsyncThunk',
  async (update: {serverDate : IServerDate, id: number, CodigoPostal: ICodigoPostal}) => await updateCodigoPostal(update.serverDate, update.id, update.CodigoPostal)
    .then(async (result) => result)
    .catch(async (error) => getEmptyStatusCodigoPostal(DEFAULT_STATE.error))  
);

export const createCodigoPostalAsyncThunk = createAsyncThunk(
  'personas/createCodigoPostalAsyncThunk',
  async (create : {serverDate : IServerDate, CodigoPostal: ICodigoPostal}) => await createCodigoPostal(create.serverDate, create.CodigoPostal)
    .then(async (result) => result)
    .catch(async (error) => getEmptyStatusCodigoPostal(DEFAULT_STATE.error))  
);

export const deleteCodigoPostalAsyncThunk = createAsyncThunk(
  'personas/deleteCodigoPostalAsyncThunk',
  async (del : {id : number}) => await deleteCodigoPostal(del.id)
    .then(async (result) => result)
    .catch(async (error) => getEmptyStatusCodigoPostal())  
);
