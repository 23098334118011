/**
 * Métodos AsyncThunk para ser usados en los reducers y
 * los resultados a las llamadas http puedan ser usados para
 * actualizar el state en redux
 */

import { createAsyncThunk } from "@reduxjs/toolkit";
import { DEFAULT_STATE } from "../../enums/default_state";
import { activateCentroDeTrabajo, activateEmpleado, createMultipleCentroDeTrabajo, createMultipleEmpleado, deactivateCentroDeTrabajo, deactivateEmpleado, deleteMultipleCentroDeTrabajo, deleteMultipleEmpleado } from "../../http/personas";
import { getEmptyStatusCentroDeTrabajo, getEmptyStatusEmpleado } from "../../interfaces/personas";
import { IServerDate } from "../../interfaces/time";
import { ICreateEmpleado, ICreateEmpleadoData } from "../../slices/pagesSlice/teamMembersSlice";
import { ICreateCentroDeTrabajoData } from "../../slices/pagesSlice/workCentersSlice";

export const deactivateEmpleadoAsyncThunk = createAsyncThunk(
  'personas/deactivateEmpleadoAsyncThunk',
  async (queryAll : {serverDate : IServerDate, idCliente : number, ids: number[]}) => await deactivateEmpleado(queryAll.serverDate, queryAll.idCliente, queryAll.ids)
    .then(async (result) => result)
    .catch(async (error) => getEmptyStatusEmpleado(DEFAULT_STATE.error))  
);

export const activateEmpleadoAsyncThunk = createAsyncThunk(
  'personas/activateEmpleadoAsyncThunk',
  async (queryAll : {serverDate : IServerDate, idCliente : number, ids: number[]}) => await activateEmpleado(queryAll.serverDate, queryAll.idCliente, queryAll.ids)
    .then(async (result) => result)
    .catch(async (error) => getEmptyStatusEmpleado(DEFAULT_STATE.error))  
);

export const deleteMultipleEmpleadoAsyncThunk = createAsyncThunk(
  'personas/deleteMultipleEmpleadoAsyncThunk',
  async (queryAll : {serverDate : IServerDate, idCliente : number, ids: number[]}) => await deleteMultipleEmpleado(queryAll.serverDate, queryAll.idCliente, queryAll.ids)
    .then(async (result) => result)
    .catch(async (error) => getEmptyStatusEmpleado(DEFAULT_STATE.error))  
);

export const createMultipleEmpleadoAsyncThunk = createAsyncThunk(
  'personas/createMultipleEmpleadoAsyncThunk',
  async (queryAll : {serverDate : IServerDate, idCliente : number, create: ICreateEmpleadoData[]}) => await createMultipleEmpleado(queryAll.serverDate, queryAll.idCliente, queryAll.create)
    .then(async (result) => result)
    .catch(async (error) => getEmptyStatusEmpleado(DEFAULT_STATE.error))  
);
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export const deactivateCentroDeTrabajoAsyncThunk = createAsyncThunk(
  'personas/deactivateCentroDeTrabajoAsyncThunk',
  async (queryAll : {serverDate : IServerDate, idCliente : number, ids: number[]}) => await deactivateCentroDeTrabajo(queryAll.serverDate, queryAll.idCliente, queryAll.ids)
    .then(async (result) => result)
    .catch(async (error) => getEmptyStatusCentroDeTrabajo(DEFAULT_STATE.error))  
);

export const activateCentroDeTrabajoAsyncThunk = createAsyncThunk(
  'personas/activateCentroDeTrabajoAsyncThunk',
  async (queryAll : {serverDate : IServerDate, idCliente : number, ids: number[]}) => await activateCentroDeTrabajo(queryAll.serverDate, queryAll.idCliente, queryAll.ids)
    .then(async (result) => result)
    .catch(async (error) => getEmptyStatusCentroDeTrabajo(DEFAULT_STATE.error))  
);

export const deleteMultipleCentroDeTrabajoAsyncThunk = createAsyncThunk(
  'personas/deleteMultipleCentroDeTrabajoAsyncThunk',
  async (queryAll : {serverDate : IServerDate, idCliente : number, ids: number[]}) => await deleteMultipleCentroDeTrabajo(queryAll.serverDate, queryAll.idCliente, queryAll.ids)
    .then(async (result) => result)
    .catch(async (error) => getEmptyStatusCentroDeTrabajo(DEFAULT_STATE.error))  
);

export const createMultipleCentroDeTrabajoAsyncThunk = createAsyncThunk(
  'personas/createMultipleCentroDeTrabajoAsyncThunk',
  async (queryAll : {serverDate : IServerDate, idCliente : number, create: ICreateCentroDeTrabajoData[]}) => await createMultipleCentroDeTrabajo(queryAll.serverDate, queryAll.idCliente, queryAll.create)
    .then(async (result) => result)
    .catch(async (error) => getEmptyStatusCentroDeTrabajo(DEFAULT_STATE.error))  
);
