import { getFormattedDateString } from "../../api/helpers";
import { IServerDate } from "./IServerDate";

export interface ILocalStorageExpiry {
  interface_name : string;
  today : string;
  last_update : string;
  update_in_days : number;
}

export const getEmptyLocalStorageExpiry = (interface_name? : string, update_in_days? : number, serverDate?: IServerDate) => {
  const temp: ILocalStorageExpiry = 
  {
    interface_name : interface_name ? interface_name : "",
    today : getFormattedDateString(serverDate?.zona_horaria),
    last_update : "",
    update_in_days : update_in_days ? update_in_days : 0,
  };
  return temp;
}
