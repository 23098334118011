/**
 * Métodos para el manejo de IonicStorage 
 * @setStorage              guarda un unico objeto al storage
 * @getStorage              obtiene el objeto guardado con @setStorage
 * @setStorageArray         guarda el array de objetos en el storage
 * @getStorageArray         obtiene el array de objetos guardado con @setStorageArray
 * @removeStorageArray      elimina el array de objetos guardado con @setStorageArray
 * @setStorageItemArray     agrega un elemento al array de objeto guardado con @setStorageArray
 * @getStorageItemArray     obtiene un elemento del array guardado con @setStorageArray buscandolo por su id 
 * @removeStorageItemArray  elimina un elemento del array guardado con @setStorageArray buscandolo por su id 
 */

import { setStorageData, getStorageData, removeStorageData, setArrayItemStorageData, getArrayItemStorageData, removeArrayItemStorageData } from "../../api/storage";
import { IEmpresa, getEmptyEmpresa } from "../../interfaces/personas";

export const setStorageEmpresa = async (IEmpresa : IEmpresa) => await setStorageData('IEmpresa', IEmpresa);
export const getStorageEmpresa = async () : Promise<IEmpresa> => await getStorageData('IEmpresa', getEmptyEmpresa());
export const removeStorageEmpresa = async () => await removeStorageData('IEmpresa');
export const setStorageEmpresaArray = async (IEmpresa : IEmpresa[]) => await setStorageData('IEmpresaArray', IEmpresa);
export const getStorageEmpresaArray = async () : Promise<IEmpresa[]> => await getStorageData('IEmpresaArray', [] as IEmpresa[]);
export const removeStorageEmpresaArray = async () => await removeStorageData('IEmpresaArray');
export const setStorageEmpresaArrayItem = async (id: number, IEmpresa : IEmpresa) : Promise<IEmpresa[]> => await setArrayItemStorageData('IEmpresaArray', id, IEmpresa, [] as IEmpresa[]);
export const getStorageEmpresaArrayItem = async (id: number) : Promise<IEmpresa> => await getArrayItemStorageData('IEmpresaArray', id, getEmptyEmpresa);
export const removeStorageEmpresaArrayItem = async (id: number) : Promise<IEmpresa[]> => await removeArrayItemStorageData('IEmpresaArray', id, [] as IEmpresa[]);
