/** 
 * Componente que se encarga del elemento MvTipoCanalVenta
 * @IMvTipoCanalVenta es la interfaz que contiene los parámetos el elemento MvTipoCanalVenta
 * @getEmptyMvTipoCanalVenta  devuelve un objeto con los parámetros por defecto; @param {id}, opcional cuando no encuentra el objeto en el LocalSotrage
 * @IStatusMvTipoCanalVenta es la interfaz para los estatus a llamadas a la API; @param {status}, opcional cuando queremos otro estado de inizialización de tipo [DEFAULT_STATE]
 * @getEmptyStatusMvTipoCanalVenta devuelve un objeto con los parámetros por defecto,
 * @setMvTipoCanalVenta devuelve un objeto de tipo [IMvTipoCanalVenta] recibiendo @param {object} y @param {serverDate}, para verificar que los datos estén en el formato correcto y ajustarlos a la zona horaria actual
 * @getSerializableMvTipoCanalVenta recibe un @param {IMvTipoCanalVenta}, para devolver un objeto serizalizable para las peticiones de la API
 * */

import { getDateString, isNotEmpty, SERVER_TIME_ZONE } from "../../api/helpers";
import { DEFAULT_STATE } from "../../enums/default_state";
import { getStoragePaisArrayItem } from "../../localStorage/personas";
import { convertDateToZonaHoraria, ILocalStorageExpiry, getEmptyLocalStorageExpiry, IServerDate } from "../time";
import { IPais, getEmptyPais, setPais } from "./IPais";

export interface IMvTipoCanalVenta {
  id : number,
  dato : string,
  fecha_elaboracion : string, // Fecha
  estatus_sistema : boolean,
  ultima_actualizacion : string, // Fecha
  pais : IPais,
}

export const getEmptyMvTipoCanalVenta = (id? : number) => {
  const temp : IMvTipoCanalVenta = 
  {
    id : id ? id : 0,
    dato : "",
    fecha_elaboracion : "",
    estatus_sistema : false,
    ultima_actualizacion : "",
    pais : getEmptyPais(),
  };
  return temp;
}

export interface IStatusMvTipoCanalVenta {
  estado : DEFAULT_STATE,
  mensaje : string,
  data : IMvTipoCanalVenta,
  data_array: IMvTipoCanalVenta[],
  expiry : ILocalStorageExpiry,
  expiry_array : ILocalStorageExpiry,
}

export const getEmptyStatusMvTipoCanalVenta = (status: DEFAULT_STATE = DEFAULT_STATE.loading) => {
  const temp : IStatusMvTipoCanalVenta = 
  {
    estado : status,
    mensaje : "",
    data : getEmptyMvTipoCanalVenta(),
    data_array : [],
    expiry : getEmptyLocalStorageExpiry("IMvTipoCanalVenta", 0),
    expiry_array : getEmptyLocalStorageExpiry("IMvTipoCanalVentaArray", 0),
  };
  return temp;
}

export const setMvTipoCanalVenta = async (
  object : any,
  serverDate : IServerDate,
) => {
  const temp = getEmptyMvTipoCanalVenta();
  if (isNotEmpty(object.id)) temp.id = object.id;
  if (isNotEmpty(object.dato)) temp.dato = object.dato;
  if (isNotEmpty(object.fecha_elaboracion)) temp.fecha_elaboracion = convertDateToZonaHoraria(serverDate, object.fecha_elaboracion);
  if (isNotEmpty(object.estatus_sistema)) temp.estatus_sistema = object.estatus_sistema;
  if (isNotEmpty(object.ultima_actualizacion)) temp.ultima_actualizacion = convertDateToZonaHoraria(serverDate, object.ultima_actualizacion);
  if (isNotEmpty(object.pais)) temp.pais = (typeof object.pais === 'number') ? await getStoragePaisArrayItem(object.pais) : await setPais(object.pais, serverDate);
  return temp;
}

export const getSerializableMvTipoCanalVenta = (IMvTipoCanalVenta : IMvTipoCanalVenta) => {
  return {
    id : IMvTipoCanalVenta.id,
    dato : IMvTipoCanalVenta.dato,
    fecha_elaboracion : IMvTipoCanalVenta.fecha_elaboracion,
    estatus_sistema : IMvTipoCanalVenta.estatus_sistema,
    ultima_actualizacion : getDateString(SERVER_TIME_ZONE),
    pais : IMvTipoCanalVenta.pais.id > 0 ? IMvTipoCanalVenta.pais.id : null,
  };
}
