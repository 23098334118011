/**
 * Métodos del, get, post, put para llamadas a la API
 * @get     en este método primero consulta con @getFromLocalStorage si es necesario hacer la petición http sino se obtiene del LocalStorage;
 *          si hay un error en la petición http se busca el objeto en el LocalStorage sino lo encuentra devuelve un objeto vacío; si la
 *          petición se realiza correctamente actualiza el valor individual y en el array del objeto en el LocalStorage y devuelve el objeto
 *          obtenido del servidor.
 * @getAll  en este método primero consulta con @getFromLocalStorage si es necesario hacer la petición http sino se obtiene del LocalStorage;
 *          si hay un error en la petición http se busca el objeto array en el LocalStorage sino lo encuentra devuelve un array vacío; si la
 *          petición se realiza correctamente actualiza array en el LocalStorage y devuelve el array obtenido del servidor
 * @update  en este método después de realizar la petición http si falla devuelve el objeto que se iba a actualizar; si la petición se
 *          realiza correctamente actualiza el valor individual y en el array del objeto en el LocalStorage y devuelve el objeto obtenido del
 *          servidor.
 * @create  en este método después de realizar la petición http si falla devuelve el objeto que se iba a crear; si la petición se realiza
 *          correctamente actualiza el valor individual y en el array del objeto en el LocalStorage y devuelve el objeto obtenido del
 *          servidor.
 * @delete  en este método después de realizar la petición http si falla devuelve el objeto que se iba a eliminar; si la petición se realiza
 *          correctamente elimina el valor individual y en el objeto en el arrya del LocalStorage y devuelve un objeto vacio (Default)
 */

import { del, get, post, put } from "../../api/api";
import {
  getFromLocalStorage,
  updateLocalStorageExpiry,
} from "../../api/helpers";
import { removeArrayItemStorageData } from "../../api/storage";
import { DEFAULT_STATE } from "../../enums/default_state";
import {
  getEmptyStatusMvTipoCanalVenta,
  setMvTipoCanalVenta,
  IMvTipoCanalVenta,
  getSerializableMvTipoCanalVenta,
} from "../../interfaces/personas";
import { IServerDate } from "../../interfaces/time";
import {
  getStorageMvTipoCanalVentaArrayItem,
  setStorageMvTipoCanalVenta,
  setStorageMvTipoCanalVentaArrayItem,
  getStorageMvTipoCanalVentaArray,
  setStorageMvTipoCanalVentaArray,
  removeStorageMvTipoCanalVenta,
} from "../../localStorage/personas";

export const getMvTipoCanalVenta = async (
  serverDate: IServerDate,
  pk: number
) => {
  const temp = getEmptyStatusMvTipoCanalVenta();
  const doHttp = await getFromLocalStorage(serverDate, temp.expiry);
  temp.expiry = doHttp.storage;
  if (!doHttp.result) {
    console.log(
      `(localStorage) MvTipoCanalVenta con pk: ${pk}, obtenido con éxito`
    );
    temp.estado = DEFAULT_STATE.success;
    temp.mensaje = `MvTipoCanalVenta obtenido con éxito`;
    temp.data = await getStorageMvTipoCanalVentaArrayItem(pk);
    return temp;
  }
  const result = await get(`personas/tipo-canal-venta/${pk}`);
  const data = result.data;
  console.log(`MvTipoCanalVenta data: `, data);
  if (!data.id || data.detail) {
    console.log(
      `Error al obtener MvTipoCanalVenta con pk: ${pk}, ${data.detail}`
    );
    temp.estado = DEFAULT_STATE.error;
    temp.mensaje = `Error al obtener el MvTipoCanalVenta: ${
      data.detail ?? "Hubo un problema al conectarse con el servidor"
    }`;
    temp.data = await getStorageMvTipoCanalVentaArrayItem(pk);
    return temp;
  }
  console.log(`MvTipoCanalVenta con pk: ${pk}, obtenido con éxito`);
  temp.estado = DEFAULT_STATE.success;
  temp.mensaje = `MvTipoCanalVenta obtenido con éxito`;
  temp.data = await setMvTipoCanalVenta(data, serverDate);
  await setStorageMvTipoCanalVenta(temp.data);
  temp.data_array = await setStorageMvTipoCanalVentaArrayItem(pk, temp.data);
  temp.expiry = await updateLocalStorageExpiry(serverDate, temp.expiry);
  return temp;
};

export const getAllMvTipoCanalVenta = async (serverDate: IServerDate) => {
  const temp = getEmptyStatusMvTipoCanalVenta();
  const doHttp = await getFromLocalStorage(serverDate, temp.expiry_array);
  temp.expiry_array = doHttp.storage;
  if (!doHttp.result) {
    console.log(
      `(localStorage) Todos los MvTipoCanalVentas obtenidos con éxito`
    );
    temp.estado = DEFAULT_STATE.success;
    temp.mensaje = `Todos los MvTipoCanalVenta obtenidos con éxito`;
    temp.data_array = await getStorageMvTipoCanalVentaArray();
    return temp;
  }
  const result = await get(`personas/tipo-canal-venta`);
  const data = result.data;
  if (data.detail) {
    console.log(
      `Error al obtener la lista de MvTipoCanalVenta, ${data.detail}`
    );
    temp.estado = DEFAULT_STATE.error;
    temp.mensaje = `Error al obtener la lista de MvTipoCanalVenta: ${
      data.detail ?? "Hubo un problema al conectarse con el servidor"
    }`;
    temp.data_array = await getStorageMvTipoCanalVentaArray();
    return temp;
  }
  console.log(`Todos los MvTipoCanalVenta obtenidos con éxito`);
  temp.estado = DEFAULT_STATE.success;
  temp.mensaje = `Todos los MvTipoCanalVenta obtenidos con éxito`;
  temp.data_array = await Promise.all(
    data.map(async (e: any) => await setMvTipoCanalVenta(e, serverDate))
  );
  await setStorageMvTipoCanalVentaArray(temp.data_array);
  temp.expiry_array = await updateLocalStorageExpiry(
    serverDate,
    temp.expiry_array
  );
  return temp;
};

export const updateMvTipoCanalVenta = async (
  serverDate: IServerDate,
  pk: number,
  object: IMvTipoCanalVenta
) => {
  const temp = getEmptyStatusMvTipoCanalVenta();
  const result = await put(
    `personas/tipo-canal-venta/${pk}`,
    getSerializableMvTipoCanalVenta(object)
  );
  const data = result.data;
  if (!data.id || data.detail) {
    console.log(
      `Error al actualizar MvTipoCanalVenta con pk: ${pk}, ${data.detail}`
    );
    temp.estado = DEFAULT_STATE.error;
    temp.mensaje = `Error al actualizar el MvTipoCanalVenta: ${
      data.detail ?? "Hubo un problema al conectarse con el servidor"
    }`;
    temp.data = await setMvTipoCanalVenta(object, serverDate);
    return temp;
  }
  console.log(`MvTipoCanalVenta con pk: ${pk}, actualizado con éxito`);
  temp.estado = DEFAULT_STATE.success;
  temp.mensaje = `MvTipoCanalVenta actualizado con éxito`;
  temp.data = await setMvTipoCanalVenta(data, serverDate);
  await setStorageMvTipoCanalVenta(temp.data);
  temp.data_array = await setStorageMvTipoCanalVentaArrayItem(
    temp.data.id,
    temp.data
  );
  return temp;
};

export const createMvTipoCanalVenta = async (
  serverDate: IServerDate,
  object: IMvTipoCanalVenta
) => {
  const temp = getEmptyStatusMvTipoCanalVenta();
  const result = await post(
    `personas/tipo-canal-venta`,
    getSerializableMvTipoCanalVenta(object)
  );
  const data = result.data;
  if (!data.id || data.detail) {
    console.log(
      `Error al crear MvTipoCanalVenta con los datos ${object}, ${data.detail}`
    );
    temp.estado = DEFAULT_STATE.error;
    temp.mensaje = `Error al crear el MvTipoCanalVenta: ${
      data.detail ?? "Hubo un problema al conectarse con el servidor"
    }`;
    temp.data = await setMvTipoCanalVenta(object, serverDate);
    return temp;
  }
  console.log(`MvTipoCanalVenta creado con éxito`);
  temp.estado = DEFAULT_STATE.success;
  temp.mensaje = `MvTipoCanalVenta creado con éxito`;
  temp.data = await setMvTipoCanalVenta(data, serverDate);
  await setStorageMvTipoCanalVenta(temp.data);
  temp.data_array = await setStorageMvTipoCanalVentaArrayItem(
    temp.data.id,
    temp.data
  );
  return temp;
};

export const deleteMvTipoCanalVenta = async (pk: number) => {
  const temp = getEmptyStatusMvTipoCanalVenta();
  const result = await del(`personas/tipo-canal-venta/${pk}`);
  const data = result.data;
  if (data.detail) {
    console.log(
      `Error al eliminar MvTipoCanalVenta con pk: ${pk}, ${data.detail}`
    );
    temp.estado = DEFAULT_STATE.error;
    temp.mensaje = `Error al eliminar el datos fiscales: ${data.detail}`;
    temp.data = await getStorageMvTipoCanalVentaArrayItem(pk);
    temp.data_array = await getStorageMvTipoCanalVentaArray();
    return temp;
  }
  console.log(`MvTipoCanalVenta eliminado con éxito`);
  temp.estado = DEFAULT_STATE.success;
  temp.mensaje = `MvTipoCanalVenta eliminado con éxito`;
  await removeStorageMvTipoCanalVenta();
  temp.data_array = await removeArrayItemStorageData(
    "IMvTipoCanalVenta",
    pk,
    []
  );
  return temp;
};
