/**
 * Métodos AsyncThunk para ser usados en los reducers y
 * los resultados a las llamadas http puedan ser usados para
 * actualizar el state en redux
 */

import { createAsyncThunk } from "@reduxjs/toolkit";
import { DEFAULT_STATE } from "../../enums/default_state";
import { getRondin, getAllRondin, updateRondin, createRondin, deleteRondin } from "../../http/rondines";
import { getEmptyStatusRondin, IRondin } from "../../interfaces/rondines";
import { IServerDate } from "../../interfaces/time";

export const getRondinAsyncThunk = createAsyncThunk(
  'rondines/getRondinAsyncThunk',
  async (query : {serverDate : IServerDate, id: number}) => await getRondin(query.serverDate, query.id)
    .then(async (result) => result)
    .catch(async (error) => getEmptyStatusRondin(DEFAULT_STATE.error))  
);

export const getAllRondinAsyncThunk = createAsyncThunk(
  'rondines/getAllRondinAsyncThunk',
  async (queryAll : {serverDate : IServerDate, idCliente : number}) => await getAllRondin(queryAll.serverDate, queryAll.idCliente)
    .then(async (result) => result)
    .catch(async (error) => getEmptyStatusRondin(DEFAULT_STATE.error))  
);

export const updateRondinAsyncThunk = createAsyncThunk(
  'rondines/updateRondinAsyncThunk',
  async (update: {serverDate : IServerDate, id: number, idCliente: number, Rondin: IRondin}) => await updateRondin(update.serverDate, update.id, update.idCliente, update.Rondin)
    .then(async (result) => result)
    .catch(async (error) => getEmptyStatusRondin(DEFAULT_STATE.error))  
);

export const createRondinAsyncThunk = createAsyncThunk(
  'rondines/createRondinAsyncThunk',
  async (create : {serverDate : IServerDate, idCliente : number, Rondin: IRondin}) => await createRondin(create.serverDate, create.idCliente, create.Rondin)
    .then(async (result) => result)
    .catch(async (error) => getEmptyStatusRondin(DEFAULT_STATE.error))  
);

export const deleteRondinAsyncThunk = createAsyncThunk(
  'rondines/deleteRondinAsyncThunk',
  async (del : {id : number, idCliente: number}) => await deleteRondin(del.id, del.idCliente)
    .then(async (result) => result)
    .catch(async (error) => getEmptyStatusRondin())  
);
