/**
 * Métodos AsyncThunk para ser usados en los reducers y
 * los resultados a las llamadas http puedan ser usados para
 * actualizar el state en redux
 */

import { createAsyncThunk } from "@reduxjs/toolkit";
import { DEFAULT_STATE } from "../../enums/default_state";
import { getHorario, getAllHorario, updateHorario, createHorario, deleteHorario } from "../../http/nomina";
import { getEmptyStatusHorario, IHorario } from "../../interfaces/nomina";
import { IServerDate } from "../../interfaces/time";

export const getHorarioAsyncThunk = createAsyncThunk(
  'nomina/getHorarioAsyncThunk',
  async (query : {serverDate : IServerDate, id: number, idCliente : number}) => await getHorario(query.serverDate, query.id, query.idCliente)
    .then(async (result) => result)
    .catch(async (error) => getEmptyStatusHorario(DEFAULT_STATE.error))  
);

export const getAllHorarioAsyncThunk = createAsyncThunk(
  'nomina/getAllHorarioAsyncThunk',
  async (queryAll : {serverDate : IServerDate, idCliente : number}) => await getAllHorario(queryAll.serverDate, queryAll.idCliente)
    .then(async (result) => result)
    .catch(async (error) => getEmptyStatusHorario(DEFAULT_STATE.error))  
);

export const updateHorarioAsyncThunk = createAsyncThunk(
  'nomina/updateHorarioAsyncThunk',
  async (update: {serverDate : IServerDate, id: number, idCliente : number, object: IHorario}) => await updateHorario(update.serverDate, update.id, update.idCliente, update.object)
    .then(async (result) => result)
    .catch(async (error) => getEmptyStatusHorario(DEFAULT_STATE.error))  
);

export const createHorarioAsyncThunk = createAsyncThunk(
  'nomina/createHorarioAsyncThunk',
  async (create : {serverDate : IServerDate, idCliente : number, object: IHorario}) => await createHorario(create.serverDate, create.idCliente, create.object)
    .then(async (result) => result)
    .catch(async (error) => getEmptyStatusHorario(DEFAULT_STATE.error))  
);

export const deleteHorarioAsyncThunk = createAsyncThunk(
  'nomina/deleteHorarioAsyncThunk',
  async (del : {id : number, idCliente : number}) => await deleteHorario(del.id, del.idCliente)
    .then(async (result) => result)
    .catch(async (error) => getEmptyStatusHorario())  
);
