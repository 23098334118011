import { get, post } from "../../api/api";
import { getFromLocalStorage, updateLocalStorageExpiry } from "../../api/helpers";
import { DEFAULT_STATE } from "../../enums/default_state";
import { IMovimientoRondin, getEmptyStatusMovimientoRondin, ICancelarRondin, getEmptyStatusCancelarRondin, getEmptyStatusRondin, setRondin, IRondinesEmpleadoFecha, getEmptyStatusCheckRondin, IHistorialRondinesCancelados, getEmptyStatusRondinCancelado, getEmptyStatusRondinCompletado, IHistorialRondinesCanceladosEmpleado, IUltimoCheck, IHistorialRondinesCompletados, getEmptyStatusUltimoCheck, getEmptyStatusHistorialRondinesCanceladosEmpleado, getEmptyStatusHistorialRondinesCompletados, getEmptyStatusHistorialRondinesCancelados, getEmptyStatusRondinesEmpleadoFecha, setCheckRondin } from "../../interfaces/rondines";
import { IServerDate } from "../../interfaces/time";
import { getStorageCheckRondinArray, getStorageRondinArray, setStorageCheckRondin, setStorageCheckRondinArray, setStorageRondinArray } from "../../localStorage/rondines";

export const getAllClienteQentryRondin = async (serverDate: IServerDate, idCliente : number) => {
  const temp = getEmptyStatusRondin();
  const doHttp = await getFromLocalStorage(serverDate, temp.expiry_array);
  temp.expiry_array = doHttp.storage;
  if (!doHttp.result) {
    console.log(`(localStorage) Todos los Rondins obtenidos con éxito`);
    temp.estado = DEFAULT_STATE.success;
    temp.mensaje = `Todos los Rondin obtenidos con éxito`;
    temp.data_array = await getStorageRondinArray();
  }
  const result = await get(`rondines/rondin-cliente/${idCliente}`);
  const data = result.data;
  if (data.detail) {
    console.log(`Error al obtener la lista de Rondin, ${data.detail}`);
    temp.estado = DEFAULT_STATE.error;
    temp.mensaje = `Error al obtener la lista de Rondin: ${data.detail ?? "Hubo un problema al conectarse con el servidor"}`;
    temp.data_array = await getStorageRondinArray();
    return temp;
  }
  console.log(`Todos los Rondin obtenidos con éxito`);
  temp.estado = DEFAULT_STATE.success;
  temp.mensaje = `Todos los Rondin obtenidos con éxito`;
  temp.data_array = await Promise.all(data.map(async (e : any) => await setRondin(e, serverDate)));
  await setStorageRondinArray(temp.data_array);
  temp.expiry_array = await updateLocalStorageExpiry(serverDate, temp.expiry_array);
  return temp;
}

export const iniciarRondin = async (object : IMovimientoRondin) => {
  const temp = getEmptyStatusMovimientoRondin();
  const result = await post(`rondines/iniciar-rondin`, object);
  const data = result.data;
  if (!data.data || data.detail ) {
    console.log(`Error al iniciar el Rondin con los datos ${object}, ${data.detail}`);
    temp.estado = DEFAULT_STATE.error;
    temp.mensaje = `Error al iniciar el Rondin: ${data.mensaje ?? "Hubo un problema al conectarse con el servidor"}`;
    return temp;
  }
  console.log(`Rondin iniciado con éxito`);
  temp.estado = DEFAULT_STATE.success;
  temp.mensaje = `Rondin iniciado con éxito`;
  temp.data = data;
  return temp;
}

export const nodoIntermedio = async (object : IMovimientoRondin) => {
  const temp = getEmptyStatusMovimientoRondin();
  const result = await post(`rondines/nodo-intermedio`, object);
  const data = result.data;
  if (!data.data || data.detail ) {
    console.log(`Error al registrar el checkpoint con los datos ${object}, ${data.detail}`);
    temp.estado = DEFAULT_STATE.error;
    temp.mensaje = `Error al registrar el checkpoint: ${data.mensaje ?? "Hubo un problema al conectarse con el servidor"}`;
    return temp;
  }
  console.log(`Checkpoint registrado con éxito`);
  temp.estado = DEFAULT_STATE.success;
  temp.mensaje = `Checkpoint registrado con éxito`;
  temp.data = data;
  return temp;
}

export const finalizarRondin = async (object : IMovimientoRondin) => {
  const temp = getEmptyStatusMovimientoRondin();
  const result = await post(`rondines/finalizar-rondin`, object);
  const data = result.data;
  if (!data.data || data.detail ) {
    console.log(`Error al finalizar Rondin con los datos ${object}, ${data.detail}`);
    temp.estado = DEFAULT_STATE.error;
    temp.mensaje = `Error al finalizar el Rondin: ${data.mensaje ?? "Hubo un problema al conectarse con el servidor"}`;
    return temp;
  }
  console.log(`Rondin finalizado con éxito`);
  temp.estado = DEFAULT_STATE.success;
  temp.mensaje = `Rondin finalizado con éxito`;
  temp.data = data;
  return temp;
}

export const cancelarRondin = async (object : ICancelarRondin) => {
  const temp = getEmptyStatusCancelarRondin();
  const result = await post(`rondines/cancelar-rondin`, object);
  const data = result.data;
  if (!data.data || data.detail ) {
    console.log(`Error al cancelar el Rondin con los datos ${object}, ${data.detail}`);
    temp.estado = DEFAULT_STATE.error;
    temp.mensaje = `Error al cancelar el Rondin: ${data.mensaje ?? "Hubo un problema al conectarse con el servidor"}`;
    return temp;
  }
  console.log(`Rondin cancelado con éxito`);
  temp.estado = DEFAULT_STATE.success;
  temp.mensaje = `Rondin cancelado con éxito`;
  temp.data = data;
  return temp;
}

export const rondinesEmpleadoFecha = async (object : IRondinesEmpleadoFecha) => {
  const temp = getEmptyStatusRondinesEmpleadoFecha();
  const result = await get(`rondines/rondines-empleado-fecha/${object.empleado}/${object.fecha_inicio}/${object.fecha_fin}`);
  const data = result.data;
  if (!data.data || data.detail ) {
    console.log(`Error al obtener los Rondines con los datos ${object}, ${data.detail}`);
    temp.estado = DEFAULT_STATE.error;
    temp.mensaje = `Error al obtener los Rondines: ${data.mensaje ?? "Hubo un problema al conectarse con el servidor"}`;
    return temp;
  }
  console.log(`Rondin obtenido con éxito`);
  temp.estado = DEFAULT_STATE.success;
  temp.mensaje = `Rondin obtenido con éxito`;
  temp.data = data;
  return temp;
}

export const rondinesCancelados = async (object : IHistorialRondinesCancelados) => {
  const temp = getEmptyStatusHistorialRondinesCancelados();
  const result = await get(`rondines/rondines-cancelados/${object.fecha_inicio}/${object.fecha_fin}`);
  const data = result.data;
  if (!data.data || data.detail ) {
    console.log(`Error al obtener los Rondines cancelados con los datos ${object}, ${data.detail}`);
    temp.estado = DEFAULT_STATE.error;
    temp.mensaje = `Error al obtener los Rondines cancelados: ${data.mensaje ?? "Hubo un problema al conectarse con el servidor"}`;
    return temp;
  }
  console.log(`Rondin obtenido con éxito`);
  temp.estado = DEFAULT_STATE.success;
  temp.mensaje = `Rondin obtenido con éxito`;
  temp.data = data;
  return temp;
}

export const rondinesCompletados = async (object : IHistorialRondinesCompletados) => {
  const temp = getEmptyStatusHistorialRondinesCompletados();
  const result = await get(`rondines/rondines-completados/${object.fecha_inicio}/${object.fecha_fin}`);
  const data = result.data;
  if (!data.data || data.detail ) {
    console.log(`Error al obtener los Rondines completados con los datos ${object}, ${data.detail}`);
    temp.estado = DEFAULT_STATE.error;
    temp.mensaje = `Error al obtener los Rondines completados: ${data.mensaje ?? "Hubo un problema al conectarse con el servidor"}`;
    return temp;
  }
  console.log(`Rondin obtenido con éxito`);
  temp.estado = DEFAULT_STATE.success;
  temp.mensaje = `Rondin obtenido con éxito`;
  temp.data = data;
  return temp;
}

export const rondinesCanceladosEmpleado = async (object : IHistorialRondinesCanceladosEmpleado) => {
  const temp = getEmptyStatusHistorialRondinesCanceladosEmpleado();
  const result = await get(`rondines/rondines-cancelados-empleado/${object.empleado}/${object.fecha_inicio}/${object.fecha_fin}`);
  const data = result.data;
  if (!data.data || data.detail ) {
    console.log(`Error al obtener los Rondines cancelados con los datos ${object}, ${data.detail}`);
    temp.estado = DEFAULT_STATE.error;
    temp.mensaje = `Error al obtener los Rondines cancelados: ${data.mensaje ?? "Hubo un problema al conectarse con el servidor"}`;
    return temp;
  }
  console.log(`Rondin obtenido con éxito`);
  temp.estado = DEFAULT_STATE.success;
  temp.mensaje = `Rondin obtenido con éxito`;
  temp.data = data;
  return temp;
}

export const ultimoCheck = async (object : IUltimoCheck) => {
  const temp = getEmptyStatusUltimoCheck();
  const result = await get(`rondines/ultimo-check/${object.empleado}`);
  const data = result.data;
  if (!data.data || data.detail ) {
    console.log(`Error al obtener los Rondines cancelados con los datos ${object}, ${data.detail}`);
    temp.estado = DEFAULT_STATE.error;
    temp.mensaje = `Error al obtener los Rondines cancelados: ${data.mensaje ?? "Hubo un problema al conectarse con el servidor"}`;
    return temp;
  }
  console.log(`Rondin obtenido con éxito`);
  temp.estado = DEFAULT_STATE.success;
  temp.mensaje = `Rondin obtenido con éxito`;
  temp.data = data;
  return temp;
}