/** 
 * Componente que se encarga del elemento ServicioQentry
 * @IServicioQentry es la interfaz que contiene los parámetos el elemento ServicioQentry
 * @getEmptyServicioQentry  devuelve un objeto con los parámetros por defecto; @param {id}, opcional cuando no encuentra el objeto en el LocalSotrage
 * @IStatusServicioQentry es la interfaz para los estatus a llamadas a la API; @param {status}, opcional cuando queremos otro estado de inizialización de tipo [DEFAULT_STATE]
 * @getEmptyStatusServicioQentry devuelve un objeto con los parámetros por defecto,
 * @setServicioQentry devuelve un objeto de tipo [IServicioQentry] recibiendo @param {object} y @param {serverDate}, para verificar que los datos estén en el formato correcto y ajustarlos a la zona horaria actual
 * @getSerializableServicioQentry recibe un @param {IServicioQentry}, para devolver un objeto serizalizable para las peticiones de la API
 * */

import { getDateString, isNotEmpty, SERVER_TIME_ZONE } from "../../api/helpers";
import { DEFAULT_STATE } from "../../enums/default_state";
import { getStorageUserArrayItem, getStorageClienteQentryArrayItem } from "../../localStorage/personas";
import { getStorageMvTipoServicioQentryArrayItem } from "../../localStorage/personas/MvTipoServicioQentry";
import { convertDateToZonaHoraria, ILocalStorageExpiry, getEmptyLocalStorageExpiry, IServerDate } from "../time";
import { getEmptyClienteQentry, IClienteQentry, setClienteQentry } from "./IClienteQentry";
import { getEmptyMvTipoServicioQentry, IMvTipoServicioQentry, setMvTipoServicioQentry } from "./IMvTipoServicioQentry";
import { IUser, getEmptyUser, setUser } from "./IUser";

export interface IServicioQentry {
  id : number,
  nombre : string,
  descripcion : string,
  costo : number, // Decimal
  precio_venta : number, // Decimal
  tipo_servicio : IMvTipoServicioQentry, 
  autor : IUser,
  fecha_elaboracion : string, // Fecha
  estatus_sistema : boolean,
  ultima_actualizacion : string, // Fecha
  cliente_qentry : IClienteQentry
}

export const getEmptyServicioQentry = (id? : number) => {
  const temp : IServicioQentry = 
  {
    id : id ? id : 0,
    nombre : "",
    descripcion : "",
    costo : 0,
    precio_venta : 0,
    tipo_servicio : getEmptyMvTipoServicioQentry(),
    autor : getEmptyUser(),
    fecha_elaboracion : "",
    estatus_sistema : false,
    ultima_actualizacion : "",
    cliente_qentry : getEmptyClienteQentry(),
  };
  return temp;
}

export interface IStatusServicioQentry {
  estado : DEFAULT_STATE,
  mensaje : string,
  data : IServicioQentry,
  data_array: IServicioQentry[],
  expiry : ILocalStorageExpiry,
  expiry_array : ILocalStorageExpiry,
}

export const getEmptyStatusServicioQentry = (status: DEFAULT_STATE = DEFAULT_STATE.loading) => {
  const temp : IStatusServicioQentry = 
  {
    estado : status,
    mensaje : "",
    data : getEmptyServicioQentry(),
    data_array : [],
    expiry : getEmptyLocalStorageExpiry("IServicioQentry", 0),
    expiry_array : getEmptyLocalStorageExpiry("IServicioQentryArray", 0),
  };
  return temp;
}

export const setServicioQentry = async (
  object : any,
  serverDate : IServerDate,
) => {
  const temp = getEmptyServicioQentry();
  if (isNotEmpty(object.id)) temp.id = object.id;
  if (isNotEmpty(object.nombre)) temp.nombre = object.nombre;
  if (isNotEmpty(object.descripcion)) temp.descripcion = object.descripcion;
  if (isNotEmpty(object.costo)) temp.costo = Number(object.costo);
  if (isNotEmpty(object.precio_venta)) temp.precio_venta = Number(object.precio_venta);
  if (isNotEmpty(object.tipo_servicio)) temp.tipo_servicio = (typeof object.tipo_servicio === 'number') ? await getStorageMvTipoServicioQentryArrayItem(object.tipo_servicio) : await setMvTipoServicioQentry(object.tipo_servicio, serverDate);
  if (isNotEmpty(object.autor)) temp.autor = (typeof object.autor === 'number') ? await getStorageUserArrayItem(object.autor) : await setUser(object.autor, serverDate);
  if (isNotEmpty(object.fecha_elaboracion)) temp.fecha_elaboracion = convertDateToZonaHoraria(serverDate, object.fecha_elaboracion);
  if (isNotEmpty(object.estatus_sistema)) temp.estatus_sistema = object.estatus_sistema;
  if (isNotEmpty(object.ultima_actualizacion)) temp.ultima_actualizacion = convertDateToZonaHoraria(serverDate, object.ultima_actualizacion);
  if (isNotEmpty(object.cliente_qentry)) temp.cliente_qentry = (typeof object.cliente_qentry === 'number') ? await getStorageClienteQentryArrayItem(object.cliente_qentry) : await setClienteQentry(object.cliente_qentry, serverDate);
  return temp;
}

export const getSerializableServicioQentry = (IServicioQentry : IServicioQentry) => {
  return {
    id : IServicioQentry.id,
    nombre : IServicioQentry.nombre,
    descripcion : IServicioQentry.descripcion,
    costo : IServicioQentry.costo.toFixed(2),
    precio_venta : IServicioQentry.precio_venta.toFixed(2),
    tipo_servicio : IServicioQentry.tipo_servicio.id > 0 ? IServicioQentry.tipo_servicio.id : null,
    autor : IServicioQentry.autor.id,
    fecha_elaboracion : IServicioQentry.fecha_elaboracion,
    estatus_sistema : IServicioQentry.estatus_sistema,
    ultima_actualizacion : getDateString(SERVER_TIME_ZONE),
    cliente_qentry : IServicioQentry.cliente_qentry.id,
  };
}
