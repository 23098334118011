/**
 * Métodos AsyncThunk para ser usados en los reducers y
 * los resultados a las llamadas http puedan ser usados para
 * actualizar el state en redux
 */

import { createAsyncThunk } from "@reduxjs/toolkit";
import { DEFAULT_STATE } from "../../enums/default_state";
import { getMvTipoNotificacion, getAllMvTipoNotificacion, updateMvTipoNotificacion, createMvTipoNotificacion, deleteMvTipoNotificacion } from "../../http/nomina";
import { getEmptyStatusMvTipoNotificacion, IMvTipoNotificacion } from "../../interfaces/nomina";
import { IServerDate } from "../../interfaces/time";

export const getMvTipoNotificacionAsyncThunk = createAsyncThunk(
  'nomina/getMvTipoNotificacionAsyncThunk',
  async (query : {serverDate : IServerDate, id: number}) => await getMvTipoNotificacion(query.serverDate, query.id)
    .then(async (result) => result)
    .catch(async (error) => getEmptyStatusMvTipoNotificacion(DEFAULT_STATE.error))  
);

export const getAllMvTipoNotificacionAsyncThunk = createAsyncThunk(
  'nomina/getAllMvTipoNotificacionAsyncThunk',
  async (queryAll : {serverDate : IServerDate}) => await getAllMvTipoNotificacion(queryAll.serverDate)
    .then(async (result) => result)
    .catch(async (error) => getEmptyStatusMvTipoNotificacion(DEFAULT_STATE.error))  
);

export const updateMvTipoNotificacionAsyncThunk = createAsyncThunk(
  'nomina/updateMvTipoNotificacionAsyncThunk',
  async (update: {serverDate : IServerDate, id: number, MvTipoNotificacion: IMvTipoNotificacion}) => await updateMvTipoNotificacion(update.serverDate, update.id, update.MvTipoNotificacion)
    .then(async (result) => result)
    .catch(async (error) => getEmptyStatusMvTipoNotificacion(DEFAULT_STATE.error))  
);

export const createMvTipoNotificacionAsyncThunk = createAsyncThunk(
  'nomina/createMvTipoNotificacionAsyncThunk',
  async (create : {serverDate : IServerDate, MvTipoNotificacion: IMvTipoNotificacion}) => await createMvTipoNotificacion(create.serverDate, create.MvTipoNotificacion)
    .then(async (result) => result)
    .catch(async (error) => getEmptyStatusMvTipoNotificacion(DEFAULT_STATE.error))  
);

export const deleteMvTipoNotificacionAsyncThunk = createAsyncThunk(
  'nomina/deleteMvTipoNotificacionAsyncThunk',
  async (del : {id : number}) => await deleteMvTipoNotificacion(del.id)
    .then(async (result) => result)
    .catch(async (error) => getEmptyStatusMvTipoNotificacion())  
);
