import { qentry3CreateUsers } from "../../../storage/UserApi";
import { get, post, postFile } from "../../api/api";
import {
  getFromLocalStorage,
  updateLocalStorageExpiry,
} from "../../api/helpers";
import { DEFAULT_STATE } from "../../enums/default_state";
import { getStorageIAuth } from "../../interfaces/auth/IAuth";
import {
  getEmptyStatusCentroDeTrabajo,
  getEmptyStatusEmpleado,
  ICodigoPostal,
  setCentroDeTrabajo,
  setEmpleado,
} from "../../interfaces/personas";
import { IServerDate } from "../../interfaces/time";
import {
  getStorageCentroDeTrabajoArray,
  getStorageEmpleadoArray,
  setStorageCentroDeTrabajoArray,
  setStorageEmpleadoArray,
} from "../../localStorage/personas";
import { ICreateEmpleadoData } from "../../slices/pagesSlice/teamMembersSlice";
import { ICreateCentroDeTrabajoData } from "../../slices/pagesSlice/workCentersSlice";

export const deactivateEmpleado = async (
  serverDate: IServerDate,
  idCliente: number,
  ids: number[]
) => {
  const temp = getEmptyStatusEmpleado();
  const doHttp = await getFromLocalStorage(serverDate, temp.expiry_array);
  temp.expiry_array = doHttp.storage;
  const t_r = await post("personas/helpers/modificar-estatus-empleados", {
    lista_ids_empleados: ids,
    estatus: false,
  });
  console.log("t_r: ", t_r.data);
  const result = await get(`personas/empleado/${idCliente}`);
  const data = result.data;
  if (data.detail) {
    console.log(`Error al obtener la lista de Empleado`, data);
    temp.estado = DEFAULT_STATE.error;
    temp.mensaje = `Error al obtener la lista de empleados: ${
      data.detail ?? "Hubo un problema al conectarse con el servidor"
    }`;
    temp.data_array = await getStorageEmpleadoArray();
    return temp;
  }
  console.log(`Todos las Empleados obtenidos con éxito`);
  temp.estado = DEFAULT_STATE.success;
  temp.mensaje = `Todos las empleados obtenidas con éxito`;
  temp.data_array = await Promise.all(
    data.map(async (e: any) => await setEmpleado(e, serverDate))
  );
  await setStorageEmpleadoArray(temp.data_array);
  temp.expiry_array = await updateLocalStorageExpiry(
    serverDate,
    temp.expiry_array
  );
  return temp;
};

export const activateEmpleado = async (
  serverDate: IServerDate,
  idCliente: number,
  ids: number[]
) => {
  const temp = getEmptyStatusEmpleado();
  const doHttp = await getFromLocalStorage(serverDate, temp.expiry_array);
  temp.expiry_array = doHttp.storage;
  const t_r = await post("personas/helpers/modificar-estatus-empleados", {
    lista_ids_empleados: ids,
    estatus: true,
  });
  console.log("t_r2: ", t_r.data);
  const result = await get(`personas/empleado/${idCliente}`);
  const data = result.data;
  if (data.detail) {
    console.log(`Error al obtener la lista de Empleado`, data);
    temp.estado = DEFAULT_STATE.error;
    temp.mensaje = `Error al obtener la lista de empleados: ${
      data.detail ?? "Hubo un problema al conectarse con el servidor"
    }`;
    temp.data_array = await getStorageEmpleadoArray();
    return temp;
  }
  console.log(`Todos las Empleados obtenidos con éxito`);
  temp.estado = DEFAULT_STATE.success;
  temp.mensaje = `Todos las empleados obtenidas con éxito`;
  temp.data_array = await Promise.all(
    data.map(async (e: any) => await setEmpleado(e, serverDate))
  );
  await setStorageEmpleadoArray(temp.data_array);
  temp.expiry_array = await updateLocalStorageExpiry(
    serverDate,
    temp.expiry_array
  );
  return temp;
};

export const deleteMultipleEmpleado = async (
  serverDate: IServerDate,
  idCliente: number,
  ids: number[]
) => {
  const temp = getEmptyStatusEmpleado();
  const doHttp = await getFromLocalStorage(serverDate, temp.expiry_array);
  temp.expiry_array = doHttp.storage;
  await post("personas/helpers/borrar-empleados", { lista_ids_empleados: ids });
  const result = await get(`personas/empleado/${idCliente}`);
  const data = result.data;
  if (data.detail) {
    console.log(`Error al obtener la lista de Empleado`, data);
    temp.estado = DEFAULT_STATE.error;
    temp.mensaje = `Error al obtener la lista de empleados: ${
      data.detail ?? "Hubo un problema al conectarse con el servidor"
    }`;
    temp.data_array = await getStorageEmpleadoArray();
    return temp;
  }
  console.log(`Todos las Empleados obtenidos con éxito`);
  temp.estado = DEFAULT_STATE.success;
  temp.mensaje = `Todos las empleados obtenidas con éxito`;
  temp.data_array = await Promise.all(
    data.map(async (e: any) => await setEmpleado(e, serverDate))
  );
  await setStorageEmpleadoArray(temp.data_array);
  temp.expiry_array = await updateLocalStorageExpiry(
    serverDate,
    temp.expiry_array
  );
  return temp;
};

export const createMultipleEmpleado = async (
  serverDate: IServerDate,
  idCliente: number,
  create: ICreateEmpleadoData[]
) => {
  const temp = getEmptyStatusEmpleado();
  const doHttp = await getFromLocalStorage(serverDate, temp.expiry_array);
  temp.expiry_array = doHttp.storage;
  const temp_upload = await qentry3CreateUsers(create);

  if (temp_upload.data && temp_upload.data.result) {
    const users = temp_upload.data.result as ICreateEmpleadoData[];
    await post("personas/helpers/empleado-from-diccionario", {
      id_clienteQentry: idCliente,
      empleados: users.map((e) => ({
        uuid: e.uuid,
        id_empresa: e.id_empresa,
        email: e.email,
        primer_nombre: e.primer_nombre,
        segundo_nombre: e.segundo_nombre,
        apellido_paterno: e.apellido_paterno,
        apellido_materno: e.apellido_materno,
        telefono_celular: e.telefono_celular,
      })),
    });
  } else {
    console.log(`Error al crear la lista de Empleados`);
    temp.estado = DEFAULT_STATE.error;
    temp.mensaje = `Error al crear la lista de empleados: Hubo un problema al conectarse con el servidor`;
    temp.data_array = await getStorageEmpleadoArray();
    return temp;
  }
  const result = await get(`personas/empleado/${idCliente}`);
  const data = result.data;
  if (data.detail) {
    console.log(`Error al obtener la lista de Empleado`, data);
    temp.estado = DEFAULT_STATE.error;
    temp.mensaje = `Error al obtener la lista de empleados: ${
      data.detail ?? "Hubo un problema al conectarse con el servidor"
    }`;
    temp.data_array = await getStorageEmpleadoArray();
    return temp;
  }
  console.log(`Todos las Empleados obtenidos con éxito`, data);
  temp.estado = DEFAULT_STATE.success;
  temp.mensaje = `Todos las empleados obtenidas con éxito`;
  temp.data_array = await Promise.all(
    data.map(async (e: any) => await setEmpleado(e, serverDate))
  );
  await setStorageEmpleadoArray(temp.data_array);
  temp.expiry_array = await updateLocalStorageExpiry(
    serverDate,
    temp.expiry_array
  );
  return temp;
};
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
export const deactivateCentroDeTrabajo = async (
  serverDate: IServerDate,
  idCliente: number,
  ids: number[]
) => {
  const temp = getEmptyStatusCentroDeTrabajo();
  const doHttp = await getFromLocalStorage(serverDate, temp.expiry_array);
  temp.expiry_array = doHttp.storage;
  const t_r = await post("personas/helpers/modificar-estatus-centros", {
    lista_ids_centros: ids,
    estatus: false,
  });
  console.log("t_r: ", t_r.data);
  const result = await get(`personas/centro-trabajo/${idCliente}`);
  const data = result.data;
  if (data.detail) {
    console.log(`Error al obtener la lista de CentroDeTrabajo`, data);
    temp.estado = DEFAULT_STATE.error;
    temp.mensaje = `Error al obtener la lista de códigos qr: ${
      data.detail ?? "Hubo un problema al conectarse con el servidor"
    }`;
    temp.data_array = await getStorageCentroDeTrabajoArray();
    return temp;
  }
  console.log(`Todos las CentroDeTrabajos obtenidos con éxito`);
  temp.estado = DEFAULT_STATE.success;
  temp.mensaje = `Todos las códigos qr obtenidas con éxito`;
  temp.data_array = await Promise.all(
    data.map(async (e: any) => await setCentroDeTrabajo(e, serverDate))
  );
  await setStorageCentroDeTrabajoArray(temp.data_array);
  temp.expiry_array = await updateLocalStorageExpiry(
    serverDate,
    temp.expiry_array
  );
  return temp;
};

export const activateCentroDeTrabajo = async (
  serverDate: IServerDate,
  idCliente: number,
  ids: number[]
) => {
  const temp = getEmptyStatusCentroDeTrabajo();
  const doHttp = await getFromLocalStorage(serverDate, temp.expiry_array);
  temp.expiry_array = doHttp.storage;
  const t_r = await post("personas/helpers/modificar-estatus-centros", {
    lista_ids_centros: ids,
    estatus: true,
  });
  console.log("t_r2: ", t_r.data);
  const result = await get(`personas/centro-trabajo/${idCliente}`);
  const data = result.data;
  if (data.detail) {
    console.log(`Error al obtener la lista de CentroDeTrabajo`, data);
    temp.estado = DEFAULT_STATE.error;
    temp.mensaje = `Error al obtener la lista de códigos qr: ${
      data.detail ?? "Hubo un problema al conectarse con el servidor"
    }`;
    temp.data_array = await getStorageCentroDeTrabajoArray();
    return temp;
  }
  console.log(`Todos las CentroDeTrabajos obtenidos con éxito`);
  temp.estado = DEFAULT_STATE.success;
  temp.mensaje = `Todos las códigos qr obtenidas con éxito`;
  temp.data_array = await Promise.all(
    data.map(async (e: any) => await setCentroDeTrabajo(e, serverDate))
  );
  await setStorageCentroDeTrabajoArray(temp.data_array);
  temp.expiry_array = await updateLocalStorageExpiry(
    serverDate,
    temp.expiry_array
  );
  return temp;
};

export const deleteMultipleCentroDeTrabajo = async (
  serverDate: IServerDate,
  idCliente: number,
  ids: number[]
) => {
  const temp = getEmptyStatusCentroDeTrabajo();
  const doHttp = await getFromLocalStorage(serverDate, temp.expiry_array);
  temp.expiry_array = doHttp.storage;
  await post("personas/helpers/borrar-centros", { lista_ids_centros: ids });
  const result = await get(`personas/centro-trabajo/${idCliente}`);
  const data = result.data;
  if (data.detail) {
    console.log(`Error al obtener la lista de CentroDeTrabajo`, data);
    temp.estado = DEFAULT_STATE.error;
    temp.mensaje = `Error al obtener la lista de códigos qr: ${
      data.detail ?? "Hubo un problema al conectarse con el servidor"
    }`;
    temp.data_array = await getStorageCentroDeTrabajoArray();
    return temp;
  }
  console.log(`Todos las CentroDeTrabajos obtenidos con éxito`);
  temp.estado = DEFAULT_STATE.success;
  temp.mensaje = `Todos las códigos qr obtenidas con éxito`;
  temp.data_array = await Promise.all(
    data.map(async (e: any) => await setCentroDeTrabajo(e, serverDate))
  );
  await setStorageCentroDeTrabajoArray(temp.data_array);
  temp.expiry_array = await updateLocalStorageExpiry(
    serverDate,
    temp.expiry_array
  );
  return temp;
};

export const createMultipleCentroDeTrabajo = async (
  serverDate: IServerDate,
  idCliente: number,
  create: ICreateCentroDeTrabajoData[]
) => {
  const temp = getEmptyStatusCentroDeTrabajo();
  const doHttp = await getFromLocalStorage(serverDate, temp.expiry_array);
  temp.expiry_array = doHttp.storage;
  console.log("createMultipleCentroDeTrabajo: ", create);
  const user = await getStorageIAuth();
  const tempCreate = await Promise.all(
    create.map(async (e) => {
      let url = "";
      const tempRes = await postFile(
        "personas/helpers/subir-archivo",
        e.blob,
        e.clave_codigo + ".png"
      );
      console.log("tempRes", tempRes);
      if (tempRes.data.archivos) url = tempRes.data.archivos[0].url_archivo;
      console.log("File URL", url);
      return {
        ...e,
        url_imagen_codigo: url,
        id_autor: user.empleado.usuario.id,
      } as ICreateCentroDeTrabajoData;
    })
  );

  console.log("personas/helpers/codigo-qr-from-diccionario");
  await post("personas/helpers/codigo-qr-from-diccionario", {
    codigos_qr: tempCreate,
  });
  const result = await get(`personas/centro-trabajo/${idCliente}`);
  const data = result.data;
  if (data.detail) {
    console.log(`Error al obtener la lista de CentroDeTrabajo`, data);
    temp.estado = DEFAULT_STATE.error;
    temp.mensaje = `Error al obtener la lista de códigos qr: ${
      data.detail ?? "Hubo un problema al conectarse con el servidor"
    }`;
    temp.data_array = await getStorageCentroDeTrabajoArray();
    return temp;
  }
  console.log(`Todos las CentroDeTrabajos obtenidos con éxito`);
  temp.estado = DEFAULT_STATE.success;
  temp.mensaje = `Todos las códigos qr obtenidas con éxito`;
  temp.data_array = await Promise.all(
    data.map(async (e: any) => await setCentroDeTrabajo(e, serverDate))
  );
  await setStorageCentroDeTrabajoArray(temp.data_array);
  temp.expiry_array = await updateLocalStorageExpiry(
    serverDate,
    temp.expiry_array
  );
  return temp;
};

export const searchCodigoPostal = async (codigoPostal: string) => {
  const cpList = await post("personas/helpers/buscar-cp", { cp: codigoPostal });
  if (cpList.data.codigos_encontrados) {
    return cpList.data.codigos_encontrados as ICodigoPostal[];
  }
  return [];
};

// helpers

export interface GraphData {
  empleados_asignados_centro: [];
  empleados_ausentes: [];
  empleados_con_retardo: [];
  empleados_en_horas_extra: [];
  empleados_esperados: [];
  empleados_presentes: [];
  empleados_presentes_no_esperados: [];
  empleados_sin_ubicacion: [];
}

export const getEmptyGraphData = () => {
  const temp: GraphData = {
    empleados_asignados_centro: [],
    empleados_ausentes: [],
    empleados_con_retardo: [],
    empleados_en_horas_extra: [],
    empleados_esperados: [],
    empleados_presentes: [],
    empleados_presentes_no_esperados: [],
    empleados_sin_ubicacion: [],
  };
  return temp;
};

export const searchEmpleadoEnCentro = async () => {
  const centros = await getStorageCentroDeTrabajoArray();
  const result = await Promise.all(
    centros.map(async (e) => {
      const r = await post("nomina/helpers/empleados-en-centro", {
        id_centro: e.id,
      });
      if (r.data.empleados_asignados_centro)
        return { id: e.id, data: r.data as GraphData };
      else return { id: e.id, data: getEmptyGraphData() };
    })
  );
  return result;
};
