import { createAsyncThunk } from "@reduxjs/toolkit";
import { getServerDate } from "../../http/time/ServerDate";
import { ILocation } from "../../interfaces/auth/ILocation";
import { getEmptyStatusServerDate } from "../../interfaces/time";

export const getServerDateAsyncThunk = createAsyncThunk(
  'time/getServerDateAsyncThunk',
  async (coordinates: ILocation) => await getServerDate(coordinates)
    .then(async (result) => result)
    .catch(async (error) => getEmptyStatusServerDate())  
);