/**
 * Métodos AsyncThunk para ser usados en los reducers y
 * los resultados a las llamadas http puedan ser usados para
 * actualizar el state en redux
 */

import { createAsyncThunk } from "@reduxjs/toolkit";
import { DEFAULT_STATE } from "../../enums/default_state";
import { getVacaciones, getAllVacaciones, updateVacaciones, createVacaciones, deleteVacaciones } from "../../http/nomina";
import { getEmptyStatusVacaciones, IVacaciones } from "../../interfaces/nomina";
import { IServerDate } from "../../interfaces/time";

export const getVacacionesAsyncThunk = createAsyncThunk(
  'nomina/getVacacionesAsyncThunk',
  async (query : {serverDate : IServerDate, id: number, idCliente : number}) => await getVacaciones(query.serverDate, query.id, query.idCliente)
    .then(async (result) => result)
    .catch(async (error) => getEmptyStatusVacaciones(DEFAULT_STATE.error))  
);

export const getAllVacacionesAsyncThunk = createAsyncThunk(
  'nomina/getAllVacacionesAsyncThunk',
  async (queryAll : {serverDate : IServerDate, idCliente : number}) => await getAllVacaciones(queryAll.serverDate, queryAll.idCliente)
    .then(async (result) => result)
    .catch(async (error) => getEmptyStatusVacaciones(DEFAULT_STATE.error))  
);

export const updateVacacionesAsyncThunk = createAsyncThunk(
  'nomina/updateVacacionesAsyncThunk',
  async (update: {serverDate : IServerDate, id: number, idCliente : number, object: IVacaciones}) => await updateVacaciones(update.serverDate, update.id, update.idCliente, update.object)
    .then(async (result) => result)
    .catch(async (error) => getEmptyStatusVacaciones(DEFAULT_STATE.error))  
);

export const createVacacionesAsyncThunk = createAsyncThunk(
  'nomina/createVacacionesAsyncThunk',
  async (create : {serverDate : IServerDate, idCliente : number, object: IVacaciones}) => await createVacaciones(create.serverDate, create.idCliente, create.object)
    .then(async (result) => result)
    .catch(async (error) => getEmptyStatusVacaciones(DEFAULT_STATE.error))  
);

export const deleteVacacionesAsyncThunk = createAsyncThunk(
  'nomina/deleteVacacionesAsyncThunk',
  async (del : {id : number, idCliente : number}) => await deleteVacaciones(del.id, del.idCliente)
    .then(async (result) => result)
    .catch(async (error) => getEmptyStatusVacaciones())  
);
