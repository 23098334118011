/**
 * Métodos del, get, post, put para llamadas a la API
 * @get     en este método primero consulta con @getFromLocalStorage si es necesario hacer la petición http sino se obtiene del LocalStorage;
 *          si hay un error en la petición http se busca el objeto en el LocalStorage sino lo encuentra devuelve un objeto vacío; si la
 *          petición se realiza correctamente actualiza el valor individual y en el array del objeto en el LocalStorage y devuelve el objeto
 *          obtenido del servidor.
 * @getAll  en este método primero consulta con @getFromLocalStorage si es necesario hacer la petición http sino se obtiene del LocalStorage;
 *          si hay un error en la petición http se busca el objeto array en el LocalStorage sino lo encuentra devuelve un array vacío; si la
 *          petición se realiza correctamente actualiza array en el LocalStorage y devuelve el array obtenido del servidor
 * @update  en este método después de realizar la petición http si falla devuelve el objeto que se iba a actualizar; si la petición se
 *          realiza correctamente actualiza el valor individual y en el array del objeto en el LocalStorage y devuelve el objeto obtenido del
 *          servidor.
 * @create  en este método después de realizar la petición http si falla devuelve el objeto que se iba a crear; si la petición se realiza
 *          correctamente actualiza el valor individual y en el array del objeto en el LocalStorage y devuelve el objeto obtenido del
 *          servidor.
 * @delete  en este método después de realizar la petición http si falla devuelve el objeto que se iba a eliminar; si la petición se realiza
 *          correctamente elimina el valor individual y en el objeto en el arrya del LocalStorage y devuelve un objeto vacio (Default)
 */

import { del, get, post, put } from "../../api/api";
import {
  getFromLocalStorage,
  updateLocalStorageExpiry,
} from "../../api/helpers";
import { removeArrayItemStorageData } from "../../api/storage";
import { DEFAULT_STATE } from "../../enums/default_state";
import {
  getEmptyStatusMvTipoHorasExtra,
  setMvTipoHorasExtra,
  IMvTipoHorasExtra,
  getSerializableMvTipoHorasExtra,
} from "../../interfaces/nomina";
import { IServerDate } from "../../interfaces/time";
import {
  getStorageMvTipoHorasExtraArrayItem,
  setStorageMvTipoHorasExtra,
  setStorageMvTipoHorasExtraArrayItem,
  getStorageMvTipoHorasExtraArray,
  setStorageMvTipoHorasExtraArray,
  removeStorageMvTipoHorasExtra,
} from "../../localStorage/nomina";

export const getMvTipoHorasExtra = async (
  serverDate: IServerDate,
  pk: number
) => {
  const temp = getEmptyStatusMvTipoHorasExtra();
  const doHttp = await getFromLocalStorage(serverDate, temp.expiry);
  temp.expiry = doHttp.storage;
  if (!doHttp.result) {
    console.log(
      `(localStorage) MvTipoHorasExtra con pk: ${pk}, obtenido con éxito`
    );
    temp.estado = DEFAULT_STATE.success;
    temp.mensaje = `MvTipoHorasExtra obtenido con éxito`;
    temp.data = await getStorageMvTipoHorasExtraArrayItem(pk);
    return temp;
  }
  const result = await get(`nomina/tipo-horas-extra/${pk}`);
  const data = result.data;
  console.log(`MvTipoHorasExtra data: `, data);
  if (!data.id || data.detail) {
    console.log(
      `Error al obtener MvTipoHorasExtra con pk: ${pk}, ${data.detail}`
    );
    temp.estado = DEFAULT_STATE.error;
    temp.mensaje = `Error al obtener el MvTipoHorasExtra: ${
      data.detail ?? "Hubo un problema al conectarse con el servidor"
    }`;
    temp.data = await getStorageMvTipoHorasExtraArrayItem(pk);
    return temp;
  }
  console.log(`MvTipoHorasExtra con pk: ${pk}, obtenido con éxito`);
  temp.estado = DEFAULT_STATE.success;
  temp.mensaje = `MvTipoHorasExtra obtenido con éxito`;
  temp.data = await setMvTipoHorasExtra(data, serverDate);
  await setStorageMvTipoHorasExtra(temp.data);
  temp.data_array = await setStorageMvTipoHorasExtraArrayItem(pk, temp.data);
  temp.expiry = await updateLocalStorageExpiry(serverDate, temp.expiry);
  return temp;
};

export const getAllMvTipoHorasExtra = async (serverDate: IServerDate) => {
  const temp = getEmptyStatusMvTipoHorasExtra();
  const doHttp = await getFromLocalStorage(serverDate, temp.expiry_array);
  temp.expiry_array = doHttp.storage;
  if (!doHttp.result) {
    console.log(
      `(localStorage) Todos los MvTipoHorasExtras obtenidos con éxito`
    );
    temp.estado = DEFAULT_STATE.success;
    temp.mensaje = `Todos los MvTipoHorasExtra obtenidos con éxito`;
    temp.data_array = await getStorageMvTipoHorasExtraArray();
    return temp;
  }
  const result = await get(`nomina/tipo-horas-extra`);
  const data = result.data;
  if (data.detail) {
    console.log(
      `Error al obtener la lista de MvTipoHorasExtra, ${data.detail}`
    );
    temp.estado = DEFAULT_STATE.error;
    temp.mensaje = `Error al obtener la lista de MvTipoHorasExtra: ${
      data.detail ?? "Hubo un problema al conectarse con el servidor"
    }`;
    temp.data_array = await getStorageMvTipoHorasExtraArray();
    return temp;
  }
  console.log(`Todos los MvTipoHorasExtra obtenidos con éxito`, data);
  temp.estado = DEFAULT_STATE.success;
  temp.mensaje = `Todos los MvTipoHorasExtra obtenidos con éxito`;
  temp.data_array = await Promise.all(
    data.map(async (e: any) => await setMvTipoHorasExtra(e, serverDate))
  );
  await setStorageMvTipoHorasExtraArray(temp.data_array);
  temp.expiry_array = await updateLocalStorageExpiry(
    serverDate,
    temp.expiry_array
  );
  return temp;
};

export const updateMvTipoHorasExtra = async (
  serverDate: IServerDate,
  pk: number,
  object: IMvTipoHorasExtra
) => {
  const temp = getEmptyStatusMvTipoHorasExtra();
  const result = await put(
    `nomina/tipo-horas-extra/${pk}`,
    getSerializableMvTipoHorasExtra(object)
  );
  const data = result.data;
  if (!data.id || data.detail) {
    console.log(
      `Error al actualizar MvTipoHorasExtra con pk: ${pk}, ${data.detail}`
    );
    temp.estado = DEFAULT_STATE.error;
    temp.mensaje = `Error al actualizar el MvTipoHorasExtra: ${
      data.detail ?? "Hubo un problema al conectarse con el servidor"
    }`;
    temp.data = await setMvTipoHorasExtra(object, serverDate);
    return temp;
  }
  console.log(`MvTipoHorasExtra con pk: ${pk}, actualizado con éxito`);
  temp.estado = DEFAULT_STATE.success;
  temp.mensaje = `MvTipoHorasExtra actualizado con éxito`;
  temp.data = await setMvTipoHorasExtra(data, serverDate);
  await setStorageMvTipoHorasExtra(temp.data);
  temp.data_array = await setStorageMvTipoHorasExtraArrayItem(
    temp.data.id,
    temp.data
  );
  return temp;
};

export const createMvTipoHorasExtra = async (
  serverDate: IServerDate,
  object: IMvTipoHorasExtra
) => {
  const temp = getEmptyStatusMvTipoHorasExtra();
  const result = await post(
    `nomina/tipo-horas-extra`,
    getSerializableMvTipoHorasExtra(object)
  );
  const data = result.data;
  if (!data.id || data.detail) {
    console.log(
      `Error al crear MvTipoHorasExtra con los datos ${object}, ${data.detail}`
    );
    temp.estado = DEFAULT_STATE.error;
    temp.mensaje = `Error al crear el MvTipoHorasExtra: ${
      data.detail ?? "Hubo un problema al conectarse con el servidor"
    }`;
    temp.data = await setMvTipoHorasExtra(object, serverDate);
    return temp;
  }
  console.log(`MvTipoHorasExtra creado con éxito`);
  temp.estado = DEFAULT_STATE.success;
  temp.mensaje = `MvTipoHorasExtra creado con éxito`;
  temp.data = await setMvTipoHorasExtra(data, serverDate);
  await setStorageMvTipoHorasExtra(temp.data);
  temp.data_array = await setStorageMvTipoHorasExtraArrayItem(
    temp.data.id,
    temp.data
  );
  return temp;
};

export const deleteMvTipoHorasExtra = async (pk: number) => {
  const temp = getEmptyStatusMvTipoHorasExtra();
  const result = await del(`nomina/tipo-horas-extra/${pk}`);
  const data = result.data;
  if (data.detail) {
    console.log(
      `Error al eliminar MvTipoHorasExtra con pk: ${pk}, ${data.detail}`
    );
    temp.estado = DEFAULT_STATE.error;
    temp.mensaje = `Error al eliminar el datos fiscales: ${data.detail}`;
    temp.data = await getStorageMvTipoHorasExtraArrayItem(pk);
    temp.data_array = await getStorageMvTipoHorasExtraArray();
    return temp;
  }
  console.log(`MvTipoHorasExtra eliminado con éxito`);
  temp.estado = DEFAULT_STATE.success;
  temp.mensaje = `MvTipoHorasExtra eliminado con éxito`;
  await removeStorageMvTipoHorasExtra();
  temp.data_array = await removeArrayItemStorageData(
    "IMvTipoHorasExtra",
    pk,
    []
  );
  return temp;
};
