/** 
 * Componente que se encarga del elemento CambioEstatusAsistencia
 * @ICambioEstatusAsistencia es la interfaz que contiene los parámetos el elemento CambioEstatusAsistencia
 * @getEmptyCambioEstatusAsistencia  devuelve un objeto con los parámetros por defecto; @param {id}, opcional cuando no encuentra el objeto en el LocalSotrage
 * @IStatusCambioEstatusAsistencia es la interfaz para los estatus a llamadas a la API; @param {status}, opcional cuando queremos otro estado de inizialización de tipo [DEFAULT_STATE]
 * @getEmptyStatusCambioEstatusAsistencia devuelve un objeto con los parámetros por defecto,
 * @setCambioEstatusAsistencia devuelve un objeto de tipo [ICambioEstatusAsistencia] recibiendo @param {object} y @param {serverDate}, para verificar que los datos estén en el formato correcto y ajustarlos a la zona horaria actual
 * @getSerializableCambioEstatusAsistencia recibe un @param {ICambioEstatusAsistencia}, para devolver un objeto serizalizable para las peticiones de la API
 * */

import { isNotEmpty, getDateString, SERVER_TIME_ZONE } from "../../api/helpers";
import { DEFAULT_STATE } from "../../enums/default_state";
import { getStorageAsistenciaArrayItem, getStorageMvEstatusAsistenciaArrayItem } from "../../localStorage/nomina";
import { getStorageClienteQentryArrayItem, getStorageUserArrayItem } from "../../localStorage/personas";
import { IUser, getEmptyUser, setUser, IClienteQentry, getEmptyClienteQentry, setClienteQentry } from "../personas";
import { ILocalStorageExpiry, getEmptyLocalStorageExpiry, IServerDate, convertDateToZonaHoraria } from "../time";
import { IAsistencia, getEmptyAsistencia, setAsistencia } from "./Asistencia";
import { IMvEstatusAsistencia, getEmptyMvEstatusAsistencia, setMvEstatusAsistencia } from "./MvEstatusAsistencia";

export interface ICambioEstatusAsistencia {
  id : number,
  asistencia : IAsistencia,
  nuevo_estatus : IMvEstatusAsistencia,
  autor : IUser,
  fecha_elaboracion : string, // Fecha
  estatus_sistema : boolean,
  ultima_actualizacion : string, // Fecha
  cliente_qentry : IClienteQentry,
}

export const getEmptyCambioEstatusAsistencia = (id? : number) => {
  const temp : ICambioEstatusAsistencia = 
  {
    id : id ? id : 0,
    asistencia : getEmptyAsistencia(),
    nuevo_estatus : getEmptyMvEstatusAsistencia(),
    autor : getEmptyUser(),
    fecha_elaboracion : "",
    estatus_sistema : false,
    ultima_actualizacion : "",
    cliente_qentry : getEmptyClienteQentry(),
  };
  return temp;
}

export interface IStatusCambioEstatusAsistencia {
  estado : DEFAULT_STATE,
  mensaje : string,
  data : ICambioEstatusAsistencia,
  data_array: ICambioEstatusAsistencia[],
  expiry : ILocalStorageExpiry,
  expiry_array : ILocalStorageExpiry,
}

export const getEmptyStatusCambioEstatusAsistencia = (status: DEFAULT_STATE = DEFAULT_STATE.loading) => {
  const temp : IStatusCambioEstatusAsistencia = 
  {
    estado : status,
    mensaje : "",
    data : getEmptyCambioEstatusAsistencia(),
    data_array : [],
    expiry : getEmptyLocalStorageExpiry("ICambioEstatusAsistencia", 0),
    expiry_array : getEmptyLocalStorageExpiry("ICambioEstatusAsistenciaArray", 0),
  };
  return temp;
}

export const setCambioEstatusAsistencia = async (
  object : any,
  serverDate : IServerDate,
) => {
  const temp = getEmptyCambioEstatusAsistencia();
  if (isNotEmpty(object.id)) temp.id = object.id;
  if (isNotEmpty(object.asistencia)) temp.asistencia = (typeof object.asistencia === 'number') ? await getStorageAsistenciaArrayItem(object.asistencia) : await setAsistencia(object.asistencia, serverDate);
  if (isNotEmpty(object.nuevo_estatus)) temp.nuevo_estatus = (typeof object.nuevo_estatus === 'number') ? await getStorageMvEstatusAsistenciaArrayItem(object.nuevo_estatus) : await setMvEstatusAsistencia(object.nuevo_estatus, serverDate);
  if (isNotEmpty(object.autor)) temp.autor = (typeof object.autor === 'number') ? await getStorageUserArrayItem(object.autor) : await setUser(object.autor, serverDate);
  if (isNotEmpty(object.fecha_elaboracion)) temp.fecha_elaboracion = convertDateToZonaHoraria(serverDate, object.fecha_elaboracion);
  if (isNotEmpty(object.estatus_sistema)) temp.estatus_sistema = object.estatus_sistema;
  if (isNotEmpty(object.ultima_actualizacion)) temp.ultima_actualizacion = convertDateToZonaHoraria(serverDate, object.ultima_actualizacion);
  if (isNotEmpty(object.cliente_qentry)) temp.cliente_qentry = (typeof object.cliente_qentry === 'number') ? await getStorageClienteQentryArrayItem(object.cliente_qentry) : await setClienteQentry(object.cliente_qentry, serverDate);
  return temp;
}

export const getSerializableCambioEstatusAsistencia = (ICambioEstatusAsistencia : ICambioEstatusAsistencia ) => {
  return {
    id : ICambioEstatusAsistencia.id,
    asistencia : ICambioEstatusAsistencia.asistencia.id,
    nuevo_estatus : ICambioEstatusAsistencia.nuevo_estatus.id,
    autor : ICambioEstatusAsistencia.autor.id,
    fecha_elaboracion : ICambioEstatusAsistencia.fecha_elaboracion,
    estatus_sistema : ICambioEstatusAsistencia.estatus_sistema,
    ultima_actualizacion : getDateString(SERVER_TIME_ZONE),
    cliente_qentry : ICambioEstatusAsistencia.cliente_qentry.id,
  };
}
