
/** 
 * Componente que se encarga del elemento DatosFiscales
 * @IDatosFiscales es la interfaz que contiene los parámetos el elemento DatosFiscales
 * @getEmptyDatosFiscales  devuelve un objeto con los parámetros por defecto; @param {id}, opcional cuando no encuentra el objeto en el LocalSotrage
 * @IStatusDatosFiscales es la interfaz para los estatus a llamadas a la API; @param {status}, opcional cuando queremos otro estado de inizialización de tipo [DEFAULT_STATE]
 * @getEmptyStatusDatosFiscales devuelve un objeto con los parámetros por defecto,
 * @setDatosFiscales devuelve un objeto de tipo [IDatosFiscales] recibiendo @param {object} y @param {serverDate}, para verificar que los datos estén en el formato correcto y ajustarlos a la zona horaria actual
 * @getSerializableDatosFiscales recibe un @param {IDatosFiscales}, para devolver un objeto serizalizable para las peticiones de la API
 * */

import { getDateString, isNotEmpty, SERVER_TIME_ZONE } from "../../api/helpers";
import { DEFAULT_STATE } from "../../enums/default_state";
import { getStorageMvUsoFacturaArrayItem, getStorageUserArrayItem } from "../../localStorage/personas";
import { convertDateToZonaHoraria, ILocalStorageExpiry, getEmptyLocalStorageExpiry, IServerDate } from "../time";
import { IMvUsoFactura, getEmptyMvUsoFactura, setMvUsoFactura } from "./IMvUsoFactura";
import { IUser, getEmptyUser, setUser } from "./IUser";

export interface IDatosFiscales {
  id : number,
  tax_id : string,
  nombre_o_razon_social : string,
  uso_factura : IMvUsoFactura,
  rfc_default : boolean,
  autor : IUser
  fecha_elaboracion : string, //Fecha
  estatus_sistema : boolean,
  ultima_actualizacion : string, //Fecha
}

export const getEmptyDatosFiscales = (id? : number) => {
  const temp : IDatosFiscales = 
  {
    id : id ? id : 0,
    tax_id : "",
    nombre_o_razon_social : "",
    uso_factura : getEmptyMvUsoFactura(),
    rfc_default : false,
    autor : getEmptyUser(),
    fecha_elaboracion : "",
    estatus_sistema : false,
    ultima_actualizacion :  "",
  };
  return temp;
}

export interface IStatusDatosFiscales  {
  estado : DEFAULT_STATE,
  mensaje : string,
  data : IDatosFiscales ,
  data_array: IDatosFiscales[],
  expiry : ILocalStorageExpiry,
  expiry_array : ILocalStorageExpiry,
}

export const getEmptyStatusDatosFiscales = (status: DEFAULT_STATE = DEFAULT_STATE.loading) => {
  const temp : IStatusDatosFiscales = 
  {
    estado : status,
    mensaje : "",
    data : getEmptyDatosFiscales(),
    data_array : [],
    expiry : getEmptyLocalStorageExpiry("IDatosFiscales", 0),
    expiry_array : getEmptyLocalStorageExpiry("IDatosFiscalesArray", 0),
  };
  return temp;
}

export const setDatosFiscales = async (
  object : any,
  serverDate : IServerDate,
) => {
  const temp = getEmptyDatosFiscales();
  if (isNotEmpty(object.id)) temp.id = object.id;
  if (isNotEmpty(object.tax_id)) temp.tax_id = object.tax_id;
  if (isNotEmpty(object.nombre_o_razon_social)) temp.nombre_o_razon_social = object.nombre_o_razon_social;
  if (isNotEmpty(object.uso_factura)) temp.uso_factura = (typeof object.uso_factura === 'number') ? await getStorageMvUsoFacturaArrayItem(object.uso_factura) : await setMvUsoFactura(object.uso_factura, serverDate);
  if (isNotEmpty(object.rfc_default)) temp.rfc_default = object.rfc_default;
  if (isNotEmpty(object.autor)) temp.autor = (typeof object.autor === 'number') ? await getStorageUserArrayItem(object.autor) : await setUser(object.autor, serverDate);
  if (isNotEmpty(object.fecha_elaboracion)) temp.fecha_elaboracion = convertDateToZonaHoraria(serverDate, object.fecha_elaboracion);
  if (isNotEmpty(object.estatus_sistema)) temp.estatus_sistema = object.estatus_sistema;
  if (isNotEmpty(object.ultima_actualizacion)) temp.ultima_actualizacion = convertDateToZonaHoraria(serverDate, object.ultima_actualizacion);
  return temp;
}

export const getSerializableDatosFiscales = (IDatosFiscales : IDatosFiscales) => {
  return {
    id : IDatosFiscales.id,
    tax_id : IDatosFiscales.tax_id,
    nombre_o_razon_social : IDatosFiscales.nombre_o_razon_social,
    uso_factura : IDatosFiscales.uso_factura.id,
    rfc_default : IDatosFiscales.rfc_default,    
    autor : IDatosFiscales.autor.id,
    fecha_elaboracion : IDatosFiscales.fecha_elaboracion,
    estatus_sistema : IDatosFiscales.estatus_sistema,
    ultima_actualizacion : getDateString(SERVER_TIME_ZONE),
  };
}
