/**
 * Es el menú lateral izquierdo para la lista de páginas de la aplicación
 * @function getUserType devuelve el tipo de usuario que inició sesión
 */

import React from "react";
import {
  IonContent,
  IonIcon,
  IonItem,
  IonImg,
  IonLabel,
  IonList,
  IonListHeader,
  IonMenu,
  IonMenuToggle,
  IonNote,
} from "@ionic/react";

import { useLocation } from "react-router-dom";
import {
  addOutline,
  addSharp,
  airplaneOutline,
  airplaneSharp,
  bandageOutline,
  bandageSharp,
  briefcaseOutline,
  briefcaseSharp,
  documentsOutline,
  documentsSharp,
  documentTextOutline,
  documentTextSharp,
  gridOutline,
  gridSharp,
  mailOutline,
  mailSharp,
  notificationsOutline,
  notificationsSharp,
  personOutline,
  personSharp,
  peopleOutline,
  peopleSharp,
  phonePortraitOutline,
  phonePortraitSharp,
  timeOutline,
  timeSharp,
  walkOutline,
  walkSharp,
  helpOutline,
  helpSharp,
  busOutline,
  businessSharp,
  businessOutline,
} from "ionicons/icons";
import "./Menu.css";

import useRedux from "../hooks/useRedux";
import { getNombreEmpleado } from "../redux/slices/personasSlice";

const ADMIN = 1;
const USER = 2;

interface AppPage {
  url: string;
  iosIcon: string;
  mdIcon: string;
  title: string;
  tipo: string[];
}

const appPages: AppPage[] = [
  {
    title: "Resumen",
    url: "/page/Resumen",
    iosIcon: gridOutline,
    mdIcon: gridSharp,
    tipo: ["Cliente", "Administrativo"],
  },
];

const controlAsistencia: AppPage[] = [
  {
    title: "Usuarios colaboradores",
    url: "/page/Usuarios-colaboradores",
    iosIcon: peopleOutline,
    mdIcon: peopleSharp,
    tipo: ["Cliente", "Administrativo"],
  },
  {
    title: "Centros de trabajo",
    url: "/page/Centros-de-trabajo",
    iosIcon: briefcaseOutline,
    mdIcon: briefcaseSharp,
    tipo: ["Cliente", "Administrativo"],
  },
  {
    title: "Rondines",
    url: "/page/Rondines",
    iosIcon: walkOutline,
    mdIcon: walkSharp,
    tipo: ["Cliente", "Administrativo"],
  },
  {
    title: "Reportes",
    url: "/page/Reportes",
    iosIcon: documentsOutline,
    mdIcon: documentsSharp,
    tipo: ["Cliente", "Administrativo"],
  },
  {
    title: "Historial / Bitácora",
    url: "/page/Historial",
    iosIcon: documentTextOutline,
    mdIcon: documentTextSharp,
    tipo: ["Cliente", "Administrativo", "Supervisor"],
  },
  {
    title: "Solicitudes",
    url: "/page/Solicitudes",
    iosIcon: notificationsOutline,
    mdIcon: notificationsSharp,
    tipo: ["Cliente", "Administrativo", "Supervisor"],
  },
  {
    title: "Incapacidades",
    url: "/page/Incapacidades",
    iosIcon: bandageOutline,
    mdIcon: bandageSharp,
    tipo: ["Cliente", "Administrativo", "Supervisor"],
  },
  {
    title: "Vacaciones",
    url: "/page/Vacaciones",
    iosIcon: airplaneOutline,
    mdIcon: airplaneSharp,
    tipo: ["Cliente", "Administrativo", "Supervisor"],
  },
  {
    title: "Cambio de dispositivo",
    url: "/page/Solicitudes-cambio-dispositivo",
    iosIcon: phonePortraitOutline,
    mdIcon: phonePortraitSharp,
    tipo: ["Cliente", "Administrativo", "Supervisor"],
  },
  {
    title: "Cambios de horarios",
    url: "/page/Cambios-de-horarios",
    iosIcon: timeOutline,
    mdIcon: timeSharp,
    tipo: ["Cliente", "Administrativo", "Supervisor"],
  },
  {
    title: "Empresas",
    url: "/page/Empresas",
    iosIcon: businessOutline,
    mdIcon: businessSharp,
    tipo: ["Cliente", "Administrativo"],
  },
];

const elemento32: AppPage[] = [
  {
    title: "Perfil",
    url: "/page/Perfil",
    iosIcon: personOutline,
    mdIcon: personSharp,
    tipo: ["Cliente", "Administrador"],
  },
  {
    title: "Aplicaciones del usuario",
    url: "/page/Aplicaciones-del-usuario",
    iosIcon: addOutline,
    mdIcon: addSharp,
    tipo: ["Cliente", "Administrador"],
  },
  {
    title: "Faq",
    url: "/page/Faq",
    iosIcon: helpOutline,
    mdIcon: helpSharp,
    tipo: ["Cliente", "Administrador", "Supervisor"],
  },
];
const Menu: React.FC = () => {
  const [{ auth }] = useRedux();
  const location = useLocation();

  function getUserType() {
    if (auth.empleado.tipo_empleado.id === ADMIN) return "Administrativo";
    if (auth.empleado.es_supervisor) return "Supervisor";
    if (auth.empleado.tipo_empleado.id === USER) return "Trabajador";
    return "Trabajador";
  }

  return (
    <IonMenu contentId="main" type="overlay">
      <IonContent>
        <IonList id="inbox-list">
          <IonImg class="logo" src="../assets/img/qentrydoswhite.png" />
          <IonListHeader>Bienvenido a QEntry Admin</IonListHeader>
          <IonNote>{`${getNombreEmpleado(auth.empleado)}`}</IonNote>
          {appPages.map((appPage, index) => {
            return (
              <IonMenuToggle
                key={index}
                autoHide={false}
                id="resume"
                className="ejemplo"
              >
                <IonItem
                  className={
                    location.pathname === appPage.url ? "selected" : ""
                  }
                  routerLink={appPage.url}
                  routerDirection="none"
                  lines="none"
                  detail={false}
                >
                  <IonIcon
                    slot="start"
                    ios={appPage.iosIcon}
                    md={appPage.mdIcon}
                  />
                  <IonLabel>{appPage.title}</IonLabel>
                </IonItem>
              </IonMenuToggle>
            );
          })}
        </IonList>

        <IonList id="control-asistencia-list">
          <IonNote>Control de asistencia</IonNote>
          {controlAsistencia.map((appPage, index) => {
            //userContext.userData.
            return appPage.tipo.find((e) => e === getUserType()) ? (
              <IonMenuToggle key={index} autoHide={false}>
                <IonItem
                  className={
                    location.pathname === appPage.url ? "selected" : ""
                  }
                  routerLink={appPage.url}
                  routerDirection="none"
                  lines="none"
                  detail={false}
                >
                  <IonIcon
                    slot="start"
                    ios={appPage.iosIcon}
                    md={appPage.mdIcon}
                  />
                  <IonLabel>{appPage.title}</IonLabel>
                </IonItem>
              </IonMenuToggle>
            ) : (
              <IonMenuToggle key={index}></IonMenuToggle>
            );
          })}
        </IonList>

        <IonList id="elemento32-list">
          <IonNote>Datos personales</IonNote>
          {elemento32.map((appPage, index) => {
            return (
              <IonMenuToggle key={index} autoHide={false}>
                <IonItem
                  className={
                    location.pathname === appPage.url ? "selected" : ""
                  }
                  routerLink={appPage.url}
                  routerDirection="none"
                  lines="none"
                  detail={false}
                >
                  <IonIcon
                    slot="start"
                    ios={appPage.iosIcon}
                    md={appPage.mdIcon}
                  />
                  <IonLabel>{appPage.title}</IonLabel>
                </IonItem>
              </IonMenuToggle>
            );
          })}
        </IonList>
      </IonContent>
    </IonMenu>
  );
};

export default Menu;
