import axios from 'axios';

const _axios = axios.create({
  baseURL: process.env.REACT_APP_SERVER_URL,
});

_axios.interceptors.response.use(
  (response) => {
    const res = response;
    if (res.data.e) {
        res.data.detail = res.data.e;        
    }
    return res;
  }
)

export default _axios;

