/**
 * Componente que se encarga del elemento SolicitudCambioTelefonoAutorizado
 * @ISolicitudCambioTelefonoAutorizado es la interfaz que contiene los parámetos el elemento SolicitudCambioTelefonoAutorizado
 * @getEmptySolicitudCambioTelefonoAutorizado  devuelve un objeto con los parámetros por defecto; @param {id}, opcional cuando no encuentra el objeto en el LocalSotrage
 * @IStatusSolicitudCambioTelefonoAutorizado es la interfaz para los estatus a llamadas a la API; @param {status}, opcional cuando queremos otro estado de inizialización de tipo [DEFAULT_STATE]
 * @getEmptyStatusSolicitudCambioTelefonoAutorizado devuelve un objeto con los parámetros por defecto,
 * @setSolicitudCambioTelefonoAutorizado devuelve un objeto de tipo [ISolicitudCambioTelefonoAutorizado] recibiendo @param {object} y @param {serverDate}, para verificar que los datos estén en el formato correcto y ajustarlos a la zona horaria actual
 * @getSerializableSolicitudCambioTelefonoAutorizado recibe un @param {ISolicitudCambioTelefonoAutorizado}, para devolver un objeto serizalizable para las peticiones de la API
 * */

import { getDateString, isNotEmpty, SERVER_TIME_ZONE } from "../../api/helpers";
import { DEFAULT_STATE } from "../../enums/default_state";
import {
  getStorageEmpleadoArrayItem,
  getStorageUserArrayItem,
  getStorageMvEstatusSolicitudCambioTelefonoAutorizadoArrayItem,
  getStorageClienteQentryArrayItem,
} from "../../localStorage/personas";
import {
  convertDateToZonaHoraria,
  ILocalStorageExpiry,
  getEmptyLocalStorageExpiry,
  IServerDate,
} from "../time";
import {
  IClienteQentry,
  getEmptyClienteQentry,
  setClienteQentry,
} from "./IClienteQentry";
import { IEmpleado, getEmptyEmpleado, setEmpleado } from "./IEmpleado";
import {
  IMvEstatusSolicitudCambioTelefonoAutorizado,
  getEmptyMvEstatusSolicitudCambioTelefonoAutorizado,
  setMvEstatusSolicitudCambioTelefonoAutorizado,
} from "./IMvEstatusSolicitudCambioTelefonoAutorizado";
import { IUser, getEmptyUser, setUser } from "./IUser";

export interface ISolicitudCambioTelefonoAutorizado {
  id: number;
  empleado: IEmpleado;
  id_telefono_autorizado_anterior: string;
  id_telefono_autorizado_nuevo: string;
  usuario_aprobador: IUser;
  estatus: IMvEstatusSolicitudCambioTelefonoAutorizado;
  fecha_elaboracion: string; // Fecha
  estatus_sistema: boolean;
  ultima_actualizacion: string; // Fecha
  cliente_qentry: IClienteQentry;
}

export interface IUpdateTelefonoAutorizado {
  id_empleado: number;
  id_telefono: string;
}

export const getEmptySolicitudCambioTelefonoAutorizado = (id?: number) => {
  const temp: ISolicitudCambioTelefonoAutorizado = {
    id: id ? id : 0,
    empleado: getEmptyEmpleado(),
    id_telefono_autorizado_anterior: "",
    id_telefono_autorizado_nuevo: "",
    usuario_aprobador: getEmptyUser(),
    estatus: getEmptyMvEstatusSolicitudCambioTelefonoAutorizado(),
    fecha_elaboracion: "",
    estatus_sistema: false,
    ultima_actualizacion: "",
    cliente_qentry: getEmptyClienteQentry(),
  };
  return temp;
};

export const getEmptyUpdateTelefonoAutorizado = () => {
  const temp: IUpdateTelefonoAutorizado = {
    id_empleado: 0,
    id_telefono: "",
  };
  return temp;
};

export interface IStatusSolicitudCambioTelefonoAutorizado {
  estado: DEFAULT_STATE;
  mensaje: string;
  data: ISolicitudCambioTelefonoAutorizado;
  data_array: ISolicitudCambioTelefonoAutorizado[];
  expiry: ILocalStorageExpiry;
  expiry_array: ILocalStorageExpiry;
}

export interface IStatusUpdateTelefonoAutorizado {
  estado: DEFAULT_STATE;
  mensaje: string;
  data: IUpdateTelefonoAutorizado;
  data_array: IUpdateTelefonoAutorizado[];
  expiry: ILocalStorageExpiry;
  expiry_array: ILocalStorageExpiry;
}

export const getEmptyStatusSolicitudCambioTelefonoAutorizado = (
  status: DEFAULT_STATE = DEFAULT_STATE.loading
) => {
  const temp: IStatusSolicitudCambioTelefonoAutorizado = {
    estado: status,
    mensaje: "",
    data: getEmptySolicitudCambioTelefonoAutorizado(),
    data_array: [],
    expiry: getEmptyLocalStorageExpiry("ISolicitudCambioTelefonoAutorizado", 0),
    expiry_array: getEmptyLocalStorageExpiry(
      "ISolicitudCambioTelefonoAutorizadoArray",
      0
    ),
  };
  return temp;
};

export const getEmptyStatusUpdateTelefonoAutorizado = (
  status: DEFAULT_STATE = DEFAULT_STATE.loading
) => {
  const temp: IStatusUpdateTelefonoAutorizado = {
    estado: status,
    mensaje: "",
    data: getEmptyUpdateTelefonoAutorizado(),
    data_array: [],
    expiry: getEmptyLocalStorageExpiry("IUpdateTelefonoAutorizado", 0),
    expiry_array: getEmptyLocalStorageExpiry(
      "IUpdateTelefonoAutorizadoArray",
      0
    ),
  };
  return temp;
};

export const setSolicitudCambioTelefonoAutorizado = async (
  object: any,
  serverDate: IServerDate
) => {
  const temp = getEmptySolicitudCambioTelefonoAutorizado();
  if (isNotEmpty(object.id)) temp.id = object.id;
  if (isNotEmpty(object.empleado))
    temp.empleado =
      typeof object.empleado === "number"
        ? await getStorageEmpleadoArrayItem(object.empleado)
        : await setEmpleado(object.empleado, serverDate);
  if (isNotEmpty(object.id_telefono_autorizado_anterior))
    temp.id_telefono_autorizado_anterior =
      object.id_telefono_autorizado_anterior;
  if (isNotEmpty(object.id_telefono_autorizado_nuevo))
    temp.id_telefono_autorizado_nuevo = object.id_telefono_autorizado_nuevo;
  if (isNotEmpty(object.usuario_aprobador))
    temp.usuario_aprobador =
      typeof object.usuario_aprobador === "number"
        ? await getStorageUserArrayItem(object.usuario_aprobador)
        : await setUser(object.usuario_aprobador, serverDate);
  if (isNotEmpty(object.estatus))
    temp.estatus =
      typeof object.estatus === "number"
        ? await getStorageMvEstatusSolicitudCambioTelefonoAutorizadoArrayItem(
            object.estatus
          )
        : await setMvEstatusSolicitudCambioTelefonoAutorizado(
            object.estatus,
            serverDate
          );
  if (isNotEmpty(object.fecha_elaboracion))
    temp.fecha_elaboracion = convertDateToZonaHoraria(
      serverDate,
      object.fecha_elaboracion
    );
  if (isNotEmpty(object.estatus_sistema))
    temp.estatus_sistema = object.estatus_sistema;
  if (isNotEmpty(object.ultima_actualizacion))
    temp.ultima_actualizacion = convertDateToZonaHoraria(
      serverDate,
      object.ultima_actualizacion
    );
  if (isNotEmpty(object.cliente_qentry))
    temp.cliente_qentry =
      typeof object.cliente_qentry === "number"
        ? await getStorageClienteQentryArrayItem(object.cliente_qentry)
        : await setClienteQentry(object.cliente_qentry, serverDate);
  return temp;
};

export const setUpdateTelefonoAutorizado = async (object: any) => {
  const temp = getEmptyUpdateTelefonoAutorizado();
  if (isNotEmpty(object.id_empleado)) temp.id_empleado = object.id_empleado;
  if (isNotEmpty(object.empleado)) temp.id_telefono = object.id_telefono;
  return temp;
};

export const getSerializableSolicitudCambioTelefonoAutorizado = (
  ISolicitudCambioTelefonoAutorizado: ISolicitudCambioTelefonoAutorizado
) => {
  return {
    id: ISolicitudCambioTelefonoAutorizado.id,
    empleado: ISolicitudCambioTelefonoAutorizado.empleado.id,
    id_telefono_autorizado_anterior:
      ISolicitudCambioTelefonoAutorizado.id_telefono_autorizado_anterior,
    id_telefono_autorizado_nuevo:
      ISolicitudCambioTelefonoAutorizado.id_telefono_autorizado_nuevo,
    usuario_aprobador:
      ISolicitudCambioTelefonoAutorizado.usuario_aprobador.id > 0
        ? ISolicitudCambioTelefonoAutorizado.usuario_aprobador.id
        : null,
    estatus:
      ISolicitudCambioTelefonoAutorizado.estatus.id > 0
        ? ISolicitudCambioTelefonoAutorizado.estatus.id
        : 0,
    fecha_elaboracion: ISolicitudCambioTelefonoAutorizado.fecha_elaboracion,
    estatus_sistema: ISolicitudCambioTelefonoAutorizado.estatus_sistema,
    ultima_actualizacion: getDateString(SERVER_TIME_ZONE),
    cliente_qentry: ISolicitudCambioTelefonoAutorizado.cliente_qentry.id,
  };
};
