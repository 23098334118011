/** 
 * Componente que se encarga del elemento ModificadorNomina
 * @IModificadorNomina es la interfaz que contiene los parámetos el elemento ModificadorNomina
 * @getEmptyModificadorNomina  devuelve un objeto con los parámetros por defecto; @param {id}, opcional cuando no encuentra el objeto en el LocalSotrage
 * @IStatusModificadorNomina es la interfaz para los estatus a llamadas a la API; @param {status}, opcional cuando queremos otro estado de inizialización de tipo [DEFAULT_STATE]
 * @getEmptyStatusModificadorNomina devuelve un objeto con los parámetros por defecto,
 * @setModificadorNomina devuelve un objeto de tipo [IModificadorNomina] recibiendo @param {object} y @param {serverDate}, para verificar que los datos estén en el formato correcto y ajustarlos a la zona horaria actual
 * @getSerializableModificadorNomina recibe un @param {IModificadorNomina}, para devolver un objeto serizalizable para las peticiones de la API
 * */

import { getDateString, isNotEmpty, SERVER_TIME_ZONE } from "../../api/helpers";
import { DEFAULT_STATE } from "../../enums/default_state";
import { getStorageEmpresaArrayItem, getStorageUserArrayItem, getStorageClienteQentryArrayItem } from "../../localStorage/personas";
import { convertDateToZonaHoraria, ILocalStorageExpiry, getEmptyLocalStorageExpiry, IServerDate } from "../time";
import { IClienteQentry, getEmptyClienteQentry, setClienteQentry } from "./IClienteQentry";
import { IEmpresa, getEmptyEmpresa, setEmpresa } from "./IEmpresa";
import { IUser, getEmptyUser, setUser } from "./IUser";

export interface IModificadorNomina {
  id : number,
  nombre_modificador : string,
  valor_modificador : number, // Decimal
  empresa : IEmpresa,
  autor : IUser,
  fecha_elaboracion : string, // Fecha
  estatus_sistema : boolean,
  ultima_actualizacion : string, // Fecha
  cliente_qentry : IClienteQentry
}

export const getEmptyModificadorNomina = (id? : number) => {
  const temp : IModificadorNomina = 
  {
    id : id ? id : 0,
    nombre_modificador : "",
    valor_modificador : 0,
    empresa : getEmptyEmpresa(),
    autor : getEmptyUser(),
    fecha_elaboracion : "",
    estatus_sistema : false,
    ultima_actualizacion : "",
    cliente_qentry : getEmptyClienteQentry(),
  };
  return temp;
}

export interface IStatusModificadorNomina {
  estado : DEFAULT_STATE,
  mensaje : string,
  data : IModificadorNomina,
  data_array: IModificadorNomina[],
  expiry : ILocalStorageExpiry,
  expiry_array : ILocalStorageExpiry,
}

export const getEmptyStatusModificadorNomina = (status: DEFAULT_STATE = DEFAULT_STATE.loading) => {
  const temp : IStatusModificadorNomina = 
  {
    estado : status,
    mensaje : "",
    data : getEmptyModificadorNomina(),
    data_array : [],
    expiry : getEmptyLocalStorageExpiry("IModificadorNomina", 0),
    expiry_array : getEmptyLocalStorageExpiry("IModificadorNominaArray", 0),
  };
  return temp;
}

export const setModificadorNomina = async (
  object : any,
  serverDate : IServerDate,
) => {
  const temp = getEmptyModificadorNomina();
  if (isNotEmpty(object.id)) temp.id = object.id;
  if (isNotEmpty(object.nombre_modificador)) temp.nombre_modificador = object.nombre_modificador;
  if (isNotEmpty(object.valor_modificador)) temp.valor_modificador = Number(object.valor_modificador);
  if (isNotEmpty(object.empresa)) temp.empresa = (typeof object.empresa === 'number') ? await getStorageEmpresaArrayItem(object.empresa) : await setEmpresa(object.empresa, serverDate);
  if (isNotEmpty(object.autor)) temp.autor = (typeof object.autor === 'number') ? await getStorageUserArrayItem(object.autor) : await setUser(object.autor, serverDate);
  if (isNotEmpty(object.fecha_elaboracion)) temp.fecha_elaboracion = convertDateToZonaHoraria(serverDate, object.fecha_elaboracion);
  if (isNotEmpty(object.estatus_sistema)) temp.estatus_sistema = object.estatus_sistema;
  if (isNotEmpty(object.ultima_actualizacion)) temp.ultima_actualizacion = convertDateToZonaHoraria(serverDate, object.ultima_actualizacion);
  if (isNotEmpty(object.cliente_qentry)) temp.cliente_qentry = (typeof object.cliente_qentry === 'number') ? await getStorageClienteQentryArrayItem(object.cliente_qentry) : await setClienteQentry(object.cliente_qentry, serverDate);
  return temp;
}

export const getSerializableModificadorNomina = (IModificadorNomina : IModificadorNomina) => {
  return {
    id : IModificadorNomina.id,
    nombre_modificador : IModificadorNomina.nombre_modificador,
    valor_modificador : IModificadorNomina.valor_modificador.toFixed(2),
    empresa : IModificadorNomina.empresa.id,
    autor : IModificadorNomina.autor.id,
    fecha_elaboracion : IModificadorNomina.fecha_elaboracion,
    estatus_sistema : IModificadorNomina.estatus_sistema,
    ultima_actualizacion : getDateString(SERVER_TIME_ZONE),
    cliente_qentry : IModificadorNomina.cliente_qentry.id
  };
}
