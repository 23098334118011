/**
 * Métodos del, get, post, put para llamadas a la API
 * @get     en este método primero consulta con @getFromLocalStorage si es necesario hacer la petición http sino se obtiene del LocalStorage;
 *          si hay un error en la petición http se busca el objeto en el LocalStorage sino lo encuentra devuelve un objeto vacío; si la
 *          petición se realiza correctamente actualiza el valor individual y en el array del objeto en el LocalStorage y devuelve el objeto
 *          obtenido del servidor.
 * @getAll  en este método primero consulta con @getFromLocalStorage si es necesario hacer la petición http sino se obtiene del LocalStorage;
 *          si hay un error en la petición http se busca el objeto array en el LocalStorage sino lo encuentra devuelve un array vacío; si la
 *          petición se realiza correctamente actualiza array en el LocalStorage y devuelve el array obtenido del servidor
 * @update  en este método después de realizar la petición http si falla devuelve el objeto que se iba a actualizar; si la petición se
 *          realiza correctamente actualiza el valor individual y en el array del objeto en el LocalStorage y devuelve el objeto obtenido del
 *          servidor.
 * @create  en este método después de realizar la petición http si falla devuelve el objeto que se iba a crear; si la petición se realiza
 *          correctamente actualiza el valor individual y en el array del objeto en el LocalStorage y devuelve el objeto obtenido del
 *          servidor.
 * @delete  en este método después de realizar la petición http si falla devuelve el objeto que se iba a eliminar; si la petición se realiza
 *          correctamente elimina el valor individual y en el objeto en el arrya del LocalStorage y devuelve un objeto vacio (Default)
 */

import { del, get, post, put } from "../../api/api";
import {
  getFromLocalStorage,
  updateLocalStorageExpiry,
} from "../../api/helpers";
import { removeArrayItemStorageData } from "../../api/storage";
import { DEFAULT_STATE } from "../../enums/default_state";
import {
  getEmptyStatusIncapacidad,
  setIncapacidad,
  IIncapacidad,
  getSerializableIncapacidad,
} from "../../interfaces/nomina";
import { IServerDate } from "../../interfaces/time";
import {
  getStorageIncapacidadArrayItem,
  setStorageIncapacidad,
  setStorageIncapacidadArrayItem,
  getStorageIncapacidadArray,
  setStorageIncapacidadArray,
  removeStorageIncapacidad,
} from "../../localStorage/nomina";

export const getIncapacidad = async (
  serverDate: IServerDate,
  pk: number,
  idCliente: number
) => {
  const temp = getEmptyStatusIncapacidad();
  const doHttp = await getFromLocalStorage(serverDate, temp.expiry);
  temp.expiry = doHttp.storage;
  if (!doHttp.result) {
    console.log(`(localStorage) Incapacidad con pk: ${pk}, obtenido con éxito`);
    temp.estado = DEFAULT_STATE.success;
    temp.mensaje = `Incapacidad obtenida con éxito`;
    temp.data = await getStorageIncapacidadArrayItem(pk);
    return temp;
  }
  const result = await get(`nomina/incapacidad/${pk}/${idCliente}`);
  const data = result.data;
  console.log(`Incapacidad data: `, data);
  if (!data.id || data.detail) {
    console.log(`Error al obtener Incapacidad con pk: ${pk}, ${data.detail}`);
    temp.estado = DEFAULT_STATE.error;
    temp.mensaje = `Error al obtener la datos personales: ${
      data.detail ?? "Hubo un problema al conectarse con el servidor"
    }`;
    temp.data = await getStorageIncapacidadArrayItem(pk);
    return temp;
  }
  console.log(`Incapacidad con pk: ${pk}, obtenido con éxito`);
  temp.estado = DEFAULT_STATE.success;
  temp.mensaje = `Incapacidad obtenida con éxito`;
  temp.data = await setIncapacidad(data, serverDate);
  await setStorageIncapacidad(temp.data);
  temp.data_array = await setStorageIncapacidadArrayItem(pk, temp.data);
  temp.expiry = await updateLocalStorageExpiry(serverDate, temp.expiry);
  return temp;
};

export const getAllIncapacidad = async (
  serverDate: IServerDate,
  idCliente: number
) => {
  const temp = getEmptyStatusIncapacidad();
  const doHttp = await getFromLocalStorage(serverDate, temp.expiry_array);
  temp.expiry_array = doHttp.storage;
  if (!doHttp.result) {
    console.log(`(localStorage) Todos las Incapacidad obtenidas con éxito`);
    temp.estado = DEFAULT_STATE.success;
    temp.mensaje = `Todos las Incapacidad obtenidas con éxito`;
    temp.data_array = await getStorageIncapacidadArray();
    // return temp;
  }
  const result = await get(`nomina/incapacidad/${idCliente}`);
  const data = result.data;
  if (data.detail) {
    console.log(`Error al obtener la lista de Incapacidad`, data);
    temp.estado = DEFAULT_STATE.error;
    temp.mensaje = `Error al obtener la lista de Incapacidad: ${
      data.detail ?? "Hubo un problema al conectarse con el servidor"
    }`;
    temp.data_array = await getStorageIncapacidadArray();
    return temp;
  }
  console.log(`Todos las Incapacidads obtenidos con éxito`);
  temp.estado = DEFAULT_STATE.success;
  temp.mensaje = `Todos las Incapacidad obtenidas con éxito`;
  temp.data_array = await Promise.all(
    data.map(async (e: any) => await setIncapacidad(e, serverDate))
  );
  await setStorageIncapacidadArray(temp.data_array);
  temp.expiry_array = await updateLocalStorageExpiry(
    serverDate,
    temp.expiry_array
  );
  return temp;
};

export const updateIncapacidad = async (
  serverDate: IServerDate,
  pk: number,
  idCliente: number,
  object: IIncapacidad
) => {
  const temp = getEmptyStatusIncapacidad();
  const result = await put(
    `nomina/incapacidad/${pk}/${idCliente}`,
    getSerializableIncapacidad(object)
  );
  const data = result.data;
  if (!data.id || data.detail) {
    console.log(
      `Error al actualizar Incapacidad con pk: ${pk}, ${data.detail}`
    );
    temp.estado = DEFAULT_STATE.error;
    temp.mensaje = `Error al actualizar la datos personales: ${
      data.detail ?? "Hubo un problema al conectarse con el servidor"
    }`;
    temp.data = await setIncapacidad(object, serverDate);
    return temp;
  }
  console.log(`Incapacidad con pk: ${pk}, actualizado con éxito`);
  temp.estado = DEFAULT_STATE.success;
  temp.mensaje = `Incapacidad actualizada con éxito`;
  temp.data = await setIncapacidad(data, serverDate);
  await setStorageIncapacidad(temp.data);
  temp.data_array = await setStorageIncapacidadArrayItem(
    temp.data.id,
    temp.data
  );
  return temp;
};

export const createIncapacidad = async (
  serverDate: IServerDate,
  idCliente: number,
  object: IIncapacidad
) => {
  const temp = getEmptyStatusIncapacidad();
  const result = await post(
    `nomina/incapacidad/${idCliente}`,
    getSerializableIncapacidad(object)
  );
  const data = result.data;
  if (!data.id || data.detail) {
    console.log(
      `Error al crear Incapacidad con los datos ${object}, ${data.detail}`
    );
    temp.estado = DEFAULT_STATE.error;
    temp.mensaje = `Error al crear la datos personales: ${
      data.detail ?? "Hubo un problema al conectarse con el servidor"
    }`;
    temp.data = await setIncapacidad(object, serverDate);
    return temp;
  }
  console.log(`Incapacidad creada con éxito`);
  temp.estado = DEFAULT_STATE.success;
  temp.mensaje = `Incapacidad creada con éxito`;
  temp.data = await setIncapacidad(data, serverDate);
  await setStorageIncapacidad(temp.data);
  temp.data_array = await setStorageIncapacidadArrayItem(
    temp.data.id,
    temp.data
  );
  return temp;
};

export const deleteIncapacidad = async (pk: number, idCliente: number) => {
  const temp = getEmptyStatusIncapacidad();
  const result = await del(`nomina/incapacidad/${pk}/${idCliente}`);
  const data = result.data;
  if (data.detail) {
    console.log(`Error al eliminar Incapacidad con pk: ${pk}, ${data.detail}`);
    temp.estado = DEFAULT_STATE.error;
    temp.mensaje = `Error al eliminar la datos personales: ${data.detail}`;
    temp.data = await getStorageIncapacidadArrayItem(pk);
    temp.data_array = await getStorageIncapacidadArray();
    return temp;
  }
  console.log(`Incapacidad eliminada con éxito`);
  temp.estado = DEFAULT_STATE.success;
  temp.mensaje = `Incapacidad eliminada con éxito`;
  await removeStorageIncapacidad();
  temp.data_array = await removeArrayItemStorageData("IIncapacidad", pk, []);
  return temp;
};
