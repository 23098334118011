 
/**
 * Métodos del, get, post, put para llamadas a la API
 * @get     en este método primero consulta con @getFromLocalStorage si es necesario hacer la petición http sino se obtiene del LocalStorage;
 *          si hay un error en la petición http se busca el objeto en el LocalStorage sino lo encuentra devuelve un objeto vacío; si la
 *          petición se realiza correctamente actualiza el valor individual y en el array del objeto en el LocalStorage y devuelve el objeto
 *          obtenido del servidor.
 * @getAll  en este método primero consulta con @getFromLocalStorage si es necesario hacer la petición http sino se obtiene del LocalStorage;
 *          si hay un error en la petición http se busca el objeto array en el LocalStorage sino lo encuentra devuelve un array vacío; si la
 *          petición se realiza correctamente actualiza array en el LocalStorage y devuelve el array obtenido del servidor
 * @update  en este método después de realizar la petición http si falla devuelve el objeto que se iba a actualizar; si la petición se
 *          realiza correctamente actualiza el valor individual y en el array del objeto en el LocalStorage y devuelve el objeto obtenido del
 *          servidor.
 * @create  en este método después de realizar la petición http si falla devuelve el objeto que se iba a crear; si la petición se realiza 
 *          correctamente actualiza el valor individual y en el array del objeto en el LocalStorage y devuelve el objeto obtenido del
 *          servidor.
 * @delete  en este método después de realizar la petición http si falla devuelve el objeto que se iba a eliminar; si la petición se realiza 
 *          correctamente elimina el valor individual y en el objeto en el arrya del LocalStorage y devuelve un objeto vacio (Default)
 */

import { del, get, post, put } from "../../api/api";
import { getFromLocalStorage, updateLocalStorageExpiry } from "../../api/helpers";
import { removeArrayItemStorageData } from "../../api/storage";
import { DEFAULT_STATE } from "../../enums/default_state";
import { getEmptyStatusModificadorNomina, setModificadorNomina, IModificadorNomina, getSerializableModificadorNomina } from "../../interfaces/personas";
import { IServerDate } from "../../interfaces/time";
import { getStorageModificadorNominaArrayItem, setStorageModificadorNomina, setStorageModificadorNominaArrayItem, getStorageModificadorNominaArray, setStorageModificadorNominaArray, removeStorageModificadorNomina } from "../../localStorage/personas";

export const getModificadorNomina = async (serverDate: IServerDate, pk : number, idCliente : number) => {
  const temp = getEmptyStatusModificadorNomina();
  const doHttp = await getFromLocalStorage(serverDate, temp.expiry);
  temp.expiry = doHttp.storage;
  if (!doHttp.result) {
    console.log(`(localStorage) ModificadorNomina con pk: ${pk}, obtenido con éxito`);
    temp.estado = DEFAULT_STATE.success;
    temp.mensaje = `ModificadorNomina obtenida con éxito`;
    temp.data = await getStorageModificadorNominaArrayItem(pk);
    return temp;
  }
  const result = await get(`personas/modificador-nomina/${pk}/${idCliente}`);
  const data = result.data;
  console.log(`ModificadorNomina data: `, data);
  if (!data.id || data.detail) {
    console.log(`Error al obtener ModificadorNomina con pk: ${pk}, ${data.detail}`);
    temp.estado = DEFAULT_STATE.error;
    temp.mensaje =  `Error al obtener la ModificadorNomina: ${data.detail ?? "Hubo un problema al conectarse con el servidor"}`;
    temp.data = await getStorageModificadorNominaArrayItem(pk);
    return temp;
  }
  console.log(`ModificadorNomina con pk: ${pk}, obtenido con éxito`);
  temp.estado = DEFAULT_STATE.success;
  temp.mensaje = `ModificadorNomina obtenida con éxito`;
  temp.data = await setModificadorNomina(data, serverDate);
  await setStorageModificadorNomina(temp.data);
  temp.data_array = await setStorageModificadorNominaArrayItem(pk, temp.data);
  temp.expiry = await updateLocalStorageExpiry(serverDate, temp.expiry);
  return temp;
}

export const getAllModificadorNomina = async (serverDate: IServerDate, idCliente : number) => {
  const temp = getEmptyStatusModificadorNomina();
  const doHttp = await getFromLocalStorage(serverDate, temp.expiry_array);
  temp.expiry_array = doHttp.storage;
  if (!doHttp.result) {
    console.log(`(localStorage) Todos las ModificadorNominas obtenidas con éxito`);
    temp.estado = DEFAULT_STATE.success;
    temp.mensaje = `Todos las ModificadorNomina obtenidas con éxito`;
    temp.data_array = await getStorageModificadorNominaArray();
  }
  const result = await get(`personas/modificador-nomina/${idCliente}`);
  const data = result.data;
  if (data.detail) {
    console.log(`Error al obtener la lista de ModificadorNomina`, data);
    temp.estado = DEFAULT_STATE.error;
    temp.mensaje = `Error al obtener la lista de ModificadorNomina: ${data.detail ?? "Hubo un problema al conectarse con el servidor"}`;
    temp.data_array = await getStorageModificadorNominaArray();
    return temp;
  }
  console.log(`Todos las ModificadorNominas obtenidos con éxito`);
  temp.estado = DEFAULT_STATE.success;
  temp.mensaje = `Todos las ModificadorNomina obtenidas con éxito`;
  temp.data_array = await Promise.all(data.map(async (e : any) => await setModificadorNomina(e, serverDate)));
  await setStorageModificadorNominaArray(temp.data_array);
  temp.expiry_array = await updateLocalStorageExpiry(serverDate, temp.expiry_array);
  return temp;
}

export const updateModificadorNomina = async (serverDate: IServerDate, pk : number, idCliente : number, object : IModificadorNomina) => {
  const temp = getEmptyStatusModificadorNomina();
  const result = await put(`personas/modificador-nomina/${pk}/${idCliente}`, getSerializableModificadorNomina(object));
  const data = result.data;
  if (!data.id || data.detail) {
    console.log(`Error al actualizar ModificadorNomina con pk: ${pk}, ${data.detail}`);
    temp.estado = DEFAULT_STATE.error;
    temp.mensaje = `Error al actualizar la ModificadorNomina: ${data.detail ?? "Hubo un problema al conectarse con el servidor"}`;
    temp.data = await setModificadorNomina(object, serverDate);
    return temp;
  }
  console.log(`ModificadorNomina con pk: ${pk}, actualizado con éxito`);
  temp.estado = DEFAULT_STATE.success;
  temp.mensaje = `ModificadorNomina actualizada con éxito`;
  temp.data = await setModificadorNomina(data, serverDate);
  await setStorageModificadorNomina(temp.data);
  temp.data_array = await setStorageModificadorNominaArrayItem(temp.data.id, temp.data);
  return temp;
}

export const createModificadorNomina = async (serverDate: IServerDate, idCliente : number, object : IModificadorNomina) => {
  const temp = getEmptyStatusModificadorNomina();
  const result = await post(`personas/modificador-nomina/${idCliente}`, getSerializableModificadorNomina(object));
  const data = result.data;
  if (!data.id || data.detail) {
    console.log(`Error al crear ModificadorNomina con los datos ${object}, ${data.detail}`);
    temp.estado = DEFAULT_STATE.error;
    temp.mensaje = `Error al crear la ModificadorNomina: ${data.detail ?? "Hubo un problema al conectarse con el servidor"}`;
    temp.data = await setModificadorNomina(object, serverDate);
    return temp;
  }
  console.log(`ModificadorNomina creada con éxito`);
  temp.estado = DEFAULT_STATE.success;
  temp.mensaje = `ModificadorNomina creada con éxito`;
  temp.data = await setModificadorNomina(data, serverDate);
  await setStorageModificadorNomina(temp.data);
  temp.data_array = await setStorageModificadorNominaArrayItem(temp.data.id, temp.data);
  return temp;
}

export const deleteModificadorNomina = async (pk : number, idCliente : number) => {
  const temp = getEmptyStatusModificadorNomina();
  const result = await del(`personas/modificador-nomina/${pk}/${idCliente}`);
  const data = result.data;
  if (data.detail) {
    console.log(`Error al eliminar ModificadorNomina con pk: ${pk}, ${data.detail}`);
    temp.estado = DEFAULT_STATE.error;
    temp.mensaje = `Error al eliminar la ModificadorNomina: ${data.detail}`;
    temp.data = await getStorageModificadorNominaArrayItem(pk);
    temp.data_array = await getStorageModificadorNominaArray();
    return temp;
  }
  console.log(`ModificadorNomina eliminada con éxito`);
  temp.estado = DEFAULT_STATE.success;
  temp.mensaje = `ModificadorNomina eliminada con éxito`;
  await removeStorageModificadorNomina();
  temp.data_array = await removeArrayItemStorageData("IModificadorNomina", pk, []);
  return temp;
}
