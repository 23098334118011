/** 
 * Componente que se encarga del elemento MvMotivoNotificacion
 * @IMvMotivoNotificacion es la interfaz que contiene los parámetos el elemento MvMotivoNotificacion
 * @getEmptyMvMotivoNotificacion  devuelve un objeto con los parámetros por defecto; @param {id}, opcional cuando no encuentra el objeto en el LocalSotrage
 * @IStatusMvMotivoNotificacion es la interfaz para los estatus a llamadas a la API; @param {status}, opcional cuando queremos otro estado de inizialización de tipo [DEFAULT_STATE]
 * @getEmptyStatusMvMotivoNotificacion devuelve un objeto con los parámetros por defecto,
 * @setMvMotivoNotificacion devuelve un objeto de tipo [IMvMotivoNotificacion] recibiendo @param {object} y @param {serverDate}, para verificar que los datos estén en el formato correcto y ajustarlos a la zona horaria actual
 * @getSerializableMvMotivoNotificacion recibe un @param {IMvMotivoNotificacion}, para devolver un objeto serizalizable para las peticiones de la API
 * */

import { getDateString, isNotEmpty, SERVER_TIME_ZONE } from "../../api/helpers";
import { DEFAULT_STATE } from "../../enums/default_state";
import { ILocalStorageExpiry, getEmptyLocalStorageExpiry, IServerDate, convertDateToZonaHoraria } from "../time";

export interface IMvMotivoNotificacion {
  id : number,
  dato : string,
  fecha_elaboracion : string, // Fecha
  estatus_sistema : boolean,
  ultima_actualizacion : string, // Fecha
}

export const getEmptyMvMotivoNotificacion = (id? : number) => {
  const temp : IMvMotivoNotificacion = 
  {
    id : id ? id : 0,
    dato : "",
    fecha_elaboracion : "",
    estatus_sistema : false,
    ultima_actualizacion : "",
  };
  return temp;
}

export interface IStatusMvMotivoNotificacion {
  estado : DEFAULT_STATE,
  mensaje : string,
  data : IMvMotivoNotificacion,
  data_array: IMvMotivoNotificacion[],
  expiry : ILocalStorageExpiry,
  expiry_array : ILocalStorageExpiry,
}

export const getEmptyStatusMvMotivoNotificacion = (status: DEFAULT_STATE = DEFAULT_STATE.loading) => {
  const temp : IStatusMvMotivoNotificacion = 
  {
    estado : status,
    mensaje : "",
    data : getEmptyMvMotivoNotificacion(),
    data_array : [],
    expiry : getEmptyLocalStorageExpiry("IMvMotivoNotificacion", 0),
    expiry_array : getEmptyLocalStorageExpiry("IMvMotivoNotificacionArray", 0),
  };
  return temp;
}

export const setMvMotivoNotificacion = async (
  object : any,
  serverDate : IServerDate,
) => {
  const temp = getEmptyMvMotivoNotificacion();
  if (isNotEmpty(object.id)) temp.id = object.id;
  if (isNotEmpty(object.dato)) temp.dato = object.dato;
  if (isNotEmpty(object.fecha_elaboracion)) temp.fecha_elaboracion = convertDateToZonaHoraria(serverDate, object.fecha_elaboracion);
  if (isNotEmpty(object.estatus_sistema)) temp.estatus_sistema = object.estatus_sistema;
  if (isNotEmpty(object.ultima_actualizacion)) temp.ultima_actualizacion = convertDateToZonaHoraria(serverDate, object.ultima_actualizacion);
  return temp;
}

export const getSerializableMvMotivoNotificacion = (IMvMotivoNotificacion : IMvMotivoNotificacion ) => {
  return {
    id : IMvMotivoNotificacion.id,
    dato : IMvMotivoNotificacion.dato,
    fecha_elaboracion : IMvMotivoNotificacion.fecha_elaboracion,
    estatus_sistema : IMvMotivoNotificacion.estatus_sistema,
    ultima_actualizacion : getDateString(SERVER_TIME_ZONE),
  };
}
