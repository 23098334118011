/**
 * Reducers creados para obtener asíncronamente 
 * los datos de los modelos y dependiendo del resultado
 * de la peticón actualice el state en redux o mande un mensaje de error
 */

import { PayloadAction } from "@reduxjs/toolkit";
import { getIncidenciaAsyncThunk, getAllIncidenciaAsyncThunk, updateIncidenciaAsyncThunk, createIncidenciaAsyncThunk, deleteIncidenciaAsyncThunk } from "../../asyncThunk/nomina";
import { DEFAULT_STATE } from "../../enums/default_state";
import { IStatusIncidencia } from "../../interfaces/nomina";
import { nominaInitialState } from "../../slices/nominaSlice";

// Reducers: Incidencia
export const createIncidenciaReducers = () => {
  const get = () => {
    var { pending, fulfilled, rejected } = getIncidenciaAsyncThunk;
    return {
      [pending.type]: (state : typeof nominaInitialState) => {
        state.nominaState.incidencia.estado = DEFAULT_STATE.loading;
      },
      [fulfilled.type]: (state : typeof nominaInitialState, action : PayloadAction<IStatusIncidencia>) => {
        const result = action.payload;
        result.expiry_array = state.nominaState.incidencia.expiry_array;
        if (result.estado === DEFAULT_STATE.error) result.data_array = state.nominaState.incidencia.data_array;
        state.nominaState.incidencia = result;
      },
      [rejected.type]: (state : typeof nominaInitialState, action : PayloadAction<IStatusIncidencia>) => {
        state.nominaState.incidencia = {
          ...state.nominaState.incidencia,
          estado: action.payload.estado,
          mensaje: "Ocurrió un problema al realizar la solicitud",
        };
      },
    };
  };
  
  const getAll = () => {
    var { pending, fulfilled, rejected } = getAllIncidenciaAsyncThunk;
    return {
      [pending.type]: (state : typeof nominaInitialState) => {
        state.nominaState.incidencia.estado = DEFAULT_STATE.loading;
      },
      [fulfilled.type]: (state : typeof nominaInitialState, action : PayloadAction<IStatusIncidencia>) => {
        const result = action.payload;
        result.data = state.nominaState.incidencia.data;
        result.expiry = state.nominaState.incidencia.expiry;
        if (result.estado === DEFAULT_STATE.error) result.data_array = state.nominaState.incidencia.data_array;
        state.nominaState.incidencia = result;
      },
      [rejected.type]: (state : typeof nominaInitialState, action : PayloadAction<IStatusIncidencia>) => {
        state.nominaState.incidencia = {
          ...state.nominaState.incidencia,
          estado: action.payload.estado,
          mensaje: "Ocurrió un problema al realizar la solicitud",
        };
      },
    };
  };
  
  const update = () => {
    var { pending, fulfilled, rejected } = updateIncidenciaAsyncThunk;
    return {
      [pending.type]: (state : typeof nominaInitialState) => {
        state.nominaState.incidencia.estado = DEFAULT_STATE.loading;
      },
      [fulfilled.type]: (state : typeof nominaInitialState, action : PayloadAction<IStatusIncidencia>) => {
        const result: IStatusIncidencia = action.payload;
        result.expiry = state.nominaState.incidencia.expiry;
        result.expiry_array = state.nominaState.incidencia.expiry_array;
        if (result.estado === DEFAULT_STATE.error) result.data_array = state.nominaState.incidencia.data_array;
        state.nominaState.incidencia = result;
      },
      [rejected.type]: (state : typeof nominaInitialState, action : PayloadAction<IStatusIncidencia>) => {
        state.nominaState.incidencia = {
          ...state.nominaState.incidencia,
          estado: action.payload.estado,
          mensaje: "Ocurrió un problema al realizar la solicitud",
        };
      },
    };
  };

  const create = () => {
    var { pending, fulfilled, rejected } = createIncidenciaAsyncThunk;
    return {
      [pending.type]: (state : typeof nominaInitialState) => {
        state.nominaState.incidencia.estado = DEFAULT_STATE.loading;
      },
      [fulfilled.type]: (state : typeof nominaInitialState, action : PayloadAction<IStatusIncidencia>) => {
        const result = action.payload;
        result.expiry = state.nominaState.incidencia.expiry;
        result.expiry_array = state.nominaState.incidencia.expiry_array;
        if (result.estado === DEFAULT_STATE.error) result.data_array = state.nominaState.incidencia.data_array;
        state.nominaState.incidencia = result;
      },
      [rejected.type]: (state : typeof nominaInitialState, action : PayloadAction<IStatusIncidencia>) => {
        state.nominaState.incidencia = {
          ...state.nominaState.incidencia,
          estado: action.payload.estado,
          mensaje: "Ocurrió un problema al realizar la solicitud",
        };
      },
    };
  };
  
  const del = () => {
    var { pending, fulfilled, rejected } = deleteIncidenciaAsyncThunk;
    return {
      [pending.type]: (state : typeof nominaInitialState) => {
        state.nominaState.incidencia.estado = DEFAULT_STATE.loading;
      },
      [fulfilled.type]: (state : typeof nominaInitialState, action : PayloadAction<IStatusIncidencia>) => {
        const result: IStatusIncidencia = action.payload;
        result.expiry = state.nominaState.incidencia.expiry;
        result.expiry_array = state.nominaState.incidencia.expiry_array;
        if (result.estado === DEFAULT_STATE.error) result.data_array = state.nominaState.incidencia.data_array;
        state.nominaState.incidencia = result;
      },
      [rejected.type]: (state : typeof nominaInitialState, action : PayloadAction<IStatusIncidencia>) => {
        state.nominaState.incidencia = {
          ...state.nominaState.incidencia,
          estado: action.payload.estado,
          mensaje: "Ocurrió un problema al realizar la solicitud",
        };
      },
    };
  };

  return {
    ...get(),
    ...getAll(),
    ...update(),
    ...create(),
    ...del(),
  };
};
