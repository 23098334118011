 
/**
 * Métodos del, get, post, put para llamadas a la API
 * @get     en este método primero consulta con @getFromLocalStorage si es necesario hacer la petición http sino se obtiene del LocalStorage;
 *          si hay un error en la petición http se busca el objeto en el LocalStorage sino lo encuentra devuelve un objeto vacío; si la
 *          petición se realiza correctamente actualiza el valor individual y en el array del objeto en el LocalStorage y devuelve el objeto
 *          obtenido del servidor.
 * @getAll  en este método primero consulta con @getFromLocalStorage si es necesario hacer la petición http sino se obtiene del LocalStorage;
 *          si hay un error en la petición http se busca el objeto array en el LocalStorage sino lo encuentra devuelve un array vacío; si la
 *          petición se realiza correctamente actualiza array en el LocalStorage y devuelve el array obtenido del servidor
 * @update  en este método después de realizar la petición http si falla devuelve el objeto que se iba a actualizar; si la petición se
 *          realiza correctamente actualiza el valor individual y en el array del objeto en el LocalStorage y devuelve el objeto obtenido del
 *          servidor.
 * @create  en este método después de realizar la petición http si falla devuelve el objeto que se iba a crear; si la petición se realiza 
 *          correctamente actualiza el valor individual y en el array del objeto en el LocalStorage y devuelve el objeto obtenido del
 *          servidor.
 * @delete  en este método después de realizar la petición http si falla devuelve el objeto que se iba a eliminar; si la petición se realiza 
 *          correctamente elimina el valor individual y en el objeto en el arrya del LocalStorage y devuelve un objeto vacio (Default)
 */

import { del, get, post, put } from "../../api/api";
import { getFromLocalStorage, updateLocalStorageExpiry } from "../../api/helpers";
import { removeArrayItemStorageData } from "../../api/storage";
import { DEFAULT_STATE } from "../../enums/default_state";
import { getEmptyStatusModificacionSuscripcionQentry, setModificacionSuscripcionQentry, IModificacionSuscripcionQentry, getSerializableModificacionSuscripcionQentry } from "../../interfaces/personas";
import { IServerDate } from "../../interfaces/time";
import { getStorageModificacionSuscripcionQentryArrayItem, setStorageModificacionSuscripcionQentry, setStorageModificacionSuscripcionQentryArrayItem, getStorageModificacionSuscripcionQentryArray, setStorageModificacionSuscripcionQentryArray, removeStorageModificacionSuscripcionQentry } from "../../localStorage/personas";

export const getModificacionSuscripcionQentry = async (serverDate: IServerDate, pk : number) => {
  const temp = getEmptyStatusModificacionSuscripcionQentry();
  const doHttp = await getFromLocalStorage(serverDate, temp.expiry);
  temp.expiry = doHttp.storage;
  if (!doHttp.result) {
    console.log(`(localStorage) ModificacionSuscripcionQentry con pk: ${pk}, obtenido con éxito`);
    temp.estado = DEFAULT_STATE.success;
    temp.mensaje = `ModificacionSuscripcionQentry obtenida con éxito`;
    temp.data = await getStorageModificacionSuscripcionQentryArrayItem(pk);
    return temp;
  }
  const result = await get(`personas/modificacion-suscripcion-qentry/${pk}`);
  const data = result.data;
  console.log(`ModificacionSuscripcionQentry data: `, data);
  if (!data.id || data.detail) {
    console.log(`Error al obtener ModificacionSuscripcionQentry con pk: ${pk}, ${data.detail}`);
    temp.estado = DEFAULT_STATE.error;
    temp.mensaje =  `Error al obtener la ModificacionSuscripcionQentryLista: ${data.detail ?? "Hubo un problema al conectarse con el servidor"}`;
    temp.data = await getStorageModificacionSuscripcionQentryArrayItem(pk);
    return temp;
  }
  console.log(`ModificacionSuscripcionQentry con pk: ${pk}, obtenido con éxito`);
  temp.estado = DEFAULT_STATE.success;
  temp.mensaje = `ModificacionSuscripcionQentry obtenida con éxito`;
  temp.data = await setModificacionSuscripcionQentry(data, serverDate);
  await setStorageModificacionSuscripcionQentry(temp.data);
  temp.data_array = await setStorageModificacionSuscripcionQentryArrayItem(pk, temp.data);
  temp.expiry = await updateLocalStorageExpiry(serverDate, temp.expiry);
  return temp;
}

export const getAllModificacionSuscripcionQentry = async (serverDate: IServerDate) => {
  const temp = getEmptyStatusModificacionSuscripcionQentry();
  const doHttp = await getFromLocalStorage(serverDate, temp.expiry_array);
  temp.expiry_array = doHttp.storage;
  if (!doHttp.result) {
    console.log(`(localStorage) Todos las ModificacionSuscripcionQentrys obtenidas con éxito`);
    temp.estado = DEFAULT_STATE.success;
    temp.mensaje = `Todos las ModificacionSuscripcionQentryLista obtenidas con éxito`;
    temp.data_array = await getStorageModificacionSuscripcionQentryArray();
  }
  const result = await get(`personas/modificacion-suscripcion-qentry`);
  const data = result.data;
  if (data.detail) {
    console.log(`Error al obtener la lista de ModificacionSuscripcionQentry`, data);
    temp.estado = DEFAULT_STATE.error;
    temp.mensaje = `Error al obtener la lista de ModificacionSuscripcionQentryLista: ${data.detail ?? "Hubo un problema al conectarse con el servidor"}`;
    temp.data_array = await getStorageModificacionSuscripcionQentryArray();
    return temp;
  }
  console.log(`Todos las ModificacionSuscripcionQentrys obtenidos con éxito`);
  temp.estado = DEFAULT_STATE.success;
  temp.mensaje = `Todos las ModificacionSuscripcionQentryLista obtenidas con éxito`;
  temp.data_array = await Promise.all(data.map(async (e : any) => await setModificacionSuscripcionQentry(e, serverDate)));
  await setStorageModificacionSuscripcionQentryArray(temp.data_array);
  temp.expiry_array = await updateLocalStorageExpiry(serverDate, temp.expiry_array);
  return temp;
}

export const updateModificacionSuscripcionQentry = async (serverDate: IServerDate, pk : number, object : IModificacionSuscripcionQentry) => {
  const temp = getEmptyStatusModificacionSuscripcionQentry();
  const result = await put(`personas/modificacion-suscripcion-qentry/${pk}`, getSerializableModificacionSuscripcionQentry(object));
  const data = result.data;
  if (!data.id || data.detail) {
    console.log(`Error al actualizar ModificacionSuscripcionQentry con pk: ${pk}, ${data.detail}`);
    temp.estado = DEFAULT_STATE.error;
    temp.mensaje = `Error al actualizar la ModificacionSuscripcionQentryLista: ${data.detail ?? "Hubo un problema al conectarse con el servidor"}`;
    temp.data = await setModificacionSuscripcionQentry(object, serverDate);
    return temp;
  }
  console.log(`ModificacionSuscripcionQentry con pk: ${pk}, actualizado con éxito`);
  temp.estado = DEFAULT_STATE.success;
  temp.mensaje = `ModificacionSuscripcionQentry actualizada con éxito`;
  temp.data = await setModificacionSuscripcionQentry(data, serverDate);
  await setStorageModificacionSuscripcionQentry(temp.data);
  temp.data_array = await setStorageModificacionSuscripcionQentryArrayItem(temp.data.id, temp.data);
  return temp;
}

export const createModificacionSuscripcionQentry = async (serverDate: IServerDate, object : IModificacionSuscripcionQentry) => {
  const temp = getEmptyStatusModificacionSuscripcionQentry();
  const result = await post(`personas/modificacion-suscripcion-qentry`, getSerializableModificacionSuscripcionQentry(object));
  const data = result.data;
  if (!data.id || data.detail) {
    console.log(`Error al crear ModificacionSuscripcionQentry con los datos ${object}, ${data.detail}`);
    temp.estado = DEFAULT_STATE.error;
    temp.mensaje = `Error al crear la ModificacionSuscripcionQentryLista: ${data.detail ?? "Hubo un problema al conectarse con el servidor"}`;
    temp.data = await setModificacionSuscripcionQentry(object, serverDate);
    return temp;
  }
  console.log(`ModificacionSuscripcionQentry creada con éxito`);
  temp.estado = DEFAULT_STATE.success;
  temp.mensaje = `ModificacionSuscripcionQentry creada con éxito`;
  temp.data = await setModificacionSuscripcionQentry(data, serverDate);
  await setStorageModificacionSuscripcionQentry(temp.data);
  temp.data_array = await setStorageModificacionSuscripcionQentryArrayItem(temp.data.id, temp.data);
  return temp;
}

export const deleteModificacionSuscripcionQentry = async (pk : number) => {
  const temp = getEmptyStatusModificacionSuscripcionQentry();
  const result = await del(`personas/modificacion-suscripcion-qentry/${pk}`);
  const data = result.data;
  if (data.detail) {
    console.log(`Error al eliminar ModificacionSuscripcionQentry con pk: ${pk}, ${data.detail}`);
    temp.estado = DEFAULT_STATE.error;
    temp.mensaje = `Error al eliminar la ModificacionSuscripcionQentryLista: ${data.detail}`;
    temp.data = await getStorageModificacionSuscripcionQentryArrayItem(pk);
    temp.data_array = await getStorageModificacionSuscripcionQentryArray();
    return temp;
  }
  console.log(`ModificacionSuscripcionQentry eliminada con éxito`);
  temp.estado = DEFAULT_STATE.success;
  temp.mensaje = `ModificacionSuscripcionQentry eliminada con éxito`;
  await removeStorageModificacionSuscripcionQentry();
  temp.data_array = await removeArrayItemStorageData("IModificacionSuscripcionQentry", pk, []);
  return temp;
}
