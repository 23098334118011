/**
 * Métodos para el manejo de IonicStorage 
 * @setStorage              guarda un unico objeto al storage
 * @getStorage              obtiene el objeto guardado con @setStorage
 * @setStorageArray         guarda el array de objetos en el storage
 * @getStorageArray         obtiene el array de objetos guardado con @setStorageArray
 * @removeStorageArray      elimina el array de objetos guardado con @setStorageArray
 * @setStorageItemArray     agrega un elemento al array de objeto guardado con @setStorageArray
 * @getStorageItemArray     obtiene un elemento del array guardado con @setStorageArray buscandolo por su id 
 * @removeStorageItemArray  elimina un elemento del array guardado con @setStorageArray buscandolo por su id 
 */

import { setStorageData, getStorageData, removeStorageData, setArrayItemStorageData, getArrayItemStorageData, removeArrayItemStorageData } from "../../api/storage";
import { IMvEstatusSolicitudCambioTelefonoAutorizado, getEmptyMvEstatusSolicitudCambioTelefonoAutorizado } from "../../interfaces/personas";

export const setStorageMvEstatusSolicitudCambioTelefonoAutorizado = async (IMvEstatusSolicitudCambioTelefonoAutorizado : IMvEstatusSolicitudCambioTelefonoAutorizado) => await setStorageData('IMvEstatusSolicitudCambioTelefonoAutorizado', IMvEstatusSolicitudCambioTelefonoAutorizado);
export const getStorageMvEstatusSolicitudCambioTelefonoAutorizado = async () : Promise<IMvEstatusSolicitudCambioTelefonoAutorizado> => await getStorageData('IMvEstatusSolicitudCambioTelefonoAutorizado', getEmptyMvEstatusSolicitudCambioTelefonoAutorizado());
export const removeStorageMvEstatusSolicitudCambioTelefonoAutorizado = async () => await removeStorageData('IMvEstatusSolicitudCambioTelefonoAutorizado');
export const setStorageMvEstatusSolicitudCambioTelefonoAutorizadoArray = async (IMvEstatusSolicitudCambioTelefonoAutorizado : IMvEstatusSolicitudCambioTelefonoAutorizado[]) => await setStorageData('IMvEstatusSolicitudCambioTelefonoAutorizadoArray', IMvEstatusSolicitudCambioTelefonoAutorizado);
export const getStorageMvEstatusSolicitudCambioTelefonoAutorizadoArray = async () : Promise<IMvEstatusSolicitudCambioTelefonoAutorizado[]> => await getStorageData('IMvEstatusSolicitudCambioTelefonoAutorizadoArray', [] as IMvEstatusSolicitudCambioTelefonoAutorizado[]);
export const removeStorageMvEstatusSolicitudCambioTelefonoAutorizadoArray = async () => await removeStorageData('IMvEstatusSolicitudCambioTelefonoAutorizadoArray');
export const setStorageMvEstatusSolicitudCambioTelefonoAutorizadoArrayItem = async (id: number, IMvEstatusSolicitudCambioTelefonoAutorizado : IMvEstatusSolicitudCambioTelefonoAutorizado) : Promise<IMvEstatusSolicitudCambioTelefonoAutorizado[]> => await setArrayItemStorageData('IMvEstatusSolicitudCambioTelefonoAutorizadoArray', id, IMvEstatusSolicitudCambioTelefonoAutorizado, [] as IMvEstatusSolicitudCambioTelefonoAutorizado[]);
export const getStorageMvEstatusSolicitudCambioTelefonoAutorizadoArrayItem = async (id: number) : Promise<IMvEstatusSolicitudCambioTelefonoAutorizado> => await getArrayItemStorageData('IMvEstatusSolicitudCambioTelefonoAutorizadoArray', id, getEmptyMvEstatusSolicitudCambioTelefonoAutorizado);
export const removeStorageMvEstatusSolicitudCambioTelefonoAutorizadoArrayItem = async (id: number) : Promise<IMvEstatusSolicitudCambioTelefonoAutorizado[]> => await removeArrayItemStorageData('IMvEstatusSolicitudCambioTelefonoAutorizadoArray', id, [] as IMvEstatusSolicitudCambioTelefonoAutorizado[]);
