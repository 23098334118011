/**
 * Métodos para el manejo de IonicStorage 
 * @setStorage              guarda un unico objeto al storage
 * @getStorage              obtiene el objeto guardado con @setStorage
 * @setStorageArray         guarda el array de objetos en el storage
 * @getStorageArray         obtiene el array de objetos guardado con @setStorageArray
 * @removeStorageArray      elimina el array de objetos guardado con @setStorageArray
 * @setStorageItemArray     agrega un elemento al array de objeto guardado con @setStorageArray
 * @getStorageItemArray     obtiene un elemento del array guardado con @setStorageArray buscandolo por su id 
 * @removeStorageItemArray  elimina un elemento del array guardado con @setStorageArray buscandolo por su id 
 */

import { setStorageData, getStorageData, removeStorageData, setArrayItemStorageData, getArrayItemStorageData, removeArrayItemStorageData } from "../../api/storage";
import { IMvMotivoPagoSuscripcion, getEmptyMvMotivoPagoSuscripcion } from "../../interfaces/personas";

export const setStorageMvMotivoPagoSuscripcion = async (IMvMotivoPagoSuscripcion : IMvMotivoPagoSuscripcion) => await setStorageData('IMvMotivoPagoSuscripcion', IMvMotivoPagoSuscripcion);
export const getStorageMvMotivoPagoSuscripcion = async () : Promise<IMvMotivoPagoSuscripcion> => await getStorageData('IMvMotivoPagoSuscripcion', getEmptyMvMotivoPagoSuscripcion());
export const removeStorageMvMotivoPagoSuscripcion = async () => await removeStorageData('IMvMotivoPagoSuscripcion');
export const setStorageMvMotivoPagoSuscripcionArray = async (IMvMotivoPagoSuscripcion : IMvMotivoPagoSuscripcion[]) => await setStorageData('IMvMotivoPagoSuscripcionArray', IMvMotivoPagoSuscripcion);
export const getStorageMvMotivoPagoSuscripcionArray = async () : Promise<IMvMotivoPagoSuscripcion[]> => await getStorageData('IMvMotivoPagoSuscripcionArray', [] as IMvMotivoPagoSuscripcion[]);
export const removeStorageMvMotivoPagoSuscripcionArray = async () => await removeStorageData('IMvMotivoPagoSuscripcionArray');
export const setStorageMvMotivoPagoSuscripcionArrayItem = async (id: number, IMvMotivoPagoSuscripcion : IMvMotivoPagoSuscripcion) : Promise<IMvMotivoPagoSuscripcion[]> => await setArrayItemStorageData('IMvMotivoPagoSuscripcionArray', id, IMvMotivoPagoSuscripcion, [] as IMvMotivoPagoSuscripcion[]);
export const getStorageMvMotivoPagoSuscripcionArrayItem = async (id: number) : Promise<IMvMotivoPagoSuscripcion> => await getArrayItemStorageData('IMvMotivoPagoSuscripcionArray', id, getEmptyMvMotivoPagoSuscripcion);
export const removeStorageMvMotivoPagoSuscripcionArrayItem = async (id: number) : Promise<IMvMotivoPagoSuscripcion[]> => await removeArrayItemStorageData('IMvMotivoPagoSuscripcionArray', id, [] as IMvMotivoPagoSuscripcion[]);
