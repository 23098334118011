/**
 * Reducers creados para obtener asíncronamente 
 * los datos de los modelos y dependiendo del resultado
 * de la peticón actualice el state en redux o mande un mensaje de error
 */

import { PayloadAction } from "@reduxjs/toolkit";
import { getMvTipoServicioQentryAsyncThunk, getAllMvTipoServicioQentryAsyncThunk, updateMvTipoServicioQentryAsyncThunk, createMvTipoServicioQentryAsyncThunk, deleteMvTipoServicioQentryAsyncThunk } from "../../asyncThunk/personas";
import { DEFAULT_STATE } from "../../enums/default_state";
import { IStatusMvTipoServicioQentry } from "../../interfaces/personas";
import { personasInitialState } from "../../slices/personasSlice";

// Reducers: MvTipoServicioQentry
export const createMvTipoServicioQentryReducers = () => {
  const get = () => {
    var { pending, fulfilled, rejected } = getMvTipoServicioQentryAsyncThunk;
    return {
      [pending.type]: (state : typeof personasInitialState) => {
        state.personasState.mvTipoServicioQentry.estado = DEFAULT_STATE.loading;
      },
      [fulfilled.type]: (state : typeof personasInitialState, action : PayloadAction<IStatusMvTipoServicioQentry>) => {
        const result = action.payload;
        result.expiry_array = state.personasState.mvTipoServicioQentry.expiry_array;
        if (result.estado === DEFAULT_STATE.error) result.data_array = state.personasState.mvTipoServicioQentry.data_array;
        state.personasState.mvTipoServicioQentry = result;
      },
      [rejected.type]: (state : typeof personasInitialState, action : PayloadAction<IStatusMvTipoServicioQentry>) => {
        state.personasState.mvTipoServicioQentry = {
          ...state.personasState.mvTipoServicioQentry,
          estado: action.payload.estado,
          mensaje: "Ocurrió un problema al realizar la solicitud",
        };
      },
    };
  };
  
  const getAll = () => {
    var { pending, fulfilled, rejected } = getAllMvTipoServicioQentryAsyncThunk;
    return {
      [pending.type]: (state : typeof personasInitialState) => {
        state.personasState.mvTipoServicioQentry.estado = DEFAULT_STATE.loading;
      },
      [fulfilled.type]: (state : typeof personasInitialState, action : PayloadAction<IStatusMvTipoServicioQentry>) => {
        const result = action.payload;
        result.data = state.personasState.mvTipoServicioQentry.data;
        result.expiry = state.personasState.mvTipoServicioQentry.expiry;
        if (result.estado === DEFAULT_STATE.error) result.data_array = state.personasState.mvTipoServicioQentry.data_array;
        state.personasState.mvTipoServicioQentry = result;
      },
      [rejected.type]: (state : typeof personasInitialState, action : PayloadAction<IStatusMvTipoServicioQentry>) => {
        state.personasState.mvTipoServicioQentry = {
          ...state.personasState.mvTipoServicioQentry,
          estado: action.payload.estado,
          mensaje: "Ocurrió un problema al realizar la solicitud",
        };
      },
    };
  };
  
  const update = () => {
    var { pending, fulfilled, rejected } = updateMvTipoServicioQentryAsyncThunk;
    return {
      [pending.type]: (state : typeof personasInitialState) => {
        state.personasState.mvTipoServicioQentry.estado = DEFAULT_STATE.loading;
      },
      [fulfilled.type]: (state : typeof personasInitialState, action : PayloadAction<IStatusMvTipoServicioQentry>) => {
        const result: IStatusMvTipoServicioQentry = action.payload;
        result.expiry = state.personasState.mvTipoServicioQentry.expiry;
        result.expiry_array = state.personasState.mvTipoServicioQentry.expiry_array;
        if (result.estado === DEFAULT_STATE.error) result.data_array = state.personasState.mvTipoServicioQentry.data_array;
        state.personasState.mvTipoServicioQentry = result;
      },
      [rejected.type]: (state : typeof personasInitialState, action : PayloadAction<IStatusMvTipoServicioQentry>) => {
        state.personasState.mvTipoServicioQentry = {
          ...state.personasState.mvTipoServicioQentry,
          estado: action.payload.estado,
          mensaje: "Ocurrió un problema al realizar la solicitud",
        };
      },
    };
  };

  const create = () => {
    var { pending, fulfilled, rejected } = createMvTipoServicioQentryAsyncThunk;
    return {
      [pending.type]: (state : typeof personasInitialState) => {
        state.personasState.mvTipoServicioQentry.estado = DEFAULT_STATE.loading;
      },
      [fulfilled.type]: (state : typeof personasInitialState, action : PayloadAction<IStatusMvTipoServicioQentry>) => {
        const result = action.payload;
        result.expiry = state.personasState.mvTipoServicioQentry.expiry;
        result.expiry_array = state.personasState.mvTipoServicioQentry.expiry_array;
        if (result.estado === DEFAULT_STATE.error) result.data_array = state.personasState.mvTipoServicioQentry.data_array;
        state.personasState.mvTipoServicioQentry = result;
      },
      [rejected.type]: (state : typeof personasInitialState, action : PayloadAction<IStatusMvTipoServicioQentry>) => {
        state.personasState.mvTipoServicioQentry = {
          ...state.personasState.mvTipoServicioQentry,
          estado: action.payload.estado,
          mensaje: "Ocurrió un problema al realizar la solicitud",
        };
      },
    };
  };
  
  const del = () => {
    var { pending, fulfilled, rejected } = deleteMvTipoServicioQentryAsyncThunk;
    return {
      [pending.type]: (state : typeof personasInitialState) => {
        state.personasState.mvTipoServicioQentry.estado = DEFAULT_STATE.loading;
      },
      [fulfilled.type]: (state : typeof personasInitialState, action : PayloadAction<IStatusMvTipoServicioQentry>) => {
        const result: IStatusMvTipoServicioQentry = action.payload;
        result.expiry = state.personasState.mvTipoServicioQentry.expiry;
        result.expiry_array = state.personasState.mvTipoServicioQentry.expiry_array;
        if (result.estado === DEFAULT_STATE.error) result.data_array = state.personasState.mvTipoServicioQentry.data_array;
        state.personasState.mvTipoServicioQentry = result;
      },
      [rejected.type]: (state : typeof personasInitialState, action : PayloadAction<IStatusMvTipoServicioQentry>) => {
        state.personasState.mvTipoServicioQentry = {
          ...state.personasState.mvTipoServicioQentry,
          estado: action.payload.estado,
          mensaje: "Ocurrió un problema al realizar la solicitud",
        };
      },
    };
  };

  return {
    ...get(),
    ...getAll(),
    ...update(),
    ...create(),
    ...del(),
  };
};
