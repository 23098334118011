/**
 * Reducers creados para obtener asíncronamente 
 * los datos de los modelos y dependiendo del resultado
 * de la peticón actualice el state en redux o mande un mensaje de error
 */

import { PayloadAction } from "@reduxjs/toolkit";
import { getPagoSuscripcionAsyncThunk, getAllPagoSuscripcionAsyncThunk, updatePagoSuscripcionAsyncThunk, createPagoSuscripcionAsyncThunk, deletePagoSuscripcionAsyncThunk } from "../../asyncThunk/personas";
import { DEFAULT_STATE } from "../../enums/default_state";
import { IStatusPagoSuscripcion } from "../../interfaces/personas";
import { personasInitialState } from "../../slices/personasSlice";

// Reducers: PagoSuscripcion
export const createPagoSuscripcionReducers = () => {
  const get = () => {
    var { pending, fulfilled, rejected } = getPagoSuscripcionAsyncThunk;
    return {
      [pending.type]: (state : typeof personasInitialState) => {
        state.personasState.pagoSuscripcion.estado = DEFAULT_STATE.loading;
      },
      [fulfilled.type]: (state : typeof personasInitialState, action : PayloadAction<IStatusPagoSuscripcion>) => {
        const result = action.payload;
        result.expiry_array = state.personasState.pagoSuscripcion.expiry_array;
        if (result.estado === DEFAULT_STATE.error) result.data_array = state.personasState.pagoSuscripcion.data_array;
        state.personasState.pagoSuscripcion = result;
      },
      [rejected.type]: (state : typeof personasInitialState, action : PayloadAction<IStatusPagoSuscripcion>) => {
        state.personasState.pagoSuscripcion = {
          ...state.personasState.pagoSuscripcion,
          estado: action.payload.estado,
          mensaje: "Ocurrió un problema al realizar la solicitud",
        };
      },
    };
  };
  
  const getAll = () => {
    var { pending, fulfilled, rejected } = getAllPagoSuscripcionAsyncThunk;
    return {
      [pending.type]: (state : typeof personasInitialState) => {
        state.personasState.pagoSuscripcion.estado = DEFAULT_STATE.loading;
      },
      [fulfilled.type]: (state : typeof personasInitialState, action : PayloadAction<IStatusPagoSuscripcion>) => {
        const result = action.payload;
        result.data = state.personasState.pagoSuscripcion.data;
        result.expiry = state.personasState.pagoSuscripcion.expiry;
        if (result.estado === DEFAULT_STATE.error) result.data_array = state.personasState.pagoSuscripcion.data_array;
        state.personasState.pagoSuscripcion = result;
      },
      [rejected.type]: (state : typeof personasInitialState, action : PayloadAction<IStatusPagoSuscripcion>) => {
        state.personasState.pagoSuscripcion = {
          ...state.personasState.pagoSuscripcion,
          estado: action.payload.estado,
          mensaje: "Ocurrió un problema al realizar la solicitud",
        };
      },
    };
  };
  
  const update = () => {
    var { pending, fulfilled, rejected } = updatePagoSuscripcionAsyncThunk;
    return {
      [pending.type]: (state : typeof personasInitialState) => {
        state.personasState.pagoSuscripcion.estado = DEFAULT_STATE.loading;
      },
      [fulfilled.type]: (state : typeof personasInitialState, action : PayloadAction<IStatusPagoSuscripcion>) => {
        const result: IStatusPagoSuscripcion = action.payload;
        result.expiry = state.personasState.pagoSuscripcion.expiry;
        result.expiry_array = state.personasState.pagoSuscripcion.expiry_array;
        if (result.estado === DEFAULT_STATE.error) result.data_array = state.personasState.pagoSuscripcion.data_array;
        state.personasState.pagoSuscripcion = result;
      },
      [rejected.type]: (state : typeof personasInitialState, action : PayloadAction<IStatusPagoSuscripcion>) => {
        state.personasState.pagoSuscripcion = {
          ...state.personasState.pagoSuscripcion,
          estado: action.payload.estado,
          mensaje: "Ocurrió un problema al realizar la solicitud",
        };
      },
    };
  };

  const create = () => {
    var { pending, fulfilled, rejected } = createPagoSuscripcionAsyncThunk;
    return {
      [pending.type]: (state : typeof personasInitialState) => {
        state.personasState.pagoSuscripcion.estado = DEFAULT_STATE.loading;
      },
      [fulfilled.type]: (state : typeof personasInitialState, action : PayloadAction<IStatusPagoSuscripcion>) => {
        const result = action.payload;
        result.expiry = state.personasState.pagoSuscripcion.expiry;
        result.expiry_array = state.personasState.pagoSuscripcion.expiry_array;
        if (result.estado === DEFAULT_STATE.error) result.data_array = state.personasState.pagoSuscripcion.data_array;
        state.personasState.pagoSuscripcion = result;
      },
      [rejected.type]: (state : typeof personasInitialState, action : PayloadAction<IStatusPagoSuscripcion>) => {
        state.personasState.pagoSuscripcion = {
          ...state.personasState.pagoSuscripcion,
          estado: action.payload.estado,
          mensaje: "Ocurrió un problema al realizar la solicitud",
        };
      },
    };
  };
  
  const del = () => {
    var { pending, fulfilled, rejected } = deletePagoSuscripcionAsyncThunk;
    return {
      [pending.type]: (state : typeof personasInitialState) => {
        state.personasState.pagoSuscripcion.estado = DEFAULT_STATE.loading;
      },
      [fulfilled.type]: (state : typeof personasInitialState, action : PayloadAction<IStatusPagoSuscripcion>) => {
        const result: IStatusPagoSuscripcion = action.payload;
        result.expiry = state.personasState.pagoSuscripcion.expiry;
        result.expiry_array = state.personasState.pagoSuscripcion.expiry_array;
        if (result.estado === DEFAULT_STATE.error) result.data_array = state.personasState.pagoSuscripcion.data_array;
        state.personasState.pagoSuscripcion = result;
      },
      [rejected.type]: (state : typeof personasInitialState, action : PayloadAction<IStatusPagoSuscripcion>) => {
        state.personasState.pagoSuscripcion = {
          ...state.personasState.pagoSuscripcion,
          estado: action.payload.estado,
          mensaje: "Ocurrió un problema al realizar la solicitud",
        };
      },
    };
  };

  return {
    ...get(),
    ...getAll(),
    ...update(),
    ...create(),
    ...del(),
  };
};
