/**
 * Hooks usados para trabajar los states de redux (a excepción de los usados en las pages)
 * @function fetchClienteQentry obtiene los datos del cliente y otros modelos necesarios
 * @function fetchEmpresa obtiene los datos de las empresas del cliente y otros modelos necesarios
 * @function fetchEmpleado obtiene los datos de los empleados del cliente y otros modelos necesarios
 * @function fetchCentrosDeTrabajo obtiene los datos de los centros de trabajo del cliente y otros modelos necesarios
 * @function fetchRondines obtiene los datos de los rondines del cliente y otros modelos necesarios
 * @function fetchHorarios obtiene los datos de los horarios de los empleados del cliente y otros modelos necesarios
 * @function fetchAsistencia obtiene los datos del registro de asistencia de lo empleados del cliente y otros modelos necesarios
 * @function fetchNotifications obtiene los datos de las notificaciones del cliente y otros modelos necesarios
 * @function fetchVacations obtiene los datos de las vacaciones de los empleados del cliente y otros modelos necesarios
 * @function fetchIncapacidades obtiene los datos de las incapacidades de los empleados del cliente y otros modelos necesarios
 * @function editDatosPersonales actualiza los datos personales de un empledo
 * @function addDatosFiscales agrega un dato fiscal de un empleado
 * @function removeDatosFiscales elimina un dato fiscal de un empleado
 * @function editDatosFiscales edita un tato fiscal de un empleado
 * @function addOrEditInformacionBancaria crea o edita la información bancaria de un empleado
 * @function editConfiguracion actualiza los datos de un empleado
 * @function editCentrosDeTrabajo actualiza los datos de un centro de trabajo del cliente
 * @function addOrEditRondin agrega o actualiza rondines del cliente
 * @function deleteRondin elimina un rondin del cliente
 * @function updateHorarios actualiza los horarios de los empleados
 * @function treatAsUTC convierte la fecha a UTC
 * @function daysBetween calcula el número de días entre dos fechas
 * @function createVacations crea una solicitud de vacaciones
 * @function updateVacations actualiza el estado de una solicitud de vacaciones
 * @function createIncapacidades crea una solicitud de incapacidad
 * @function updateIncapacidades actualiza el estado de una solicitud de incapacidad
 */

import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import { postFile } from "../redux/api/api";
import { getReportsDateString } from "../redux/api/helpers";
import {
  createCambioEstatusIncapacidadAsyncThunk,
  createCambioEstatusVacacionesAsyncThunk,
  createHorarioAsyncThunk,
  createIncapacidadAsyncThunk,
  createMvMotivoIncapacidadAsyncThunk,
  createMvMotivoVacacionesAsyncThunk,
  createVacacionesAsyncThunk,
  getAllAsistenciaAsyncThunk,
  getAllCambioEstatusIncapacidadAsyncThunk,
  getAllCambioEstatusVacacionesAsyncThunk,
  getAllHorarioAsyncThunk,
  getAllIncapacidadAsyncThunk,
  getAllMvEstatusAsistenciaAsyncThunk,
  getAllMvEstatusIncapacidadesAsyncThunk,
  getAllMvEstatusVacacionesAsyncThunk,
  getAllMvMotivoIncapacidadAsyncThunk,
  getAllMvMotivoNotificacionAsyncThunk,
  getAllMvMotivoVacacionesAsyncThunk,
  getAllMvTipoAsistenciaAsyncThunk,
  getAllMvTipoJornadaAsyncThunk,
  getAllMvTipoMovimientoAsistenciaAsyncThunk,
  getAllMvTipoNotificacionAsyncThunk,
  getAllNotificacionesAsyncThunk,
  getAllTurnoAsyncThunk,
  getAllVacacionesAsyncThunk,
  updateHorarioAsyncThunk,
  updateIncapacidadAsyncThunk,
  updateVacacionesAsyncThunk,
} from "../redux/asyncThunk/nomina";
import {
  createEmpresaAsyncThunk,
  getAllSolicitudCambioTelefonoAutorizadoAsyncThunk,
  updateEmpresaAsyncThunk,
  updateSolicitudCambioTelefonoAutorizadoAsyncThunk,
} from "../redux/asyncThunk/personas";
import { updateTelefonoAutorizadoAsyncThunk } from "../redux/asyncThunk/personas/SolicitudCambioTelefonoAutorizado";
import {
  getAllMvTipoUsuarioAsyncThunk,
  getAllUserAsyncThunk,
  getAllPaisAsyncThunk,
  getAllMvUsoFacturaAsyncThunk,
  getAllDatosFiscalesAsyncThunk,
  getClienteQentryAsyncThunk,
  getAllDireccionAsyncThunk,
  getAllEmpresaAsyncThunk,
  getAllEstadoAsyncThunk,
  getAllGeolocalizacionAsyncThunk,
  getAllCentroDeTrabajoAsyncThunk,
  getAllCodigoQrAsyncThunk,
  getAllDatosPersonalesAsyncThunk,
  getAllDocumentoAsyncThunk,
  getAllEmpleadoAsyncThunk,
  getAllImagenAsyncThunk,
  getAllInformacionBancariaAsyncThunk,
  getAllInformacionContratoAsyncThunk,
  getAllMvBancoAsyncThunk,
  getAllMvEstadoCivilAsyncThunk,
  getAllMvGeneroAsyncThunk,
  getAllMvTipoDocumentoAsyncThunk,
  getAllMvTipoEmpleadoAsyncThunk,
  createDatosFiscalesAsyncThunk,
  deleteDatosFiscalesAsyncThunk,
  updateDatosFiscalesAsyncThunk,
  updateEmpleadoAsyncThunk,
  updateDatosPersonalesAsyncThunk,
  createInformacionBancariaAsyncThunk,
  deleteInformacionBancariaAsyncThunk,
  updateInformacionBancariaAsyncThunk,
  updateCentroDeTrabajoAsyncThunk,
  updateDireccionAsyncThunk,
  updateCodigoQrAsyncThunk,
  createDireccionAsyncThunk,
  createGeolocalizacionAsyncThunk,
  updateGeolocalizacionAsyncThunk,
  createCentroDeTrabajoAsyncThunk,
  createMultipleCentroDeTrabajoAsyncThunk,
  createSolicitudCambioTelefonoAutorizadoAsyncThunk,
  getAllMvEstatusSolicitudCambioTelefonoAutorizadoAsyncThunk,
} from "../redux/asyncThunk/personas";
import {
  createCheckpointRondinAsyncThunk,
  createRondinAsyncThunk,
  deleteRondinAsyncThunk,
  getAllCheckpointRondinAsyncThunk,
  getAllCheckRondinAsyncThunk,
  getAllClienteQentryRondinAsyncThunk,
  getAllMvMotivoCancelacionAsyncThunk,
  getAllRondinAsyncThunk,
  getAllRondinCanceladoAsyncThunk,
  getAllRondinCompletadoAsyncThunk,
  updateRondinAsyncThunk,
} from "../redux/asyncThunk/rondines";
import { getServerDateAsyncThunk } from "../redux/asyncThunk/time/ServerDate";
import { getAllMvEstatusSolicitudCambioTelefonoAutorizado } from "../redux/http/personas";
import { IAuth } from "../redux/interfaces/auth";
import { ILocation } from "../redux/interfaces/auth/ILocation";
import {
  getEmptyCambioEstatusIncapacidad,
  getEmptyCambioEstatusVacaciones,
  getEmptyHorario,
  getEmptyIncapacidad,
  getEmptyVacaciones,
  IIncapacidad,
  IStatusAsistencia,
  IStatusCambioEstatusAsistencia,
  IStatusCambioEstatusIncapacidad,
  IStatusCambioEstatusVacaciones,
  IStatusHorario,
  IStatusHorasExtra,
  IStatusIncapacidad,
  IStatusIncidencia,
  IStatusMvEstatusAsistencia,
  IStatusMvEstatusIncapacidades,
  IStatusMvEstatusVacaciones,
  IStatusMvMotivoIncapacidad,
  IStatusMvMotivoNotificacion,
  IStatusMvMotivoVacaciones,
  IStatusMvRiesgoPuesto,
  IStatusMvTipoAsistencia,
  IStatusMvTipoContrato,
  IStatusMvTipoHorasExtra,
  IStatusMvTipoJornada,
  IStatusMvTipoMovimientoAsistencia,
  IStatusMvTipoNotificacion,
  IStatusNotificaciones,
  IStatusPuesto,
  IStatusTabuladorHorasExtra,
  IStatusTurno,
  IStatusVacaciones,
  IVacaciones,
  setHorario,
  setIncapacidad,
  setVacaciones,
} from "../redux/interfaces/nomina";
import {
  getEmptyCentroDeTrabajo,
  getEmptyDatosFiscales,
  getEmptyDireccion,
  getEmptyEmpresa,
  getEmptySolicitudCambioTelefonoAutorizado,
  ICodigoQr,
  IDatosFiscales,
  IDatosPersonales,
  IDireccion,
  IEmpleado,
  IEmpresa,
  IInformacionBancaria,
  ISolicitudCambioTelefonoAutorizado,
  IStatusCanalVenta,
  IStatusCentroDeTrabajo,
  IStatusClienteQentry,
  IStatusCodigoPostal,
  IStatusCodigoQr,
  IStatusDatosFiscales,
  IStatusDatosPersonales,
  IStatusDireccion,
  IStatusDocumento,
  IStatusEmpleado,
  IStatusEmpresa,
  IStatusEstado,
  IStatusGeolocalizacion,
  IStatusImagen,
  IStatusInformacionBancaria,
  IStatusInformacionContrato,
  IStatusInformacionSuscripcion,
  IStatusModificacionSuscripcionQentry,
  IStatusModificadorNomina,
  IStatusMvAntiguedad,
  IStatusMvBanco,
  IStatusMvEstadoCivil,
  IStatusMvEstatusSolicitudCambioTelefonoAutorizado,
  IStatusMvFormaDePagoSuscripcion,
  IStatusMvGenero,
  IStatusMvImpuestos,
  IStatusMvMotivoPagoSuscripcion,
  IStatusMvTipoCanalVenta,
  IStatusMvTipoDocumento,
  IStatusMvTipoEmpleado,
  IStatusMvTipoServicioQentry,
  IStatusMvTipoSuscripcion,
  IStatusMvTipoUsuario,
  IStatusMvUsoFactura,
  IStatusPagoSuscripcion,
  IStatusPais,
  IStatusServicioQentry,
  IStatusSolicitudCambioTelefonoAutorizado,
  IStatusSuscripcionQentry,
  IStatusTipoDocumentosPorPuesto,
  IStatusUser,
  IUser,
  setSolicitudCambioTelefonoAutorizado,
} from "../redux/interfaces/personas";
import {
  getEmptyRondin,
  IRondin,
  IStatusCheckEnRuta,
  IStatusCheckpointRondin,
  IStatusCheckRondin,
  IStatusMvMotivoCancelacion,
  IStatusRondin,
  IStatusRondinCancelado,
  IStatusRondinCompletado,
} from "../redux/interfaces/rondines";
import { ITheme } from "../redux/interfaces/theme";
import { IStatusServerDate } from "../redux/interfaces/time";
import { setPatrolsRondinModal } from "../redux/slices/pagesSlice/patrolsSlice";
import { HorariosUpdate } from "../redux/slices/pagesSlice/scheduleChangeSlice";
import { setTeamMembersEmpleadoModal } from "../redux/slices/pagesSlice/teamMembersSlice";
import { ICreateCentroDeTrabajoData } from "../redux/slices/pagesSlice/workCentersSlice";
import { AppDispatch, RootState } from "../redux/store";
import { getEmpleadosACargoAsyncThunk } from "../redux/asyncThunk/personas/Empleado";

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

function ReduxData(): [
  {
    /*
      THEME
    */
    theme: ITheme;
    /*
      AUTH
    */
    auth: IAuth;
    /*
      PERSONAS
    */
    // Modelos principales
    serverDate: IStatusServerDate;
    location: ILocation;
    clienteQentry: IStatusClienteQentry;
    empresa: IStatusEmpresa;
    empleado: IStatusEmpleado;
    // Modelos multivaluados
    mvAntiguedad: IStatusMvAntiguedad;
    mvBanco: IStatusMvBanco;
    mvEstadoCivil: IStatusMvEstadoCivil;
    mvEstatusSolicitudCambioTelefonoAutorizado: IStatusMvEstatusSolicitudCambioTelefonoAutorizado;
    mvFormaDePagoSuscripcion: IStatusMvFormaDePagoSuscripcion;
    mvGenero: IStatusMvGenero;
    mvImpuestos: IStatusMvImpuestos;
    mvMotivoPagoSuscripcion: IStatusMvMotivoPagoSuscripcion;
    mvTipoCanalVenta: IStatusMvTipoCanalVenta;
    mvTipoDocumento: IStatusMvTipoDocumento;
    mvTipoEmpleado: IStatusMvTipoEmpleado;
    mvTipoServicioQentry: IStatusMvTipoServicioQentry;
    mvTipoSuscripcion: IStatusMvTipoSuscripcion;
    mvTipoUsuario: IStatusMvTipoUsuario;
    mvUsoFactura: IStatusMvUsoFactura;
    // Modelos funcionales
    canalVenta: IStatusCanalVenta;
    centroDeTrabajo: IStatusCentroDeTrabajo;
    codigoPostal: IStatusCodigoPostal;
    codigoQr: IStatusCodigoQr;
    datosFiscales: IStatusDatosFiscales;
    datosPersonales: IStatusDatosPersonales;
    direccion: IStatusDireccion;
    documento: IStatusDocumento;
    estado: IStatusEstado;
    geolocalizacion: IStatusGeolocalizacion;
    imagen: IStatusImagen;
    informacionBancaria: IStatusInformacionBancaria;
    informacionContrato: IStatusInformacionContrato;
    informacionSuscripcion: IStatusInformacionSuscripcion;
    modificacionSuscripcionQentry: IStatusModificacionSuscripcionQentry;
    modificadorNomina: IStatusModificadorNomina;
    pagoSuscripcion: IStatusPagoSuscripcion;
    pais: IStatusPais;
    servicioQentry: IStatusServicioQentry;
    solicitudCambioTelefonoAutorizado: IStatusSolicitudCambioTelefonoAutorizado;
    suscripcionQentry: IStatusSuscripcionQentry;
    tipoDocumentosPorPuesto: IStatusTipoDocumentosPorPuesto;
    user: IStatusUser;
    /*
      RONDINES
    */
    checkEnRuta: IStatusCheckEnRuta;
    checkpointRondin: IStatusCheckpointRondin;
    checkRondin: IStatusCheckRondin;
    mvMotivoCancelacion: IStatusMvMotivoCancelacion;
    rondin: IStatusRondin;
    rondinCancelado: IStatusRondinCancelado;
    rondinCompletado: IStatusRondinCompletado;
    /*
      NOMINA
    */
    asistencia: IStatusAsistencia;
    cambioEstatusAsistencia: IStatusCambioEstatusAsistencia;
    cambioEstatusIncapacidad: IStatusCambioEstatusIncapacidad;
    cambioEstatusVacaciones: IStatusCambioEstatusVacaciones;
    horario: IStatusHorario;
    horasExtra: IStatusHorasExtra;
    incapacidad: IStatusIncapacidad;
    incidencia: IStatusIncidencia;
    mvEstatusAsistencia: IStatusMvEstatusAsistencia;
    mvEstatusIncapacidades: IStatusMvEstatusIncapacidades;
    mvEstatusVacaciones: IStatusMvEstatusVacaciones;
    mvMotivoIncapacidad: IStatusMvMotivoIncapacidad;
    mvMotivoNotificacion: IStatusMvMotivoNotificacion;
    mvMotivoVacaciones: IStatusMvMotivoVacaciones;
    mvRiesgoPuesto: IStatusMvRiesgoPuesto;
    mvTipoAsistencia: IStatusMvTipoAsistencia;
    mvTipoContrato: IStatusMvTipoContrato;
    mvTipoHorasExtra: IStatusMvTipoHorasExtra;
    mvTipoJornada: IStatusMvTipoJornada;
    mvTipoMovimientoAsistencia: IStatusMvTipoMovimientoAsistencia;
    mvTipoNotificacion: IStatusMvTipoNotificacion;
    notificaciones: IStatusNotificaciones;
    puesto: IStatusPuesto;
    tabuladorHorasExtra: IStatusTabuladorHorasExtra;
    turno: IStatusTurno;
    vacaciones: IStatusVacaciones;

    // Functions
    fetchClienteQentry: () => void;
    fetchEmpresa: () => void;
    fetchEmpleado: () => void;
    fetchCentrosDeTrabajo: () => void;
    fetchRondines: () => void;
    fetchHorarios: () => void;
    fetchAsistencia: () => void;
    fetchNotifications: () => void;
    fetchVacations: () => void;
    fetchIncapacidades: () => void;
    fetchSolicitudesCambioDispositivo: () => void;
    createEmpresa: (
      direccion: IDireccion,
      datosFiscales: IDatosFiscales,
      empresa: IEmpresa
    ) => void;
    updateEmpresa: (
      direccion: IDireccion,
      datosFiscales: IDatosFiscales,
      empresa: IEmpresa
    ) => void;
    editDatosPersonales: (
      id: number,
      value: IDatosPersonales,
      empleadoModal: IEmpleado
    ) => void;
    addDatosFiscales: (value: IDatosFiscales, empleadoModal: IEmpleado) => void;
    removeDatosFiscales: (id: number, empleadoModal: IEmpleado) => void;
    editDatosFiscales: (
      id: number,
      value: IDatosFiscales,
      empleadoModal: IEmpleado
    ) => void;
    addOrEditInformacionBancaria: (
      value: IInformacionBancaria,
      empleadoModal: IEmpleado
    ) => void;
    editConfiguracion: (empleadoModal: IEmpleado) => void;
    createCentrosDeTrabajo: (
      centroDeTrabajoModal: ICreateCentroDeTrabajoData
    ) => void;
    editCentrosDeTrabajo: (centroDeTrabajoModal: ICodigoQr, blob: Blob) => void;
    addOrEditRondin: (rondinModal: IRondin) => void;
    deleteRondin: (rondinModal: IRondin) => void;
    updateHorarios: (horarios: HorariosUpdate[]) => void;
    createVacations: (
      empleado: IEmpleado,
      motivo: string,
      fecha_inicio: string,
      fecha_fin: string
    ) => void;
    createIncapacidades: (
      empleado: IEmpleado,
      motivo: string,
      fecha_inicio: string,
      fecha_fin: string
    ) => void;
    updateVacations: (
      vacaciones: IVacaciones,
      comentarios: string,
      aprobar: boolean
    ) => void;
    updateIncapacidades: (
      incapacidad: IIncapacidad,
      comentarios: string,
      aprobar: boolean
    ) => void;
    createDeviceChangeRequest: (
      empleado: IEmpleado,
      id_telefono_autorizado_anterior: string,
      id_telefono_autorizado_nuevo: string,
      usuario_aprobador: IUser
    ) => void;
    updateDeviceChangeRequest: (
      solicitudCambio: ISolicitudCambioTelefonoAutorizado,
      aprobar: boolean
    ) => void;
  }
] {
  const dispatch = useAppDispatch();
  /*
    THEME
  */
  const theme = useAppSelector((state) => state.theme.themeState);
  /*
    AUTH
  */
  const auth = useAppSelector((state) => state.auth.authState);

  /*
    PERSONAS (Modelos)
  */
  // Modelos principales
  const serverDate = useAppSelector((state) => state.time.timeState.serverDate);
  const location = useAppSelector((state) => state.time.timeState.location);
  const clienteQentry = useAppSelector(
    (state) => state.personas.personasState.clienteQentry
  );
  const empresa = useAppSelector(
    (state) => state.personas.personasState.empresa
  );
  const empleado = useAppSelector(
    (state) => state.personas.personasState.empleado
  );

  // Modelos multivaluados
  const mvAntiguedad = useAppSelector(
    (state) => state.personas.personasState.mvAntiguedad
  );
  const mvBanco = useAppSelector(
    (state) => state.personas.personasState.mvBanco
  );
  const mvEstadoCivil = useAppSelector(
    (state) => state.personas.personasState.mvEstadoCivil
  );
  const mvEstatusSolicitudCambioTelefonoAutorizado = useAppSelector(
    (state) =>
      state.personas.personasState.mvEstatusSolicitudCambioTelefonoAutorizado
  );
  const mvFormaDePagoSuscripcion = useAppSelector(
    (state) => state.personas.personasState.mvFormaDePagoSuscripcion
  );
  const mvGenero = useAppSelector(
    (state) => state.personas.personasState.mvGenero
  );
  const mvImpuestos = useAppSelector(
    (state) => state.personas.personasState.mvImpuestos
  );
  const mvMotivoPagoSuscripcion = useAppSelector(
    (state) => state.personas.personasState.mvMotivoPagoSuscripcion
  );
  const mvTipoCanalVenta = useAppSelector(
    (state) => state.personas.personasState.mvTipoCanalVenta
  );
  const mvTipoDocumento = useAppSelector(
    (state) => state.personas.personasState.mvTipoDocumento
  );
  const mvTipoEmpleado = useAppSelector(
    (state) => state.personas.personasState.mvTipoEmpleado
  );
  const mvTipoServicioQentry = useAppSelector(
    (state) => state.personas.personasState.mvTipoServicioQentry
  );
  const mvTipoSuscripcion = useAppSelector(
    (state) => state.personas.personasState.mvTipoSuscripcion
  );
  const mvTipoUsuario = useAppSelector(
    (state) => state.personas.personasState.mvTipoUsuario
  );
  const mvUsoFactura = useAppSelector(
    (state) => state.personas.personasState.mvUsoFactura
  );

  // Modelos funcionales
  const canalVenta = useAppSelector(
    (state) => state.personas.personasState.canalVenta
  );
  const centroDeTrabajo = useAppSelector(
    (state) => state.personas.personasState.centroDeTrabajo
  );
  const codigoPostal = useAppSelector(
    (state) => state.personas.personasState.codigoPostal
  );
  const codigoQr = useAppSelector(
    (state) => state.personas.personasState.codigoQr
  );
  const datosFiscales = useAppSelector(
    (state) => state.personas.personasState.datosFiscales
  );
  const datosPersonales = useAppSelector(
    (state) => state.personas.personasState.datosPersonales
  );
  const direccion = useAppSelector(
    (state) => state.personas.personasState.direccion
  );
  const documento = useAppSelector(
    (state) => state.personas.personasState.documento
  );
  const estado = useAppSelector((state) => state.personas.personasState.estado);
  const geolocalizacion = useAppSelector(
    (state) => state.personas.personasState.geolocalizacion
  );
  const imagen = useAppSelector((state) => state.personas.personasState.imagen);
  const informacionBancaria = useAppSelector(
    (state) => state.personas.personasState.informacionBancaria
  );
  const informacionContrato = useAppSelector(
    (state) => state.personas.personasState.informacionContrato
  );
  const informacionSuscripcion = useAppSelector(
    (state) => state.personas.personasState.informacionSuscripcion
  );
  const modificacionSuscripcionQentry = useAppSelector(
    (state) => state.personas.personasState.modificacionSuscripcionQentry
  );
  const modificadorNomina = useAppSelector(
    (state) => state.personas.personasState.modificadorNomina
  );
  const pagoSuscripcion = useAppSelector(
    (state) => state.personas.personasState.pagoSuscripcion
  );
  const pais = useAppSelector((state) => state.personas.personasState.pais);
  const servicioQentry = useAppSelector(
    (state) => state.personas.personasState.servicioQentry
  );
  const solicitudCambioTelefonoAutorizado = useAppSelector(
    (state) => state.personas.personasState.solicitudCambioTelefonoAutorizado
  );
  const suscripcionQentry = useAppSelector(
    (state) => state.personas.personasState.suscripcionQentry
  );
  const tipoDocumentosPorPuesto = useAppSelector(
    (state) => state.personas.personasState.tipoDocumentosPorPuesto
  );
  const user = useAppSelector((state) => state.personas.personasState.user);

  /*
    RONDINES (Modelos)
  */
  const checkEnRuta = useAppSelector(
    (state) => state.rondines.rondinesState.checkEnRuta
  );
  const checkpointRondin = useAppSelector(
    (state) => state.rondines.rondinesState.checkpointRondin
  );
  const checkRondin = useAppSelector(
    (state) => state.rondines.rondinesState.checkRondin
  );
  const mvMotivoCancelacion = useAppSelector(
    (state) => state.rondines.rondinesState.mvMotivoCancelacion
  );
  const rondin = useAppSelector((state) => state.rondines.rondinesState.rondin);
  const rondinCancelado = useAppSelector(
    (state) => state.rondines.rondinesState.rondinCancelado
  );
  const rondinCompletado = useAppSelector(
    (state) => state.rondines.rondinesState.rondinCompletado
  );

  /*
    Nomina (Modelos)
  */
  const asistencia = useAppSelector(
    (state) => state.nomina.nominaState.asistencia
  );
  const cambioEstatusAsistencia = useAppSelector(
    (state) => state.nomina.nominaState.cambioEstatusAsistencia
  );
  const cambioEstatusIncapacidad = useAppSelector(
    (state) => state.nomina.nominaState.cambioEstatusIncapacidad
  );
  const cambioEstatusVacaciones = useAppSelector(
    (state) => state.nomina.nominaState.cambioEstatusVacaciones
  );
  const horario = useAppSelector((state) => state.nomina.nominaState.horario);
  const horasExtra = useAppSelector(
    (state) => state.nomina.nominaState.horasExtra
  );
  const incapacidad = useAppSelector(
    (state) => state.nomina.nominaState.incapacidad
  );
  const incidencia = useAppSelector(
    (state) => state.nomina.nominaState.incidencia
  );
  const mvEstatusAsistencia = useAppSelector(
    (state) => state.nomina.nominaState.mvEstatusAsistencia
  );
  const mvEstatusIncapacidades = useAppSelector(
    (state) => state.nomina.nominaState.mvEstatusIncapacidades
  );
  const mvEstatusVacaciones = useAppSelector(
    (state) => state.nomina.nominaState.mvEstatusVacaciones
  );
  const mvMotivoIncapacidad = useAppSelector(
    (state) => state.nomina.nominaState.mvMotivoIncapacidad
  );
  const mvMotivoNotificacion = useAppSelector(
    (state) => state.nomina.nominaState.mvMotivoNotificacion
  );
  const mvMotivoVacaciones = useAppSelector(
    (state) => state.nomina.nominaState.mvMotivoVacaciones
  );
  const mvRiesgoPuesto = useAppSelector(
    (state) => state.nomina.nominaState.mvRiesgoPuesto
  );
  const mvTipoAsistencia = useAppSelector(
    (state) => state.nomina.nominaState.mvTipoAsistencia
  );
  const mvTipoContrato = useAppSelector(
    (state) => state.nomina.nominaState.mvTipoContrato
  );
  const mvTipoHorasExtra = useAppSelector(
    (state) => state.nomina.nominaState.mvTipoHorasExtra
  );
  const mvTipoJornada = useAppSelector(
    (state) => state.nomina.nominaState.mvTipoJornada
  );
  const mvTipoMovimientoAsistencia = useAppSelector(
    (state) => state.nomina.nominaState.mvTipoMovimientoAsistencia
  );
  const mvTipoNotificacion = useAppSelector(
    (state) => state.nomina.nominaState.mvTipoNotificacion
  );
  const notificaciones = useAppSelector(
    (state) => state.nomina.nominaState.notificaciones
  );
  const puesto = useAppSelector((state) => state.nomina.nominaState.puesto);
  const tabuladorHorasExtra = useAppSelector(
    (state) => state.nomina.nominaState.tabuladorHorasExtra
  );
  const turno = useAppSelector((state) => state.nomina.nominaState.turno);
  const vacaciones = useAppSelector(
    (state) => state.nomina.nominaState.vacaciones
  );

  const fetchClienteQentry = async () => {
    await dispatch(
      getServerDateAsyncThunk({ latitud: 19.04504, longitud: -98.26024 })
    );
    dispatch(getAllMvTipoUsuarioAsyncThunk({ serverDate: serverDate.data }));
    dispatch(getAllUserAsyncThunk({ serverDate: serverDate.data }));
    dispatch(getAllPaisAsyncThunk({ serverDate: serverDate.data }));
    dispatch(getAllMvUsoFacturaAsyncThunk({ serverDate: serverDate.data }));
    dispatch(getAllDatosFiscalesAsyncThunk({ serverDate: serverDate.data }));
    await dispatch(
      getClienteQentryAsyncThunk({ serverDate: serverDate.data, id: 1 })
    );
  };

  const fetchEmpresa = async () => {
    dispatch(getAllEstadoAsyncThunk({ serverDate: serverDate.data }));
    await dispatch(
      getAllGeolocalizacionAsyncThunk({
        serverDate: serverDate.data,
        idCliente: clienteQentry.data.id,
      })
    );
    await dispatch(
      getAllDireccionAsyncThunk({
        serverDate: serverDate.data,
        idCliente: clienteQentry.data.id,
      })
    );
    await dispatch(
      getAllEmpresaAsyncThunk({
        serverDate: serverDate.data,
        idCliente: clienteQentry.data.id,
      })
    );
  };

  const fetchEmpleado = async () => {
    await dispatch(
      getAllImagenAsyncThunk({
        serverDate: serverDate.data,
        idCliente: clienteQentry.data.id,
      })
    );
    await dispatch(getAllMvGeneroAsyncThunk({ serverDate: serverDate.data }));
    await dispatch(
      getAllMvEstadoCivilAsyncThunk({ serverDate: serverDate.data })
    );
    await dispatch(
      getAllGeolocalizacionAsyncThunk({
        serverDate: serverDate.data,
        idCliente: clienteQentry.data.id,
      })
    );
    await dispatch(
      getAllDireccionAsyncThunk({
        serverDate: serverDate.data,
        idCliente: clienteQentry.data.id,
      })
    );
    await dispatch(
      getAllDatosPersonalesAsyncThunk({
        serverDate: serverDate.data,
        idCliente: clienteQentry.data.id,
      })
    );
    await dispatch(getAllMvBancoAsyncThunk({ serverDate: serverDate.data }));
    await dispatch(
      getAllInformacionBancariaAsyncThunk({
        serverDate: serverDate.data,
        idCliente: clienteQentry.data.id,
      })
    );
    await dispatch(
      getAllMvTipoDocumentoAsyncThunk({ serverDate: serverDate.data })
    );
    await dispatch(
      getAllDocumentoAsyncThunk({
        serverDate: serverDate.data,
        idCliente: clienteQentry.data.id,
      })
    );
    await dispatch(
      getAllInformacionContratoAsyncThunk({
        serverDate: serverDate.data,
        idCliente: clienteQentry.data.id,
      })
    );
    await dispatch(
      getAllCentroDeTrabajoAsyncThunk({
        serverDate: serverDate.data,
        idCliente: clienteQentry.data.id,
      })
    );
    await dispatch(
      getAllCodigoQrAsyncThunk({
        serverDate: serverDate.data,
        idCliente: clienteQentry.data.id,
      })
    );
    await dispatch(
      getAllMvTipoEmpleadoAsyncThunk({ serverDate: serverDate.data })
    );
    await dispatch(
      getAllEmpleadoAsyncThunk({
        serverDate: serverDate.data,
        idCliente: clienteQentry.data.id,
      })
    );
  };

  const fetchCentrosDeTrabajo = async () => {
    dispatch(
      getAllImagenAsyncThunk({
        serverDate: serverDate.data,
        idCliente: clienteQentry.data.id,
      })
    );
    await dispatch(
      getAllGeolocalizacionAsyncThunk({
        serverDate: serverDate.data,
        idCliente: clienteQentry.data.id,
      })
    );
    await dispatch(
      getAllDireccionAsyncThunk({
        serverDate: serverDate.data,
        idCliente: clienteQentry.data.id,
      })
    );
    await dispatch(
      getAllCentroDeTrabajoAsyncThunk({
        serverDate: serverDate.data,
        idCliente: clienteQentry.data.id,
      })
    );
    await dispatch(
      getAllCodigoQrAsyncThunk({
        serverDate: serverDate.data,
        idCliente: clienteQentry.data.id,
      })
    );
  };

  const fetchRondines = async () => {
    await dispatch(
      getAllMvMotivoCancelacionAsyncThunk({
        serverDate: serverDate.data,
        idCliente: clienteQentry.data.id,
      })
    );
    await dispatch(
      getAllCheckpointRondinAsyncThunk({
        serverDate: serverDate.data,
        idCliente: clienteQentry.data.id,
      })
    );
    await dispatch(
      getAllCheckRondinAsyncThunk({
        serverDate: serverDate.data,
        idCliente: clienteQentry.data.id,
      })
    );
    await dispatch(
      getAllClienteQentryRondinAsyncThunk({
        serverDate: serverDate.data,
        idCliente: clienteQentry.data.id,
      })
    );
    await dispatch(
      getAllRondinCanceladoAsyncThunk({
        serverDate: serverDate.data,
        idCliente: clienteQentry.data.id,
      })
    );
    await dispatch(
      getAllRondinCompletadoAsyncThunk({
        serverDate: serverDate.data,
        idCliente: clienteQentry.data.id,
      })
    );
  };

  const fetchHorarios = async () => {
    await dispatch(
      getAllTurnoAsyncThunk({
        serverDate: serverDate.data,
        idCliente: clienteQentry.data.id,
      })
    );
    await dispatch(
      getAllHorarioAsyncThunk({
        serverDate: serverDate.data,
        idCliente: clienteQentry.data.id,
      })
    );
  };

  const fetchAsistencia = async () => {
    await dispatch(
      getAllMvTipoAsistenciaAsyncThunk({ serverDate: serverDate.data })
    );
    await dispatch(
      getAllMvTipoMovimientoAsistenciaAsyncThunk({
        serverDate: serverDate.data,
      })
    );
    await dispatch(
      getAllMvEstatusAsistenciaAsyncThunk({ serverDate: serverDate.data })
    );
    await dispatch(
      getAllMvTipoJornadaAsyncThunk({ serverDate: serverDate.data })
    );
    await dispatch(
      getAllAsistenciaAsyncThunk({
        serverDate: serverDate.data,
        idCliente: clienteQentry.data.id,
      })
    );
  };

  const fetchNotifications = async () => {
    await dispatch(
      getAllMvTipoNotificacionAsyncThunk({ serverDate: serverDate.data })
    );
    await dispatch(
      getAllMvMotivoNotificacionAsyncThunk({ serverDate: serverDate.data })
    );
    await dispatch(
      getAllNotificacionesAsyncThunk({
        serverDate: serverDate.data,
        idCliente: clienteQentry.data.id,
      })
    );
  };

  const fetchVacations = async () => {
    await dispatch(
      getAllMvMotivoVacacionesAsyncThunk({ serverDate: serverDate.data })
    );
    await dispatch(
      getAllMvEstatusVacacionesAsyncThunk({ serverDate: serverDate.data })
    );
    await dispatch(
      getAllVacacionesAsyncThunk({
        serverDate: serverDate.data,
        idCliente: clienteQentry.data.id,
      })
    );
    await dispatch(
      getAllCambioEstatusVacacionesAsyncThunk({
        serverDate: serverDate.data,
        idCliente: clienteQentry.data.id,
      })
    );
  };

  const fetchIncapacidades = async () => {
    await dispatch(
      getAllMvMotivoIncapacidadAsyncThunk({ serverDate: serverDate.data })
    );
    await dispatch(
      getAllMvEstatusIncapacidadesAsyncThunk({ serverDate: serverDate.data })
    );
    await dispatch(
      getAllIncapacidadAsyncThunk({
        serverDate: serverDate.data,
        idCliente: clienteQentry.data.id,
      })
    );
    await dispatch(
      getAllCambioEstatusIncapacidadAsyncThunk({
        serverDate: serverDate.data,
        idCliente: clienteQentry.data.id,
      })
    );
  };

  const fetchSolicitudesCambioDispositivo = async () => {
    await dispatch(
      getAllMvEstatusSolicitudCambioTelefonoAutorizadoAsyncThunk({
        serverDate: serverDate.data,
      })
    );
    await dispatch(
      getAllSolicitudCambioTelefonoAutorizadoAsyncThunk({
        serverDate: serverDate.data,
        idCliente: clienteQentry.data.id,
      })
    );
  };

  /*
    Empresa 
  */

  const createEmpresa = async (
    direccion: IDireccion,
    datosFiscales: IDatosFiscales,
    empresa: IEmpresa
  ) => {
    const tempDireccion = direccion;
    const tempDatosFiscales = datosFiscales;
    const tempEmpresa = empresa;

    const geolocalizacionCreated = await dispatch(
      createGeolocalizacionAsyncThunk({
        serverDate: serverDate.data,
        idCliente: clienteQentry.data.id,
        object: direccion.geolocalizacion,
      })
    );
    const geolocalizacionPayload =
      geolocalizacionCreated.payload as IStatusGeolocalizacion;
    console.log({ geolocalizacionPayload });
    tempDireccion.geolocalizacion = geolocalizacionPayload.data;

    const direccionCreated = await dispatch(
      createDireccionAsyncThunk({
        serverDate: serverDate.data,
        idCliente: clienteQentry.data.id,
        object: tempDireccion,
      })
    );
    const direccionPayload = direccionCreated.payload as IStatusDireccion;
    console.log({ direccionPayload });

    const datosFiscalesCreated = await dispatch(
      createDatosFiscalesAsyncThunk({
        serverDate: serverDate.data,
        DatosFiscales: datosFiscales,
      })
    );

    const datosFiscalesPayload =
      datosFiscalesCreated.payload as IStatusDatosFiscales;
    console.log({ datosFiscalesPayload });

    tempEmpresa.direccion = direccionPayload.data;
    tempEmpresa.datos_fiscales = datosFiscalesPayload.data;

    await dispatch(
      createEmpresaAsyncThunk({
        serverDate: serverDate.data,
        idCliente: clienteQentry.data.id,
        object: tempEmpresa,
      })
    );
    await fetchEmpresa();
  };

  const updateEmpresa = async (
    direccion: IDireccion,
    datosFiscales: IDatosFiscales,
    empresa: IEmpresa
  ) => {
    const geolocalizacionUpdated = await dispatch(
      updateGeolocalizacionAsyncThunk({
        serverDate: serverDate.data,
        id: direccion.geolocalizacion.id,
        idCliente: clienteQentry.data.id,
        object: direccion.geolocalizacion,
      })
    );
    const direccionUpdated = await dispatch(
      updateDireccionAsyncThunk({
        serverDate: serverDate.data,
        id: direccion.id,
        idCliente: clienteQentry.data.id,
        object: direccion,
      })
    );
    const datosFiscalesUpdated = await dispatch(
      updateDatosFiscalesAsyncThunk({
        serverDate: serverDate.data,
        id: datosFiscales.id,
        DatosFiscales: datosFiscales,
      })
    );
    await dispatch(
      updateEmpresaAsyncThunk({
        serverDate: serverDate.data,
        id: empresa.id,
        idCliente: clienteQentry.data.id,
        object: empresa,
      })
    );
    await fetchEmpresa();
  };
  /*
    Empleado 
  */

  const editDatosPersonales = async (
    id: number,
    value: IDatosPersonales,
    empleadoModal: IEmpleado
  ) => {
    const geolocalizacionCreated = !value.direccion.geolocalizacion.id
      ? await dispatch(
          createGeolocalizacionAsyncThunk({
            serverDate: serverDate.data,
            idCliente: clienteQentry.data.id,
            object: value.direccion.geolocalizacion,
          })
        )
      : await dispatch(
          updateGeolocalizacionAsyncThunk({
            serverDate: serverDate.data,
            id: value.id,
            idCliente: clienteQentry.data.id,
            object: value.direccion.geolocalizacion,
          })
        );
    const geolocalizacion =
      geolocalizacionCreated.payload as IStatusGeolocalizacion;
    const direccionCreated = !value.direccion.id
      ? await dispatch(
          createDireccionAsyncThunk({
            serverDate: serverDate.data,
            idCliente: clienteQentry.data.id,
            object: {
              ...value.direccion,
              geolocalizacion: geolocalizacion.data,
            },
          })
        )
      : await dispatch(
          updateDireccionAsyncThunk({
            serverDate: serverDate.data,
            id: value.id,
            idCliente: clienteQentry.data.id,
            object: {
              ...value.direccion,
              geolocalizacion: geolocalizacion.data,
            },
          })
        );
    const direccion = direccionCreated.payload as IStatusDireccion;
    const datosPersonalesEdited = await dispatch(
      updateDatosPersonalesAsyncThunk({
        serverDate: serverDate.data,
        id: id,
        idCliente: clienteQentry.data.id,
        object: { ...value, direccion: direccion.data },
      })
    );
    const datosPersonales =
      datosPersonalesEdited.payload as IStatusDatosPersonales;
    const empleadoUpdated = await dispatch(
      updateEmpleadoAsyncThunk({
        serverDate: serverDate.data,
        id: empleadoModal.id,
        idCliente: clienteQentry.data.id,
        object: empleadoModal,
      })
    );
    const empleado = empleadoUpdated.payload as IStatusEmpleado;
    dispatch(setTeamMembersEmpleadoModal(empleado.data));
  };

  const addDatosFiscales = async (
    value: IDatosFiscales,
    empleadoModal: IEmpleado
  ) => {
    const datosFiscalesCreated = await dispatch(
      createDatosFiscalesAsyncThunk({
        serverDate: serverDate.data,
        DatosFiscales: value,
      })
    );
    const datosFiscales = datosFiscalesCreated.payload as IStatusDatosFiscales;
    const empleadoUpdated = await dispatch(
      updateEmpleadoAsyncThunk({
        serverDate: serverDate.data,
        id: empleadoModal.id,
        idCliente: clienteQentry.data.id,
        object: {
          ...empleadoModal,
          datos_fiscales: [...empleadoModal.datos_fiscales, datosFiscales.data],
        },
      })
    );
    const empleado = empleadoUpdated.payload as IStatusEmpleado;
    dispatch(setTeamMembersEmpleadoModal(empleado.data));
  };

  const removeDatosFiscales = async (id: number, empleadoModal: IEmpleado) => {
    await dispatch(deleteDatosFiscalesAsyncThunk({ id: id }));
    const empleadoUpdated = await dispatch(
      updateEmpleadoAsyncThunk({
        serverDate: serverDate.data,
        id: empleadoModal.id,
        idCliente: clienteQentry.data.id,
        object: {
          ...empleadoModal,
          datos_fiscales: [
            ...empleadoModal.datos_fiscales.filter((e) => e.id !== id),
          ],
        },
      })
    );
    const empleado = empleadoUpdated.payload as IStatusEmpleado;
    dispatch(setTeamMembersEmpleadoModal(empleado.data));
  };

  const editDatosFiscales = async (
    id: number,
    value: IDatosFiscales,
    empleadoModal: IEmpleado
  ) => {
    const datosFiscalesEdited = await dispatch(
      updateDatosFiscalesAsyncThunk({
        serverDate: serverDate.data,
        id: id,
        DatosFiscales: value,
      })
    );
    const datosFiscales = datosFiscalesEdited.payload as IStatusDatosFiscales;
    const empleadoUpdated = await dispatch(
      updateEmpleadoAsyncThunk({
        serverDate: serverDate.data,
        id: empleadoModal.id,
        idCliente: clienteQentry.data.id,
        object: {
          ...empleadoModal,
          datos_fiscales: empleadoModal.datos_fiscales.map((e) => {
            if (e.id === datosFiscales.data.id) return datosFiscales.data;
            else return e;
          }),
        },
      })
    );
    const empleado = empleadoUpdated.payload as IStatusEmpleado;
    dispatch(setTeamMembersEmpleadoModal(empleado.data));
  };

  const addOrEditInformacionBancaria = async (
    value: IInformacionBancaria,
    empleadoModal: IEmpleado
  ) => {
    const informacionBancariaCreated = !value.id
      ? await dispatch(
          createInformacionBancariaAsyncThunk({
            serverDate: serverDate.data,
            idCliente: clienteQentry.data.id,
            object: { ...value, cliente_qentry: clienteQentry.data },
          })
        )
      : await dispatch(
          updateInformacionBancariaAsyncThunk({
            serverDate: serverDate.data,
            id: value.id,
            idCliente: clienteQentry.data.id,
            object: value,
          })
        );
    const informacionBancaria =
      informacionBancariaCreated.payload as IStatusInformacionBancaria;
    const empleadoUpdated = await dispatch(
      updateEmpleadoAsyncThunk({
        serverDate: serverDate.data,
        id: empleadoModal.id,
        idCliente: clienteQentry.data.id,
        object: {
          ...empleadoModal,
          informacion_bancaria: informacionBancaria.data,
        },
      })
    );
    const empleado = empleadoUpdated.payload as IStatusEmpleado;
    dispatch(setTeamMembersEmpleadoModal(empleado.data));
  };

  const editConfiguracion = async (empleadoModal: IEmpleado) => {
    const empleadoUpdated = await dispatch(
      updateEmpleadoAsyncThunk({
        serverDate: serverDate.data,
        id: empleadoModal.id,
        idCliente: clienteQentry.data.id,
        object: empleadoModal,
      })
    );
    const empleado = empleadoUpdated.payload as IStatusEmpleado;
    dispatch(setTeamMembersEmpleadoModal(empleado.data));
  };

  /*
    Centros de trabajo
  */

  const createCentrosDeTrabajo = async (
    centroDeTrabajoModal: ICreateCentroDeTrabajoData
  ) => {
    const temp = getEmptyCentroDeTrabajo();
    temp.activo = true;
    temp.nombre_centro = centroDeTrabajoModal.nombre_centro;
    temp.empresa.id = centroDeTrabajoModal.id_empresa;
    temp.cliente_qentry.id = clienteQentry.data.id;
    temp.distancia_maxima_permitida =
      centroDeTrabajoModal.distancia_maxima_permitida;
    temp.numero_empleados_asignados =
      centroDeTrabajoModal.numero_empleados_asignados;
    temp.centro_trabajo_predeterminado =
      centroDeTrabajoModal.centro_trabajo_predeterminado;
    temp.activo = true;
    temp.estatus_sistema = true;
    temp.autor.id = centroDeTrabajoModal.id_autor;
    //Direccion
    temp.direccion.calle = centroDeTrabajoModal.calle;
    temp.direccion.numero_interior = centroDeTrabajoModal.numero_interior;
    temp.direccion.numero_exterior = centroDeTrabajoModal.numero_exterior;
    temp.direccion.codigo_postal.id = centroDeTrabajoModal.id_cp;
    temp.direccion.colonia = centroDeTrabajoModal.colonia;
    temp.direccion.ciudad = centroDeTrabajoModal.ciudad;
    temp.direccion.colonia = centroDeTrabajoModal.colonia;
    temp.direccion.delegacion_municipio =
      centroDeTrabajoModal.delegacion_municipio;
    temp.direccion.numero_contacto = centroDeTrabajoModal.numero_contacto;
    temp.direccion.geolocalizacion.latitud = centroDeTrabajoModal.latitud;
    temp.direccion.geolocalizacion.longitud = centroDeTrabajoModal.longitud;
    temp.direccion.geolocalizacion.autor.id = centroDeTrabajoModal.id_autor;
    temp.direccion.geolocalizacion.cliente_qentry.id = clienteQentry.data.id;
    temp.direccion.geolocalizacion.descripcion =
      centroDeTrabajoModal.descripcion_geolocalizacion;
    temp.direccion.geolocalizacion.estatus_sistema = true;
    temp.direccion.estado.id = centroDeTrabajoModal.id_estado;
    temp.direccion.pais.id = centroDeTrabajoModal.id_pais;
    temp.direccion.autor.id = centroDeTrabajoModal.id_autor;
    temp.direccion.cliente_qentry.id = clienteQentry.data.id;
    temp.direccion.estatus_sistema = true;
    temp.autor.id = centroDeTrabajoModal.id_autor;
    temp.cliente_qentry.id = clienteQentry.data.id;

    const geolocalizacionCreated = await dispatch(
      createGeolocalizacionAsyncThunk({
        serverDate: serverDate.data,
        idCliente: clienteQentry.data.id,
        object: temp.direccion.geolocalizacion,
      })
    );
    const geolocalizacion =
      geolocalizacionCreated.payload as IStatusGeolocalizacion;
    console.log({ geolocalizacion });
    temp.direccion.geolocalizacion = geolocalizacion.data;

    const direccionCreated = await dispatch(
      createDireccionAsyncThunk({
        serverDate: serverDate.data,
        idCliente: clienteQentry.data.id,
        object: temp.direccion,
      })
    );
    const direccion = direccionCreated.payload as IStatusDireccion;
    console.log({ direccion });

    temp.direccion = direccion.data;

    const centroDeTrabajoCreated = await dispatch(
      createCentroDeTrabajoAsyncThunk({
        serverDate: serverDate.data,
        idCliente: clienteQentry.data.id,
        object: temp,
      })
    );
    const centroDeTrabajo =
      centroDeTrabajoCreated.payload as IStatusGeolocalizacion;

    console.log({ centroDeTrabajo });
    centroDeTrabajoModal.id_centro = centroDeTrabajo.data.id;

    await dispatch(
      createMultipleCentroDeTrabajoAsyncThunk({
        serverDate: serverDate.data,
        idCliente: clienteQentry.data.id,
        create: [centroDeTrabajoModal],
      })
    );
    fetchCentrosDeTrabajo();
  };

  const editCentrosDeTrabajo = async (
    centroDeTrabajoModal: ICodigoQr,
    blob: Blob
  ) => {
    let url = "";
    let nombre = "";
    const tempRes = await postFile(
      "personas/helpers/subir-archivo",
      blob,
      centroDeTrabajoModal.clave_codigo + ".png"
    );
    centroDeTrabajoModal.imagen_codigo = null;
    //if (tempRes.data.archivos) centroDeTrabajoModal.imagen_codigo = `${process.env.REACT_APP_STORAGE_URL}${tempRes.data.archivos[0].url_archivo}`;
    const geolocalizacionUpdated = await dispatch(
      updateGeolocalizacionAsyncThunk({
        serverDate: serverDate.data,
        id: centroDeTrabajoModal.geolocalizacion.id,
        idCliente: clienteQentry.data.id,
        object: centroDeTrabajoModal.geolocalizacion,
      })
    );
    const direccionUpdated = await dispatch(
      updateDireccionAsyncThunk({
        serverDate: serverDate.data,
        id: centroDeTrabajoModal.centro_de_trabajo.direccion.id,
        idCliente: clienteQentry.data.id,
        object: centroDeTrabajoModal.centro_de_trabajo.direccion,
      })
    );
    const centroDeTrabajoUpdated = await dispatch(
      updateCentroDeTrabajoAsyncThunk({
        serverDate: serverDate.data,
        id: centroDeTrabajoModal.centro_de_trabajo.id,
        idCliente: clienteQentry.data.id,
        object: centroDeTrabajoModal.centro_de_trabajo,
      })
    );
    const codigoQrUpdated = await dispatch(
      updateCodigoQrAsyncThunk({
        serverDate: serverDate.data,
        id: centroDeTrabajoModal.id,
        idCliente: clienteQentry.data.id,
        object: centroDeTrabajoModal,
      })
    );
    // fetchCentrosDeTrabajo()
  };

  /*
    Rondines
  */

  const addOrEditRondin = async (rondinModal: IRondin) => {
    console.log("addOrEditRondin: ", rondinModal);
    if (rondinModal.id === 0) {
      // Se deben crear
      let i = 0;
      const checkpoints = [];
      for (const checkpoint of rondinModal.checkpoints) {
        console.log(`checkpoint (${i}):`, checkpoint);
        const checkpointRondinCreated = await dispatch(
          createCheckpointRondinAsyncThunk({
            serverDate: serverDate.data,
            idCliente: clienteQentry.data.id,
            CheckpointRondin: checkpoint,
          })
        );
        const checkpointRondin =
          checkpointRondinCreated.payload as IStatusCheckpointRondin;
        console.log(`checkpointResult (${i}):`, checkpointRondin.data);
        checkpoints.push(checkpointRondin.data);
        i++;
      }
      rondinModal.checkpoints = checkpoints;
    } else {
      // Se deben actualizar
      // TODO: Muy probablemente se deben eliminar y volver a crear
    }
    console.log("checkpointRondinCreated: ", rondinModal);
    const checkpointRondinCreated =
      rondinModal.id === 0
        ? await dispatch(
            createRondinAsyncThunk({
              serverDate: serverDate.data,
              idCliente: clienteQentry.data.id,
              Rondin: rondinModal,
            })
          )
        : await dispatch(
            updateRondinAsyncThunk({
              serverDate: serverDate.data,
              id: rondinModal.id,
              idCliente: clienteQentry.data.id,
              Rondin: rondinModal,
            })
          );

    console.log("checkpointRondinCreated: ", checkpointRondinCreated.payload);
    await dispatch(
      getAllClienteQentryRondinAsyncThunk({
        serverDate: serverDate.data,
        idCliente: clienteQentry.data.id,
      })
    );
    dispatch(setPatrolsRondinModal(getEmptyRondin()));
  };

  const deleteRondin = async (rondinModal: IRondin) => {
    const checkpointRondinCreated = await dispatch(
      deleteRondinAsyncThunk({
        id: rondinModal.id,
        idCliente: clienteQentry.data.id,
      })
    );
    await dispatch(
      getAllClienteQentryRondinAsyncThunk({
        serverDate: serverDate.data,
        idCliente: clienteQentry.data.id,
      })
    );
    dispatch(setPatrolsRondinModal(getEmptyRondin()));
  };

  /*
    Nomina
  */

  const updateHorarios = async (horarios: HorariosUpdate[]) => {
    const horariosUpdated = horarios.map(async (e, i) => {
      let empleadoToUpdate = getEmptyHorario();
      const empleadoData = empleado.data_array.find(
        (v) => v.id.toString() === e.id
      );
      const empleadoHorario = horario.data_array.find(
        (v) => v.empleado.id.toString() === e.id
      );
      if (empleadoHorario)
        empleadoToUpdate = {
          ...(await setHorario(empleadoHorario, serverDate.data)),
        };
      else if (empleadoData) empleadoToUpdate.empleado = empleadoData;
      empleadoToUpdate.cliente_qentry =
        empleadoToUpdate.empleado.cliente_qentry;
      empleadoToUpdate.autor = auth.empleado.usuario;
      //Lunes
      empleadoToUpdate.inicio_lunes = e.horarios[0].entrada;
      empleadoToUpdate.fin_lunes = e.horarios[0].salida;
      //Martes
      empleadoToUpdate.inicio_martes = e.horarios[1].entrada;
      empleadoToUpdate.fin_martes = e.horarios[1].salida;
      //Miercoles
      empleadoToUpdate.inicio_miercoles = e.horarios[2].entrada;
      empleadoToUpdate.fin_miercoles = e.horarios[2].salida;
      //Jueves
      empleadoToUpdate.inicio_jueves = e.horarios[3].entrada;
      empleadoToUpdate.fin_jueves = e.horarios[3].salida;
      //Viernes
      empleadoToUpdate.inicio_viernes = e.horarios[4].entrada;
      empleadoToUpdate.fin_viernes = e.horarios[4].salida;
      //Sabado
      empleadoToUpdate.inicio_sabado = e.horarios[5].entrada;
      empleadoToUpdate.fin_sabado = e.horarios[5].salida;
      //Domingo
      empleadoToUpdate.inicio_domingo = e.horarios[6].entrada;
      empleadoToUpdate.fin_domingo = e.horarios[6].salida;

      empleadoToUpdate.estatus_sistema = true;

      const horarioUpdated =
        empleadoToUpdate.id > 0
          ? await dispatch(
              updateHorarioAsyncThunk({
                serverDate: serverDate.data,
                id: empleadoToUpdate.id,
                idCliente: clienteQentry.data.id,
                object: empleadoToUpdate,
              })
            )
          : await dispatch(
              createHorarioAsyncThunk({
                serverDate: serverDate.data,
                idCliente: clienteQentry.data.id,
                object: empleadoToUpdate,
              })
            );
      const horarioResult = horarioUpdated.payload as IStatusHorario;

      return `Horario empleado: ${e.id}, estado: ${horarioResult.mensaje}`;
    });
    await fetchHorarios();
  };

  // Days between dates
  function treatAsUTC(date: string) {
    var result = new Date(date);
    result.setMinutes(result.getMinutes() - result.getTimezoneOffset());
    return result.getMilliseconds();
  }

  function daysBetween(startDate: string, endDate: string) {
    var millisecondsPerDay = 24 * 60 * 60 * 1000;
    return Math.trunc(
      (treatAsUTC(endDate) - treatAsUTC(startDate)) / millisecondsPerDay
    );
  }

  const createVacations = async (
    empleado: IEmpleado,
    motivo: string,
    fecha_inicio: string,
    fecha_fin: string
  ) => {
    const tempStatus = getEmptyCambioEstatusVacaciones();
    const temp = getEmptyVacaciones();
    temp.empleado = empleado;
    temp.fecha_inicio_vacaciones = getReportsDateString(fecha_inicio);
    temp.fecha_fin_vacaciones = getReportsDateString(fecha_fin);
    temp.dias_a_tomar = daysBetween(fecha_inicio, fecha_fin);
    temp.dias_restantes = temp.dias_a_tomar;
    temp.cliente_qentry = clienteQentry.data;
    temp.autor = auth.empleado.usuario;
    temp.estatus_sistema = true;
    temp.estatus_vacaciones.id = 1;
    temp.motivo_vacaciones.dato = motivo;
    temp.motivo_vacaciones.estatus_sistema = true;
    const resultMvMotivoVacaciones = await dispatch(
      createMvMotivoVacacionesAsyncThunk({
        serverDate: serverDate.data,
        MvMotivoVacaciones: temp.motivo_vacaciones,
      })
    );
    const mvMotivoVacaciones =
      resultMvMotivoVacaciones.payload as IStatusMvMotivoVacaciones;
    temp.motivo_vacaciones = mvMotivoVacaciones.data;
    const resultVacaciones = await dispatch(
      createVacacionesAsyncThunk({
        serverDate: serverDate.data,
        idCliente: clienteQentry.data.id,
        object: temp,
      })
    );
    const vacaciones = resultVacaciones.payload as IStatusVacaciones;
    tempStatus.vacaciones = vacaciones.data;
    tempStatus.nuevo_estatus = vacaciones.data.estatus_vacaciones;
    tempStatus.cliente_qentry = clienteQentry.data;
    tempStatus.autor = auth.empleado.usuario;
    tempStatus.estatus_sistema = true;
    const resultCambioEstatusVacaciones = await dispatch(
      createCambioEstatusVacacionesAsyncThunk({
        serverDate: serverDate.data,
        idCliente: clienteQentry.data.id,
        object: tempStatus,
      })
    );
    await fetchVacations();
  };

  const updateVacations = async (
    vacaciones: IVacaciones,
    comentarios: string,
    aprobar: boolean
  ) => {
    const tempVacations = await setVacaciones(vacaciones, serverDate.data);
    tempVacations.comentarios_aprobador = comentarios;
    tempVacations.estatus_vacaciones.id = aprobar ? 2 : 3;
    const resultVacaciones = await dispatch(
      updateVacacionesAsyncThunk({
        serverDate: serverDate.data,
        id: vacaciones.id,
        idCliente: clienteQentry.data.id,
        object: tempVacations,
      })
    );
    await fetchVacations();
  };

  const createIncapacidades = async (
    empleado: IEmpleado,
    motivo: string,
    fecha_inicio: string,
    fecha_fin: string
  ) => {
    const tempStatus = getEmptyCambioEstatusIncapacidad();
    const temp = getEmptyIncapacidad();
    temp.empleado = empleado;
    temp.fecha_inicio_incapacidad = getReportsDateString(fecha_inicio);
    temp.fecha_fin_incapacidad = getReportsDateString(fecha_fin);
    temp.comprobante.id = 1;
    temp.dias_a_tomar = daysBetween(fecha_inicio, fecha_fin);
    temp.cliente_qentry = clienteQentry.data;
    temp.autor = auth.empleado.usuario;
    temp.estatus_sistema = true;
    temp.estatus_incapacidad.id = 1;
    temp.motivo_incapacidad.valor = motivo;
    temp.motivo_incapacidad.dato = motivo;
    temp.motivo_incapacidad.estatus_sistema = true;
    const resultMvMotivoIncapacidad = await dispatch(
      createMvMotivoIncapacidadAsyncThunk({
        serverDate: serverDate.data,
        MvMotivoIncapacidad: temp.motivo_incapacidad,
      })
    );
    const mvMotivoIncapacidad =
      resultMvMotivoIncapacidad.payload as IStatusMvMotivoIncapacidad;
    temp.motivo_incapacidad = mvMotivoIncapacidad.data;
    const resultIncapacidad = await dispatch(
      createIncapacidadAsyncThunk({
        serverDate: serverDate.data,
        idCliente: clienteQentry.data.id,
        object: temp,
      })
    );
    const incapacidad = resultIncapacidad.payload as IStatusIncapacidad;
    tempStatus.incapacidad = incapacidad.data;
    tempStatus.nuevo_estatus = incapacidad.data.estatus_incapacidad;
    tempStatus.cliente_qentry = clienteQentry.data;
    tempStatus.autor = auth.empleado.usuario;
    tempStatus.estatus_sistema = true;
    const resultCambioEstatusIncapacidad = await dispatch(
      createCambioEstatusIncapacidadAsyncThunk({
        serverDate: serverDate.data,
        idCliente: clienteQentry.data.id,
        object: tempStatus,
      })
    );
    console.log("Resultado cambio", resultCambioEstatusIncapacidad);

    await fetchIncapacidades();
  };

  const updateIncapacidades = async (
    incapacidad: IIncapacidad,
    comentarios: string,
    aprobar: boolean
  ) => {
    const tempIncapacidad = await setIncapacidad(incapacidad, serverDate.data);
    tempIncapacidad.comentarios_aprobador = comentarios;
    tempIncapacidad.estatus_incapacidad.id = aprobar ? 2 : 3;
    const resultIncapacidad = await dispatch(
      updateIncapacidadAsyncThunk({
        serverDate: serverDate.data,
        id: incapacidad.id,
        idCliente: clienteQentry.data.id,
        object: tempIncapacidad,
      })
    );
    await fetchIncapacidades();
  };

  const createDeviceChangeRequest = async (
    empleado: IEmpleado,
    id_telefono_autorizado_anterior: string,
    id_telefono_autorizado_nuevo: string,
    usuario_aprobador: IUser
  ) => {
    // const tempStatus = getEmptyCambioEstatusVacaciones();
    const temp = getEmptySolicitudCambioTelefonoAutorizado(); // getEmptyVacaciones();
    temp.empleado = empleado;
    temp.id_telefono_autorizado_anterior = id_telefono_autorizado_anterior;
    temp.id_telefono_autorizado_nuevo = id_telefono_autorizado_nuevo;
    temp.usuario_aprobador = usuario_aprobador;
    temp.estatus.id = 1;
    temp.estatus_sistema = true;
    temp.cliente_qentry = clienteQentry.data;

    const resultSolicitudCambioDispositivo = await dispatch(
      createSolicitudCambioTelefonoAutorizadoAsyncThunk({
        serverDate: serverDate.data,
        idCliente: clienteQentry.data.id,
        object: temp,
      })
    );
    console.log("Resultado create solicitud", resultSolicitudCambioDispositivo);
    await fetchSolicitudesCambioDispositivo();
  };

  const updateDeviceChangeRequest = async (
    solicitudCambio: ISolicitudCambioTelefonoAutorizado,
    aprobar: boolean
  ) => {
    const tempSolicitudCambioDispositivo =
      await setSolicitudCambioTelefonoAutorizado(
        solicitudCambio,
        serverDate.data
      );
    // const tempVacations = await setVacaciones(vacaciones, serverDate.data);
    tempSolicitudCambioDispositivo.usuario_aprobador.id =
      auth.empleado.usuario.id;
    tempSolicitudCambioDispositivo.estatus.id = aprobar ? 2 : 3;
    const resultSolicitudCambioDispositivo = await dispatch(
      updateSolicitudCambioTelefonoAutorizadoAsyncThunk({
        serverDate: serverDate.data,
        id: solicitudCambio.id,
        idCliente: clienteQentry.data.id,
        object: tempSolicitudCambioDispositivo,
      })
    );
    if (aprobar) {
      const resultUpdateIdTelefono = await dispatch(
        updateTelefonoAutorizadoAsyncThunk({
          idEmpleado: tempSolicitudCambioDispositivo.empleado.id,
          idTelefono:
            tempSolicitudCambioDispositivo.id_telefono_autorizado_nuevo,
        })
      );
      console.log("Resultado update id telefono", resultUpdateIdTelefono);
    }
    console.log("Resultado update solicitud", resultSolicitudCambioDispositivo);

    await fetchSolicitudesCambioDispositivo();
  };

  return [
    {
      /*
        THEME
      */
      theme,
      /*
        AUTH
      */
      auth,
      /*
        PERSONAS
      */
      // Modelos principales
      serverDate,
      location,
      clienteQentry,
      empresa,
      empleado,
      // Modelos multivaluados
      mvAntiguedad,
      mvBanco,
      mvEstadoCivil,
      mvEstatusSolicitudCambioTelefonoAutorizado,
      mvFormaDePagoSuscripcion,
      mvGenero,
      mvImpuestos,
      mvMotivoPagoSuscripcion,
      mvTipoCanalVenta,
      mvTipoDocumento,
      mvTipoEmpleado,
      mvTipoServicioQentry,
      mvTipoSuscripcion,
      mvTipoUsuario,
      mvUsoFactura,
      // Modelos funcionales
      canalVenta,
      centroDeTrabajo,
      codigoPostal,
      codigoQr,
      datosFiscales,
      datosPersonales,
      direccion,
      documento,
      estado,
      geolocalizacion,
      imagen,
      informacionBancaria,
      informacionContrato,
      informacionSuscripcion,
      modificacionSuscripcionQentry,
      modificadorNomina,
      pagoSuscripcion,
      pais,
      servicioQentry,
      solicitudCambioTelefonoAutorizado,
      suscripcionQentry,
      tipoDocumentosPorPuesto,
      user,

      /*
        Rondines
      */
      checkEnRuta,
      checkpointRondin,
      checkRondin,
      mvMotivoCancelacion,
      rondin,
      rondinCancelado,
      rondinCompletado,
      /*
        NOMINA
      */
      asistencia,
      cambioEstatusAsistencia,
      cambioEstatusIncapacidad,
      cambioEstatusVacaciones,
      horario,
      horasExtra,
      incapacidad,
      incidencia,
      mvEstatusAsistencia,
      mvEstatusIncapacidades,
      mvEstatusVacaciones,
      mvMotivoIncapacidad,
      mvMotivoNotificacion,
      mvMotivoVacaciones,
      mvRiesgoPuesto,
      mvTipoAsistencia,
      mvTipoContrato,
      mvTipoHorasExtra,
      mvTipoJornada,
      mvTipoMovimientoAsistencia,
      mvTipoNotificacion,
      notificaciones,
      puesto,
      tabuladorHorasExtra,
      turno,
      vacaciones,

      // Funciones
      fetchClienteQentry,
      fetchEmpresa,
      fetchEmpleado,
      fetchCentrosDeTrabajo,
      fetchRondines,
      fetchHorarios,
      fetchAsistencia,
      fetchNotifications,
      fetchVacations,
      fetchIncapacidades,
      fetchSolicitudesCambioDispositivo,
      createEmpresa,
      updateEmpresa,
      editDatosPersonales,
      addDatosFiscales,
      removeDatosFiscales,
      editDatosFiscales,
      addOrEditInformacionBancaria,
      editConfiguracion,
      createCentrosDeTrabajo,
      editCentrosDeTrabajo,
      addOrEditRondin,
      deleteRondin,
      updateHorarios,
      createVacations,
      createIncapacidades,
      createDeviceChangeRequest,
      updateVacations,
      updateIncapacidades,
      updateDeviceChangeRequest,
    },
  ];
}

export default ReduxData;
