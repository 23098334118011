import { formatDate, formatTime, getDateString, getDefaultTimeZone, isNotEmpty } from "../../api/helpers";
import { DEFAULT_STATE } from "../../enums/default_state";

export interface IServerDate {
  fecha_hora : string;
  zona_horaria : string;
}

export const getDefaultServerDate = () => {
  const temp : IServerDate = 
  {
    fecha_hora : getDateString(),
    zona_horaria : getDefaultTimeZone(),
  };
  return temp;
}


export interface IStatusServerDate {
  estado : DEFAULT_STATE,
  mensaje : string,
  data : IServerDate,
}

export const getEmptyStatusServerDate = () => {
  const temp : IStatusServerDate = 
  {
    estado : DEFAULT_STATE.loading,
    mensaje : "",
    data : getDefaultServerDate(),
  };
  return temp;
}

export const setServerDate = (
  object : any
) => {
  const temp = getDefaultServerDate();
  if (isNotEmpty(object.fecha_hora)) temp.fecha_hora = object.fecha_hora;
  if (isNotEmpty(object.zona_horaria)) temp.zona_horaria = object.zona_horaria;
  return temp;
}


export const getFecha = (serverDate : IServerDate) => getDateString(serverDate.fecha_hora);
export const getHora = (serverDate : IServerDate) => getDateString(serverDate.fecha_hora);
export const convertDateToZonaHoraria = (serverDate : IServerDate, date : string) => getDateString(serverDate.zona_horaria, date);
export const convertDateToZonaHorariaFormatted = (serverDate : IServerDate, date : string) => formatDate(getDateString(serverDate.zona_horaria, date));