/**
 * Métodos AsyncThunk para ser usados en los reducers y
 * los resultados a las llamadas http puedan ser usados para
 * actualizar el state en redux
 */

import { createAsyncThunk } from "@reduxjs/toolkit";
import { DEFAULT_STATE } from "../../enums/default_state";
import { getMvTipoJornada, getAllMvTipoJornada, updateMvTipoJornada, createMvTipoJornada, deleteMvTipoJornada } from "../../http/nomina";
import { getEmptyStatusMvTipoJornada, IMvTipoJornada } from "../../interfaces/nomina";
import { IServerDate } from "../../interfaces/time";

export const getMvTipoJornadaAsyncThunk = createAsyncThunk(
  'nomina/getMvTipoJornadaAsyncThunk',
  async (query : {serverDate : IServerDate, id: number}) => await getMvTipoJornada(query.serverDate, query.id)
    .then(async (result) => result)
    .catch(async (error) => getEmptyStatusMvTipoJornada(DEFAULT_STATE.error))  
);

export const getAllMvTipoJornadaAsyncThunk = createAsyncThunk(
  'nomina/getAllMvTipoJornadaAsyncThunk',
  async (queryAll : {serverDate : IServerDate}) => await getAllMvTipoJornada(queryAll.serverDate)
    .then(async (result) => result)
    .catch(async (error) => getEmptyStatusMvTipoJornada(DEFAULT_STATE.error))  
);

export const updateMvTipoJornadaAsyncThunk = createAsyncThunk(
  'nomina/updateMvTipoJornadaAsyncThunk',
  async (update: {serverDate : IServerDate, id: number, MvTipoJornada: IMvTipoJornada}) => await updateMvTipoJornada(update.serverDate, update.id, update.MvTipoJornada)
    .then(async (result) => result)
    .catch(async (error) => getEmptyStatusMvTipoJornada(DEFAULT_STATE.error))  
);

export const createMvTipoJornadaAsyncThunk = createAsyncThunk(
  'nomina/createMvTipoJornadaAsyncThunk',
  async (create : {serverDate : IServerDate, MvTipoJornada: IMvTipoJornada}) => await createMvTipoJornada(create.serverDate, create.MvTipoJornada)
    .then(async (result) => result)
    .catch(async (error) => getEmptyStatusMvTipoJornada(DEFAULT_STATE.error))  
);

export const deleteMvTipoJornadaAsyncThunk = createAsyncThunk(
  'nomina/deleteMvTipoJornadaAsyncThunk',
  async (del : {id : number}) => await deleteMvTipoJornada(del.id)
    .then(async (result) => result)
    .catch(async (error) => getEmptyStatusMvTipoJornada())  
);
