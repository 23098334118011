/** 
 * Componente que se encarga del elemento Rondin
 * @IRondin es la interfaz que contiene los parámetos el elemento Rondin
 * @getEmptyRondin  devuelve un objeto con los parámetros por defecto; @param {id}, opcional cuando no encuentra el objeto en el LocalSotrage
 * @IStatusRondin es la interfaz para los estatus a llamadas a la API; @param {status}, opcional cuando queremos otro estado de inizialización de tipo [DEFAULT_STATE]
 * @getEmptyStatusRondin devuelve un objeto con los parámetros por defecto,
 * @setRondin devuelve un objeto de tipo [IRondin] recibiendo @param {object} y @param {serverDate}, para verificar que los datos estén en el formato correcto y ajustarlos a la zona horaria actual
 * @getSerializableRondin recibe un @param {IRondin}, para devolver un objeto serizalizable para las peticiones de la API
 * */

import { getDateString, isNotEmpty, SERVER_TIME_ZONE } from "../../api/helpers";
import { DEFAULT_STATE } from "../../enums/default_state";
import { getStorageClienteQentryArrayItem, getStorageEmpleadoArrayItem, getStorageEmpresaArrayItem } from "../../localStorage/personas";
import { getStorageCheckpointRondinArrayItem } from "../../localStorage/rondines";
import { getEmptyClienteQentry, getEmptyEmpresa, IClienteQentry, IEmpleado, IEmpresa, setClienteQentry, setEmpleado, setEmpresa } from "../personas";
import { ILocalStorageExpiry, getEmptyLocalStorageExpiry, IServerDate, convertDateToZonaHoraria } from "../time";
import { ICheckpointRondin, setCheckpointRondin } from "./CheckpointRondin";

export interface IRondin {
  id : number,
  nombre_rondin : string,
  checkpoints : ICheckpointRondin[],
  empleados : IEmpleado[],
  distancia_maxima_permitida : string,
  empresa : IEmpresa,
  cliente_qentry : IClienteQentry,
  fecha_elaboracion : string, // Fecha
  estatus_sistema : boolean,
  ultima_actualizacion : string, // Fecha
}

export const getEmptyRondin = (id? : number) => {
  const temp : IRondin = 
  {
    id : id ? id : 0,
    nombre_rondin : "",
    checkpoints : [],
    empleados : [],
    distancia_maxima_permitida : "",
    empresa : getEmptyEmpresa(),
    cliente_qentry : getEmptyClienteQentry(),
    fecha_elaboracion : "",
    estatus_sistema : false,
    ultima_actualizacion : "",
  };
  return temp;
}

export interface IStatusRondin {
  estado : DEFAULT_STATE,
  mensaje : string,
  data : IRondin,
  data_array: IRondin[],
  expiry : ILocalStorageExpiry,
  expiry_array : ILocalStorageExpiry,
}

export const getEmptyStatusRondin = (status: DEFAULT_STATE = DEFAULT_STATE.loading) => {
  const temp : IStatusRondin = 
  {
    estado : status,
    mensaje : "",
    data : getEmptyRondin(),
    data_array : [],
    expiry : getEmptyLocalStorageExpiry("IRondin", 0),
    expiry_array : getEmptyLocalStorageExpiry("IRondinArray", 0),
  };
  return temp;
}

export const setRondin = async (
  object : any,
  serverDate : IServerDate,
) => {
  const temp = getEmptyRondin();
  if (isNotEmpty(object.id)) temp.id = object.id;
  if (isNotEmpty(object.nombre_rondin)) temp.nombre_rondin = object.nombre_rondin;
  if (isNotEmpty(object.checkpoints)) temp.checkpoints = (typeof object.checkpoints[0] === 'number') ? await Promise.all(object.checkpoints.map(async (e : number) => await getStorageCheckpointRondinArrayItem(e))) : await Promise.all(object.checkpoints.map(async (e : any) => await setCheckpointRondin(e, serverDate)));
  if (isNotEmpty(object.empleados)) temp.empleados = (typeof object.empleados[0] === 'number') ? await Promise.all(object.empleados.map(async (e : number) => await getStorageEmpleadoArrayItem(e))) : await Promise.all(object.empleados.map(async (e : any) => await setEmpleado(e, serverDate)));
  if (isNotEmpty(object.distancia_maxima_permitida)) temp.distancia_maxima_permitida = object.distancia_maxima_permitida;
  if (isNotEmpty(object.empresa)) temp.empresa = (typeof object.empresa === 'number') ? await getStorageEmpresaArrayItem(object.empresa) : await setEmpresa(object.empresa, serverDate);
  if (isNotEmpty(object.cliente_qentry)) temp.cliente_qentry = (typeof object.cliente_qentry === 'number') ? await getStorageClienteQentryArrayItem(object.cliente_qentry) : await setClienteQentry(object.cliente_qentry, serverDate);
  if (isNotEmpty(object.fecha_elaboracion)) temp.fecha_elaboracion = convertDateToZonaHoraria(serverDate, object.fecha_elaboracion);
  if (isNotEmpty(object.estatus_sistema)) temp.estatus_sistema = object.estatus_sistema;
  if (isNotEmpty(object.ultima_actualizacion)) temp.ultima_actualizacion = convertDateToZonaHoraria(serverDate, object.ultima_actualizacion);
  return temp;
}

export const getSerializableRondin = (IRondin : IRondin ) => {
  return {
    id : IRondin.id,
    nombre_rondin : IRondin.nombre_rondin,
    checkpoints : IRondin.checkpoints.map((e) => e.id),
    empleados : IRondin.empleados.map((e) => e.id),
    distancia_maxima_permitida : IRondin.distancia_maxima_permitida,
    empresa : IRondin.empresa.id,
    cliente_qentry : IRondin.cliente_qentry.id,
    fecha_elaboracion : IRondin.fecha_elaboracion,
    estatus_sistema : IRondin.estatus_sistema,
    ultima_actualizacion : getDateString(SERVER_TIME_ZONE),
  };
}