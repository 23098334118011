/**
 * Métodos para el manejo de IonicStorage 
 * @setStorage              guarda un unico objeto al storage
 * @getStorage              obtiene el objeto guardado con @setStorage
 * @setStorageArray         guarda el array de objetos en el storage
 * @getStorageArray         obtiene el array de objetos guardado con @setStorageArray
 * @removeStorageArray      elimina el array de objetos guardado con @setStorageArray
 * @setStorageItemArray     agrega un elemento al array de objeto guardado con @setStorageArray
 * @getStorageItemArray     obtiene un elemento del array guardado con @setStorageArray buscandolo por su id 
 * @removeStorageItemArray  elimina un elemento del array guardado con @setStorageArray buscandolo por su id 
 */

import { setStorageData, getStorageData, removeStorageData, setArrayItemStorageData, getArrayItemStorageData, removeArrayItemStorageData } from "../../api/storage";
import { IMvEstatusVacaciones, getEmptyMvEstatusVacaciones } from "../../interfaces/nomina";

export const setStorageMvEstatusVacaciones = async (IMvEstatusVacaciones : IMvEstatusVacaciones) => await setStorageData('IMvEstatusVacaciones', IMvEstatusVacaciones);
export const getStorageMvEstatusVacaciones = async () : Promise<IMvEstatusVacaciones> => await getStorageData('IMvEstatusVacaciones', getEmptyMvEstatusVacaciones());
export const removeStorageMvEstatusVacaciones = async () => await removeStorageData('IMvEstatusVacaciones');
export const setStorageMvEstatusVacacionesArray = async (IMvEstatusVacaciones : IMvEstatusVacaciones[]) => await setStorageData('IMvEstatusVacacionesArray', IMvEstatusVacaciones);
export const getStorageMvEstatusVacacionesArray = async () : Promise<IMvEstatusVacaciones[]> => await getStorageData('IMvEstatusVacacionesArray', [] as IMvEstatusVacaciones[]);
export const removeStorageMvEstatusVacacionesArray = async () => await removeStorageData('IMvEstatusVacacionesArray');
export const setStorageMvEstatusVacacionesArrayItem = async (id: number, IMvEstatusVacaciones : IMvEstatusVacaciones) : Promise<IMvEstatusVacaciones[]> => await setArrayItemStorageData('IMvEstatusVacacionesArray', id, IMvEstatusVacaciones, [] as IMvEstatusVacaciones[]);
export const getStorageMvEstatusVacacionesArrayItem = async (id: number) : Promise<IMvEstatusVacaciones> => await getArrayItemStorageData('IMvEstatusVacacionesArray', id, getEmptyMvEstatusVacaciones);
export const removeStorageMvEstatusVacacionesArrayItem = async (id: number) : Promise<IMvEstatusVacaciones[]> => await removeArrayItemStorageData('IMvEstatusVacacionesArray', id, [] as IMvEstatusVacaciones[]);
