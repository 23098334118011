/**
 * Modal usados para la creación, edicion de los centros de trabajo/codigos qr
 * @EditEmpleadoModal
 *   @sectionDatosPersonales
 *     @function onValidateDatosPersonales habilita o desabilita el componente error en los @component {IonItem} dependiendo del valor de su input
 *     @function disabledDatosPersonalesButton verifica que los campos estén el formato correcto para su actualización
 *     @function updateDatosPersonales  acomoda los datos escritos en un objeto @IDatosPersonales para la creación/actualización de los datos personales del trabajador
 *     @function searchCP busca el CP escrito en @createCentroDeTrabajoCodigoPostal y devuele los resultados como un alert para seleccionar uno.
 *     @function inititalCP obtiene el @param {IStatusCodigoPostal} listaCP para guardar el dato en @codigoPostal y su id en @hiddenCodigoPostalId
 *   @sectionDatosFicales
 *     @CreateDatosFiscalesModal
 *       @function acceptDisabled verifica que los campos estén el formato correcto para su actualización
 *       @function onValidate habilita o desabilita el componente error en los @component {IonItem} dependiendo del valor de su input
 *     @EditDatosFiscalesModal
 *       @function acceptDisabled verifica que los campos estén el formato correcto para su actualización
 *       @function onValidate habilita o desabilita el componente error en los @component {IonItem} dependiendo del valor de su input
 *   @sectionInformacionBancaria
 *     @function onValidateInformacionBancaria habilita o desabilita el componente error en los @component {IonItem} dependiendo del valor de su input
 *     @function updateInformacionBancaria acomoda los datos escritos en un objeto @IIformacionBancaria para la creación/actualización de la información bancaria del trabajador
 *   @sectionConfiguracion
 *     @function onValidateConfiguracion habilita o desabilita el componente error en los @component {IonItem} dependiendo del valor de su input
 *     @function updateConfiguracion  acomoda los datos escritos en un objeto @IEmpleado para la creación/actualización de los datos del trabajador
 * @CreateEmpleadoModal
 *   @function disableAceptar verifica que los campos estén el formato correcto para su actualización
 *   @function onValidate habilita o desabilita el componente error en los @component {IonItem} dependiendo del valor de su input
 *   @function searchCP busca el CP escrito en @createCentroDeTrabajoCodigoPostal y devuele los resultados como un alert para seleccionar uno.
 *   @function validate evía una cloud a @qentry3ValidatePhoneNumber para verificar el que formato sea el correcto
 */
import { Wrapper, Status } from "@googlemaps/react-wrapper";
import {
  IonPage,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonButton,
  IonTitle,
  IonContent,
  IonGrid,
  IonRow,
  IonCol,
  IonAccordionGroup,
  IonAccordion,
  IonItem,
  IonLabel,
  IonText,
  IonInput,
  IonPopover,
  IonDatetime,
  IonCard,
  IonCardContent,
  IonIcon,
  IonCheckbox,
  IonList,
  IonProgressBar,
  IonSearchbar,
  IonSpinner,
  useIonActionSheet,
  useIonModal,
  useIonPopover,
  IonCardTitle,
  useIonAlert,
  IonNote,
  useIonToast,
} from "@ionic/react";
import {
  addCircle,
  business,
  calendar,
  checkmark,
  chevronBackSharp,
  chevronForward,
  chevronForwardSharp,
  close,
  document as documentIcon,
  documentText,
  download,
  ellipsisVertical,
  filterOutline,
  flag,
  informationCircle,
  mail,
  pencil,
  person,
  phoneLandscape,
  phonePortrait,
  search,
  trash,
} from "ionicons/icons";
import { useEffect, useRef, useState } from "react";
import readXlsxFile from "read-excel-file";
import { useAppSelector } from "../hooks/useRedux";
import {
  getEmptyDatosFiscales,
  ICodigoQr,
  IDatosFiscales,
  IDatosPersonales,
  IEmpleado,
  setDatosPersonales,
} from "../redux/interfaces/personas";
import {
  getMemoizedTeamMembersSelectedGenero,
  getMemoizedTeamMembersSelectedEstadoCivil,
  ICreateEmpleado,
  ICreateEmpleadoData,
  IDefaultTable,
  IEmpleadoData,
  teamMembersInitialState,
  getMemoizedTeamMembersSelectedUsoFactura,
  getMemoizedTeamMembersSelectedBanco,
  getMemoizedTeamMembersSelectedEmpleados,
  getMemoizedEmpleadoWithExceptionAsSelectList,
  getMemoizedTeamMembersSelectedCodigoQr,
  getMemoizedTeamMembersSelectedTipoEmpleado,
  getMemoizedTeamMembersSelectedEstado,
  getMemoizedTeamMembersSelectedPais,
} from "../redux/slices/pagesSlice/teamMembersSlice";
import {
  getGlobalMemoizedMvGeneroAsSelectList,
  getGlobalMemoizedMvEstadoCivilAsSelectList,
  getGlobalMemoizedEmpresaAsSelectList,
  getGlobalMemoizedMvUsoFacturaAsSelectList,
  getGlobalMemoizedMvBancoAsSelectList,
  getMemoizedCodigoQrList,
  getGlobalMemoizedMvTipoEmpleadoAsSelectList,
  getGlobalMemoizedEstadoAsSelectList,
  getGlobalMemoizedPaisAsSelectList,
} from "../redux/slices/personasSlice";
import { qentry3ValidateUsers } from "../storage/UserApi";
import Selector from "./Selector";
import useRedux from "../hooks/useRedux";
import { DEFAULT_STATE } from "../redux/enums/default_state";
import { getCodigoPostal, searchCodigoPostal } from "../redux/http/personas";
import Map from "./Map";
import { StatusIcon } from "./GoogleMapsV3";
import Marker from "./Maker";
import { List, setListItem } from "../redux/interfaces/helpers";
import { getOnlyDateString, getTimeString, sleep } from "../redux/api/helpers";

const EditEmpleadoModal: React.FC<{
  onDismiss: (data?: any, role?: string | undefined) => void;
  accept?: (data: IEmpleado) => void;
}> = ({ accept, onDismiss }) => {
  const [
    {
      serverDate,
      auth,
      mvBanco,
      mvUsoFactura,
      mvGenero,
      mvEstadoCivil,
      mvTipoEmpleado,
      codigoQr,
      empleado,
      user,
      datosPersonales,
      informacionBancaria,
      estado,
      pais,
      clienteQentry,
      location: empleadoLocation,
      addDatosFiscales,
      removeDatosFiscales,
      editDatosFiscales,
      editDatosPersonales,
      addOrEditInformacionBancaria,
      editConfiguracion,
    },
  ] = useRedux();
  const [presentActionSheet] = useIonActionSheet();
  const [presentToast, dismissToast] = useIonToast();
  const empleadoModal = useAppSelector(
    (state) => state.teamMembers.empleadoModal
  );
  // Mapa
  const [searchText, setSearchText] = useState(""); // initial zoom
  const [zoom, setZoom] = useState(10); // initial zoom
  const [center, setCenter] = useState<google.maps.LatLngLiteral>({
    lat: 19.42847,
    lng: -99.12766,
  });
  const [currentLocation, setCurrentLocation] = useState<google.maps.LatLng>(
    new google.maps.LatLng({
      lat: empleadoLocation.latitud,
      lng: empleadoLocation.longitud,
    })
  );
  const [places, setPlaces] = useState<google.maps.LatLng[]>([]);
  const [findPlace, setFindPlace] = useState<google.maps.Geocoder>();
  const [mapLoading, setMapLoading] = useState(true);

  const render = (status: Status) => {
    if (status == Status.SUCCESS) setMapLoading(false);
  };

  const addressComponent = (
    geocoderResult: google.maps.GeocoderResult,
    componentType: string
  ) => {
    if (
      !geocoderResult.address_components ||
      geocoderResult.address_components.length === 0
    )
      return "";
    const res = geocoderResult.address_components.find((e) =>
      e.types.includes(componentType)
    );
    if (!res) return "";
    return res.long_name;
  };

  const postalCode = (place: google.maps.GeocoderResult) => {
    if (!place.address_components || place.address_components.length === 0)
      return "";
    const res = place.address_components.find((e) =>
      e.types.includes("postal_code")
    );
    if (!res) return "";
    return res.long_name;
  };

  const onClick = (e: google.maps.MapMouseEvent) => {
    // avoid directly mutating state
    console.log("dentro onClick e", e);

    setPlaces([e.latLng!]);
    setCurrentLocation(e.latLng!);
    setCenter({ lat: e.latLng!?.lat(), lng: e.latLng!?.lng() });
    setZoom(20);
    fillAddressFromCurrentPosition(e.latLng!);
  };

  const fillAddressFromCurrentPosition = (position: google.maps.LatLng) => {
    const newGeocoder = new google!.maps!.Geocoder();
    console.log(
      "newGeocoder dentro de fillAddressFromCurrentPosition",
      newGeocoder
    );

    // onPlaceAdded([value]);
    //if (findPlace === undefined) setFindPlace(newGeocoder);
    console.log("Despues de findPlace fillAddressFromCurrentPosition");

    sleep(200).then(() => {
      console.log("Dentro de sleep fillAddressFromCurrentPosition", findPlace);

      newGeocoder
        ? newGeocoder.geocode({ location: position }, async (result) => {
            console.log(
              "Dentro de geocode de fillAddressFromCurrentPosition",
              result
            );

            if (!result) {
              presentToast({
                message:
                  "No se pudo obtener la dirección del punto marcado. Por favor agréguelo manualmente",
                color: "warning",
                duration: 2500,
              });
              return;
            }
            newGeocoder.geocode(
              { placeId: result[0].place_id, componentRestrictions: null },
              async (finalResult) => {
                console.log(
                  "finalResult fillAddressFromCurrentPosition",
                  finalResult
                );

                if (!finalResult) {
                  presentToast({
                    message:
                      "No pudo obtener la dirección a traves del id del punto marcado, por favor agréguelo manualmente",
                    color: "warning",
                    duration: 2500,
                  });
                  return;
                }
                console.log({ finalResult });
                const numExteriorAddress = addressComponent(
                  finalResult[0],
                  "street_number"
                );
                const calleAddress = addressComponent(finalResult[0], "route");
                const coloniaAddress = addressComponent(
                  finalResult[0],
                  "sublocality_level_1"
                );
                const ciudadAddress = addressComponent(
                  finalResult[0],
                  "locality"
                );
                const entidadAddress = addressComponent(
                  finalResult[0],
                  "administrative_area_level_1"
                );
                const paisAddress = addressComponent(finalResult[0], "country");
                const cpAddress = addressComponent(
                  finalResult[0],
                  "postal_code"
                );

                if (!cpAddress) {
                  setValidCP(false);
                  presentToast({
                    message:
                      "La ubicación marcada no tiene un código postal, por favor agréguelo manualmente",
                    color: "warning",
                    duration: 2500,
                  });
                  return;
                }

                const estadoValue = estado.data_array.find((e) =>
                  e.nombre.includes(entidadAddress)
                )!;
                const paisValue = pais.data_array.find(
                  (e) =>
                    e.nombre.includes("México") || e.nombre.includes("Mexico")
                )!;

                setEstado(
                  setListItem({
                    id: estadoValue.id.toString(),
                    title: estadoValue.nombre,
                  })
                );
                setPais(
                  setListItem({
                    id: paisValue.id.toString(),
                    title: paisValue.nombre,
                  })
                );

                setNumeroExterior(numExteriorAddress);
                setNombreCalle(calleAddress);
                setNombreColonia(coloniaAddress);
                setCiudad(ciudadAddress);
                setCodigoPostal(cpAddress);

                setSearchText(
                  `${cpAddress} ${estadoValue.nombre} ${paisValue.nombre}`
                );

                console.log(
                  "searchText useEffect fillAddressFromCurrentPosition",
                  searchText
                );

                const findedCP = await getCpIfExist(
                  cpAddress,
                  ciudadAddress !== "" ? coloniaAddress : ciudadAddress
                );
                console.log("Codigo encontrado", findedCP);

                if (findedCP) {
                  setHiddenCodigoPostalId(findedCP.id);
                  setDelegacionMunicipio(findedCP.nombre_municipio);
                  setNombreColonia(findedCP.nombre_asentamiento);
                  setCiudad(findedCP.nombre_ciudad);
                }
                setValidCP(true);
              }
            );
          })
        : console.log("noooooo newGeocoder");
    });
    console.log("Despues de sleep");
  };

  const onPlaceAdded = (e: google.maps.LatLng[]) => {
    console.log("Dentro de onPlaceAdded", e);

    setPlaces([...e]);
    setCurrentLocation(e[0]);
    if (!findPlace) setFindPlace(new google.maps.Geocoder());
    sleep(200).then(() => {
      findPlace?.geocode({ location: e[0] }, (result) => {
        console.log("geocode result onPlaceAdded", result);

        if (!result) {
          presentToast({
            message:
              "No se pudo obtener la dirección del punto marcado. Por favor agréguelo manualmente",
            color: "warning",
            duration: 2500,
          });
          return;
        }
        findPlace.geocode(
          { placeId: result[0].place_id, componentRestrictions: null },
          (finalResult) => {
            if (!finalResult) {
              presentToast({
                message:
                  "No pudo obtener la dirección a través del id del punto marcado, por favor agréguelo manualmente",
                color: "warning",
                duration: 2500,
              });
              return;
            }
            const cpAddress = addressComponent(finalResult[0], "postal_code");

            if (!cpAddress) {
              presentToast({
                message:
                  "La ubicación marcada no tiene un código postal, por favor agréguelo manualmente",
                color: "warning",
                duration: 2500,
              });
              return;
            }
            setCodigoPostal(cpAddress);
            searchCP(false);
          }
        );
      });
    });
    setValidCP(true);
  };

  const onPlacesDrag = (e: google.maps.MapMouseEvent) => {
    setPlaces([e.latLng!]);
    setCurrentLocation(e.latLng!);
    sleep(200).then(() => {
      findPlace?.geocode({ location: e.latLng }, (result) => {
        if (!result) {
          presentToast({
            message:
              "La ubicación marcada no tiene un código postal, por favor agréguelo manualmente",
            color: "warning",
            duration: 2500,
          });
          return;
        }
        findPlace.geocode(
          { placeId: result[0].place_id, componentRestrictions: null },
          (finalResult) => {
            if (!finalResult) {
              presentToast({
                message:
                  "La ubicación marcada no tiene un código postal, por favor agréguelo manualmente",
                color: "warning",
                duration: 2500,
              });
              return;
            }
            const cp = postalCode(finalResult[0]);
            if (!cp) {
              presentToast({
                message:
                  "La ubicación marcada no tiene un código postal, por favor agréguelo manualmente",
                color: "warning",
                duration: 2500,
              });
              return;
            }
            setCodigoPostal(cp);
            searchCP(false);
          }
        );
      });
    });
    setValidCP(true);
  };

  const onIdle = (m: google.maps.Map) => {
    if (m.getZoom()) setZoom(m.getZoom()!);
    if (m.getCenter()) setCenter(m.getCenter()!.toJSON());
  };

  // Datos Personales
  const defaultDatosPersonalesErrorMessages = [
    "Por favor un ingrese un nombre válido.",
    "Por favor un ingrese un apellido válido.",
    "Por favor un ingrese un teléfono válido.",
    "Por favor un ingrese un código postal válido.",
    "Por favor un ingrese una calle.",
    "Por favor un ingrese un numero exterior.",
    "Por favor un ingrese una colonia.",
    "Por favor un ingrese una ciudad.",
  ];
  const [showDatosPersonalesErrorMessage, setShowDatosPersonalesErrorMessage] =
    useState<{ estatus: boolean; msg: string }[]>(
      defaultDatosPersonalesErrorMessages.map((v) => ({
        estatus: false,
        msg: v,
      }))
    );
  const [validCP, setValidCP] = useState<boolean>(true);
  const [hiddenCodigoPostalId, setHiddenCodigoPostalId] = useState<number>(1);

  const empleadoModalDatosPersonalesGenero = useAppSelector(
    getMemoizedTeamMembersSelectedGenero
  );
  const empleadoModalDatosPersonalesEstadoCivil = useAppSelector(
    getMemoizedTeamMembersSelectedEstadoCivil
  );
  const mvGeneroList = useAppSelector(getGlobalMemoizedMvGeneroAsSelectList);
  const mvEstadoCivilList = useAppSelector(
    getGlobalMemoizedMvEstadoCivilAsSelectList
  );
  const estadoList = useAppSelector(getGlobalMemoizedEstadoAsSelectList);
  const estadoModalConfiguracionEstado = useAppSelector(
    getMemoizedTeamMembersSelectedEstado
  );
  const paisList = useAppSelector(getGlobalMemoizedPaisAsSelectList);
  const paisModalConfiguracionPais = useAppSelector(
    getMemoizedTeamMembersSelectedPais
  );

  const [primerNombre, setPrimerNombre] = useState(
    empleadoModal.datos_personales.primer_nombre
  );
  const [segundoNombre, setSegundoNombre] = useState(
    empleadoModal.datos_personales.segundo_nombre
  );
  const [apellidoPaterno, setApellidoPaterno] = useState(
    empleadoModal.datos_personales.apellido_paterno
  );
  const [apellidoMaterno, setApellidoMaterno] = useState(
    empleadoModal.datos_personales.apellido_materno
  );
  const [fechaDeNacimiento, setFechaDeNacimiento] = useState(
    empleadoModal.datos_personales.fecha_nacimiento
  );
  const [edad, setEdad] = useState(empleadoModal.datos_personales.edad);
  const [curp, setCURP] = useState(
    empleadoModal.datos_personales.numero_identidad_CURP
  );
  const [correo, setCorreo] = useState(empleadoModal.usuario.username);
  const [telefonoCasa, setTelefonoCasa] = useState(
    empleadoModal.datos_personales.telefono_casa
  );
  const [telefonoMovil, setTelefonoMovil] = useState(
    empleadoModal.datos_personales.telefono_movil
  );
  const [genero, setGenero] = useState(empleadoModalDatosPersonalesGenero);
  const [estadoCivil, setEstadoCivil] = useState(
    empleadoModalDatosPersonalesEstadoCivil
  );
  const [codigoPostal, setCodigoPostal] = useState(
    empleadoModal.datos_personales.direccion.codigo_postal.codigo_postal
  );
  const [calle, setNombreCalle] = useState(
    empleadoModal.datos_personales.direccion.calle
  );
  const [numeroExterior, setNumeroExterior] = useState(
    empleadoModal.datos_personales.direccion.numero_exterior
  );
  const [numeroInterior, setNumeroInterior] = useState(
    empleadoModal.datos_personales.direccion.numero_interior
  );
  const [colonia, setNombreColonia] = useState(
    empleadoModal.datos_personales.direccion.colonia
  );
  const [ciudad, setCiudad] = useState(
    empleadoModal.datos_personales.direccion.ciudad
  );
  const [delegacionMunicipio, setDelegacionMunicipio] = useState(
    empleadoModal.datos_personales.direccion.delegacion_municipio
  );
  const [datosPersonalesEstado, setEstado] = useState(
    estadoModalConfiguracionEstado
  );
  const [datosPersonalesPais, setPais] = useState(paisModalConfiguracionPais);

  const itemPrimerNombre = useRef<HTMLIonItemElement>(null);
  const itemApellidoPaterno = useRef<HTMLIonItemElement>(null);
  const itemTelefonoCasa = useRef<HTMLIonItemElement>(null);
  const inputPrimerNombre = useRef<HTMLIonInputElement>(null);
  const inputApellidoPaterno = useRef<HTMLIonInputElement>(null);
  const inputTelefonoCasa = useRef<HTMLIonInputElement>(null);
  const itemCodigoPostal = useRef<HTMLIonItemElement>(null);
  const itemCalle = useRef<HTMLIonItemElement>(null);
  const itemNumeroExterior = useRef<HTMLIonItemElement>(null);
  const itemColonia = useRef<HTMLIonItemElement>(null);
  const itemCiudad = useRef<HTMLIonItemElement>(null);

  const [presentCodigoPostalList, dismissCodigoPostalList] =
    useIonActionSheet();
  const [presentAlert] = useIonAlert();

  const onValidateDatosPersonales = (id: number, value: any) => {
    if (!itemPrimerNombre.current) return;
    if (!itemApellidoPaterno.current) return;
    if (!itemTelefonoCasa.current) return;
    if (!itemCodigoPostal.current) return;
    if (!itemCalle.current) return;
    if (!itemNumeroExterior.current) return;
    if (!itemColonia.current) return;
    if (!itemCiudad.current) return;

    switch (id) {
      case 0:
        setPrimerNombre(value);
        const testPrimerNombre = !/.+/.test(value.trim());
        if (testPrimerNombre) itemPrimerNombre.current.classList.add("error");
        else itemPrimerNombre.current.classList.remove("error");
        setShowDatosPersonalesErrorMessage(
          showDatosPersonalesErrorMessage.map((e, i) => {
            if (i !== id) return e;
            return { estatus: testPrimerNombre, msg: e.msg };
          })
        );
        break;
      case 1:
        setApellidoPaterno(value);
        const testApellidoPaterno = !/.+/.test(value.trim());
        if (testApellidoPaterno)
          itemApellidoPaterno.current.classList.add("error");
        else itemApellidoPaterno.current.classList.remove("error");
        setShowDatosPersonalesErrorMessage(
          showDatosPersonalesErrorMessage.map((e, i) => {
            if (i !== id) return e;
            return { estatus: testApellidoPaterno, msg: e.msg };
          })
        );
        break;
      case 2:
        setTelefonoCasa(value);
        const testTelefonoCasa =
          !/^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/.test(value.trim()) &&
          /.+/.test(value.trim());
        if (testTelefonoCasa) itemTelefonoCasa.current.classList.add("error");
        else itemTelefonoCasa.current.classList.remove("error");
        setShowDatosPersonalesErrorMessage(
          showDatosPersonalesErrorMessage.map((e, i) => {
            if (i !== id) return e;
            return { estatus: testTelefonoCasa, msg: e.msg };
          })
        );
        break;
      case 3:
        setValidCP(false);
        setCodigoPostal(value);
        const testItemCodigoPostal = !/[0-9]+/.test(value.trim());
        if (testItemCodigoPostal)
          itemCodigoPostal.current.classList.add("error");
        else itemCodigoPostal.current.classList.remove("error");
        setShowDatosPersonalesErrorMessage(
          showDatosPersonalesErrorMessage.map((e, i) => {
            if (i !== id) return e;
            return { estatus: testItemCodigoPostal, msg: e.msg };
          })
        );
        break;
      case 4:
        setNombreCalle(value);
        const testItemCalle = !/.+/.test(value.trim());
        if (testItemCalle) itemCalle.current.classList.add("error");
        else itemCalle.current.classList.remove("error");
        setShowDatosPersonalesErrorMessage(
          showDatosPersonalesErrorMessage.map((e, i) => {
            if (i !== id) return e;
            return { estatus: testItemCalle, msg: e.msg };
          })
        );
        break;
      case 5:
        setNumeroExterior(value);
        const testItemNumeroExterior = !/.+/.test(value.trim());
        if (testItemNumeroExterior)
          itemNumeroExterior.current.classList.add("error");
        else itemNumeroExterior.current.classList.remove("error");
        setShowDatosPersonalesErrorMessage(
          showDatosPersonalesErrorMessage.map((e, i) => {
            if (i !== id) return e;
            return { estatus: testItemNumeroExterior, msg: e.msg };
          })
        );
        break;
      case 6:
        setNombreColonia(value);
        const testItemColonia = !/.+/.test(value.trim());
        if (testItemColonia) itemColonia.current.classList.add("error");
        else itemColonia.current.classList.remove("error");
        setShowDatosPersonalesErrorMessage(
          showDatosPersonalesErrorMessage.map((e, i) => {
            if (i !== id) return e;
            return { estatus: testItemColonia, msg: e.msg };
          })
        );
        break;
      case 7:
        setCiudad(value);
        const testItemCiudad = !/.+/.test(value.trim());
        if (testItemCiudad) itemCiudad.current.classList.add("error");
        else itemCiudad.current.classList.remove("error");
        setShowDatosPersonalesErrorMessage(
          showDatosPersonalesErrorMessage.map((e, i) => {
            if (i !== id) return e;
            return { estatus: testItemCiudad, msg: e.msg };
          })
        );
        break;
    }
  };

  const disabledDatosPersonalesButton = () => {
    const hasErrors = showDatosPersonalesErrorMessage.some((e) => e.estatus);
    if (hasErrors) return true;
    if (!primerNombre || !primerNombre.trim()) return true;
    if (!apellidoPaterno || !apellidoPaterno.trim()) return true;
    if (!currentLocation) return true;
    if (!validCP) return true;
    return false;
  };

  const updateDatosPersonales = async () => {
    const idAutor = auth.empleado.usuario.id;
    const autor = user.data_array.find((e) => e.id === idAutor)!;
    const temp = {
      ...(await setDatosPersonales(
        empleadoModal.datos_personales,
        serverDate.data
      )),
    };
    temp.primer_nombre = primerNombre;
    temp.segundo_nombre = segundoNombre;
    temp.apellido_paterno = apellidoPaterno;
    temp.apellido_materno = apellidoMaterno;
    temp.fecha_nacimiento = fechaDeNacimiento;
    temp.edad = edad;
    temp.numero_identidad_CURP = curp;
    temp.email = empleadoModal.usuario.username;
    temp.telefono_casa = telefonoCasa;
    temp.genero = mvGenero.data_array.find(
      (e) => e.id.toString() === genero.id
    )!;
    temp.estado_civil = mvEstadoCivil.data_array.find(
      (e) => e.id.toString() === estadoCivil.id
    )!;
    // Dirección
    temp.direccion.geolocalizacion.latitud = places[0].lat().toString();
    temp.direccion.geolocalizacion.longitud = places[0].lng().toString();
    temp.direccion.geolocalizacion.estatus_sistema = true;
    temp.direccion.geolocalizacion.autor = autor;
    temp.direccion.geolocalizacion.cliente_qentry = clienteQentry.data;
    temp.direccion.numero_contacto = temp.telefono_movil;
    temp.direccion.codigo_postal.id = hiddenCodigoPostalId;
    temp.direccion.calle = calle;
    temp.direccion.numero_exterior = numeroExterior;
    temp.direccion.numero_interior = numeroInterior;
    temp.direccion.colonia = colonia;
    temp.direccion.ciudad = ciudad;
    temp.direccion.delegacion_municipio = delegacionMunicipio;
    temp.direccion.estado = estado.data_array.find(
      (e) => e.id.toString() === datosPersonalesEstado.id
    )!;
    temp.direccion.pais = pais.data_array.find(
      (e) => e.id.toString() === datosPersonalesPais.id
    )!;
    temp.direccion.estatus_sistema = true;
    temp.direccion.autor = autor;
    temp.direccion.cliente_qentry = clienteQentry.data;

    await editDatosPersonales(empleadoModal.id, temp, empleadoModal);
    setDatosFiscales(getEmptyDatosFiscales());
  };

  const removeAccents = (str: string) => {
    return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
  };

  const getCpIfExist = async (codigoPostal: string, colonia: string) => {
    console.log("Dentro de getCpIfExist");

    console.log("Codigo a buscar", codigoPostal, "Colonia a buscar", colonia);
    const listaCP = await searchCodigoPostal(codigoPostal);
    console.log("lista de codigos encontrados", listaCP);
    return listaCP.length === 0
      ? undefined
      : listaCP.length === 1
      ? listaCP[0]
      : listaCP.find((itemCp) =>
          removeAccents(itemCp.nombre_asentamiento).includes(colonia)
        );
  };

  const searchCP = async (autosearch = true) => {
    setValidCP(false);
    if (!codigoPostal.length) {
      return presentAlert({
        header: "Aviso",
        message: "Código postal vacío",
        buttons: ["OK"],
      });
    }
    const listaCP = await searchCodigoPostal(codigoPostal);
    if (listaCP.length === 0) {
      return presentAlert({
        header: "Aviso",
        message: "No se encontró el código postal",
        buttons: ["OK"],
      });
    }
    return presentCodigoPostalList({
      buttons: [
        ...listaCP.map((cpItem) => ({
          text: `${cpItem.codigo_postal}, ${cpItem.nombre_asentamiento}`,
          handler: () => {
            const estadoValue = estado.data_array.find(
              (e) => e.nombre === cpItem.nombre_entidad
            )!;
            const paisValue = pais.data_array.find(
              (e) => e.nombre.includes("México") || e.nombre.includes("Mexico")
            )!;

            setHiddenCodigoPostalId(cpItem.id);
            setNombreColonia(cpItem.nombre_asentamiento);
            setCiudad(cpItem.nombre_ciudad);
            setDelegacionMunicipio(cpItem.nombre_municipio);
            setEstado(
              setListItem({
                id: estadoValue.id.toString(),
                title: estadoValue.nombre,
              })
            );
            setPais(
              setListItem({
                id: paisValue.id.toString(),
                title: paisValue.nombre,
              })
            );
            if (autosearch)
              setSearchText(
                `${codigoPostal} ${estadoValue.nombre} ${paisValue.nombre}`
              );
            setValidCP(true);
          },
        })),
        { text: "Cancelar", role: "cancel" },
      ],
      header: "Seleccionar código postal",
    });
  };

  const inititalCP = async () => {
    setValidCP(false);
    console.log("Dentro de inititalCP empleadoLocation", { empleadoLocation });
    console.log({ cp: empleadoModal.datos_personales.direccion.codigo_postal });
    const listaCP = await getCodigoPostal(
      serverDate.data,
      empleadoModal.datos_personales.direccion.codigo_postal.id
    );
    console.log({ cp1: listaCP.data });
    if (listaCP.data.id !== 0) {
      setValidCP(true);
      setCodigoPostal(listaCP.data.codigo_postal);
    }
  };

  useEffect(() => {
    // inititalCP();
    const load = async () => {
      if (!mapLoading) {
        let location = {
          lat: empleadoLocation.latitud,
          lng: empleadoLocation.longitud,
        };
        let isNew = true;
        if (empleadoModal.datos_personales.direccion.geolocalizacion.id !== 0) {
          console.log("Empleado si tiene geolocalizacin");

          location.lat = Number(
            empleadoModal.datos_personales.direccion.geolocalizacion.latitud
          );
          location.lng = Number(
            empleadoModal.datos_personales.direccion.geolocalizacion.longitud
          );
          isNew = false;
          inititalCP();
        }
        console.log("location dentro de useEffect", location);

        // const location = {
        //   lat: Number(
        //     empleadoModal.datos_personales.direccion.geolocalizacion.latitud
        //   ),
        //   lng: Number(
        //     empleadoModal.datos_personales.direccion.geolocalizacion.longitud
        //   ),
        // };
        const value = new google!.maps!.LatLng(location);
        setCurrentLocation(value);
        setPlaces([value]);
        setCenter(location);
        setZoom(20);
        if (isNew) {
          fillAddressFromCurrentPosition(value);
        }
      }
    };
    // inititalCP();
    load();
  }, [mapLoading]);

  // Datos Fiscales
  const [datosFiscales, setDatosFiscales] = useState<IDatosFiscales>(
    getEmptyDatosFiscales()
  );

  const CreateDatosFiscalesModal: React.FC<{
    onDismiss: (data?: any, role?: string | undefined) => void;
    accept?: (data: IDatosFiscales) => void;
  }> = ({ accept, onDismiss }) => {
    const defaultErrorMessages = [
      "Por favor un ingrese un RFC o TaxID válido.",
      "Por favor un ingrese un Nombre o Razón Social válido.",
    ];
    const [showErrorMessage, setShowErrorMessage] = useState<
      { estatus: boolean; msg: string }[]
    >(defaultErrorMessages.map((v) => ({ estatus: false, msg: v })));

    const mvUsoFacturaList = useAppSelector(
      getGlobalMemoizedMvUsoFacturaAsSelectList
    );
    const empleadoModalDatosDatosFiscalesMvUsoFactura = useAppSelector(
      (state) =>
        getMemoizedTeamMembersSelectedUsoFactura(state, datosFiscales.id)
    );

    const [taxId, setTaxId] = useState<string>(datosFiscales.tax_id);
    const [nombreORazonSocial, setNombreORazonSocial] = useState<string>(
      datosFiscales.nombre_o_razon_social
    );
    const [usoFactura, setUsoFactura] = useState(
      empleadoModalDatosDatosFiscalesMvUsoFactura
    );

    const itemTaxId = useRef<HTMLIonItemElement>(null);
    const itemNombreORazonSocial = useRef<HTMLIonItemElement>(null);

    const onValidate = (id: number, value: any) => {
      if (!itemTaxId.current) return;
      if (!itemNombreORazonSocial.current) return;

      switch (id) {
        case 0:
          setTaxId(value);
          const testTaxId = !/.+/.test(value.trim());
          if (testTaxId) itemTaxId.current.classList.add("error");
          else itemTaxId.current.classList.remove("error");
          setShowErrorMessage(
            showErrorMessage.map((e, i) => {
              if (i !== id) return e;
              return { estatus: testTaxId, msg: e.msg };
            })
          );
          break;
        case 1:
          setNombreORazonSocial(value);
          const testNombreORazonSocial = !/.+/.test(value.trim());
          if (testNombreORazonSocial)
            itemNombreORazonSocial.current.classList.add("error");
          else itemNombreORazonSocial.current.classList.remove("error");
          setShowErrorMessage(
            showErrorMessage.map((e, i) => {
              if (i !== id) return e;
              return { estatus: testNombreORazonSocial, msg: e.msg };
            })
          );
          break;
      }
    };

    const acceptDisabled = () => {
      return (
        showErrorMessage.some((e) => e.estatus) || !taxId || !nombreORazonSocial
      );
    };

    return (
      <IonPage>
        <IonHeader>
          <IonToolbar color="secondary" className="toolBarStart">
            <IonButtons slot="start">
              <IonButton onClick={() => onDismiss()}>Cancelar</IonButton>
            </IonButtons>
            <IonTitle className="toolBarStart center">Datos Fiscales</IonTitle>
            <IonButtons slot="end">
              <IonButton
                strong={true}
                disabled={acceptDisabled()}
                onClick={async () => {
                  const idAutor = auth.empleado.usuario.id;
                  const temp = getEmptyDatosFiscales();
                  temp.tax_id = taxId;
                  temp.nombre_o_razon_social = nombreORazonSocial;
                  temp.uso_factura = mvUsoFactura.data_array.find(
                    (e) => e.id.toString() === usoFactura.id
                  )!;
                  temp.autor = user.data_array.find((e) => e.id === idAutor)!;
                  temp.estatus_sistema = true;
                  if (accept) {
                    accept(temp);
                  }
                }}
              >
                Aceptar
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent className="ion-padding">
          <IonGrid>
            <IonRow>
              <IonCol
                sizeXl="6"
                sizeLg="6"
                sizeMd="6"
                sizeSm="6"
                sizeXs="12"
                size="12"
              >
                <IonItem className="modal-input" ref={itemTaxId}>
                  <IonIcon
                    slot="start"
                    icon={documentIcon}
                    class="icon-input-margin"
                  />
                  <IonLabel position="floating" className="modal-label">
                    RFC o TaxID <IonText color="danger">*</IonText>
                  </IonLabel>
                  <IonInput
                    clearInput={true}
                    inputmode="text"
                    type="text"
                    color="secondary"
                    value={taxId}
                    onIonChange={(e) => onValidate(0, e.detail.value)}
                  />
                </IonItem>
                {showErrorMessage[0].estatus ? (
                  <IonText class="input-error-message">
                    {showErrorMessage[0].msg}
                  </IonText>
                ) : (
                  <></>
                )}
              </IonCol>
              <IonCol
                sizeXl="6"
                sizeLg="6"
                sizeMd="6"
                sizeSm="6"
                sizeXs="12"
                size="12"
              >
                <IonItem className="modal-input" ref={itemNombreORazonSocial}>
                  <IonIcon
                    slot="start"
                    icon={documentIcon}
                    class="icon-input-margin"
                  />
                  <IonLabel position="floating" className="modal-label">
                    Nombre o Razón Social <IonText color="danger">*</IonText>
                  </IonLabel>
                  <IonInput
                    clearInput={true}
                    inputmode="text"
                    type="text"
                    color="secondary"
                    value={nombreORazonSocial}
                    onIonChange={(e) => onValidate(1, e.detail.value)}
                  />
                </IonItem>
                {showErrorMessage[1].estatus ? (
                  <IonText class="input-error-message">
                    {showErrorMessage[1].msg}
                  </IonText>
                ) : (
                  <></>
                )}
              </IonCol>
              <IonCol
                sizeXl="6"
                sizeLg="6"
                sizeMd="6"
                sizeSm="6"
                sizeXs="12"
                size="12"
              >
                <Selector
                  classStyle="modal-input"
                  value={usoFactura}
                  label="Uso factura"
                  list={mvUsoFacturaList}
                  change={setUsoFactura}
                  required={true}
                  icon={documentIcon}
                />
              </IonCol>
            </IonRow>
          </IonGrid>
          <IonItem lines="none" className="modal-input">
            <IonLabel>
              <IonText color="danger">*</IonText> Campos requeridos
            </IonLabel>
          </IonItem>
        </IonContent>
      </IonPage>
    );
  };

  const EditDatosFiscalesModal: React.FC<{
    onDismiss: (data?: any, role?: string | undefined) => void;
    accept?: (data: IDatosFiscales) => void;
  }> = ({ accept, onDismiss }) => {
    const defaultErrorMessages = [
      "Por favor un ingrese un RFC o TaxID válido.",
      "Por favor un ingrese un Nombre o Razón Social válido.",
    ];
    const [showErrorMessage, setShowErrorMessage] = useState<
      { estatus: boolean; msg: string }[]
    >(defaultErrorMessages.map((v) => ({ estatus: false, msg: v })));

    const mvUsoFacturaList = useAppSelector(
      getGlobalMemoizedMvUsoFacturaAsSelectList
    );
    const empleadoModalDatosDatosFiscalesMvUsoFactura = useAppSelector(
      (state) =>
        getMemoizedTeamMembersSelectedUsoFactura(state, datosFiscales.id)
    );

    const [taxId, setTaxId] = useState<string>(datosFiscales.tax_id);
    const [nombreORazonSocial, setNombreORazonSocial] = useState<string>(
      datosFiscales.nombre_o_razon_social
    );
    const [usoFactura, setUsoFactura] = useState(
      empleadoModalDatosDatosFiscalesMvUsoFactura
    );

    const itemTaxId = useRef<HTMLIonItemElement>(null);
    const itemNombreORazonSocial = useRef<HTMLIonItemElement>(null);

    const onValidate = (id: number, value: any) => {
      if (!itemTaxId.current) return;
      if (!itemNombreORazonSocial.current) return;

      switch (id) {
        case 0:
          setTaxId(value);
          const testTaxId = !/.+/.test(value.trim());
          if (testTaxId) itemTaxId.current.classList.add("error");
          else itemTaxId.current.classList.remove("error");
          setShowErrorMessage(
            showErrorMessage.map((e, i) => {
              if (i !== id) return e;
              return { estatus: testTaxId, msg: e.msg };
            })
          );
          break;
        case 1:
          setNombreORazonSocial(value);
          const testNombreORazonSocial = !/.+/.test(value.trim());
          if (testNombreORazonSocial)
            itemNombreORazonSocial.current.classList.add("error");
          else itemNombreORazonSocial.current.classList.remove("error");
          setShowErrorMessage(
            showErrorMessage.map((e, i) => {
              if (i !== id) return e;
              return { estatus: testNombreORazonSocial, msg: e.msg };
            })
          );
          break;
      }
    };

    const acceptDisabled = () => {
      return (
        showErrorMessage.some((e) => e.estatus) || !taxId || !nombreORazonSocial
      );
    };

    return (
      <IonPage>
        <IonHeader>
          <IonToolbar color="secondary" className="toolBarStart">
            <IonButtons slot="start">
              <IonButton onClick={() => onDismiss()}>Cancelar</IonButton>
            </IonButtons>
            <IonTitle className="toolBarStart center">Datos Fiscales</IonTitle>
            <IonButtons slot="end">
              <IonButton
                strong={true}
                disabled={acceptDisabled()}
                onClick={async () => {
                  const idAutor = auth.empleado.usuario.id;
                  const temp = { ...datosFiscales };
                  temp.tax_id = taxId;
                  temp.nombre_o_razon_social = nombreORazonSocial;
                  temp.uso_factura = mvUsoFactura.data_array.find(
                    (e) => e.id.toString() === usoFactura.id
                  )!;
                  temp.autor = user.data_array.find((e) => e.id === idAutor)!;
                  if (accept) {
                    accept(temp);
                  }
                }}
              >
                Aceptar
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent className="ion-padding">
          <IonGrid>
            <IonRow>
              <IonCol
                sizeXl="6"
                sizeLg="6"
                sizeMd="6"
                sizeSm="6"
                sizeXs="12"
                size="12"
              >
                <IonItem className="modal-input" ref={itemTaxId}>
                  <IonIcon
                    slot="start"
                    icon={documentIcon}
                    class="icon-input-margin"
                  />
                  <IonLabel position="floating" className="modal-label">
                    RFC o TaxID <IonText color="danger">*</IonText>
                  </IonLabel>
                  <IonInput
                    clearInput={true}
                    inputmode="text"
                    type="text"
                    color="secondary"
                    value={taxId}
                    onIonChange={(e) => onValidate(0, e.detail.value)}
                  />
                </IonItem>
                {showErrorMessage[0].estatus ? (
                  <IonText class="input-error-message">
                    {showErrorMessage[0].msg}
                  </IonText>
                ) : (
                  <></>
                )}
              </IonCol>
              <IonCol
                sizeXl="6"
                sizeLg="6"
                sizeMd="6"
                sizeSm="6"
                sizeXs="12"
                size="12"
              >
                <IonItem className="modal-input" ref={itemNombreORazonSocial}>
                  <IonIcon
                    slot="start"
                    icon={documentIcon}
                    class="icon-input-margin"
                  />
                  <IonLabel position="floating" className="modal-label">
                    Nombre o Razón Social <IonText color="danger">*</IonText>
                  </IonLabel>
                  <IonInput
                    clearInput={true}
                    inputmode="text"
                    type="text"
                    color="secondary"
                    value={nombreORazonSocial}
                    onIonChange={(e) => onValidate(1, e.detail.value)}
                  />
                </IonItem>
                {showErrorMessage[1].estatus ? (
                  <IonText class="input-error-message">
                    {showErrorMessage[1].msg}
                  </IonText>
                ) : (
                  <></>
                )}
              </IonCol>
              <IonCol
                sizeXl="6"
                sizeLg="6"
                sizeMd="6"
                sizeSm="6"
                sizeXs="12"
                size="12"
              >
                <Selector
                  classStyle="modal-input"
                  value={usoFactura}
                  label="Uso factura"
                  list={mvUsoFacturaList}
                  change={setUsoFactura}
                  required={true}
                  icon={documentIcon}
                />
              </IonCol>
            </IonRow>
          </IonGrid>
          <IonItem lines="none" className="modal-input">
            <IonLabel>
              <IonText color="danger">*</IonText> Campos requeridos
            </IonLabel>
          </IonItem>
        </IonContent>
      </IonPage>
    );
  };

  const [presentCreateDatosFiscalesModal, dismissCreateDatosFiscalesModal] =
    useIonModal(CreateDatosFiscalesModal, {
      onDismiss: () => {
        dismissCreateDatosFiscalesModal();
        setDatosFiscales(getEmptyDatosFiscales());
      },
      accept: async (data: IDatosFiscales) => {
        dismissCreateDatosFiscalesModal();
        await addDatosFiscales(data, empleadoModal);
        setDatosFiscales(getEmptyDatosFiscales());
      },
    });

  const [presentEditDatosFiscalesModal, dismissEditDatosFiscalesModal] =
    useIonModal(EditDatosFiscalesModal, {
      onDismiss: () => {
        dismissEditDatosFiscalesModal();
        setDatosFiscales(getEmptyDatosFiscales());
      },
      accept: async (data: IDatosFiscales) => {
        dismissEditDatosFiscalesModal();
        await editDatosFiscales(data.id, data, empleadoModal);
        setDatosFiscales(getEmptyDatosFiscales());
      },
    });

  const deleteDatosFiscales = (data: IDatosFiscales) => {
    presentActionSheet({
      header: "¿Está seguro de eliminar estos datos fiscales?",
      buttons: [
        {
          text: "Eliminar",
          role: "destructive",
          handler: async () => {
            removeDatosFiscales(data.id, empleadoModal);
          },
        },
        {
          text: "Cancelar",
          role: "cancel",
        },
      ],
    });
  };

  // Informacion Bancaria

  const defaultInformacionBancariaErrorMessages = [
    "Por favor un ingrese una CLABE válido.",
    "Por favor un ingrese un número de nuenta válido.",
  ];
  const [
    showInformacionBancariaErrorMessage,
    setShowInformacionBancariaErrorMessage,
  ] = useState<{ estatus: boolean; msg: string }[]>(
    defaultInformacionBancariaErrorMessages.map((v) => ({
      estatus: false,
      msg: v,
    }))
  );

  const empleadoModalInformacionBancariaBanco = useAppSelector(
    getMemoizedTeamMembersSelectedBanco
  );
  const mvBancoList = useAppSelector(getGlobalMemoizedMvBancoAsSelectList);

  const [clabe, setClabe] = useState(empleadoModal.informacion_bancaria.clabe);
  const [numeroCuenta, setNumeroCuenta] = useState(
    empleadoModal.informacion_bancaria.numero_cuenta
  );
  const [sucursal, setSucursal] = useState(
    empleadoModal.informacion_bancaria.sucursal
  );
  const [banco, setBanco] = useState(empleadoModalInformacionBancariaBanco);

  const itemClabe = useRef<HTMLIonItemElement>(null);
  const itemNumeroCuenta = useRef<HTMLIonItemElement>(null);

  const onValidateInformacionBancaria = (id: number, value: any) => {
    if (!itemClabe.current) return;
    if (!itemNumeroCuenta.current) return;

    switch (id) {
      case 0:
        setClabe(value);
        const testClabe = !/^[0-9]{1,20}$/.test(value.trim());
        if (testClabe) itemClabe.current.classList.add("error");
        else itemClabe.current.classList.remove("error");
        setShowInformacionBancariaErrorMessage(
          showInformacionBancariaErrorMessage.map((e, i) => {
            if (i !== id) return e;
            return { estatus: testClabe, msg: e.msg };
          })
        );
        break;
      case 1:
        setNumeroCuenta(value);
        const testNumeroCuenta = !/^[0-9]{1,20}$/.test(value.trim());
        if (testNumeroCuenta) itemNumeroCuenta.current.classList.add("error");
        else itemNumeroCuenta.current.classList.remove("error");
        setShowInformacionBancariaErrorMessage(
          showInformacionBancariaErrorMessage.map((e, i) => {
            if (i !== id) return e;
            return { estatus: testNumeroCuenta, msg: e.msg };
          })
        );
        break;
    }
  };

  const updateInformacionBancaria = async () => {
    const idAutor = auth.empleado.usuario.id;
    const temp = { ...empleadoModal.informacion_bancaria };
    temp.clabe = clabe;
    temp.numero_cuenta = numeroCuenta;
    temp.sucursal = sucursal;
    temp.banco = mvBanco.data_array.find((e) => e.id.toString() === banco.id)!;
    temp.autor = user.data_array.find((e) => e.id === idAutor)!;
    temp.estatus_sistema = true;
    await addOrEditInformacionBancaria(temp, empleadoModal);
  };

  // Configuración
  const defaultConfiguracionErrorMessages = [
    "Por favor un ingrese un fecha válida.",
    "Por favor un ingrese un número de seguro social válido.",
  ];
  const [showConfiguracionErrorMessage, setShowConfiguracionErrorMessage] =
    useState<{ estatus: boolean; msg: string }[]>(
      defaultConfiguracionErrorMessages.map((v) => ({ estatus: false, msg: v }))
    );

  const empleadosList = useAppSelector((state) =>
    getMemoizedEmpleadoWithExceptionAsSelectList(state, empleadoModal.id)
  );
  const empleadoModalConfiguracionEmpleados = useAppSelector((state) =>
    getMemoizedTeamMembersSelectedEmpleados(state, empleadoModal.id)
  );
  const centrosDeTrabajoList = useAppSelector(getMemoizedCodigoQrList);

  const empleadoModalConfiguracionCentrosDeTrabajo = useAppSelector(
    getMemoizedTeamMembersSelectedCodigoQr
  );
  const tipoEmpleadoList = useAppSelector(
    getGlobalMemoizedMvTipoEmpleadoAsSelectList
  );
  const empleadoModalConfiguracionTipoEmpleado = useAppSelector(
    getMemoizedTeamMembersSelectedTipoEmpleado
  );

  const [fechaContratacion, setFechaContratacion] = useState(
    empleadoModal.fecha_contratacion
  );
  const [nss, setNSS] = useState(empleadoModal.numero_seguridad_social);
  const [empleados, setEmpleados] = useState(
    empleadoModalConfiguracionEmpleados
  );
  const [centrosDeTrabajo, setCentrosDeTrabajo] = useState(
    empleadoModalConfiguracionCentrosDeTrabajo
  );
  const [centroDeTrabajo, setCentroDeTrabajo] = useState<List>({
    id: "0",
    title: "",
  });

  const [tipoEmpleado, setTipoEmpleado] = useState(
    empleadoModalConfiguracionTipoEmpleado
  );
  const [sindicalizado, setSindicalizado] = useState(
    empleadoModal.sindicalizado
  );
  const [supervisor, setSupervisor] = useState(empleadoModal.es_supervisor);
  const [gerente, setGerente] = useState(empleadoModal.es_gerente);
  const [multicentro, setMulticentro] = useState(empleadoModal.es_multi_centro);
  const [accedeHistorial, setAccedeHistorial] = useState(
    empleadoModal.accede_a_historial
  );
  const [accedeWhatsapp, setAccedeWhatsapp] = useState(
    empleadoModal.accede_a_whatsapp
  );
  const [checaWhatsapp, setChecaWhatsapp] = useState(
    empleadoModal.checa_con_whatsapp
  );

  const itemFechaContratacion = useRef<HTMLIonItemElement>(null);
  const itemNSS = useRef<HTMLIonItemElement>(null);

  const centrosDeTrabajoEmpresa = () => {
    // const centrosPorEmpresa = centroDeTrabajoRedux.data_array.filter(centro => centro.empresa.id === empleadoModal.empresa.id)
    const qrsPorCentrosEmpresa = codigoQr.data_array.filter(
      (cQr) =>
        cQr.centro_de_trabajo.empresa.id === empleadoModal.empresa.id &&
        cQr.imagen_codigo?.length
    );
    return qrsPorCentrosEmpresa.map((cQr: ICodigoQr) =>
      setListItem({
        id: cQr.id.toString(),
        title: cQr.centro_de_trabajo.nombre_centro,
      })
    );
  };

  const onValidateConfiguracion = (id: number, value: any) => {
    if (!itemNSS.current) return;
    switch (id) {
      case 1:
        setNSS(value);
        const testNSS = !/.+/.test(value.trim());
        if (testNSS) itemNSS.current.classList.add("error");
        else itemNSS.current.classList.remove("error");
        setShowConfiguracionErrorMessage(
          showConfiguracionErrorMessage.map((e, i) => {
            if (i !== id) return e;
            return { estatus: testNSS, msg: e.msg };
          })
        );
        break;
    }
  };

  const updateConfiguracion = async () => {
    const temp = { ...empleadoModal };
    temp.tipo_empleado = mvTipoEmpleado.data_array.find(
      (e) => e.id.toString() === tipoEmpleado.id
    )!;
    temp.empleados_a_cargo = empleado.data_array.filter((e) =>
      empleados.some((v) => v.id === e.id.toString())
    )!;
    console.log("centrosDeTrabajo seleccionado", centrosDeTrabajo);
    let qrs = [];
    console.log("multicentro", multicentro);

    const centros = multicentro ? centrosDeTrabajo : [centroDeTrabajo];
    console.log("centros", centros);

    for (const ct of centros) {
      console.log("codigoQr.data_array", codigoQr.data_array);

      const finded = codigoQr.data_array.find(
        (cQr) => cQr.id.toString() === ct.id
      );
      console.log("finded", finded);

      if (finded) {
        qrs.push(finded);
      }
    }
    temp.codigos_qr_asignados = qrs;
    console.log("temp.codigos_qr_asignados", temp.codigos_qr_asignados);
    temp.centro_trabajo = temp.codigos_qr_asignados.map(
      (e) => e.centro_de_trabajo
    );
    console.log("temp.centro_trabajo", temp.centro_trabajo);
    temp.fecha_contratacion = fechaContratacion;
    temp.numero_seguridad_social = nss;
    temp.sindicalizado = sindicalizado;
    temp.es_supervisor = supervisor;
    temp.es_gerente = gerente;
    temp.es_multi_centro = multicentro;
    temp.accede_a_historial = accedeHistorial;
    temp.accede_a_whatsapp = accedeWhatsapp;
    temp.checa_con_whatsapp = checaWhatsapp;
    temp.estatus_sistema = true;

    await editConfiguracion(temp);
  };
  useEffect(() => {
    if (!multicentro) {
      setCentroDeTrabajo(centrosDeTrabajo[0]);
    }
  }, [multicentro, centrosDeTrabajo]);

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar color="secondary" className="toolBarStart">
          <IonButtons slot="start">
            <IonButton onClick={() => onDismiss()}>Regresar</IonButton>
          </IonButtons>
          <IonTitle className="toolBarStart">Empleado</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent className="ion-padding">
        <IonGrid>
          <IonRow class="no-padding">
            <IonCol size="12" class="no-padding">
              <IonAccordionGroup>
                <IonAccordion value="0">
                  <IonItem slot="header" color="light">
                    <IonLabel>
                      <h2>
                        <b>Datos personales</b>
                      </h2>
                    </IonLabel>
                  </IonItem>
                  <IonRow slot="content">
                    <IonCol
                      sizeXl="6"
                      sizeLg="6"
                      sizeMd="6"
                      sizeSm="6"
                      sizeXs="12"
                      size="12"
                    >
                      <IonItem className="modal-input" ref={itemPrimerNombre}>
                        <IonLabel position="floating" className="modal-label">
                          Primer nombre <IonText color="danger">*</IonText>
                        </IonLabel>
                        <IonIcon
                          slot="start"
                          icon={person}
                          class="icon-input-margin"
                        />
                        <IonInput
                          type="text"
                          clearInput={true}
                          color="secondary"
                          ref={inputPrimerNombre}
                          value={primerNombre}
                          onIonChange={(e) =>
                            onValidateDatosPersonales(0, e.detail.value)
                          }
                        />
                      </IonItem>
                      {showDatosPersonalesErrorMessage[0].estatus ? (
                        <IonText class="input-error-message">
                          {showDatosPersonalesErrorMessage[0].msg}
                        </IonText>
                      ) : (
                        <></>
                      )}
                    </IonCol>
                    <IonCol
                      sizeXl="6"
                      sizeLg="6"
                      sizeMd="6"
                      sizeSm="6"
                      sizeXs="12"
                      size="12"
                    >
                      <IonItem className="modal-input">
                        <IonLabel position="floating" className="modal-label">
                          Segundo nombre
                        </IonLabel>
                        <IonIcon
                          slot="start"
                          icon={person}
                          class="icon-input-margin"
                        />
                        <IonInput
                          type="text"
                          clearInput={true}
                          color="secondary"
                          value={segundoNombre}
                          onIonChange={(e) => setSegundoNombre(e.detail.value!)}
                        />
                      </IonItem>
                    </IonCol>
                    <IonCol
                      sizeXl="6"
                      sizeLg="6"
                      sizeMd="6"
                      sizeSm="6"
                      sizeXs="12"
                      size="12"
                    >
                      <IonItem
                        className="modal-input"
                        ref={itemApellidoPaterno}
                      >
                        <IonLabel position="floating" className="modal-label">
                          Apellido paterno <IonText color="danger">*</IonText>
                        </IonLabel>
                        <IonIcon
                          slot="start"
                          icon={person}
                          class="icon-input-margin"
                        />
                        <IonInput
                          type="text"
                          clearInput={true}
                          color="secondary"
                          ref={inputApellidoPaterno}
                          value={apellidoPaterno}
                          onIonChange={(e) =>
                            onValidateDatosPersonales(1, e.detail.value)
                          }
                        />
                      </IonItem>
                      {showDatosPersonalesErrorMessage[1].estatus ? (
                        <IonText class="input-error-message">
                          {showDatosPersonalesErrorMessage[1].msg}
                        </IonText>
                      ) : (
                        <></>
                      )}
                    </IonCol>
                    <IonCol
                      sizeXl="6"
                      sizeLg="6"
                      sizeMd="6"
                      sizeSm="6"
                      sizeXs="12"
                      size="12"
                    >
                      <IonItem className="modal-input">
                        <IonLabel position="floating" className="modal-label">
                          Apellido materno
                        </IonLabel>
                        <IonIcon
                          slot="start"
                          icon={person}
                          class="icon-input-margin"
                        />
                        <IonInput
                          type="text"
                          clearInput={true}
                          color="secondary"
                          value={apellidoMaterno}
                          onIonChange={(e) =>
                            setApellidoMaterno(e.detail.value!)
                          }
                        />
                      </IonItem>
                    </IonCol>
                    <IonCol
                      sizeXl="6"
                      sizeLg="6"
                      sizeMd="6"
                      sizeSm="6"
                      sizeXs="12"
                      size="12"
                    >
                      <IonItem className="modal-input">
                        <IonLabel position="floating" className="modal-label">
                          Fecha de nacimiento
                        </IonLabel>
                        <IonIcon
                          slot="start"
                          icon={calendar}
                          class="icon-input-margin"
                        />
                        <IonInput
                          id="empleado-edad-date"
                          type="text"
                          clearInput={true}
                          color="secondary"
                          value={fechaDeNacimiento}
                          className="custom-picker"
                        />

                        <IonPopover
                          className="custom-picker"
                          id="empleado-edad-picker"
                          animated={false}
                          backdropDismiss={true}
                          trigger="empleado-edad-date"
                          triggerAction="click"
                          show-backdrop="true"
                          class="date-picker"
                        >
                          <IonDatetime
                            className="custom-picker"
                            id="empleado-edad-datetime"
                            color="primary"
                            presentation="date"
                            placeholder="Seleccionar fecha"
                            min="1933-01-01"
                            value={fechaDeNacimiento}
                            onIonChange={async (e) => {
                              setFechaDeNacimiento(
                                (e.detail.value as string)!.split("T")[0]
                              );
                            }}
                          ></IonDatetime>
                        </IonPopover>
                      </IonItem>
                    </IonCol>
                    <IonCol
                      sizeXl="6"
                      sizeLg="6"
                      sizeMd="6"
                      sizeSm="6"
                      sizeXs="12"
                      size="12"
                    >
                      <IonItem className="modal-input">
                        <IonLabel position="floating" className="modal-label">
                          Edad
                        </IonLabel>
                        <IonIcon
                          slot="start"
                          icon={person}
                          class="icon-input-margin"
                        />
                        <IonInput
                          type="number"
                          color="secondary"
                          min="0"
                          value={edad}
                          onIonChange={(e) => setEdad(Number(e.detail.value!))}
                        />
                      </IonItem>
                    </IonCol>
                    <IonCol
                      sizeXl="6"
                      sizeLg="6"
                      sizeMd="6"
                      sizeSm="6"
                      sizeXs="12"
                      size="12"
                    >
                      <IonItem className="modal-input">
                        <IonLabel position="floating" className="modal-label">
                          CURP
                        </IonLabel>
                        <IonIcon
                          slot="start"
                          icon={documentIcon}
                          class="icon-input-margin"
                        />
                        <IonInput
                          type="text"
                          clearInput={true}
                          color="secondary"
                          value={curp}
                          onIonChange={(e) => setCURP(e.detail.value!)}
                        />
                      </IonItem>
                    </IonCol>
                    <IonCol
                      sizeXl="6"
                      sizeLg="6"
                      sizeMd="6"
                      sizeSm="6"
                      sizeXs="12"
                      size="12"
                    >
                      <IonItem className="modal-input" disabled>
                        <IonLabel position="floating" className="modal-label">
                          Correo
                        </IonLabel>
                        <IonIcon
                          slot="start"
                          icon={mail}
                          class="icon-input-margin"
                        />
                        <IonInput
                          type="text"
                          color="secondary"
                          value={correo}
                        />
                      </IonItem>
                    </IonCol>
                    <IonCol
                      sizeXl="6"
                      sizeLg="6"
                      sizeMd="6"
                      sizeSm="6"
                      sizeXs="12"
                      size="12"
                    >
                      <IonItem className="modal-input" ref={itemTelefonoCasa}>
                        <IonLabel position="floating" className="modal-label">
                          Teléfono de casa
                        </IonLabel>
                        <IonIcon
                          slot="start"
                          icon={phonePortrait}
                          class="icon-input-margin"
                        />
                        <IonInput
                          inputmode="tel"
                          clearInput={true}
                          type="tel"
                          color="secondary"
                          placeholder={"+522215778772"}
                          ref={inputTelefonoCasa}
                          value={telefonoCasa}
                          onIonChange={(e) =>
                            onValidateDatosPersonales(2, e.detail.value)
                          }
                        />
                      </IonItem>
                      {showDatosPersonalesErrorMessage[2].estatus ? (
                        <IonText class="input-error-message">
                          {showDatosPersonalesErrorMessage[2].msg}
                        </IonText>
                      ) : (
                        <></>
                      )}
                    </IonCol>
                    <IonCol
                      sizeXl="6"
                      sizeLg="6"
                      sizeMd="6"
                      sizeSm="6"
                      sizeXs="12"
                      size="12"
                    >
                      <IonItem className="modal-input" disabled>
                        <IonLabel position="floating" className="modal-label">
                          Teléfono móvil
                        </IonLabel>
                        <IonIcon
                          slot="start"
                          icon={phonePortrait}
                          class="icon-input-margin"
                        />
                        <IonInput
                          inputmode="tel"
                          type="tel"
                          color="secondary"
                          value={telefonoMovil}
                          placeholder={"+522215778772"}
                        />
                      </IonItem>
                    </IonCol>
                    <IonCol
                      sizeXl="6"
                      sizeLg="6"
                      sizeMd="6"
                      sizeSm="6"
                      sizeXs="12"
                      size="12"
                    >
                      <Selector
                        classStyle="modal-input"
                        value={genero}
                        label="Género"
                        list={mvGeneroList}
                        change={setGenero}
                        icon={person}
                      />
                    </IonCol>
                    <IonCol
                      sizeXl="6"
                      sizeLg="6"
                      sizeMd="6"
                      sizeSm="6"
                      sizeXs="12"
                      size="12"
                    >
                      <Selector
                        classStyle="modal-input"
                        value={estadoCivil}
                        label="Estado Civil"
                        list={mvEstadoCivilList}
                        change={setEstadoCivil}
                        icon={person}
                      />
                    </IonCol>
                    <IonCol size="12">
                      <IonLabel>
                        <h2>
                          <b>
                            Dirección <IonText color="danger">**</IonText>
                          </b>
                        </h2>
                      </IonLabel>
                    </IonCol>
                    <IonCol size="9">
                      <IonItem className="modal-input" ref={itemCodigoPostal}>
                        <IonIcon
                          slot="start"
                          icon={business}
                          class="icon-input-margin"
                        />
                        <IonLabel position="floating" className="modal-label">
                          Código Postal
                        </IonLabel>
                        <IonInput
                          clearInput={true}
                          inputmode="text"
                          type="text"
                          color="secondary"
                          value={codigoPostal}
                          onIonChange={(e) =>
                            onValidateDatosPersonales(3, e.detail.value!)
                          }
                        />
                      </IonItem>
                      {showDatosPersonalesErrorMessage[3].estatus ? (
                        <IonText class="input-error-message">
                          {showDatosPersonalesErrorMessage[3].msg}
                        </IonText>
                      ) : (
                        <></>
                      )}
                    </IonCol>
                    <IonCol size="3">
                      <IonButton
                        expand="block"
                        size="large"
                        onClick={() => searchCP()}
                      >
                        <IonIcon slot="icon-only" icon={search} />
                      </IonButton>
                    </IonCol>
                    <IonCol size="12">
                      <div
                        style={{
                          display: "flex",
                          height: "300px",
                          flexDirection: "column",
                        }}
                      >
                        <Wrapper
                          apiKey={process.env.REACT_APP_GOOGLE_MAPS_KEY!}
                          libraries={["places"]}
                          callback={render}
                        >
                          <Map
                            center={center}
                            onClick={onClick}
                            onIdle={onIdle}
                            onPlaceAdded={onPlaceAdded}
                            zoom={zoom}
                            hasSearchBox={false}
                            searchText={searchText}
                            style={{ flexGrow: "1", height: "100%" }}
                          >
                            <Marker
                              key="nueva-dirección"
                              icon={StatusIcon[3]}
                              position={currentLocation}
                              content={"Dirección"}
                              clickable={true}
                              draggable={true}
                              onDrag={onPlacesDrag}
                            />
                          </Map>
                        </Wrapper>
                      </div>
                      <IonNote>
                        <IonText color="danger">**</IonText>Si la ubicación no
                        se detecta automáticamente al buscar el código postal
                        por favor seleccione un punto en el mapa.
                      </IonNote>
                    </IonCol>
                    <IonCol size="12" sizeMd="6" sizeLg="6" sizeXl="6">
                      <IonItem className="modal-input" ref={itemCalle}>
                        <IonIcon
                          slot="start"
                          icon={business}
                          class="icon-input-margin"
                        />
                        <IonLabel position="floating" className="modal-label">
                          Calle<IonText color="danger">*</IonText>
                        </IonLabel>
                        <IonInput
                          clearInput={true}
                          disabled={!validCP}
                          inputmode="text"
                          type="text"
                          color="secondary"
                          value={calle}
                          onIonChange={(e) =>
                            onValidateDatosPersonales(4, e.detail.value!)
                          }
                        />
                      </IonItem>
                      {showDatosPersonalesErrorMessage[4].estatus ? (
                        <IonText class="input-error-message">
                          {showDatosPersonalesErrorMessage[4].msg}
                        </IonText>
                      ) : (
                        <></>
                      )}
                    </IonCol>
                    <IonCol size="12" sizeMd="6" sizeLg="6" sizeXl="6">
                      <IonItem className="modal-input" ref={itemNumeroExterior}>
                        <IonIcon
                          slot="start"
                          icon={business}
                          class="icon-input-margin"
                        />
                        <IonLabel position="floating" className="modal-label">
                          Número exterior<IonText color="danger">*</IonText>
                        </IonLabel>
                        <IonInput
                          clearInput={true}
                          disabled={!validCP}
                          inputmode="text"
                          type="text"
                          color="secondary"
                          value={numeroExterior}
                          onIonChange={(e) =>
                            onValidateDatosPersonales(5, e.detail.value!)
                          }
                        />
                      </IonItem>
                      {showDatosPersonalesErrorMessage[5].estatus ? (
                        <IonText class="input-error-message">
                          {showDatosPersonalesErrorMessage[5].msg}
                        </IonText>
                      ) : (
                        <></>
                      )}
                    </IonCol>
                    <IonCol size="12" sizeMd="6" sizeLg="6" sizeXl="6">
                      <IonItem className="modal-input">
                        <IonIcon
                          slot="start"
                          icon={business}
                          class="icon-input-margin"
                        />
                        <IonLabel position="floating" className="modal-label">
                          Número interior
                        </IonLabel>
                        <IonInput
                          clearInput={true}
                          disabled={!validCP}
                          inputmode="text"
                          type="text"
                          color="secondary"
                          value={numeroInterior}
                          onIonChange={(e) =>
                            setNumeroInterior(e.detail.value!)
                          }
                        />
                      </IonItem>
                    </IonCol>
                    <IonCol size="12" sizeMd="6" sizeLg="6" sizeXl="6">
                      <IonItem className="modal-input" ref={itemColonia}>
                        <IonIcon
                          slot="start"
                          icon={business}
                          class="icon-input-margin"
                        />
                        <IonLabel position="floating" className="modal-label">
                          Colonia<IonText color="danger">*</IonText>
                        </IonLabel>
                        <IonInput
                          clearInput={true}
                          disabled={!validCP}
                          inputmode="text"
                          type="text"
                          color="secondary"
                          value={colonia}
                          onIonChange={(e) =>
                            onValidateDatosPersonales(6, e.detail.value!)
                          }
                        />
                      </IonItem>
                      {showDatosPersonalesErrorMessage[6].estatus ? (
                        <IonText class="input-error-message">
                          {showDatosPersonalesErrorMessage[6].msg}
                        </IonText>
                      ) : (
                        <></>
                      )}
                    </IonCol>
                    <IonCol size="12" sizeMd="6" sizeLg="6" sizeXl="6">
                      <IonItem className="modal-input" ref={itemCiudad}>
                        <IonIcon
                          slot="start"
                          icon={business}
                          class="icon-input-margin"
                        />
                        <IonLabel position="floating" className="modal-label">
                          Ciudad<IonText color="danger">*</IonText>
                        </IonLabel>
                        <IonInput
                          clearInput={true}
                          disabled={!validCP}
                          inputmode="text"
                          type="text"
                          color="secondary"
                          value={ciudad}
                          onIonChange={(e) =>
                            onValidateDatosPersonales(7, e.detail.value!)
                          }
                        />
                      </IonItem>
                      {showDatosPersonalesErrorMessage[7].estatus ? (
                        <IonText class="input-error-message">
                          {showDatosPersonalesErrorMessage[7].msg}
                        </IonText>
                      ) : (
                        <></>
                      )}
                    </IonCol>
                    <IonCol size="12" sizeMd="6" sizeLg="6" sizeXl="6">
                      <IonItem className="modal-input">
                        <IonIcon
                          slot="start"
                          icon={business}
                          class="icon-input-margin"
                        />
                        <IonLabel position="floating" className="modal-label">
                          Delegación/Municipio
                        </IonLabel>
                        <IonInput
                          clearInput={true}
                          disabled={!validCP}
                          inputmode="text"
                          type="text"
                          color="secondary"
                          value={delegacionMunicipio}
                          onIonChange={(e) =>
                            setDelegacionMunicipio(e.detail.value!)
                          }
                        />
                      </IonItem>
                    </IonCol>
                    <IonCol
                      sizeXl="6"
                      sizeLg="6"
                      sizeMd="6"
                      sizeSm="6"
                      sizeXs="12"
                      size="12"
                    >
                      <Selector
                        classStyle="modal-input"
                        value={datosPersonalesEstado}
                        label="Estado"
                        list={estadoList}
                        change={setEstado}
                        icon={documentText}
                        disabled={!validCP}
                      />
                    </IonCol>
                    <IonCol
                      sizeXl="6"
                      sizeLg="6"
                      sizeMd="6"
                      sizeSm="6"
                      sizeXs="12"
                      size="12"
                    >
                      <Selector
                        classStyle="modal-input"
                        value={datosPersonalesPais}
                        label="País"
                        list={paisList}
                        change={setPais}
                        icon={flag}
                        disabled={!validCP}
                      />
                    </IonCol>
                    <IonCol size="12">
                      <IonButton
                        expand="block"
                        color="primary"
                        disabled={disabledDatosPersonalesButton()}
                        onClick={() => updateDatosPersonales()}
                      >
                        {datosPersonales.estado === DEFAULT_STATE.loading ? (
                          <IonSpinner
                            className="loading-center-horizontal"
                            name="circles"
                            color="medium"
                            slot="start"
                          />
                        ) : (
                          <IonLabel>Actualizar</IonLabel>
                        )}
                      </IonButton>
                      <IonItem lines="none" className="modal-input">
                        <IonLabel>
                          <IonText color="danger">*</IonText> Campos requeridos
                        </IonLabel>
                      </IonItem>
                    </IonCol>
                  </IonRow>
                </IonAccordion>
                <IonAccordion value="1">
                  <IonItem slot="header" color="light">
                    <IonLabel>
                      <h2>
                        <b>Datos fiscales</b>
                      </h2>
                    </IonLabel>
                  </IonItem>
                  <IonRow className="no-padding scroll-items " slot="content">
                    <IonCol>
                      <IonCard className="no-padding ">
                        <IonCardContent class="no-padding">
                          <IonButton
                            class="no-padding no-margin button-add-horizontal"
                            expand="block"
                            onClick={() => presentCreateDatosFiscalesModal()}
                          >
                            <IonIcon
                              slot="icon-only"
                              icon={addCircle}
                              size="large"
                            />
                          </IonButton>
                        </IonCardContent>
                      </IonCard>
                    </IonCol>
                    {empleadoModal.datos_fiscales.map((e, i) => {
                      return (
                        <IonCol
                          key={`${i} - ${e.tax_id} - ${e.nombre_o_razon_social}`}
                        >
                          <IonCard
                            color="dark"
                            className="component-horizontal-scroll"
                          >
                            <IonCardContent>
                              <IonLabel className="text-overflow ion-text-nowrap">
                                <h1>
                                  <b>{e.tax_id}</b>
                                </h1>
                                <h3>
                                  <b>{e.nombre_o_razon_social}</b>
                                </h3>
                                <h3>
                                  <b>{`${e.uso_factura.clave}`}</b>
                                </h3>
                              </IonLabel>
                            </IonCardContent>
                            <IonButton
                              fill="clear"
                              size="small"
                              onClick={() => {
                                setDatosFiscales(e);
                                presentEditDatosFiscalesModal();
                              }}
                            >
                              <IonIcon
                                slot="icon-only"
                                icon={pencil}
                                color="light"
                              />
                            </IonButton>
                            <IonButton
                              fill="clear"
                              size="small"
                              onClick={() => deleteDatosFiscales(e)}
                            >
                              <IonIcon
                                slot="icon-only"
                                icon={trash}
                                color="light"
                              />
                            </IonButton>
                          </IonCard>
                        </IonCol>
                      );
                    })}
                  </IonRow>
                </IonAccordion>
                <IonAccordion value="2">
                  <IonItem slot="header" color="light">
                    <IonLabel>
                      <h2>
                        <b>Informacion bancaria</b>
                      </h2>
                    </IonLabel>
                  </IonItem>
                  <IonRow slot="content">
                    <IonCol
                      sizeXl="6"
                      sizeLg="6"
                      sizeMd="6"
                      sizeSm="6"
                      sizeXs="12"
                      size="12"
                    >
                      <IonItem className="modal-input" ref={itemClabe}>
                        <IonIcon
                          slot="start"
                          icon={documentIcon}
                          class="icon-input-margin"
                        />
                        <IonLabel position="floating" className="modal-label">
                          CLABE <IonText color="danger">*</IonText>
                        </IonLabel>
                        <IonInput
                          clearInput={true}
                          inputmode="text"
                          type="text"
                          maxlength={20}
                          color="secondary"
                          value={clabe}
                          onIonChange={(e) =>
                            onValidateInformacionBancaria(0, e.detail.value)
                          }
                        />
                      </IonItem>
                      {showInformacionBancariaErrorMessage[0].estatus ? (
                        <IonText class="input-error-message">
                          {showInformacionBancariaErrorMessage[0].msg}
                        </IonText>
                      ) : (
                        <></>
                      )}
                    </IonCol>
                    <IonCol
                      sizeXl="6"
                      sizeLg="6"
                      sizeMd="6"
                      sizeSm="6"
                      sizeXs="12"
                      size="12"
                    >
                      <IonItem className="modal-input" ref={itemNumeroCuenta}>
                        <IonIcon
                          slot="start"
                          icon={documentIcon}
                          class="icon-input-margin"
                        />
                        <IonLabel position="floating" className="modal-label">
                          Número de Cuenta <IonText color="danger">*</IonText>
                        </IonLabel>
                        <IonInput
                          clearInput={true}
                          inputmode="text"
                          type="text"
                          maxlength={20}
                          color="secondary"
                          value={numeroCuenta}
                          onIonChange={(e) =>
                            onValidateInformacionBancaria(1, e.detail.value)
                          }
                        />
                      </IonItem>
                      {showInformacionBancariaErrorMessage[1].estatus ? (
                        <IonText class="input-error-message">
                          {showInformacionBancariaErrorMessage[1].msg}
                        </IonText>
                      ) : (
                        <></>
                      )}
                    </IonCol>
                    <IonCol
                      sizeXl="6"
                      sizeLg="6"
                      sizeMd="6"
                      sizeSm="6"
                      sizeXs="12"
                      size="12"
                    >
                      <IonItem className="modal-input">
                        <IonIcon
                          slot="start"
                          icon={business}
                          class="icon-input-margin"
                        />
                        <IonLabel position="floating" className="modal-label">
                          Sucursal
                        </IonLabel>
                        <IonInput
                          clearInput={true}
                          inputmode="text"
                          type="text"
                          color="secondary"
                          value={sucursal}
                          onIonChange={(e) => setSucursal(e.detail.value!)}
                        />
                      </IonItem>
                    </IonCol>
                    <IonCol
                      sizeXl="6"
                      sizeLg="6"
                      sizeMd="6"
                      sizeSm="6"
                      sizeXs="12"
                      size="12"
                    >
                      <Selector
                        classStyle="modal-input"
                        value={banco}
                        label="Banco"
                        list={mvBancoList}
                        change={setBanco}
                        required={true}
                        icon={business}
                      />
                    </IonCol>
                    <IonCol size="12">
                      <IonButton
                        expand="block"
                        color="primary"
                        onClick={() => updateInformacionBancaria()}
                      >
                        {informacionBancaria.estado ===
                        DEFAULT_STATE.loading ? (
                          <IonSpinner
                            className="loading-center-horizontal"
                            name="circles"
                            color="medium"
                            slot="start"
                          />
                        ) : (
                          <IonLabel>Actualizar</IonLabel>
                        )}
                      </IonButton>
                      <IonItem lines="none" className="modal-input">
                        <IonLabel>
                          <IonText color="danger">*</IonText> Campos requeridos
                        </IonLabel>
                      </IonItem>
                    </IonCol>
                  </IonRow>
                </IonAccordion>
                <IonAccordion value="5">
                  <IonItem slot="header" color="light">
                    <IonLabel>
                      <h2>
                        <b>Configuración del trabajador</b>
                      </h2>
                    </IonLabel>
                  </IonItem>
                  <IonRow slot="content">
                    <IonCol
                      sizeXl="6"
                      sizeLg="6"
                      sizeMd="6"
                      sizeSm="6"
                      sizeXs="12"
                      size="12"
                    >
                      <IonItem className="modal-input">
                        <IonLabel position="floating" className="modal-label">
                          Fecha de contratación{" "}
                          <IonText color="danger">*</IonText>
                        </IonLabel>
                        <IonIcon
                          slot="start"
                          icon={calendar}
                          class="icon-input-margin"
                        />
                        <IonInput
                          id="empleado-contratacion-date"
                          type="text"
                          clearInput={true}
                          color="secondary"
                          value={fechaContratacion}
                          className="custom-picker"
                        />

                        <IonPopover
                          className="custom-picker"
                          id="empleado-contratacion-picker"
                          animated={false}
                          backdropDismiss={true}
                          trigger="empleado-contratacion-date"
                          triggerAction="click"
                          show-backdrop="true"
                          class="date-picker"
                        >
                          <IonDatetime
                            className="custom-picker"
                            id="empleado-contratacion-datetime"
                            color="primary"
                            presentation="date"
                            placeholder="Seleccionar fecha"
                            min="2017-01-01"
                            value={fechaDeNacimiento}
                            onIonChange={async (e) => {
                              setFechaContratacion(
                                (e.detail.value as string)!.split("T")[0]
                              );
                            }}
                          ></IonDatetime>
                        </IonPopover>
                      </IonItem>
                    </IonCol>
                    <IonCol
                      sizeXl="6"
                      sizeLg="6"
                      sizeMd="6"
                      sizeSm="6"
                      sizeXs="12"
                      size="12"
                    >
                      <IonItem className="modal-input" ref={itemNSS}>
                        <IonIcon
                          slot="start"
                          icon={documentIcon}
                          class="icon-input-margin"
                        />
                        <IonLabel position="floating" className="modal-label">
                          NSS <IonText color="danger">*</IonText>
                        </IonLabel>
                        <IonInput
                          clearInput={true}
                          inputmode="text"
                          type="text"
                          maxlength={20}
                          color="secondary"
                          value={nss}
                          onIonChange={(e) =>
                            onValidateConfiguracion(1, e.detail.value!)
                          }
                        />
                      </IonItem>
                      {showConfiguracionErrorMessage[1].estatus ? (
                        <IonText class="input-error-message">
                          {showConfiguracionErrorMessage[1].msg}
                        </IonText>
                      ) : (
                        <></>
                      )}
                    </IonCol>
                    <IonCol
                      sizeXl="6"
                      sizeLg="6"
                      sizeMd="6"
                      sizeSm="6"
                      sizeXs="12"
                      size="12"
                    >
                      <Selector
                        classStyle="modal-input"
                        value={empleados}
                        label="Empleados que le reportan"
                        list={empleadosList}
                        change={setEmpleados}
                        icon={person}
                        multiple={true}
                      />
                    </IonCol>
                    <IonCol
                      sizeXl="6"
                      sizeLg="6"
                      sizeMd="6"
                      sizeSm="6"
                      sizeXs="12"
                      size="12"
                    >
                      <Selector
                        classStyle="modal-input"
                        value={multicentro ? centrosDeTrabajo : centroDeTrabajo}
                        label="Centros de trabajo"
                        list={centrosDeTrabajoList}
                        change={
                          multicentro ? setCentrosDeTrabajo : setCentroDeTrabajo
                        }
                        icon={business}
                        multiple={multicentro}
                      />
                    </IonCol>
                    <IonCol
                      sizeXl="6"
                      sizeLg="6"
                      sizeMd="6"
                      sizeSm="6"
                      sizeXs="12"
                      size="12"
                    >
                      <Selector
                        classStyle="modal-input"
                        value={tipoEmpleado}
                        label="Tipo de empleado"
                        list={tipoEmpleadoList}
                        change={setTipoEmpleado}
                        icon={person}
                      />
                    </IonCol>
                    <IonCol
                      sizeXl="6"
                      sizeLg="6"
                      sizeMd="6"
                      sizeSm="6"
                      sizeXs="12"
                      size="12"
                    >
                      <IonItem className="modal-input">
                        <IonLabel className="modal-label">
                          Sidicalizado
                        </IonLabel>
                        <IonCheckbox
                          slot="start"
                          checked={sindicalizado}
                          onIonChange={(e) =>
                            setSindicalizado(e.detail.checked)
                          }
                        />
                      </IonItem>
                    </IonCol>
                    <IonCol
                      sizeXl="6"
                      sizeLg="6"
                      sizeMd="6"
                      sizeSm="6"
                      sizeXs="12"
                      size="12"
                    >
                      <IonItem className="modal-input">
                        <IonLabel className="modal-label">Supervisor</IonLabel>
                        <IonCheckbox
                          slot="start"
                          checked={supervisor}
                          onIonChange={(e) => setSupervisor(e.detail.checked)}
                        />
                      </IonItem>
                    </IonCol>
                    <IonCol
                      sizeXl="6"
                      sizeLg="6"
                      sizeMd="6"
                      sizeSm="6"
                      sizeXs="12"
                      size="12"
                    >
                      <IonItem className="modal-input">
                        <IonLabel className="modal-label">Gerente</IonLabel>
                        <IonCheckbox
                          slot="start"
                          checked={gerente}
                          onIonChange={(e) => setGerente(e.detail.checked)}
                        />
                      </IonItem>
                    </IonCol>
                    <IonCol
                      sizeXl="6"
                      sizeLg="6"
                      sizeMd="6"
                      sizeSm="6"
                      sizeXs="12"
                      size="12"
                    >
                      <IonItem className="modal-input">
                        <IonLabel className="modal-label">Multicentro</IonLabel>
                        <IonCheckbox
                          slot="start"
                          checked={multicentro}
                          onIonChange={(e) => setMulticentro(e.detail.checked)}
                        />
                      </IonItem>
                    </IonCol>
                    <IonCol
                      sizeXl="6"
                      sizeLg="6"
                      sizeMd="6"
                      sizeSm="6"
                      sizeXs="12"
                      size="12"
                    >
                      <IonItem className="modal-input">
                        <IonLabel className="modal-label">
                          Accede a historial
                        </IonLabel>
                        <IonCheckbox
                          slot="start"
                          checked={accedeHistorial}
                          onIonChange={(e) =>
                            setAccedeHistorial(e.detail.checked)
                          }
                        />
                      </IonItem>
                    </IonCol>
                    <IonCol
                      sizeXl="6"
                      sizeLg="6"
                      sizeMd="6"
                      sizeSm="6"
                      sizeXs="12"
                      size="12"
                    >
                      <IonItem className="modal-input">
                        <IonLabel className="modal-label">
                          Accede a whatsapp
                        </IonLabel>
                        <IonCheckbox
                          slot="start"
                          checked={accedeWhatsapp}
                          onIonChange={(e) =>
                            setAccedeWhatsapp(e.detail.checked)
                          }
                        />
                      </IonItem>
                    </IonCol>
                    <IonCol
                      sizeXl="6"
                      sizeLg="6"
                      sizeMd="6"
                      sizeSm="6"
                      sizeXs="12"
                      size="12"
                    >
                      <IonItem className="modal-input">
                        <IonLabel className="modal-label">
                          Checa con whatsapp
                        </IonLabel>
                        <IonCheckbox
                          slot="start"
                          checked={checaWhatsapp}
                          onIonChange={(e) =>
                            setChecaWhatsapp(e.detail.checked)
                          }
                        />
                      </IonItem>
                    </IonCol>
                    <IonCol size="12">
                      <IonButton
                        expand="block"
                        color="primary"
                        onClick={() => updateConfiguracion()}
                      >
                        {empleado.estado === DEFAULT_STATE.loading ? (
                          <IonSpinner
                            className="loading-center-horizontal"
                            name="circles"
                            color="medium"
                            slot="start"
                          />
                        ) : (
                          <IonLabel>Actualizar</IonLabel>
                        )}
                      </IonButton>
                      <IonItem lines="none" className="modal-input">
                        <IonLabel>
                          <IonText color="danger">*</IonText> Campos requeridos
                        </IonLabel>
                      </IonItem>
                    </IonCol>
                  </IonRow>
                </IonAccordion>
              </IonAccordionGroup>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

const CreateEmpleadoModal: React.FC<{
  onDismiss: (data?: any, role?: string | undefined) => void;
  data: ICreateEmpleadoData;
  autofocus: number;
  accept?: (data: ICreateEmpleadoData) => void;
}> = ({ onDismiss, data, autofocus, accept }) => {
  const selectedEmpresa = useAppSelector(
    (state) => state.teamMembers.selectedEmpresa
  );

  const defaultErrorMessages = [
    "Por favor un ingrese un nombre válido.",
    "Por favor un ingrese un apellido válido.",
    "Por favor un ingrese un correo válido.",
    "Por favor un ingrese un teléfono válido.",
  ];
  const [createUserPrimerNombre, setCreateUserPrimerNombre] = useState("");
  const [createUserSegundoNombre, setCreateUserSegundoNombre] = useState("");
  const [createUserApellidoPaterno, setCreateUserApellidoPaterno] =
    useState("");
  const [createUserApellidoMaterno, setCreateUserApellidoMaterno] =
    useState("");
  const [createUserEmail, setCreateUserEmail] = useState("");
  const [createUserTelefonoCelular, setCreateUserTelefonoCelular] =
    useState("+52");

  const itemPrimerNombre = useRef<HTMLIonItemElement>(null);
  const itemApellidoPaterno = useRef<HTMLIonItemElement>(null);
  const itemEmail = useRef<HTMLIonItemElement>(null);
  const itemTelefonoCelular = useRef<HTMLIonItemElement>(null);
  const inputPrimerNombre = useRef<HTMLIonInputElement>(null);
  const inputApellidoPaterno = useRef<HTMLIonInputElement>(null);
  const inputEmail = useRef<HTMLIonInputElement>(null);
  const inputTelefonoCelular = useRef<HTMLIonInputElement>(null);

  const [showErrorMessage, setShowErrorMessage] = useState<
    { estatus: boolean; msg: string }[]
  >(defaultErrorMessages.map((v) => ({ estatus: false, msg: v })));
  const [dataHasErrors, setDataHasErrors] = useState<boolean>(true);
  const [loadingValidate, setLoadingValidate] = useState<boolean>(false);

  const disableAceptar = () => {
    const notValid = showErrorMessage.some((v) => v.estatus);
    if (notValid) return true;
    const primerNombreValid =
      createUserPrimerNombre && createUserPrimerNombre.trim();
    if (!primerNombreValid) return true;
    const apellidoPaternoValid =
      createUserApellidoPaterno && createUserApellidoPaterno.trim();
    if (!apellidoPaternoValid) return true;
    const emailValid = createUserEmail && createUserEmail.trim();
    if (!emailValid) return true;
    const telefonoCelularValid =
      createUserTelefonoCelular && createUserTelefonoCelular.trim();
    if (!telefonoCelularValid) return true;
    if (
      !data &&
      (dataHasErrors || selectedEmpresa.id === "" || loadingValidate)
    )
      return true;
    return false;
  };

  const onValidate = (id: number, value: any) => {
    if (!itemPrimerNombre.current) return;
    if (!itemApellidoPaterno.current) return;
    if (!itemEmail.current) return;
    if (!itemTelefonoCelular.current) return;

    switch (id) {
      case 0:
        const testPrimerNombre = !/.+/.test(value.trim());
        if (testPrimerNombre) itemPrimerNombre.current.classList.add("error");
        else itemPrimerNombre.current.classList.remove("error");
        setShowErrorMessage(
          showErrorMessage.map((e, i) => {
            if (i !== id) return e;
            return { estatus: testPrimerNombre, msg: e.msg };
          })
        );
        break;
      case 1:
        const testApellidoPaterno = !/.+/.test(value.trim());
        if (testApellidoPaterno)
          itemApellidoPaterno.current.classList.add("error");
        else itemApellidoPaterno.current.classList.remove("error");
        setShowErrorMessage(
          showErrorMessage.map((e, i) => {
            if (i !== id) return e;
            return { estatus: testApellidoPaterno, msg: e.msg };
          })
        );
        break;
      case 2:
        const testEmail = !/^[^\s@]+@[^\s@]+\.?[^\s@]+$/.test(value.trim());
        if (testEmail) itemEmail.current.classList.add("error");
        else itemEmail.current.classList.remove("error");
        setShowErrorMessage(
          showErrorMessage.map((e, i) => {
            if (i !== id) return e;
            return { estatus: testEmail, msg: e.msg };
          })
        );
        break;
      case 3:
        const testTelefonoCelular =
          !/^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/.test(value.trim());
        if (testTelefonoCelular)
          itemTelefonoCelular.current.classList.add("error");
        else itemTelefonoCelular.current.classList.remove("error");
        setShowErrorMessage(
          showErrorMessage.map((e, i) => {
            if (i !== id) return e;
            return { estatus: testTelefonoCelular, msg: e.msg };
          })
        );
        break;
    }
  };

  const validate = async () => {
    setLoadingValidate(true);
    const actual: ICreateEmpleadoData[] = [
      {
        id_empresa: Number(selectedEmpresa.id),
        uuid: "",
        primer_nombre: createUserPrimerNombre,
        segundo_nombre: createUserSegundoNombre,
        apellido_paterno: createUserApellidoPaterno,
        apellido_materno: createUserApellidoMaterno,
        telefono_celular: createUserTelefonoCelular,
        email: createUserEmail,
      },
    ];
    setDataHasErrors(false);

    const res = await qentry3ValidateUsers(actual);

    setLoadingValidate(false);
    if (res.data.result) {
      const temp = res.data.result as ICreateEmpleadoData[];
      if (res.data.hasError) setDataHasErrors(true);
      if (!data && res.data.hasError) {
        setShowErrorMessage(
          showErrorMessage.map((e, i) => {
            if (!temp[0].errors) return e;
            const index = temp[0].errors.findIndex((v) => v.id === i);
            switch (i) {
              case 0:
                if (index !== -1)
                  itemPrimerNombre.current?.classList.add("error");
                else itemPrimerNombre.current?.classList.remove("error");
                break;
              case 1:
                if (index !== -1)
                  itemApellidoPaterno.current?.classList.add("error");
                else itemApellidoPaterno.current?.classList.remove("error");
                break;
              case 2:
                if (index !== -1) itemEmail.current?.classList.add("error");
                else itemEmail.current?.classList.remove("error");
                break;
              case 3:
                if (index !== -1)
                  itemTelefonoCelular.current?.classList.add("error");
                else itemTelefonoCelular.current?.classList.remove("error");
                break;
            }
            if (index === -1) return e;
            return { estatus: true, msg: temp[0].errors[index].msg };
          })
        );
      }
      /*
      setShowErrorMessage(showErrorMessage.map((e, i) => {
          if (i !== id) return e;
          return {estatus: testPrimerNombre, msg: e.msg};
        }));
      */
      return temp;
    }
    const invalidEmpresa = actual.some((e) => e.id_empresa === 0);
    if (invalidEmpresa) setDataHasErrors(true);
  };

  useEffect(() => {
    if (!data) return;
    setCreateUserPrimerNombre(data.primer_nombre);
    setCreateUserSegundoNombre(data.segundo_nombre);
    setCreateUserApellidoPaterno(data.apellido_paterno);
    setCreateUserApellidoMaterno(data.apellido_materno);
    setCreateUserEmail(data.email);
    setCreateUserTelefonoCelular(data.telefono_celular);
  }, [data]);

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar color="secondary" className="toolBarStart">
          <IonButtons slot="start">
            <IonButton onClick={() => onDismiss()}>Cancelar</IonButton>
          </IonButtons>
          <IonTitle className="toolBarStart center">Empleado</IonTitle>
          <IonButtons slot="end">
            <IonButton
              strong={true}
              disabled={disableAceptar()}
              onClick={() => {
                if (accept)
                  accept({
                    id_empresa: data
                      ? data.id_empresa
                      : Number(selectedEmpresa.id),
                    uuid: data ? data.uuid : "",
                    primer_nombre: createUserPrimerNombre,
                    segundo_nombre: createUserSegundoNombre,
                    apellido_paterno: createUserApellidoPaterno,
                    apellido_materno: createUserApellidoMaterno,
                    telefono_celular: createUserTelefonoCelular,
                    email: createUserEmail,
                  });
                onDismiss();
              }}
            >
              Aceptar
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent className="ion-padding">
        {!data && selectedEmpresa.id === "" ? (
          <IonItem lines="none" color="danger">
            <IonLabel>
              <h2>
                <b>Por favor seleccione una empresa</b>
              </h2>
            </IonLabel>
          </IonItem>
        ) : (
          <></>
        )}
        <IonItem lines="none" className="modal-input">
          <IonLabel>
            <h2>
              <b>Datos del trabajador </b>
            </h2>
          </IonLabel>
        </IonItem>
        <IonGrid>
          <IonRow>
            <IonCol size="12">
              <IonItem className="modal-input" ref={itemPrimerNombre}>
                <IonIcon slot="start" icon={person} class="icon-input-margin" />
                <IonLabel position="floating" className="modal-label">
                  Primer nombre <IonText color="danger">*</IonText>
                </IonLabel>
                <IonInput
                  clearInput={true}
                  disabled={!data && selectedEmpresa.id === ""}
                  ref={inputPrimerNombre}
                  inputmode="text"
                  type="text"
                  color="secondary"
                  value={createUserPrimerNombre}
                  onIonChange={(e) => {
                    setCreateUserPrimerNombre(e.detail.value!);
                    onValidate(0, e.detail.value!);
                  }}
                />
              </IonItem>
              {showErrorMessage[0].estatus ? (
                <IonText class="input-error-message">
                  {showErrorMessage[0].msg}
                </IonText>
              ) : (
                <></>
              )}
            </IonCol>
            <IonCol size="12">
              <IonItem className="modal-input">
                <IonIcon slot="start" icon={person} class="icon-input-margin" />
                <IonLabel position="floating" className="modal-label">
                  Segundo nombre
                </IonLabel>
                <IonInput
                  clearInput={true}
                  disabled={!data && selectedEmpresa.id === ""}
                  inputmode="text"
                  type="text"
                  value={createUserSegundoNombre}
                  onIonChange={(e) =>
                    setCreateUserSegundoNombre(e.detail.value!)
                  }
                />
              </IonItem>
            </IonCol>
            <IonCol size="12">
              <IonItem className="modal-input" ref={itemApellidoPaterno}>
                <IonIcon slot="start" icon={person} class="icon-input-margin" />
                <IonLabel position="floating" className="modal-label">
                  Apellido paterno <IonText color="danger">*</IonText>
                </IonLabel>
                <IonInput
                  clearInput={true}
                  disabled={!data && selectedEmpresa.id === ""}
                  ref={inputApellidoPaterno}
                  inputmode="text"
                  type="text"
                  value={createUserApellidoPaterno}
                  onIonChange={(e) => {
                    setCreateUserApellidoPaterno(e.detail.value!);
                    onValidate(1, e.detail.value!);
                  }}
                />
              </IonItem>
              {showErrorMessage[1].estatus ? (
                <IonText class="input-error-message">
                  {showErrorMessage[1].msg}
                </IonText>
              ) : (
                <></>
              )}
            </IonCol>
            <IonCol size="12">
              <IonItem className="modal-input">
                <IonIcon slot="start" icon={person} class="icon-input-margin" />
                <IonLabel position="floating" className="modal-label">
                  Apellido materno
                </IonLabel>
                <IonInput
                  clearInput={true}
                  disabled={!data && selectedEmpresa.id === ""}
                  inputmode="text"
                  type="text"
                  value={createUserApellidoMaterno}
                  onIonChange={(e) =>
                    setCreateUserApellidoMaterno(e.detail.value!)
                  }
                />
              </IonItem>
            </IonCol>
            <IonCol size="12">
              <IonItem className="modal-input" ref={itemEmail}>
                <IonIcon slot="start" icon={mail} class="icon-input-margin" />
                <IonLabel position="floating" className="modal-label">
                  Correo <IonText color="danger">*</IonText>
                </IonLabel>
                <IonInput
                  clearInput={true}
                  disabled={!data && selectedEmpresa.id === ""}
                  ref={inputEmail}
                  inputmode="email"
                  type="email"
                  value={createUserEmail}
                  onIonChange={(e) => {
                    setCreateUserEmail(e.detail.value!);
                    onValidate(2, e.detail.value!);
                  }}
                />
              </IonItem>
              {showErrorMessage[2].estatus ? (
                <IonText class="input-error-message">
                  {showErrorMessage[2].msg}
                </IonText>
              ) : (
                <></>
              )}
            </IonCol>
            <IonCol size="12">
              <IonItem className="modal-input" ref={itemTelefonoCelular}>
                <IonIcon
                  slot="start"
                  icon={phonePortrait}
                  class="icon-input-margin"
                />
                <IonLabel position="floating" className="modal-label">
                  Teléfono celular<IonText color="danger">*</IonText>
                </IonLabel>
                <IonInput
                  clearInput={true}
                  disabled={!data && selectedEmpresa.id === ""}
                  placeholder={"+522215778772"}
                  ref={inputTelefonoCelular}
                  inputmode="tel"
                  type="tel"
                  value={createUserTelefonoCelular}
                  onIonChange={(e) => {
                    setCreateUserTelefonoCelular(e.detail.value!);
                    onValidate(3, e.detail.value!);
                  }}
                />
              </IonItem>
              {showErrorMessage[3].estatus ? (
                <IonText class="input-error-message">
                  {showErrorMessage[3].msg}
                </IonText>
              ) : (
                <></>
              )}
            </IonCol>
          </IonRow>
        </IonGrid>
        {!data ? (
          <IonButton
            expand="block"
            disabled={selectedEmpresa.id === "" || loadingValidate}
            onClick={async () => await validate()}
          >
            {loadingValidate ? (
              <IonSpinner
                className="loading-center-horizontal"
                name="circles"
                color="medium"
                slot="start"
              />
            ) : (
              <IonLabel>Validar datos</IonLabel>
            )}
          </IonButton>
        ) : (
          <></>
        )}
        <IonItem lines="none" className="modal-input">
          <IonLabel>
            <IonText color="danger">*</IonText> Campos requeridos
          </IonLabel>
        </IonItem>
      </IonContent>
    </IonPage>
  );
};

const BulkLoadEmpleadoModal: React.FC<{
  dismiss: () => void;
  accept?: (data: ICreateEmpleadoData[]) => void;
}> = ({ dismiss, accept }) => {
  const [{ clienteQentry }] = useRedux();
  const [presentToast, dismissToast] = useIonToast();

  const empresaList = useAppSelector(getGlobalMemoizedEmpresaAsSelectList);
  const selectedEmpresa = useAppSelector(
    (state) => state.teamMembers.selectedEmpresa
  );

  interface BulkLoadEmpleadoModal extends IDefaultTable {
    selectedEmpresa: List;
    selectedEmpleados: List[];
  }
  const clone = (({ selectedCentroDeTrabajo, ...o }) => o)(
    teamMembersInitialState.activeTable
  );
  const [bulkLoadData, setBulkLoadData] = useState<ICreateEmpleado>({
    id_clienteQentry: clienteQentry.data.id,
    empleados: [],
  });
  const [tableState, setTableState] = useState<BulkLoadEmpleadoModal>({
    selectedEmpresa: teamMembersInitialState.selectedEmpresa,
    totalPages: 1,
    pageMessage: "0 - 0 de 0",
    ...clone,
  });
  const [filteredEmpleados, setFilteredEmpleados] = useState<
    ICreateEmpleadoData[]
  >([]);
  const [empleadoToEdit, setEmpleadoToEdit] = useState<{
    data: IEmpleadoData;
    autofocus: number;
  } | null>(null);
  const [dataHasErrors, setDataHasErrors] = useState<boolean>(false);
  const [empleadoErrors, setEmpleadoErrors] = useState<{
    e: any;
    data: ICreateEmpleadoData;
  }>();
  const [loadingEmpleados, setLoadingEmpleados] = useState<boolean>(false);
  const firstItem = (tableState.page - 1) * tableState.maxPerPage;
  const lastItem = firstItem + tableState.maxPerPage - 1;

  const changeEmpleadoEmpresa = (uuid: string, id_empresa: number) => {
    setDataHasErrors(false);
    const temp = bulkLoadData.empleados.map((e) => {
      if (e.uuid !== uuid) return e;
      else return { ...e, id_empresa };
    });
    setBulkLoadData({
      ...bulkLoadData,
      empleados: temp,
    });
    const hasError = temp.some((e) => e.errors);
    const invalidEmpresa = temp.some((e) => e.id_empresa === 0);
    if (invalidEmpresa || hasError) setDataHasErrors(true);
  };

  const FilterPopover: React.FC = () => {
    return (
      <IonContent>
        <IonList>
          {empresaList.map((empresa) => {
            return (
              <IonItem
                key={`empresa-bulk-load-${empresa.id}`}
                color={
                  tableState.selectedEmpresa.id === empresa.id
                    ? "primary"
                    : undefined
                }
                type="button"
                lines="full"
                button={true}
                detail={false}
                onClick={() => {
                  dismissFilterPopover();
                  setTableState({ ...tableState, selectedEmpresa: empresa });
                }}
              >
                <IonLabel>{empresa.title}</IonLabel>
              </IonItem>
            );
          })}
        </IonList>
      </IonContent>
    );
  };

  const OptionsPopover: React.FC = () => {
    return (
      <IonContent>
        <IonList>
          <IonItem
            lines="full"
            button={true}
            type="button"
            detail={false}
            disabled={tableState.remove}
            onClick={() => {
              dismissOptionsPopover();
              setTableState({
                ...tableState,
                remove: true,
                selectedEmpleados: [],
              });
            }}
          >
            <IonText>
              <h6>Quitar registros</h6>
              <p>
                <i>
                  Elimina registros sin necesidad de volver a subir el archivo
                  de nuevo
                </i>
              </p>
            </IonText>
          </IonItem>
        </IonList>
      </IonContent>
    );
  };

  const ErrorsPopover: React.FC<{
    onDismiss: (data?: any, role?: string | undefined) => void;
    data: ICreateEmpleadoData;
  }> = ({ onDismiss, data }) => {
    return (
      <IonContent>
        <IonList>
          {data &&
            data.errors &&
            data.errors.map((e, i) => (
              <IonItem
                key={`empleado-errors-${i}`}
                lines="full"
                button={true}
                type="button"
                detail={false}
                onClick={() => {
                  onDismiss();
                  setEmpleadoToEdit({ data: data, autofocus: e.id });
                }}
              >
                {e.msg}
              </IonItem>
            ))}
          {data.id_empresa === 0 ? (
            <IonItem
              lines="full"
              button={true}
              type="button"
              detail={false}
              onClick={() => {
                onDismiss();
                presentEmpresasList({
                  buttons: [
                    ...empresaList.map((v) => ({
                      text: v.title,
                      handler: () => {
                        changeEmpleadoEmpresa(data.uuid, Number(v.id));
                        dismissEmpresasList();
                      },
                    })),
                    { text: "Cancelar", role: "cancel" },
                  ],
                  header: "Seleccionar empresa",
                });
              }}
            >
              Seleccione una empresa
            </IonItem>
          ) : (
            <></>
          )}
        </IonList>
      </IonContent>
    );
  };

  const filter = () => {
    const search = tableState.searchText.toLocaleLowerCase().trim();
    if (!search && tableState.selectedEmpresa.id === "")
      return bulkLoadData.empleados;
    const empresa = Number(tableState.selectedEmpresa.id);
    return bulkLoadData.empleados.filter((e) => {
      if (empresa !== 0 && e.id_empresa !== empresa) return false;
      if (!search) return true;
      return `${e.primer_nombre}${
        e.segundo_nombre ? e.segundo_nombre + " " : " "
      }${e.apellido_paterno} ${e.apellido_materno}`
        .toLocaleLowerCase()
        .includes(search);
    });
  };

  const validateData = async (data: ICreateEmpleadoData[]) => {
    setLoadingEmpleados(true);
    try {
      const res = await qentry3ValidateUsers(data);
      setLoadingEmpleados(false);
      setDataHasErrors(false);
      if (res.data.result) {
        const temp = res.data.result as ICreateEmpleadoData[];
        const invalidEmpresa = temp.some((e) => e.id_empresa === 0);
        console.log("(res.data.hasError) onIonChange: ", res.data.hasError);
        console.log("(invalidEmpresa) onIonChange: ", invalidEmpresa);
        if (invalidEmpresa || res.data.hasError) setDataHasErrors(true);
        return temp;
      }
      const invalidEmpresa = data.some((e) => e.id_empresa === 0);
      if (invalidEmpresa) setDataHasErrors(true);
    } catch (e) {
      presentToast({
        message: "Ocurrió un problema al validar los datos",
        color: "danger",
        duration: 2500,
      });
    }

    return data;
  };

  useEffect(() => {
    setFilteredEmpleados(filter());
    console.log("(bulkLoadData) onIonChange: ", bulkLoadData);
  }, [bulkLoadData]);

  useEffect(() => {
    setFilteredEmpleados(filter());
    setTableState({
      ...tableState,
      loading: false,
    });
    console.log("(filteredEmpleados) onIonChange: ", filteredEmpleados);
  }, [tableState.searchText, tableState.selectedEmpresa]);

  useEffect(() => {
    let value = Math.trunc(filteredEmpleados.length / tableState.maxPerPage);
    if (filteredEmpleados.length % tableState.maxPerPage > 0) value++;
    setTableState({
      ...tableState,
      totalPages: value,
    });
  }, [filteredEmpleados]);

  useEffect(() => {
    setTableState({
      ...tableState,
      pageMessage: `${firstItem + 1} - ${
        filteredEmpleados.length < lastItem
          ? filteredEmpleados.length
          : lastItem + 1
      } de ${filteredEmpleados.length}`,
    });
  }, [tableState.page, filteredEmpleados]);

  useEffect(() => {
    if (empleadoToEdit !== null) presentBulkLoadEditEmpleadoModal();
  }, [empleadoToEdit]);

  useEffect(() => {
    if (empleadoErrors) presentErrorsPopover({ event: empleadoErrors.e });
  }, [empleadoErrors]);

  const [presentFilterPopover, dismissFilterPopover] =
    useIonPopover(FilterPopover);
  const [presentOptionsPopover, dismissOptionsPopover] =
    useIonPopover(OptionsPopover);
  const [presentErrorsPopover, dismissErrorsPopover] = useIonPopover(
    ErrorsPopover,
    { data: empleadoErrors?.data, onDismiss: () => dismissErrorsPopover() }
  );

  const [presentBulkLoadEditEmpleadoModal, dismissBulkLoadEditEmpleadoModal] =
    useIonModal(CreateEmpleadoModal, {
      data: empleadoToEdit?.data,
      autofocus: empleadoToEdit?.autofocus,
      onDismiss: () => {
        dismissBulkLoadEditEmpleadoModal();
        setEmpleadoToEdit(null);
      },
      accept: async (data: ICreateEmpleadoData) => {
        const temp = bulkLoadData.empleados.map((e) => {
          if (e.uuid !== data.uuid) return e;
          return data;
        });
        setBulkLoadData({
          ...bulkLoadData,
          empleados: await validateData(temp),
        });
        setEmpleadoToEdit(null);
      },
    });

  const [presentEmpresasList, dismissEmpresasList] = useIonActionSheet();

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar color="secondary" className="toolBarStart">
          <IonButtons slot="start">
            <IonButton onClick={() => dismiss()}>Cancelar</IonButton>
          </IonButtons>
          <IonTitle className="toolBarStart center">Carga masiva</IonTitle>
          <IonButtons slot="end">
            <IonButton
              strong={true}
              disabled={loadingEmpleados || dataHasErrors}
              onClick={async () => {
                if (accept) accept(bulkLoadData.empleados);
              }}
            >
              Aceptar
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonItem lines="none" color="primary">
          Descargar formato de ejemplo
          <IonButton
            slot="end"
            fill="clear"
            download="Layout_Carga_Empleados"
            href="https://qentry3.sfo3.digitaloceanspaces.com/Layout_Carga_Empleados.xlsx"
          >
            <IonIcon slot="icon-only" color="secondary" icon={download} />
          </IonButton>
        </IonItem>
        <IonGrid className="no-padding">
          <IonRow className="no-padding">
            <IonCol size="12">
              <IonItem lines="none" className="modal-input">
                <IonLabel>
                  <h2>
                    <b>Seleccione el archivo </b>
                  </h2>
                </IonLabel>
              </IonItem>
              <IonItem className="modal-input">
                <input
                  className="full-width"
                  type="file"
                  accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                  id="bulk-load-input"
                  onChange={(e: any) => {
                    readXlsxFile(e.target.files[0]).then(async (rows) => {
                      // `rows` is an array of rows
                      // each row being an array of cells.
                      let result = rows
                        .filter((e, id) => id > 3)
                        .map(
                          (array: any[]) =>
                            ({
                              id_empresa: Number(selectedEmpresa.id),
                              uuid: `temp_${[...Array(32)]
                                .map(() => Math.random().toString(36).slice(2))
                                .join("")}`,
                              primer_nombre: array[0]
                                ? String(array[0]).trim()
                                : "",
                              segundo_nombre: array[1]
                                ? String(array[1]).trim()
                                : "",
                              apellido_paterno: array[2]
                                ? String(array[2]).trim()
                                : "",
                              apellido_materno: array[3]
                                ? String(array[3]).trim()
                                : "",
                              telefono_celular: array[4]
                                ? String(array[4]).trim()
                                : "",
                              email: array[5] ? String(array[5]).trim() : "",
                              selected_empresa: selectedEmpresa,
                            } as ICreateEmpleadoData)
                        );

                      result = await validateData(result);

                      setBulkLoadData({
                        ...bulkLoadData,
                        empleados: [...result],
                      });
                      setFilteredEmpleados([...result]);
                    });
                  }}
                />
                <IonButton
                  slot="end"
                  fill="clear"
                  onClick={() => {
                    const input = document.getElementById(
                      "bulk-load-input"
                    ) as HTMLInputElement;
                    if (input) {
                      input.value = "";
                      setBulkLoadData({ ...bulkLoadData, empleados: [] });
                    }
                  }}
                >
                  <IonIcon slot="icon-only" icon={close} />
                </IonButton>
              </IonItem>
              {loadingEmpleados ? (
                <>
                  <IonProgressBar type="indeterminate" color="primary" />
                </>
              ) : (
                <></>
              )}
            </IonCol>
            {bulkLoadData.empleados.length > 0 ? (
              <IonCol size="12" className="ion-padding">
                <IonItem
                  lines="none"
                  color={dataHasErrors ? "danger" : "primary"}
                >
                  <IonText>
                    {dataHasErrors
                      ? "Por favor revise los detalles."
                      : "Listo para subir"}
                  </IonText>
                  {dataHasErrors ? (
                    <IonButton
                      slot="end"
                      color="secondary"
                      fill="outline"
                      disabled={loadingEmpleados}
                      onClick={async () => {
                        setBulkLoadData({
                          ...bulkLoadData,
                          empleados: [
                            ...(await validateData(bulkLoadData.empleados)),
                          ],
                        });
                      }}
                    >
                      <IonText color="secondary">Validar</IonText>
                    </IonButton>
                  ) : (
                    <></>
                  )}
                </IonItem>
                <IonItem className="accordion-header" lines="full">
                  <IonLabel>Lista de trabajadores a crear</IonLabel>
                </IonItem>
                <IonList lines="full">
                  <IonItem color="light" className="table-helper">
                    <IonButton
                      slot="start"
                      fill="clear"
                      shape="round"
                      size="small"
                      onClick={(e: any) => presentFilterPopover({ event: e })}
                    >
                      <IonIcon slot="icon-only" icon={filterOutline} />
                    </IonButton>
                    <IonSearchbar
                      color="light"
                      class="search-bar"
                      debounce={400}
                      onIonChange={(e) => {
                        setTableState({
                          ...tableState,
                          loading: true,
                          page: 1,
                          searchText: e.detail.value!,
                        });
                      }}
                      placeholder="Nombre"
                      className="search-bar-padding"
                    />
                    <IonButtons slot="end">
                      <IonButton
                        fill="clear"
                        shape="round"
                        size="small"
                        onClick={(e: any) =>
                          presentOptionsPopover({ event: e })
                        }
                      >
                        <IonIcon slot="icon-only" icon={ellipsisVertical} />
                      </IonButton>
                    </IonButtons>
                  </IonItem>
                  {tableState.remove ? (
                    <IonItem color="tertiary">
                      <IonCheckbox
                        slot="start"
                        disabled={filteredEmpleados.length === 0}
                        indeterminate={tableState.isIndeterminate}
                        checked={tableState.selectAll}
                        onIonChange={(e) => {
                          if (tableState.selectAll === e.detail.checked) return;
                          let newSelectedEmpleados: List[] = [];
                          if (e.detail.checked) {
                            newSelectedEmpleados = [
                              ...tableState.selectedEmpleados,
                            ];
                            filteredEmpleados.forEach((item) => {
                              const index = newSelectedEmpleados.findIndex(
                                (e) => e.id === item.uuid
                              );
                              if (index === -1)
                                newSelectedEmpleados.push(
                                  setListItem({
                                    id: item.uuid,
                                    title: item.primer_nombre,
                                  })
                                );
                            });
                            setTableState({
                              ...tableState,
                              selectedEmpleados: newSelectedEmpleados,
                            });
                          } else {
                            setTableState({
                              ...tableState,
                              selectedEmpleados: newSelectedEmpleados,
                            });
                          }
                        }}
                      />

                      <IonLabel>{tableState.selectedEmpleados.length}</IonLabel>
                      <IonButtons slot="end">
                        <IonButton
                          disabled={tableState.selectedEmpleados.length < 1}
                          onClick={() => {
                            setBulkLoadData({
                              ...bulkLoadData,
                              empleados: bulkLoadData.empleados.filter(
                                (s) =>
                                  tableState.selectedEmpleados.findIndex(
                                    (v) => v.id === s.uuid
                                  ) === -1
                              ),
                            });
                            setTableState({
                              ...tableState,
                              remove: false,
                              selectedEmpleados: [],
                            });
                          }}
                        >
                          <IonIcon
                            color="primary"
                            slot="icon-only"
                            icon={checkmark}
                          />
                        </IonButton>
                        <IonButton
                          onClick={() =>
                            setTableState({
                              ...tableState,
                              remove: false,
                              selectedEmpleados: [],
                            })
                          }
                        >
                          <IonIcon
                            color="danger"
                            slot="icon-only"
                            icon={close}
                          />
                        </IonButton>
                      </IonButtons>
                    </IonItem>
                  ) : (
                    <></>
                  )}
                  <IonItem color="secondary">
                    <IonGrid>
                      <IonRow>
                        <IonCol
                          size="12"
                          sizeXs="12"
                          sizeSm="12"
                          sizeMd="6"
                          sizeLg="6"
                          sizeXl="6"
                        >
                          <IonLabel class="ion-hide-md-down">Nombre</IonLabel>
                          <IonLabel class="ion-hide-md-up">
                            Nombre / Empresa
                          </IonLabel>
                        </IonCol>
                        <IonCol size="6" class="ion-hide-md-down">
                          <IonLabel>Empresa</IonLabel>
                        </IonCol>
                      </IonRow>
                    </IonGrid>
                  </IonItem>
                  {tableState.loading ? (
                    <>
                      <IonProgressBar type="indeterminate" color="primary" />
                    </>
                  ) : (
                    <></>
                  )}
                  {filteredEmpleados.length > 0 &&
                  tableState.selectedEmpresa ? (
                    filteredEmpleados.map((e, id) => {
                      const show = id <= lastItem && id >= firstItem;
                      return show ? (
                        <IonItem
                          className="no-adding"
                          key={`bulk-load-modal-empleado-${id}-${e.uuid}`}
                        >
                          {tableState.remove ? (
                            <IonCheckbox
                              checked={
                                tableState.selectedEmpleados.findIndex(
                                  (v) => v.id === e.uuid
                                ) !== -1
                              }
                              slot="start"
                              onIonChange={(v) => {
                                const item = setListItem({
                                  id: e.uuid,
                                  title: e.primer_nombre,
                                });
                                const index =
                                  tableState.selectedEmpleados.findIndex(
                                    (v) => v.id === e.uuid
                                  );
                                if (v.detail.checked && index === -1) {
                                  setTableState({
                                    ...tableState,
                                    selectedEmpleados: [
                                      ...tableState.selectedEmpleados,
                                      item,
                                    ],
                                  });
                                } else if (!v.detail.checked && index !== -1) {
                                  setTableState({
                                    ...tableState,
                                    selectedEmpleados:
                                      tableState.selectedEmpleados.filter(
                                        (e) => e.id !== item.id
                                      ),
                                  });
                                }
                              }}
                            />
                          ) : (
                            <IonButton
                              slot="start"
                              fill="clear"
                              class="bulk-load-empleado-status"
                              onClick={(v) =>
                                e.errors || e.id_empresa === 0
                                  ? setEmpleadoErrors({ e: v, data: e })
                                  : {}
                              }
                            >
                              <IonIcon
                                slot="icon-only"
                                color={
                                  e.errors || e.id_empresa === 0
                                    ? "danger"
                                    : "primary"
                                }
                                icon={
                                  e.errors || e.id_empresa === 0
                                    ? informationCircle
                                    : checkmark
                                }
                              />
                            </IonButton>
                          )}
                          <IonGrid>
                            <IonRow>
                              <IonCol
                                size="12"
                                sizeXs="12"
                                sizeSm="12"
                                sizeMd="6"
                                sizeLg="6"
                                sizeXl="6"
                              >
                                <IonText>
                                  <h6>{`${e.primer_nombre} ${e.segundo_nombre} ${e.apellido_paterno} ${e.apellido_materno}`}</h6>
                                </IonText>
                                <IonText
                                  color="tertiary"
                                  class="ion-hide-md-up"
                                  onClick={() =>
                                    presentEmpresasList({
                                      buttons: [
                                        ...empresaList.map((v) => ({
                                          text: v.title,
                                          handler: () => {
                                            changeEmpleadoEmpresa(
                                              e.uuid,
                                              Number(v.id)
                                            );
                                            dismissEmpresasList();
                                          },
                                        })),
                                        { text: "Cancelar", role: "cancel" },
                                      ],
                                      header: "Seleccionar empresa",
                                    })
                                  }
                                >
                                  <IonText autoCapitalize="true">
                                    <h6>
                                      {
                                        empresaList.find(
                                          (v) => Number(v.id) === e.id_empresa
                                        )?.title
                                      }
                                    </h6>
                                  </IonText>
                                  <IonIcon slot="end" icon={chevronForward} />
                                </IonText>
                              </IonCol>
                              <IonCol size="6" class="ion-hide-md-down">
                                <IonText
                                  color="tertiary"
                                  onClick={() =>
                                    presentEmpresasList({
                                      buttons: [
                                        ...empresaList.map((v) => ({
                                          text: v.title,
                                          handler: () => {
                                            changeEmpleadoEmpresa(
                                              e.uuid,
                                              Number(v.id)
                                            );
                                            dismissEmpresasList();
                                          },
                                        })),
                                        { text: "Cancelar", role: "cancel" },
                                      ],
                                      header: "Seleccionar empresa",
                                    })
                                  }
                                >
                                  <IonText autoCapitalize="true">
                                    <h6>
                                      {
                                        empresaList.find(
                                          (v) => Number(v.id) === e.id_empresa
                                        )?.title
                                      }
                                    </h6>
                                  </IonText>
                                  <IonIcon slot="end" icon={chevronForward} />
                                </IonText>
                              </IonCol>
                            </IonRow>
                          </IonGrid>
                          {!tableState.remove ? (
                            <IonButton
                              slot="end"
                              fill="clear"
                              onClick={() =>
                                setEmpleadoToEdit({ data: e, autofocus: 0 })
                              }
                            >
                              <IonIcon
                                slot="icon-only"
                                icon={pencil}
                                color="primary"
                              />
                            </IonButton>
                          ) : (
                            <></>
                          )}
                        </IonItem>
                      ) : (
                        <IonItem
                          key={`bulk-load-modal-empleado-hidden-${id}-${e.uuid}`}
                          class="item-hide"
                        />
                      );
                    })
                  ) : (
                    <IonItem>
                      <IonLabel>No hay empleados</IonLabel>
                    </IonItem>
                  )}
                  <IonItem lines="full">
                    <IonLabel slot="end" className="ion-text-right">
                      {tableState.pageMessage}
                    </IonLabel>
                    <IonButtons slot="end">
                      <IonButton
                        disabled={tableState.page === 1}
                        class="grouped-buttons"
                        onClick={() =>
                          setTableState({
                            ...tableState,
                            page: tableState.page + 1,
                          })
                        }
                      >
                        <IonIcon slot="icon-only" icon={chevronBackSharp} />
                      </IonButton>
                      <IonButton
                        disabled={tableState.page >= tableState.totalPages}
                        class="grouped-buttons"
                        onClick={() =>
                          setTableState({
                            ...tableState,
                            page: tableState.page - 1,
                          })
                        }
                      >
                        <IonIcon slot="icon-only" icon={chevronForwardSharp} />
                      </IonButton>
                    </IonButtons>
                  </IonItem>
                </IonList>
              </IonCol>
            ) : (
              <></>
            )}
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export { EditEmpleadoModal, CreateEmpleadoModal, BulkLoadEmpleadoModal };
