/**
 * Métodos AsyncThunk para ser usados en los reducers y
 * los resultados a las llamadas http puedan ser usados para
 * actualizar el state en redux
 */

import { createAsyncThunk } from "@reduxjs/toolkit";
import { DEFAULT_STATE } from "../../enums/default_state";
import { getCheckpointRondin, getAllCheckpointRondin, updateCheckpointRondin, createCheckpointRondin, deleteCheckpointRondin } from "../../http/rondines";
import { getEmptyStatusCheckpointRondin, ICheckpointRondin } from "../../interfaces/rondines";
import { IServerDate } from "../../interfaces/time";

export const getCheckpointRondinAsyncThunk = createAsyncThunk(
  'rondines/getCheckpointRondinAsyncThunk',
  async (query : {serverDate : IServerDate, id: number}) => await getCheckpointRondin(query.serverDate, query.id)
    .then(async (result) => result)
    .catch(async (error) => getEmptyStatusCheckpointRondin(DEFAULT_STATE.error))  
);

export const getAllCheckpointRondinAsyncThunk = createAsyncThunk(
  'rondines/getAllCheckpointRondinAsyncThunk',
  async (queryAll : {serverDate : IServerDate, idCliente : number}) => await getAllCheckpointRondin(queryAll.serverDate, queryAll.idCliente)
    .then(async (result) => result)
    .catch(async (error) => getEmptyStatusCheckpointRondin(DEFAULT_STATE.error))  
);

export const updateCheckpointRondinAsyncThunk = createAsyncThunk(
  'rondines/updateCheckpointRondinAsyncThunk',
  async (update: {serverDate : IServerDate, id: number, CheckpointRondin: ICheckpointRondin}) => await updateCheckpointRondin(update.serverDate, update.id, update.CheckpointRondin)
    .then(async (result) => result)
    .catch(async (error) => getEmptyStatusCheckpointRondin(DEFAULT_STATE.error))  
);

export const createCheckpointRondinAsyncThunk = createAsyncThunk(
  'rondines/createCheckpointRondinAsyncThunk',
  async (create : {serverDate : IServerDate, idCliente : number, CheckpointRondin: ICheckpointRondin}) => await createCheckpointRondin(create.serverDate, create.idCliente, create.CheckpointRondin)
    .then(async (result) => result)
    .catch(async (error) => getEmptyStatusCheckpointRondin(DEFAULT_STATE.error))  
);

export const deleteCheckpointRondinAsyncThunk = createAsyncThunk(
  'rondines/deleteCheckpointRondinAsyncThunk',
  async (del : {id : number}) => await deleteCheckpointRondin(del.id)
    .then(async (result) => result)
    .catch(async (error) => getEmptyStatusCheckpointRondin())  
);
