/** 
 * Componente que se encarga del elemento Empleado
 * @IEmpleado es la interfaz que contiene los parámetos el elemento Empleado
 * @getEmptyEmpleado  devuelve un objeto con los parámetros por defecto; @param {id}, opcional cuando no encuentra el objeto en el LocalSotrage
 * @IStatusEmpleado es la interfaz para los estatus a llamadas a la API; @param {status}, opcional cuando queremos otro estado de inizialización de tipo [DEFAULT_STATE]
 * @getEmptyStatusEmpleado devuelve un objeto con los parámetros por defecto,
 * @setEmpleado devuelve un objeto de tipo [IEmpleado] recibiendo @param {object} y @param {serverDate}, para verificar que los datos estén en el formato correcto y ajustarlos a la zona horaria actual
 * @getSerializableEmpleado recibe un @param {IEmpleado}, para devolver un objeto serizalizable para las peticiones de la API
 * */

import { getDateString, getFormattedDateString, isNotEmpty, SERVER_TIME_ZONE } from "../../api/helpers";
import { DEFAULT_STATE } from "../../enums/default_state";
import { getStorageUserArrayItem, getStorageDatosPersonalesArrayItem, getStorageDatosFiscalesArrayItem, getStorageInformacionBancariaArrayItem, getStorageInformacionContratoArrayItem, getStorageCentroDeTrabajoArrayItem, getStorageEmpleadoArrayItem, getStorageCodigoQrArrayItem, getStorageEmpresaArrayItem, getStorageImagenArrayItem, getStorageClienteQentryArrayItem } from "../../localStorage/personas";
import { getStorageMvTipoEmpleadoArrayItem } from "../../localStorage/personas/MvTipoEmpleado";
import { convertDateToZonaHoraria, ILocalStorageExpiry, getEmptyLocalStorageExpiry, IServerDate } from "../time";
import { ICentroDeTrabajo, setCentroDeTrabajo } from "./ICentroDeTrabajo";
import { getEmptyClienteQentry, IClienteQentry, setClienteQentry } from "./IClienteQentry";
import { ICodigoQr, setCodigoQr } from "./ICodigoQr";
import { IDatosFiscales, setDatosFiscales } from "./IDatosFiscales";
import { IDatosPersonales, getEmptyDatosPersonales, setDatosPersonales } from "./IDatosPersonales";
import { IEmpresa, getEmptyEmpresa, setEmpresa } from "./IEmpresa";
import { IImagen, setImagen } from "./IImagen";
import { IInformacionBancaria, getEmptyInformacionBancaria, setInformacionBancaria } from "./IInformacionBancaria";
import { IInformacionContrato, getEmptyInformacionContrato, setInformacionContrato } from "./IInformacionContrato";
import { getEmptyMvTipoEmpleado, IMvTipoEmpleado, setMvTipoEmpleado } from "./IMvTipoEmpleado";
import { IUser, getEmptyUser, setUser } from "./IUser";

export interface IEmpleado {
  id : number,
  usuario : IUser,
  datos_personales : IDatosPersonales,
  datos_fiscales : IDatosFiscales[],
  informacion_bancaria : IInformacionBancaria,
  informacion_contrato : IInformacionContrato,
  centro_trabajo : ICentroDeTrabajo[],
  fecha_contratacion : string, // Fecha (Sin horas)
  empleados_a_cargo : IEmpleado[],
  numero_seguridad_social : string,
  sindicalizado : boolean,
  es_supervisor : boolean,
  es_gerente : boolean,
  es_multi_centro : boolean,
  accede_a_historial : boolean,
  accede_a_whatsapp : boolean,
  checa_con_whatsapp : boolean,
  id_telefono_autorizado : string,
  codigos_qr_asignados : ICodigoQr[],
  tipo_empleado : IMvTipoEmpleado,
  empresa : IEmpresa,
  fotos_perfil : IImagen[],
  activo: boolean,
  fecha_elaboracion : string, // Fecha
  estatus_sistema : boolean,
  ultima_actualizacion : string, // Fecha
  cliente_qentry : IClienteQentry,
}

export const getEmptyEmpleado = (id? : number) => {
  const temp : IEmpleado = 
  {
    id : id ? id : 0,
    usuario : getEmptyUser(),
    datos_personales : getEmptyDatosPersonales(),
    datos_fiscales : [],
    informacion_bancaria : getEmptyInformacionBancaria(),
    informacion_contrato : getEmptyInformacionContrato(),
    centro_trabajo : [],
    fecha_contratacion : "",
    empleados_a_cargo : [],
    numero_seguridad_social : "",
    sindicalizado : false,
    es_supervisor : false,
    es_gerente : false,
    es_multi_centro : false,
    accede_a_historial : false,
    accede_a_whatsapp : false,
    checa_con_whatsapp : false,
    id_telefono_autorizado : "",
    codigos_qr_asignados : [],
    tipo_empleado : getEmptyMvTipoEmpleado(),
    empresa : getEmptyEmpresa(),
    fotos_perfil : [],
    activo: false,
    fecha_elaboracion : "", // Fecha
    estatus_sistema : false,
    ultima_actualizacion : "", // Fecha
    cliente_qentry : getEmptyClienteQentry(),
  };
  return temp;
}

export interface IStatusEmpleado {
  estado : DEFAULT_STATE,
  mensaje : string,
  data : IEmpleado,
  data_array: IEmpleado[],
  expiry : ILocalStorageExpiry,
  expiry_array : ILocalStorageExpiry,
}

export const getEmptyStatusEmpleado = (status: DEFAULT_STATE = DEFAULT_STATE.loading) => {
  const temp : IStatusEmpleado = 
  {
    estado : status,
    mensaje : "",
    data : getEmptyEmpleado(),
    data_array : [],
    expiry : getEmptyLocalStorageExpiry("IEmpleado", 0),
    expiry_array : getEmptyLocalStorageExpiry("IEmpleadoArray", 0),
  };
  return temp;
}

export const setEmpleado = async (
  object : any,
  serverDate : IServerDate,
) => {
  const temp = getEmptyEmpleado();
  if (isNotEmpty(object.id)) temp.id = object.id;
  if (isNotEmpty(object.usuario)) temp.usuario = (typeof object.usuario === 'number') ? await getStorageUserArrayItem(object.usuario) : await setUser(object.usuario, serverDate);
  if (isNotEmpty(object.datos_personales)) temp.datos_personales = (typeof object.datos_personales === 'number') ? await getStorageDatosPersonalesArrayItem(object.datos_personales) : await setDatosPersonales(object.datos_personales, serverDate);
  if (isNotEmpty(object.datos_fiscales)) temp.datos_fiscales = (typeof object.datos_fiscales[0] === 'number') ? await Promise.all(object.datos_fiscales.map(async (e : number) => await getStorageDatosFiscalesArrayItem(e))) : await Promise.all(object.datos_fiscales.map(async (e : any) => await setDatosFiscales(e, serverDate)));
  if (isNotEmpty(object.informacion_bancaria)) temp.informacion_bancaria = (typeof object.informacion_bancaria === 'number') ? await getStorageInformacionBancariaArrayItem(object.informacion_bancaria) : await setInformacionBancaria(object.informacion_bancaria, serverDate);
  if (isNotEmpty(object.informacion_contrato)) temp.informacion_contrato = (typeof object.informacion_contrato === 'number') ? await getStorageInformacionContratoArrayItem(object.informacion_contrato) : await setInformacionContrato(object.informacion_contrato, serverDate);
  if (isNotEmpty(object.centro_trabajo)) temp.centro_trabajo = (typeof object.centro_trabajo[0] === 'number') ? await Promise.all(object.centro_trabajo.map(async (e : number) => await getStorageCentroDeTrabajoArrayItem(e))) : await Promise.all(object.centro_trabajo.map(async (e : any) => await setCentroDeTrabajo(e, serverDate)));
  if (isNotEmpty(object.fecha_contratacion)) temp.fecha_contratacion = object.fecha_contratacion;
  if (isNotEmpty(object.empleados_a_cargo)) temp.empleados_a_cargo = (typeof object.empleados_a_cargo[0] === 'number') ? await Promise.all(object.empleados_a_cargo.map(async (e : number) => await getStorageEmpleadoArrayItem(e))) : await Promise.all(object.empleados_a_cargo.map(async (e : any) => await setEmpleado(e, serverDate)));
  if (isNotEmpty(object.numero_seguridad_social)) temp.numero_seguridad_social = object.numero_seguridad_social;
  if (isNotEmpty(object.sindicalizado)) temp.sindicalizado = object.sindicalizado;
  if (isNotEmpty(object.es_supervisor)) temp.es_supervisor = object.es_supervisor;
  if (isNotEmpty(object.es_gerente)) temp.es_gerente = object.es_gerente;
  if (isNotEmpty(object.es_multi_centro)) temp.es_multi_centro = object.es_multi_centro;
  if (isNotEmpty(object.accede_a_historial)) temp.accede_a_historial = object.accede_a_historial;
  if (isNotEmpty(object.checa_con_whatsapp)) temp.checa_con_whatsapp = object.checa_con_whatsapp;
  if (isNotEmpty(object.accede_a_whatsapp)) temp.accede_a_whatsapp = object.accede_a_whatsapp;
  if (isNotEmpty(object.id_telefono_autorizado)) temp.id_telefono_autorizado = object.id_telefono_autorizado;
  if (isNotEmpty(object.codigos_qr_asignados)) temp.codigos_qr_asignados = (typeof object.codigos_qr_asignados[0] === 'number') ? await Promise.all(object.codigos_qr_asignados.map(async (e : number) => await getStorageCodigoQrArrayItem(e))) : await Promise.all(object.codigos_qr_asignados.map(async (e : any) => await setCodigoQr(e, serverDate)));
  if (isNotEmpty(object.tipo_empleado)) temp.tipo_empleado = (typeof object.tipo_empleado === 'number') ? await getStorageMvTipoEmpleadoArrayItem(object.tipo_empleado) : await setMvTipoEmpleado(object.tipo_empleado, serverDate);
  if (isNotEmpty(object.empresa)) temp.empresa = (typeof object.empresa === 'number') ? await getStorageEmpresaArrayItem(object.empresa) : await setEmpresa(object.empresa, serverDate);
  if (isNotEmpty(object.fotos_perfil)) temp.fotos_perfil = (typeof object.fotos_perfil[0] === 'number') ? await Promise.all(object.fotos_perfil.map(async (e : number) => await getStorageImagenArrayItem(e))) : await Promise.all(object.fotos_perfil.map(async (e : any) => await setImagen(e, serverDate)));
  if (isNotEmpty(object.activo)) temp.activo = object.activo;
  if (isNotEmpty(object.fecha_elaboracion)) temp.fecha_elaboracion = convertDateToZonaHoraria(serverDate, object.fecha_elaboracion);
  if (isNotEmpty(object.estatus_sistema)) temp.estatus_sistema = object.estatus_sistema;
  if (isNotEmpty(object.ultima_actualizacion)) temp.ultima_actualizacion = convertDateToZonaHoraria(serverDate, object.ultima_actualizacion);
  if (isNotEmpty(object.cliente_qentry)) temp.cliente_qentry = (typeof object.cliente_qentry === 'number') ? await getStorageClienteQentryArrayItem(object.cliente_qentry) : await setClienteQentry(object.cliente_qentry, serverDate);
  return temp;
}

export const getSerializableEmpleado = (IEmpleado : IEmpleado) => {
  return {
    id : IEmpleado.id,
    usuario : IEmpleado.usuario.id,
    datos_personales : IEmpleado.datos_personales.id,
    datos_fiscales : IEmpleado.datos_fiscales.map((e) => e.id),
    informacion_bancaria : IEmpleado.informacion_bancaria.id > 0 ? IEmpleado.informacion_bancaria.id : null,
    informacion_contrato : IEmpleado.informacion_contrato.id > 0 ? IEmpleado.informacion_contrato.id : null,
    centro_trabajo : IEmpleado.centro_trabajo.map((e) => e.id),
    fecha_contratacion : getFormattedDateString(SERVER_TIME_ZONE, IEmpleado.fecha_contratacion),
    empleados_a_cargo : IEmpleado.empleados_a_cargo.map((e) => e.id),
    numero_seguridad_social : IEmpleado.numero_seguridad_social,
    sindicalizado : IEmpleado.sindicalizado,
    es_supervisor : IEmpleado.es_supervisor,
    es_gerente : IEmpleado.es_gerente,
    es_multi_centro : IEmpleado.es_multi_centro,
    accede_a_historial : IEmpleado.accede_a_historial,
    checa_con_whatsapp : IEmpleado.checa_con_whatsapp,
    accede_a_whatsapp : IEmpleado.accede_a_whatsapp,
    id_telefono_autorizado : IEmpleado.id_telefono_autorizado,
    codigos_qr_asignados : IEmpleado.codigos_qr_asignados.map((e) => e.id),
    tipo_empleado : IEmpleado.tipo_empleado.id > 0 ? IEmpleado.tipo_empleado.id : null,
    empresa : IEmpleado.empresa.id,
    activo : IEmpleado.activo,
    fotos_perfil : IEmpleado.fotos_perfil.map((e) => e.id),
    fecha_elaboracion : IEmpleado.fecha_elaboracion,
    estatus_sistema : IEmpleado.estatus_sistema,
    ultima_actualizacion : getDateString(SERVER_TIME_ZONE),
    cliente_qentry : IEmpleado.cliente_qentry.id,
  };
}
