/**
 * Métodos AsyncThunk para ser usados en los reducers y
 * los resultados a las llamadas http puedan ser usados para
 * actualizar el state en redux
 */

import { createAsyncThunk } from "@reduxjs/toolkit";
import { DEFAULT_STATE } from "../../enums/default_state";
import { getCheckRondin, getAllCheckRondin, updateCheckRondin, createCheckRondin, deleteCheckRondin } from "../../http/rondines";
import { getEmptyStatusCheckRondin, ICheckRondin } from "../../interfaces/rondines";
import { IServerDate } from "../../interfaces/time";

export const getCheckRondinAsyncThunk = createAsyncThunk(
  'rondines/getCheckRondinAsyncThunk',
  async (query : {serverDate : IServerDate, id: number}) => await getCheckRondin(query.serverDate, query.id)
    .then(async (result) => result)
    .catch(async (error) => getEmptyStatusCheckRondin(DEFAULT_STATE.error))  
);

export const getAllCheckRondinAsyncThunk = createAsyncThunk(
  'rondines/getAllCheckRondinAsyncThunk',
  async (queryAll : {serverDate : IServerDate, idCliente : number}) => await getAllCheckRondin(queryAll.serverDate, queryAll.idCliente)
    .then(async (result) => result)
    .catch(async (error) => getEmptyStatusCheckRondin(DEFAULT_STATE.error))  
);

export const updateCheckRondinAsyncThunk = createAsyncThunk(
  'rondines/updateCheckRondinAsyncThunk',
  async (update: {serverDate : IServerDate, id: number, CheckRondin: ICheckRondin}) => await updateCheckRondin(update.serverDate, update.id, update.CheckRondin)
    .then(async (result) => result)
    .catch(async (error) => getEmptyStatusCheckRondin(DEFAULT_STATE.error))  
);

export const createCheckRondinAsyncThunk = createAsyncThunk(
  'rondines/createCheckRondinAsyncThunk',
  async (create : {serverDate : IServerDate, idCliente : number, CheckRondin: ICheckRondin}) => await createCheckRondin(create.serverDate, create.idCliente, create.CheckRondin)
    .then(async (result) => result)
    .catch(async (error) => getEmptyStatusCheckRondin(DEFAULT_STATE.error))  
);

export const deleteCheckRondinAsyncThunk = createAsyncThunk(
  'rondines/deleteCheckRondinAsyncThunk',
  async (del : {id : number}) => await deleteCheckRondin(del.id)
    .then(async (result) => result)
    .catch(async (error) => getEmptyStatusCheckRondin())  
);
