/**
 * Métodos para el manejo de IonicStorage 
 * @setStorage              guarda un unico objeto al storage
 * @getStorage              obtiene el objeto guardado con @setStorage
 * @setStorageArray         guarda el array de objetos en el storage
 * @getStorageArray         obtiene el array de objetos guardado con @setStorageArray
 * @removeStorageArray      elimina el array de objetos guardado con @setStorageArray
 * @setStorageItemArray     agrega un elemento al array de objeto guardado con @setStorageArray
 * @getStorageItemArray     obtiene un elemento del array guardado con @setStorageArray buscandolo por su id 
 * @removeStorageItemArray  elimina un elemento del array guardado con @setStorageArray buscandolo por su id 
 */

import { setStorageData, getStorageData, removeStorageData, setArrayItemStorageData, getArrayItemStorageData, removeArrayItemStorageData } from "../../api/storage";
import { IIncapacidad, getEmptyIncapacidad } from "../../interfaces/nomina";

export const setStorageIncapacidad = async (IIncapacidad : IIncapacidad) => await setStorageData('IIncapacidad', IIncapacidad);
export const getStorageIncapacidad = async () : Promise<IIncapacidad> => await getStorageData('IIncapacidad', getEmptyIncapacidad());
export const removeStorageIncapacidad = async () => await removeStorageData('IIncapacidad');
export const setStorageIncapacidadArray = async (IIncapacidad : IIncapacidad[]) => await setStorageData('IIncapacidadArray', IIncapacidad);
export const getStorageIncapacidadArray = async () : Promise<IIncapacidad[]> => await getStorageData('IIncapacidadArray', [] as IIncapacidad[]);
export const removeStorageIncapacidadArray = async () => await removeStorageData('IIncapacidadArray');
export const setStorageIncapacidadArrayItem = async (id: number, IIncapacidad : IIncapacidad) : Promise<IIncapacidad[]> => await setArrayItemStorageData('IIncapacidadArray', id, IIncapacidad, [] as IIncapacidad[]);
export const getStorageIncapacidadArrayItem = async (id: number) : Promise<IIncapacidad> => await getArrayItemStorageData('IIncapacidadArray', id, getEmptyIncapacidad);
export const removeStorageIncapacidadArrayItem = async (id: number) : Promise<IIncapacidad[]> => await removeArrayItemStorageData('IIncapacidadArray', id, [] as IIncapacidad[]);
