import { createCheckEnRutaReducers } from "./CheckEnRuta";
import { createCheckpointRondinReducers } from "./CheckpointRondin";
import { createCheckRondinReducers } from "./CheckRondin";
import { createHelpersReducers } from "./helpers";
import { createMvMotivoCancelacionReducers } from "./MvMotivoCancelacion";
import { createRondinReducers } from "./Rondin";
import { createRondinCanceladoReducers } from "./RondinCancelado";
import { createRondinCompletadoReducers } from "./RondinCompletado";

export const rondinesReducers = {
  ...createCheckEnRutaReducers(),
  ...createCheckpointRondinReducers(),
  ...createCheckRondinReducers(),
  ...createHelpersReducers(),
  ...createMvMotivoCancelacionReducers(),
  ...createRondinReducers(),
  ...createRondinCanceladoReducers(),
  ...createRondinCompletadoReducers(),
}