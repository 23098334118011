/**
 * Componente que se encarga del elemento Direccion
 * @IDireccion es la interfaz que contiene los parámetos el elemento Direccion
 * @getEmptyDireccion  devuelve un objeto con los parámetros por defecto; @param {id}, opcional cuando no encuentra el objeto en el LocalSotrage
 * @IStatusDireccion es la interfaz para los estatus a llamadas a la API; @param {status}, opcional cuando queremos otro estado de inizialización de tipo [DEFAULT_STATE]
 * @getEmptyStatusDireccion devuelve un objeto con los parámetros por defecto,
 * @setDireccion devuelve un objeto de tipo [IDireccion] recibiendo @param {object} y @param {serverDate}, para verificar que los datos estén en el formato correcto y ajustarlos a la zona horaria actual
 * @getSerializableDireccion recibe un @param {IDireccion}, para devolver un objeto serizalizable para las peticiones de la API
 * */

import { getDateString, isNotEmpty, SERVER_TIME_ZONE } from "../../api/helpers";
import { DEFAULT_STATE } from "../../enums/default_state";
import {
  getStorageCodigoPostalArrayItem,
  getStorageEstadoArrayItem,
  getStorageGeolocalizacionArrayItem,
  getStoragePaisArrayItem,
  getStorageUserArrayItem,
  getStorageClienteQentryArrayItem,
} from "../../localStorage/personas";
import {
  convertDateToZonaHoraria,
  ILocalStorageExpiry,
  getEmptyLocalStorageExpiry,
  IServerDate,
} from "../time";
import {
  getEmptyClienteQentry,
  IClienteQentry,
  setClienteQentry,
} from "./IClienteQentry";
import {
  ICodigoPostal,
  getEmptyCodigoPostal,
  setCodigoPostal,
} from "./ICodigoPostal";
import { IEstado, getEmptyEstado, setEstado } from "./IEstado";
import {
  IGeolocalizacion,
  getEmptyGeolocalizacion,
  setGeolocalizacion,
} from "./IGeolocalizacion";
import { IPais, getEmptyPais, setPais } from "./IPais";
import { IUser, getEmptyUser, setUser } from "./IUser";

export interface IDireccion {
  id: number;
  calle: string;
  numero_exterior: string;
  numero_interior: string;
  codigo_postal: ICodigoPostal;
  colonia: string;
  ciudad: string;
  delegacion_municipio: string;
  estado: IEstado;
  numero_contacto: string;
  geolocalizacion: IGeolocalizacion;
  pais: IPais;
  autor: IUser;
  fecha_elaboracion: string; // Fecha
  estatus_sistema: boolean;
  ultima_actualizacion: string; // Fecha
  cliente_qentry: IClienteQentry;
}

export const getEmptyDireccion = (id?: number) => {
  const temp: IDireccion = {
    id: id ? id : 0,
    calle: "",
    numero_exterior: "",
    numero_interior: "",
    codigo_postal: getEmptyCodigoPostal(),
    colonia: "",
    ciudad: "",
    delegacion_municipio: "",
    estado: getEmptyEstado(),
    numero_contacto: "",
    geolocalizacion: getEmptyGeolocalizacion(),
    pais: getEmptyPais(),
    autor: getEmptyUser(),
    fecha_elaboracion: "",
    estatus_sistema: false,
    ultima_actualizacion: "",
    cliente_qentry: getEmptyClienteQentry(),
  };
  return temp;
};

export interface IStatusDireccion {
  estado: DEFAULT_STATE;
  mensaje: string;
  data: IDireccion;
  data_array: IDireccion[];
  expiry: ILocalStorageExpiry;
  expiry_array: ILocalStorageExpiry;
}

export const getEmptyStatusDireccion = (
  status: DEFAULT_STATE = DEFAULT_STATE.loading
) => {
  const temp: IStatusDireccion = {
    estado: status,
    mensaje: "",
    data: getEmptyDireccion(),
    data_array: [],
    expiry: getEmptyLocalStorageExpiry("IDireccion", 0),
    expiry_array: getEmptyLocalStorageExpiry("IDireccionArray", 0),
  };
  return temp;
};

export const setDireccion = async (object: any, serverDate: IServerDate) => {
  const temp = getEmptyDireccion();
  if (isNotEmpty(object.id)) temp.id = object.id;
  if (isNotEmpty(object.calle)) temp.calle = object.calle;
  if (isNotEmpty(object.numero_exterior))
    temp.numero_exterior = object.numero_exterior;
  if (isNotEmpty(object.numero_interior))
    temp.numero_interior = object.numero_interior;
  if (isNotEmpty(object.codigo_postal))
    temp.codigo_postal =
      typeof object.codigo_postal === "number"
        ? await getStorageCodigoPostalArrayItem(object.codigo_postal)
        : await setCodigoPostal(object.codigo_postal, serverDate);
  if (isNotEmpty(object.colonia)) temp.colonia = object.colonia;
  if (isNotEmpty(object.ciudad)) temp.ciudad = object.ciudad;
  if (isNotEmpty(object.delegacion_municipio))
    temp.delegacion_municipio = object.delegacion_municipio;
  if (isNotEmpty(object.estado))
    temp.estado =
      typeof object.estado === "number"
        ? await getStorageEstadoArrayItem(object.estado)
        : await setEstado(object.estado, serverDate);
  if (isNotEmpty(object.numero_contacto))
    temp.numero_contacto = object.numero_contacto;
  if (isNotEmpty(object.geolocalizacion))
    temp.geolocalizacion =
      typeof object.geolocalizacion === "number"
        ? await getStorageGeolocalizacionArrayItem(object.geolocalizacion)
        : await setGeolocalizacion(object.geolocalizacion, serverDate);
  if (isNotEmpty(object.pais))
    temp.pais =
      typeof object.pais === "number"
        ? await getStoragePaisArrayItem(object.pais)
        : await setPais(object.pais, serverDate);
  if (isNotEmpty(object.autor))
    temp.autor =
      typeof object.autor === "number"
        ? await getStorageUserArrayItem(object.autor)
        : await setUser(object.autor, serverDate);
  if (isNotEmpty(object.fecha_elaboracion))
    temp.fecha_elaboracion = convertDateToZonaHoraria(
      serverDate,
      object.fecha_elaboracion
    );
  if (isNotEmpty(object.estatus_sistema))
    temp.estatus_sistema = object.estatus_sistema;
  if (isNotEmpty(object.ultima_actualizacion))
    temp.ultima_actualizacion = convertDateToZonaHoraria(
      serverDate,
      object.ultima_actualizacion
    );
  if (isNotEmpty(object.cliente_qentry))
    temp.cliente_qentry =
      typeof object.cliente_qentry === "number"
        ? await getStorageClienteQentryArrayItem(object.cliente_qentry)
        : await setClienteQentry(object.cliente_qentry, serverDate);
  return temp;
};

export const getSerializableDireccion = (IDireccion: IDireccion) => {
  return {
    id: IDireccion.id,
    calle: IDireccion.calle,
    numero_exterior: IDireccion.numero_exterior,
    numero_interior: IDireccion.numero_interior,
    codigo_postal:
      IDireccion.codigo_postal.id > 0 ? IDireccion.codigo_postal.id : null,
    colonia: IDireccion.colonia,
    ciudad: IDireccion.ciudad,
    delegacion_municipio: IDireccion.delegacion_municipio,
    estado: IDireccion.estado.id > 0 ? IDireccion.estado.id : null,
    numero_contacto: IDireccion.numero_contacto,
    geolocalizacion: IDireccion.geolocalizacion.id,
    pais: IDireccion.pais.id,
    autor: IDireccion.autor.id,
    fecha_elaboracion: IDireccion.fecha_elaboracion,
    estatus_sistema: IDireccion.estatus_sistema,
    ultima_actualizacion: getDateString(SERVER_TIME_ZONE),
    cliente_qentry: IDireccion.cliente_qentry.id,
  };
};
