/**
 * Métodos AsyncThunk para ser usados en los reducers y
 * los resultados a las llamadas http puedan ser usados para
 * actualizar el state en redux
 */

import { createAsyncThunk } from "@reduxjs/toolkit";
import { DEFAULT_STATE } from "../../enums/default_state";
import { getMvMotivoVacaciones, getAllMvMotivoVacaciones, updateMvMotivoVacaciones, createMvMotivoVacaciones, deleteMvMotivoVacaciones } from "../../http/nomina";
import { getEmptyStatusMvMotivoVacaciones, IMvMotivoVacaciones } from "../../interfaces/nomina";
import { IServerDate } from "../../interfaces/time";

export const getMvMotivoVacacionesAsyncThunk = createAsyncThunk(
  'nomina/getMvMotivoVacacionesAsyncThunk',
  async (query : {serverDate : IServerDate, id: number}) => await getMvMotivoVacaciones(query.serverDate, query.id)
    .then(async (result) => result)
    .catch(async (error) => getEmptyStatusMvMotivoVacaciones(DEFAULT_STATE.error))  
);

export const getAllMvMotivoVacacionesAsyncThunk = createAsyncThunk(
  'nomina/getAllMvMotivoVacacionesAsyncThunk',
  async (queryAll : {serverDate : IServerDate}) => await getAllMvMotivoVacaciones(queryAll.serverDate)
    .then(async (result) => result)
    .catch(async (error) => getEmptyStatusMvMotivoVacaciones(DEFAULT_STATE.error))  
);

export const updateMvMotivoVacacionesAsyncThunk = createAsyncThunk(
  'nomina/updateMvMotivoVacacionesAsyncThunk',
  async (update: {serverDate : IServerDate, id: number, MvMotivoVacaciones: IMvMotivoVacaciones}) => await updateMvMotivoVacaciones(update.serverDate, update.id, update.MvMotivoVacaciones)
    .then(async (result) => result)
    .catch(async (error) => getEmptyStatusMvMotivoVacaciones(DEFAULT_STATE.error))  
);

export const createMvMotivoVacacionesAsyncThunk = createAsyncThunk(
  'nomina/createMvMotivoVacacionesAsyncThunk',
  async (create : {serverDate : IServerDate, MvMotivoVacaciones: IMvMotivoVacaciones}) => await createMvMotivoVacaciones(create.serverDate, create.MvMotivoVacaciones)
    .then(async (result) => result)
    .catch(async (error) => getEmptyStatusMvMotivoVacaciones(DEFAULT_STATE.error))  
);

export const deleteMvMotivoVacacionesAsyncThunk = createAsyncThunk(
  'nomina/deleteMvMotivoVacacionesAsyncThunk',
  async (del : {id : number}) => await deleteMvMotivoVacaciones(del.id)
    .then(async (result) => result)
    .catch(async (error) => getEmptyStatusMvMotivoVacaciones())  
);
