/**
 * Reducers creados para obtener asíncronamente 
 * los datos de los modelos y dependiendo del resultado
 * de la peticón actualice el state en redux o mande un mensaje de error
 */

import { PayloadAction } from "@reduxjs/toolkit";
import { getMvMotivoPagoSuscripcionAsyncThunk, getAllMvMotivoPagoSuscripcionAsyncThunk, updateMvMotivoPagoSuscripcionAsyncThunk, createMvMotivoPagoSuscripcionAsyncThunk, deleteMvMotivoPagoSuscripcionAsyncThunk } from "../../asyncThunk/personas";
import { DEFAULT_STATE } from "../../enums/default_state";
import { IStatusMvMotivoPagoSuscripcion } from "../../interfaces/personas";
import { personasInitialState } from "../../slices/personasSlice";

// Reducers: MvMotivoPagoSuscripcion
export const createMvMotivoPagoSuscripcionReducers = () => {
  const get = () => {
    var { pending, fulfilled, rejected } = getMvMotivoPagoSuscripcionAsyncThunk;
    return {
      [pending.type]: (state : typeof personasInitialState) => {
        state.personasState.mvMotivoPagoSuscripcion.estado = DEFAULT_STATE.loading;
      },
      [fulfilled.type]: (state : typeof personasInitialState, action : PayloadAction<IStatusMvMotivoPagoSuscripcion>) => {
        const result = action.payload;
        result.expiry_array = state.personasState.mvMotivoPagoSuscripcion.expiry_array;
        if (result.estado === DEFAULT_STATE.error) result.data_array = state.personasState.mvMotivoPagoSuscripcion.data_array;
        state.personasState.mvMotivoPagoSuscripcion = result;
      },
      [rejected.type]: (state : typeof personasInitialState, action : PayloadAction<IStatusMvMotivoPagoSuscripcion>) => {
        state.personasState.mvMotivoPagoSuscripcion = {
          ...state.personasState.mvMotivoPagoSuscripcion,
          estado: action.payload.estado,
          mensaje: "Ocurrió un problema al realizar la solicitud",
        };
      },
    };
  };
  
  const getAll = () => {
    var { pending, fulfilled, rejected } = getAllMvMotivoPagoSuscripcionAsyncThunk;
    return {
      [pending.type]: (state : typeof personasInitialState) => {
        state.personasState.mvMotivoPagoSuscripcion.estado = DEFAULT_STATE.loading;
      },
      [fulfilled.type]: (state : typeof personasInitialState, action : PayloadAction<IStatusMvMotivoPagoSuscripcion>) => {
        const result = action.payload;
        result.data = state.personasState.mvMotivoPagoSuscripcion.data;
        result.expiry = state.personasState.mvMotivoPagoSuscripcion.expiry;
        if (result.estado === DEFAULT_STATE.error) result.data_array = state.personasState.mvMotivoPagoSuscripcion.data_array;
        state.personasState.mvMotivoPagoSuscripcion = result;
      },
      [rejected.type]: (state : typeof personasInitialState, action : PayloadAction<IStatusMvMotivoPagoSuscripcion>) => {
        state.personasState.mvMotivoPagoSuscripcion = {
          ...state.personasState.mvMotivoPagoSuscripcion,
          estado: action.payload.estado,
          mensaje: "Ocurrió un problema al realizar la solicitud",
        };
      },
    };
  };
  
  const update = () => {
    var { pending, fulfilled, rejected } = updateMvMotivoPagoSuscripcionAsyncThunk;
    return {
      [pending.type]: (state : typeof personasInitialState) => {
        state.personasState.mvMotivoPagoSuscripcion.estado = DEFAULT_STATE.loading;
      },
      [fulfilled.type]: (state : typeof personasInitialState, action : PayloadAction<IStatusMvMotivoPagoSuscripcion>) => {
        const result: IStatusMvMotivoPagoSuscripcion = action.payload;
        result.expiry = state.personasState.mvMotivoPagoSuscripcion.expiry;
        result.expiry_array = state.personasState.mvMotivoPagoSuscripcion.expiry_array;
        if (result.estado === DEFAULT_STATE.error) result.data_array = state.personasState.mvMotivoPagoSuscripcion.data_array;
        state.personasState.mvMotivoPagoSuscripcion = result;
      },
      [rejected.type]: (state : typeof personasInitialState, action : PayloadAction<IStatusMvMotivoPagoSuscripcion>) => {
        state.personasState.mvMotivoPagoSuscripcion = {
          ...state.personasState.mvMotivoPagoSuscripcion,
          estado: action.payload.estado,
          mensaje: "Ocurrió un problema al realizar la solicitud",
        };
      },
    };
  };

  const create = () => {
    var { pending, fulfilled, rejected } = createMvMotivoPagoSuscripcionAsyncThunk;
    return {
      [pending.type]: (state : typeof personasInitialState) => {
        state.personasState.mvMotivoPagoSuscripcion.estado = DEFAULT_STATE.loading;
      },
      [fulfilled.type]: (state : typeof personasInitialState, action : PayloadAction<IStatusMvMotivoPagoSuscripcion>) => {
        const result = action.payload;
        result.expiry = state.personasState.mvMotivoPagoSuscripcion.expiry;
        result.expiry_array = state.personasState.mvMotivoPagoSuscripcion.expiry_array;
        if (result.estado === DEFAULT_STATE.error) result.data_array = state.personasState.mvMotivoPagoSuscripcion.data_array;
        state.personasState.mvMotivoPagoSuscripcion = result;
      },
      [rejected.type]: (state : typeof personasInitialState, action : PayloadAction<IStatusMvMotivoPagoSuscripcion>) => {
        state.personasState.mvMotivoPagoSuscripcion = {
          ...state.personasState.mvMotivoPagoSuscripcion,
          estado: action.payload.estado,
          mensaje: "Ocurrió un problema al realizar la solicitud",
        };
      },
    };
  };
  
  const del = () => {
    var { pending, fulfilled, rejected } = deleteMvMotivoPagoSuscripcionAsyncThunk;
    return {
      [pending.type]: (state : typeof personasInitialState) => {
        state.personasState.mvMotivoPagoSuscripcion.estado = DEFAULT_STATE.loading;
      },
      [fulfilled.type]: (state : typeof personasInitialState, action : PayloadAction<IStatusMvMotivoPagoSuscripcion>) => {
        const result: IStatusMvMotivoPagoSuscripcion = action.payload;
        result.expiry = state.personasState.mvMotivoPagoSuscripcion.expiry;
        result.expiry_array = state.personasState.mvMotivoPagoSuscripcion.expiry_array;
        if (result.estado === DEFAULT_STATE.error) result.data_array = state.personasState.mvMotivoPagoSuscripcion.data_array;
        state.personasState.mvMotivoPagoSuscripcion = result;
      },
      [rejected.type]: (state : typeof personasInitialState, action : PayloadAction<IStatusMvMotivoPagoSuscripcion>) => {
        state.personasState.mvMotivoPagoSuscripcion = {
          ...state.personasState.mvMotivoPagoSuscripcion,
          estado: action.payload.estado,
          mensaje: "Ocurrió un problema al realizar la solicitud",
        };
      },
    };
  };

  return {
    ...get(),
    ...getAll(),
    ...update(),
    ...create(),
    ...del(),
  };
};
