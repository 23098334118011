/**
 * Reducers creados para obtener asíncronamente 
 * los datos de los modelos y dependiendo del resultado
 * de la peticón actualice el state en redux o mande un mensaje de error
 */

import { PayloadAction } from "@reduxjs/toolkit";
import { getMvTipoNotificacionAsyncThunk, getAllMvTipoNotificacionAsyncThunk, updateMvTipoNotificacionAsyncThunk, createMvTipoNotificacionAsyncThunk, deleteMvTipoNotificacionAsyncThunk } from "../../asyncThunk/nomina";
import { DEFAULT_STATE } from "../../enums/default_state";
import { IStatusMvTipoNotificacion } from "../../interfaces/nomina";
import { nominaInitialState } from "../../slices/nominaSlice";

// Reducers: MvTipoNotificacion
export const createMvTipoNotificacionReducers = () => {
  const get = () => {
    var { pending, fulfilled, rejected } = getMvTipoNotificacionAsyncThunk;
    return {
      [pending.type]: (state : typeof nominaInitialState) => {
        state.nominaState.mvTipoNotificacion.estado = DEFAULT_STATE.loading;
      },
      [fulfilled.type]: (state : typeof nominaInitialState, action : PayloadAction<IStatusMvTipoNotificacion>) => {
        const result = action.payload;
        result.expiry_array = state.nominaState.mvTipoNotificacion.expiry_array;
        if (result.estado === DEFAULT_STATE.error) result.data_array = state.nominaState.mvTipoNotificacion.data_array;
        state.nominaState.mvTipoNotificacion = result;
      },
      [rejected.type]: (state : typeof nominaInitialState, action : PayloadAction<IStatusMvTipoNotificacion>) => {
        state.nominaState.mvTipoNotificacion = {
          ...state.nominaState.mvTipoNotificacion,
          estado: action.payload.estado,
          mensaje: "Ocurrió un problema al realizar la solicitud",
        };
      },
    };
  };
  
  const getAll = () => {
    var { pending, fulfilled, rejected } = getAllMvTipoNotificacionAsyncThunk;
    return {
      [pending.type]: (state : typeof nominaInitialState) => {
        state.nominaState.mvTipoNotificacion.estado = DEFAULT_STATE.loading;
      },
      [fulfilled.type]: (state : typeof nominaInitialState, action : PayloadAction<IStatusMvTipoNotificacion>) => {
        const result = action.payload;
        result.data = state.nominaState.mvTipoNotificacion.data;
        result.expiry = state.nominaState.mvTipoNotificacion.expiry;
        if (result.estado === DEFAULT_STATE.error) result.data_array = state.nominaState.mvTipoNotificacion.data_array;
        state.nominaState.mvTipoNotificacion = result;
      },
      [rejected.type]: (state : typeof nominaInitialState, action : PayloadAction<IStatusMvTipoNotificacion>) => {
        state.nominaState.mvTipoNotificacion = {
          ...state.nominaState.mvTipoNotificacion,
          estado: action.payload.estado,
          mensaje: "Ocurrió un problema al realizar la solicitud",
        };
      },
    };
  };
  
  const update = () => {
    var { pending, fulfilled, rejected } = updateMvTipoNotificacionAsyncThunk;
    return {
      [pending.type]: (state : typeof nominaInitialState) => {
        state.nominaState.mvTipoNotificacion.estado = DEFAULT_STATE.loading;
      },
      [fulfilled.type]: (state : typeof nominaInitialState, action : PayloadAction<IStatusMvTipoNotificacion>) => {
        const result: IStatusMvTipoNotificacion = action.payload;
        result.expiry = state.nominaState.mvTipoNotificacion.expiry;
        result.expiry_array = state.nominaState.mvTipoNotificacion.expiry_array;
        if (result.estado === DEFAULT_STATE.error) result.data_array = state.nominaState.mvTipoNotificacion.data_array;
        state.nominaState.mvTipoNotificacion = result;
      },
      [rejected.type]: (state : typeof nominaInitialState, action : PayloadAction<IStatusMvTipoNotificacion>) => {
        state.nominaState.mvTipoNotificacion = {
          ...state.nominaState.mvTipoNotificacion,
          estado: action.payload.estado,
          mensaje: "Ocurrió un problema al realizar la solicitud",
        };
      },
    };
  };

  const create = () => {
    var { pending, fulfilled, rejected } = createMvTipoNotificacionAsyncThunk;
    return {
      [pending.type]: (state : typeof nominaInitialState) => {
        state.nominaState.mvTipoNotificacion.estado = DEFAULT_STATE.loading;
      },
      [fulfilled.type]: (state : typeof nominaInitialState, action : PayloadAction<IStatusMvTipoNotificacion>) => {
        const result = action.payload;
        result.expiry = state.nominaState.mvTipoNotificacion.expiry;
        result.expiry_array = state.nominaState.mvTipoNotificacion.expiry_array;
        if (result.estado === DEFAULT_STATE.error) result.data_array = state.nominaState.mvTipoNotificacion.data_array;
        state.nominaState.mvTipoNotificacion = result;
      },
      [rejected.type]: (state : typeof nominaInitialState, action : PayloadAction<IStatusMvTipoNotificacion>) => {
        state.nominaState.mvTipoNotificacion = {
          ...state.nominaState.mvTipoNotificacion,
          estado: action.payload.estado,
          mensaje: "Ocurrió un problema al realizar la solicitud",
        };
      },
    };
  };
  
  const del = () => {
    var { pending, fulfilled, rejected } = deleteMvTipoNotificacionAsyncThunk;
    return {
      [pending.type]: (state : typeof nominaInitialState) => {
        state.nominaState.mvTipoNotificacion.estado = DEFAULT_STATE.loading;
      },
      [fulfilled.type]: (state : typeof nominaInitialState, action : PayloadAction<IStatusMvTipoNotificacion>) => {
        const result: IStatusMvTipoNotificacion = action.payload;
        result.expiry = state.nominaState.mvTipoNotificacion.expiry;
        result.expiry_array = state.nominaState.mvTipoNotificacion.expiry_array;
        if (result.estado === DEFAULT_STATE.error) result.data_array = state.nominaState.mvTipoNotificacion.data_array;
        state.nominaState.mvTipoNotificacion = result;
      },
      [rejected.type]: (state : typeof nominaInitialState, action : PayloadAction<IStatusMvTipoNotificacion>) => {
        state.nominaState.mvTipoNotificacion = {
          ...state.nominaState.mvTipoNotificacion,
          estado: action.payload.estado,
          mensaje: "Ocurrió un problema al realizar la solicitud",
        };
      },
    };
  };

  return {
    ...get(),
    ...getAll(),
    ...update(),
    ...create(),
    ...del(),
  };
};
