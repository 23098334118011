/**
 * Métodos para el manejo de IonicStorage 
 * @setStorage              guarda un unico objeto al storage
 * @getStorage              obtiene el objeto guardado con @setStorage
 * @setStorageArray         guarda el array de objetos en el storage
 * @getStorageArray         obtiene el array de objetos guardado con @setStorageArray
 * @removeStorageArray      elimina el array de objetos guardado con @setStorageArray
 * @setStorageItemArray     agrega un elemento al array de objeto guardado con @setStorageArray
 * @getStorageItemArray     obtiene un elemento del array guardado con @setStorageArray buscandolo por su id 
 * @removeStorageItemArray  elimina un elemento del array guardado con @setStorageArray buscandolo por su id 
 */

import { setStorageData, getStorageData, removeStorageData, setArrayItemStorageData, getArrayItemStorageData, removeArrayItemStorageData } from "../../api/storage";
import { IMvEstatusIncapacidades, getEmptyMvEstatusIncapacidades } from "../../interfaces/nomina";

export const setStorageMvEstatusIncapacidades = async (IMvEstatusIncapacidades : IMvEstatusIncapacidades) => await setStorageData('IMvEstatusIncapacidades', IMvEstatusIncapacidades);
export const getStorageMvEstatusIncapacidades = async () : Promise<IMvEstatusIncapacidades> => await getStorageData('IMvEstatusIncapacidades', getEmptyMvEstatusIncapacidades());
export const removeStorageMvEstatusIncapacidades = async () => await removeStorageData('IMvEstatusIncapacidades');
export const setStorageMvEstatusIncapacidadesArray = async (IMvEstatusIncapacidades : IMvEstatusIncapacidades[]) => await setStorageData('IMvEstatusIncapacidadesArray', IMvEstatusIncapacidades);
export const getStorageMvEstatusIncapacidadesArray = async () : Promise<IMvEstatusIncapacidades[]> => await getStorageData('IMvEstatusIncapacidadesArray', [] as IMvEstatusIncapacidades[]);
export const removeStorageMvEstatusIncapacidadesArray = async () => await removeStorageData('IMvEstatusIncapacidadesArray');
export const setStorageMvEstatusIncapacidadesArrayItem = async (id: number, IMvEstatusIncapacidades : IMvEstatusIncapacidades) : Promise<IMvEstatusIncapacidades[]> => await setArrayItemStorageData('IMvEstatusIncapacidadesArray', id, IMvEstatusIncapacidades, [] as IMvEstatusIncapacidades[]);
export const getStorageMvEstatusIncapacidadesArrayItem = async (id: number) : Promise<IMvEstatusIncapacidades> => await getArrayItemStorageData('IMvEstatusIncapacidadesArray', id, getEmptyMvEstatusIncapacidades);
export const removeStorageMvEstatusIncapacidadesArrayItem = async (id: number) : Promise<IMvEstatusIncapacidades[]> => await removeArrayItemStorageData('IMvEstatusIncapacidadesArray', id, [] as IMvEstatusIncapacidades[]);
