/** 
 * Componente que se encarga del elemento Estado
 * @IEstado es la interfaz que contiene los parámetos el elemento Estado
 * @getEmptyEstado  devuelve un objeto con los parámetros por defecto; @param {id}, opcional cuando no encuentra el objeto en el LocalSotrage
 * @IStatusEstado es la interfaz para los estatus a llamadas a la API; @param {status}, opcional cuando queremos otro estado de inizialización de tipo [DEFAULT_STATE]
 * @getEmptyStatusEstado devuelve un objeto con los parámetros por defecto,
 * @setEstado devuelve un objeto de tipo [IEstado] recibiendo @param {object} y @param {serverDate}, para verificar que los datos estén en el formato correcto y ajustarlos a la zona horaria actual
 * @getSerializableEstado recibe un @param {IEstado}, para devolver un objeto serizalizable para las peticiones de la API
 * */

import { getDateString, isNotEmpty, SERVER_TIME_ZONE } from "../../api/helpers";
import { DEFAULT_STATE } from "../../enums/default_state";
import { getStorageUserArrayItem, getStoragePaisArrayItem } from "../../localStorage/personas";
import { ILocalStorageExpiry, getEmptyLocalStorageExpiry, IServerDate, convertDateToZonaHoraria } from "../time";
import { IPais, getEmptyPais, setPais } from "./IPais";
import { IUser, getEmptyUser, setUser } from "./IUser";

export interface IEstado {
  id : number,
  nombre : string,
  codigo_iso : string,
  autor : IUser,
  fecha_elaboracion : string, // Fecha
  estatus_sistema : boolean,
  ultima_actualizacion : string, // Fecha
  pais : IPais,
}

export const getEmptyEstado = (id? : number) => {
  const temp : IEstado = 
  {
    id : id ? id : 0,
    nombre : "",
    codigo_iso : "",
    autor : getEmptyUser(),
    fecha_elaboracion : "",
    estatus_sistema : false,
    ultima_actualizacion : "",
    pais : getEmptyPais(),
  };
  return temp;
}

export interface IStatusEstado {
  estado : DEFAULT_STATE,
  mensaje : string,
  data : IEstado,
  data_array: IEstado[],
  expiry : ILocalStorageExpiry,
  expiry_array : ILocalStorageExpiry,
}

export const getEmptyStatusEstado = (status: DEFAULT_STATE = DEFAULT_STATE.loading) => {
  const temp : IStatusEstado = 
  {
    estado : status,
    mensaje : "",
    data : getEmptyEstado(),
    data_array : [],
    expiry : getEmptyLocalStorageExpiry("IEstado", 0),
    expiry_array : getEmptyLocalStorageExpiry("IEstadoArray", 0),
  };
  return temp;
}

export const setEstado = async (
  object : any,
  serverDate : IServerDate,
) => {
  const temp = getEmptyEstado();
  if (isNotEmpty(object.id)) temp.id = object.id;
  if (isNotEmpty(object.nombre)) temp.nombre = object.nombre;
  if (isNotEmpty(object.codigo_iso)) temp.codigo_iso = object.codigo_iso;
  if (isNotEmpty(object.autor)) temp.autor = (typeof object.autor === 'number') ? await getStorageUserArrayItem(object.autor) : await setUser(object.autor, serverDate);
  if (isNotEmpty(object.fecha_elaboracion)) temp.fecha_elaboracion = convertDateToZonaHoraria(serverDate, object.fecha_elaboracion);
  if (isNotEmpty(object.estatus_sistema)) temp.estatus_sistema = object.estatus_sistema;
  if (isNotEmpty(object.ultima_actualizacion)) temp.ultima_actualizacion = convertDateToZonaHoraria(serverDate, object.ultima_actualizacion);
  if (isNotEmpty(object.pais)) temp.pais = (typeof object.pais === 'number') ? await getStoragePaisArrayItem(object.pais) : await setPais(object.pais, serverDate);
  return temp;
}

export const getSerializableEstado = (IEstado : IEstado) => {
  return {
    id : IEstado.id,
    nombre : IEstado.nombre,
    codigo_iso : IEstado.codigo_iso,
    autor : IEstado.autor.id,
    fecha_elaboracion : IEstado.fecha_elaboracion,
    estatus_sistema : IEstado.estatus_sistema,
    ultima_actualizacion : getDateString(SERVER_TIME_ZONE),
    pais : IEstado.pais.id > 0 ? IEstado.pais.id : null,
  };
}
