/**
 * Métodos AsyncThunk para ser usados en los reducers y
 * los resultados a las llamadas http puedan ser usados para
 * actualizar el state en redux
 */

import { createAsyncThunk } from "@reduxjs/toolkit";
import { DEFAULT_STATE } from "../../enums/default_state";
import { getMvRiesgoPuesto, getAllMvRiesgoPuesto, updateMvRiesgoPuesto, createMvRiesgoPuesto, deleteMvRiesgoPuesto } from "../../http/nomina";
import { getEmptyStatusMvRiesgoPuesto, IMvRiesgoPuesto } from "../../interfaces/nomina";
import { IServerDate } from "../../interfaces/time";

export const getMvRiesgoPuestoAsyncThunk = createAsyncThunk(
  'nomina/getMvRiesgoPuestoAsyncThunk',
  async (query : {serverDate : IServerDate, id: number}) => await getMvRiesgoPuesto(query.serverDate, query.id)
    .then(async (result) => result)
    .catch(async (error) => getEmptyStatusMvRiesgoPuesto(DEFAULT_STATE.error))  
);

export const getAllMvRiesgoPuestoAsyncThunk = createAsyncThunk(
  'nomina/getAllMvRiesgoPuestoAsyncThunk',
  async (queryAll : {serverDate : IServerDate}) => await getAllMvRiesgoPuesto(queryAll.serverDate)
    .then(async (result) => result)
    .catch(async (error) => getEmptyStatusMvRiesgoPuesto(DEFAULT_STATE.error))  
);

export const updateMvRiesgoPuestoAsyncThunk = createAsyncThunk(
  'nomina/updateMvRiesgoPuestoAsyncThunk',
  async (update: {serverDate : IServerDate, id: number, MvRiesgoPuesto: IMvRiesgoPuesto}) => await updateMvRiesgoPuesto(update.serverDate, update.id, update.MvRiesgoPuesto)
    .then(async (result) => result)
    .catch(async (error) => getEmptyStatusMvRiesgoPuesto(DEFAULT_STATE.error))  
);

export const createMvRiesgoPuestoAsyncThunk = createAsyncThunk(
  'nomina/createMvRiesgoPuestoAsyncThunk',
  async (create : {serverDate : IServerDate, MvRiesgoPuesto: IMvRiesgoPuesto}) => await createMvRiesgoPuesto(create.serverDate, create.MvRiesgoPuesto)
    .then(async (result) => result)
    .catch(async (error) => getEmptyStatusMvRiesgoPuesto(DEFAULT_STATE.error))  
);

export const deleteMvRiesgoPuestoAsyncThunk = createAsyncThunk(
  'nomina/deleteMvRiesgoPuestoAsyncThunk',
  async (del : {id : number}) => await deleteMvRiesgoPuesto(del.id)
    .then(async (result) => result)
    .catch(async (error) => getEmptyStatusMvRiesgoPuesto())  
);
