/**
 * Es el componente de la página Dashboard para visualizar un resumen datos actuales del cliente
 */

import {
  IonContent,
  IonGrid,
  IonRow,
  IonCol,
  IonLabel,
  IonText,
  IonItem,
  IonSpinner,
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonCardContent,
} from "@ionic/react";
import { gridSharp, mapSharp } from "ionicons/icons";
import { useEffect, useRef, useState } from "react";
import Card from "../components/Card";
import GoogleMapsV3 from "../components/GoogleMapsV3";
import Selector from "../components/Selector";
import { useAppSelector } from "../hooks/useRedux";
import { DEFAULT_STATE } from "../redux/enums/default_state";
import {
  GraphData,
  searchEmpleadoEnCentro,
} from "../redux/http/personas/helpers";
import { List } from "../redux/interfaces/helpers";
import {
  getMemoizeDashboardCodigoQrMarkerList,
  getMemoizedDashboardActiveCentroDeTrabajoAsList,
  setDashboardSelectedEmpresa,
} from "../redux/slices/pagesSlice/dashboardSlice";
import {
  getGlobalMemoizedEmpresaAsSelectList,
  getMemoizedCodigoQrMarkerList,
  getMemoizedCodigoQrList,
} from "../redux/slices/personasSlice";

const Dashboard: React.FC = () => {
  const empleadoStatus = useAppSelector(
    (state) => state.dashboard.empleadoStatus
  );
  const empresaState = useAppSelector(
    (state) => state.personas.personasState.empresa
  );
  const selectedEmpresa = useAppSelector(
    (state) => state.dashboard.selectedEmpresa
  );
  const empresaList = useAppSelector(getGlobalMemoizedEmpresaAsSelectList);
  const makerList = useAppSelector(getMemoizeDashboardCodigoQrMarkerList);
  const selectList = useAppSelector(
    getMemoizedDashboardActiveCentroDeTrabajoAsList
  );
  const empleadoState = useAppSelector(
    (state) => state.personas.personasState.empleado
  );
  const content = useRef<HTMLIonContentElement>(null);

  const [graphData, setGraphData] = useState<{ id: number; data: GraphData }[]>(
    []
  );
  const [selectedStatus, setSelectedStatus] = useState<List>();
  const [empleadosStatusValue, setEmpleadosStatusValue] = useState<number>(0);

  useEffect(() => {
    const loadGraph = async () => {
      setGraphData(await searchEmpleadoEnCentro());
    };
    loadGraph();
  }, []);

  useEffect(() => {
    if (!selectedStatus) return;
    if (graphData.length === 0) return;

    switch (selectedStatus.id) {
      case "1":
        setEmpleadosStatusValue(
          graphData.reduce((total, curr) => {
            return total + curr.data.empleados_presentes.length;
          }, 0)
        );
        break;
      case "2":
        setEmpleadosStatusValue(
          graphData.reduce((total, curr) => {
            return total + curr.data.empleados_con_retardo.length;
          }, 0)
        );
        break;
      case "3":
        setEmpleadosStatusValue(
          graphData.reduce((total, curr) => {
            return total + curr.data.empleados_en_horas_extra.length;
          }, 0)
        );
        break;
      case "4":
        setEmpleadosStatusValue(
          graphData.reduce((total, curr) => {
            return total + curr.data.empleados_ausentes.length;
          }, 0)
        );
        break;
      case "5":
        setEmpleadosStatusValue(
          graphData.reduce((total, curr) => {
            return total + curr.data.empleados_esperados.length;
          }, 0)
        );
        break;
    }
  }, [selectedStatus, graphData]);

  return (
    <IonContent class="inner-content" ref={content}>
      <IonGrid>
        <IonRow>
          <IonCol>
            <Card
              icon={gridSharp}
              title={
                <>
                  <IonLabel>Resumen</IonLabel>
                </>
              }
              content={
                <IonGrid className="no-padding">
                  <IonRow>
                    <IonCol
                      size="12"
                      sizeXs="12"
                      sizeSm="12"
                      sizeMd="6"
                      sizeLg="6"
                      sizeXl="6"
                    >
                      <IonCard
                        className="information-card full-height no-marging"
                        color="light"
                      >
                        <IonCardHeader>
                          <IonCardTitle>
                            <IonGrid class="no-padding">
                              <IonRow class="no-padding">
                                <IonCol size="12">
                                  <IonLabel>Centros de trabajo</IonLabel>
                                </IonCol>
                              </IonRow>
                            </IonGrid>
                          </IonCardTitle>
                        </IonCardHeader>
                        <IonCardContent class="no-padding">
                          <IonGrid className="no-padding">
                            <IonRow className="no-padding information-card-content">
                              <IonCol size="12">
                                <IonRow className="no-padding ion-align-items-center full-height">
                                  <IonCol size="12" className="no-padding">
                                    {empresaState.estado !==
                                    DEFAULT_STATE.loading ? (
                                      <Selector
                                        value={selectedEmpresa}
                                        label="Cuentas/Regiones"
                                        list={empresaList}
                                        changeState={
                                          setDashboardSelectedEmpresa
                                        }
                                      />
                                    ) : (
                                      <IonItem lines="none">
                                        <IonSpinner
                                          slot="start"
                                          name="bubbles"
                                          color="medium"
                                        />
                                        <IonLabel>Cargando...</IonLabel>
                                      </IonItem>
                                    )}
                                    <div id="work-center-selector-work-centers"></div>
                                  </IonCol>
                                </IonRow>
                              </IonCol>
                            </IonRow>
                          </IonGrid>
                        </IonCardContent>
                      </IonCard>
                    </IonCol>
                    <IonCol
                      size="12"
                      sizeXs="12"
                      sizeSm="12"
                      sizeMd="6"
                      sizeLg="6"
                      sizeXl="6"
                    >
                      <IonCard
                        className="information-card full-height no-marging"
                        color="light"
                      >
                        <IonCardHeader>
                          <IonCardTitle>
                            <IonGrid class="no-padding">
                              <IonRow class="no-padding">
                                <IonCol size="12">
                                  <IonLabel>Empleados</IonLabel>
                                </IonCol>
                              </IonRow>
                            </IonGrid>
                          </IonCardTitle>
                        </IonCardHeader>
                        <IonCardContent class="no-padding">
                          <IonGrid className="no-padding">
                            <IonRow className="no-padding information-card-content">
                              <IonCol
                                size="6"
                                className="no-padding ion-align-self-center"
                              >
                                <IonRow className="no-padding ion-align-items-center full-height">
                                  <IonCol size="12" className="no-padding">
                                    <IonText class="ion-text-center">
                                      <h3>
                                        {empleadosStatusValue} de{" "}
                                        {empleadoState.data_array.length}
                                      </h3>
                                      <h3>Laborando Totales</h3>
                                    </IonText>
                                  </IonCol>
                                </IonRow>
                              </IonCol>
                              <IonCol
                                size="6"
                                className="no-padding ion-align-self-center"
                              >
                                <IonRow className="no-padding ion-align-items-center full-height">
                                  <IonCol size="12" className="no-padding">
                                    <Selector
                                      clearLine={true}
                                      label="Empleados"
                                      list={empleadoStatus}
                                      value={selectedStatus}
                                      change={setSelectedStatus}
                                    />
                                  </IonCol>
                                </IonRow>
                              </IonCol>
                            </IonRow>
                          </IonGrid>
                        </IonCardContent>
                      </IonCard>
                    </IonCol>
                  </IonRow>
                  <IonRow className="no-padding" id="dashboard-map">
                    <IonCol>
                      <GoogleMapsV3
                        id="dashboard-map"
                        content={content.current}
                        markers={makerList}
                        listData={selectList}
                        hasSearchBox={false}
                        defaultWorkCenterSelector={false}
                      />
                    </IonCol>
                  </IonRow>
                </IonGrid>
              }
            />
          </IonCol>
        </IonRow>
      </IonGrid>
    </IonContent>
  );
};

export default Dashboard;
