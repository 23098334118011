/** 
 * Componente que se encarga del elemento MvMotivoVacaciones
 * @IMvMotivoVacaciones es la interfaz que contiene los parámetos el elemento MvMotivoVacaciones
 * @getEmptyMvMotivoVacaciones  devuelve un objeto con los parámetros por defecto; @param {id}, opcional cuando no encuentra el objeto en el LocalSotrage
 * @IStatusMvMotivoVacaciones es la interfaz para los estatus a llamadas a la API; @param {status}, opcional cuando queremos otro estado de inizialización de tipo [DEFAULT_STATE]
 * @getEmptyStatusMvMotivoVacaciones devuelve un objeto con los parámetros por defecto,
 * @setMvMotivoVacaciones devuelve un objeto de tipo [IMvMotivoVacaciones] recibiendo @param {object} y @param {serverDate}, para verificar que los datos estén en el formato correcto y ajustarlos a la zona horaria actual
 * @getSerializableMvMotivoVacaciones recibe un @param {IMvMotivoVacaciones}, para devolver un objeto serizalizable para las peticiones de la API
 * */

import { getDateString, isNotEmpty, SERVER_TIME_ZONE } from "../../api/helpers";
import { DEFAULT_STATE } from "../../enums/default_state";
import { ILocalStorageExpiry, getEmptyLocalStorageExpiry, IServerDate, convertDateToZonaHoraria } from "../time";

export interface IMvMotivoVacaciones {
  id : number,
  dato : string,
  fecha_elaboracion : string, // Fecha
  estatus_sistema : boolean,
  ultima_actualizacion : string, // Fecha
}

export const getEmptyMvMotivoVacaciones = (id? : number) => {
  const temp : IMvMotivoVacaciones = 
  {
    id : id ? id : 0,
    dato : "",
    fecha_elaboracion : "",
    estatus_sistema : false,
    ultima_actualizacion : "",
  };
  return temp;
}

export interface IStatusMvMotivoVacaciones {
  estado : DEFAULT_STATE,
  mensaje : string,
  data : IMvMotivoVacaciones,
  data_array: IMvMotivoVacaciones[],
  expiry : ILocalStorageExpiry,
  expiry_array : ILocalStorageExpiry,
}

export const getEmptyStatusMvMotivoVacaciones = (status: DEFAULT_STATE = DEFAULT_STATE.loading) => {
  const temp : IStatusMvMotivoVacaciones = 
  {
    estado : status,
    mensaje : "",
    data : getEmptyMvMotivoVacaciones(),
    data_array : [],
    expiry : getEmptyLocalStorageExpiry("IMvMotivoVacaciones", 0),
    expiry_array : getEmptyLocalStorageExpiry("IMvMotivoVacacionesArray", 0),
  };
  return temp;
}

export const setMvMotivoVacaciones = async (
  object : any,
  serverDate : IServerDate,
) => {
  const temp = getEmptyMvMotivoVacaciones();
  if (isNotEmpty(object.id)) temp.id = object.id;
  if (isNotEmpty(object.dato)) temp.dato = object.dato;
  if (isNotEmpty(object.fecha_elaboracion)) temp.fecha_elaboracion = convertDateToZonaHoraria(serverDate, object.fecha_elaboracion);
  if (isNotEmpty(object.estatus_sistema)) temp.estatus_sistema = object.estatus_sistema;
  if (isNotEmpty(object.ultima_actualizacion)) temp.ultima_actualizacion = convertDateToZonaHoraria(serverDate, object.ultima_actualizacion);
  return temp;
}

export const getSerializableMvMotivoVacaciones = (IMvMotivoVacaciones : IMvMotivoVacaciones ) => {
  return {
    id : IMvMotivoVacaciones.id,
    dato : IMvMotivoVacaciones.dato,
    fecha_elaboracion : IMvMotivoVacaciones.fecha_elaboracion,
    estatus_sistema : IMvMotivoVacaciones.estatus_sistema,
    ultima_actualizacion : getDateString(SERVER_TIME_ZONE),
  };
}
