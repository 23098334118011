/** 
 * Componente que se encarga del elemento MvTipoServicioQentry
 * @IMvTipoServicioQentry es la interfaz que contiene los parámetos el elemento MvTipoServicioQentry
 * @getEmptyMvTipoServicioQentry  devuelve un objeto con los parámetros por defecto; @param {id}, opcional cuando no encuentra el objeto en el LocalSotrage
 * @IStatusMvTipoServicioQentry es la interfaz para los estatus a llamadas a la API; @param {status}, opcional cuando queremos otro estado de inizialización de tipo [DEFAULT_STATE]
 * @getEmptyStatusMvTipoServicioQentry devuelve un objeto con los parámetros por defecto,
 * @setMvTipoServicioQentry devuelve un objeto de tipo [IMvTipoServicioQentry] recibiendo @param {object} y @param {serverDate}, para verificar que los datos estén en el formato correcto y ajustarlos a la zona horaria actual
 * @getSerializableMvTipoServicioQentry recibe un @param {IMvTipoServicioQentry}, para devolver un objeto serizalizable para las peticiones de la API
 * */

import { getDateString, isNotEmpty, SERVER_TIME_ZONE } from "../../api/helpers";
import { DEFAULT_STATE } from "../../enums/default_state";
import { getStorageUserArrayItem } from "../../localStorage/personas";
import { convertDateToZonaHoraria, ILocalStorageExpiry, getEmptyLocalStorageExpiry, IServerDate } from "../time";
import { IUser, getEmptyUser, setUser } from "./IUser";

export interface IMvTipoServicioQentry {
  id : number,
  dato : string,
  autor : IUser,
  fecha_elaboracion : string, // Fecha
  estatus_sistema : boolean,
  ultima_actualizacion : string, // Fecha
}

export const getEmptyMvTipoServicioQentry = (id? : number) => {
  const temp : IMvTipoServicioQentry = 
  {
    id : id ? id : 0,
    dato : "",
    autor : getEmptyUser(),
    fecha_elaboracion : "",
    estatus_sistema : false,
    ultima_actualizacion :  "",
  };
  return temp;
}

export interface IStatusMvTipoServicioQentry {
  estado : DEFAULT_STATE,
  mensaje : string,
  data : IMvTipoServicioQentry,
  data_array: IMvTipoServicioQentry[],
  expiry : ILocalStorageExpiry,
  expiry_array : ILocalStorageExpiry,
}

export const getEmptyStatusMvTipoServicioQentry = (status: DEFAULT_STATE = DEFAULT_STATE.loading) => {
  const temp : IStatusMvTipoServicioQentry = 
  {
    estado : status,
    mensaje : "",
    data : getEmptyMvTipoServicioQentry(),
    data_array : [],
    expiry : getEmptyLocalStorageExpiry("IMvTipoServicioQentry", 0),
    expiry_array : getEmptyLocalStorageExpiry("IMvTipoServicioQentryArray", 0),
  };
  return temp;
}

export const setMvTipoServicioQentry = async (
  object : any,
  serverDate : IServerDate,
) => {
  const temp = getEmptyMvTipoServicioQentry();
  if (isNotEmpty(object.id)) temp.id = object.id;
  if (isNotEmpty(object.dato)) temp.dato = object.dato;
  if (isNotEmpty(object.autor)) temp.autor = (typeof object.autor === 'number') ? await getStorageUserArrayItem(object.autor) : await setUser(object.autor, serverDate);
  if (isNotEmpty(object.fecha_elaboracion)) temp.fecha_elaboracion = convertDateToZonaHoraria(serverDate, object.fecha_elaboracion);
  if (isNotEmpty(object.estatus_sistema)) temp.estatus_sistema = object.estatus_sistema;
  if (isNotEmpty(object.ultima_actualizacion)) temp.ultima_actualizacion = convertDateToZonaHoraria(serverDate, object.ultima_actualizacion);
  return temp;
}

export const getSerializableMvTipoServicioQentry = (IMvTipoServicioQentry : IMvTipoServicioQentry) => {
  return {
    id : IMvTipoServicioQentry.id,
    dato : IMvTipoServicioQentry.dato,
    autor : IMvTipoServicioQentry.autor.id,
    fecha_elaboracion : IMvTipoServicioQentry.fecha_elaboracion,
    estatus_sistema : IMvTipoServicioQentry.estatus_sistema,
    ultima_actualizacion : getDateString(SERVER_TIME_ZONE),
  };
}
