/**
 * Métodos para el manejo de IonicStorage 
 * @setStorage              guarda un unico objeto al storage
 * @getStorage              obtiene el objeto guardado con @setStorage
 * @setStorageArray         guarda el array de objetos en el storage
 * @getStorageArray         obtiene el array de objetos guardado con @setStorageArray
 * @removeStorageArray      elimina el array de objetos guardado con @setStorageArray
 * @setStorageItemArray     agrega un elemento al array de objeto guardado con @setStorageArray
 * @getStorageItemArray     obtiene un elemento del array guardado con @setStorageArray buscandolo por su id 
 * @removeStorageItemArray  elimina un elemento del array guardado con @setStorageArray buscandolo por su id 
 */

import { setStorageData, getStorageData, removeStorageData, setArrayItemStorageData, getArrayItemStorageData, removeArrayItemStorageData } from "../../api/storage";
import { IVacaciones, getEmptyVacaciones } from "../../interfaces/nomina";

export const setStorageVacaciones = async (IVacaciones : IVacaciones) => await setStorageData('IVacaciones', IVacaciones);
export const getStorageVacaciones = async () : Promise<IVacaciones> => await getStorageData('IVacaciones', getEmptyVacaciones());
export const removeStorageVacaciones = async () => await removeStorageData('IVacaciones');
export const setStorageVacacionesArray = async (IVacaciones : IVacaciones[]) => await setStorageData('IVacacionesArray', IVacaciones);
export const getStorageVacacionesArray = async () : Promise<IVacaciones[]> => await getStorageData('IVacacionesArray', [] as IVacaciones[]);
export const removeStorageVacacionesArray = async () => await removeStorageData('IVacacionesArray');
export const setStorageVacacionesArrayItem = async (id: number, IVacaciones : IVacaciones) : Promise<IVacaciones[]> => await setArrayItemStorageData('IVacacionesArray', id, IVacaciones, [] as IVacaciones[]);
export const getStorageVacacionesArrayItem = async (id: number) : Promise<IVacaciones> => await getArrayItemStorageData('IVacacionesArray', id, getEmptyVacaciones);
export const removeStorageVacacionesArrayItem = async (id: number) : Promise<IVacaciones[]> => await removeArrayItemStorageData('IVacacionesArray', id, [] as IVacaciones[]);
