/**
 * Métodos para el manejo de IonicStorage 
 * @setStorage              guarda un unico objeto al storage
 * @getStorage              obtiene el objeto guardado con @setStorage
 * @setStorageArray         guarda el array de objetos en el storage
 * @getStorageArray         obtiene el array de objetos guardado con @setStorageArray
 * @removeStorageArray      elimina el array de objetos guardado con @setStorageArray
 * @setStorageItemArray     agrega un elemento al array de objeto guardado con @setStorageArray
 * @getStorageItemArray     obtiene un elemento del array guardado con @setStorageArray buscandolo por su id 
 * @removeStorageItemArray  elimina un elemento del array guardado con @setStorageArray buscandolo por su id 
 */

import { setStorageData, getStorageData, removeStorageData, setArrayItemStorageData, getArrayItemStorageData, removeArrayItemStorageData } from "../../api/storage";
import { IMvBanco, getEmptyMvBanco } from "../../interfaces/personas";

export const setStorageMvBanco = async (IMvBanco : IMvBanco) => await setStorageData('IMvBanco', IMvBanco);
export const getStorageMvBanco = async () : Promise<IMvBanco> => await getStorageData('IMvBanco', getEmptyMvBanco());
export const removeStorageMvBanco = async () => await removeStorageData('IMvBanco');
export const setStorageMvBancoArray = async (IMvBanco : IMvBanco[]) => await setStorageData('IMvBancoArray', IMvBanco);
export const getStorageMvBancoArray = async () : Promise<IMvBanco[]> => await getStorageData('IMvBancoArray', [] as IMvBanco[]);
export const removeStorageMvBancoArray = async () => await removeStorageData('IMvBancoArray');
export const setStorageMvBancoArrayItem = async (id: number, IMvBanco : IMvBanco) : Promise<IMvBanco[]> => await setArrayItemStorageData('IMvBancoArray', id, IMvBanco, [] as IMvBanco[]);
export const getStorageMvBancoArrayItem = async (id: number) : Promise<IMvBanco> => await getArrayItemStorageData('IMvBancoArray', id, getEmptyMvBanco);
export const removeStorageMvBancoArrayItem = async (id: number) : Promise<IMvBanco[]> => await removeArrayItemStorageData('IMvBancoArray', id, [] as IMvBanco[]);
