/**
 * Métodos del, get, post, put para llamadas a la API
 * @get     en este método primero consulta con @getFromLocalStorage si es necesario hacer la petición http sino se obtiene del LocalStorage;
 *          si hay un error en la petición http se busca el objeto en el LocalStorage sino lo encuentra devuelve un objeto vacío; si la
 *          petición se realiza correctamente actualiza el valor individual y en el array del objeto en el LocalStorage y devuelve el objeto
 *          obtenido del servidor.
 * @getAll  en este método primero consulta con @getFromLocalStorage si es necesario hacer la petición http sino se obtiene del LocalStorage;
 *          si hay un error en la petición http se busca el objeto array en el LocalStorage sino lo encuentra devuelve un array vacío; si la
 *          petición se realiza correctamente actualiza array en el LocalStorage y devuelve el array obtenido del servidor
 * @update  en este método después de realizar la petición http si falla devuelve el objeto que se iba a actualizar; si la petición se
 *          realiza correctamente actualiza el valor individual y en el array del objeto en el LocalStorage y devuelve el objeto obtenido del
 *          servidor.
 * @create  en este método después de realizar la petición http si falla devuelve el objeto que se iba a crear; si la petición se realiza
 *          correctamente actualiza el valor individual y en el array del objeto en el LocalStorage y devuelve el objeto obtenido del
 *          servidor.
 * @delete  en este método después de realizar la petición http si falla devuelve el objeto que se iba a eliminar; si la petición se realiza
 *          correctamente elimina el valor individual y en el objeto en el arrya del LocalStorage y devuelve un objeto vacio (Default)
 */

import { del, get, post, put } from "../../api/api";
import {
  getFromLocalStorage,
  updateLocalStorageExpiry,
} from "../../api/helpers";
import { removeArrayItemStorageData } from "../../api/storage";
import { DEFAULT_STATE } from "../../enums/default_state";
import {
  getEmptyStatusMvUsoFactura,
  setMvUsoFactura,
  IMvUsoFactura,
  getSerializableMvUsoFactura,
} from "../../interfaces/personas";
import { IServerDate } from "../../interfaces/time";
import {
  getStorageMvUsoFacturaArrayItem,
  setStorageMvUsoFactura,
  setStorageMvUsoFacturaArrayItem,
  getStorageMvUsoFacturaArray,
  setStorageMvUsoFacturaArray,
  removeStorageMvUsoFactura,
} from "../../localStorage/personas";

export const getMvUsoFactura = async (serverDate: IServerDate, pk: number) => {
  const temp = getEmptyStatusMvUsoFactura();
  const doHttp = await getFromLocalStorage(serverDate, temp.expiry);
  temp.expiry = doHttp.storage;
  if (!doHttp.result) {
    console.log(
      `(localStorage) MvUsoFactura con pk: ${pk}, obtenido con éxito`
    );
    temp.estado = DEFAULT_STATE.success;
    temp.mensaje = `MvUsoFactura obtenido con éxito`;
    temp.data = await getStorageMvUsoFacturaArrayItem(pk);
    return temp;
  }
  const result = await get(`personas/uso-factura/${pk}`);
  const data = result.data;
  console.log(`MvUsoFactura data: `, data);
  if (!data.id || data.detail) {
    console.log(`Error al obtener MvUsoFactura con pk: ${pk}, ${data.detail}`);
    temp.estado = DEFAULT_STATE.error;
    temp.mensaje = `Error al obtener el uso factura: ${
      data.detail ?? "Hubo un problema al conectarse con el servidor"
    }`;
    temp.data = await getStorageMvUsoFacturaArrayItem(pk);
    return temp;
  }
  console.log(`MvUsoFactura con pk: ${pk}, obtenido con éxito`);
  temp.estado = DEFAULT_STATE.success;
  temp.mensaje = `MvUsoFactura obtenido con éxito`;
  temp.data = await setMvUsoFactura(data, serverDate);
  await setStorageMvUsoFactura(temp.data);
  temp.data_array = await setStorageMvUsoFacturaArrayItem(pk, temp.data);
  temp.expiry = await updateLocalStorageExpiry(serverDate, temp.expiry);
  return temp;
};

export const getAllMvUsoFactura = async (serverDate: IServerDate) => {
  const temp = getEmptyStatusMvUsoFactura();
  const doHttp = await getFromLocalStorage(serverDate, temp.expiry_array);
  temp.expiry_array = doHttp.storage;
  if (!doHttp.result) {
    console.log(`(localStorage) Todos los MvUsoFacturas obtenidos con éxito`);
    temp.estado = DEFAULT_STATE.success;
    temp.mensaje = `Todos los uso facturas obtenidos con éxito`;
    temp.data_array = await getStorageMvUsoFacturaArray();
    return temp;
  }
  const result = await get(`personas/uso-factura`);
  const data = result.data;
  if (data.detail) {
    console.log(`Error al obtener la lista de MvUsoFactura, ${data.detail}`);
    temp.estado = DEFAULT_STATE.error;
    temp.mensaje = `Error al obtener la lista de uso facturas: ${
      data.detail ?? "Hubo un problema al conectarse con el servidor"
    }`;
    temp.data_array = await getStorageMvUsoFacturaArray();
    return temp;
  }
  console.log(`Todos los MvUsoFacturaes obtenidos con éxito`);
  temp.estado = DEFAULT_STATE.success;
  temp.mensaje = `Todos los uso facturas obtenidos con éxito`;
  temp.data_array = await Promise.all(
    data.map(async (e: any) => await setMvUsoFactura(e, serverDate))
  );
  await setStorageMvUsoFacturaArray(temp.data_array);
  temp.expiry_array = await updateLocalStorageExpiry(
    serverDate,
    temp.expiry_array
  );
  return temp;
};

export const updateMvUsoFactura = async (
  serverDate: IServerDate,
  pk: number,
  object: IMvUsoFactura
) => {
  const temp = getEmptyStatusMvUsoFactura();
  const result = await put(
    `personas/uso-factura/${pk}`,
    getSerializableMvUsoFactura(object)
  );
  const data = result.data;
  if (!data.id || data.detail) {
    console.log(
      `Error al actualizar MvUsoFactura con pk: ${pk}, ${data.detail}`
    );
    temp.estado = DEFAULT_STATE.error;
    temp.mensaje = `Error al actualizar el uso factura: ${
      data.detail ?? "Hubo un problema al conectarse con el servidor"
    }`;
    temp.data = await setMvUsoFactura(object, serverDate);
    return temp;
  }
  console.log(`MvUsoFactura con pk: ${pk}, actualizado con éxito`);
  temp.estado = DEFAULT_STATE.success;
  temp.mensaje = `MvUsoFactura actualizado con éxito`;
  temp.data = await setMvUsoFactura(data, serverDate);
  await setStorageMvUsoFactura(temp.data);
  temp.data_array = await setStorageMvUsoFacturaArrayItem(
    temp.data.id,
    temp.data
  );
  return temp;
};

export const createMvUsoFactura = async (
  serverDate: IServerDate,
  object: IMvUsoFactura
) => {
  const temp = getEmptyStatusMvUsoFactura();
  const result = await post(
    `personas/uso-factura`,
    getSerializableMvUsoFactura(object)
  );
  const data = result.data;
  if (!data.id || data.detail) {
    console.log(
      `Error al crear MvUsoFactura con los datos ${object}, ${data.detail}`
    );
    temp.estado = DEFAULT_STATE.error;
    temp.mensaje = `Error al crear el uso factura: ${
      data.detail ?? "Hubo un problema al conectarse con el servidor"
    }`;
    temp.data = await setMvUsoFactura(object, serverDate);
    return temp;
  }
  console.log(`MvUsoFactura creado con éxito`);
  temp.estado = DEFAULT_STATE.success;
  temp.mensaje = `MvUsoFactura creado con éxito`;
  temp.data = await setMvUsoFactura(data, serverDate);
  await setStorageMvUsoFactura(temp.data);
  temp.data_array = await setStorageMvUsoFacturaArrayItem(
    temp.data.id,
    temp.data
  );
  return temp;
};

export const deleteMvUsoFactura = async (pk: number) => {
  const temp = getEmptyStatusMvUsoFactura();
  const result = await del(`personas/uso-factura/${pk}`);
  const data = result.data;
  if (data.detail) {
    console.log(`Error al eliminar MvUsoFactura con pk: ${pk}, ${data.detail}`);
    temp.estado = DEFAULT_STATE.error;
    temp.mensaje = `Error al eliminar el uso factura: ${data.detail}`;
    temp.data = await getStorageMvUsoFacturaArrayItem(pk);
    temp.data_array = await getStorageMvUsoFacturaArray();
    return temp;
  }
  console.log(`MvUsoFactura eliminado con éxito`);
  temp.estado = DEFAULT_STATE.success;
  temp.mensaje = `MvUsoFactura eliminado con éxito`;
  await removeStorageMvUsoFactura();
  temp.data_array = await removeArrayItemStorageData("IMvUsoFactura", pk, []);
  return temp;
};
