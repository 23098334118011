import {
  IonAccordion,
  IonAccordionGroup,
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonCheckbox,
  IonCol,
  IonContent,
  IonGrid,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonPopover,
  IonProgressBar,
  IonRow,
  IonSearchbar,
  IonSpinner,
  IonText,
  useIonModal,
  useIonToast,
} from "@ionic/react";
import {
  checkmark,
  chevronBackSharp,
  chevronForwardSharp,
  download,
  ellipsisVertical,
  pencil,
  personRemove,
  close,
  business,
  add,
  briefcaseSharp,
} from "ionicons/icons";
import xlsx, { utils } from "json-as-xlsx";
import { useEffect, useRef, useState } from "react";
import Card from "../components/Card";
import {
  EditCentroDeTrabajoModal,
  CreateCentroDeTrabajoModal,
} from "../components/CentroDeTrabajoModal";
import GoogleMapsV3 from "../components/GoogleMapsV3";
import Selector from "../components/Selector";
import useRedux, { useAppDispatch, useAppSelector } from "../hooks/useRedux";
import { convertDMS } from "../redux/api/helpers";
import {
  activateCentroDeTrabajoAsyncThunk,
  deactivateCentroDeTrabajoAsyncThunk,
  deleteMultipleCentroDeTrabajoAsyncThunk,
} from "../redux/asyncThunk/personas";
import { DEFAULT_STATE } from "../redux/enums/default_state";
import { setListItem } from "../redux/interfaces/helpers";
import { getEmptyCodigoQr } from "../redux/interfaces/personas";
import {
  activeWorkCentersNextPage,
  activeWorkCentersPreviousPage,
  addAllWorkCentersActiveSelectedCentroDeTrabajo,
  addAllWorkCentersInactiveSelectedCentroDeTrabajo,
  addWorkCentersActiveSelectedCentroDeTrabajo,
  addWorkCentersInactiveSelectedCentroDeTrabajo,
  getMemoizedWorkCentersActiveCentroDeTrabajo,
  getMemoizedWorkCentersActiveCentroDeTrabajoAsList,
  getMemoizedWorkCentersActivePageMessage,
  getMemoizedWorkCentersActiveSearch,
  getMemoizedWorkCentersActiveTotalPages,
  getMemoizedWorkCentersInactiveCentroDeTrabajo,
  getMemoizedWorkCentersInactivePageMessage,
  getMemoizedWorkCentersInactiveSearch,
  getMemoizedWorkCentersInactiveTotalPages,
  getMemoizeWorkCentersCodigoQrMarkerList,
  ICreateCentroDeTrabajoData,
  inactiveWorkCentersNextPage,
  inactiveWorkCentersPreviousPage,
  removeAllWorkCentersActiveSelectedCentroDeTrabajo,
  removeAllWorkCentersInactiveSelectedCentroDeTrabajo,
  removeWorkCentersActiveSelectedCentroDeTrabajo,
  removeWorkCentersInactiveSelectedCentroDeTrabajo,
  resetWorkCentersActivePage,
  resetWorkCentersInactivePage,
  setWorkCentersActiveLoading,
  setWorkCentersActiveRemoveStatus,
  setWorkCentersActiveSearchValue,
  setWorkCentersCentroDeTrabajoModal,
  setWorkCentersInactiveActivateStatus,
  setWorkCentersInactiveLoading,
  setWorkCentersInactiveRemoveStatus,
  setWorkCentersInactiveSearchValue,
  setWorkCentersPlaceModal,
  setWorkCentersSelectedEmpresa,
} from "../redux/slices/pagesSlice/workCentersSlice";
import {
  getGlobalMemoizedEmpresaAsSelectList,
  getMemoizedCentrosDeTrabajoAsSelectList,
} from "../redux/slices/personasSlice";

import "./WorkCenters.css";

const WorkCenter: React.FC = () => {
  const [{ clienteQentry, fetchCentrosDeTrabajo, createCentrosDeTrabajo }] =
    useRedux();
  const dispatch = useAppDispatch();
  let [presentToast, dismissToast] = useIonToast();

  const content = useRef<HTMLIonContentElement>(null);

  const serverDate = useAppSelector((state) => state.time.timeState.serverDate);
  const clienteQentryState = useAppSelector(
    (state) => state.personas.personasState.clienteQentry
  );
  const empresaState = useAppSelector(
    (state) => state.personas.personasState.empresa
  );
  const codigoQrState = useAppSelector(
    (state) => state.personas.personasState.codigoQr
  );
  const centroDeTrabajoState = useAppSelector(
    (state) => state.personas.personasState.centroDeTrabajo
  );
  const centroDeTrabajoList = useAppSelector(
    getMemoizedCentrosDeTrabajoAsSelectList
  );
  const selectedEmpresa = useAppSelector(
    (state) => state.workCenters.selectedEmpresa
  );
  const empresaList = useAppSelector(getGlobalMemoizedEmpresaAsSelectList);
  const makerList = useAppSelector(getMemoizeWorkCentersCodigoQrMarkerList);
  const selectList = useAppSelector(
    getMemoizedWorkCentersActiveCentroDeTrabajoAsList
  );
  const createCentroDeTrabajo = useAppSelector(
    (state) => state.workCenters.centroDeTrabajoCreate
  );

  // Active table
  const active = useAppSelector(getMemoizedWorkCentersActiveCentroDeTrabajo);
  const activeRemoveStatus = useAppSelector(
    (state) => state.workCenters.activeTable.remove
  );
  const activeSearchResult = useAppSelector(getMemoizedWorkCentersActiveSearch);
  const activeLoading = useAppSelector(
    (state) => state.workCenters.activeTable.loading
  );
  const activeSelected = useAppSelector(
    (state) => state.workCenters.activeTable.selectedCentrosDeTrabajo
  );
  const activeIsInteterminate = useAppSelector(
    (state) => state.workCenters.activeTable.isIndeterminate
  );
  const activeAllSelected = useAppSelector(
    (state) => state.workCenters.activeTable.selectAll
  );
  const activeTotalPages = useAppSelector(
    getMemoizedWorkCentersActiveTotalPages
  );
  const activeSearchValue = useAppSelector(
    (state) => state.workCenters.activeTable.searchText
  );
  const activePageMessage = useAppSelector(
    getMemoizedWorkCentersActivePageMessage
  );
  const activePage = useAppSelector(
    (state) => state.workCenters.activeTable.page
  );
  const activeMaxPerPage = useAppSelector(
    (state) => state.workCenters.activeTable.maxPerPage
  );
  const activeFirstItem = (activePage - 1) * activeMaxPerPage;
  const activeLastItem = activeFirstItem + activeMaxPerPage - 1;

  // Inactive table
  const inactive = useAppSelector(
    getMemoizedWorkCentersInactiveCentroDeTrabajo
  );
  const inactiveRemoveStatus = useAppSelector(
    (state) => state.workCenters.inactiveTable.remove
  );
  const inactiveActivateStatus = useAppSelector(
    (state) => state.workCenters.inactiveTable.activate
  );
  const inactiveSearchResult = useAppSelector(
    getMemoizedWorkCentersInactiveSearch
  );
  const inactiveLoading = useAppSelector(
    (state) => state.workCenters.inactiveTable.loading
  );
  const inactiveSelected = useAppSelector(
    (state) => state.workCenters.inactiveTable.selectedCentrosDeTrabajo
  );
  const inactiveIsInteterminate = useAppSelector(
    (state) => state.workCenters.inactiveTable.isIndeterminate
  );
  const inactiveAllSelected = useAppSelector(
    (state) => state.workCenters.inactiveTable.selectAll
  );
  const inactiveTotalPages = useAppSelector(
    getMemoizedWorkCentersInactiveTotalPages
  );
  const inactiveSearchValue = useAppSelector(
    (state) => state.workCenters.inactiveTable.searchText
  );
  const inactivePageMessage = useAppSelector(
    getMemoizedWorkCentersInactivePageMessage
  );
  const inactivePage = useAppSelector(
    (state) => state.workCenters.inactiveTable.page
  );
  const inactiveMaxPerPage = useAppSelector(
    (state) => state.workCenters.inactiveTable.maxPerPage
  );
  const inactiveFirstItem = (inactivePage - 1) * inactiveMaxPerPage;
  const inactiveLastItem = inactiveFirstItem + inactiveMaxPerPage - 1;

  // CentroDeTrabajo modal
  const centroDeTrabajoId = useAppSelector(
    (state) => state.workCenters.centroDeTrabajoModal.id
  );
  const [centroDeTrabajoModalActive, setCentroDeTrabajoModalActive] =
    useState<boolean>(false);

  // New work center
  const place = useAppSelector((state) => state.workCenters.placeModal);

  useEffect(() => {
    console.log("(XS) onIonChange: ", centroDeTrabajoId);
    if (centroDeTrabajoId !== 0 && !centroDeTrabajoModalActive) {
      setCentroDeTrabajoModalActive(true);
      presentEditCentroDeTrabajoModal({ backdropDismiss: false });
    }
  }, [centroDeTrabajoId]);
  const [presentEditCentroDeTrabajoModal, dismissEditCentroDeTrabajoModal] =
    useIonModal(EditCentroDeTrabajoModal, {
      onDismiss: () => {
        setCentroDeTrabajoModalActive(false);
        dismissEditCentroDeTrabajoModal();
        dispatch(setWorkCentersCentroDeTrabajoModal(getEmptyCodigoQr()));
      },
    });
  const [presentCreateCentroDeTrabajoModal, dismissCreateCentroDeTrabajoModal] =
    useIonModal(CreateCentroDeTrabajoModal, {
      data: createCentroDeTrabajo,
      onDismiss: () => {
        setCentroDeTrabajoModalActive(false);
        dismissCreateCentroDeTrabajoModal();
      },
      autofocus: 0,
      accept: async (v: ICreateCentroDeTrabajoData) => {
        setCentroDeTrabajoModalActive(false);
        createCentrosDeTrabajo(v);
      },
    });

  const handlePlace = (place: google.maps.LatLng[]) => {
    dispatch(
      setWorkCentersPlaceModal([{ lat: place[0].lat(), lng: place[0].lng() }])
    );
  };

  const downloadWorkCentersData = () => {
    // utils.aoa_to_sheet([ [ "Link", "No Link" ] ]);

    const data = [
      {
        sheet: "Trabajadores",
        columns: [
          { label: "Nombre", value: "nombre_centro" },
          { label: "Direccion", value: "direccion" },
          { label: "Empresa", value: "empresa" },
          {
            label: "Distancia máxima permitida",
            value: "distancia_maxima_permitida",
          },
          {
            label: "Número de emplados asignados",
            value: "numero_empleados_asignados",
          },
          { label: "Imagen", value: "imagen" },
          { label: "Ubicación (Google Maps)", value: "ubicacion" },
        ],
        content: makerList.map((e) => {
          return {
            nombre_centro: e.name,
            direccion: e.direccion,
            empresa: e.empresa,
            distancia_maxima_permitida: e.distancia_maxima_permitida,
            numero_empleados_asignados: e.numero_empleados_asignados,
            imagen: {
              v: `${process.env.REACT_APP_STORAGE_URL}archivos/${e.qrCode}/${e.qrCode}.png`,
              l: {
                Target: `${process.env.REACT_APP_STORAGE_URL}archivos/${e.qrCode}/${e.qrCode}.png`,
                Tooltip: `${process.env.REACT_APP_STORAGE_URL}archivos/${e.qrCode}/${e.qrCode}.png`,
              },
            },
            ubicacion: {
              v: `https://www.google.com/maps/place/${convertDMS(
                e.position.lat,
                e.position.lng
              )}/@${e.position.lat},${e.position.lng}z`,
              l: {
                Target: `https://www.google.com/maps/place/${convertDMS(
                  e.position.lat,
                  e.position.lng
                )}/@${e.position.lat},${e.position.lng}z`,
                Tooltip: `https://www.google.com/maps/place/${convertDMS(
                  e.position.lat,
                  e.position.lng
                )}/@${e.position.lat},${e.position.lng}z`,
              },
            },
          };
        }),
      },
    ];

    const settings = {
      fileName: "Lista centros de trabajo activos", // Name of the resulting spreadsheet
      extraLength: 3, // A bigger number means that columns will be wider
      writeMode: "writeFile", // The available parameters are 'WriteFile' and 'write'. This setting is optional. Useful in such cases https://docs.sheetjs.com/docs/solutions/output#example-remote-file
      writeOptions: {}, // Style options from https://docs.sheetjs.com/docs/api/write-options
    };

    xlsx(data, settings, (workbook) => {
      utils.aoa_to_sheet([["Link", "No Link"]]);
    });
  };

  const downloadImage = async (url: string | null, fileName: string) => {
    if (url) {
      const image = await fetch(url);
      const imageBlob = await image.blob();
      const imageURL = URL.createObjectURL(imageBlob);
      const link = document.createElement("a");
      link.href = imageURL;
      link.download = fileName + ".png";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else {
      console.log("Nada que descargar");
    }
  };

  return (
    <IonContent class="inner-content" scrollEvents={true} ref={content}>
      <IonPopover
        trigger="popover-options-active-codigos-qr"
        triggerAction={"click"}
        dismissOnSelect={true}
        alignment="center"
      >
        <IonContent>
          <IonList>
            <IonItem
              lines="full"
              button={true}
              type="button"
              detail={false}
              disabled={activeRemoveStatus}
              onClick={() => dispatch(setWorkCentersActiveRemoveStatus(true))}
            >
              <IonText>
                <h6>
                  <b>Desactivar códigos qr</b>
                </h6>
                <p>
                  <i>Inhabilita temporalmente a tus códigos qr</i>
                </p>
              </IonText>
            </IonItem>
            <IonItem
              lines="full"
              button={true}
              type="button"
              detail={false}
              onClick={downloadWorkCentersData}
            >
              <IonText>
                <h6>
                  <b>Exportar</b>
                </h6>
                <p>
                  <i>Descarga los datos de tus códigos qr en formato excel</i>
                </p>
              </IonText>
            </IonItem>
          </IonList>
        </IonContent>
      </IonPopover>
      <IonPopover
        trigger="popover-options-inactive-codigos-qr"
        triggerAction={"click"}
        dismissOnSelect={true}
        side="bottom"
        alignment="end"
      >
        <IonContent>
          <IonList>
            <IonItem
              lines="full"
              button={true}
              detail={false}
              type="button"
              disabled={inactiveRemoveStatus || inactiveActivateStatus}
              onClick={() =>
                dispatch(setWorkCentersInactiveActivateStatus(true))
              }
            >
              <IonLabel>Activar códigos qr</IonLabel>
              <IonIcon slot="start" icon={personRemove} />
            </IonItem>
            <IonItem
              lines="full"
              button={true}
              detail={false}
              type="button"
              disabled={inactiveRemoveStatus || inactiveActivateStatus}
              onClick={() => dispatch(setWorkCentersInactiveRemoveStatus(true))}
            >
              <IonLabel>Eliminar códigos qr</IonLabel>
              <IonIcon slot="start" icon={personRemove} />
            </IonItem>
            <IonItem lines="full" button={true} detail={false}>
              <IonLabel>Descargar lista actual</IonLabel>
              <IonIcon slot="start" icon={download} />
            </IonItem>
          </IonList>
        </IonContent>
      </IonPopover>
      <IonGrid>
        <IonRow>
          <IonCol>
            <Card
              icon={briefcaseSharp}
              title={
                <>
                  <IonLabel>Resumen de centros de trabajo</IonLabel>
                </>
              }
              content={
                <IonGrid className="no-padding">
                  <IonRow>
                    <IonCol
                      sizeXl="6"
                      sizeLg="6"
                      sizeMd="6"
                      sizeSm="6"
                      sizeXs="12"
                      size="12"
                    >
                      <IonText>
                        <h5>
                          Toda la información de tus cuentas y los centros de
                          trabajo los puedes encontrar aquí.
                        </h5>
                      </IonText>
                      {empresaState.estado !== DEFAULT_STATE.loading ? (
                        <Selector
                          value={selectedEmpresa}
                          label="Cuentas/Regiones"
                          list={empresaList}
                          changeState={setWorkCentersSelectedEmpresa}
                        />
                      ) : (
                        <IonItem lines="none">
                          <IonSpinner
                            slot="start"
                            name="bubbles"
                            color="medium"
                          />
                          <IonLabel>Cargando...</IonLabel>
                        </IonItem>
                      )}
                      <div id="work-center-selector-work-centers"></div>
                    </IonCol>
                    <IonCol
                      sizeXl="6"
                      sizeLg="6"
                      sizeMd="6"
                      sizeSm="6"
                      sizeXs="12"
                      size="12"
                    >
                      <IonCard
                        className="information-card full-height no-marging"
                        color="light"
                      >
                        <IonCardHeader>
                          <IonCardTitle>
                            <IonGrid className="no-padding">
                              <IonRow className="no-padding">
                                <IonCol size="6">
                                  <IonLabel>
                                    Resumen de centros de trabajo
                                  </IonLabel>
                                </IonCol>
                                <IonCol size="6">
                                  <IonButton
                                    expand="block"
                                    fill="outline"
                                    onClick={() => {
                                      if (place.length > 0) {
                                        presentCreateCentroDeTrabajoModal({
                                          backdropDismiss: false,
                                        });
                                      } else {
                                        presentToast({
                                          message:
                                            "Por favor, seleccione algún punto en el mapa",
                                          color: "warning",
                                          duration: 1500,
                                        });
                                      }
                                    }}
                                  >
                                    <IonIcon icon={add} />
                                    <IonIcon icon={business} />
                                  </IonButton>
                                </IonCol>
                              </IonRow>
                            </IonGrid>
                          </IonCardTitle>
                        </IonCardHeader>
                        <IonCardContent class="no-padding">
                          <IonGrid className="no-padding">
                            <IonRow className="no-padding information-card-content">
                              <IonCol
                                size="6"
                                className="no-padding ion-align-self-center"
                              >
                                <IonRow className="no-padding ion-align-items-center full-height">
                                  <IonCol size="12" className="no-padding">
                                    <IonText class="ion-text-center">
                                      {centroDeTrabajoState.estado ===
                                      DEFAULT_STATE.loading ? (
                                        <IonSpinner
                                          slot="start"
                                          name="bubbles"
                                          color="medium"
                                        />
                                      ) : (
                                        <h3>{active.length}</h3>
                                      )}
                                    </IonText>
                                  </IonCol>
                                  <IonCol size="12" className="no-padding">
                                    <IonText class="ion-text-center">
                                      <h3>Centros de trabajo activos</h3>
                                    </IonText>
                                  </IonCol>
                                </IonRow>
                              </IonCol>
                              <IonCol
                                size="6"
                                className="no-padding ion-align-self-center"
                              >
                                <IonRow className="no-padding ion-align-items-center full-height">
                                  <IonCol size="12" className="no-padding">
                                    <IonText class="ion-text-center">
                                      {centroDeTrabajoState.estado ===
                                      DEFAULT_STATE.loading ? (
                                        <IonSpinner
                                          slot="start"
                                          name="bubbles"
                                          color="medium"
                                        />
                                      ) : (
                                        <h3>{inactive.length}</h3>
                                      )}
                                    </IonText>
                                  </IonCol>
                                  <IonCol size="12" className="no-padding">
                                    <IonText class="ion-text-center">
                                      <h3>Centros de trabajo inactivos</h3>
                                    </IonText>
                                  </IonCol>
                                </IonRow>
                              </IonCol>
                            </IonRow>
                          </IonGrid>
                        </IonCardContent>
                      </IonCard>
                    </IonCol>
                    <IonCol size="12">
                      <IonAccordionGroup>
                        <IonAccordion value="active-codigos-qr">
                          <IonItem
                            className="accordion-header"
                            lines="full"
                            slot="header"
                          >
                            <IonLabel>Lista de códigos qr activos</IonLabel>
                          </IonItem>
                          <IonList lines="full" slot="content">
                            <IonItem
                              color="light"
                              className="table-helper"
                              disabled={
                                centroDeTrabajoState.estado ===
                                DEFAULT_STATE.loading
                              }
                            >
                              <IonSearchbar
                                color="light"
                                class="search-bar"
                                // value={activeSearchValue}
                                onIonInput={() => {
                                  if (!activeLoading)
                                    dispatch(setWorkCentersActiveLoading(true));
                                }}
                                debounce={400}
                                onIonChange={async (e) => {
                                  dispatch(resetWorkCentersActivePage());
                                  dispatch(
                                    setWorkCentersActiveSearchValue(
                                      e.detail.value!
                                    )
                                  );
                                  dispatch(setWorkCentersActiveLoading(false));
                                }}
                                placeholder="Nombre"
                                className="search-bar-padding"
                              />
                              <IonButtons slot="end">
                                <IonButton
                                  fill="clear"
                                  shape="round"
                                  size="small"
                                  id="popover-options-active-codigos-qr"
                                >
                                  <IonIcon
                                    slot="icon-only"
                                    icon={ellipsisVertical}
                                  />
                                </IonButton>
                              </IonButtons>
                            </IonItem>
                            {activeRemoveStatus ? (
                              <IonItem color="tertiary">
                                <IonCheckbox
                                  slot="start"
                                  disabled={activeSearchResult.length === 0}
                                  indeterminate={activeIsInteterminate}
                                  checked={activeAllSelected}
                                  onIonChange={(e) => {
                                    if (activeAllSelected !== e.detail.checked)
                                      dispatch(
                                        e.detail.checked
                                          ? addAllWorkCentersActiveSelectedCentroDeTrabajo(
                                              {
                                                item: activeSearchResult.map(
                                                  (v) =>
                                                    setListItem({
                                                      id: v.id.toString(),
                                                      title: v.nombre_centro,
                                                    })
                                                ),
                                                activeSearchResult:
                                                  activeSearchResult,
                                                searchValue: activeSearchValue,
                                              }
                                            )
                                          : removeAllWorkCentersActiveSelectedCentroDeTrabajo()
                                      );
                                  }}
                                />

                                <IonLabel>{activeSelected.length}</IonLabel>
                                <IonButtons slot="end">
                                  <IonButton
                                    disabled={activeSelected.length < 1}
                                    onClick={async () => {
                                      dispatch(
                                        setWorkCentersActiveRemoveStatus(false)
                                      );
                                      await dispatch(
                                        deactivateCentroDeTrabajoAsyncThunk({
                                          serverDate: serverDate.data,
                                          idCliente: clienteQentryState.data.id,
                                          ids: activeSelected.map((e) =>
                                            Number(e.id)
                                          ),
                                        })
                                      );
                                    }}
                                  >
                                    <IonIcon
                                      color="primary"
                                      slot="icon-only"
                                      icon={checkmark}
                                    />
                                  </IonButton>
                                  <IonButton
                                    onClick={() =>
                                      dispatch(
                                        setWorkCentersActiveRemoveStatus(false)
                                      )
                                    }
                                  >
                                    <IonIcon
                                      color="danger"
                                      slot="icon-only"
                                      icon={close}
                                    />
                                  </IonButton>
                                </IonButtons>
                              </IonItem>
                            ) : (
                              <></>
                            )}
                            <IonItem color="secondary">
                              <IonGrid>
                                <IonRow>
                                  <IonCol
                                    size="12"
                                    sizeXs="12"
                                    sizeSm="12"
                                    sizeMd="6"
                                    sizeLg="6"
                                    sizeXl="6"
                                  >
                                    <IonLabel>Centro de trabajo</IonLabel>
                                  </IonCol>
                                </IonRow>
                              </IonGrid>
                            </IonItem>
                            {activeLoading ? (
                              <>
                                <IonProgressBar
                                  type="indeterminate"
                                  color="primary"
                                />
                              </>
                            ) : (
                              <></>
                            )}
                            {centroDeTrabajoState.estado ===
                            DEFAULT_STATE.loading ? (
                              <IonItem lines="none">
                                <IonLabel>
                                  Cargando lista de códigos qr activos.
                                </IonLabel>
                                <IonSpinner
                                  className="loading-center-horizontal"
                                  name="circles"
                                  color="medium"
                                  slot="start"
                                />
                              </IonItem>
                            ) : activeSearchResult.length > 0 ? (
                              activeSearchResult.map((e, id) => {
                                const qr = codigoQrState.data_array.find(
                                  (v) => v.centro_de_trabajo.id === e.id
                                )!;
                                const show =
                                  id <= activeLastItem && id >= activeFirstItem;
                                if (show) {
                                  const checked =
                                    activeSelected.findIndex(
                                      (v) => v.id === e.id.toString()
                                    ) !== -1;
                                  const item = setListItem({
                                    id: e.id.toString(),
                                    title: e.nombre_centro,
                                  });
                                  if (checked)
                                    dispatch(
                                      addWorkCentersActiveSelectedCentroDeTrabajo(
                                        {
                                          item: item,
                                          activeSearchResult:
                                            activeSearchResult,
                                        }
                                      )
                                    );
                                }
                                return show ? (
                                  <IonItem key={`active-${id}-${e.id}`}>
                                    {activeRemoveStatus ? (
                                      <IonCheckbox
                                        checked={
                                          activeSelected.findIndex(
                                            (v) => v.id === e.id.toString()
                                          ) !== -1
                                        }
                                        slot="start"
                                        onIonChange={(v) => {
                                          const item = setListItem({
                                            id: e.id.toString(),
                                            title: e.nombre_centro,
                                          });
                                          dispatch(
                                            v.detail.checked
                                              ? addWorkCentersActiveSelectedCentroDeTrabajo(
                                                  {
                                                    item: item,
                                                    activeSearchResult:
                                                      activeSearchResult,
                                                  }
                                                )
                                              : removeWorkCentersActiveSelectedCentroDeTrabajo(
                                                  item
                                                )
                                          );
                                        }}
                                      />
                                    ) : (
                                      <></>
                                    )}
                                    <IonGrid>
                                      <IonRow>
                                        <IonCol
                                          size="12"
                                          sizeXs="12"
                                          sizeSm="12"
                                          sizeMd="6"
                                          sizeLg="6"
                                          sizeXl="6"
                                        >
                                          <IonText>
                                            {`${e.nombre_centro}`}
                                          </IonText>
                                        </IonCol>
                                      </IonRow>
                                    </IonGrid>
                                    {!activeRemoveStatus && qr ? (
                                      <IonButtons slot="end">
                                        <IonButton
                                          fill="clear"
                                          title="Descargar código"
                                          download={
                                            qr.imagen_codigo
                                              ? qr.imagen_codigo
                                              : undefined
                                          }
                                          href={`${
                                            process.env.REACT_APP_STORAGE_URL
                                          }archivos/${qr!.clave_codigo}/${
                                            qr!.clave_codigo
                                          }.png`}
                                          target="_blank"
                                          onClick={() =>
                                            downloadImage(
                                              qr!.imagen_codigo,
                                              e.nombre_centro
                                            )
                                          }
                                        >
                                          <IonIcon
                                            slot="icon-only"
                                            icon={download}
                                            color="primary"
                                          />
                                        </IonButton>
                                        <IonButton
                                          fill="clear"
                                          title="Editar centro"
                                          onClick={() => {
                                            dispatch(
                                              setWorkCentersPlaceModal([
                                                {
                                                  lat: Number(
                                                    qr.geolocalizacion.latitud
                                                  ),
                                                  lng: Number(
                                                    qr.geolocalizacion.longitud
                                                  ),
                                                },
                                              ])
                                            );
                                            dispatch(
                                              setWorkCentersCentroDeTrabajoModal(
                                                qr
                                              )
                                            );
                                          }}
                                        >
                                          <IonIcon
                                            slot="icon-only"
                                            icon={pencil}
                                            color="primary"
                                          />
                                        </IonButton>
                                      </IonButtons>
                                    ) : (
                                      <></>
                                    )}
                                  </IonItem>
                                ) : (
                                  <IonItem
                                    key={`active-hidden-${id}-${e.id}`}
                                    class="item-hide"
                                  />
                                );
                              })
                            ) : (
                              <IonItem>
                                <IonLabel>
                                  No hay más códigos qr activos
                                </IonLabel>
                              </IonItem>
                            )}
                            <IonItem lines="full">
                              <IonLabel slot="end" className="ion-text-right">
                                {activePageMessage}
                              </IonLabel>
                              <IonButtons slot="end">
                                <IonButton
                                  disabled={activePage === 1}
                                  class="grouped-buttons"
                                  onClick={() =>
                                    dispatch(activeWorkCentersPreviousPage())
                                  }
                                >
                                  <IonIcon
                                    slot="icon-only"
                                    icon={chevronBackSharp}
                                  />
                                </IonButton>
                                <IonButton
                                  disabled={activePage >= activeTotalPages}
                                  class="grouped-buttons"
                                  onClick={() =>
                                    dispatch(
                                      activeWorkCentersNextPage(
                                        activeTotalPages
                                      )
                                    )
                                  }
                                >
                                  <IonIcon
                                    slot="icon-only"
                                    icon={chevronForwardSharp}
                                  />
                                </IonButton>
                              </IonButtons>
                            </IonItem>
                          </IonList>
                        </IonAccordion>
                        <IonAccordion value="inactive-codigos-qr">
                          <IonItem
                            className="accordion-header"
                            lines="full"
                            slot="header"
                          >
                            <IonLabel>Lista de códigos qr inactivos</IonLabel>
                          </IonItem>
                          <IonList lines="full" slot="content">
                            <IonItem
                              color="light"
                              className="table-helper"
                              disabled={
                                centroDeTrabajoState.estado ===
                                DEFAULT_STATE.loading
                              }
                            >
                              <IonSearchbar
                                color="light"
                                class="search-bar"
                                // value={inactiveSearchValue}
                                onIonInput={() => {
                                  if (!inactiveLoading)
                                    dispatch(
                                      setWorkCentersInactiveLoading(true)
                                    );
                                }}
                                debounce={400}
                                onIonChange={async (e) => {
                                  dispatch(resetWorkCentersInactivePage());
                                  dispatch(
                                    setWorkCentersInactiveSearchValue(
                                      e.detail.value!
                                    )
                                  );
                                  dispatch(
                                    setWorkCentersInactiveLoading(false)
                                  );
                                }}
                                placeholder="Nombre"
                                className="search-bar-padding"
                              />
                              <IonButtons slot="end">
                                <IonButton
                                  fill="clear"
                                  shape="round"
                                  size="small"
                                  id="popover-options-inactive-codigos-qr"
                                >
                                  <IonIcon
                                    slot="icon-only"
                                    icon={ellipsisVertical}
                                  />
                                </IonButton>
                              </IonButtons>
                            </IonItem>
                            {inactiveRemoveStatus || inactiveActivateStatus ? (
                              <IonItem color="tertiary">
                                <IonCheckbox
                                  slot="start"
                                  disabled={inactiveSearchResult.length === 0}
                                  indeterminate={inactiveIsInteterminate}
                                  checked={inactiveAllSelected}
                                  onIonChange={(e) => {
                                    if (
                                      inactiveAllSelected !== e.detail.checked
                                    )
                                      dispatch(
                                        e.detail.checked
                                          ? addAllWorkCentersInactiveSelectedCentroDeTrabajo(
                                              {
                                                item: inactiveSearchResult.map(
                                                  (v) =>
                                                    setListItem({
                                                      id: v.id.toString(),
                                                      title: v.nombre_centro,
                                                    })
                                                ),
                                                inactiveSearchResult:
                                                  inactiveSearchResult,
                                                searchValue:
                                                  inactiveSearchValue,
                                              }
                                            )
                                          : removeAllWorkCentersInactiveSelectedCentroDeTrabajo()
                                      );
                                  }}
                                />

                                <IonLabel>
                                  {(inactiveRemoveStatus
                                    ? "Eliminar: "
                                    : inactiveActivateStatus
                                    ? "Activar: "
                                    : "") + inactiveSelected.length}
                                </IonLabel>
                                <IonButtons slot="end">
                                  <IonButton
                                    disabled={inactiveSelected.length < 1}
                                    onClick={async () => {
                                      if (inactiveRemoveStatus) {
                                        dispatch(
                                          setWorkCentersInactiveRemoveStatus(
                                            false
                                          )
                                        );
                                        await dispatch(
                                          deleteMultipleCentroDeTrabajoAsyncThunk(
                                            {
                                              serverDate: serverDate.data,
                                              idCliente:
                                                clienteQentryState.data.id,
                                              ids: inactiveSelected.map((e) =>
                                                Number(e.id)
                                              ),
                                            }
                                          )
                                        );
                                        return;
                                      }
                                      if (inactiveActivateStatus) {
                                        dispatch(
                                          setWorkCentersInactiveActivateStatus(
                                            false
                                          )
                                        );
                                        await dispatch(
                                          activateCentroDeTrabajoAsyncThunk({
                                            serverDate: serverDate.data,
                                            idCliente:
                                              clienteQentryState.data.id,
                                            ids: inactiveSelected.map((e) =>
                                              Number(e.id)
                                            ),
                                          })
                                        );
                                        return;
                                      }
                                    }}
                                  >
                                    <IonIcon
                                      color="primary"
                                      slot="icon-only"
                                      icon={checkmark}
                                    />
                                  </IonButton>
                                  <IonButton
                                    onClick={() =>
                                      dispatch(
                                        setWorkCentersInactiveRemoveStatus(
                                          false
                                        )
                                      )
                                    }
                                  >
                                    <IonIcon
                                      color="danger"
                                      slot="icon-only"
                                      icon={close}
                                    />
                                  </IonButton>
                                </IonButtons>
                              </IonItem>
                            ) : (
                              <></>
                            )}
                            <IonItem color="secondary">
                              <IonGrid>
                                <IonRow>
                                  <IonCol
                                    size="12"
                                    sizeXs="12"
                                    sizeSm="12"
                                    sizeMd="6"
                                    sizeLg="6"
                                    sizeXl="6"
                                  >
                                    <IonLabel>Centro de trabajo</IonLabel>
                                  </IonCol>
                                </IonRow>
                              </IonGrid>
                            </IonItem>
                            {inactiveLoading ? (
                              <>
                                <IonProgressBar
                                  type="indeterminate"
                                  color="primary"
                                />
                              </>
                            ) : (
                              <></>
                            )}
                            {centroDeTrabajoState.estado ===
                            DEFAULT_STATE.loading ? (
                              <IonItem lines="none">
                                <IonLabel>
                                  Cargando lista de códigos qr activos.
                                </IonLabel>
                                <IonSpinner
                                  className="loading-center-horizontal"
                                  name="circles"
                                  color="medium"
                                  slot="start"
                                />
                              </IonItem>
                            ) : inactiveSearchResult.length > 0 ? (
                              inactiveSearchResult.map((e, id) => {
                                const show =
                                  id <= inactiveLastItem &&
                                  id >= inactiveFirstItem;
                                if (show) {
                                  const checked =
                                    inactiveSelected.findIndex(
                                      (v) => v.id === e.id.toString()
                                    ) !== -1;
                                  const item = setListItem({
                                    id: e.id.toString(),
                                    title: e.nombre_centro,
                                  });
                                  if (checked)
                                    dispatch(
                                      addWorkCentersInactiveSelectedCentroDeTrabajo(
                                        {
                                          item: item,
                                          inactiveSearchResult:
                                            inactiveSearchResult,
                                        }
                                      )
                                    );
                                }
                                return show ? (
                                  <IonItem key={`inactive-${id}-${e.id}`}>
                                    {inactiveRemoveStatus ||
                                    inactiveActivateStatus ? (
                                      <IonCheckbox
                                        checked={
                                          inactiveSelected.findIndex(
                                            (v) => v.id === e.id.toString()
                                          ) !== -1
                                        }
                                        slot="start"
                                        onIonChange={(v) => {
                                          const item = setListItem({
                                            id: e.id.toString(),
                                            title: e.nombre_centro,
                                          });
                                          dispatch(
                                            v.detail.checked
                                              ? addWorkCentersInactiveSelectedCentroDeTrabajo(
                                                  {
                                                    item: item,
                                                    inactiveSearchResult:
                                                      inactiveSearchResult,
                                                  }
                                                )
                                              : removeWorkCentersInactiveSelectedCentroDeTrabajo(
                                                  item
                                                )
                                          );
                                        }}
                                      />
                                    ) : (
                                      <></>
                                    )}
                                    <IonGrid>
                                      <IonRow>
                                        <IonCol
                                          size="12"
                                          sizeXs="12"
                                          sizeSm="12"
                                          sizeMd="6"
                                          sizeLg="6"
                                          sizeXl="6"
                                        >
                                          <IonText>
                                            {`${e.nombre_centro}`}
                                          </IonText>
                                        </IonCol>
                                      </IonRow>
                                    </IonGrid>
                                  </IonItem>
                                ) : (
                                  <IonItem
                                    key={`inactive-hidden-${id}-${e.id}`}
                                    class="item-hide"
                                  />
                                );
                              })
                            ) : (
                              <IonItem>
                                <IonLabel>
                                  No hay más códigos qr activos
                                </IonLabel>
                              </IonItem>
                            )}
                            <IonItem lines="full">
                              <IonLabel slot="end" className="ion-text-right">
                                {inactivePageMessage}
                              </IonLabel>
                              <IonButtons slot="end">
                                <IonButton
                                  disabled={inactivePage === 1}
                                  class="grouped-buttons"
                                  onClick={() =>
                                    dispatch(inactiveWorkCentersPreviousPage())
                                  }
                                >
                                  <IonIcon
                                    slot="icon-only"
                                    icon={chevronBackSharp}
                                  />
                                </IonButton>
                                <IonButton
                                  disabled={inactivePage >= inactiveTotalPages}
                                  class="grouped-buttons"
                                  onClick={() =>
                                    dispatch(
                                      inactiveWorkCentersNextPage(
                                        inactiveTotalPages
                                      )
                                    )
                                  }
                                >
                                  <IonIcon
                                    slot="icon-only"
                                    icon={chevronForwardSharp}
                                  />
                                </IonButton>
                              </IonButtons>
                            </IonItem>
                          </IonList>
                        </IonAccordion>
                      </IonAccordionGroup>
                    </IonCol>
                  </IonRow>
                  <IonRow className="no-padding">
                    <IonCol size="12" id="workCenter-map">
                      <GoogleMapsV3
                        id="workCenter-map"
                        content={content.current}
                        markers={makerList}
                        listData={selectList}
                        hasSearchBox={true}
                        defaultWorkCenterSelector={false}
                        handlePlace={handlePlace}
                      />
                    </IonCol>
                  </IonRow>
                </IonGrid>
              }
            />
          </IonCol>
        </IonRow>
      </IonGrid>
    </IonContent>
  );
};

export default WorkCenter;
