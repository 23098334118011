
import { auth as firebaseAuth, sendPasswordResetEmail } from "../firebase/config";


import API from './Api';
import axios from 'axios';
import { ICreateEmpleadoData } from "../redux/slices/pagesSlice/teamMembersSlice";

export async function qentry3ValidateUsers(data: ICreateEmpleadoData[]) {
  return await API.post(`/qentry3ValidateUsers`, { data: data });//ready
}

export async function qentry3CreateUsers(data: ICreateEmpleadoData[]) {
  return await API.post(`/qentry3CreateUsers`, { data: data });//ready
}

export async function qentry3ValidatePhoneNumber(data: string) {
  return await API.post(`/qentry3ValidatePhoneNumber`, { data: data });//ready
}
export async function verifyPhone(phone: string) {
  return await API.post(`/verifyPhone`, { phone: phone });
}

export async function getPass(email: string, phone: string) {
  return await API.post('/getPass',
    {
      email: email,
      phone: phone,
    });
}
 
export async function sendRecoveryEmail(email: string) {
  return await sendPasswordResetEmail(firebaseAuth, email)
    .then((res) => {
      console.log('res_', res);
    })
    .catch((err) => {
      console.log('err_', err);
    });

}