/**
 * Métodos AsyncThunk para ser usados en los reducers y
 * los resultados a las llamadas http puedan ser usados para
 * actualizar el state en redux
 */

import { createAsyncThunk } from "@reduxjs/toolkit";
import { DEFAULT_STATE } from "../../enums/default_state";
import { getInformacionSuscripcion, getAllInformacionSuscripcion, updateInformacionSuscripcion, createInformacionSuscripcion, deleteInformacionSuscripcion } from "../../http/personas";
import { getEmptyStatusInformacionSuscripcion, IInformacionSuscripcion } from "../../interfaces/personas";
import { IServerDate } from "../../interfaces/time";

export const getInformacionSuscripcionAsyncThunk = createAsyncThunk(
  'personas/getInformacionSuscripcionAsyncThunk',
  async (query : {serverDate : IServerDate, id: number, idCliente : number}) => await getInformacionSuscripcion(query.serverDate, query.id, query.idCliente)
    .then(async (result) => result)
    .catch(async (error) => getEmptyStatusInformacionSuscripcion(DEFAULT_STATE.error))  
);

export const getAllInformacionSuscripcionAsyncThunk = createAsyncThunk(
  'personas/getAllInformacionSuscripcionAsyncThunk',
  async (queryAll : {serverDate : IServerDate, idCliente : number}) => await getAllInformacionSuscripcion(queryAll.serverDate, queryAll.idCliente)
    .then(async (result) => result)
    .catch(async (error) => getEmptyStatusInformacionSuscripcion(DEFAULT_STATE.error))  
);

export const updateInformacionSuscripcionAsyncThunk = createAsyncThunk(
  'personas/updateInformacionSuscripcionAsyncThunk',
  async (update: {serverDate : IServerDate, id: number, idCliente : number, object: IInformacionSuscripcion}) => await updateInformacionSuscripcion(update.serverDate, update.id, update.idCliente, update.object)
    .then(async (result) => result)
    .catch(async (error) => getEmptyStatusInformacionSuscripcion(DEFAULT_STATE.error))  
);

export const createInformacionSuscripcionAsyncThunk = createAsyncThunk(
  'personas/createInformacionSuscripcionAsyncThunk',
  async (create : {serverDate : IServerDate, idCliente : number, object: IInformacionSuscripcion}) => await createInformacionSuscripcion(create.serverDate, create.idCliente, create.object)
    .then(async (result) => result)
    .catch(async (error) => getEmptyStatusInformacionSuscripcion(DEFAULT_STATE.error))  
);

export const deleteInformacionSuscripcionAsyncThunk = createAsyncThunk(
  'personas/deleteInformacionSuscripcionAsyncThunk',
  async (del : {id : number, idCliente : number}) => await deleteInformacionSuscripcion(del.id, del.idCliente)
    .then(async (result) => result)
    .catch(async (error) => getEmptyStatusInformacionSuscripcion())  
);
