/**
 * Métodos del, get, post, put para llamadas a la API
 * @get     en este método primero consulta con @getFromLocalStorage si es necesario hacer la petición http sino se obtiene del LocalStorage;
 *          si hay un error en la petición http se busca el objeto en el LocalStorage sino lo encuentra devuelve un objeto vacío; si la
 *          petición se realiza correctamente actualiza el valor individual y en el array del objeto en el LocalStorage y devuelve el objeto
 *          obtenido del servidor.
 * @getAll  en este método primero consulta con @getFromLocalStorage si es necesario hacer la petición http sino se obtiene del LocalStorage;
 *          si hay un error en la petición http se busca el objeto array en el LocalStorage sino lo encuentra devuelve un array vacío; si la
 *          petición se realiza correctamente actualiza array en el LocalStorage y devuelve el array obtenido del servidor
 * @update  en este método después de realizar la petición http si falla devuelve el objeto que se iba a actualizar; si la petición se
 *          realiza correctamente actualiza el valor individual y en el array del objeto en el LocalStorage y devuelve el objeto obtenido del
 *          servidor.
 * @create  en este método después de realizar la petición http si falla devuelve el objeto que se iba a crear; si la petición se realiza 
 *          correctamente actualiza el valor individual y en el array del objeto en el LocalStorage y devuelve el objeto obtenido del
 *          servidor.
 * @delete  en este método después de realizar la petición http si falla devuelve el objeto que se iba a eliminar; si la petición se realiza 
 *          correctamente elimina el valor individual y en el objeto en el arrya del LocalStorage y devuelve un objeto vacio (Default)
 */

import { del, get, post, put } from "../../api/api";
import { getFromLocalStorage, updateLocalStorageExpiry } from "../../api/helpers";
import { removeArrayItemStorageData } from "../../api/storage";
import { DEFAULT_STATE } from "../../enums/default_state";
import { getEmptyStatusInformacionContrato, setInformacionContrato, IInformacionContrato, getSerializableInformacionContrato } from "../../interfaces/personas";
import { IServerDate } from "../../interfaces/time";
import { getStorageInformacionContratoArrayItem, setStorageInformacionContrato, setStorageInformacionContratoArrayItem, getStorageInformacionContratoArray, setStorageInformacionContratoArray, removeStorageInformacionContrato } from "../../localStorage/personas";

export const getInformacionContrato = async (serverDate: IServerDate, pk : number, idCliente : number) => {
  const temp = getEmptyStatusInformacionContrato();
  const doHttp = await getFromLocalStorage(serverDate, temp.expiry);
  temp.expiry = doHttp.storage;
  if (!doHttp.result) {
    console.log(`(localStorage) InformacionContrato con pk: ${pk}, obtenido con éxito`);
    temp.estado = DEFAULT_STATE.success;
    temp.mensaje = `InformacionContrato obtenida con éxito`;
    temp.data = await getStorageInformacionContratoArrayItem(pk);
    return temp;
  }
  const result = await get(`personas/informacion-contrato/${pk}/${idCliente}`);
  const data = result.data;
  console.log(`InformacionContrato data: `, data);
  if (!data.id || data.detail) {
    console.log(`Error al obtener InformacionContrato con pk: ${pk}, ${data.detail}`);
    temp.estado = DEFAULT_STATE.error;
    temp.mensaje =  `Error al obtener la informacion de contrato: ${data.detail ?? "Hubo un problema al conectarse con el servidor"}`;
    temp.data = await getStorageInformacionContratoArrayItem(pk);
    return temp;
  }
  console.log(`InformacionContrato con pk: ${pk}, obtenido con éxito`);
  temp.estado = DEFAULT_STATE.success;
  temp.mensaje = `InformacionContrato obtenida con éxito`;
  temp.data = await setInformacionContrato(data, serverDate);
  await setStorageInformacionContrato(temp.data);
  temp.data_array = await setStorageInformacionContratoArrayItem(pk, temp.data);
  temp.expiry = await updateLocalStorageExpiry(serverDate, temp.expiry);
  return temp;
}

export const getAllInformacionContrato = async (serverDate: IServerDate, idCliente : number) => {
  const temp = getEmptyStatusInformacionContrato();
  const doHttp = await getFromLocalStorage(serverDate, temp.expiry_array);
  temp.expiry_array = doHttp.storage;
  if (!doHttp.result) {
    console.log(`(localStorage) Todos las InformacionContratos obtenidas con éxito`);
    temp.estado = DEFAULT_STATE.success;
    temp.mensaje = `Todos las informacion de contratos obtenidas con éxito`;
    temp.data_array = await getStorageInformacionContratoArray();
  }
  const result = await get(`personas/informacion-contrato/${idCliente}`);
  const data = result.data;
  if (data.detail) {
    console.log(`Error al obtener la lista de InformacionContrato`, data);
    temp.estado = DEFAULT_STATE.error;
    temp.mensaje = `Error al obtener la lista de informacion de contratos: ${data.detail ?? "Hubo un problema al conectarse con el servidor"}`;
    temp.data_array = await getStorageInformacionContratoArray();
    return temp;
  }
  console.log(`Todos las InformacionContratos obtenidos con éxito`);
  temp.estado = DEFAULT_STATE.success;
  temp.mensaje = `Todos las informacion de contratos obtenidas con éxito`;
  temp.data_array = await Promise.all(data.map(async (e : any) => await setInformacionContrato(e, serverDate)));
  await setStorageInformacionContratoArray(temp.data_array);
  temp.expiry_array = await updateLocalStorageExpiry(serverDate, temp.expiry_array);
  return temp;
}

export const updateInformacionContrato = async (serverDate: IServerDate, pk : number, idCliente : number, object : IInformacionContrato) => {
  const temp = getEmptyStatusInformacionContrato();
  const result = await put(`personas/informacion-contrato/${pk}/${idCliente}`, getSerializableInformacionContrato(object));
  const data = result.data;
  if (!data.id || data.detail) {
    console.log(`Error al actualizar InformacionContrato con pk: ${pk}, ${data.detail}`);
    temp.estado = DEFAULT_STATE.error;
    temp.mensaje = `Error al actualizar la informacion de contrato: ${data.detail ?? "Hubo un problema al conectarse con el servidor"}`;
    temp.data = await setInformacionContrato(object, serverDate);
    return temp;
  }
  console.log(`InformacionContrato con pk: ${pk}, actualizado con éxito`);
  temp.estado = DEFAULT_STATE.success;
  temp.mensaje = `InformacionContrato actualizada con éxito`;
  temp.data = await setInformacionContrato(data, serverDate);
  await setStorageInformacionContrato(temp.data);
  temp.data_array = await setStorageInformacionContratoArrayItem(temp.data.id, temp.data);
  return temp;
}

export const createInformacionContrato = async (serverDate: IServerDate, idCliente : number, object : IInformacionContrato) => {
  const temp = getEmptyStatusInformacionContrato();
  const result = await post(`personas/informacion-contrato/${idCliente}`, getSerializableInformacionContrato(object));
  const data = result.data;
  if (!data.id || data.detail) {
    console.log(`Error al crear InformacionContrato con los datos ${object}, ${data.detail}`);
    temp.estado = DEFAULT_STATE.error;
    temp.mensaje = `Error al crear la informacion de contrato: ${data.detail ?? "Hubo un problema al conectarse con el servidor"}`;
    temp.data = await setInformacionContrato(object, serverDate);
    return temp;
  }
  console.log(`InformacionContrato creada con éxito`);
  temp.estado = DEFAULT_STATE.success;
  temp.mensaje = `InformacionContrato creada con éxito`;
  temp.data = await setInformacionContrato(data, serverDate);
  await setStorageInformacionContrato(temp.data);
  temp.data_array = await setStorageInformacionContratoArrayItem(temp.data.id, temp.data);
  return temp;
}

export const deleteInformacionContrato = async (pk : number, idCliente : number) => {
  const temp = getEmptyStatusInformacionContrato();
  const result = await del(`personas/informacion-contrato/${pk}/${idCliente}`);
  const data = result.data;
  if (data.detail) {
    console.log(`Error al eliminar InformacionContrato con pk: ${pk}, ${data.detail}`);
    temp.estado = DEFAULT_STATE.error;
    temp.mensaje = `Error al eliminar la informacion de contrato: ${data.detail}`;
    temp.data = await getStorageInformacionContratoArrayItem(pk);
    temp.data_array = await getStorageInformacionContratoArray();
    return temp;
  }
  console.log(`InformacionContrato eliminada con éxito`);
  temp.estado = DEFAULT_STATE.success;
  temp.mensaje = `InformacionContrato eliminada con éxito`;
  await removeStorageInformacionContrato();
  temp.data_array = await removeArrayItemStorageData("IInformacionContrato", pk, []);
  return temp;
}
