/**
 * Métodos para el manejo de IonicStorage 
 * @setStorage              guarda un unico objeto al storage
 * @getStorage              obtiene el objeto guardado con @setStorage
 * @setStorageArray         guarda el array de objetos en el storage
 * @getStorageArray         obtiene el array de objetos guardado con @setStorageArray
 * @removeStorageArray      elimina el array de objetos guardado con @setStorageArray
 * @setStorageItemArray     agrega un elemento al array de objeto guardado con @setStorageArray
 * @getStorageItemArray     obtiene un elemento del array guardado con @setStorageArray buscandolo por su id 
 * @removeStorageItemArray  elimina un elemento del array guardado con @setStorageArray buscandolo por su id 
 */

import { setStorageData, getStorageData, removeStorageData, setArrayItemStorageData, getArrayItemStorageData, removeArrayItemStorageData } from "../../api/storage";
import { IMvMotivoIncapacidad, getEmptyMvMotivoIncapacidad } from "../../interfaces/nomina";

export const setStorageMvMotivoIncapacidad = async (IMvMotivoIncapacidad : IMvMotivoIncapacidad) => await setStorageData('IMvMotivoIncapacidad', IMvMotivoIncapacidad);
export const getStorageMvMotivoIncapacidad = async () : Promise<IMvMotivoIncapacidad> => await getStorageData('IMvMotivoIncapacidad', getEmptyMvMotivoIncapacidad());
export const removeStorageMvMotivoIncapacidad = async () => await removeStorageData('IMvMotivoIncapacidad');
export const setStorageMvMotivoIncapacidadArray = async (IMvMotivoIncapacidad : IMvMotivoIncapacidad[]) => await setStorageData('IMvMotivoIncapacidadArray', IMvMotivoIncapacidad);
export const getStorageMvMotivoIncapacidadArray = async () : Promise<IMvMotivoIncapacidad[]> => await getStorageData('IMvMotivoIncapacidadArray', [] as IMvMotivoIncapacidad[]);
export const removeStorageMvMotivoIncapacidadArray = async () => await removeStorageData('IMvMotivoIncapacidadArray');
export const setStorageMvMotivoIncapacidadArrayItem = async (id: number, IMvMotivoIncapacidad : IMvMotivoIncapacidad) : Promise<IMvMotivoIncapacidad[]> => await setArrayItemStorageData('IMvMotivoIncapacidadArray', id, IMvMotivoIncapacidad, [] as IMvMotivoIncapacidad[]);
export const getStorageMvMotivoIncapacidadArrayItem = async (id: number) : Promise<IMvMotivoIncapacidad> => await getArrayItemStorageData('IMvMotivoIncapacidadArray', id, getEmptyMvMotivoIncapacidad);
export const removeStorageMvMotivoIncapacidadArrayItem = async (id: number) : Promise<IMvMotivoIncapacidad[]> => await removeArrayItemStorageData('IMvMotivoIncapacidadArray', id, [] as IMvMotivoIncapacidad[]);
