/**
 * Métodos AsyncThunk para ser usados en los reducers y
 * los resultados a las llamadas http puedan ser usados para
 * actualizar el state en redux
 */

import { createAsyncThunk } from "@reduxjs/toolkit";
import { DEFAULT_STATE } from "../../enums/default_state";
import { getAsistencia, getAllAsistencia, updateAsistencia, createAsistencia, deleteAsistencia } from "../../http/nomina";
import { getEmptyStatusAsistencia, IAsistencia } from "../../interfaces/nomina";
import { IServerDate } from "../../interfaces/time";

export const getAsistenciaAsyncThunk = createAsyncThunk(
  'nomina/getAsistenciaAsyncThunk',
  async (query : {serverDate : IServerDate, id: number, idCliente : number}) => await getAsistencia(query.serverDate, query.id, query.idCliente)
    .then(async (result) => result)
    .catch(async (error) => getEmptyStatusAsistencia(DEFAULT_STATE.error))  
);

export const getAllAsistenciaAsyncThunk = createAsyncThunk(
  'nomina/getAllAsistenciaAsyncThunk',
  async (queryAll : {serverDate : IServerDate, idCliente : number}) => await getAllAsistencia(queryAll.serverDate, queryAll.idCliente)
    .then(async (result) => result)
    .catch(async (error) => getEmptyStatusAsistencia(DEFAULT_STATE.error))  
);

export const updateAsistenciaAsyncThunk = createAsyncThunk(
  'nomina/updateAsistenciaAsyncThunk',
  async (update: {serverDate : IServerDate, id: number, idCliente : number, object: IAsistencia}) => await updateAsistencia(update.serverDate, update.id, update.idCliente, update.object)
    .then(async (result) => result)
    .catch(async (error) => getEmptyStatusAsistencia(DEFAULT_STATE.error))  
);

export const createAsistenciaAsyncThunk = createAsyncThunk(
  'nomina/createAsistenciaAsyncThunk',
  async (create : {serverDate : IServerDate, idCliente : number, object: IAsistencia}) => await createAsistencia(create.serverDate, create.idCliente, create.object)
    .then(async (result) => result)
    .catch(async (error) => getEmptyStatusAsistencia(DEFAULT_STATE.error))  
);

export const deleteAsistenciaAsyncThunk = createAsyncThunk(
  'nomina/deleteAsistenciaAsyncThunk',
  async (del : {id : number, idCliente : number}) => await deleteAsistencia(del.id, del.idCliente)
    .then(async (result) => result)
    .catch(async (error) => getEmptyStatusAsistencia())  
);
