/**
 * Métodos AsyncThunk para ser usados en los reducers y
 * los resultados a las llamadas http puedan ser usados para
 * actualizar el state en redux
 */

import { createAsyncThunk } from "@reduxjs/toolkit";
import { DEFAULT_STATE } from "../../enums/default_state";
import { getMvTipoAsistencia, getAllMvTipoAsistencia, updateMvTipoAsistencia, createMvTipoAsistencia, deleteMvTipoAsistencia } from "../../http/nomina";
import { getEmptyStatusMvTipoAsistencia, IMvTipoAsistencia } from "../../interfaces/nomina";
import { IServerDate } from "../../interfaces/time";

export const getMvTipoAsistenciaAsyncThunk = createAsyncThunk(
  'nomina/getMvTipoAsistenciaAsyncThunk',
  async (query : {serverDate : IServerDate, id: number}) => await getMvTipoAsistencia(query.serverDate, query.id)
    .then(async (result) => result)
    .catch(async (error) => getEmptyStatusMvTipoAsistencia(DEFAULT_STATE.error))  
);

export const getAllMvTipoAsistenciaAsyncThunk = createAsyncThunk(
  'nomina/getAllMvTipoAsistenciaAsyncThunk',
  async (queryAll : {serverDate : IServerDate}) => await getAllMvTipoAsistencia(queryAll.serverDate)
    .then(async (result) => result)
    .catch(async (error) => getEmptyStatusMvTipoAsistencia(DEFAULT_STATE.error))  
);

export const updateMvTipoAsistenciaAsyncThunk = createAsyncThunk(
  'nomina/updateMvTipoAsistenciaAsyncThunk',
  async (update: {serverDate : IServerDate, id: number, MvTipoAsistencia: IMvTipoAsistencia}) => await updateMvTipoAsistencia(update.serverDate, update.id, update.MvTipoAsistencia)
    .then(async (result) => result)
    .catch(async (error) => getEmptyStatusMvTipoAsistencia(DEFAULT_STATE.error))  
);

export const createMvTipoAsistenciaAsyncThunk = createAsyncThunk(
  'nomina/createMvTipoAsistenciaAsyncThunk',
  async (create : {serverDate : IServerDate, MvTipoAsistencia: IMvTipoAsistencia}) => await createMvTipoAsistencia(create.serverDate, create.MvTipoAsistencia)
    .then(async (result) => result)
    .catch(async (error) => getEmptyStatusMvTipoAsistencia(DEFAULT_STATE.error))  
);

export const deleteMvTipoAsistenciaAsyncThunk = createAsyncThunk(
  'nomina/deleteMvTipoAsistenciaAsyncThunk',
  async (del : {id : number}) => await deleteMvTipoAsistencia(del.id)
    .then(async (result) => result)
    .catch(async (error) => getEmptyStatusMvTipoAsistencia())  
);
