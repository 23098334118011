/**
 * Métodos para el manejo de IonicStorage 
 * @setStorage              guarda un unico objeto al storage
 * @getStorage              obtiene el objeto guardado con @setStorage
 * @setStorageArray         guarda el array de objetos en el storage
 * @getStorageArray         obtiene el array de objetos guardado con @setStorageArray
 * @removeStorageArray      elimina el array de objetos guardado con @setStorageArray
 * @setStorageItemArray     agrega un elemento al array de objeto guardado con @setStorageArray
 * @getStorageItemArray     obtiene un elemento del array guardado con @setStorageArray buscandolo por su id 
 * @removeStorageItemArray  elimina un elemento del array guardado con @setStorageArray buscandolo por su id 
 */

import { setStorageData, getStorageData, removeStorageData, setArrayItemStorageData, getArrayItemStorageData, removeArrayItemStorageData } from "../../api/storage";
import { ITabuladorHorasExtra, getEmptyTabuladorHorasExtra } from "../../interfaces/nomina";

export const setStorageTabuladorHorasExtra = async (ITabuladorHorasExtra : ITabuladorHorasExtra) => await setStorageData('ITabuladorHorasExtra', ITabuladorHorasExtra);
export const getStorageTabuladorHorasExtra = async () : Promise<ITabuladorHorasExtra> => await getStorageData('ITabuladorHorasExtra', getEmptyTabuladorHorasExtra());
export const removeStorageTabuladorHorasExtra = async () => await removeStorageData('ITabuladorHorasExtra');
export const setStorageTabuladorHorasExtraArray = async (ITabuladorHorasExtra : ITabuladorHorasExtra[]) => await setStorageData('ITabuladorHorasExtraArray', ITabuladorHorasExtra);
export const getStorageTabuladorHorasExtraArray = async () : Promise<ITabuladorHorasExtra[]> => await getStorageData('ITabuladorHorasExtraArray', [] as ITabuladorHorasExtra[]);
export const removeStorageTabuladorHorasExtraArray = async () => await removeStorageData('ITabuladorHorasExtraArray');
export const setStorageTabuladorHorasExtraArrayItem = async (id: number, ITabuladorHorasExtra : ITabuladorHorasExtra) : Promise<ITabuladorHorasExtra[]> => await setArrayItemStorageData('ITabuladorHorasExtraArray', id, ITabuladorHorasExtra, [] as ITabuladorHorasExtra[]);
export const getStorageTabuladorHorasExtraArrayItem = async (id: number) : Promise<ITabuladorHorasExtra> => await getArrayItemStorageData('ITabuladorHorasExtraArray', id, getEmptyTabuladorHorasExtra);
export const removeStorageTabuladorHorasExtraArrayItem = async (id: number) : Promise<ITabuladorHorasExtra[]> => await removeArrayItemStorageData('ITabuladorHorasExtraArray', id, [] as ITabuladorHorasExtra[]);
