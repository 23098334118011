/**
 * Es el componente utilizado en la página Usuarios colaboradores para agregar, editar, desactivar y eliminar empleados y el estado actual de actividad
 * @function getGraphData obtiene la actividad de los empleados para la gráfica
 */

import React, { useRef } from "react";
import {
  IonContent,
  IonGrid,
  IonRow,
  IonCol,
  IonLabel,
  IonPopover,
  IonList,
  IonItem,
  IonIcon,
  IonAccordionGroup,
  IonAccordion,
  IonButton,
  IonSearchbar,
  IonCheckbox,
  IonButtons,
  IonSpinner,
  IonText,
  IonProgressBar,
  useIonModal,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
} from "@ionic/react";
import {
  personRemove,
  download,
  filterOutline,
  ellipsisVertical,
  checkmark,
  chevronBackSharp,
  chevronForwardSharp,
  close,
  pencil,
  personAdd,
  people,
} from "ionicons/icons";
import { useEffect, useState } from "react";
import GoogleMapsV3 from "../components/GoogleMapsV3";

import Card from "../components/Card";
import Selector from "../components/Selector";
import useRedux, { useAppDispatch, useAppSelector } from "../hooks/useRedux";
import { DEFAULT_STATE } from "../redux/enums/default_state";
import {
  getGlobalMemoizedEmpresaAsSelectList,
  getMemoizedCentrosDeTrabajoAsSelectList,
  getNombreEmpleado,
} from "../redux/slices/personasSlice";

import { Doughnut } from "react-chartjs-2";
import { Chart as ChartJS } from "chart.js";
import {
  activateEmpleadoAsyncThunk,
  createMultipleEmpleadoAsyncThunk,
  deactivateEmpleadoAsyncThunk,
  deleteMultipleEmpleadoAsyncThunk,
} from "../redux/asyncThunk/personas";
import { getEmptyEmpleado } from "../redux/interfaces/personas";
import {
  getMemoizedTeamMembersActiveSearch,
  getMemoizedTeamMembersActiveTotalPages,
  getMemoizedTeamMembersActivePageMessage,
  getMemoizedTeamMembersInactiveSearch,
  getMemoizedTeamMembersInactiveTotalPages,
  getMemoizedTeamMembersSelectedGenero,
  getMemoizedTeamMembersSelectedEstadoCivil,
  IEmpleadoData,
  ICreateEmpleadoData,
  IDefaultTable,
  teamMembersInitialState,
  ICreateEmpleado,
  setTeamMembersEmpleadoModal,
  setTeamMembersActiveRemoveStatus,
  setTeamMembersInactiveCentroDeTrabajo,
  setTeamMembersInactiveActivateStatus,
  setTeamMembersInactiveRemoveStatus,
  setTeamMembersActiveLoading,
  resetTeamMembersActivePage,
  setTeamMembersActiveSearchValue,
  addAllTeamMembersActiveSelectedEmpleado,
  removeAllTeamMembersActiveSelectedEmpleado,
  addTeamMembersActiveSelectedEmpleado,
  removeTeamMembersActiveSelectedEmpleado,
  activeTeamMembersPreviousPage,
  activeTeamMembersNextPage,
  setTeamMembersInactiveLoading,
  resetTeamMembersInactivePage,
  setTeamMembersInactiveSearchValue,
  addAllTeamMembersInactiveSelectedEmpleado,
  removeAllTeamMembersInactiveSelectedEmpleado,
  addTeamMembersInactiveSelectedEmpleado,
  removeTeamMembersInactiveSelectedEmpleado,
  inactiveTeamMembersPreviousPage,
  inactiveTeamMembersNextPage,
  getMemoizedTeamMembersInactivePageMessage,
  setTeamMembersSelectedEmpresa,
  setTeamMembersActiveCentroDeTrabajo,
  getMemoizedTeamMembersActiveEmpleado,
  setTeamMembersActiveEmpleadoStatus,
} from "../redux/slices/pagesSlice/teamMembersSlice";
import {
  GraphData,
  searchEmpleadoEnCentro,
} from "../redux/http/personas/helpers";

import "./TeamMembers.css";
import {
  EditEmpleadoModal,
  BulkLoadEmpleadoModal,
  CreateEmpleadoModal,
} from "../components/EmpleadoModal";
import {
  getFormattedDateString,
  getFullName,
  SERVER_TIME_ZONE,
} from "../redux/api/helpers";
import { List, setListItem } from "../redux/interfaces/helpers";
import xlsx from "json-as-xlsx";

const TeamMembers: React.FC = () => {
  const [{ clienteQentry, empleado, serverDate, fetchEmpleado }] = useRedux();

  const dispatch = useAppDispatch();
  const centroDeTrabajoList = useAppSelector(
    getMemoizedCentrosDeTrabajoAsSelectList
  );
  const empresaList = useAppSelector(getGlobalMemoizedEmpresaAsSelectList);
  const selectedEmpresa = useAppSelector(
    (state) => state.teamMembers.selectedEmpresa
  );
  const selectedStatus = useAppSelector(
    (state) => state.teamMembers.activeTable.selectedEmpleadoStatus
  );
  const empleadoStatus = useAppSelector(
    (state) => state.teamMembers.empleadoStatus
  );
  const fullActiveEmpleadoList = useAppSelector(
    getMemoizedTeamMembersActiveEmpleado
  );

  // Active table
  const activeSelectedCentroDeTrabajo = useAppSelector(
    (state) => state.teamMembers.activeTable.selectedCentroDeTrabajo
  );
  const activeRemoveStatus = useAppSelector(
    (state) => state.teamMembers.activeTable.remove
  );
  const activeSearchResult = useAppSelector(getMemoizedTeamMembersActiveSearch);
  const activeLoading = useAppSelector(
    (state) => state.teamMembers.activeTable.loading
  );
  const activeSelected = useAppSelector(
    (state) => state.teamMembers.activeTable.selectedEmpleados
  );
  const activeIsInteterminate = useAppSelector(
    (state) => state.teamMembers.activeTable.isIndeterminate
  );
  const activeAllSelected = useAppSelector(
    (state) => state.teamMembers.activeTable.selectAll
  );
  const activeTotalPages = useAppSelector(
    getMemoizedTeamMembersActiveTotalPages
  );
  const activeSearchValue = useAppSelector(
    (state) => state.teamMembers.activeTable.searchText
  );
  const activePageMessage = useAppSelector(
    getMemoizedTeamMembersActivePageMessage
  );
  const activePage = useAppSelector(
    (state) => state.teamMembers.activeTable.page
  );
  const activeMaxPerPage = useAppSelector(
    (state) => state.teamMembers.activeTable.maxPerPage
  );
  const activeFirstItem = (activePage - 1) * activeMaxPerPage;
  const activeLastItem = activeFirstItem + activeMaxPerPage - 1;

  // Inactive table
  const inactiveSelectedCentroDeTrabajo = useAppSelector(
    (state) => state.teamMembers.inactiveTable.selectedCentroDeTrabajo
  );
  const inactiveRemoveStatus = useAppSelector(
    (state) => state.teamMembers.inactiveTable.remove
  );
  const inactiveActivateStatus = useAppSelector(
    (state) => state.teamMembers.inactiveTable.activate
  );
  const inactiveSearchResult = useAppSelector(
    getMemoizedTeamMembersInactiveSearch
  );
  const inactiveLoading = useAppSelector(
    (state) => state.teamMembers.inactiveTable.loading
  );
  const inactiveSelected = useAppSelector(
    (state) => state.teamMembers.inactiveTable.selectedEmpleados
  );
  const inactiveIsInteterminate = useAppSelector(
    (state) => state.teamMembers.inactiveTable.isIndeterminate
  );
  const inactiveAllSelected = useAppSelector(
    (state) => state.teamMembers.inactiveTable.selectAll
  );
  const inactiveTotalPages = useAppSelector(
    getMemoizedTeamMembersInactiveTotalPages
  );
  const inactiveSearchValue = useAppSelector(
    (state) => state.teamMembers.inactiveTable.searchText
  );
  const inactivePageMessage = useAppSelector(
    getMemoizedTeamMembersInactivePageMessage
  );
  const inactivePage = useAppSelector(
    (state) => state.teamMembers.inactiveTable.page
  );
  const inactiveMaxPerPage = useAppSelector(
    (state) => state.teamMembers.inactiveTable.maxPerPage
  );
  const inactiveFirstItem = (inactivePage - 1) * inactiveMaxPerPage;
  const inactiveLastItem = inactiveFirstItem + inactiveMaxPerPage - 1;

  // Empleado modal
  const empleadoId = useAppSelector(
    (state) => state.teamMembers.empleadoModal.id
  );
  const [empleadoModalActive, setEmpleadoModalActive] =
    useState<boolean>(false);

  const [graphData, setGraphData] = useState<{ id: number; data: GraphData }[]>(
    []
  );
  const [faltas, setFaltas] = useState<number>(0);
  const [tiempoExtra, setTiempoExtra] = useState<number>(0);
  const [puntual, setPuntual] = useState<number>(0);

  const [empleadosStatusValue, setEmpleadosStatusValue] = useState<number>(0);
  const activeFilter = useRef<HTMLIonPopoverElement>(null);
  const inactiveFilter = useRef<HTMLIonPopoverElement>(null);

  useEffect(() => {
    const loadGraph = async () => {
      setGraphData(await searchEmpleadoEnCentro());
    };
    loadGraph();
  }, []);

  useEffect(() => {
    if (!selectedStatus) return;
    if (graphData.length === 0) return;

    switch (selectedStatus.id) {
      case "1":
        setEmpleadosStatusValue(
          graphData.reduce((total, curr) => {
            return total + curr.data.empleados_presentes.length;
          }, 0)
        );
        break;
      case "2":
        setEmpleadosStatusValue(
          graphData.reduce((total, curr) => {
            return total + curr.data.empleados_con_retardo.length;
          }, 0)
        );
        break;
      case "3":
        setEmpleadosStatusValue(
          graphData.reduce((total, curr) => {
            return total + curr.data.empleados_en_horas_extra.length;
          }, 0)
        );
        break;
      case "4":
        setEmpleadosStatusValue(
          graphData.reduce((total, curr) => {
            return total + curr.data.empleados_ausentes.length;
          }, 0)
        );
        break;
      case "5":
        console.log("dentro case 5");
        setEmpleadosStatusValue(
          graphData.reduce((total, curr) => {
            return total + curr.data.empleados_esperados.length;
          }, 0)
        );
        break;
    }
  }, [selectedStatus, graphData]);

  useEffect(() => {
    if (empleadoId !== 0 && !empleadoModalActive) {
      setEmpleadoModalActive(true);
      presentEditEmpleadoModal({ backdropDismiss: false });
    }
  }, [empleadoId]);

  useEffect(() => {
    getGraphData();
  }, [graphData]);

  const [presentEditEmpleadoModal, dismissEditEmpleadoModal] = useIonModal(
    EditEmpleadoModal,
    {
      onDismiss: () => {
        setEmpleadoModalActive(false);
        dismissEditEmpleadoModal();
        dispatch(setTeamMembersEmpleadoModal(getEmptyEmpleado()));
      },
    }
  );
  const [presentCreateEmpleadoModal, dismissCreateEmpleadoModal] = useIonModal(
    CreateEmpleadoModal,
    {
      onDismiss: () => {
        setEmpleadoModalActive(false);
        dismissCreateEmpleadoModal();
        // dispatch(setEmpleadoModal(getEmptyEmpleado()));
      },
      autofocus: 0,
      accept: async (v: ICreateEmpleadoData) => {
        await dispatch(
          createMultipleEmpleadoAsyncThunk({
            serverDate: serverDate.data,
            idCliente: clienteQentry.data.id,
            create: [v],
          })
        );
        await fetchEmpleado();
      },
    }
  );
  const [presentBulkLoadEmpleadoModal, dismissBulkLoadEmpleadoModal] =
    useIonModal(BulkLoadEmpleadoModal, {
      dismiss: () => {
        setEmpleadoModalActive(false);
        dismissBulkLoadEmpleadoModal();
      },
      accept: async (v: ICreateEmpleadoData[]) => {
        setEmpleadoModalActive(false);
        dismissBulkLoadEmpleadoModal();
        await dispatch(
          createMultipleEmpleadoAsyncThunk({
            serverDate: serverDate.data,
            idCliente: clienteQentry.data.id,
            create: v,
          })
        );
        await fetchEmpleado();
      },
    });

  const getGraphData = () => {
    const temp = [
      empleado.data_array.length -
        (graphData.reduce(
          (partial, e) =>
            partial +
            e.data.empleados_presentes?.length +
            e.data.empleados_presentes_no_esperados?.length +
            e.data.empleados_en_horas_extra?.length,
          0
        ) ?? 0),
      graphData.reduce(
        (partial, e) => partial + e.data.empleados_en_horas_extra?.length,
        0
      ),
      graphData.reduce(
        (partial, e) =>
          partial +
          e.data.empleados_presentes?.length +
          e.data.empleados_presentes_no_esperados?.length,
        0
      ),
    ];
    setFaltas(temp[0]);
    setTiempoExtra(temp[1]);
    setPuntual(temp[2]);
    return temp;
  };

  const data = {
    labels: ["Faltas", "Tiempo extra", "En tiempo"],
    datasets: [
      {
        label: "# of Votes",
        data: [faltas, tiempoExtra, puntual],
        backgroundColor: [
          "rgba(235, 68, 90, 0.8)",
          "rgba(16, 24, 32, 0.8)",
          "rgba(205, 222, 0, 0.8)",
        ],
        borderColor: [
          "rgba(235, 68, 90, 1)",
          "rgba(16, 24, 32, 1)",
          "rgba(205, 222, 0, 1)",
        ],
        borderWidth: 1,
      },
    ],
  };

  const downloadWorkersData = () => {
    const data = [
      {
        sheet: "Trabajadores",
        columns: [
          { label: "Nombre", value: "nombre" },
          { label: "Fecha de nacimiento", value: "fecha_nacimiento" },
          { label: "CURP", value: "numero_identidad_CURP" },
          { label: "Fecha de contratación", value: "fecha_contratacion" },
          { label: "Centros asignados", value: "centro_trabajo" },
          { label: "Empleados a cargo", value: "empleados_a_cargo" },
        ],
        content: fullActiveEmpleadoList.map((e) => {
          return {
            nombre: getFullName(e),
            fecha_nacimiento: e.datos_personales.fecha_nacimiento,
            numero_identidad_CURP: e.datos_personales.numero_identidad_CURP,
            fecha_contratacion: e.fecha_contratacion,
            centro_trabajo: e.centro_trabajo
              .map((v) => v.nombre_centro)
              .join(", "),
            empleados_a_cargo: e.empleados_a_cargo
              .map((v) => getFullName(v))
              .join(", "),
          };
        }),
      },
    ];

    const settings = {
      fileName: "Lista empleados activos", // Name of the resulting spreadsheet
      extraLength: 3, // A bigger number means that columns will be wider
      writeMode: "writeFile", // The available parameters are 'WriteFile' and 'write'. This setting is optional. Useful in such cases https://docs.sheetjs.com/docs/solutions/output#example-remote-file
      writeOptions: {}, // Style options from https://docs.sheetjs.com/docs/api/write-options
    };

    xlsx(data, settings);
  };

  const handleActiveFilter = () => {
    activeFilter.current?.dismiss();
    dispatch(
      setTeamMembersActiveCentroDeTrabajo({
        id: "",
        title: "Todos los centros de trabajo",
      })
    );
  };

  const handleInactiveFilter = () => {
    inactiveFilter.current?.dismiss();
    dispatch(
      setTeamMembersInactiveCentroDeTrabajo({
        id: "",
        title: "Todos los centros de trabajo",
      })
    );
  };

  return (
    <IonContent id="TeamMembers" class="inner-content">
      <IonPopover
        trigger="popover-filter-active-empleados"
        dismissOnSelect={false}
        alignment="center"
        ref={activeFilter}
      >
        <IonContent>
          <IonList>
            <IonItem
              lines="none"
              type="button"
              button={true}
              detail={false}
              onClick={handleActiveFilter}
            >
              <IonLabel>Borrar filtros</IonLabel>
            </IonItem>
            <IonItem
              lines="none"
              button={true}
              id="centro-de-trabajo-active-trigger"
            >
              <IonLabel>Centro de trabajo</IonLabel>
            </IonItem>
            <IonPopover
              trigger="centro-de-trabajo-active-trigger"
              dismissOnSelect={true}
              side="right"
              alignment="start"
            >
              <IonContent>
                <IonList>
                  {centroDeTrabajoList.map((centro) => {
                    return (
                      <IonItem
                        key={`active-${centro.id}`}
                        color={
                          activeSelectedCentroDeTrabajo.id === centro.id
                            ? "primary"
                            : undefined
                        }
                        type="button"
                        lines="full"
                        button={true}
                        detail={false}
                        onClick={() =>
                          dispatch(setTeamMembersActiveCentroDeTrabajo(centro))
                        }
                      >
                        <IonLabel>{centro.title}</IonLabel>
                      </IonItem>
                    );
                  })}
                </IonList>
              </IonContent>
            </IonPopover>
          </IonList>
        </IonContent>
      </IonPopover>
      <IonPopover
        trigger="popover-options-active-empleados"
        dismissOnSelect={true}
        alignment="center"
      >
        <IonContent>
          <IonList>
            <IonItem
              lines="full"
              button={true}
              type="button"
              detail={false}
              disabled={activeRemoveStatus}
              onClick={() => dispatch(setTeamMembersActiveRemoveStatus(true))}
            >
              <IonText>
                <h6>
                  <b>Desactivar empleados</b>
                </h6>
                <p>
                  <i>Inhabilita temporalmente a tus empleados</i>
                </p>
              </IonText>
            </IonItem>
            <IonItem
              lines="full"
              button={true}
              type="button"
              detail={false}
              disabled={activeRemoveStatus}
              onClick={() =>
                presentBulkLoadEmpleadoModal({ backdropDismiss: false })
              }
            >
              <IonText>
                <h6>
                  <b>Importar empleados desde tabla</b>
                </h6>
                <p>
                  <i>Importar nuevos empleados de forma masiva</i>
                </p>
              </IonText>
            </IonItem>
            <IonItem
              lines="full"
              button={true}
              type="button"
              detail={false}
              onClick={downloadWorkersData}
            >
              <IonText>
                <h6>
                  <b>Exportar</b>
                </h6>
                <p>
                  <i>Descarga los datos de tus empleados en formato excel</i>
                </p>
              </IonText>
            </IonItem>
          </IonList>
        </IonContent>
      </IonPopover>
      <IonPopover
        trigger="popover-filter-inactive-empleados"
        dismissOnSelect={false}
        side="right"
        alignment="start"
      >
        <IonContent>
          <IonList>
            <IonItem
              lines="none"
              type="button"
              button={true}
              detail={false}
              onClick={handleInactiveFilter}
            >
              <IonLabel>Borrar filtros</IonLabel>
            </IonItem>
            <IonItem
              lines="full"
              button={true}
              id="centro-de-trabajo-inactive-trigger"
            >
              <IonLabel>Centro de trabajo</IonLabel>
            </IonItem>
            <IonPopover
              trigger="centro-de-trabajo-inactive-trigger"
              dismissOnSelect={true}
              side="end"
            >
              <IonContent>
                <IonList>
                  {centroDeTrabajoList.map((centro) => {
                    return (
                      <IonItem
                        key={`inactive-${centro.id}`}
                        type="button"
                        lines="full"
                        color={
                          inactiveSelectedCentroDeTrabajo.id === centro.id
                            ? "primary"
                            : undefined
                        }
                        button={true}
                        detail={false}
                        onClick={() =>
                          dispatch(
                            setTeamMembersInactiveCentroDeTrabajo(centro)
                          )
                        }
                      >
                        <IonLabel>{centro.title}</IonLabel>
                      </IonItem>
                    );
                  })}
                </IonList>
              </IonContent>
            </IonPopover>
          </IonList>
        </IonContent>
      </IonPopover>
      <IonPopover
        trigger="popover-options-inactive-empleados"
        dismissOnSelect={true}
        side="bottom"
        alignment="end"
      >
        <IonContent>
          <IonList>
            <IonItem
              lines="full"
              button={true}
              detail={false}
              type="button"
              disabled={inactiveRemoveStatus || inactiveActivateStatus}
              onClick={() =>
                dispatch(setTeamMembersInactiveActivateStatus(true))
              }
            >
              <IonLabel>Activar empleados</IonLabel>
              <IonIcon slot="start" icon={personRemove} />
            </IonItem>
            <IonItem
              lines="full"
              button={true}
              detail={false}
              type="button"
              disabled={inactiveRemoveStatus || inactiveActivateStatus}
              onClick={() => dispatch(setTeamMembersInactiveRemoveStatus(true))}
            >
              <IonLabel>Eliminar empleados</IonLabel>
              <IonIcon slot="start" icon={personRemove} />
            </IonItem>
            <IonItem lines="full" button={true} detail={false}>
              <IonLabel>Descargar lista actual</IonLabel>
              <IonIcon slot="start" icon={download} />
            </IonItem>
          </IonList>
        </IonContent>
      </IonPopover>
      <IonGrid>
        <IonRow>
          <IonCol>
            <Card
              icon={people}
              title={<IonLabel>Resumen de trabajadores</IonLabel>}
              content={
                <IonGrid class="no-padding">
                  <IonRow className="no-padding">
                    <IonCol size="12">
                      <IonText>
                        <h5>
                          Encuentra todos tus empleados, puedes filtrar por el
                          centro de trabajo.
                        </h5>
                      </IonText>
                      <Selector
                        value={selectedEmpresa}
                        label="Cuentas/Regiones"
                        list={empresaList}
                        changeState={setTeamMembersSelectedEmpresa}
                      />
                    </IonCol>
                    {empleado.estado !== DEFAULT_STATE.loading ? (
                      <IonCol
                        className="graph-height no-padding"
                        sizeXl="5"
                        sizeLg="6"
                        sizeMd="6"
                        sizeSm="12"
                        sizeXs="12"
                        size="12"
                      >
                        <Card
                          styleCss={"no-margin no-padding graph-height"}
                          title={
                            <IonLabel>
                              Comportamiento global de empleados
                            </IonLabel>
                          }
                          content={
                            <Doughnut
                              id="empleados-asistencia"
                              data={data}
                              options={{
                                responsive: false,
                                maintainAspectRatio: false,
                                plugins: {
                                  legend: {
                                    display: true,
                                    position: "right",
                                  },
                                },
                              }}
                              plugins={[
                                {
                                  id: "doughnutEmpleados_plugin",
                                  beforeDraw(chart, args, options) {
                                    const chartArea = chart.chartArea;
                                    var width = chartArea.width,
                                      height = chart.height,
                                      ctx = chart.ctx;
                                    ctx.restore();
                                    // Cantidad empleados
                                    var fontSize = (height / 80).toFixed(2);
                                    ctx.font =
                                      "bold " + fontSize + "em sans-serif";

                                    ctx.textBaseline = "middle";
                                    var text = "" + empleado.data_array.length;
                                    var textX = Math.round(
                                      (width - ctx.measureText(text).width) / 2
                                    );
                                    var textY =
                                      chartArea.top +
                                      (chartArea.height * 8) / 17;
                                    ctx.fillText(text, textX, textY);
                                    // Label
                                    var fontSizeLabel = (height / 260).toFixed(
                                      2
                                    );
                                    ctx.font = fontSizeLabel + "em sans-serif";
                                    ctx.textBaseline = "top";
                                    var textLabel = "Total empleados";
                                    var textXLabel = Math.round(
                                      (width -
                                        ctx.measureText(textLabel).width) /
                                        2
                                    );
                                    var textYLabel =
                                      chartArea.top +
                                      (chartArea.height * 9) / 17;
                                    ctx.fillText(
                                      textLabel,
                                      textXLabel,
                                      textYLabel
                                    );

                                    ctx.save();
                                  },
                                },
                              ]}
                            />
                          }
                        />
                      </IonCol>
                    ) : (
                      <IonCol
                        sizeXl="5"
                        sizeLg="6"
                        sizeMd="6"
                        sizeSm="12"
                        sizeXs="12"
                        size="12"
                      >
                        <IonItem lines="none">
                          <IonLabel>Cargando gráfica.</IonLabel>
                          <IonSpinner
                            className="loading-center-horizontal"
                            name="circles"
                            color="medium"
                            slot="start"
                          />
                        </IonItem>
                      </IonCol>
                    )}
                    <IonCol
                      sizeXl="7"
                      sizeLg="6"
                      sizeMd="6"
                      sizeSm="12"
                      sizeXs="12"
                      size="12"
                    >
                      <IonCard
                        className="information-card full-height no-marging"
                        color="light"
                      >
                        <IonCardHeader>
                          <IonCardTitle>
                            <IonGrid class="no-padding">
                              <IonRow class="no-padding">
                                <IonCol size="6">
                                  <IonLabel>Empleados en turno</IonLabel>
                                </IonCol>
                                <IonCol size="6">
                                  <IonButton
                                    expand="block"
                                    fill="outline"
                                    size="small"
                                    onClick={() =>
                                      presentCreateEmpleadoModal({
                                        backdropDismiss: false,
                                      })
                                    }
                                  >
                                    <IonIcon
                                      slot="icon-only"
                                      icon={personAdd}
                                    />
                                  </IonButton>
                                </IonCol>
                              </IonRow>
                            </IonGrid>
                          </IonCardTitle>
                        </IonCardHeader>
                        <IonCardContent class="no-padding">
                          <IonGrid className="no-padding">
                            <IonRow className="no-padding information-card-content">
                              <IonCol
                                size="6"
                                className="no-padding ion-align-self-center"
                              >
                                <IonRow className="no-padding ion-align-items-center full-height">
                                  <IonCol size="12" className="no-padding">
                                    <IonText class="ion-text-center">
                                      <h3>
                                        {empleadosStatusValue} de{" "}
                                        {empleado.data_array.length}
                                      </h3>
                                      <h3>Laborando Totales</h3>
                                    </IonText>
                                  </IonCol>
                                </IonRow>
                              </IonCol>
                              <IonCol
                                size="6"
                                className="no-padding ion-align-self-center"
                              >
                                <IonRow className="no-padding ion-align-items-center full-height">
                                  <IonCol size="12" className="no-padding">
                                    <Selector
                                      clearLine={true}
                                      label="Empleados"
                                      list={empleadoStatus}
                                      value={selectedStatus}
                                      changeState={
                                        setTeamMembersActiveEmpleadoStatus
                                      }
                                    />
                                  </IonCol>
                                </IonRow>
                              </IonCol>
                            </IonRow>
                          </IonGrid>
                        </IonCardContent>
                      </IonCard>
                    </IonCol>
                  </IonRow>
                  <IonRow className="only-padding-top">
                    <IonCol size="12">
                      <IonAccordionGroup>
                        <IonAccordion value="active-empleados">
                          <IonItem
                            className="accordion-header"
                            lines="full"
                            slot="header"
                          >
                            <IonLabel>Lista de trabajadores activos</IonLabel>
                          </IonItem>
                          <IonList lines="full" slot="content">
                            <IonItem
                              color="light"
                              className="table-helper"
                              disabled={
                                empleado.estado === DEFAULT_STATE.loading
                              }
                            >
                              <IonButton
                                slot="start"
                                fill="clear"
                                shape="round"
                                size="small"
                                id="popover-filter-active-empleados"
                              >
                                <IonIcon
                                  slot="icon-only"
                                  icon={filterOutline}
                                />
                              </IonButton>
                              <IonSearchbar
                                color="light"
                                class="search-bar"
                                // value={activeSearchValue}
                                onIonInput={() => {
                                  if (!activeLoading)
                                    dispatch(setTeamMembersActiveLoading(true));
                                }}
                                debounce={400}
                                onIonChange={async (e) => {
                                  dispatch(resetTeamMembersActivePage());
                                  dispatch(
                                    setTeamMembersActiveSearchValue(
                                      e.detail.value!
                                    )
                                  );
                                  dispatch(setTeamMembersActiveLoading(false));
                                }}
                                placeholder="Nombre"
                                className="search-bar-padding"
                              />
                              <IonButtons slot="end">
                                <IonButton
                                  fill="clear"
                                  shape="round"
                                  size="small"
                                  id="popover-options-active-empleados"
                                >
                                  <IonIcon
                                    slot="icon-only"
                                    icon={ellipsisVertical}
                                  />
                                </IonButton>
                              </IonButtons>
                            </IonItem>
                            {activeRemoveStatus ? (
                              <IonItem color="tertiary">
                                <IonCheckbox
                                  slot="start"
                                  disabled={activeSearchResult.length === 0}
                                  indeterminate={activeIsInteterminate}
                                  checked={activeAllSelected}
                                  onIonChange={(e) => {
                                    if (activeAllSelected !== e.detail.checked)
                                      dispatch(
                                        e.detail.checked
                                          ? addAllTeamMembersActiveSelectedEmpleado(
                                              {
                                                item: activeSearchResult.map(
                                                  (v) =>
                                                    setListItem({
                                                      id: v.id.toString(),
                                                      title:
                                                        v.datos_personales
                                                          .primer_nombre,
                                                    })
                                                ),
                                                activeSearchResult:
                                                  activeSearchResult,
                                                centro:
                                                  activeSelectedCentroDeTrabajo,
                                                searchValue: activeSearchValue,
                                              }
                                            )
                                          : removeAllTeamMembersActiveSelectedEmpleado()
                                      );
                                  }}
                                />

                                <IonLabel>{activeSelected.length}</IonLabel>
                                <IonButtons slot="end">
                                  <IonButton
                                    disabled={activeSelected.length < 1}
                                    onClick={async () => {
                                      dispatch(
                                        setTeamMembersActiveRemoveStatus(false)
                                      );
                                      await dispatch(
                                        deactivateEmpleadoAsyncThunk({
                                          serverDate: serverDate.data,
                                          idCliente: clienteQentry.data.id,
                                          ids: activeSelected.map((e) =>
                                            Number(e.id)
                                          ),
                                        })
                                      );
                                    }}
                                  >
                                    <IonIcon
                                      color="primary"
                                      slot="icon-only"
                                      icon={checkmark}
                                    />
                                  </IonButton>
                                  <IonButton
                                    onClick={() =>
                                      dispatch(
                                        setTeamMembersActiveRemoveStatus(false)
                                      )
                                    }
                                  >
                                    <IonIcon
                                      color="danger"
                                      slot="icon-only"
                                      icon={close}
                                    />
                                  </IonButton>
                                </IonButtons>
                              </IonItem>
                            ) : (
                              <></>
                            )}
                            <IonItem color="secondary">
                              <IonGrid>
                                <IonRow>
                                  <IonCol
                                    size="12"
                                    sizeXs="12"
                                    sizeSm="12"
                                    sizeMd="6"
                                    sizeLg="6"
                                    sizeXl="6"
                                  >
                                    <IonLabel>Nombre</IonLabel>
                                  </IonCol>
                                  <IonCol size="6" class="ion-hide-md-down">
                                    <IonLabel>Centros de trabajo</IonLabel>
                                  </IonCol>
                                </IonRow>
                              </IonGrid>
                            </IonItem>
                            {activeLoading ? (
                              <>
                                <IonProgressBar
                                  type="indeterminate"
                                  color="primary"
                                />
                              </>
                            ) : (
                              <></>
                            )}
                            {empleado.estado === DEFAULT_STATE.loading ? (
                              <IonItem lines="none">
                                <IonLabel>
                                  Cargando lista de empleados activos.
                                </IonLabel>
                                <IonSpinner
                                  className="loading-center-horizontal"
                                  name="circles"
                                  color="medium"
                                  slot="start"
                                />
                              </IonItem>
                            ) : activeSearchResult.length > 0 &&
                              activeSelectedCentroDeTrabajo ? (
                              activeSearchResult.map((e, id) => {
                                const show =
                                  id <= activeLastItem && id >= activeFirstItem;
                                if (show) {
                                  const checked =
                                    activeSelected.findIndex(
                                      (v) => v.id === e.id.toString()
                                    ) !== -1;
                                  const item = setListItem({
                                    id: e.id.toString(),
                                    title: e.datos_personales.primer_nombre,
                                  });
                                  if (checked)
                                    dispatch(
                                      addTeamMembersActiveSelectedEmpleado({
                                        item: item,
                                        activeSearchResult: activeSearchResult,
                                        centro: activeSelectedCentroDeTrabajo,
                                      })
                                    );
                                }
                                return show ? (
                                  <IonItem key={`active-${id}-${e.id}`}>
                                    {activeRemoveStatus ? (
                                      <IonCheckbox
                                        checked={
                                          activeSelected.findIndex(
                                            (v) => v.id === e.id.toString()
                                          ) !== -1
                                        }
                                        slot="start"
                                        onIonChange={(v) => {
                                          const item = setListItem({
                                            id: e.id.toString(),
                                            title:
                                              e.datos_personales.primer_nombre,
                                          });
                                          dispatch(
                                            v.detail.checked
                                              ? addTeamMembersActiveSelectedEmpleado(
                                                  {
                                                    item: item,
                                                    activeSearchResult:
                                                      activeSearchResult,
                                                    centro:
                                                      activeSelectedCentroDeTrabajo,
                                                  }
                                                )
                                              : removeTeamMembersActiveSelectedEmpleado(
                                                  item
                                                )
                                          );
                                        }}
                                      />
                                    ) : (
                                      <></>
                                    )}
                                    <IonGrid>
                                      <IonRow>
                                        <IonCol
                                          size="12"
                                          sizeXs="12"
                                          sizeSm="12"
                                          sizeMd="6"
                                          sizeLg="6"
                                          sizeXl="6"
                                        >
                                          <IonText>
                                            {`${getNombreEmpleado(e)}`}
                                          </IonText>
                                        </IonCol>
                                        <IonCol
                                          size="6"
                                          class="ion-hide-md-down"
                                        >
                                          <IonText>
                                            {e.centro_trabajo.map((c) => (
                                              <h3
                                                key={`active-${id}-${e.id}-${c.id}`}
                                              >
                                                {c.nombre_centro}
                                              </h3>
                                            ))}
                                          </IonText>
                                        </IonCol>
                                      </IonRow>
                                    </IonGrid>
                                    {!activeRemoveStatus ? (
                                      <IonButton
                                        fill="clear"
                                        slot="end"
                                        onClick={() => {
                                          const temp = { ...e };
                                          if (!temp.numero_seguridad_social)
                                            temp.numero_seguridad_social = "SN";
                                          if (!temp.id_telefono_autorizado)
                                            temp.id_telefono_autorizado = "SN";
                                          if (!temp.fecha_contratacion)
                                            temp.fecha_contratacion =
                                              getFormattedDateString(
                                                SERVER_TIME_ZONE
                                              );
                                          console.log(
                                            "setTeamMembersEmpleadoModal",
                                            temp
                                          );
                                          dispatch(
                                            setTeamMembersEmpleadoModal(temp)
                                          );
                                        }}
                                      >
                                        <IonIcon
                                          slot="icon-only"
                                          icon={pencil}
                                          color="primary"
                                        />
                                      </IonButton>
                                    ) : (
                                      <></>
                                    )}
                                  </IonItem>
                                ) : (
                                  <IonItem
                                    key={`active-hidden-${id}-${e.id}`}
                                    class="item-hide"
                                  />
                                );
                              })
                            ) : (
                              <IonItem>
                                <IonLabel>
                                  No hay más empleados activos
                                </IonLabel>
                              </IonItem>
                            )}
                            <IonItem lines="full">
                              <IonLabel slot="end" className="ion-text-right">
                                {activePageMessage}
                              </IonLabel>
                              <IonButtons slot="end">
                                <IonButton
                                  disabled={activePage === 1}
                                  class="grouped-buttons"
                                  onClick={() =>
                                    dispatch(activeTeamMembersPreviousPage())
                                  }
                                >
                                  <IonIcon
                                    slot="icon-only"
                                    icon={chevronBackSharp}
                                  />
                                </IonButton>
                                <IonButton
                                  disabled={activePage >= activeTotalPages}
                                  class="grouped-buttons"
                                  onClick={() =>
                                    dispatch(
                                      activeTeamMembersNextPage(
                                        activeTotalPages
                                      )
                                    )
                                  }
                                >
                                  <IonIcon
                                    slot="icon-only"
                                    icon={chevronForwardSharp}
                                  />
                                </IonButton>
                              </IonButtons>
                            </IonItem>
                          </IonList>
                        </IonAccordion>
                        <IonAccordion value="inactive-empleados">
                          <IonItem
                            className="accordion-header"
                            lines="full"
                            slot="header"
                          >
                            <IonLabel>Lista de trabajadores inactivos</IonLabel>
                          </IonItem>
                          <IonList lines="full" slot="content">
                            <IonItem
                              color="light"
                              className="table-helper"
                              disabled={
                                empleado.estado === DEFAULT_STATE.loading
                              }
                            >
                              <IonButton
                                slot="start"
                                fill="clear"
                                shape="round"
                                size="small"
                                id="popover-filter-inactive-empleados"
                              >
                                <IonIcon
                                  slot="icon-only"
                                  icon={filterOutline}
                                />
                              </IonButton>
                              <IonSearchbar
                                color="light"
                                class="search-bar"
                                // value={inactiveSearchValue}
                                onIonInput={() => {
                                  if (!inactiveLoading)
                                    dispatch(
                                      setTeamMembersInactiveLoading(true)
                                    );
                                }}
                                debounce={400}
                                onIonChange={async (e) => {
                                  dispatch(resetTeamMembersInactivePage());
                                  dispatch(
                                    setTeamMembersInactiveSearchValue(
                                      e.detail.value!
                                    )
                                  );
                                  dispatch(
                                    setTeamMembersInactiveLoading(false)
                                  );
                                }}
                                placeholder="Nombre"
                                className="search-bar-padding"
                              />
                              <IonButtons slot="end">
                                <IonButton
                                  fill="clear"
                                  shape="round"
                                  size="small"
                                  id="popover-options-inactive-empleados"
                                >
                                  <IonIcon
                                    slot="icon-only"
                                    icon={ellipsisVertical}
                                  />
                                </IonButton>
                              </IonButtons>
                            </IonItem>
                            {inactiveRemoveStatus || inactiveActivateStatus ? (
                              <IonItem color="tertiary">
                                <IonCheckbox
                                  slot="start"
                                  disabled={inactiveSearchResult.length === 0}
                                  indeterminate={inactiveIsInteterminate}
                                  checked={inactiveAllSelected}
                                  onIonChange={(e) => {
                                    if (
                                      inactiveAllSelected !== e.detail.checked
                                    )
                                      dispatch(
                                        e.detail.checked
                                          ? addAllTeamMembersInactiveSelectedEmpleado(
                                              {
                                                item: inactiveSearchResult.map(
                                                  (v) =>
                                                    setListItem({
                                                      id: v.id.toString(),
                                                      title:
                                                        v.datos_personales
                                                          .primer_nombre,
                                                    })
                                                ),
                                                inactiveSearchResult:
                                                  inactiveSearchResult,
                                                centro:
                                                  inactiveSelectedCentroDeTrabajo,
                                                searchValue:
                                                  inactiveSearchValue,
                                              }
                                            )
                                          : removeAllTeamMembersInactiveSelectedEmpleado()
                                      );
                                  }}
                                />

                                <IonLabel>
                                  {(inactiveRemoveStatus
                                    ? "Eliminar: "
                                    : inactiveActivateStatus
                                    ? "Activar: "
                                    : "") + inactiveSelected.length}
                                </IonLabel>
                                <IonButtons slot="end">
                                  <IonButton
                                    disabled={inactiveSelected.length < 1}
                                    onClick={async () => {
                                      if (inactiveRemoveStatus) {
                                        dispatch(
                                          setTeamMembersInactiveRemoveStatus(
                                            false
                                          )
                                        );
                                        await dispatch(
                                          deleteMultipleEmpleadoAsyncThunk({
                                            serverDate: serverDate.data,
                                            idCliente: clienteQentry.data.id,
                                            ids: inactiveSelected.map((e) =>
                                              Number(e.id)
                                            ),
                                          })
                                        );
                                        return;
                                      }
                                      if (inactiveActivateStatus) {
                                        dispatch(
                                          setTeamMembersInactiveActivateStatus(
                                            false
                                          )
                                        );
                                        await dispatch(
                                          activateEmpleadoAsyncThunk({
                                            serverDate: serverDate.data,
                                            idCliente: clienteQentry.data.id,
                                            ids: inactiveSelected.map((e) =>
                                              Number(e.id)
                                            ),
                                          })
                                        );
                                        return;
                                      }
                                    }}
                                  >
                                    <IonIcon
                                      color="primary"
                                      slot="icon-only"
                                      icon={checkmark}
                                    />
                                  </IonButton>
                                  <IonButton
                                    onClick={() =>
                                      dispatch(
                                        setTeamMembersInactiveRemoveStatus(
                                          false
                                        )
                                      )
                                    }
                                  >
                                    <IonIcon
                                      color="danger"
                                      slot="icon-only"
                                      icon={close}
                                    />
                                  </IonButton>
                                </IonButtons>
                              </IonItem>
                            ) : (
                              <></>
                            )}
                            <IonItem color="secondary">
                              <IonGrid>
                                <IonRow>
                                  <IonCol
                                    size="12"
                                    sizeXs="12"
                                    sizeSm="12"
                                    sizeMd="6"
                                    sizeLg="6"
                                    sizeXl="6"
                                  >
                                    <IonLabel>Nombre</IonLabel>
                                  </IonCol>
                                  <IonCol size="6" class="ion-hide-md-down">
                                    <IonLabel>Centros de trabajo</IonLabel>
                                  </IonCol>
                                </IonRow>
                              </IonGrid>
                            </IonItem>
                            {inactiveLoading ? (
                              <>
                                <IonProgressBar
                                  type="indeterminate"
                                  color="primary"
                                />
                              </>
                            ) : (
                              <></>
                            )}
                            {empleado.estado === DEFAULT_STATE.loading ? (
                              <IonItem lines="none">
                                <IonLabel>
                                  Cargando lista de empleados activos.
                                </IonLabel>
                                <IonSpinner
                                  className="loading-center-horizontal"
                                  name="circles"
                                  color="medium"
                                  slot="start"
                                />
                              </IonItem>
                            ) : inactiveSearchResult.length > 0 &&
                              inactiveSelectedCentroDeTrabajo ? (
                              inactiveSearchResult.map((e, id) => {
                                const show =
                                  id <= inactiveLastItem &&
                                  id >= inactiveFirstItem;
                                if (show) {
                                  const checked =
                                    inactiveSelected.findIndex(
                                      (v) => v.id === e.id.toString()
                                    ) !== -1;
                                  const item = setListItem({
                                    id: e.id.toString(),
                                    title: e.datos_personales.primer_nombre,
                                  });
                                  if (checked)
                                    dispatch(
                                      addTeamMembersInactiveSelectedEmpleado({
                                        item: item,
                                        inactiveSearchResult:
                                          inactiveSearchResult,
                                        centro: inactiveSelectedCentroDeTrabajo,
                                      })
                                    );
                                }
                                return show ? (
                                  <IonItem key={`inactive-${id}-${e.id}`}>
                                    {inactiveRemoveStatus ||
                                    inactiveActivateStatus ? (
                                      <IonCheckbox
                                        checked={
                                          inactiveSelected.findIndex(
                                            (v) => v.id === e.id.toString()
                                          ) !== -1
                                        }
                                        slot="start"
                                        onIonChange={(v) => {
                                          const item = setListItem({
                                            id: e.id.toString(),
                                            title:
                                              e.datos_personales.primer_nombre,
                                          });
                                          dispatch(
                                            v.detail.checked
                                              ? addTeamMembersInactiveSelectedEmpleado(
                                                  {
                                                    item: item,
                                                    inactiveSearchResult:
                                                      inactiveSearchResult,
                                                    centro:
                                                      inactiveSelectedCentroDeTrabajo,
                                                  }
                                                )
                                              : removeTeamMembersInactiveSelectedEmpleado(
                                                  item
                                                )
                                          );
                                        }}
                                      />
                                    ) : (
                                      <></>
                                    )}
                                    <IonGrid>
                                      <IonRow>
                                        <IonCol
                                          size="12"
                                          sizeXs="12"
                                          sizeSm="12"
                                          sizeMd="6"
                                          sizeLg="6"
                                          sizeXl="6"
                                        >
                                          <IonText>
                                            {`${getNombreEmpleado(e)}`}
                                          </IonText>
                                        </IonCol>
                                        <IonCol
                                          size="6"
                                          class="ion-hide-md-down"
                                        >
                                          <IonText>
                                            {e.centro_trabajo.map((c) => (
                                              <h3
                                                key={`inactive-${id}-${e.id}-${c.id}`}
                                              >
                                                {c.nombre_centro}
                                              </h3>
                                            ))}
                                          </IonText>
                                        </IonCol>
                                      </IonRow>
                                    </IonGrid>
                                  </IonItem>
                                ) : (
                                  <IonItem
                                    key={`inactive-hidden-${id}-${e.id}`}
                                    class="item-hide"
                                  />
                                );
                              })
                            ) : (
                              <IonItem>
                                <IonLabel>
                                  No hay más empleados activos
                                </IonLabel>
                              </IonItem>
                            )}
                            <IonItem lines="full">
                              <IonLabel slot="end" className="ion-text-right">
                                {inactivePageMessage}
                              </IonLabel>
                              <IonButtons slot="end">
                                <IonButton
                                  disabled={inactivePage === 1}
                                  class="grouped-buttons"
                                  onClick={() =>
                                    dispatch(inactiveTeamMembersPreviousPage())
                                  }
                                >
                                  <IonIcon
                                    slot="icon-only"
                                    icon={chevronBackSharp}
                                  />
                                </IonButton>
                                <IonButton
                                  disabled={inactivePage >= inactiveTotalPages}
                                  class="grouped-buttons"
                                  onClick={() =>
                                    dispatch(
                                      inactiveTeamMembersNextPage(
                                        inactiveTotalPages
                                      )
                                    )
                                  }
                                >
                                  <IonIcon
                                    slot="icon-only"
                                    icon={chevronForwardSharp}
                                  />
                                </IonButton>
                              </IonButtons>
                            </IonItem>
                          </IonList>
                        </IonAccordion>
                      </IonAccordionGroup>
                    </IonCol>
                  </IonRow>
                </IonGrid>
              }
            />
          </IonCol>
        </IonRow>
        <IonRow
          className="no-padding"
          id="dashboard-map"
          style={{ height: "0", width: "0", display: "none" }}
        >
          <IonCol>
            <GoogleMapsV3
              id="dashboard-map"
              content={undefined}
              markers={[]}
              listData={[]}
              hasSearchBox={false}
              defaultWorkCenterSelector={false}
            />
          </IonCol>
        </IonRow>
      </IonGrid>
    </IonContent>
  );
};

export default TeamMembers;
