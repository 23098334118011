/** 
 * Componente que se encarga del elemento InformacionContrato
 * @IInformacionContrato es la interfaz que contiene los parámetos el elemento InformacionContrato
 * @getEmptyInformacionContrato  devuelve un objeto con los parámetros por defecto; @param {id}, opcional cuando no encuentra el objeto en el LocalSotrage
 * @IStatusInformacionContrato es la interfaz para los estatus a llamadas a la API; @param {status}, opcional cuando queremos otro estado de inizialización de tipo [DEFAULT_STATE]
 * @getEmptyStatusInformacionContrato devuelve un objeto con los parámetros por defecto,
 * @setInformacionContrato devuelve un objeto de tipo [IInformacionContrato] recibiendo @param {object} y @param {serverDate}, para verificar que los datos estén en el formato correcto y ajustarlos a la zona horaria actual
 * @getSerializableInformacionContrato recibe un @param {IInformacionContrato}, para devolver un objeto serizalizable para las peticiones de la API
 * */

import { getDateString, isNotEmpty, SERVER_TIME_ZONE } from "../../api/helpers";
import { DEFAULT_STATE } from "../../enums/default_state";
import { getStorageServicioQentryArrayItem, getStorageCanalVentaArrayItem, getStorageMvTipoSuscripcionArrayItem, getStorageUserArrayItem, getStorageClienteQentryArrayItem } from "../../localStorage/personas";
import { convertDateToZonaHoraria, ILocalStorageExpiry, getEmptyLocalStorageExpiry, IServerDate } from "../time";
import { ICanalVenta, getEmptyCanalVenta, setCanalVenta } from "./ICanalVenta";
import { getEmptyClienteQentry, IClienteQentry, setClienteQentry } from "./IClienteQentry";
import { IMvTipoSuscripcion, getEmptyMvTipoSuscripcion, setMvTipoSuscripcion } from "./IMvTipoSuscripcion";
import { IServicioQentry, setServicioQentry } from "./IServicioQentry";
import { IUser, getEmptyUser, setUser } from "./IUser";

export interface IInformacionSuscripcion {
  id : number,
  servicios : IServicioQentry[],
  canal_venta : ICanalVenta,
  tipo_suscripcion : IMvTipoSuscripcion,
  vigente : boolean,
  fecha_inicio : string, // Fecha
  fecha_vencimiento : string, // Fecha
  total_servicios : number, // Decimal
  sub_total : number, // Decimal
  impuestos : number, // Decimal
  descuento : number, // Decimal
  total : number, // Decimal
  descuento_aplicado : boolean,
  autor : IUser,
  fecha_elaboracion : string, // Fecha
  estatus_sistema : boolean,
  ultima_actualizacion : string, // Fecha
  cliente_qentry : IClienteQentry,
}

export const getEmptyInformacionSuscripcion = (id? : number) => {
  const temp : IInformacionSuscripcion = 
  {
    id : id ? id : 0,
    servicios : [],
    canal_venta : getEmptyCanalVenta(),
    tipo_suscripcion : getEmptyMvTipoSuscripcion(),
    vigente : false,
    fecha_inicio : "",
    fecha_vencimiento : "",
    total_servicios : 0,
    sub_total : 0,
    impuestos : 0,
    descuento : 0,
    total : 0,
    descuento_aplicado : false,
    autor : getEmptyUser(),
    fecha_elaboracion : "",
    estatus_sistema : false,
    ultima_actualizacion :  "",
    cliente_qentry : getEmptyClienteQentry(),
  };
  return temp;
}

export interface IStatusInformacionSuscripcion {
  estado : DEFAULT_STATE,
  mensaje : string,
  data : IInformacionSuscripcion,
  data_array: IInformacionSuscripcion[],
  expiry : ILocalStorageExpiry,
  expiry_array : ILocalStorageExpiry,
}

export const getEmptyStatusInformacionSuscripcion = (status: DEFAULT_STATE = DEFAULT_STATE.loading) => {
  const temp : IStatusInformacionSuscripcion = 
  {
    estado : status,
    mensaje : "",
    data : getEmptyInformacionSuscripcion(),
    data_array : [],
    expiry : getEmptyLocalStorageExpiry("IInformacionSuscripcion", 0),
    expiry_array : getEmptyLocalStorageExpiry("IInformacionSuscripcionArray", 0),
  };
  return temp;
}

export const setInformacionSuscripcion = async (
  object : any,
  serverDate : IServerDate,
) => {
  const temp = getEmptyInformacionSuscripcion();
  if (isNotEmpty(object.id)) temp.id = object.id;
  if (isNotEmpty(object.servicios)) temp.servicios = (typeof object.servicios[0] === 'number') ? await Promise.all(object.servicios.map(async (e : number) => await getStorageServicioQentryArrayItem(e))) : await Promise.all(object.servicios.map(async (e : any) => await setServicioQentry(e, serverDate)));
  if (isNotEmpty(object.canal_venta)) temp.canal_venta = (typeof object.canal_venta === 'number') ? await getStorageCanalVentaArrayItem(object.canal_venta) : await setCanalVenta(object.canal_venta, serverDate);
  if (isNotEmpty(object.tipo_suscripcion)) temp.tipo_suscripcion = (typeof object.tipo_suscripcion === 'number') ? await getStorageMvTipoSuscripcionArrayItem(object.tipo_suscripcion) : await setMvTipoSuscripcion(object.tipo_suscripcion, serverDate);
  if (isNotEmpty(object.vigente)) temp.vigente = object.vigente;
  if (isNotEmpty(object.fecha_inicio)) temp.fecha_inicio = convertDateToZonaHoraria(serverDate, object.fecha_inicio);
  if (isNotEmpty(object.fecha_vencimiento)) temp.fecha_vencimiento = convertDateToZonaHoraria(serverDate, object.fecha_vencimiento);
  if (isNotEmpty(object.total_servicios)) temp.total_servicios = Number(object.total_servicios);
  if (isNotEmpty(object.sub_total)) temp.sub_total = Number(object.sub_total);
  if (isNotEmpty(object.impuestos)) temp.impuestos = Number(object.impuestos);
  if (isNotEmpty(object.descuento)) temp.descuento = Number(object.descuento);
  if (isNotEmpty(object.total)) temp.total = Number(object.total);
  if (isNotEmpty(object.descuento_aplicado)) temp.descuento_aplicado = object.descuento_aplicado;
  if (isNotEmpty(object.autor)) temp.autor = (typeof object.autor === 'number') ? await getStorageUserArrayItem(object.autor) : await setUser(object.autor, serverDate);
  if (isNotEmpty(object.fecha_elaboracion)) temp.fecha_elaboracion = convertDateToZonaHoraria(serverDate, object.fecha_elaboracion);
  if (isNotEmpty(object.estatus_sistema)) temp.estatus_sistema = object.estatus_sistema;
  if (isNotEmpty(object.ultima_actualizacion)) temp.ultima_actualizacion = convertDateToZonaHoraria(serverDate, object.ultima_actualizacion);
  if (isNotEmpty(object.cliente_qentry)) temp.cliente_qentry = (typeof object.cliente_qentry === 'number') ? await getStorageClienteQentryArrayItem(object.cliente_qentry) : await setClienteQentry(object.cliente_qentry, serverDate);
  return temp;
}

export const getSerializableInformacionSuscripcion = (IInformacionSuscripcion : IInformacionSuscripcion) => {
  return {
    id : IInformacionSuscripcion.id,
    servicios : IInformacionSuscripcion.servicios.map((e) => e.id),
    canal_venta : IInformacionSuscripcion.canal_venta.id,
    tipo_suscripcion : IInformacionSuscripcion.tipo_suscripcion.id,
    vigente : IInformacionSuscripcion.vigente,
    fecha_inicio : getDateString(SERVER_TIME_ZONE, IInformacionSuscripcion.fecha_inicio),
    fecha_vencimiento : getDateString(SERVER_TIME_ZONE, IInformacionSuscripcion.fecha_vencimiento),
    total_servicios : IInformacionSuscripcion.total_servicios.toFixed(2),
    sub_total : IInformacionSuscripcion.sub_total.toFixed(2),
    impuestos : IInformacionSuscripcion.impuestos.toFixed(2),
    descuento : IInformacionSuscripcion.descuento.toFixed(2),
    total : IInformacionSuscripcion.total.toFixed(2),
    descuento_aplicado : IInformacionSuscripcion.descuento_aplicado,
    autor : IInformacionSuscripcion.autor.id,
    fecha_elaboracion : IInformacionSuscripcion.fecha_elaboracion,
    estatus_sistema : IInformacionSuscripcion.estatus_sistema,
    ultima_actualizacion : getDateString(SERVER_TIME_ZONE),
    cliente_qentry : IInformacionSuscripcion.cliente_qentry.id
  };
}
