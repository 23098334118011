/** 
 * Componente que se encarga del elemento CheckRondin
 * @ICheckRondin es la interfaz que contiene los parámetos el elemento CheckRondin
 * @getEmptyCheckRondin  devuelve un objeto con los parámetros por defecto; @param {id}, opcional cuando no encuentra el objeto en el LocalSotrage
 * @IStatusCheckRondin es la interfaz para los estatus a llamadas a la API; @param {status}, opcional cuando queremos otro estado de inizialización de tipo [DEFAULT_STATE]
 * @getEmptyStatusCheckRondin devuelve un objeto con los parámetros por defecto,
 * @setCheckRondin devuelve un objeto de tipo [ICheckRondin] recibiendo @param {object} y @param {serverDate}, para verificar que los datos estén en el formato correcto y ajustarlos a la zona horaria actual
 * @getSerializableCheckRondin recibe un @param {ICheckRondin}, para devolver un objeto serizalizable para las peticiones de la API
 * */

import { getEmptyRondin, IRondin, setRondin } from ".";
import { getDateString, isNotEmpty, SERVER_TIME_ZONE } from "../../api/helpers";
import { DEFAULT_STATE } from "../../enums/default_state";
import { getStorageClienteQentryArrayItem, getStorageEmpleadoArrayItem, getStorageEmpresaArrayItem, getStorageGeolocalizacionArrayItem } from "../../localStorage/personas";
import { getStorageCheckpointRondinArrayItem, getStorageRondinArrayItem } from "../../localStorage/rondines";
import { getEmptyClienteQentry, getEmptyEmpleado, getEmptyEmpresa, getEmptyGeolocalizacion, IClienteQentry, IEmpleado, IEmpresa, IGeolocalizacion, setClienteQentry, setEmpleado, setEmpresa, setGeolocalizacion } from "../personas";
import { ILocalStorageExpiry, getEmptyLocalStorageExpiry, IServerDate, convertDateToZonaHoraria } from "../time";
import { getEmptyCheckpointRondin, ICheckpointRondin, setCheckpointRondin } from "./CheckpointRondin";

export interface ICheckRondin {
  id : number,
  empleado : IEmpleado,
  checkpoint : ICheckpointRondin,
  geolocalizacion : IGeolocalizacion,
  rondin : IRondin,
  identificador_rondin : string,
  cliente_qentry : IClienteQentry,
  empresa : IEmpresa,
  fecha_elaboracion : string, // Fecha
  estatus_sistema : boolean,
  ultima_actualizacion : string, // Fecha
}

export const getEmptyCheckRondin = (id? : number) => {
  const temp : ICheckRondin = 
  {
    id : id ? id : 0,
    empleado : getEmptyEmpleado(),
    checkpoint : getEmptyCheckpointRondin(),
    geolocalizacion : getEmptyGeolocalizacion(),
    rondin : getEmptyRondin(),
    identificador_rondin : "",
    empresa : getEmptyEmpresa(),
    cliente_qentry : getEmptyClienteQentry(),
    fecha_elaboracion : "",
    estatus_sistema : false,
    ultima_actualizacion : "",
  };
  return temp;
}

export interface IStatusCheckRondin {
  estado : DEFAULT_STATE,
  mensaje : string,
  data : ICheckRondin,
  data_array: ICheckRondin[],
  expiry : ILocalStorageExpiry,
  expiry_array : ILocalStorageExpiry,
}

export const getEmptyStatusCheckRondin = (status: DEFAULT_STATE = DEFAULT_STATE.loading) => {
  const temp : IStatusCheckRondin = 
  {
    estado : status,
    mensaje : "",
    data : getEmptyCheckRondin(),
    data_array : [],
    expiry : getEmptyLocalStorageExpiry("ICheckRondin", 0),
    expiry_array : getEmptyLocalStorageExpiry("ICheckRondinArray", 0),
  };
  return temp;
}

export const setCheckRondin = async (
  object : any,
  serverDate : IServerDate,
) => {
  const temp = getEmptyCheckRondin();
  if (isNotEmpty(object.id)) temp.id = object.id;
  if (isNotEmpty(object.empleado)) temp.empleado = (typeof object.empleado === 'number') ? await getStorageEmpleadoArrayItem(object.empleado) : await setEmpleado(object.empleado, serverDate);
  if (isNotEmpty(object.checkpoint)) temp.checkpoint = (typeof object.checkpoint === 'number') ? await getStorageCheckpointRondinArrayItem(object.checkpoint) : await setCheckpointRondin(object.checkpoint, serverDate);
  if (isNotEmpty(object.geolocalizacion)) temp.geolocalizacion = (typeof object.geolocalizacion === 'number') ? await getStorageGeolocalizacionArrayItem(object.geolocalizacion) : await setGeolocalizacion(object.geolocalizacion, serverDate);
  if (isNotEmpty(object.rondin)) temp.rondin = (typeof object.rondin === 'number') ? await getStorageRondinArrayItem(object.rondin) : await setRondin(object.rondin, serverDate);
  if (isNotEmpty(object.identificador_rondin)) temp.identificador_rondin = object.identificador_rondin;
  if (isNotEmpty(object.cliente_qentry)) temp.cliente_qentry = (typeof object.cliente_qentry === 'number') ? await getStorageClienteQentryArrayItem(object.cliente_qentry) : await setClienteQentry(object.cliente_qentry, serverDate);
  if (isNotEmpty(object.empresa)) temp.empresa = (typeof object.empresa === 'number') ? await getStorageEmpresaArrayItem(object.empresa) : await setEmpresa(object.empresa, serverDate);
  if (isNotEmpty(object.fecha_elaboracion)) temp.fecha_elaboracion = convertDateToZonaHoraria(serverDate, object.fecha_elaboracion);
  if (isNotEmpty(object.estatus_sistema)) temp.estatus_sistema = object.estatus_sistema;
  if (isNotEmpty(object.ultima_actualizacion)) temp.ultima_actualizacion = convertDateToZonaHoraria(serverDate, object.ultima_actualizacion);
  return temp;
}

export const getSerializableCheckRondin = (ICheckRondin : ICheckRondin ) => {
  return {
    id : ICheckRondin.id,
    empleado : ICheckRondin.empleado.id,
    checkpoint : ICheckRondin.checkpoint.id,
    geolocalizacion : ICheckRondin.geolocalizacion.id,
    rondin : ICheckRondin.rondin.id,
    identificador_rondin : ICheckRondin.identificador_rondin,
    empresa : ICheckRondin.empresa.id,
    cliente_qentry : ICheckRondin.cliente_qentry.id,
    fecha_elaboracion : ICheckRondin.fecha_elaboracion,
    estatus_sistema : ICheckRondin.estatus_sistema,
    ultima_actualizacion : getDateString(SERVER_TIME_ZONE),
  };
}