/**
 * Métodos para el manejo de IonicStorage 
 * @setStorage              guarda un unico objeto al storage
 * @getStorage              obtiene el objeto guardado con @setStorage
 * @setStorageArray         guarda el array de objetos en el storage
 * @getStorageArray         obtiene el array de objetos guardado con @setStorageArray
 * @removeStorageArray      elimina el array de objetos guardado con @setStorageArray
 * @setStorageItemArray     agrega un elemento al array de objeto guardado con @setStorageArray
 * @getStorageItemArray     obtiene un elemento del array guardado con @setStorageArray buscandolo por su id 
 * @removeStorageItemArray  elimina un elemento del array guardado con @setStorageArray buscandolo por su id 
 */

import { setStorageData, getStorageData, removeStorageData, setArrayItemStorageData, getArrayItemStorageData, removeArrayItemStorageData } from "../../api/storage";
import { ICambioEstatusAsistencia, getEmptyCambioEstatusAsistencia } from "../../interfaces/nomina";

export const setStorageCambioEstatusAsistencia = async (ICambioEstatusAsistencia : ICambioEstatusAsistencia) => await setStorageData('ICambioEstatusAsistencia', ICambioEstatusAsistencia);
export const getStorageCambioEstatusAsistencia = async () : Promise<ICambioEstatusAsistencia> => await getStorageData('ICambioEstatusAsistencia', getEmptyCambioEstatusAsistencia());
export const removeStorageCambioEstatusAsistencia = async () => await removeStorageData('ICambioEstatusAsistencia');
export const setStorageCambioEstatusAsistenciaArray = async (ICambioEstatusAsistencia : ICambioEstatusAsistencia[]) => await setStorageData('ICambioEstatusAsistenciaArray', ICambioEstatusAsistencia);
export const getStorageCambioEstatusAsistenciaArray = async () : Promise<ICambioEstatusAsistencia[]> => await getStorageData('ICambioEstatusAsistenciaArray', [] as ICambioEstatusAsistencia[]);
export const removeStorageCambioEstatusAsistenciaArray = async () => await removeStorageData('ICambioEstatusAsistenciaArray');
export const setStorageCambioEstatusAsistenciaArrayItem = async (id: number, ICambioEstatusAsistencia : ICambioEstatusAsistencia) : Promise<ICambioEstatusAsistencia[]> => await setArrayItemStorageData('ICambioEstatusAsistenciaArray', id, ICambioEstatusAsistencia, [] as ICambioEstatusAsistencia[]);
export const getStorageCambioEstatusAsistenciaArrayItem = async (id: number) : Promise<ICambioEstatusAsistencia> => await getArrayItemStorageData('ICambioEstatusAsistenciaArray', id, getEmptyCambioEstatusAsistencia);
export const removeStorageCambioEstatusAsistenciaArrayItem = async (id: number) : Promise<ICambioEstatusAsistencia[]> => await removeArrayItemStorageData('ICambioEstatusAsistenciaArray', id, [] as ICambioEstatusAsistencia[]);
