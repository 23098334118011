import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { List, setListItem } from "../../interfaces/helpers";
import { ISolicitudCambioTelefonoAutorizado } from "../../interfaces/personas";
import { RootState } from "../../store";
import { getNombreEmpleado } from "../personasSlice";

const POR_APROVAR = 1;

export interface IRequests {
  selectedEmpresa: List;
  empleadosList: List[];
}

export const requestsInitialState: IRequests = {
  selectedEmpresa: { id: "", title: "Todas las empresas" },
  empleadosList: [],
};

export const requestsSlice = createSlice({
  name: "requests",
  initialState: requestsInitialState,
  reducers: {
    setRequestsSelectedEmpresa: (state, action: PayloadAction<List>) => {
      state.selectedEmpresa = action.payload;
    },
  },
});

export const {
  setRequestsSelectedEmpresa,
  // Modal
} = requestsSlice.actions;

export default requestsSlice.reducer;

export const getMemoizedRequestsActiveEmpleado = createSelector(
  (state: RootState) => state.requests.selectedEmpresa,
  (state: RootState) => state.personas.personasState.empleado.data_array,
  (selectedEmpresa, empleados) => {
    if (selectedEmpresa.id === "") return empleados.filter((e) => e.activo);
    const empresa = Number(selectedEmpresa.id);
    return empleados.filter((e) => e.empresa.id === empresa && e.activo);
  }
);

export const getMemoizedRequestsActiveEmpleadoAsSelectList = createSelector(
  getMemoizedRequestsActiveEmpleado,
  (empleados) => {
    return empleados.map((e) =>
      setListItem({
        id: e.id.toString(),
        title: getNombreEmpleado(e),
      })
    );
  }
);

export const getMemoizedRequestsVacationsByEmpresa = createSelector(
  getMemoizedRequestsActiveEmpleado,
  (state: RootState) => state.nomina.nominaState.vacaciones.data_array,
  (empleados, vacaciones) => {
    return vacaciones.filter(
      (e) =>
        empleados.some((v) => v.id === e.empleado.id) &&
        e.estatus_vacaciones.id === POR_APROVAR
    );
  }
);

export const getMemoizedRequestsDisabilitiesByEmpresa = createSelector(
  getMemoizedRequestsActiveEmpleado,
  (state: RootState) => state.nomina.nominaState.incapacidad.data_array,
  (empleados, incapacidad) => {
    return incapacidad.filter(
      (e) =>
        empleados.some((v) => v.id === e.empleado.id) &&
        e.estatus_incapacidad.id === POR_APROVAR
    );
  }
);

export const getMemoizedRequestsDeviceChangeByEmpresa = createSelector(
  getMemoizedRequestsActiveEmpleado,
  (state: RootState) =>
    state.personas.personasState.solicitudCambioTelefonoAutorizado.data_array,
  (empleados, solicitudCambio) => {
    return solicitudCambio.filter(
      (solicitud: ISolicitudCambioTelefonoAutorizado) =>
        empleados.some((empleado) => empleado.id === solicitud.empleado.id) &&
        solicitud.estatus.id === POR_APROVAR
    );
  }
);
