/**
 * Métodos para el manejo de IonicStorage 
 * @setStorage              guarda un unico objeto al storage
 * @getStorage              obtiene el objeto guardado con @setStorage
 * @setStorageArray         guarda el array de objetos en el storage
 * @getStorageArray         obtiene el array de objetos guardado con @setStorageArray
 * @removeStorageArray      elimina el array de objetos guardado con @setStorageArray
 * @setStorageItemArray     agrega un elemento al array de objeto guardado con @setStorageArray
 * @getStorageItemArray     obtiene un elemento del array guardado con @setStorageArray buscandolo por su id 
 * @removeStorageItemArray  elimina un elemento del array guardado con @setStorageArray buscandolo por su id 
 */

import { setStorageData, getStorageData, removeStorageData, setArrayItemStorageData, getArrayItemStorageData, removeArrayItemStorageData } from "../../api/storage";
import { IPagoSuscripcion, getEmptyPagoSuscripcion } from "../../interfaces/personas";

export const setStoragePagoSuscripcion = async (IPagoSuscripcion : IPagoSuscripcion) => await setStorageData('IPagoSuscripcion', IPagoSuscripcion);
export const getStoragePagoSuscripcion = async () : Promise<IPagoSuscripcion> => await getStorageData('IPagoSuscripcion', getEmptyPagoSuscripcion());
export const removeStoragePagoSuscripcion = async () => await removeStorageData('IPagoSuscripcion');
export const setStoragePagoSuscripcionArray = async (IPagoSuscripcion : IPagoSuscripcion[]) => await setStorageData('IPagoSuscripcionArray', IPagoSuscripcion);
export const getStoragePagoSuscripcionArray = async () : Promise<IPagoSuscripcion[]> => await getStorageData('IPagoSuscripcionArray', [] as IPagoSuscripcion[]);
export const removeStoragePagoSuscripcionArray = async () => await removeStorageData('IPagoSuscripcionArray');
export const setStoragePagoSuscripcionArrayItem = async (id: number, IPagoSuscripcion : IPagoSuscripcion) : Promise<IPagoSuscripcion[]> => await setArrayItemStorageData('IPagoSuscripcionArray', id, IPagoSuscripcion, [] as IPagoSuscripcion[]);
export const getStoragePagoSuscripcionArrayItem = async (id: number) : Promise<IPagoSuscripcion> => await getArrayItemStorageData('IPagoSuscripcionArray', id, getEmptyPagoSuscripcion);
export const removeStoragePagoSuscripcionArrayItem = async (id: number) : Promise<IPagoSuscripcion[]> => await removeArrayItemStorageData('IPagoSuscripcionArray', id, [] as IPagoSuscripcion[]);
