import { createSelector, createSlice } from "@reduxjs/toolkit";
import { setListItem } from "../interfaces/helpers";
import { getEmptyStatusAsistencia, getEmptyStatusCambioEstatusAsistencia, getEmptyStatusCambioEstatusIncapacidad, getEmptyStatusCambioEstatusVacaciones, getEmptyStatusHorario, getEmptyStatusHorasExtra, getEmptyStatusIncapacidad, getEmptyStatusIncidencia, getEmptyStatusMvEstatusAsistencia, getEmptyStatusMvEstatusIncapacidades, getEmptyStatusMvEstatusVacaciones, getEmptyStatusMvMotivoIncapacidad, getEmptyStatusMvMotivoNotificacion, getEmptyStatusMvMotivoVacaciones, getEmptyStatusMvRiesgoPuesto, getEmptyStatusMvTipoAsistencia, getEmptyStatusMvTipoContrato, getEmptyStatusMvTipoHorasExtra, getEmptyStatusMvTipoJornada, getEmptyStatusMvTipoMovimientoAsistencia, getEmptyStatusMvTipoNotificacion, getEmptyStatusNotificaciones, getEmptyStatusPuesto, getEmptyStatusTabuladorHorasExtra, getEmptyStatusTurno, getEmptyStatusVacaciones } from "../interfaces/nomina";
import { nominaReducers } from "../reducers/nomina";
import { RootState } from "../store";

export const nominaInitialState = {
  nominaState: {
    asistencia: getEmptyStatusAsistencia(),
    cambioEstatusAsistencia: getEmptyStatusCambioEstatusAsistencia(),
    cambioEstatusIncapacidad: getEmptyStatusCambioEstatusIncapacidad(),
    cambioEstatusVacaciones: getEmptyStatusCambioEstatusVacaciones(),
    horario: getEmptyStatusHorario(),
    horasExtra: getEmptyStatusHorasExtra(),
    incapacidad: getEmptyStatusIncapacidad(),
    incidencia: getEmptyStatusIncidencia(),
    mvEstatusAsistencia: getEmptyStatusMvEstatusAsistencia(),
    mvEstatusIncapacidades: getEmptyStatusMvEstatusIncapacidades(),
    mvEstatusVacaciones: getEmptyStatusMvEstatusVacaciones(),
    mvMotivoIncapacidad: getEmptyStatusMvMotivoIncapacidad(),
    mvMotivoNotificacion: getEmptyStatusMvMotivoNotificacion(),
    mvMotivoVacaciones: getEmptyStatusMvMotivoVacaciones(),
    mvRiesgoPuesto: getEmptyStatusMvRiesgoPuesto(),
    mvTipoAsistencia: getEmptyStatusMvTipoAsistencia(),
    mvTipoContrato: getEmptyStatusMvTipoContrato(),
    mvTipoHorasExtra: getEmptyStatusMvTipoHorasExtra(),
    mvTipoJornada: getEmptyStatusMvTipoJornada(),
    mvTipoMovimientoAsistencia: getEmptyStatusMvTipoMovimientoAsistencia(),
    mvTipoNotificacion: getEmptyStatusMvTipoNotificacion(),
    notificaciones: getEmptyStatusNotificaciones(),
    puesto: getEmptyStatusPuesto(),
    tabuladorHorasExtra: getEmptyStatusTabuladorHorasExtra(),
    turno: getEmptyStatusTurno(),
    vacaciones: getEmptyStatusVacaciones(),
  },
}

export const nominaSlice = createSlice({
  name: 'nomina',
  initialState: nominaInitialState,
  reducers: {
  },
  extraReducers: nominaReducers,
});


export default nominaSlice.reducer;

export const getGlobalMemoizedMvTipoAsistenciaAsSelectList = createSelector(
  (state : RootState) => state.nomina.nominaState.mvTipoAsistencia.data_array,
  (mvTipoAsistencia) => {
    const items = mvTipoAsistencia.map((e) => setListItem({id: e.id.toString(), title: e.dato}));
    items.sort((a, b) => {
      return (a.title > b.title) ? 1 : ((a.title < b.title) ? -1 : 0);
    });
    items.push(setListItem({id: "", title: "Todos los registros"}));
    return items;
  }
);

export const getGlobalMemoizedMvTipoMovimientoAsistenciaAsSelectList = createSelector(
  (state : RootState) => state.nomina.nominaState.mvTipoMovimientoAsistencia.data_array,
  (mvTipoMovimientoAsistencia) => {
    const items = mvTipoMovimientoAsistencia.map((e) => setListItem({id: e.id.toString(), title: e.dato}));
    items.sort((a, b) => {
      return (a.title > b.title) ? 1 : ((a.title < b.title) ? -1 : 0);
    });
    items.push(setListItem({id: "", title: "Todos los movimientos"}));
    return items;
  }
);

export const getGlobalMemoizedMvEstatusVacacionesAsSelectList = createSelector(
  (state : RootState) => state.nomina.nominaState.mvEstatusVacaciones.data_array,
  (mvEstatusVacaciones) => {
    const items = mvEstatusVacaciones.map((e) => setListItem({id: e.id.toString(), title: e.dato}));
    items.sort((a, b) => {
      return (a.title > b.title) ? 1 : ((a.title < b.title) ? -1 : 0);
    });
    items.push(setListItem({id: "", title: "Todos los estatus"}));
    return items;
  }
);

export const getGlobalMemoizedMvEstatusIncapacidadesAsSelectList = createSelector(
  (state : RootState) => state.nomina.nominaState.mvEstatusIncapacidades.data_array,
  (mvEstatusIncapacidades) => {
    const items = mvEstatusIncapacidades.map((e) => setListItem({id: e.id.toString(), title: e.dato}));
    items.sort((a, b) => {
      return (a.title > b.title) ? 1 : ((a.title < b.title) ? -1 : 0);
    });
    items.push(setListItem({id: "", title: "Todos los estatus"}));
    return items;
  }
);