/**
 * Reducers creados para obtener asíncronamente 
 * los datos de los modelos y dependiendo del resultado
 * de la peticón actualice el state en redux o mande un mensaje de error
 */

import { PayloadAction } from "@reduxjs/toolkit";
import { getCheckpointRondinAsyncThunk, getAllCheckpointRondinAsyncThunk, updateCheckpointRondinAsyncThunk, createCheckpointRondinAsyncThunk, deleteCheckpointRondinAsyncThunk } from "../../asyncThunk/rondines";
import { DEFAULT_STATE } from "../../enums/default_state";
import { IStatusCheckpointRondin } from "../../interfaces/rondines";
import { rondinesInitialState } from "../../slices/rondinesSlice";

// Reducers: CheckpointRondin
export const createCheckpointRondinReducers = () => {
  const get = () => {
    var { pending, fulfilled, rejected } = getCheckpointRondinAsyncThunk;
    return {
      [pending.type]: (state : typeof rondinesInitialState) => {
        state.rondinesState.checkpointRondin.estado = DEFAULT_STATE.loading;
      },
      [fulfilled.type]: (state : typeof rondinesInitialState, action : PayloadAction<IStatusCheckpointRondin>) => {
        const result = action.payload;
        result.expiry_array = state.rondinesState.checkpointRondin.expiry_array;
        if (result.estado === DEFAULT_STATE.error) result.data_array = state.rondinesState.checkpointRondin.data_array;
        state.rondinesState.checkpointRondin = result;
      },
      [rejected.type]: (state : typeof rondinesInitialState, action : PayloadAction<IStatusCheckpointRondin>) => {
        state.rondinesState.checkpointRondin = {
          ...state.rondinesState.checkpointRondin,
          estado: action.payload.estado,
          mensaje: "Ocurrió un problema al realizar la solicitud",
        };
      },
    };
  };
  
  const getAll = () => {
    var { pending, fulfilled, rejected } = getAllCheckpointRondinAsyncThunk;
    return {
      [pending.type]: (state : typeof rondinesInitialState) => {
        state.rondinesState.checkpointRondin.estado = DEFAULT_STATE.loading;
      },
      [fulfilled.type]: (state : typeof rondinesInitialState, action : PayloadAction<IStatusCheckpointRondin>) => {
        const result = action.payload;
        result.data = state.rondinesState.checkpointRondin.data;
        result.expiry = state.rondinesState.checkpointRondin.expiry;
        if (result.estado === DEFAULT_STATE.error) result.data_array = state.rondinesState.checkpointRondin.data_array;
        state.rondinesState.checkpointRondin = result;
      },
      [rejected.type]: (state : typeof rondinesInitialState, action : PayloadAction<IStatusCheckpointRondin>) => {
        state.rondinesState.checkpointRondin = {
          ...state.rondinesState.checkpointRondin,
          estado: action.payload.estado,
          mensaje: "Ocurrió un problema al realizar la solicitud",
        };
      },
    };
  };
  
  const update = () => {
    var { pending, fulfilled, rejected } = updateCheckpointRondinAsyncThunk;
    return {
      [pending.type]: (state : typeof rondinesInitialState) => {
        state.rondinesState.checkpointRondin.estado = DEFAULT_STATE.loading;
      },
      [fulfilled.type]: (state : typeof rondinesInitialState, action : PayloadAction<IStatusCheckpointRondin>) => {
        const result: IStatusCheckpointRondin = action.payload;
        result.expiry = state.rondinesState.checkpointRondin.expiry;
        result.expiry_array = state.rondinesState.checkpointRondin.expiry_array;
        if (result.estado === DEFAULT_STATE.error) result.data_array = state.rondinesState.checkpointRondin.data_array;
        state.rondinesState.checkpointRondin = result;
      },
      [rejected.type]: (state : typeof rondinesInitialState, action : PayloadAction<IStatusCheckpointRondin>) => {
        state.rondinesState.checkpointRondin = {
          ...state.rondinesState.checkpointRondin,
          estado: action.payload.estado,
          mensaje: "Ocurrió un problema al realizar la solicitud",
        };
      },
    };
  };

  const create = () => {
    var { pending, fulfilled, rejected } = createCheckpointRondinAsyncThunk;
    return {
      [pending.type]: (state : typeof rondinesInitialState) => {
        state.rondinesState.checkpointRondin.estado = DEFAULT_STATE.loading;
      },
      [fulfilled.type]: (state : typeof rondinesInitialState, action : PayloadAction<IStatusCheckpointRondin>) => {
        const result = action.payload;
        result.expiry = state.rondinesState.checkpointRondin.expiry;
        result.expiry_array = state.rondinesState.checkpointRondin.expiry_array;
        if (result.estado === DEFAULT_STATE.error) result.data_array = state.rondinesState.checkpointRondin.data_array;
        state.rondinesState.checkpointRondin = result;
      },
      [rejected.type]: (state : typeof rondinesInitialState, action : PayloadAction<IStatusCheckpointRondin>) => {
        state.rondinesState.checkpointRondin = {
          ...state.rondinesState.checkpointRondin,
          estado: action.payload.estado,
          mensaje: "Ocurrió un problema al realizar la solicitud",
        };
      },
    };
  };
  
  const del = () => {
    var { pending, fulfilled, rejected } = deleteCheckpointRondinAsyncThunk;
    return {
      [pending.type]: (state : typeof rondinesInitialState) => {
        state.rondinesState.checkpointRondin.estado = DEFAULT_STATE.loading;
      },
      [fulfilled.type]: (state : typeof rondinesInitialState, action : PayloadAction<IStatusCheckpointRondin>) => {
        const result: IStatusCheckpointRondin = action.payload;
        result.expiry = state.rondinesState.checkpointRondin.expiry;
        result.expiry_array = state.rondinesState.checkpointRondin.expiry_array;
        if (result.estado === DEFAULT_STATE.error) result.data_array = state.rondinesState.checkpointRondin.data_array;
        state.rondinesState.checkpointRondin = result;
      },
      [rejected.type]: (state : typeof rondinesInitialState, action : PayloadAction<IStatusCheckpointRondin>) => {
        state.rondinesState.checkpointRondin = {
          ...state.rondinesState.checkpointRondin,
          estado: action.payload.estado,
          mensaje: "Ocurrió un problema al realizar la solicitud",
        };
      },
    };
  };

  return {
    ...get(),
    ...getAll(),
    ...update(),
    ...create(),
    ...del(),
  };
};
