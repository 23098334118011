/**
 * Métodos AsyncThunk para ser usados en los reducers y
 * los resultados a las llamadas http puedan ser usados para
 * actualizar el state en redux
 */

import { createAsyncThunk } from "@reduxjs/toolkit";
import { DEFAULT_STATE } from "../../enums/default_state";
import { getIncapacidad, getAllIncapacidad, updateIncapacidad, createIncapacidad, deleteIncapacidad } from "../../http/nomina";
import { getEmptyStatusIncapacidad, IIncapacidad } from "../../interfaces/nomina";
import { IServerDate } from "../../interfaces/time";

export const getIncapacidadAsyncThunk = createAsyncThunk(
  'nomina/getIncapacidadAsyncThunk',
  async (query : {serverDate : IServerDate, id: number, idCliente : number}) => await getIncapacidad(query.serverDate, query.id, query.idCliente)
    .then(async (result) => result)
    .catch(async (error) => getEmptyStatusIncapacidad(DEFAULT_STATE.error))  
);

export const getAllIncapacidadAsyncThunk = createAsyncThunk(
  'nomina/getAllIncapacidadAsyncThunk',
  async (queryAll : {serverDate : IServerDate, idCliente : number}) => await getAllIncapacidad(queryAll.serverDate, queryAll.idCliente)
    .then(async (result) => result)
    .catch(async (error) => getEmptyStatusIncapacidad(DEFAULT_STATE.error))  
);

export const updateIncapacidadAsyncThunk = createAsyncThunk(
  'nomina/updateIncapacidadAsyncThunk',
  async (update: {serverDate : IServerDate, id: number, idCliente : number, object: IIncapacidad}) => await updateIncapacidad(update.serverDate, update.id, update.idCliente, update.object)
    .then(async (result) => result)
    .catch(async (error) => getEmptyStatusIncapacidad(DEFAULT_STATE.error))  
);

export const createIncapacidadAsyncThunk = createAsyncThunk(
  'nomina/createIncapacidadAsyncThunk',
  async (create : {serverDate : IServerDate, idCliente : number, object: IIncapacidad}) => await createIncapacidad(create.serverDate, create.idCliente, create.object)
    .then(async (result) => result)
    .catch(async (error) => getEmptyStatusIncapacidad(DEFAULT_STATE.error))  
);

export const deleteIncapacidadAsyncThunk = createAsyncThunk(
  'nomina/deleteIncapacidadAsyncThunk',
  async (del : {id : number, idCliente : number}) => await deleteIncapacidad(del.id, del.idCliente)
    .then(async (result) => result)
    .catch(async (error) => getEmptyStatusIncapacidad())  
);
