/**
 * Métodos AsyncThunk para ser usados en los reducers y
 * los resultados a las llamadas http puedan ser usados para
 * actualizar el state en redux
 */

import { createAsyncThunk } from "@reduxjs/toolkit";
import { DEFAULT_STATE } from "../../enums/default_state";
import { getGeolocalizacion, getAllGeolocalizacion, updateGeolocalizacion, createGeolocalizacion, deleteGeolocalizacion } from "../../http/personas";
import { getEmptyStatusGeolocalizacion, IGeolocalizacion } from "../../interfaces/personas";
import { IServerDate } from "../../interfaces/time";

export const getGeolocalizacionAsyncThunk = createAsyncThunk(
  'personas/getGeolocalizacionAsyncThunk',
  async (query : {serverDate : IServerDate, id: number, idCliente : number}) => await getGeolocalizacion(query.serverDate, query.id, query.idCliente)
    .then(async (result) => result)
    .catch(async (error) => getEmptyStatusGeolocalizacion(DEFAULT_STATE.error))  
);

export const getAllGeolocalizacionAsyncThunk = createAsyncThunk(
  'personas/getAllGeolocalizacionAsyncThunk',
  async (queryAll : {serverDate : IServerDate, idCliente : number}) => await getAllGeolocalizacion(queryAll.serverDate, queryAll.idCliente)
    .then(async (result) => result)
    .catch(async (error) => getEmptyStatusGeolocalizacion(DEFAULT_STATE.error))  
);

export const updateGeolocalizacionAsyncThunk = createAsyncThunk(
  'personas/updateGeolocalizacionAsyncThunk',
  async (update: {serverDate : IServerDate, id: number, idCliente : number, object: IGeolocalizacion}) => await updateGeolocalizacion(update.serverDate, update.id, update.idCliente, update.object)
    .then(async (result) => result)
    .catch(async (error) => getEmptyStatusGeolocalizacion(DEFAULT_STATE.error))  
);

export const createGeolocalizacionAsyncThunk = createAsyncThunk(
  'personas/createGeolocalizacionAsyncThunk',
  async (create : {serverDate : IServerDate, idCliente : number, object: IGeolocalizacion}) => await createGeolocalizacion(create.serverDate, create.idCliente, create.object)
    .then(async (result) => result)
    .catch(async (error) => getEmptyStatusGeolocalizacion(DEFAULT_STATE.error))  
);

export const deleteGeolocalizacionAsyncThunk = createAsyncThunk(
  'personas/deleteGeolocalizacionAsyncThunk',
  async (del : {id : number, idCliente : number}) => await deleteGeolocalizacion(del.id, del.idCliente)
    .then(async (result) => result)
    .catch(async (error) => getEmptyStatusGeolocalizacion())  
);
