/** 
 * Componente que se encarga del elemento MvTipoUsuario
 * @IMvTipoUsuario es la interfaz que contiene los parámetos el elemento MvTipoUsuario
 * @getEmptyMvTipoUsuario  devuelve un objeto con los parámetros por defecto; @param {id}, opcional cuando no encuentra el objeto en el LocalSotrage
 * @IStatusMvTipoUsuario es la interfaz para los estatus a llamadas a la API; @param {status}, opcional cuando queremos otro estado de inizialización de tipo [DEFAULT_STATE]
 * @getEmptyStatusMvTipoUsuario devuelve un objeto con los parámetros por defecto,
 * @setMvTipoUsuario devuelve un objeto de tipo [IMvTipoUsuario] recibiendo @param {object} y @param {serverDate}, para verificar que los datos estén en el formato correcto y ajustarlos a la zona horaria actual
 * @getSerializableMvTipoUsuario recibe un @param {IMvTipoUsuario}, para devolver un objeto serizalizable para las peticiones de la API
 * */

import { getDateString, isNotEmpty, SERVER_TIME_ZONE } from "../../api/helpers";
import { DEFAULT_STATE } from "../../enums/default_state";
import { convertDateToZonaHoraria, IServerDate } from "../time";
import { getEmptyLocalStorageExpiry, ILocalStorageExpiry } from "../time/ILocalStorageExpiry";

export interface IMvTipoUsuario {
  id : number,
  dato : string,
  fecha_elaboracion : string, // Fecha
  estatus_sistema : boolean,
  ultima_actualizacion : string, // Fecha
}

export const getEmptyMvTipoUsuario = (id? : number) => {
  const temp : IMvTipoUsuario = 
  {
    id : id ? id : 0,
    dato : "",
    fecha_elaboracion : "",
    estatus_sistema : false,
    ultima_actualizacion : "",
  };
  return temp;
}

export interface IStatusMvTipoUsuario {
  estado : DEFAULT_STATE,
  mensaje : string,
  data : IMvTipoUsuario,
  data_array: IMvTipoUsuario[],
  expiry : ILocalStorageExpiry,
  expiry_array : ILocalStorageExpiry,
}

export const getEmptyStatusMvTipoUsuario = (status: DEFAULT_STATE = DEFAULT_STATE.loading) => {
  const temp : IStatusMvTipoUsuario = 
  {
    estado : status,
    mensaje : "",
    data : getEmptyMvTipoUsuario(),
    data_array : [],
    expiry : getEmptyLocalStorageExpiry("IMvTipoUsuario", 0),
    expiry_array : getEmptyLocalStorageExpiry("IMvTipoUsuarioArray", 0),
  };
  return temp;
}

export const setMvTipoUsuario = async (
  object : any,
  serverDate : IServerDate,
) => {
  const temp = getEmptyMvTipoUsuario();
  if (isNotEmpty(object.id)) temp.id = object.id;
  if (isNotEmpty(object.dato)) temp.dato = object.dato;
  if (isNotEmpty(object.fecha_elaboracion)) temp.fecha_elaboracion = convertDateToZonaHoraria(serverDate, object.fecha_elaboracion);
  if (isNotEmpty(object.estatus_sistema)) temp.estatus_sistema = object.estatus_sistema;
  if (isNotEmpty(object.ultima_actualizacion)) temp.ultima_actualizacion = convertDateToZonaHoraria(serverDate, object.ultima_actualizacion);
  return temp;
}

export const getSerializableMvTipoUsuario = (IMvTipoUsuario : IMvTipoUsuario ) => {
  return {
    id : IMvTipoUsuario.id,
    dato : IMvTipoUsuario.dato,
    fecha_elaboracion : IMvTipoUsuario.fecha_elaboracion,
    estatus_sistema : IMvTipoUsuario.estatus_sistema,
    ultima_actualizacion : getDateString(SERVER_TIME_ZONE),
  };
}
