/**
 * Es el componente utilizado en la página Perfil para la visalización de los datos del usuario
 * @function handleDismiss cierra el modal de verificación del teléfono celular
 * @function handleChangePhone modifica el valor del state del @phone
 * @function handleCaptcha guarda el valor del state del @RecaptchaVerifier
 * @function handleSave realiza la validación del teléfono celular
 * @function logout se encarga de cerrar la sesión del usuario
 */

import React, { useState } from "react";
import {
  IonGrid,
  IonRow,
  IonCol,
  IonLabel,
  IonButton,
  IonIcon,
  IonItem,
  IonList,
  IonContent,
  useIonModal,
  useIonAlert,
} from "@ionic/react";

import { personSharp, phonePortraitSharp, mailSharp } from "ionicons/icons";

import Card from "../components/Card";
import useRedux, { useAppDispatch } from "../hooks/useRedux";
import {
  ApplicationVerifier,
  auth as firebaseAuth,
  PhoneAuthProvider,
  RecaptchaVerifier,
  updatePhoneNumber,
} from "../firebase/config";
import PhoneVerifier from "../components/PhoneVerifier";
import { signOut } from "../redux/asyncThunk/auth";
import { getNombreEmpleado } from "../redux/slices/personasSlice";

const Profile: React.FC = () => {
  const dispatch = useAppDispatch();
  const [presentAlert, dissmissAlert] = useIonAlert();
  const [{ auth }] = useRedux();
  let [captcha, setCaptcha] = useState<RecaptchaVerifier>();
  let [captchaId, setCaptchaId] = useState<number>();
  let [phone, setPhone] = useState<string>("");

  function handleDismiss() {
    dismissModal();
  }

  function handleChangePhone(data: string) {
    setPhone(data);
  }
  function handleCaptcha(data: RecaptchaVerifier) {
    if (data) {
      setCaptcha(data);
    }
  }

  function handleCaptchaId(data: number) {
    if (data) {
      setCaptchaId(data);
    }
  }

  async function handleSave() {
    if (phone && firebaseAuth.currentUser && captcha) {
      console.log("phone", phone);
      if (captcha) {
        const phoneProvider = new PhoneAuthProvider(firebaseAuth);
        const id = await phoneProvider.verifyPhoneNumber(
          phone,
          captcha as ApplicationVerifier
        );
        const code = window.prompt(
          "Por favor ingrese el código que ha enviado"
        );
        const cred = PhoneAuthProvider.credential(id, code!);
        await updatePhoneNumber(firebaseAuth.currentUser, cred);
        console.log("phone number changed", id, cred, firebaseAuth.currentUser);
        dismissModal();
      }
    }
  }

  const [presentModal, dismissModal] = useIonModal(PhoneVerifier, {
    phone,
    onDismiss: handleDismiss,
    onChangePhone: handleChangePhone,
    onSave: handleSave,
    onCaptcha: handleCaptcha,
    onCaptchaId: handleCaptchaId,
  });

  const logout = () => {
    presentAlert({
      header: `¿Está seguro de que quiere cerrar su sesión?`,
      buttons: [
        {
          text: "Aceptar",
          handler() {
            dispatch(signOut(auth));
          },
        },
        {
          text: "Cancelar",
          role: "cancel",
        },
      ],
    });
  };

  return (
    <IonContent class="inner-content">
      <IonGrid>
        <IonRow>
          <IonCol size="12" sizeMd="12" sizeLg="12">
            <Card
              img="../assets/img/logosingle.png"
              title={
                <IonRow>
                  <IonCol className="ion-text-center">
                    <IonLabel>
                      <h1>Cliente</h1>
                      <h2 color="primary">{`${getNombreEmpleado(
                        auth.empleado
                      )}`}</h2>
                    </IonLabel>
                  </IonCol>
                </IonRow>
              }
            />
          </IonCol>
          <IonCol size="12" sizeMd="12" sizeLg="12">
            <Card
              icon={personSharp}
              title={<IonLabel>Datos de la cuenta</IonLabel>}
              content={
                <IonGrid>
                  <IonRow>
                    <IonCol size="12">
                      <IonList lines="none">
                        <IonItem>
                          <IonIcon
                            color="primary"
                            icon={personSharp}
                            slot="start"
                          />
                          <IonLabel>{`${getNombreEmpleado(
                            auth.empleado
                          )}`}</IonLabel>
                        </IonItem>
                        <IonItem>
                          <IonIcon
                            color="primary"
                            icon={mailSharp}
                            slot="start"
                          />
                          <IonLabel>{auth.user!.email}</IonLabel>
                        </IonItem>
                        <IonItem>
                          <IonIcon
                            color="primary"
                            icon={phonePortraitSharp}
                            slot="start"
                          />
                          <IonLabel>
                            {auth.empleado.datos_personales.telefono_movil}
                          </IonLabel>
                          <IonButton
                            color="warning"
                            slot="end"
                            onClick={() => {
                              presentModal({
                                cssClass: "phone-modal",
                              });
                            }}
                          >
                            <IonIcon
                              slot="icon-only"
                              icon={phonePortraitSharp}
                            />
                          </IonButton>
                        </IonItem>
                      </IonList>
                    </IonCol>
                  </IonRow>
                  <IonRow className="no-padding">
                    <IonCol size="12" sizeXl="12">
                      <IonButton routerLink="Faq" expand="block">
                        FAQ Preguntas frecuentes
                      </IonButton>
                    </IonCol>
                    <IonCol size="12" sizeXl="6">
                      <IonButton expand="block" color="primary">
                        <IonLabel color="secondary">
                          Generar nueva contraseña
                        </IonLabel>
                      </IonButton>
                    </IonCol>
                    <IonCol size="12" sizeXl="6">
                      <IonButton
                        expand="block"
                        color="danger"
                        onClick={() => logout()}
                      >
                        <IonLabel color="secondary">Cerrar sesión</IonLabel>
                      </IonButton>
                    </IonCol>
                  </IonRow>
                </IonGrid>
              }
            />
          </IonCol>
        </IonRow>
      </IonGrid>
    </IonContent>
  );
};

export default Profile;
