/**
 * Métodos AsyncThunk para ser usados en los reducers y
 * los resultados a las llamadas http puedan ser usados para
 * actualizar el state en redux
 */

import { createAsyncThunk } from "@reduxjs/toolkit";
import { DEFAULT_STATE } from "../../enums/default_state";
import { getMvEstatusIncapacidades, getAllMvEstatusIncapacidades, updateMvEstatusIncapacidades, createMvEstatusIncapacidades, deleteMvEstatusIncapacidades } from "../../http/nomina";
import { getEmptyStatusMvEstatusIncapacidades, IMvEstatusIncapacidades } from "../../interfaces/nomina";
import { IServerDate } from "../../interfaces/time";

export const getMvEstatusIncapacidadesAsyncThunk = createAsyncThunk(
  'nomina/getMvEstatusIncapacidadesAsyncThunk',
  async (query : {serverDate : IServerDate, id: number}) => await getMvEstatusIncapacidades(query.serverDate, query.id)
    .then(async (result) => result)
    .catch(async (error) => getEmptyStatusMvEstatusIncapacidades(DEFAULT_STATE.error))  
);

export const getAllMvEstatusIncapacidadesAsyncThunk = createAsyncThunk(
  'nomina/getAllMvEstatusIncapacidadesAsyncThunk',
  async (queryAll : {serverDate : IServerDate}) => await getAllMvEstatusIncapacidades(queryAll.serverDate)
    .then(async (result) => result)
    .catch(async (error) => getEmptyStatusMvEstatusIncapacidades(DEFAULT_STATE.error))  
);

export const updateMvEstatusIncapacidadesAsyncThunk = createAsyncThunk(
  'nomina/updateMvEstatusIncapacidadesAsyncThunk',
  async (update: {serverDate : IServerDate, id: number, MvEstatusIncapacidades: IMvEstatusIncapacidades}) => await updateMvEstatusIncapacidades(update.serverDate, update.id, update.MvEstatusIncapacidades)
    .then(async (result) => result)
    .catch(async (error) => getEmptyStatusMvEstatusIncapacidades(DEFAULT_STATE.error))  
);

export const createMvEstatusIncapacidadesAsyncThunk = createAsyncThunk(
  'nomina/createMvEstatusIncapacidadesAsyncThunk',
  async (create : {serverDate : IServerDate, MvEstatusIncapacidades: IMvEstatusIncapacidades}) => await createMvEstatusIncapacidades(create.serverDate, create.MvEstatusIncapacidades)
    .then(async (result) => result)
    .catch(async (error) => getEmptyStatusMvEstatusIncapacidades(DEFAULT_STATE.error))  
);

export const deleteMvEstatusIncapacidadesAsyncThunk = createAsyncThunk(
  'nomina/deleteMvEstatusIncapacidadesAsyncThunk',
  async (del : {id : number}) => await deleteMvEstatusIncapacidades(del.id)
    .then(async (result) => result)
    .catch(async (error) => getEmptyStatusMvEstatusIncapacidades())  
);
