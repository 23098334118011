/**
 * Reducers creados para obtener asíncronamente 
 * los datos de los modelos y dependiendo del resultado
 * de la peticón actualice el state en redux o mande un mensaje de error
 */

import { PayloadAction } from "@reduxjs/toolkit";
import { activateCentroDeTrabajoAsyncThunk, activateEmpleadoAsyncThunk, createMultipleCentroDeTrabajoAsyncThunk, createMultipleEmpleadoAsyncThunk, deactivateCentroDeTrabajoAsyncThunk, deactivateEmpleadoAsyncThunk, deleteMultipleCentroDeTrabajoAsyncThunk, deleteMultipleEmpleadoAsyncThunk } from "../../asyncThunk/personas";
import { DEFAULT_STATE } from "../../enums/default_state";
import { IStatusCentroDeTrabajo, IStatusEmpleado } from "../../interfaces/personas";
import { personasInitialState } from "../../slices/personasSlice";

// Reducers: helpers
export const createHelpersReducers = () => {
  
  const deactivateEmpleado = () => {
    var { pending, fulfilled, rejected } = deactivateEmpleadoAsyncThunk;
    return {
      [pending.type]: (state : typeof personasInitialState) => {
        state.personasState.empleado.estado = DEFAULT_STATE.loading;
      },
      [fulfilled.type]: (state : typeof personasInitialState, action : PayloadAction<IStatusEmpleado>) => {
        const result = action.payload;
        result.data = state.personasState.empleado.data;
        result.expiry = state.personasState.empleado.expiry;
        if (result.estado === DEFAULT_STATE.error) result.data_array = state.personasState.empleado.data_array;
        state.personasState.empleado = result;
      },
      [rejected.type]: (state : typeof personasInitialState, action : PayloadAction<IStatusEmpleado>) => {
        state.personasState.empleado = {
          ...state.personasState.empleado,
          estado: action.payload.estado,
          mensaje: "Ocurrió un problema al realizar la solicitud",
        };
      },
    };
  };
  
  const activateEmpleado = () => {
    var { pending, fulfilled, rejected } = activateEmpleadoAsyncThunk;
    return {
      [pending.type]: (state : typeof personasInitialState) => {
        state.personasState.empleado.estado = DEFAULT_STATE.loading;
      },
      [fulfilled.type]: (state : typeof personasInitialState, action : PayloadAction<IStatusEmpleado>) => {
        const result = action.payload;
        result.data = state.personasState.empleado.data;
        result.expiry = state.personasState.empleado.expiry;
        if (result.estado === DEFAULT_STATE.error) result.data_array = state.personasState.empleado.data_array;
        state.personasState.empleado = result;
      },
      [rejected.type]: (state : typeof personasInitialState, action : PayloadAction<IStatusEmpleado>) => {
        state.personasState.empleado = {
          ...state.personasState.empleado,
          estado: action.payload.estado,
          mensaje: "Ocurrió un problema al realizar la solicitud",
        };
      },
    };
  };
  
  const deleteMultipleEmpleado = () => {
    var { pending, fulfilled, rejected } = deleteMultipleEmpleadoAsyncThunk;
    return {
      [pending.type]: (state : typeof personasInitialState) => {
        state.personasState.empleado.estado = DEFAULT_STATE.loading;
      },
      [fulfilled.type]: (state : typeof personasInitialState, action : PayloadAction<IStatusEmpleado>) => {
        const result = action.payload;
        result.data = state.personasState.empleado.data;
        result.expiry = state.personasState.empleado.expiry;
        if (result.estado === DEFAULT_STATE.error) result.data_array = state.personasState.empleado.data_array;
        state.personasState.empleado = result;
      },
      [rejected.type]: (state : typeof personasInitialState, action : PayloadAction<IStatusEmpleado>) => {
        state.personasState.empleado = {
          ...state.personasState.empleado,
          estado: action.payload.estado,
          mensaje: "Ocurrió un problema al realizar la solicitud",
        };
      },
    };
  };
  
  const createMultipleEmpleado = () => {
    var { pending, fulfilled, rejected } = createMultipleEmpleadoAsyncThunk;
    return {
      [pending.type]: (state : typeof personasInitialState) => {
        state.personasState.empleado.estado = DEFAULT_STATE.loading;
      },
      [fulfilled.type]: (state : typeof personasInitialState, action : PayloadAction<IStatusEmpleado>) => {
        const result = action.payload;
        result.data_array = state.personasState.empleado.data_array;
        result.expiry = state.personasState.empleado.expiry;
        if (result.estado === DEFAULT_STATE.error) result.data_array = state.personasState.empleado.data_array;
        state.personasState.empleado = result;
      },
      [rejected.type]: (state : typeof personasInitialState, action : PayloadAction<IStatusEmpleado>) => {
        state.personasState.empleado = {
          ...state.personasState.empleado,
          estado: action.payload.estado,
          mensaje: "Ocurrió un problema al realizar la solicitud",
        };
      },
    };
  };
  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  const deactivateCentroDeTrabajo = () => {
    var { pending, fulfilled, rejected } = deactivateCentroDeTrabajoAsyncThunk;
    return {
      [pending.type]: (state : typeof personasInitialState) => {
        state.personasState.centroDeTrabajo.estado = DEFAULT_STATE.loading;
      },
      [fulfilled.type]: (state : typeof personasInitialState, action : PayloadAction<IStatusCentroDeTrabajo>) => {
        const result = action.payload;
        result.data = state.personasState.centroDeTrabajo.data;
        result.expiry = state.personasState.centroDeTrabajo.expiry;
        if (result.estado === DEFAULT_STATE.error) result.data_array = state.personasState.centroDeTrabajo.data_array;
        state.personasState.centroDeTrabajo = result;
      },
      [rejected.type]: (state : typeof personasInitialState, action : PayloadAction<IStatusCentroDeTrabajo>) => {
        state.personasState.centroDeTrabajo = {
          ...state.personasState.centroDeTrabajo,
          estado: action.payload.estado,
          mensaje: "Ocurrió un problema al realizar la solicitud",
        };
      },
    };
  };
  
  const activateCentroDeTrabajo = () => {
    var { pending, fulfilled, rejected } = activateCentroDeTrabajoAsyncThunk;
    return {
      [pending.type]: (state : typeof personasInitialState) => {
        state.personasState.centroDeTrabajo.estado = DEFAULT_STATE.loading;
      },
      [fulfilled.type]: (state : typeof personasInitialState, action : PayloadAction<IStatusCentroDeTrabajo>) => {
        const result = action.payload;
        result.data = state.personasState.centroDeTrabajo.data;
        result.expiry = state.personasState.centroDeTrabajo.expiry;
        if (result.estado === DEFAULT_STATE.error) result.data_array = state.personasState.centroDeTrabajo.data_array;
        state.personasState.centroDeTrabajo = result;
      },
      [rejected.type]: (state : typeof personasInitialState, action : PayloadAction<IStatusCentroDeTrabajo>) => {
        state.personasState.centroDeTrabajo = {
          ...state.personasState.centroDeTrabajo,
          estado: action.payload.estado,
          mensaje: "Ocurrió un problema al realizar la solicitud",
        };
      },
    };
  };
  
  const deleteMultipleCentroDeTrabajo = () => {
    var { pending, fulfilled, rejected } = deleteMultipleCentroDeTrabajoAsyncThunk;
    return {
      [pending.type]: (state : typeof personasInitialState) => {
        state.personasState.centroDeTrabajo.estado = DEFAULT_STATE.loading;
      },
      [fulfilled.type]: (state : typeof personasInitialState, action : PayloadAction<IStatusCentroDeTrabajo>) => {
        const result = action.payload;
        result.data_array = state.personasState.centroDeTrabajo.data_array;
        result.expiry = state.personasState.centroDeTrabajo.expiry;
        if (result.estado === DEFAULT_STATE.error) result.data_array = state.personasState.centroDeTrabajo.data_array;
        state.personasState.centroDeTrabajo = result;
      },
      [rejected.type]: (state : typeof personasInitialState, action : PayloadAction<IStatusCentroDeTrabajo>) => {
        state.personasState.centroDeTrabajo = {
          ...state.personasState.centroDeTrabajo,
          estado: action.payload.estado,
          mensaje: "Ocurrió un problema al realizar la solicitud",
        };
      },
    };
  };
  
  const createMultipleCentroDeTrabajo = () => {
    var { pending, fulfilled, rejected } = createMultipleCentroDeTrabajoAsyncThunk;
    return {
      [pending.type]: (state : typeof personasInitialState) => {
        state.personasState.centroDeTrabajo.estado = DEFAULT_STATE.loading;
      },
      [fulfilled.type]: (state : typeof personasInitialState, action : PayloadAction<IStatusCentroDeTrabajo>) => {
        const result = action.payload;
        result.data_array = state.personasState.centroDeTrabajo.data_array;
        result.expiry = state.personasState.centroDeTrabajo.expiry;
        if (result.estado === DEFAULT_STATE.error) result.data_array = state.personasState.centroDeTrabajo.data_array;
        state.personasState.centroDeTrabajo = result;
      },
      [rejected.type]: (state : typeof personasInitialState, action : PayloadAction<IStatusCentroDeTrabajo>) => {
        state.personasState.centroDeTrabajo = {
          ...state.personasState.centroDeTrabajo,
          estado: action.payload.estado,
          mensaje: "Ocurrió un problema al realizar la solicitud",
        };
      },
    };
  };

  return {
    ...deactivateEmpleado(),
    ...activateEmpleado(),
    ...deleteMultipleEmpleado(),
    ...createMultipleEmpleado(),
    ...deactivateCentroDeTrabajo(),
    ...activateCentroDeTrabajo(),
    ...deleteMultipleCentroDeTrabajo(),
    ...createMultipleCentroDeTrabajo(),
  };
};
