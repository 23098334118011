/**
 * Métodos para el manejo de IonicStorage 
 * @setStorage              guarda un unico objeto al storage
 * @getStorage              obtiene el objeto guardado con @setStorage
 * @setStorageArray         guarda el array de objetos en el storage
 * @getStorageArray         obtiene el array de objetos guardado con @setStorageArray
 * @removeStorageArray      elimina el array de objetos guardado con @setStorageArray
 * @setStorageItemArray     agrega un elemento al array de objeto guardado con @setStorageArray
 * @getStorageItemArray     obtiene un elemento del array guardado con @setStorageArray buscandolo por su id 
 * @removeStorageItemArray  elimina un elemento del array guardado con @setStorageArray buscandolo por su id 
 */

import { setStorageData, getStorageData, removeStorageData, setArrayItemStorageData, getArrayItemStorageData, removeArrayItemStorageData } from "../../api/storage";
import { INotificaciones, getEmptyNotificaciones } from "../../interfaces/nomina";

export const setStorageNotificaciones = async (INotificaciones : INotificaciones) => await setStorageData('INotificaciones', INotificaciones);
export const getStorageNotificaciones = async () : Promise<INotificaciones> => await getStorageData('INotificaciones', getEmptyNotificaciones());
export const removeStorageNotificaciones = async () => await removeStorageData('INotificaciones');
export const setStorageNotificacionesArray = async (INotificaciones : INotificaciones[]) => await setStorageData('INotificacionesArray', INotificaciones);
export const getStorageNotificacionesArray = async () : Promise<INotificaciones[]> => await getStorageData('INotificacionesArray', [] as INotificaciones[]);
export const removeStorageNotificacionesArray = async () => await removeStorageData('INotificacionesArray');
export const setStorageNotificacionesArrayItem = async (id: number, INotificaciones : INotificaciones) : Promise<INotificaciones[]> => await setArrayItemStorageData('INotificacionesArray', id, INotificaciones, [] as INotificaciones[]);
export const getStorageNotificacionesArrayItem = async (id: number) : Promise<INotificaciones> => await getArrayItemStorageData('INotificacionesArray', id, getEmptyNotificaciones);
export const removeStorageNotificacionesArrayItem = async (id: number) : Promise<INotificaciones[]> => await removeArrayItemStorageData('INotificacionesArray', id, [] as INotificaciones[]);
