/**
 * Métodos para el manejo de IonicStorage 
 * @setStorage              guarda un unico objeto al storage
 * @getStorage              obtiene el objeto guardado con @setStorage
 * @setStorageArray         guarda el array de objetos en el storage
 * @getStorageArray         obtiene el array de objetos guardado con @setStorageArray
 * @removeStorageArray      elimina el array de objetos guardado con @setStorageArray
 * @setStorageItemArray     agrega un elemento al array de objeto guardado con @setStorageArray
 * @getStorageItemArray     obtiene un elemento del array guardado con @setStorageArray buscandolo por su id 
 * @removeStorageItemArray  elimina un elemento del array guardado con @setStorageArray buscandolo por su id 
 */

import { setStorageData, getStorageData, removeStorageData, setArrayItemStorageData, getArrayItemStorageData, removeArrayItemStorageData } from "../../api/storage";
import { IMvFormaDePagoSuscripcion, getEmptyMvFormaDePagoSuscripcion } from "../../interfaces/personas";

export const setStorageMvFormaDePagoSuscripcion = async (IMvFormaDePagoSuscripcion : IMvFormaDePagoSuscripcion) => await setStorageData('IMvFormaDePagoSuscripcion', IMvFormaDePagoSuscripcion);
export const getStorageMvFormaDePagoSuscripcion = async () : Promise<IMvFormaDePagoSuscripcion> => await getStorageData('IMvFormaDePagoSuscripcion', getEmptyMvFormaDePagoSuscripcion());
export const removeStorageMvFormaDePagoSuscripcion = async () => await removeStorageData('IMvFormaDePagoSuscripcion');
export const setStorageMvFormaDePagoSuscripcionArray = async (IMvFormaDePagoSuscripcion : IMvFormaDePagoSuscripcion[]) => await setStorageData('IMvFormaDePagoSuscripcionArray', IMvFormaDePagoSuscripcion);
export const getStorageMvFormaDePagoSuscripcionArray = async () : Promise<IMvFormaDePagoSuscripcion[]> => await getStorageData('IMvFormaDePagoSuscripcionArray', [] as IMvFormaDePagoSuscripcion[]);
export const removeStorageMvFormaDePagoSuscripcionArray = async () => await removeStorageData('IMvFormaDePagoSuscripcionArray');
export const setStorageMvFormaDePagoSuscripcionArrayItem = async (id: number, IMvFormaDePagoSuscripcion : IMvFormaDePagoSuscripcion) : Promise<IMvFormaDePagoSuscripcion[]> => await setArrayItemStorageData('IMvFormaDePagoSuscripcionArray', id, IMvFormaDePagoSuscripcion, [] as IMvFormaDePagoSuscripcion[]);
export const getStorageMvFormaDePagoSuscripcionArrayItem = async (id: number) : Promise<IMvFormaDePagoSuscripcion> => await getArrayItemStorageData('IMvFormaDePagoSuscripcionArray', id, getEmptyMvFormaDePagoSuscripcion);
export const removeStorageMvFormaDePagoSuscripcionArrayItem = async (id: number) : Promise<IMvFormaDePagoSuscripcion[]> => await removeArrayItemStorageData('IMvFormaDePagoSuscripcionArray', id, [] as IMvFormaDePagoSuscripcion[]);
