/**
 * Métodos del, get, post, put para llamadas a la API
 * @get     en este método primero consulta con @getFromLocalStorage si es necesario hacer la petición http sino se obtiene del LocalStorage;
 *          si hay un error en la petición http se busca el objeto en el LocalStorage sino lo encuentra devuelve un objeto vacío; si la
 *          petición se realiza correctamente actualiza el valor individual y en el array del objeto en el LocalStorage y devuelve el objeto
 *          obtenido del servidor.
 * @getAll  en este método primero consulta con @getFromLocalStorage si es necesario hacer la petición http sino se obtiene del LocalStorage;
 *          si hay un error en la petición http se busca el objeto array en el LocalStorage sino lo encuentra devuelve un array vacío; si la
 *          petición se realiza correctamente actualiza array en el LocalStorage y devuelve el array obtenido del servidor
 * @update  en este método después de realizar la petición http si falla devuelve el objeto que se iba a actualizar; si la petición se
 *          realiza correctamente actualiza el valor individual y en el array del objeto en el LocalStorage y devuelve el objeto obtenido del
 *          servidor.
 * @create  en este método después de realizar la petición http si falla devuelve el objeto que se iba a crear; si la petición se realiza 
 *          correctamente actualiza el valor individual y en el array del objeto en el LocalStorage y devuelve el objeto obtenido del
 *          servidor.
 * @delete  en este método después de realizar la petición http si falla devuelve el objeto que se iba a eliminar; si la petición se realiza 
 *          correctamente elimina el valor individual y en el objeto en el arrya del LocalStorage y devuelve un objeto vacio (Default)
 */

import { del, get, post, put } from "../../api/api";
import { getFromLocalStorage, updateLocalStorageExpiry } from "../../api/helpers";
import { removeArrayItemStorageData } from "../../api/storage";
import { DEFAULT_STATE } from "../../enums/default_state";
import { getEmptyStatusCentroDeTrabajo, setCentroDeTrabajo, ICentroDeTrabajo, getSerializableCentroDeTrabajo } from "../../interfaces/personas";
import { IServerDate } from "../../interfaces/time";
import { getStorageCentroDeTrabajoArrayItem, setStorageCentroDeTrabajo, setStorageCentroDeTrabajoArrayItem, getStorageCentroDeTrabajoArray, setStorageCentroDeTrabajoArray, removeStorageCentroDeTrabajo } from "../../localStorage/personas";

export const getCentroDeTrabajo = async (serverDate: IServerDate, pk : number, idCliente : number) => {
  const temp = getEmptyStatusCentroDeTrabajo();
  const doHttp = await getFromLocalStorage(serverDate, temp.expiry);
  temp.expiry = doHttp.storage;
  if (!doHttp.result) {
    console.log(`(localStorage) CentroDeTrabajo con pk: ${pk}, obtenido con éxito`);
    temp.estado = DEFAULT_STATE.success;
    temp.mensaje = `Centro de trabajo  obtenido con éxito`;
    temp.data = await getStorageCentroDeTrabajoArrayItem(pk);
    return temp;
  }
  const result = await get(`personas/centro-trabajo/${pk}/${idCliente}`);
  const data = result.data;
  console.log(`CentroDeTrabajo data: `, data);
  if (!data.id || data.detail) {
    console.log(`Error al obtener CentroDeTrabajo con pk: ${pk}, ${data.detail}`);
    temp.estado = DEFAULT_STATE.error;
    temp.mensaje =  `Error al obtener la centro de trabajo: ${data.detail ?? "Hubo un problema al conectarse con el servidor"}`;
    temp.data = await getStorageCentroDeTrabajoArrayItem(pk);
    return temp;
  }
  console.log(`CentroDeTrabajo con pk: ${pk}, obtenido con éxito`);
  temp.estado = DEFAULT_STATE.success;
  temp.mensaje = `Centro de trabajo obtenido con éxito`;
  temp.data = await setCentroDeTrabajo(data, serverDate);
  await setStorageCentroDeTrabajo(temp.data);
  temp.data_array = await setStorageCentroDeTrabajoArrayItem(pk, temp.data);
  temp.expiry = await updateLocalStorageExpiry(serverDate, temp.expiry);
  return temp;
}

export const getAllCentroDeTrabajo = async (serverDate: IServerDate, idCliente : number) => {
  const temp = getEmptyStatusCentroDeTrabajo();
  const doHttp = await getFromLocalStorage(serverDate, temp.expiry_array);
  temp.expiry_array = doHttp.storage;
  if (!doHttp.result) {
    console.log(`(localStorage) Todos los CentroDeTrabajo obtenidos con éxito`);
    temp.estado = DEFAULT_STATE.success;
    temp.mensaje = `Todos los centros de trabajo obtenidos con éxito`;
    temp.data_array = await getStorageCentroDeTrabajoArray();
  }
  const result = await get(`personas/centro-trabajo/${idCliente}`);
  const data = result.data;
  if (data.detail) {
    console.log(`Error al obtener la lista de CentroDeTrabajo`, data);
    temp.estado = DEFAULT_STATE.error;
    temp.mensaje = `Error al obtener la lista de centros de trabajo: ${data.detail ?? "Hubo un problema al conectarse con el servidor"}`;
    temp.data_array = await getStorageCentroDeTrabajoArray();
    return temp;
  }
  console.log(`Todos las CentroDeTrabajoes obtenidos con éxito`);
  temp.estado = DEFAULT_STATE.success;
  temp.mensaje = `Todos los centros de trabajo obtenidos con éxito`;
  temp.data_array = await Promise.all(data.map(async (e : any) => await setCentroDeTrabajo(e, serverDate)));
  await setStorageCentroDeTrabajoArray(temp.data_array);
  temp.expiry_array = await updateLocalStorageExpiry(serverDate, temp.expiry_array);
  return temp;
}

export const updateCentroDeTrabajo = async (serverDate: IServerDate, pk : number, idCliente : number, object : ICentroDeTrabajo) => {
  const temp = getEmptyStatusCentroDeTrabajo();
  const result = await put(`personas/centro-trabajo/${pk}/${idCliente}`, getSerializableCentroDeTrabajo(object));
  const data = result.data;
  if (!data.id || data.detail) {
    console.log(`Error al actualizar CentroDeTrabajo con pk: ${pk}, ${data.detail}`);
    temp.estado = DEFAULT_STATE.error;
    temp.mensaje = `Error al actualizar la centro de trabajo: ${data.detail ?? "Hubo un problema al conectarse con el servidor"}`;
    temp.data = await setCentroDeTrabajo(object, serverDate);
    return temp;
  }
  console.log(`CentroDeTrabajo con pk: ${pk}, actualizado con éxito`);
  temp.estado = DEFAULT_STATE.success;
  temp.mensaje = `Centro de trabajo actualizado con éxito`;
  temp.data = await setCentroDeTrabajo(data, serverDate);
  await setStorageCentroDeTrabajo(temp.data);
  temp.data_array = await setStorageCentroDeTrabajoArrayItem(temp.data.id, temp.data);
  return temp;
}

export const createCentroDeTrabajo = async (serverDate: IServerDate, idCliente : number, object : ICentroDeTrabajo) => {
  const temp = getEmptyStatusCentroDeTrabajo();
  const result = await post(`personas/centro-trabajo/${idCliente}`, getSerializableCentroDeTrabajo(object));
  const data = result.data;
  if (!data.id || data.detail) {
    console.log(`Error al crear CentroDeTrabajo con los datos ${object}, ${data.detail}`);
    temp.estado = DEFAULT_STATE.error;
    temp.mensaje = `Error al crear la centro de trabajo: ${data.detail ?? "Hubo un problema al conectarse con el servidor"}`;
    temp.data = await setCentroDeTrabajo(object, serverDate);
    return temp;
  }
  console.log(`CentroDeTrabajo creada con éxito`);
  temp.estado = DEFAULT_STATE.success;
  temp.mensaje = `Centro de trabajo  creada con éxito`;
  temp.data = await setCentroDeTrabajo(data, serverDate);
  await setStorageCentroDeTrabajo(temp.data);
  temp.data_array = await setStorageCentroDeTrabajoArrayItem(temp.data.id, temp.data);
  return temp;
}

export const deleteCentroDeTrabajo = async (pk : number, idCliente : number) => {
  const temp = getEmptyStatusCentroDeTrabajo();
  const result = await del(`personas/centro-trabajo/${pk}/${idCliente}`);
  const data = result.data;
  if (data.detail) {
    console.log(`Error al eliminar CentroDeTrabajo con pk: ${pk}, ${data.detail}`);
    temp.estado = DEFAULT_STATE.error;
    temp.mensaje = `Error al eliminar la centro de trabajo: ${data.detail}`;
    temp.data = await getStorageCentroDeTrabajoArrayItem(pk);
    temp.data_array = await getStorageCentroDeTrabajoArray();
    return temp;
  }
  console.log(`CentroDeTrabajo eliminado con éxito`);
  temp.estado = DEFAULT_STATE.success;
  temp.mensaje = `Centro de trabajo  eliminado con éxito`;
  await removeStorageCentroDeTrabajo();
  temp.data_array = await removeArrayItemStorageData("ICentroDeTrabajo", pk, []);
  return temp;
}
