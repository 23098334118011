/**
 * Métodos AsyncThunk para ser usados en los reducers y
 * los resultados a las llamadas http puedan ser usados para
 * actualizar el state en redux
 */

import { createAsyncThunk } from "@reduxjs/toolkit";
import { DEFAULT_STATE } from "../../enums/default_state";
import { getMvTipoContrato, getAllMvTipoContrato, updateMvTipoContrato, createMvTipoContrato, deleteMvTipoContrato } from "../../http/nomina";
import { getEmptyStatusMvTipoContrato, IMvTipoContrato } from "../../interfaces/nomina";
import { IServerDate } from "../../interfaces/time";

export const getMvTipoContratoAsyncThunk = createAsyncThunk(
  'nomina/getMvTipoContratoAsyncThunk',
  async (query : {serverDate : IServerDate, id: number}) => await getMvTipoContrato(query.serverDate, query.id)
    .then(async (result) => result)
    .catch(async (error) => getEmptyStatusMvTipoContrato(DEFAULT_STATE.error))  
);

export const getAllMvTipoContratoAsyncThunk = createAsyncThunk(
  'nomina/getAllMvTipoContratoAsyncThunk',
  async (queryAll : {serverDate : IServerDate}) => await getAllMvTipoContrato(queryAll.serverDate)
    .then(async (result) => result)
    .catch(async (error) => getEmptyStatusMvTipoContrato(DEFAULT_STATE.error))  
);

export const updateMvTipoContratoAsyncThunk = createAsyncThunk(
  'nomina/updateMvTipoContratoAsyncThunk',
  async (update: {serverDate : IServerDate, id: number, MvTipoContrato: IMvTipoContrato}) => await updateMvTipoContrato(update.serverDate, update.id, update.MvTipoContrato)
    .then(async (result) => result)
    .catch(async (error) => getEmptyStatusMvTipoContrato(DEFAULT_STATE.error))  
);

export const createMvTipoContratoAsyncThunk = createAsyncThunk(
  'nomina/createMvTipoContratoAsyncThunk',
  async (create : {serverDate : IServerDate, MvTipoContrato: IMvTipoContrato}) => await createMvTipoContrato(create.serverDate, create.MvTipoContrato)
    .then(async (result) => result)
    .catch(async (error) => getEmptyStatusMvTipoContrato(DEFAULT_STATE.error))  
);

export const deleteMvTipoContratoAsyncThunk = createAsyncThunk(
  'nomina/deleteMvTipoContratoAsyncThunk',
  async (del : {id : number}) => await deleteMvTipoContrato(del.id)
    .then(async (result) => result)
    .catch(async (error) => getEmptyStatusMvTipoContrato())  
);
