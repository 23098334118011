/**
 * Maneja el mapa de Google para su uso en @Dasboard / @WorkCenters / @EditEmpleadoModal
 * @interface GoogleMapsV3Props
 *   @param {string} id: ultilizado con @param {string} content para hacer scroll al mapa cuando se seleccione un centro de trabajo en @Dasboard / @WorkCenters
 *   @param {HTMLIonContentElement | null} content: para definir en que @IonContent nos encontramos, si en @Dasboard ó @WorkCenters
 *   @param {boolean} hasSearchBox: para activar la caja de busqueda de ubicaciones de Places de google.
 *   @param {any} present: se utiliza para presentrar el diálogo del Maker seleccionado (en su InfoWindow).
 *   @param {MakerData} markers: es la lista de @ICondigosQr transformatios en Markers que puedan ser usados en el mapa
 *   @param {List} listData: es la lista de los nombres de los centros de trabajo para poder colocarlos en el @Selector
 *   @param {boolean} defaultWorkCenterSelector: se utiliza para saber si colocamos arriba del mapa el SearchBox o lo dejamos dentro
 *   @param {Function} handlePlace: regristra los clics en el mapa en @WorkCenters para que su ubicación pueda ser usada en la creación de un nuevo centro de trabajo
 * @function onPlacesDrag actualiza la lista de ubicaciones local de @places del centro de trabajo / dirección cuando su Marker es arrastrado de su ubicación en @WorkCenters / @EditEmpleadoModal
 * @function onCentroDrag actualiza la lista de ubicaciones local de @markers y las envía a @Dasboard / @WorkCenters / @EditEmpleadoModal con @handlePlace cuando un Marker es arrastrado de su ubicación
 * @function onClick regista los clicks en el mapa la para la creacón del centro de trabajo en @Dasboard y de la dirección en @EditEmpleadoModal
 * @function onPlaceAdded actualiza los datos del Marker de centro a trabajdo a crear en @Dasboard y de la dirección en @EditEmpleadoModal
 * @function onIdle realiza acciones mientras el mapa está inactivo.
 * @function createButton crea un botón dinámicamente para su uso dentro del InfoWindow del Marker
 * @function makerClick presenta el diálogo de edición del centro de trabajo
 * @function scrollTo hace el scroll hacia el mapa cuando un centro de trabajo es seleccindo en el @Selector
 */

import Map from "./Map";
import { Wrapper, Status } from "@googlemaps/react-wrapper";
import { useEffect, useMemo, useRef, useState } from "react";
import Marker from "./Maker";
import Selector from "./Selector";
import { IonCol, IonGrid, IonRow } from "@ionic/react";
import { List, MakerData } from "../redux/interfaces/helpers";
import useRedux from "../hooks/useRedux";

interface GoogleMapsV3Props {
  id?: string;
  content?: HTMLIonContentElement | null;
  hasSearchBox: boolean;
  present?: any;
  markers: MakerData[];
  listData: List[];
  defaultWorkCenterSelector?: boolean;
  handlePlace?: (place: google.maps.LatLng[]) => void;
  handlePlacePostalCode?: (postal_code: string) => void;
}

export const StatusIcon = [
  "../assets/img/green-dot.png",
  "../assets/img/yellow-dot.png",
  "../assets/img/purple-dot.png",
  "../assets/img/red-dot.png",
];

const GoogleMapsV3: React.FC<GoogleMapsV3Props> = ({
  id,
  content,
  hasSearchBox,
  present,
  markers,
  listData,
  defaultWorkCenterSelector = true,
  handlePlace,
  handlePlacePostalCode,
}) => {
  const [{ location }] = useRedux();
  const [places, setPlaces] = useState<google.maps.LatLng[]>([]);
  const [selectedMarker, setSelectedMarker] = useState<List>();
  const [zoom, setZoom] = useState(10); // initial zoom
  const [center, setCenter] = useState<google.maps.LatLngLiteral>({
    lat: location.latitud,
    lng: location.longitud,
  });

  const selectorRef = useRef<HTMLIonRowElement>(null);

  const onPlacesDrag = (e: google.maps.MapMouseEvent) => {
    setPlaces([e.latLng!]);
    if (handlePlace) handlePlace([e.latLng!]);
  };

  const onCentroDrag = (e: google.maps.MapMouseEvent, id: number) => {
    markers[id].position = {
      lat: e.latLng?.lat() ?? 0,
      lng: e.latLng?.lng() ?? 0,
    };
  };

  const onClick = (e: google.maps.MapMouseEvent) => {
    // avoid directly mutating state
    setPlaces([e.latLng!]);
    if (handlePlace) handlePlace([e.latLng!]);
  };

  const onPlaceAdded = (e: google.maps.LatLng[]) => {
    setPlaces([...e]);
    if (handlePlace) handlePlace([...e]);
  };

  const onPostalCodeFromPlace = (e: string) => {
    if (handlePlacePostalCode) handlePlacePostalCode(e);
  };

  const onIdle = (m: google.maps.Map) => {
    if (m.getZoom()) setZoom(m.getZoom()!);
    if (m.getCenter()) setCenter(m.getCenter()!.toJSON());
  };

  const createButton = (e?: MakerData) => {
    const div = document.createElement("div");
    const button = document.createElement("button");
    button.onclick = () => makerClick(e);
    button.innerHTML = `${e ? e.name : "Crear centro de trabajo"}`;
    button.classList.add("info-window-btn");
    button.classList.add("ripple");
    div.appendChild(button);
    return div;
  };

  const makerClick = (e?: MakerData) => {
    console.log("makerClick (e): ", e);
    if (present) present();
  };

  useEffect(() => {
    if (!defaultWorkCenterSelector && selectorRef.current) {
      const destination = document.getElementById(
        "work-center-selector-work-centers"
      );
      destination?.appendChild(selectorRef.current);
    }
  }, [selectorRef.current, defaultWorkCenterSelector]);

  useEffect(() => {
    if (selectedMarker) {
      const selected = markers.find((e) => e.id === selectedMarker.id);
      if (selected) {
        setCenter(selected.position);
        setZoom(20);
        if (id && content) scrollTo(id, content);
      }
    }
  }, [selectedMarker]);

  const googleMap = useMemo(() => {
    return (
      <div
        style={{ display: "flex", height: "600px", flexDirection: "column" }}
      >
        <Wrapper
          apiKey={process.env.REACT_APP_GOOGLE_MAPS_KEY!}
          libraries={["places"]}
        >
          <Map
            center={center}
            onClick={hasSearchBox ? onClick : undefined}
            onIdle={onIdle}
            onPlaceAdded={onPlaceAdded}
            onPostalCodeFromPlace={onPostalCodeFromPlace}
            zoom={zoom}
            hasSearchBox={hasSearchBox}
            style={{ flexGrow: "1", height: "100%" }}
          >
            {markers.map((latLng, i) => (
              <Marker
                key={`centro-de-trabajo-${i}`}
                title={latLng.name}
                icon={StatusIcon[4]}
                position={latLng.position}
                isSelected={
                  selectedMarker ? selectedMarker.id === latLng.qrCode : false
                }
                content={hasSearchBox ? createButton(latLng) : latLng.name}
                clickable={true}
                draggable={hasSearchBox}
                onDrag={(e) => onCentroDrag(e, i)}
              />
            ))}
            {places.map((place, i) => (
              <Marker
                key={`nuevo-centro-de-trabajo-${i}`}
                icon={StatusIcon[2]}
                position={place}
                content={
                  hasSearchBox ? createButton : "Crear centro de trabajo"
                }
                clickable={true}
                draggable={hasSearchBox}
                onDrag={hasSearchBox ? onPlacesDrag : undefined}
              />
            ))}
          </Map>
        </Wrapper>
      </div>
    );
  }, [markers, places, center]);

  const scrollTo = (elementId: string, content: HTMLIonContentElement) => {
    const element = document.getElementById(elementId);
    if (!element) return;
    const Y = element.offsetTop;
    content.scrollToBottom(500);
  };

  return (
    <IonGrid className="no-padding">
      <IonRow
        className="no-padding"
        id="work-center-selector-original"
        ref={selectorRef}
      >
        <IonCol className="google-maps-selector">
          <Selector
            label="Centros de trabajo"
            value={selectedMarker}
            list={listData}
            change={setSelectedMarker}
          />
        </IonCol>
      </IonRow>
      <IonRow>
        <IonCol className="no-padding">{googleMap}</IonCol>
      </IonRow>
    </IonGrid>
  );
};

export default GoogleMapsV3;
