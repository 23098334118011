import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { setStorageData, getStorageData, removeStorageData } from "../api/storage";
import { getDefaultITheme, ITheme } from "../interfaces/theme";



export const getThemeAsyncThunk = createAsyncThunk(
  'theme/getThemeAsyncThunk',
  async () => await getStorageTheme()
    .then(async (result) => result)
    .catch(async (error) => getDefaultITheme())  
);

export const setThemeAsyncThunk = createAsyncThunk(
  'theme/setThemeAsyncThunk',
  async (theme: ITheme) => await setStorageTheme(theme)
    .then(async (result) => getStorageTheme())
    .catch(async (error) => getDefaultITheme())  
);

export const setStorageTheme = async (ITheme : ITheme) => await setStorageData('ITheme', ITheme);
export const getStorageTheme = async () : Promise<ITheme> => await getStorageData('ITheme', getDefaultITheme());
export const removeStorageTheme = async () => await removeStorageData('ITheme');

export const themeSlice = createSlice({
  name: 'theme',
  initialState: {
    themeState: getDefaultITheme(),
  },
  reducers: {
  },
  extraReducers: {
    // Reducers: getServerDateAsyncThunk
    [getThemeAsyncThunk.pending.type]: (state, action) => {},
    [getThemeAsyncThunk.fulfilled.type]: (state, action: PayloadAction<ITheme>) => {
      state.themeState = action.payload;
    },
    [getThemeAsyncThunk.rejected.type]: (state, action: PayloadAction<ITheme>) => {
      state.themeState = action.payload;
    },
    // Reducers: getServerDateAsyncThunk
    [setThemeAsyncThunk.pending.type]: (state, action) => {},
    [setThemeAsyncThunk.fulfilled.type]: (state, action: PayloadAction<ITheme>) => {
      state.themeState = action.payload;
    },
    [setThemeAsyncThunk.rejected.type]: (state, action: PayloadAction<ITheme>) => {},
  }
});


export default themeSlice.reducer;
