/**
 * Métodos para el manejo de IonicStorage 
 * @setStorage              guarda un unico objeto al storage
 * @getStorage              obtiene el objeto guardado con @setStorage
 * @setStorageArray         guarda el array de objetos en el storage
 * @getStorageArray         obtiene el array de objetos guardado con @setStorageArray
 * @removeStorageArray      elimina el array de objetos guardado con @setStorageArray
 * @setStorageItemArray     agrega un elemento al array de objeto guardado con @setStorageArray
 * @getStorageItemArray     obtiene un elemento del array guardado con @setStorageArray buscandolo por su id 
 * @removeStorageItemArray  elimina un elemento del array guardado con @setStorageArray buscandolo por su id 
 */

import { setStorageData, getStorageData, removeStorageData, setArrayItemStorageData, getArrayItemStorageData, removeArrayItemStorageData } from "../../api/storage";
import { IServicioQentry, getEmptyServicioQentry } from "../../interfaces/personas";

export const setStorageServicioQentry = async (IServicioQentry : IServicioQentry) => await setStorageData('IServicioQentry', IServicioQentry);
export const getStorageServicioQentry = async () : Promise<IServicioQentry> => await getStorageData('IServicioQentry', getEmptyServicioQentry());
export const removeStorageServicioQentry = async () => await removeStorageData('IServicioQentry');
export const setStorageServicioQentryArray = async (IServicioQentry : IServicioQentry[]) => await setStorageData('IServicioQentryArray', IServicioQentry);
export const getStorageServicioQentryArray = async () : Promise<IServicioQentry[]> => await getStorageData('IServicioQentryArray', [] as IServicioQentry[]);
export const removeStorageServicioQentryArray = async () => await removeStorageData('IServicioQentryArray');
export const setStorageServicioQentryArrayItem = async (id: number, IServicioQentry : IServicioQentry) : Promise<IServicioQentry[]> => await setArrayItemStorageData('IServicioQentryArray', id, IServicioQentry, [] as IServicioQentry[]);
export const getStorageServicioQentryArrayItem = async (id: number) : Promise<IServicioQentry> => await getArrayItemStorageData('IServicioQentryArray', id, getEmptyServicioQentry);
export const removeStorageServicioQentryArrayItem = async (id: number) : Promise<IServicioQentry[]> => await removeArrayItemStorageData('IServicioQentryArray', id, [] as IServicioQentry[]);
