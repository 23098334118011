/** 
 * Componente que se encarga del elemento Incidencia
 * @IIncidencia es la interfaz que contiene los parámetos el elemento Incidencia
 * @getEmptyIncidencia  devuelve un objeto con los parámetros por defecto; @param {id}, opcional cuando no encuentra el objeto en el LocalSotrage
 * @IStatusIncidencia es la interfaz para los estatus a llamadas a la API; @param {status}, opcional cuando queremos otro estado de inizialización de tipo [DEFAULT_STATE]
 * @getEmptyStatusIncidencia devuelve un objeto con los parámetros por defecto,
 * @setIncidencia devuelve un objeto de tipo [IIncidencia] recibiendo @param {object} y @param {serverDate}, para verificar que los datos estén en el formato correcto y ajustarlos a la zona horaria actual
 * @getSerializableIncidencia recibe un @param {IIncidencia}, para devolver un objeto serizalizable para las peticiones de la API
 * */

 import { getDateString, isNotEmpty, SERVER_TIME_ZONE } from "../../api/helpers";
import { DEFAULT_STATE } from "../../enums/default_state";
import { getStorageAsistenciaArrayItem } from "../../localStorage/nomina";
import { getStorageEmpleadoArrayItem, getStorageUserArrayItem, getStorageClienteQentryArrayItem } from "../../localStorage/personas";
import { getEmptyClienteQentry, getEmptyEmpleado, getEmptyUser, IClienteQentry, IEmpleado, IUser, setClienteQentry, setEmpleado, setUser } from "../personas";
import { ILocalStorageExpiry, getEmptyLocalStorageExpiry, IServerDate, convertDateToZonaHoraria } from "../time";
import { IAsistencia, setAsistencia } from "./Asistencia";

export interface IIncidencia {
  id : number,
  empleado : IEmpleado,
  fecha_inicio_periodo : string, // Fecha
  fecha_fin_periodo : string, // Fecha
  total_horas_laboradas : number, // Decimal
  total_horas_extras : number, // Decimal
  total_horas_ordinarias : number, // Decimal
  total_faltas : number,
  total_retardos : number,
  total_dias_vacaciones : number,
  total_dias_incapacidad : number,
  total_dias_laborados : number,
  total_dias_descanso : number,
  registros_asistencias : IAsistencia[],
  autor : IUser,
  fecha_elaboracion : string, // Fecha
  estatus_sistema : boolean,
  ultima_actualizacion : string, // Fecha
  cliente_qentry : IClienteQentry,
}

export const getEmptyIncidencia = (id? : number) => {
  const temp : IIncidencia = 
  {
    id : id ? id : 0,
    empleado : getEmptyEmpleado(),
    fecha_inicio_periodo : "", // Fecha
    fecha_fin_periodo : "", // Fecha
    total_horas_laboradas : 0, // Decimal
    total_horas_extras : 0, // Decimal
    total_horas_ordinarias : 0, // Decimal
    total_faltas : 0,
    total_retardos : 0,
    total_dias_vacaciones : 0,
    total_dias_incapacidad : 0,
    total_dias_laborados : 0,
    total_dias_descanso : 0,
    registros_asistencias : [],
    autor : getEmptyUser(),
    fecha_elaboracion : "",
    estatus_sistema : false,
    ultima_actualizacion : "",
    cliente_qentry : getEmptyClienteQentry(),
  };
  return temp;
}

export interface IStatusIncidencia {
  estado : DEFAULT_STATE,
  mensaje : string,
  data : IIncidencia,
  data_array: IIncidencia[],
  expiry : ILocalStorageExpiry,
  expiry_array : ILocalStorageExpiry,
}

export const getEmptyStatusIncidencia = (status: DEFAULT_STATE = DEFAULT_STATE.loading) => {
  const temp : IStatusIncidencia = 
  {
    estado : status,
    mensaje : "",
    data : getEmptyIncidencia(),
    data_array : [],
    expiry : getEmptyLocalStorageExpiry("IIncidencia", 0),
    expiry_array : getEmptyLocalStorageExpiry("IIncidenciaArray", 0),
  };
  return temp;
}

export const setIncidencia = async (
  object : any,
  serverDate : IServerDate,
) => {
  const temp = getEmptyIncidencia();
  if (isNotEmpty(object.id)) temp.id = object.id;
  if (isNotEmpty(object.empleado)) temp.empleado = (typeof object.empleado === 'number') ? await getStorageEmpleadoArrayItem(object.empleado) : await setEmpleado(object.empleado, serverDate);
  if (isNotEmpty(object.fecha_inicio_periodo)) temp.fecha_inicio_periodo = convertDateToZonaHoraria(serverDate, object.fecha_inicio_periodo);
  if (isNotEmpty(object.fecha_fin_periodo)) temp.fecha_fin_periodo = convertDateToZonaHoraria(serverDate, object.fecha_fin_periodo);
  if (isNotEmpty(object.total_horas_laboradas)) temp.total_horas_laboradas = Number(object.total_horas_laboradas);
  if (isNotEmpty(object.total_horas_extras)) temp.total_horas_extras = Number(object.total_horas_extras);
  if (isNotEmpty(object.total_horas_ordinarias)) temp.total_horas_ordinarias = Number(object.total_horas_ordinarias);
  if (isNotEmpty(object.total_faltas)) temp.total_faltas = object.total_faltas;
  if (isNotEmpty(object.total_retardos)) temp.total_retardos = object.total_retardos;
  if (isNotEmpty(object.total_dias_vacaciones)) temp.total_dias_vacaciones = object.total_dias_vacaciones;
  if (isNotEmpty(object.total_dias_incapacidad)) temp.total_dias_incapacidad = object.total_dias_incapacidad;
  if (isNotEmpty(object.total_dias_laborados)) temp.total_dias_laborados = object.total_dias_laborados;
  if (isNotEmpty(object.total_dias_descanso)) temp.total_dias_descanso = object.total_dias_descanso;
  if (isNotEmpty(object.registros_asistencias)) temp.registros_asistencias = (typeof object.registros_asistencias[0] === 'number') ? await Promise.all(object.registros_asistencias.map(async (e : number) => await getStorageAsistenciaArrayItem(e))) : await Promise.all(object.registros_asistencias.map(async (e : any) => await setAsistencia(e, serverDate)));
  if (isNotEmpty(object.autor)) temp.autor = (typeof object.autor === 'number') ? await getStorageUserArrayItem(object.autor) : await setUser(object.autor, serverDate);
  if (isNotEmpty(object.fecha_elaboracion)) temp.fecha_elaboracion = convertDateToZonaHoraria(serverDate, object.fecha_elaboracion);
  if (isNotEmpty(object.estatus_sistema)) temp.estatus_sistema = object.estatus_sistema;
  if (isNotEmpty(object.ultima_actualizacion)) temp.ultima_actualizacion = convertDateToZonaHoraria(serverDate, object.ultima_actualizacion);
  if (isNotEmpty(object.cliente_qentry)) temp.cliente_qentry = (typeof object.cliente_qentry === 'number') ? await getStorageClienteQentryArrayItem(object.cliente_qentry) : await setClienteQentry(object.cliente_qentry, serverDate);
  return temp;
}

export const getSerializableIncidencia = (IIncidencia : IIncidencia ) => {
  return {
    id : IIncidencia.id,
    empleado : IIncidencia.empleado.id,
    fecha_inicio_periodo : getDateString(SERVER_TIME_ZONE, IIncidencia.fecha_inicio_periodo), // Fecha
    fecha_fin_periodo : getDateString(SERVER_TIME_ZONE, IIncidencia.fecha_fin_periodo), // Fecha
    total_horas_laboradas : IIncidencia.total_horas_laboradas.toFixed(2), // Decimal
    total_horas_extras : IIncidencia.total_horas_extras.toFixed(2), // Decimal
    total_horas_ordinarias : IIncidencia.total_horas_ordinarias.toFixed(2), // Decimal
    total_faltas : IIncidencia.total_faltas,
    total_retardos : IIncidencia.total_retardos,
    total_dias_vacaciones : IIncidencia.total_dias_vacaciones,
    total_dias_incapacidad : IIncidencia.total_dias_incapacidad,
    total_dias_laborados : IIncidencia.total_dias_laborados,
    total_dias_descanso : IIncidencia.total_dias_descanso,
    registros_asistencias : IIncidencia.registros_asistencias.map((e) => e.id),
    autor : IIncidencia.autor.id,
    fecha_elaboracion : IIncidencia.fecha_elaboracion,
    estatus_sistema : IIncidencia.estatus_sistema,
    ultima_actualizacion : getDateString(SERVER_TIME_ZONE),
    cliente_qentry : IIncidencia.cliente_qentry.id,
  };
}
