/**
 * Métodos del, get, post, put para llamadas a la API
 * @get     en este método primero consulta con @getFromLocalStorage si es necesario hacer la petición http sino se obtiene del LocalStorage;
 *          si hay un error en la petición http se busca el objeto en el LocalStorage sino lo encuentra devuelve un objeto vacío; si la
 *          petición se realiza correctamente actualiza el valor individual y en el array del objeto en el LocalStorage y devuelve el objeto
 *          obtenido del servidor.
 * @getAll  en este método primero consulta con @getFromLocalStorage si es necesario hacer la petición http sino se obtiene del LocalStorage;
 *          si hay un error en la petición http se busca el objeto array en el LocalStorage sino lo encuentra devuelve un array vacío; si la
 *          petición se realiza correctamente actualiza array en el LocalStorage y devuelve el array obtenido del servidor
 * @update  en este método después de realizar la petición http si falla devuelve el objeto que se iba a actualizar; si la petición se
 *          realiza correctamente actualiza el valor individual y en el array del objeto en el LocalStorage y devuelve el objeto obtenido del
 *          servidor.
 * @create  en este método después de realizar la petición http si falla devuelve el objeto que se iba a crear; si la petición se realiza
 *          correctamente actualiza el valor individual y en el array del objeto en el LocalStorage y devuelve el objeto obtenido del
 *          servidor.
 * @delete  en este método después de realizar la petición http si falla devuelve el objeto que se iba a eliminar; si la petición se realiza
 *          correctamente elimina el valor individual y en el objeto en el arrya del LocalStorage y devuelve un objeto vacio (Default)
 */

import { del, get, post, put } from "../../api/api";
import {
  getFromLocalStorage,
  updateLocalStorageExpiry,
} from "../../api/helpers";
import { removeArrayItemStorageData } from "../../api/storage";
import { DEFAULT_STATE } from "../../enums/default_state";
import {
  getEmptyStatusEmpresa,
  setEmpresa,
  IEmpresa,
  getSerializableEmpresa,
} from "../../interfaces/personas";
import { IServerDate } from "../../interfaces/time";
import {
  getStorageEmpresaArrayItem,
  setStorageEmpresa,
  setStorageEmpresaArrayItem,
  getStorageEmpresaArray,
  setStorageEmpresaArray,
  removeStorageEmpresa,
} from "../../localStorage/personas";

export const getEmpresa = async (
  serverDate: IServerDate,
  pk: number,
  idCliente: number
) => {
  const temp = getEmptyStatusEmpresa();
  const doHttp = await getFromLocalStorage(serverDate, temp.expiry);
  temp.expiry = doHttp.storage;
  if (!doHttp.result) {
    console.log(`(localStorage) Empresa con pk: ${pk}, obtenido con éxito`);
    temp.estado = DEFAULT_STATE.success;
    temp.mensaje = `Empresa obtenida con éxito`;
    temp.data = await getStorageEmpresaArrayItem(pk);
    return temp;
  }
  const result = await get(`personas/empresa/${pk}/${idCliente}`);
  const data = result.data;
  console.log(`Empresa data: `, data);
  if (!data.id || data.detail) {
    console.log(`Error al obtener Empresa con pk: ${pk}, ${data.detail}`);
    temp.estado = DEFAULT_STATE.error;
    temp.mensaje = `Error al obtener la empresa: ${
      data.detail ?? "Hubo un problema al conectarse con el servidor"
    }`;
    temp.data = await getStorageEmpresaArrayItem(pk);
    return temp;
  }
  console.log(`Empresa con pk: ${pk}, obtenido con éxito`);
  temp.estado = DEFAULT_STATE.success;
  temp.mensaje = `Empresa obtenida con éxito`;
  temp.data = await setEmpresa(data, serverDate);
  await setStorageEmpresa(temp.data);
  temp.data_array = await setStorageEmpresaArrayItem(pk, temp.data);
  temp.expiry = await updateLocalStorageExpiry(serverDate, temp.expiry);
  return temp;
};

export const getAllEmpresa = async (
  serverDate: IServerDate,
  idCliente: number
) => {
  const temp = getEmptyStatusEmpresa();
  const doHttp = await getFromLocalStorage(serverDate, temp.expiry_array);
  temp.expiry_array = doHttp.storage;
  if (!doHttp.result) {
    console.log(`(localStorage) Todos las Empresa obtenidas con éxito`);
    temp.estado = DEFAULT_STATE.success;
    temp.mensaje = `Todos las Empresa obtenidas con éxito`;
    temp.data_array = await getStorageEmpresaArray();
    // return temp;
  }
  const result = await get(`personas/empresa/${idCliente}`);
  const data = result.data;
  if (data.detail) {
    console.log(`Error al obtener la lista de Empresa`, data);
    temp.estado = DEFAULT_STATE.error;
    temp.mensaje = `Error al obtener la lista de Empresa: ${
      data.detail ?? "Hubo un problema al conectarse con el servidor"
    }`;
    temp.data_array = await getStorageEmpresaArray();
    return temp;
  }
  console.log(`Todos las Empresas obtenidos con éxito`);
  temp.estado = DEFAULT_STATE.success;
  temp.mensaje = `Todos las empresa obtenidas con éxito`;
  temp.data_array = await Promise.all(
    data.map(async (e: any) => await setEmpresa(e, serverDate))
  );
  await setStorageEmpresaArray(temp.data_array);
  temp.expiry_array = await updateLocalStorageExpiry(
    serverDate,
    temp.expiry_array
  );
  return temp;
};

export const updateEmpresa = async (
  serverDate: IServerDate,
  pk: number,
  idCliente: number,
  object: IEmpresa
) => {
  const temp = getEmptyStatusEmpresa();
  const result = await put(
    `personas/empresa/${pk}/${idCliente}`,
    getSerializableEmpresa(object)
  );
  const data = result.data;
  if (!data.id || data.detail) {
    console.log(`Error al actualizar Empresa con pk: ${pk}, ${data.detail}`);
    temp.estado = DEFAULT_STATE.error;
    temp.mensaje = `Error al actualizar la empresa: ${
      data.detail ?? "Hubo un problema al conectarse con el servidor"
    }`;
    temp.data = await setEmpresa(object, serverDate);
    return temp;
  }
  console.log(`Empresa con pk: ${pk}, actualizado con éxito`);
  temp.estado = DEFAULT_STATE.success;
  temp.mensaje = `Empresa actualizada con éxito`;
  temp.data = await setEmpresa(data, serverDate);
  await setStorageEmpresa(temp.data);
  temp.data_array = await setStorageEmpresaArrayItem(temp.data.id, temp.data);
  return temp;
};

export const createEmpresa = async (
  serverDate: IServerDate,
  idCliente: number,
  object: IEmpresa
) => {
  const temp = getEmptyStatusEmpresa();
  const result = await post(
    `personas/empresa/${idCliente}`,
    getSerializableEmpresa(object)
  );
  const data = result.data;
  if (!data.id || data.detail) {
    console.log(
      `Error al crear Empresa con los datos ${object}, ${data.detail}`
    );
    temp.estado = DEFAULT_STATE.error;
    temp.mensaje = `Error al crear la empresa: ${
      data.detail ?? "Hubo un problema al conectarse con el servidor"
    }`;
    temp.data = await setEmpresa(object, serverDate);
    return temp;
  }
  console.log(`Empresa creada con éxito`);
  temp.estado = DEFAULT_STATE.success;
  temp.mensaje = `Empresa creada con éxito`;
  temp.data = await setEmpresa(data, serverDate);
  await setStorageEmpresa(temp.data);
  temp.data_array = await setStorageEmpresaArrayItem(temp.data.id, temp.data);
  return temp;
};

export const deleteEmpresa = async (pk: number, idCliente: number) => {
  const temp = getEmptyStatusEmpresa();
  const result = await del(`personas/empresa/${pk}/${idCliente}`);
  const data = result.data;
  if (data.detail) {
    console.log(`Error al eliminar Empresa con pk: ${pk}, ${data.detail}`);
    temp.estado = DEFAULT_STATE.error;
    temp.mensaje = `Error al eliminar la empresa: ${data.detail}`;
    temp.data = await getStorageEmpresaArrayItem(pk);
    temp.data_array = await getStorageEmpresaArray();
    return temp;
  }
  console.log(`Empresa eliminada con éxito`);
  temp.estado = DEFAULT_STATE.success;
  temp.mensaje = `Empresa eliminada con éxito`;
  await removeStorageEmpresa();
  temp.data_array = await removeArrayItemStorageData("IEmpresa", pk, []);
  return temp;
};
