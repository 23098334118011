/**
 * Reducers creados para obtener asíncronamente 
 * los datos de los modelos y dependiendo del resultado
 * de la peticón actualice el state en redux o mande un mensaje de error
 */

import { PayloadAction } from "@reduxjs/toolkit";
import { getGeolocalizacionAsyncThunk, getAllGeolocalizacionAsyncThunk, updateGeolocalizacionAsyncThunk, createGeolocalizacionAsyncThunk, deleteGeolocalizacionAsyncThunk } from "../../asyncThunk/personas";
import { DEFAULT_STATE } from "../../enums/default_state";
import { IStatusGeolocalizacion } from "../../interfaces/personas";
import { personasInitialState } from "../../slices/personasSlice";

// Reducers: Geolocalizacion
export const createGeolocalizacionReducers = () => {
  const get = () => {
    var { pending, fulfilled, rejected } = getGeolocalizacionAsyncThunk;
    return {
      [pending.type]: (state : typeof personasInitialState) => {
        state.personasState.geolocalizacion.estado = DEFAULT_STATE.loading;
      },
      [fulfilled.type]: (state : typeof personasInitialState, action : PayloadAction<IStatusGeolocalizacion>) => {
        const result = action.payload;
        result.expiry_array = state.personasState.geolocalizacion.expiry_array;
        if (result.estado === DEFAULT_STATE.error) result.data_array = state.personasState.geolocalizacion.data_array;
        state.personasState.geolocalizacion = result;
      },
      [rejected.type]: (state : typeof personasInitialState, action : PayloadAction<IStatusGeolocalizacion>) => {
        state.personasState.geolocalizacion = {
          ...state.personasState.geolocalizacion,
          estado: action.payload.estado,
          mensaje: "Ocurrió un problema al realizar la solicitud",
        };
      },
    };
  };
  
  const getAll = () => {
    var { pending, fulfilled, rejected } = getAllGeolocalizacionAsyncThunk;
    return {
      [pending.type]: (state : typeof personasInitialState) => {
        state.personasState.geolocalizacion.estado = DEFAULT_STATE.loading;
      },
      [fulfilled.type]: (state : typeof personasInitialState, action : PayloadAction<IStatusGeolocalizacion>) => {
        const result = action.payload;
        result.data = state.personasState.geolocalizacion.data;
        result.expiry = state.personasState.geolocalizacion.expiry;
        if (result.estado === DEFAULT_STATE.error) result.data_array = state.personasState.geolocalizacion.data_array;
        state.personasState.geolocalizacion = result;
      },
      [rejected.type]: (state : typeof personasInitialState, action : PayloadAction<IStatusGeolocalizacion>) => {
        state.personasState.geolocalizacion = {
          ...state.personasState.geolocalizacion,
          estado: action.payload.estado,
          mensaje: "Ocurrió un problema al realizar la solicitud",
        };
      },
    };
  };
  
  const update = () => {
    var { pending, fulfilled, rejected } = updateGeolocalizacionAsyncThunk;
    return {
      [pending.type]: (state : typeof personasInitialState) => {
        state.personasState.geolocalizacion.estado = DEFAULT_STATE.loading;
      },
      [fulfilled.type]: (state : typeof personasInitialState, action : PayloadAction<IStatusGeolocalizacion>) => {
        const result: IStatusGeolocalizacion = action.payload;
        result.expiry = state.personasState.geolocalizacion.expiry;
        result.expiry_array = state.personasState.geolocalizacion.expiry_array;
        if (result.estado === DEFAULT_STATE.error) result.data_array = state.personasState.geolocalizacion.data_array;
        state.personasState.geolocalizacion = result;
      },
      [rejected.type]: (state : typeof personasInitialState, action : PayloadAction<IStatusGeolocalizacion>) => {
        state.personasState.geolocalizacion = {
          ...state.personasState.geolocalizacion,
          estado: action.payload.estado,
          mensaje: "Ocurrió un problema al realizar la solicitud",
        };
      },
    };
  };

  const create = () => {
    var { pending, fulfilled, rejected } = createGeolocalizacionAsyncThunk;
    return {
      [pending.type]: (state : typeof personasInitialState) => {
        state.personasState.geolocalizacion.estado = DEFAULT_STATE.loading;
      },
      [fulfilled.type]: (state : typeof personasInitialState, action : PayloadAction<IStatusGeolocalizacion>) => {
        const result = action.payload;
        result.expiry = state.personasState.geolocalizacion.expiry;
        result.expiry_array = state.personasState.geolocalizacion.expiry_array;
        if (result.estado === DEFAULT_STATE.error) result.data_array = state.personasState.geolocalizacion.data_array;
        state.personasState.geolocalizacion = result;
      },
      [rejected.type]: (state : typeof personasInitialState, action : PayloadAction<IStatusGeolocalizacion>) => {
        state.personasState.geolocalizacion = {
          ...state.personasState.geolocalizacion,
          estado: action.payload.estado,
          mensaje: "Ocurrió un problema al realizar la solicitud",
        };
      },
    };
  };
  
  const del = () => {
    var { pending, fulfilled, rejected } = deleteGeolocalizacionAsyncThunk;
    return {
      [pending.type]: (state : typeof personasInitialState) => {
        state.personasState.geolocalizacion.estado = DEFAULT_STATE.loading;
      },
      [fulfilled.type]: (state : typeof personasInitialState, action : PayloadAction<IStatusGeolocalizacion>) => {
        const result: IStatusGeolocalizacion = action.payload;
        result.expiry = state.personasState.geolocalizacion.expiry;
        result.expiry_array = state.personasState.geolocalizacion.expiry_array;
        if (result.estado === DEFAULT_STATE.error) result.data_array = state.personasState.geolocalizacion.data_array;
        state.personasState.geolocalizacion = result;
      },
      [rejected.type]: (state : typeof personasInitialState, action : PayloadAction<IStatusGeolocalizacion>) => {
        state.personasState.geolocalizacion = {
          ...state.personasState.geolocalizacion,
          estado: action.payload.estado,
          mensaje: "Ocurrió un problema al realizar la solicitud",
        };
      },
    };
  };

  return {
    ...get(),
    ...getAll(),
    ...update(),
    ...create(),
    ...del(),
  };
};
