/**
 * Métodos para el manejo de IonicStorage 
 * @setStorage              guarda un unico objeto al storage
 * @getStorage              obtiene el objeto guardado con @setStorage
 * @setStorageArray         guarda el array de objetos en el storage
 * @getStorageArray         obtiene el array de objetos guardado con @setStorageArray
 * @removeStorageArray      elimina el array de objetos guardado con @setStorageArray
 * @setStorageItemArray     agrega un elemento al array de objeto guardado con @setStorageArray
 * @getStorageItemArray     obtiene un elemento del array guardado con @setStorageArray buscandolo por su id 
 * @removeStorageItemArray  elimina un elemento del array guardado con @setStorageArray buscandolo por su id 
 */

import { setStorageData, getStorageData, removeStorageData, setArrayItemStorageData, getArrayItemStorageData, removeArrayItemStorageData } from "../../api/storage";
import { ICambioEstatusVacaciones, getEmptyCambioEstatusVacaciones } from "../../interfaces/nomina";

export const setStorageCambioEstatusVacaciones = async (ICambioEstatusVacaciones : ICambioEstatusVacaciones) => await setStorageData('ICambioEstatusVacaciones', ICambioEstatusVacaciones);
export const getStorageCambioEstatusVacaciones = async () : Promise<ICambioEstatusVacaciones> => await getStorageData('ICambioEstatusVacaciones', getEmptyCambioEstatusVacaciones());
export const removeStorageCambioEstatusVacaciones = async () => await removeStorageData('ICambioEstatusVacaciones');
export const setStorageCambioEstatusVacacionesArray = async (ICambioEstatusVacaciones : ICambioEstatusVacaciones[]) => await setStorageData('ICambioEstatusVacacionesArray', ICambioEstatusVacaciones);
export const getStorageCambioEstatusVacacionesArray = async () : Promise<ICambioEstatusVacaciones[]> => await getStorageData('ICambioEstatusVacacionesArray', [] as ICambioEstatusVacaciones[]);
export const removeStorageCambioEstatusVacacionesArray = async () => await removeStorageData('ICambioEstatusVacacionesArray');
export const setStorageCambioEstatusVacacionesArrayItem = async (id: number, ICambioEstatusVacaciones : ICambioEstatusVacaciones) : Promise<ICambioEstatusVacaciones[]> => await setArrayItemStorageData('ICambioEstatusVacacionesArray', id, ICambioEstatusVacaciones, [] as ICambioEstatusVacaciones[]);
export const getStorageCambioEstatusVacacionesArrayItem = async (id: number) : Promise<ICambioEstatusVacaciones> => await getArrayItemStorageData('ICambioEstatusVacacionesArray', id, getEmptyCambioEstatusVacaciones);
export const removeStorageCambioEstatusVacacionesArrayItem = async (id: number) : Promise<ICambioEstatusVacaciones[]> => await removeArrayItemStorageData('ICambioEstatusVacacionesArray', id, [] as ICambioEstatusVacaciones[]);
