import { createSlice } from '@reduxjs/toolkit';
import { detectActiveSession, getIAuthFromStorage, loginByEmail, signOut } from '../asyncThunk/auth';
import { AuthState, getDefaultIAuth} from '../interfaces/auth';

export const authSlice = createSlice({
  name: 'auth',
  initialState: {
    authState: getDefaultIAuth(AuthState.loading),
  },
  reducers: {
    setIAuth: (state, action) => {
      state.authState = action.payload;
    }
  },
  extraReducers: {
    // Reducers: loginByEmail
    [loginByEmail.pending.type]: (state, action) => {
      state.authState.isLoggedIn = AuthState.loading;
    },
    [loginByEmail.fulfilled.type]: (state, action) => {
      state.authState = action.payload;
    },
    [loginByEmail.rejected.type]: (state, action) => {
      state.authState = action.payload;
    },
    // Reducers: detectActiveSession
    [detectActiveSession.pending.type]: (state, action) => {
      state.authState.isLoggedIn = AuthState.loading;
    },
    [detectActiveSession.fulfilled.type]: (state, action) => {
      state.authState = action.payload;
    },
    [detectActiveSession.rejected.type]: (state, action) => {
      state.authState = action.payload;
    },
    // Reducers: signOut
    [signOut.pending.type]: (state, action) => {      
      state.authState.isLoggedIn = AuthState.loading;
    },
    [signOut.fulfilled.type]: (state, action) => {
      state.authState = action.payload;},
    [signOut.rejected.type]: (state, action) => {
      state.authState = action.payload;
    },
    // Reducers: getIAuthFromStorage
    [getIAuthFromStorage.pending.type]: (state, action) => {
      state.authState.isLoggedIn = AuthState.loading;
    },    
    [getIAuthFromStorage.fulfilled.type]: (state, action) => {
    },
    [getIAuthFromStorage.rejected.type]: (state, action) => {
      state.authState = action.payload;
    },
  }
});

export const { setIAuth } = authSlice.actions

export default authSlice.reducer;
