import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { useAppDispatch } from "../../../hooks/useRedux";
import {
  getDate,
  getFormattedDateString,
  getOnlyDateString,
  SERVER_TIME_ZONE,
} from "../../api/helpers";
import { List, setListItem } from "../../interfaces/helpers";
import { getEmptyEmpleado, IEmpleado } from "../../interfaces/personas";
import { RootState } from "../../store";
import { getMemoizedCodigoQrList, getNombreEmpleado } from "../personasSlice";

export interface IDefaultTable {
  loading: boolean;
  remove: boolean;
  searchText: string;
  page: number;
  totalPages: number;
  maxPerPage: number;
  selectAll: boolean;
  isIndeterminate: boolean;
  pageMessage: string;
}

export interface IEmpleadoData {
  primer_nombre: string;
  segundo_nombre: string;
  apellido_paterno: string;
  apellido_materno: string;
  telefono_celular: string;
  email: string;
}

export interface ICreateEmpleadoData extends IEmpleadoData {
  id_empresa: number;
  uuid: string;
  errors?: { id: number; msg: string }[];
}

export interface ICreateEmpleado {
  id_clienteQentry: number;
  empleados: ICreateEmpleadoData[];
}

export interface ITeamMembers {
  selectedEmpresa: List;
  empleadoStatus: List[];
  activeTable: {
    loading: boolean;
    remove: boolean;
    searchText: string;
    page: number;
    maxPerPage: number;
    selectedCentroDeTrabajo: List;
    selectedEmpleadoStatus: List;
    selectedEmpleados: List[];
    selectAll: boolean;
    isIndeterminate: boolean;
  };
  inactiveTable: {
    loading: boolean;
    activate: boolean;
    remove: boolean;
    searchText: string;
    page: number;
    maxPerPage: number;
    selectedCentroDeTrabajo: List;
    selectedEmpleados: List[];
    selectAll: boolean;
    isIndeterminate: boolean;
  };
  empleadoModal: IEmpleado;
}

export const teamMembersInitialState: ITeamMembers = {
  selectedEmpresa: { id: "", title: "Todas las empresas" },
  empleadoStatus: [
    {
      id: "0",
      title: "Todos los estados",
    },
    {
      id: "1",
      title: "Laborando",
    },
    {
      id: "2",
      title: "Con retardo",
    },
    {
      id: "3",
      title: "En tiempo extra",
    },
    {
      id: "4",
      title: "Ausente",
    },
    {
      id: "5",
      title: "Esperados",
    },
  ],
  activeTable: {
    loading: false,
    remove: false,
    searchText: "",
    page: 1,
    maxPerPage: 10,
    selectedCentroDeTrabajo: { id: "", title: "Todos los centros de trabajo" },
    selectedEmpleadoStatus: { id: "", title: "Todos los estados" },
    selectedEmpleados: [],
    selectAll: false,
    isIndeterminate: false,
  },
  inactiveTable: {
    loading: false,
    activate: false,
    remove: false,
    searchText: "",
    page: 1,
    maxPerPage: 10,
    selectedCentroDeTrabajo: { id: "", title: "Todos los centros de trabajo" },
    selectedEmpleados: [],
    selectAll: false,
    isIndeterminate: false,
  },
  empleadoModal: getEmptyEmpleado(),
};

export const teamMembersSlice = createSlice({
  name: "teamMembers",
  initialState: teamMembersInitialState,
  reducers: {
    setTeamMembersSelectedEmpresa: (state, action: PayloadAction<List>) => {
      state.activeTable = teamMembersInitialState.activeTable;
      state.inactiveTable = teamMembersInitialState.inactiveTable;
      state.selectedEmpresa = action.payload;
    },
    // Emppleado Modal
    setTeamMembersEmpleadoModal: (state, action: PayloadAction<IEmpleado>) => {
      console.log("setTeamMembersEmpleadoModal", action.payload);
      state.empleadoModal = action.payload;
    },
    setTeamMembersEmpleadoModalDatosPersonalesPrimerNombre: (
      state,
      action: PayloadAction<string>
    ) => {
      state.empleadoModal.datos_personales.primer_nombre = action.payload;
    },
    setTeamMembersEmpleadoModalDatosPersonalesGenero: (
      state,
      action: PayloadAction<List>
    ) => {
      const value = {
        id: Number(action.payload.id),
        dato: action.payload.title,
      };
      state.empleadoModal.datos_personales.genero = {
        ...state.empleadoModal.datos_personales.genero,
        ...value,
      };
    },
    setTeamMembersEmpleadoModalDatosPersonalesEstadoCivil: (
      state,
      action: PayloadAction<List>
    ) => {
      const value = {
        id: Number(action.payload.id),
        dato: action.payload.title,
      };
      state.empleadoModal.datos_personales.estado_civil = {
        ...state.empleadoModal.datos_personales.estado_civil,
        ...value,
      };
    },

    // Active Empleados Table
    setTeamMembersActiveLoading: (state, action: PayloadAction<boolean>) => {
      if (action.payload !== state.activeTable.loading)
        state.activeTable.loading = action.payload;
    },
    setTeamMembersActiveSelectAllStatus: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.activeTable.selectAll = action.payload;
    },
    setTeamMembersActiveRemoveStatus: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.activeTable.remove = action.payload;
      state.activeTable.selectedEmpleados = [];
    },
    addTeamMembersActiveSelectedEmpleado: (
      state,
      action: PayloadAction<{
        item: List;
        activeSearchResult: IEmpleado[];
        centro: List;
      }>
    ) => {
      if (state.activeTable.selectedEmpleados.length < 1)
        state.activeTable.selectedEmpleados.push(action.payload.item);
      else {
        const index = state.activeTable.selectedEmpleados.findIndex(
          (e) => e.id === action.payload.item.id
        );
        if (index === -1)
          state.activeTable.selectedEmpleados.push(action.payload.item);
      }
      const hasAll = action.payload.activeSearchResult.every(
        (e) =>
          state.activeTable.selectedEmpleados.findIndex(
            (v) => v.id === e.id.toString()
          ) > -1
      );
      if (state.activeTable.selectedEmpleados.length > 0 && !hasAll)
        state.activeTable.isIndeterminate = true;
      else if (hasAll) {
        state.activeTable.isIndeterminate = false;
        state.activeTable.selectAll = true;
      }
    },
    removeTeamMembersActiveSelectedEmpleado: (
      state,
      action: PayloadAction<List>
    ) => {
      if (state.activeTable.selectedEmpleados.length < 1) return;
      const index = state.activeTable.selectedEmpleados.findIndex(
        (e) => e.id === action.payload.id
      );
      if (index !== -1) state.activeTable.selectedEmpleados.splice(index, 1);
      if (state.activeTable.selectedEmpleados.length > 0)
        state.activeTable.isIndeterminate = true;
      else {
        state.activeTable.isIndeterminate = false;
        state.activeTable.selectAll = false;
      }
    },
    addAllTeamMembersActiveSelectedEmpleado: (
      state,
      action: PayloadAction<{
        item: List[];
        activeSearchResult: IEmpleado[];
        centro: List;
        searchValue: string;
      }>
    ) => {
      const hasAll = action.payload.activeSearchResult.every(
        (e) =>
          state.activeTable.selectedEmpleados.findIndex(
            (v) => v.id === e.id.toString()
          ) > -1
      );
      state.activeTable.selectAll = hasAll;
      if (hasAll) {
        state.activeTable.isIndeterminate = false;
      } else if (state.activeTable.selectedEmpleados.length > 0) {
        state.activeTable.isIndeterminate = true;
      } else {
        state.activeTable.isIndeterminate = false;
      }
      if (
        action.payload.centro.id !==
        state.activeTable.selectedCentroDeTrabajo.id
      )
        return;
      if (action.payload.searchValue !== state.activeTable.searchText) return;
      if (action.payload.item.length === 0) return;
      if (state.activeTable.selectedEmpleados.length < 1) {
        state.activeTable.selectedEmpleados = action.payload.item;
      } else {
        action.payload.item.forEach((e) => {
          const index = state.activeTable.selectedEmpleados.findIndex(
            (v) => v.id === e.id
          );
          if (index === -1) state.activeTable.selectedEmpleados.push(e);
        });
      }
    },
    removeAllTeamMembersActiveSelectedEmpleado: (state) => {
      state.activeTable.selectAll = false;
      state.activeTable.isIndeterminate = false;
      state.activeTable.selectedEmpleados = [];
    },
    setTeamMembersActiveCentroDeTrabajo: (
      state,
      action: PayloadAction<List>
    ) => {
      state.activeTable.page = 1;
      state.activeTable.selectedCentroDeTrabajo = action.payload;
    },
    setTeamMembersActiveEmpleadoStatus: (
      state,
      action: PayloadAction<List>
    ) => {
      state.activeTable.page = 1;
      state.activeTable.selectedEmpleadoStatus = action.payload;
    },
    setTeamMembersActiveSearchValue: (state, action: PayloadAction<string>) => {
      state.activeTable.searchText = action.payload;
    },
    resetTeamMembersActivePage: (state) => {
      if (!state.activeTable.loading) state.activeTable.loading = true;
      state.activeTable.page = 1;
    },
    activeTeamMembersNextPage: (state, action: PayloadAction<number>) => {
      if (state.activeTable.page < action.payload) state.activeTable.page++;
    },
    activeTeamMembersPreviousPage: (state) => {
      if (state.activeTable.page > 1) state.activeTable.page--;
    },
    // Inactive Empleados Table
    setTeamMembersInactiveLoading: (state, action: PayloadAction<boolean>) => {
      if (action.payload !== state.inactiveTable.loading)
        state.inactiveTable.loading = action.payload;
    },
    setTeamMembersInactiveSelectAllStatus: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.inactiveTable.selectAll = action.payload;
    },
    setTeamMembersInactiveActivateStatus: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.inactiveTable.activate = action.payload;
      state.inactiveTable.remove = false;
      state.inactiveTable.selectedEmpleados = [];
    },
    setTeamMembersInactiveRemoveStatus: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.inactiveTable.activate = false;
      state.inactiveTable.remove = action.payload;
      state.inactiveTable.selectedEmpleados = [];
    },
    addTeamMembersInactiveSelectedEmpleado: (
      state,
      action: PayloadAction<{
        item: List;
        inactiveSearchResult: IEmpleado[];
        centro: List;
      }>
    ) => {
      if (state.inactiveTable.selectedEmpleados.length < 1)
        state.inactiveTable.selectedEmpleados.push(action.payload.item);
      else {
        const index = state.inactiveTable.selectedEmpleados.findIndex(
          (e) => e.id === action.payload.item.id
        );
        if (index === -1)
          state.inactiveTable.selectedEmpleados.push(action.payload.item);
      }
      const hasAll = action.payload.inactiveSearchResult.every(
        (e) =>
          state.inactiveTable.selectedEmpleados.findIndex(
            (v) => v.id === e.id.toString()
          ) > -1
      );
      if (state.inactiveTable.selectedEmpleados.length > 0 && !hasAll)
        state.inactiveTable.isIndeterminate = true;
      else if (hasAll) {
        state.inactiveTable.isIndeterminate = false;
        state.inactiveTable.selectAll = true;
      }
    },
    removeTeamMembersInactiveSelectedEmpleado: (
      state,
      action: PayloadAction<List>
    ) => {
      if (state.inactiveTable.selectedEmpleados.length < 1) return;
      const index = state.inactiveTable.selectedEmpleados.findIndex(
        (e) => e.id === action.payload.id
      );
      if (index !== -1) state.inactiveTable.selectedEmpleados.splice(index, 1);
      if (state.inactiveTable.selectedEmpleados.length > 0)
        state.inactiveTable.isIndeterminate = true;
      else {
        state.inactiveTable.isIndeterminate = false;
        state.inactiveTable.selectAll = false;
      }
    },
    addAllTeamMembersInactiveSelectedEmpleado: (
      state,
      action: PayloadAction<{
        item: List[];
        inactiveSearchResult: IEmpleado[];
        centro: List;
        searchValue: string;
      }>
    ) => {
      const hasAll = action.payload.inactiveSearchResult.every(
        (e) =>
          state.inactiveTable.selectedEmpleados.findIndex(
            (v) => v.id === e.id.toString()
          ) > -1
      );
      state.inactiveTable.selectAll = hasAll;
      if (hasAll) {
        state.inactiveTable.isIndeterminate = false;
      } else if (state.inactiveTable.selectedEmpleados.length > 0) {
        state.inactiveTable.isIndeterminate = true;
      } else {
        state.inactiveTable.isIndeterminate = false;
      }
      if (
        action.payload.centro.id !==
        state.inactiveTable.selectedCentroDeTrabajo.id
      )
        return;
      if (action.payload.searchValue !== state.inactiveTable.searchText) return;
      if (action.payload.item.length === 0) return;
      if (state.inactiveTable.selectedEmpleados.length < 1) {
        state.inactiveTable.selectedEmpleados = action.payload.item;
      } else {
        action.payload.item.forEach((e) => {
          const index = state.inactiveTable.selectedEmpleados.findIndex(
            (v) => v.id === e.id
          );
          if (index === -1) state.inactiveTable.selectedEmpleados.push(e);
        });
      }
    },
    removeAllTeamMembersInactiveSelectedEmpleado: (state) => {
      state.inactiveTable.selectAll = false;
      state.inactiveTable.isIndeterminate = false;
      state.inactiveTable.selectedEmpleados = [];
    },
    setTeamMembersInactiveCentroDeTrabajo: (
      state,
      action: PayloadAction<List>
    ) => {
      state.inactiveTable.page = 1;
      state.inactiveTable.selectedCentroDeTrabajo = action.payload;
    },
    setTeamMembersInactiveSearchValue: (
      state,
      action: PayloadAction<string>
    ) => {
      state.inactiveTable.searchText = action.payload;
    },
    resetTeamMembersInactivePage: (state) => {
      if (!state.inactiveTable.loading) state.inactiveTable.loading = true;
      state.inactiveTable.page = 1;
    },
    inactiveTeamMembersNextPage: (state, action: PayloadAction<number>) => {
      if (state.inactiveTable.page < action.payload) state.inactiveTable.page++;
    },
    inactiveTeamMembersPreviousPage: (state) => {
      if (state.inactiveTable.page > 1) state.inactiveTable.page--;
    },
  },
});

export const {
  setTeamMembersSelectedEmpresa,
  // Empleado Modal
  setTeamMembersEmpleadoModal,
  setTeamMembersEmpleadoModalDatosPersonalesPrimerNombre,
  setTeamMembersEmpleadoModalDatosPersonalesGenero,
  setTeamMembersEmpleadoModalDatosPersonalesEstadoCivil,
  // Active Empleados Table
  setTeamMembersActiveLoading,
  setTeamMembersActiveSelectAllStatus,
  setTeamMembersActiveRemoveStatus,
  addTeamMembersActiveSelectedEmpleado,
  removeTeamMembersActiveSelectedEmpleado,
  addAllTeamMembersActiveSelectedEmpleado,
  removeAllTeamMembersActiveSelectedEmpleado,
  setTeamMembersActiveCentroDeTrabajo,
  setTeamMembersActiveEmpleadoStatus,
  setTeamMembersActiveSearchValue,
  resetTeamMembersActivePage,
  activeTeamMembersNextPage,
  activeTeamMembersPreviousPage,
  // Inactive Empleados Table
  setTeamMembersInactiveLoading,
  setTeamMembersInactiveActivateStatus,
  setTeamMembersInactiveSelectAllStatus,
  setTeamMembersInactiveRemoveStatus,
  addTeamMembersInactiveSelectedEmpleado,
  removeTeamMembersInactiveSelectedEmpleado,
  addAllTeamMembersInactiveSelectedEmpleado,
  removeAllTeamMembersInactiveSelectedEmpleado,
  setTeamMembersInactiveCentroDeTrabajo,
  setTeamMembersInactiveSearchValue,
  resetTeamMembersInactivePage,
  inactiveTeamMembersNextPage,
  inactiveTeamMembersPreviousPage,
} = teamMembersSlice.actions;

export default teamMembersSlice.reducer;

///////////////////////////////////////////////////////////////////////////////////////////////
export const getMemoizedTeamMembersActiveEmpleado = createSelector(
  (state: RootState) => state.teamMembers.selectedEmpresa,
  (state: RootState) => state.personas.personasState.empleado.data_array,
  (selectedEmpresa, empleados) => {
    if (selectedEmpresa.id === "") return empleados.filter((e) => e.activo);
    const empresa = Number(selectedEmpresa.id);
    return empleados.filter((e) => e.empresa.id === empresa && e.activo);
  }
);

export const getMemoizedTeamMembersActiveSearch = createSelector(
  getMemoizedTeamMembersActiveEmpleado,
  (state: RootState) => state.teamMembers.activeTable.searchText,
  (state: RootState) => state.teamMembers.activeTable.selectedCentroDeTrabajo,
  (state: RootState) => state.teamMembers.activeTable.selectedEmpleadoStatus,
  (state: RootState) => state.nomina.nominaState.asistencia.data_array,
  (empleados, searchText, centroDeTrabajo, empleadoStatus, asistencia) => {
    const search = searchText.toLocaleLowerCase().trim();
    if (!search && centroDeTrabajo.id === "" && empleadoStatus.id === "")
      return empleados;
    const centro = Number(centroDeTrabajo.id);
    const status = Number(empleadoStatus.id);

    const asistenciaHoy = asistencia.filter(
      (e) =>
        getOnlyDateString(e.momento_registro_asistencia) === getOnlyDateString()
    );
    const empleadoAsistencia = empleados.map((e) => {
      const empleadoAsis = asistenciaHoy.filter((v) => v.empleado.id === e.id);
      const empleadoAsisEntrada = empleadoAsis.filter(
        (v) => v.tipo_movimiento_asistencia.id === 1
      );
      const empleadoAsisSalida = empleadoAsis.filter(
        (v) => v.tipo_movimiento_asistencia.id === 2
      );
      return {
        id_empleado: e.id,
        reg: {
          entrada: empleadoAsisEntrada,
          salida: empleadoAsisSalida,
        },
      };
    });

    return empleados.filter((e) => {
      if (
        centro !== 0 &&
        e.centro_trabajo.findIndex((e) => e.id === centro) === -1
      )
        return false;

      const asisEmpleado = empleadoAsistencia.find(
        (v) => v.id_empleado === e.id
      );

      if (
        status === 1 &&
        (!asisEmpleado ||
          asisEmpleado.reg.entrada.length !== 1 ||
          asisEmpleado.reg.salida.length > 0)
      )
        return false;

      if (
        status === 2 &&
        (!asisEmpleado ||
          asisEmpleado.reg.entrada.length <= asisEmpleado.reg.salida.length ||
          asisEmpleado.reg.entrada[asisEmpleado.reg.entrada.length - 1]
            .asistencia_puntual)
      )
        return false;

      if (
        status === 3 &&
        (!asisEmpleado ||
          asisEmpleado.reg.entrada.length <= asisEmpleado.reg.salida.length)
      )
        return false;

      if (!search) return true;

      if (
        status === 4 &&
        (!asisEmpleado ||
          asisEmpleado.reg.entrada.length <= asisEmpleado.reg.salida.length)
      )
        return true;

      return getNombreEmpleado(e).toLocaleLowerCase().includes(search);
    });
  }
);

export const getMemoizedTeamMembersActiveTotalPages = createSelector(
  getMemoizedTeamMembersActiveSearch,
  (state: RootState) => state.teamMembers.activeTable.maxPerPage,
  (empleados, maxPerPage) => {
    let value = Math.trunc(empleados.length / maxPerPage);
    if (empleados.length % maxPerPage > 0) value++;
    return value;
  }
);

export const getMemoizedTeamMembersActiveEmpleadoLength = createSelector(
  getMemoizedTeamMembersActiveSearch,
  (empleados) => empleados.length
);

export const getMemoizedTeamMembersActivePageMessage = createSelector(
  getMemoizedTeamMembersActiveSearch,
  (state: RootState) => state.teamMembers.activeTable.page,
  (state: RootState) => state.teamMembers.activeTable.maxPerPage,
  (empleados, page, maxPerPage) => {
    const firstItem = (page - 1) * maxPerPage;
    const lastItem = firstItem + maxPerPage - 1;
    const length = empleados.length;
    return `${firstItem + 1} - ${
      length < lastItem ? length : lastItem + 1
    } de ${length}`;
  }
);
////////////////////////////////////////////////////////////////////////////////////////////////

export const getMemoizedTeamMembersInactiveEmpleado = createSelector(
  (state: RootState) => state.teamMembers.selectedEmpresa,
  (state: RootState) => state.personas.personasState.empleado.data_array,
  (selectedEmpresa, empleados) => {
    if (selectedEmpresa.id === "") return empleados.filter((e) => !e.activo);
    const empresa = Number(selectedEmpresa.id);
    return empleados.filter((e) => e.empresa.id === empresa && !e.activo);
  }
);

export const getMemoizedTeamMembersInactiveSearch = createSelector(
  getMemoizedTeamMembersInactiveEmpleado,
  (state: RootState) => state.teamMembers.inactiveTable.searchText,
  (state: RootState) => state.teamMembers.inactiveTable.selectedCentroDeTrabajo,
  (empleados, searchText, centroDeTrabajo) => {
    const search = searchText.toLocaleLowerCase().trim();
    if (!search && centroDeTrabajo.id === "") return empleados;
    const centro = Number(centroDeTrabajo.id);
    return empleados.filter((e) => {
      if (
        centro !== 0 &&
        e.centro_trabajo.findIndex((e) => e.id === centro) === -1
      )
        return false;
      if (!search) return true;
      return getNombreEmpleado(e).toLocaleLowerCase().includes(search);
    });
  }
);

export const getMemoizedTeamMembersInactiveTotalPages = createSelector(
  getMemoizedTeamMembersInactiveSearch,
  (state: RootState) => state.teamMembers.activeTable.maxPerPage,
  (empleados, maxPerPage) => {
    let value = Math.trunc(empleados.length / maxPerPage);
    if (empleados.length % maxPerPage === 0) value++;
    return value;
  }
);

export const getMemoizedTeamMembersInactiveEmpleadoLength = createSelector(
  getMemoizedTeamMembersInactiveSearch,
  (empleados) => empleados.length
);

export const getMemoizedTeamMembersInactivePageMessage = createSelector(
  getMemoizedTeamMembersInactiveSearch,
  (state: RootState) => state.teamMembers.inactiveTable.page,
  (state: RootState) => state.teamMembers.activeTable.maxPerPage,
  (empleados, page, maxPerPage) => {
    const firstItem = (page - 1) * maxPerPage;
    const lastItem = firstItem + maxPerPage - 1;
    const length = empleados.length;
    return `${firstItem + 1} - ${
      length < lastItem ? length : lastItem + 1
    } de ${length}`;
  }
);

// Empleado Edit Modal
export const getMemoizedTeamMembersSelectedGenero = createSelector(
  (state: RootState) => state.personas.personasState.mvGenero.data_array,
  (state: RootState) => state.teamMembers.empleadoModal.datos_personales.genero,
  (generoList, genero) => {
    if (genero.id === 0 && generoList[0])
      return setListItem({
        id: generoList[0].id.toString(),
        title: generoList[0].dato,
      });
    return setListItem({ id: genero.id.toString(), title: genero.dato });
  }
);

export const getMemoizedTeamMembersSelectedEstadoCivil = createSelector(
  (state: RootState) => state.personas.personasState.mvEstadoCivil.data_array,
  (state: RootState) =>
    state.teamMembers.empleadoModal.datos_personales.estado_civil,
  (estadoCivilList, estadoCivil) => {
    if (estadoCivil.id === 0 && estadoCivilList[0])
      return setListItem({
        id: estadoCivilList[0].id.toString(),
        title: estadoCivilList[0].dato,
      });
    return setListItem({
      id: estadoCivil.id.toString(),
      title: estadoCivil.dato,
    });
  }
);

export const getMemoizedTeamMembersSelectedUsoFactura = createSelector(
  (state: RootState) => state.personas.personasState.mvUsoFactura.data_array,
  (state: RootState) => state.teamMembers.empleadoModal.datos_fiscales,
  (state: RootState, itemId: number) => itemId,
  (usoFacturaList, usoFactura, usoFacturaId) => {
    const nomina = usoFacturaList.findIndex((e) => e.id === 23);
    const id = usoFactura.findIndex((e) => e.id === usoFacturaId);

    if (id < 0)
      return setListItem({
        id: usoFacturaList[nomina].id.toString(),
        title: `${usoFacturaList[nomina].clave} -  ${usoFacturaList[nomina].dato}`,
      });
    return setListItem({
      id: usoFactura[id].uso_factura.id.toString(),
      title: `${usoFactura[id].uso_factura.clave} -  ${usoFactura[id].uso_factura.dato}`,
    });
  }
);

export const getMemoizedTeamMembersSelectedBanco = createSelector(
  (state: RootState) => state.personas.personasState.mvBanco.data_array,
  (state: RootState) =>
    state.teamMembers.empleadoModal.informacion_bancaria.banco,
  (bancoList, banco) => {
    if (banco.id === 0 && bancoList[0])
      return setListItem({
        id: bancoList[0].id.toString(),
        title: bancoList[0].dato,
      });
    return setListItem({ id: banco.id.toString(), title: banco.dato });
  }
);

export const getMemoizedEmpleadoWithExceptionAsSelectList = createSelector(
  getMemoizedTeamMembersActiveEmpleado,
  (state: RootState, exceptionId: number) => exceptionId,
  (empleado, exceptionId) => {
    const items = empleado
      .filter(
        (e) =>
          e.id !== exceptionId ||
          e.empleados_a_cargo.some((v) => v.id === exceptionId)
      )
      .map((e) =>
        setListItem({
          id: e.id.toString(),
          title: getNombreEmpleado(e),
        })
      );
    items.sort((a, b) => {
      return a.title > b.title ? 1 : a.title < b.title ? -1 : 0;
    });
    return items;
  }
);

export const getMemoizedTeamMembersSelectedEmpleados = createSelector(
  (state: RootState, exceptionId: number) =>
    getMemoizedEmpleadoWithExceptionAsSelectList(state, exceptionId),
  (state: RootState) => state.teamMembers.empleadoModal.empleados_a_cargo,
  (empleados, empleadosCargo) => {
    if (empleadosCargo.length === 0) return [] as List[];
    return empleados.filter(
      (e) => empleadosCargo.findIndex((v) => v.id.toString() === e.id) !== -1
    );
  }
);

export const getMemoizedTeamMembersSelectedCodigoQr = createSelector(
  getMemoizedCodigoQrList,
  (state: RootState) => state.teamMembers.empleadoModal.codigos_qr_asignados,
  (codigoQrList, codigoQr) => {
    if (codigoQr.length === 0) return [] as List[];
    return codigoQrList.filter(
      (e) => codigoQr.findIndex((v) => v.id.toString() === e.id) !== -1
    );
  }
);

export const getMemoizedTeamMembersSelectedTipoEmpleado = createSelector(
  (state: RootState) => state.personas.personasState.mvTipoEmpleado.data_array,
  (state: RootState) => state.teamMembers.empleadoModal.tipo_empleado,
  (mvTipoEmpleadoList, mvTipoEmpleado) => {
    let id = mvTipoEmpleadoList.findIndex((e) => e.id === 2);
    if (id < 0) id = 0;
    if (mvTipoEmpleado.id === 0 && mvTipoEmpleadoList[id])
      return setListItem({
        id: mvTipoEmpleadoList[id].id.toString(),
        title: mvTipoEmpleadoList[id].dato,
      });
    return setListItem({
      id: mvTipoEmpleado.id.toString(),
      title: mvTipoEmpleado.dato,
    });
  }
);

export const getMemoizedTeamMembersSelectedPais = createSelector(
  (state: RootState) => state.personas.personasState.pais.data_array,
  (state: RootState) =>
    state.teamMembers.empleadoModal.datos_personales.direccion.pais,
  (paisList, pais) => {
    if (pais.id === 0 && paisList[0])
      return setListItem({
        id: paisList[0].id.toString(),
        title: paisList[0].nombre,
      });
    return setListItem({ id: pais.id.toString(), title: pais.nombre });
  }
);

export const getMemoizedTeamMembersSelectedEstado = createSelector(
  (state: RootState) => state.personas.personasState.estado.data_array,
  (state: RootState) =>
    state.teamMembers.empleadoModal.datos_personales.direccion.estado,
  getMemoizedTeamMembersSelectedPais,
  (estadoList, estado, pais) => {
    const tempList = estadoList.filter((e) => e.pais.id.toString() === pais.id);
    if (!tempList)
      return setListItem({
        id: "0",
        title: "",
      });
    if (tempList.find((e) => e.id === estado.id))
      return setListItem({ id: estado.id.toString(), title: estado.nombre });
    return setListItem({
      id: tempList[0].id.toString(),
      title: tempList[0].nombre,
    });
  }
);
