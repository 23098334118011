/**
 * Métodos AsyncThunk para ser usados en los reducers y
 * los resultados a las llamadas http puedan ser usados para
 * actualizar el state en redux
 */

import { createAsyncThunk } from "@reduxjs/toolkit";
import { DEFAULT_STATE } from "../../enums/default_state";
import { getCanalVenta, getAllCanalVenta, updateCanalVenta, createCanalVenta, deleteCanalVenta } from "../../http/personas";
import { getEmptyStatusCanalVenta, ICanalVenta } from "../../interfaces/personas";
import { IServerDate } from "../../interfaces/time";

export const getCanalVentaAsyncThunk = createAsyncThunk(
  'personas/getCanalVentaAsyncThunk',
  async (query : {serverDate : IServerDate, id: number}) => await getCanalVenta(query.serverDate, query.id)
    .then(async (result) => result)
    .catch(async (error) => getEmptyStatusCanalVenta(DEFAULT_STATE.error))  
);

export const getAllCanalVentaAsyncThunk = createAsyncThunk(
  'personas/getAllCanalVentaAsyncThunk',
  async (queryAll : {serverDate : IServerDate}) => await getAllCanalVenta(queryAll.serverDate)
    .then(async (result) => result)
    .catch(async (error) => getEmptyStatusCanalVenta(DEFAULT_STATE.error))  
);

export const updateCanalVentaAsyncThunk = createAsyncThunk(
  'personas/updateCanalVentaAsyncThunk',
  async (update: {serverDate : IServerDate, id: number, CanalVenta: ICanalVenta}) => await updateCanalVenta(update.serverDate, update.id, update.CanalVenta)
    .then(async (result) => result)
    .catch(async (error) => getEmptyStatusCanalVenta(DEFAULT_STATE.error))  
);

export const createCanalVentaAsyncThunk = createAsyncThunk(
  'personas/createCanalVentaAsyncThunk',
  async (create : {serverDate : IServerDate, CanalVenta: ICanalVenta}) => await createCanalVenta(create.serverDate, create.CanalVenta)
    .then(async (result) => result)
    .catch(async (error) => getEmptyStatusCanalVenta(DEFAULT_STATE.error))  
);

export const deleteCanalVentaAsyncThunk = createAsyncThunk(
  'personas/deleteCanalVentaAsyncThunk',
  async (del : {id : number}) => await deleteCanalVenta(del.id)
    .then(async (result) => result)
    .catch(async (error) => getEmptyStatusCanalVenta())  
);
