/**
 * Métod post para llamada a la API
 */


import { post } from "../../api/api";
import { DEFAULT_STATE } from "../../enums/default_state";
import { ILocation } from "../../interfaces/auth/ILocation";
import { getEmptyStatusServerDate, setServerDate } from "../../interfaces/time/IServerDate";
import { setStorageServerDate } from "../../localStorage/time/ServerDate";

export const getServerDate = async (coordinates: ILocation) => {
  const temp = getEmptyStatusServerDate();
  const result = await post(`nomina/helpers/fecha-hora-coordenadas`, coordinates);
  const data = result.data;
  if (data.detail){
    console.log(`Error al obtener la fecha y hora.`);
    temp.estado = DEFAULT_STATE.error;
    temp.mensaje = "Error al obtener la fecha y hora";
    return temp;
  }
  console.log(`ServerDate obtenido con éxito`);
  temp.estado = DEFAULT_STATE.success;
  temp.mensaje = `ServerDate obtenido con éxito`;
  temp.data = setServerDate(data);
  await setStorageServerDate(temp.data);
  return temp;
}
