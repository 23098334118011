/**
 * Métodos AsyncThunk para ser usados en los reducers y
 * los resultados a las llamadas http puedan ser usados para
 * actualizar el state en redux
 */

import { createAsyncThunk } from "@reduxjs/toolkit";
import { DEFAULT_STATE } from "../../enums/default_state";
import { getRondinCancelado, getAllRondinCancelado, updateRondinCancelado, createRondinCancelado, deleteRondinCancelado } from "../../http/rondines";
import { getEmptyStatusRondinCancelado, IRondinCancelado } from "../../interfaces/rondines";
import { IServerDate } from "../../interfaces/time";

export const getRondinCanceladoAsyncThunk = createAsyncThunk(
  'rondines/getRondinCanceladoAsyncThunk',
  async (query : {serverDate : IServerDate, id: number}) => await getRondinCancelado(query.serverDate, query.id)
    .then(async (result) => result)
    .catch(async (error) => getEmptyStatusRondinCancelado(DEFAULT_STATE.error))  
);

export const getAllRondinCanceladoAsyncThunk = createAsyncThunk(
  'rondines/getAllRondinCanceladoAsyncThunk',
  async (queryAll : {serverDate : IServerDate, idCliente : number}) => await getAllRondinCancelado(queryAll.serverDate, queryAll.idCliente)
    .then(async (result) => result)
    .catch(async (error) => getEmptyStatusRondinCancelado(DEFAULT_STATE.error))  
);

export const updateRondinCanceladoAsyncThunk = createAsyncThunk(
  'rondines/updateRondinCanceladoAsyncThunk',
  async (update: {serverDate : IServerDate, id: number, RondinCancelado: IRondinCancelado}) => await updateRondinCancelado(update.serverDate, update.id, update.RondinCancelado)
    .then(async (result) => result)
    .catch(async (error) => getEmptyStatusRondinCancelado(DEFAULT_STATE.error))  
);

export const createRondinCanceladoAsyncThunk = createAsyncThunk(
  'rondines/createRondinCanceladoAsyncThunk',
  async (create : {serverDate : IServerDate, idCliente : number, RondinCancelado: IRondinCancelado}) => await createRondinCancelado(create.serverDate, create.idCliente, create.RondinCancelado)
    .then(async (result) => result)
    .catch(async (error) => getEmptyStatusRondinCancelado(DEFAULT_STATE.error))  
);

export const deleteRondinCanceladoAsyncThunk = createAsyncThunk(
  'rondines/deleteRondinCanceladoAsyncThunk',
  async (del : {id : number}) => await deleteRondinCancelado(del.id)
    .then(async (result) => result)
    .catch(async (error) => getEmptyStatusRondinCancelado())  
);
