/**
 * Métodos para el manejo de IonicStorage 
 * @setStorage              guarda un unico objeto al storage
 * @getStorage              obtiene el objeto guardado con @setStorage
 * @setStorageArray         guarda el array de objetos en el storage
 * @getStorageArray         obtiene el array de objetos guardado con @setStorageArray
 * @removeStorageArray      elimina el array de objetos guardado con @setStorageArray
 * @setStorageItemArray     agrega un elemento al array de objeto guardado con @setStorageArray
 * @getStorageItemArray     obtiene un elemento del array guardado con @setStorageArray buscandolo por su id 
 * @removeStorageItemArray  elimina un elemento del array guardado con @setStorageArray buscandolo por su id 
 */

import { setStorageData, getStorageData, removeStorageData, setArrayItemStorageData, getArrayItemStorageData, removeArrayItemStorageData } from "../../api/storage";
import { ICheckEnRuta, getEmptyCheckEnRuta } from "../../interfaces/rondines";

export const setStorageCheckEnRuta = async (ICheckEnRuta : ICheckEnRuta) => await setStorageData('ICheckEnRuta', ICheckEnRuta);
export const getStorageCheckEnRuta = async () : Promise<ICheckEnRuta> => await getStorageData('ICheckEnRuta', getEmptyCheckEnRuta());
export const removeStorageCheckEnRuta = async () => await removeStorageData('ICheckEnRuta');
export const setStorageCheckEnRutaArray = async (ICheckEnRuta : ICheckEnRuta[]) => await setStorageData('ICheckEnRutaArray', ICheckEnRuta);
export const getStorageCheckEnRutaArray = async () : Promise<ICheckEnRuta[]> => await getStorageData('ICheckEnRutaArray', [] as ICheckEnRuta[]);
export const removeStorageCheckEnRutaArray = async () => await removeStorageData('ICheckEnRutaArray');
export const setStorageCheckEnRutaArrayItem = async (id: number, ICheckEnRuta : ICheckEnRuta) : Promise<ICheckEnRuta[]> => await setArrayItemStorageData('ICheckEnRutaArray', id, ICheckEnRuta, [] as ICheckEnRuta[]);
export const getStorageCheckEnRutaArrayItem = async (id: number) : Promise<ICheckEnRuta> => await getArrayItemStorageData('ICheckEnRutaArray', id, getEmptyCheckEnRuta);
export const removeStorageCheckEnRutaArrayItem = async (id: number) : Promise<ICheckEnRuta[]> => await removeArrayItemStorageData('ICheckEnRutaArray', id, [] as ICheckEnRuta[]);
