/**
 * Es el objeto Map para manejar los estados de los Marker y el SearchBox; [GoogleMapsPlataform] https://developers.google.com/maps/documentation/javascript/react-map#typescript_2
 * @deepCompareEqualsForMaps es en método para compara ubicaciones de google maps siendo estas de tipo @interface google.maps.LatLng
 * @useDeepCompareMemoize guarda em memoria el estado de la comparación de @deepCompareEqualsForMaps
 * @useDeepCompareEffectForMaps funciona como un useEffect de React pero las dependencias verifica si se modificaron usando @useDeepCompareMemoize
 * @interface MapProps
 *   @param {{ [key: string]: string }} style: se utiliza para agregar estilos al mapa
 *   @param {boolean} hasSearchBox: se utiliza para crear o no el input de busqueda de ubicaciones
 *   @param {Function} onClick: es el método a realizar cuando se hace clic al mapa
 *   @param {Function} onIdle: es el método a realizar cuando el mapa se encuentra inactivo
 *   @param {Function} onPlaceAdded: es el método a realizar para generar nuevos Markers.
 *   @param {string} searchText: es el texto a buscar en el SearchBox
 * @function onPlacesChanged crea el Marker cuando se selecciona una ubicación en el SearchBox.
 */

import React from "react";
import { useRef, useState, useEffect } from "react";
import { createCustomEqual } from "fast-equals";
import { isLatLngLiteral } from "@googlemaps/typescript-guards";
import "./Map.css";

const deepCompareEqualsForMaps = createCustomEqual(
  (deepEqual) => (a: any, b: any) => {
    if (
      isLatLngLiteral(a) ||
      a instanceof google.maps.LatLng ||
      isLatLngLiteral(b) ||
      b instanceof google.maps.LatLng
    ) {
      return new google.maps.LatLng(a).equals(new google.maps.LatLng(b));
    }

    // TODO extend to other types

    // use fast-equals for other objects
    return deepEqual(a, b);
  }
);

function useDeepCompareMemoize(value: any) {
  const ref = useRef();

  if (!deepCompareEqualsForMaps(value, ref.current)) {
    ref.current = value;
  }

  return ref.current;
}

function useDeepCompareEffectForMaps(
  callback: React.EffectCallback,
  dependencies: any[]
) {
  React.useEffect(callback, dependencies.map(useDeepCompareMemoize));
}

interface MapProps extends google.maps.MapOptions {
  style: { [key: string]: string };
  hasSearchBox: boolean;
  onClick?: (e: google.maps.MapMouseEvent) => void;
  onIdle?: (
    map: google.maps.Map,
    service?: google.maps.places.PlacesService
  ) => void;
  onPlaceAdded?: (place: google.maps.LatLng[]) => void;
  onPostalCodeFromPlace?: (postalCode: string) => void;
  searchText?: string;
}

const Map: React.FC<MapProps> = ({
  onClick,
  onIdle,
  onPlaceAdded,
  onPostalCodeFromPlace,
  children,
  style,
  hasSearchBox,
  searchText,
  ...options
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const [map, setMap] = useState<google.maps.Map>();
  const [searchBox, setSearchBox] = useState<google.maps.places.SearchBox>();
  const [service, setService] = useState<google.maps.places.PlacesService>();

  const postalCode = (place: google.maps.places.PlaceResult) => {
    if (!place.address_components || place.address_components.length === 0)
      return "";
    const res = place.address_components.find((e) =>
      e.types.includes("postal_code")
    );
    if (!res) return "";
    return res.long_name;
  };

  const getService = () => service;

  const onPlacesChanged = () => {
    console.log("onPlacesChanged");
    if (!searchBox || !map || !onPlaceAdded) return;
    const place = searchBox.getPlaces();
    if (!place || !place[0] || !place[0].geometry) return;
    console.log({ addr: place[0] });

    if (place[0].geometry.viewport) {
      map.fitBounds(place[0].geometry.viewport);
    } else {
      map.setCenter(place[0].geometry.location!);
      map.setZoom(5);
    }
    onPlaceAdded([place[0].geometry.location!]);
    if (onPostalCodeFromPlace) onPostalCodeFromPlace(postalCode(place[0]));
  };

  useEffect(() => {
    if (ref.current && !map) {
      setMap(new window.google.maps.Map(ref.current, {}));
    }
  }, [ref, map]);

  useDeepCompareEffectForMaps(() => {
    if (map) {
      map.setOptions(options);
      setService(new google.maps.places.PlacesService(map));
    }
  }, [map, options]);

  useEffect(() => {
    if (map && hasSearchBox && !searchBox) {
      var input = document.getElementById("pac-input")! as HTMLInputElement;
      // map.controls[google.maps.ControlPosition.TOP_LEFT].push(input);
      setSearchBox(new google.maps.places.SearchBox(input));
    }
  }, [map, hasSearchBox]);

  useEffect(() => {
    if (map && searchBox) {
      ["places_changed"].forEach((eventName) =>
        google.maps.event.clearListeners(searchBox, eventName)
      );
      if (onPlacesChanged) {
        google.maps.event.addListener(
          searchBox,
          "places_changed",
          onPlacesChanged
        );
      }
    }
  }, [map, searchBox]);

  useEffect(() => {
    if (map) {
      ["click", "idle"].forEach((eventName) =>
        google.maps.event.clearListeners(map, eventName)
      );

      if (onClick) {
        map.addListener("click", onClick);
      }

      if (onIdle) {
        map.addListener("idle", () => onIdle(map, service));
      }
    }
  }, [map, onClick, onIdle]);

  useEffect(() => {
    console.log("searchText: ", searchText);
    console.log("map: ", map);
    console.log("service: ", service);
    if (map && service && searchText && searchText.trim()) {
      console.log("findPlaceFromQuery: ");
      service.findPlaceFromQuery(
        {
          query: searchText,
          fields: ["name", "geometry"],
        },
        (results, status) => {
          console.log("status: ", status);
          if (status === google.maps.places.PlacesServiceStatus.OK) {
            console.log("results: ", results);
            if (!results) return;
            const place = results;
            if (!place || !place[0] || !place[0].geometry) return;
            if (place[0].geometry.viewport) {
              map!.fitBounds(place[0].geometry.viewport);
            } else {
              map!.setCenter(place[0].geometry.location!);
              map!.setZoom(5);
            }
            console.log({ addr2: place[0] });
            onPlaceAdded!([place[0].geometry.location!]);
            console.log("useEffect onPlacesChanged");
            if (onPostalCodeFromPlace)
              onPostalCodeFromPlace(postalCode(place[0]));
          }
        }
      );
    }
  }, [searchText]);

  return (
    <>
      {hasSearchBox ? (
        <input
          id="pac-input"
          className="controls"
          type="text"
          placeholder="Escriba la dirección para crear el centro de trabajo."
        />
      ) : (
        <></>
      )}

      <div ref={ref} style={style} />
      {React.Children.map(children, (child) => {
        if (React.isValidElement(child)) {
          // set the map prop on the child component
          // @ts-ignore
          return React.cloneElement(child, { map });
        }
      })}
    </>
  );
};

export default Map;
