/**
 * Métodos AsyncThunk para ser usados en los reducers y
 * los resultados a las llamadas http puedan ser usados para
 * actualizar el state en redux
 */

import { createAsyncThunk } from "@reduxjs/toolkit";
import { DEFAULT_STATE } from "../../enums/default_state";
import { getCambioEstatusAsistencia, getAllCambioEstatusAsistencia, updateCambioEstatusAsistencia, createCambioEstatusAsistencia, deleteCambioEstatusAsistencia } from "../../http/nomina";
import { getEmptyStatusCambioEstatusAsistencia, ICambioEstatusAsistencia } from "../../interfaces/nomina";
import { IServerDate } from "../../interfaces/time";

export const getCambioEstatusAsistenciaAsyncThunk = createAsyncThunk(
  'nomina/getCambioEstatusAsistenciaAsyncThunk',
  async (query : {serverDate : IServerDate, id: number, idCliente : number}) => await getCambioEstatusAsistencia(query.serverDate, query.id, query.idCliente)
    .then(async (result) => result)
    .catch(async (error) => getEmptyStatusCambioEstatusAsistencia(DEFAULT_STATE.error))  
);

export const getAllCambioEstatusAsistenciaAsyncThunk = createAsyncThunk(
  'nomina/getAllCambioEstatusAsistenciaAsyncThunk',
  async (queryAll : {serverDate : IServerDate, idCliente : number}) => await getAllCambioEstatusAsistencia(queryAll.serverDate, queryAll.idCliente)
    .then(async (result) => result)
    .catch(async (error) => getEmptyStatusCambioEstatusAsistencia(DEFAULT_STATE.error))  
);

export const updateCambioEstatusAsistenciaAsyncThunk = createAsyncThunk(
  'nomina/updateCambioEstatusAsistenciaAsyncThunk',
  async (update: {serverDate : IServerDate, id: number, idCliente : number, object: ICambioEstatusAsistencia}) => await updateCambioEstatusAsistencia(update.serverDate, update.id, update.idCliente, update.object)
    .then(async (result) => result)
    .catch(async (error) => getEmptyStatusCambioEstatusAsistencia(DEFAULT_STATE.error))  
);

export const createCambioEstatusAsistenciaAsyncThunk = createAsyncThunk(
  'nomina/createCambioEstatusAsistenciaAsyncThunk',
  async (create : {serverDate : IServerDate, idCliente : number, object: ICambioEstatusAsistencia}) => await createCambioEstatusAsistencia(create.serverDate, create.idCliente, create.object)
    .then(async (result) => result)
    .catch(async (error) => getEmptyStatusCambioEstatusAsistencia(DEFAULT_STATE.error))  
);

export const deleteCambioEstatusAsistenciaAsyncThunk = createAsyncThunk(
  'nomina/deleteCambioEstatusAsistenciaAsyncThunk',
  async (del : {id : number, idCliente : number}) => await deleteCambioEstatusAsistencia(del.id, del.idCliente)
    .then(async (result) => result)
    .catch(async (error) => getEmptyStatusCambioEstatusAsistencia())  
);
