import { createSlice, createSelector, PayloadAction } from "@reduxjs/toolkit";
import { personasReducers } from "../reducers/personas/";
import {
  getEmptyStatusCentroDeTrabajo,
  getEmptyStatusClienteQentry,
  getEmptyStatusCodigoPostal,
  getEmptyStatusCodigoQr,
  getEmptyStatusDireccion,
  getEmptyStatusDatosFiscales,
  getEmptyStatusEmpresa,
  getEmptyStatusEstado,
  getEmptyStatusGeolocalizacion,
  getEmptyStatusPais,
  getEmptyStatusUser,
  getEmptyStatusMvUsoFactura,
  getEmptyStatusMvTipoUsuario,
  ICodigoQr,
  getEmptyStatusDocumento,
  getEmptyStatusDatosPersonales,
  getEmptyStatusAntiguedadEmpleado,
  getEmptyStatusCanalVenta,
  getEmptyStatusEmpleado,
  getEmptyStatusImagen,
  getEmptyStatusInformacionBancaria,
  getEmptyStatusInformacionContrato,
  getEmptyStatusInformacionSuscripcion,
  getEmptyStatusMvAntiguedad,
  getEmptyStatusMvEstadoCivil,
  getEmptyStatusMvGenero,
  getEmptyStatusMvTipoDocumento,
  getEmptyStatusMvTipoEmpleado,
  getEmptyStatusMvBanco,
  getEmptyStatusModificacionSuscripcionQentry,
  getEmptyStatusModificadorNomina,
  getEmptyStatusMvEstatusSolicitudCambioTelefonoAutorizado,
  getEmptyStatusMvFormaDePagoSuscripcion,
  getEmptyStatusMvTipoCanalVenta,
  getEmptyStatusMvImpuestos,
  getEmptyStatusMvMotivoPagoSuscripcion,
  getEmptyStatusMvTipoServicioQentry,
  getEmptyStatusMvTipoSuscripcion,
  getEmptyStatusPagoSuscripcion,
  getEmptyStatusServicioQentry,
  getEmptyStatusSolicitudCambioTelefonoAutorizado,
  getEmptyStatusSuscripcionQentry,
  getEmptyStatusTipoDocumentosPorPuesto,
  IEmpleado,
  IDireccion,
  IDatosFiscales,
} from "../interfaces/personas";
import { RootState } from "../store";
import { getMemoizedTeamMembersActiveEmpleado } from "./pagesSlice/teamMembersSlice";
import { MakerData } from "../interfaces/helpers/MakerData";
import { setListItem } from "../interfaces/helpers";

export const personasInitialState = {
  personasState: {
    antiguedadEmpleado: getEmptyStatusAntiguedadEmpleado(),
    canalVenta: getEmptyStatusCanalVenta(),
    centroDeTrabajo: getEmptyStatusCentroDeTrabajo(),
    clienteQentry: getEmptyStatusClienteQentry(),
    codigoPostal: getEmptyStatusCodigoPostal(),
    codigoQr: getEmptyStatusCodigoQr(),
    datosFiscales: getEmptyStatusDatosFiscales(),
    datosPersonales: getEmptyStatusDatosPersonales(),
    direccion: getEmptyStatusDireccion(),
    documento: getEmptyStatusDocumento(),
    empleado: getEmptyStatusEmpleado(),
    empresa: getEmptyStatusEmpresa(),
    estado: getEmptyStatusEstado(),
    geolocalizacion: getEmptyStatusGeolocalizacion(),
    imagen: getEmptyStatusImagen(),
    informacionBancaria: getEmptyStatusInformacionBancaria(),
    informacionContrato: getEmptyStatusInformacionContrato(),
    informacionSuscripcion: getEmptyStatusInformacionSuscripcion(),
    modificacionSuscripcionQentry:
      getEmptyStatusModificacionSuscripcionQentry(),
    modificadorNomina: getEmptyStatusModificadorNomina(),
    mvAntiguedad: getEmptyStatusMvAntiguedad(),
    mvBanco: getEmptyStatusMvBanco(),
    mvEstadoCivil: getEmptyStatusMvEstadoCivil(),
    mvEstatusSolicitudCambioTelefonoAutorizado:
      getEmptyStatusMvEstatusSolicitudCambioTelefonoAutorizado(),
    mvFormaDePagoSuscripcion: getEmptyStatusMvFormaDePagoSuscripcion(),
    mvGenero: getEmptyStatusMvGenero(),
    mvImpuestos: getEmptyStatusMvImpuestos(),
    mvMotivoPagoSuscripcion: getEmptyStatusMvMotivoPagoSuscripcion(),
    mvTipoCanalVenta: getEmptyStatusMvTipoCanalVenta(),
    mvTipoDocumento: getEmptyStatusMvTipoDocumento(),
    mvTipoEmpleado: getEmptyStatusMvTipoEmpleado(),
    mvTipoServicioQentry: getEmptyStatusMvTipoServicioQentry(),
    mvTipoSuscripcion: getEmptyStatusMvTipoSuscripcion(),
    mvTipoUsuario: getEmptyStatusMvTipoUsuario(),
    mvUsoFactura: getEmptyStatusMvUsoFactura(),
    pagoSuscripcion: getEmptyStatusPagoSuscripcion(),
    pais: getEmptyStatusPais(),
    servicioQentry: getEmptyStatusServicioQentry(),
    solicitudCambioTelefonoAutorizado:
      getEmptyStatusSolicitudCambioTelefonoAutorizado(),
    suscripcionQentry: getEmptyStatusSuscripcionQentry(),
    tipoDocumentosPorPuesto: getEmptyStatusTipoDocumentosPorPuesto(),
    user: getEmptyStatusUser(),
  },
};

export const personasSlice = createSlice({
  name: "personas",
  initialState: personasInitialState,
  reducers: {},
  extraReducers: personasReducers,
});

export default personasSlice.reducer;

export const getNombreEmpleado = (empleado: IEmpleado) => {
  return `${empleado.datos_personales.primer_nombre} ${
    empleado.datos_personales.segundo_nombre
      ? empleado.datos_personales.segundo_nombre + " "
      : " "
  }${empleado.datos_personales.apellido_paterno} ${
    empleado.datos_personales.apellido_materno
  }`;
};

export const getDireccion = (direccion: IDireccion) => {
  const {
    calle: c,
    numero_exterior: ne,
    numero_interior: ni,
    colonia: col,
    delegacion_municipio: dom,
    codigo_postal: cp,
    estado: es,
    pais: pa,
  } = direccion;
  return `${c} ${ne} ${ni}, Col. ${col}, ${dom ? dom : ""}, ${
    cp && cp.id ? cp.codigo_postal : ""
  }. ${es && es.id ? es.nombre : ""}, ${pa && pa.id ? pa.codigo_iso : ""}`;
};

export const getGlobalMemoizedActiveEmpleadoAsSelectList = createSelector(
  (state: RootState) => state.personas.personasState.empleado.data_array,
  (empleado) => {
    const items = empleado
      .filter((e) => e.activo)
      .map((e) =>
        setListItem({
          id: e.id.toString(),
          title: getNombreEmpleado(e),
        })
      );
    items.sort((a, b) => {
      return a.title > b.title ? 1 : a.title < b.title ? -1 : 0;
    });
    return items;
  }
);

export const getGlobalMemoizedEmpresaAsSelectList = createSelector(
  (state: RootState) => state.personas.personasState.empresa.data_array,
  (empresas) => {
    const items = empresas.map((e) =>
      setListItem({ id: e.id.toString(), title: e.nombre_empresa })
    );
    items.sort((a, b) => {
      return a.title > b.title ? 1 : a.title < b.title ? -1 : 0;
    });
    items.push({ id: "", title: "Todas las empresas" });
    return items;
  }
);

export const getMemoizedCodigoQrMarkerList = createSelector(
  (state: RootState) => state.personas.personasState.codigoQr.data_array,
  (items) => items.map((e) => setMakerDataItem(e))
);

export const getMemoizedCodigoQrList = createSelector(
  (state: RootState) => state.personas.personasState.codigoQr.data_array,
  (items) => {
    const temp = [...items];
    temp.sort((a, b) => {
      return a.id > b.id ? 1 : a.id < b.id ? -1 : 0;
    });
    return temp.map((e) =>
      setListItem({
        id: e.id.toString(),
        title: e.centro_de_trabajo.nombre_centro,
      })
    );
  }
);

export const setMakerDataItem = (codigoQr: ICodigoQr) => {
  const direccion = codigoQr.centro_de_trabajo.direccion;
  const temp: MakerData = {
    id: codigoQr.id.toString(),
    infoWindowOpen: false,
    name: codigoQr.centro_de_trabajo.nombre_centro,
    position: {
      lat: Number(codigoQr.geolocalizacion.latitud),
      lng: Number(codigoQr.geolocalizacion.longitud),
    },
    icon: {
      path: "M 0,0 C -2,-20 -10,-22 -10,-30 A 10,10 0 1,1 10,-30 C 10,-22 2,-20 0,0 z",
      fillColor: codigoQr.centro_de_trabajo.activo ? "red" : "gray",
      fillOpacity: 1,
      strokeWeight: 0,
      rotation: 0,
      scale: 1,
    },
    qrCode: codigoQr.clave_codigo,
    status: 3,
    modified: false,
    deactivated: !codigoQr.estatus_sistema,
    empresa: codigoQr.centro_de_trabajo.empresa.nombre_empresa,
    distancia_maxima_permitida:
      codigoQr.centro_de_trabajo.distancia_maxima_permitida,
    numero_empleados_asignados:
      codigoQr.centro_de_trabajo.numero_empleados_asignados,
    direccion: `${direccion.calle}, ${direccion.colonia}, ${direccion.ciudad}, ${direccion.delegacion_municipio}, ${direccion.estado.nombre}, ${direccion.codigo_postal.codigo_postal}`,
  };
  return temp;
};

export const getMemoizedEmpleadoList = createSelector(
  (state: RootState) => state.personas.personasState.empresa.data,
  (state: RootState) => state.personas.personasState.empleado.data_array,
  (empresa, empleados) => {
    return empleados
      .filter((e) => e.empresa.id === empresa.id)
      .map((e) =>
        setListItem({
          id: e.id.toString(),
          title: getNombreEmpleado(e),
        })
      );
  }
);

export const getMemoizedCentrosDeTrabajoAsSelectList = createSelector(
  (state: RootState) => state.personas.personasState.centroDeTrabajo.data_array,
  (centros) => {
    const item = centros.map((e) =>
      setListItem({ id: e.id.toString(), title: e.nombre_centro })
    );
    item.sort((a, b) => {
      return a.title > b.title ? 1 : a.title < b.title ? -1 : 0;
    });
    item.push({ id: "", title: "Todos los centros de trabajo" });
    return item;
  }
);

export const getGlobalMemoizedMvGeneroAsSelectList = createSelector(
  (state: RootState) => state.personas.personasState.mvGenero.data_array,
  (genero) => {
    const items = genero.map((e) =>
      setListItem({ id: e.id.toString(), title: e.dato })
    );
    items.sort((a, b) => {
      return a.title > b.title ? 1 : a.title < b.title ? -1 : 0;
    });
    return items;
  }
);

export const getGlobalMemoizedMvEstadoCivilAsSelectList = createSelector(
  (state: RootState) => state.personas.personasState.mvEstadoCivil.data_array,
  (mvEstadoCivil) => {
    const items = mvEstadoCivil.map((e) =>
      setListItem({ id: e.id.toString(), title: e.dato })
    );
    items.sort((a, b) => {
      return a.title > b.title ? 1 : a.title < b.title ? -1 : 0;
    });
    return items;
  }
);

export const getGlobalMemoizedMvUsoFacturaAsSelectList = createSelector(
  (state: RootState) => state.personas.personasState.mvUsoFactura.data_array,
  (mvUsoFactura) => {
    const items = mvUsoFactura.map((e) =>
      setListItem({ id: e.id.toString(), title: `${e.clave} -  ${e.dato}` })
    );
    items.sort((a, b) => {
      return a.title > b.title ? 1 : a.title < b.title ? -1 : 0;
    });
    return items;
  }
);

export const getGlobalMemoizedMvBancoAsSelectList = createSelector(
  (state: RootState) => state.personas.personasState.mvBanco.data_array,
  (mvBanco) => {
    const items = mvBanco.map((e) =>
      setListItem({ id: e.id.toString(), title: e.dato })
    );
    items.sort((a, b) => {
      return a.title > b.title ? 1 : a.title < b.title ? -1 : 0;
    });
    return items;
  }
);

export const getGlobalMemoizedMvTipoEmpleadoAsSelectList = createSelector(
  (state: RootState) => state.personas.personasState.mvTipoEmpleado.data_array,
  (mvTipoEmpleado) => {
    const items = mvTipoEmpleado.map((e) =>
      setListItem({ id: e.id.toString(), title: e.dato })
    );
    items.sort((a, b) => {
      return a.title > b.title ? 1 : a.title < b.title ? -1 : 0;
    });
    return items;
  }
);

export const getGlobalMemoizedPaisAsSelectList = createSelector(
  (state: RootState) => state.personas.personasState.pais.data_array,
  (pais) => {
    const items = pais.map((e) =>
      setListItem({ id: e.id.toString(), title: e.nombre })
    );
    items.sort((a, b) => {
      return a.title > b.title ? 1 : a.title < b.title ? -1 : 0;
    });
    return items;
  }
);

export const getGlobalMemoizedEstadoAsSelectList = createSelector(
  (state: RootState) => state.personas.personasState.estado.data_array,
  (pais) => {
    const items = pais.map((e) =>
      setListItem({ id: e.id.toString(), title: e.nombre })
    );
    items.sort((a, b) => {
      return a.title > b.title ? 1 : a.title < b.title ? -1 : 0;
    });
    return items;
  }
);

export const getGlobalMemoizedMvEstatusSolicitudCambioDispositivoAsSelectList =
  createSelector(
    (state: RootState) =>
      state.personas.personasState.mvEstatusSolicitudCambioTelefonoAutorizado
        .data_array,
    (mvEstatusCambioSolicitud) => {
      const items = mvEstatusCambioSolicitud.map((estatus) =>
        setListItem({
          id: estatus.id.toString(),
          title: estatus.dato,
        })
      );
      items.sort((a, b) => {
        return a.title > b.title ? 1 : a.title < b.title ? -1 : 0;
      });
      items.push(setListItem({ id: "", title: "Todos los estatus" }));
      return items;
    }
  );
