/**
 * Métodos del, get, post, put para llamadas a la API
 * @get     en este método primero consulta con @getFromLocalStorage si es necesario hacer la petición http sino se obtiene del LocalStorage;
 *          si hay un error en la petición http se busca el objeto en el LocalStorage sino lo encuentra devuelve un objeto vacío; si la
 *          petición se realiza correctamente actualiza el valor individual y en el array del objeto en el LocalStorage y devuelve el objeto
 *          obtenido del servidor.
 * @getAll  en este método primero consulta con @getFromLocalStorage si es necesario hacer la petición http sino se obtiene del LocalStorage;
 *          si hay un error en la petición http se busca el objeto array en el LocalStorage sino lo encuentra devuelve un array vacío; si la
 *          petición se realiza correctamente actualiza array en el LocalStorage y devuelve el array obtenido del servidor
 * @update  en este método después de realizar la petición http si falla devuelve el objeto que se iba a actualizar; si la petición se
 *          realiza correctamente actualiza el valor individual y en el array del objeto en el LocalStorage y devuelve el objeto obtenido del
 *          servidor.
 * @create  en este método después de realizar la petición http si falla devuelve el objeto que se iba a crear; si la petición se realiza
 *          correctamente actualiza el valor individual y en el array del objeto en el LocalStorage y devuelve el objeto obtenido del
 *          servidor.
 * @delete  en este método después de realizar la petición http si falla devuelve el objeto que se iba a eliminar; si la petición se realiza
 *          correctamente elimina el valor individual y en el objeto en el arrya del LocalStorage y devuelve un objeto vacio (Default)
 */

import { del, get, post, put } from "../../api/api";
import {
  getFromLocalStorage,
  updateLocalStorageExpiry,
} from "../../api/helpers";
import { removeArrayItemStorageData } from "../../api/storage";
import { DEFAULT_STATE } from "../../enums/default_state";
import {
  getEmptyStatusMvBanco,
  setMvBanco,
  IMvBanco,
  getSerializableMvBanco,
} from "../../interfaces/personas";
import { IServerDate } from "../../interfaces/time";
import {
  getStorageMvBancoArrayItem,
  setStorageMvBanco,
  setStorageMvBancoArrayItem,
  getStorageMvBancoArray,
  setStorageMvBancoArray,
  removeStorageMvBanco,
} from "../../localStorage/personas";

export const getMvBanco = async (serverDate: IServerDate, pk: number) => {
  const temp = getEmptyStatusMvBanco();
  const doHttp = await getFromLocalStorage(serverDate, temp.expiry);
  temp.expiry = doHttp.storage;
  if (!doHttp.result) {
    console.log(`(localStorage) MvBanco con pk: ${pk}, obtenido con éxito`);
    temp.estado = DEFAULT_STATE.success;
    temp.mensaje = `MvBanco obtenido con éxito`;
    temp.data = await getStorageMvBancoArrayItem(pk);
    return temp;
  }
  const result = await get(`personas/banco/${pk}`);
  const data = result.data;
  console.log(`MvBanco data: `, data);
  if (!data.id || data.detail) {
    console.log(`Error al obtener MvBanco con pk: ${pk}, ${data.detail}`);
    temp.estado = DEFAULT_STATE.error;
    temp.mensaje = `Error al obtener el banco: ${
      data.detail ?? "Hubo un problema al conectarse con el servidor"
    }`;
    temp.data = await getStorageMvBancoArrayItem(pk);
    return temp;
  }
  console.log(`MvBanco con pk: ${pk}, obtenido con éxito`);
  temp.estado = DEFAULT_STATE.success;
  temp.mensaje = `MvBanco obtenido con éxito`;
  temp.data = await setMvBanco(data, serverDate);
  await setStorageMvBanco(temp.data);
  temp.data_array = await setStorageMvBancoArrayItem(pk, temp.data);
  temp.expiry = await updateLocalStorageExpiry(serverDate, temp.expiry);
  return temp;
};

export const getAllMvBanco = async (serverDate: IServerDate) => {
  const temp = getEmptyStatusMvBanco();
  const doHttp = await getFromLocalStorage(serverDate, temp.expiry_array);
  temp.expiry_array = doHttp.storage;
  if (!doHttp.result) {
    console.log(`(localStorage) Todos los MvBancos obtenidos con éxito`);
    temp.estado = DEFAULT_STATE.success;
    temp.mensaje = `Todos los bancos obtenidos con éxito`;
    temp.data_array = await getStorageMvBancoArray();
    return temp;
  }
  const result = await get(`personas/banco`);
  const data = result.data;
  if (data.detail) {
    console.log(`Error al obtener la lista de MvBanco, ${data.detail}`);
    temp.estado = DEFAULT_STATE.error;
    temp.mensaje = `Error al obtener la lista de bancos: ${
      data.detail ?? "Hubo un problema al conectarse con el servidor"
    }`;
    temp.data_array = await getStorageMvBancoArray();
    return temp;
  }
  console.log(`Todos los MvBancoes obtenidos con éxito`);
  temp.estado = DEFAULT_STATE.success;
  temp.mensaje = `Todos los bancos obtenidos con éxito`;
  temp.data_array = await Promise.all(
    data.map(async (e: any) => await setMvBanco(e, serverDate))
  );
  await setStorageMvBancoArray(temp.data_array);
  temp.expiry_array = await updateLocalStorageExpiry(
    serverDate,
    temp.expiry_array
  );
  return temp;
};

export const updateMvBanco = async (
  serverDate: IServerDate,
  pk: number,
  object: IMvBanco
) => {
  const temp = getEmptyStatusMvBanco();
  const result = await put(
    `personas/banco/${pk}`,
    getSerializableMvBanco(object)
  );
  const data = result.data;
  if (!data.id || data.detail) {
    console.log(`Error al actualizar MvBanco con pk: ${pk}, ${data.detail}`);
    temp.estado = DEFAULT_STATE.error;
    temp.mensaje = `Error al actualizar el banco: ${
      data.detail ?? "Hubo un problema al conectarse con el servidor"
    }`;
    temp.data = await setMvBanco(object, serverDate);
    return temp;
  }
  console.log(`MvBanco con pk: ${pk}, actualizado con éxito`);
  temp.estado = DEFAULT_STATE.success;
  temp.mensaje = `MvBanco actualizado con éxito`;
  temp.data = await setMvBanco(data, serverDate);
  await setStorageMvBanco(temp.data);
  temp.data_array = await setStorageMvBancoArrayItem(temp.data.id, temp.data);
  return temp;
};

export const createMvBanco = async (
  serverDate: IServerDate,
  object: IMvBanco
) => {
  const temp = getEmptyStatusMvBanco();
  const result = await post(`personas/banco`, getSerializableMvBanco(object));
  const data = result.data;
  if (!data.id || data.detail) {
    console.log(
      `Error al crear MvBanco con los datos ${object}, ${data.detail}`
    );
    temp.estado = DEFAULT_STATE.error;
    temp.mensaje = `Error al crear el banco: ${
      data.detail ?? "Hubo un problema al conectarse con el servidor"
    }`;
    temp.data = await setMvBanco(object, serverDate);
    return temp;
  }
  console.log(`MvBanco creado con éxito`);
  temp.estado = DEFAULT_STATE.success;
  temp.mensaje = `MvBanco creado con éxito`;
  temp.data = await setMvBanco(data, serverDate);
  await setStorageMvBanco(temp.data);
  temp.data_array = await setStorageMvBancoArrayItem(temp.data.id, temp.data);
  return temp;
};

export const deleteMvBanco = async (pk: number) => {
  const temp = getEmptyStatusMvBanco();
  const result = await del(`personas/banco/${pk}`);
  const data = result.data;
  if (data.detail) {
    console.log(`Error al eliminar MvBanco con pk: ${pk}, ${data.detail}`);
    temp.estado = DEFAULT_STATE.error;
    temp.mensaje = `Error al eliminar el banco: ${data.detail}`;
    temp.data = await getStorageMvBancoArrayItem(pk);
    temp.data_array = await getStorageMvBancoArray();
    return temp;
  }
  console.log(`MvBanco eliminado con éxito`);
  temp.estado = DEFAULT_STATE.success;
  temp.mensaje = `MvBanco eliminado con éxito`;
  await removeStorageMvBanco();
  temp.data_array = await removeArrayItemStorageData("IMvBanco", pk, []);
  return temp;
};
