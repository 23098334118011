/** 
 * Componente que se encarga del elemento Documento
 * @IDocumento es la interfaz que contiene los parámetos el elemento Documento
 * @getEmptyDocumento  devuelve un objeto con los parámetros por defecto; @param {id}, opcional cuando no encuentra el objeto en el LocalSotrage
 * @IStatusDocumento es la interfaz para los estatus a llamadas a la API; @param {status}, opcional cuando queremos otro estado de inizialización de tipo [DEFAULT_STATE]
 * @getEmptyStatusDocumento devuelve un objeto con los parámetros por defecto,
 * @setDocumento devuelve un objeto de tipo [IDocumento] recibiendo @param {object} y @param {serverDate}, para verificar que los datos estén en el formato correcto y ajustarlos a la zona horaria actual
 * @getSerializableDocumento recibe un @param {IDocumento}, para devolver un objeto serizalizable para las peticiones de la API
 * */

import { getDateString, isNotEmpty, SERVER_TIME_ZONE } from "../../api/helpers";
import { DEFAULT_STATE } from "../../enums/default_state";
import { getStorageMvTipoDocumentoArrayItem, getStorageUserArrayItem, getStorageClienteQentryArrayItem } from "../../localStorage/personas";
import { convertDateToZonaHoraria, ILocalStorageExpiry, getEmptyLocalStorageExpiry, IServerDate } from "../time";
import { getEmptyClienteQentry, IClienteQentry, setClienteQentry } from "./IClienteQentry";
import { IMvTipoDocumento, getEmptyMvTipoDocumento, setMvTipoDocumento } from "./IMvTipoDocumento";
import { IUser, getEmptyUser, setUser } from "./IUser";

export interface IDocumento {
  id : number,
  nombre_documento : string,
  documento : string, // URL del documento (type: File)
  tipo_documento : IMvTipoDocumento,
  autor : IUser,
  fecha_elaboracion : string, // Fecha
  estatus_sistema : boolean,
  ultima_actualizacion : string, // Fecha
  cliente_qentry : IClienteQentry,
}

export const getEmptyDocumento = (id? : number) => {
  const temp : IDocumento = 
  {
    id : id ? id : 0,
    nombre_documento : "",
    documento : "",
    tipo_documento : getEmptyMvTipoDocumento(),
    autor : getEmptyUser(),
    fecha_elaboracion : "",
    estatus_sistema : false,
    ultima_actualizacion :  "",
    cliente_qentry : getEmptyClienteQentry(),
  };
  return temp;
}

export interface IStatusDocumento {
  estado : DEFAULT_STATE,
  mensaje : string,
  data : IDocumento,
  data_array: IDocumento[],
  expiry : ILocalStorageExpiry,
  expiry_array : ILocalStorageExpiry,
}

export const getEmptyStatusDocumento = (status: DEFAULT_STATE = DEFAULT_STATE.loading) => {
  const temp : IStatusDocumento = 
  {
    estado : status,
    mensaje : "",
    data : getEmptyDocumento(),
    data_array : [],
    expiry : getEmptyLocalStorageExpiry("IDocumento", 0),
    expiry_array : getEmptyLocalStorageExpiry("IDocumentoArray", 0),
  };
  return temp;
}

export const setDocumento = async (
  object : any,
  serverDate : IServerDate,
) => {
  const temp = getEmptyDocumento();
  if (isNotEmpty(object.id)) temp.id = object.id;
  if (isNotEmpty(object.nombre_documento)) temp.nombre_documento = object.nombre_documento;
  if (isNotEmpty(object.documento)) temp.documento = object.documento;
  if (isNotEmpty(object.tipo_documento)) temp.tipo_documento = (typeof object.tipo_documento === 'number') ? await getStorageMvTipoDocumentoArrayItem(object.tipo_documento) : await setMvTipoDocumento(object.tipo_documento, serverDate);
  if (isNotEmpty(object.autor)) temp.autor = (typeof object.autor === 'number') ? await getStorageUserArrayItem(object.autor) : await setUser(object.autor, serverDate);
  if (isNotEmpty(object.fecha_elaboracion)) temp.fecha_elaboracion = convertDateToZonaHoraria(serverDate, object.fecha_elaboracion);
  if (isNotEmpty(object.estatus_sistema)) temp.estatus_sistema = object.estatus_sistema;
  if (isNotEmpty(object.ultima_actualizacion)) temp.ultima_actualizacion = convertDateToZonaHoraria(serverDate, object.ultima_actualizacion);
  if (isNotEmpty(object.cliente_qentry)) temp.cliente_qentry = (typeof object.cliente_qentry === 'number') ? await getStorageClienteQentryArrayItem(object.cliente_qentry) : await setClienteQentry(object.cliente_qentry, serverDate);
  return temp;
}

export const getSerializableDocumento = (IDocumento : IDocumento) => {
  return {
    id : IDocumento.id,
    nombre_documento : IDocumento.nombre_documento,
    documento : IDocumento.documento,
    tipo_documento : IDocumento.tipo_documento.id,
    autor : IDocumento.autor.id,
    fecha_elaboracion : IDocumento.fecha_elaboracion,
    estatus_sistema : IDocumento.estatus_sistema,
    ultima_actualizacion : getDateString(SERVER_TIME_ZONE),
    cliente_qentry : IDocumento.cliente_qentry.id,
  };
}
