
/** 
 * Componente que se encarga del elemento MvUsoFactura
 * @IMvUsoFactura es la interfaz que contiene los parámetos el elemento MvUsoFactura
 * @getEmptyMvUsoFactura  devuelve un objeto con los parámetros por defecto; @param {id}, opcional cuando no encuentra el objeto en el LocalSotrage
 * @IStatusMvUsoFactura es la interfaz para los estatus a llamadas a la API; @param {status}, opcional cuando queremos otro estado de inizialización de tipo [DEFAULT_STATE]
 * @getEmptyStatusMvUsoFactura devuelve un objeto con los parámetros por defecto,
 * @setMvUsoFactura devuelve un objeto de tipo [IMvUsoFactura] recibiendo @param {object} y @param {serverDate}, para verificar que los datos estén en el formato correcto y ajustarlos a la zona horaria actual
 * @getSerializableMvUsoFactura recibe un @param {IMvUsoFactura}, para devolver un objeto serizalizable para las peticiones de la API
 * */

import { getDateString, isNotEmpty, SERVER_TIME_ZONE } from "../../api/helpers";
import { IUser, getEmptyUser, setUser } from "./IUser";
import { IPais, getEmptyPais, setPais } from "./IPais";
import { getStoragePaisArrayItem } from "../../localStorage/personas/Pais";
import { getStorageUserArrayItem } from "../../localStorage/personas/User";
import { getEmptyLocalStorageExpiry, ILocalStorageExpiry } from "../time/ILocalStorageExpiry";
import { DEFAULT_STATE } from "../../enums/default_state";
import { convertDateToZonaHoraria, IServerDate } from "../time";

export interface IMvUsoFactura {
  id : number,
  dato : string,
  clave : string,
  autor : IUser,
  fecha_elaboracion : string,
  estatus_sistema : boolean,
  ultima_actualizacion : string,
  pais : IPais
}

export const getEmptyMvUsoFactura = (id? : number) => {
  const temp : IMvUsoFactura = 
  {
    id : id ? id : 0,
    dato : "",
    clave : "",
    autor : getEmptyUser(),
    fecha_elaboracion : "",
    estatus_sistema : false,
    ultima_actualizacion : "",
    pais : getEmptyPais(),
  };
  return temp;
}

export interface IStatusMvUsoFactura {
  estado : DEFAULT_STATE,
  mensaje : string,
  data : IMvUsoFactura,
  data_array: IMvUsoFactura[],
  expiry : ILocalStorageExpiry,
  expiry_array : ILocalStorageExpiry,
}

export const getEmptyStatusMvUsoFactura = (status: DEFAULT_STATE = DEFAULT_STATE.loading) => {
  const temp : IStatusMvUsoFactura = 
  {
    estado : status,
    mensaje : "",
    data : getEmptyMvUsoFactura(),
    data_array : [],
    expiry : getEmptyLocalStorageExpiry("IMvUsoFactura", 0),
    expiry_array : getEmptyLocalStorageExpiry("IMvUsoFacturaArray", 0),
  };
  return temp;
}

export const setMvUsoFactura = async (
  object : any,
  serverDate : IServerDate,
) => {
  const temp = getEmptyMvUsoFactura();
  if (isNotEmpty(object.id)) temp.id = object.id;
  if (isNotEmpty(object.dato)) temp.dato = object.dato;
  if (isNotEmpty(object.clave)) temp.clave = object.clave;
  if (isNotEmpty(object.autor)) temp.autor = (typeof object.autor === 'number') ? await getStorageUserArrayItem(object.autor) : await setUser(object.autor, serverDate);
  if (isNotEmpty(object.fecha_elaboracion)) temp.fecha_elaboracion = convertDateToZonaHoraria(serverDate, object.fecha_elaboracion);
  if (isNotEmpty(object.estatus_sistema)) temp.estatus_sistema = object.estatus_sistema;
  if (isNotEmpty(object.ultima_actualizacion)) temp.ultima_actualizacion = convertDateToZonaHoraria(serverDate, object.ultima_actualizacion);
  if (isNotEmpty(object.pais)) temp.pais = (typeof object.pais === 'number') ? await getStoragePaisArrayItem(object.pais) : await setPais(object.pais, serverDate);
  return temp;
}

export const getSerializableMvUsoFactura = (IMvUsoFactura : IMvUsoFactura) => {
  return {
    id : IMvUsoFactura.id,
    dato : IMvUsoFactura.dato,
    clave : IMvUsoFactura.clave,
    autor : IMvUsoFactura.autor.id,
    fecha_elaboracion : IMvUsoFactura.fecha_elaboracion,
    estatus_sistema : IMvUsoFactura.estatus_sistema,
    ultima_actualizacion : getDateString(SERVER_TIME_ZONE),
    pais : IMvUsoFactura.pais.id > 0 ? IMvUsoFactura.pais.id : null,
  };
}
