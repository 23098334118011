/**
 * Es el componente utilizado en la página LoginPhone
 * @function verifyPhoneNumber verifica que el número de teléfono sea válido y que pertenesca a un usuario
 * @function sendCode envía el código de verificación por SMS al télefono utilizado
 * @function compareCodes compara el codigo escrito con el envíado por SMS
 */

import React, { useEffect, useState } from 'react';
import {
    IonButton,
    IonCol,
    IonContent,
    IonGrid,
    IonIcon,
    IonImg,
    IonInput,
    IonItem,
    IonLabel,
    IonPage,
    IonRow,
    useIonToast,
  } from '@ionic/react';

import {
  phonePortraitSharp,
  keySharp,
  sendSharp,
} from 'ionicons/icons';

import {
  verifyPhone
} from "../storage/UserApi"

import {
  LoginMesages,
} from "../string/Messages";
import { auth as firebaseAuth, RecaptchaVerifier, ConfirmationResult, signInWithPhoneNumber} from "../firebase/config";

import './Login.css';

const LoginPhone: React.FC = () => {
  
  const [present, dismiss] = useIonToast();
  let [lang, setLang] = useState<string>("es");
  let [phoneDisable, setPhoneDisabled] = useState<boolean>(false);
  let [logInPhone, setLogInPhone] = useState("");
  let [logInCode, setLogInCode] = useState("");
  let [logInCodeRes, setLogInCodeRes] = useState<ConfirmationResult>();
  let [ captcha, setCaptcha ] = useState<RecaptchaVerifier>()
  let [ captchaId, setCaptchaId ] = useState<number>();

  
  useEffect(() => {
    const verifier = new RecaptchaVerifier('recaptcha-container-login', {
        callback: (response:any) => console.log('callback', response),
        'size': 'invisible',
    }, firebaseAuth);
    setCaptcha(verifier);
    verifier.render().then((widgetId) => {
      setCaptchaId(widgetId);
    });
  }, []);

  const verifyPhoneNumber = async () => {
    if (logInPhone) {
      verifyPhone(logInPhone)
        .then((res) =>{
          const data = res.data;
          if (data.result === 1) {
            setPhoneDisabled(true);
            sendCode();
          } else {
            present({
              message: LoginMesages['PhoneNotExist'][lang],
              color: "danger",
              duration: 2500,
            });
          }
        })
    } else { 
      present({
        message: LoginMesages['PhoneInputError'][lang],
        color: "danger",
        duration: 2500,
      });  
    }
  }

  const sendCode = async () => {
    if (captcha) {
      signInWithPhoneNumber(firebaseAuth, logInPhone, captcha)
      .then((confirmationResult : any) => {
        // SMS sent. Prompt user to type the code from the message, then sign the
        // user in with confirmationResult.confirm(code).
        setLogInCodeRes(confirmationResult)
        // ...
      }).catch((err : any) => {
        // Error; SMS not sent
        // ...
        console.log("errorSMS", err);
        present({
          message: LoginMesages['SendCodeButton'][lang] + ": " + err,
          color: "danger",
          duration: 2500,
        });  
      });
    }
  }

  const compareCodes = async () => {
    if (logInCodeRes) {
      if (logInCode) {
        logInCodeRes.confirm(logInCode)
      } else{
        present({
          message: LoginMesages['LoginPhoneError'][lang],
          color: "danger",
          duration: 2500,
        });
      }
    }
  }  
 
  return (
    <IonPage>
      <IonContent>
          <IonGrid class="full-screen no-padding">
            <IonRow className="full-screen">
              <IonCol size="12" sizeMd="12" sizeLg="12 "class="form ion-align-items-stretch">
                <IonRow className="ion-justify-content-center">
                  <IonCol size="12" >
                    <IonImg src="../assets/img/qentrydos.png" class="img-form" />
                  </IonCol>
                </IonRow>
                <IonRow className="ion-justify-content-center">
                  <IonCol size="12" >
                    <IonItem class="secondary-bg" lines="none">
                      <IonLabel className="input-icon">
                        <IonIcon icon={phonePortraitSharp} />
                      </IonLabel>
                      <IonInput
                        disabled={phoneDisable}
                        class="input-secondary-bg"
                        required
                        autofocus={true}
                        autocorrect="on"
                        type="tel"
                        placeholder={ LoginMesages['PhoneInput'][lang]}
                        value={logInPhone}
                        onIonChange={(event) => setLogInPhone((event.detail.value) ? event.detail.value : "")}
                      />
                      <IonButton color="secondary" slot="end" disabled={phoneDisable} hidden={phoneDisable} type="submit" class="round-25" onClick={() => verifyPhoneNumber()}>
                        <IonIcon color="primary" slot="icon-only" icon={sendSharp} />
                      </IonButton>
                    </IonItem>
                  </IonCol>
                </IonRow>
                <IonRow className="ion-justify-content-center">
                  <IonCol size="12">
                    <IonItem class="secondary-bg" color="" lines="none">
                      <IonLabel 
                        hidden={!phoneDisable} 
                        className="input-icon">
                        <IonIcon icon={keySharp} />
                      </IonLabel>
                      <IonInput
                        disabled={!phoneDisable}
                        hidden={!phoneDisable}
                        class="input-secondary-bg"
                        required
                        type="password"
                        placeholder={ LoginMesages['CodeInput'][lang]}
                        value={logInCode}
                        onIonChange={(event) =>
                          setLogInCode((event.detail.value) ? event.detail.value : "")
                        }
                      />
                    </IonItem>
                  </IonCol>
                </IonRow>
                <IonRow className="ion-justify-content-center ion-text-center">
                  <IonCol size="8">
                    <IonButton disabled={!phoneDisable} hidden={!phoneDisable} type="submit" class="round-25" onClick={() => compareCodes()}>
                      {LoginMesages['LoginPhoneButton'][lang]}
                    </IonButton>
                  </IonCol>
                  
                  <IonCol size="12" class='ion-center'>
                    <div data-class="g-recaptcha" data-sitekey="6LedmRAcAAAAACkmQWbVj8AjRELA37RtKKq2FUBe" id="recaptcha-container-login"></div>
                  </IonCol>
                  
                  <IonCol size="8">
                    <IonButton class="round-25" routerLink='/Login'>
                      {LoginMesages['ToEmailLogin'][lang]}
                    </IonButton>
                  </IonCol>
                </IonRow>
              </IonCol>
            </IonRow>
          </IonGrid>
      </IonContent>
    </IonPage>
  );
};
  
export default LoginPhone;