/**
 * Métodos del, get, post, put para llamadas a la API
 * @get     en este método primero consulta con @getFromLocalStorage si es necesario hacer la petición http sino se obtiene del LocalStorage;
 *          si hay un error en la petición http se busca el objeto en el LocalStorage sino lo encuentra devuelve un objeto vacío; si la
 *          petición se realiza correctamente actualiza el valor individual y en el array del objeto en el LocalStorage y devuelve el objeto
 *          obtenido del servidor.
 * @getAll  en este método primero consulta con @getFromLocalStorage si es necesario hacer la petición http sino se obtiene del LocalStorage;
 *          si hay un error en la petición http se busca el objeto array en el LocalStorage sino lo encuentra devuelve un array vacío; si la
 *          petición se realiza correctamente actualiza array en el LocalStorage y devuelve el array obtenido del servidor
 * @update  en este método después de realizar la petición http si falla devuelve el objeto que se iba a actualizar; si la petición se
 *          realiza correctamente actualiza el valor individual y en el array del objeto en el LocalStorage y devuelve el objeto obtenido del
 *          servidor.
 * @create  en este método después de realizar la petición http si falla devuelve el objeto que se iba a crear; si la petición se realiza
 *          correctamente actualiza el valor individual y en el array del objeto en el LocalStorage y devuelve el objeto obtenido del
 *          servidor.
 * @delete  en este método después de realizar la petición http si falla devuelve el objeto que se iba a eliminar; si la petición se realiza
 *          correctamente elimina el valor individual y en el objeto en el arrya del LocalStorage y devuelve un objeto vacio (Default)
 */

import { del, get, post, put } from "../../api/api";
import {
  getFromLocalStorage,
  updateLocalStorageExpiry,
} from "../../api/helpers";
import { removeArrayItemStorageData } from "../../api/storage";
import { DEFAULT_STATE } from "../../enums/default_state";
import {
  getEmptyStatusPais,
  setPais,
  IPais,
  getSerializablePais,
} from "../../interfaces/personas";
import { IServerDate } from "../../interfaces/time";
import {
  getStoragePaisArrayItem,
  setStoragePais,
  setStoragePaisArrayItem,
  getStoragePaisArray,
  setStoragePaisArray,
  removeStoragePais,
} from "../../localStorage/personas";

export const getPais = async (serverDate: IServerDate, pk: number) => {
  const temp = getEmptyStatusPais();
  const doHttp = await getFromLocalStorage(serverDate, temp.expiry);
  temp.expiry = doHttp.storage;
  if (!doHttp.result) {
    console.log(`(localStorage) Pais con pk: ${pk}, obtenido con éxito`);
    temp.estado = DEFAULT_STATE.success;
    temp.mensaje = `Pais  obtenido con éxito`;
    temp.data = await getStoragePaisArrayItem(pk);
    return temp;
  }
  const result = await get(`personas/pais/${pk}`);
  const data = result.data;
  console.log(`Pais data: `, data);
  if (!data.id || data.detail) {
    console.log(`Error al obtener Pais con pk: ${pk}, ${data.detail}`);
    temp.estado = DEFAULT_STATE.error;
    temp.mensaje = `Error al obtener el país: ${
      data.detail ?? "Hubo un problema al conectarse con el servidor"
    }`;
    temp.data = await getStoragePaisArrayItem(pk);
    return temp;
  }
  console.log(`Pais con pk: ${pk}, obtenido con éxito`);
  temp.estado = DEFAULT_STATE.success;
  temp.mensaje = `Pais obtenido con éxito`;
  temp.data = await setPais(data, serverDate);
  await setStoragePais(temp.data);
  temp.data_array = await setStoragePaisArrayItem(pk, temp.data);
  temp.expiry = await updateLocalStorageExpiry(serverDate, temp.expiry);
  return temp;
};

export const getAllPais = async (serverDate: IServerDate) => {
  const temp = getEmptyStatusPais();
  const doHttp = await getFromLocalStorage(serverDate, temp.expiry_array);
  temp.expiry_array = doHttp.storage;
  if (!doHttp.result) {
    console.log(`(localStorage) Todos los Paises obtenidos con éxito`);
    temp.estado = DEFAULT_STATE.success;
    temp.mensaje = `Todos los paises obtenidos con éxito`;
    temp.data_array = await getStoragePaisArray();
    return temp;
  }
  const result = await get(`personas/pais`);
  const data = result.data;
  if (data.detail) {
    console.log(`Error al obtener la lista de Pais, ${data.detail}`);
    temp.estado = DEFAULT_STATE.error;
    temp.mensaje = `Error al obtener la lista de paises: ${
      data.detail ?? "Hubo un problema al conectarse con el servidor"
    }`;
    temp.data_array = await getStoragePaisArray();
    return temp;
  }
  console.log(`Todos los Paises obtenidos con éxito`);
  temp.estado = DEFAULT_STATE.success;
  temp.mensaje = `Todos los paises obtenidos con éxito`;
  temp.data_array = await Promise.all(
    data.map(async (e: any) => await setPais(e, serverDate))
  );
  await setStoragePaisArray(temp.data_array);
  temp.expiry_array = await updateLocalStorageExpiry(
    serverDate,
    temp.expiry_array
  );
  return temp;
};

export const updatePais = async (
  serverDate: IServerDate,
  pk: number,
  object: IPais
) => {
  const temp = getEmptyStatusPais();
  const result = await put(`personas/pais/${pk}`, getSerializablePais(object));
  const data = result.data;
  if (!data.id || data.detail) {
    console.log(`Error al actualizar Pais con pk: ${pk}, ${data.detail}`);
    temp.estado = DEFAULT_STATE.error;
    temp.mensaje = `Error al actualizar el país: ${
      data.detail ?? "Hubo un problema al conectarse con el servidor"
    }`;
    temp.data = await setPais(object, serverDate);
    return temp;
  }
  console.log(`Pais con pk: ${pk}, actualizado con éxito`);
  temp.estado = DEFAULT_STATE.success;
  temp.mensaje = `Pais actualizado con éxito`;
  temp.data = await setPais(data, serverDate);
  await setStoragePais(temp.data);
  temp.data_array = await setStoragePaisArrayItem(temp.data.id, temp.data);
  return temp;
};

export const createPais = async (serverDate: IServerDate, object: IPais) => {
  const temp = getEmptyStatusPais();
  const result = await post(`personas/pais`, getSerializablePais(object));
  const data = result.data;
  if (!data.id || data.detail) {
    console.log(`Error al crear Pais con los datos ${object}, ${data.detail}`);
    temp.estado = DEFAULT_STATE.error;
    temp.mensaje = `Error al crear el país: ${
      data.detail ?? "Hubo un problema al conectarse con el servidor"
    }`;
    temp.data = await setPais(object, serverDate);
    return temp;
  }
  console.log(`Pais creado con éxito`);
  temp.estado = DEFAULT_STATE.success;
  temp.mensaje = `Pais creado con éxito`;
  temp.data = await setPais(data, serverDate);
  await setStoragePais(temp.data);
  temp.data_array = await setStoragePaisArrayItem(temp.data.id, temp.data);
  return temp;
};

export const deletePais = async (pk: number) => {
  const temp = getEmptyStatusPais();
  const result = await del(`personas/pais/${pk}`);
  const data = result.data;
  if (data.detail) {
    console.log(`Error al eliminar Pais con pk: ${pk}, ${data.detail}`);
    temp.estado = DEFAULT_STATE.error;
    temp.mensaje = `Error al eliminar al país: ${data.detail}`;
    temp.data = await getStoragePaisArrayItem(pk);
    temp.data_array = await getStoragePaisArray();
    return temp;
  }
  console.log(`Pais eliminado con éxito`);
  temp.estado = DEFAULT_STATE.success;
  temp.mensaje = `País eliminado con éxito`;
  await removeStoragePais();
  temp.data_array = await removeArrayItemStorageData("IPais", pk, []);
  return temp;
};
