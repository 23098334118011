/**
 * Métodos del, get, post, put para llamadas a la API
 * @get     en este método primero consulta con @getFromLocalStorage si es necesario hacer la petición http sino se obtiene del LocalStorage;
 *          si hay un error en la petición http se busca el objeto en el LocalStorage sino lo encuentra devuelve un objeto vacío; si la
 *          petición se realiza correctamente actualiza el valor individual y en el array del objeto en el LocalStorage y devuelve el objeto
 *          obtenido del servidor.
 * @getAll  en este método primero consulta con @getFromLocalStorage si es necesario hacer la petición http sino se obtiene del LocalStorage;
 *          si hay un error en la petición http se busca el objeto array en el LocalStorage sino lo encuentra devuelve un array vacío; si la
 *          petición se realiza correctamente actualiza array en el LocalStorage y devuelve el array obtenido del servidor
 * @update  en este método después de realizar la petición http si falla devuelve el objeto que se iba a actualizar; si la petición se
 *          realiza correctamente actualiza el valor individual y en el array del objeto en el LocalStorage y devuelve el objeto obtenido del
 *          servidor.
 * @create  en este método después de realizar la petición http si falla devuelve el objeto que se iba a crear; si la petición se realiza
 *          correctamente actualiza el valor individual y en el array del objeto en el LocalStorage y devuelve el objeto obtenido del
 *          servidor.
 * @delete  en este método después de realizar la petición http si falla devuelve el objeto que se iba a eliminar; si la petición se realiza
 *          correctamente elimina el valor individual y en el objeto en el arrya del LocalStorage y devuelve un objeto vacio (Default)
 */

import { del, get, post, put } from "../../api/api";
import {
  getFromLocalStorage,
  updateLocalStorageExpiry,
} from "../../api/helpers";
import { removeArrayItemStorageData } from "../../api/storage";
import { DEFAULT_STATE } from "../../enums/default_state";
import {
  getEmptyStatusMvTipoJornada,
  setMvTipoJornada,
  IMvTipoJornada,
  getSerializableMvTipoJornada,
} from "../../interfaces/nomina";
import { IServerDate } from "../../interfaces/time";
import {
  getStorageMvTipoJornadaArrayItem,
  setStorageMvTipoJornada,
  setStorageMvTipoJornadaArrayItem,
  getStorageMvTipoJornadaArray,
  setStorageMvTipoJornadaArray,
  removeStorageMvTipoJornada,
} from "../../localStorage/nomina";

export const getMvTipoJornada = async (serverDate: IServerDate, pk: number) => {
  const temp = getEmptyStatusMvTipoJornada();
  const doHttp = await getFromLocalStorage(serverDate, temp.expiry);
  temp.expiry = doHttp.storage;
  if (!doHttp.result) {
    console.log(
      `(localStorage) MvTipoJornada con pk: ${pk}, obtenido con éxito`
    );
    temp.estado = DEFAULT_STATE.success;
    temp.mensaje = `MvTipoJornada obtenido con éxito`;
    temp.data = await getStorageMvTipoJornadaArrayItem(pk);
    return temp;
  }
  const result = await get(`nomina/tipo-jornada/${pk}`);
  const data = result.data;
  console.log(`MvTipoJornada data: `, data);
  if (!data.id || data.detail) {
    console.log(`Error al obtener MvTipoJornada con pk: ${pk}, ${data.detail}`);
    temp.estado = DEFAULT_STATE.error;
    temp.mensaje = `Error al obtener el MvTipoJornada: ${
      data.detail ?? "Hubo un problema al conectarse con el servidor"
    }`;
    temp.data = await getStorageMvTipoJornadaArrayItem(pk);
    return temp;
  }
  console.log(`MvTipoJornada con pk: ${pk}, obtenido con éxito`);
  temp.estado = DEFAULT_STATE.success;
  temp.mensaje = `MvTipoJornada obtenido con éxito`;
  temp.data = await setMvTipoJornada(data, serverDate);
  await setStorageMvTipoJornada(temp.data);
  temp.data_array = await setStorageMvTipoJornadaArrayItem(pk, temp.data);
  temp.expiry = await updateLocalStorageExpiry(serverDate, temp.expiry);
  return temp;
};

export const getAllMvTipoJornada = async (serverDate: IServerDate) => {
  const temp = getEmptyStatusMvTipoJornada();
  const doHttp = await getFromLocalStorage(serverDate, temp.expiry_array);
  temp.expiry_array = doHttp.storage;
  if (!doHttp.result) {
    console.log(`(localStorage) Todos los MvTipoJornadas obtenidos con éxito`);
    temp.estado = DEFAULT_STATE.success;
    temp.mensaje = `Todos los MvTipoJornada obtenidos con éxito`;
    temp.data_array = await getStorageMvTipoJornadaArray();
    return temp;
  }
  const result = await get(`nomina/tipo-jornada`);
  const data = result.data;
  if (data.detail) {
    console.log(`Error al obtener la lista de MvTipoJornada, ${data.detail}`);
    temp.estado = DEFAULT_STATE.error;
    temp.mensaje = `Error al obtener la lista de MvTipoJornada: ${
      data.detail ?? "Hubo un problema al conectarse con el servidor"
    }`;
    temp.data_array = await getStorageMvTipoJornadaArray();
    return temp;
  }
  console.log(`Todos los MvTipoJornada obtenidos con éxito`, data);
  temp.estado = DEFAULT_STATE.success;
  temp.mensaje = `Todos los MvTipoJornada obtenidos con éxito`;
  temp.data_array = await Promise.all(
    data.map(async (e: any) => await setMvTipoJornada(e, serverDate))
  );
  await setStorageMvTipoJornadaArray(temp.data_array);
  temp.expiry_array = await updateLocalStorageExpiry(
    serverDate,
    temp.expiry_array
  );
  return temp;
};

export const updateMvTipoJornada = async (
  serverDate: IServerDate,
  pk: number,
  object: IMvTipoJornada
) => {
  const temp = getEmptyStatusMvTipoJornada();
  const result = await put(
    `nomina/tipo-jornada/${pk}`,
    getSerializableMvTipoJornada(object)
  );
  const data = result.data;
  if (!data.id || data.detail) {
    console.log(
      `Error al actualizar MvTipoJornada con pk: ${pk}, ${data.detail}`
    );
    temp.estado = DEFAULT_STATE.error;
    temp.mensaje = `Error al actualizar el MvTipoJornada: ${
      data.detail ?? "Hubo un problema al conectarse con el servidor"
    }`;
    temp.data = await setMvTipoJornada(object, serverDate);
    return temp;
  }
  console.log(`MvTipoJornada con pk: ${pk}, actualizado con éxito`);
  temp.estado = DEFAULT_STATE.success;
  temp.mensaje = `MvTipoJornada actualizado con éxito`;
  temp.data = await setMvTipoJornada(data, serverDate);
  await setStorageMvTipoJornada(temp.data);
  temp.data_array = await setStorageMvTipoJornadaArrayItem(
    temp.data.id,
    temp.data
  );
  return temp;
};

export const createMvTipoJornada = async (
  serverDate: IServerDate,
  object: IMvTipoJornada
) => {
  const temp = getEmptyStatusMvTipoJornada();
  const result = await post(
    `nomina/tipo-jornada`,
    getSerializableMvTipoJornada(object)
  );
  const data = result.data;
  if (!data.id || data.detail) {
    console.log(
      `Error al crear MvTipoJornada con los datos ${object}, ${data.detail}`
    );
    temp.estado = DEFAULT_STATE.error;
    temp.mensaje = `Error al crear el MvTipoJornada: ${
      data.detail ?? "Hubo un problema al conectarse con el servidor"
    }`;
    temp.data = await setMvTipoJornada(object, serverDate);
    return temp;
  }
  console.log(`MvTipoJornada creado con éxito`);
  temp.estado = DEFAULT_STATE.success;
  temp.mensaje = `MvTipoJornada creado con éxito`;
  temp.data = await setMvTipoJornada(data, serverDate);
  await setStorageMvTipoJornada(temp.data);
  temp.data_array = await setStorageMvTipoJornadaArrayItem(
    temp.data.id,
    temp.data
  );
  return temp;
};

export const deleteMvTipoJornada = async (pk: number) => {
  const temp = getEmptyStatusMvTipoJornada();
  const result = await del(`nomina/tipo-jornada/${pk}`);
  const data = result.data;
  if (data.detail) {
    console.log(
      `Error al eliminar MvTipoJornada con pk: ${pk}, ${data.detail}`
    );
    temp.estado = DEFAULT_STATE.error;
    temp.mensaje = `Error al eliminar el datos fiscales: ${data.detail}`;
    temp.data = await getStorageMvTipoJornadaArrayItem(pk);
    temp.data_array = await getStorageMvTipoJornadaArray();
    return temp;
  }
  console.log(`MvTipoJornada eliminado con éxito`);
  temp.estado = DEFAULT_STATE.success;
  temp.mensaje = `MvTipoJornada eliminado con éxito`;
  await removeStorageMvTipoJornada();
  temp.data_array = await removeArrayItemStorageData("IMvTipoJornada", pk, []);
  return temp;
};
