/**
 * Métodos AsyncThunk para ser usados en los reducers y
 * los resultados a las llamadas http puedan ser usados para
 * actualizar el state en redux
 */

import { createAsyncThunk } from "@reduxjs/toolkit";
import { DEFAULT_STATE } from "../../enums/default_state";
import { deleteUser, getAllUser, getUser, createUser, updateUser } from "../../http/personas";
import { getEmptyStatusUser, IUser } from "../../interfaces/personas";
import { IServerDate } from "../../interfaces/time";

export const getUserAsyncThunk = createAsyncThunk(
  'personas/getUserAsyncThunk',
  async (query : {serverDate : IServerDate, id: number}) => await getUser(query.serverDate, query.id)
    .then(async (result) => result)
    .catch(async (error) => getEmptyStatusUser(DEFAULT_STATE.error))  
);

export const getAllUserAsyncThunk = createAsyncThunk(
  'personas/getAllUserAsyncThunk',
  async (queryAll : {serverDate : IServerDate}) => await getAllUser(queryAll.serverDate)
    .then(async (result) => result)
    .catch(async (error) => getEmptyStatusUser(DEFAULT_STATE.error))  
);

export const updateUserAsyncThunk = createAsyncThunk(
  'personas/updateUserAsyncThunk',
  async (update: {serverDate : IServerDate, id: number, User: IUser}) => await updateUser(update.serverDate, update.id, update.User)
    .then(async (result) => result)
    .catch(async (error) => getEmptyStatusUser(DEFAULT_STATE.error))  
);

export const createUserAsyncThunk = createAsyncThunk(
  'personas/createUserAsyncThunk',
  async (create : {serverDate : IServerDate, User: IUser}) => await createUser(create.serverDate, create.User)
    .then(async (result) => result)
    .catch(async (error) => getEmptyStatusUser(DEFAULT_STATE.error))  
);

export const deleteUserAsyncThunk = createAsyncThunk(
  'personas/deleteUserAsyncThunk',
  async (del : {id : number}) => await deleteUser(del.id)
    .then(async (result) => result)
    .catch(async (error) => getEmptyStatusUser())  
);

