/**
 * Métodos para el manejo de IonicStorage 
 * @setStorage              guarda un unico objeto al storage
 * @getStorage              obtiene el objeto guardado con @setStorage
 * @setStorageArray         guarda el array de objetos en el storage
 * @getStorageArray         obtiene el array de objetos guardado con @setStorageArray
 * @removeStorageArray      elimina el array de objetos guardado con @setStorageArray
 * @setStorageItemArray     agrega un elemento al array de objeto guardado con @setStorageArray
 * @getStorageItemArray     obtiene un elemento del array guardado con @setStorageArray buscandolo por su id 
 * @removeStorageItemArray  elimina un elemento del array guardado con @setStorageArray buscandolo por su id 
 */

import { setStorageData, getStorageData, removeStorageData, setArrayItemStorageData, getArrayItemStorageData, removeArrayItemStorageData } from "../../api/storage";
import { IMvTipoEmpleado, getEmptyMvTipoEmpleado } from "../../interfaces/personas";

export const setStorageMvTipoEmpleado = async (IMvTipoEmpleado : IMvTipoEmpleado) => await setStorageData('IMvTipoEmpleado', IMvTipoEmpleado);
export const getStorageMvTipoEmpleado = async () : Promise<IMvTipoEmpleado> => await getStorageData('IMvTipoEmpleado', getEmptyMvTipoEmpleado());
export const removeStorageMvTipoEmpleado = async () => await removeStorageData('IMvTipoEmpleado');
export const setStorageMvTipoEmpleadoArray = async (IMvTipoEmpleado : IMvTipoEmpleado[]) => await setStorageData('IMvTipoEmpleadoArray', IMvTipoEmpleado);
export const getStorageMvTipoEmpleadoArray = async () : Promise<IMvTipoEmpleado[]> => await getStorageData('IMvTipoEmpleadoArray', [] as IMvTipoEmpleado[]);
export const removeStorageMvTipoEmpleadoArray = async () => await removeStorageData('IMvTipoEmpleadoArray');
export const setStorageMvTipoEmpleadoArrayItem = async (id: number, IMvTipoEmpleado : IMvTipoEmpleado) : Promise<IMvTipoEmpleado[]> => await setArrayItemStorageData('IMvTipoEmpleadoArray', id, IMvTipoEmpleado, [] as IMvTipoEmpleado[]);
export const getStorageMvTipoEmpleadoArrayItem = async (id: number) : Promise<IMvTipoEmpleado> => await getArrayItemStorageData('IMvTipoEmpleadoArray', id, getEmptyMvTipoEmpleado);
export const removeStorageMvTipoEmpleadoArrayItem = async (id: number) : Promise<IMvTipoEmpleado[]> => await removeArrayItemStorageData('IMvTipoEmpleadoArray', id, [] as IMvTipoEmpleado[]);
