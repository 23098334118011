/**
 * Métodos para el manejo de IonicStorage 
 * @setStorage              guarda un unico objeto al storage
 * @getStorage              obtiene el objeto guardado con @setStorage
 * @setStorageArray         guarda el array de objetos en el storage
 * @getStorageArray         obtiene el array de objetos guardado con @setStorageArray
 * @removeStorageArray      elimina el array de objetos guardado con @setStorageArray
 * @setStorageItemArray     agrega un elemento al array de objeto guardado con @setStorageArray
 * @getStorageItemArray     obtiene un elemento del array guardado con @setStorageArray buscandolo por su id 
 * @removeStorageItemArray  elimina un elemento del array guardado con @setStorageArray buscandolo por su id 
 */

import { setStorageData, getStorageData, removeStorageData, setArrayItemStorageData, getArrayItemStorageData, removeArrayItemStorageData } from "../../api/storage";
import { IModificacionSuscripcionQentry, getEmptyModificacionSuscripcionQentry } from "../../interfaces/personas";

export const setStorageModificacionSuscripcionQentry = async (IModificacionSuscripcionQentry : IModificacionSuscripcionQentry) => await setStorageData('IModificacionSuscripcionQentry', IModificacionSuscripcionQentry);
export const getStorageModificacionSuscripcionQentry = async () : Promise<IModificacionSuscripcionQentry> => await getStorageData('IModificacionSuscripcionQentry', getEmptyModificacionSuscripcionQentry());
export const removeStorageModificacionSuscripcionQentry = async () => await removeStorageData('IModificacionSuscripcionQentry');
export const setStorageModificacionSuscripcionQentryArray = async (IModificacionSuscripcionQentry : IModificacionSuscripcionQentry[]) => await setStorageData('IModificacionSuscripcionQentryArray', IModificacionSuscripcionQentry);
export const getStorageModificacionSuscripcionQentryArray = async () : Promise<IModificacionSuscripcionQentry[]> => await getStorageData('IModificacionSuscripcionQentryArray', [] as IModificacionSuscripcionQentry[]);
export const removeStorageModificacionSuscripcionQentryArray = async () => await removeStorageData('IModificacionSuscripcionQentryArray');
export const setStorageModificacionSuscripcionQentryArrayItem = async (id: number, IModificacionSuscripcionQentry : IModificacionSuscripcionQentry) : Promise<IModificacionSuscripcionQentry[]> => await setArrayItemStorageData('IModificacionSuscripcionQentryArray', id, IModificacionSuscripcionQentry, [] as IModificacionSuscripcionQentry[]);
export const getStorageModificacionSuscripcionQentryArrayItem = async (id: number) : Promise<IModificacionSuscripcionQentry> => await getArrayItemStorageData('IModificacionSuscripcionQentryArray', id, getEmptyModificacionSuscripcionQentry);
export const removeStorageModificacionSuscripcionQentryArrayItem = async (id: number) : Promise<IModificacionSuscripcionQentry[]> => await removeArrayItemStorageData('IModificacionSuscripcionQentryArray', id, [] as IModificacionSuscripcionQentry[]);
