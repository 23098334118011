/** 
 * Componente que se encarga del elemento ClienteQentry
 * @IClienteQentry es la interfaz que contiene los parámetos el elemento ClienteQentry
 * @getEmptyClienteQentry  devuelve un objeto con los parámetros por defecto; @param {id}, opcional cuando no encuentra el objeto en el LocalSotrage
 * @IStatusClienteQentry es la interfaz para los estatus a llamadas a la API; @param {status}, opcional cuando queremos otro estado de inizialización de tipo [DEFAULT_STATE]
 * @getEmptyStatusClienteQentry devuelve un objeto con los parámetros por defecto,
 * @setClienteQentry devuelve un objeto de tipo [IClienteQentry] recibiendo @param {object} y @param {serverDate}, para verificar que los datos estén en el formato correcto y ajustarlos a la zona horaria actual
 * @getSerializableClienteQentry recibe un @param {IClienteQentry}, para devolver un objeto serizalizable para las peticiones de la API
 * */

import { getDateString, isNotEmpty, SERVER_TIME_ZONE } from "../../api/helpers";
import { DEFAULT_STATE } from "../../enums/default_state";
import { getStorageDatosFiscalesArrayItem, getStoragePaisArrayItem, getStorageUserArrayItem } from "../../localStorage/personas";
import { convertDateToZonaHoraria, ILocalStorageExpiry, getEmptyLocalStorageExpiry } from "../time";
import { IServerDate } from "../time/IServerDate";
import { IDatosFiscales, getEmptyDatosFiscales, setDatosFiscales } from "./IDatosFiscales";
import { IPais, getEmptyPais, setPais } from "./IPais";
import { IUser, setUser } from "./IUser";

export interface IClienteQentry {
  id : number,
  nombre_comercial : string,
  razon_social : string,
  datos_fiscales : IDatosFiscales,
  usuarios_responsables : IUser[],
  num_sucursales : number,
  num_usuarios : number,
  clave_activacion : string,
  fecha_elaboracion : string, //Fecha
  estatus_sistema : boolean,
  ultima_actualizacion : string, //Fecha
  pais : IPais,
}

export const getEmptyClienteQentry = (id? : number) => {
  const temp : IClienteQentry = 
  {
    id : id ? id : 0,
    nombre_comercial : "",
    razon_social : "",
    datos_fiscales : getEmptyDatosFiscales(),
    usuarios_responsables : [],
    num_sucursales : 0,
    num_usuarios : 0,
    clave_activacion : "",
    fecha_elaboracion : "",
    estatus_sistema : false,
    ultima_actualizacion : "",
    pais : getEmptyPais(),
  };
  return temp;
}

export interface IStatusClienteQentry {
  estado : DEFAULT_STATE,
  mensaje : string,
  data : IClienteQentry,
  data_array : IClienteQentry[],
  expiry : ILocalStorageExpiry,
  expiry_array : ILocalStorageExpiry,
}

export const getEmptyStatusClienteQentry = (status: DEFAULT_STATE = DEFAULT_STATE.loading) => {
  const temp : IStatusClienteQentry = 
  {
    estado : status,
    mensaje : "",
    data : getEmptyClienteQentry(),
    data_array : [],
    expiry : getEmptyLocalStorageExpiry("IClienteQentry", 0),
    expiry_array : getEmptyLocalStorageExpiry("IClienteQentryArray", 0),
  };
  return temp;
}

export const setClienteQentry = async (
  object : any,
  serverDate : IServerDate,
) => {
  const temp = getEmptyClienteQentry();
  if (isNotEmpty(object.id)) temp.id = object.id;
  if (isNotEmpty(object.nombre_comercial)) temp.nombre_comercial = object.nombre_comercial;
  if (isNotEmpty(object.razon_social)) temp.razon_social = object.razon_social;
  if (isNotEmpty(object.datos_fiscales)) temp.datos_fiscales = (typeof object.datos_fiscales === 'number') ? await getStorageDatosFiscalesArrayItem(object.datos_fiscales) : await setDatosFiscales(object.datos_fiscales, serverDate);
  if (isNotEmpty(object.usuarios_responsables)) temp.usuarios_responsables = (typeof object.usuarios_responsables[0] === 'number') ? await Promise.all(object.usuarios_responsables.map(async (e : number) => await getStorageUserArrayItem(e))) : await Promise.all(object.usuarios_responsables.map(async (e : any) => await setUser(e, serverDate)));
  if (isNotEmpty(object.num_sucursales)) temp.num_sucursales = object.num_sucursales;
  if (isNotEmpty(object.num_usuarios)) temp.num_usuarios = object.num_usuarios;
  if (isNotEmpty(object.clave_activacion)) temp.clave_activacion = object.clave_activacion;
  if (isNotEmpty(object.fecha_elaboracion)) temp.fecha_elaboracion = convertDateToZonaHoraria(serverDate, object.fecha_elaboracion);
  if (isNotEmpty(object.estatus_sistema)) temp.estatus_sistema = object.estatus_sistema;
  if (isNotEmpty(object.ultima_actualizacion)) temp.ultima_actualizacion = convertDateToZonaHoraria(serverDate, object.ultima_actualizacion);
  if (isNotEmpty(object.pais)) temp.pais = (typeof object.pais === 'number') ? await getStoragePaisArrayItem(object.pais) : await setPais(object.pais, serverDate);
  return temp;
}

export const getSerializableClienteQentry = (IClienteQentry : IClienteQentry) => {
  return {
    id : IClienteQentry.id,
    nombre_comercial : IClienteQentry.nombre_comercial,
    razon_social : IClienteQentry.razon_social,
    datos_fiscales : IClienteQentry.datos_fiscales.id,
    usuarios_responsables : IClienteQentry.usuarios_responsables.map((e) => e.id),
    num_sucursales : IClienteQentry.num_sucursales,
    num_usuarios : IClienteQentry.num_usuarios,
    clave_activacion : IClienteQentry.clave_activacion,
    fecha_elaboracion : IClienteQentry.fecha_elaboracion,
    estatus_sistema : IClienteQentry.estatus_sistema,
    ultima_actualizacion : getDateString(SERVER_TIME_ZONE),
    pais : IClienteQentry.pais.id > 0 ? IClienteQentry.pais.id : null,
  };
}
