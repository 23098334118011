/** 
 * Componente que se encarga del elemento MvTipoContrato
 * @IMvTipoContrato es la interfaz que contiene los parámetos el elemento MvTipoContrato
 * @getEmptyMvTipoContrato  devuelve un objeto con los parámetros por defecto; @param {id}, opcional cuando no encuentra el objeto en el LocalSotrage
 * @IStatusMvTipoContrato es la interfaz para los estatus a llamadas a la API; @param {status}, opcional cuando queremos otro estado de inizialización de tipo [DEFAULT_STATE]
 * @getEmptyStatusMvTipoContrato devuelve un objeto con los parámetros por defecto,
 * @setMvTipoContrato devuelve un objeto de tipo [IMvTipoContrato] recibiendo @param {object} y @param {serverDate}, para verificar que los datos estén en el formato correcto y ajustarlos a la zona horaria actual
 * @getSerializableMvTipoContrato recibe un @param {IMvTipoContrato}, para devolver un objeto serizalizable para las peticiones de la API
 * */

import { getDateString, isNotEmpty, SERVER_TIME_ZONE } from "../../api/helpers";
import { DEFAULT_STATE } from "../../enums/default_state";
import { getStoragePaisArrayItem } from "../../localStorage/personas";
import { IPais, getEmptyPais, setPais } from "../personas";
import { ILocalStorageExpiry, getEmptyLocalStorageExpiry, IServerDate, convertDateToZonaHoraria } from "../time";

export interface IMvTipoContrato {
  id : number,
  dato : string,
  valor : string,
  fecha_elaboracion : string, // Fecha
  estatus_sistema : boolean,
  ultima_actualizacion : string, // Fecha
  pais : IPais,
}

export const getEmptyMvTipoContrato = (id? : number) => {
  const temp : IMvTipoContrato = 
  {
    id : id ? id : 0,
    dato : "",
    valor : "",
    fecha_elaboracion : "",
    estatus_sistema : false,
    ultima_actualizacion : "",
    pais : getEmptyPais(),
  };
  return temp;
}

export interface IStatusMvTipoContrato {
  estado : DEFAULT_STATE,
  mensaje : string,
  data : IMvTipoContrato,
  data_array: IMvTipoContrato[],
  expiry : ILocalStorageExpiry,
  expiry_array : ILocalStorageExpiry,
}

export const getEmptyStatusMvTipoContrato = (status: DEFAULT_STATE = DEFAULT_STATE.loading) => {
  const temp : IStatusMvTipoContrato = 
  {
    estado : status,
    mensaje : "",
    data : getEmptyMvTipoContrato(),
    data_array : [],
    expiry : getEmptyLocalStorageExpiry("IMvTipoContrato", 0),
    expiry_array : getEmptyLocalStorageExpiry("IMvTipoContratoArray", 0),
  };
  return temp;
}

export const setMvTipoContrato = async (
  object : any,
  serverDate : IServerDate,
) => {
  const temp = getEmptyMvTipoContrato();
  if (isNotEmpty(object.id)) temp.id = object.id;
  if (isNotEmpty(object.dato)) temp.dato = object.dato;
  if (isNotEmpty(object.valor)) temp.valor = object.valor;
  if (isNotEmpty(object.fecha_elaboracion)) temp.fecha_elaboracion = convertDateToZonaHoraria(serverDate, object.fecha_elaboracion);
  if (isNotEmpty(object.estatus_sistema)) temp.estatus_sistema = object.estatus_sistema;
  if (isNotEmpty(object.ultima_actualizacion)) temp.ultima_actualizacion = convertDateToZonaHoraria(serverDate, object.ultima_actualizacion);
  if (isNotEmpty(object.pais)) temp.pais = (typeof object.pais === 'number') ? await getStoragePaisArrayItem(object.pais) : await setPais(object.pais, serverDate);
  return temp;
}

export const getSerializableMvTipoContrato = (IMvTipoContrato : IMvTipoContrato ) => {
  return {
    id : IMvTipoContrato.id,
    dato : IMvTipoContrato.dato,
    valor : IMvTipoContrato.valor,
    fecha_elaboracion : IMvTipoContrato.fecha_elaboracion,
    estatus_sistema : IMvTipoContrato.estatus_sistema,
    ultima_actualizacion : getDateString(SERVER_TIME_ZONE),
    pais : IMvTipoContrato.pais.id,
  };
}
