/**
 * Métodos AsyncThunk para ser usados en los reducers y
 * los resultados a las llamadas http puedan ser usados para
 * actualizar el state en redux
 */

import { createAsyncThunk } from "@reduxjs/toolkit";
import { DEFAULT_STATE } from "../../enums/default_state";
import { getDatosFiscales, getAllDatosFiscales, updateDatosFiscales, createDatosFiscales, deleteDatosFiscales } from "../../http/personas";
import { getEmptyStatusDatosFiscales, IDatosFiscales } from "../../interfaces/personas";
import { IServerDate } from "../../interfaces/time";

export const getDatosFiscalesAsyncThunk = createAsyncThunk(
  'personas/getDatosFiscalesAsyncThunk',
  async (query : {serverDate : IServerDate, id: number}) => await getDatosFiscales(query.serverDate, query.id)
    .then(async (result) => result)
    .catch(async (error) => getEmptyStatusDatosFiscales(DEFAULT_STATE.error))  
);

export const getAllDatosFiscalesAsyncThunk = createAsyncThunk(
  'personas/getAllDatosFiscalesAsyncThunk',
  async (queryAll : {serverDate : IServerDate}) => await getAllDatosFiscales(queryAll.serverDate)
    .then(async (result) => result)
    .catch(async (error) => getEmptyStatusDatosFiscales(DEFAULT_STATE.error))  
);

export const updateDatosFiscalesAsyncThunk = createAsyncThunk(
  'personas/updateDatosFiscalesAsyncThunk',
  async (update: {serverDate : IServerDate, id: number, DatosFiscales: IDatosFiscales}) => await updateDatosFiscales(update.serverDate, update.id, update.DatosFiscales)
    .then(async (result) => result)
    .catch(async (error) => getEmptyStatusDatosFiscales(DEFAULT_STATE.error))  
);

export const createDatosFiscalesAsyncThunk = createAsyncThunk(
  'personas/createDatosFiscalesAsyncThunk',
  async (create : {serverDate : IServerDate, DatosFiscales: IDatosFiscales}) => await createDatosFiscales(create.serverDate, create.DatosFiscales)
    .then(async (result) => result)
    .catch(async (error) => getEmptyStatusDatosFiscales(DEFAULT_STATE.error))  
);

export const deleteDatosFiscalesAsyncThunk = createAsyncThunk(
  'personas/deleteDatosFiscalesAsyncThunk',
  async (del : {id : number}) => await deleteDatosFiscales(del.id)
    .then(async (result) => result)
    .catch(async (error) => getEmptyStatusDatosFiscales())  
);
