/** 
 * Componente que se encarga del elemento CodigoPostal
 * @ICodigoPostal es la interfaz que contiene los parámetos el elemento CodigoPostal
 * @getEmptyCodigoPostal  devuelve un objeto con los parámetros por defecto; @param {id}, opcional cuando no encuentra el objeto en el LocalSotrage
 * @IStatusCodigoPostal es la interfaz para los estatus a llamadas a la API; @param {status}, opcional cuando queremos otro estado de inizialización de tipo [DEFAULT_STATE]
 * @getEmptyStatusCodigoPostal devuelve un objeto con los parámetros por defecto,
 * @setCodigoPostal devuelve un objeto de tipo [ICodigoPostal] recibiendo @param {object} y @param {serverDate}, para verificar que los datos estén en el formato correcto y ajustarlos a la zona horaria actual
 * @getSerializableCodigoPostal recibe un @param {ICodigoPostal}, para devolver un objeto serizalizable para las peticiones de la API
 * */

import { getDateString, isNotEmpty, SERVER_TIME_ZONE } from "../../api/helpers";
import { DEFAULT_STATE } from "../../enums/default_state";
import { getStoragePaisArrayItem, getStorageUserArrayItem } from "../../localStorage/personas";
import { convertDateToZonaHoraria, ILocalStorageExpiry, getEmptyLocalStorageExpiry } from "../time";
import { IServerDate } from "../time";
import { IPais, getEmptyPais, setPais } from "./IPais";
import { IUser, getEmptyUser, setUser } from "./IUser";

export interface ICodigoPostal {
  id : number,
  codigo_postal : string,
  nombre_asentamiento : string,
  tipo_asentamiento : string,
  nombre_municipio : string,
  nombre_entidad : string,
  nombre_ciudad : string,
  codigo_postal_administracion : string,
  clave_entidad : string,
  codigo_postal_oficina : string,
  clave_tipo_asentamiento : string,
  clave_municipio : string,
  id_asentamiento : string,
  zona : string,
  clave_ciudad : string,
  pais : IPais
  autor : IUser,
  fecha_elaboracion : string, //Fecha
  estatus_sistema : boolean,
  ultima_actualizacion : string, //Fecha
}

export const getEmptyCodigoPostal = (id? : number) => {
  const temp : ICodigoPostal = 
  {
    id : id ? id : 0,
    codigo_postal : "",
    nombre_asentamiento : "",
    tipo_asentamiento : "",
    nombre_municipio : "",
    nombre_entidad : "",
    nombre_ciudad : "",
    codigo_postal_administracion : "",
    clave_entidad : "",
    codigo_postal_oficina : "",
    clave_tipo_asentamiento : "",
    clave_municipio : "",
    id_asentamiento : "",
    zona : "",
    clave_ciudad : "",
    pais : getEmptyPais(),
    autor : getEmptyUser(),
    fecha_elaboracion : "",
    estatus_sistema : false,
    ultima_actualizacion :  "",
  };
  return temp;
}

export interface IStatusCodigoPostal {
  estado : DEFAULT_STATE,
  mensaje : string,
  data : ICodigoPostal,
  data_array: ICodigoPostal[],
  expiry : ILocalStorageExpiry,
  expiry_array : ILocalStorageExpiry,
}

export const getEmptyStatusCodigoPostal = (status: DEFAULT_STATE = DEFAULT_STATE.loading) => {
  const temp : IStatusCodigoPostal = 
  {
    estado : status,
    mensaje : "",
    data : getEmptyCodigoPostal(),
    data_array : [],
    expiry : getEmptyLocalStorageExpiry("ICodigoPostal", 0),
    expiry_array : getEmptyLocalStorageExpiry("ICodigoPostalArray", 0),
  };
  return temp;
}

export const setCodigoPostal = async (
  object : any,
  serverDate : IServerDate,
) => {
  const temp = getEmptyCodigoPostal();
  if (isNotEmpty(object.id)) temp.id = object.id;
  if (isNotEmpty(object.codigo_postal)) temp.codigo_postal = object.codigo_postal;
  if (isNotEmpty(object.nombre_asentamiento)) temp.nombre_asentamiento = object.nombre_asentamiento;
  if (isNotEmpty(object.tipo_asentamiento)) temp.tipo_asentamiento = object.tipo_asentamiento;
  if (isNotEmpty(object.nombre_municipio)) temp.nombre_municipio = object.nombre_municipio;
  if (isNotEmpty(object.nombre_entidad)) temp.nombre_entidad = object.nombre_entidad;
  if (isNotEmpty(object.nombre_ciudad)) temp.nombre_ciudad = object.nombre_ciudad;
  if (isNotEmpty(object.codigo_postal_administracion)) temp.codigo_postal_administracion = object.codigo_postal_administracion;
  if (isNotEmpty(object.clave_entidad)) temp.clave_entidad = object.clave_entidad;
  if (isNotEmpty(object.codigo_postal_oficina)) temp.codigo_postal_oficina = object.codigo_postal_oficina;
  if (isNotEmpty(object.clave_tipo_asentamiento)) temp.clave_tipo_asentamiento = object.clave_tipo_asentamiento;
  if (isNotEmpty(object.clave_municipio)) temp.clave_municipio = object.clave_municipio;
  if (isNotEmpty(object.id_asentamiento)) temp.id_asentamiento = object.id_asentamiento;
  if (isNotEmpty(object.zona)) temp.zona = object.zona;
  if (isNotEmpty(object.clave_ciudad)) temp.clave_ciudad = object.clave_ciudad;
  if (isNotEmpty(object.pais)) temp.pais = (typeof object.pais === 'number') ? await getStoragePaisArrayItem(object.pais) : await setPais(object.pais, serverDate);
  if (isNotEmpty(object.autor)) temp.autor = (typeof object.autor === 'number') ? await getStorageUserArrayItem(object.autor) : await setUser(object.autor, serverDate);
  if (isNotEmpty(object.fecha_elaboracion)) temp.fecha_elaboracion = convertDateToZonaHoraria(serverDate, object.fecha_elaboracion);
  if (isNotEmpty(object.estatus_sistema)) temp.estatus_sistema = object.estatus_sistema;
  if (isNotEmpty(object.ultima_actualizacion)) temp.ultima_actualizacion = convertDateToZonaHoraria(serverDate, object.ultima_actualizacion);
  return temp;
}

export const getSerializableCodigoPostal = (ICodigoPostal : ICodigoPostal) => {
  return {
    id : ICodigoPostal.id,
    codigo_postal : ICodigoPostal.codigo_postal,
    nombre_asentamiento : ICodigoPostal.nombre_asentamiento,
    tipo_asentamiento : ICodigoPostal.tipo_asentamiento,
    nombre_municipio : ICodigoPostal.nombre_municipio,
    nombre_entidad : ICodigoPostal.nombre_entidad,
    nombre_ciudad : ICodigoPostal.nombre_ciudad,
    codigo_postal_administracion : ICodigoPostal.codigo_postal_administracion,
    clave_entidad : ICodigoPostal.clave_entidad,
    codigo_postal_oficina : ICodigoPostal.codigo_postal_oficina,
    clave_tipo_asentamiento : ICodigoPostal.clave_tipo_asentamiento,
    clave_municipio : ICodigoPostal.clave_municipio,
    id_asentamiento : ICodigoPostal.id_asentamiento,
    zona : ICodigoPostal.zona,
    clave_ciudad : ICodigoPostal.clave_ciudad,
    pais : ICodigoPostal.pais.id,
    autor : ICodigoPostal.autor.id,
    fecha_elaboracion : ICodigoPostal.fecha_elaboracion,
    estatus_sistema : ICodigoPostal.estatus_sistema,
    ultima_actualizacion : getDateString(SERVER_TIME_ZONE),
  };
}
