/** 
 * Componente que se encarga del elemento Imagen
 * @IImagen es la interfaz que contiene los parámetos el elemento Imagen
 * @getEmptyImagen  devuelve un objeto con los parámetros por defecto; @param {id}, opcional cuando no encuentra el objeto en el LocalSotrage
 * @IStatusImagen es la interfaz para los estatus a llamadas a la API; @param {status}, opcional cuando queremos otro estado de inizialización de tipo [DEFAULT_STATE]
 * @getEmptyStatusImagen devuelve un objeto con los parámetros por defecto,
 * @setImagen devuelve un objeto de tipo [IImagen] recibiendo @param {object} y @param {serverDate}, para verificar que los datos estén en el formato correcto y ajustarlos a la zona horaria actual
 * @getSerializableImagen recibe un @param {IImagen}, para devolver un objeto serizalizable para las peticiones de la API
 * */

import { getDateString, isNotEmpty, SERVER_TIME_ZONE } from "../../api/helpers";
import { DEFAULT_STATE } from "../../enums/default_state";
import { getStorageUserArrayItem, getStorageClienteQentryArrayItem } from "../../localStorage/personas";
import { convertDateToZonaHoraria, ILocalStorageExpiry, getEmptyLocalStorageExpiry, IServerDate } from "../time";
import { getEmptyClienteQentry, IClienteQentry, setClienteQentry } from "./IClienteQentry";
import { IUser, getEmptyUser, setUser } from "./IUser";

export interface IImagen {
  id : number,
  imagen : string, // Imagen
  autor : IUser,
  fecha_elaboracion : string, // Fecha
  estatus_sistema : boolean,
  ultima_actualizacion : string, // Fecha
  cliente_qentry : IClienteQentry,
}

export const getEmptyImagen = (id? : number) => {
  const temp : IImagen = 
  {
    id : id ? id : 0,
    imagen : "",
    autor : getEmptyUser(),
    fecha_elaboracion : "",
    estatus_sistema : false,
    ultima_actualizacion :  "",
    cliente_qentry : getEmptyClienteQentry(),
  };
  return temp;
}

export interface IStatusImagen {
  estado : DEFAULT_STATE,
  mensaje : string,
  data : IImagen,
  data_array: IImagen[],
  expiry : ILocalStorageExpiry,
  expiry_array : ILocalStorageExpiry,
}

export const getEmptyStatusImagen = (status: DEFAULT_STATE = DEFAULT_STATE.loading) => {
  const temp : IStatusImagen = 
  {
    estado : status,
    mensaje : "",
    data : getEmptyImagen(),
    data_array : [],
    expiry : getEmptyLocalStorageExpiry("IImagen", 0),
    expiry_array : getEmptyLocalStorageExpiry("IImagenArray", 0),
  };
  return temp;
}

export const setImagen = async (
  object : any,
  serverDate : IServerDate,
) => {
  const temp = getEmptyImagen();
  if (isNotEmpty(object.id)) temp.id = object.id;
  if (isNotEmpty(object.imagen)) temp.imagen = object.imagen;
  if (isNotEmpty(object.autor)) temp.autor = (typeof object.autor === 'number') ? await getStorageUserArrayItem(object.autor) : await setUser(object.autor, serverDate);
  if (isNotEmpty(object.fecha_elaboracion)) temp.fecha_elaboracion = convertDateToZonaHoraria(serverDate, object.fecha_elaboracion);
  if (isNotEmpty(object.estatus_sistema)) temp.estatus_sistema = object.estatus_sistema;
  if (isNotEmpty(object.ultima_actualizacion)) temp.ultima_actualizacion = convertDateToZonaHoraria(serverDate, object.ultima_actualizacion);
  if (isNotEmpty(object.cliente_qentry)) temp.cliente_qentry = (typeof object.cliente_qentry === 'number') ? await getStorageClienteQentryArrayItem(object.cliente_qentry) : await setClienteQentry(object.cliente_qentry, serverDate);
  return temp;
}

export const getSerializableImagen = (IImagen : IImagen) => {
  return {
    id : IImagen.id,
    imagen : IImagen.imagen,
    autor : IImagen.autor.id,
    fecha_elaboracion : IImagen.fecha_elaboracion,
    estatus_sistema : IImagen.estatus_sistema,
    ultima_actualizacion : getDateString(SERVER_TIME_ZONE),
    cliente_qentry : IImagen.cliente_qentry.id,
  };
}
