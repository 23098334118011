/**
 * Métodos para el manejo de IonicStorage 
 * @setStorage              guarda un unico objeto al storage
 * @getStorage              obtiene el objeto guardado con @setStorage
 * @setStorageArray         guarda el array de objetos en el storage
 * @getStorageArray         obtiene el array de objetos guardado con @setStorageArray
 * @removeStorageArray      elimina el array de objetos guardado con @setStorageArray
 * @setStorageItemArray     agrega un elemento al array de objeto guardado con @setStorageArray
 * @getStorageItemArray     obtiene un elemento del array guardado con @setStorageArray buscandolo por su id 
 * @removeStorageItemArray  elimina un elemento del array guardado con @setStorageArray buscandolo por su id 
 */

import { setStorageData, getStorageData, removeStorageData, setArrayItemStorageData, getArrayItemStorageData, removeArrayItemStorageData } from "../../api/storage";
import { ICambioEstatusIncapacidad, getEmptyCambioEstatusIncapacidad } from "../../interfaces/nomina";

export const setStorageCambioEstatusIncapacidad = async (ICambioEstatusIncapacidad : ICambioEstatusIncapacidad) => await setStorageData('ICambioEstatusIncapacidad', ICambioEstatusIncapacidad);
export const getStorageCambioEstatusIncapacidad = async () : Promise<ICambioEstatusIncapacidad> => await getStorageData('ICambioEstatusIncapacidad', getEmptyCambioEstatusIncapacidad());
export const removeStorageCambioEstatusIncapacidad = async () => await removeStorageData('ICambioEstatusIncapacidad');
export const setStorageCambioEstatusIncapacidadArray = async (ICambioEstatusIncapacidad : ICambioEstatusIncapacidad[]) => await setStorageData('ICambioEstatusIncapacidadArray', ICambioEstatusIncapacidad);
export const getStorageCambioEstatusIncapacidadArray = async () : Promise<ICambioEstatusIncapacidad[]> => await getStorageData('ICambioEstatusIncapacidadArray', [] as ICambioEstatusIncapacidad[]);
export const removeStorageCambioEstatusIncapacidadArray = async () => await removeStorageData('ICambioEstatusIncapacidadArray');
export const setStorageCambioEstatusIncapacidadArrayItem = async (id: number, ICambioEstatusIncapacidad : ICambioEstatusIncapacidad) : Promise<ICambioEstatusIncapacidad[]> => await setArrayItemStorageData('ICambioEstatusIncapacidadArray', id, ICambioEstatusIncapacidad, [] as ICambioEstatusIncapacidad[]);
export const getStorageCambioEstatusIncapacidadArrayItem = async (id: number) : Promise<ICambioEstatusIncapacidad> => await getArrayItemStorageData('ICambioEstatusIncapacidadArray', id, getEmptyCambioEstatusIncapacidad);
export const removeStorageCambioEstatusIncapacidadArrayItem = async (id: number) : Promise<ICambioEstatusIncapacidad[]> => await removeArrayItemStorageData('ICambioEstatusIncapacidadArray', id, [] as ICambioEstatusIncapacidad[]);
