/** 
 * Componente que se encarga del elemento PagoSuscripcion
 * @IPagoSuscripcion es la interfaz que contiene los parámetos el elemento PagoSuscripcion
 * @getEmptyPagoSuscripcion  devuelve un objeto con los parámetros por defecto; @param {id}, opcional cuando no encuentra el objeto en el LocalSotrage
 * @IStatusPagoSuscripcion es la interfaz para los estatus a llamadas a la API; @param {status}, opcional cuando queremos otro estado de inizialización de tipo [DEFAULT_STATE]
 * @getEmptyStatusPagoSuscripcion devuelve un objeto con los parámetros por defecto,
 * @setPagoSuscripcion devuelve un objeto de tipo [IPagoSuscripcion] recibiendo @param {object} y @param {serverDate}, para verificar que los datos estén en el formato correcto y ajustarlos a la zona horaria actual
 * @getSerializablePagoSuscripcion recibe un @param {IPagoSuscripcion}, para devolver un objeto serizalizable para las peticiones de la API
 * */

import { getDateString, isNotEmpty, SERVER_TIME_ZONE } from "../../api/helpers";
import { DEFAULT_STATE } from "../../enums/default_state";
import { getStorageClienteQentryArrayItem, getStorageSuscripcionQentryArrayItem, getStorageMvMotivoPagoSuscripcionArrayItem, getStorageMvFormaDePagoSuscripcionArrayItem, getStorageUserArrayItem } from "../../localStorage/personas";
import { convertDateToZonaHoraria, ILocalStorageExpiry, getEmptyLocalStorageExpiry, IServerDate } from "../time";
import { IClienteQentry, getEmptyClienteQentry, setClienteQentry } from "./IClienteQentry";
import { IMvFormaDePagoSuscripcion, getEmptyMvFormaDePagoSuscripcion, setMvFormaDePagoSuscripcion } from "./IMvFormaDePagoSuscripcion";
import { IMvMotivoPagoSuscripcion, getEmptyMvMotivoPagoSuscripcion, setMvMotivoPagoSuscripcion } from "./IMvMotivoPagoSuscripcion";
import { ISuscripcionQentry, getEmptySuscripcionQentry, setSuscripcionQentry } from "./ISuscripcionQentry";
import { IUser, getEmptyUser, setUser } from "./IUser";

export interface IPagoSuscripcion {
  id : number,
  cliente_qentry : IClienteQentry,
  suscripcion : ISuscripcionQentry,
  motivo_pago : IMvMotivoPagoSuscripcion,
  forma_pago : IMvFormaDePagoSuscripcion,
  sub_total : number, // Decimal
  impuestos : number, // Decimal
  descuento : number, // Decimal
  total : number, // Decimal
  descuento_aplicado : boolean,
  autor : IUser,
  fecha_elaboracion : string, // Fecha
  estatus_sistema : boolean,
  ultima_actualizacion : string, // Fecha
}

export const getEmptyPagoSuscripcion = (id? : number) => {
  const temp : IPagoSuscripcion = 
  {
    id : id ? id : 0,
    cliente_qentry : getEmptyClienteQentry(),
    suscripcion : getEmptySuscripcionQentry(),
    motivo_pago : getEmptyMvMotivoPagoSuscripcion(),
    forma_pago : getEmptyMvFormaDePagoSuscripcion(),
    sub_total : 0,
    impuestos : 0,
    descuento : 0,
    total : 0,
    descuento_aplicado : false,
    autor : getEmptyUser(),
    fecha_elaboracion : "",
    estatus_sistema : false,
    ultima_actualizacion :  "",
  };
  return temp;
}

export interface IStatusPagoSuscripcion {
  estado : DEFAULT_STATE,
  mensaje : string,
  data : IPagoSuscripcion,
  data_array: IPagoSuscripcion[],
  expiry : ILocalStorageExpiry,
  expiry_array : ILocalStorageExpiry,
}

export const getEmptyStatusPagoSuscripcion = (status: DEFAULT_STATE = DEFAULT_STATE.loading) => {
  const temp : IStatusPagoSuscripcion = 
  {
    estado : status,
    mensaje : "",
    data : getEmptyPagoSuscripcion(),
    data_array : [],
    expiry : getEmptyLocalStorageExpiry("IPagoSuscripcion", 0),
    expiry_array : getEmptyLocalStorageExpiry("IPagoSuscripcionArray", 0),
  };
  return temp;
}

export const setPagoSuscripcion = async (
  object : any,
  serverDate : IServerDate,
) => {
  const temp = getEmptyPagoSuscripcion();
  if (isNotEmpty(object.id)) temp.id = object.id;
  if (isNotEmpty(object.cliente_qentry)) temp.cliente_qentry = (typeof object.cliente_qentry === 'number') ? await getStorageClienteQentryArrayItem(object.cliente_qentry) : await setClienteQentry(object.cliente_qentry, serverDate);
  if (isNotEmpty(object.suscripcion)) temp.suscripcion = (typeof object.suscripcion === 'number') ? await getStorageSuscripcionQentryArrayItem(object.suscripcion) : await setSuscripcionQentry(object.suscripcion, serverDate);
  if (isNotEmpty(object.motivo_pago)) temp.motivo_pago = (typeof object.motivo_pago === 'number') ? await getStorageMvMotivoPagoSuscripcionArrayItem(object.motivo_pago) : await setMvMotivoPagoSuscripcion(object.motivo_pago, serverDate);
  if (isNotEmpty(object.forma_pago)) temp.forma_pago = (typeof object.forma_pago === 'number') ? await getStorageMvFormaDePagoSuscripcionArrayItem(object.forma_pago) : await setMvFormaDePagoSuscripcion(object.forma_pago, serverDate);
  if (isNotEmpty(object.sub_total)) temp.sub_total = Number(object.sub_total);
  if (isNotEmpty(object.impuestos)) temp.impuestos = Number(object.impuestos);
  if (isNotEmpty(object.descuento)) temp.descuento = Number(object.descuento);
  if (isNotEmpty(object.total)) temp.total = Number(object.total);
  if (isNotEmpty(object.descuento_aplicado)) temp.descuento_aplicado = object.descuento_aplicado;
  if (isNotEmpty(object.autor)) temp.autor = (typeof object.autor === 'number') ? await getStorageUserArrayItem(object.autor) : await setUser(object.autor, serverDate);
  if (isNotEmpty(object.fecha_elaboracion)) temp.fecha_elaboracion = convertDateToZonaHoraria(serverDate, object.fecha_elaboracion);
  if (isNotEmpty(object.estatus_sistema)) temp.estatus_sistema = object.estatus_sistema;
  if (isNotEmpty(object.ultima_actualizacion)) temp.ultima_actualizacion = convertDateToZonaHoraria(serverDate, object.ultima_actualizacion);
  return temp;
}

export const getSerializablePagoSuscripcion = (IPagoSuscripcion : IPagoSuscripcion) => {
  return {
    id : IPagoSuscripcion.id,
    cliente_qentry : IPagoSuscripcion.cliente_qentry.id,
    suscripcion : IPagoSuscripcion.suscripcion.id,
    motivo_pago : IPagoSuscripcion.motivo_pago.id,
    forma_pago : IPagoSuscripcion.forma_pago.id,
    sub_total : IPagoSuscripcion.sub_total.toFixed(2),
    impuestos : IPagoSuscripcion.impuestos.toFixed(2),
    descuento : IPagoSuscripcion.descuento.toFixed(2),
    total : IPagoSuscripcion.total.toFixed(2),
    descuento_aplicado : IPagoSuscripcion.descuento_aplicado,
    autor : IPagoSuscripcion.autor.id,
    fecha_elaboracion : IPagoSuscripcion.fecha_elaboracion,
    estatus_sistema : IPagoSuscripcion.estatus_sistema,
    ultima_actualizacion : getDateString(SERVER_TIME_ZONE),
  };
}
