/** 
 * Componente que se encarga del elemento User
 * @IUser es la interfaz que contiene los parámetos el elemento User
 * @getEmptyUser  devuelve un objeto con los parámetros por defecto; @param {id}, opcional cuando no encuentra el objeto en el LocalSotrage
 * @IStatusUser es la interfaz para los estatus a llamadas a la API; @param {status}, opcional cuando queremos otro estado de inizialización de tipo [DEFAULT_STATE]
 * @getEmptyStatusUser devuelve un objeto con los parámetros por defecto,
 * @setUser devuelve un objeto de tipo [IUser] recibiendo @param {object} y @param {serverDate}, para verificar que los datos estén en el formato correcto y ajustarlos a la zona horaria actual
 * @getSerializableUser recibe un @param {IUser}, para devolver un objeto serizalizable para las peticiones de la API
 * */

import { isNotEmpty } from "../../api/helpers";
import { DEFAULT_STATE } from "../../enums/default_state";
import { getStorageMvTipoUsuarioArrayItem } from "../../localStorage/personas";
import { IServerDate } from "../time";
import { ILocalStorageExpiry, getEmptyLocalStorageExpiry } from "../time/ILocalStorageExpiry";
import { IMvTipoUsuario, getEmptyMvTipoUsuario, setMvTipoUsuario } from "./IMvTipoUsuario";

export interface IUser {
  id : number,
  username : string,
  tipo_usuario : IMvTipoUsuario,
  uuid_firebase : string,
}

export const getEmptyUser = (id? : number) => {
  const temp : IUser = 
  {
    id : id ? id : 0,
    username : "",
    tipo_usuario : getEmptyMvTipoUsuario(),
    uuid_firebase : "",
  };
  return temp;
}

export interface IStatusUser {
  estado : DEFAULT_STATE,
  mensaje : string,
  data : IUser,
  data_array: IUser[],
  expiry : ILocalStorageExpiry,
  expiry_array : ILocalStorageExpiry,
}

export const getEmptyStatusUser = (status: DEFAULT_STATE = DEFAULT_STATE.loading) => {
  const temp : IStatusUser = 
  {
    estado : status,
    mensaje : "",
    data : getEmptyUser(),
    data_array : [],
    expiry : getEmptyLocalStorageExpiry("IUser", 0),
    expiry_array : getEmptyLocalStorageExpiry("IUserArray", 0),
  };
  return temp;
}

export const setUser = async (
  object : any,
  serverDate : IServerDate,
) => {
  const temp = getEmptyUser();
  if (isNotEmpty(object.id)) temp.id = object.id;
  if (isNotEmpty(object.username)) temp.username = object.username;
  if (isNotEmpty(object.tipo_usuario)) temp.tipo_usuario = (typeof object.tipo_usuario === 'number') ? await getStorageMvTipoUsuarioArrayItem(object.tipo_usuario) : await setMvTipoUsuario(object.tipo_usuario, serverDate);
  if (isNotEmpty(object.uuid_firebase)) temp.uuid_firebase = object.uuid_firebase;
  return temp;
}

export const getSerializableUser = (IUser : IUser) => {
  return {
    id : IUser.id,
    username : IUser.username,
    tipo_usuario : IUser.tipo_usuario.id > 0 ? IUser.tipo_usuario.id : null,
    uuid_firebase : IUser.uuid_firebase,
  };
}
