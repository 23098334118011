/**
 * Métodos para el manejo de IonicStorage 
 * @setStorage              guarda un unico objeto al storage
 * @getStorage              obtiene el objeto guardado con @setStorage
 * @setStorageArray         guarda el array de objetos en el storage
 * @getStorageArray         obtiene el array de objetos guardado con @setStorageArray
 * @removeStorageArray      elimina el array de objetos guardado con @setStorageArray
 * @setStorageItemArray     agrega un elemento al array de objeto guardado con @setStorageArray
 * @getStorageItemArray     obtiene un elemento del array guardado con @setStorageArray buscandolo por su id 
 * @removeStorageItemArray  elimina un elemento del array guardado con @setStorageArray buscandolo por su id 
 */

import { setStorageData, getStorageData, removeStorageData, setArrayItemStorageData, getArrayItemStorageData, removeArrayItemStorageData } from "../../api/storage";
import { IMvRiesgoPuesto, getEmptyMvRiesgoPuesto } from "../../interfaces/nomina";

export const setStorageMvRiesgoPuesto = async (IMvRiesgoPuesto : IMvRiesgoPuesto) => await setStorageData('IMvRiesgoPuesto', IMvRiesgoPuesto);
export const getStorageMvRiesgoPuesto = async () : Promise<IMvRiesgoPuesto> => await getStorageData('IMvRiesgoPuesto', getEmptyMvRiesgoPuesto());
export const removeStorageMvRiesgoPuesto = async () => await removeStorageData('IMvRiesgoPuesto');
export const setStorageMvRiesgoPuestoArray = async (IMvRiesgoPuesto : IMvRiesgoPuesto[]) => await setStorageData('IMvRiesgoPuestoArray', IMvRiesgoPuesto);
export const getStorageMvRiesgoPuestoArray = async () : Promise<IMvRiesgoPuesto[]> => await getStorageData('IMvRiesgoPuestoArray', [] as IMvRiesgoPuesto[]);
export const removeStorageMvRiesgoPuestoArray = async () => await removeStorageData('IMvRiesgoPuestoArray');
export const setStorageMvRiesgoPuestoArrayItem = async (id: number, IMvRiesgoPuesto : IMvRiesgoPuesto) : Promise<IMvRiesgoPuesto[]> => await setArrayItemStorageData('IMvRiesgoPuestoArray', id, IMvRiesgoPuesto, [] as IMvRiesgoPuesto[]);
export const getStorageMvRiesgoPuestoArrayItem = async (id: number) : Promise<IMvRiesgoPuesto> => await getArrayItemStorageData('IMvRiesgoPuestoArray', id, getEmptyMvRiesgoPuesto);
export const removeStorageMvRiesgoPuestoArrayItem = async (id: number) : Promise<IMvRiesgoPuesto[]> => await removeArrayItemStorageData('IMvRiesgoPuestoArray', id, [] as IMvRiesgoPuesto[]);
