/** 
 * Componente que se encarga del elemento CheckpointRondin
 * @ICheckpointRondin es la interfaz que contiene los parámetos el elemento CheckpointRondin
 * @getEmptyCheckpointRondin  devuelve un objeto con los parámetros por defecto; @param {id}, opcional cuando no encuentra el objeto en el LocalSotrage
 * @IStatusCheckpointRondin es la interfaz para los estatus a llamadas a la API; @param {status}, opcional cuando queremos otro estado de inizialización de tipo [DEFAULT_STATE]
 * @getEmptyStatusCheckpointRondin devuelve un objeto con los parámetros por defecto,
 * @setCheckpointRondin devuelve un objeto de tipo [ICheckpointRondin] recibiendo @param {object} y @param {serverDate}, para verificar que los datos estén en el formato correcto y ajustarlos a la zona horaria actual
 * @getSerializableCheckpointRondin recibe un @param {ICheckpointRondin}, para devolver un objeto serizalizable para las peticiones de la API
 * */

import { getDateString, isNotEmpty, SERVER_TIME_ZONE } from "../../api/helpers";
import { DEFAULT_STATE } from "../../enums/default_state";
import { getStorageClienteQentryArrayItem, getStorageCodigoQrArrayItem, getStorageEmpresaArrayItem, getStorageGeolocalizacionArrayItem } from "../../localStorage/personas";
import { getEmptyClienteQentry, getEmptyCodigoQr, getEmptyEmpresa, getEmptyGeolocalizacion, IClienteQentry, ICodigoQr, IEmpresa, IGeolocalizacion, setClienteQentry, setCodigoQr, setEmpresa, setGeolocalizacion } from "../personas";
import { ILocalStorageExpiry, getEmptyLocalStorageExpiry, IServerDate, convertDateToZonaHoraria } from "../time";

export interface ICheckpointRondin {
  id : number,
  nombre_checkpoint : string,
  codigo_qr : ICodigoQr,
  nodo_final : boolean,
  nodo_inicial : boolean,
  geolocalizacion : IGeolocalizacion,
  cliente_qentry : IClienteQentry,
  empresa : IEmpresa,
  fecha_elaboracion : string, // Fecha
  estatus_sistema : boolean,
  ultima_actualizacion : string, // Fecha
}

export const getEmptyCheckpointRondin = (id? : number) => {
  const temp : ICheckpointRondin = 
  {
    id : id ? id : 0,
    nombre_checkpoint : "",
    codigo_qr : getEmptyCodigoQr(),
    nodo_final : false,
    nodo_inicial : false,
    geolocalizacion : getEmptyGeolocalizacion(),
    empresa : getEmptyEmpresa(),
    cliente_qentry : getEmptyClienteQentry(),
    fecha_elaboracion : "",
    estatus_sistema : false,
    ultima_actualizacion : "",
  };
  return temp;
}

export interface IStatusCheckpointRondin {
  estado : DEFAULT_STATE,
  mensaje : string,
  data : ICheckpointRondin,
  data_array: ICheckpointRondin[],
  expiry : ILocalStorageExpiry,
  expiry_array : ILocalStorageExpiry,
}

export const getEmptyStatusCheckpointRondin = (status: DEFAULT_STATE = DEFAULT_STATE.loading) => {
  const temp : IStatusCheckpointRondin = 
  {
    estado : status,
    mensaje : "",
    data : getEmptyCheckpointRondin(),
    data_array : [],
    expiry : getEmptyLocalStorageExpiry("ICheckpointRondin", 0),
    expiry_array : getEmptyLocalStorageExpiry("ICheckpointRondinArray", 0),
  };
  return temp;
}

export const setCheckpointRondin = async (
  object : any,
  serverDate : IServerDate,
) => {
  const temp = getEmptyCheckpointRondin();
  if (isNotEmpty(object.id)) temp.id = object.id;
  if (isNotEmpty(object.nombre_checkpoint)) temp.nombre_checkpoint = object.nombre_checkpoint;
  if (isNotEmpty(object.codigo_qr)) temp.codigo_qr = (typeof object.codigo_qr === 'number') ? await getStorageCodigoQrArrayItem(object.codigo_qr) : await setCodigoQr(object.codigo_qr, serverDate);  
  if (isNotEmpty(object.nodo_final)) temp.nodo_final = object.nodo_final;
  if (isNotEmpty(object.nodo_inicial)) temp.nodo_inicial = object.nodo_inicial;
  if (isNotEmpty(object.geolocalizacion)) temp.geolocalizacion = (typeof object.geolocalizacion === 'number') ? await getStorageGeolocalizacionArrayItem(object.geolocalizacion) : await setGeolocalizacion(object.geolocalizacion, serverDate);
  if (isNotEmpty(object.cliente_qentry)) temp.cliente_qentry = (typeof object.cliente_qentry === 'number') ? await getStorageClienteQentryArrayItem(object.cliente_qentry) : await setClienteQentry(object.cliente_qentry, serverDate);
  if (isNotEmpty(object.empresa)) temp.empresa = (typeof object.empresa === 'number') ? await getStorageEmpresaArrayItem(object.empresa) : await setEmpresa(object.empresa, serverDate);  
  if (isNotEmpty(object.fecha_elaboracion)) temp.fecha_elaboracion = convertDateToZonaHoraria(serverDate, object.fecha_elaboracion);
  if (isNotEmpty(object.estatus_sistema)) temp.estatus_sistema = object.estatus_sistema;
  if (isNotEmpty(object.ultima_actualizacion)) temp.ultima_actualizacion = convertDateToZonaHoraria(serverDate, object.ultima_actualizacion);
  return temp;
}

export const getSerializableCheckpointRondin = (ICheckpointRondin : ICheckpointRondin ) => {
  return {
    id : ICheckpointRondin.id,
    nombre_checkpoint : ICheckpointRondin.nombre_checkpoint,
    codigo_qr : ICheckpointRondin.codigo_qr.id,
    nodo_final : ICheckpointRondin.nodo_final,
    nodo_inicial : ICheckpointRondin.nodo_inicial,
    geolocalizacion : ICheckpointRondin.geolocalizacion.id,
    empresa : ICheckpointRondin.empresa.id,
    cliente_qentry : ICheckpointRondin.cliente_qentry.id,
    fecha_elaboracion : ICheckpointRondin.fecha_elaboracion,
    estatus_sistema : ICheckpointRondin.estatus_sistema,
    ultima_actualizacion : getDateString(SERVER_TIME_ZONE),
  };
}
