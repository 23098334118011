/** 
 * Componente que se encarga del elemento Turno
 * @ITurno es la interfaz que contiene los parámetos el elemento Turno
 * @getEmptyTurno  devuelve un objeto con los parámetros por defecto; @param {id}, opcional cuando no encuentra el objeto en el LocalSotrage
 * @IStatusTurno es la interfaz para los estatus a llamadas a la API; @param {status}, opcional cuando queremos otro estado de inizialización de tipo [DEFAULT_STATE]
 * @getEmptyStatusTurno devuelve un objeto con los parámetros por defecto,
 * @setTurno devuelve un objeto de tipo [ITurno] recibiendo @param {object} y @param {serverDate}, para verificar que los datos estén en el formato correcto y ajustarlos a la zona horaria actual
 * @getSerializableTurno recibe un @param {ITurno}, para devolver un objeto serizalizable para las peticiones de la API
 * */

import { getDateString, isNotEmpty, SERVER_TIME_ZONE } from "../../api/helpers";
import { DEFAULT_STATE } from "../../enums/default_state";
import { getStorageEmpleadoArrayItem, getStorageUserArrayItem, getStorageClienteQentryArrayItem } from "../../localStorage/personas";
import { getEmptyClienteQentry, getEmptyEmpleado, getEmptyUser, IClienteQentry, IEmpleado, IUser, setClienteQentry, setEmpleado, setUser } from "../personas";
import { ILocalStorageExpiry, getEmptyLocalStorageExpiry, IServerDate, convertDateToZonaHoraria } from "../time";

export interface ITurno {
  id : number,
  nombre_horario : string,
  inicio_lunes : string | null, // Horas
  fin_lunes : string | null, // Horas
  inicio_martes : string | null, // Horas
  fin_martes : string | null, // Horas
  inicio_miercoles : string | null, // Horas
  fin_miercoles : string | null, // Horas
  inicio_jueves : string | null, // Horas
  fin_jueves : string | null, // Horas
  inicio_viernes : string | null, // Horas
  fin_viernes : string | null, // Horas
  inicio_sabado : string | null, // Horas
  fin_sabado : string | null, // Horas
  inicio_domingo : string | null, // Horas
  fin_domingo : string | null, // Horas
  dias_descanso_por_semana : number,
  minutos_tolerancia_entrada : number,
  minutos_tolerancia_salida : number,
  horario_24_hrs : boolean,
  autor : IUser,
  fecha_elaboracion : string, // Fecha
  estatus_sistema : boolean,
  ultima_actualizacion : string, // Fecha
  cliente_qentry : IClienteQentry
}

export const getEmptyTurno = (id? : number) => {
  const temp : ITurno = 
  {
    id : id ? id : 0,
    nombre_horario : "",
    inicio_lunes : null, // Horas
    fin_lunes : null, // Horas
    inicio_martes : null, // Horas
    fin_martes : null, // Horas
    inicio_miercoles : null, // Horas
    fin_miercoles : null, // Horas
    inicio_jueves : null, // Horas
    fin_jueves : null, // Horas
    inicio_viernes : null, // Horas
    fin_viernes : null, // Horas
    inicio_sabado : null, // Horas
    fin_sabado : null, // Horas
    inicio_domingo : null, // Horas
    fin_domingo : null, // Horas
    dias_descanso_por_semana : 0,
    minutos_tolerancia_entrada : 0,
    minutos_tolerancia_salida : 0,
    horario_24_hrs : false,
    autor : getEmptyUser(),
    fecha_elaboracion : "",
    estatus_sistema : false,
    ultima_actualizacion : "",
    cliente_qentry : getEmptyClienteQentry(),
  };
  return temp;
}

export interface IStatusTurno {
  estado : DEFAULT_STATE,
  mensaje : string,
  data : ITurno,
  data_array: ITurno[],
  expiry : ILocalStorageExpiry,
  expiry_array : ILocalStorageExpiry,
}

export const getEmptyStatusTurno = (status: DEFAULT_STATE = DEFAULT_STATE.loading) => {
  const temp : IStatusTurno = 
  {
    estado : status,
    mensaje : "",
    data : getEmptyTurno(),
    data_array : [],
    expiry : getEmptyLocalStorageExpiry("ITurno", 0),
    expiry_array : getEmptyLocalStorageExpiry("ITurnoArray", 0),
  };
  return temp;
}

export const setTurno = async (
  object : any,
  serverDate : IServerDate,
) => {
  const temp = getEmptyTurno();
  if (isNotEmpty(object.id)) temp.id = object.id;
  if (isNotEmpty(object.nombre_horario)) temp.nombre_horario = object.nombre_horario;
  if (isNotEmpty(object.inicio_lunes)) temp.inicio_lunes = object.inicio_lunes;
  if (isNotEmpty(object.fin_lunes)) temp.fin_lunes = object.fin_lunes;
  if (isNotEmpty(object.inicio_martes)) temp.inicio_martes = object.inicio_martes;
  if (isNotEmpty(object.fin_martes)) temp.fin_martes = object.fin_martes;
  if (isNotEmpty(object.inicio_miercoles)) temp.inicio_miercoles = object.inicio_miercoles;
  if (isNotEmpty(object.fin_miercoles)) temp.fin_miercoles = object.fin_miercoles;
  if (isNotEmpty(object.inicio_jueves)) temp.inicio_jueves = object.inicio_jueves;
  if (isNotEmpty(object.fin_jueves)) temp.fin_jueves = object.fin_jueves;
  if (isNotEmpty(object.inicio_viernes)) temp.inicio_viernes = object.inicio_viernes;
  if (isNotEmpty(object.fin_viernes)) temp.fin_viernes = object.fin_viernes;
  if (isNotEmpty(object.inicio_sabado)) temp.inicio_sabado = object.inicio_sabado;
  if (isNotEmpty(object.fin_sabado)) temp.fin_sabado = object.fin_sabado;
  if (isNotEmpty(object.inicio_domingo)) temp.inicio_domingo = object.inicio_domingo;
  if (isNotEmpty(object.fin_domingo)) temp.fin_domingo = object.fin_domingo;
  if (isNotEmpty(object.dias_descanso_por_semana)) temp.dias_descanso_por_semana = object.dias_descanso_por_semana;
  if (isNotEmpty(object.minutos_tolerancia_entrada)) temp.minutos_tolerancia_entrada = object.minutos_tolerancia_entrada;
  if (isNotEmpty(object.minutos_tolerancia_salida)) temp.minutos_tolerancia_salida = object.minutos_tolerancia_salida;
  if (isNotEmpty(object.horario_24_hrs)) temp.horario_24_hrs = object.horario_24_hrs;
  if (isNotEmpty(object.autor)) temp.autor = (typeof object.autor === 'number') ? await getStorageUserArrayItem(object.autor) : await setUser(object.autor, serverDate);
  if (isNotEmpty(object.fecha_elaboracion)) temp.fecha_elaboracion = convertDateToZonaHoraria(serverDate, object.fecha_elaboracion);
  if (isNotEmpty(object.estatus_sistema)) temp.estatus_sistema = object.estatus_sistema;
  if (isNotEmpty(object.ultima_actualizacion)) temp.ultima_actualizacion = convertDateToZonaHoraria(serverDate, object.ultima_actualizacion);
  if (isNotEmpty(object.cliente_qentry)) temp.cliente_qentry = (typeof object.cliente_qentry === 'number') ? await getStorageClienteQentryArrayItem(object.cliente_qentry) : await setClienteQentry(object.cliente_qentry, serverDate);
  
  return temp;
}

export const getSerializableTurno = (ITurno : ITurno ) => {
  return {
    id : ITurno.id,
    nombre_horario : ITurno.nombre_horario,
    inicio_lunes : ITurno.inicio_lunes, // Horas
    fin_lunes : ITurno.fin_lunes, // Horas
    inicio_martes : ITurno.inicio_martes, // Horas
    fin_martes : ITurno.fin_martes, // Horas
    inicio_miercoles : ITurno.inicio_miercoles, // Horas
    fin_miercoles : ITurno.fin_miercoles, // Horas
    inicio_jueves : ITurno.inicio_jueves, // Horas
    fin_jueves : ITurno.fin_jueves, // Horas
    inicio_viernes : ITurno.inicio_viernes, // Horas
    fin_viernes : ITurno.fin_viernes, // Horas
    inicio_sabado : ITurno.inicio_sabado, // Horas
    fin_sabado : ITurno.fin_sabado, // Horas
    inicio_domingo : ITurno.inicio_domingo, // Horas
    fin_domingo : ITurno.fin_domingo, // Horas
    dias_descanso_por_semana : ITurno.dias_descanso_por_semana,
    minutos_tolerancia_entrada : ITurno.minutos_tolerancia_entrada,
    minutos_tolerancia_salida : ITurno.minutos_tolerancia_salida,
    horario_24_hrs : ITurno.horario_24_hrs,
    autor : ITurno.autor.id,
    fecha_elaboracion : ITurno.fecha_elaboracion,
    estatus_sistema : ITurno.estatus_sistema,
    ultima_actualizacion : getDateString(SERVER_TIME_ZONE),
    cliente_qentry : ITurno.cliente_qentry.id,
  };
}
