/**
 * Métodos AsyncThunk para ser usados en los reducers y
 * los resultados a las llamadas http puedan ser usados para
 * actualizar el state en redux
 */

import { createAsyncThunk } from "@reduxjs/toolkit";
import { DEFAULT_STATE } from "../../enums/default_state";
import { getMvAntiguedad, getAllMvAntiguedad, updateMvAntiguedad, createMvAntiguedad, deleteMvAntiguedad } from "../../http/personas";
import { getEmptyStatusMvAntiguedad, IMvAntiguedad } from "../../interfaces/personas";
import { IServerDate } from "../../interfaces/time";

export const getMvAntiguedadAsyncThunk = createAsyncThunk(
  'personas/getMvAntiguedadAsyncThunk',
  async (query : {serverDate : IServerDate, id: number}) => await getMvAntiguedad(query.serverDate, query.id)
    .then(async (result) => result)
    .catch(async (error) => getEmptyStatusMvAntiguedad(DEFAULT_STATE.error))  
);

export const getAllMvAntiguedadAsyncThunk = createAsyncThunk(
  'personas/getAllMvAntiguedadAsyncThunk',
  async (queryAll : {serverDate : IServerDate}) => await getAllMvAntiguedad(queryAll.serverDate)
    .then(async (result) => result)
    .catch(async (error) => getEmptyStatusMvAntiguedad(DEFAULT_STATE.error))  
);

export const updateMvAntiguedadAsyncThunk = createAsyncThunk(
  'personas/updateMvAntiguedadAsyncThunk',
  async (update: {serverDate : IServerDate, id: number, MvAntiguedad: IMvAntiguedad}) => await updateMvAntiguedad(update.serverDate, update.id, update.MvAntiguedad)
    .then(async (result) => result)
    .catch(async (error) => getEmptyStatusMvAntiguedad(DEFAULT_STATE.error))  
);

export const createMvAntiguedadAsyncThunk = createAsyncThunk(
  'personas/createMvAntiguedadAsyncThunk',
  async (create : {serverDate : IServerDate, MvAntiguedad: IMvAntiguedad}) => await createMvAntiguedad(create.serverDate, create.MvAntiguedad)
    .then(async (result) => result)
    .catch(async (error) => getEmptyStatusMvAntiguedad(DEFAULT_STATE.error))  
);

export const deleteMvAntiguedadAsyncThunk = createAsyncThunk(
  'personas/deleteMvAntiguedadAsyncThunk',
  async (del : {id : number}) => await deleteMvAntiguedad(del.id)
    .then(async (result) => result)
    .catch(async (error) => getEmptyStatusMvAntiguedad())  
);

