/**
 * Reducers creados para obtener asíncronamente 
 * los datos de los modelos y dependiendo del resultado
 * de la peticón actualice el state en redux o mande un mensaje de error
 */

import { PayloadAction } from "@reduxjs/toolkit";
import { getServicioQentryAsyncThunk, getAllServicioQentryAsyncThunk, updateServicioQentryAsyncThunk, createServicioQentryAsyncThunk, deleteServicioQentryAsyncThunk } from "../../asyncThunk/personas";
import { DEFAULT_STATE } from "../../enums/default_state";
import { IStatusServicioQentry } from "../../interfaces/personas";
import { personasInitialState } from "../../slices/personasSlice";

// Reducers: ServicioQentry
export const createServicioQentryReducers = () => {
  const get = () => {
    var { pending, fulfilled, rejected } = getServicioQentryAsyncThunk;
    return {
      [pending.type]: (state : typeof personasInitialState) => {
        state.personasState.servicioQentry.estado = DEFAULT_STATE.loading;
      },
      [fulfilled.type]: (state : typeof personasInitialState, action : PayloadAction<IStatusServicioQentry>) => {
        const result = action.payload;
        result.expiry_array = state.personasState.servicioQentry.expiry_array;
        if (result.estado === DEFAULT_STATE.error) result.data_array = state.personasState.servicioQentry.data_array;
        state.personasState.servicioQentry = result;
      },
      [rejected.type]: (state : typeof personasInitialState, action : PayloadAction<IStatusServicioQentry>) => {
        state.personasState.servicioQentry = {
          ...state.personasState.servicioQentry,
          estado: action.payload.estado,
          mensaje: "Ocurrió un problema al realizar la solicitud",
        };
      },
    };
  };
  
  const getAll = () => {
    var { pending, fulfilled, rejected } = getAllServicioQentryAsyncThunk;
    return {
      [pending.type]: (state : typeof personasInitialState) => {
        state.personasState.servicioQentry.estado = DEFAULT_STATE.loading;
      },
      [fulfilled.type]: (state : typeof personasInitialState, action : PayloadAction<IStatusServicioQentry>) => {
        const result = action.payload;
        result.data = state.personasState.servicioQentry.data;
        result.expiry = state.personasState.servicioQentry.expiry;
        if (result.estado === DEFAULT_STATE.error) result.data_array = state.personasState.servicioQentry.data_array;
        state.personasState.servicioQentry = result;
      },
      [rejected.type]: (state : typeof personasInitialState, action : PayloadAction<IStatusServicioQentry>) => {
        state.personasState.servicioQentry = {
          ...state.personasState.servicioQentry,
          estado: action.payload.estado,
          mensaje: "Ocurrió un problema al realizar la solicitud",
        };
      },
    };
  };
  
  const update = () => {
    var { pending, fulfilled, rejected } = updateServicioQentryAsyncThunk;
    return {
      [pending.type]: (state : typeof personasInitialState) => {
        state.personasState.servicioQentry.estado = DEFAULT_STATE.loading;
      },
      [fulfilled.type]: (state : typeof personasInitialState, action : PayloadAction<IStatusServicioQentry>) => {
        const result: IStatusServicioQentry = action.payload;
        result.expiry = state.personasState.servicioQentry.expiry;
        result.expiry_array = state.personasState.servicioQentry.expiry_array;
        if (result.estado === DEFAULT_STATE.error) result.data_array = state.personasState.servicioQentry.data_array;
        state.personasState.servicioQentry = result;
      },
      [rejected.type]: (state : typeof personasInitialState, action : PayloadAction<IStatusServicioQentry>) => {
        state.personasState.servicioQentry = {
          ...state.personasState.servicioQentry,
          estado: action.payload.estado,
          mensaje: "Ocurrió un problema al realizar la solicitud",
        };
      },
    };
  };

  const create = () => {
    var { pending, fulfilled, rejected } = createServicioQentryAsyncThunk;
    return {
      [pending.type]: (state : typeof personasInitialState) => {
        state.personasState.servicioQentry.estado = DEFAULT_STATE.loading;
      },
      [fulfilled.type]: (state : typeof personasInitialState, action : PayloadAction<IStatusServicioQentry>) => {
        const result = action.payload;
        result.expiry = state.personasState.servicioQentry.expiry;
        result.expiry_array = state.personasState.servicioQentry.expiry_array;
        if (result.estado === DEFAULT_STATE.error) result.data_array = state.personasState.servicioQentry.data_array;
        state.personasState.servicioQentry = result;
      },
      [rejected.type]: (state : typeof personasInitialState, action : PayloadAction<IStatusServicioQentry>) => {
        state.personasState.servicioQentry = {
          ...state.personasState.servicioQentry,
          estado: action.payload.estado,
          mensaje: "Ocurrió un problema al realizar la solicitud",
        };
      },
    };
  };
  
  const del = () => {
    var { pending, fulfilled, rejected } = deleteServicioQentryAsyncThunk;
    return {
      [pending.type]: (state : typeof personasInitialState) => {
        state.personasState.servicioQentry.estado = DEFAULT_STATE.loading;
      },
      [fulfilled.type]: (state : typeof personasInitialState, action : PayloadAction<IStatusServicioQentry>) => {
        const result: IStatusServicioQentry = action.payload;
        result.expiry = state.personasState.servicioQentry.expiry;
        result.expiry_array = state.personasState.servicioQentry.expiry_array;
        if (result.estado === DEFAULT_STATE.error) result.data_array = state.personasState.servicioQentry.data_array;
        state.personasState.servicioQentry = result;
      },
      [rejected.type]: (state : typeof personasInitialState, action : PayloadAction<IStatusServicioQentry>) => {
        state.personasState.servicioQentry = {
          ...state.personasState.servicioQentry,
          estado: action.payload.estado,
          mensaje: "Ocurrió un problema al realizar la solicitud",
        };
      },
    };
  };

  return {
    ...get(),
    ...getAll(),
    ...update(),
    ...create(),
    ...del(),
  };
};
