/**
 * Métodos AsyncThunk para ser usados en los reducers y
 * los resultados a las llamadas http puedan ser usados para
 * actualizar el state en redux
 */

import { createAsyncThunk } from "@reduxjs/toolkit";
import { DEFAULT_STATE } from "../../enums/default_state";
import { getMvTipoMovimientoAsistencia, getAllMvTipoMovimientoAsistencia, updateMvTipoMovimientoAsistencia, createMvTipoMovimientoAsistencia, deleteMvTipoMovimientoAsistencia } from "../../http/nomina";
import { getEmptyStatusMvTipoMovimientoAsistencia, IMvTipoMovimientoAsistencia } from "../../interfaces/nomina";
import { IServerDate } from "../../interfaces/time";

export const getMvTipoMovimientoAsistenciaAsyncThunk = createAsyncThunk(
  'nomina/getMvTipoMovimientoAsistenciaAsyncThunk',
  async (query : {serverDate : IServerDate, id: number}) => await getMvTipoMovimientoAsistencia(query.serverDate, query.id)
    .then(async (result) => result)
    .catch(async (error) => getEmptyStatusMvTipoMovimientoAsistencia(DEFAULT_STATE.error))  
);

export const getAllMvTipoMovimientoAsistenciaAsyncThunk = createAsyncThunk(
  'nomina/getAllMvTipoMovimientoAsistenciaAsyncThunk',
  async (queryAll : {serverDate : IServerDate}) => await getAllMvTipoMovimientoAsistencia(queryAll.serverDate)
    .then(async (result) => result)
    .catch(async (error) => getEmptyStatusMvTipoMovimientoAsistencia(DEFAULT_STATE.error))  
);

export const updateMvTipoMovimientoAsistenciaAsyncThunk = createAsyncThunk(
  'nomina/updateMvTipoMovimientoAsistenciaAsyncThunk',
  async (update: {serverDate : IServerDate, id: number, MvTipoMovimientoAsistencia: IMvTipoMovimientoAsistencia}) => await updateMvTipoMovimientoAsistencia(update.serverDate, update.id, update.MvTipoMovimientoAsistencia)
    .then(async (result) => result)
    .catch(async (error) => getEmptyStatusMvTipoMovimientoAsistencia(DEFAULT_STATE.error))  
);

export const createMvTipoMovimientoAsistenciaAsyncThunk = createAsyncThunk(
  'nomina/createMvTipoMovimientoAsistenciaAsyncThunk',
  async (create : {serverDate : IServerDate, MvTipoMovimientoAsistencia: IMvTipoMovimientoAsistencia}) => await createMvTipoMovimientoAsistencia(create.serverDate, create.MvTipoMovimientoAsistencia)
    .then(async (result) => result)
    .catch(async (error) => getEmptyStatusMvTipoMovimientoAsistencia(DEFAULT_STATE.error))  
);

export const deleteMvTipoMovimientoAsistenciaAsyncThunk = createAsyncThunk(
  'nomina/deleteMvTipoMovimientoAsistenciaAsyncThunk',
  async (del : {id : number}) => await deleteMvTipoMovimientoAsistencia(del.id)
    .then(async (result) => result)
    .catch(async (error) => getEmptyStatusMvTipoMovimientoAsistencia())  
);
