/**
 * Reducers creados para obtener asíncronamente 
 * los datos de los modelos y dependiendo del resultado
 * de la peticón actualice el state en redux o mande un mensaje de error
 */

import { PayloadAction } from "@reduxjs/toolkit";
import { getNotificacionesAsyncThunk, getAllNotificacionesAsyncThunk, updateNotificacionesAsyncThunk, createNotificacionesAsyncThunk, deleteNotificacionesAsyncThunk } from "../../asyncThunk/nomina";
import { DEFAULT_STATE } from "../../enums/default_state";
import { IStatusNotificaciones } from "../../interfaces/nomina";
import { nominaInitialState } from "../../slices/nominaSlice";

// Reducers: Notificaciones
export const createNotificacionesReducers = () => {
  const get = () => {
    var { pending, fulfilled, rejected } = getNotificacionesAsyncThunk;
    return {
      [pending.type]: (state : typeof nominaInitialState) => {
        state.nominaState.notificaciones.estado = DEFAULT_STATE.loading;
      },
      [fulfilled.type]: (state : typeof nominaInitialState, action : PayloadAction<IStatusNotificaciones>) => {
        const result = action.payload;
        result.expiry_array = state.nominaState.notificaciones.expiry_array;
        if (result.estado === DEFAULT_STATE.error) result.data_array = state.nominaState.notificaciones.data_array;
        state.nominaState.notificaciones = result;
      },
      [rejected.type]: (state : typeof nominaInitialState, action : PayloadAction<IStatusNotificaciones>) => {
        state.nominaState.notificaciones = {
          ...state.nominaState.notificaciones,
          estado: action.payload.estado,
          mensaje: "Ocurrió un problema al realizar la solicitud",
        };
      },
    };
  };
  
  const getAll = () => {
    var { pending, fulfilled, rejected } = getAllNotificacionesAsyncThunk;
    return {
      [pending.type]: (state : typeof nominaInitialState) => {
        state.nominaState.notificaciones.estado = DEFAULT_STATE.loading;
      },
      [fulfilled.type]: (state : typeof nominaInitialState, action : PayloadAction<IStatusNotificaciones>) => {
        const result = action.payload;
        result.data = state.nominaState.notificaciones.data;
        result.expiry = state.nominaState.notificaciones.expiry;
        if (result.estado === DEFAULT_STATE.error) result.data_array = state.nominaState.notificaciones.data_array;
        state.nominaState.notificaciones = result;
      },
      [rejected.type]: (state : typeof nominaInitialState, action : PayloadAction<IStatusNotificaciones>) => {
        state.nominaState.notificaciones = {
          ...state.nominaState.notificaciones,
          estado: action.payload.estado,
          mensaje: "Ocurrió un problema al realizar la solicitud",
        };
      },
    };
  };
  
  const update = () => {
    var { pending, fulfilled, rejected } = updateNotificacionesAsyncThunk;
    return {
      [pending.type]: (state : typeof nominaInitialState) => {
        state.nominaState.notificaciones.estado = DEFAULT_STATE.loading;
      },
      [fulfilled.type]: (state : typeof nominaInitialState, action : PayloadAction<IStatusNotificaciones>) => {
        const result: IStatusNotificaciones = action.payload;
        result.expiry = state.nominaState.notificaciones.expiry;
        result.expiry_array = state.nominaState.notificaciones.expiry_array;
        if (result.estado === DEFAULT_STATE.error) result.data_array = state.nominaState.notificaciones.data_array;
        state.nominaState.notificaciones = result;
      },
      [rejected.type]: (state : typeof nominaInitialState, action : PayloadAction<IStatusNotificaciones>) => {
        state.nominaState.notificaciones = {
          ...state.nominaState.notificaciones,
          estado: action.payload.estado,
          mensaje: "Ocurrió un problema al realizar la solicitud",
        };
      },
    };
  };

  const create = () => {
    var { pending, fulfilled, rejected } = createNotificacionesAsyncThunk;
    return {
      [pending.type]: (state : typeof nominaInitialState) => {
        state.nominaState.notificaciones.estado = DEFAULT_STATE.loading;
      },
      [fulfilled.type]: (state : typeof nominaInitialState, action : PayloadAction<IStatusNotificaciones>) => {
        const result = action.payload;
        result.expiry = state.nominaState.notificaciones.expiry;
        result.expiry_array = state.nominaState.notificaciones.expiry_array;
        if (result.estado === DEFAULT_STATE.error) result.data_array = state.nominaState.notificaciones.data_array;
        state.nominaState.notificaciones = result;
      },
      [rejected.type]: (state : typeof nominaInitialState, action : PayloadAction<IStatusNotificaciones>) => {
        state.nominaState.notificaciones = {
          ...state.nominaState.notificaciones,
          estado: action.payload.estado,
          mensaje: "Ocurrió un problema al realizar la solicitud",
        };
      },
    };
  };
  
  const del = () => {
    var { pending, fulfilled, rejected } = deleteNotificacionesAsyncThunk;
    return {
      [pending.type]: (state : typeof nominaInitialState) => {
        state.nominaState.notificaciones.estado = DEFAULT_STATE.loading;
      },
      [fulfilled.type]: (state : typeof nominaInitialState, action : PayloadAction<IStatusNotificaciones>) => {
        const result: IStatusNotificaciones = action.payload;
        result.expiry = state.nominaState.notificaciones.expiry;
        result.expiry_array = state.nominaState.notificaciones.expiry_array;
        if (result.estado === DEFAULT_STATE.error) result.data_array = state.nominaState.notificaciones.data_array;
        state.nominaState.notificaciones = result;
      },
      [rejected.type]: (state : typeof nominaInitialState, action : PayloadAction<IStatusNotificaciones>) => {
        state.nominaState.notificaciones = {
          ...state.nominaState.notificaciones,
          estado: action.payload.estado,
          mensaje: "Ocurrió un problema al realizar la solicitud",
        };
      },
    };
  };

  return {
    ...get(),
    ...getAll(),
    ...update(),
    ...create(),
    ...del(),
  };
};
