/** 
 * Componente que se encarga del elemento TipoDocumentosPorPuesto
 * @ITipoDocumentosPorPuesto es la interfaz que contiene los parámetos el elemento TipoDocumentosPorPuesto
 * @getEmptyTipoDocumentosPorPuesto  devuelve un objeto con los parámetros por defecto; @param {id}, opcional cuando no encuentra el objeto en el LocalSotrage
 * @IStatusTipoDocumentosPorPuesto es la interfaz para los estatus a llamadas a la API; @param {status}, opcional cuando queremos otro estado de inizialización de tipo [DEFAULT_STATE]
 * @getEmptyStatusTipoDocumentosPorPuesto devuelve un objeto con los parámetros por defecto,
 * @setTipoDocumentosPorPuesto devuelve un objeto de tipo [ITipoDocumentosPorPuesto] recibiendo @param {object} y @param {serverDate}, para verificar que los datos estén en el formato correcto y ajustarlos a la zona horaria actual
 * @getSerializableTipoDocumentosPorPuesto recibe un @param {ITipoDocumentosPorPuesto}, para devolver un objeto serizalizable para las peticiones de la API
 * */

import { getDateString, isNotEmpty, SERVER_TIME_ZONE } from "../../api/helpers";
import { DEFAULT_STATE } from "../../enums/default_state";
import { getStorageEmpresaArrayItem, getStorageMvTipoDocumentoArrayItem, getStorageUserArrayItem, getStorageClienteQentryArrayItem } from "../../localStorage/personas";
import { convertDateToZonaHoraria, ILocalStorageExpiry, getEmptyLocalStorageExpiry, IServerDate } from "../time";
import { IClienteQentry, getEmptyClienteQentry, setClienteQentry } from "./IClienteQentry";
import { IEmpresa, getEmptyEmpresa, setEmpresa } from "./IEmpresa";
import { IMvTipoDocumento, setMvTipoDocumento } from "./IMvTipoDocumento";
import { IUser, getEmptyUser, setUser } from "./IUser";

export interface ITipoDocumentosPorPuesto {
  id : number,
  puesto : number, // nomina.Puesto
  empresa : IEmpresa,
  tipo_documentos : IMvTipoDocumento[], 
  autor : IUser,
  fecha_elaboracion : string, // Fecha
  estatus_sistema : boolean,
  ultima_actualizacion : string, // Fecha
  cliente_qentry : IClienteQentry
}

export const getEmptyTipoDocumentosPorPuesto = (id? : number) => {
  const temp : ITipoDocumentosPorPuesto = 
  {
    id : id ? id : 0,
    puesto : 0,
    empresa : getEmptyEmpresa(),
    tipo_documentos : [],
    autor : getEmptyUser(),
    fecha_elaboracion : "",
    estatus_sistema : false,
    ultima_actualizacion : "",
    cliente_qentry : getEmptyClienteQentry(),
  };
  return temp;
}

export interface IStatusTipoDocumentosPorPuesto {
  estado : DEFAULT_STATE,
  mensaje : string,
  data : ITipoDocumentosPorPuesto,
  data_array: ITipoDocumentosPorPuesto[],
  expiry : ILocalStorageExpiry,
  expiry_array : ILocalStorageExpiry,
}

export const getEmptyStatusTipoDocumentosPorPuesto = (status: DEFAULT_STATE = DEFAULT_STATE.loading) => {
  const temp : IStatusTipoDocumentosPorPuesto = 
  {
    estado : status,
    mensaje : "",
    data : getEmptyTipoDocumentosPorPuesto(),
    data_array : [],
    expiry : getEmptyLocalStorageExpiry("ITipoDocumentosPorPuesto", 0),
    expiry_array : getEmptyLocalStorageExpiry("ITipoDocumentosPorPuestoArray", 0),
  };
  return temp;
}

export const setTipoDocumentosPorPuesto = async (
  object : any,
  serverDate : IServerDate,
) => {
  const temp = getEmptyTipoDocumentosPorPuesto();
  if (isNotEmpty(object.id)) temp.id = object.id;
  if (isNotEmpty(object.puesto)) temp.puesto = object.puesto;
  if (isNotEmpty(object.empresa)) temp.empresa = (typeof object.empresa === 'number') ? await getStorageEmpresaArrayItem(object.empresa) : await setEmpresa(object.empresa, serverDate);
  if (isNotEmpty(object.tipo_documentos)) temp.tipo_documentos = (typeof object.tipo_documentos[0] === 'number') ? await Promise.all(object.tipo_documentos.map(async (e : number) => await getStorageMvTipoDocumentoArrayItem(e))) : await Promise.all(object.tipo_documentos.map(async (e : any) => await setMvTipoDocumento(e, serverDate)));
  if (isNotEmpty(object.autor)) temp.autor = (typeof object.autor === 'number') ? await getStorageUserArrayItem(object.autor) : await setUser(object.autor, serverDate);
  if (isNotEmpty(object.fecha_elaboracion)) temp.fecha_elaboracion = convertDateToZonaHoraria(serverDate, object.fecha_elaboracion);
  if (isNotEmpty(object.estatus_sistema)) temp.estatus_sistema = object.estatus_sistema;
  if (isNotEmpty(object.ultima_actualizacion)) temp.ultima_actualizacion = convertDateToZonaHoraria(serverDate, object.ultima_actualizacion);
  if (isNotEmpty(object.cliente_qentry)) temp.cliente_qentry = (typeof object.cliente_qentry === 'number') ? await getStorageClienteQentryArrayItem(object.cliente_qentry) : await setClienteQentry(object.cliente_qentry, serverDate);
  return temp;
}

export const getSerializableTipoDocumentosPorPuesto = (ITipoDocumentosPorPuesto : ITipoDocumentosPorPuesto) => {
  return {
    id : ITipoDocumentosPorPuesto.id,
    puesto : ITipoDocumentosPorPuesto.puesto,
    empresa : ITipoDocumentosPorPuesto.empresa.id,
    tipo_documentos : ITipoDocumentosPorPuesto.tipo_documentos.map((e) => e.id),
    autor : ITipoDocumentosPorPuesto.autor.id,
    fecha_elaboracion : ITipoDocumentosPorPuesto.fecha_elaboracion,
    estatus_sistema : ITipoDocumentosPorPuesto.estatus_sistema,
    ultima_actualizacion : getDateString(SERVER_TIME_ZONE),
    cliente_qentry : ITipoDocumentosPorPuesto.cliente_qentry.id
  };
}
