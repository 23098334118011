/**
 * Reducers creados para obtener asíncronamente 
 * los datos de los modelos y dependiendo del resultado
 * de la peticón actualice el state en redux o mande un mensaje de error
 */

import { PayloadAction } from "@reduxjs/toolkit";
import { getRondinCompletadoAsyncThunk, getAllRondinCompletadoAsyncThunk, updateRondinCompletadoAsyncThunk, createRondinCompletadoAsyncThunk, deleteRondinCompletadoAsyncThunk } from "../../asyncThunk/rondines";
import { DEFAULT_STATE } from "../../enums/default_state";
import { IStatusRondinCompletado } from "../../interfaces/rondines";
import { rondinesInitialState } from "../../slices/rondinesSlice";

// Reducers: RondinCompletado
export const createRondinCompletadoReducers = () => {
  const get = () => {
    var { pending, fulfilled, rejected } = getRondinCompletadoAsyncThunk;
    return {
      [pending.type]: (state : typeof rondinesInitialState) => {
        state.rondinesState.rondinCompletado.estado = DEFAULT_STATE.loading;
      },
      [fulfilled.type]: (state : typeof rondinesInitialState, action : PayloadAction<IStatusRondinCompletado>) => {
        const result = action.payload;
        result.expiry_array = state.rondinesState.rondinCompletado.expiry_array;
        if (result.estado === DEFAULT_STATE.error) result.data_array = state.rondinesState.rondinCompletado.data_array;
        state.rondinesState.rondinCompletado = result;
      },
      [rejected.type]: (state : typeof rondinesInitialState, action : PayloadAction<IStatusRondinCompletado>) => {
        state.rondinesState.rondinCompletado = {
          ...state.rondinesState.rondinCompletado,
          estado: action.payload.estado,
          mensaje: "Ocurrió un problema al realizar la solicitud",
        };
      },
    };
  };
  
  const getAll = () => {
    var { pending, fulfilled, rejected } = getAllRondinCompletadoAsyncThunk;
    return {
      [pending.type]: (state : typeof rondinesInitialState) => {
        state.rondinesState.rondinCompletado.estado = DEFAULT_STATE.loading;
      },
      [fulfilled.type]: (state : typeof rondinesInitialState, action : PayloadAction<IStatusRondinCompletado>) => {
        const result = action.payload;
        result.data = state.rondinesState.rondinCompletado.data;
        result.expiry = state.rondinesState.rondinCompletado.expiry;
        if (result.estado === DEFAULT_STATE.error) result.data_array = state.rondinesState.rondinCompletado.data_array;
        state.rondinesState.rondinCompletado = result;
      },
      [rejected.type]: (state : typeof rondinesInitialState, action : PayloadAction<IStatusRondinCompletado>) => {
        state.rondinesState.rondinCompletado = {
          ...state.rondinesState.rondinCompletado,
          estado: action.payload.estado,
          mensaje: "Ocurrió un problema al realizar la solicitud",
        };
      },
    };
  };
  
  const update = () => {
    var { pending, fulfilled, rejected } = updateRondinCompletadoAsyncThunk;
    return {
      [pending.type]: (state : typeof rondinesInitialState) => {
        state.rondinesState.rondinCompletado.estado = DEFAULT_STATE.loading;
      },
      [fulfilled.type]: (state : typeof rondinesInitialState, action : PayloadAction<IStatusRondinCompletado>) => {
        const result: IStatusRondinCompletado = action.payload;
        result.expiry = state.rondinesState.rondinCompletado.expiry;
        result.expiry_array = state.rondinesState.rondinCompletado.expiry_array;
        if (result.estado === DEFAULT_STATE.error) result.data_array = state.rondinesState.rondinCompletado.data_array;
        state.rondinesState.rondinCompletado = result;
      },
      [rejected.type]: (state : typeof rondinesInitialState, action : PayloadAction<IStatusRondinCompletado>) => {
        state.rondinesState.rondinCompletado = {
          ...state.rondinesState.rondinCompletado,
          estado: action.payload.estado,
          mensaje: "Ocurrió un problema al realizar la solicitud",
        };
      },
    };
  };

  const create = () => {
    var { pending, fulfilled, rejected } = createRondinCompletadoAsyncThunk;
    return {
      [pending.type]: (state : typeof rondinesInitialState) => {
        state.rondinesState.rondinCompletado.estado = DEFAULT_STATE.loading;
      },
      [fulfilled.type]: (state : typeof rondinesInitialState, action : PayloadAction<IStatusRondinCompletado>) => {
        const result = action.payload;
        result.expiry = state.rondinesState.rondinCompletado.expiry;
        result.expiry_array = state.rondinesState.rondinCompletado.expiry_array;
        if (result.estado === DEFAULT_STATE.error) result.data_array = state.rondinesState.rondinCompletado.data_array;
        state.rondinesState.rondinCompletado = result;
      },
      [rejected.type]: (state : typeof rondinesInitialState, action : PayloadAction<IStatusRondinCompletado>) => {
        state.rondinesState.rondinCompletado = {
          ...state.rondinesState.rondinCompletado,
          estado: action.payload.estado,
          mensaje: "Ocurrió un problema al realizar la solicitud",
        };
      },
    };
  };
  
  const del = () => {
    var { pending, fulfilled, rejected } = deleteRondinCompletadoAsyncThunk;
    return {
      [pending.type]: (state : typeof rondinesInitialState) => {
        state.rondinesState.rondinCompletado.estado = DEFAULT_STATE.loading;
      },
      [fulfilled.type]: (state : typeof rondinesInitialState, action : PayloadAction<IStatusRondinCompletado>) => {
        const result: IStatusRondinCompletado = action.payload;
        result.expiry = state.rondinesState.rondinCompletado.expiry;
        result.expiry_array = state.rondinesState.rondinCompletado.expiry_array;
        if (result.estado === DEFAULT_STATE.error) result.data_array = state.rondinesState.rondinCompletado.data_array;
        state.rondinesState.rondinCompletado = result;
      },
      [rejected.type]: (state : typeof rondinesInitialState, action : PayloadAction<IStatusRondinCompletado>) => {
        state.rondinesState.rondinCompletado = {
          ...state.rondinesState.rondinCompletado,
          estado: action.payload.estado,
          mensaje: "Ocurrió un problema al realizar la solicitud",
        };
      },
    };
  };

  return {
    ...get(),
    ...getAll(),
    ...update(),
    ...create(),
    ...del(),
  };
};
