/**
 * Métodos del, get, post, put para llamadas a la API
 * @get     en este método primero consulta con @getFromLocalStorage si es necesario hacer la petición http sino se obtiene del LocalStorage;
 *          si hay un error en la petición http se busca el objeto en el LocalStorage sino lo encuentra devuelve un objeto vacío; si la
 *          petición se realiza correctamente actualiza el valor individual y en el array del objeto en el LocalStorage y devuelve el objeto
 *          obtenido del servidor.
 * @getAll  en este método primero consulta con @getFromLocalStorage si es necesario hacer la petición http sino se obtiene del LocalStorage;
 *          si hay un error en la petición http se busca el objeto array en el LocalStorage sino lo encuentra devuelve un array vacío; si la
 *          petición se realiza correctamente actualiza array en el LocalStorage y devuelve el array obtenido del servidor
 * @update  en este método después de realizar la petición http si falla devuelve el objeto que se iba a actualizar; si la petición se
 *          realiza correctamente actualiza el valor individual y en el array del objeto en el LocalStorage y devuelve el objeto obtenido del
 *          servidor.
 * @create  en este método después de realizar la petición http si falla devuelve el objeto que se iba a crear; si la petición se realiza
 *          correctamente actualiza el valor individual y en el array del objeto en el LocalStorage y devuelve el objeto obtenido del
 *          servidor.
 * @delete  en este método después de realizar la petición http si falla devuelve el objeto que se iba a eliminar; si la petición se realiza
 *          correctamente elimina el valor individual y en el objeto en el arrya del LocalStorage y devuelve un objeto vacio (Default)
 */

import { del, get, post, put } from "../../api/api";
import {
  getFromLocalStorage,
  updateLocalStorageExpiry,
} from "../../api/helpers";
import { removeArrayItemStorageData } from "../../api/storage";
import { DEFAULT_STATE } from "../../enums/default_state";
import {
  getEmptyStatusMvTipoAsistencia,
  setMvTipoAsistencia,
  IMvTipoAsistencia,
  getSerializableMvTipoAsistencia,
} from "../../interfaces/nomina";
import { IServerDate } from "../../interfaces/time";
import {
  getStorageMvTipoAsistenciaArrayItem,
  setStorageMvTipoAsistencia,
  setStorageMvTipoAsistenciaArrayItem,
  getStorageMvTipoAsistenciaArray,
  setStorageMvTipoAsistenciaArray,
  removeStorageMvTipoAsistencia,
} from "../../localStorage/nomina";

export const getMvTipoAsistencia = async (
  serverDate: IServerDate,
  pk: number
) => {
  const temp = getEmptyStatusMvTipoAsistencia();
  const doHttp = await getFromLocalStorage(serverDate, temp.expiry);
  temp.expiry = doHttp.storage;
  if (!doHttp.result) {
    console.log(
      `(localStorage) MvTipoAsistencia con pk: ${pk}, obtenido con éxito`
    );
    temp.estado = DEFAULT_STATE.success;
    temp.mensaje = `MvTipoAsistencia obtenido con éxito`;
    temp.data = await getStorageMvTipoAsistenciaArrayItem(pk);
    return temp;
  }
  const result = await get(`nomina/tipo-asistencia/${pk}`);
  const data = result.data;
  console.log(`MvTipoAsistencia data: `, data);
  if (!data.id || data.detail) {
    console.log(
      `Error al obtener MvTipoAsistencia con pk: ${pk}, ${data.detail}`
    );
    temp.estado = DEFAULT_STATE.error;
    temp.mensaje = `Error al obtener el MvTipoAsistencia: ${
      data.detail ?? "Hubo un problema al conectarse con el servidor"
    }`;
    temp.data = await getStorageMvTipoAsistenciaArrayItem(pk);
    return temp;
  }
  console.log(`MvTipoAsistencia con pk: ${pk}, obtenido con éxito`);
  temp.estado = DEFAULT_STATE.success;
  temp.mensaje = `MvTipoAsistencia obtenido con éxito`;
  temp.data = await setMvTipoAsistencia(data, serverDate);
  await setStorageMvTipoAsistencia(temp.data);
  temp.data_array = await setStorageMvTipoAsistenciaArrayItem(pk, temp.data);
  temp.expiry = await updateLocalStorageExpiry(serverDate, temp.expiry);
  return temp;
};

export const getAllMvTipoAsistencia = async (serverDate: IServerDate) => {
  const temp = getEmptyStatusMvTipoAsistencia();
  const doHttp = await getFromLocalStorage(serverDate, temp.expiry_array);
  temp.expiry_array = doHttp.storage;
  if (!doHttp.result) {
    console.log(
      `(localStorage) Todos los MvTipoAsistencias obtenidos con éxito`
    );
    temp.estado = DEFAULT_STATE.success;
    temp.mensaje = `Todos los MvTipoAsistencia obtenidos con éxito`;
    temp.data_array = await getStorageMvTipoAsistenciaArray();
    return temp;
  }
  const result = await get(`nomina/tipo-asistencia`);
  const data = result.data;
  if (data.detail) {
    console.log(
      `Error al obtener la lista de MvTipoAsistencia, ${data.detail}`
    );
    temp.estado = DEFAULT_STATE.error;
    temp.mensaje = `Error al obtener la lista de MvTipoAsistencia: ${
      data.detail ?? "Hubo un problema al conectarse con el servidor"
    }`;
    temp.data_array = await getStorageMvTipoAsistenciaArray();
    return temp;
  }
  console.log(`Todos los MvTipoAsistencia obtenidos con éxito`, data);
  temp.estado = DEFAULT_STATE.success;
  temp.mensaje = `Todos los MvTipoAsistencia obtenidos con éxito`;
  temp.data_array = await Promise.all(
    data.map(async (e: any) => await setMvTipoAsistencia(e, serverDate))
  );
  await setStorageMvTipoAsistenciaArray(temp.data_array);
  temp.expiry_array = await updateLocalStorageExpiry(
    serverDate,
    temp.expiry_array
  );
  return temp;
};

export const updateMvTipoAsistencia = async (
  serverDate: IServerDate,
  pk: number,
  object: IMvTipoAsistencia
) => {
  const temp = getEmptyStatusMvTipoAsistencia();
  const result = await put(
    `nomina/tipo-asistencia/${pk}`,
    getSerializableMvTipoAsistencia(object)
  );
  const data = result.data;
  if (!data.id || data.detail) {
    console.log(
      `Error al actualizar MvTipoAsistencia con pk: ${pk}, ${data.detail}`
    );
    temp.estado = DEFAULT_STATE.error;
    temp.mensaje = `Error al actualizar el MvTipoAsistencia: ${
      data.detail ?? "Hubo un problema al conectarse con el servidor"
    }`;
    temp.data = await setMvTipoAsistencia(object, serverDate);
    return temp;
  }
  console.log(`MvTipoAsistencia con pk: ${pk}, actualizado con éxito`);
  temp.estado = DEFAULT_STATE.success;
  temp.mensaje = `MvTipoAsistencia actualizado con éxito`;
  temp.data = await setMvTipoAsistencia(data, serverDate);
  await setStorageMvTipoAsistencia(temp.data);
  temp.data_array = await setStorageMvTipoAsistenciaArrayItem(
    temp.data.id,
    temp.data
  );
  return temp;
};

export const createMvTipoAsistencia = async (
  serverDate: IServerDate,
  object: IMvTipoAsistencia
) => {
  const temp = getEmptyStatusMvTipoAsistencia();
  const result = await post(
    `nomina/tipo-asistencia`,
    getSerializableMvTipoAsistencia(object)
  );
  const data = result.data;
  if (!data.id || data.detail) {
    console.log(
      `Error al crear MvTipoAsistencia con los datos ${object}, ${data.detail}`
    );
    temp.estado = DEFAULT_STATE.error;
    temp.mensaje = `Error al crear el MvTipoAsistencia: ${
      data.detail ?? "Hubo un problema al conectarse con el servidor"
    }`;
    temp.data = await setMvTipoAsistencia(object, serverDate);
    return temp;
  }
  console.log(`MvTipoAsistencia creado con éxito`);
  temp.estado = DEFAULT_STATE.success;
  temp.mensaje = `MvTipoAsistencia creado con éxito`;
  temp.data = await setMvTipoAsistencia(data, serverDate);
  await setStorageMvTipoAsistencia(temp.data);
  temp.data_array = await setStorageMvTipoAsistenciaArrayItem(
    temp.data.id,
    temp.data
  );
  return temp;
};

export const deleteMvTipoAsistencia = async (pk: number) => {
  const temp = getEmptyStatusMvTipoAsistencia();
  const result = await del(`nomina/tipo-asistencia/${pk}`);
  const data = result.data;
  if (data.detail) {
    console.log(
      `Error al eliminar MvTipoAsistencia con pk: ${pk}, ${data.detail}`
    );
    temp.estado = DEFAULT_STATE.error;
    temp.mensaje = `Error al eliminar el datos fiscales: ${data.detail}`;
    temp.data = await getStorageMvTipoAsistenciaArrayItem(pk);
    temp.data_array = await getStorageMvTipoAsistenciaArray();
    return temp;
  }
  console.log(`MvTipoAsistencia eliminado con éxito`);
  temp.estado = DEFAULT_STATE.success;
  temp.mensaje = `MvTipoAsistencia eliminado con éxito`;
  await removeStorageMvTipoAsistencia();
  temp.data_array = await removeArrayItemStorageData(
    "IMvTipoAsistencia",
    pk,
    []
  );
  return temp;
};
