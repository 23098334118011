/**
 * Es el componente utilizado en la página Cambio de horarios para editar los horarios de los empleados
 * @function deleteUserList elimina un empleado de la lista de empleados seleccionados @selectedUser
 * @function addUser agrega un empleado de la lista de empleados seleccionados @selectedUser
 * @function filterSearch filtra la lista de empleados con el texto de busqueda
 * @function compare verifica que los horarios de un día esten completos (entrada y salida)
 * @function sendHorarios actualiza los horarios de los empleados
 * @function setScheduleTurno asigna los horarios del turno al/los empleado(s)
 * @function resetSchedule resetea el horario
 */

import React, { useState, useEffect, useRef } from "react";
import {
  IonGrid,
  IonRow,
  IonCol,
  IonChip,
  IonDatetime,
  IonLabel,
  IonButton,
  IonIcon,
  IonItem,
  IonContent,
  useIonToast,
  IonSearchbar,
  IonCard,
  IonList,
  IonText,
  IonAvatar,
  IonImg,
  IonPopover,
  IonInput,
  IonButtons,
  IonSpinner,
} from "@ionic/react";

import { timeSharp, closeCircle } from "ionicons/icons";

import Card from "../components/Card";
import Selector from "../components/Selector";
import useRedux, { useAppSelector } from "../hooks/useRedux";
import {
  formatTime,
  getDjangoTimeString,
  getTimeString,
} from "../redux/api/helpers";
import { getGlobalMemoizedEmpresaAsSelectList } from "../redux/slices/personasSlice";
import {
  getMemoizedScheduleChangeEmpleadoAsSelectList,
  getMemoizedScheduleChangeTurnoAsSelectList,
  Horarios,
  HorariosUpdate,
  setScheduleChangeSelectedEmpresa,
  setScheduleChangeSelectedTurno,
} from "../redux/slices/pagesSlice/scheduleChangeSlice";
import { DEFAULT_STATE } from "../redux/enums/default_state";
import { List } from "../redux/interfaces/helpers";

const ScheduleChange: React.FC = () => {
  const [{ serverDate, horario, turno, updateHorarios }] = useRedux();

  const empleado = useAppSelector(
    getMemoizedScheduleChangeEmpleadoAsSelectList
  );
  const empresaList = useAppSelector(getGlobalMemoizedEmpresaAsSelectList);
  const selectedEmpresa = useAppSelector(
    (state) => state.scheduleChange.selectedEmpresa
  );
  const turnoList = useAppSelector(getMemoizedScheduleChangeTurnoAsSelectList);
  const selectedTurno = useAppSelector(
    (state) => state.scheduleChange.selectedTurno
  );
  const defaultHorario = useAppSelector(
    (state) => state.scheduleChange.defaultHorario
  );

  const [present, dismiss] = useIonToast();
  let [horarioSent, setHorarioSent] = useState<Horarios[]>([
    ...defaultHorario.map((e) => ({ ...e })),
  ]);

  let [selectedMonIniTime, setSelectedMonIniTime] = useState<string | null>("");
  let [selectedMonFinTime, setSelectedMonFinTime] = useState<string | null>("");

  console.log({ selectedMonIniTime });

  let [selectedTueIniTime, setSelectedTueIniTime] = useState<string | null>("");
  let [selectedTueFinTime, setSelectedTueFinTime] = useState<string | null>("");

  let [selectedWenIniTime, setSelectedWenIniTime] = useState<string | null>("");
  let [selectedWenFinTime, setSelectedWenFinTime] = useState<string | null>("");

  let [selectedThuIniTime, setSelectedThuIniTime] = useState<string | null>("");
  let [selectedThuFinTime, setSelectedThuFinTime] = useState<string | null>("");

  let [selectedFryIniTime, setSelectedFryIniTime] = useState<string | null>("");
  let [selectedFryFinTime, setSelectedFryFinTime] = useState<string | null>("");

  let [selectedSatIniTime, setSelectedSatIniTime] = useState<string | null>("");
  let [selectedSatFinTime, setSelectedSatFinTime] = useState<string | null>("");

  let [selectedSunIniTime, setSelectedSunIniTime] = useState<string | null>("");
  let [selectedSunFinTime, setSelectedSunFinTime] = useState<string | null>("");

  const datetimeMonIni = useRef<null | HTMLIonDatetimeElement>(null);
  const datetimeMonFin = useRef<null | HTMLIonDatetimeElement>(null);

  const datetimeTueIni = useRef<null | HTMLIonDatetimeElement>(null);
  const datetimeTueFin = useRef<null | HTMLIonDatetimeElement>(null);

  const datetimeWenIni = useRef<null | HTMLIonDatetimeElement>(null);
  const datetimeWenFin = useRef<null | HTMLIonDatetimeElement>(null);

  const datetimeThuIni = useRef<null | HTMLIonDatetimeElement>(null);
  const datetimeThuFin = useRef<null | HTMLIonDatetimeElement>(null);

  const datetimeFryIni = useRef<null | HTMLIonDatetimeElement>(null);
  const datetimeFryFin = useRef<null | HTMLIonDatetimeElement>(null);

  const datetimeSatIni = useRef<null | HTMLIonDatetimeElement>(null);
  const datetimeSatFin = useRef<null | HTMLIonDatetimeElement>(null);

  const datetimeSunIni = useRef<null | HTMLIonDatetimeElement>(null);
  const datetimeSunFin = useRef<null | HTMLIonDatetimeElement>(null);

  const confirm = (day: number, ini: boolean) => {
    switch (day) {
      case 0:
        ini
          ? datetimeMonIni.current?.confirm()
          : datetimeMonFin.current?.confirm();
        break;
      case 1:
        ini
          ? datetimeTueIni.current?.confirm()
          : datetimeTueFin.current?.confirm();
        break;
      case 2:
        ini
          ? datetimeWenIni.current?.confirm()
          : datetimeWenFin.current?.confirm();
        break;
      case 3:
        ini
          ? datetimeThuIni.current?.confirm()
          : datetimeThuFin.current?.confirm();
        break;
      case 4:
        ini
          ? datetimeFryIni.current?.confirm()
          : datetimeFryFin.current?.confirm();
        break;
      case 5:
        ini
          ? datetimeSatIni.current?.confirm()
          : datetimeSatFin.current?.confirm();
        break;
      case 6:
        ini
          ? datetimeSunIni.current?.confirm()
          : datetimeSunFin.current?.confirm();
        break;
    }
  };

  let [selectedUser, setSelectedUser] = useState<List[]>([]);

  const [searchQuery, setSearchQuery] = useState("");
  const [filteredSearch, setFilteredSearch] = useState<List[]>([]);

  const deleteUserList = (id: string) => {
    let filterID = selectedUser.filter((e) => e.id !== id);
    setSelectedUser(filterID);
  };

  const addUser = (title: string, id: string) => {
    let key = selectedUser.filter((e) => e.id === id);
    if (key.length > 0) {
      present({
        message: "Ya existe ese usuario en la selección",
        color: "danger",
        duration: 2500,
      });
    } else {
      const temp = [...selectedUser];
      temp.push({
        id: id,
        title: title,
      });
      setSelectedUser(temp);
      setSearchQuery("");
    }
  };

  const filterSearch = () => {
    let tempSearchResult = empleado.filter((e) =>
      e.title.toLowerCase().includes(searchQuery.toLowerCase().trim())
    );
    setFilteredSearch(tempSearchResult);
  };

  useEffect(() => {
    searchQuery && searchQuery.trim() && searchQuery.length > 0
      ? filterSearch()
      : setFilteredSearch([]);
  }, [searchQuery]);

  useEffect(() => {
    if (selectedUser.length !== 1) return resetSchedule();
    const horarioEmpleado = horario.data_array.find(
      (e) => e.empleado.id.toString() === selectedUser[0].id
    );
    console.log("horarioEmpleado: ", horarioEmpleado);
    if (!horarioEmpleado) return;

    //Lunes
    setSelectedMonIniTime(horarioEmpleado.inicio_lunes);
    setSelectedMonFinTime(horarioEmpleado.fin_lunes);
    //Martes
    setSelectedTueIniTime(horarioEmpleado.inicio_martes);
    setSelectedTueFinTime(horarioEmpleado.fin_martes);
    //Miercoles
    setSelectedWenIniTime(horarioEmpleado.inicio_miercoles);
    setSelectedWenFinTime(horarioEmpleado.fin_miercoles);
    //Jueves
    setSelectedThuIniTime(horarioEmpleado.inicio_jueves);
    setSelectedThuFinTime(horarioEmpleado.fin_jueves);
    //Viernes
    setSelectedFryIniTime(horarioEmpleado.inicio_viernes);
    setSelectedFryFinTime(horarioEmpleado.fin_viernes);
    //Sabado
    setSelectedSatIniTime(horarioEmpleado.inicio_sabado);
    setSelectedSatFinTime(horarioEmpleado.fin_sabado);
    //Domingo
    setSelectedSunIniTime(horarioEmpleado.inicio_domingo);
    setSelectedSunFinTime(horarioEmpleado.fin_domingo);
  }, [selectedUser]);

  useEffect(() => {
    if (selectedMonIniTime) {
      horarioSent[0].entrada = selectedMonIniTime;
      horarioSent[0].seleccionado = true;
    } else {
      horarioSent[0].entrada = "";
      horarioSent[0].seleccionado = false;
    }

    if (selectedMonFinTime) {
      horarioSent[0].salida = selectedMonFinTime;
      horarioSent[0].seleccionado = true;
    } else {
      horarioSent[0].salida = "";
      horarioSent[0].seleccionado = false;
    }
  }, [selectedMonIniTime, selectedMonFinTime]);

  useEffect(() => {
    if (selectedTueIniTime) {
      horarioSent[1].entrada = selectedTueIniTime;
      horarioSent[1].seleccionado = true;
    } else {
      horarioSent[1].entrada = "";
      horarioSent[1].seleccionado = false;
    }

    if (selectedTueFinTime) {
      horarioSent[1].salida = selectedTueFinTime;
      horarioSent[1].seleccionado = true;
    } else {
      horarioSent[1].salida = "";
      horarioSent[1].seleccionado = false;
    }
  }, [selectedTueIniTime, selectedTueFinTime]);

  useEffect(() => {
    if (selectedWenIniTime) {
      horarioSent[2].entrada = selectedWenIniTime;
      horarioSent[2].seleccionado = true;
    } else {
      horarioSent[2].entrada = "";
      horarioSent[2].seleccionado = false;
    }

    if (selectedWenFinTime) {
      horarioSent[2].salida = selectedWenFinTime;
      horarioSent[2].seleccionado = true;
    } else {
      horarioSent[2].salida = "";
      horarioSent[2].seleccionado = false;
    }
  }, [selectedWenIniTime, selectedWenFinTime]);

  useEffect(() => {
    if (selectedThuIniTime) {
      horarioSent[3].entrada = selectedThuIniTime;
      horarioSent[3].seleccionado = true;
    } else {
      horarioSent[3].entrada = "";
      horarioSent[3].seleccionado = false;
    }

    if (selectedThuFinTime) {
      horarioSent[3].salida = selectedThuFinTime;
      horarioSent[3].seleccionado = true;
    } else {
      horarioSent[3].salida = "";
      horarioSent[3].seleccionado = false;
    }
  }, [selectedThuIniTime, selectedThuFinTime]);

  useEffect(() => {
    if (selectedFryIniTime) {
      horarioSent[4].entrada = selectedFryIniTime;
      horarioSent[4].seleccionado = true;
    } else {
      horarioSent[4].entrada = "";
      horarioSent[4].seleccionado = false;
    }

    if (selectedFryFinTime) {
      horarioSent[4].salida = selectedFryFinTime;
      horarioSent[4].seleccionado = true;
    } else {
      horarioSent[4].salida = "";
      horarioSent[4].seleccionado = false;
    }
  }, [selectedFryIniTime, selectedFryFinTime]);

  useEffect(() => {
    if (selectedSatIniTime) {
      horarioSent[5].entrada = selectedSatIniTime;
      horarioSent[5].seleccionado = true;
    } else {
      horarioSent[5].entrada = "";
      horarioSent[5].seleccionado = false;
    }

    if (selectedSatFinTime) {
      horarioSent[5].salida = selectedSatFinTime;
      horarioSent[5].seleccionado = true;
    } else {
      horarioSent[5].salida = "";
      horarioSent[5].seleccionado = false;
    }
  }, [selectedSatIniTime, selectedSatFinTime]);

  useEffect(() => {
    if (selectedSunIniTime) {
      horarioSent[6].entrada = selectedSunIniTime;
      horarioSent[6].seleccionado = true;
    } else {
      horarioSent[6].entrada = "";
      horarioSent[6].seleccionado = false;
    }

    if (selectedSunFinTime) {
      horarioSent[6].salida = selectedSunFinTime;
      horarioSent[6].seleccionado = true;
    } else {
      horarioSent[6].salida = "";
      horarioSent[6].seleccionado = false;
    }
  }, [selectedSunIniTime, selectedSunFinTime]);

  function compare(a: string | null | undefined, b: string | null | undefined) {
    return (a && !b) || (!a && b) ? true : false;
  }

  function sendHorarios() {
    if (selectedUser.length < 1) {
      present({
        message: "Por favor elija algún usuario",
        color: "danger",
        duration: 2500,
      });
      return;
    }

    if (compare(selectedMonIniTime, selectedMonFinTime)) {
      present({
        message: "Los campos del día Lunes están incompletos",
        color: "danger",
        duration: 2500,
      });
      return;
    }

    if (compare(selectedTueIniTime, selectedTueFinTime)) {
      present({
        message: "Los campos del día Martes están incompletos",
        color: "danger",
        duration: 2500,
      });
      return;
    }

    if (compare(selectedWenIniTime, selectedWenFinTime)) {
      present({
        message: "Los campos del día Miércoles están incompletos",
        color: "danger",
        duration: 2500,
      });
      return;
    }

    if (compare(selectedThuIniTime, selectedThuFinTime)) {
      present({
        message: "Los campos del día Jueves están incompletos",
        color: "danger",
        duration: 2500,
      });
      return;
    }

    if (compare(selectedFryIniTime, selectedFryFinTime)) {
      present({
        message: "Los campos del día Viernes están incompletos",
        color: "danger",
        duration: 2500,
      });
      return;
    }

    if (compare(selectedSatIniTime, selectedSatFinTime)) {
      present({
        message: "Los campos del día Sábado están incompletos",
        color: "danger",
        duration: 2500,
      });
      return;
    }

    if (compare(selectedSunIniTime, selectedSunFinTime)) {
      present({
        message: "Los campos del día Domingo están incompletos",
        color: "danger",
        duration: 2500,
      });
      return;
    }

    const horariosTemp: HorariosUpdate[] = [];
    selectedUser.forEach((user) => {
      horariosTemp.push({
        id: user.id,
        horarios: horarioSent.map((e) => {
          return {
            ...e,
            entrada: e.entrada ? getDjangoTimeString(e.entrada) : null,
            salida: e.salida ? getDjangoTimeString(e.salida) : null,
          };
        }),
      });
    });
    updateHorarios(horariosTemp);
  }

  const setScheduleTurno = () => {
    const turnoData = turno.data_array.find(
      (e) => e.id.toString() === selectedTurno.id
    );
    if (!turnoData) return;
    setSelectedMonIniTime(turnoData.inicio_lunes);
    setSelectedMonFinTime(turnoData.fin_lunes);
    //Martes
    setSelectedTueIniTime(turnoData.inicio_martes);
    setSelectedTueFinTime(turnoData.fin_martes);
    //Miercoles
    setSelectedWenIniTime(turnoData.inicio_miercoles);
    setSelectedWenFinTime(turnoData.fin_miercoles);
    //Jueves
    setSelectedThuIniTime(turnoData.inicio_jueves);
    setSelectedThuFinTime(turnoData.fin_jueves);
    //Viernes
    setSelectedFryIniTime(turnoData.inicio_viernes);
    setSelectedFryFinTime(turnoData.fin_viernes);
    //Sabado
    setSelectedSatIniTime(turnoData.inicio_sabado);
    setSelectedSatFinTime(turnoData.fin_sabado);
    //Domingo
    setSelectedSunIniTime(turnoData.inicio_domingo);
    setSelectedSunFinTime(turnoData.fin_domingo);
  };

  const resetSchedule = () => {
    //Lunes
    setSelectedMonIniTime("");
    setSelectedMonFinTime("");
    //Martes
    setSelectedTueIniTime("");
    setSelectedTueFinTime("");
    //Miercoles
    setSelectedWenIniTime("");
    setSelectedWenFinTime("");
    //Jueves
    setSelectedThuIniTime("");
    setSelectedThuFinTime("");
    //Viernes
    setSelectedFryIniTime("");
    setSelectedFryFinTime("");
    //Sabado
    setSelectedSatIniTime("");
    setSelectedSatFinTime("");
    //Domingo
    setSelectedSunIniTime("");
    setSelectedSunFinTime("");
  };

  useEffect(() => {
    console.log({ estado: horario.estado });
    if (horario.estado === DEFAULT_STATE.loading || !horario.mensaje) return;
    present({
      message: horario.mensaje,
      color: horario.estado === DEFAULT_STATE.error ? "danger" : "success",
      duration: 2500,
    });
  }, [horario.estado]);

  return (
    <IonContent class="inner-content">
      <IonGrid>
        <IonRow>
          <IonCol>
            <Card
              icon={timeSharp}
              title={
                <IonLabel>
                  Subir horarios <IonText color="danger">*</IonText>
                </IonLabel>
              }
              subtitle={
                <Selector
                  value={selectedEmpresa}
                  label="Cuentas/Regiones"
                  list={empresaList}
                  changeState={setScheduleChangeSelectedEmpresa}
                />
              }
              //content="prueba"
              content={
                <IonGrid>
                  <IonRow>
                    <IonCol size="12">
                      <IonSearchbar
                        color="light"
                        placeholder="Buscar usuario"
                        onIonChange={(e) => setSearchQuery(e.detail.value!)}
                        value={searchQuery}
                      ></IonSearchbar>
                    </IonCol>
                  </IonRow>
                  <IonRow>
                    <IonCol size="12">
                      <IonCard className="ionCardContent">
                        {filteredSearch.map((searchQuery) => (
                          <IonList
                            key={searchQuery.id}
                            className="ionCardContent"
                          >
                            <IonText
                              key={searchQuery.id}
                              className="ionText"
                              onClick={() => {
                                addUser(searchQuery.title, searchQuery.id);
                              }}
                            >
                              {searchQuery.title}
                            </IonText>
                          </IonList>
                        ))}
                      </IonCard>
                    </IonCol>
                  </IonRow>
                  <IonRow>
                    <IonCol size="12">
                      <IonText>Usuarios seleccionados:</IonText>
                      <IonCard>
                        {selectedUser.map((users) => (
                          <IonChip key={users.id}>
                            <IonAvatar>
                              <IonImg src="https://cdn-icons-png.flaticon.com/512/64/64572.png"></IonImg>
                            </IonAvatar>
                            <IonLabel>{users.title}</IonLabel>
                            <IonIcon
                              color="danger"
                              icon={closeCircle}
                              onClick={() => {
                                deleteUserList(users.id);
                              }}
                            ></IonIcon>
                          </IonChip>
                        ))}
                      </IonCard>
                    </IonCol>
                  </IonRow>
                  <IonCol size="12">
                    <IonRow className="ion-justify-content-center colContentTime">
                      <IonCol
                        size="12"
                        sizeXl="4"
                        sizeLg="6"
                        sizeMd="6"
                        sizeSm="12"
                        sizeXs="12"
                      >
                        <Selector
                          color="light"
                          value={selectedTurno}
                          label="Turno"
                          list={turnoList}
                          changeState={setScheduleChangeSelectedTurno}
                        />
                      </IonCol>
                      <IonCol size="10" className="ion-text-center">
                        <IonLabel>
                          Selecciona un turno para asignarlo al trabajador
                        </IonLabel>
                      </IonCol>
                      {selectedTurno.id !== "" ? (
                        <IonCol
                          size="12"
                          sizeXl="4"
                          sizeLg="6"
                          sizeMd="6"
                          sizeSm="12"
                          sizeXs="12"
                        >
                          <IonButton
                            onClick={() => setScheduleTurno()}
                            expand="block"
                          >
                            <IonLabel>Asignar horario</IonLabel>
                          </IonButton>
                        </IonCol>
                      ) : (
                        <></>
                      )}
                    </IonRow>
                  </IonCol>
                  <IonRow>
                    <IonCol size="12" sizeMd="6" sizeLg="6" sizeXl="4">
                      <IonRow>
                        <IonCol size="12">
                          <IonLabel>
                            <h2>Lunes:</h2>
                          </IonLabel>
                        </IonCol>
                        <IonCol size="12">
                          <IonItem>
                            <IonLabel position="floating">Entrada:</IonLabel>
                            <IonInput
                              id="schedule-mon-ini-date"
                              type="text"
                              color="secondary"
                              value={selectedMonIniTime}
                            />
                            <IonPopover
                              animated={false}
                              backdropDismiss={true}
                              trigger="schedule-mon-ini-date"
                              triggerAction="click"
                              show-backdrop="true"
                              class="date-picker"
                            >
                              <IonDatetime
                                ref={datetimeMonIni}
                                className="custom-time-picker"
                                color="primary"
                                presentation="time"
                                placeholder="Seleccionar fecha"
                                locale="es-MX"
                                showDefaultButtons={true}
                                doneText="Confirmar"
                                cancelText="Cancelar"
                                value={selectedMonIniTime}
                                onIonChange={(e) => {
                                  console.log(
                                    "hora lunes inicio",
                                    e.detail.value
                                  );
                                  if (!e.detail.value) return;

                                  setSelectedMonIniTime(
                                    getTimeString(
                                      serverDate.data.zona_horaria,
                                      e.detail.value as string
                                    )
                                  );
                                }}
                              >
                                {/* <IonButtons slot="buttons">
                                  <IonButton color="primary" onClick={(e) => confirm(0, true)}>Confirmar</IonButton>
                                </IonButtons>  */}
                              </IonDatetime>
                            </IonPopover>
                          </IonItem>
                        </IonCol>
                        <IonCol size="12">
                          <IonItem className="modal-input">
                            <IonLabel position="floating">Salida</IonLabel>
                            <IonInput
                              id="schedule-mon-fin-date"
                              type="text"
                              color="secondary"
                              value={selectedMonFinTime}
                            />
                            <IonPopover
                              animated={false}
                              backdropDismiss={true}
                              trigger="schedule-mon-fin-date"
                              triggerAction="click"
                              show-backdrop="true"
                              class="date-picker"
                            >
                              <IonDatetime
                                ref={datetimeMonFin}
                                className="custom-time-picker"
                                color="primary"
                                presentation="time"
                                placeholder="Seleccionar fecha"
                                locale="es-MX"
                                showDefaultButtons={true}
                                doneText="Confirmar"
                                cancelText="Cancelar"
                                value={selectedMonFinTime}
                                onIonChange={(e) => {
                                  if (!e.detail.value) return;
                                  setSelectedMonFinTime(
                                    getTimeString(
                                      serverDate.data.zona_horaria,
                                      e.detail.value as string
                                    )
                                  );
                                }}
                              >
                                {/* <IonButtons slot="buttons">
                                  <IonButton color="primary" onClick={(e) => confirm(0, false)}>Confirmar</IonButton>
                                </IonButtons>  */}
                              </IonDatetime>
                            </IonPopover>
                          </IonItem>
                        </IonCol>
                      </IonRow>
                    </IonCol>
                    <IonCol size="12" sizeMd="6" sizeLg="6" sizeXl="4">
                      <IonRow>
                        <IonCol size="12">
                          <IonLabel>
                            <h2>Martes:</h2>
                          </IonLabel>
                        </IonCol>
                        <IonCol size="12">
                          <IonItem>
                            <IonLabel position="floating">Entrada:</IonLabel>
                            <IonInput
                              id="schedule-tue-ini-date"
                              type="text"
                              color="secondary"
                              value={selectedTueIniTime}
                            />
                            <IonPopover
                              animated={false}
                              backdropDismiss={true}
                              trigger="schedule-tue-ini-date"
                              triggerAction="click"
                              show-backdrop="true"
                              class="date-picker"
                            >
                              <IonDatetime
                                ref={datetimeTueIni}
                                className="custom-time-picker"
                                color="primary"
                                presentation="time"
                                placeholder="Seleccionar fecha"
                                locale="es-MX"
                                showDefaultButtons={true}
                                doneText="Confirmar"
                                cancelText="Cancelar"
                                value={selectedTueIniTime}
                                onIonChange={(e) => {
                                  if (!e.detail.value) return;
                                  setSelectedTueIniTime(
                                    getTimeString(
                                      serverDate.data.zona_horaria,
                                      e.detail.value as string
                                    )
                                  );
                                }}
                              >
                                {/* <IonButtons slot="buttons">
                                  <IonButton color="primary" onClick={(e) => confirm(1, true)}>Confirmar</IonButton>
                                </IonButtons>  */}
                              </IonDatetime>
                            </IonPopover>
                          </IonItem>
                        </IonCol>
                        <IonCol size="12">
                          <IonItem className="modal-input">
                            <IonLabel position="floating">Salida</IonLabel>
                            <IonInput
                              id="schedule-tue-fin-date"
                              type="text"
                              color="secondary"
                              value={selectedTueFinTime}
                            />
                            <IonPopover
                              animated={false}
                              backdropDismiss={true}
                              trigger="schedule-tue-fin-date"
                              triggerAction="click"
                              show-backdrop="true"
                              class="date-picker"
                            >
                              <IonDatetime
                                ref={datetimeTueFin}
                                className="custom-time-picker"
                                color="primary"
                                presentation="time"
                                placeholder="Seleccionar fecha"
                                locale="es-MX"
                                showDefaultButtons={true}
                                doneText="Confirmar"
                                cancelText="Cancelar"
                                value={selectedTueFinTime}
                                onIonChange={(e) => {
                                  if (!e.detail.value) return;
                                  setSelectedTueFinTime(
                                    getTimeString(
                                      serverDate.data.zona_horaria,
                                      e.detail.value as string
                                    )
                                  );
                                }}
                              >
                                {/* <IonButtons slot="buttons">
                                  <IonButton color="primary" onClick={(e) => confirm(1, false)}>Confirmar</IonButton>
                                </IonButtons>  */}
                              </IonDatetime>
                            </IonPopover>
                          </IonItem>
                        </IonCol>
                      </IonRow>
                    </IonCol>
                    <IonCol size="12" sizeMd="6" sizeLg="6" sizeXl="4">
                      <IonRow>
                        <IonCol size="12">
                          <IonLabel>
                            <h2>Miércoles:</h2>
                          </IonLabel>
                        </IonCol>
                        <IonCol size="12">
                          <IonItem>
                            <IonLabel position="floating">Entrada:</IonLabel>
                            <IonInput
                              id="schedule-wen-ini-date"
                              type="text"
                              color="secondary"
                              value={selectedWenIniTime}
                            />
                            <IonPopover
                              animated={false}
                              backdropDismiss={true}
                              trigger="schedule-wen-ini-date"
                              triggerAction="click"
                              show-backdrop="true"
                              class="date-picker"
                            >
                              <IonDatetime
                                ref={datetimeWenIni}
                                className="custom-time-picker"
                                color="primary"
                                presentation="time"
                                placeholder="Seleccionar fecha"
                                locale="es-MX"
                                showDefaultButtons={true}
                                doneText="Confirmar"
                                cancelText="Cancelar"
                                value={selectedWenIniTime}
                                onIonChange={(e) => {
                                  if (!e.detail.value) return;
                                  setSelectedWenIniTime(
                                    getTimeString(
                                      serverDate.data.zona_horaria,
                                      e.detail.value as string
                                    )
                                  );
                                }}
                              >
                                {/* <IonButtons slot="buttons">
                                  <IonButton color="primary" onClick={(e) => confirm(2, true)}>Confirmar</IonButton>
                                </IonButtons>  */}
                              </IonDatetime>
                            </IonPopover>
                          </IonItem>
                        </IonCol>
                        <IonCol size="12">
                          <IonItem className="modal-input">
                            <IonLabel position="floating">Salida</IonLabel>
                            <IonInput
                              id="schedule-wen-fin-date"
                              type="text"
                              color="secondary"
                              value={selectedWenFinTime}
                            />
                            <IonPopover
                              animated={false}
                              backdropDismiss={true}
                              trigger="schedule-wen-fin-date"
                              triggerAction="click"
                              show-backdrop="true"
                              class="date-picker"
                            >
                              <IonDatetime
                                ref={datetimeWenFin}
                                className="custom-time-picker"
                                color="primary"
                                presentation="time"
                                placeholder="Seleccionar fecha"
                                locale="es-MX"
                                showDefaultButtons={true}
                                doneText="Confirmar"
                                cancelText="Cancelar"
                                value={selectedWenFinTime}
                                onIonChange={(e) => {
                                  if (!e.detail.value) return;
                                  setSelectedWenFinTime(
                                    getTimeString(
                                      serverDate.data.zona_horaria,
                                      e.detail.value as string
                                    )
                                  );
                                }}
                              >
                                {/* <IonButtons slot="buttons">
                                  <IonButton color="primary" onClick={(e) => confirm(2, false)}>Confirmar</IonButton>
                                </IonButtons>  */}
                              </IonDatetime>
                            </IonPopover>
                          </IonItem>
                        </IonCol>
                      </IonRow>
                    </IonCol>
                    <IonCol size="12" sizeMd="6" sizeLg="6" sizeXl="4">
                      <IonRow>
                        <IonCol size="12">
                          <IonLabel>
                            <h2>Jueves:</h2>
                          </IonLabel>
                        </IonCol>
                        <IonCol size="12">
                          <IonItem>
                            <IonLabel position="floating">Entrada:</IonLabel>
                            <IonInput
                              id="schedule-thu-ini-date"
                              type="text"
                              color="secondary"
                              value={selectedThuIniTime}
                            />
                            <IonPopover
                              animated={false}
                              backdropDismiss={true}
                              trigger="schedule-thu-ini-date"
                              triggerAction="click"
                              show-backdrop="true"
                              class="date-picker"
                            >
                              <IonDatetime
                                ref={datetimeThuIni}
                                className="custom-time-picker"
                                color="primary"
                                presentation="time"
                                placeholder="Seleccionar fecha"
                                locale="es-MX"
                                showDefaultButtons={true}
                                doneText="Confirmar"
                                cancelText="Cancelar"
                                value={selectedThuIniTime}
                                onIonChange={(e) => {
                                  if (!e.detail.value) return;
                                  setSelectedThuIniTime(
                                    getTimeString(
                                      serverDate.data.zona_horaria,
                                      e.detail.value as string
                                    )
                                  );
                                }}
                              >
                                {/* <IonButtons slot="buttons">
                                  <IonButton color="primary" onClick={(e) => confirm(3, true)}>Confirmar</IonButton>
                                </IonButtons>                                   */}
                              </IonDatetime>
                            </IonPopover>
                          </IonItem>
                        </IonCol>
                        <IonCol size="12">
                          <IonItem className="modal-input">
                            <IonLabel position="floating">Salida</IonLabel>
                            <IonInput
                              id="schedule-thu-fin-date"
                              type="text"
                              color="secondary"
                              value={selectedThuFinTime}
                            />
                            <IonPopover
                              animated={false}
                              backdropDismiss={true}
                              trigger="schedule-thu-fin-date"
                              triggerAction="click"
                              show-backdrop="true"
                              class="date-picker"
                            >
                              <IonDatetime
                                ref={datetimeThuFin}
                                className="custom-time-picker"
                                color="primary"
                                presentation="time"
                                placeholder="Seleccionar fecha"
                                locale="es-MX"
                                showDefaultButtons={true}
                                doneText="Confirmar"
                                cancelText="Cancelar"
                                value={selectedThuFinTime}
                                onIonChange={(e) => {
                                  if (!e.detail.value) return;
                                  setSelectedThuFinTime(
                                    getTimeString(
                                      serverDate.data.zona_horaria,
                                      e.detail.value as string
                                    )
                                  );
                                }}
                              >
                                {/* <IonButtons slot="buttons">
                                  <IonButton color="primary" onClick={(e) => confirm(3, false)}>Confirmar</IonButton>
                                </IonButtons>  */}
                              </IonDatetime>
                            </IonPopover>
                          </IonItem>
                        </IonCol>
                      </IonRow>
                    </IonCol>
                    <IonCol size="12" sizeMd="6" sizeLg="6" sizeXl="4">
                      <IonRow>
                        <IonCol size="12">
                          <IonLabel>
                            <h2>Viernes:</h2>
                          </IonLabel>
                        </IonCol>
                        <IonCol size="12">
                          <IonItem>
                            <IonLabel position="floating">Entrada:</IonLabel>
                            <IonInput
                              id="schedule-fry-ini-date"
                              type="text"
                              color="secondary"
                              value={selectedFryIniTime}
                            />
                            <IonPopover
                              animated={false}
                              backdropDismiss={true}
                              trigger="schedule-fry-ini-date"
                              triggerAction="click"
                              show-backdrop="true"
                              class="date-picker"
                            >
                              <IonDatetime
                                ref={datetimeFryIni}
                                className="custom-time-picker"
                                color="primary"
                                presentation="time"
                                placeholder="Seleccionar fecha"
                                locale="es-MX"
                                showDefaultButtons={true}
                                doneText="Confirmar"
                                cancelText="Cancelar"
                                value={selectedFryIniTime}
                                onIonChange={(e) => {
                                  if (!e.detail.value) return;
                                  setSelectedFryIniTime(
                                    getTimeString(
                                      serverDate.data.zona_horaria,
                                      e.detail.value as string
                                    )
                                  );
                                }}
                              >
                                {/* <IonButtons slot="buttons">
                                  <IonButton color="primary" onClick={(e) => confirm(4, true)}>Confirmar</IonButton>
                                </IonButtons>  */}
                              </IonDatetime>
                            </IonPopover>
                          </IonItem>
                        </IonCol>
                        <IonCol size="12">
                          <IonItem className="modal-input">
                            <IonLabel position="floating">Salida</IonLabel>
                            <IonInput
                              id="schedule-fry-fin-date"
                              type="text"
                              color="secondary"
                              value={selectedFryFinTime}
                            />
                            <IonPopover
                              animated={false}
                              backdropDismiss={true}
                              trigger="schedule-fry-fin-date"
                              triggerAction="click"
                              show-backdrop="true"
                              class="date-picker"
                            >
                              <IonDatetime
                                ref={datetimeFryFin}
                                className="custom-time-picker"
                                color="primary"
                                presentation="time"
                                placeholder="Seleccionar fecha"
                                locale="es-MX"
                                showDefaultButtons={true}
                                doneText="Confirmar"
                                cancelText="Cancelar"
                                value={selectedFryFinTime}
                                onIonChange={(e) => {
                                  if (!e.detail.value) return;
                                  setSelectedFryFinTime(
                                    getTimeString(
                                      serverDate.data.zona_horaria,
                                      e.detail.value as string
                                    )
                                  );
                                }}
                              >
                                {/* <IonButtons slot="buttons">
                                  <IonButton color="primary" onClick={(e) => confirm(4, false)}>Confirmar</IonButton>
                                </IonButtons>  */}
                              </IonDatetime>
                            </IonPopover>
                          </IonItem>
                        </IonCol>
                      </IonRow>
                    </IonCol>
                    <IonCol size="12" sizeMd="6" sizeLg="6" sizeXl="4">
                      <IonRow>
                        <IonCol size="12">
                          <IonLabel>
                            <h2>Sábado:</h2>
                          </IonLabel>
                        </IonCol>
                        <IonCol size="12">
                          <IonItem>
                            <IonLabel position="floating">Entrada:</IonLabel>
                            <IonInput
                              id="schedule-sat-ini-date"
                              type="text"
                              color="secondary"
                              value={selectedSatIniTime}
                            />
                            <IonPopover
                              animated={false}
                              backdropDismiss={true}
                              trigger="schedule-sat-ini-date"
                              triggerAction="click"
                              show-backdrop="true"
                              class="date-picker"
                            >
                              <IonDatetime
                                ref={datetimeSatIni}
                                className="custom-time-picker"
                                color="primary"
                                presentation="time"
                                placeholder="Seleccionar fecha"
                                locale="es-MX"
                                showDefaultButtons={true}
                                doneText="Confirmar"
                                cancelText="Cancelar"
                                value={selectedSatIniTime}
                                onIonChange={(e) => {
                                  if (!e.detail.value) return;
                                  setSelectedSatIniTime(
                                    getTimeString(
                                      serverDate.data.zona_horaria,
                                      e.detail.value as string
                                    )
                                  );
                                }}
                              >
                                {/* <IonButtons slot="buttons">
                                  <IonButton color="primary" onClick={(e) => confirm(5, true)}>Confirmar</IonButton>
                                </IonButtons>  */}
                              </IonDatetime>
                            </IonPopover>
                          </IonItem>
                        </IonCol>
                        <IonCol size="12">
                          <IonItem className="modal-input">
                            <IonLabel position="floating">Salida</IonLabel>
                            <IonInput
                              id="schedule-sat-fin-date"
                              type="text"
                              color="secondary"
                              value={selectedSatFinTime}
                            />
                            <IonPopover
                              animated={false}
                              backdropDismiss={true}
                              trigger="schedule-sat-fin-date"
                              triggerAction="click"
                              show-backdrop="true"
                              class="date-picker"
                            >
                              <IonDatetime
                                ref={datetimeSatFin}
                                className="custom-time-picker"
                                color="primary"
                                presentation="time"
                                placeholder="Seleccionar fecha"
                                locale="es-MX"
                                showDefaultButtons={true}
                                doneText="Confirmar"
                                cancelText="Cancelar"
                                value={selectedSatFinTime}
                                onIonChange={(e) => {
                                  if (!e.detail.value) return;
                                  setSelectedSatFinTime(
                                    getTimeString(
                                      serverDate.data.zona_horaria,
                                      e.detail.value as string
                                    )
                                  );
                                }}
                              >
                                {/* <IonButtons slot="buttons">
                                  <IonButton color="primary" onClick={(e) => confirm(5, false)}>Confirmar</IonButton>
                                </IonButtons>  */}
                              </IonDatetime>
                            </IonPopover>
                          </IonItem>
                        </IonCol>
                      </IonRow>
                    </IonCol>
                    <IonCol size="12" sizeMd="6" sizeLg="6" sizeXl="4">
                      <IonRow>
                        <IonCol size="12">
                          <IonLabel>
                            <h2>Domingo:</h2>
                          </IonLabel>
                        </IonCol>
                        <IonCol size="12">
                          <IonItem>
                            <IonLabel position="floating">Entrada:</IonLabel>
                            <IonInput
                              id="schedule-sun-ini-date"
                              type="text"
                              color="secondary"
                              value={selectedSunIniTime}
                            />
                            <IonPopover
                              animated={false}
                              backdropDismiss={true}
                              trigger="schedule-sun-ini-date"
                              triggerAction="click"
                              show-backdrop="true"
                              class="date-picker"
                            >
                              <IonDatetime
                                ref={datetimeSunIni}
                                className="custom-time-picker"
                                color="primary"
                                presentation="time"
                                placeholder="Seleccionar fecha"
                                locale="es-MX"
                                showDefaultButtons={true}
                                doneText="Confirmar"
                                cancelText="Cancelar"
                                value={selectedSunIniTime}
                                onIonChange={async (e) => {
                                  if (!e.detail.value) return;
                                  setSelectedSunIniTime(
                                    getTimeString(
                                      serverDate.data.zona_horaria,
                                      e.detail.value as string
                                    )
                                  );
                                }}
                              >
                                {/* <IonButtons slot="buttons">
                                  <IonButton color="primary" onClick={(e) => confirm(6, true)}>Confirmar</IonButton>
                                </IonButtons>  */}
                              </IonDatetime>
                            </IonPopover>
                          </IonItem>
                        </IonCol>
                        <IonCol size="12">
                          <IonItem className="modal-input">
                            <IonLabel position="floating">Salida</IonLabel>
                            <IonInput
                              id="schedule-sun-fin-date"
                              type="text"
                              color="secondary"
                              value={selectedSunFinTime}
                            />
                            <IonPopover
                              animated={false}
                              backdropDismiss={true}
                              trigger="schedule-sun-fin-date"
                              triggerAction="click"
                              show-backdrop="true"
                              class="date-picker"
                            >
                              <IonDatetime
                                ref={datetimeSunFin}
                                className="custom-time-picker"
                                color="primary"
                                presentation="time"
                                placeholder="Seleccionar fecha"
                                locale="es-MX"
                                showDefaultButtons={true}
                                doneText="Confirmar"
                                cancelText="Cancelar"
                                value={selectedSunFinTime}
                                onIonChange={(e) => {
                                  if (!e.detail.value) return;
                                  setSelectedSunFinTime(
                                    getTimeString(
                                      serverDate.data.zona_horaria,
                                      e.detail.value as string
                                    )
                                  );
                                }}
                              >
                                {/* <IonButtons slot="buttons">
                                  <IonButton color="primary" onClick={(e) => confirm(6, false)}>Confirmar</IonButton>
                                </IonButtons>  */}
                              </IonDatetime>
                            </IonPopover>
                          </IonItem>
                        </IonCol>
                      </IonRow>
                    </IonCol>
                  </IonRow>
                  <IonRow>
                    <IonCol>
                      <IonButton
                        expand="block"
                        disabled={
                          selectedUser.length < 1 ||
                          horario.estado === DEFAULT_STATE.loading
                        }
                        onClick={() => sendHorarios()}
                      >
                        {horario.estado === DEFAULT_STATE.loading ? (
                          <IonSpinner
                            className="loading-center-horizontal"
                            name="circles"
                            color="medium"
                            slot="start"
                          />
                        ) : (
                          <IonLabel>Subir</IonLabel>
                        )}
                      </IonButton>
                    </IonCol>
                  </IonRow>
                  <IonRow class="no-padding">
                    <IonCol size="12">
                      <IonLabel>
                        <IonText color="danger">*</IonText> Las horas estan en
                        zona horaria CDMX (GMT-5)
                      </IonLabel>
                    </IonCol>
                  </IonRow>
                </IonGrid>
              }
            />
          </IonCol>
        </IonRow>
      </IonGrid>
    </IonContent>
  );
};

export default ScheduleChange;
