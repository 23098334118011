/** 
 * Componente que se encarga del elemento MvMotivoIncapacidad
 * @IMvMotivoIncapacidad es la interfaz que contiene los parámetos el elemento MvMotivoIncapacidad
 * @getEmptyMvMotivoIncapacidad  devuelve un objeto con los parámetros por defecto; @param {id}, opcional cuando no encuentra el objeto en el LocalSotrage
 * @IStatusMvMotivoIncapacidad es la interfaz para los estatus a llamadas a la API; @param {status}, opcional cuando queremos otro estado de inizialización de tipo [DEFAULT_STATE]
 * @getEmptyStatusMvMotivoIncapacidad devuelve un objeto con los parámetros por defecto,
 * @setMvMotivoIncapacidad devuelve un objeto de tipo [IMvMotivoIncapacidad] recibiendo @param {object} y @param {serverDate}, para verificar que los datos estén en el formato correcto y ajustarlos a la zona horaria actual
 * @getSerializableMvMotivoIncapacidad recibe un @param {IMvMotivoIncapacidad}, para devolver un objeto serizalizable para las peticiones de la API
 * */

import { getDateString, isNotEmpty, SERVER_TIME_ZONE } from "../../api/helpers";
import { DEFAULT_STATE } from "../../enums/default_state";
import { ILocalStorageExpiry, getEmptyLocalStorageExpiry, IServerDate, convertDateToZonaHoraria } from "../time";

export interface IMvMotivoIncapacidad {
  id : number,
  dato : string,
  valor : string,
  fecha_elaboracion : string, // Fecha
  estatus_sistema : boolean,
  ultima_actualizacion : string, // Fecha
}

export const getEmptyMvMotivoIncapacidad = (id? : number) => {
  const temp : IMvMotivoIncapacidad = 
  {
    id : id ? id : 0,
    dato : "",
    valor : "",
    fecha_elaboracion : "",
    estatus_sistema : false,
    ultima_actualizacion : "",
  };
  return temp;
}

export interface IStatusMvMotivoIncapacidad {
  estado : DEFAULT_STATE,
  mensaje : string,
  data : IMvMotivoIncapacidad,
  data_array: IMvMotivoIncapacidad[],
  expiry : ILocalStorageExpiry,
  expiry_array : ILocalStorageExpiry,
}

export const getEmptyStatusMvMotivoIncapacidad = (status: DEFAULT_STATE = DEFAULT_STATE.loading) => {
  const temp : IStatusMvMotivoIncapacidad = 
  {
    estado : status,
    mensaje : "",
    data : getEmptyMvMotivoIncapacidad(),
    data_array : [],
    expiry : getEmptyLocalStorageExpiry("IMvMotivoIncapacidad", 0),
    expiry_array : getEmptyLocalStorageExpiry("IMvMotivoIncapacidadArray", 0),
  };
  return temp;
}

export const setMvMotivoIncapacidad = async (
  object : any,
  serverDate : IServerDate,
) => {
  const temp = getEmptyMvMotivoIncapacidad();
  if (isNotEmpty(object.id)) temp.id = object.id;
  if (isNotEmpty(object.dato)) temp.dato = object.dato;
  if (isNotEmpty(object.valor)) temp.valor = object.valor;
  if (isNotEmpty(object.fecha_elaboracion)) temp.fecha_elaboracion = convertDateToZonaHoraria(serverDate, object.fecha_elaboracion);
  if (isNotEmpty(object.estatus_sistema)) temp.estatus_sistema = object.estatus_sistema;
  if (isNotEmpty(object.ultima_actualizacion)) temp.ultima_actualizacion = convertDateToZonaHoraria(serverDate, object.ultima_actualizacion);
  return temp;
}

export const getSerializableMvMotivoIncapacidad = (IMvMotivoIncapacidad : IMvMotivoIncapacidad ) => {
  return {
    id : IMvMotivoIncapacidad.id,
    dato : IMvMotivoIncapacidad.dato,
    valor : IMvMotivoIncapacidad.valor,
    fecha_elaboracion : IMvMotivoIncapacidad.fecha_elaboracion,
    estatus_sistema : IMvMotivoIncapacidad.estatus_sistema,
    ultima_actualizacion : getDateString(SERVER_TIME_ZONE),
  };
}
