/**
 * Métodos para el manejo de IonicStorage 
 * @setStorage              guarda un unico objeto al storage
 * @getStorage              obtiene el objeto guardado con @setStorage
 * @setStorageArray         guarda el array de objetos en el storage
 * @getStorageArray         obtiene el array de objetos guardado con @setStorageArray
 * @removeStorageArray      elimina el array de objetos guardado con @setStorageArray
 * @setStorageItemArray     agrega un elemento al array de objeto guardado con @setStorageArray
 * @getStorageItemArray     obtiene un elemento del array guardado con @setStorageArray buscandolo por su id 
 * @removeStorageItemArray  elimina un elemento del array guardado con @setStorageArray buscandolo por su id 
 */

import { setStorageData, getStorageData, removeStorageData, setArrayItemStorageData, getArrayItemStorageData, removeArrayItemStorageData } from "../../api/storage";
import { ITipoDocumentosPorPuesto, getEmptyTipoDocumentosPorPuesto } from "../../interfaces/personas";

export const setStorageTipoDocumentosPorPuesto = async (ITipoDocumentosPorPuesto : ITipoDocumentosPorPuesto) => await setStorageData('ITipoDocumentosPorPuesto', ITipoDocumentosPorPuesto);
export const getStorageTipoDocumentosPorPuesto = async () : Promise<ITipoDocumentosPorPuesto> => await getStorageData('ITipoDocumentosPorPuesto', getEmptyTipoDocumentosPorPuesto());
export const removeStorageTipoDocumentosPorPuesto = async () => await removeStorageData('ITipoDocumentosPorPuesto');
export const setStorageTipoDocumentosPorPuestoArray = async (ITipoDocumentosPorPuesto : ITipoDocumentosPorPuesto[]) => await setStorageData('ITipoDocumentosPorPuestoArray', ITipoDocumentosPorPuesto);
export const getStorageTipoDocumentosPorPuestoArray = async () : Promise<ITipoDocumentosPorPuesto[]> => await getStorageData('ITipoDocumentosPorPuestoArray', [] as ITipoDocumentosPorPuesto[]);
export const removeStorageTipoDocumentosPorPuestoArray = async () => await removeStorageData('ITipoDocumentosPorPuestoArray');
export const setStorageTipoDocumentosPorPuestoArrayItem = async (id: number, ITipoDocumentosPorPuesto : ITipoDocumentosPorPuesto) : Promise<ITipoDocumentosPorPuesto[]> => await setArrayItemStorageData('ITipoDocumentosPorPuestoArray', id, ITipoDocumentosPorPuesto, [] as ITipoDocumentosPorPuesto[]);
export const getStorageTipoDocumentosPorPuestoArrayItem = async (id: number) : Promise<ITipoDocumentosPorPuesto> => await getArrayItemStorageData('ITipoDocumentosPorPuestoArray', id, getEmptyTipoDocumentosPorPuesto);
export const removeStorageTipoDocumentosPorPuestoArrayItem = async (id: number) : Promise<ITipoDocumentosPorPuesto[]> => await removeArrayItemStorageData('ITipoDocumentosPorPuestoArray', id, [] as ITipoDocumentosPorPuesto[]);
