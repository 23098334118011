/**
 * Reducers creados para obtener asíncronamente 
 * los datos de los modelos y dependiendo del resultado
 * de la peticón actualice el state en redux o mande un mensaje de error
 */

import { PayloadAction } from "@reduxjs/toolkit";
import { getTurnoAsyncThunk, getAllTurnoAsyncThunk, updateTurnoAsyncThunk, createTurnoAsyncThunk, deleteTurnoAsyncThunk } from "../../asyncThunk/nomina";
import { DEFAULT_STATE } from "../../enums/default_state";
import { IStatusTurno } from "../../interfaces/nomina";
import { nominaInitialState } from "../../slices/nominaSlice";

// Reducers: Turno
export const createTurnoReducers = () => {
  const get = () => {
    var { pending, fulfilled, rejected } = getTurnoAsyncThunk;
    return {
      [pending.type]: (state : typeof nominaInitialState) => {
        state.nominaState.turno.estado = DEFAULT_STATE.loading;
      },
      [fulfilled.type]: (state : typeof nominaInitialState, action : PayloadAction<IStatusTurno>) => {
        const result = action.payload;
        result.expiry_array = state.nominaState.turno.expiry_array;
        if (result.estado === DEFAULT_STATE.error) result.data_array = state.nominaState.turno.data_array;
        state.nominaState.turno = result;
      },
      [rejected.type]: (state : typeof nominaInitialState, action : PayloadAction<IStatusTurno>) => {
        state.nominaState.turno = {
          ...state.nominaState.turno,
          estado: action.payload.estado,
          mensaje: "Ocurrió un problema al realizar la solicitud",
        };
      },
    };
  };
  
  const getAll = () => {
    var { pending, fulfilled, rejected } = getAllTurnoAsyncThunk;
    return {
      [pending.type]: (state : typeof nominaInitialState) => {
        state.nominaState.turno.estado = DEFAULT_STATE.loading;
      },
      [fulfilled.type]: (state : typeof nominaInitialState, action : PayloadAction<IStatusTurno>) => {
        const result = action.payload;
        result.data = state.nominaState.turno.data;
        result.expiry = state.nominaState.turno.expiry;
        if (result.estado === DEFAULT_STATE.error) result.data_array = state.nominaState.turno.data_array;
        state.nominaState.turno = result;
      },
      [rejected.type]: (state : typeof nominaInitialState, action : PayloadAction<IStatusTurno>) => {
        state.nominaState.turno = {
          ...state.nominaState.turno,
          estado: action.payload.estado,
          mensaje: "Ocurrió un problema al realizar la solicitud",
        };
      },
    };
  };
  
  const update = () => {
    var { pending, fulfilled, rejected } = updateTurnoAsyncThunk;
    return {
      [pending.type]: (state : typeof nominaInitialState) => {
        state.nominaState.turno.estado = DEFAULT_STATE.loading;
      },
      [fulfilled.type]: (state : typeof nominaInitialState, action : PayloadAction<IStatusTurno>) => {
        const result: IStatusTurno = action.payload;
        result.expiry = state.nominaState.turno.expiry;
        result.expiry_array = state.nominaState.turno.expiry_array;
        if (result.estado === DEFAULT_STATE.error) result.data_array = state.nominaState.turno.data_array;
        state.nominaState.turno = result;
      },
      [rejected.type]: (state : typeof nominaInitialState, action : PayloadAction<IStatusTurno>) => {
        state.nominaState.turno = {
          ...state.nominaState.turno,
          estado: action.payload.estado,
          mensaje: "Ocurrió un problema al realizar la solicitud",
        };
      },
    };
  };

  const create = () => {
    var { pending, fulfilled, rejected } = createTurnoAsyncThunk;
    return {
      [pending.type]: (state : typeof nominaInitialState) => {
        state.nominaState.turno.estado = DEFAULT_STATE.loading;
      },
      [fulfilled.type]: (state : typeof nominaInitialState, action : PayloadAction<IStatusTurno>) => {
        const result = action.payload;
        result.expiry = state.nominaState.turno.expiry;
        result.expiry_array = state.nominaState.turno.expiry_array;
        if (result.estado === DEFAULT_STATE.error) result.data_array = state.nominaState.turno.data_array;
        state.nominaState.turno = result;
      },
      [rejected.type]: (state : typeof nominaInitialState, action : PayloadAction<IStatusTurno>) => {
        state.nominaState.turno = {
          ...state.nominaState.turno,
          estado: action.payload.estado,
          mensaje: "Ocurrió un problema al realizar la solicitud",
        };
      },
    };
  };
  
  const del = () => {
    var { pending, fulfilled, rejected } = deleteTurnoAsyncThunk;
    return {
      [pending.type]: (state : typeof nominaInitialState) => {
        state.nominaState.turno.estado = DEFAULT_STATE.loading;
      },
      [fulfilled.type]: (state : typeof nominaInitialState, action : PayloadAction<IStatusTurno>) => {
        const result: IStatusTurno = action.payload;
        result.expiry = state.nominaState.turno.expiry;
        result.expiry_array = state.nominaState.turno.expiry_array;
        if (result.estado === DEFAULT_STATE.error) result.data_array = state.nominaState.turno.data_array;
        state.nominaState.turno = result;
      },
      [rejected.type]: (state : typeof nominaInitialState, action : PayloadAction<IStatusTurno>) => {
        state.nominaState.turno = {
          ...state.nominaState.turno,
          estado: action.payload.estado,
          mensaje: "Ocurrió un problema al realizar la solicitud",
        };
      },
    };
  };

  return {
    ...get(),
    ...getAll(),
    ...update(),
    ...create(),
    ...del(),
  };
};
