/**
 * Métodos AsyncThunk para ser usados en los reducers y
 * los resultados a las llamadas http puedan ser usados para
 * actualizar el state en redux
 */

import { createAsyncThunk } from "@reduxjs/toolkit";
import { DEFAULT_STATE } from "../../enums/default_state";
import { getMvTipoUsuario, getAllMvTipoUsuario, updateMvTipoUsuario, createMvTipoUsuario, deleteMvTipoUsuario } from "../../http/personas";
import { getEmptyStatusMvTipoUsuario, IMvTipoUsuario } from "../../interfaces/personas";
import { IServerDate } from "../../interfaces/time";

export const getMvTipoUsuarioAsyncThunk = createAsyncThunk(
  'personas/getMvTipoUsuarioAsyncThunk',
  async (query : {serverDate : IServerDate, id: number}) => await getMvTipoUsuario(query.serverDate, query.id)
    .then(async (result) => result)
    .catch(async (error) => getEmptyStatusMvTipoUsuario(DEFAULT_STATE.error))  
);

export const getAllMvTipoUsuarioAsyncThunk = createAsyncThunk(
  'personas/getAllMvTipoUsuarioAsyncThunk',
  async (queryAll : {serverDate : IServerDate}) => await getAllMvTipoUsuario(queryAll.serverDate)
    .then(async (result) => result)
    .catch(async (error) => getEmptyStatusMvTipoUsuario(DEFAULT_STATE.error))  
);

export const updateMvTipoUsuarioAsyncThunk = createAsyncThunk(
  'personas/updateMvTipoUsuarioAsyncThunk',
  async (update: {serverDate : IServerDate, id: number, MvTipoUsuario: IMvTipoUsuario}) => await updateMvTipoUsuario(update.serverDate, update.id, update.MvTipoUsuario)
    .then(async (result) => result)
    .catch(async (error) => getEmptyStatusMvTipoUsuario(DEFAULT_STATE.error))  
);

export const createMvTipoUsuarioAsyncThunk = createAsyncThunk(
  'personas/createMvTipoUsuarioAsyncThunk',
  async (create : {serverDate : IServerDate, MvTipoUsuario: IMvTipoUsuario}) => await createMvTipoUsuario(create.serverDate, create.MvTipoUsuario)
    .then(async (result) => result)
    .catch(async (error) => getEmptyStatusMvTipoUsuario(DEFAULT_STATE.error))  
);

export const deleteMvTipoUsuarioAsyncThunk = createAsyncThunk(
  'personas/deleteMvTipoUsuarioAsyncThunk',
  async (del : {id : number}) => await deleteMvTipoUsuario(del.id)
    .then(async (result) => result)
    .catch(async (error) => getEmptyStatusMvTipoUsuario())  
);

