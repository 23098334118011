/**
 * Reducers creados para obtener asíncronamente 
 * los datos de los modelos y dependiendo del resultado
 * de la peticón actualice el state en redux o mande un mensaje de error
 */

import { PayloadAction } from "@reduxjs/toolkit";
import { getAntiguedadEmpleadoAsyncThunk, getAllAntiguedadEmpleadoAsyncThunk, updateAntiguedadEmpleadoAsyncThunk, createAntiguedadEmpleadoAsyncThunk, deleteAntiguedadEmpleadoAsyncThunk } from "../../asyncThunk/personas";
import { DEFAULT_STATE } from "../../enums/default_state";
import { IStatusAntiguedadEmpleado } from "../../interfaces/personas";
import { personasInitialState } from "../../slices/personasSlice";

// Reducers: AntiguedadEmpleado
export const createAntiguedadEmpleadoReducers = () => {
  const get = () => {
    var { pending, fulfilled, rejected } = getAntiguedadEmpleadoAsyncThunk;
    return {
      [pending.type]: (state : typeof personasInitialState) => {
        state.personasState.antiguedadEmpleado.estado = DEFAULT_STATE.loading;
      },
      [fulfilled.type]: (state : typeof personasInitialState, action : PayloadAction<IStatusAntiguedadEmpleado>) => {
        const result = action.payload;
        result.expiry_array = state.personasState.antiguedadEmpleado.expiry_array;
        if (result.estado === DEFAULT_STATE.error) result.data_array = state.personasState.antiguedadEmpleado.data_array;
        state.personasState.antiguedadEmpleado = result;
      },
      [rejected.type]: (state : typeof personasInitialState, action : PayloadAction<IStatusAntiguedadEmpleado>) => {
        state.personasState.antiguedadEmpleado = {
          ...state.personasState.antiguedadEmpleado,
          estado: action.payload.estado,
          mensaje: "Ocurrió un problema al realizar la solicitud",
        };
      },
    };
  };
  
  const getAll = () => {
    var { pending, fulfilled, rejected } = getAllAntiguedadEmpleadoAsyncThunk;
    return {
      [pending.type]: (state : typeof personasInitialState) => {
        state.personasState.antiguedadEmpleado.estado = DEFAULT_STATE.loading;
      },
      [fulfilled.type]: (state : typeof personasInitialState, action : PayloadAction<IStatusAntiguedadEmpleado>) => {
        const result = action.payload;
        result.data = state.personasState.antiguedadEmpleado.data;
        result.expiry = state.personasState.antiguedadEmpleado.expiry;
        if (result.estado === DEFAULT_STATE.error) result.data_array = state.personasState.antiguedadEmpleado.data_array;
        state.personasState.antiguedadEmpleado = result;
      },
      [rejected.type]: (state : typeof personasInitialState, action : PayloadAction<IStatusAntiguedadEmpleado>) => {
        state.personasState.antiguedadEmpleado = {
          ...state.personasState.antiguedadEmpleado,
          estado: action.payload.estado,
          mensaje: "Ocurrió un problema al realizar la solicitud",
        };
      },
    };
  };
  
  const update = () => {
    var { pending, fulfilled, rejected } = updateAntiguedadEmpleadoAsyncThunk;
    return {
      [pending.type]: (state : typeof personasInitialState) => {
        state.personasState.antiguedadEmpleado.estado = DEFAULT_STATE.loading;
      },
      [fulfilled.type]: (state : typeof personasInitialState, action : PayloadAction<IStatusAntiguedadEmpleado>) => {
        const result: IStatusAntiguedadEmpleado = action.payload;
        result.expiry = state.personasState.antiguedadEmpleado.expiry;
        result.expiry_array = state.personasState.antiguedadEmpleado.expiry_array;
        if (result.estado === DEFAULT_STATE.error) result.data_array = state.personasState.antiguedadEmpleado.data_array;
        state.personasState.antiguedadEmpleado = result;
      },
      [rejected.type]: (state : typeof personasInitialState, action : PayloadAction<IStatusAntiguedadEmpleado>) => {
        state.personasState.antiguedadEmpleado = {
          ...state.personasState.antiguedadEmpleado,
          estado: action.payload.estado,
          mensaje: "Ocurrió un problema al realizar la solicitud",
        };
      },
    };
  };

  const create = () => {
    var { pending, fulfilled, rejected } = createAntiguedadEmpleadoAsyncThunk;
    return {
      [pending.type]: (state : typeof personasInitialState) => {
        state.personasState.antiguedadEmpleado.estado = DEFAULT_STATE.loading;
      },
      [fulfilled.type]: (state : typeof personasInitialState, action : PayloadAction<IStatusAntiguedadEmpleado>) => {
        const result = action.payload;
        result.expiry = state.personasState.antiguedadEmpleado.expiry;
        result.expiry_array = state.personasState.antiguedadEmpleado.expiry_array;
        if (result.estado === DEFAULT_STATE.error) result.data_array = state.personasState.antiguedadEmpleado.data_array;
        state.personasState.antiguedadEmpleado = result;
      },
      [rejected.type]: (state : typeof personasInitialState, action : PayloadAction<IStatusAntiguedadEmpleado>) => {
        state.personasState.antiguedadEmpleado = {
          ...state.personasState.antiguedadEmpleado,
          estado: action.payload.estado,
          mensaje: "Ocurrió un problema al realizar la solicitud",
        };
      },
    };
  };
  
  const del = () => {
    var { pending, fulfilled, rejected } = deleteAntiguedadEmpleadoAsyncThunk;
    return {
      [pending.type]: (state : typeof personasInitialState) => {
        state.personasState.antiguedadEmpleado.estado = DEFAULT_STATE.loading;
      },
      [fulfilled.type]: (state : typeof personasInitialState, action : PayloadAction<IStatusAntiguedadEmpleado>) => {
        const result: IStatusAntiguedadEmpleado = action.payload;
        result.expiry = state.personasState.antiguedadEmpleado.expiry;
        result.expiry_array = state.personasState.antiguedadEmpleado.expiry_array;
        if (result.estado === DEFAULT_STATE.error) result.data_array = state.personasState.antiguedadEmpleado.data_array;
        state.personasState.antiguedadEmpleado = result;
      },
      [rejected.type]: (state : typeof personasInitialState, action : PayloadAction<IStatusAntiguedadEmpleado>) => {
        state.personasState.antiguedadEmpleado = {
          ...state.personasState.antiguedadEmpleado,
          estado: action.payload.estado,
          mensaje: "Ocurrió un problema al realizar la solicitud",
        };
      },
    };
  };

  return {
    ...get(),
    ...getAll(),
    ...update(),
    ...create(),
    ...del(),
  };
};
