/**
 * Métodos para el manejo de IonicStorage 
 * @setStorage              guarda un unico objeto al storage
 * @getStorage              obtiene el objeto guardado con @setStorage
 * @setStorageArray         guarda el array de objetos en el storage
 * @getStorageArray         obtiene el array de objetos guardado con @setStorageArray
 * @removeStorageArray      elimina el array de objetos guardado con @setStorageArray
 * @setStorageItemArray     agrega un elemento al array de objeto guardado con @setStorageArray
 * @getStorageItemArray     obtiene un elemento del array guardado con @setStorageArray buscandolo por su id 
 * @removeStorageItemArray  elimina un elemento del array guardado con @setStorageArray buscandolo por su id 
 */

import { setStorageData, getStorageData, removeStorageData, setArrayItemStorageData, getArrayItemStorageData, removeArrayItemStorageData } from "../../api/storage";
import { IMvTipoHorasExtra, getEmptyMvTipoHorasExtra } from "../../interfaces/nomina";

export const setStorageMvTipoHorasExtra = async (IMvTipoHorasExtra : IMvTipoHorasExtra) => await setStorageData('IMvTipoHorasExtra', IMvTipoHorasExtra);
export const getStorageMvTipoHorasExtra = async () : Promise<IMvTipoHorasExtra> => await getStorageData('IMvTipoHorasExtra', getEmptyMvTipoHorasExtra());
export const removeStorageMvTipoHorasExtra = async () => await removeStorageData('IMvTipoHorasExtra');
export const setStorageMvTipoHorasExtraArray = async (IMvTipoHorasExtra : IMvTipoHorasExtra[]) => await setStorageData('IMvTipoHorasExtraArray', IMvTipoHorasExtra);
export const getStorageMvTipoHorasExtraArray = async () : Promise<IMvTipoHorasExtra[]> => await getStorageData('IMvTipoHorasExtraArray', [] as IMvTipoHorasExtra[]);
export const removeStorageMvTipoHorasExtraArray = async () => await removeStorageData('IMvTipoHorasExtraArray');
export const setStorageMvTipoHorasExtraArrayItem = async (id: number, IMvTipoHorasExtra : IMvTipoHorasExtra) : Promise<IMvTipoHorasExtra[]> => await setArrayItemStorageData('IMvTipoHorasExtraArray', id, IMvTipoHorasExtra, [] as IMvTipoHorasExtra[]);
export const getStorageMvTipoHorasExtraArrayItem = async (id: number) : Promise<IMvTipoHorasExtra> => await getArrayItemStorageData('IMvTipoHorasExtraArray', id, getEmptyMvTipoHorasExtra);
export const removeStorageMvTipoHorasExtraArrayItem = async (id: number) : Promise<IMvTipoHorasExtra[]> => await removeArrayItemStorageData('IMvTipoHorasExtraArray', id, [] as IMvTipoHorasExtra[]);
