/**
 * Métodos AsyncThunk para ser usados en los reducers y
 * los resultados a las llamadas http puedan ser usados para
 * actualizar el state en redux
 */

import { createAsyncThunk } from "@reduxjs/toolkit";
import { DEFAULT_STATE } from "../../enums/default_state";
import { getTipoDocumentosPorPuesto, getAllTipoDocumentosPorPuesto, updateTipoDocumentosPorPuesto, createTipoDocumentosPorPuesto, deleteTipoDocumentosPorPuesto } from "../../http/personas";
import { getEmptyStatusTipoDocumentosPorPuesto, ITipoDocumentosPorPuesto } from "../../interfaces/personas";
import { IServerDate } from "../../interfaces/time";

export const getTipoDocumentosPorPuestoAsyncThunk = createAsyncThunk(
  'personas/getTipoDocumentosPorPuestoAsyncThunk',
  async (query : {serverDate : IServerDate, id: number, idCliente : number}) => await getTipoDocumentosPorPuesto(query.serverDate, query.id, query.idCliente)
    .then(async (result) => result)
    .catch(async (error) => getEmptyStatusTipoDocumentosPorPuesto(DEFAULT_STATE.error))  
);

export const getAllTipoDocumentosPorPuestoAsyncThunk = createAsyncThunk(
  'personas/getAllTipoDocumentosPorPuestoAsyncThunk',
  async (queryAll : {serverDate : IServerDate, idCliente : number}) => await getAllTipoDocumentosPorPuesto(queryAll.serverDate, queryAll.idCliente)
    .then(async (result) => result)
    .catch(async (error) => getEmptyStatusTipoDocumentosPorPuesto(DEFAULT_STATE.error))  
);

export const updateTipoDocumentosPorPuestoAsyncThunk = createAsyncThunk(
  'personas/updateTipoDocumentosPorPuestoAsyncThunk',
  async (update: {serverDate : IServerDate, id: number, idCliente : number, object: ITipoDocumentosPorPuesto}) => await updateTipoDocumentosPorPuesto(update.serverDate, update.id, update.idCliente, update.object)
    .then(async (result) => result)
    .catch(async (error) => getEmptyStatusTipoDocumentosPorPuesto(DEFAULT_STATE.error))  
);

export const createTipoDocumentosPorPuestoAsyncThunk = createAsyncThunk(
  'personas/createTipoDocumentosPorPuestoAsyncThunk',
  async (create : {serverDate : IServerDate, idCliente : number, object: ITipoDocumentosPorPuesto}) => await createTipoDocumentosPorPuesto(create.serverDate, create.idCliente, create.object)
    .then(async (result) => result)
    .catch(async (error) => getEmptyStatusTipoDocumentosPorPuesto(DEFAULT_STATE.error))  
);

export const deleteTipoDocumentosPorPuestoAsyncThunk = createAsyncThunk(
  'personas/deleteTipoDocumentosPorPuestoAsyncThunk',
  async (del : {id : number, idCliente : number}) => await deleteTipoDocumentosPorPuesto(del.id, del.idCliente)
    .then(async (result) => result)
    .catch(async (error) => getEmptyStatusTipoDocumentosPorPuesto())  
);
