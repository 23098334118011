/**
 * Métodos AsyncThunk para ser usados en los reducers y
 * los resultados a las llamadas http puedan ser usados para
 * actualizar el state en redux
 */

import { createAsyncThunk } from "@reduxjs/toolkit";
import { DEFAULT_STATE } from "../../enums/default_state";
import { getMvMotivoNotificacion, getAllMvMotivoNotificacion, updateMvMotivoNotificacion, createMvMotivoNotificacion, deleteMvMotivoNotificacion } from "../../http/nomina";
import { getEmptyStatusMvMotivoNotificacion, IMvMotivoNotificacion } from "../../interfaces/nomina";
import { IServerDate } from "../../interfaces/time";

export const getMvMotivoNotificacionAsyncThunk = createAsyncThunk(
  'nomina/getMvMotivoNotificacionAsyncThunk',
  async (query : {serverDate : IServerDate, id: number}) => await getMvMotivoNotificacion(query.serverDate, query.id)
    .then(async (result) => result)
    .catch(async (error) => getEmptyStatusMvMotivoNotificacion(DEFAULT_STATE.error))  
);

export const getAllMvMotivoNotificacionAsyncThunk = createAsyncThunk(
  'nomina/getAllMvMotivoNotificacionAsyncThunk',
  async (queryAll : {serverDate : IServerDate}) => await getAllMvMotivoNotificacion(queryAll.serverDate)
    .then(async (result) => result)
    .catch(async (error) => getEmptyStatusMvMotivoNotificacion(DEFAULT_STATE.error))  
);

export const updateMvMotivoNotificacionAsyncThunk = createAsyncThunk(
  'nomina/updateMvMotivoNotificacionAsyncThunk',
  async (update: {serverDate : IServerDate, id: number, MvMotivoNotificacion: IMvMotivoNotificacion}) => await updateMvMotivoNotificacion(update.serverDate, update.id, update.MvMotivoNotificacion)
    .then(async (result) => result)
    .catch(async (error) => getEmptyStatusMvMotivoNotificacion(DEFAULT_STATE.error))  
);

export const createMvMotivoNotificacionAsyncThunk = createAsyncThunk(
  'nomina/createMvMotivoNotificacionAsyncThunk',
  async (create : {serverDate : IServerDate, MvMotivoNotificacion: IMvMotivoNotificacion}) => await createMvMotivoNotificacion(create.serverDate, create.MvMotivoNotificacion)
    .then(async (result) => result)
    .catch(async (error) => getEmptyStatusMvMotivoNotificacion(DEFAULT_STATE.error))  
);

export const deleteMvMotivoNotificacionAsyncThunk = createAsyncThunk(
  'nomina/deleteMvMotivoNotificacionAsyncThunk',
  async (del : {id : number}) => await deleteMvMotivoNotificacion(del.id)
    .then(async (result) => result)
    .catch(async (error) => getEmptyStatusMvMotivoNotificacion())  
);
