/**
 * Métodos para el manejo de IonicStorage 
 * @setStorage              guarda un unico objeto al storage
 * @getStorage              obtiene el objeto guardado con @setStorage
 * @setStorageArray         guarda el array de objetos en el storage
 * @getStorageArray         obtiene el array de objetos guardado con @setStorageArray
 * @removeStorageArray      elimina el array de objetos guardado con @setStorageArray
 * @setStorageItemArray     agrega un elemento al array de objeto guardado con @setStorageArray
 * @getStorageItemArray     obtiene un elemento del array guardado con @setStorageArray buscandolo por su id 
 * @removeStorageItemArray  elimina un elemento del array guardado con @setStorageArray buscandolo por su id 
 */

import { setStorageData, getStorageData, removeStorageData, setArrayItemStorageData, getArrayItemStorageData, removeArrayItemStorageData } from "../../api/storage";
import { IInformacionContrato, getEmptyInformacionContrato } from "../../interfaces/personas";

export const setStorageInformacionContrato = async (IInformacionContrato : IInformacionContrato) => await setStorageData('IInformacionContrato', IInformacionContrato);
export const getStorageInformacionContrato = async () : Promise<IInformacionContrato> => await getStorageData('IInformacionContrato', getEmptyInformacionContrato());
export const removeStorageInformacionContrato = async () => await removeStorageData('IInformacionContrato');
export const setStorageInformacionContratoArray = async (IInformacionContrato : IInformacionContrato[]) => await setStorageData('IInformacionContratoArray', IInformacionContrato);
export const getStorageInformacionContratoArray = async () : Promise<IInformacionContrato[]> => await getStorageData('IInformacionContratoArray', [] as IInformacionContrato[]);
export const removeStorageInformacionContratoArray = async () => await removeStorageData('IInformacionContratoArray');
export const setStorageInformacionContratoArrayItem = async (id: number, IInformacionContrato : IInformacionContrato) : Promise<IInformacionContrato[]> => await setArrayItemStorageData('IInformacionContratoArray', id, IInformacionContrato, [] as IInformacionContrato[]);
export const getStorageInformacionContratoArrayItem = async (id: number) : Promise<IInformacionContrato> => await getArrayItemStorageData('IInformacionContratoArray', id, getEmptyInformacionContrato);
export const removeStorageInformacionContratoArrayItem = async (id: number) : Promise<IInformacionContrato[]> => await removeArrayItemStorageData('IInformacionContratoArray', id, [] as IInformacionContrato[]);
