/**
 * Métodos para el manejo de IonicStorage 
 * @setStorage              guarda un unico objeto al storage
 * @getStorage              obtiene el objeto guardado con @setStorage
 * @setStorageArray         guarda el array de objetos en el storage
 * @getStorageArray         obtiene el array de objetos guardado con @setStorageArray
 * @removeStorageArray      elimina el array de objetos guardado con @setStorageArray
 * @setStorageItemArray     agrega un elemento al array de objeto guardado con @setStorageArray
 * @getStorageItemArray     obtiene un elemento del array guardado con @setStorageArray buscandolo por su id 
 * @removeStorageItemArray  elimina un elemento del array guardado con @setStorageArray buscandolo por su id 
 */

import { setStorageData, getStorageData, removeStorageData, setArrayItemStorageData, getArrayItemStorageData, removeArrayItemStorageData } from "../../api/storage";
import { IMvTipoCanalVenta, getEmptyMvTipoCanalVenta } from "../../interfaces/personas";

export const setStorageMvTipoCanalVenta = async (IMvTipoCanalVenta : IMvTipoCanalVenta) => await setStorageData('IMvTipoCanalVenta', IMvTipoCanalVenta);
export const getStorageMvTipoCanalVenta = async () : Promise<IMvTipoCanalVenta> => await getStorageData('IMvTipoCanalVenta', getEmptyMvTipoCanalVenta());
export const removeStorageMvTipoCanalVenta = async () => await removeStorageData('IMvTipoCanalVenta');
export const setStorageMvTipoCanalVentaArray = async (IMvTipoCanalVenta : IMvTipoCanalVenta[]) => await setStorageData('IMvTipoCanalVentaArray', IMvTipoCanalVenta);
export const getStorageMvTipoCanalVentaArray = async () : Promise<IMvTipoCanalVenta[]> => await getStorageData('IMvTipoCanalVentaArray', [] as IMvTipoCanalVenta[]);
export const removeStorageMvTipoCanalVentaArray = async () => await removeStorageData('IMvTipoCanalVentaArray');
export const setStorageMvTipoCanalVentaArrayItem = async (id: number, IMvTipoCanalVenta : IMvTipoCanalVenta) : Promise<IMvTipoCanalVenta[]> => await setArrayItemStorageData('IMvTipoCanalVentaArray', id, IMvTipoCanalVenta, [] as IMvTipoCanalVenta[]);
export const getStorageMvTipoCanalVentaArrayItem = async (id: number) : Promise<IMvTipoCanalVenta> => await getArrayItemStorageData('IMvTipoCanalVentaArray', id, getEmptyMvTipoCanalVenta);
export const removeStorageMvTipoCanalVentaArrayItem = async (id: number) : Promise<IMvTipoCanalVenta[]> => await removeArrayItemStorageData('IMvTipoCanalVentaArray', id, [] as IMvTipoCanalVenta[]);
