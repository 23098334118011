/**
 * Métodos AsyncThunk para ser usados en los reducers y
 * los resultados a las llamadas http puedan ser usados para
 * actualizar el state en redux
 */

import { createAsyncThunk } from "@reduxjs/toolkit";
import { DEFAULT_STATE } from "../../enums/default_state";
import { getMvTipoDocumento, getAllMvTipoDocumento, updateMvTipoDocumento, createMvTipoDocumento, deleteMvTipoDocumento } from "../../http/personas";
import { getEmptyStatusMvTipoDocumento, IMvTipoDocumento } from "../../interfaces/personas";
import { IServerDate } from "../../interfaces/time";

export const getMvTipoDocumentoAsyncThunk = createAsyncThunk(
  'personas/getMvTipoDocumentoAsyncThunk',
  async (query : {serverDate : IServerDate, id: number}) => await getMvTipoDocumento(query.serverDate, query.id)
    .then(async (result) => result)
    .catch(async (error) => getEmptyStatusMvTipoDocumento(DEFAULT_STATE.error))  
);

export const getAllMvTipoDocumentoAsyncThunk = createAsyncThunk(
  'personas/getAllMvTipoDocumentoAsyncThunk',
  async (queryAll : {serverDate : IServerDate}) => await getAllMvTipoDocumento(queryAll.serverDate)
    .then(async (result) => result)
    .catch(async (error) => getEmptyStatusMvTipoDocumento(DEFAULT_STATE.error))  
);

export const updateMvTipoDocumentoAsyncThunk = createAsyncThunk(
  'personas/updateMvTipoDocumentoAsyncThunk',
  async (update: {serverDate : IServerDate, id: number, MvTipoDocumento: IMvTipoDocumento}) => await updateMvTipoDocumento(update.serverDate, update.id, update.MvTipoDocumento)
    .then(async (result) => result)
    .catch(async (error) => getEmptyStatusMvTipoDocumento(DEFAULT_STATE.error))  
);

export const createMvTipoDocumentoAsyncThunk = createAsyncThunk(
  'personas/createMvTipoDocumentoAsyncThunk',
  async (create : {serverDate : IServerDate, MvTipoDocumento: IMvTipoDocumento}) => await createMvTipoDocumento(create.serverDate, create.MvTipoDocumento)
    .then(async (result) => result)
    .catch(async (error) => getEmptyStatusMvTipoDocumento(DEFAULT_STATE.error))  
);

export const deleteMvTipoDocumentoAsyncThunk = createAsyncThunk(
  'personas/deleteMvTipoDocumentoAsyncThunk',
  async (del : {id : number}) => await deleteMvTipoDocumento(del.id)
    .then(async (result) => result)
    .catch(async (error) => getEmptyStatusMvTipoDocumento())  
);

