/**
 * Reducers creados para obtener asíncronamente 
 * los datos de los modelos y dependiendo del resultado
 * de la peticón actualice el state en redux o mande un mensaje de error
 */

import { PayloadAction } from "@reduxjs/toolkit";
import { getHorasExtraAsyncThunk, getAllHorasExtraAsyncThunk, updateHorasExtraAsyncThunk, createHorasExtraAsyncThunk, deleteHorasExtraAsyncThunk } from "../../asyncThunk/nomina";
import { DEFAULT_STATE } from "../../enums/default_state";
import { IStatusHorasExtra } from "../../interfaces/nomina";
import { nominaInitialState } from "../../slices/nominaSlice";

// Reducers: HorasExtra
export const createHorasExtraReducers = () => {
  const get = () => {
    var { pending, fulfilled, rejected } = getHorasExtraAsyncThunk;
    return {
      [pending.type]: (state : typeof nominaInitialState) => {
        state.nominaState.horasExtra.estado = DEFAULT_STATE.loading;
      },
      [fulfilled.type]: (state : typeof nominaInitialState, action : PayloadAction<IStatusHorasExtra>) => {
        const result = action.payload;
        result.expiry_array = state.nominaState.horasExtra.expiry_array;
        if (result.estado === DEFAULT_STATE.error) result.data_array = state.nominaState.horasExtra.data_array;
        state.nominaState.horasExtra = result;
      },
      [rejected.type]: (state : typeof nominaInitialState, action : PayloadAction<IStatusHorasExtra>) => {
        state.nominaState.horasExtra = {
          ...state.nominaState.horasExtra,
          estado: action.payload.estado,
          mensaje: "Ocurrió un problema al realizar la solicitud",
        };
      },
    };
  };
  
  const getAll = () => {
    var { pending, fulfilled, rejected } = getAllHorasExtraAsyncThunk;
    return {
      [pending.type]: (state : typeof nominaInitialState) => {
        state.nominaState.horasExtra.estado = DEFAULT_STATE.loading;
      },
      [fulfilled.type]: (state : typeof nominaInitialState, action : PayloadAction<IStatusHorasExtra>) => {
        const result = action.payload;
        result.data = state.nominaState.horasExtra.data;
        result.expiry = state.nominaState.horasExtra.expiry;
        if (result.estado === DEFAULT_STATE.error) result.data_array = state.nominaState.horasExtra.data_array;
        state.nominaState.horasExtra = result;
      },
      [rejected.type]: (state : typeof nominaInitialState, action : PayloadAction<IStatusHorasExtra>) => {
        state.nominaState.horasExtra = {
          ...state.nominaState.horasExtra,
          estado: action.payload.estado,
          mensaje: "Ocurrió un problema al realizar la solicitud",
        };
      },
    };
  };
  
  const update = () => {
    var { pending, fulfilled, rejected } = updateHorasExtraAsyncThunk;
    return {
      [pending.type]: (state : typeof nominaInitialState) => {
        state.nominaState.horasExtra.estado = DEFAULT_STATE.loading;
      },
      [fulfilled.type]: (state : typeof nominaInitialState, action : PayloadAction<IStatusHorasExtra>) => {
        const result: IStatusHorasExtra = action.payload;
        result.expiry = state.nominaState.horasExtra.expiry;
        result.expiry_array = state.nominaState.horasExtra.expiry_array;
        if (result.estado === DEFAULT_STATE.error) result.data_array = state.nominaState.horasExtra.data_array;
        state.nominaState.horasExtra = result;
      },
      [rejected.type]: (state : typeof nominaInitialState, action : PayloadAction<IStatusHorasExtra>) => {
        state.nominaState.horasExtra = {
          ...state.nominaState.horasExtra,
          estado: action.payload.estado,
          mensaje: "Ocurrió un problema al realizar la solicitud",
        };
      },
    };
  };

  const create = () => {
    var { pending, fulfilled, rejected } = createHorasExtraAsyncThunk;
    return {
      [pending.type]: (state : typeof nominaInitialState) => {
        state.nominaState.horasExtra.estado = DEFAULT_STATE.loading;
      },
      [fulfilled.type]: (state : typeof nominaInitialState, action : PayloadAction<IStatusHorasExtra>) => {
        const result = action.payload;
        result.expiry = state.nominaState.horasExtra.expiry;
        result.expiry_array = state.nominaState.horasExtra.expiry_array;
        if (result.estado === DEFAULT_STATE.error) result.data_array = state.nominaState.horasExtra.data_array;
        state.nominaState.horasExtra = result;
      },
      [rejected.type]: (state : typeof nominaInitialState, action : PayloadAction<IStatusHorasExtra>) => {
        state.nominaState.horasExtra = {
          ...state.nominaState.horasExtra,
          estado: action.payload.estado,
          mensaje: "Ocurrió un problema al realizar la solicitud",
        };
      },
    };
  };
  
  const del = () => {
    var { pending, fulfilled, rejected } = deleteHorasExtraAsyncThunk;
    return {
      [pending.type]: (state : typeof nominaInitialState) => {
        state.nominaState.horasExtra.estado = DEFAULT_STATE.loading;
      },
      [fulfilled.type]: (state : typeof nominaInitialState, action : PayloadAction<IStatusHorasExtra>) => {
        const result: IStatusHorasExtra = action.payload;
        result.expiry = state.nominaState.horasExtra.expiry;
        result.expiry_array = state.nominaState.horasExtra.expiry_array;
        if (result.estado === DEFAULT_STATE.error) result.data_array = state.nominaState.horasExtra.data_array;
        state.nominaState.horasExtra = result;
      },
      [rejected.type]: (state : typeof nominaInitialState, action : PayloadAction<IStatusHorasExtra>) => {
        state.nominaState.horasExtra = {
          ...state.nominaState.horasExtra,
          estado: action.payload.estado,
          mensaje: "Ocurrió un problema al realizar la solicitud",
        };
      },
    };
  };

  return {
    ...get(),
    ...getAll(),
    ...update(),
    ...create(),
    ...del(),
  };
};
