/**
 * Reducers creados para obtener asíncronamente 
 * los datos de los modelos y dependiendo del resultado
 * de la peticón actualice el state en redux o mande un mensaje de error
 */

import { PayloadAction } from "@reduxjs/toolkit";
import { getTipoDocumentosPorPuestoAsyncThunk, getAllTipoDocumentosPorPuestoAsyncThunk, updateTipoDocumentosPorPuestoAsyncThunk, createTipoDocumentosPorPuestoAsyncThunk, deleteTipoDocumentosPorPuestoAsyncThunk } from "../../asyncThunk/personas";
import { DEFAULT_STATE } from "../../enums/default_state";
import { IStatusTipoDocumentosPorPuesto } from "../../interfaces/personas";
import { personasInitialState } from "../../slices/personasSlice";

// Reducers: TipoDocumentosPorPuesto
export const createTipoDocumentosPorPuestoReducers = () => {
  const get = () => {
    var { pending, fulfilled, rejected } = getTipoDocumentosPorPuestoAsyncThunk;
    return {
      [pending.type]: (state : typeof personasInitialState) => {
        state.personasState.tipoDocumentosPorPuesto.estado = DEFAULT_STATE.loading;
      },
      [fulfilled.type]: (state : typeof personasInitialState, action : PayloadAction<IStatusTipoDocumentosPorPuesto>) => {
        const result = action.payload;
        result.expiry_array = state.personasState.tipoDocumentosPorPuesto.expiry_array;
        if (result.estado === DEFAULT_STATE.error) result.data_array = state.personasState.tipoDocumentosPorPuesto.data_array;
        state.personasState.tipoDocumentosPorPuesto = result;
      },
      [rejected.type]: (state : typeof personasInitialState, action : PayloadAction<IStatusTipoDocumentosPorPuesto>) => {
        state.personasState.tipoDocumentosPorPuesto = {
          ...state.personasState.tipoDocumentosPorPuesto,
          estado: action.payload.estado,
          mensaje: "Ocurrió un problema al realizar la solicitud",
        };
      },
    };
  };
  
  const getAll = () => {
    var { pending, fulfilled, rejected } = getAllTipoDocumentosPorPuestoAsyncThunk;
    return {
      [pending.type]: (state : typeof personasInitialState) => {
        state.personasState.tipoDocumentosPorPuesto.estado = DEFAULT_STATE.loading;
      },
      [fulfilled.type]: (state : typeof personasInitialState, action : PayloadAction<IStatusTipoDocumentosPorPuesto>) => {
        const result = action.payload;
        result.data = state.personasState.tipoDocumentosPorPuesto.data;
        result.expiry = state.personasState.tipoDocumentosPorPuesto.expiry;
        if (result.estado === DEFAULT_STATE.error) result.data_array = state.personasState.tipoDocumentosPorPuesto.data_array;
        state.personasState.tipoDocumentosPorPuesto = result;
      },
      [rejected.type]: (state : typeof personasInitialState, action : PayloadAction<IStatusTipoDocumentosPorPuesto>) => {
        state.personasState.tipoDocumentosPorPuesto = {
          ...state.personasState.tipoDocumentosPorPuesto,
          estado: action.payload.estado,
          mensaje: "Ocurrió un problema al realizar la solicitud",
        };
      },
    };
  };
  
  const update = () => {
    var { pending, fulfilled, rejected } = updateTipoDocumentosPorPuestoAsyncThunk;
    return {
      [pending.type]: (state : typeof personasInitialState) => {
        state.personasState.tipoDocumentosPorPuesto.estado = DEFAULT_STATE.loading;
      },
      [fulfilled.type]: (state : typeof personasInitialState, action : PayloadAction<IStatusTipoDocumentosPorPuesto>) => {
        const result: IStatusTipoDocumentosPorPuesto = action.payload;
        result.expiry = state.personasState.tipoDocumentosPorPuesto.expiry;
        result.expiry_array = state.personasState.tipoDocumentosPorPuesto.expiry_array;
        if (result.estado === DEFAULT_STATE.error) result.data_array = state.personasState.tipoDocumentosPorPuesto.data_array;
        state.personasState.tipoDocumentosPorPuesto = result;
      },
      [rejected.type]: (state : typeof personasInitialState, action : PayloadAction<IStatusTipoDocumentosPorPuesto>) => {
        state.personasState.tipoDocumentosPorPuesto = {
          ...state.personasState.tipoDocumentosPorPuesto,
          estado: action.payload.estado,
          mensaje: "Ocurrió un problema al realizar la solicitud",
        };
      },
    };
  };

  const create = () => {
    var { pending, fulfilled, rejected } = createTipoDocumentosPorPuestoAsyncThunk;
    return {
      [pending.type]: (state : typeof personasInitialState) => {
        state.personasState.tipoDocumentosPorPuesto.estado = DEFAULT_STATE.loading;
      },
      [fulfilled.type]: (state : typeof personasInitialState, action : PayloadAction<IStatusTipoDocumentosPorPuesto>) => {
        const result = action.payload;
        result.expiry = state.personasState.tipoDocumentosPorPuesto.expiry;
        result.expiry_array = state.personasState.tipoDocumentosPorPuesto.expiry_array;
        if (result.estado === DEFAULT_STATE.error) result.data_array = state.personasState.tipoDocumentosPorPuesto.data_array;
        state.personasState.tipoDocumentosPorPuesto = result;
      },
      [rejected.type]: (state : typeof personasInitialState, action : PayloadAction<IStatusTipoDocumentosPorPuesto>) => {
        state.personasState.tipoDocumentosPorPuesto = {
          ...state.personasState.tipoDocumentosPorPuesto,
          estado: action.payload.estado,
          mensaje: "Ocurrió un problema al realizar la solicitud",
        };
      },
    };
  };
  
  const del = () => {
    var { pending, fulfilled, rejected } = deleteTipoDocumentosPorPuestoAsyncThunk;
    return {
      [pending.type]: (state : typeof personasInitialState) => {
        state.personasState.tipoDocumentosPorPuesto.estado = DEFAULT_STATE.loading;
      },
      [fulfilled.type]: (state : typeof personasInitialState, action : PayloadAction<IStatusTipoDocumentosPorPuesto>) => {
        const result: IStatusTipoDocumentosPorPuesto = action.payload;
        result.expiry = state.personasState.tipoDocumentosPorPuesto.expiry;
        result.expiry_array = state.personasState.tipoDocumentosPorPuesto.expiry_array;
        if (result.estado === DEFAULT_STATE.error) result.data_array = state.personasState.tipoDocumentosPorPuesto.data_array;
        state.personasState.tipoDocumentosPorPuesto = result;
      },
      [rejected.type]: (state : typeof personasInitialState, action : PayloadAction<IStatusTipoDocumentosPorPuesto>) => {
        state.personasState.tipoDocumentosPorPuesto = {
          ...state.personasState.tipoDocumentosPorPuesto,
          estado: action.payload.estado,
          mensaje: "Ocurrió un problema al realizar la solicitud",
        };
      },
    };
  };

  return {
    ...get(),
    ...getAll(),
    ...update(),
    ...create(),
    ...del(),
  };
};
