/**
 * Métodos del, get, post, put para llamadas a la API
 * @get     en este método primero consulta con @getFromLocalStorage si es necesario hacer la petición http sino se obtiene del LocalStorage;
 *          si hay un error en la petición http se busca el objeto en el LocalStorage sino lo encuentra devuelve un objeto vacío; si la
 *          petición se realiza correctamente actualiza el valor individual y en el array del objeto en el LocalStorage y devuelve el objeto
 *          obtenido del servidor.
 * @getAll  en este método primero consulta con @getFromLocalStorage si es necesario hacer la petición http sino se obtiene del LocalStorage;
 *          si hay un error en la petición http se busca el objeto array en el LocalStorage sino lo encuentra devuelve un array vacío; si la
 *          petición se realiza correctamente actualiza array en el LocalStorage y devuelve el array obtenido del servidor
 * @update  en este método después de realizar la petición http si falla devuelve el objeto que se iba a actualizar; si la petición se
 *          realiza correctamente actualiza el valor individual y en el array del objeto en el LocalStorage y devuelve el objeto obtenido del
 *          servidor.
 * @create  en este método después de realizar la petición http si falla devuelve el objeto que se iba a crear; si la petición se realiza
 *          correctamente actualiza el valor individual y en el array del objeto en el LocalStorage y devuelve el objeto obtenido del
 *          servidor.
 * @delete  en este método después de realizar la petición http si falla devuelve el objeto que se iba a eliminar; si la petición se realiza
 *          correctamente elimina el valor individual y en el objeto en el arrya del LocalStorage y devuelve un objeto vacio (Default)
 */

import { del, get, post, put } from "../../api/api";
import {
  getFromLocalStorage,
  updateLocalStorageExpiry,
} from "../../api/helpers";
import { removeArrayItemStorageData } from "../../api/storage";
import { DEFAULT_STATE } from "../../enums/default_state";
import {
  getEmptyStatusMvRiesgoPuesto,
  setMvRiesgoPuesto,
  IMvRiesgoPuesto,
  getSerializableMvRiesgoPuesto,
} from "../../interfaces/nomina";
import { IServerDate } from "../../interfaces/time";
import {
  getStorageMvRiesgoPuestoArrayItem,
  setStorageMvRiesgoPuesto,
  setStorageMvRiesgoPuestoArrayItem,
  getStorageMvRiesgoPuestoArray,
  setStorageMvRiesgoPuestoArray,
  removeStorageMvRiesgoPuesto,
} from "../../localStorage/nomina";

export const getMvRiesgoPuesto = async (
  serverDate: IServerDate,
  pk: number
) => {
  const temp = getEmptyStatusMvRiesgoPuesto();
  const doHttp = await getFromLocalStorage(serverDate, temp.expiry);
  temp.expiry = doHttp.storage;
  if (!doHttp.result) {
    console.log(
      `(localStorage) MvRiesgoPuesto con pk: ${pk}, obtenido con éxito`
    );
    temp.estado = DEFAULT_STATE.success;
    temp.mensaje = `MvRiesgoPuesto obtenido con éxito`;
    temp.data = await getStorageMvRiesgoPuestoArrayItem(pk);
    return temp;
  }
  const result = await get(`nomina/riesgo-puesto/${pk}`);
  const data = result.data;
  console.log(`MvRiesgoPuesto data: `, data);
  if (!data.id || data.detail) {
    console.log(
      `Error al obtener MvRiesgoPuesto con pk: ${pk}, ${data.detail}`
    );
    temp.estado = DEFAULT_STATE.error;
    temp.mensaje = `Error al obtener el MvRiesgoPuesto: ${
      data.detail ?? "Hubo un problema al conectarse con el servidor"
    }`;
    temp.data = await getStorageMvRiesgoPuestoArrayItem(pk);
    return temp;
  }
  console.log(`MvRiesgoPuesto con pk: ${pk}, obtenido con éxito`);
  temp.estado = DEFAULT_STATE.success;
  temp.mensaje = `MvRiesgoPuesto obtenido con éxito`;
  temp.data = await setMvRiesgoPuesto(data, serverDate);
  await setStorageMvRiesgoPuesto(temp.data);
  temp.data_array = await setStorageMvRiesgoPuestoArrayItem(pk, temp.data);
  temp.expiry = await updateLocalStorageExpiry(serverDate, temp.expiry);
  return temp;
};

export const getAllMvRiesgoPuesto = async (serverDate: IServerDate) => {
  const temp = getEmptyStatusMvRiesgoPuesto();
  const doHttp = await getFromLocalStorage(serverDate, temp.expiry_array);
  temp.expiry_array = doHttp.storage;
  if (!doHttp.result) {
    console.log(`(localStorage) Todos los MvRiesgoPuestos obtenidos con éxito`);
    temp.estado = DEFAULT_STATE.success;
    temp.mensaje = `Todos los MvRiesgoPuesto obtenidos con éxito`;
    temp.data_array = await getStorageMvRiesgoPuestoArray();
    return temp;
  }
  const result = await get(`nomina/riesgo-puesto`);
  const data = result.data;
  if (data.detail) {
    console.log(`Error al obtener la lista de MvRiesgoPuesto, ${data.detail}`);
    temp.estado = DEFAULT_STATE.error;
    temp.mensaje = `Error al obtener la lista de MvRiesgoPuesto: ${
      data.detail ?? "Hubo un problema al conectarse con el servidor"
    }`;
    temp.data_array = await getStorageMvRiesgoPuestoArray();
    return temp;
  }
  console.log(`Todos los MvRiesgoPuesto obtenidos con éxito`, data);
  temp.estado = DEFAULT_STATE.success;
  temp.mensaje = `Todos los MvRiesgoPuesto obtenidos con éxito`;
  temp.data_array = await Promise.all(
    data.map(async (e: any) => await setMvRiesgoPuesto(e, serverDate))
  );
  await setStorageMvRiesgoPuestoArray(temp.data_array);
  temp.expiry_array = await updateLocalStorageExpiry(
    serverDate,
    temp.expiry_array
  );
  return temp;
};

export const updateMvRiesgoPuesto = async (
  serverDate: IServerDate,
  pk: number,
  object: IMvRiesgoPuesto
) => {
  const temp = getEmptyStatusMvRiesgoPuesto();
  const result = await put(
    `nomina/riesgo-puesto/${pk}`,
    getSerializableMvRiesgoPuesto(object)
  );
  const data = result.data;
  if (!data.id || data.detail) {
    console.log(
      `Error al actualizar MvRiesgoPuesto con pk: ${pk}, ${data.detail}`
    );
    temp.estado = DEFAULT_STATE.error;
    temp.mensaje = `Error al actualizar el MvRiesgoPuesto: ${
      data.detail ?? "Hubo un problema al conectarse con el servidor"
    }`;
    temp.data = await setMvRiesgoPuesto(object, serverDate);
    return temp;
  }
  console.log(`MvRiesgoPuesto con pk: ${pk}, actualizado con éxito`);
  temp.estado = DEFAULT_STATE.success;
  temp.mensaje = `MvRiesgoPuesto actualizado con éxito`;
  temp.data = await setMvRiesgoPuesto(data, serverDate);
  await setStorageMvRiesgoPuesto(temp.data);
  temp.data_array = await setStorageMvRiesgoPuestoArrayItem(
    temp.data.id,
    temp.data
  );
  return temp;
};

export const createMvRiesgoPuesto = async (
  serverDate: IServerDate,
  object: IMvRiesgoPuesto
) => {
  const temp = getEmptyStatusMvRiesgoPuesto();
  const result = await post(
    `nomina/riesgo-puesto`,
    getSerializableMvRiesgoPuesto(object)
  );
  const data = result.data;
  if (!data.id || data.detail) {
    console.log(
      `Error al crear MvRiesgoPuesto con los datos ${object}, ${data.detail}`
    );
    temp.estado = DEFAULT_STATE.error;
    temp.mensaje = `Error al crear el MvRiesgoPuesto: ${
      data.detail ?? "Hubo un problema al conectarse con el servidor"
    }`;
    temp.data = await setMvRiesgoPuesto(object, serverDate);
    return temp;
  }
  console.log(`MvRiesgoPuesto creado con éxito`);
  temp.estado = DEFAULT_STATE.success;
  temp.mensaje = `MvRiesgoPuesto creado con éxito`;
  temp.data = await setMvRiesgoPuesto(data, serverDate);
  await setStorageMvRiesgoPuesto(temp.data);
  temp.data_array = await setStorageMvRiesgoPuestoArrayItem(
    temp.data.id,
    temp.data
  );
  return temp;
};

export const deleteMvRiesgoPuesto = async (pk: number) => {
  const temp = getEmptyStatusMvRiesgoPuesto();
  const result = await del(`nomina/riesgo-puesto/${pk}`);
  const data = result.data;
  if (data.detail) {
    console.log(
      `Error al eliminar MvRiesgoPuesto con pk: ${pk}, ${data.detail}`
    );
    temp.estado = DEFAULT_STATE.error;
    temp.mensaje = `Error al eliminar el datos fiscales: ${data.detail}`;
    temp.data = await getStorageMvRiesgoPuestoArrayItem(pk);
    temp.data_array = await getStorageMvRiesgoPuestoArray();
    return temp;
  }
  console.log(`MvRiesgoPuesto eliminado con éxito`);
  temp.estado = DEFAULT_STATE.success;
  temp.mensaje = `MvRiesgoPuesto eliminado con éxito`;
  await removeStorageMvRiesgoPuesto();
  temp.data_array = await removeArrayItemStorageData("IMvRiesgoPuesto", pk, []);
  return temp;
};
