/**
 * Métodos del, get, post, put para llamadas a la API
 * @get     en este método primero consulta con @getFromLocalStorage si es necesario hacer la petición http sino se obtiene del LocalStorage;
 *          si hay un error en la petición http se busca el objeto en el LocalStorage sino lo encuentra devuelve un objeto vacío; si la
 *          petición se realiza correctamente actualiza el valor individual y en el array del objeto en el LocalStorage y devuelve el objeto
 *          obtenido del servidor.
 * @getAll  en este método primero consulta con @getFromLocalStorage si es necesario hacer la petición http sino se obtiene del LocalStorage;
 *          si hay un error en la petición http se busca el objeto array en el LocalStorage sino lo encuentra devuelve un array vacío; si la
 *          petición se realiza correctamente actualiza array en el LocalStorage y devuelve el array obtenido del servidor
 * @update  en este método después de realizar la petición http si falla devuelve el objeto que se iba a actualizar; si la petición se
 *          realiza correctamente actualiza el valor individual y en el array del objeto en el LocalStorage y devuelve el objeto obtenido del
 *          servidor.
 * @create  en este método después de realizar la petición http si falla devuelve el objeto que se iba a crear; si la petición se realiza
 *          correctamente actualiza el valor individual y en el array del objeto en el LocalStorage y devuelve el objeto obtenido del
 *          servidor.
 * @delete  en este método después de realizar la petición http si falla devuelve el objeto que se iba a eliminar; si la petición se realiza
 *          correctamente elimina el valor individual y en el objeto en el arrya del LocalStorage y devuelve un objeto vacio (Default)
 */

import { del, get, post, put } from "../../api/api";
import {
  getFromLocalStorage,
  updateLocalStorageExpiry,
} from "../../api/helpers";
import { removeArrayItemStorageData } from "../../api/storage";
import { DEFAULT_STATE } from "../../enums/default_state";
import {
  getEmptyStatusMvImpuestos,
  setMvImpuestos,
  IMvImpuestos,
  getSerializableMvImpuestos,
} from "../../interfaces/personas";
import { IServerDate } from "../../interfaces/time";
import {
  getStorageMvImpuestosArrayItem,
  setStorageMvImpuestos,
  setStorageMvImpuestosArrayItem,
  getStorageMvImpuestosArray,
  setStorageMvImpuestosArray,
  removeStorageMvImpuestos,
} from "../../localStorage/personas";

export const getMvImpuestos = async (serverDate: IServerDate, pk: number) => {
  const temp = getEmptyStatusMvImpuestos();
  const doHttp = await getFromLocalStorage(serverDate, temp.expiry);
  temp.expiry = doHttp.storage;
  if (!doHttp.result) {
    console.log(`(localStorage) MvImpuestos con pk: ${pk}, obtenido con éxito`);
    temp.estado = DEFAULT_STATE.success;
    temp.mensaje = `MvImpuestos obtenido con éxito`;
    temp.data = await getStorageMvImpuestosArrayItem(pk);
    return temp;
  }
  const result = await get(`personas/impuestos/${pk}`);
  const data = result.data;
  console.log(`MvImpuestos data: `, data);
  if (!data.id || data.detail) {
    console.log(`Error al obtener MvImpuestos con pk: ${pk}, ${data.detail}`);
    temp.estado = DEFAULT_STATE.error;
    temp.mensaje = `Error al obtener el MvImpuestos: ${
      data.detail ?? "Hubo un problema al conectarse con el servidor"
    }`;
    temp.data = await getStorageMvImpuestosArrayItem(pk);
    return temp;
  }
  console.log(`MvImpuestos con pk: ${pk}, obtenido con éxito`);
  temp.estado = DEFAULT_STATE.success;
  temp.mensaje = `MvImpuestos obtenido con éxito`;
  temp.data = await setMvImpuestos(data, serverDate);
  await setStorageMvImpuestos(temp.data);
  temp.data_array = await setStorageMvImpuestosArrayItem(pk, temp.data);
  temp.expiry = await updateLocalStorageExpiry(serverDate, temp.expiry);
  return temp;
};

export const getAllMvImpuestos = async (serverDate: IServerDate) => {
  const temp = getEmptyStatusMvImpuestos();
  const doHttp = await getFromLocalStorage(serverDate, temp.expiry_array);
  temp.expiry_array = doHttp.storage;
  if (!doHttp.result) {
    console.log(`(localStorage) Todos los MvImpuestoss obtenidos con éxito`);
    temp.estado = DEFAULT_STATE.success;
    temp.mensaje = `Todos los MvImpuestos obtenidos con éxito`;
    temp.data_array = await getStorageMvImpuestosArray();
    return temp;
  }
  const result = await get(`personas/impuestos`);
  const data = result.data;
  if (data.detail) {
    console.log(`Error al obtener la lista de MvImpuestos, ${data.detail}`);
    temp.estado = DEFAULT_STATE.error;
    temp.mensaje = `Error al obtener la lista de MvImpuestos: ${
      data.detail ?? "Hubo un problema al conectarse con el servidor"
    }`;
    temp.data_array = await getStorageMvImpuestosArray();
    return temp;
  }
  console.log(`Todos los MvImpuestos obtenidos con éxito`);
  temp.estado = DEFAULT_STATE.success;
  temp.mensaje = `Todos los MvImpuestos obtenidos con éxito`;
  temp.data_array = await Promise.all(
    data.map(async (e: any) => await setMvImpuestos(e, serverDate))
  );
  await setStorageMvImpuestosArray(temp.data_array);
  temp.expiry_array = await updateLocalStorageExpiry(
    serverDate,
    temp.expiry_array
  );
  return temp;
};

export const updateMvImpuestos = async (
  serverDate: IServerDate,
  pk: number,
  object: IMvImpuestos
) => {
  const temp = getEmptyStatusMvImpuestos();
  const result = await put(
    `personas/impuestos/${pk}`,
    getSerializableMvImpuestos(object)
  );
  const data = result.data;
  if (!data.id || data.detail) {
    console.log(
      `Error al actualizar MvImpuestos con pk: ${pk}, ${data.detail}`
    );
    temp.estado = DEFAULT_STATE.error;
    temp.mensaje = `Error al actualizar el MvImpuestos: ${
      data.detail ?? "Hubo un problema al conectarse con el servidor"
    }`;
    temp.data = await setMvImpuestos(object, serverDate);
    return temp;
  }
  console.log(`MvImpuestos con pk: ${pk}, actualizado con éxito`);
  temp.estado = DEFAULT_STATE.success;
  temp.mensaje = `MvImpuestos actualizado con éxito`;
  temp.data = await setMvImpuestos(data, serverDate);
  await setStorageMvImpuestos(temp.data);
  temp.data_array = await setStorageMvImpuestosArrayItem(
    temp.data.id,
    temp.data
  );
  return temp;
};

export const createMvImpuestos = async (
  serverDate: IServerDate,
  object: IMvImpuestos
) => {
  const temp = getEmptyStatusMvImpuestos();
  const result = await post(
    `personas/impuestos`,
    getSerializableMvImpuestos(object)
  );
  const data = result.data;
  if (!data.id || data.detail) {
    console.log(
      `Error al crear MvImpuestos con los datos ${object}, ${data.detail}`
    );
    temp.estado = DEFAULT_STATE.error;
    temp.mensaje = `Error al crear el MvImpuestos: ${
      data.detail ?? "Hubo un problema al conectarse con el servidor"
    }`;
    temp.data = await setMvImpuestos(object, serverDate);
    return temp;
  }
  console.log(`MvImpuestos creado con éxito`);
  temp.estado = DEFAULT_STATE.success;
  temp.mensaje = `MvImpuestos creado con éxito`;
  temp.data = await setMvImpuestos(data, serverDate);
  await setStorageMvImpuestos(temp.data);
  temp.data_array = await setStorageMvImpuestosArrayItem(
    temp.data.id,
    temp.data
  );
  return temp;
};

export const deleteMvImpuestos = async (pk: number) => {
  const temp = getEmptyStatusMvImpuestos();
  const result = await del(`personas/impuestos/${pk}`);
  const data = result.data;
  if (data.detail) {
    console.log(`Error al eliminar MvImpuestos con pk: ${pk}, ${data.detail}`);
    temp.estado = DEFAULT_STATE.error;
    temp.mensaje = `Error al eliminar el datos fiscales: ${data.detail}`;
    temp.data = await getStorageMvImpuestosArrayItem(pk);
    temp.data_array = await getStorageMvImpuestosArray();
    return temp;
  }
  console.log(`MvImpuestos eliminado con éxito`);
  temp.estado = DEFAULT_STATE.success;
  temp.mensaje = `MvImpuestos eliminado con éxito`;
  await removeStorageMvImpuestos();
  temp.data_array = await removeArrayItemStorageData("IMvImpuestos", pk, []);
  return temp;
};
