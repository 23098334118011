/**
 * Métodos AsyncThunk para ser usados en los reducers y
 * los resultados a las llamadas http puedan ser usados para
 * actualizar el state en redux
 */

import { createAsyncThunk } from "@reduxjs/toolkit";
import { DEFAULT_STATE } from "../../enums/default_state";
import { getMvFormaDePagoSuscripcion, getAllMvFormaDePagoSuscripcion, updateMvFormaDePagoSuscripcion, createMvFormaDePagoSuscripcion, deleteMvFormaDePagoSuscripcion } from "../../http/personas";
import { getEmptyStatusMvFormaDePagoSuscripcion, IMvFormaDePagoSuscripcion } from "../../interfaces/personas";
import { IServerDate } from "../../interfaces/time";

export const getMvFormaDePagoSuscripcionAsyncThunk = createAsyncThunk(
  'personas/getMvFormaDePagoSuscripcionAsyncThunk',
  async (query : {serverDate : IServerDate, id: number}) => await getMvFormaDePagoSuscripcion(query.serverDate, query.id)
    .then(async (result) => result)
    .catch(async (error) => getEmptyStatusMvFormaDePagoSuscripcion(DEFAULT_STATE.error))  
);

export const getAllMvFormaDePagoSuscripcionAsyncThunk = createAsyncThunk(
  'personas/getAllMvFormaDePagoSuscripcionAsyncThunk',
  async (queryAll : {serverDate : IServerDate}) => await getAllMvFormaDePagoSuscripcion(queryAll.serverDate)
    .then(async (result) => result)
    .catch(async (error) => getEmptyStatusMvFormaDePagoSuscripcion(DEFAULT_STATE.error))  
);

export const updateMvFormaDePagoSuscripcionAsyncThunk = createAsyncThunk(
  'personas/updateMvFormaDePagoSuscripcionAsyncThunk',
  async (update: {serverDate : IServerDate, id: number, MvFormaDePagoSuscripcion: IMvFormaDePagoSuscripcion}) => await updateMvFormaDePagoSuscripcion(update.serverDate, update.id, update.MvFormaDePagoSuscripcion)
    .then(async (result) => result)
    .catch(async (error) => getEmptyStatusMvFormaDePagoSuscripcion(DEFAULT_STATE.error))  
);

export const createMvFormaDePagoSuscripcionAsyncThunk = createAsyncThunk(
  'personas/createMvFormaDePagoSuscripcionAsyncThunk',
  async (create : {serverDate : IServerDate, MvFormaDePagoSuscripcion: IMvFormaDePagoSuscripcion}) => await createMvFormaDePagoSuscripcion(create.serverDate, create.MvFormaDePagoSuscripcion)
    .then(async (result) => result)
    .catch(async (error) => getEmptyStatusMvFormaDePagoSuscripcion(DEFAULT_STATE.error))  
);

export const deleteMvFormaDePagoSuscripcionAsyncThunk = createAsyncThunk(
  'personas/deleteMvFormaDePagoSuscripcionAsyncThunk',
  async (del : {id : number}) => await deleteMvFormaDePagoSuscripcion(del.id)
    .then(async (result) => result)
    .catch(async (error) => getEmptyStatusMvFormaDePagoSuscripcion())  
);
