/**
 * Métodos del, get, post, put para llamadas a la API
 * @get     en este método primero consulta con @getFromLocalStorage si es necesario hacer la petición http sino se obtiene del LocalStorage;
 *          si hay un error en la petición http se busca el objeto en el LocalStorage sino lo encuentra devuelve un objeto vacío; si la
 *          petición se realiza correctamente actualiza el valor individual y en el array del objeto en el LocalStorage y devuelve el objeto
 *          obtenido del servidor.
 * @getAll  en este método primero consulta con @getFromLocalStorage si es necesario hacer la petición http sino se obtiene del LocalStorage;
 *          si hay un error en la petición http se busca el objeto array en el LocalStorage sino lo encuentra devuelve un array vacío; si la
 *          petición se realiza correctamente actualiza array en el LocalStorage y devuelve el array obtenido del servidor
 * @update  en este método después de realizar la petición http si falla devuelve el objeto que se iba a actualizar; si la petición se
 *          realiza correctamente actualiza el valor individual y en el array del objeto en el LocalStorage y devuelve el objeto obtenido del
 *          servidor.
 * @create  en este método después de realizar la petición http si falla devuelve el objeto que se iba a crear; si la petición se realiza
 *          correctamente actualiza el valor individual y en el array del objeto en el LocalStorage y devuelve el objeto obtenido del
 *          servidor.
 * @delete  en este método después de realizar la petición http si falla devuelve el objeto que se iba a eliminar; si la petición se realiza
 *          correctamente elimina el valor individual y en el objeto en el arrya del LocalStorage y devuelve un objeto vacio (Default)
 */

import { del, get, post, put } from "../../api/api";
import {
  getFromLocalStorage,
  updateLocalStorageExpiry,
} from "../../api/helpers";
import { removeArrayItemStorageData } from "../../api/storage";
import { DEFAULT_STATE } from "../../enums/default_state";
import {
  getEmptyStatusIncidencia,
  setIncidencia,
  IIncidencia,
  getSerializableIncidencia,
} from "../../interfaces/nomina";
import { IServerDate } from "../../interfaces/time";
import {
  getStorageIncidenciaArrayItem,
  setStorageIncidencia,
  setStorageIncidenciaArrayItem,
  getStorageIncidenciaArray,
  setStorageIncidenciaArray,
  removeStorageIncidencia,
} from "../../localStorage/nomina";

export const getIncidencia = async (
  serverDate: IServerDate,
  pk: number,
  idCliente: number
) => {
  const temp = getEmptyStatusIncidencia();
  const doHttp = await getFromLocalStorage(serverDate, temp.expiry);
  temp.expiry = doHttp.storage;
  if (!doHttp.result) {
    console.log(`(localStorage) Incidencia con pk: ${pk}, obtenido con éxito`);
    temp.estado = DEFAULT_STATE.success;
    temp.mensaje = `Incidencia obtenida con éxito`;
    temp.data = await getStorageIncidenciaArrayItem(pk);
    return temp;
  }
  const result = await get(`nomina/incidencia/${pk}/${idCliente}`);
  const data = result.data;
  console.log(`Incidencia data: `, data);
  if (!data.id || data.detail) {
    console.log(`Error al obtener Incidencia con pk: ${pk}, ${data.detail}`);
    temp.estado = DEFAULT_STATE.error;
    temp.mensaje = `Error al obtener la datos personales: ${
      data.detail ?? "Hubo un problema al conectarse con el servidor"
    }`;
    temp.data = await getStorageIncidenciaArrayItem(pk);
    return temp;
  }
  console.log(`Incidencia con pk: ${pk}, obtenido con éxito`);
  temp.estado = DEFAULT_STATE.success;
  temp.mensaje = `Incidencia obtenida con éxito`;
  temp.data = await setIncidencia(data, serverDate);
  await setStorageIncidencia(temp.data);
  temp.data_array = await setStorageIncidenciaArrayItem(pk, temp.data);
  temp.expiry = await updateLocalStorageExpiry(serverDate, temp.expiry);
  return temp;
};

export const getAllIncidencia = async (
  serverDate: IServerDate,
  idCliente: number
) => {
  const temp = getEmptyStatusIncidencia();
  const doHttp = await getFromLocalStorage(serverDate, temp.expiry_array);
  temp.expiry_array = doHttp.storage;
  if (!doHttp.result) {
    console.log(`(localStorage) Todos las Incidencias obtenidas con éxito`);
    temp.estado = DEFAULT_STATE.success;
    temp.mensaje = `Todos las Incidencias obtenidas con éxito`;
    temp.data_array = await getStorageIncidenciaArray();
    // return temp;
  }
  const result = await get(`nomina/incidencia/${idCliente}`);
  const data = result.data;
  if (data.detail) {
    console.log(`Error al obtener la lista de Incidencia`, data);
    temp.estado = DEFAULT_STATE.error;
    temp.mensaje = `Error al obtener la lista de Incidencias: ${
      data.detail ?? "Hubo un problema al conectarse con el servidor"
    }`;
    temp.data_array = await getStorageIncidenciaArray();
    return temp;
  }
  console.log(`Todos las Incidencias obtenidos con éxito`);
  temp.estado = DEFAULT_STATE.success;
  temp.mensaje = `Todos las Incidencias obtenidas con éxito`;
  temp.data_array = await Promise.all(
    data.map(async (e: any) => await setIncidencia(e, serverDate))
  );
  await setStorageIncidenciaArray(temp.data_array);
  temp.expiry_array = await updateLocalStorageExpiry(
    serverDate,
    temp.expiry_array
  );
  return temp;
};

export const updateIncidencia = async (
  serverDate: IServerDate,
  pk: number,
  idCliente: number,
  object: IIncidencia
) => {
  const temp = getEmptyStatusIncidencia();
  const result = await put(
    `nomina/incidencia/${pk}/${idCliente}`,
    getSerializableIncidencia(object)
  );
  const data = result.data;
  if (!data.id || data.detail) {
    console.log(`Error al actualizar Incidencia con pk: ${pk}, ${data.detail}`);
    temp.estado = DEFAULT_STATE.error;
    temp.mensaje = `Error al actualizar la datos personales: ${
      data.detail ?? "Hubo un problema al conectarse con el servidor"
    }`;
    temp.data = await setIncidencia(object, serverDate);
    return temp;
  }
  console.log(`Incidencia con pk: ${pk}, actualizado con éxito`);
  temp.estado = DEFAULT_STATE.success;
  temp.mensaje = `Incidencia actualizada con éxito`;
  temp.data = await setIncidencia(data, serverDate);
  await setStorageIncidencia(temp.data);
  temp.data_array = await setStorageIncidenciaArrayItem(
    temp.data.id,
    temp.data
  );
  return temp;
};

export const createIncidencia = async (
  serverDate: IServerDate,
  idCliente: number,
  object: IIncidencia
) => {
  const temp = getEmptyStatusIncidencia();
  const result = await post(
    `nomina/incidencia/${idCliente}`,
    getSerializableIncidencia(object)
  );
  const data = result.data;
  if (!data.id || data.detail) {
    console.log(
      `Error al crear Incidencia con los datos ${object}, ${data.detail}`
    );
    temp.estado = DEFAULT_STATE.error;
    temp.mensaje = `Error al crear la datos personales: ${
      data.detail ?? "Hubo un problema al conectarse con el servidor"
    }`;
    temp.data = await setIncidencia(object, serverDate);
    return temp;
  }
  console.log(`Incidencia creada con éxito`);
  temp.estado = DEFAULT_STATE.success;
  temp.mensaje = `Incidencia creada con éxito`;
  temp.data = await setIncidencia(data, serverDate);
  await setStorageIncidencia(temp.data);
  temp.data_array = await setStorageIncidenciaArrayItem(
    temp.data.id,
    temp.data
  );
  return temp;
};

export const deleteIncidencia = async (pk: number, idCliente: number) => {
  const temp = getEmptyStatusIncidencia();
  const result = await del(`nomina/incidencia/${pk}/${idCliente}`);
  const data = result.data;
  if (data.detail) {
    console.log(`Error al eliminar Incidencia con pk: ${pk}, ${data.detail}`);
    temp.estado = DEFAULT_STATE.error;
    temp.mensaje = `Error al eliminar la datos personales: ${data.detail}`;
    temp.data = await getStorageIncidenciaArrayItem(pk);
    temp.data_array = await getStorageIncidenciaArray();
    return temp;
  }
  console.log(`Incidencia eliminada con éxito`);
  temp.estado = DEFAULT_STATE.success;
  temp.mensaje = `Incidencia eliminada con éxito`;
  await removeStorageIncidencia();
  temp.data_array = await removeArrayItemStorageData("IIncidencia", pk, []);
  return temp;
};
