/**
 * Métodos para el manejo de IonicStorage 
 * @setStorage              guarda un unico objeto al storage
 * @getStorage              obtiene el objeto guardado con @setStorage
 * @setStorageArray         guarda el array de objetos en el storage
 * @getStorageArray         obtiene el array de objetos guardado con @setStorageArray
 * @removeStorageArray      elimina el array de objetos guardado con @setStorageArray
 * @setStorageItemArray     agrega un elemento al array de objeto guardado con @setStorageArray
 * @getStorageItemArray     obtiene un elemento del array guardado con @setStorageArray buscandolo por su id 
 * @removeStorageItemArray  elimina un elemento del array guardado con @setStorageArray buscandolo por su id 
 */

import { setStorageData, getStorageData, removeStorageData, setArrayItemStorageData, getArrayItemStorageData, removeArrayItemStorageData } from "../../api/storage";
import { ICodigoQr, getEmptyCodigoQr } from "../../interfaces/personas";

export const setStorageCodigoQr = async (ICodigoQr : ICodigoQr) => await setStorageData('ICodigoQr', ICodigoQr);
export const getStorageCodigoQr = async () : Promise<ICodigoQr> => await getStorageData('ICodigoQr', getEmptyCodigoQr());
export const removeStorageCodigoQr = async () => await removeStorageData('ICodigoQr');
export const setStorageCodigoQrArray = async (ICodigoQr : ICodigoQr[]) => await setStorageData('ICodigoQrArray', ICodigoQr);
export const getStorageCodigoQrArray = async () : Promise<ICodigoQr[]> => await getStorageData('ICodigoQrArray', [] as ICodigoQr[]);
export const removeStorageCodigoQrArray = async () => await removeStorageData('ICodigoQrArray');
export const setStorageCodigoQrArrayItem = async (id: number, ICodigoQr : ICodigoQr) : Promise<ICodigoQr[]> => await setArrayItemStorageData('ICodigoQrArray', id, ICodigoQr, [] as ICodigoQr[]);
export const getStorageCodigoQrArrayItem = async (id: number) : Promise<ICodigoQr> => await getArrayItemStorageData('ICodigoQrArray', id, getEmptyCodigoQr);
export const removeStorageCodigoQrArrayItem = async (id: number) : Promise<ICodigoQr[]> => await removeArrayItemStorageData('ICodigoQrArray', id, [] as ICodigoQr[]);
