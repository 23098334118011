/**
 * Métodos para el manejo de IonicStorage 
 * @setStorage              guarda un unico objeto al storage
 * @getStorage              obtiene el objeto guardado con @setStorage
 * @setStorageArray         guarda el array de objetos en el storage
 * @getStorageArray         obtiene el array de objetos guardado con @setStorageArray
 * @removeStorageArray      elimina el array de objetos guardado con @setStorageArray
 * @setStorageItemArray     agrega un elemento al array de objeto guardado con @setStorageArray
 * @getStorageItemArray     obtiene un elemento del array guardado con @setStorageArray buscandolo por su id 
 * @removeStorageItemArray  elimina un elemento del array guardado con @setStorageArray buscandolo por su id 
 */

import { setStorageData, getStorageData, removeStorageData, setArrayItemStorageData, getArrayItemStorageData, removeArrayItemStorageData } from "../../api/storage";
import { IDocumento, getEmptyDocumento } from "../../interfaces/personas";

export const setStorageDocumento = async (IDocumento : IDocumento) => await setStorageData('IDocumento', IDocumento);
export const getStorageDocumento = async () : Promise<IDocumento> => await getStorageData('IDocumento', getEmptyDocumento());
export const removeStorageDocumento = async () => await removeStorageData('IDocumento');
export const setStorageDocumentoArray = async (IDocumento : IDocumento[]) => await setStorageData('IDocumentoArray', IDocumento);
export const getStorageDocumentoArray = async () : Promise<IDocumento[]> => await getStorageData('IDocumentoArray', [] as IDocumento[]);
export const removeStorageDocumentoArray = async () => await removeStorageData('IDocumentoArray');
export const setStorageDocumentoArrayItem = async (id: number, IDocumento : IDocumento) : Promise<IDocumento[]> => await setArrayItemStorageData('IDocumentoArray', id, IDocumento, [] as IDocumento[]);
export const getStorageDocumentoArrayItem = async (id: number) : Promise<IDocumento> => await getArrayItemStorageData('IDocumentoArray', id, getEmptyDocumento);
export const removeStorageDocumentoArrayItem = async (id: number) : Promise<IDocumento[]> => await removeArrayItemStorageData('IDocumentoArray', id, [] as IDocumento[]);
