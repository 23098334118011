/**
 * Métodos para el manejo de IonicStorage 
 * @setStorage              guarda un unico objeto al storage
 * @getStorage              obtiene el objeto guardado con @setStorage
 * @setStorageArray         guarda el array de objetos en el storage
 * @getStorageArray         obtiene el array de objetos guardado con @setStorageArray
 * @removeStorageArray      elimina el array de objetos guardado con @setStorageArray
 * @setStorageItemArray     agrega un elemento al array de objeto guardado con @setStorageArray
 * @getStorageItemArray     obtiene un elemento del array guardado con @setStorageArray buscandolo por su id 
 * @removeStorageItemArray  elimina un elemento del array guardado con @setStorageArray buscandolo por su id 
 */

import { setStorageData, getStorageData, removeStorageData, setArrayItemStorageData, getArrayItemStorageData, removeArrayItemStorageData } from "../../api/storage";
import { IMvTipoAsistencia, getEmptyMvTipoAsistencia } from "../../interfaces/nomina";

export const setStorageMvTipoAsistencia = async (IMvTipoAsistencia : IMvTipoAsistencia) => await setStorageData('IMvTipoAsistencia', IMvTipoAsistencia);
export const getStorageMvTipoAsistencia = async () : Promise<IMvTipoAsistencia> => await getStorageData('IMvTipoAsistencia', getEmptyMvTipoAsistencia());
export const removeStorageMvTipoAsistencia = async () => await removeStorageData('IMvTipoAsistencia');
export const setStorageMvTipoAsistenciaArray = async (IMvTipoAsistencia : IMvTipoAsistencia[]) => await setStorageData('IMvTipoAsistenciaArray', IMvTipoAsistencia);
export const getStorageMvTipoAsistenciaArray = async () : Promise<IMvTipoAsistencia[]> => await getStorageData('IMvTipoAsistenciaArray', [] as IMvTipoAsistencia[]);
export const removeStorageMvTipoAsistenciaArray = async () => await removeStorageData('IMvTipoAsistenciaArray');
export const setStorageMvTipoAsistenciaArrayItem = async (id: number, IMvTipoAsistencia : IMvTipoAsistencia) : Promise<IMvTipoAsistencia[]> => await setArrayItemStorageData('IMvTipoAsistenciaArray', id, IMvTipoAsistencia, [] as IMvTipoAsistencia[]);
export const getStorageMvTipoAsistenciaArrayItem = async (id: number) : Promise<IMvTipoAsistencia> => await getArrayItemStorageData('IMvTipoAsistenciaArray', id, getEmptyMvTipoAsistencia);
export const removeStorageMvTipoAsistenciaArrayItem = async (id: number) : Promise<IMvTipoAsistencia[]> => await removeArrayItemStorageData('IMvTipoAsistenciaArray', id, [] as IMvTipoAsistencia[]);
