/**
 * Métodos AsyncThunk para ser usados en los reducers y
 * los resultados a las llamadas http puedan ser usados para
 * actualizar el state en redux
 */

import { createAsyncThunk } from "@reduxjs/toolkit";
import { DEFAULT_STATE } from "../../enums/default_state";
import { getCheckEnRuta, getAllCheckEnRuta, updateCheckEnRuta, createCheckEnRuta, deleteCheckEnRuta } from "../../http/rondines";
import { getEmptyStatusCheckEnRuta, ICheckEnRuta } from "../../interfaces/rondines";
import { IServerDate } from "../../interfaces/time";

export const getCheckEnRutaAsyncThunk = createAsyncThunk(
  'rondines/getCheckEnRutaAsyncThunk',
  async (query : {serverDate : IServerDate, id: number}) => await getCheckEnRuta(query.serverDate, query.id)
    .then(async (result) => result)
    .catch(async (error) => getEmptyStatusCheckEnRuta(DEFAULT_STATE.error))  
);

export const getAllCheckEnRutaAsyncThunk = createAsyncThunk(
  'rondines/getAllCheckEnRutaAsyncThunk',
  async (queryAll : {serverDate : IServerDate, idCliente : number}) => await getAllCheckEnRuta(queryAll.serverDate, queryAll.idCliente)
    .then(async (result) => result)
    .catch(async (error) => getEmptyStatusCheckEnRuta(DEFAULT_STATE.error))  
);

export const updateCheckEnRutaAsyncThunk = createAsyncThunk(
  'rondines/updateCheckEnRutaAsyncThunk',
  async (update: {serverDate : IServerDate, id: number, CheckEnRuta: ICheckEnRuta}) => await updateCheckEnRuta(update.serverDate, update.id, update.CheckEnRuta)
    .then(async (result) => result)
    .catch(async (error) => getEmptyStatusCheckEnRuta(DEFAULT_STATE.error))  
);

export const createCheckEnRutaAsyncThunk = createAsyncThunk(
  'rondines/createCheckEnRutaAsyncThunk',
  async (create : {serverDate : IServerDate, idCliente : number, CheckEnRuta: ICheckEnRuta}) => await createCheckEnRuta(create.serverDate, create.idCliente, create.CheckEnRuta)
    .then(async (result) => result)
    .catch(async (error) => getEmptyStatusCheckEnRuta(DEFAULT_STATE.error))  
);

export const deleteCheckEnRutaAsyncThunk = createAsyncThunk(
  'rondines/deleteCheckEnRutaAsyncThunk',
  async (del : {id : number}) => await deleteCheckEnRuta(del.id)
    .then(async (result) => result)
    .catch(async (error) => getEmptyStatusCheckEnRuta())  
);
