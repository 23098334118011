/**
 * Métodos del, get, post, put para llamadas a la API
 * @get     en este método primero consulta con @getFromLocalStorage si es necesario hacer la petición http sino se obtiene del LocalStorage;
 *          si hay un error en la petición http se busca el objeto en el LocalStorage sino lo encuentra devuelve un objeto vacío; si la
 *          petición se realiza correctamente actualiza el valor individual y en el array del objeto en el LocalStorage y devuelve el objeto
 *          obtenido del servidor.
 * @getAll  en este método primero consulta con @getFromLocalStorage si es necesario hacer la petición http sino se obtiene del LocalStorage;
 *          si hay un error en la petición http se busca el objeto array en el LocalStorage sino lo encuentra devuelve un array vacío; si la
 *          petición se realiza correctamente actualiza array en el LocalStorage y devuelve el array obtenido del servidor
 * @update  en este método después de realizar la petición http si falla devuelve el objeto que se iba a actualizar; si la petición se
 *          realiza correctamente actualiza el valor individual y en el array del objeto en el LocalStorage y devuelve el objeto obtenido del
 *          servidor.
 * @create  en este método después de realizar la petición http si falla devuelve el objeto que se iba a crear; si la petición se realiza 
 *          correctamente actualiza el valor individual y en el array del objeto en el LocalStorage y devuelve el objeto obtenido del
 *          servidor.
 * @delete  en este método después de realizar la petición http si falla devuelve el objeto que se iba a eliminar; si la petición se realiza 
 *          correctamente elimina el valor individual y en el objeto en el arrya del LocalStorage y devuelve un objeto vacio (Default)
 */

import { del, get, post, put } from "../../api/api";
import { getFromLocalStorage, updateLocalStorageExpiry } from "../../api/helpers";
import { removeArrayItemStorageData } from "../../api/storage";
import { DEFAULT_STATE } from "../../enums/default_state";
import { getEmptyStatusCheckRondin, setCheckRondin, ICheckRondin, getSerializableCheckRondin } from "../../interfaces/rondines";
import { IServerDate } from "../../interfaces/time";
import { getStorageCheckRondinArrayItem, setStorageCheckRondin, setStorageCheckRondinArrayItem, getStorageCheckRondinArray, setStorageCheckRondinArray, removeStorageCheckRondin } from "../../localStorage/rondines";

export const getCheckRondin = async (serverDate: IServerDate, pk : number) => {
  const temp = getEmptyStatusCheckRondin();
  const doHttp = await getFromLocalStorage(serverDate, temp.expiry);
  temp.expiry = doHttp.storage;
  if (!doHttp.result) {
    console.log(`(localStorage) CheckRondin con pk: ${pk}, obtenido con éxito`);
    temp.estado = DEFAULT_STATE.success;
    temp.mensaje = `CheckRondin obtenido con éxito`;
    temp.data = await getStorageCheckRondinArrayItem(pk);
    return temp;
  }
  const result = await get(`rondines/check-rondin/${pk}`);
  const data = result.data;
  console.log(`CheckRondin data: `, data);
  if (!data.id || data.detail) {
    console.log(`Error al obtener CheckRondin con pk: ${pk}, ${data.detail}`);
    temp.estado = DEFAULT_STATE.error;
    temp.mensaje =  `Error al obtener el CheckRondin: ${data.detail ?? "Hubo un problema al conectarse con el servidor"}`;
    temp.data = await getStorageCheckRondinArrayItem(pk);
    return temp;
  }
  console.log(`CheckRondin con pk: ${pk}, obtenido con éxito`);
  temp.estado = DEFAULT_STATE.success;
  temp.mensaje = `CheckRondin obtenido con éxito`;
  temp.data = await setCheckRondin(data, serverDate);
  await setStorageCheckRondin(temp.data);
  temp.data_array = await setStorageCheckRondinArrayItem(pk, temp.data);
  temp.expiry = await updateLocalStorageExpiry(serverDate, temp.expiry);
  return temp;
}

export const getAllCheckRondin = async (serverDate: IServerDate, idCliente : number) => {
  const temp = getEmptyStatusCheckRondin();
  const doHttp = await getFromLocalStorage(serverDate, temp.expiry_array);
  temp.expiry_array = doHttp.storage;
  if (!doHttp.result) {
    console.log(`(localStorage) Todos los CheckRondins obtenidos con éxito`);
    temp.estado = DEFAULT_STATE.success;
    temp.mensaje = `Todos los CheckRondin obtenidos con éxito`;
    temp.data_array = await getStorageCheckRondinArray();
  }
  const result = await get(`rondines/check-rondin/${idCliente}`);
  const data = result.data;
  if (data.detail) {
    console.log(`Error al obtener la lista de CheckRondin, ${data.detail}`);
    temp.estado = DEFAULT_STATE.error;
    temp.mensaje = `Error al obtener la lista de CheckRondin: ${data.detail ?? "Hubo un problema al conectarse con el servidor"}`;
    temp.data_array = await getStorageCheckRondinArray();
    return temp;
  }
  console.log(`Todos los CheckRondin obtenidos con éxito`);
  temp.estado = DEFAULT_STATE.success;
  temp.mensaje = `Todos los CheckRondin obtenidos con éxito`;
  temp.data_array = await Promise.all(data.map(async (e : any) => await setCheckRondin(e, serverDate)));
  await setStorageCheckRondinArray(temp.data_array);
  temp.expiry_array = await updateLocalStorageExpiry(serverDate, temp.expiry_array);
  return temp;
}

export const updateCheckRondin = async (serverDate: IServerDate, pk : number, object : ICheckRondin) => {
  const temp = getEmptyStatusCheckRondin();
  const result = await put(`rondines/check-rondin/${pk}`, getSerializableCheckRondin(object));
  const data = result.data;
  if (!data.id || data.detail) {
    console.log(`Error al actualizar CheckRondin con pk: ${pk}, ${data.detail}`);
    temp.estado = DEFAULT_STATE.error;
    temp.mensaje = `Error al actualizar el CheckRondin: ${data.detail ?? "Hubo un problema al conectarse con el servidor"}`;
    temp.data = await setCheckRondin(object, serverDate);
    return temp;
  }
  console.log(`CheckRondin con pk: ${pk}, actualizado con éxito`);
  temp.estado = DEFAULT_STATE.success;
  temp.mensaje = `CheckRondin actualizado con éxito`;
  temp.data = await setCheckRondin(data, serverDate);
  await setStorageCheckRondin(temp.data);
  temp.data_array = await setStorageCheckRondinArrayItem(temp.data.id, temp.data);
  return temp;
}

export const createCheckRondin = async (serverDate: IServerDate, idCliente : number, object : ICheckRondin) => {
  const temp = getEmptyStatusCheckRondin();
  const result = await post(`rondines/check-rondin/${idCliente}`, getSerializableCheckRondin(object));
  const data = result.data;
  if (!data.id || data.detail) {
    console.log(`Error al crear CheckRondin con los datos ${object}, ${data.detail}`);
    temp.estado = DEFAULT_STATE.error;
    temp.mensaje = `Error al crear el CheckRondin: ${data.detail ?? "Hubo un problema al conectarse con el servidor"}`;
    temp.data = await setCheckRondin(object, serverDate);
    return temp;
  }
  console.log(`CheckRondin creado con éxito`);
  temp.estado = DEFAULT_STATE.success;
  temp.mensaje = `CheckRondin creado con éxito`;
  temp.data = await setCheckRondin(data, serverDate);
  await setStorageCheckRondin(temp.data);
  temp.data_array = await setStorageCheckRondinArrayItem(temp.data.id, temp.data);
  return temp;
}

export const deleteCheckRondin = async (pk : number) => {
  const temp = getEmptyStatusCheckRondin();
  const result = await del(`rondines/check-rondin/${pk}`);
  const data = result.data;
  if (data.detail) {
    console.log(`Error al eliminar CheckRondin con pk: ${pk}, ${data.detail}`);
    temp.estado = DEFAULT_STATE.error;
    temp.mensaje = `Error al eliminar el datos fiscales: ${data.detail}`;
    temp.data = await getStorageCheckRondinArrayItem(pk);
    temp.data_array = await getStorageCheckRondinArray();
    return temp;
  }
  console.log(`CheckRondin eliminado con éxito`);
  temp.estado = DEFAULT_STATE.success;
  temp.mensaje = `CheckRondin eliminado con éxito`;
  await removeStorageCheckRondin();
  temp.data_array = await removeArrayItemStorageData("ICheckRondin", pk, []);
  return temp;
}
