/**
 * Métodos para el manejo de IonicStorage 
 * @setStorage              guarda un unico objeto al storage
 * @getStorage              obtiene el objeto guardado con @setStorage
 * @setStorageArray         guarda el array de objetos en el storage
 * @getStorageArray         obtiene el array de objetos guardado con @setStorageArray
 * @removeStorageArray      elimina el array de objetos guardado con @setStorageArray
 * @setStorageItemArray     agrega un elemento al array de objeto guardado con @setStorageArray
 * @getStorageItemArray     obtiene un elemento del array guardado con @setStorageArray buscandolo por su id 
 * @removeStorageItemArray  elimina un elemento del array guardado con @setStorageArray buscandolo por su id 
 */

import { setStorageData, getStorageData, removeStorageData, setArrayItemStorageData, getArrayItemStorageData, removeArrayItemStorageData } from "../../api/storage";
import { ICentroDeTrabajo, getEmptyCentroDeTrabajo } from "../../interfaces/personas";

export const setStorageCentroDeTrabajo = async (ICentroDeTrabajo : ICentroDeTrabajo) => await setStorageData('ICentroDeTrabajo', ICentroDeTrabajo);
export const getStorageCentroDeTrabajo = async () : Promise<ICentroDeTrabajo> => await getStorageData('ICentroDeTrabajo', getEmptyCentroDeTrabajo());
export const removeStorageCentroDeTrabajo = async () => await removeStorageData('ICentroDeTrabajo');
export const setStorageCentroDeTrabajoArray = async (ICentroDeTrabajo : ICentroDeTrabajo[]) => await setStorageData('ICentroDeTrabajoArray', ICentroDeTrabajo);
export const getStorageCentroDeTrabajoArray = async () : Promise<ICentroDeTrabajo[]> => await getStorageData('ICentroDeTrabajoArray', [] as ICentroDeTrabajo[]);
export const removeStorageCentroDeTrabajoArray = async () => await removeStorageData('ICentroDeTrabajoArray');
export const setStorageCentroDeTrabajoArrayItem = async (id: number, ICentroDeTrabajo : ICentroDeTrabajo) : Promise<ICentroDeTrabajo[]> => await setArrayItemStorageData('ICentroDeTrabajoArray', id, ICentroDeTrabajo, [] as ICentroDeTrabajo[]);
export const getStorageCentroDeTrabajoArrayItem = async (id: number) : Promise<ICentroDeTrabajo> => await getArrayItemStorageData('ICentroDeTrabajoArray', id, getEmptyCentroDeTrabajo);
export const removeStorageCentroDeTrabajoArrayItem = async (id: number) : Promise<ICentroDeTrabajo[]> => await removeArrayItemStorageData('ICentroDeTrabajoArray', id, [] as ICentroDeTrabajo[]);
