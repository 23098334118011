/**
 * Métodos AsyncThunk para ser usados en los reducers y
 * los resultados a las llamadas http puedan ser usados para
 * actualizar el state en redux
 */

import { createAsyncThunk } from "@reduxjs/toolkit";
import { DEFAULT_STATE } from "../../enums/default_state";
import { getMvMotivoPagoSuscripcion, getAllMvMotivoPagoSuscripcion, updateMvMotivoPagoSuscripcion, createMvMotivoPagoSuscripcion, deleteMvMotivoPagoSuscripcion } from "../../http/personas";
import { getEmptyStatusMvMotivoPagoSuscripcion, IMvMotivoPagoSuscripcion } from "../../interfaces/personas";
import { IServerDate } from "../../interfaces/time";

export const getMvMotivoPagoSuscripcionAsyncThunk = createAsyncThunk(
  'personas/getMvMotivoPagoSuscripcionAsyncThunk',
  async (query : {serverDate : IServerDate, id: number}) => await getMvMotivoPagoSuscripcion(query.serverDate, query.id)
    .then(async (result) => result)
    .catch(async (error) => getEmptyStatusMvMotivoPagoSuscripcion(DEFAULT_STATE.error))  
);

export const getAllMvMotivoPagoSuscripcionAsyncThunk = createAsyncThunk(
  'personas/getAllMvMotivoPagoSuscripcionAsyncThunk',
  async (queryAll : {serverDate : IServerDate}) => await getAllMvMotivoPagoSuscripcion(queryAll.serverDate)
    .then(async (result) => result)
    .catch(async (error) => getEmptyStatusMvMotivoPagoSuscripcion(DEFAULT_STATE.error))  
);

export const updateMvMotivoPagoSuscripcionAsyncThunk = createAsyncThunk(
  'personas/updateMvMotivoPagoSuscripcionAsyncThunk',
  async (update: {serverDate : IServerDate, id: number, MvMotivoPagoSuscripcion: IMvMotivoPagoSuscripcion}) => await updateMvMotivoPagoSuscripcion(update.serverDate, update.id, update.MvMotivoPagoSuscripcion)
    .then(async (result) => result)
    .catch(async (error) => getEmptyStatusMvMotivoPagoSuscripcion(DEFAULT_STATE.error))  
);

export const createMvMotivoPagoSuscripcionAsyncThunk = createAsyncThunk(
  'personas/createMvMotivoPagoSuscripcionAsyncThunk',
  async (create : {serverDate : IServerDate, MvMotivoPagoSuscripcion: IMvMotivoPagoSuscripcion}) => await createMvMotivoPagoSuscripcion(create.serverDate, create.MvMotivoPagoSuscripcion)
    .then(async (result) => result)
    .catch(async (error) => getEmptyStatusMvMotivoPagoSuscripcion(DEFAULT_STATE.error))  
);

export const deleteMvMotivoPagoSuscripcionAsyncThunk = createAsyncThunk(
  'personas/deleteMvMotivoPagoSuscripcionAsyncThunk',
  async (del : {id : number}) => await deleteMvMotivoPagoSuscripcion(del.id)
    .then(async (result) => result)
    .catch(async (error) => getEmptyStatusMvMotivoPagoSuscripcion())  
);
