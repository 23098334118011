
import { post } from "../../api/api";
import { isNotEmpty } from "../../api/helpers";
import { getStorageData, setStorageData } from "../../api/storage";
import { getDatosPersonales } from "../../http/personas";
import { getEmptyEmpleado, IEmpleado, setEmpleado } from "../personas";
import { IServerDate } from "../time";
import { IAuthException } from "./IAuthException";
import { IFirebaseUser } from "./IFirebaseUser";

export enum AuthState {
  offline,
  online,
  loading,
};

export interface IAuth {
  isLoggedIn: AuthState;
  empleado: IEmpleado,
  user?: IFirebaseUser | null;
  error?: IAuthException;
};

export const getDefaultIAuth = (state?: AuthState, user?: IFirebaseUser, setStorage?: boolean) => {
  const temp: IAuth = 
  {
    isLoggedIn: state ? state : AuthState.offline,
    user: user,
    empleado: getEmptyEmpleado(),
  };
  return temp;
};

export const clearIAuthStorage = async () => {
  await setStorageIAuth(getDefaultIAuth())
}

export const getResultIAuth = async (user: IFirebaseUser | null, serverDate: IServerDate) => {
  const temp: IAuth = 
  {
    isLoggedIn: AuthState.offline,
    user: user,
    empleado: getEmptyEmpleado(),
  };
  if (!user) {
    await setStorageIAuth(temp);
    return temp;
  }

  const resultEmpleado = await post("personas/helpers/empleado-from-token");
  if (!resultEmpleado.data || !resultEmpleado.data.id || resultEmpleado.data.tipo_empleado === 2) {
    delete temp.user;
    temp.error = {
      code: "0",
      message: "No tiene acceso al sistema",
      name: "",
    } as IAuthException;
    await setStorageIAuth(temp);
    return temp;
  }
  temp.isLoggedIn = AuthState.online;

  temp.empleado = await setEmpleado(resultEmpleado.data, serverDate);

  const resultDatosPersonales = await getDatosPersonales(serverDate, temp.empleado.datos_personales.id, temp.empleado.datos_personales.cliente_qentry.id);

  if (resultDatosPersonales.data.id) {
    temp.empleado.datos_personales = resultDatosPersonales.data;
  }
  
  await setStorageIAuth(temp);
  return temp;
};


export const getErrorIAuth = async (error: IAuthException, user?: IFirebaseUser | null, iAuth?: IAuth) => {
  const temp: IAuth = 
  {
    isLoggedIn: (user) ? AuthState.online : AuthState.offline,
    user: user,
    error: error,
    empleado: (iAuth) ? iAuth.empleado : getEmptyEmpleado(),
  };
  return temp;
};

export const setStorageIAuth = async (IAuth: IAuth) => await setStorageData('IAuth', IAuth);

export const getStorageIAuth  = async () : Promise<IAuth> => await getStorageData('IAuth', getDefaultIAuth());

export const getIfExistIAuth = async () => { 
  const storageIAuth = await getStorageIAuth();
  if (isNotEmpty(storageIAuth)) return storageIAuth;
  return getDefaultIAuth();
};
