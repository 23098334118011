interface Lang {
  [key: string]: {
    "es" : string,
    "en" : string;
    [key: string]: string,  
  };
}

export const LoginMesages: Lang = {
  'PhoneInput': {es: "Teléfono", en: "Phone"},
  'CodeInput': {es: "Código", en: "Code"},
  'EmailInput': {es: "Correo", en: "Email"},
  'PasswordInput': {es: "Contraseña", en: "Password"},
  'PhoneInputError': {es: "Por favor escriba un número de teléfono", en: "Invalid phone number"},
  'CodeInputError': {es: "Por favor escriba el código", en: "Invalid Code"},
  'LoginButton': {es: "Iniciar Sesión", en: "Log In"},
  'LoginPhoneButton': {es: "Veficar código", en: "Verify code"},
  'SendCodeButton': {es: "Error al envíar el código", en: "Error sending de SMS"},
  'SMSError': {es: "Error al enviar el mensaje", en: "SMS not sent"},
  'LoginError': {es: "Correo o contraseña incorrectos", en: "Email or password incorrect"},
  'PhoneNotExist': {es: "El télefono no esta registrado", en: "Incorrect phone"},
  'LoginPhoneError': {es: "Código incorrecto", en: "Incorrect code"},
  'PasswordChangeMessage': {es: "¿Has olvidado la contraseña?", en: "Forgot password?"},
  'PasswordChangeButton': {es: "¡Entra aquí!", en: "Click here!"},
  'ToPhoneLogin': {es: "Ingresar con teléfono", en: "Phone Login"},
  'ToEmailLogin': {es: "Ingresar con correo", en: "Email Login"},
};
