/**
 * Métodos del, get, post, put para llamadas a la API
 * @get     en este método primero consulta con @getFromLocalStorage si es necesario hacer la petición http sino se obtiene del LocalStorage;
 *          si hay un error en la petición http se busca el objeto en el LocalStorage sino lo encuentra devuelve un objeto vacío; si la
 *          petición se realiza correctamente actualiza el valor individual y en el array del objeto en el LocalStorage y devuelve el objeto
 *          obtenido del servidor.
 * @getAll  en este método primero consulta con @getFromLocalStorage si es necesario hacer la petición http sino se obtiene del LocalStorage;
 *          si hay un error en la petición http se busca el objeto array en el LocalStorage sino lo encuentra devuelve un array vacío; si la
 *          petición se realiza correctamente actualiza array en el LocalStorage y devuelve el array obtenido del servidor
 * @update  en este método después de realizar la petición http si falla devuelve el objeto que se iba a actualizar; si la petición se
 *          realiza correctamente actualiza el valor individual y en el array del objeto en el LocalStorage y devuelve el objeto obtenido del
 *          servidor.
 * @create  en este método después de realizar la petición http si falla devuelve el objeto que se iba a crear; si la petición se realiza
 *          correctamente actualiza el valor individual y en el array del objeto en el LocalStorage y devuelve el objeto obtenido del
 *          servidor.
 * @delete  en este método después de realizar la petición http si falla devuelve el objeto que se iba a eliminar; si la petición se realiza
 *          correctamente elimina el valor individual y en el objeto en el arrya del LocalStorage y devuelve un objeto vacio (Default)
 */

import { del, get, post, put } from "../../api/api";
import {
  getFromLocalStorage,
  updateLocalStorageExpiry,
} from "../../api/helpers";
import { removeArrayItemStorageData } from "../../api/storage";
import { DEFAULT_STATE } from "../../enums/default_state";
import {
  getEmptyStatusTurno,
  setTurno,
  ITurno,
  getSerializableTurno,
} from "../../interfaces/nomina";
import { IServerDate } from "../../interfaces/time";
import {
  getStorageTurnoArrayItem,
  setStorageTurno,
  setStorageTurnoArrayItem,
  getStorageTurnoArray,
  setStorageTurnoArray,
  removeStorageTurno,
} from "../../localStorage/nomina";

export const getTurno = async (
  serverDate: IServerDate,
  pk: number,
  idCliente: number
) => {
  const temp = getEmptyStatusTurno();
  const doHttp = await getFromLocalStorage(serverDate, temp.expiry);
  temp.expiry = doHttp.storage;
  if (!doHttp.result) {
    console.log(`(localStorage) Turno con pk: ${pk}, obtenido con éxito`);
    temp.estado = DEFAULT_STATE.success;
    temp.mensaje = `Turno obtenida con éxito`;
    temp.data = await getStorageTurnoArrayItem(pk);
    return temp;
  }
  const result = await get(`nomina/turno/${pk}/${idCliente}`);
  const data = result.data;
  console.log(`Turno data: `, data);
  if (!data.id || data.detail) {
    console.log(`Error al obtener Turno con pk: ${pk}, ${data.detail}`);
    temp.estado = DEFAULT_STATE.error;
    temp.mensaje = `Error al obtener la datos personales: ${
      data.detail ?? "Hubo un problema al conectarse con el servidor"
    }`;
    temp.data = await getStorageTurnoArrayItem(pk);
    return temp;
  }
  console.log(`Turno con pk: ${pk}, obtenido con éxito`);
  temp.estado = DEFAULT_STATE.success;
  temp.mensaje = `Turno obtenida con éxito`;
  temp.data = await setTurno(data, serverDate);
  await setStorageTurno(temp.data);
  temp.data_array = await setStorageTurnoArrayItem(pk, temp.data);
  temp.expiry = await updateLocalStorageExpiry(serverDate, temp.expiry);
  return temp;
};

export const getAllTurno = async (
  serverDate: IServerDate,
  idCliente: number
) => {
  const temp = getEmptyStatusTurno();
  const doHttp = await getFromLocalStorage(serverDate, temp.expiry_array);
  temp.expiry_array = doHttp.storage;
  if (!doHttp.result) {
    console.log(`(localStorage) Todos las Turnos obtenidas con éxito`);
    temp.estado = DEFAULT_STATE.success;
    temp.mensaje = `Todos las Turnos obtenidas con éxito`;
    temp.data_array = await getStorageTurnoArray();
    // return temp;
  }
  const result = await get(`nomina/turno/${idCliente}`);
  const data = result.data;
  if (data.detail) {
    console.log(`Error al obtener la lista de Turno`, data);
    temp.estado = DEFAULT_STATE.error;
    temp.mensaje = `Error al obtener la lista de Turnos: ${
      data.detail ?? "Hubo un problema al conectarse con el servidor"
    }`;
    temp.data_array = await getStorageTurnoArray();
    return temp;
  }
  console.log(`Todos las Turnos obtenidos con éxito`);
  temp.estado = DEFAULT_STATE.success;
  temp.mensaje = `Todos las Turnos obtenidas con éxito`;
  temp.data_array = await Promise.all(
    data.map(async (e: any) => await setTurno(e, serverDate))
  );
  await setStorageTurnoArray(temp.data_array);
  temp.expiry_array = await updateLocalStorageExpiry(
    serverDate,
    temp.expiry_array
  );
  return temp;
};

export const updateTurno = async (
  serverDate: IServerDate,
  pk: number,
  idCliente: number,
  object: ITurno
) => {
  const temp = getEmptyStatusTurno();
  const result = await put(
    `nomina/turno/${pk}/${idCliente}`,
    getSerializableTurno(object)
  );
  const data = result.data;
  if (!data.id || data.detail) {
    console.log(`Error al actualizar Turno con pk: ${pk}, ${data.detail}`);
    temp.estado = DEFAULT_STATE.error;
    temp.mensaje = `Error al actualizar la datos personales: ${
      data.detail ?? "Hubo un problema al conectarse con el servidor"
    }`;
    temp.data = await setTurno(object, serverDate);
    return temp;
  }
  console.log(`Turno con pk: ${pk}, actualizado con éxito`);
  temp.estado = DEFAULT_STATE.success;
  temp.mensaje = `Turno actualizada con éxito`;
  temp.data = await setTurno(data, serverDate);
  await setStorageTurno(temp.data);
  temp.data_array = await setStorageTurnoArrayItem(temp.data.id, temp.data);
  return temp;
};

export const createTurno = async (
  serverDate: IServerDate,
  idCliente: number,
  object: ITurno
) => {
  const temp = getEmptyStatusTurno();
  const result = await post(
    `nomina/turno/${idCliente}`,
    getSerializableTurno(object)
  );
  const data = result.data;
  if (!data.id || data.detail) {
    console.log(`Error al crear Turno con los datos ${object}, ${data.detail}`);
    temp.estado = DEFAULT_STATE.error;
    temp.mensaje = `Error al crear la datos personales: ${
      data.detail ?? "Hubo un problema al conectarse con el servidor"
    }`;
    temp.data = await setTurno(object, serverDate);
    return temp;
  }
  console.log(`Turno creada con éxito`);
  temp.estado = DEFAULT_STATE.success;
  temp.mensaje = `Turno creada con éxito`;
  temp.data = await setTurno(data, serverDate);
  await setStorageTurno(temp.data);
  temp.data_array = await setStorageTurnoArrayItem(temp.data.id, temp.data);
  return temp;
};

export const deleteTurno = async (pk: number, idCliente: number) => {
  const temp = getEmptyStatusTurno();
  const result = await del(`nomina/turno/${pk}/${idCliente}`);
  const data = result.data;
  if (data.detail) {
    console.log(`Error al eliminar Turno con pk: ${pk}, ${data.detail}`);
    temp.estado = DEFAULT_STATE.error;
    temp.mensaje = `Error al eliminar la datos personales: ${data.detail}`;
    temp.data = await getStorageTurnoArrayItem(pk);
    temp.data_array = await getStorageTurnoArray();
    return temp;
  }
  console.log(`Turno eliminada con éxito`);
  temp.estado = DEFAULT_STATE.success;
  temp.mensaje = `Turno eliminada con éxito`;
  await removeStorageTurno();
  temp.data_array = await removeArrayItemStorageData("ITurno", pk, []);
  return temp;
};
