/**
 * Métodos para el manejo de IonicStorage 
 * @setStorage              guarda un unico objeto al storage
 * @getStorage              obtiene el objeto guardado con @setStorage
 * @setStorageArray         guarda el array de objetos en el storage
 * @getStorageArray         obtiene el array de objetos guardado con @setStorageArray
 * @removeStorageArray      elimina el array de objetos guardado con @setStorageArray
 * @setStorageItemArray     agrega un elemento al array de objeto guardado con @setStorageArray
 * @getStorageItemArray     obtiene un elemento del array guardado con @setStorageArray buscandolo por su id 
 * @removeStorageItemArray  elimina un elemento del array guardado con @setStorageArray buscandolo por su id 
 */

import { setStorageData, getStorageData, removeStorageData, setArrayItemStorageData, getArrayItemStorageData, removeArrayItemStorageData } from "../../api/storage";
import { IRondinCompletado, getEmptyRondinCompletado } from "../../interfaces/rondines";

export const setStorageRondinCompletado = async (IRondinCompletado : IRondinCompletado) => await setStorageData('IRondinCompletado', IRondinCompletado);
export const getStorageRondinCompletado = async () : Promise<IRondinCompletado> => await getStorageData('IRondinCompletado', getEmptyRondinCompletado());
export const removeStorageRondinCompletado = async () => await removeStorageData('IRondinCompletado');
export const setStorageRondinCompletadoArray = async (IRondinCompletado : IRondinCompletado[]) => await setStorageData('IRondinCompletadoArray', IRondinCompletado);
export const getStorageRondinCompletadoArray = async () : Promise<IRondinCompletado[]> => await getStorageData('IRondinCompletadoArray', [] as IRondinCompletado[]);
export const removeStorageRondinCompletadoArray = async () => await removeStorageData('IRondinCompletadoArray');
export const setStorageRondinCompletadoArrayItem = async (id: number, IRondinCompletado : IRondinCompletado) : Promise<IRondinCompletado[]> => await setArrayItemStorageData('IRondinCompletadoArray', id, IRondinCompletado, [] as IRondinCompletado[]);
export const getStorageRondinCompletadoArrayItem = async (id: number) : Promise<IRondinCompletado> => await getArrayItemStorageData('IRondinCompletadoArray', id, getEmptyRondinCompletado);
export const removeStorageRondinCompletadoArrayItem = async (id: number) : Promise<IRondinCompletado[]> => await removeArrayItemStorageData('IRondinCompletadoArray', id, [] as IRondinCompletado[]);
