/**
 * Métodos para el manejo de IonicStorage 
 * @setStorage              guarda un unico objeto al storage
 * @getStorage              obtiene el objeto guardado con @setStorage
 * @setStorageArray         guarda el array de objetos en el storage
 * @getStorageArray         obtiene el array de objetos guardado con @setStorageArray
 * @removeStorageArray      elimina el array de objetos guardado con @setStorageArray
 * @setStorageItemArray     agrega un elemento al array de objeto guardado con @setStorageArray
 * @getStorageItemArray     obtiene un elemento del array guardado con @setStorageArray buscandolo por su id 
 * @removeStorageItemArray  elimina un elemento del array guardado con @setStorageArray buscandolo por su id 
 */

import { setStorageData, getStorageData, removeStorageData, setArrayItemStorageData, getArrayItemStorageData, removeArrayItemStorageData } from "../../api/storage";
import { IMvTipoDocumento, getEmptyMvTipoDocumento } from "../../interfaces/personas";

export const setStorageMvTipoDocumento = async (IMvTipoDocumento : IMvTipoDocumento) => await setStorageData('IMvTipoDocumento', IMvTipoDocumento);
export const getStorageMvTipoDocumento = async () : Promise<IMvTipoDocumento> => await getStorageData('IMvTipoDocumento', getEmptyMvTipoDocumento());
export const removeStorageMvTipoDocumento = async () => await removeStorageData('IMvTipoDocumento');
export const setStorageMvTipoDocumentoArray = async (IMvTipoDocumento : IMvTipoDocumento[]) => await setStorageData('IMvTipoDocumentoArray', IMvTipoDocumento);
export const getStorageMvTipoDocumentoArray = async () : Promise<IMvTipoDocumento[]> => await getStorageData('IMvTipoDocumentoArray', [] as IMvTipoDocumento[]);
export const removeStorageMvTipoDocumentoArray = async () => await removeStorageData('IMvTipoDocumentoArray');
export const setStorageMvTipoDocumentoArrayItem = async (id: number, IMvTipoDocumento : IMvTipoDocumento) : Promise<IMvTipoDocumento[]> => await setArrayItemStorageData('IMvTipoDocumentoArray', id, IMvTipoDocumento, [] as IMvTipoDocumento[]);
export const getStorageMvTipoDocumentoArrayItem = async (id: number) : Promise<IMvTipoDocumento> => await getArrayItemStorageData('IMvTipoDocumentoArray', id, getEmptyMvTipoDocumento);
export const removeStorageMvTipoDocumentoArrayItem = async (id: number) : Promise<IMvTipoDocumento[]> => await removeArrayItemStorageData('IMvTipoDocumentoArray', id, [] as IMvTipoDocumento[]);
