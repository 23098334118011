/**
 * Métodos AsyncThunk para ser usados en los reducers y
 * los resultados a las llamadas http puedan ser usados para
 * actualizar el state en redux
 */

import { createAsyncThunk } from "@reduxjs/toolkit";
import { DEFAULT_STATE } from "../../enums/default_state";
import { getMvGenero, getAllMvGenero, updateMvGenero, createMvGenero, deleteMvGenero } from "../../http/personas";
import { getEmptyStatusMvGenero, IMvGenero } from "../../interfaces/personas";
import { IServerDate } from "../../interfaces/time";

export const getMvGeneroAsyncThunk = createAsyncThunk(
  'personas/getMvGeneroAsyncThunk',
  async (query : {serverDate : IServerDate, id: number}) => await getMvGenero(query.serverDate, query.id)
    .then(async (result) => result)
    .catch(async (error) => getEmptyStatusMvGenero(DEFAULT_STATE.error))  
);

export const getAllMvGeneroAsyncThunk = createAsyncThunk(
  'personas/getAllMvGeneroAsyncThunk',
  async (queryAll : {serverDate : IServerDate}) => await getAllMvGenero(queryAll.serverDate)
    .then(async (result) => result)
    .catch(async (error) => getEmptyStatusMvGenero(DEFAULT_STATE.error))  
);

export const updateMvGeneroAsyncThunk = createAsyncThunk(
  'personas/updateMvGeneroAsyncThunk',
  async (update: {serverDate : IServerDate, id: number, MvGenero: IMvGenero}) => await updateMvGenero(update.serverDate, update.id, update.MvGenero)
    .then(async (result) => result)
    .catch(async (error) => getEmptyStatusMvGenero(DEFAULT_STATE.error))  
);

export const createMvGeneroAsyncThunk = createAsyncThunk(
  'personas/createMvGeneroAsyncThunk',
  async (create : {serverDate : IServerDate, MvGenero: IMvGenero}) => await createMvGenero(create.serverDate, create.MvGenero)
    .then(async (result) => result)
    .catch(async (error) => getEmptyStatusMvGenero(DEFAULT_STATE.error))  
);

export const deleteMvGeneroAsyncThunk = createAsyncThunk(
  'personas/deleteMvGeneroAsyncThunk',
  async (del : {id : number}) => await deleteMvGenero(del.id)
    .then(async (result) => result)
    .catch(async (error) => getEmptyStatusMvGenero())  
);

