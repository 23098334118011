import { configureStore } from "@reduxjs/toolkit";
import authReducer from "./slices/authSlice";
import nominaReducer from "./slices/nominaSlice";
import personasReducer from "./slices/personasSlice";
import rondinesReducer from "./slices/rondinesSlice";
import themeReducer from "./slices/themeSlice";
import timeReducer from "./slices/timeSlice";

import dashboardReducer from "./slices/pagesSlice/dashboardSlice";
import disabilitiesReducer from "./slices/pagesSlice/disabilitiesSlice";
import teamMembersReducer from "./slices/pagesSlice/teamMembersSlice";
import workCentersReducer from "./slices/pagesSlice/workCentersSlice";
import patrolsReducer from "./slices/pagesSlice/patrolsSlice";
import scheduleChangeReducer from "./slices/pagesSlice/scheduleChangeSlice";
import recordReducer from "./slices/pagesSlice/recordSlice";
import reportsReducer from "./slices/pagesSlice/reportsSlice";
import requestsReducer from "./slices/pagesSlice/requestsSlice";
import vacationsReducer from "./slices/pagesSlice/vacationsSlice";
import deviceChangeRequestsReducer from "./slices/pagesSlice/deviceChangeRequestsSlice";

const store = configureStore({
  reducer: {
    auth: authReducer,
    nomina: nominaReducer,
    personas: personasReducer,
    rondines: rondinesReducer,
    theme: themeReducer,
    time: timeReducer,
    dashboard: dashboardReducer,
    disabilities: disabilitiesReducer,
    teamMembers: teamMembersReducer,
    workCenters: workCentersReducer,
    patrols: patrolsReducer,
    scheduleChange: scheduleChangeReducer,
    record: recordReducer,
    reports: reportsReducer,
    requests: requestsReducer,
    vacations: vacationsReducer,
    deviceChangeRequest: deviceChangeRequestsReducer,
  },
});

export default store;
export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
