/**
 * Métodos para el manejo de IonicStorage 
 * @setStorage              guarda un unico objeto al storage
 * @getStorage              obtiene el objeto guardado con @setStorage
 * @setStorageArray         guarda el array de objetos en el storage
 * @getStorageArray         obtiene el array de objetos guardado con @setStorageArray
 * @removeStorageArray      elimina el array de objetos guardado con @setStorageArray
 * @setStorageItemArray     agrega un elemento al array de objeto guardado con @setStorageArray
 * @getStorageItemArray     obtiene un elemento del array guardado con @setStorageArray buscandolo por su id 
 * @removeStorageItemArray  elimina un elemento del array guardado con @setStorageArray buscandolo por su id 
 */

import { setStorageData, getStorageData, removeStorageData, setArrayItemStorageData, getArrayItemStorageData, removeArrayItemStorageData } from "../../api/storage";
import { ICheckpointRondin, getEmptyCheckpointRondin } from "../../interfaces/rondines";

export const setStorageCheckpointRondin = async (ICheckpointRondin : ICheckpointRondin) => await setStorageData('ICheckpointRondin', ICheckpointRondin);
export const getStorageCheckpointRondin = async () : Promise<ICheckpointRondin> => await getStorageData('ICheckpointRondin', getEmptyCheckpointRondin());
export const removeStorageCheckpointRondin = async () => await removeStorageData('ICheckpointRondin');
export const setStorageCheckpointRondinArray = async (ICheckpointRondin : ICheckpointRondin[]) => await setStorageData('ICheckpointRondinArray', ICheckpointRondin);
export const getStorageCheckpointRondinArray = async () : Promise<ICheckpointRondin[]> => await getStorageData('ICheckpointRondinArray', [] as ICheckpointRondin[]);
export const removeStorageCheckpointRondinArray = async () => await removeStorageData('ICheckpointRondinArray');
export const setStorageCheckpointRondinArrayItem = async (id: number, ICheckpointRondin : ICheckpointRondin) : Promise<ICheckpointRondin[]> => await setArrayItemStorageData('ICheckpointRondinArray', id, ICheckpointRondin, [] as ICheckpointRondin[]);
export const getStorageCheckpointRondinArrayItem = async (id: number) : Promise<ICheckpointRondin> => await getArrayItemStorageData('ICheckpointRondinArray', id, getEmptyCheckpointRondin);
export const removeStorageCheckpointRondinArrayItem = async (id: number) : Promise<ICheckpointRondin[]> => await removeArrayItemStorageData('ICheckpointRondinArray', id, [] as ICheckpointRondin[]);
