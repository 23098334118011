/**
 * Métodos para el manejo de IonicStorage 
 * @setStorage              guarda un unico objeto al storage
 * @getStorage              obtiene el objeto guardado con @setStorage
 * @setStorageArray         guarda el array de objetos en el storage
 * @getStorageArray         obtiene el array de objetos guardado con @setStorageArray
 * @removeStorageArray      elimina el array de objetos guardado con @setStorageArray
 * @setStorageItemArray     agrega un elemento al array de objeto guardado con @setStorageArray
 * @getStorageItemArray     obtiene un elemento del array guardado con @setStorageArray buscandolo por su id 
 * @removeStorageItemArray  elimina un elemento del array guardado con @setStorageArray buscandolo por su id 
 */

import { setStorageData, getStorageData, removeStorageData, setArrayItemStorageData, getArrayItemStorageData, removeArrayItemStorageData } from "../../api/storage";
import { IInformacionBancaria, getEmptyInformacionBancaria } from "../../interfaces/personas";

export const setStorageInformacionBancaria = async (IInformacionBancaria : IInformacionBancaria) => await setStorageData('IInformacionBancaria', IInformacionBancaria);
export const getStorageInformacionBancaria = async () : Promise<IInformacionBancaria> => await getStorageData('IInformacionBancaria', getEmptyInformacionBancaria());
export const removeStorageInformacionBancaria = async () => await removeStorageData('IInformacionBancaria');
export const setStorageInformacionBancariaArray = async (IInformacionBancaria : IInformacionBancaria[]) => await setStorageData('IInformacionBancariaArray', IInformacionBancaria);
export const getStorageInformacionBancariaArray = async () : Promise<IInformacionBancaria[]> => await getStorageData('IInformacionBancariaArray', [] as IInformacionBancaria[]);
export const removeStorageInformacionBancariaArray = async () => await removeStorageData('IInformacionBancariaArray');
export const setStorageInformacionBancariaArrayItem = async (id: number, IInformacionBancaria : IInformacionBancaria) : Promise<IInformacionBancaria[]> => await setArrayItemStorageData('IInformacionBancariaArray', id, IInformacionBancaria, [] as IInformacionBancaria[]);
export const getStorageInformacionBancariaArrayItem = async (id: number) : Promise<IInformacionBancaria> => await getArrayItemStorageData('IInformacionBancariaArray', id, getEmptyInformacionBancaria);
export const removeStorageInformacionBancariaArrayItem = async (id: number) : Promise<IInformacionBancaria[]> => await removeArrayItemStorageData('IInformacionBancariaArray', id, [] as IInformacionBancaria[]);
