/**
 * Interfaces y Status para los helpers en "../../http/rondines/helpers" 
 * para peticiones especiales en rondines 
 */

import { getEmptyCheckRondin, getEmptyRondinCancelado, ICheckRondin, IRondinCancelado, IRondinCompletado } from ".";
import { DEFAULT_STATE } from "../../enums/default_state";

export interface IMovimientoRondin {
  id_rondin : number,
  id_empleado : number,
  id_geolocalizacion : number,
  id_checkpoint : number,
  identificador_rondin : string,
}



export const getEmptyMovimientoRondin = () => {
  const temp : IMovimientoRondin = 
  {
    id_rondin : 0,
    id_empleado : 0,
    id_geolocalizacion : 0,
    id_checkpoint : 0,
    identificador_rondin : "",
  };
  return temp;
}

export interface IStatusMovimientoRondin {
  estado : DEFAULT_STATE,
  mensaje : string,
  data : ICheckRondin,
}

export const getEmptyStatusMovimientoRondin = (status: DEFAULT_STATE = DEFAULT_STATE.loading) => {
  const temp : IStatusMovimientoRondin = 
  {
    estado : status,
    mensaje : "",
    data : getEmptyCheckRondin(),
  };
  return temp;
}


export interface ICancelarRondin {
  id_rondin : number,
  id_empleado : number,
  id_motivo_cancelacion : number,
  id_checkrondin : number,
  identificador_rondin : string,
  comentarios : string,
}

export const getEmptyCancelarRondin = () => {
  const temp : ICancelarRondin = 
  {
    id_rondin : 0,
    id_empleado : 0,
    id_motivo_cancelacion : 0,
    id_checkrondin : 0,
    identificador_rondin : "",
    comentarios : "",
  };
  return temp;
}

export interface IStatusCancelarRondin {
  estado : DEFAULT_STATE,
  mensaje : string,
  data : IRondinCancelado,
}

export const getEmptyStatusCancelarRondin = (status: DEFAULT_STATE = DEFAULT_STATE.loading) => {
  const temp : IStatusCancelarRondin = 
  {
    estado : status,
    mensaje : "",
    data : getEmptyRondinCancelado(),
  };
  return temp;
}


// Rango de fechas
export interface IDateRange {
  fecha_inicio : string,
  fecha_fin : string,
}

export const getEmptyDateRange = () => {
  const temp : IDateRange = 
  {
    fecha_inicio : "",
    fecha_fin : "",
  };
  return temp;
}


export interface IRondinesEmpleadoFecha extends IDateRange {
  empleado : number,
}

export const getEmptyRondinesEmpleadoFecha = () => {
  const temp : IRondinesEmpleadoFecha = 
  {
    ...getEmptyDateRange(),
    empleado : 0,
  };
  return temp;
}

export interface IStatusRondinesEmpleadoFecha {
  estado : DEFAULT_STATE,
  mensaje : string,
  data : ICheckRondin[],
}

export const getEmptyStatusRondinesEmpleadoFecha = (status: DEFAULT_STATE = DEFAULT_STATE.loading) => {
  const temp : IStatusRondinesEmpleadoFecha = 
  {
    estado : status,
    mensaje : "",
    data : [],
  };
  return temp;
}


export interface IHistorialRondinesCancelados extends IDateRange {}

export const getEmptyHistorialRondinesCancelados = () => {
  const temp : IHistorialRondinesCancelados = 
  {
    ...getEmptyDateRange(),
  };
  return temp;
}

export interface IStatusHistorialRondinesCancelados {
  estado : DEFAULT_STATE,
  mensaje : string,
  data : IRondinCancelado[],
}

export const getEmptyStatusHistorialRondinesCancelados = (status: DEFAULT_STATE = DEFAULT_STATE.loading) => {
  const temp : IStatusHistorialRondinesCancelados = 
  {
    estado : status,
    mensaje : "",
    data : [],
  };
  return temp;
}


export interface IHistorialRondinesCompletados extends IDateRange {}

export const getEmptyHistorialRondinesCompletados = () => {
  const temp : IHistorialRondinesCompletados = 
  {
    ...getEmptyDateRange(),
  };
  return temp;
}

export interface IStatusHistorialRondinesCompletados {
  estado : DEFAULT_STATE,
  mensaje : string,
  data : IRondinCompletado[],
}

export const getEmptyStatusHistorialRondinesCompletados = (status: DEFAULT_STATE = DEFAULT_STATE.loading) => {
  const temp : IStatusHistorialRondinesCompletados = 
  {
    estado : status,
    mensaje : "",
    data : [],
  };
  return temp;
}


export interface IHistorialRondinesCanceladosEmpleado extends IDateRange {
  empleado : number,
}

export const getEmptyHistorialRondinesCanceladosEmpleado = () => {
  const temp : IHistorialRondinesCanceladosEmpleado = 
  {
    ...getEmptyDateRange(),
    empleado : 0,
  };
  return temp;
}

export interface IStatusHistorialRondinesCanceladosEmpleado {
  estado : DEFAULT_STATE,
  mensaje : string,
  data : IRondinCancelado[],
}

export const getEmptyStatusHistorialRondinesCanceladosEmpleado = (status: DEFAULT_STATE = DEFAULT_STATE.loading) => {
  const temp : IStatusHistorialRondinesCanceladosEmpleado = 
  {
    estado : status,
    mensaje : "",
    data : [],
  };
  return temp;
}


export interface IUltimoCheck {
  empleado : number,
}

export const getEmptyUltimoCheck = () => {
  const temp : IUltimoCheck = 
  {
    empleado : 0,
  };
  return temp;
}

export interface IStatusUltimoCheck {
  estado : DEFAULT_STATE,
  mensaje : string,
  data : ICheckRondin,
}

export const getEmptyStatusUltimoCheck = (status: DEFAULT_STATE = DEFAULT_STATE.loading) => {
  const temp : IStatusUltimoCheck = 
  {
    estado : status,
    mensaje : "",
    data : getEmptyCheckRondin(),
  };
  return temp;
}
