/**
 * Métodos AsyncThunk para ser usados en los reducers y
 * los resultados a las llamadas http puedan ser usados para
 * actualizar el state en redux
 */

import { createAsyncThunk } from "@reduxjs/toolkit";
import { DEFAULT_STATE } from "../../enums/default_state";
import { getCentroDeTrabajo, getAllCentroDeTrabajo, updateCentroDeTrabajo, createCentroDeTrabajo, deleteCentroDeTrabajo } from "../../http/personas";
import { getEmptyStatusCentroDeTrabajo, ICentroDeTrabajo } from "../../interfaces/personas";
import { IServerDate } from "../../interfaces/time";

export const getCentroDeTrabajoAsyncThunk = createAsyncThunk(
  'personas/getCentroDeTrabajoAsyncThunk',
  async (query : {serverDate : IServerDate, id: number, idCliente : number}) => await getCentroDeTrabajo(query.serverDate, query.id, query.idCliente)
    .then(async (result) => result)
    .catch(async (error) => getEmptyStatusCentroDeTrabajo(DEFAULT_STATE.error))  
);

export const getAllCentroDeTrabajoAsyncThunk = createAsyncThunk(
  'personas/getAllCentroDeTrabajoAsyncThunk',
  async (queryAll : {serverDate : IServerDate, idCliente : number}) => await getAllCentroDeTrabajo(queryAll.serverDate, queryAll.idCliente)
    .then(async (result) => result)
    .catch(async (error) => getEmptyStatusCentroDeTrabajo(DEFAULT_STATE.error))  
);

export const updateCentroDeTrabajoAsyncThunk = createAsyncThunk(
  'personas/updateCentroDeTrabajoAsyncThunk',
  async (update: {serverDate : IServerDate, id: number, idCliente : number, object: ICentroDeTrabajo}) => await updateCentroDeTrabajo(update.serverDate, update.id, update.idCliente, update.object)
    .then(async (result) => result)
    .catch(async (error) => getEmptyStatusCentroDeTrabajo(DEFAULT_STATE.error))  
);

export const createCentroDeTrabajoAsyncThunk = createAsyncThunk(
  'personas/createCentroDeTrabajoAsyncThunk',
  async (create : {serverDate : IServerDate, idCliente : number, object: ICentroDeTrabajo}) => await createCentroDeTrabajo(create.serverDate, create.idCliente, create.object)
    .then(async (result) => result)
    .catch(async (error) => getEmptyStatusCentroDeTrabajo(DEFAULT_STATE.error))  
);

export const deleteCentroDeTrabajoAsyncThunk = createAsyncThunk(
  'personas/deleteCentroDeTrabajoAsyncThunk',
  async (del : {id : number, idCliente : number}) => await deleteCentroDeTrabajo(del.id, del.idCliente)
    .then(async (result) => result)
    .catch(async (error) => getEmptyStatusCentroDeTrabajo())  
);
