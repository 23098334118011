/**
 * Métodos AsyncThunk para ser usados en los reducers y
 * los resultados a las llamadas http puedan ser usados para
 * actualizar el state en redux
 */

import { createAsyncThunk } from "@reduxjs/toolkit";
import { DEFAULT_STATE } from "../../enums/default_state";
import { getDatosPersonales, getAllDatosPersonales, updateDatosPersonales, createDatosPersonales, deleteDatosPersonales } from "../../http/personas";
import { getEmptyStatusDatosPersonales, IDatosPersonales } from "../../interfaces/personas";
import { IServerDate } from "../../interfaces/time";

export const getDatosPersonalesAsyncThunk = createAsyncThunk(
  'personas/getDatosPersonalesAsyncThunk',
  async (query : {serverDate : IServerDate, id: number, idCliente : number}) => await getDatosPersonales(query.serverDate, query.id, query.idCliente)
    .then(async (result) => result)
    .catch(async (error) => getEmptyStatusDatosPersonales(DEFAULT_STATE.error))  
);

export const getAllDatosPersonalesAsyncThunk = createAsyncThunk(
  'personas/getAllDatosPersonalesAsyncThunk',
  async (queryAll : {serverDate : IServerDate, idCliente : number}) => await getAllDatosPersonales(queryAll.serverDate, queryAll.idCliente)
    .then(async (result) => result)
    .catch(async (error) => getEmptyStatusDatosPersonales(DEFAULT_STATE.error))  
);

export const updateDatosPersonalesAsyncThunk = createAsyncThunk(
  'personas/updateDatosPersonalesAsyncThunk',
  async (update: {serverDate : IServerDate, id: number, idCliente : number, object: IDatosPersonales}) => await updateDatosPersonales(update.serverDate, update.id, update.idCliente, update.object)
    .then(async (result) => result)
    .catch(async (error) => getEmptyStatusDatosPersonales(DEFAULT_STATE.error))  
);

export const createDatosPersonalesAsyncThunk = createAsyncThunk(
  'personas/createDatosPersonalesAsyncThunk',
  async (create : {serverDate : IServerDate, idCliente : number, object: IDatosPersonales}) => await createDatosPersonales(create.serverDate, create.idCliente, create.object)
    .then(async (result) => result)
    .catch(async (error) => getEmptyStatusDatosPersonales(DEFAULT_STATE.error))  
);

export const deleteDatosPersonalesAsyncThunk = createAsyncThunk(
  'personas/deleteDatosPersonalesAsyncThunk',
  async (del : {id : number, idCliente : number}) => await deleteDatosPersonales(del.id, del.idCliente)
    .then(async (result) => result)
    .catch(async (error) => getEmptyStatusDatosPersonales())  
);
