/**
 * Componente que se encarga del elemento Empresa
 * @IEmpresa es la interfaz que contiene los parámetos el elemento Empresa
 * @getEmptyEmpresa  devuelve un objeto con los parámetros por defecto; @param {id}, opcional cuando no encuentra el objeto en el LocalSotrage
 * @IStatusEmpresa es la interfaz para los estatus a llamadas a la API; @param {status}, opcional cuando queremos otro estado de inizialización de tipo [DEFAULT_STATE]
 * @getEmptyStatusEmpresa devuelve un objeto con los parámetros por defecto,
 * @setEmpresa devuelve un objeto de tipo [IEmpresa] recibiendo @param {object} y @param {serverDate}, para verificar que los datos estén en el formato correcto y ajustarlos a la zona horaria actual
 * @getSerializableEmpresa recibe un @param {IEmpresa}, para devolver un objeto serizalizable para las peticiones de la API
 * */

import { getDateString, isNotEmpty, SERVER_TIME_ZONE } from "../../api/helpers";
import { DEFAULT_STATE } from "../../enums/default_state";
import {
  getStorageClienteQentryArrayItem,
  getStorageDatosFiscalesArrayItem,
  getStorageDireccionArrayItem,
  getStorageSuscripcionQentryArrayItem,
  getStorageUserArrayItem,
} from "../../localStorage/personas";
import {
  convertDateToZonaHoraria,
  ILocalStorageExpiry,
  getEmptyLocalStorageExpiry,
  IServerDate,
} from "../time";
import {
  getEmptyClienteQentry,
  IClienteQentry,
  setClienteQentry,
} from "./IClienteQentry";
import {
  IDatosFiscales,
  getEmptyDatosFiscales,
  setDatosFiscales,
} from "./IDatosFiscales";
import { IDireccion, getEmptyDireccion, setDireccion } from "./IDireccion";
import {
  ISuscripcionQentry,
  getEmptySuscripcionQentry,
  setSuscripcionQentry,
} from "./ISuscripcionQentry";
import { IUser, getEmptyUser, setUser } from "./IUser";

export interface IEmpresa {
  id: number;
  nombre_empresa: string;
  cliente_qentry: IClienteQentry;
  datos_fiscales: IDatosFiscales;
  direccion: IDireccion;
  suscripcion: ISuscripcionQentry;
  autor: IUser;
  fecha_elaboracion: string; // Fecha
  estatus_sistema: boolean;
  ultima_actualizacion: string; // Fecha
}

export const getEmptyEmpresa = (id?: number) => {
  const temp: IEmpresa = {
    id: id ? id : 0,
    nombre_empresa: "",
    cliente_qentry: getEmptyClienteQentry(),
    datos_fiscales: getEmptyDatosFiscales(),
    direccion: getEmptyDireccion(),
    suscripcion: getEmptySuscripcionQentry(),
    autor: getEmptyUser(),
    fecha_elaboracion: "",
    estatus_sistema: false,
    ultima_actualizacion: "",
  };
  return temp;
};

export interface IStatusEmpresa {
  estado: DEFAULT_STATE;
  mensaje: string;
  data: IEmpresa;
  data_array: IEmpresa[];
  expiry: ILocalStorageExpiry;
  expiry_array: ILocalStorageExpiry;
}

export const getEmptyStatusEmpresa = (
  status: DEFAULT_STATE = DEFAULT_STATE.loading
) => {
  const temp: IStatusEmpresa = {
    estado: status,
    mensaje: "",
    data: getEmptyEmpresa(),
    data_array: [],
    expiry: getEmptyLocalStorageExpiry("IEmpresa", 0),
    expiry_array: getEmptyLocalStorageExpiry("IEmpresaArray", 0),
  };
  return temp;
};

export const setEmpresa = async (object: any, serverDate: IServerDate) => {
  const temp = getEmptyEmpresa();
  if (isNotEmpty(object.id)) temp.id = object.id;
  if (isNotEmpty(object.nombre_empresa))
    temp.nombre_empresa = object.nombre_empresa;
  if (isNotEmpty(object.cliente_qentry))
    temp.cliente_qentry =
      typeof object.cliente_qentry === "number"
        ? await getStorageClienteQentryArrayItem(object.cliente_qentry)
        : await setClienteQentry(object.cliente_qentry, serverDate);
  if (isNotEmpty(object.datos_fiscales))
    temp.datos_fiscales =
      typeof object.datos_fiscales === "number"
        ? await getStorageDatosFiscalesArrayItem(object.datos_fiscales)
        : await setDatosFiscales(object.datos_fiscales, serverDate);
  if (isNotEmpty(object.direccion))
    temp.direccion =
      typeof object.direccion === "number"
        ? await getStorageDireccionArrayItem(object.direccion)
        : await setDireccion(object.direccion, serverDate);
  if (isNotEmpty(object.suscripcion))
    temp.suscripcion =
      typeof object.suscripcion === "number"
        ? await getStorageSuscripcionQentryArrayItem(object.suscripcion)
        : await setSuscripcionQentry(object.suscripcion, serverDate);
  if (isNotEmpty(object.autor))
    temp.autor =
      typeof object.autor === "number"
        ? await getStorageUserArrayItem(object.autor)
        : await setUser(object.autor, serverDate);
  if (isNotEmpty(object.fecha_elaboracion))
    temp.fecha_elaboracion = convertDateToZonaHoraria(
      serverDate,
      object.fecha_elaboracion
    );
  if (isNotEmpty(object.estatus_sistema))
    temp.estatus_sistema = object.estatus_sistema;
  if (isNotEmpty(object.ultima_actualizacion))
    temp.ultima_actualizacion = convertDateToZonaHoraria(
      serverDate,
      object.ultima_actualizacion
    );
  return temp;
};

export const getSerializableEmpresa = (IEmpresa: IEmpresa) => {
  return {
    id: IEmpresa.id,
    nombre_empresa: IEmpresa.nombre_empresa,
    cliente_qentry: IEmpresa.cliente_qentry.id,
    datos_fiscales: IEmpresa.datos_fiscales.id,
    direccion: IEmpresa.direccion.id,
    suscripcion: IEmpresa.suscripcion.id > 0 ? IEmpresa.suscripcion.id : null,
    autor: IEmpresa.autor.id,
    fecha_elaboracion: IEmpresa.fecha_elaboracion,
    estatus_sistema: IEmpresa.estatus_sistema,
    ultima_actualizacion: getDateString(SERVER_TIME_ZONE),
  };
};
