/**
 * Métodos AsyncThunk para ser usados en los reducers y
 * los resultados a las llamadas http puedan ser usados para
 * actualizar el state en redux
 */

import { createAsyncThunk } from "@reduxjs/toolkit";
import { DEFAULT_STATE } from "../../enums/default_state";
import { getCambioEstatusIncapacidad, getAllCambioEstatusIncapacidad, updateCambioEstatusIncapacidad, createCambioEstatusIncapacidad, deleteCambioEstatusIncapacidad } from "../../http/nomina";
import { getEmptyStatusCambioEstatusIncapacidad, ICambioEstatusIncapacidad } from "../../interfaces/nomina";
import { IServerDate } from "../../interfaces/time";

export const getCambioEstatusIncapacidadAsyncThunk = createAsyncThunk(
  'nomina/getCambioEstatusIncapacidadAsyncThunk',
  async (query : {serverDate : IServerDate, id: number, idCliente : number}) => await getCambioEstatusIncapacidad(query.serverDate, query.id, query.idCliente)
    .then(async (result) => result)
    .catch(async (error) => getEmptyStatusCambioEstatusIncapacidad(DEFAULT_STATE.error))  
);

export const getAllCambioEstatusIncapacidadAsyncThunk = createAsyncThunk(
  'nomina/getAllCambioEstatusIncapacidadAsyncThunk',
  async (queryAll : {serverDate : IServerDate, idCliente : number}) => await getAllCambioEstatusIncapacidad(queryAll.serverDate, queryAll.idCliente)
    .then(async (result) => result)
    .catch(async (error) => getEmptyStatusCambioEstatusIncapacidad(DEFAULT_STATE.error))  
);

export const updateCambioEstatusIncapacidadAsyncThunk = createAsyncThunk(
  'nomina/updateCambioEstatusIncapacidadAsyncThunk',
  async (update: {serverDate : IServerDate, id: number, idCliente : number, object: ICambioEstatusIncapacidad}) => await updateCambioEstatusIncapacidad(update.serverDate, update.id, update.idCliente, update.object)
    .then(async (result) => result)
    .catch(async (error) => getEmptyStatusCambioEstatusIncapacidad(DEFAULT_STATE.error))  
);

export const createCambioEstatusIncapacidadAsyncThunk = createAsyncThunk(
  'nomina/createCambioEstatusIncapacidadAsyncThunk',
  async (create : {serverDate : IServerDate, idCliente : number, object: ICambioEstatusIncapacidad}) => await createCambioEstatusIncapacidad(create.serverDate, create.idCliente, create.object)
    .then(async (result) => result)
    .catch(async (error) => getEmptyStatusCambioEstatusIncapacidad(DEFAULT_STATE.error))  
);

export const deleteCambioEstatusIncapacidadAsyncThunk = createAsyncThunk(
  'nomina/deleteCambioEstatusIncapacidadAsyncThunk',
  async (del : {id : number, idCliente : number}) => await deleteCambioEstatusIncapacidad(del.id, del.idCliente)
    .then(async (result) => result)
    .catch(async (error) => getEmptyStatusCambioEstatusIncapacidad())  
);
