/**
 * Es el modal para la verificación del teléfono celular del trabajador
 * @param {string} phone: el número de télefono a verificar
 * @param {Function} onDismiss: el método que se realiza al cerrar el modal
 * @param {Function} onChangePhone: el método que actualiza el valor del state del número de teléfono
 * @param {Function} onSave: el método que se realiza el envío del codigo de verificación y la varificación de este.
 * @param {Function} onCaptcha: el método que se actualiza el valor del state del objeto RecaptchaVerifier
 * @param {Function} onCaptchaId: el método que se actualiza el valor del state del id del Captcha
 */

import React, { useEffect } from 'react';
import { IonButton, IonItem, IonInput, IonLabel, IonGrid, IonRow, IonCol, IonIcon, IonContent } from '@ionic/react';

import {
  sendSharp,
  closeCircleSharp,
} from 'ionicons/icons';

import { auth as firebaseAuth, RecaptchaVerifier} from "../firebase/config";
import "./PhoneVerifier.css"


const PhoneVerifier: React.FC<{
  phone: string;
  onDismiss: () => void;
  onChangePhone: (data: string) => void;
  onSave(): () => void;
  onCaptcha: (data: RecaptchaVerifier) => void;
  onCaptchaId: (data: number) => void;
}> = ({
  phone, 
  onDismiss,
  onChangePhone,
  onSave,
  onCaptcha,
  onCaptchaId,
 }) => {
  
  useEffect(() => {
    const verifier = new RecaptchaVerifier('recaptcha-container', {
        callback: (response:any) => console.log('callback', response),
    }, firebaseAuth);
    onCaptcha(verifier);
    verifier.render().then((widgetId) => {
      onCaptchaId(widgetId);
    });
  }, []);

  return (

  <IonContent>
    <IonGrid className="no-padding-modal">
      <IonRow className="ion-justify-content-center ion-text-center ion-align-self-center no-padding-modal">
        <IonCol className="no-padding-modal">
          <IonItem color="primary">
            <IonLabel>
              Escribe el teléfono:
            </IonLabel>
          </IonItem>
        </IonCol>
        <IonCol size="12" className="no-padding-modal">
          <IonItem>
            <IonLabel position="floating">Teléfono</IonLabel>
            <IonInput type="tel" value={phone} placeholder="Teléfono" onIonChange={e => onChangePhone(e.detail.value!)}></IonInput>
          </IonItem>
        </IonCol>
        <IonCol size="12" class='ion-center'>
          <div data-class="g-recaptcha" data-sitekey="6LedmRAcAAAAACkmQWbVj8AjRELA37RtKKq2FUBe" id="recaptcha-container"></div>
        </IonCol>
        <IonCol size="6">
          <IonButton expand="block" color="secondary" onClick={() => onSave()}>
            <IonIcon slot="icon-only" icon={sendSharp} />
          </IonButton>
        </IonCol>
        <IonCol size="6">
          <IonButton expand="block"  color="danger" onClick={() => onDismiss()}>
            <IonIcon slot="icon-only" icon={closeCircleSharp} />
          </IonButton>
        </IonCol>
      </IonRow>
    </IonGrid>
  </IonContent>
)};

export default PhoneVerifier;

