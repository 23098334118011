/** 
 * Componente que se encarga del elemento RondinCompletado
 * @IRondinCompletado es la interfaz que contiene los parámetos el elemento RondinCompletado
 * @getEmptyRondinCompletado  devuelve un objeto con los parámetros por defecto; @param {id}, opcional cuando no encuentra el objeto en el LocalSotrage
 * @IStatusRondinCompletado es la interfaz para los estatus a llamadas a la API; @param {status}, opcional cuando queremos otro estado de inizialización de tipo [DEFAULT_STATE]
 * @getEmptyStatusRondinCompletado devuelve un objeto con los parámetros por defecto,
 * @setRondinCompletado devuelve un objeto de tipo [IRondinCompletado] recibiendo @param {object} y @param {serverDate}, para verificar que los datos estén en el formato correcto y ajustarlos a la zona horaria actual
 * @getSerializableRondinCompletado recibe un @param {IRondinCompletado}, para devolver un objeto serizalizable para las peticiones de la API
 * */

import { IRondin, getEmptyRondin, setRondin } from ".";
import { isNotEmpty, getDateString, SERVER_TIME_ZONE } from "../../api/helpers";
import { DEFAULT_STATE } from "../../enums/default_state";
import { getStorageClienteQentryArrayItem, getStorageEmpleadoArrayItem, getStorageEmpresaArrayItem } from "../../localStorage/personas";
import { getStorageRondinArrayItem } from "../../localStorage/rondines";
import { IEmpleado, getEmptyEmpleado, setEmpleado, getEmptyClienteQentry, getEmptyEmpresa, IClienteQentry, IEmpresa, setClienteQentry, setEmpresa } from "../personas";
import { ILocalStorageExpiry, getEmptyLocalStorageExpiry, IServerDate, convertDateToZonaHoraria } from "../time";

export interface IRondinCompletado {
  id : number,
  empleado : IEmpleado,
  rondin : IRondin,
  identificador_rondin : string,
  cliente_qentry : IClienteQentry,
  empresa : IEmpresa,
  fecha_elaboracion : string, // Fecha
  estatus_sistema : boolean,
  ultima_actualizacion : string, // Fecha
}

export const getEmptyRondinCompletado = (id? : number) => {
  const temp : IRondinCompletado = 
  {
    id : id ? id : 0,
    empleado : getEmptyEmpleado(),
    rondin : getEmptyRondin(),
    identificador_rondin : "",
    empresa : getEmptyEmpresa(),
    cliente_qentry : getEmptyClienteQentry(),
    fecha_elaboracion : "",
    estatus_sistema : false,
    ultima_actualizacion : "",
  };
  return temp;
}

export interface IStatusRondinCompletado {
  estado : DEFAULT_STATE,
  mensaje : string,
  data : IRondinCompletado,
  data_array: IRondinCompletado[],
  expiry : ILocalStorageExpiry,
  expiry_array : ILocalStorageExpiry,
}

export const getEmptyStatusRondinCompletado = (status: DEFAULT_STATE = DEFAULT_STATE.loading) => {
  const temp : IStatusRondinCompletado = 
  {
    estado : status,
    mensaje : "",
    data : getEmptyRondinCompletado(),
    data_array : [],
    expiry : getEmptyLocalStorageExpiry("IRondinCompletado", 0),
    expiry_array : getEmptyLocalStorageExpiry("IRondinCompletadoArray", 0),
  };
  return temp;
}

export const setRondinCompletado = async (
  object : any,
  serverDate : IServerDate,
) => {
  const temp = getEmptyRondinCompletado();
  if (isNotEmpty(object.id)) temp.id = object.id;
  if (isNotEmpty(object.empleado)) temp.empleado = (typeof object.empleado === 'number') ? await getStorageEmpleadoArrayItem(object.empleado) : await setEmpleado(object.empleado, serverDate);
  if (isNotEmpty(object.rondin)) temp.rondin = (typeof object.rondin === 'number') ? await getStorageRondinArrayItem(object.rondin) : await setRondin(object.rondin, serverDate);
  if (isNotEmpty(object.identificador_rondin)) temp.identificador_rondin = object.identificador_rondin;
  if (isNotEmpty(object.cliente_qentry)) temp.cliente_qentry = (typeof object.cliente_qentry === 'number') ? await getStorageClienteQentryArrayItem(object.cliente_qentry) : await setClienteQentry(object.cliente_qentry, serverDate);
  if (isNotEmpty(object.empresa)) temp.empresa = (typeof object.empresa === 'number') ? await getStorageEmpresaArrayItem(object.empresa) : await setEmpresa(object.empresa, serverDate);
  if (isNotEmpty(object.fecha_elaboracion)) temp.fecha_elaboracion = convertDateToZonaHoraria(serverDate, object.fecha_elaboracion);
  if (isNotEmpty(object.estatus_sistema)) temp.estatus_sistema = object.estatus_sistema;
  if (isNotEmpty(object.ultima_actualizacion)) temp.ultima_actualizacion = convertDateToZonaHoraria(serverDate, object.ultima_actualizacion);
  return temp;
}

export const getSerializableRondinCompletado = (IRondinCompletado : IRondinCompletado ) => {
  return {
    id : IRondinCompletado.id,
    empleado : IRondinCompletado.empleado.id,
    rondin : IRondinCompletado.rondin.id,
    identificador_rondin : IRondinCompletado.identificador_rondin,
    empresa : IRondinCompletado.empresa.id,
    cliente_qentry : IRondinCompletado.cliente_qentry.id,
    fecha_elaboracion : IRondinCompletado.fecha_elaboracion,
    estatus_sistema : IRondinCompletado.estatus_sistema,
    ultima_actualizacion : getDateString(SERVER_TIME_ZONE),
  };
}