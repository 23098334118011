/**
 * Reducers creados para obtener asíncronamente 
 * los datos de los modelos y dependiendo del resultado
 * de la peticón actualice el state en redux o mande un mensaje de error
 */

import { PayloadAction } from "@reduxjs/toolkit";
import { getDatosFiscalesAsyncThunk, getAllDatosFiscalesAsyncThunk, updateDatosFiscalesAsyncThunk, createDatosFiscalesAsyncThunk, deleteDatosFiscalesAsyncThunk } from "../../asyncThunk/personas";
import { DEFAULT_STATE } from "../../enums/default_state";
import { IStatusDatosFiscales } from "../../interfaces/personas";
import { personasInitialState } from "../../slices/personasSlice";

// Reducers: DatosFiscales
export const createDatosFiscalesReducers = () => {
  const get = () => {
    var { pending, fulfilled, rejected } = getDatosFiscalesAsyncThunk;
    return {
      [pending.type]: (state : typeof personasInitialState) => {
        state.personasState.datosFiscales.estado = DEFAULT_STATE.loading;
      },
      [fulfilled.type]: (state : typeof personasInitialState, action : PayloadAction<IStatusDatosFiscales>) => {
        const result = action.payload;
        result.expiry_array = state.personasState.datosFiscales.expiry_array;
        if (result.estado === DEFAULT_STATE.error) result.data_array = state.personasState.datosFiscales.data_array;
        state.personasState.datosFiscales = result;
      },
      [rejected.type]: (state : typeof personasInitialState, action : PayloadAction<IStatusDatosFiscales>) => {
        state.personasState.datosFiscales = {
          ...state.personasState.datosFiscales,
          estado: action.payload.estado,
          mensaje: "Ocurrió un problema al realizar la solicitud",
        };
      },
    };
  };
  
  const getAll = () => {
    var { pending, fulfilled, rejected } = getAllDatosFiscalesAsyncThunk;
    return {
      [pending.type]: (state : typeof personasInitialState) => {
        state.personasState.datosFiscales.estado = DEFAULT_STATE.loading;
      },
      [fulfilled.type]: (state : typeof personasInitialState, action : PayloadAction<IStatusDatosFiscales>) => {
        const result = action.payload;
        result.data = state.personasState.datosFiscales.data;
        result.expiry = state.personasState.datosFiscales.expiry;
        if (result.estado === DEFAULT_STATE.error) result.data_array = state.personasState.datosFiscales.data_array;
        state.personasState.datosFiscales = result;
      },
      [rejected.type]: (state : typeof personasInitialState, action : PayloadAction<IStatusDatosFiscales>) => {
        state.personasState.datosFiscales = {
          ...state.personasState.datosFiscales,
          estado: action.payload.estado,
          mensaje: "Ocurrió un problema al realizar la solicitud",
        };
      },
    };
  };
  
  const update = () => {
    var { pending, fulfilled, rejected } = updateDatosFiscalesAsyncThunk;
    return {
      [pending.type]: (state : typeof personasInitialState) => {
        state.personasState.datosFiscales.estado = DEFAULT_STATE.loading;
      },
      [fulfilled.type]: (state : typeof personasInitialState, action : PayloadAction<IStatusDatosFiscales>) => {
        const result: IStatusDatosFiscales = action.payload;
        result.expiry = state.personasState.datosFiscales.expiry;
        result.expiry_array = state.personasState.datosFiscales.expiry_array;
        if (result.estado === DEFAULT_STATE.error) result.data_array = state.personasState.datosFiscales.data_array;
        state.personasState.datosFiscales = result;
      },
      [rejected.type]: (state : typeof personasInitialState, action : PayloadAction<IStatusDatosFiscales>) => {
        state.personasState.datosFiscales = {
          ...state.personasState.datosFiscales,
          estado: action.payload.estado,
          mensaje: "Ocurrió un problema al realizar la solicitud",
        };
      },
    };
  };

  const create = () => {
    var { pending, fulfilled, rejected } = createDatosFiscalesAsyncThunk;
    return {
      [pending.type]: (state : typeof personasInitialState) => {
        state.personasState.datosFiscales.estado = DEFAULT_STATE.loading;
      },
      [fulfilled.type]: (state : typeof personasInitialState, action : PayloadAction<IStatusDatosFiscales>) => {
        const result = action.payload;
        result.expiry = state.personasState.datosFiscales.expiry;
        result.expiry_array = state.personasState.datosFiscales.expiry_array;
        if (result.estado === DEFAULT_STATE.error) result.data_array = state.personasState.datosFiscales.data_array;
        state.personasState.datosFiscales = result;
      },
      [rejected.type]: (state : typeof personasInitialState, action : PayloadAction<IStatusDatosFiscales>) => {
        state.personasState.datosFiscales = {
          ...state.personasState.datosFiscales,
          estado: action.payload.estado,
          mensaje: "Ocurrió un problema al realizar la solicitud",
        };
      },
    };
  };
  
  const del = () => {
    var { pending, fulfilled, rejected } = deleteDatosFiscalesAsyncThunk;
    return {
      [pending.type]: (state : typeof personasInitialState) => {
        state.personasState.datosFiscales.estado = DEFAULT_STATE.loading;
      },
      [fulfilled.type]: (state : typeof personasInitialState, action : PayloadAction<IStatusDatosFiscales>) => {
        const result: IStatusDatosFiscales = action.payload;
        result.expiry = state.personasState.datosFiscales.expiry;
        result.expiry_array = state.personasState.datosFiscales.expiry_array;
        if (result.estado === DEFAULT_STATE.error) result.data_array = state.personasState.datosFiscales.data_array;
        state.personasState.datosFiscales = result;
      },
      [rejected.type]: (state : typeof personasInitialState, action : PayloadAction<IStatusDatosFiscales>) => {
        state.personasState.datosFiscales = {
          ...state.personasState.datosFiscales,
          estado: action.payload.estado,
          mensaje: "Ocurrió un problema al realizar la solicitud",
        };
      },
    };
  };

  return {
    ...get(),
    ...getAll(),
    ...update(),
    ...create(),
    ...del(),
  };
};
