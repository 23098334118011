/**
 * Componente utilizado para el efecto de cards como si fueran notas.
 * @interface CardProps
 *  @param {string} icon: si hay un icono adjunta otro card individual con el icono descrito en la parte superior derecha en overflow
 *  @param {string} img: si hay una url de una imagen la coloca centrada en la parte de arriba del card.
 *  @param {any} title, @param {any} subtitle: estan agrupados de lado derecho del objeto creeado con @param {string} icon, o debajo de @param {string} img
 *  @param {any} content: para definir estios extras a la card.
 *  @param {string} styleCss: para definir estios extras a la card.
 *  @param {string} contentstyleCss: para agregar estilos extra al@param {any} content del card.
 */

import React from 'react';

import {
  IonCard,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonCardContent,
  IonIcon,
  IonImg,
} from '@ionic/react';


import './Card.css';

interface CardProps {
  icon?: string;
  img?: string;
  title?: any;
  subtitle?: any;
  content?: any;
  styleCss?: any;
  contentstyleCss?: any;
}

const Card: React.FC<CardProps> = ({icon, img, title, subtitle, content, styleCss, contentstyleCss}) => {
  return (
    <IonCard class={"card-container " + styleCss}>
      {
        (icon) ?        
          <IonCard class="card-icon">
            <IonIcon icon={icon}/>
          </IonCard>
        :
          <></>
      }
      {
        (img) ?        
          <IonImg class="card-img" src={img} />
        :
          <></>
      }
      {
        title ?
          <IonCardHeader class={(icon) ? "card-header-style" : undefined }>
            <IonCardTitle>{title}</IonCardTitle>
            <IonCardSubtitle >{subtitle}</IonCardSubtitle>
          </IonCardHeader>
        :
          <></>
      }

      <IonCardContent className={contentstyleCss ? contentstyleCss +" card-content-style" : "card-content-style"}>
        {content}
      </IonCardContent>
    </IonCard>
  );
};

export default Card;
