/**
 * Métodos del, get, post, put para llamadas a la API
 * @get     en este método primero consulta con @getFromLocalStorage si es necesario hacer la petición http sino se obtiene del LocalStorage;
 *          si hay un error en la petición http se busca el objeto en el LocalStorage sino lo encuentra devuelve un objeto vacío; si la
 *          petición se realiza correctamente actualiza el valor individual y en el array del objeto en el LocalStorage y devuelve el objeto
 *          obtenido del servidor.
 * @getAll  en este método primero consulta con @getFromLocalStorage si es necesario hacer la petición http sino se obtiene del LocalStorage;
 *          si hay un error en la petición http se busca el objeto array en el LocalStorage sino lo encuentra devuelve un array vacío; si la
 *          petición se realiza correctamente actualiza array en el LocalStorage y devuelve el array obtenido del servidor
 * @update  en este método después de realizar la petición http si falla devuelve el objeto que se iba a actualizar; si la petición se
 *          realiza correctamente actualiza el valor individual y en el array del objeto en el LocalStorage y devuelve el objeto obtenido del
 *          servidor.
 * @create  en este método después de realizar la petición http si falla devuelve el objeto que se iba a crear; si la petición se realiza
 *          correctamente actualiza el valor individual y en el array del objeto en el LocalStorage y devuelve el objeto obtenido del
 *          servidor.
 * @delete  en este método después de realizar la petición http si falla devuelve el objeto que se iba a eliminar; si la petición se realiza
 *          correctamente elimina el valor individual y en el objeto en el arrya del LocalStorage y devuelve un objeto vacio (Default)
 */

import { del, get, post, put } from "../../api/api";
import {
  getFromLocalStorage,
  updateLocalStorageExpiry,
} from "../../api/helpers";
import { removeArrayItemStorageData } from "../../api/storage";
import { DEFAULT_STATE } from "../../enums/default_state";
import {
  getEmptyStatusServicioQentry,
  setServicioQentry,
  IServicioQentry,
  getSerializableServicioQentry,
} from "../../interfaces/personas";
import { IServerDate } from "../../interfaces/time";
import {
  getStorageServicioQentryArrayItem,
  setStorageServicioQentry,
  setStorageServicioQentryArrayItem,
  getStorageServicioQentryArray,
  setStorageServicioQentryArray,
  removeStorageServicioQentry,
} from "../../localStorage/personas";

export const getServicioQentry = async (
  serverDate: IServerDate,
  pk: number,
  idCliente: number
) => {
  const temp = getEmptyStatusServicioQentry();
  const doHttp = await getFromLocalStorage(serverDate, temp.expiry);
  temp.expiry = doHttp.storage;
  if (!doHttp.result) {
    console.log(
      `(localStorage) ServicioQentry con pk: ${pk}, obtenido con éxito`
    );
    temp.estado = DEFAULT_STATE.success;
    temp.mensaje = `ServicioQentry obtenida con éxito`;
    temp.data = await getStorageServicioQentryArrayItem(pk);
    return temp;
  }
  const result = await get(`personas/servicio-qentry/${pk}/${idCliente}`);
  const data = result.data;
  console.log(`ServicioQentry data: `, data);
  if (!data.id || data.detail) {
    console.log(
      `Error al obtener ServicioQentry con pk: ${pk}, ${data.detail}`
    );
    temp.estado = DEFAULT_STATE.error;
    temp.mensaje = `Error al obtener la ServicioQentry: ${
      data.detail ?? "Hubo un problema al conectarse con el servidor"
    }`;
    temp.data = await getStorageServicioQentryArrayItem(pk);
    return temp;
  }
  console.log(`ServicioQentry con pk: ${pk}, obtenido con éxito`);
  temp.estado = DEFAULT_STATE.success;
  temp.mensaje = `ServicioQentry obtenida con éxito`;
  temp.data = await setServicioQentry(data, serverDate);
  await setStorageServicioQentry(temp.data);
  temp.data_array = await setStorageServicioQentryArrayItem(pk, temp.data);
  temp.expiry = await updateLocalStorageExpiry(serverDate, temp.expiry);
  return temp;
};

export const getAllServicioQentry = async (
  serverDate: IServerDate,
  idCliente: number
) => {
  const temp = getEmptyStatusServicioQentry();
  const doHttp = await getFromLocalStorage(serverDate, temp.expiry_array);
  temp.expiry_array = doHttp.storage;
  if (!doHttp.result) {
    console.log(`(localStorage) Todos las ServicioQentry obtenidas con éxito`);
    temp.estado = DEFAULT_STATE.success;
    temp.mensaje = `Todos las ServicioQentry obtenidas con éxito`;
    temp.data_array = await getStorageServicioQentryArray();
    return temp;
  }
  const result = await get(`personas/servicio-qentry/${idCliente}`);
  const data = result.data;
  if (data.detail) {
    console.log(`Error al obtener la lista de ServicioQentry`, data);
    temp.estado = DEFAULT_STATE.error;
    temp.mensaje = `Error al obtener la lista de ServicioQentry: ${
      data.detail ?? "Hubo un problema al conectarse con el servidor"
    }`;
    temp.data_array = await getStorageServicioQentryArray();
    return temp;
  }
  console.log(`Todos las ServicioQentrys obtenidos con éxito`);
  temp.estado = DEFAULT_STATE.success;
  temp.mensaje = `Todos las ServicioQentry obtenidas con éxito`;
  temp.data_array = await Promise.all(
    data.map(async (e: any) => await setServicioQentry(e, serverDate))
  );
  await setStorageServicioQentryArray(temp.data_array);
  temp.expiry_array = await updateLocalStorageExpiry(
    serverDate,
    temp.expiry_array
  );
  return temp;
};

export const updateServicioQentry = async (
  serverDate: IServerDate,
  pk: number,
  idCliente: number,
  object: IServicioQentry
) => {
  const temp = getEmptyStatusServicioQentry();
  const result = await put(
    `personas/servicio-qentry/${pk}/${idCliente}`,
    getSerializableServicioQentry(object)
  );
  const data = result.data;
  if (!data.id || data.detail) {
    console.log(
      `Error al actualizar ServicioQentry con pk: ${pk}, ${data.detail}`
    );
    temp.estado = DEFAULT_STATE.error;
    temp.mensaje = `Error al actualizar la ServicioQentry: ${
      data.detail ?? "Hubo un problema al conectarse con el servidor"
    }`;
    temp.data = await setServicioQentry(object, serverDate);
    return temp;
  }
  console.log(`ServicioQentry con pk: ${pk}, actualizado con éxito`);
  temp.estado = DEFAULT_STATE.success;
  temp.mensaje = `ServicioQentry actualizada con éxito`;
  temp.data = await setServicioQentry(data, serverDate);
  await setStorageServicioQentry(temp.data);
  temp.data_array = await setStorageServicioQentryArrayItem(
    temp.data.id,
    temp.data
  );
  return temp;
};

export const createServicioQentry = async (
  serverDate: IServerDate,
  idCliente: number,
  object: IServicioQentry
) => {
  const temp = getEmptyStatusServicioQentry();
  const result = await post(
    `personas/servicio-qentry/${idCliente}`,
    getSerializableServicioQentry(object)
  );
  const data = result.data;
  if (!data.id || data.detail) {
    console.log(
      `Error al crear ServicioQentry con los datos ${object}, ${data.detail}`
    );
    temp.estado = DEFAULT_STATE.error;
    temp.mensaje = `Error al crear la ServicioQentry: ${
      data.detail ?? "Hubo un problema al conectarse con el servidor"
    }`;
    temp.data = await setServicioQentry(object, serverDate);
    return temp;
  }
  console.log(`ServicioQentry creada con éxito`);
  temp.estado = DEFAULT_STATE.success;
  temp.mensaje = `ServicioQentry creada con éxito`;
  temp.data = await setServicioQentry(data, serverDate);
  await setStorageServicioQentry(temp.data);
  temp.data_array = await setStorageServicioQentryArrayItem(
    temp.data.id,
    temp.data
  );
  return temp;
};

export const deleteServicioQentry = async (pk: number, idCliente: number) => {
  const temp = getEmptyStatusServicioQentry();
  const result = await del(`personas/servicio-qentry/${pk}/${idCliente}`);
  const data = result.data;
  if (data.detail) {
    console.log(
      `Error al eliminar ServicioQentry con pk: ${pk}, ${data.detail}`
    );
    temp.estado = DEFAULT_STATE.error;
    temp.mensaje = `Error al eliminar la ServicioQentry: ${data.detail}`;
    temp.data = await getStorageServicioQentryArrayItem(pk);
    temp.data_array = await getStorageServicioQentryArray();
    return temp;
  }
  console.log(`ServicioQentry eliminada con éxito`);
  temp.estado = DEFAULT_STATE.success;
  temp.mensaje = `ServicioQentry eliminada con éxito`;
  await removeStorageServicioQentry();
  temp.data_array = await removeArrayItemStorageData("IServicioQentry", pk, []);
  return temp;
};
