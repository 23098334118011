/**
 * Es el componente utilizado en la página Historial para la visualización de los registros de asistencia y rondines
 */

import React, { useState, useEffect } from "react";
import {
  IonGrid,
  IonRow,
  IonCol,
  IonDatetime,
  IonLabel,
  IonList,
  IonIcon,
  IonItem,
  IonContent,
  IonInput,
  IonPopover,
  IonSpinner,
} from "@ionic/react";

import { calendar, documentTextSharp } from "ionicons/icons";

import Card from "../components/Card";
import Selector from "../components/Selector";
import useRedux, { useAppSelector } from "../hooks/useRedux";

import "./Record.css";
import {
  getGlobalMemoizedEmpresaAsSelectList,
  getNombreEmpleado,
} from "../redux/slices/personasSlice";
import {
  setRecordSelectedEmpresa,
  setRecordSelectedMvTipoAsistencia,
  setRecordSelectedMvTipoMovimientoAsistencia,
} from "../redux/slices/pagesSlice/recordSlice";
import Thumbnail from "../components/Thumbnail";
import { getOnlyDateString } from "../redux/api/helpers";
import { DEFAULT_STATE } from "../redux/enums/default_state";
import { IAsistencia } from "../redux/interfaces/nomina";
import {
  getGlobalMemoizedMvTipoAsistenciaAsSelectList,
  getGlobalMemoizedMvTipoMovimientoAsistenciaAsSelectList,
} from "../redux/slices/nominaSlice";

const Record: React.FC = () => {
  const [{ asistencia, rondinCancelado, rondinCompletado }] = useRedux();

  const empresaList = useAppSelector(getGlobalMemoizedEmpresaAsSelectList);
  const selectedEmpresa = useAppSelector(
    (state) => state.record.selectedEmpresa
  );

  const mvTipoAsistenciaList = useAppSelector(
    getGlobalMemoizedMvTipoAsistenciaAsSelectList
  );
  const selectedMvTipoAsistencia = useAppSelector(
    (state) => state.record.selectedMvTipoAsistencia
  );

  const mvTipoMovimientoAsistenciaList = useAppSelector(
    getGlobalMemoizedMvTipoMovimientoAsistenciaAsSelectList
  );
  const selectedMvTipoMovimientoAsistencia = useAppSelector(
    (state) => state.record.selectedMvTipoMovimientoAsistencia
  );

  let [selectedDate, setSelectedDate] = useState<string>(getOnlyDateString());
  let [selectedPatrolDate, setSelectedPatrolDate] = useState<string>(
    getOnlyDateString()
  );

  const [asistenciaByDate, setAsistenciaByDate] = useState<IAsistencia[]>([]);

  useEffect(() => {
    let filtered = asistencia.data_array.filter(
      (e) => getOnlyDateString(e.fecha_elaboracion) === selectedDate
    );
    if (selectedEmpresa.id !== "")
      filtered = filtered.filter(
        (e) => selectedEmpresa.id === e.empleado.empresa.id.toString()
      );
    if (selectedMvTipoAsistencia.id !== "")
      filtered = filtered.filter(
        (e) => selectedMvTipoAsistencia.id === e.tipo_asistencia.id.toString()
      );
    if (selectedMvTipoMovimientoAsistencia.id !== "")
      filtered = filtered.filter(
        (e) =>
          selectedMvTipoMovimientoAsistencia.id ===
          e.tipo_movimiento_asistencia.id.toString()
      );
    setAsistenciaByDate(filtered);
  }, [
    asistencia.data_array,
    selectedDate,
    selectedMvTipoAsistencia,
    selectedMvTipoMovimientoAsistencia,
    selectedEmpresa,
  ]);

  return (
    <IonContent class="inner-content">
      <IonGrid>
        <IonRow>
          <IonCol>
            <Card
              icon={documentTextSharp}
              title={<IonLabel>Seleccionar Cuenta/Región.</IonLabel>}
              subtitle={
                <>
                  <Selector
                    value={selectedEmpresa}
                    label="Cuentas/Regiones"
                    list={empresaList}
                    changeState={setRecordSelectedEmpresa}
                  />
                  <IonItem className="modal-input">
                    <IonLabel position="floating" className="modal-label">
                      Fecha
                    </IonLabel>
                    <IonIcon
                      slot="start"
                      icon={calendar}
                      class="icon-input-margin"
                    />
                    <IonInput
                      id="record-date"
                      type="text"
                      clearInput={true}
                      color="secondary"
                      value={selectedDate}
                      className="custom-picker"
                    />

                    <IonPopover
                      className="custom-picker"
                      id="record-date-picker"
                      animated={false}
                      backdropDismiss={true}
                      trigger="record-date"
                      triggerAction="click"
                      show-backdrop="true"
                      class="date-picker"
                    >
                      <IonDatetime
                        className="custom-picker"
                        id="record-date-datetime"
                        color="primary"
                        presentation="date"
                        placeholder="Seleccionar fecha"
                        min="2017-01-01"
                        value={selectedDate}
                        onIonChange={async (e) => {
                          setSelectedDate(
                            getOnlyDateString(e.detail.value as string)
                          );
                        }}
                      ></IonDatetime>
                    </IonPopover>
                  </IonItem>
                </>
              }
              content={
                <IonGrid className="no-padding">
                  <IonRow className="no-padding">
                    <IonCol size="12">
                      <IonItem color="secondary" lines="full">
                        <IonLabel>
                          <h3>Registro de entradas y salidas</h3>
                          <div>
                            <Selector
                              classStyle="secondary"
                              color="secondary"
                              value={selectedMvTipoAsistencia}
                              label="Tipo de Registro"
                              list={mvTipoAsistenciaList}
                              changeState={setRecordSelectedMvTipoAsistencia}
                            />
                          </div>
                          <div>
                            <Selector
                              classStyle="secondary"
                              color="secondary"
                              value={selectedMvTipoMovimientoAsistencia}
                              label="Tipo de Movimiento"
                              list={mvTipoMovimientoAsistenciaList}
                              changeState={
                                setRecordSelectedMvTipoMovimientoAsistencia
                              }
                            />
                          </div>
                        </IonLabel>
                      </IonItem>
                      <IonList className="selection-area">
                        {asistenciaByDate.map((e) => {
                          return (
                            <IonItem
                              key={`${e.id}-${e.fecha_elaboracion}`}
                              lines="full"
                            >
                              <IonLabel>
                                <h3>
                                  <b>Empleado</b>:{" "}
                                  {getNombreEmpleado(e.empleado)}
                                </h3>
                                <h3>
                                  <b>Centro de trabajo</b>:{" "}
                                  {e.centro_de_trabajo.nombre_centro}
                                </h3>
                                <h3>
                                  <b>Distancia</b>:{" "}
                                  {e.distancia_con_centro_de_trabajo}m
                                </h3>
                                <h3>
                                  <b>Asistencia puntual</b>:{" "}
                                  {e.asistencia_puntual ? "Si" : "No"}
                                </h3>
                                <h3>
                                  <b>Registro</b>: {e.tipo_asistencia.dato}
                                </h3>
                                <h3>
                                  <b>Tipo de movimiento</b>:{" "}
                                  {e.tipo_movimiento_asistencia.dato}
                                </h3>
                                <h3>
                                  <b>Fecha</b>: {e.fecha_elaboracion}
                                </h3>
                              </IonLabel>
                              {
                                <Thumbnail
                                  src={
                                    e.evidencia_asistencia_por_tercero.imagen
                                  }
                                />
                              }
                            </IonItem>
                          );
                        })}
                        {asistencia.estado === DEFAULT_STATE.loading ? (
                          <IonSpinner
                            className="loading-center-horizontal"
                            name="circles"
                            color="medium"
                          />
                        ) : asistenciaByDate.length === 0 ? (
                          <IonItem lines="full">
                            <IonLabel>No hay datos</IonLabel>
                          </IonItem>
                        ) : (
                          <></>
                        )}
                      </IonList>
                    </IonCol>
                  </IonRow>
                </IonGrid>
              }
            />
          </IonCol>
        </IonRow>
      </IonGrid>
    </IonContent>
  );
};

export default Record;
