import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { getServerDateAsyncThunk } from "../asyncThunk/time/ServerDate";
import { DEFAULT_STATE } from "../enums/default_state";
import { ILocation } from "../interfaces/auth/ILocation";
import { getEmptyStatusServerDate } from "../interfaces/time";

export const timeSlice = createSlice({
  name: 'time',
  initialState: {
    timeState: {
      serverDate: getEmptyStatusServerDate(),
      location: {
        latitud: Number(process.env.REACT_APP_DEFAULT_COORDS_LAT!),
        longitud: Number(process.env.REACT_APP_DEFAULT_COORDS_LNG!),
      } as ILocation
    }
  },
  reducers: {
    setLocation: (state, action: PayloadAction<ILocation>) => {
      state.timeState.location = action.payload;
    }
  },
  extraReducers: {
    // Reducers: getServerDateAsyncThunk
    [getServerDateAsyncThunk.pending.type]: (state, action) => {
      state.timeState.serverDate.estado = DEFAULT_STATE.loading;
    },
    [getServerDateAsyncThunk.fulfilled.type]: (state, action) => {
      // console.log('getServerDateAsyncThunk.fulfilled');
      // console.log('action.payload: ', action.payload);
      state.timeState.serverDate = action.payload;
    },
    [getServerDateAsyncThunk.rejected.type]: (state, action) => {
      // console.log('getServerDateAsyncThunk.rejected');
      state.timeState.serverDate = action.payload;
    },
  }
});

export const { setLocation } = timeSlice.actions

export default timeSlice.reducer;
