/**
 * Métodos para el manejo de IonicStorage 
 * @setStorage              guarda un unico objeto al storage
 * @getStorage              obtiene el objeto guardado con @setStorage
 * @setStorageArray         guarda el array de objetos en el storage
 * @getStorageArray         obtiene el array de objetos guardado con @setStorageArray
 * @removeStorageArray      elimina el array de objetos guardado con @setStorageArray
 * @setStorageItemArray     agrega un elemento al array de objeto guardado con @setStorageArray
 * @getStorageItemArray     obtiene un elemento del array guardado con @setStorageArray buscandolo por su id 
 * @removeStorageItemArray  elimina un elemento del array guardado con @setStorageArray buscandolo por su id 
 */

import { setStorageData, getStorageData, removeStorageData, setArrayItemStorageData, getArrayItemStorageData, removeArrayItemStorageData } from "../../api/storage";
import { ISuscripcionQentry, getEmptySuscripcionQentry } from "../../interfaces/personas";

export const setStorageSuscripcionQentry = async (ISuscripcionQentry : ISuscripcionQentry) => await setStorageData('ISuscripcionQentry', ISuscripcionQentry);
export const getStorageSuscripcionQentry = async () : Promise<ISuscripcionQentry> => await getStorageData('ISuscripcionQentry', getEmptySuscripcionQentry());
export const removeStorageSuscripcionQentry = async () => await removeStorageData('ISuscripcionQentry');
export const setStorageSuscripcionQentryArray = async (ISuscripcionQentry : ISuscripcionQentry[]) => await setStorageData('ISuscripcionQentryArray', ISuscripcionQentry);
export const getStorageSuscripcionQentryArray = async () : Promise<ISuscripcionQentry[]> => await getStorageData('ISuscripcionQentryArray', [] as ISuscripcionQentry[]);
export const removeStorageSuscripcionQentryArray = async () => await removeStorageData('ISuscripcionQentryArray');
export const setStorageSuscripcionQentryArrayItem = async (id: number, ISuscripcionQentry : ISuscripcionQentry) : Promise<ISuscripcionQentry[]> => await setArrayItemStorageData('ISuscripcionQentryArray', id, ISuscripcionQentry, [] as ISuscripcionQentry[]);
export const getStorageSuscripcionQentryArrayItem = async (id: number) : Promise<ISuscripcionQentry> => await getArrayItemStorageData('ISuscripcionQentryArray', id, getEmptySuscripcionQentry);
export const removeStorageSuscripcionQentryArrayItem = async (id: number) : Promise<ISuscripcionQentry[]> => await removeArrayItemStorageData('ISuscripcionQentryArray', id, [] as ISuscripcionQentry[]);
