import { createSlice, PayloadAction, createSelector } from "@reduxjs/toolkit";
import { List, setListItem } from "../../interfaces/helpers";
import { RootState } from "../../store";
import { getNombreEmpleado } from "../personasSlice";

export interface Horarios {
  dia: string;
  entrada: string | null;
  numDia: number;
  salida: string | null;
  seleccionado: boolean;
}

export interface HorariosUpdate {
  id: string;
  horarios: Horarios[];
}

export interface IScheduleChange {
  selectedEmpresa: List;
  selectedTurno: List;
  empleadosList: List[];
  defaultHorario: Horarios[];
}

export const scheduleChangeInitialState: IScheduleChange = {
  selectedEmpresa: { id: "", title: "Todas las empresas" },
  selectedTurno: { id: "", title: "" },
  empleadosList: [],
  defaultHorario: [
    {
      dia: "Lunes",
      entrada: "",
      numDia: 1,
      salida: "",
      seleccionado: false,
    },
    {
      dia: "Martes",
      entrada: "",
      numDia: 2,
      salida: "",
      seleccionado: false,
    },
    {
      dia: "Miércoles",
      entrada: "",
      numDia: 3,
      salida: "",
      seleccionado: false,
    },
    {
      dia: "Jueves",
      entrada: "",
      numDia: 4,
      salida: "",
      seleccionado: false,
    },
    {
      dia: "Viernes",
      entrada: "",
      numDia: 5,
      salida: "",
      seleccionado: false,
    },
    {
      dia: "Sábado",
      entrada: "",
      numDia: 6,
      salida: "",
      seleccionado: false,
    },
    {
      dia: "Domingo",
      entrada: "",
      numDia: 7,
      salida: "",
      seleccionado: false,
    },
  ],
};

export const scheduleChangeSlice = createSlice({
  name: "scheduleChange",
  initialState: scheduleChangeInitialState,
  reducers: {
    setScheduleChangeSelectedEmpresa: (state, action: PayloadAction<List>) => {
      state.selectedEmpresa = action.payload;
    },
    setScheduleChangeSelectedTurno: (state, action: PayloadAction<List>) => {
      state.selectedTurno = action.payload;
    },
  },
});

export const {
  setScheduleChangeSelectedEmpresa,
  setScheduleChangeSelectedTurno,
} = scheduleChangeSlice.actions;

export default scheduleChangeSlice.reducer;

export const getMemoizedScheduleChangeActiveEmpleado = createSelector(
  (state: RootState) => state.scheduleChange.selectedEmpresa,
  (state: RootState) => state.personas.personasState.empleado.data_array,
  (selectedEmpresa, empleados) => {
    if (selectedEmpresa.id === "") return empleados.filter((e) => e.activo);
    const empresa = Number(selectedEmpresa.id);
    return empleados.filter((e) => e.empresa.id === empresa && e.activo);
  }
);

export const getMemoizedScheduleChangeEmpleadoAsSelectList = createSelector(
  getMemoizedScheduleChangeActiveEmpleado,
  (empleado) => {
    const items = empleado.map((e) =>
      setListItem({ id: e.id.toString(), title: getNombreEmpleado(e) })
    );
    items.sort((a, b) => {
      return a.title > b.title ? 1 : a.title < b.title ? -1 : 0;
    });
    return items;
  }
);

export const getMemoizedScheduleChangeTurnoAsSelectList = createSelector(
  (state: RootState) => state.nomina.nominaState.turno.data_array,
  (turno) => {
    const items = turno.map((e) =>
      setListItem({ id: e.id.toString(), title: e.nombre_horario })
    );
    items.sort((a, b) => {
      return a.title > b.title ? 1 : a.title < b.title ? -1 : 0;
    });
    return items;
  }
);
