/**
 * Métodos del, get, post, put para llamadas a la API
 * @get     en este método primero consulta con @getFromLocalStorage si es necesario hacer la petición http sino se obtiene del LocalStorage;
 *          si hay un error en la petición http se busca el objeto en el LocalStorage sino lo encuentra devuelve un objeto vacío; si la
 *          petición se realiza correctamente actualiza el valor individual y en el array del objeto en el LocalStorage y devuelve el objeto
 *          obtenido del servidor.
 * @getAll  en este método primero consulta con @getFromLocalStorage si es necesario hacer la petición http sino se obtiene del LocalStorage;
 *          si hay un error en la petición http se busca el objeto array en el LocalStorage sino lo encuentra devuelve un array vacío; si la
 *          petición se realiza correctamente actualiza array en el LocalStorage y devuelve el array obtenido del servidor
 * @update  en este método después de realizar la petición http si falla devuelve el objeto que se iba a actualizar; si la petición se
 *          realiza correctamente actualiza el valor individual y en el array del objeto en el LocalStorage y devuelve el objeto obtenido del
 *          servidor.
 * @create  en este método después de realizar la petición http si falla devuelve el objeto que se iba a crear; si la petición se realiza
 *          correctamente actualiza el valor individual y en el array del objeto en el LocalStorage y devuelve el objeto obtenido del
 *          servidor.
 * @delete  en este método después de realizar la petición http si falla devuelve el objeto que se iba a eliminar; si la petición se realiza
 *          correctamente elimina el valor individual y en el objeto en el arrya del LocalStorage y devuelve un objeto vacio (Default)
 */

import { del, get, post, put } from "../../api/api";
import {
  getFromLocalStorage,
  updateLocalStorageExpiry,
} from "../../api/helpers";
import { removeArrayItemStorageData } from "../../api/storage";
import { DEFAULT_STATE } from "../../enums/default_state";
import {
  getEmptyStatusMvEstatusVacaciones,
  setMvEstatusVacaciones,
  IMvEstatusVacaciones,
  getSerializableMvEstatusVacaciones,
} from "../../interfaces/nomina";
import { IServerDate } from "../../interfaces/time";
import {
  getStorageMvEstatusVacacionesArrayItem,
  setStorageMvEstatusVacaciones,
  setStorageMvEstatusVacacionesArrayItem,
  getStorageMvEstatusVacacionesArray,
  setStorageMvEstatusVacacionesArray,
  removeStorageMvEstatusVacaciones,
} from "../../localStorage/nomina";

export const getMvEstatusVacaciones = async (
  serverDate: IServerDate,
  pk: number
) => {
  const temp = getEmptyStatusMvEstatusVacaciones();
  const doHttp = await getFromLocalStorage(serverDate, temp.expiry);
  temp.expiry = doHttp.storage;
  if (!doHttp.result) {
    console.log(
      `(localStorage) MvEstatusVacaciones con pk: ${pk}, obtenido con éxito`
    );
    temp.estado = DEFAULT_STATE.success;
    temp.mensaje = `MvEstatusVacaciones obtenido con éxito`;
    temp.data = await getStorageMvEstatusVacacionesArrayItem(pk);
    return temp;
  }
  const result = await get(`nomina/estatus-vacaciones/${pk}`);
  const data = result.data;
  console.log(`MvEstatusVacaciones data: `, data);
  if (!data.id || data.detail) {
    console.log(
      `Error al obtener MvEstatusVacaciones con pk: ${pk}, ${data.detail}`
    );
    temp.estado = DEFAULT_STATE.error;
    temp.mensaje = `Error al obtener el MvEstatusVacaciones: ${
      data.detail ?? "Hubo un problema al conectarse con el servidor"
    }`;
    temp.data = await getStorageMvEstatusVacacionesArrayItem(pk);
    return temp;
  }
  console.log(`MvEstatusVacaciones con pk: ${pk}, obtenido con éxito`);
  temp.estado = DEFAULT_STATE.success;
  temp.mensaje = `MvEstatusVacaciones obtenido con éxito`;
  temp.data = await setMvEstatusVacaciones(data, serverDate);
  await setStorageMvEstatusVacaciones(temp.data);
  temp.data_array = await setStorageMvEstatusVacacionesArrayItem(pk, temp.data);
  temp.expiry = await updateLocalStorageExpiry(serverDate, temp.expiry);
  return temp;
};

export const getAllMvEstatusVacaciones = async (serverDate: IServerDate) => {
  const temp = getEmptyStatusMvEstatusVacaciones();
  const doHttp = await getFromLocalStorage(serverDate, temp.expiry_array);
  temp.expiry_array = doHttp.storage;
  if (!doHttp.result) {
    console.log(
      `(localStorage) Todos los MvEstatusVacacioness obtenidos con éxito`
    );
    temp.estado = DEFAULT_STATE.success;
    temp.mensaje = `Todos los MvEstatusVacaciones obtenidos con éxito`;
    temp.data_array = await getStorageMvEstatusVacacionesArray();
    return temp;
  }
  const result = await get(`nomina/estatus-vacaciones`);
  const data = result.data;
  if (data.detail) {
    console.log(
      `Error al obtener la lista de MvEstatusVacaciones, ${data.detail}`
    );
    temp.estado = DEFAULT_STATE.error;
    temp.mensaje = `Error al obtener la lista de MvEstatusVacaciones: ${
      data.detail ?? "Hubo un problema al conectarse con el servidor"
    }`;
    temp.data_array = await getStorageMvEstatusVacacionesArray();
    return temp;
  }
  console.log(`Todos los MvEstatusVacaciones obtenidos con éxito`, data);
  temp.estado = DEFAULT_STATE.success;
  temp.mensaje = `Todos los MvEstatusVacaciones obtenidos con éxito`;
  temp.data_array = await Promise.all(
    data.map(async (e: any) => await setMvEstatusVacaciones(e, serverDate))
  );
  await setStorageMvEstatusVacacionesArray(temp.data_array);
  temp.expiry_array = await updateLocalStorageExpiry(
    serverDate,
    temp.expiry_array
  );
  return temp;
};

export const updateMvEstatusVacaciones = async (
  serverDate: IServerDate,
  pk: number,
  object: IMvEstatusVacaciones
) => {
  const temp = getEmptyStatusMvEstatusVacaciones();
  const result = await put(
    `nomina/estatus-vacaciones/${pk}`,
    getSerializableMvEstatusVacaciones(object)
  );
  const data = result.data;
  if (!data.id || data.detail) {
    console.log(
      `Error al actualizar MvEstatusVacaciones con pk: ${pk}, ${data.detail}`
    );
    temp.estado = DEFAULT_STATE.error;
    temp.mensaje = `Error al actualizar el MvEstatusVacaciones: ${
      data.detail ?? "Hubo un problema al conectarse con el servidor"
    }`;
    temp.data = await setMvEstatusVacaciones(object, serverDate);
    return temp;
  }
  console.log(`MvEstatusVacaciones con pk: ${pk}, actualizado con éxito`);
  temp.estado = DEFAULT_STATE.success;
  temp.mensaje = `MvEstatusVacaciones actualizado con éxito`;
  temp.data = await setMvEstatusVacaciones(data, serverDate);
  await setStorageMvEstatusVacaciones(temp.data);
  temp.data_array = await setStorageMvEstatusVacacionesArrayItem(
    temp.data.id,
    temp.data
  );
  return temp;
};

export const createMvEstatusVacaciones = async (
  serverDate: IServerDate,
  object: IMvEstatusVacaciones
) => {
  const temp = getEmptyStatusMvEstatusVacaciones();
  const result = await post(
    `nomina/estatus-vacaciones`,
    getSerializableMvEstatusVacaciones(object)
  );
  const data = result.data;
  if (!data.id || data.detail) {
    console.log(
      `Error al crear MvEstatusVacaciones con los datos ${object}, ${data.detail}`
    );
    temp.estado = DEFAULT_STATE.error;
    temp.mensaje = `Error al crear el MvEstatusVacaciones: ${
      data.detail ?? "Hubo un problema al conectarse con el servidor"
    }`;
    temp.data = await setMvEstatusVacaciones(object, serverDate);
    return temp;
  }
  console.log(`MvEstatusVacaciones creado con éxito`);
  temp.estado = DEFAULT_STATE.success;
  temp.mensaje = `MvEstatusVacaciones creado con éxito`;
  temp.data = await setMvEstatusVacaciones(data, serverDate);
  await setStorageMvEstatusVacaciones(temp.data);
  temp.data_array = await setStorageMvEstatusVacacionesArrayItem(
    temp.data.id,
    temp.data
  );
  return temp;
};

export const deleteMvEstatusVacaciones = async (pk: number) => {
  const temp = getEmptyStatusMvEstatusVacaciones();
  const result = await del(`nomina/estatus-vacaciones/${pk}`);
  const data = result.data;
  if (data.detail) {
    console.log(
      `Error al eliminar MvEstatusVacaciones con pk: ${pk}, ${data.detail}`
    );
    temp.estado = DEFAULT_STATE.error;
    temp.mensaje = `Error al eliminar el datos fiscales: ${data.detail}`;
    temp.data = await getStorageMvEstatusVacacionesArrayItem(pk);
    temp.data_array = await getStorageMvEstatusVacacionesArray();
    return temp;
  }
  console.log(`MvEstatusVacaciones eliminado con éxito`);
  temp.estado = DEFAULT_STATE.success;
  temp.mensaje = `MvEstatusVacaciones eliminado con éxito`;
  await removeStorageMvEstatusVacaciones();
  temp.data_array = await removeArrayItemStorageData(
    "IMvEstatusVacaciones",
    pk,
    []
  );
  return temp;
};
